export enum EIntegrationTypes {
  INTEGRATION_TYPE_SHOPIFY = 21,
  INTEGRATION_TYPE_FACEBOOK = 141,
  INTEGRATION_TYPE_SMS = 8, // it is not original id, used only for testing
  INTEGRATION_TYPE_EMAIL = 158,
  INTEGRATION_TYPE_WHATSAPP = 185,
  INTEGRATION_TYPE_SLACK = 221,
  // TODO : REPLACE WITH CORRECT ID AND REMOVE THIS COMMENT
  INTEGRATION_TYPE_OUTBOUND_EMAIL = 222,
}
