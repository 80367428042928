import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DeleteCampaignParams {
  categoryId: string | number;
  templateId: string | number;
  variantId: string | number;
}

async function deleteTemplateService(params: DeleteCampaignParams) {
  const { data: res } = await axiosJSON.delete(
    "/api/campaign/templates/delete",
    {
      data: params,
    },
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something Unexpected Occured!";
  }
}

export default deleteTemplateService;
