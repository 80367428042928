import PricingBox from "./children/PricingBox/PricingBox";
import ErrorModal from "./children/ErrorModal/ErrorModal";
import UpgradeModal, {
  UpgradeBytePlanUI,
} from "./children/UpgradeModal/UpgradeModal";
import { useCallback, useState } from "react";
import { PossibleHiddenFeatures } from "src/services/Settings/Users/getCurrentUser";

export type AllPricingModalTypes = null | "initiate" | "error" | "pricing";

function UpgradePlan({
  showModal,
  setShowModal,
  icon,
  upgradeType = "modal",
}: {
  showModal: AllPricingModalTypes;
  setShowModal: React.Dispatch<React.SetStateAction<AllPricingModalTypes>>;
  icon: null | PossibleHiddenFeatures;
  upgradeType?: "modal" | "component";
}) {
  const [retryLoading, setRetryLoading] = useState(false);
  const onHide = useCallback(
    function () {
      setShowModal(null);
    },
    [setShowModal],
  );

  return (
    <div>
      <PricingBox
        showModal={showModal === "pricing"}
        upgrade={function () {
          setShowModal("error");
        }}
        onHide={onHide}
      />
      <ErrorModal
        showLoader={retryLoading}
        showModal={showModal === "error"}
        retry={function () {
          setShowModal("error");
          setRetryLoading(true);
          setTimeout(() => {
            setRetryLoading(false);
          }, 1500);
        }}
        back={function () {
          setShowModal("pricing");
        }}
        onHide={onHide}
      />
      {upgradeType === "modal" ? (
        <UpgradeModal
          showModal={showModal === "initiate"}
          icon={icon}
          upgrade={function () {
            setShowModal("pricing");
          }}
          onHide={onHide}
        />
      ) : (
        <UpgradeBytePlanUI
          upgrade={function () {
            console.log("called");
            setShowModal("pricing");
          }}
          icon={icon}
        />
      )}
    </div>
  );
}

export default UpgradePlan;
