import React, { useCallback, useState } from "react";
import styles from "./EmailPreviewText.module.scss";

// Define the interface for the props
interface EmailPreviewTextProps {
  id: string;
  labelText: string;
  placeholder: string;
  onTextChange?: (text: string) => void;
  showError?: boolean;
  initialText?: string;
  errorMsg?: string;
}

const EmailPreviewText: React.FC<EmailPreviewTextProps> = ({
  id,
  labelText,
  placeholder,
  onTextChange,
  showError = false,
  initialText = "",
  errorMsg,
}) => {
  const [inputText, setInputText] = useState(initialText);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      setInputText(text);
      if (onTextChange) {
        onTextChange(text);
      }
    },
    [onTextChange],
  );

  return (
    <div className={`${styles.uploadWrapper}`}>
      <div className="mb-3">
        <label
          htmlFor={id}
          className={`form-label ${styles.label}`}
        >
          {labelText}
        </label>
        <input
          type="text"
          className={`form-control ${styles.inputBox} ${
            showError && inputText.trim() === ""
              ? "border border-danger shadow-none"
              : ""
          }`}
          id={id}
          placeholder={placeholder}
          value={inputText}
          onChange={handleInputChange}
        />
        {showError &&
        inputText.trim() === "" &&
        errorMsg &&
        errorMsg?.length > 0 ? (
          <div className={styles.errorText}>{errorMsg}</div>
        ) : null}
      </div>
    </div>
  );
};

export default EmailPreviewText;
