import { axiosJSON } from "src/globals/axiosEndPoints";
import { ECampaignStatus } from "./getCampaignById";

export interface UpdateCampaignParam {
  campaignId: number;
  name?: string;
  status?: ECampaignStatus;
}

export const updateCampaign = async (params: UpdateCampaignParam) => {
  const { data: res } = await axiosJSON.post("/api/campaign/update", params);
  if (res.err) {
    throw res.msg;
  }

  return res.data;
};
