import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CampaignTrigger {
  id: number | string;
  value: string;
  info?: string;
  /** Not Used at the moment - Use this to add any sub options for this particular trigger */
  subOptions?: Array<{
    id: number | string;
    value: string;
  }>;
}

const getTriggersService = async () => {
  const { data: res } = await axiosJSON.get(`/api/campaign/getTriggers`, {
    params: { cacheIt: true, cacheTime: Infinity },
  });

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res.data as CampaignTrigger[];
};

export default getTriggersService;
