import { useInfiniteQuery } from "@tanstack/react-query";
import { getTimeLineEvents } from "src/services/TimeLine/getTimeLineEvent";

interface UseGetTimeLineDataProps {
  customerId: string | number; // Customer identifier, can be a string or number
  eventData?: any[]; // Optional array of event data
  start?: number; // Optional starting index for pagination
  limit?: number; // Optional limit for the number of items per page
}

export interface StageData {
  attributeId: number; // ID of the attribute
  stageId: string; // ID of the stage
  stageName: string; // Name of the stage
  value: number; // Value associated with the stage
}

export interface OptionType {
  label: string; // Display label for the option
  value: string; // Value of the option
}

const DEFAULT_LIMIT = 10; // Default limit for items per page

export const useGetTimeLineEvents = ({
  customerId,
  start = 0, // Default to 0 if not provided
  limit = DEFAULT_LIMIT, // Default limit for the number of items
}: UseGetTimeLineDataProps) => {
  const query = useInfiniteQuery(
    ["getTimelineEvents", customerId],
    (
      { pageParam = start }, // Use provided start value if available
    ) =>
      getTimeLineEvents({
        payload: {
          customerId,
          start: pageParam,
          limit: limit, // Use provided limit or default
        },
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        // Use the last page's data length to determine the next page param
        const currentPageParam = pages.length * limit; // Calculate the current page param based on the number of pages
        return lastPage?.data?.length === limit ? currentPageParam : undefined;
      },
    },
  );

  // Combine all `StageData` items from the fetched pages if available
  const stageData: StageData[] =
    query.data?.pages?.flatMap((page) => page) || [];

  // Map stageName to options array with key-value pairs
  const options: OptionType[] =
    query.data?.pages?.flatMap((page) =>
      page.map((data: StageData) => ({
        label: data.stageName,
        value: data.stageName,
      })),
    ) || [];

  return {
    data: query.data,
    stageData,
    options,
    fetchNextPage: query.fetchNextPage,
    hasNextPage: query.hasNextPage,
    isFetching: query.isFetching,
  };
};
