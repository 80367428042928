
import { TimeLineData } from "../TimelineForm/TimelineForm";
import styles from "./SuccessMsg.module.scss";
import successMsg from "src/assets/images/successMsg.gif";

interface Props {
  timeLineData: TimeLineData[],
  onHide: () => void,
  setTimeLineData: React.Dispatch<React.SetStateAction<TimeLineData[]>>
}

const SuccessMsg = ({ onHide, timeLineData, setTimeLineData }: Props) => {

  return (
    <div>
      <div className="py-5 px-5">
        <div className="d-flex justify-content-center mb-4">
          <img
            src={successMsg}
            alt="success"
            className={`${styles.warningImg}`}
          />
        </div>

        <h5 className={`mb-3 px-3 ${styles.heading}`}>
          Changes Saved Successfully!
        </h5>
        <p className={`px-5 mx-2 ${styles.desc}`}>
          Your pipeline events and timeline stages have been updated
          successfully.
        </p>
        <div
          className={`d-flex justify-content-center align-items-center pt-3 ${styles.btnWrapper}`}
        >
          <button className={`${styles.saveBtn}`} onClick={onHide}>Okay</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessMsg;
