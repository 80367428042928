import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FileDrop } from "react-file-drop";
import imgUpload from "src/assets/images/imgUpload.png";
import { deleteAttachment } from "src/services/Attachment/deleteAttachment";
import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import styles from "./UploadLogo.module.scss";
import { Warning } from "../../Hooks/useSendMessageContext";
// import logo from "src/assets/images/dummyLogo.png";

const SUPPORTED_EXTENSION_TYPES = ["jpg", "jpeg", "png"];
const ALLOWED_FILE_TYPES = ".png, .jpg, .jpeg";
const ALLOWED_FILES_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

const isFileSupported = (fileName: string) => {
  const type = fileName.split(".").pop() || "";
  return SUPPORTED_EXTENSION_TYPES.includes(type.toLowerCase().trim());
};

const UploadLogo = ({
  onChange,
  initialUrl,
  disabled = false,
  setShowFileWarning,
  showFileWarning,
}: {
  onChange: (val: string, url: string) => void;
  initialUrl: string;
  disabled?: boolean;
  setShowFileWarning: (showFileWarning?: Warning) => void;
  showFileWarning: Warning;
}) => {
  const [imageUrl, setImageUrl] = useState<string>(initialUrl);
  const [attachmentId, setAttachmentId] = useState<string>("");
  const [dragClass, setDragClass] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const imgInputRef = useRef<HTMLInputElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const onProgressChange = useCallback((progressEvent: ProgressEvent) => {
    const progress = Math.round(
      (100 * progressEvent.loaded) / progressEvent.total,
    );

    console.log(progress);
  }, []);

  const removeImageHandler = useCallback(
    async (attachmentId: string, clearInputs = true) => {
      if (!attachmentId || disabled || isRemoving) {
        return;
      }

      try {
        setIsRemoving(true);

        await deleteAttachment({ attachmentId: parseInt(attachmentId + "") });

        if (clearInputs) {
          setAttachmentId("");
          setImageUrl("");
          onChange("", "");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsRemoving(false);
      }
    },
    [disabled, isRemoving, onChange],
  );

  const filePicker = useCallback(() => {
    // Clear warnings before uploading new file
    setShowFileWarning({ show: false, type: null });
    if (imgInputRef.current) {
      imgInputRef.current.click();
    }
  }, []);

  const onFileInputChange = useCallback(
    async (files: FileList | null) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const abortSignal = abortControllerRef.current.signal;

      if (!disabled && !showLoading && files) {
        const uploadableFiles = Array.from(files).filter((file) =>
          isFileSupported(file.name),
        );

        if (uploadableFiles.length !== 0) {
          const file = uploadableFiles[0];
          // Check file size is less than allowed size
          if (file.size > ALLOWED_FILES_SIZE) {
            // If the limit is exceeded, show a warning
            setShowFileWarning({
              show: true,
              type: "sizelimit",
            });
            // Clear inputs
            setAttachmentId("");
            setImageUrl("");
            onChange("", "");
            return;
          }

          setImageUrl(URL.createObjectURL(file));

          try {
            setShowLoading(true);

            if (attachmentId && attachmentId.trim() !== "") {
              // Wait until any previous image is removed
              await removeImageHandler(attachmentId, false);
            }

            const uploadRes = await uploadAttachment(
              {
                files: [file],
                abortControllerSignal: abortSignal,
              },
              onProgressChange,
              true,
            );

            if (uploadRes.attachments[0].status === true) {
              const fileObject = {
                batchNumber: uploadRes.attachments[0].batchNumber,
                attachmentId: uploadRes.attachments[0].attachmentId,
                attachmentName: uploadRes.attachments[0].attachmentName,
                isPublic: true,
                attachmentURL: "",
              };

              if (uploadRes.attachments[0]?.publicURL) {
                fileObject.isPublic = true;
                fileObject.attachmentURL = uploadRes.attachments[0].publicURL;
              }

              setAttachmentId(fileObject.attachmentId + "");
              onChange(fileObject.batchNumber, fileObject.attachmentURL);
              setShowFileWarning({
                show: false,
                type: null,
              });
            }
          } catch (err) {
            const uploadErr = err as Error;
            if (uploadErr?.message !== "canceled") {
              console.error(uploadErr);
              // onFailed(fileObject.id);
            }
          } finally {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
              setShowLoading(false);
            }, 1500);
          }
        } else {
          setShowFileWarning({
            show: true,
            type: "extensionsupport",
          });
          // Clear inputs
          setAttachmentId("");
          setImageUrl("");
          onChange("", "");
        }
      }
    },
    [
      attachmentId,
      disabled,
      onChange,
      onProgressChange,
      removeImageHandler,
      showLoading,
    ],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <div className={`${styles.uploadWrapper}`}>
      <h5 className={`${styles.heading}`}>Upload your logo:</h5>
      <div
        className={`position-relative p-2 ${styles.uploadBox} ${styles.drag} ${
          showFileWarning.show ? styles.errorBorder : ""
        }`}
      >
        {showLoading && (
          <div
            className={`${styles.logoLoader} d-flex justify-content-center align-items-center vertical-align-middle`}
          >
            <Spinner
              size="sm"
              variant="primary"
            />
          </div>
        )}
        <div className="text-center">
          <input
            accept={ALLOWED_FILE_TYPES}
            style={{ display: "none" }}
            ref={imgInputRef}
            type="file"
            onChange={(e) => onFileInputChange(e.target.files)}
            disabled={disabled}
          />
          <FileDrop
            onTargetClick={filePicker}
            onDrop={(files, e) => {
              e.preventDefault();
              if (!disabled) {
                onFileInputChange(files);
              }

              return false;
            }}
            onFrameDragEnter={() => !disabled && setDragClass(styles.dragging)}
            onFrameDragLeave={() => !disabled && setDragClass("")}
            onDragOver={() => !disabled && setDragClass(styles.dragged)}
            onDragLeave={() => !disabled && setDragClass(styles.dragging)}
            onFrameDrop={() => !disabled && setDragClass("")}
            className={`${dragClass}`}
          >
            {imageUrl && imageUrl.trim() !== "" ? (
              <div className="">
                <span
                  className={`d-block ${styles.removeText} cursor-pointer`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeImageHandler(attachmentId);
                  }}
                >
                  {isRemoving ? (
                    <Spinner
                      size="sm"
                      className="me-2"
                    />
                  ) : null}{" "}
                  Remove logo
                </span>
                <div className="text-center">
                  <img
                    src={imageUrl}
                    alt="logo"
                    width={61}
                    height={59}
                    className="mx-auto"
                  />
                </div>
              </div>
            ) : (
              <>
                <img
                  src={imgUpload}
                  alt="logo"
                  width={71}
                  className="mx-auto"
                />
                <p className={`mb-1 mt-1 ${styles.dropText}`}>
                  Drop your logo here, or browse
                </p>
                <span className={`d-block ${styles.sizeLimit}`}>
                  Maximum image size 5Mb 200 x 230 pixels
                </span>
                <h6 className={`${styles.supports}`}>Supports: JPG,JPEG,PNG</h6>

                {showFileWarning && showFileWarning.type === "sizelimit" ? (
                  <div className="mt-3">
                    <p className={`mb-0 ${styles.errorHead}`}>
                      File size is too big !
                    </p>
                    <span className={`${styles.errorDesc}`}>
                      File size cannot be more than 5MB
                    </span>
                  </div>
                ) : null}
                {showFileWarning && showFileWarning.type === "empty" ? (
                  <div className="mt-3">
                    <p className={`mb-0 ${styles.errorHead}`}>
                      File is not Uploaded !
                    </p>
                    <span className={`${styles.errorDesc}`}>
                      Please upload logo
                    </span>
                  </div>
                ) : null}
                {showFileWarning &&
                showFileWarning.type === "extensionsupport" ? (
                  <div className="mt-3">
                    <p className={`mb-0 ${styles.errorHead}`}>
                      File format not supported !
                    </p>
                    <span className={`${styles.errorDesc}`}>
                      Supports: JPG,JPEG,PNG
                    </span>
                  </div>
                ) : null}
              </>
            )}
          </FileDrop>
        </div>
      </div>
      {imageUrl && imageUrl.trim() !== "" ? (
        <button
          className={`mt-2 ${styles.changeBtn}`}
          onClick={filePicker}
          disabled={disabled || showLoading || isRemoving}
        >
          Change logo
        </button>
      ) : null}
    </div>
  );
};

export default UploadLogo;
