import { useCallback, useEffect, useMemo } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { SegmentDataForUpdateI } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { ConditionI } from "src/services/CustomerSegments/createSegment.service";
import { FieldValueInstance } from "src/services/CustomerSegments/getConditionOptions.service";
import useFetchConditionDD from "../../../hooks/useFetchConditionDD";
import styles from "../../../UpdateSegmentDetails.module.scss";

interface Props {
  fieldValues: Array<FieldValueInstance>;
  fieldKey: string;
  value: Array<string> | string | null;
  handlePush: (index: number) => void;
  handlePop: (index: number) => void;
  onChange: (
    value: Array<string> | string | null,
    l: Record<string, string[]>,
  ) => void;
  valuesOfSelectKeys?: ConditionI["valuesOfSelectKeys"];
  placeholder?: string;
  error?: string;
  suffix?: string;
  index?: number;
  isMulti?: boolean;
  segmentDataForUpdate: SegmentDataForUpdateI | null;
}

const SelectInput = ({
  fieldValues,
  fieldKey,
  value,
  onChange,
  valuesOfSelectKeys = {},
  placeholder = "None",
  error,
  suffix,
  index = 0,
  isMulti = false,
  handlePush,
  handlePop,
  segmentDataForUpdate,
}: Props) => {
  const valueBeforeIndex = useMemo(() => {
    const ret = (
      isMulti
        ? ((value as Array<string> | null) ?? [])[0] ?? ""
        : (value as string | null) ?? ""
    )
      .split("::")
      .slice(0, index)
      .join("::");
    if (ret) {
      return ret;
    } else {
      return null;
    }
  }, [value, isMulti, index]);

  const {
    conditionOpitionDD,
    conditionOpitionDDStatus,
    hasMore,
    fetchConditionDD,
  } = useFetchConditionDD({
    segmentType: segmentDataForUpdate?.segmentType ?? "",
    fieldKey,
    key: valueBeforeIndex ? valueBeforeIndex : undefined,
    fieldValuesFetched: fieldValues,
  });

  const conditionOpitionDDObj = useMemo(() => {
    const fv: Record<string, FieldValueInstance> = {};
    for (let i = 0; i < conditionOpitionDD.length; i++) {
      const v = conditionOpitionDD[i];
      fv[v.key] = v;
    }
    return fv;
  }, [conditionOpitionDD]);

  const selectedField: FieldValueInstance | Array<FieldValueInstance> | null =
    useMemo(() => {
      if (isMulti) {
        const ret: Array<FieldValueInstance> = (
          (value as Array<string> | null) ?? []
        ).map((v) => {
          const valuesData = v.split("::");
          const valueData = valuesData[index] ? valuesData[index] : null;
          const labels = valuesOfSelectKeys[v] ?? [];
          const label =
            conditionOpitionDDObj[valueData + ""]?.name ??
            labels[index] ??
            null;

          return {
            key: valueData ?? "",
            name: label ?? "",
          };
        });

        return ret;
      } else {
        let valueData: string | null = ((value as string | null)?.split("::") ??
          [])[index];
        if (!valueData) {
          valueData = null;
        }
        const labels = (value as string | null)
          ? valuesOfSelectKeys[value as string] ?? []
          : [];
        const label =
          conditionOpitionDDObj[valueData + ""]?.name ?? labels[index] ?? null;

        const ret =
          valueData && label
            ? {
                key: valueData,
                name: label,
                fieldValues:
                  conditionOpitionDDObj[valueData + ""]?.fieldValues ??
                  (labels.length > index + 1 ? [] : undefined),
              }
            : null;

        return ret;
      }
    }, [value, valuesOfSelectKeys, isMulti, conditionOpitionDDObj]);

  const handleOnMenuOpen = useCallback(() => {
    if (conditionOpitionDD.length === 0) {
      fetchConditionDD();
    }
  }, [conditionOpitionDD, fetchConditionDD]);

  const isError = useMemo(() => {
    const ret =
      (error ? true : false) &&
      ((
        isMulti
          ? (selectedField as FieldValueInstance[] | null)?.length
          : (selectedField as FieldValueInstance | null)
      )
        ? false
        : true);

    return ret;
  }, [error, selectedField, isMulti]);

  useEffect(() => {
    handlePush(index);
    return () => {
      handlePop(index);
    };
  }, [handlePush, handlePop]);

  // Filter out the selected options
  const filteredOptions = useMemo(() => {
    const selectedKeys = new Set(
      isMulti
        ? (selectedField as Array<FieldValueInstance>).map((field) => field.key)
        : selectedField
          ? [(selectedField as FieldValueInstance).key]
          : [],
    );
    return conditionOpitionDD.filter(
      (option) => !selectedKeys.has(option.key + ""),
    );
  }, [conditionOpitionDD, selectedField, isMulti]);

  return (
    <>
      <div className="mx-1 d-flex flex-column">
        <Select
          options={filteredOptions.map((option) => ({
            value: option.key,
            label: option.name,
          }))}
          onMenuOpen={handleOnMenuOpen}
          onMenuScrollToBottom={hasMore ? fetchConditionDD : undefined}
          isLoading={conditionOpitionDDStatus === "pending"}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: null,
          }}
          styles={customStyles}
          className={`${isError ? "border-0" : ""} ${
            isError ? styles.errBorder : ""
          }`}
          value={
            isMulti
              ? (selectedField as Array<FieldValueInstance> | null)?.length
                ? (selectedField as Array<FieldValueInstance> | null)?.map(
                    (v) => ({
                      value: v.key,
                      label: v.name,
                    }),
                  )
                : null
              : (selectedField as FieldValueInstance | null)
                ? {
                    value: (selectedField as FieldValueInstance).key,
                    label: (selectedField as FieldValueInstance).name,
                  }
                : null
          }
          isMulti={isMulti}
          isSearchable={true}
          onChange={(option) => {
            if (isMulti) {
              let keys = (
                ((value as Array<string> | null) ?? [])[0]?.split("::") ?? []
              )
                .slice(0, index)
                .join("::");

              const retLabels: typeof valuesOfSelectKeys = {};
              const ret =
                (
                  option as MultiValue<{
                    value: string;
                    label: string;
                  }>
                )?.map((v) => {
                  const key = `${keys ? `${keys}::` : ""}${v.value}`;
                  retLabels[key] = [...(valuesOfSelectKeys[key] ?? [])];
                  retLabels[key][index] = v.label;
                  return key;
                }) ?? [];

              onChange(ret, retLabels);
            } else {
              let keys = (value as string | null)?.split("::") ?? [];
              if (
                (
                  option as SingleValue<{
                    value: string;
                    label: string;
                  }>
                )?.value
              ) {
                keys[index] = (
                  option as {
                    value: string;
                    label: string;
                  }
                ).value;
              } else {
                keys = keys.slice(0, index);
              }

              const label =
                (
                  option as SingleValue<{
                    value: string;
                    label: string;
                  }>
                )?.label ?? "";

              const ret = keys.join("::");

              const retLabels: typeof valuesOfSelectKeys = {};

              if (ret && label) {
                retLabels[ret] = [...(valuesOfSelectKeys[ret] ?? [])];
                retLabels[ret][index] = label;
              }

              onChange(ret, retLabels);
            }
          }}
        />
        {isError ? <div className={`${styles.errText} ms-2`}>{error}</div> : ""}
      </div>
      {!isMulti ? (
        (selectedField as FieldValueInstance | null)?.fieldValues ? (
          <SelectInput
            fieldValues={
              (selectedField as FieldValueInstance).fieldValues ?? []
            }
            value={value}
            onChange={onChange}
            valuesOfSelectKeys={valuesOfSelectKeys}
            placeholder={placeholder}
            error={error}
            index={index + 1}
            fieldKey={fieldKey}
            handlePush={handlePush}
            handlePop={handlePop}
            segmentDataForUpdate={segmentDataForUpdate}
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {suffix && index === 0 ? (
        <div className={`${styles.daysAgoText} ms-2 d-flex align-items-center`}>
          {suffix}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "37px",
    width: "100%",
    border: "1px solid #BBBBBB",
    borderRadius: "4px",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minWidth: "180px",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
    color: "#000000",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#F5F5F5", // Light blue background
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "0px 3px",
    marginRight: "2px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#707070",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "unset",
      color: "#707070",
    },
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
    display: "none",
  }),
  indicatorSeparator: (provided: any) => ({
    display: "none", // Hides the separator
  }),
  indicatorsContainer: (provided: any) => ({
    display: "none", // Hides the separator
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#FFF5F6" : "",
    color: state.isFocused ? "#000000" : "",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#FFF5F6",
      color: "#000000",
    },
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0.4px",
  }),
};

export default SelectInput;
