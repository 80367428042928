import { useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import caution from "src/assets/images/caution.png";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import { AJAXSTATUS } from "src/globals/constants";
import { ConditionOption } from "src/services/CustomerSegments/getConditionOptions.service";
import styles from "../../UpdateSegmentDetails.module.scss";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";

interface Props {
  conditionOptionType: "custom" | "default";
  groupIndex: number;
  conditionOptions: Array<ConditionOption>;
  fetchMoreConditionOptions?: () => void;
  hasNextPage?: boolean;
  conditionOptionsStatus?: AJAXSTATUS;
}

const ConditionOptions = ({
  conditionOptionType,
  groupIndex,
  conditionOptions,
  fetchMoreConditionOptions,
  hasNextPage = false,
  conditionOptionsStatus,
}: Props) => {
  const { updateSegmentDetails, dispatch } = useUpdateSegmentDetails();
  const group = useMemo(() => {
    if (
      updateSegmentDetails.segmentDetails.groupConditions &&
      updateSegmentDetails.segmentDetails.groupConditions[groupIndex]
    ) {
      return updateSegmentDetails.segmentDetails.groupConditions[groupIndex];
    } else {
      return null;
    }
  }, [updateSegmentDetails, groupIndex]);
  const [tooltipVisibility, setTooltipVisibility] = useState<
    Record<string, boolean>
  >({});

  const textRefs = useRef<Record<string, HTMLSpanElement | null>>({});

  useEffect(() => {
    const newTooltipVisibility: Record<string, boolean> = {};

    conditionOptions.forEach((option) => {
      const ref = textRefs.current[option.fieldKey];
      if (ref && option.fieldName.length > 14) {
        newTooltipVisibility[option.fieldKey] = true;
      } else {
        newTooltipVisibility[option.fieldKey] = false;
      }
    });

    setTooltipVisibility(newTooltipVisibility);
  }, [conditionOptions]);

  return (
    <div className="mb-3">
      <p className={`${styles.defaultText}`}>
        {conditionOptionType === "custom" ? "Custom" : "Default"} conditions
      </p>
      <InfiniteScroll
        hasMoreBottom={hasNextPage}
        loadMoreFromBottom={fetchMoreConditionOptions}
        className={`${styles.companyBox}`}
        infiniteLoader={
          <LoadMoreLoader
            fetchMore={
              fetchMoreConditionOptions ? fetchMoreConditionOptions : () => {}
            }
            variant="blue"
            status={
              conditionOptionsStatus === "fulfilled"
                ? "fulfilled"
                : conditionOptionsStatus === "pending"
                  ? "pending"
                  : null
            }
          />
        }
      >
        <div className="d-flex align-items-center mt-2 mb-2 w-100">
          <div className="w-100">
            {conditionOptions.map((option) => {
              return (
                <div
                  className="d-flex justify-content-between align-items-center mb-2"
                  key={option.fieldKey}
                >
                  <div className="d-flex align-items-center">
                    <OverlayTrigger
                      placement="top-start"
                      trigger={["hover", "focus"]}
                      overlay={
                        tooltipVisibility[option.fieldKey] ? (
                          <Tooltip className={`${styles.toolTipCustom}`}>
                            {option.fieldName}
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          {...triggerHandler}
                          ref={ref}
                        >
                          <span
                            ref={(el) =>
                              (textRefs.current[option.fieldKey] = el)
                            }
                            className={`pe-1 ${styles.companyHead}`}
                          >
                            {option.fieldName}
                          </span>
                        </div>
                      )}
                    </OverlayTrigger>
                    {option.fieldDescription && (
                      <OverlayTrigger
                        placement="bottom"
                        trigger={["hover", "focus"]}
                        overlay={
                          <Tooltip className={` ${styles.toolTipCustom}`}>
                            {option.fieldDescription}
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <div
                            {...triggerHandler}
                            ref={ref}
                          >
                            <img
                              src={caution}
                              alt=""
                              className={`cursor-pointer ${styles.cautionImg}`}
                            />
                          </div>
                        )}
                      </OverlayTrigger>
                    )}
                  </div>
                  <div>
                    {!group?.conditions.some(
                      (cond) => cond.fieldKey === option.fieldKey,
                    ) ? (
                      <button
                        className={`ms-2 ${styles.addBtn}`}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch("addSegmentGroupCondition", {
                            groupIndex,
                            conditionOption: option,
                            conditionLength: group?.conditions?.length ?? 0,
                          });
                        }}
                        id={`${option.fieldName.replace(" ", "_")}Btn`}
                      >
                        Add condition
                      </button>
                    ) : (
                      <button
                        className={`ms-2 ${styles.removeBtn}`}
                        onClick={() => {
                          dispatch("deleteSegmentGroupCondition", {
                            groupIndex,
                            fieldKey: option.fieldKey,
                          });
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ConditionOptions;
