import styles from "./SequenceSummary.module.scss";
import SequenceSteps from "./SequenceSteps/SequenceSteps";
import { useAddCampaignState } from "../../../../Hooks/useAddCampaignContext";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";

const SequenceSummary = () => {
  const { state, dispatch } = useAddCampaignState();
  const addSequence = useCallback(() => {
    const sequence = [...state.sequence];
    sequence.push({
      id: uuid(),
      variantId: state.variantId,
      templateId: state.templateId,
      sections: [],
      channel: null,
      triggerAfter: 0,
    });

    dispatch("updateState", { sequence: sequence });
  }, [state, dispatch]);

  return (
    <div>
      {state.sequence ? (
        <div className="px-3">
          <h4 className={`${styles.sequenceHead}`}>Sequence steps</h4>
          {state.sequence.map((sequence, index) => {
            return (
              <SequenceSteps
                sequence={sequence}
                index={index}
                key={index}
              />
            );
          })}
          {!state.seqLimit ||
          (state.sequence ?? []).length <= state.seqLimit ? (
            <div className="d-flex align-items-center mt-3">
              <div
                className="d-flex align-items-center justify-content-center cursor-pointer"
                onClick={addSequence}
              >
                <div className={`${styles.addSequence}`}>
                  <span>
                    <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
                <span className={`ps-2 ${styles.addText}`}>
                  Add sequence step
                </span>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default SequenceSummary;
