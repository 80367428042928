import React, { useEffect } from "react";
import TimelineInfo from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineInfo";
import {
  TimelineActionTypes,
  useTimeline,
} from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

const ReportTimeline = ({
  showTimeline,
  timelineRef,
  showLoader,
  timelineInfo,
  onLoad,
}: {
  timelineInfo: TimelineData | undefined;
  showTimeline: boolean;
  showLoader: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
  onLoad?: () => void;
}) => {
  const { dispatch } = useTimeline();

  useEffect(() => {
    if (timelineInfo) {
      if (onLoad) {
        onLoad();
      }
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: timelineInfo,
      });
    }
  }, [timelineInfo, dispatch, onLoad]);

  useEffect(() => {
    dispatch({
      type: TimelineActionTypes.SET_LOADING,
      payload: showLoader,
    });
  }, [showLoader, dispatch]);

  return (
    <TimelineInfo
      timelineRef={timelineRef}
      showTimeline={showTimeline}
    />
  );
};

export default ReportTimeline;
