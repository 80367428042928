import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { deleteDispatchRuleConfigService } from "src/services/Automation/DispatchRulesConfigModal/deleteDispatchRuleConfig.service";
import styles from "./DeleteDispatch.module.scss";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";

const DeleteDispatch = ({
  hideModal,
  configurationId,
  integrationId,
  onDeleted,
  dispatchRuleName,
  automationType,
}: {
  hideModal: () => void;
  configurationId: string;
  integrationId: string;
  onDeleted: () => void;
  dispatchRuleName: string;
  automationType: EAutomationType;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onDelete = useCallback(async () => {
    try {
      setIsLoading(true);
      await deleteDispatchRuleConfigService({
        payload: {
          configurationId,
          integrationId,
        },
        type: automationType,
      });
      onDeleted();
    } catch (err) {
      pushTheToast({
        text: typeof err === "string" ? err : "Cannot delete config",
        position: "top-right",
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  }, [automationType, configurationId, integrationId, onDeleted]);

  return (
    <div
      className={`${styles.innerContainer} p-2 d-flex justify-content-center align-items-center flex-column`}
    >
      <div className={`${styles.title} mb-3`}>
        Are you sure to delete dispatching rules "{dispatchRuleName}"?
      </div>
      <div className="d-flex justify-content-center align-items-centerx">
        <button
          className={`${styles.deleteBtn} px-3`}
          onClick={hideModal}
          disabled={isLoading}
        >
          No
        </button>
        <button
          className={`${styles.deleteBtn} px-3 ms-2`}
          onClick={onDelete}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              size="sm"
              className="mx-3"
            />
          ) : (
            `Yes, I'm sure`
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteDispatch;
