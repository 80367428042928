import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  BrandData,
  GetAllBotProfileBrandPayload,
  GetAllBrandData,
} from "../Bot/BotProfiles/getAllBotProfileBrands";

export const getCampaignBrands = async (
  params: GetAllBotProfileBrandPayload,
) => {
  const { data: res } = await axiosJSON.post("/api/campaign/getBrands", params);
  if (res.err) {
    throw res.msg;
  }

  if (res.err === true) {
    throw new Error(res.msg as string);
  }

  const ret: GetAllBrandData = {
    brands: {},
    brandIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.count;
  ret.metaData.total = res.metaData.totalCount;
  res.data.forEach((brand: BrandData) => {
    ret.brands[brand.id] = brand;
    ret.brandIds.push(brand.id);
  });

  return ret;
};
