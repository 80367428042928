import UserAvatar from "src/components/UserAvatar";
import styles from "./MessageTable.module.scss";
import { Modal } from "react-bootstrap";
import { useMemo, useState } from "react";
import PreviewModal from "./PreviewModal/PreviewModal";
import {
  AllMessagesTableData,
  IChannel,
  ICustomer,
  IMessage,
} from "src/services/Campaign/getAllSentMessages.service";
import { useAppSelector } from "src/store/store";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
import AxiosImg from "src/components/AxiosImg";

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.862"
    height="9.501"
    viewBox="0 0 11.862 9.501"
  >
    <path d="M10.668.583a1.153,1.153,0,0,1,.847.347,1.153,1.153,0,0,1,.347.847V8.89a1.186,1.186,0,0,1-1.195,1.195H1.195a1.153,1.153,0,0,1-.847-.347A1.153,1.153,0,0,1,0,8.89V1.778A1.153,1.153,0,0,1,.347.931,1.153,1.153,0,0,1,1.195.583Zm0,2.389V1.778L5.917,4.75,1.195,1.778V2.972L5.917,5.917Z" />
  </svg>
);

const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.03"
    height="11.03"
    viewBox="0 0 11.03 11.03"
  >
    <path d="M11,.473a.345.345,0,0,0-.448-.448L.528,4.036h0L.216,4.16a.345.345,0,0,0-.057.611l.283.179h0L3.886,7.143l2.191,3.443h0l.179.283a.345.345,0,0,0,.611-.057L11,.473Zm-1.264,1.3.325-.812-.812.325L4.087,6.455,4.32,6.6a.345.345,0,0,1,.106.106l.148.233L9.741,1.776Z" />
  </svg>
);

const ChannelIcon = ({ value }: { value: IChannel }) => (
  <div className="d-flex align-items-center">
    {value === "email" ? (
      <div>
        <EmailIcon />
        <span className={`ps-1 ${styles.element}`}>Email</span>
      </div>
    ) : (
      <div>
        <ChatIcon />
        <span className={`ps-1 ${styles.element}`}>Chat</span>
      </div>
    )}
  </div>
);

interface TableDataRowProps {
  messageData: IMessage;
}

const TableDataRow = ({ messageData }: TableDataRowProps) => {
  const [preview, setPreview] = useState(false);
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const formatDate = useMemo(
    () => (date: string) => {
      if (!userTimezone) return date ?? "";
      const formattedDate = formatMDYTimeStringDate(
        date,
        userTimezone,
      )?.replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
      return formattedDate ?? "";
    },
    [userTimezone],
  );

  return (
    <div className={styles.tableData}>
      {messageData.rowData.map((rowData, index) => {
        const { type, value } = rowData;
        return (
          <div
            className={styles.col}
            key={index}
          >
            <span className={styles.rowElement}>
              {/* Date */}
              {type == "date" ? (
                <span className={styles.textMediumBold}>
                  <i className="fa-solid fa-clock pe-1"></i>
                  {formatDate(value as string)}
                </span>
              ) : null}

              {/* Channel */}
              {type == "channel" ? (
                <ChannelIcon value={value as IChannel} />
              ) : null}

              {/* Customer */}
              {type == "customer" ? (
                <div>
                  {(value as ICustomer)?.imageUrl &&
                  ((value as ICustomer)?.imageUrl ?? "").trim()?.length > 0 ? (
                    <AxiosImg
                      className={styles.profileIcon}
                      url={(value as ICustomer).imageUrl ?? ""}
                    />
                  ) : (
                    <UserAvatar
                      name={(value as ICustomer)?.name ?? ""}
                      size={19}
                      className="m-auto"
                    />
                  )}
                  <span className={`${styles.textMediumBold} ms-1`}>
                    {(value as ICustomer)?.name?.toString() ?? ""}
                  </span>
                </div>
              ) : null}

              {/* Text */}
              {type == "string" || type === "number" ? (
                <span>{value?.toString() ?? ""}</span>
              ) : null}

              {/* Preview button */}
              {type == "preview" ? (
                <button
                  className={`px-2 ${styles.previewBtn}`}
                  onClick={() => setPreview(true)}
                >
                  Preview
                </button>
              ) : null}
            </span>
          </div>
        );
      })}

      {preview && (
        <Modal
          show={preview}
          onHide={() => setPreview(false)}
          backdropClassName={styles.modalBackDrop}
          dialogClassName={styles.modalDialog}
          contentClassName={styles.modalContent}
          centered
        >
          <PreviewModal
            onHide={() => setPreview(false)}
            messageId={messageData.messageId}
          />
        </Modal>
      )}
    </div>
  );
};

const MessageTable = ({ data }: { data: AllMessagesTableData }) => {
  return (
    <div>
      <div className={`mb-2 ${styles.tableHeader}`}>
        {data.tableHeadingIds.map((headingId) => {
          const heading = data.tableHeadings[headingId];
          return (
            <div
              className={`${styles.col}`}
              key={heading.tableHeadingId}
            >
              <span className={`${styles.elememt}`}>
                {heading.tableHeadingName ?? ""}
              </span>
            </div>
          );
        })}
      </div>
      {data.allMessageIds.map((messageId, index) => {
        const messageData = data.allMessages[messageId];
        return (
          <TableDataRow
            key={index}
            messageData={messageData}
          />
        );
      })}
    </div>
  );
};

export default MessageTable;
