import {
  FeatureSectionSequence,
  BlogSectionSequence,
  ESequenceSectionType,
} from "src/services/Campaign/createCampaignV2.service";
import styles from "../SequenceSection.module.scss";
import BaseInput from "./BaseInput";
import { v4 as uuid } from "uuid";
import { validateURLNew } from "src/utils/utils";

const MultiTextSection = ({
  heading,
  placeholder,
  sideHeading,
  sidesubHeading,
  section,
  addBtnText,
  sectionIndex,
  sectionType,
  showErrors,
  handleSectionsChange,
  errorMsg,
  seqLimit,
  handleDeleteSection,
}: {
  heading: string;
  sideHeading: string;
  sidesubHeading: string;
  placeholder: string;
  addBtnText: string;
  sectionIndex: number;
  seqLimit?: number;
  showErrors: boolean;
  section: FeatureSectionSequence | BlogSectionSequence;
  handleSectionsChange: (
    sectionIdx: number,
    section: FeatureSectionSequence | BlogSectionSequence,
  ) => void;
  errorMsg: string;
  handleDeleteSection: () => void;
  sectionType: ESequenceSectionType;
}) => {
  // Helper function to update sections
  const updateSections = (subSectionIndex: number, value: string) => {
    const newSections = [...(section.sections ?? [])];
    newSections[subSectionIndex] = {
      ...newSections[subSectionIndex],
      value,
    };

    // Initialize updatedSection with common properties
    let updatedSection: FeatureSectionSequence | BlogSectionSequence;

    // Type guard for `FeatureSectionSequence`
    if (sectionType === ESequenceSectionType.Feature) {
      const newFeaturesList = [
        ...((section as FeatureSectionSequence).features ?? []),
      ];
      newFeaturesList[subSectionIndex] = value;

      updatedSection = {
        ...section,
        sections: newSections,
        features: newFeaturesList,
      } as FeatureSectionSequence;
    }
    // Type guard for `BlogSectionSequence`
    else if (sectionType === ESequenceSectionType.Blog) {
      const newBlogsList = [...((section as BlogSectionSequence).blogs ?? [])];
      newBlogsList[subSectionIndex] = value;

      updatedSection = {
        ...section,
        sections: newSections,
        blogs: newBlogsList,
      } as BlogSectionSequence;
    } else {
      // Fallback in case of unexpected `sectionType`
      updatedSection = {
        ...section,
        sections: newSections,
      };
    }
    handleSectionsChange(sectionIndex, updatedSection);
  };

  // Add a new subsection
  const handleAddSubSection = () => {
    // Create new subsections array with a new entry
    const newSections = [
      ...(section.sections ?? []),
      { id: uuid(), value: "", isDefault: false },
    ];

    // Initialize updatedSection with common properties
    let updatedSection: FeatureSectionSequence | BlogSectionSequence;

    // Type guard for `FeatureSectionSequence`
    if (sectionType === ESequenceSectionType.Feature) {
      const newFeaturesList = [
        ...((section as FeatureSectionSequence).features ?? []),
        "",
      ];

      updatedSection = {
        ...section,
        sections: newSections,
        features: newFeaturesList,
      } as FeatureSectionSequence;
    }
    // Type guard for `BlogSectionSequence`
    else if (sectionType === ESequenceSectionType.Blog) {
      const newBlogsList = [
        ...((section as BlogSectionSequence).blogs ?? []),
        "",
      ];

      updatedSection = {
        ...section,
        sections: newSections,
        blogs: newBlogsList,
      } as BlogSectionSequence;
    } else {
      // Fallback in case of unexpected `sectionType`
      updatedSection = {
        ...section,
        sections: newSections,
      };
    }
    handleSectionsChange(sectionIndex, updatedSection);
  };

  // Delete subsection
  const deleteSubSection = (subSectionIdx: number) => {
    const newSections = (section.sections ?? []).filter(
      (_, i) => i !== subSectionIdx,
    );
    // Initialize updatedSection with common properties
    let updatedSection: FeatureSectionSequence | BlogSectionSequence;

    // Type guard for `FeatureSectionSequence`
    if (sectionType === ESequenceSectionType.Feature) {
      const newFeaturesList = [
        ...((section as FeatureSectionSequence).features ?? [])?.filter(
          (_, i) => i !== subSectionIdx,
        ),
      ];
      updatedSection = {
        ...section,
        sections: newSections,
        features: newFeaturesList,
      } as FeatureSectionSequence;
    }
    // Type guard for `BlogSectionSequence`
    else if (sectionType === ESequenceSectionType.Blog) {
      const newBlogsList = [
        ...((section as BlogSectionSequence).blogs ?? [])?.filter(
          (_, i) => i !== subSectionIdx,
        ),
      ];

      updatedSection = {
        ...section,
        sections: newSections,
        blogs: newBlogsList,
      } as BlogSectionSequence;
    } else {
      // Fallback in case of unexpected `sectionType`
      updatedSection = {
        ...section,
        sections: newSections,
      };
    }
    handleSectionsChange(sectionIndex, updatedSection);
  };

  return (
    <div className={`w-100 d-flex ${styles.introBox} `}>
      <div className="d-flex align-items-center me-2">
        <p className="mb-0 d-flex">
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </p>
      </div>
      <div className="w-100">
        <div className={`mt-2 w-100`}>
          <div className="d-flex justify-content-between">
            <h5 className={`${styles.introHead}`}>{heading}</h5>
            <span
              className={`${styles.deleteText} cursor-pointer`}
              onClick={handleDeleteSection}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>
          <div className={`${styles.container}`}>
            {section.sections?.map((subSection, index) => {
              const isNotValid =
                sectionType === ESequenceSectionType.Feature
                  ? subSection.value?.trim().length == 0
                  : sectionType === ESequenceSectionType.Blog
                    ? validateURLNew(subSection.value) != null
                    : false;
              return (
                <div className="w-100">
                  <div
                    key={subSection.id}
                    className="w-100 d-flex"
                  >
                    <h5
                      className={`mb-3 ${styles.featureText} d-flex align-items-center`}
                    >
                      {sideHeading} {index + 1}:
                    </h5>
                    <p
                      className={`${styles.blogLabel} mb-3 d-flex align-items-center`}
                    >
                      {sidesubHeading}
                    </p>
                    <div
                      className={`d-flex align-items-center me-3 ${styles.inputWrapper} w-100`}
                    >
                      <BaseInput
                        onChange={(value: string) => {
                          updateSections(index, value);
                        }}
                        value={subSection.value}
                        error={isNotValid && showErrors ? errorMsg : ""}
                        placeholder={placeholder}
                        disabled={subSection.isDefault}
                      />
                      <div
                        className={`${styles.delete} ms-2 mb-4 d-flex align-items-center cursor-pointer`}
                        onClick={() => {
                          deleteSubSection(index);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          {!seqLimit || (section.sections ?? []).length <= seqLimit ? (
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center justify-content-center cursor-pointer"
                onClick={handleAddSubSection}
              >
                <div className={`${styles.addBox}`}>
                  <span>
                    <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
                <span className={`ps-2 ${styles.addText}`}>{addBtnText}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MultiTextSection;
