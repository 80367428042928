import { useCallback, useEffect, useRef, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentSettingsService, {
  GetSegmentSettingsParams,
  SegmentAttribute,
} from "src/services/CustomerSegments/Settings/getSegmentSettings.service";

const LIMIT = 10;

const useSegmentEvent = (type: GetSegmentSettingsParams["type"]) => {
  const [attributes, setAttributes] = useState<SegmentAttribute[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<AJAXSTATUS>("idle");
  const [hasMore, setHasMore] = useState(true);
  const initialized = useRef(false);

  const fetchAttributes = useCallback(async () => {
    if (loadingStatus === "pending" || !hasMore) {
      return;
    }

    try {
      setLoadingStatus("pending");

      const res = await getSegmentSettingsService({
        type,
        limit: LIMIT,
        start: attributes.length,
      });

      setHasMore(res.length >= LIMIT);

      setAttributes((prev) => {
        // Combine old and new attributes, filtering out duplicates by 'id'
        const combined = [...prev, ...res];
        const uniqueAttributes = combined.reduce<SegmentAttribute[]>(
          (acc, item) => {
            if (!acc.some((attr) => attr.id === item.id)) {
              acc.push(item);
            }
            return acc;
          },
          [],
        );
        return uniqueAttributes;
      });

      setLoadingStatus("fulfilled");
    } catch (err) {
      setLoadingStatus("rejected");
    }
  }, [attributes.length, hasMore, loadingStatus, type]);

  useEffect(() => {
    if (
      loadingStatus === "idle" &&
      attributes.length === 0 &&
      initialized.current === false
    ) {
      fetchAttributes();
      initialized.current = true;
    }
  }, [attributes.length, fetchAttributes, loadingStatus]);

  return {
    attributes,
    hasMore,
    loadingStatus,
    fetchAttributes,
  };
};

export default useSegmentEvent;
