import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export type CompareTypes =
  | "is"
  | "isNot"
  | "contains"
  | "notContain"
  | "any"
  | "all"
  | "isBetween"
  | "none";
export interface BetweenRange<T = string | number | null> {
  // In case of string it may be date-time. Can not be null while in payload.
  start: T;
  end: T;
}
export interface HoursDays {
  value: string;
  time: string;
}
export interface Currency {
  value: string;
  currency: string;
}
export interface CurrencyBetweenRange {
  value: BetweenRange;
  currency: string;
}

export type LogicalOperatorTypes = "or" | "and";

export type FieldDataDateTypes =
  | "date"
  | "dateTime"
  | "dateOrDateTime"
  | "dateRange"
  | "dateTimeRange"
  | "dateOrDateTimeRange";

export type FieldDataTypes =
  | "string"
  | "number"
  | "numberRange"
  | "noValue"
  | "select"
  | "hoursOrDaysNumeric"
  | "currencyNumeric"
  | "currencyRangeNumeric"
  | FieldDataDateTypes;

export interface CompareDetail {
  compareType: CompareTypes;
  compareName: string;
  compareKey?: string;
  fieldDataType: FieldDataTypes;
  suffix?: string;
}

export interface CompareData {
  groupName: string;
  compareDetails: Array<CompareDetail | CompareData>;
}

export interface ConditionType {
  conditionTypeKey: string;
  conditionTypeName: string;
  isMulti?: boolean;
  compareDetails: Array<CompareDetail | CompareData>;
}
export interface FieldValueInstance {
  key: string;
  name: string;
  fieldValues?: Array<FieldValueInstance>;
  isMulti?: boolean;
}

export interface ConditionOption {
  fieldKey: string;
  fieldName: string;
  // fieldDescription: string;
  // compareDetails: Array<CompareDetail | CompareData>;
  // fieldValues?: Array<FieldValueInstance>;
  // isMulti?: boolean;
  conditionTypes: ConditionType[];
}

export interface ConditionOptionsResponse {
  conditionOptions: ConditionOption[];
}

export async function getConditionOptionsService({
  type,
  integrationId,
}: {
  type: string;
  integrationId: string;
}) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/getConditionOptions`
    : `/api/automation/${type}/getConditionOptions`;

  const { data: res } = await axiosJSON.post(apiEndPoint, { integrationId });

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as ConditionOptionsResponse;
}
