import { useMemo, useState } from "react";
import styles from "./TriggerCheckbox.module.scss";
import editIcon from "src/assets/images/editIcon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";

const TriggerCheckbox = () => {
  const [edit, setEdit] = useState(false);

  const { state, dispatch } = useAddCampaignState();

  const selectedTrigger = useMemo(() => {
    return state.allTriggers.find((trigger) => trigger.id === state.triggerId);
  }, [state.allTriggers, state.triggerId]);

  return (
    <div>
      <div>
        <div className="d-flex align-items-center">
          <h5 className={`mb-0 ${styles.triggerText} pe-2`}>
            Define when this campaign should be triggered{" "}
          </h5>
          {edit ? (
            <span
              className={`${styles.editText} cursor-pointer`}
              onClick={() => setEdit(!edit)}
            >
              Editing...
            </span>
          ) : (
            <img
              src={editIcon}
              alt={"edit"}
              className="cursor-pointer"
              width={9}
              onClick={() => setEdit(!edit)}
            />
          )}
        </div>
        {edit ? (
          <div className="mb-2">
            {state.allTriggers.map((trigger) => (
              <div
                key={trigger.id}
                className="form-check"
              >
                <input
                  className={`form-check-input ${
                    state.showErrors && !selectedTrigger
                      ? "border border-danger"
                      : ""
                  }`}
                  type="radio"
                  name="segmentOptions"
                  checked={selectedTrigger?.id === trigger.id}
                  onChange={() =>
                    dispatch("updateState", {
                      triggerId: trigger.id,
                      showErrors: false,
                    })
                  }
                  id={`${trigger.id}_trigger`}
                />
                <label
                  className={`form-check-label ${styles.checkLabel}`}
                  htmlFor={`${trigger.id}_trigger`}
                >
                  {trigger.value}
                  {trigger.info && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className={` ${styles.toolTipCustom}`}>
                          <span className={`${styles.tooltipCss}`}>
                            {trigger.info}
                          </span>
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <span
                          {...triggerHandler}
                          ref={ref}
                        >
                          <span
                            className={`ps-1 cursor-pointer ${styles.caution}`}
                          >
                            <i className="fa-solid fa-circle-exclamation"></i>
                          </span>
                        </span>
                      )}
                    </OverlayTrigger>
                  )}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={`${styles.checkLabel} mb-2 ${
              state.showErrors && !selectedTrigger ? "text-danger" : ""
            }`}
          >
            {selectedTrigger ? selectedTrigger.value : "No Trigger Selected!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default TriggerCheckbox;
