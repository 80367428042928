import Lottie from "lottie-light-react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ripple from "src/assets/images/ripple.json";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  Question,
  QuestionType,
  QuestionVisibility,
} from "src/services/Automation/getAutomationById.service";
import CategoryQuestion from "../Components/CategoryQuestion/CategoryQuestion";
import ChannelQuestion from "../Components/ChannelQuestion/ChannelQuestion";
import CheckBoxQuestion from "src/routes/BotSettings/Children/Automation/BugManagement/Children/ConfigureSteps/Children/Components/CheckBoxQuestion/CheckBoxQuestion";
import ListQuestion from "../Components/ListQuestion/ListQuestion";
import RadioQuestion from "../Components/RadioQuestion/RadioQuestion";
import QuestionActions from "./Children/QuestionActions/QuestionActions";
import styles from "./Question.module.scss";
import InputBox from "src/routes/BotSettings/Children/Automation/TicketRoute/Children/Components/InputBox/InputBox";
import ConfigureGroupQuestion from "src/routes/BotSettings/Children/Automation/TicketRoute/Children/Components/ConfigureGroupQuestion/ConfigureGroupQuestion";
import ActionQuestion from "src/routes/BotSettings/Children/Automation/TicketRoute/Children/Components/ActionQuestion/ActionQuestion";
import AutomationSummary from "src/routes/BotSettings/Children/Automation/TicketRoute/Children/Components/AssignBox/children/AutomationSummary";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { useCallback, useMemo, useState } from "react";
import {
  findMoveToNextButtonPositionAlternate,
  updateVisibilityAndStatus,
} from "src/routes/BotSettings/Children/Automation/helpers/Automation.helpers";

const InfoIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 14.098 14.098"
    >
      <g
        id="Component_356_4"
        data-name="Component 356 – 4"
        transform="translate(0.5 0.5)"
      >
        <g
          id="Icon_ion-information-circle-outline"
          data-name="Icon ion-information-circle-outline"
          transform="translate(-4.5 -4.5)"
        >
          <path
            id="Path_7337"
            data-name="Path 7337"
            d="M11.049,4.5A6.549,6.549,0,1,0,17.6,11.049,6.549,6.549,0,0,0,11.049,4.5Z"
            fill="none"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            id="Path_7338"
            data-name="Path 7338"
            d="M15.469,15.469h1.139V19.6"
            transform="translate(-5.416 -5.416)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_7339"
            data-name="Path 7339"
            d="M14.625,23.906h3.132"
            transform="translate(-5 -9.583)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_7340"
            data-name="Path 7340"
            d="M16.535,9.141a.925.925,0,1,0,.925.925.925.925,0,0,0-.925-.925Z"
            transform="translate(-5.486 -2.292)"
          />
        </g>
      </g>
    </svg>
  );
};

const MoveToNextBtn = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) => {
  const { type } = useParams();
  return (
    <button
      {...props}
      className={`mt-3 ${styles.moveBtn}`}
    >
      <span style={{ width: "33px", height: "33px" }}>
        <Lottie animationData={ripple}></Lottie>
      </span>
      <span>
        {type === EAutomationType.TICKET_ROUTING
          ? `Move to next question`
          : `Move to next step`}
      </span>
    </button>
  );
};

interface QuestionsProps {
  questionData: Question;
  stepId: string;
  questionNumber: number;
  showQuestionNumber?: boolean;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveNext?: React.Dispatch<React.SetStateAction<boolean>>;
  updateActiveStep: (val: number) => void;
  showError?: boolean;
  setShowError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionsComponent = ({
  questionData,
  stepId,
  setShowErrorModal,
  updateActiveStep,
}: {
  questionData: Question;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
  updateActiveStep: (val: number) => void;
}) => {
  switch (questionData.questionType) {
    // case QuestionType.Text:
    case QuestionType.Checkbox:
      return (
        <CheckBoxQuestion
          questionData={questionData}
          stepId={stepId}
          setShowErrorModal={setShowErrorModal}
        />
      );
    case QuestionType.List:
    case QuestionType.MultiList:
      return (
        <ListQuestion
          questionData={questionData}
          stepId={stepId}
        />
      );
    case QuestionType.Radio:
      return (
        <RadioQuestion
          questionData={questionData}
          stepId={stepId}
          setShowErrorModal={setShowErrorModal}
        />
      );
    case QuestionType.BugChannel:
      return (
        <ChannelQuestion
          questionData={questionData}
          stepId={stepId}
        />
      );
    case QuestionType.BugCategories:
      return (
        <CategoryQuestion
          questionData={questionData}
          stepId={stepId}
        />
      );
    case QuestionType.TextBox:
      return (
        <InputBox
          questionData={questionData}
          stepId={stepId}
          setShowErrorModal={setShowErrorModal}
        />
      );
    case QuestionType.ConditionGroup:
      return (
        <ConfigureGroupQuestion
          questionData={questionData}
          stepId={stepId}
          setShowErrorModal={setShowErrorModal}
        />
      );
    case QuestionType.Action:
      return (
        <ActionQuestion
          questionData={questionData}
          stepId={stepId}
          setShowErrorModal={setShowErrorModal}
        />
      );
    case QuestionType.AutomationSummary:
      return <AutomationSummary updateActiveStep={updateActiveStep} />;
    case QuestionType.ConfigOnly:
    default:
      return null;
  }
};

const StepQuestion = ({
  questionData,
  stepId,
  questionNumber,
  updateActiveStep,
  showQuestionNumber,
  setShowErrorModal,
  setMoveNext,
  setShowError,
  showError = false,
}: QuestionsProps) => {
  const { formData, updateFormData, type } = useAutomationContext();
  const moveToNextStep = useCallback(() => {
    if (type === EAutomationType.TICKET_ROUTING) {
      if (setMoveNext) setMoveNext(true);
      if (setShowError) setShowError(true);
    }

    const updatedData = updateVisibilityAndStatus(formData, false);

    const {
      moveToNextQuestionKey,
      visibleQuestionId,
      visibleStepId,
      showGoLive,
      activeStepIndex,
    } = findMoveToNextButtonPositionAlternate({
      questionsData: updatedData.questionsData,
      stepsData: updatedData.stepsData,
      stepIds: updatedData.stepIds,
      currentStepId: stepId,
      currentQuestionKey: questionData.questionKey,
    });

    if (visibleQuestionId && updatedData.questionsData[visibleQuestionId]) {
      updatedData.questionsData[visibleQuestionId].visibility =
        QuestionVisibility.Show;
    }

    if (visibleStepId && updatedData.stepsData[visibleStepId]) {
      updatedData.stepsData[visibleStepId].isVisible = true;
    }

    if (activeStepIndex !== null) {
      updateActiveStep(activeStepIndex);
    }

    if (showGoLive !== null) {
      updatedData.showGoLive = showGoLive;
    }

    updateFormData({ ...updatedData, moveToNextQuestionKey });
  }, [
    formData,
    questionData.questionKey,
    setMoveNext,
    stepId,
    type,
    updateActiveStep,
    updateFormData,
  ]);

  return (
    <div
      className={`mt-3 ${
        type === EAutomationType.TICKET_ROUTING && styles.query
      } ${
        type === EAutomationType.TICKET_ROUTING && questionData.isAnswered
          ? styles.activeLine
          : ""
      } ${
        type === EAutomationType.TICKET_ROUTING &&
        showError &&
        !questionData.isAnswered
          ? styles.errorLine
          : ""
      }`}
    >
      <h4 className={`mb-0 pb-1 ${styles.questionHead}`}>
        {/* Question Title */}
        {showQuestionNumber ? <span>Q{questionNumber}. </span> : null}
        {questionData.questionTitle}
        {/* Info popup */}
        {questionData.questionInfo && questionData.questionInfo.length > 0 && (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body bsPrefix={`popover-body ${styles.popOver}`}>
                  {questionData.questionInfo.map((data, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`${
                          data.isBold
                            ? `${styles.heading} ${
                                idx !== questionData.questionInfo?.length
                                  ? "mb-2"
                                  : ""
                              }`
                            : styles.section
                        } `}
                      >
                        {data.text}
                      </div>
                    );
                  })}
                </Popover.Body>
              </Popover>
            }
          >
            <span className="ms-1">
              <InfoIconSVG />
            </span>
          </OverlayTrigger>
        )}
      </h4>

      <QuestionsComponent
        questionData={questionData}
        stepId={stepId}
        setShowErrorModal={setShowErrorModal}
        updateActiveStep={updateActiveStep}
      />

      <div className="d-flex align-items-center">
        {questionData.action &&
          questionData.action.map((data, idx) => {
            return (
              <QuestionActions
                key={idx}
                data={data}
                actionIdx={idx}
                questionKey={questionData.questionKey}
                isBrandRequired={questionData.isBrandRequired}
              />
            );
          })}

        {formData.moveToNextQuestionKey === questionData.questionKey &&
          !(
            type === EAutomationType.TICKET_ROUTING && !questionData.isAnswered
          ) &&
          formData.showGoLive !== true && (
            <MoveToNextBtn
              onClick={moveToNextStep}
              disabled={!questionData.isAnswered}
            />
          )}
      </div>
    </div>
  );
};

export default StepQuestion;
