import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  EOneTimeCampaignStatus,
  IOneTimeCampaign,
} from "src/services/Campaign/getAllOneTimeCampaigns.service";
import { SegmentMessageType } from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import styles from "./OneTimeCampaignTableRow.module.scss";
import UserDetailsModal from "./UserDetailsModal/UserDetailsModal";

interface Props extends IOneTimeCampaign {}

const OneTimeCampaignTableRow = ({
  title,
  sender,
  clicked,
  viewed,
  createdAt,
  content,
  id,
  status,
  type,
  unsubscribed,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<
    "viewed" | "clicked" | "unsubscribe"
  >("viewed");

  const handleShowModal = (type: "viewed" | "clicked" | "unsubscribe") => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={`d-flex align-items-center mb-3 ${styles.tableHeader}`}>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element} pe-1`}>{title} </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        {status === EOneTimeCampaignStatus.Draft ? (
          <span className={`px-2 ${styles.draftStatus}`}>Draft</span>
        ) : (
          <span className={`px-2 ${styles.sentStatus}`}>sent</span>
        )}
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element}`}> {sender}</span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        {content === SegmentMessageType.Chat ? (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="email"
              width="11.862"
              height="9.501"
              viewBox="0 0 11.862 9.501"
            >
              <path
                id="Path_7370"
                data-name="Path 7370"
                d="M10.668.583a1.153,1.153,0,0,1,.847.347,1.153,1.153,0,0,1,.347.847V8.89a1.186,1.186,0,0,1-1.195,1.195H1.195a1.153,1.153,0,0,1-.847-.347A1.153,1.153,0,0,1,0,8.89V1.778A1.153,1.153,0,0,1,.347.931,1.153,1.153,0,0,1,1.195.583Zm0,2.389V1.778L5.917,4.75,1.195,1.778V2.972L5.917,5.917Z"
                transform="translate(0 -0.583)"
              />
            </svg>

            <span className={`ps-1 ${styles.element}`}>Email</span>
          </div>
        ) : (
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="send-fill"
              width="11.03"
              height="11.03"
              viewBox="0 0 11.03 11.03"
            >
              <g
                id="Group_2334"
                data-name="Group 2334"
              >
                <path
                  id="Path_275"
                  data-name="Path 275"
                  d="M11,.473a.345.345,0,0,0-.448-.448L.528,4.036h0L.216,4.16a.345.345,0,0,0-.057.611l.283.179h0L3.886,7.143l2.191,3.443h0l.179.283a.345.345,0,0,0,.611-.057L11,.473Zm-1.264,1.3.325-.812-.812.325L4.087,6.455,4.32,6.6a.345.345,0,0,1,.106.106l.148.233L9.741,1.776Z"
                  transform="translate(0.001 0)"
                  fill-rule="evenodd"
                />
              </g>
            </svg>{" "}
            <span className={`ps-1 ${styles.element}`}>Chat</span>
          </div>
        )}
      </div>
      <div
        className={`${styles.col} text-truncate`}
        // onClick={() => handleShowModal("clicked")}
      >
        <span className={`${styles.element} text-decoration-underline`}>
          {clicked}{" "}
        </span>
      </div>
      <div
        className={`${styles.col} text-truncate`}
        // onClick={() => handleShowModal("viewed")}
      >
        <span className={`${styles.element} text-decoration-underline`}>
          {viewed}{" "}
        </span>
      </div>
      <div
        className={`${styles.col} text-truncate`}
        // onClick={() => handleShowModal("unsubscribe")}
      >
        <span className={`${styles.element} text-decoration-underline`}>
          {unsubscribed}
        </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element}`}>
          {type === "users" ? "Users" : "Others"}{" "}
        </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element}`}>{createdAt} </span>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <UserDetailsModal
          onHide={handleCloseModal}
          modalType={modalType}
        />
      </Modal>
    </div>
  );
};

export default OneTimeCampaignTableRow;
