import styles from "../../ConfigureSteps.module.scss";
import {
  QuestionWrapper,
  CheckboxWrapper,
  MoveToNextQWrapper,
  RadioWrapper,
} from "../Wrappers/Wrappers";
import { ChangeEvent, useCallback, useMemo, useState ,useEffect} from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { UseMutateFunction } from "@tanstack/react-query";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { Link } from "react-router-dom";
import { setBackButtonRedirectPath } from "src/store/slices/botProfiles/botProfiles.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { ConfigureSteps } from "../../useConfigureSteps";
import { Modal } from "react-bootstrap";
import AdvanceBotModal from "../EmailSettings/AdvanceBotModal/AdvanceBotModal";
import Lottie from "lottie-light-react";
import ListQuestion from "src/routes/BotSettings/Children/Automation/AutomationTabSettings/Children/ConfigureSteps/Children/Components/ListQuestion/ListQuestion";
import {
  IMultiSelectListQuestion,
  QuestionType,
  QuestionVisibility,
} from "src/services/Automation/getAutomationById.service";
import { InputActionMeta } from "react-select";
import Select, { components, GroupBase, MenuListProps } from "react-select";
import SelectUserSegment from "./SelectUserSegment";
import PlanModal from "src/components/PlanUpgrade/Children/PlanModal/PlanModal";
import ContactSaleModal from "src/routes/Setting/children/MainSetting/children/Billing/Children/FeatureLimits/ContactSaleModal/ContactSaleModal";
import { useBillingContext } from "src/routes/Setting/children/MainSetting/children/Billing/BillingContext";

/**
 * Component for managing basic settings in bot configuration.
 * updateBotSettings Function to update bot settings.
 * refetchGetBot Function to refetch bot settings.
 * configureSteps Instance of ConfigureSteps for managing configuration steps.
 */
function BasicSettings({
  updateBotSettings,
  refetchGetBot,
  configureSteps,
}: {
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  refetchGetBot: () => void;
  configureSteps: ConfigureSteps;
}) {
  const {
    state,
    dispatch,
    profileId,
    handleMoveToNextStep,
    currentActiveStep,
    setCurrentActiveStep,
  } = configureSteps;
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures
  );


  const appDispatch = useAppDispatch();
  const [showAdvance, setShowAdvance] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const { billingDetails } = useBillingContext();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [isFeatureDisabled, setIsFeatureDisabled] = useState(true);
  console.log(billingDetails);
  // function to show configure followup questions modal
  const showAdvanceModal = () => {
    setShowAdvance(true);
  };
  // function to hide configure followup questions modal
  const hideAdvanceModal = () => {
    setShowAdvance(false);
  };

  const showCompleted = useMemo(() => {
    return state.botProfile.visibleQuestionKeys?.includes("channels") &&
      state.botProfile.visibleQuestionKeys?.includes("answer_content")
      ? true
      : false;
  }, [state.botProfile.visibleQuestionKeys]);

  // const handleRadioAnswerChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {},
  //   []
  // );
  const WARNING_MESSAGE = "You cannot remove all channels";
  /**
   * Handles the change in selected channels.
   */
  const handleChannelChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Extracts channel id from event attributes
      const channelId = e.currentTarget.getAttribute("data-channel-id");
      if (channelId) {
        if (state.botProfile.channels?.includes(channelId)) {
          if (state.botProfile.channels?.length === 1) {
            pushTheToast({
              text: WARNING_MESSAGE,
              position: "top-right",
              type: "warning",
            });
            return;
          }
          // Removes channel from selected channels
          dispatch({
            type: "UPDATE_CHANNELS",
            payload: state.botProfile.channels.filter(
              (c: string) => c !== channelId,
            ),
          });
          // Updates bot settings via API call
          updateBotSettings(
            {
              id: state.botProfile.id,
              channels: state.botProfile.channels?.filter(
                (c: string) => c !== channelId,
              ) as Array<string>,
            },
            {
              onSuccess: (data) => {
                if (data.statusCode && data.statusCode !== undefined) {
                  refetchGetBot();
                  dispatch({
                    type: "UPDATE_STATUS",
                    payload: "draft",
                  });
                }
              },
            },
          );
        } else {
          // Adds channel to selected channels
          dispatch({
            type: "UPDATE_CHANNELS",
            payload: [...state.botProfile.channels, channelId],
          });
          // Updates bot settings via API call
          updateBotSettings(
            {
              id: state.botProfile.id,
              channels: [
                ...state.botProfile.channels,
                channelId,
              ] as Array<string>,
            },
            {
              onSuccess: (data) => {
                if (data.statusCode && data.statusCode !== undefined) {
                  refetchGetBot();
                  dispatch({
                    type: "UPDATE_STATUS",
                    payload: "draft",
                  });
                }
              },
            },
          );
        }
      }
    },
    [state, dispatch, updateBotSettings, refetchGetBot],
  );

  const toggleConfigureAdvancedBotSettings = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Extracting name and value from event attributes
      const name = e.target.getAttribute("name");
      let value: string | null | boolean = e.target.getAttribute("data-value");

      if (value === "true") {
        value = true;
      }
      if (value === "false") {
        value = false;
      }
      // Dispatches update to bot settings
      if (name && value != null) {
        dispatch({
          type: "UPDATE_ANY_DETAILS",
          payload: { key: name, value },
        });
        // Updates bot settings via API call
        updateBotSettings(
          {
            id: state.botProfile.id,
            [name]: value,
          },
          {
            onSuccess: (data) => {
              if (data.statusCode && data.statusCode !== undefined) {
                refetchGetBot();
                dispatch({
                  type: "UPDATE_STATUS",
                  payload: "draft",
                });
              }
            },
          },
        );
      }
    },
    [state, dispatch, updateBotSettings, refetchGetBot],
  );

  /**
   * Handles the change in selected answer sources.
   * @param e Change event object.
   */
  const handleAnswerSourcesChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Extracts answer source id from event attributes
      const answerSourceId = parseInt(
        e.currentTarget.getAttribute("data-answer-source-id") ?? "0",
      );
      console.log(answerSourceId);
      if (answerSourceId) {
        if (Array.isArray(state.botProfile.answerContent)) {
          if (state.botProfile.answerContent?.includes(answerSourceId)) {
            if (state.botProfile.answerContent?.length === 1) {
              pushTheToast({
                text: "You cannot remove all sources",
                position: "top-right",
                type: "warning",
              });

              return;
            }
            // Removes answer source from selected sources
            dispatch({
              type: "UPDATE_BOTTY_ANSWER_CONTENT",
              payload: state.botProfile.answerContent?.filter(
                (item) => item !== answerSourceId,
              ),
            });
            // Updates bot settings via API call
            updateBotSettings(
              {
                id: state.botProfile.id,
                answer_content: state.botProfile.answerContent?.filter(
                  (item) => item !== answerSourceId,
                ) as Array<number>,
              },
              {
                onSuccess: (data) => {
                  if (data.statusCode && data.statusCode !== undefined) {
                    refetchGetBot();
                    dispatch({
                      type: "UPDATE_STATUS",
                      payload: "draft",
                    });
                  }
                },
              },
            );
          } else {
            // Adds answer source to selected sources
            dispatch({
              type: "UPDATE_BOTTY_ANSWER_CONTENT",
              payload: [...state.botProfile.answerContent, answerSourceId],
            });
            // Updates bot settings via API call
            updateBotSettings(
              {
                id: state.botProfile.id,
                answer_content: [
                  ...state.botProfile.answerContent,
                  answerSourceId,
                ] as Array<number>,
              },
              {
                onSuccess: (data) => {
                  if (data.statusCode && data.statusCode !== undefined) {
                    refetchGetBot();
                    dispatch({
                      type: "UPDATE_STATUS",
                      payload: "draft",
                    });
                  }
                },
              },
            );
          }
        } else {
          // Sets answer source as the only selected source
          dispatch({
            type: "UPDATE_BOTTY_ANSWER_CONTENT",
            payload: [answerSourceId],
          });
          // Updates bot settings via API call
          updateBotSettings(
            {
              id: state.botProfile.id,
              answer_content: [answerSourceId],
            },
            {
              onSuccess: (data) => {
                if (data.statusCode && data.statusCode !== undefined) {
                  refetchGetBot();
                  dispatch({
                    type: "UPDATE_STATUS",
                    payload: "draft",
                  });
                }
              },
            },
          );
        }
      }
    },
    [
      state.botProfile.answerContent,
      state.botProfile.id,
      dispatch,
      updateBotSettings,
      refetchGetBot,
    ],
  );

  const handleUserSegmentChanges = useCallback(
    (newValue: any[]): void => {
      // Dispatches update to bot settings
      dispatch({
        type: "UPDATE_USER_SEGMENT",
        payload: newValue
      });


      // Updates bot settings via API call
      updateBotSettings(
        {
          id: state.botProfile.id,
          userSegmentIds: newValue.map((option) => option.value),
        },
        {
          onSuccess: (data) => {
            if (data.statusCode && data.statusCode !== undefined) {
              refetchGetBot();
              dispatch({
                type: "UPDATE_STATUS",
                payload: "draft",
              });
            }
          },
        }
      );
    },
    [state.botProfile, dispatch, updateBotSettings, refetchGetBot],
  );



  /**
   * Moves to the next step from answer content selection.
   */
  const handleMoveToNextFromAnswer = useCallback(() => {
    // Checks if answer content and introduction message are selected
    if (state.botProfile.answerContent.length > 0) {
      // Commented this as there is no need to check for introduction message here
      // if (state.botProfile.introductionMessage?.length > 0) {
      handleMoveToNextStep("bottyAnswerContent");
      // Commented this as there is no need to check for introduction message here
      // } else {
      //   pushTheToast({
      //     text: "Please enter introduction message",
      //     position: "top-right",
      //     type: "warning",
      //   });
      // }
    } else {
      pushTheToast({
        text: "Please select at least one answer content",
        position: "top-right",
        type: "warning",
      });
    }
  }, [handleMoveToNextStep, state.botProfile.answerContent.length]);

  /**
   * Moves to the next step from channel selection.
   */
  const handleMoveToNextFromChannels = useCallback(() => {
    // Checks if channels are selected
    if (state.botProfile.channels.length > 0) {
      handleMoveToNextStep("channels");
    } else {
      pushTheToast({
        text: "Please select at least one channel",
        position: "top-right",
        type: "warning",
      });
    }
  }, [handleMoveToNextStep, state.botProfile.channels.length]);

  /**
   * Moves to the next step from channel selection.
   */
  const handleMoveToNextFromAdvanceSetting = useCallback(() => {
    // Checks if channels are selected
    if (
      state.botProfile.isConfigureAdvancedBotSettings &&
      state.botProfile.configureAdvancedBotSettingsModel?.isCompleted
    ) {
      handleMoveToNextStep("isConfigureAdvancedBotSettings");
    } else if (!state.botProfile.isConfigureAdvancedBotSettings) {
      handleMoveToNextStep("isConfigureAdvancedBotSettings");
    }
  }, [
    handleMoveToNextStep,
    state.botProfile.isConfigureAdvancedBotSettings,
    state.botProfile.configureAdvancedBotSettingsModel?.isCompleted,
  ]);

  const handleMoveToNextFromUseCustomerInfo = useCallback(() => {
    handleMoveToNextStep("useAdditionalCustomerInformation");
  }, [handleMoveToNextStep]);

  const handleMoveToNextFromUserSegment = useCallback(() => {
    handleMoveToNextStep("user_segment");
  }, [handleMoveToNextStep]);

  // Jun 11 Getting current user data for ui visibility
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  return (
    <div
      className="d-flex position-relative mb-2"
      id={`step_${state.botProfile.id}`}
    >
      <div className={`d-flex flex-column align-items-center me-2`}>
        <div>
          <span
            className={`${showCompleted ? styles.doneCount : styles.count}`}
          >
            {showCompleted ? (
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            ) : (
              <span>1</span>
            )}
          </span>
        </div>

        <div className={`${styles.statusBar} ${styles.statusBarOne} `}></div>
      </div>

      <div className={`p-3 w-100 ${styles.stepBox}`}>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setCurrentActiveStep(currentActiveStep === 1 ? 0 : 1);
          }}
        >
          <h6 className={`mb-0 ${styles.stepHead}`}>Basic Settings</h6>
          <span className={`${styles.stepSpan}`}>
            Here, you will configure the basic, channel-independent settings for
            the Cern bot.
          </span>
        </div>
        {currentActiveStep === 1 && (
          <>
            <QuestionWrapper
              question={`Q1. What channels do you want Cern to appear on?`}
            >
              {/* June 11 Show Email option based on ui visibility */}
              {currentUserData?.ui_visibility?.includes(
                "email_bot_enabled",
              ) && (
                  <CheckboxWrapper
                    label="Email"
                    checked={state.botProfile.channels.includes("5")}
                    onChange={handleChannelChange}
                    data-channel-id="5"
                  />
                )}
              {currentUserData?.ui_visibility?.includes(
                "integration_facebook_view",
              ) && (
                  <CheckboxWrapper
                    label="Instagram DMs"
                    checked={state.botProfile.channels.includes("2")}
                    onChange={handleChannelChange}
                    data-channel-id="2"
                  />
                )}
              {currentUserData?.ui_visibility?.includes(
                "integration_facebook_view",
              ) && (
                  <CheckboxWrapper
                    label="Facebook DMs"
                    checked={state.botProfile.channels.includes("3")}
                    onChange={handleChannelChange}
                    data-channel-id="3"
                  />
                )}
              <CheckboxWrapper
                label="Live Chat"
                checked={state.botProfile.channels.includes("4")}
                onChange={handleChannelChange}
                data-channel-id="4"
              />
            </QuestionWrapper>
            {state.botProfile.visibleQuestionKeys?.includes("channels") ? (
              <>
                <QuestionWrapper
                  question={`Q2. The bot can be trained using a variety of different sources. What sources would you like to use?`}
                >
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(1)}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="1"
                    label={
                      <>
                        Knowledge base articles
                        {!(disabledFeatures && disabledFeatures.includes("bot_profile")) ? (<Link
                          to={`/bot/botProfiles/${profileId}/answerSource/kbArticles`}
                          className={`${styles.links} ms-2`}
                          onClick={() => {
                            appDispatch(
                              setBackButtonRedirectPath({
                                path: window.location.pathname,
                              }),
                            )
                          }}
                          id={`article_${profileId}_btn`}
                        >
                          See all articles
                        </Link>) : (<span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowPlanModal(true)
                        }}>Upgrade to pro </span>
                        )}


                      </>

                    }
                  />
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(2)}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="2"
                    label={
                      <>
                        External sources
                        {!(disabledFeatures && disabledFeatures.includes("bot_profile")) ? (<> <Link
                          to={`/bot/botProfiles/${profileId}/answerSource/external`}
                          className={`${styles.links} ms-2`}
                          onClick={() => {
                            appDispatch(
                              setBackButtonRedirectPath({
                                path: window.location.pathname,
                              }),
                            );
                          }}
                          id={`import_${profileId}_btn`}
                        >
                          Import source
                        </Link></>) : (<> <span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowPlanModal(true)
                        }}>Upgrade to pro </span>
                        </>)}
                      </>
                    }
                  />
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(3)}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="3"
                    label={
                      <>
                        Custom Q&A
                        {!(disabledFeatures && disabledFeatures.includes("bot_profile")) ? (<> <Link
                          to={`/bot/botProfiles/${profileId}/answerSource/customQa`}
                          className={`${styles.links} ms-2`}
                          onClick={() => {
                            appDispatch(
                              setBackButtonRedirectPath({
                                path: window.location.pathname,
                              }),
                            );
                          }}
                          id={`import_${profileId}_btn`}
                        >
                          Import
                        </Link></>) : (<> <span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowPlanModal(true)
                        }}>Upgrade to pro </span>
                        </>)}
                      </>

                    }

                  />
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(4)}
                    isDisabled={isFeatureDisabled}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="4"
                    label={
                      <>
                        <span className={isFeatureDisabled ? styles.disabledText : ""}>Agent suggestions</span>
                        <span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowContactUsModal(true);
                        }}>Contact sales </span>
                      </>
                    }
                  />
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(5)}
                    isDisabled={isFeatureDisabled}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="5"
                    label={
                      <>
                        <span className={isFeatureDisabled ? styles.disabledText : ""}>Train Products</span>
                        <span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowContactUsModal(true);
                        }}>Contact sales </span>
                      </>
                    }
                  />
                  <CheckboxWrapper
                    checked={state.botProfile.answerContent?.includes(6)}
                    isDisabled={isFeatureDisabled}
                    onChange={handleAnswerSourcesChanges}
                    data-answer-source-id="6"
                    label={
                      <>
                        <span className={isFeatureDisabled ? styles.disabledText : ""}>Train Customers</span>
                        <span className={`${styles.links} ms-2`} onClick={(e) => {
                          e.preventDefault()
                          setShowContactUsModal(true);
                        }}>Contact sales </span>
                      </>
                    }
                  />
                </QuestionWrapper>
              </>
            ) : (
              <></>
            )}
            {/**show question if question two is answered */}
            {state.botProfile.visibleQuestionKeys?.includes(
              "answer_content",
            ) && (
                <QuestionWrapper
                  question={`Q3. Will the responses delivered by Cern be different depending on the exact page a customer is initiating a chat from or other details of the customer, for example, their order status?`}
                >
                  <RadioWrapper
                    name="useAdditionalCustomerInformation"
                    checked={
                      state.botProfile.useAdditionalCustomerInformation === true
                        ? true
                        : false
                    }
                    onChange={toggleConfigureAdvancedBotSettings}
                    data-value="true"
                    label="Yes"
                  />
                  <RadioWrapper
                    name="useAdditionalCustomerInformation"
                    checked={
                      state.botProfile.useAdditionalCustomerInformation === true
                        ? false
                        : true
                    }
                    onChange={toggleConfigureAdvancedBotSettings}
                    data-value="false"
                    label="No"
                  />
                </QuestionWrapper>
              )}
            {/**show question if question three is answered */}
            {state.botProfile.visibleQuestionKeys?.includes(
              "useAdditionalCustomerInformation",
            ) && (
                <QuestionWrapper
                  question={`Q4. Would you like to go into advanced configuration for the bot? You do not have to do this if this is the first time you are configuring a bot. We only suggest users with a deep understanding of the bot algorithm to tinker with advanced settings.`}
                >
                  <RadioWrapper
                    name="isConfigureAdvancedBotSettings"
                    label="Yes"
                    checked={
                      state.botProfile.isConfigureAdvancedBotSettings === true
                        ? true
                        : false
                    }
                    onChange={toggleConfigureAdvancedBotSettings}
                    data-value="true"
                  />
                  <RadioWrapper
                    name="isConfigureAdvancedBotSettings"
                    label="No"
                    checked={
                      state.botProfile.isConfigureAdvancedBotSettings === true
                        ? false
                        : true
                    }
                    onChange={toggleConfigureAdvancedBotSettings}
                    data-value="false"
                  />
                </QuestionWrapper>
              )}
            <div className="d-flex">
              {state.botProfile.visibleQuestionKeys?.includes(
                "useAdditionalCustomerInformation",
              ) &&
                state.botProfile.isConfigureAdvancedBotSettings &&
                state.botProfile.isConfigureAdvancedBotSettings ? (
                <button
                  className={`me-2 ${styles.configureBtn}`}
                  onClick={showAdvanceModal}
                >
                  {state.botProfile.configureAdvancedBotSettingsModel
                    ?.isCompleted ? (
                    <span className={`${styles.checkLogo} mx-1 px-1`}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  ) : (
                    <span className={`pe-1`}>+</span>
                  )}{" "}
                  Configure advanced settings
                </button>
              ) : null}
              <Modal
                backdropClassName={`${styles.modalBack}`}
                show={showAdvance}
                onHide={hideAdvanceModal}
                dialogClassName={`${styles.advanceModalDialog}`}
                contentClassName={`${styles.advanceModalContent}`}
                enforceFocus={false}
                centered
              >
                <AdvanceBotModal
                  onSaveComplete={() => {
                    dispatch({
                      type: "UPDATE_CONFIGURE_FORM_STATUS",
                      payload: "completed",
                    });
                  }}
                  onHide={hideAdvanceModal}
                  configureSteps={configureSteps}
                  updateBotSettings={updateBotSettings}
                  refetchGetBot={refetchGetBot}
                />
              </Modal>

              {!state.botProfile.visibleQuestionKeys?.includes(
                "isConfigureAdvancedBotSettings",
              ) && (
                  <>
                    {state.botProfile.visibleQuestionKeys?.includes(
                      "useAdditionalCustomerInformation",
                    ) ? (
                      <MoveToNextQWrapper
                        disabled={
                          state.botProfile.isConfigureAdvancedBotSettings &&
                          !state.botProfile.configureAdvancedBotSettingsModel
                            ?.isCompleted
                        }
                        onClick={handleMoveToNextFromAdvanceSetting}
                      />
                    ) : state.botProfile.visibleQuestionKeys?.includes(
                      "answer_content",
                    ) ? (
                      <MoveToNextQWrapper
                        onClick={handleMoveToNextFromUseCustomerInfo}
                      />
                    ) : state.botProfile.visibleQuestionKeys?.includes(
                      "channels",
                    ) ? (
                      <MoveToNextQWrapper onClick={handleMoveToNextFromAnswer} />
                    ) : (
                      <MoveToNextQWrapper
                        onClick={handleMoveToNextFromChannels}
                      />
                    )}
                  </>
                )}
            </div>
            {state.botProfile.visibleQuestionKeys?.includes(
              "isConfigureAdvancedBotSettings",
            ) && (
                <>
                  <QuestionWrapper
                    question={`Q5. Which user segments should the bot tailor its response for?`}
                  >
                    <SelectUserSegment
                      userSegmentOptions={state.botProfile.userSegmentsDetails}
                      onchange={handleUserSegmentChanges}
                      name={"userSegment"}

                    />
                  </QuestionWrapper>
                  <MoveToNextQWrapper onClick={handleMoveToNextFromUserSegment} />
                </>
              )}
          </>
        )}
      </div>
      <PlanModal hideModal={function (): void {
        setShowPlanModal(false);
      }} showModal={showPlanModal} />
      {/* Contact Sales Modal */}
      {/* {billingDetails?.featureLimit?.isContactSales && ( */}
      <Modal
        backdropClassName={`${styles.contactmodalBack}`}
        show={showContactUsModal}
        onHide={() => setShowContactUsModal(false)}
        dialogClassName={`${styles.contactmodalDialog}`}
        contentClassName={`${styles.contactmodalContent}`}
        enforceFocus={false}
        centered={true}
      >
        <ContactSaleModal onHide={() => setShowContactUsModal(false)} />
      </Modal>
      {/* )} */}
    </div>
  );
}

export default BasicSettings;
