import { useQuery } from "@tanstack/react-query";
import getTimeline from "src/services/ShopifySidebar/getTimeline";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { TimelineActionTypes, useTimeline } from "../../TimelineProvider/TimelineProvider";

export const useTimeLineMonthData = (year?: string | number) => {
  const {state, dispatch} = useTimeline();
  return useQuery<TimelineData, Error>(
    ["innerTicket/timelineMonthly", year],
    () =>
      getTimeline({
        refrenceId: state.referenceId,
        refrenceType: "customer",
        filter: { year: year! },
      }),
    {
      staleTime: 60000,
      cacheTime: 60000,
      enabled: !!year,
      onSuccess: (data) => {
        dispatch({
          type: TimelineActionTypes.SET_TIMELINE_DATA,
          payload: data
        })
      }
    },
  );
};
