import { useCallback, useEffect, useRef, useState } from "react";
import { AddTicketTagsPayload } from "src/services/TicketService/addTicketTags";
import TicketService from "src/services/TicketService/TicketService";
import { removeWhiteSpaces } from "src/utils/validations";
import Loader from "../Loader";
import styles from "./AddTagsDropDown.module.scss";
import TagItem from "./children/TagItem/TagItem";

export interface OneTag {
  id: number;
  tagName: string;
}

type AllTagsType = Array<OneTag>;

export interface AddTagProps {
  ticketIds: string;
  activeTags: AllTagsType;
  setActiveTags: (activeTags: AllTagsType) => void;
}

const getFilteredTags = (
  tagList: AllTagsType,
  activeTagList: AllTagsType,
  menuInputValue: string,
) => {
  return tagList.filter(
    (tag) =>
      !activeTagList.map((tag2) => tag2.id).includes(tag.id) &&
      tag.tagName
        .toLowerCase()
        .includes(removeWhiteSpaces(menuInputValue).toLocaleLowerCase()),
  );
};

function AddTagsDropDown({
  ticketIds,
  activeTags,
  setActiveTags,
}: AddTagProps) {
  const [menuInputValue, setMenuInputValue] = useState("");
  const [tagList, setTagList]: [AllTagsType, Function] = useState([]);
  const [ajaxStatus, setAjaxStatus]: [
    "pending" | "fulfilled" | "rejected",
    Function,
  ] = useState("pending");

  const tagListRef = useRef(tagList);
  useEffect(() => {
    tagListRef.current = tagList;
  }, [tagList]);
  const handleOnClickCreateTag = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (
        removeWhiteSpaces(menuInputValue) !== "" &&
        !tagList
          .map((data) => data.tagName?.toLowerCase().trim())
          .includes(removeWhiteSpaces(menuInputValue).toLowerCase())
      ) {
        setAjaxStatus("pending");
        TicketService.createTagApi({ name: menuInputValue })
          .then((tagId) => {
            setTagList([
              ...tagListRef.current,
              { id: tagId, tagName: removeWhiteSpaces(menuInputValue) },
            ]);
            setAjaxStatus("fulfilled");
          })
          .catch((err) => {
            setAjaxStatus("rejected");
          });
      }
    },
    [menuInputValue, tagList],
  );
  const handleOnClickAddTagItem = (tag: OneTag) => {
    const servicePayload: AddTicketTagsPayload = {
      ticketIds: ticketIds.split(","),
      tags: [...activeTags.map((tag2) => tag2.id), tag.id],
    };
    setAjaxStatus("pending");
    TicketService.addTicketTagsApi(servicePayload)
      .then((res) => {
        setAjaxStatus("fulfilled");
        setActiveTags([...activeTags, tag]);
      })
      .catch((err) => {
        setAjaxStatus("rejected");
      });
  };

  useEffect(() => {
    setAjaxStatus("pending");
    TicketService.fetchTicketsTagsApi(true)
      .then((tags: any) => {
        setTagList(tags);
        setAjaxStatus("fulfilled");
      })
      .catch((err) => {
        setAjaxStatus("rejected");
      });
  }, [false]);

  const filteredTags = getFilteredTags(tagList, activeTags, menuInputValue);

  return (
    <div className={`${styles["addTagBox"]}`}>
      {ajaxStatus === "pending" ? (
        <Loader className={`${styles.loader}`} />
      ) : (
        <>
          <input
            data-lpignore="true"
            type="text"
            className={`${styles["addInput"]}`}
            placeholder="Enter tag name"
            value={menuInputValue}
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e: any) => {
              setMenuInputValue(e.target.value);
            }}
            onKeyUp={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleOnClickCreateTag(e);
              }
            }}
          />
          <div className={`${styles["addTagDropdownMain"]} mt-1`}>
            {removeWhiteSpaces(menuInputValue) !== "" &&
            !tagList
              .map((data) => data.tagName.toLowerCase().trim())
              .includes(removeWhiteSpaces(menuInputValue).toLowerCase()) ? (
              <div
                className={`btn w-100 ${styles["addTagDropdownBtn"]}`}
                onClick={handleOnClickCreateTag}
              >
                + Create tag "{removeWhiteSpaces(menuInputValue)}"
              </div>
            ) : (
              filteredTags.length === 0 && (
                <div className={`${styles.tagAlreadyAdded}`}>
                  A tag with name “
                  <span className={`${styles.tagAlreadyAddedValue}`}>
                    {removeWhiteSpaces(menuInputValue)}
                  </span>
                  ” is already added
                </div>
              )
            )}
            {filteredTags.map((tag, index) => (
              <TagItem
                key={index}
                tag={tag}
                onClick={handleOnClickAddTagItem}
              />
            ))}
            {}
          </div>
        </>
      )}
    </div>
  );
}

export default AddTagsDropDown;
