import { useCallback, useMemo } from "react";
import activeChatType from "src/assets/images/activeChatType.png";
import activeEmailType from "src/assets/images/activeEmailType.png";
import chatType from "src/assets/images/chatType.png";
import emailType from "src/assets/images/emailType.png";
import Loader from "src/components/Loader";
import { SegmentMessageType } from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { useSendMessage } from "../../Hooks/useSendMessageContext";
import styles from "../../SendMessageModal.module.scss";
// import bannerType from "src/assets/images/bannerType.png";
// import surveyType from "src/assets/images/surveyType.png";

interface MessageTypeListItem {
  type: SegmentMessageType;
  img: string;
  activeImg: string;
  alt: string;
  label: string;
}

const messageTypes: Record<SegmentMessageType, MessageTypeListItem> = {
  [SegmentMessageType.Chat]: {
    type: SegmentMessageType.Chat,
    img: chatType,
    activeImg: activeChatType,
    alt: "chat_type",
    label: "Chat",
  },
  [SegmentMessageType.Email]: {
    type: SegmentMessageType.Email,
    img: emailType,
    activeImg: activeEmailType,
    alt: "email_type",
    label: "Email",
  },
  // [SegmentMessageType.Survey]: {
  //     type: SegmentMessageType.Survey,
  //     img: surveyType,
  //     active: activeSurveyType,
  //     alt: "survey_type",
  //     label: "Survey",
  // },
  // [SegmentMessageType.Banner]: {
  //     type: SegmentMessageType.Banner,
  //     img: bannerType,
  //     active: activeBannerType,
  //     alt: "banner_type",
  //     label: "Banner",
  // },
};

const SelectMessageType = () => {
  const { state, dispatch } = useSendMessage();

  const messageTypeList = useMemo(() => {
    return state.allMessageTypes.map((val) => messageTypes[val]);
  }, [state.allMessageTypes]);

  const handleClick = useCallback(
    (type: SegmentMessageType) => {
      dispatch("updateMessageType", { messageType: type });
      dispatch("updateState", { showErrors: false });
    },
    [dispatch],
  );

  return (
    <div>
      <h4 className={`${styles.contentHead}`}>Select message type</h4>
      <div
        className={`d-flex ${styles.typeBox} mb-4 ${
          state.showErrors && state.selectedTypes.length === 0
            ? styles.errorBorder
            : ""
        }`}
      >
        {state.messageTypesFetchStatus === "pending" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center w-100">
            <Loader
              className={`${styles.loaderHeight} mt-0`}
              imgClassName={`pt-0`}
            />
          </div>
        ) : state.messageTypesFetchStatus === "rejected" &&
          messageTypeList.length === 0 ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : messageTypeList.length === 0 ? (
          <div
            className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
          >
            <span> No Items Found</span>
          </div>
        ) : (
          messageTypeList.map((messageType, index) => {
            const isSelected = state.selectedTypes.includes(messageType.type);

            return (
              <button
                key={index}
                className={`px-2 ${styles.typeBtn} ${
                  isSelected ? styles.activeBtn : ""
                }`}
                onClick={() => handleClick(messageType.type)}
              >
                <img
                  src={isSelected ? messageType.activeImg : messageType.img}
                  alt={messageType.alt}
                  className={`${styles.typeImg}`}
                />
                <span className="ps-1">{messageType.label}</span>
              </button>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SelectMessageType;
