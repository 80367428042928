import { useState } from "react";
import styles from "./StatusSelect.module.scss";
import Select, { SingleValue } from "react-select";
import { ESegmentStatus } from "src/services/Campaign/updateSegment";
// Define the type for option items
interface OptionType {
  label: string;
  value: ESegmentStatus;
}

// Dummy data for the select options with images
const options: OptionType[] = [
  {
    label: "Approved",
    value: ESegmentStatus.Approved,
  },
  {
    label: "Draft",
    value: ESegmentStatus.Draft,
  },
];

const customStyles = {
  control: (styles: any, { selectProps }: any) => {
    // Get the currently selected option
    const selectedValue = selectProps.value?.value;
    return {
      ...styles,
      background:
        selectedValue === ESegmentStatus.Approved ? "#BAFFD6" : "#FFF6BA",
      width: "100px",
      minHeight: "18px",
      display: "flex",
      alignItems: "center",
      border: "none",
      borderRadius: "4px",
      marginRight: "2px",
      font: "normal normal normal 12px/18px Poppins",
      color: selectedValue === ESegmentStatus.Approved ? "#286846" : "#443232",
      cursor: "pointer",
    };
  },
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 12px/18px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "6px 12px",
    width: "229px",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#fff" : "#000",
    backgroundColor: isFocused ? "#0B68BB" : undefined,
    cursor: "pointer",
    font: "normal normal normal 14px/21px Poppins",
    padding: "6px",
    margin: "2px",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "18px", // Adjust the height if needed
    fontSize: "12px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 12px/18px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};
// Custom Option component to display both image and text
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <div
      className={`form-check`}
      ref={innerRef}
      {...innerProps}
    >
      <input
        className="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="data.label"
        checked={isSelected}
      />
      <label
        className={`form-check-label `}
        htmlFor={data.value}
      >
        <div className="d-flex">
          <span
            className={`cursor-pointer ${
              data.value === ESegmentStatus.Approved
                ? styles.approvedStatus
                : styles.draftStatus
            }`}
          >
            {" "}
            {data.label}
          </span>
          {/* show below ui for loader state  */}
          <div className={`ms-3 ${styles.loaderState} d-none`}>
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};
// Custom SingleValue component to display both image and text in selected value
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <span className={`${styles.optionLabel}`}>{data.label}</span>
    </div>
  );
};
const CustomIndicator = (props: any) => {
  const { selectProps } = props;
  const isOpen = selectProps.menuIsOpen;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i
          className={`fa-solid ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
        ></i>
      </span>
    </div>
  );
};

const StatusSelect = ({
  status,
  setStatus,
}: {
  status: ESegmentStatus;
  setStatus: (status: ESegmentStatus) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    options.find((option) => option.value === status) || options[1], // defaults to Draft if no match found
  );

  // Handle dropdown change
  const handleDropdownChange = (newValue: SingleValue<OptionType>) => {
    setSelectedOption(newValue); // Set the new value as the selected option
    setStatus(newValue?.value as ESegmentStatus);
  };

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleDropdownChange}
        options={options}
        placeholder=""
        isSearchable={false}
        styles={customStyles}
        isClearable={true} // Enables the clear indicator (X icon)
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomIndicator,
          Option: CustomOption, // Use custom Option component
          SingleValue: CustomSingleValue, // Use custom SingleValue component
        }}
      />
    </div>
  );
};

export default StatusSelect;
