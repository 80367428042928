import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import {
  GetAllSegmentsParams,
  GetAllSegmentsResponse,
  SegementData,
  getAllSegments,
} from "src/services/Campaign/getAllSegments.serivce";
const LIMIT = 10;

const useGetAllSegment = (payload: GetAllSegmentsParams) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    return ["campaignReport/getAllSegments", payload];
  }, [payload]);

  const { data, isLoading, isError } = useQuery(queryKey, {
    queryFn: () => getAllSegments(payload),
  });
  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.metaData?.totalCount
      ? Math.ceil(data?.metaData?.totalCount / LIMIT)
      : 0;
  }, [data?.metaData?.totalCount]);

  const onStatusChange = useCallback(
    (segmentId: number) => {
      queryClient.setQueryData(
        queryKey,
        (oldData: GetAllSegmentsResponse | undefined) => {
          if (!oldData) return oldData;

          // Filter out the segment with the specified segmentId from data
          const filteredData = oldData.data.filter(
            (segment: SegementData) => segment.id !== segmentId,
          );

          // Update metaData totalCount if it should reflect the length of data
          const updatedMeta = {
            ...oldData.metaData,
            totalCount: filteredData.length,
          };

          // Return the updated data structure
          return { ...oldData, data: filteredData, metaData: updatedMeta };
        },
      );

      // Invalidate and refetch the query to keep the data in sync with the server
      queryClient.refetchQueries(queryKey);
    },
    [queryClient, queryKey],
  );
  const allSegments = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  return {
    allSegments: allSegments,
    isLoading,
    isError,
    totalPages,
    onStatusChange,
  };
};

export default useGetAllSegment;
