import ReportBar from "src/components/svg/ReportBar";
import styles from "./SunBurstReport.module.scss";
import SunBurstZoomable, {
  SunBurstZoomableData,
} from "src/components/SunBurstZoomable/SunBurstZoomable";
import { useEffect, useMemo } from "react";
import FilterWrapper from "../FilterWrapper/FilterWrapper";
import {
  useReportFilters,
  useReportFiltersCreate,
} from "../../hooks/reportFilters/useReportFilters";
import { useQuery } from "@tanstack/react-query";
import getSunBurstReportService from "src/services/report/getSunBurstReport.service";
import Loader from "src/components/Loader";
import TimelineStage from "./Children/CampaignSelect/TimelineStage";
import ConversationsTabs from "./Children/ConversationsTabs/ConversationsTabs";

const Report = () => {
  const { filtersForAPI } = useReportFilters();

  const { data, isError, isLoading } = useQuery(
    ["getSunBurstReportService", filtersForAPI],
    {
      queryFn: async function () {
        return getSunBurstReportService({ filters: filtersForAPI });
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  if (isLoading) {
    return (
      <span className="d-flex align-items-center justify-content-center mt-3 text-danger">
        <Loader />
      </span>
    );
  } else if (isError) {
    return (
      <span className="d-flex align-items-center justify-content-center mt-3 text-danger">
        Error loading content
      </span>
    );
  }
  if (data?.name) {
    return <SunBurstZoomable data={data} />;
  } else {
    return (
      <span className="d-flex align-items-center justify-content-center mt-3 text-secondary">
        No Data Found
      </span>
    );
  }
};

const SunBurstReport = () => {
  const [state, dispatch, Provider] = useReportFiltersCreate({
    globalDateApplicable: false,
  });

  return (
    <Provider value={[state, dispatch]}>
      <div className={`d-flex align-items-center `}>
        <span className="d-none">
          <ReportBar />
        </span>
        <span className={`me-4 ${styles.heading}`}>{"Stage"}</span>
        <TimelineStage />
        <div className={`ms-2 ${styles.filterWrapper}`}>
          <FilterWrapper scope="sunburst" />
        </div>
      </div>

      <ConversationsTabs />
    </Provider>
  );
};

export default SunBurstReport;
