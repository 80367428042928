import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getAllEmailTemplateService, {
  GetAllEmailTemplateResponse,
} from "src/services/Campaign/getAllEmailTemplate.service";

const LIMIT = 10;

export type InfiniteGetAllEmailTemplate = InfiniteData<PageData>;

type PageData = GetAllEmailTemplateResponse & {
  page: number;
};

const useFetchCampaignTemplates = (
  onInitialFetch?: (
    templateCategories: GetAllEmailTemplateResponse["allTemplateCategories"],
  ) => void,
) => {
  const initialFetched = useRef<boolean>(false);
  const [search, setSearch] = useState<string>();

  // Query key includes searchText (trimmed or undefined if empty) for cache identification
  const queryKey = useMemo(
    () => ["getAllEmailTemplateService", search?.trim() || undefined],
    [search],
  );

  const { data, status, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<PageData>(queryKey, {
      queryFn: async ({ pageParam = 1 }) => {
        const res = await getAllEmailTemplateService({
          limit: LIMIT,
          start: (pageParam - 1) * LIMIT,
          search,
        });

        return { ...res, page: pageParam } as PageData;
      },
      getNextPageParam: (lastPage) => {
        const { page, hasMore } = lastPage;
        return hasMore ? page + 1 : undefined;
      },
      keepPreviousData: true,
    });

  // Memoized computation to merge data across all pages.
  const allTemplateCategories = useMemo(() => {
    if (!data) {
      return [];
    }

    // Reduce the paginated data into a single structure containing all segment ids and data
    return data.pages.flatMap((val) => val.allTemplateCategories);
  }, [data]);

  /**
   * Function to load more segments when needed.
   * Only triggers the fetch if there are more pages to load and the query is not already loading.
   */
  const fetchMore = useCallback(() => {
    if (hasNextPage && status !== "loading" && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, status]);

  useEffect(() => {
    if (
      onInitialFetch &&
      !initialFetched.current &&
      allTemplateCategories.length > 0
    ) {
      onInitialFetch(allTemplateCategories);
      initialFetched.current = true;
    }
  }, [allTemplateCategories, onInitialFetch]);

  return {
    allTemplateCategories,
    fetchMore,
    search,
    setSearch,
    queryKey,
    hasMore: hasNextPage,
    templatesFetchStatus: (isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected") as AJAXSTATUS,
  };
};

export default useFetchCampaignTemplates;
