import { axiosJSON } from "src/globals/axiosEndPoints";
import { CampaignEmail } from "./getCampaignById";

interface getCampaignEmailByIdParam {
  id: number | string;
  campaignId?: number | string;
}

export const getCampaignEmailById = async (
  params: getCampaignEmailByIdParam,
) => {
  const { data: res } = await axiosJSON.post("/api/campaign/email/get", params);
  if (res.err) {
    throw res.msg;
  }

  return res.data as CampaignEmail;
};
