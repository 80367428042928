import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { StepQuestion } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./Steptimeline.module.scss";

/**
 * StepTimeline component renders a visual representation of a step in a timeline-based UI.
 * It shows the step's title, a dot representing the step, and a line connecting to the next step.
 *
 * @param stepQuestion - The question data associated with the step.
 * @param isActive - Boolean indicating whether this step is currently active.
 * @param isLast - Boolean indicating whether this step is the last one in the timeline.
 */
const StepTimeline = ({
  stepQuestion,
  isActive,
  isLast,
}: {
  stepQuestion: StepQuestion;
  isActive: boolean;
  isLast: boolean;
}) => {
  return (
    <OverlayTrigger
      placement="top"
      trigger="hover"
      overlay={
        <Tooltip className={` ${styles.toolTipCustom}`}>
          <span className={`p-1 ${styles.toolTipCustom}`}>
            {/* Display question title in the tooltip */}
            {stepQuestion.questionTitle}
          </span>
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div
          ref={ref} // Attach ref here
          {...triggerHandler} // Apply trigger handler for hover events
          className={`d-flex position-relative ${styles.timelineWrapper}`}
        >
          {/* The visual dot representing the step */}
          <div
            className={`${styles.timeline} ${
              isLast ? styles.last : ""
            } d-flex flex-column align-items-center justify-content-center`}
          >
            <div>
              <div
                className={`${styles.greyDot} ${
                  isActive ? styles.activeDot : ""
                }`}
              ></div>
            </div>

            {/* If not the last step, show the connector line */}
            {!isLast && (
              <div
                className={`${styles.stepHeight} ${
                  isActive ? styles.activeStepHeight : ""
                }`}
              ></div>
            )}
          </div>

          {/* Display the question title next to the dot */}
          <div className="w-100 pb-2">
            <span
              className={`${styles.stepTitle} text-truncate ${
                isLast ? "border-0" : ""
              }`}
            >
              {stepQuestion.questionTitle}
            </span>
          </div>
        </div>
      )}
    </OverlayTrigger>
  );
};

export default StepTimeline;
