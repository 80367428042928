import { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import {
  DiscountSectionSequence,
  SequenceStepSection,
} from "src/services/Campaign/createCampaignV2.service";
import styles from "../SequenceSection.module.scss";

const DiscountSection = ({
  section,
  handleSectionsChange,
  handleDelete,
  showErrors,
}: {
  section: DiscountSectionSequence;
  handleSectionsChange: (section: SequenceStepSection) => void;
  handleDelete: () => void;
  showErrors: boolean;
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(showErrors);

  // Update popover visibility based on showErrors prop
  useEffect(() => {
    if (showErrors && (!section.amount || section.amount == 0)) {
      setShowPopover(showErrors);
    } else {
      setShowPopover(false);
    }
  }, [showErrors]);

  // Function to update the type of discount (percentage or currency)
  const updateCurrencyType = (type: "percentage" | "currency") => {
    const updatedSection = {
      ...section,
      type,
    } as DiscountSectionSequence;

    handleSectionsChange(updatedSection);
  };

  // Function to update the amount of discount
  const onAmountChange = (amount: number) => {
    const updatedSection = {
      ...section,
      amount,
    } as DiscountSectionSequence;

    handleSectionsChange(updatedSection);
  };

  // Popover component for error display
  const popover =
    showErrors && (!section.amount || section.amount == 0) ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <div className="border px-2 bg-warning text-white me-1 rounded-3 ">
            !
          </div>
          <div className="mt-1">Please enter amount</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <div className={`w-100 d-flex ${styles.introBox} `}>
      <div className="d-flex align-items-center me-2">
        <p className="mb-0 d-flex">
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </p>
      </div>
      <div className="w-100">
        <div className={`mt-2 w-100`}>
          <div className="d-flex justify-content-between">
            <h5 className={`${styles.introHead}`}>Offering Discount</h5>
            <span
              className={`${styles.deleteText} cursor-pointer`}
              onClick={handleDelete}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>
          <div className="d-flex align-items-center w-100">
            <p className={`${styles.formLabel}`}>Will be</p>
            <div className={`ms-3 me-3 ${styles.inputWrapper} w-100`}>
              <div className="d-flex">
                <OverlayTrigger
                  show={showPopover}
                  placement="bottom"
                  overlay={popover}
                  rootClose={true}
                  onToggle={(isShown) => setShowPopover(isShown)}
                  rootCloseEvent="mousedown"
                >
                  <div>
                    <div
                      className={`d-flex justify-content-between align-items-center py-1 ms-lg-3 ${
                        styles.counterWrap
                      } ${
                        showErrors && (!section.amount || section.amount == 0)
                          ? "border-danger"
                          : ""
                      }`}
                    >
                      {/* Decrement button */}
                      <div
                        className="px-3"
                        role="button"
                        onClick={() =>
                          section.amount > 1 &&
                          onAmountChange(section.amount - 1)
                        }
                      >
                        <div
                          className={`d-flex align-items-center justify-content-center ${
                            section.amount > 1
                              ? styles.addMore
                              : styles.disableBtn
                          }`}
                        >
                          -
                        </div>
                      </div>

                      {/* Display the count with a leading zero if it is a single digit */}
                      <div className={`px-3 ${styles.count}`}>
                        {section.amount < 10
                          ? `0${section.amount}`
                          : section.amount}
                      </div>

                      {/* Increment button */}
                      <div
                        className="px-3"
                        role="button"
                        onClick={() => onAmountChange(section.amount + 1)}
                      >
                        <div
                          className={`d-flex align-items-center justify-content-center ${styles.addMore}`}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>

                {/* Dropdown for selecting type of discount */}
                <Dropdown>
                  <Dropdown.Toggle
                    className={`ms-lg-2 mt-2 mt-lg-0 ${styles.currencyBox}`}
                  >
                    {section.type
                      ? section.type === "percentage"
                        ? "Percentage [%]"
                        : section.type === "currency"
                          ? "USD"
                          : "Select"
                      : "Select"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.mainSelectMenu} mt-1`}>
                    <Dropdown.Item
                      value="percentage"
                      onClick={() => updateCurrencyType("percentage")}
                      className={`${styles.daysddItem}`}
                    >
                      Percentage [%]
                    </Dropdown.Item>
                    <Dropdown.Item
                      value="currency"
                      onClick={() => updateCurrencyType("currency")}
                      className={`${styles.daysddItem}`}
                    >
                      USD
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountSection;
