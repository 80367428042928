import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import styles from "./ArticleSetting.module.scss";
import { v4 as uuid } from "uuid";
import useArticleSetting from "./useArticleSetting";
import { Dropdown, Modal } from "react-bootstrap";
import { useState } from "react";
import NewCategory from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/KnowledgeBasedArticles/Children/NewCategory/NewCategory";
import useCreateArticle from "../../useCreateArticle";
import { KeywordData } from "src/services/LiveChat/Settings/knowledgeBased/article/getKeywordsAndVolums";

interface Props {
  selectedCategory: { label: string; value: number | string };
  articleStatus: string;
  searchKeyword: string;
  setSelectedCategory: any;
  setArticleStatus: any;
  setSearchKeyword: any;
  isNew: boolean;
  integrationId: number | string;
  showError: boolean;
  setShowError: any;
  keywordVolums?: KeywordData[];
  generatedBy?: string
}

const ArticleSetting = ({
  selectedCategory,
  articleStatus,
  searchKeyword,
  setSelectedCategory,
  setArticleStatus,
  setSearchKeyword,
  isNew,
  integrationId,
  showError,
  setShowError,
  keywordVolums,
  generatedBy
}: Props) => {
  const {
    handleInfiniteScroll,
    fetchArticleCategoryAjaxStatus,
    categoryData,
    metaData,
    categoryIdList,
    handleSelectedCategory,
  } = useArticleSetting({ integrationId, isNew, setSelectedCategory });

  //Added these to give option to "New Category" when there are no categories
  const {
    activeCategoryId,
    isCategoryNameUpdate,
    newCategoryName,
    setNewCategory,
    handleSubmit,
    onHide,
    showCategoryLoader,
    showValdation,
    setShowValidation,
    showModal,
    handleNewCategory,
  } = useCreateArticle();

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      <div className={`${styles.chatSettings}`}>
        <h3 className={`${styles.setHead}`}> Article settings</h3>
        <div>
          {keywordVolums?.length && generatedBy === "ai" ? (
            <div>
              <div className={`${styles.chatCategory}`}>
                Keywords & Volume targeted
              </div>
              <div className={`p-2 ${styles.keywordContainer} mt-1`}>
                <div className="d-flex justify-content-between mb-1">
                  <div className={styles.columnHeading}>Keywords</div>
                  <div className={styles.columnHeading}>Volume</div>
                </div>
                {keywordVolums?.map((keyAndVolum) => (
                  <div className="d-flex justify-content-between mt-1">
                    <div
                      className={`${styles.keywordAndVolum} ${styles.keywordWidth}`}
                    >
                      {keyAndVolum.keyword}
                    </div>
                    <div
                      className={`${styles.keywordAndVolum} ${styles.volumeWidth}`}
                    >
                      {keyAndVolum.volume.toLocaleString()}
                    </div>
                  </div>
                ))}
              </div>
              <p className={`${styles.keywordAndVolum} my-2`}>
                All the displayed volume targeted is in monthly searches
              </p>
            </div>
          ) : null}
          <div className="mb-2">
            <span className={`${styles.chatCategory}`}>Category</span>

            <Dropdown bsPrefix="dropdown w-100">
              <Dropdown.Toggle
                as="div"
                id="dropdown-basic"
                bsPrefix={`dropdown-toggle cursor-pointer px-2 ${
                  styles.categoryMain
                } ${
                  showError && selectedCategory.label.length === 0 //will show red border if category is not selected
                    ? "border border-danger"
                    : ""
                }`}
                // onClick={(e) => setShowMenu(!showMenu)}
              >
                <span onClick={(e) => setShowMenu(!showMenu)}>
                  <span className={`text-truncate ${styles.started}`}>
                    {selectedCategory.label.length !== 0
                      ? selectedCategory.label
                      : "NA"}
                  </span>
                </span>
              </Dropdown.Toggle>
              {/* show error */}
              {showError && selectedCategory.label.length === 0 && (
                <span className={`${styles.errorSpan}`}>
                  *Please select a category!
                </span>
              )}
              <Dropdown.Menu
                bsPrefix={`dropdown-menu ${styles.addNewBox}`}
                show={showMenu}
              >
                <InfiniteScroll
                  // className={`mt-4 ${styles.infScroll}`}
                  loadMore={handleInfiniteScroll}
                  loader={
                    fetchArticleCategoryAjaxStatus === "pending" &&
                    Object.keys(categoryData).length === 0 ? (
                      <div
                        className={`d-flex flex-column m-auto ${styles.loading}`}
                      >
                        <Loader />
                      </div>
                    ) : undefined
                  }
                  errorMessage={
                    fetchArticleCategoryAjaxStatus === "fulfilled" &&
                    Object.keys(categoryData).length === 0 ? (
                      // <div className={`d-flex flex-column m-auto`}>
                      //   <span className={`${styles.noData}`}>
                      //     No Category Found
                      //   </span>
                      // </div>

                      // Added option to add new category when there are no categories
                      <li className="ms-3">
                        <span
                          className={`${styles.innerDropDown}`}
                          onClick={(e) => {
                            setShowMenu(false);
                            handleNewCategory();
                            setShowError(false);
                          }}
                        >
                          <span className="me-2">
                            <i className="fa-solid fa-folder"></i>
                          </span>
                          New Category
                        </span>
                      </li>
                    ) : undefined
                  }
                  initialDataLoaded={false}
                  hasMore={
                    fetchArticleCategoryAjaxStatus === "rejected"
                      ? false
                      : metaData.total === null
                        ? true
                        : metaData.total != null &&
                          categoryIdList.length < metaData.total
                  }
                >
                  {Object.keys(categoryData).map((key, index: number) => {
                    return (
                      <li key={uuid()}>
                        <a
                          className={`dropdown-item text-truncate ${styles.listItem}`}
                          href="#"
                          onClick={(e) => {
                            setShowMenu(false);
                            handleSelectedCategory({
                              categoryId: categoryData[key].categoryId,
                              categoryName: categoryData[key].categoryName,
                            });
                            setShowError(false);
                          }}
                        >
                          <span className={`me-1`}>
                            {" "}
                            <i className="fa-solid fa-folder"></i>
                          </span>{" "}
                          {categoryData[key].categoryName}
                        </a>
                      </li>
                    );
                  })}
                </InfiniteScroll>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mb-2">
            <span className={`${styles.chatCategory}`}>Status</span>

            <Dropdown bsPrefix="dropdown w-100">
              <Dropdown.Toggle
                as="div"
                id="dropdown-basic"
                bsPrefix={`dropdown-toggle cursor-pointer px-2 ${styles.categoryMain}`}
              >
                {articleStatus === "public" ? (
                  <span className={`${styles.statusType}`}>Public</span>
                ) : (
                  <span className={`${styles.statusType2}`}>Draft</span>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.addNewBox}`}>
                <li>
                  <a
                    className={`dropdown-item ${styles.innerDropDown}`}
                    href="#"
                  >
                    <div
                      className={`d-flex`}
                      onClick={(e) => setArticleStatus("public")}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={articleStatus === "public"}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div>
                        <span className={`${styles.statusType}`}>Public</span>
                        <p className={`mb-0 mt-1 ${styles.statusText}`}>
                          Anyone will be able to view this article
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className={`dropdown-item ${styles.innerDropDown}`}
                    href="#"
                  >
                    <div
                      className={`d-flex`}
                      onClick={(e) => setArticleStatus("draft")}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={articleStatus === "draft"}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div>
                        <span className={`${styles.statusType2}`}>Draft</span>
                        <p className={`mb-0 mt-1 ${styles.statusText}`}>
                          Keep it as a draft for further edits
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* Add New Category Modal */}
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            enforceFocus={false}
          >
            <NewCategory
              categoryId={activeCategoryId}
              isUpdate={isCategoryNameUpdate.current}
              categoryName={newCategoryName}
              setCategoryName={setNewCategory}
              handleSubmit={handleSubmit}
              onHide={onHide}
              showLoader={showCategoryLoader}
              showValidation={showValdation}
              setShowValidation={setShowValidation}
            />
          </Modal>
          <div>
            <span className={`${styles.chatCategory}`}>Search keywords</span>
            <textarea
              className={`p-2 ${styles.searchKeyword}`}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            ></textarea>
            <p className={`${styles.statusText}`}>
              Add words or phrases, separated by commas,that readers may use to
              search for this article.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleSetting;
