import { axiosJSON } from "src/globals/axiosEndPoints";

interface GetAllTenantParams {
  start: number;
  limit: number;
}

interface TenantData {
  tenantId: string;
  tenantWebsite: string | null;
  CampaignSuggestedLastDate: string | null;
  SegmentSuggestedLastDate: string | null;
  SegmentApprovedLastDate: string | null;
  AiSuggestionsPendingForBackend: string | null;
}

export interface GetAllTenantResponse {
  data: TenantData[];
  hasMore: boolean;
}

export const getAllTenant = async (params: GetAllTenantParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/admin/getAllTenants",
    params,
  );
  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllTenantResponse = {
    data: res.data,
    hasMore: true,
  };

  if (ret.data.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
};
