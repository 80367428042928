import styles from "./Shopify.module.scss";
import shopifyLogo from "src/assets/images/shopify.png";
import SearchBar from "src/components/SearchBar/SearchBar";
import CreateOrderButton from "../CreateOrderButton/CreateOrderButton";
import AllOrdersButton from "../AllOrdersButton/AllOrdersButton";
import Order from "../Order/Order";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchShopifySidebarData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { Spinner } from "react-bootstrap";

function Shopify() {
  const dispatch = useAppDispatch();
  const shopifyData = useAppSelector(
    (state) => state.shopifySidebar.shopifyData,
  );

  const shopifyDataStatus = useAppSelector(
    (state) => state.shopifySidebar.shopifyDataStatus,
  );

  if (shopifyData.customer !== undefined) {
    return (
      <div className={`${styles.shopify} my-2 p-2   overflowX-hidden`}>
        <div className={`p-2`}>
          <div className={`${styles.logo}`}>
            <img
              className={`mx-0`}
              src={shopifyLogo}
            />
            <span className={`mx-2`}>Shopify</span>
          </div>
          {/* <SearchBar
            className={`${styles.search} my-2`}
            inputClassName={`${styles.input}`}
            placeholder={`Search Shopify`}
            onSearch={(value: any) => {
              // // console.log(value);
            }}
          /> */}
          <div className={`${styles.name} mt-3 mb-1`}>
            {shopifyData.customer.name}
          </div>
          <div
            className={`${styles.total} my-1 d-flex justify-content-between`}
          >
            <div className={`${styles.name} mx-0`}>Total Spent:</div>
            <div
              className={`${styles.value}`}
            >{`${shopifyData.customer.totalAmountSpent?.currencyCode} ${shopifyData.customer.totalAmountSpent?.amount}`}</div>
          </div>
          <div
            className={`${styles.total} my-1 d-flex justify-content-between`}
          >
            <div className={`${styles.name} mx-0`}>Total Orders:</div>
            <div className={`${styles.value}`}>
              {shopifyData.customer.totalOrdersCount}
            </div>
          </div>
          {/* <CreateOrderButton /> */}
        </div>
        <AllOrdersButton />
        {shopifyData.orderIds.map((id, index) => {
          return (
            <Order
              key={id}
              index={index}
              order={shopifyData.orders[id]}
            />
          );
        })}
        <div
          className={`${styles.someMoreOrder} ${
            shopifyData.totalOrders - shopifyData.orderIds.length
              ? "d-flex"
              : "d-none"
          } justify-content-center`}
          onClick={(e) => {
            if (shopifyData.totalOrders > shopifyData.orderIds.length) {
              dispatch(fetchShopifySidebarData());
            }
          }}
        >
          {shopifyDataStatus === "pending" && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}{" "}
          <span>
            +{shopifyData.totalOrders - shopifyData.orderIds.length} more orders
          </span>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Shopify;
