import styles from "../../ConfigureSteps.module.scss";
import { useId } from "react";
import Lottie from "lottie-light-react";
import ripple from "src/assets/images/ripple.json";

export const CheckboxWrapper = ({
  label,
  checked,
  onChange,
  isDisabled,
  ...props
}: {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  [props: string]: any;
  isDisabled?: boolean;
}) => {
  const id = useId();

  return (
    <div className="form-check d-flex">
      <div>
        <input
          {...props}
          className="form-check-input"
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={isDisabled === undefined ? false : isDisabled}
        />
      </div>
      <label
        className={`form-check-label ${styles.formLabel}`}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export const RadioWrapper = ({
  label,
  name,
  checked,
  onChange,
  ...props
}: {
  label: string | React.ReactNode;
  name: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  [props: string]: any;
}) => {
  const id = useId();

  return (
    <div className="form-check d-flex">
      <div>
        <input
          {...props}
          className="form-check-input"
          type="radio"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <label
        className={`form-check-label ${styles.formLabel}`}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export const QuestionWrapper = ({
  question,
  children,
}: {
  question: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="mt-2 mb-1">
      <p className={`mb-0 pb-1 ${styles.question}`}>{question}</p>
      <div>{children}</div>
    </div>
  );
};

export const MoveToNextQWrapper = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <div>
      <button
        className={`${styles.moveBtn}`}
        onClick={onClick}
        disabled={disabled}
        id="moveNextBtn"
      >
        <span style={{ width: "33px", height: "33px" }}>
          <Lottie animationData={ripple}></Lottie>
        </span>
        <span>Move to next question</span>
      </button>
    </div>
  );
};
