import { useCallback, useEffect, useState } from "react";
import getAllCustomersService, {
  CustomerInfo,
} from "src/services/TicketService/InternalChatBot/getAllCustomers.service";

const PGINATION_LIMIT = 20;

function useCustomerPagination() {
  const [chats, setChats] = useState<CustomerInfo[]>([]);
  const [isLoading, setISLoading] = useState(true);
  const [isError, setISError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const changePage = useCallback(() => {
    setISLoading(true);
    setISError(false);
    getAllCustomersService({ start: chats.length, limit: PGINATION_LIMIT })
      .then((res) => {
        setTotalCustomers(res.totalCustomers);
        if (res.customerIds.length !== PGINATION_LIMIT) {
          setHasNextPage(false);
        }
        setChats((prev) => {
          let allIds: typeof res.customerIds = [];
          const data: typeof res.customers = {};
          for (let i = 0; i < prev.length; i++) {
            const v = prev[i];
            allIds.push(v.id);
            data[v.id] = v;
          }
          allIds = [...new Set([...allIds, ...res.customerIds])];
          return allIds.map((id) => data[id] ?? res.customers[id]);
        });
      })
      .catch((err) => {
        console.error(err);
        setISError(true);
      })
      .finally(() => {
        setISLoading(false);
      });
  }, [chats]);

  useEffect(() => {
    setISLoading(true);
    setISError(false);
    getAllCustomersService({ start: 0, limit: PGINATION_LIMIT })
      .then((res) => {
        setTotalCustomers(res.totalCustomers);
        if (res.customerIds.length !== PGINATION_LIMIT) {
          setHasNextPage(false);
        }
        setChats(res.customerIds.map((id) => res.customers[id]));
      })
      .catch((err) => {
        console.error(err);
        setISError(true);
      })
      .finally(() => {
        setISLoading(false);
      });
  }, [false]);

  return {
    changePage,
    chats,
    hasNextPage,
    isLoading,
    isError,
    totalCustomers,
  };
}

export default useCustomerPagination;
