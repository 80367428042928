import UserAvatar from "src/components/UserAvatar";
import styles from "./CampaignParentTable.module.scss";
import group from "src/assets/images/group.svg";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import useTenantData from "./useTenantData";
import Loader from "src/components/Loader";
import { useNavigate } from "react-router-dom";

const TableHeader = () => {
  return (
    <div className={`${styles.tableHeader} mb-2`}>
      <div
        className={`${styles.colFirst} d-flex align-items-center text-truncate`}
      >
        <img
          src={group}
          alt="group"
        />
        <span className={`ps-1 ${styles.element} text-truncate`}>
          Tenant Name
        </span>
      </div>
      <div
        className={`${styles.colSecond} d-flex align-items-center text-truncate`}
      >
        <span className={`${styles.element} text-truncate`}>
          Tenant Website
        </span>
        <span className={`ps-1 ${styles.element}`}>
          <i className="fa-solid fa-caret-up"></i>
        </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element} text-truncate`}>
          Campaign suggested last date
        </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element} text-truncate`}>
          Segment suggested last date
        </span>
      </div>
      <div className={`${styles.col} text-truncate`}>
        <span className={`${styles.element} text-truncate`}>
          Segment approved last date
        </span>
      </div>
      <div className={`${styles.colLast} text-truncate`}>
        <span className={`${styles.element} text-truncate`}>
          AI Suggestions Pending For Backend
        </span>
      </div>
    </div>
  );
}

const CampaignParentTable = () => {
  const {allTenants, hasMore, fetchMore, fetchStatus} = useTenantData();
  const navigate = useNavigate();

  return (
    <div className="m-2 h-100">
      <TableHeader />
      <InfiniteScroll
        className={`mb-1 ${styles.infiniteScroll}`}
        hasMoreBottom={hasMore}
        loadMoreFromBottom={fetchMore}
      >
        {allTenants.length === 0 && fetchStatus === "pending" ? (
          <div className="d-flex justify-content-center align-items-center align-items-center">
            <Loader />
          </div>
        ) : allTenants.length === 0 ? (
          <div className="text-danger d-flex align-items-center justify-content-center w-100 h-100">
            No Tenant Found
          </div>
        ) : (
          allTenants.map((tenant) => (
            <div
              key={tenant.tenantId}
              className={`${styles.tableData} cursor-pointer`}
              onClick={() => navigate(`/campaigns/admin/${tenant.tenantId}`)}
            >
              <div
                className={`${styles.colFirst} d-flex align-items-center text-truncate`}
              >
                <UserAvatar
                  name="NA"
                  size={18}
                />
                <span className={`ps-1 ${styles.rowElement} text-truncate`}>
                  {tenant.tenantId}
                </span>
              </div>

              <div
                className={`${styles.colSecond} d-flex align-items-center text-truncate`}
              >
                <a
                  className={`${styles.website} text-truncate`}
                  href={tenant.tenantWebsite ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  {tenant.tenantWebsite}
                </a>
              </div>

              <div className={`${styles.col}`}>
                <span className={`${styles.rowElement}`}>
                  {tenant.CampaignSuggestedLastDate}
                </span>
              </div>

              <div className={`${styles.col}`}>
                <span className={`${styles.rowElement}`}>
                  {tenant.SegmentSuggestedLastDate}
                </span>
              </div>

              <div className={`${styles.col}`}>
                <span className={`${styles.rowElement}`}>
                  {tenant.SegmentApprovedLastDate}
                </span>
              </div>

              <div className={`${styles.colLast}`}>
                <span className={`${styles.rowElement}`}>
                  {tenant.AiSuggestionsPendingForBackend}
                </span>
              </div>
            </div>
          ))
        )}
      </InfiniteScroll>
    </div>
  );
};

export default CampaignParentTable;
