import { useCallback, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { InfoIconSVG } from "src/routes/BotSettings/Children/Automation/AutomationTabSettings/Children/ConfigureSteps/Children/Components/ConfigureDispatchRule/Children/DispatchSection/Children/DispatchIssueDetails/IssueDescriptionComp/IssueDescriptionComp";
import {
  GetSegmentSettingsParams,
  SegmentAttribute,
} from "src/services/CustomerSegments/Settings/getSegmentSettings.service";
import { UpdateSegmentAttribute } from "src/services/CustomerSegments/Settings/updateSegmentSettings.service";
import styles from "./SegmentEvent.module.scss";
import useSegmentEvent from "./useSegmentEvent";

const TableRow = ({
  attribute,
  updatedEventType,
  handleChange,
}: {
  attribute: SegmentAttribute;
  updatedEventType?: SegmentAttribute["eventType"];
  handleChange: (id: string, val: SegmentAttribute["eventType"]) => void;
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [updatedAttribute, setUpdatedAttribute] = useState(
    updatedEventType ? updatedEventType : attribute.eventType,
  );

  return (
    <div className={`${styles.tableSection} d-flex`}>
      <div
        className={`${styles.attribute} ${
          attribute.hyperlink ? styles.link : ""
        }`}
      >
        <span
          className={`${attribute.hyperlink ? "cursor-pointer" : ""}`}
          onClick={() => {
            if (attribute.hyperlink) {
              window.open(attribute.hyperlink.url, "_blank");
            }
          }}
        >
          {attribute.name}
        </span>
        {attribute.hyperlink && (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            show={showPopover}
            onToggle={(nextShow) => setShowPopover(nextShow)}
            overlay={
              <Popover
                id="popover-basic"
                bsPrefix={`popover ${styles.customPopver}`}
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={() => setShowPopover(false)}
              >
                This will be available if the
                <span
                  className={`mx-1 ${styles.sublink} cursor-pointer`}
                  onClick={() => {
                    if (attribute.hyperlink) {
                      window.open(attribute.hyperlink.url, "_blank");
                    }
                  }}
                >
                  {attribute.hyperlink.relatedFeatureName}
                </span>
                is enabled
              </Popover>
            }
          >
            <span className={`ps-1`}>
              <InfoIconSVG />
            </span>
          </OverlayTrigger>
        )}
      </div>
      <div
        className={`${styles.majorEvent} form-check form-switch d-flex align-items-center justify-content-center`}
      >
        <input
          type="checkbox"
          role="switch"
          checked={updatedAttribute === "major"}
          onChange={(e) => {
            e.stopPropagation();
            if (updatedAttribute === "major" && e.target.checked === false) {
              handleChange(attribute.id, null);
              setUpdatedAttribute(null);
              return;
            }
            handleChange(attribute.id, "major");
            setUpdatedAttribute("major");
          }}
          id={`${attribute.id}_majorEvent`}
          className={`form-check-input cursor-pointer ${styles.switchBox}`}
        />
      </div>
      <div
        className={`${styles.minorEvent} form-check form-switch d-flex align-items-center justify-content-center`}
      >
        <input
          type="checkbox"
          role="switch"
          checked={updatedAttribute === "minor"}
          onChange={(e) => {
            e.stopPropagation();
            if (updatedAttribute === "minor" && e.target.checked === false) {
              handleChange(attribute.id, null);
              setUpdatedAttribute(null);
              return;
            }
            handleChange(attribute.id, "minor");
            setUpdatedAttribute("minor");
          }}
          id={`${attribute.id}_minorEvent`}
          className={`form-check-input cursor-pointer ${styles.switchBox}`}
        />
      </div>
    </div>
  );
};

const SegmentEvent = ({
  type,
  updatedAttributes,
  onAssignAttribute,
}: {
  type: GetSegmentSettingsParams["type"];
  updatedAttributes: Map<string, UpdateSegmentAttribute>;
  onAssignAttribute: (id: string, val: UpdateSegmentAttribute) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { attributes, fetchAttributes, loadingStatus, hasMore } =
    useSegmentEvent(type);

  const handleChange = useCallback(
    (id: string, val: SegmentAttribute["eventType"]) => {
      onAssignAttribute(id, { id, eventType: val, type });
    },
    [onAssignAttribute, type],
  );

  return (
    <div className={`${styles.eventWrapper}`}>
      {/* Header */}
      <div className="d-flex justify-content-between">
        <h3 className={`${styles.eventHeading}`}>
          {type === "custom" ? "Custom events" : "Default events"}
        </h3>
        <div
          className="cursor-pointer"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <i
            className={`${
              isExpanded ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"
            }`}
          ></i>
        </div>
      </div>

      {/* Content */}
      {isExpanded && (
        <div className={`${styles.table}`}>
          <div
            className={`${styles.tableSection} ${styles.tableHeader} d-flex`}
          >
            <div className={`${styles.attribute} ${styles.tableHeaderItem}`}>
              Attributes
            </div>
            <div className={`${styles.majorEvent} ${styles.tableHeaderItem}`}>
              Major Events
            </div>
            <div className={`${styles.minorEvent} ${styles.tableHeaderItem}`}>
              Minor Events
            </div>
          </div>

          {loadingStatus === "pending" && attributes.length === 0 ? (
            <div className="d-flex justify-content-center align-content-center align-items-center">
              <Loader />
            </div>
          ) : loadingStatus === "rejected" ? (
            <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
              Error Loading Content...
            </div>
          ) : (
            <InfiniteScroll
              className={`${styles.tableRows}`}
              hasMoreBottom={hasMore}
              loadMoreFromBottom={fetchAttributes}
              infiniteLoader={
                <LoadMoreLoader
                  fetchMore={fetchAttributes}
                  variant="pink"
                  status={
                    loadingStatus === "fulfilled"
                      ? "fulfilled"
                      : loadingStatus === "pending"
                        ? "pending"
                        : null
                  }
                />
              }
            >
              {attributes.length === 0 ? (
                <div
                  className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
                >
                  <span>No Attributes Found</span>
                </div>
              ) : (
                attributes.map((attribute) => (
                  <TableRow
                    attribute={attribute}
                    key={attribute.id}
                    handleChange={handleChange}
                    updatedEventType={
                      updatedAttributes.get(attribute.id)?.eventType
                    }
                  />
                ))
              )}
            </InfiniteScroll>
          )}
        </div>
      )}
    </div>
  );
};

export default SegmentEvent;
