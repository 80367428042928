/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { ESegmentStatus } from "./updateSegment";

export interface GetAllSegmentsParams {
  start: number;
  limit: number;
  filters: {
    status: ESegmentStatus;
  };
}

export interface SegementData {
  id: number;
  name: string;
  status: ESegmentStatus;
  campaignId: string | number;
  timelineData: TimelineData;
}

export interface GetAllSegmentsResponse {
  err: boolean;
  data: SegementData[];
  metaData: {
    totalCount: number;
  };
}

export const getAllSegments = async (params: GetAllSegmentsParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/reports/segment/getAllSegment",
    params,
  );
  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res as GetAllSegmentsResponse;
};
