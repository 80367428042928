import { Col, Nav, Row, Tab } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AddConditionButton from "src/components/AutomationComponents/ContentSection/AddConditionButton";
import Condition from "src/components/AutomationComponents/ContentSection/Condition";
import ContentHeading from "src/components/AutomationComponents/ContentSection/ContentHeading";
import OperatorSelect from "src/components/AutomationComponents/ContentSection/OperatorSelect";
import Values from "src/components/AutomationComponents/ContentSection/Values";
import VariableSelect from "src/components/AutomationComponents/ContentSection/VariableSelect";
import DiscardAndSave from "src/components/AutomationComponents/DiscardAndSave";
import AddMore from "src/components/AutomationComponents/TabSection/AddMore";
import AutomationTab from "src/components/AutomationComponents/TabSection/AutomationTab";
import CloseButtonForTab from "src/components/AutomationComponents/TabSection/CloseButtonForTab";
import ConditionGroup from "src/components/AutomationComponents/TabSection/ConditionGroup";
import TabError from "src/components/AutomationComponents/TabSection/TabError";
import TabName from "src/components/AutomationComponents/TabSection/TabName";
import Loader from "src/components/Loader";
import ReasonRequestBox from "../../AntiRefundStrategies/RefundBox/RefundBox";
import styles from "./ReturnOrder.module.scss";
import useReturnOrder from "./useReturnOrder";

interface Props {
  onSave: (isSaved: boolean) => void;
}

const ReturnOrder = ({ onSave }: Props) => {
  const {
    reasons,
    operators,
    antiRefundOrderTypes,
    selectedOrderTypeId,
    fetchAjaxStatus,
    saveFailed,
    isAddBtnEnabled,
    conditionOptions,
    defaultOptions,
    updateAjaxStatus,
    initialHash,
    currentHash,
    dispAddCondition,
    dispAddTab,
    dispDeleteCondition,
    dispPostConfig,
    dispPushCurrentHashForSelectedTab,
    dispUpdateConditionForValues,
    dispUpdateConditionForVariables,
    dispUpdateConditonForOperator,
    dispUpdateOrderType,
    dispValidateCondition,
    onDiscard,
    onToggleAllOrder,
    onDeleteTab,
    onSelectTab,
  } = useReturnOrder({ onSave });

  if (fetchAjaxStatus === "pending") {
    return (
      <div className={`${styles.loaderHeight}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="position-relative">
      {updateAjaxStatus === "pending" ? (
        <div className={`${styles.loadingBlock}`}></div>
      ) : (
        ""
      )}
      <Tab.Container
        id="left-tabs-example"
        activeKey={selectedOrderTypeId ?? ""}
        onSelect={onSelectTab}
      >
        <Row>
          <Col>
            <Nav
              variant="tabs"
              className={`d-flex align-items-center border-0 ${styles.tabSectionWrapper} `}
            >
              {antiRefundOrderTypes.map((order, idx) => {
                return (
                  <Nav.Item
                    role="button"
                    key={"tabItem_" + order.orderTypeId}
                  >
                    <Nav.Link
                      eventKey={order.orderTypeId}
                      className={`py-1 px-3  text-dark shadow-sm  ${styles.tabStyles}`}
                      style={{
                        background:
                          selectedOrderTypeId !== null
                            ? selectedOrderTypeId === order.orderTypeId
                              ? order.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#FFFFFF 0% 0% no-repeat padding-box "
                              : order.errorCount > 0
                                ? "#FFDCDC 0% 0% no-repeat padding-box"
                                : "#f5f5f5 0% 0% no-repeat padding-box"
                            : "",
                      }}
                    >
                      <AutomationTab idx={idx}>
                        <TabName
                          id={order.orderTypeId as string}
                          name={order.orderTypeName}
                          updateType={dispUpdateOrderType}
                          pushCurrentHash={dispPushCurrentHashForSelectedTab}
                        />

                        <TabError errorCount={order.errorCount} />

                        <div className="ms-1">
                          <CloseButtonForTab
                            index={idx}
                            name={order.orderTypeName}
                            hasStaticTab={false}
                            onDelete={() =>
                              onDeleteTab(order.orderTypeId as string)
                            }
                          />
                        </div>
                      </AutomationTab>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}

              <AddMore
                AddTab={dispAddTab}
                text=" Add custom return method type"
                selectedTabId={selectedOrderTypeId}
                validateTabType={dispValidateCondition}
              />
            </Nav>

            <Tab.Content
              className={`shadow ${styles.contentStyles} pt-4 px-4 ${
                initialHash &&
                currentHash &&
                initialHash === currentHash &&
                styles.noDiscardAndSave
              }`}
            >
              {antiRefundOrderTypes.map((order, idx) => {
                const allSelectedConditionNames: string[] =
                  order.conditions.map((condition) => condition.variableName);

                const filteredConditions = conditionOptions.filter(
                  (condition) => !allSelectedConditionNames.includes(condition),
                );

                return (
                  <Tab.Pane
                    key={"tab_" + order.orderTypeId}
                    eventKey={order.orderTypeId}
                    unmountOnExit={true}
                    onEnter={dispValidateCondition}
                    onEntered={dispValidateCondition}
                    onExited={dispValidateCondition}
                  >
                    <ConditionGroup
                      key={order.orderTypeId + "default_condition"}
                      title=""
                      initialShow={true}
                      disableHide={true}
                      errorMessage={order.error}
                    >
                      {/* Conditions Section  */}
                      <ContentHeading text="Apply anti-refund strategies on " />
                      {/* Conditions Section  */}
                      {idx !== 0 ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <Form.Label
                              htmlFor={`${order.orderTypeId}`}
                              className={`mb-0 me-2 ${styles.applyFormCheck}`}
                            >
                              Apply to all orders
                            </Form.Label>
                            <Form.Check
                              type="switch"
                              id={`${order.orderTypeId}`}
                              className={`mb-0 mt-1`}
                              role="button"
                              checked={order.applyToAllOrders}
                              onChange={(e) => onToggleAllOrder(e, order)}
                              disabled={idx === 0}
                            />
                          </div>
                        </div>
                      ) : null}

                      {order.applyToAllOrders ? (
                        <ConditionGroup
                          key={order.orderTypeId + "default_condition_1"}
                          title=""
                          initialShow={true}
                          disableHide={true}
                        >
                          <VariableSelect
                            key={order.orderTypeId + "main_condition"}
                            id={order.orderTypeId as string}
                            condition={{} as any}
                            variablesName={defaultOptions}
                            pushCurrentHash={dispPushCurrentHashForSelectedTab}
                            updateConditon={() => {}}
                            selectedVariableName={""}
                            showPlaceholder={false}
                          />
                        </ConditionGroup>
                      ) : (
                        <>
                          {order.conditions.length === 0 ? (
                            <ConditionGroup
                              key={order.orderTypeId + "other_condition"}
                              title=""
                              initialShow={true}
                              disableHide={true}
                            >
                              <VariableSelect
                                id={order.orderTypeId as string}
                                condition={{} as any}
                                variablesName={filteredConditions}
                                pushCurrentHash={
                                  dispPushCurrentHashForSelectedTab
                                }
                                updateConditon={dispAddCondition}
                                selectedVariableName={""}
                                showPlaceholder={true}
                                errorMessage={order.error}
                              />
                            </ConditionGroup>
                          ) : null}

                          {order.conditions.map((condition, idx) => {
                            return (
                              <Condition
                                key={"condition_" + condition.id}
                                id={order.orderTypeId as string}
                                condition={condition}
                                deleteCondition={dispDeleteCondition}
                                idx={idx}
                                validateCondition={dispValidateCondition}
                              >
                                <VariableSelect
                                  id={order.orderTypeId as string}
                                  condition={condition}
                                  variablesName={filteredConditions}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={
                                    dispUpdateConditionForVariables
                                  }
                                  selectedVariableName={condition.variableName}
                                />
                                <OperatorSelect
                                  condition={condition}
                                  id={order.orderTypeId as string}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  updateConditon={dispUpdateConditonForOperator}
                                  operatorNames={
                                    operators[condition.variableName]
                                  }
                                  selectedOperator={condition.operator}
                                />

                                <Values
                                  id={order.orderTypeId as string}
                                  condition={condition}
                                  updateCondition={dispUpdateConditionForValues}
                                  pushCurrentHash={
                                    dispPushCurrentHashForSelectedTab
                                  }
                                  useNewComponent={true}
                                />
                              </Condition>
                            );
                          })}
                        </>
                      )}
                      {idx !== 0 ? (
                        <AddConditionButton
                          AddCondition={dispAddCondition}
                          id={order.orderTypeId as string}
                          isAddBtnEnabled={
                            isAddBtnEnabled && !order.applyToAllOrders
                          }
                        />
                      ) : null}
                    </ConditionGroup>
                    {/* Refund Request */}
                    {reasons.map((reasonData) => {
                      return (
                        <ReasonRequestBox
                          reasonData={reasonData}
                          question="question12"
                        />
                      );
                    })}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>

        <DiscardAndSave
          onSave={dispPostConfig}
          showLoading={updateAjaxStatus === "pending"}
          onDiscard={onDiscard}
          saveFailed={saveFailed}
        />
      </Tab.Container>
    </div>
  );
};

export default ReturnOrder;
