import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AJAXSTATUS } from "src/globals/constants";
import { Phone } from "../editCustomerDetails/editCustomerDetails.declarations";
import shopifySidebarExtraReducers from "./shopifySidebar.extraReducers";
import shopifySidebarReducers from "./shopifySidebar.reducers";
import shopifySidebarThunks from "./shopifySidebar.thunks";

export const fetchShopifySidebarData = createAsyncThunk(
  "shopifySidebar/fetchShopifySidebarData",
  shopifySidebarThunks.fetchShopifySidebarDataThunk,
);
export const fetchCustomerData = createAsyncThunk(
  "shopifySidebar/fetchCustomerData",
  shopifySidebarThunks.fetchCustomerDataThunk,
);

export interface ICustomerChannels {
  id: string | number;
  name: string;
  value: string;
}

/* 
{
    "id": 2,
    "name": "Preet",
    "email": "preeti@zinosi.com",
    "customerProfileImageId": null,
    "customerProfileImageUrl": null,
    "channels": [
        {
            "id": "13",
            "name": "Email",
            "value": "preeti@zinosi.com"
        }
    ],
    "contacts": {
        "otherEmails": [
            "sss@g.com"
        ],
        "phone": [],
        "instagram": [],
        "facebook": [],
        "twitter": []
    }
}
*/

export interface TimeLineSubEvent {
  datetime: string | null;
  type: string;
  id: number | null;
  description?: string;
  listOptions?: Array<string>;
  url?: string;
  name: string;
  bgColor?: string;
  icon?: string;
}

export interface EventDetail {
  datetime: string | null;
  type: string;
  description: string;
  name: string;
  icon: string;
  eventType: string;
  ticketId: number | null;
  subEvents: TimeLineSubEvent[];
  id: string | number | null;
  position: "top" | "bottom";
  bgColor: string;
  cursor?: string | null;
  url?: string;
}

export interface TimelineEvent {
  month: string;
  label: string;
  logins: number;
  details: EventDetail[];
}

export interface Timeline {
  year: number;
  label: string;
  events: {
    minor: TimelineEvent[];
    major: TimelineEvent[];
    attribute: TimelineEvent[];
  };
  bgColor: string | null;
  position: "top" | "bottom";
}

export interface TimelineData {
  timeline: Timeline[];
  last_updated: string;
  current_view?: "monthly" | "yearly" | "weekly" | "daily"
}

export interface ICustomerData {
  id: number | string;
  name: string;
  email: string;
  isPublicAttachmentUrl?: boolean;
  customerProfileImageUrl?: string;
  profileImageId?: string;
  contacts?: {
    otherEmails: Array<string>;
    phone: Phone[];
    whatsapp: Phone[]; //Adding whatsapp field
    instagram: Array<string>;
    facebook: Array<string>;
    twitter: Array<string>;
  };
  channels: Array<ICustomerChannels>;
  primaryEmailEditable?: boolean;
  timelineInfo?: TimelineData;
}

export interface IShopifyAmountSpent {
  amount: string;
  currencyCode: string;
}

export interface IShopifyCustomer {
  id: number | string;
  name: string;
  email: string;
  phone: null | string;
  totalAmountSpent: IShopifyAmountSpent;
  totalOrdersCount: number;
}

export interface IShopifyItemImg {
  url: string;
  id: string;
}

export interface IShopifyPricing {
  presentmentMoney: IShopifyAmountSpent;
}

export interface IShopifyOrderItems {
  itemId: string;
  itemName: string;
  quantity: number;
  sku: string;
  price: IShopifyPricing;
  image: IShopifyItemImg;
}

export interface IShopifyOrders {
  orderId: string | number;
  orderName: string;
  createdAt: string;
  paymentStatus: string;
  fulfillmentStatus: string;
  orderItems: Array<IShopifyOrderItems>;
  totalAmount: IShopifyAmountSpent; // totalAmountSpent
  totalItemCount: number; // totalOrdersCount
}

export interface IShopifyData {
  customer?: IShopifyCustomer;
  orders: { [orderId: string | number]: IShopifyOrders };
  orderIds: Array<string | number>;
  totalOrders: number;
}

export interface IShopifySidebar {
  customerData: ICustomerData;
  shopifyData: IShopifyData;
  customerDataStatus: AJAXSTATUS;
  shopifyDataStatus: AJAXSTATUS;
}

export const initialState: IShopifySidebar = {
  customerData: {
    id: 0,
    name: "",
    email: "",
    channels: [],
  },
  shopifyData: {
    orders: {},
    orderIds: [],
    totalOrders: 0,
  },
  customerDataStatus: "idle",
  shopifyDataStatus: "idle",
};

export const shopifySidebarSlice = createSlice({
  name: "shopifySidebar",
  initialState,
  reducers: shopifySidebarReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchShopifySidebarData.fulfilled,
      shopifySidebarExtraReducers.fetchShopifySidebarDataFulfilled,
    );
    builder.addCase(
      fetchShopifySidebarData.rejected,
      (state: IShopifySidebar) => {
        state.shopifyDataStatus = "rejected";
      },
    );
    builder.addCase(
      fetchShopifySidebarData.pending,
      (state: IShopifySidebar) => {
        state.shopifyDataStatus = "pending";
      },
    );

    builder.addCase(
      fetchCustomerData.fulfilled,
      shopifySidebarExtraReducers.fetchCustomerDataFulfilled,
    );
    builder.addCase(fetchCustomerData.rejected, (state: IShopifySidebar) => {
      state.customerDataStatus = "rejected";
    });
    builder.addCase(fetchCustomerData.pending, (state: IShopifySidebar) => {
      state.customerDataStatus = "pending";
    });
  },
});

export const {
  resetShopifySidebar,
  setCustomerId,
  setCustomerProfileImg,
  setCustomerName,
} = shopifySidebarSlice.actions;

export default shopifySidebarSlice.reducer;
