import { axiosJSON } from "src/globals/axiosEndPoints";
import { findAutomationContextFromURLPath } from "src/utils/utils";

export interface IUpdateReturnIntegrationParams {
  integrationId: string;
  publish: boolean;
  brandId?: string;
}

async function publishReturnIntegrationService(
  payload: IUpdateReturnIntegrationParams,
) {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/${automationContext}/publish`,
    payload,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some error occurred while updating",
    );
  }

  return true;
}

export default publishReturnIntegrationService;
