import { Modal, Spinner } from "react-bootstrap";
import errorIcon from "src/assets/images/errorCircle.png";
import styles from "./DeleteSeqModal.module.scss";
import Lottie from "lottie-light-react";
import LoaderAnimation from "src/assets/animation/Loading.json";
import successMsg from "src/assets/images/successMsg.gif";
const DeleteSeqModal = ({
  showModal,
  showLoader,
  handleDelete,
  onHide,
  heading,
  subHeading,
}: {
  showModal: boolean;
  showLoader: boolean;
  handleDelete: () => void;
  onHide: () => void;
  heading: string;
  subHeading?: string;
}) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      className={`${styles.backDropClass}`}
      id="MainSBPricingErrorModal"
      centered
    >
      <div>
        <div className="px-2">
          <div className={`${styles.topIcon} mb-4 mx-auto`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="56"
              viewBox="0 0 64 56"
            >
              <g
                id="Group_17419"
                data-name="Group 17419"
                transform="translate(0.132)"
              >
                <path
                  id="Polygon_38"
                  data-name="Polygon 38"
                  d="M27.678,7.428a5,5,0,0,1,8.643,0L59.628,47.486A5,5,0,0,1,55.306,55H8.694a5,5,0,0,1-4.322-7.514Z"
                  transform="translate(-0.132)"
                  fill="#b70101"
                />
                <text
                  id="_"
                  data-name="!"
                  transform="translate(31.868 45)"
                  fill="#fff"
                  font-size="31"
                  font-family="Poppins-Medium, Poppins"
                  font-weight="500"
                >
                  <tspan
                    x="-4"
                    y="0"
                  >
                    !
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
          <div className={`${styles.title} mx-auto`}>{heading}</div>
          {subHeading ? (
            <span className={`mb-4 ${styles.subtitle}`}>{subHeading}</span>
          ) : null}
          <div className="d-flex justify-content-between mb-2 px-2 pt-1">
            <button
              className={`px-2 me-4 ${styles.cancelBtn}`}
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className={`px-2 ${styles.checkBtn} d-flex justify-content-center align-items-center`}
              onClick={handleDelete}
            >
              {showLoader && (
                <Spinner
                  size="sm"
                  className="me-2"
                />
              )}
              <span>Delete</span>
            </button>
          </div>
        </div>
        {/* for loading  */}
        <div className={`my-4 ${styles.loading} d-none`}>
          <Lottie animationData={LoaderAnimation} />
        </div>
        <div className="text-center d-none">
          <img
            src={successMsg}
            alt={"success"}
            className={`mb-4 ${styles.successImg}`}
          />
          <p className={`mb-4 ${styles.successText}`}>
            Sequence step deleted successfully!
          </p>
          <button
            className={`px-2 mb-3 ${styles.okayBtn} d-flex justify-content-center align-items-center`}
          >
            <span>Okay</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSeqModal;
