import UserAvatar from "src/components/UserAvatar";
import styles from "./SuccessMessage.module.scss";
import { BugResolve } from "src/services/BugReport/updateBugResolution";
import { dateFormate } from "../Children/dateFormate";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";
import AxiosImg from "src/components/AxiosImg";

const SuccessMessage = ({
  bugResolved,
  bugIsResolved,
  setTimeLineStep,
}: {
  bugResolved: BugResolve | undefined;
  setTimeLineStep: React.Dispatch<React.SetStateAction<number>>;
  bugIsResolved: {
    name: string | undefined;
    resolvedDate: string;
    profileImg?: string | null | undefined;
  } | null;
}) => {
  // const resolvedDate = useUTCToUserTime({
  //   timeInUTC: bugIsResolved
  //     ? bugIsResolved.resolvedDate
  //     : bugResolved?.bugResolveDateGmt ?? "",
  // });
  const resolvedDate = (() => {
    try {
      return new Date(
        (bugIsResolved
          ? bugIsResolved.resolvedDate
          : bugResolved?.bugResolveDateGmt ?? "") + "Z",
      );
    } catch (error) {
      console.error("Invalid resolved date:", error);
      return new Date(); // fallback to current date
    }
  })();
  return (
    <div className={`mt-5 pt-3 ${styles.successWrapper}`}>
      <div className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45.875"
          height="45.875"
          viewBox="0 0 45.875 45.875"
        >
          <path
            id="Icon_awesome-check-circle"
            data-name="Icon awesome-check-circle"
            d="M46.438,23.5A22.938,22.938,0,1,1,23.5.563,22.937,22.937,0,0,1,46.438,23.5ZM20.847,35.645,37.865,18.627a1.48,1.48,0,0,0,0-2.093l-2.093-2.093a1.48,1.48,0,0,0-2.093,0L19.8,28.32l-6.48-6.48a1.48,1.48,0,0,0-2.093,0L9.135,23.933a1.48,1.48,0,0,0,0,2.093l9.619,9.619a1.48,1.48,0,0,0,2.093,0Z"
            transform="translate(-0.563 -0.563)"
            fill="#56cf22"
          />
        </svg>
        <p className={`mt-3 pt-1 text-center ${styles.heading}`}>
          Issue is marked resolved!
        </p>
        <span className={`${styles.subHeading}`}>
          The issue has been reviewed and resolved by
        </span>
        <div className="d-flex justify-content-center mt-3">
          <div>
            {/* bug is either resolved by customer or agent */}
            {bugIsResolved && bugIsResolved?.profileImg ? (
              <AxiosImg
                url={bugIsResolved?.profileImg}
                className={`${styles.userImg}`}
              />
            ) : (
              <UserAvatar
                name={
                  bugIsResolved
                    ? bugIsResolved.name
                    : bugResolved?.bugResolveAgentDetails.agentName
                }
                size={21}
              />
            )}
          </div>

          <div className="ps-1">
            <div>
              <span className={` ${styles.cName}`}>
                {bugIsResolved
                  ? bugIsResolved.name
                  : bugResolved?.bugResolveAgentDetails.agentName}
              </span>
              <span className={`ps-2 ${styles.time}`}>
                <span>
                  {resolvedDate
                    ? dateFormate(resolvedDate.toString(), true)
                    : "Resolved date not available"}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 pt-3">
          <button
            className={`px-4 ${styles.resolveBtn}`}
            onClick={() => setTimeLineStep((prevState) => prevState - 1)}
          >
            <span className="pe-1">
              <i className="fa-solid fa-angle-left"></i>
            </span>{" "}
            Back
          </button>
          {/* <button
            className={`px-4 ${styles.resolveBtn} ${styles.disabledBtn}`}
            disabled
          >
            Next
            <span className="ps-1">
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
