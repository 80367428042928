import { useInfiniteQuery } from "@tanstack/react-query";
import { getConditionOptionsService } from "src/services/CustomerSegments/getConditionOptions.service";

interface UseConditionOptionsProps {
  segmentType: string;
  initialPayload: GetConditionOptionsPayload;
}

interface GetConditionOptionsPayload {
  start?: number;
  limit?: number;
  isDefaultRequired: boolean;
  segmentId?: string;
}

const LIMIT = 10;

export const useConditionOptions = ({
  segmentType,
  initialPayload,
}: UseConditionOptionsProps) => {
  const query = useInfiniteQuery(
    ["conditionOptions", segmentType, initialPayload],
    ({ pageParam = 0 }) =>
      getConditionOptionsService({
        segmentType,
        payload: {
          ...initialPayload,
          start: pageParam * LIMIT,
          limit: LIMIT,
        },
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const hasMoreDefault = lastPage.defaultConditions?.length === LIMIT;
        const hasMoreCustom = lastPage.customeConditions?.length === LIMIT;
        return hasMoreDefault || hasMoreCustom ? allPages.length : undefined;
      },
    },
  );

  // Map options from fetched pages into a single array
  const options = query.data?.pages.flatMap((page) => [
    ...(page.defaultConditions || [])
      .filter((opt) => opt.typeKey !== "timeline")
      .map((opt) => ({
        label: opt.fieldName,
        value: opt.fieldKey,
      })),
    ...(page.customeConditions || [])
      .filter((opt) => opt.typeKey !== "timeline")
      .map((opt) => ({
        label: opt.fieldName,
        value: opt.fieldKey,
      })),
  ]);
  const typeOptions = query.data?.pages.flatMap((page) => [
    ...(page.defaultConditions || []).map((opt) => ({
      label: opt.typeKey,
      value: opt.type,
    })),
    ...(page.customeConditions || []).map((opt) => ({
      label: opt.typeKey,
      value: opt.type,
    })),
  ]);
  const combinedOtions = query.data?.pages.flatMap((page) => [
    ...(page.defaultConditions || []).map((opt) => ({
      name: opt.fieldName,
      typeKey: opt.typeKey,
      type: opt.type,
      attributeId: opt.fieldKey,
    })),
    ...(page.customeConditions || []).map((opt) => ({
      name: opt.fieldName,
      typeKey: opt.typeKey,
      type: opt.type,
      attributeId: opt.fieldKey,
    })),
  ]);
  return {
    attributeOptions: options,
    attributeTypeOptions: typeOptions,
    attributeCombinedOptions: combinedOtions,
    attributeOptionFetchNextPage: query.fetchNextPage,
    attributeOptionhasNextPage: query.hasNextPage,
    isFetching: query.isFetching,
  };
};
