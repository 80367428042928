import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import SelectSegmentTable, {
  TOTAL_COUNT_PLACEHOLDER_TEXT,
} from "src/components/SelectSegmentTable/SelectSegmentTable";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import UpdateSegmentDetails from "src/routes/CustomerSegments/children/UpdateSegmentDetails/UpdateSegmentDetails";
import { CampaignStepData } from "src/services/Campaign/addCampaignStep.service";
import {
  CampaignData,
  CampaignEmail,
  ECampaignStatus,
  EmailTemplateData,
} from "src/services/Campaign/getCampaignById";
import { useAppSelector } from "src/store/store";
import { replaceSpanWithKeyB } from "src/utils/utils";
import {
  CampaignActionType,
  CampaignContext,
  useCampaignTemplateCreate,
} from "../CampainContext/CampainContext";
import { extractHeading } from "src/utils/unlayerEditorUtils";
import { MergeTags } from "state/types/types";
import useActiveCampaignEmail, {
  useDeleteCampaign,
  useUpdateCampaign,
  useUpdateCampaignEmail,
} from "../hooks/useCampaignData";
import styles from "./CampaignWrapper.module.scss";
import ActionDropdown from "./Children/ActionDropdown/ActionDropdown";
import AddStepModal from "./Children/AddStepModal/AddStepModal";
import Attributes from "./Children/Attributes/Attributes";
import CampaignBox from "./Children/CampaignBox/CampaignBox";
import ConfigureEmailTemplate from "./Children/ConfigureEmailTemplate/ConfigureEmailTemplate";
import SelectTemplateModal from "./Children/SelectTemplateModal/SelectTemplateModal";
import { useNavigate, useParams } from "react-router-dom";
export interface Campaign {
  id: number;
  description: string;
  heading: string;
  triggerAfter: number;
  channel: string;
  emailTemplateData?: EmailTemplateData;
}

// Timeout reference for debouncing
let nameTimeout: NodeJS.Timeout;
let emailTimeout: NodeJS.Timeout;

interface Props extends CampaignData {
  refetchCampaign: () => void;
}

const CampaignWrapper = ({
  id,
  name,
  emails,
  brand,
  refetchCampaign,
  userSegment,
  status,
  trigger,
}: Props) => {
  const [activeCampaignEmail, setActiveCampaignEmail] =
    useState<CampaignEmail | null>(null);
  const [campaignName, setCampaignName] = useState(name);
  const [isEditing, setIsEditing] = useState(false);
  const [showSegmentEdit, setShowSegmentEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [campaignStepData, setCampaignStepData] = useState<CampaignStepData>();
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [campaignNameError, showCampaignNameError] = useState(false);
  const [state, dispatch] = useContext(CampaignContext);
  const { data, isLoading, error, stepId } = useActiveCampaignEmail({
    activeCampaignEmail,
    setActiveCampaignEmail,
  });
  const { tenantId } = useParams();

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const [contextValue, CampaignTemplateProvider] = useCampaignTemplateCreate({
    brand: brand,
  });

  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const commonVariablesList = useMemo(() => {
    return {
      first_name: {
        name: "First Name",
        value: "{{first_name}}",
        sample: "John",
      },
      brand_name: {
        name: "Brand Name",
        value: "{{brand}}",
        sample: brand?.name ?? "acme",
      },
      brand_email: {
        name: "Brand Email",
        sample: brand?.email ?? "example@example.com",
        value: "{{brand_email}}",
      },
    } as MergeTags;
  }, [brand]);

  const commonVariableIdsList: string[] = ["1", "2"];
  const updateCampaign = useUpdateCampaign();
  const updateCampaignEmail = useUpdateCampaignEmail();
  const deleteCampaign = useDeleteCampaign();
  const navigate = useNavigate();

  const handleCampaignClick = useCallback(
    (campaign: CampaignEmail) => {
      dispatch({
        type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID,
        payload: campaign.id,
      });
      setActiveCampaignEmail(campaign);
      setIsEditing(false);
      if (tenantId) {
        navigate(`/campaigns/admin/${tenantId}/${id}/step/${campaign.id}`);
      } else {
        navigate(`/campaigns/${id}/step/${campaign.id}`);
      }
    },
    [tenantId, id],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value;
      setCampaignName(name);
      if (name?.trim().length > 0) {
        // Clear the previous timeout
        clearTimeout(nameTimeout);

        // Set a new timeout
        nameTimeout = setTimeout(() => {
          updateCampaign.mutate({ campaignId: id, name });
        }, 500);
      } else {
        showCampaignNameError(true);
      }
    },
    [id, updateCampaign],
  );

  // Function to handle the click event
  const handleClick = useCallback(() => {
    // Clear the previous timeout
    clearTimeout(nameTimeout);

    if (campaignName?.trim().length > 0) {
      // Set a new timeout to update the campaign name after a delay
      nameTimeout = setTimeout(() => {
        updateCampaign.mutate({ campaignId: id, name: campaignName });
      }, 500);
    } else {
      showCampaignNameError(true);
    }
  }, [campaignName, id, updateCampaign]);

  const handleUpdateStatus = useCallback(
    async (status: ECampaignStatus) => {
      await updateCampaign.mutateAsync({ campaignId: id, status });
    },
    [id, updateCampaign],
  );

  const handleEmailModalDataChange = (payload: Partial<EmailTemplateData>) => {
    if (activeCampaignEmail && activeCampaignEmail.emailTemplateData) {
      // const heading = payload.design ? extractHeading(payload.design) : "";
      let updatedCampaign = {
        ...activeCampaignEmail,
        emailTemplateData: {
          ...activeCampaignEmail.emailTemplateData,
          ...payload,
        },
      };
      setActiveCampaignEmail(updatedCampaign);
      updatedCampaign = {
        ...updatedCampaign,
        emailTemplateData: {
          ...updatedCampaign.emailTemplateData,
        },
      };

      const htmlElement = document.createElement("html");
      htmlElement.innerHTML = updatedCampaign.emailTemplateData.html + "";

      // Remove all <style> tags
      const styleElements = htmlElement.querySelectorAll("style");
      styleElements.forEach((styleElement) => {
        styleElement.parentNode?.removeChild(styleElement);
      });
      // Remove all newline characters
      const truncatedText = htmlElement.innerText
        ?.replace(/\n/g, " ")
        .replace(/\s\s+/g, " ");

      // Clear the previous timeout
      // clearTimeout(emailTimeout);
      // Set a new timeout
      // emailTimeout = setTimeout(() => {
      updateCampaignEmail.mutate(
        {
          campaignId: id,
          id: updatedCampaign.id,
          ...updatedCampaign.emailTemplateData,
          templateInnerText: truncatedText,
        },
        {
          onSuccess: () => {
            // Set the mutation status to idle after 2 seconds
            setTimeout(() => {
              updateCampaignEmail.reset();
            }, 2000);
          },
        },
      );
      // }, 500);
    }
  };

  const onBusinessClick = useCallback(() => {
    setCampaignStepData(undefined);
    setShowTemplate(true);
  }, []);

  const handleDeleteCampaign = useCallback(async () => {
    await deleteCampaign.mutateAsync({ campaignId: id });
  }, [deleteCampaign, id]);

  useEffect(() => {
    if (!stepId) {
      setActiveCampaignEmail(null);
      dispatch({
        type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID,
        payload: null,
      });
    } else {
      dispatch({
        type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID,
        payload: stepId,
      });
    }
  }, [stepId]);
  useEffect(() => {
    return () => {
      dispatch({
        type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID,
        payload: null,
      });
    };
  }, []);

  return (
    <div
      className={`mt-3 ${styles.campaignWrapper} ${
        stepId && styles.mobCampWrapper
      }`}
    >
      {!stepId ? (
        <div className="d-flex justify-content-between mb-3 px-3">
          <div className="w-100">
            <h4 className={`${styles.detailHead}`}>Campaign details:</h4>
            <div className={`${styles.campaignDetails}`}>
              <h5
                className={`d-flex ${styles.campaignName} d-flex flex-column flex-md-row align-items-start`}
              >
                <span className="pe-1"> Campaign name - </span>
                {isEditing ? (
                  <div>
                    <div className={`position-relative`}>
                      <input
                        type="text"
                        value={campaignName}
                        onChange={handleInputChange}
                        onBlur={() => {
                          if (campaignName?.trim().length !== 0)
                            setIsEditing(false);
                        }}
                        className={`form-control ps-2 py-1 ${
                          styles.editInput
                        } ${
                          campaignNameError &&
                          campaignName?.trim().length === 0 &&
                          "border border-danger"
                        }`}
                        autoFocus
                      />
                      <div
                        className={`${styles.checkBox} cursor-pointer ${
                          campaignNameError &&
                          campaignName?.trim().length === 0 &&
                          styles.newCheckBox
                        }`}
                        onClick={handleClick}
                      >
                        <span>
                          <i className="fa-solid fa-check"></i>
                        </span>
                      </div>
                    </div>
                    {campaignNameError && campaignName?.trim().length === 0 && (
                      <div className="text-danger mt-1">
                        {/* Custom error message here */}
                        Campaign name is required.
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <span>{campaignName}</span>
                    <span
                      className={`${styles.edit} ms-1 cursor-pointer`}
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </span>
                  </div>
                )}
              </h5>
              {currentUserData?.ui_visibility?.includes("user_segment_view") &&
                userSegment && (
                  <div>
                    <h6 className={`${styles.campaignSegment}`}>
                      User Segment -
                      <span className={`ps-1 ${styles.type}`}>
                        {userSegment.name}
                      </span>
                      <span
                        className={`${styles.edit} cursor-pointer`}
                        onClick={() => setShowSegmentEdit(true)}
                      >
                        {" "}
                        Edit
                      </span>
                      <Modal
                        backdropClassName={`${styles.userModalBack}`}
                        show={showSegmentEdit}
                        onHide={() => setShowSegmentEdit(false)}
                        dialogClassName={`${styles.userModalDialog}`}
                        contentClassName={`${styles.userModalContent}`}
                      >
                        <UpdateSegmentDetails
                          onHideUpdateSegmentModal={() =>
                            setShowSegmentEdit(false)
                          }
                          segmentDataForUpdate={{
                            action: "update",
                            segmentType: "userSegment",
                            segmentId: userSegment.id + "",
                          }}
                          onSubmit={() => {
                            refetchCampaign();
                            setIsRefetch(true);
                          }}
                        />
                      </Modal>
                    </h6>

                    {userSegment.keyAttributes && (
                      <div className={`mb-2 ${styles.attributeBox}`}>
                        <h6 className={`${styles.attrHead}`}>
                          Key attributes:
                        </h6>
                        <Attributes keyAttributes={userSegment.keyAttributes} />
                      </div>
                    )}
                  </div>
                )}
              {trigger && (
                <h5
                  className={`d-flex align-items-center mb-1 ${styles.campaignName}`}
                >
                  <span className="pe-1"> Trigger</span>
                  <span className={`${styles.triggerText}`}>
                    - {trigger?.value}
                  </span>
                  <span
                    className={`cursor-pointer ${styles.edit} ms-1`}
                    onClick={() => setErrorModal(true)}
                  >
                    Edit
                  </span>
                  <ErrorModal
                    showModal={errorModal}
                    onHide={() => setErrorModal(false)}
                    back={() => setErrorModal(false)}
                    retry={() => {
                      setShowLoading(true);
                      setTimeout(() => {
                        setShowLoading(false);
                      }, 1500);
                    }}
                    showLoader={showLoading}
                  />
                </h5>
              )}

              {currentUserData?.ui_visibility?.includes("user_segment_view") &&
                userSegment && (
                  <>
                    <div className={`${styles.devider}`}></div>
                    <SelectSegmentTable
                      activeSegmentId="allUsers"
                      activeSegmentType="userSegment"
                      onHide={() => {}}
                      segmentIds={[userSegment.id + ""]}
                      headerText={`Preview all ${TOTAL_COUNT_PLACEHOLDER_TEXT} target users who are in your audience right now.`}
                      columns={
                        (userSegment.keyAttributes
                          ?.map((val) => val.columnKey)
                          .filter((val) => typeof val === "string") ??
                          []) as string[]
                      }
                      extraHorizontalSpacing={false}
                      setIsRefetch={setIsRefetch}
                      isRefetch={isRefetch}
                    />
                  </>
                )}
            </div>
          </div>
          {/* <button
            className={`${styles.businessBtn} d-none`}
            onClick={onBusinessClick}
          >
            Business 1
            <span className="ps-1">
              <i className={`fa-solid fa-chevron-down`}></i>
            </span>
          </button> */}
        </div>
      ) : null}
      <div className={`${activeCampaignEmail && styles.shadowBox}`}>
        {stepId ? (
          <CampaignTemplateProvider value={contextValue}>
            <ConfigureEmailTemplate
              onHide={() => {
                dispatch({
                  type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID,
                  payload: null,
                });
                setActiveCampaignEmail(null);
              }}
              referenceId={parseInt(stepId + "")}
              data={activeCampaignEmail?.emailTemplateData}
              handleEmailModalDataChange={handleEmailModalDataChange}
              variablesList={commonVariablesList}
              variableIdsList={commonVariableIdsList}
              companyName={brand?.name ?? ""}
              onBusinessClick={onBusinessClick}
              status={activeCampaignEmail?.status ?? "loading"}
              saveLoader={updateCampaignEmail.status}
              emailSubject={activeCampaignEmail?.heading}
              isLoading={isLoading && !activeCampaignEmail}
            />
          </CampaignTemplateProvider>
        ) : (
          emails.map((campaign, index) => (
            <div key={campaign.id}>
              <CampaignBox
                {...campaign}
                campaignId={id}
                handleCampaignClick={() => handleCampaignClick(campaign)}
              />
            </div>
          ))
        )}
      </div>
      {!stepId ? (
        <div className="d-flex justify-content-between align-items-center mb-5 px-3">
          <div
            className="d-flex justify-content-start align-items-center cursor-pointer max-content-width"
            onClick={() => setShowModal(true)}
          >
            <div className={`${styles.addBox}`}>
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>
            </div>
            <span className={`ps-2 ${styles.addText}`}>Add step</span>
          </div>
          <div className="d-flex align-items-center">
            <ActionDropdown
              handleUpdateStatus={handleUpdateStatus}
              status={status}
              campaignName={campaignName}
              handleDelete={handleDeleteCampaign}
            />
            <button
              className={`px-2 ms-2 ${styles.approveBtn}`}
              disabled={status === ECampaignStatus.Published}
              onClick={() => handleUpdateStatus(ECampaignStatus.Published)}
            >
              {status === ECampaignStatus.Published ? "Published" : "Publish"}
            </button>
          </div>
        </div>
      ) : null}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.stepModalDialog}`}
        contentClassName={`${styles.stepModalContent}`}
        centered={true}
      >
        <AddStepModal
          campaignId={id}
          onHide={() => setShowModal(false)}
          onSave={(val) => {
            setShowModal(false);
            setCampaignStepData(val);
            setShowTemplate(true);
          }}
        />
      </Modal>
      <Modal
        show={showTemplate}
        onHide={() => setShowTemplate(false)}
        backdropClassName={`${styles.modalBack}`}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        <SelectTemplateModal
          campaignStepData={campaignStepData}
          onHide={() => setShowTemplate(false)}
          onSave={refetchCampaign}
          variables={[] as any}
          setShowErrorModal={setShowError}
          setShowTemplateModal={setShowTemplate}
        />
      </Modal>
      <ErrorModal
        showModal={showError}
        onHide={() => {
          setShowError(false);
          setShowTemplate(true);
        }}
        back={() => {
          setShowError(false);
          setShowTemplate(true);
        }}
        retry={() => {
          setShowLoading(true);
          setTimeout(() => {
            setShowLoading(false);
          }, 1500);
        }}
        showLoader={showLoading}
      />
    </div>
  );
};

export default CampaignWrapper;
