import { ISegmentUserData } from "src/services/CustomerSegments/getSegmentUserById.service";
import styles from "./UserMetrics.module.scss";

const UserMetrics = ({
  metricsData,
}: {
  metricsData: ISegmentUserData["metrics"];
}) => {
  const { supportRequestCount, subscriptionData, totalRevenue } = metricsData;

  return (
    <>
      <div className={`${styles.requestBox} mb-2 p-2`}>
        <p className={`${styles.heading} mb-1`}>Total support requests</p>
        <span className={`${styles.count}`}>{supportRequestCount}</span>
      </div>

      {totalRevenue && (
        <div className={`${styles.requestBox} mb-2 p-2`}>
          <p className={`${styles.heading} mb-1`}>Total revenue generated</p>
          <span className={`${styles.count}`}>{`${
            totalRevenue.amount < 0 ? "-" : ""
          }${totalRevenue.currencyCode}${Math.abs(totalRevenue.amount)}`}</span>
        </div>
      )}

      {subscriptionData && (
        <div className={`${styles.requestBox} mb-2 p-2`}>
          <p className={`${styles.heading} mb-1`}>Subscription preferences</p>
          <div className="form-check d-flex align-items-center mb-2">
            <input
              className={`form-check-input ${styles.checkBox}`}
              type="checkbox"
              checked={subscriptionData.unsubscribedFromAllEmails}
              id="unsubscribedFromAllEmailsCheck"
            />
            <label
              className={`form-check-label ms-2 ${styles.checkLabel}`}
              htmlFor="unsubscribedFromAllEmailsCheck"
            >
              Unsubscribed from all emails
            </label>
          </div>
          <div className={`${styles.issueBox}`}>
            <h6 className="mb-0">Email issues</h6>
            <span>
              {subscriptionData.emailIssues?.length === 0
                ? "No email issues"
                : subscriptionData.emailIssues?.join(" ,")}
            </span>
          </div>
          <div className={`my-2 ${styles.verticalLine}`}></div>
          <div className={`${styles.issueBox}`}>
            <h6 className="mb-0">SMS issues</h6>
            <span>
              {subscriptionData.smsIssues?.length === 0
                ? "No SMS issues"
                : subscriptionData.smsIssues?.join(" ,")}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserMetrics;
