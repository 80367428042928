import { useCallback, useMemo, useRef, useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import {
  EmailForwardingDetails,
  EmailForwardingErrors,
  StepQuestion,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import check from "src/assets/images/check.png";
import styles from "./EmailForwarding.module.scss";
import VerificationModals, {
  CopyText,
} from "../VerificationModals/VerificationModals";
import MoveToNext from "../../MoveToNext/MoveToNext";
import createOrVerifyEmailForwardingService from "src/services/ReturnAutoWorkFlow/createOrVerifyEmailForwarding.service";
import stepDetailsStyles from "../../../../StepDetails/StepDetails.module.scss";

/**
 * Component to display verification status (Verified or Pending).
 * @param verified - Boolean indicating if the email is verified.
 * @returns A JSX element showing the verified or pending status.
 */
export const ShowVerifiedOrPending = ({ verified }: { verified: boolean }) => {
  if (verified) {
    return (
      <div className="d-flex align-items-center py-1">
        <div className={`${styles.successCheck}`}>
          <img
            src={check}
            alt="check"
            width={15}
          />
          <span className={`ps-1 ${styles.checkText}`}>Verified!</span>
        </div>
      </div>
    );
  } else {
    return (
      <p className={`d-block py-2 mb-0 ${styles.pendingText}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.855"
          height="13.855"
          viewBox="0 0 13.855 13.855"
        >
          <path
            id="Icon_ionic-ios-alert"
            data-name="Icon ionic-ios-alert"
            d="M10.3,3.375A6.928,6.928,0,1,0,17.23,10.3,6.927,6.927,0,0,0,10.3,3.375Zm.573,3.65-.1,3.834a.477.477,0,0,1-.47.493h0a.477.477,0,0,1-.47-.493l-.1-3.834a.574.574,0,0,1,.573-.586h0A.574.574,0,0,1,10.876,7.025ZM10.3,13.567a.613.613,0,1,1,.636-.613A.619.619,0,0,1,10.3,13.567Z"
            transform="translate(-3.375 -3.375)"
            fill="#b90202"
          />
        </svg>
        <span className="ps-1">Pending</span>
      </p>
    );
  }
};

/**
 * Component displaying the steps to set up and verify email forwarding.
 * @returns A JSX element with collapsible steps for email forwarding setup.
 */
const EmailForwardingSteps = () => {
  const [showVerificationSteps, setShowVerificationSteps] = useState(false);
  const toggleVerificationSteps = () => {
    setShowVerificationSteps(!showVerificationSteps);
  };

  return (
    <div className={`px-3 py-2 mt-2  ${styles.setupBox}`}>
      <div
        className={`cursor-pointer d-flex justify-content-between`}
        onClick={toggleVerificationSteps}
        id="toggleSetup"
      >
        <span className={`pe-2 ${styles.setupSpan}`}>
          How to setup and verify email forwarding?
        </span>
        <span className={`cursor-pointer ${styles.setupSpan}`}>
          <i
            className={`fa-solid fa-chevron-${
              showVerificationSteps ? "up" : "down"
            }`}
          ></i>
        </span>
      </div>
      {showVerificationSteps && (
        <div className={`py-2`}>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 1:
            </span>
            <span className={` mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              {`Copy your default Saufter email that is provided above.`}
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={` ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 2:
            </span>
            <span className={`mb-1 ${styles.stepSpan} ${styles.msgDiv}`}>
              {`Go to your email provider page. Look for the option to add forwarding emails. (Most providers will have instructions on how to perform email forwarding in their help center.)`}
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 3:
            </span>
            <span className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              {`Paste the copied Saufter email at your email provider's email forwarding option. Don't Forget to save these changes.`}
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 4:
            </span>
            <span className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              {`After completing the process in your email provider page. Navigate back to the Saufter tab.`}
            </span>
          </div>
          <div className={`d-flex`}>
            <span className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}>
              Step 5:
            </span>
            <span className={`mb-1 ${styles.stepSpan}  ${styles.msgDiv}`}>
              {`Click Verify email forwarding button, this might take a minute to verify.`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Main component for managing email forwarding steps.
 * @param stepId - The ID of the current step.
 * @param questionId - The ID of the current question.
 *
 * @returns A JSX element with the complete email forwarding UI.
 */
function EmailForwarding({
  stepId,
  questionId,
  updateReturnPreview,
}: {
  stepId: string;
  questionId: string;
  updateReturnPreview: ({
    stepQuestion,
  }: {
    stepQuestion: StepQuestion | null;
  }) => void;
}) {
  const {
    dispatch,
    integrationId,
    questionNumber,
    stepQuestion,
    savingStepAndQuestionData,
    showErrors,
    saveStepAndQuestionData,
  } = useSaveStepsAndQuestions({
    stepId,
    questionId,
  });

  const emailForwardingValue = useMemo(() => {
    return stepQuestion.specificQuestionValue as EmailForwardingDetails | null;
  }, [stepQuestion]);

  const [forwardingErrors, setForwardingErrors] = useState(
    null as null | EmailForwardingErrors,
  );

  const [showVerifiyModal, setShowVerifyModal] = useState(
    null as null | "initiate" | "loading" | "error" | "done",
  );
  const [ddOpen, setDdOpen] = useState(false);
  const [addNewEmail, setAddNewEmail] = useState(
    emailForwardingValue?.activeEmailForVerification ? false : true,
  );

  const setOrderInfoModal = useCallback(() => {
    dispatch("setState", {
      activeStaticModal: "emailForwardingOrderInfo",
    });
  }, [dispatch]);

  const activeEmailVerified = useMemo(
    () =>
      addNewEmail
        ? false
        : emailForwardingValue?.activeEmailVerified
          ? true
          : false,
    [addNewEmail, emailForwardingValue],
  );

  const changeEmail = useCallback(
    (email: string) => {
      if (email !== emailForwardingValue?.activeEmailForVerification) {
        saveStepAndQuestionData({
          specificQuestionSaveValue: {
            value: { activeEmailForVerification: email },
          },
        });
        updateReturnPreview({
          stepQuestion: { ...stepQuestion },
        });
      }
    },
    [emailForwardingValue, stepId, questionId, saveStepAndQuestionData],
  );

  const createOrVerifyEmail = useCallback(
    (email?: string) => {
      if (email) {
        dispatch("updateQuestionSpecificValue", {
          stepId,
          questionId: questionId,
          value: { activeEmailForVerification: email },
        });
      }

      setShowVerifyModal("loading");

      createOrVerifyEmailForwardingService({
        integrationId,
        email: email
          ? email
          : emailForwardingValue?.activeEmailForVerification ?? "",
      })
        .then((res) => {
          dispatch("updateQuestionSpecificValue", {
            stepId,
            questionId: questionId,
            value: {
              activeEmailForVerification: email
                ? email
                : emailForwardingValue?.activeEmailForVerification ?? "",
              activeEmailVerified: res.activeEmailVerified ? true : false,
            },
            putActiveEmailInArray: true,
          });

          setForwardingErrors(res.verificationError ?? null);

          if (res.activeEmailVerified) {
            setShowVerifyModal("done");
          } else {
            setShowVerifyModal("error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
      updateReturnPreview({
        stepQuestion: { ...stepQuestion },
      });
    },
    [
      integrationId,
      stepId,
      questionId,
      emailForwardingValue,
      dispatch,
      saveStepAndQuestionData,
    ],
  );

  const initiateForwarding = useCallback(() => {
    setShowVerifyModal("initiate");
  }, []);

  return (
    <div
      className={`pt-3 mb-2 mt-2 ${styles.contentWrapper} ${
        stepDetailsStyles.query
      } ${
        activeEmailVerified && emailForwardingValue?.activeEmailForVerification
          ? stepDetailsStyles.activeLine
          : ""
      } ${
        showErrors &&
        (!activeEmailVerified ||
          !emailForwardingValue?.activeEmailForVerification)
          ? stepDetailsStyles.errorLine
          : ""
      }`}
    >
      <div className="px-3">
        <p className={`mb-1 ${styles.queHead}`}>
          Q{questionNumber}. {stepQuestion.questionTitle}
        </p>
        <div>
          {emailForwardingValue?.savedEmails.length ? (
            <>
              <label className={`form-label ${styles.dropLabel}`}>
                Email address from which order updates are coming from:
              </label>
              <Dropdown
                show={ddOpen}
                onToggle={setDdOpen}
                className="w-100"
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="div"
                  bsPrefix={`dropdown-toggle mb-2 px-3 ${styles.dropBtn} ${styles.rejectBtn} ${styles.pendingBtn} `}
                >
                  <span>
                    {addNewEmail
                      ? "Add new email forwarding"
                      : emailForwardingValue.activeEmailForVerification}
                  </span>
                  <span className="ps-1">
                    {savingStepAndQuestionData === "question" ? (
                      <Spinner size="sm" />
                    ) : (
                      <i
                        className={`fa-solid fa-angle-${
                          ddOpen ? "up" : "down"
                        }`}
                      ></i>
                    )}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  bsPrefix={`dropdown-menu ${styles.dropMenu} w-100`}
                >
                  {emailForwardingValue.savedEmails.map((email) => {
                    return (
                      <Dropdown.Item
                        key={email}
                        as="div"
                        bsPrefix={`dropdown-item px-2 mb-1 ${styles.dropItem}`}
                        onClick={() => {
                          setAddNewEmail(false);
                          changeEmail(email);
                        }}
                      >
                        {email}
                      </Dropdown.Item>
                    );
                  })}
                  <div
                    className="d-flex align-items-center pe-2 cursor-pointer"
                    onClick={() => {
                      setAddNewEmail(true);
                      setDdOpen(false);
                    }}
                  >
                    <div className={`${styles.addICon}`}>
                      <span>+</span>
                    </div>
                    <span className={`ms-2 ${styles.addText}`}>
                      Add new email forwarding
                    </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            ""
          )}
        </div>
        {emailForwardingValue?.forwardEmailsTo ? (
          <CopyText
            copyButtonNormalText="Copy email"
            textToCopy={emailForwardingValue.forwardEmailsTo}
          />
        ) : (
          ""
        )}
        <div className="mt-3">
          <button
            className={`px-2 ${styles.verifyBtn}`}
            onClick={initiateForwarding}
            disabled={activeEmailVerified}
          >
            Verify email forwarding
          </button>
          <ShowVerifiedOrPending verified={activeEmailVerified} />
          {emailForwardingValue?.activeEmailForVerification ? (
            <button
              className={`px-2 ${styles.viewOrderBtn}`}
              onClick={setOrderInfoModal}
            >
              View order information received
            </button>
          ) : (
            ""
          )}
          {activeEmailVerified &&
          emailForwardingValue?.activeEmailForVerification &&
          stepQuestion.showMoveToNext ? (
            <div className="ms-0 my-2">
              <MoveToNext
                stepId={stepId}
                questionId={questionId}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <EmailForwardingSteps />
      <Modal
        show={showVerifiyModal !== null}
        onHide={
          showVerifiyModal === "loading"
            ? undefined
            : () => setShowVerifyModal(null)
        }
        contentClassName={`${styles.modalContent}`}
        dialogClassName={`${styles.dialogClass}`}
        backdropClassName={`${styles.backDropClass}`}
        id="mailModal"
        centered
      >
        {emailForwardingValue ? (
          <>
            {showVerifiyModal === "initiate" && (
              <VerificationModals.Initiate
                textToCopy={emailForwardingValue.forwardEmailsTo}
                activeEmail={
                  addNewEmail
                    ? ""
                    : emailForwardingValue.activeEmailForVerification
                }
                next={(email) => {
                  setAddNewEmail(false);
                  createOrVerifyEmail(email);
                }}
                back={() => {
                  setShowVerifyModal(null);
                }}
              />
            )}
            {showVerifiyModal === "error" && forwardingErrors && (
              <VerificationModals.Error
                errors={forwardingErrors}
                retry={createOrVerifyEmail}
                back={() => {
                  setShowVerifyModal("initiate");
                }}
              />
            )}
            {showVerifiyModal === "loading" && (
              <VerificationModals.Loading subText="We are checking to see if we have received an order related email from your platform." />
            )}
            {showVerifiyModal === "done" && (
              <VerificationModals.Done
                done={() => {
                  setShowVerifyModal(null);
                }}
              />
            )}
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}

export default EmailForwarding;
