import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";
import editIcon from "src/assets/images/edit.png";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import ReportTimeline from "src/routes/Report/Children/CampaignReport/Children/ReportTimeline/ReportTimeline";
import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import useTimelineScroll from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/useTimelineScroll";
import { SegementData } from "src/services/Campaign/getAllSegments.serivce";
import { updateSegment } from "src/services/Campaign/updateSegment";
import StatusSelect from "../../../../StatusSelect/StatusSelect";
import styles from "./TableRow.module.scss";
import { Spinner } from "react-bootstrap";

const TableRow = ({
  segmentData,
  onStatusChange,
  onBack,
}: {
  segmentData: SegementData;
  onStatusChange: (segmentId: number) => void;
  onBack: (id?: string | number) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [segmentName, setSegmentName] = useState(segmentData.name);
  const [originalSegmentName, setOriginalSegmentName] = useState(
    segmentData.name,
  ); // Store original name
  const [showTimeline, setShowTimeline] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [segmentNamerror, setSegmentNamerror] = useState(false);

  const timelineRef = useRef<HTMLDivElement>(null);

  // API call to add a new comment
  const { mutate, isLoading } = useMutation({
    mutationFn: updateSegment,
    onSuccess: (data, variables) => {
      if (variables.status) {
        setStatusLoading(false);
        onStatusChange(segmentData.id);
      }
    },
    onError: (res) => {
      // Reset the segment name to the original value on error
      setSegmentName(originalSegmentName);
      pushTheToast({
        type: "danger",
        text: `${
          (res as any)?.msg ?? (res as any)?.message ?? "Update failed!!"
        } `,
        position: "top-right",
      });
    },
  });

  const {
    handleScrollLeft,
    handleScrollRight,
    disableLeft,
    disableRight,
    scrollDivRef,
    checkScroll,
  } = useTimelineScroll();

  // Function to handle input change
  const handleInputChange = (e: any) => {
    setSegmentName(e.target.value);
    setSegmentNamerror(!e.target.value || e.target.value.trim().length == 0);
  };

  // Function to handle blur event on input
  const handleBlur = () => {
    if (segmentName.trim().length > 0) {
      mutate({
        id: segmentData.id,
        name: segmentName,
      });
      setIsEditing(false);
    } else {
      setSegmentNamerror(true);
    }
  };

  // Function to handle edit icon click
  const handleEditClick = () => {
    // Set the original name when entering edit mode
    setOriginalSegmentName(segmentName);
    setIsEditing(true);
  };

  return (
    <div className={`d-flex align-items-center mb-2 ${styles.tableRow}`}>
      <div
        className={`${styles.segmentName} cursor-pointer`}
        onClick={(e) => {
          if (isEditing) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          onBack(segmentData.id);
        }}
      >
        <div className={`d-flex align-items-center me-1 ${styles.dayWrap}`}>
          {isEditing ? (
            <div className={`position-relative`}>
              <input
                type="text"
                value={segmentName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`${styles.inputChange} ${
                  segmentNamerror && segmentName?.trim().length == 0
                    ? "border border-danger"
                    : ""
                }`}
                autoFocus
              />
              {segmentNamerror && segmentName?.trim().length == 0 && (
                <div className={styles.errorText}>segment name is required</div>
              )}
              <div
                className={`${styles.checkBox} cursor-pointer ${
                  segmentNamerror &&
                  segmentName?.trim().length == 0 &&
                  styles.checkBoxNew
                }`}
                onClick={handleBlur}
              >
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
            </div>
          ) : (
            <span className={`${styles.segmentText}`}>{segmentName}</span>
          )}
          <span
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditClick();
            }}
            className={`ps-1 cursor-pointer ${
              isEditing ? "d-none" : "d-block"
            }`}
          >
            <img
              src={editIcon}
              alt="edit"
              width={10}
            />
          </span>
        </div>
      </div>
      <div className={`${styles.timeline}`}>
        <TimelineProvider
          referenceId={segmentData.campaignId}
          referenceType="customer"
        >
          <div className="d-flex align-items-center px-3">
            <div
              className={`d-flex align-items-center`}
              style={{
                width: "calc(100% - 100px)",
                marginTop: "10px",
              }}
            >
              <div>
                <div
                  className={`mx-2 ${styles.iconBox} ${
                    !disableLeft ? "cursor-pointer" : styles.btnDisabled
                  }`}
                  onClick={() => {
                    if (!disableLeft) {
                      handleScrollLeft();
                    }
                  }}
                >
                  <i className={`fa-solid fa-chevron-left`}></i>
                </div>
              </div>

              <div
                ref={scrollDivRef}
                className={`mt-2 ${styles.headerContent}`}
              >
                <ReportTimeline
                  showTimeline={showTimeline}
                  timelineRef={timelineRef}
                  showLoader={false}
                  timelineInfo={segmentData.timelineData}
                  onLoad={() => {
                    checkScroll();
                  }}
                />
              </div>
              <div className="mx-2 mt-2">
                <span
                  className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
                  onClick={() => setShowTimeline(!showTimeline)}
                >
                  <i
                    className={`fa-solid fa-chevron-${
                      !showTimeline ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </div>
              <div>
                <div
                  className={`mx-2 ${styles.iconBox} ${
                    disableRight ? styles.btnDisabled : "cursor-pointer"
                  }`}
                  onClick={handleScrollRight}
                >
                  <i className={`fa-solid fa-chevron-right`}></i>
                </div>
              </div>
            </div>
          </div>
        </TimelineProvider>
      </div>
      <div className={`${styles.status} d-flex`}>
        <StatusSelect
          status={segmentData.status}
          setStatus={(status) => {
            setStatusLoading(true);
            mutate({
              id: segmentData.id,
              status: status,
            });
          }}
        />
        {statusLoading ? (
          <Spinner
            size="sm"
            variant="basic"
            className="ms-2"
          />
        ) : null}
      </div>
    </div>
  );
};

export default TableRow;
