/**
 * Bot Profile Reducer
 *
 * Reducer function for managing state related to a bot profile. Handles various actions to update different properties of the bot profile.
 *
 * @author @Anubhav-busibud
 */

import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";

export type BotProfileState = {
  botProfile: BotProfile;
};
const pushAndReturn = (array: Array<any>, value: any) => (
  array.push(value), array
);

export function botProfileReducer(state: BotProfileState, action: any) {
  switch (action.type) {
    case "UPDATE_CHANNELS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          channels: action.payload,
        },
      };
    case "UPDATE_ELIGIBLE_USERS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          eligibleUsers: action.payload,
        },
      };
    case "UPDATE_IS_CONFIGURE_ADVANCED_BOT_SETTINGS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          isConfigureAdvancedBotSettings: action.payload,
        },
      };
    case "UPDATE_USER_SEGMENT":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          userSegmentsDetails: action.payload,
        },
      };
    case "UPDATE_INTRODUCTION_MESSAGE":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          introductionMessage: action.payload,
        },
      };
    case "UPDATE_BOT_ANSWER_STRATEGY":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          botAnswerStrategy: action.payload,
        },
      };
    case "UPDATE_NEED_USER_DETAILS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          needUserDetails: action.payload,
        },
      };
    case "UPDATE_BOTTY_ANSWER_CONTENT":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          answerContent: action.payload,
        },
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          status: action.payload,
        },
      };
    case "UPDATE_VISIBILITY":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          visibleQuestionKeys: state.botProfile.visibleQuestionKeys
            ? [...state.botProfile.visibleQuestionKeys, action.payload]
            : [action.payload],
        },
      };
    case "UPDATE_CONFIGURE_FORM_STATUS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          configureFormStatus: action.payload,
        },
      };
    case "UPDATE_ANY_DETAILS":
      return {
        ...state,
        botProfile: {
          ...state.botProfile,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
}
