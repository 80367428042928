import React, {
  createContext,
  Fragment,
  ReactNode,
  useRef,
  useState,
  ElementType,
  ReactComponentElement,
  ReactElement,
} from "react";
import { element } from "prop-types";
import { Modal } from "react-bootstrap";
import styles from "./ModalContext.module.scss";

export interface ModalStyleClasses {
  modalDialog?: string;
  modalContent?: string;
  backdrop?: string;
}

export function useModal(): {
  isOpen: boolean;
  handleModal: Function;
  modalContents: ReactElement[] | undefined;
  classNames: ModalStyleClasses;
  setClassNames: (_classNames: ModalStyleClasses) => void;
  setIsFullScreen: (fullscreen: boolean) => void;
  isFullScreen: true | undefined;
  popModalFromTop: (popTillIndex: number | undefined) => void;
} {
  let [isOpen, setIsOpen] = useState(false);
  let [modalContents, setModalContents] = useState<ReactElement[]>([<></>]);
  const [isFullScreen, setIsFullScreen] = useState<true | undefined>();
  const defaultModalStyleClasses = {
    modalDialog: styles.modalDialog,
    modalContent: styles.modalContent,
    backdrop: styles.modalBack,
  };

  const [classNames, setClassNames] = useState<ModalStyleClasses>(
    defaultModalStyleClasses,
  );

  let handleModal = (content = undefined) => {
    let newModalContents;
    if (content) {
      newModalContents = [...modalContents, content];
      setModalContents(newModalContents);
    } else {
      modalContents.pop();
      newModalContents = [...modalContents];
      setModalContents(newModalContents);
    }

    if (newModalContents.length === 2) {
      setIsOpen(true);
    }
    if (newModalContents.length === 1) {
      setIsOpen(false);
    }
  };

  let popModalFromTop = (popTillIndex: number | undefined = undefined) => {
    const newModalContents = modalContents.slice(0, popTillIndex);
    setModalContents(newModalContents);
    if (newModalContents.length === 1) {
      setIsOpen(false);
    }
  };

  let _setClassNames = (_classNames: ModalStyleClasses) => {
    setClassNames(Object.assign({}, defaultModalStyleClasses, _classNames));
  };

  let _setIsFullScreen = (fullscreen: boolean) => {
    if (fullscreen) {
      setIsFullScreen(fullscreen);
    } else {
      setIsFullScreen(undefined);
    }
  };

  return {
    isOpen,
    handleModal,
    classNames,
    setClassNames: _setClassNames,
    modalContents,
    setIsFullScreen: _setIsFullScreen,
    isFullScreen,
    popModalFromTop,
  };
}

export interface ModalContextType {
  isOpen: boolean;
  handleModal: Function;
  modalContents: ReactElement[] | undefined;
  setClassNames: (_classNames: ModalStyleClasses) => void;
  setIsFullScreen: (fullscreen: boolean) => void;
  isFullScreen: true | undefined;
  popModalFromTop: (popTillIndex: number | undefined) => void;
}

let ModalContext = createContext<ModalContextType>({
  isOpen: false,
  handleModal: () => {},
  modalContents: undefined,
  setClassNames: (_classNames) => {},
  setIsFullScreen: (fullscreen: boolean) => {},
  isFullScreen: undefined,
  popModalFromTop: (popTillIndex: number | undefined) => {},
});

function ModalProvider({
  children,
}: {
  children: ReactNode;
  classNames?: string[];
}) {
  let {
    isOpen,
    handleModal,
    modalContents,
    classNames,
    setClassNames,
    setIsFullScreen,
    isFullScreen,
    popModalFromTop,
  } = useModal();

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        handleModal,
        modalContents,
        setClassNames,
        setIsFullScreen,
        isFullScreen,
        popModalFromTop,
      }}
    >
      <Fragment>
        {!modalContents || typeof document === "undefined" ? null : (
          <Modal
            show={isOpen}
            onHide={() => handleModal()}
            className="shadow-sm p-3 mb-5  rounded overflow-hidden "
            backdropClassName={classNames.backdrop}
            dialogClassName={classNames.modalDialog}
            contentClassName={classNames.modalContent}
            centered={true}
            enforceFocus={true}
            fullscreen={isFullScreen}
            backdrop="static"
            keyboard={false}
          >
            {React.cloneElement(
              modalContents[modalContents.length - 1] ?? <></>, //added fragment as default for undefined element
              {
                isOpen,
                handleModal,
                setClassNames,
                setIsFullScreen,
                isFullScreen,
                popModalFromTop,
              },
              <></>,
            )}
          </Modal>
        )}
      </Fragment>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: element.isRequired,
};

export { ModalContext, ModalProvider };
