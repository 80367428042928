import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { ECampaignStatus } from "src/services/Campaign/getCampaignById";
import { useUpdateCampaign } from "../hooks/useCampaignData";
import styles from "./StatusSelect.module.scss";

interface OptionType {
  label: string;
  value: ECampaignStatus;
}

const options: OptionType[] = [
  {
    label: "Published",
    value: ECampaignStatus.Published,
  },
  {
    label: "Draft",
    value: ECampaignStatus.Draft,
  },
  {
    label: "Paused",
    value: ECampaignStatus.Paused,
  },
];

const adminOptions: any[] = [
  {
    label: "Pending for backend",
    value: "pendingAdmin",
  },
  {
    label: "Approved by backend",
    value: "approvedAdmin",
  },
];

const customStyles = {
  control: (styles: any, { selectProps }: any) => {
    // Get the currently selected option
    const selectedValue = selectProps.value?.value;
    return {
      ...styles,
      background:
        selectedValue === ECampaignStatus.Published ||
        selectedValue === "approvedAdmin"
          ? "#BAFFD6"
          : selectedValue === ECampaignStatus.Paused
            ? "#ffb8b8"
            : selectedValue === "pendingAdmin"
              ? "#fffbe0"
              : "#FFF6BA",
      // width: "80px",
      width: "fit-content",
      minHeight: "18px",
      display: "flex",
      alignItems: "center",
      border: "none",
      borderRadius: "4px",
      marginRight: "2px",
      font: "normal normal normal 11px/17px Poppins",
      color:
        selectedValue === ECampaignStatus.Published ||
        selectedValue === "approvedAdmin"
          ? "#286846"
          : selectedValue === ECampaignStatus.Paused
            ? "#601c1c"
            : selectedValue === "pendingAdmin"
              ? "#cb765d"
              : "#443232",
      cursor: "pointer",
    };
  },
  menu: (provided: any, { selectProps }: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "4px 12px",
    width:
      selectProps.value?.value === "pendingAdmin" ||
      selectProps.value?.value === "approvedAdmin"
        ? "180px"
        : "157px",
    zIndex: "9999",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#fff" : "#000",
    backgroundColor: isFocused ? "#0B68BB" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 4px 0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "18px", // Adjust the height if needed
    fontSize: "11px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 11px/17px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};

// Custom Option component to display both image and text
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps, selectProps } = props;
  return (
    <div
      className={`form-check`}
      ref={innerRef}
      {...innerProps}
    >
      <input
        className="form-check-input cursor-pointer"
        type="radio"
        name="flexRadioDefault"
        id={`${data.value}`}
        checked={selectProps.value?.value === data.value}
      />
      <label
        className={`form-check-label `}
        htmlFor={`${data.value}`}
      >
        <div className="d-flex">
          <span
            className={`cursor-pointer ${
              data.value === ECampaignStatus.Published ||
              data.value === "approvedAdmin"
                ? styles.approvedStatus
                : data.value === ECampaignStatus.Paused
                  ? styles.pausedStatus
                  : data.value === "pendingAdmin"
                    ? styles.pendingAdmin
                    : styles.draftStatus
            }`}
          >
            {" "}
            {data.label}
          </span>
          {/* show below ui for loader state  */}
          <div className={`ms-3 ${styles.loaderState} d-none`}>
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};

const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <span className={`${styles.optionLabel}`}>{data.label}</span>
    </div>
  );
};

const CustomIndicator = (props: any) => {
  const { selectProps } = props;
  const isOpen = selectProps.menuIsOpen;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i
          className={`fa-solid ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
        ></i>
      </span>
    </div>
  );
};

const StatusSelect = ({
  status,
  campaignId,
  disabled,
  isAdmin,
}: {
  status: ECampaignStatus | undefined;
  campaignId: number | undefined;
  disabled: boolean;
  isAdmin?: boolean;
}) => {
  const allOptions = useMemo(() => {
    return isAdmin ? adminOptions : options;
  }, [isAdmin]);
  const updateCampaign = useUpdateCampaign();
  const [selectedOption, setSelectedOption] = useState<OptionType>(() => {
    return (
      allOptions.find((val) => val.value === status) ??
      allOptions[isAdmin ? 1 : 0]
    );
  });

  useEffect(() => {
    if (status) {
      setSelectedOption(() => {
        return (
          allOptions.find((val) => val.value === status) ??
          allOptions[isAdmin ? 1 : 0]
        );
      });
    }
  }, [status]);

  const handleUpdateStatus = useCallback(
    (status: ECampaignStatus) => {
      if (!campaignId) return;

      updateCampaign.mutate({ campaignId, status });
    },
    [campaignId, updateCampaign],
  );

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={(value) => {
          if (!value) return;

          setSelectedOption(value);
          handleUpdateStatus(value.value);
        }}
        options={allOptions}
        placeholder="Status"
        isSearchable={false}
        styles={customStyles}
        isMulti={false}
        isClearable={false}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomIndicator,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
      />
    </div>
  );
};

export default StatusSelect;
