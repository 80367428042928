import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { CreateTemplateParams, TemplateStep } from "./createTemplates";
import { TemplateStep as TemplateStepRes } from "./getAllTemplates";

interface updateTemplateParams {
  templateId: string | null;
  templateSteps?: Array<TemplateStep>;
  scheduleMessage?: boolean; //should be true when scheduling message
  chatId?: number | string;
  ticketId?: number | string;
  stageId?: number | string;
  status?: "open" | "closed" | "pending";
  scheduleWith?: string | null;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/template/update`
  : "/api/liveChat/agent/message/template/update";

interface UpdateTemplateResponse {
  data: {
    id: number | string;
    templateSteps: TemplateStepRes[];
  };
  err?: boolean;
  metaData?: {
    scheduledMessagesCount: number;
  };
}
export const updateTemplateLiveChat = async (params: updateTemplateParams) => {
  try {
    const response = await axiosJSON.post(apiEndPoint, params);
    return response.data as UpdateTemplateResponse;
  } catch (err) {
    return {
      err: true,
      data: {},
    } as any;
  }
};
