/**
 * This file defines a React component representing a single row of data in the Custom Q&A table
 * on the ChatBot interface. It displays information about a question-answer pair, allows for selecting,
 * deleting, and updating the status of the pair. It also includes modals for confirming deletion and
 * displaying publishing success.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 */

import React from "react";
import styles from "./QaTableData.module.scss";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import { useNavigate, useParams } from "react-router-dom";
import { selectQaParams } from "src/services/Bot/CustomQA/selectQa.service";
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { QuestionAns } from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import { AiFillDelete } from "react-icons/ai";
import { deleteCustomQA } from "src/services/Bot/CustomQA/deleteCustomQA.services";
import { getById } from "src/services/Bot/CustomQA/getById.service";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { Modal } from "react-bootstrap";
import DeleteQA from "./children/DeleteQA/DeleteQA";
import { updateCustomQA } from "src/services/Bot/CustomQA/updateCustomQA.service";
import CustomPublishModal from "../NewAnswers/Children/CustomPublishModal/CustomPublishModal";
import BotIndexingStatus, { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";

export interface Props {
  questionData: QuestionAns;
  selectQnA: UseMutationResult<any, unknown, selectQaParams, unknown>;
  removeCustomQAFromList: (id: string | number) => void;
  updateCustomQAData: (id: number, customQA: Partial<QuestionAns>) => void;
}

const Data = ({
  questionData,
  selectQnA,
  removeCustomQAFromList,
  updateCustomQAData,
}: Props) => {
  const {
    customQAId,
    question,
    status,
    numberOfTimesAppeared,
    botTrainingStatus,
  } = questionData;
  const { profileId } = useParams();
  const queryClient = useQueryClient();
  const deleteQnA = useMutation({
    mutationFn: deleteCustomQA,
    onSuccess(data, variables, context) {
      // queryClient.setQueryData(["getAllCustomQnA"], (oldData: any) => {
      //   const filteredData = oldData?.data?.filter(
      //     (item: any) => item.customQAId !== questionData.customQAId
      //   );
      //   return {
      //     ...oldData,
      //     data: filteredData,
      //   };
      // });
    },
  });
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showPublishModal, setShowPublishModal] = React.useState(false);
  const [changedBotStatus, setchangedBotStatus] = React.useState<IBotIndexingStatus>(botTrainingStatus);

  // Function to handle delete customQA
  const handleDeleteQA = () => {
    deleteQnA.mutate(
      {
        botProfileId: profileId || "",
        customQAId: questionData.customQAId,
      },
      {
        onSuccess(data, variables, context) {
          setDeletedSuccess(true);
        },
      },
    );
  };

  const [deletedSuccess, setDeletedSuccess] = React.useState(false);

  // Function to hide the modal after delete
  const onHideDeleteModal = () => {
    if (deletedSuccess) {
      setTimeout(() => {
        removeCustomQAFromList(questionData.customQAId);
      }, 0);
    }
    setDeleteModal(false);
  };

  // Function to hide the modal on click cancel
  const onHidePublishModal = () => {
    setShowPublishModal(false);
  };

  // mutation to update customQA publish status
  const updateQnA = useMutation({
    mutationFn: updateCustomQA,
    onSuccess(data, variables, context) {
      updateCustomQAData(parseInt(variables.customQAId + ""), {
        status: variables.status === "draft" ? "draft" : "public",
        botStatus: data.botTrainingStatus,
      });

      setShowPublishModal(true);

      // queryClient.setQueryData(["getAllCustomQnA"], (oldData: any) => {
      //   const filteredData = oldData?.data?.map((item: any) => {
      //     if (item.customQAId === questionData.customQAId) {
      //       return {
      //         ...item,
      //         status: status === "draft" ? "public" : "draft",
      //       };
      //     }
      //     return item;
      //   });
      //   return {
      //     ...oldData,
      //     data: filteredData,
      //   };
      // });
    },
  });

  return (
    <>
      <div
        role="button"
        className={`mt-3 d-flex align-items-center px-3 mb-2 justify-content-between ${styles.tableHead}`}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`${questionData.customQAId}`);
        }}
      >
        <div
          className={`${styles.question}`}
          role="button"
        >
          <div className="form-check">
            <div>
              <input
                className={`form-check-input cursor-pointer ${styles.checkbox}`}
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    selectQnA.mutate({
                      botProfileId: profileId || "",
                      isSelected: true,
                      questionId: question,
                    });
                  } else {
                    selectQnA.mutate({
                      botProfileId: profileId || "",
                      isSelected: false,
                      questionId: question,
                    });
                  }
                }}
              />
            </div>

            <label className={`form-check-label pe-2 ${styles.questionName}`}>
              {question}
            </label>
          </div>
        </div>
        <div
          className={`${styles.articleUrl} pe-2`}
          role="button"
        >
          <span
            className={`px-2 py-1 ${status === "public" ? styles.publicStatus : styles.draftStatus
              }`}
          >
            {status === "draft" ? "Draft" : "Public"}
          </span>
        </div>
        {/*new column added for bot indexing status*/}
        <BotIndexingStatus
          botStatus={changedBotStatus}
          failedText={"Training failed"}
          loadingText={"This may take few minutes..."}
          syncedText={"Custom QA synced"}
          percentageLoaded={50}
          id={customQAId}
          botProfileId={profileId}
          sourceType={'customQA'}
          updateCustomQA={updateCustomQAData}
          setchangedBotStatus={setchangedBotStatus}

        />
        <div
          className={`${styles.appearance} pe-2`}
          role="button"
        >
          <span
            className={`${styles.qustionCount}`}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/bot/botProfiles/${profileId}/answerSource/kbArticles`);
            }}
          >
            {numberOfTimesAppeared}
          </span>
        </div>

        {/* Publish Button */}
        <div className={`${styles.action}`}>
          <div
            className="d-flex align-items-center justify-content-between flex-shrink-1"
            style={{ maxWidth: "153px" }}
          >
            <div>
              <button
                className={`px-3 ${styles.publishBtn}`}
                disabled={updateQnA.isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  updateQnA.mutate({
                    botProfileId: profileId || "",
                    customQAId: questionData.customQAId || "",
                    status: status === "draft" ? "public" : "draft",
                  }, {
                    onSuccess(data) {
                      setchangedBotStatus(data.botTrainingStatus);
                      // if (updateCustomQA) {
                      //   updateCustomQA({
                      //     customQAId: parseInt(questionData.customQAId + ""),
                      //     status: status === "draft" ? "public" : "draft",
                      //     // botStatus: data.botTrainingStatus,
                      //     botProfileId: profileId || "",
                      //   });
                      // }
                    },
                  }
                  );
                }}
              >
                <span className="pe-1">
                  {status === "draft" ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </span>
                {(status === "draft") ? "Publish" : "Unpublish"}
                {updateQnA.isLoading && (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </span>
                )}
              </button>
            </div>

            {/* Delete Button  */}
            <div
              className={`pe-1`}
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModal(true);
              }}
            >
              <span className={`${styles.deleteIcon}`}>
                {/* {deleteQnA.isLoading ? (
            <span>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </span>
          ) : ( */}
                <i className="fa-solid fa-trash"></i>
                {/* )} */}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        show={deleteModal}
        onHide={onHideDeleteModal}
        backdropClassName={`${styles.backDropClass}`}
      >
        <DeleteQA
          question={questionData.question}
          onHide={onHideDeleteModal}
          isLoading={deleteQnA.isLoading}
          handleDeleteQA={handleDeleteQA}
          deletedSuccess={deletedSuccess}
          setDeletedSuccess={setDeletedSuccess}
        />
      </Modal>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showPublishModal}
        onHide={onHidePublishModal}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <CustomPublishModal
          onHide={onHidePublishModal}
          customQAId={customQAId + ""}
          status={status}
          handleStatusUpdate={(id, values) => {
            updateCustomQAData(parseInt(id + ""), values);
          }}
        />
      </Modal>
    </>
  );
};

export default Data;
