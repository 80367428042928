import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentColumnsService, {
  SegmentColumn,
  SegmentColumnsResponse,
} from "src/services/CustomerSegments/getSegmentColumns.service";

const LIMIT = 10;

function useFetchColumns(
  segmentType: string,
  segmentId: string,
  onPageFetch?: (res: SegmentColumnsResponse) => void,
) {
  const { data, status, fetchNextPage, hasNextPage } = useInfiniteQuery<
    SegmentColumnsResponse,
    unknown,
    SegmentColumnsResponse
  >(["useFetchColumns", segmentType], {
    queryFn: async ({ pageParam = { columnType: "default" } }) => {
      if (!segmentType) {
        throw new Error("No Segment Type");
      }

      return await getSegmentColumnsService({
        segmentType,
        segmentId,
        ...pageParam,
      });
    },
    getNextPageParam: (currentPage, allPages) => {
      if (currentPage.allColumnKeys.length < LIMIT) {
        return null;
      }

      return {
        columnType: allPages.length === 0 ? "default" : "custom",
        start:
          allPages.flatMap((val) => val.allColumnKeys ?? []).length -
          allPages[0].allColumnKeys.length,
        limit: LIMIT,
      };
    },
    cacheTime: 3600000,
    staleTime: 3600000,
    networkMode: "always",
  });

  const columns: SegmentColumnsResponse | null = useMemo(() => {
    if (!data) return null;

    return data.pages.reduce(
      (acc, res) => {
        return {
          allColumnKeys: [...acc.allColumnKeys, ...res.allColumnKeys],
          allColumns: { ...acc.allColumns, ...res.allColumns },
        };
      },
      {
        allColumnKeys: [] as string[],
        allColumns: {} as Record<string, SegmentColumn>,
      },
    );
  }, [data]);

  useEffect(() => {
    if (columns && onPageFetch) {
      onPageFetch(columns);
    }
  }, [columns, onPageFetch]);

  return {
    columns,
    columnsStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
    fetchNextPage,
    hasNextPage,
  };
}

export default useFetchColumns;
