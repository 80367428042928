import styles from "./ReturnMethod.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import download from "src/assets/images/chat/Download.png";
import ParseVariablesWithValue from "../ParseVariablesWithValue/ParseVariablesWithValue";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useState } from "react";
import { validateURL } from "src/utils/validations";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";

const ReturnMethod = () => {
  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();
  const {
    getReturnMethodData,
    getAllReasons,
    isRestockingFeeCharged,
    isReturnReasonsAvailable,
  } = useGetAutomationData();
  const allReasons = getAllReasons();
  const { returnMethod, returnMethodMessage } = getReturnMethodData();
  const [hasError, setHasError] = useState(false);

  const handleBack = () => {
    if (selectedOrder) {
      let backPage: StepName = "reason";
      if (
        !isReturnReasonsAvailable ||
        !allReasons ||
        allReasons?.length === 0
      ) {
        backPage = "returnType";
      }

      // Check if any item's selected exchange with same item or exchange with different item
      const isExchangeItems = Object.values(
        selectedOrder.addedItems ?? {},
      ).some((data) => {
        return (
          data.selectedReturnType === "exchangeWithSameItems" ||
          data.selectedReturnType === "exchangeWithDifferentItems" ||
          data.newReturnType === "exchangeWithSameItems" ||
          data.newReturnType === "exchangeWithDifferentItems"
        );
      });
      if (isExchangeItems) {
        backPage = "arsExchange";
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: backPage,
        },
      });
    }
  };

  const handleNext = () => {
    if (selectedOrder) {
      if (returnMethod === "shipBackAtOwnExpense") {
        if (
          !selectedOrder?.returnTrackingUrl ||
          selectedOrder?.returnTrackingUrl === "" ||
          !validateURL(selectedOrder?.returnTrackingUrl)
        ) {
          setHasError(true);
          return;
        }
      }
      let nextPage: StepName = "configureNextSteps";
      let prevStep: StepName = "returnMethod";
      const isRestockFee = isRestockingFeeCharged();
      if (isRestockFee === true) {
        nextPage = "reviewRestockingFee";
      } else if (isRestockFee === false) {
        nextPage = "orderSummary";
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: nextPage,
          prevStepForConfig: prevStep,
        },
      });
    }
  };

  const onChangeTrackingUrl = (value: string) => {
    if (selectedOrder) {
      setHasError(false);
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          returnTrackingUrl: value,
        },
      });
    }
  };

  // Get all item IDs from addedItems
  const items = Object.values(selectedOrder?.addedItems ?? {});

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center mb-2">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>Return Method</span>
        </div>
        {items.map((item) => {
          const itemData = selectedOrder?.itemsInOrder?.find(
            (value) => value.id + "" === item.itemId + "",
          );
          return (
            <div
              key={item.itemId}
              className={`mb-2 ${styles.orderBox} p-3`}
            >
              <p className={`mb-0 ${styles.orderHeading}`}>{itemData?.name}</p>

              <div className={`d-flex`}>
                <div className={`${styles.cart}`}>
                  <img
                    src={
                      itemData?.name === "Acme T-Shirt" ? orderImg : orderImg2
                    }
                    alt="order"
                    className={`${styles.orderImg}`}
                  />
                </div>
                <div className={`ms-2`}>
                  <span className={`${styles.orderName}`}>
                    {itemData?.unitPrice?.currencyCode}
                    {itemData?.unitPrice?.amount} x {item.quantity}
                  </span>
                  <span className={`${styles.orderDesc}`}>
                    SKU: {itemData?.sku}
                  </span>
                </div>
              </div>
              <div>
                <div className={`${styles.subHeading} pt-3 mb-2`}>
                  Return Instructions:
                </div>
                <div className={`${styles.instruction} mb-3`}>
                  <ParseVariablesWithValue
                    message={returnMethodMessage as string}
                    isReturnMethodMsg={true}
                  />
                </div>
                {returnMethod === "shipBackAtOwnExpense" ? (
                  <div>
                    <div className={styles.textLabel}>
                      Please share the return tracking link:
                    </div>
                    <div>
                      <input
                        type="text"
                        className={`form-control ${styles.inputBox} ${
                          hasError ? "border-danger" : ""
                        }`}
                        id={"returnMethod_tracking_url"}
                        value={selectedOrder?.returnTrackingUrl}
                        onChange={(e) => onChangeTrackingUrl(e.target.value)}
                        placeholder="https://..."
                      />
                      {hasError ? (
                        <span className={styles.errormsg}>
                          Please provide the return tracking link.
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
        {/* {returnMethod === "prepaidLabels" ? (
          <button
            className={`d-block text-white w-100 py-1 ${styles.downloadBtn}`}
          >
            <span className={`${styles.downloadSpan}`}>
              Download return label
            </span>{" "}
            <img
              src={download}
              className={`ms-1 ${styles.downloadImg}`}
              alt=""
            />
          </button>
        ) : null} */}
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default ReturnMethod;
