import styles from "./HeaderTimeline.module.scss";

interface Step {
  number: number;
  name: string;
}

interface HeaderTimelineProps {
  steps: Step[];
  activeStep: number;
  lastCompletedStep: number;
}

const HeaderTimeline = ({
  steps,
  activeStep,
  lastCompletedStep,
}: HeaderTimelineProps) => {
  return (
    <div
      className={`d-flex justify-content-center ms-5 ${styles.headerContainer}`}
    >
      {steps.map((step) => {
        const isCompleted = step.number <= lastCompletedStep;

        return (
          <div
            key={step.number}
            className="d-flex flex-column align-items-start justify-content-start"
          >
            <div className="d-flex align-items-center">
              <div
                className={`${styles.stepCount} ${
                  step.number === activeStep || isCompleted ? styles.active : ""
                }`}
              >
                <span>
                  {isCompleted ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    step.number
                  )}
                </span>
              </div>
              {/* Render separator for all steps except the last one */}
              {step.number < steps.length && (
                <div className={`ms-2 me-3 ${styles.stepSeparator}`}></div>
              )}
            </div>
            <p
              className={`mt-1 ${
                step.number === activeStep
                  ? styles.activeStepName
                  : styles.stepName
              }`}
            >
              {step.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderTimeline;
