import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import {
  CSVTableVarientImage,
  ConfigureCSVTableMappedAttributes,
} from "src/routes/CustomerSegments/children/SegmentView/Children/UploadCSVModal/Hooks/useUploadNewCsvModalContext";

/**
 * Parameters for fetching all the Customers based on csv uploaded file.
 */
export interface GetAllCustomersParams {
  /** The starting index for the orders to be fetched, used for pagination. */
  start: number;
  /** The maximum number of orders to be fetched in a single request, used for pagination. */
  limit: number;
  attachmentId: string;
}

/**
 * Represents a table heading in the orders table.
 */
export interface TableHeading {
  /**
   * The unique identifier for the table heading.
   * This ID will be used in rows to render corresponding data.
   */
  tableHeadingId: string;
  /** The display name of the table heading. */
  tableHeadingName: string;
  /**
   * This is needed for the map attributes feature.
   * Here after changing the heading with anutoer one and on submit API the changedTableHeadingId will be mapped to tableHeadingId.
   */
  changedTableHeadingId?: null | string;
}

/**
 * The data for the corresponding row in the orders table.
 * - `type` can be `null`, `"string"`, `"number"`, or `"price" or `"date"`.
 * - `value` can be `null`, a `string`, a `number`, or an `IAmount`.
 */
export interface CustomerRow {
  /**
   * Here the `tableHeadingId` will be from the `tableHeadings`.
   * null can be possible in the case if image is not uploaded yet.
   */
  tableHeadingId: string;
  /**
   * `CustomerImage` will have and specific data type as `CSVTableCustomerImage` in `value`.
   * null or undefined will be there for string.
   */
  type?: null | "varientImage";
  value: null | CSVTableVarientImage | string;
}
/**
 * Represents an individual order.
 */
export interface ICustomer {
  /** The unique identifier for the Customer. */
  customerId: string;
  rowData: CustomerRow[];
}

/**
 * The response structure for the `getAllCustomers` function.
 */
export interface GetAllCustomersResponse {
  /**
   * The array of table headings to be displayed in the Customers table.
   */
  tableHeadings: TableHeading[];
  /**
   * The list of all the variants in the table.
   */
  customers: ICustomer[];
  /** The total number of rows, which is the same as the total number of variants. */
  totalCustomers: number;
  attachmentId: string;
}

/**
 * Fetches all Customers data for Uploaded CSV - Map attributes step.
 *
 * This function used to retrieve all Customers data for uploaded CSV modal for step 3.
 *
 * @param {GetAllCustomersParams} params - The parameters to send with the request.
 * @returns {Promise<ConfigureCSVTableMappedAttributes>} A promise that resolves to the response data containing all Customers.
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 */
async function getAllCustomers({
  params,
  activeSegmentType,
}: {
  params: GetAllCustomersParams;
  activeSegmentType: string;
}): Promise<ConfigureCSVTableMappedAttributes> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllImportedCustomers`
    : `/api/segment/${activeSegmentType}/csv/getAll`;
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
  const normalizedData = normalizeData(res.data);

  return normalizedData as ConfigureCSVTableMappedAttributes;
}
const normalizeData = (data: GetAllCustomersResponse) => {
  const ret: ConfigureCSVTableMappedAttributes = {
    customerIds: [],
    customers: {},
    tableHeadingIds: [],
    updatedTableHeadingIds: [],
    tableHeadings: {},
    totalCustomers: data?.totalCustomers ?? 0,
    mapAttributeErrors: {},
  };
  if (data) {
    data?.tableHeadings.forEach((tableHeading) => {
      ret.tableHeadings[tableHeading.tableHeadingId] = tableHeading;
      ret.tableHeadingIds.push(tableHeading.tableHeadingId);
    });

    data.customers.forEach((customer) => {
      ret.customers[customer.customerId] = {
        customerId: customer.customerId,
        rowData: customer.rowData,
      };

      ret.customerIds.push(customer.customerId);
    });
    ret.updatedTableHeadingIds = ret.tableHeadingIds;
    ret.totalCustomers = data.totalCustomers;
    return ret as ConfigureCSVTableMappedAttributes;
  }
};
export default getAllCustomers;
