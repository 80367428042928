/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";

export enum ESegmentStatus {
  Approved = "approved",
  Draft = "draft",
}

interface Params {
  id: string | number;
  status?: ESegmentStatus;
  name?: string;
}
interface UpdateSegmentResponse {
  err: boolean;
  statusCode: string;
  msg: string;
}
export const updateSegment = async (params: Params) => {
  const { data: res } = await axiosJSON.post(
    "/api/segment/updateSegment",
    params,
  );
  if ((res as UpdateSegmentResponse).statusCode) {
    throw {
      statusCode: (res as UpdateSegmentResponse).statusCode,
      msg: (res as UpdateSegmentResponse).msg,
    };
  }
  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res as UpdateSegmentResponse;
};
