import { Dropdown } from "react-bootstrap";
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "../../MessageTabs.module.scss";
import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { addVariableOnClick } from "src/features/ReturnAutoWorkFlow/children/BaseSteps/children/StepQuestion/children/genericAnswers/TextBox/TextBox";
import { VariablesData } from "src/services/CustomerSegments/NewMessage/getMessageTypes.service";
import { AutoCompleteRenderComponentProps } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

interface Props extends Partial<AutoCompleteRenderComponentProps> {
  placeholderRef: React.MutableRefObject<any>;
  variableIds: string[];
  variables: Record<string, VariablesData>;
  setSelectedVariables: React.Dispatch<SetStateAction<Array<VariablesData>>>;
  initialShow?: boolean;
  showToggleBtn?: boolean;
  setShowAttributeModal: () => void;
  attributes: Record<string, VariablesData>;
  attributeIds: string[];
}

const MentionVariables = ({
  placeholderRef,
  variables,
  variableIds,
  setSelectedVariables,
  initialShow = false,
  showToggleBtn = true,
  setShowAttributeModal,
  attributes,
  attributeIds,
  data,
  cleanUp,
  match,
  editor
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(initialShow);
  const [searchText, setSearchText] = useState<string>("");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const mentionRefs = useRef<(HTMLDivElement | null)[]>([]);

  //filter Default variable based on searchbox text or tiny mce mention text
  const filteredDefaultIds = useMemo(() => {
    let filteredIds = [];
    if (searchText && searchText.trim().length > 0) {
      filteredIds = variableIds.filter(
        (id) =>
          variables[id]?.variableName
            .toLowerCase()
            .includes(searchText?.toLowerCase()),
      );
    } else {
      filteredIds = variableIds;
    }
    if (match && match.trim().length > 0) {
      filteredIds = filteredIds.filter(
        (id) =>
          variables[id]?.variableKey
            .toLowerCase()
            .includes(match?.toLowerCase()),
      );
    }
    return filteredIds;
  }, [variables, searchText, variableIds, match]);

  //filter custom variable based on searchbox text or tiny mce mention text
  const filteredCustomIds = useMemo(() => {
    let filteredIds = [];
    if (searchText && searchText.trim().length > 0) {
      filteredIds = attributeIds.filter(
        (id) =>
          attributes[id]?.variableName
            .toLowerCase()
            .includes(searchText?.toLowerCase()),
      );
    } else {
      filteredIds = attributeIds;
    }
    if (match && match.trim().length > 0) {
      filteredIds = filteredIds.filter(
        (id) =>
          attributes[id]?.variableKey
            .toLowerCase()
            .includes(match?.toLowerCase()),
      );
    }
    return filteredIds;
  }, [attributes, searchText, attributeIds, match]);

  const setDropdown = useCallback(() => {
    if (showDropdown) {
      setSearchText("");
    }
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const currentState = useRef({
    filteredData: data,
    highlightedIndex: highlightedIndex,
  });

  //handle key press up | down | enter | esc
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const handleArrowNavigation = (direction: "up" | "down") => {
        const increment = direction === "down" ? 1 : -1;
        const resetIndex =
          direction === "down"
            ? 0
            : filteredCustomIds.length + filteredDefaultIds.length - 1;

        setHighlightedIndex((prevIndex) => {
          const newState = prevIndex + increment;
          mentionRefs.current[newState]?.scrollIntoView({
            behavior: "instant" as any,
            block: "nearest",
          });

          return newState >= 0 &&
            newState < filteredCustomIds.length + filteredDefaultIds.length
            ? newState
            : resetIndex;
        });
      };

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          event.stopPropagation();
          handleArrowNavigation("down");
          break;
        case "ArrowUp":
          event.preventDefault();
          event.stopPropagation();
          handleArrowNavigation("up");
          break;
        case "Enter":
          event.preventDefault();
          event.stopPropagation();
          if(!filteredCustomIds.length && !filteredDefaultIds.length){
            break;
          }
          if (highlightedIndex < filteredDefaultIds.length && editor) {
            const variableIndex = filteredDefaultIds[highlightedIndex];
            const variable = variables[variableIndex];
            const span = editor.dom.select("span#custom-autocomplete-1");
            editor.dom.remove(span[0]);
            addVariableOnClick(variable, placeholderRef);
            setSelectedVariables((prevVariable) => [...prevVariable, variable]);
            setDropdown();
          } else {
            const variableIndex =
              filteredCustomIds[highlightedIndex - filteredDefaultIds.length];
            const variable = attributes[variableIndex];
            if (editor) {
              const span = editor.dom.select("span#custom-autocomplete-1");
              editor.dom.remove(span[0]);
            }
            addVariableOnClick(variable, placeholderRef);
            setSelectedVariables((prevVariable) => [...prevVariable, variable]);
            setDropdown();
          }
          break;
        case "Escape":
          event.preventDefault();
          event.stopPropagation();
          if (cleanUp) cleanUp(true);
          break;
        default:
          break;
      }
    },
    [cleanUp, highlightedIndex],
  );

  useEffect(() => {
    try{
      if (editor) {
        editor.on("keydown", handleKeyDown);
        return () => {
          editor.off("keydown", handleKeyDown);
        };
      }
    } catch(err){
      console.error("Error setting up editor keyboard handlers:", err);
    }
  }, [editor, handleKeyDown]);

  useEffect(() => {
    setHighlightedIndex(0);
  }, [match]);

  useEffect(() => {
    const { current } = currentState;
    current.highlightedIndex = highlightedIndex;
  }, [highlightedIndex]);

  return (
    <Dropdown
      drop="up"
      show={showDropdown}
      onToggle={setDropdown}
    >
      <Dropdown.Toggle
        className={`dropdown-toggle ${styles.showBtn}`}
        as={"div"}
        variant="success"
        onClick={setDropdown}
      >
        {showToggleBtn && (
          <span className={`cursor-pointer ${styles.seeAll}`}> {`{..}`}</span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu bsPrefix={`dropdown-menu pt-0 border-0 ${styles.variBox}`}>
        <div>
          <div
            className={`px-2 d-flex justify-content-between align-items-center py-2 ${styles.headerCol}`}
          >
            <span className={`${styles.leadName}`}>All variables</span>
            <div className={`d-flex align-items-center`}>
              <button
                className={styles.addAttribute}
                onClick={setShowAttributeModal}
              >
                <i
                  className="fa fa-plus me-2"
                  aria-hidden="true"
                ></i>
                <span>Add</span>
              </button>
              <span
                className={`cursor-pointer ps-2  ${styles.closeDrop}`}
                onClick={setDropdown}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
          </div>
          <div className={`py-2 ${styles.variHeight}`}>
            <span className={styles.variableType}>Default variables</span>
            {variables && filteredDefaultIds && filteredDefaultIds.length !== 0
              ? filteredDefaultIds.map((id, idx) => {
                  const variable = variables[id];

                  if (!variable) {
                    return "";
                  }

                  return (
                    <li
                      key={idx}
                      className={`m-1 ${
                        idx === highlightedIndex && styles.highlight
                      }`}
                    >
                      <span
                        className={`dropdown-item ${styles.dropDown}`}
                        onClick={() => {
                          if (editor) {
                            const span = editor.dom.select(
                              "span#custom-autocomplete-1",
                            );
                            editor.dom.remove(span[0]);
                          }
                          addVariableOnClick(variable, placeholderRef);
                          setSelectedVariables((prevVariable) => [
                            ...prevVariable,
                            variable,
                          ]);
                          setDropdown();
                        }}
                      >
                        <p className={`mb-0 ${styles.varibleHead}`}>
                          {variable?.variableName}
                        </p>
                        <span className={`${styles.varibleContent}`}>
                          {" "}
                          Eg: {variable?.example}
                        </span>
                      </span>
                    </li>
                  );
                })
              : ""}
            {filteredCustomIds.length ? (
              <div>
                <span className={styles.variableType}>Custom variables</span>
                {filteredCustomIds.map((id, idx) => {
                  const attribute = attributes[id];

                  if (!attribute) {
                    return "";
                  }

                  return (
                    <li
                      key={idx}
                      className={`m-1 ${
                        idx === highlightedIndex - filteredDefaultIds.length &&
                        styles.highlight
                      }`}
                    >
                      <span
                        className={`dropdown-item ${styles.dropDown}`}
                        onClick={() => {
                          const newVariableData = {
                            variableName: attribute.variableName,
                            variableKey: attribute.variableKey,
                            id: attribute.id,
                            example: attribute.example,
                          };
                          if (editor) {
                            const span = editor.dom.select(
                              "span#custom-autocomplete-1",
                            );
                            editor.dom.remove(span[0]);
                          }
                          addVariableOnClick(newVariableData, placeholderRef);
                          setSelectedVariables((prevVariable) => [
                            ...prevVariable,
                            newVariableData,
                          ]);
                          setDropdown();
                        }}
                      >
                        <p className={`mb-0 ${styles.varibleHead}`}>
                          {attribute?.variableName}
                        </p>
                        <span className={`${styles.varibleContent}`}>
                          {" "}
                          Eg: {attribute?.example}
                        </span>
                      </span>
                    </li>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className={`mx-1`}>
            <SearchBar
              className={`${styles.search} form-control px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search`}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              isSearchIcon={false}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MentionVariables;
