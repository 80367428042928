import Lottie from "lottie-light-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderAnimation from "src/assets/animation/white-loader.json";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";
import addSegmentUserService from "src/services/CustomerSegments/addSegmentUser.service";
import { validateEmail } from "src/utils/utils";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./UserModal.module.scss";

const UserModal = ({
  onHide,
  setShowCSVModal,
}: {
  onHide: () => void;
  setShowCSVModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { activeSegmentDetails, fetchMoreSegmentView } = useSegmentView();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [userIdError, setUserIdError] = useState<string | null>(null);

  const validateValues = useCallback(() => {
    let valid = true;

    // Validate email format if email is not empty
    if (email.trim()) {
      if (!validateEmail(email)) {
        setEmailError(
          "Please enter a valid email address (e.g., user@example.com).",
        );
        valid = false;
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError("Email cannot be empty.");
      valid = false;
    }

    // Validate User ID if not empty
    if (userId.trim()) {
      const userIdRegex = /^[a-zA-Z0-9]+$/;
      if (!userIdRegex.test(userId)) {
        setUserIdError("User ID must be alphanumeric.");
        valid = false;
      } else {
        setUserIdError(null);
      }
    }

    // Validate name
    if (name.trim() === "") {
      valid = false;
    }

    // Clear errors if validation passes
    if (valid) {
      setEmailError(null);
      setUserIdError(null);
      setShowError(false);
    }

    return valid;
  }, [email, name, userId]);

  const onSubmit = useCallback(async () => {
    if (!validateValues()) {
      setShowError(true);
      return;
    }

    try {
      setIsLoading(true);
      const res = await addSegmentUserService({
        email,
        name,
        userId: userId.trim() === "" ? undefined : userId,
      });

      if (
        res.segmentUserId &&
        res.segmentId &&
        activeSegmentDetails.activeSegmentType
      ) {
        pushTheToast({
          position: "top-right",
          type: "success",
          text: "Successfully added the user!",
        });

        navigate(
          customerSegmentsUtils.getNavigation(
            activeSegmentDetails.activeSegmentType,
            res.segmentId,
            res.segmentUserId,
          ),
        );
        // Refetch data after adding user
        fetchMoreSegmentView(true);
        onHide();
      } else if (res.statusCode && res.message) {
        setShowError(true);

        switch (res.statusCode) {
          case "duplicate_email":
            setEmailError(res.message);
            break;
          case "duplicate_user_id":
            setUserIdError(res.message);
        }

        return;
      } else if (res.statusCodes && res?.statusCodes?.length > 0) {
        setShowError(true);
        res.statusCodes?.forEach((code) => {
          switch (code?.statusCode) {
            case "duplicate_email":
              setEmailError(code?.msg ?? "");
              break;
            case "duplicate_user_id":
              setUserIdError(code?.msg ?? "");
          }
        });
        return;
      }
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: typeof err === "string" ? err : err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    activeSegmentDetails.activeSegmentType,
    email,
    name,
    navigate,
    onHide,
    userId,
    validateValues,
  ]);

  return (
    <div>
      <h3 className={`${styles.modalHead}`}>Add new user</h3>
      <span className={`${styles.modalSubHeading}`}>
        An email address or user ID is required to create a new user. If you
        want to add multiple users please upload using{" "}
        <span
          className={`text-decoration-underline cursor-pointer ${styles.csvText}`}
          onClick={() => {
            onHide();
            setShowCSVModal(true);
          }}
        >
          CSV file.
        </span>
      </span>
      <div>
        {/* Name */}
        <div className="mb-2">
          <label
            htmlFor="nameInput"
            className={`form-label ${styles.formLabel}`}
          >
            Name
          </label>
          <input
            type="text"
            className={`form-control px-2 ${styles.formInput} ${
              showError && name.trim() === "" ? styles.errorBorder : ""
            }`}
            id="nameInput"
            aria-describedby="name"
            placeholder="John Doe"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {showError && name.trim() === "" ? (
            <span className={`${styles.errorMsg} mt-1`}>
              Action required: Name cannot be empty.
            </span>
          ) : (
            ""
          )}
        </div>

        {/* Email */}
        <div className="mb-2">
          <label
            htmlFor="emailInput"
            className={`form-label ${styles.formLabel}`}
          >
            Email
          </label>
          <input
            type="email"
            className={`form-control px-2 ${styles.formInput} ${
              showError && emailError ? styles.errorBorder : ""
            }`}
            id="emailInput"
            aria-describedby="email"
            placeholder="Johndoe@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {showError && emailError ? (
            <span className={`${styles.errorMsg} mt-1`}>
              Action required: {emailError}
            </span>
          ) : (
            ""
          )}
        </div>

        {/* User ID */}
        <div className="mb-2">
          <label
            htmlFor="userId"
            className={`form-label ${styles.formLabel}`}
          >
            User ID
          </label>
          <input
            type="text"
            className={`form-control px-2 ${styles.formInput} ${
              showError && userIdError ? styles.errorBorder : ""
            }`}
            id="userId"
            aria-describedby="id"
            placeholder="12345678"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          {showError && userIdError ? (
            <span className={`${styles.errorMsg} mt-1`}>
              Action required: {userIdError}
            </span>
          ) : (
            ""
          )}

          <div className={`form-text pb-2 ${styles.formText}`}>
            A unique user identifier that won't change.
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-4">
          <button
            className={`${styles.cancelBtn}`}
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className={`${styles.addBtn}`}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className={`${styles.loader}`}>
                <Lottie animationData={LoaderAnimation} />
              </div>
            ) : (
              "Add new user"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
