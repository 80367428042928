import { axiosJSON } from "src/globals/axiosEndPoints";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

interface MetaData {
  totalReplies: number;
  lastReplyDateGmt: string;
}

interface ApiResponse {
  err: boolean;
  data: IMessageData[];
  metaData?: MetaData;
  msg: string;
}

export const getCampaignThreadMsg = async (payload: {
  campaignId: number | string;
  campaignEmailId: number | string;
  start: number;
  limit: number;
}) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/thread/getMessages`,
    payload,
  );

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something went wrong!";
  }

  return res as ApiResponse;
};
