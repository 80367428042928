import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";
import styles from "./TicketEventMsg.module.scss";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import TicketRouteModal from "src/components/TicketCurrentStatus/TicketRouteModal/TicketRouteModal";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";
import FireImg from "src/assets/images/fire.png";
import CampaignModal from "./Children/CampaignModal/CampaignModal";

interface ITicketEventMsg {
  messageData: IMessageData & { eventSubType?: number };
}

const TicketEventMsg = ({ messageData }: ITicketEventMsg) => {
  const [showModal, setShowModal] = useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  if (
    messageData?.messageType?.toLowerCase() === "event" &&
    messageData?.eventSubType &&
    messageData?.eventMetaData &&
    messageData?.eventMetaData.ruleId
  ) {
    return (
      <div className={`d-flex justify-content-center ${styles.mainDiv}`}>
        <p
          className={`${styles.mainPara} d-flex align-items-center justify-content-center`}
        >
          <span className={`${styles.blueDot} mx-2`}></span>
          <span
            className={`${styles.eventMsgParaTxt} d-flex align-items-center justify-content-center`}
          >
            {messageData.message} -{" "}
            <span
              className={`${styles.ruleName} cursor-pointer`}
              onClick={() => setShowModal(true)}
            >
              {messageData?.eventMetaData?.ruleName},
            </span>
          </span>

          <span
            className={`${styles.eventMsgParaTxtDate} px-2 d-flex align-items-center justify-content-center`}
          >
            {getMonthNameDateAmPmTime(messageData.messageDateTime)}
          </span>
          {/* modal for ticket routing ui  */}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            contentClassName={`${styles.modalContent}`}
            dialogClassName={`${styles.modalDialog}`}
            backdropClassName={`${styles.backDropClass}`}
            id="ticketRouteModal"
            centered
            enforceFocus={false}
          >
            <TicketRouteModal
              ruleId={messageData?.eventMetaData?.ruleId ?? ""}
              ruleName={messageData?.eventMetaData?.ruleName ?? ""}
              onHide={onHide}
            />
          </Modal>
        </p>
      </div>
    );
  }

  return (
    <div className={`d-flex justify-content-center ${styles.mainDiv}`}>
      {/* <div className={`${styles.mainDiv}`}> */}

      <p
        className={`${styles.mainPara} d-flex align-items-center justify-content-center`}
      >
        {messageData.eventSubType === 227 ? (
          <div>
            <img
              className={`${styles.fireImg} mx-2`}
              src={FireImg}
            />
          </div>
        ) : (
          <div>
            <span className={`${styles.blueDot} mx-2`}></span>
          </div>
        )}

        {/* For campaign related events */}
        {messageData?.eventSubType === 230 ||
        messageData?.eventSubType === 233 ||
        messageData?.eventSubType === 234 ||
        messageData?.eventSubType === 235 ? (
          <CampaignEvent messageData={messageData} />
        ) : (
          <span
            className={`${styles.eventMsgParaTxt} d-flex align-items-center justify-content-center`}
          >
            {messageData.message},
          </span>
        )}

        <span
          className={`${styles.eventMsgParaTxtDate} px-2 d-flex align-items-center justify-content-center`}
        >
          {getMonthNameDateAmPmTime(messageData.messageDateTime)}
        </span>

        {/* June 13 Incase of integration failure event, show integarion not available text */}
        {/* Commenting this as there will be no integration failure for ticket events */}
        {/* {messageData?.integrationFailure && (
          <span>
            <IntegrationFailureText
              integrationFailure={messageData?.integrationFailure ?? null}
            />
          </span>
        )} */}
      </p>
    </div>
  );
};
const CampaignEvent = ({ messageData }: ITicketEventMsg) => {
  const [showModal, setShowModal] = useState(false);

  const { message, campaignDetails } = messageData;
  const campaignName = campaignDetails?.campaignName;

  // Split message around the campaignName to separate the matching part
  const parts = message.split(new RegExp(`(${campaignName})`, "g"));

  return (
    <span
      className={`${styles.eventMsgParaTxt} d-flex align-items-center justify-content-center`}
    >
      {parts.map((part, index) => (
        <span key={index}>
          {part === campaignName ? (
            <span
              className={styles.campaignName}
              onClick={() => setShowModal(true)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {campaignName}
            </span>
          ) : (
            <span>{part}</span>
          )}
          {/* Add a non-breaking space after each part except the last one */}
          {index !== parts.length - 1 && <span>&nbsp;</span>}
        </span>
      ))}

      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <CampaignModal
          onHide={() => setShowModal(false)}
          campaignId={messageData?.campaignDetails?.campaignId ?? 0}
          sentAt={messageData.messageDateTime ?? ""}
          stepName={messageData?.campaignDetails?.stepName ?? ""}
          templateId={messageData?.campaignDetails?.emailTemplateId ?? 0}
          ticketId={messageData?.ticketId ?? 0}
        />
      </Modal>
    </span>
  );
};

export default TicketEventMsg;
