import { useQuery } from "@tanstack/react-query";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import activityIcon from "src/assets/images/activityIcon.svg";
import activityIconHover from "src/assets/images/activityIconHover.svg";
import blackDot from "src/assets/images/blackDot.svg";
import bugIcon from "src/assets/images/bugIcon.svg";
import hoveredBugIcon from "src/assets/images/bugIconHover.svg";
import calorieHoverIcon from "src/assets/images/calorieHoverIcon.svg";
import caloryIcon from "src/assets/images/calorieIcon.svg";
import emojiHoverIcon from "src/assets/images/emojiHoverIcon.svg";
import emojiIcon from "src/assets/images/emojiIcon.svg";
import eyeIcon from "src/assets/images/Eye.svg";
import hoveredEyeIcon from "src/assets/images/EyeHover.svg";
import refundIcon from "src/assets/images/refundIcon.svg";
import hoveredRefundIcon from "src/assets/images/refundIconHover.svg";
import clock from "src/assets/images/temClock.png";
import icon from "src/assets/images/timelineIcon.png";
import userIcon from "src/assets/images/userIcon.svg";
import hoveredUserIcon from "src/assets/images/userIconHover.svg";
import { getTimelineSubEvents } from "src/services/ShopifySidebar/EditCustomerDetails/getTimelineSubEvents";
import {
  EventDetail,
  TimelineEvent,
  TimeLineSubEvent,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { useAppSelector } from "src/store/store";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
import styles from "./Timeline.module.scss";
import UserAvatar from "src/components/UserAvatar";
import { useTimeline } from "../TimelineProvider/TimelineProvider";

interface TimelineProps extends EventDetail {
  date: string | null;
  icon: string;
  description: string;
  name: string;
  eventType: string;
  expanded: boolean;
  bgColor: string;
  index: number;
  cursor?: string | null;
  attribute?: TimelineEvent;
}

function getIconForTimeline(timelineIcon: string) {
  if (timelineIcon === "eye") {
    return eyeIcon;
  } else if (timelineIcon === "user") {
    return userIcon;
  } else if (timelineIcon === "activity") {
    return activityIcon;
  } else if (timelineIcon === "bug") {
    return bugIcon;
  } else if (timelineIcon === "refund") {
    return refundIcon;
  } else if (timelineIcon === "hot_activity") {
    return caloryIcon;
  } else if (timelineIcon === "low_activity") {
    return emojiIcon;
  } else {
    return icon;
  }
}

function getHoveredIconForTimeline(timelineIcon: string) {
  if (timelineIcon === "eye") {
    return hoveredEyeIcon;
  } else if (timelineIcon === "user") {
    return hoveredUserIcon;
  } else if (timelineIcon === "activity") {
    return activityIconHover;
  } else if (timelineIcon === "bug") {
    return hoveredBugIcon;
  } else if (timelineIcon === "refund") {
    return hoveredRefundIcon;
  } else if (timelineIcon === "hot_activity") {
    return calorieHoverIcon;
  } else if (timelineIcon === "low_activity") {
    return emojiHoverIcon;
  } else {
    return icon;
  }
}
interface MajorEventPopOverProps {
  events: EventDetail["subEvents"];
  isLoading: boolean;
  isError: boolean;
  currentCursor?: string | null;
  handleRedirect: (url: string | null | undefined) => void;
  refetch: () => void;
}

const MajorEventPopOver: React.FC<MajorEventPopOverProps> = ({
  events,
  isLoading,
  isError,
  currentCursor,
  handleRedirect,
  refetch,
}) => {
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [activeEventIndex, setActiveEventIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  // useEffect(() => {
  //   if (events.length > 0) {
  //     setSelectedEventIndex(0);
  //   }
  // }, [events.length]);

  const handleNextEvent = () => {
    if (selectedEventIndex < events.length - 1) {
      setSelectedEventIndex(selectedEventIndex + 1);
      setActiveEventIndex(selectedEventIndex + 1);
      if (selectedEventIndex >= events.length - 3 && currentCursor) {
        refetch();
      }
    }
  };

  const handlePreviousEvent = () => {
    if (selectedEventIndex > 0) {
      setSelectedEventIndex(selectedEventIndex - 1);
      setActiveEventIndex(selectedEventIndex - 1);
    }
  };

  const activeEvent = useMemo(
    () => events[activeEventIndex],
    [activeEventIndex, events],
  );

  const hasPlaceholder = useMemo(() => {
    return events[activeEventIndex]?.description?.includes("{{list}}");
  }, [activeEventIndex, events]);

  const selectedEvent = events[selectedEventIndex];

  return (
    <div>
      {/* Tabs Section */}
      <div className={`d-flex align-items-center ${styles.tabsSection}`}>
        <div
          className="d-flex align-items-center"
          style={{
            borderBottom:
              activeEventIndex === selectedEventIndex
                ? `2px solid ${selectedEvent?.bgColor}`
                : `2px solid transparent`,
          }}
        >
          {selectedEvent.icon ? (
            <img
              src={getHoveredIconForTimeline(selectedEvent.icon + "")}
              alt=""
              height={12}
            />
          ) : (
            <UserAvatar
              name={selectedEvent.name}
              size={12}
              colorScheme={
                selectedEvent.bgColor
                  ? { fc: "white", bg: selectedEvent.bgColor }
                  : undefined
              }
            />
          )}
          <span
            className={`ps-2 ${styles.eventName} cursor-pointer`}
            style={{
              color:
                activeEventIndex === selectedEventIndex
                  ? selectedEvent?.bgColor
                  : "#C8C8C8",
            }}
            onClick={() => setActiveEventIndex(selectedEventIndex)}
          >
            {selectedEvent.name}
          </span>
        </div>
        {events[selectedEventIndex + 1] || currentCursor ? (
          <>
            <div className={`mx-2 ${styles.spacer}`}></div>
            <div
              className="d-flex align-items-center"
              style={{
                borderBottom:
                  activeEventIndex === selectedEventIndex + 1
                    ? `2px solid ${events[selectedEventIndex + 1]?.bgColor}`
                    : `2px solid transparent`,
              }}
            >
              {events[selectedEventIndex + 1]?.icon ? (
                <img
                  src={getHoveredIconForTimeline(
                    events[selectedEventIndex + 1]
                      ? events[selectedEventIndex + 1].icon + ""
                      : "",
                  )}
                  alt=""
                  height={12}
                />
              ) : (
                <UserAvatar
                  name={events[selectedEventIndex + 1]?.name}
                  size={12}
                  colorScheme={
                    events[selectedEventIndex + 1]?.bgColor
                      ? {
                          fc: "white",
                          bg: events[selectedEventIndex + 1].bgColor ?? "",
                        }
                      : undefined
                  }
                />
              )}
              <span
                className={`ps-2 ${styles.eventName} cursor-pointer`}
                style={{
                  color:
                    activeEventIndex === selectedEventIndex + 1
                      ? events[selectedEventIndex + 1]?.bgColor
                      : "#C8C8C8",
                }}
                onClick={() => setActiveEventIndex(selectedEventIndex + 1)}
              >
                {events[selectedEventIndex + 1]
                  ? events[selectedEventIndex + 1].name
                  : "Loading..."}
              </span>
            </div>
          </>
        ) : null}

        {events.length > 2 && (
          <>
            <div className={`mx-2 ${styles.spacer}`}></div>

            <div
              className="d-flex align-items-center pe-2"
              style={{ marginLeft: "auto" }}
            >
              <span
                className={`${styles.arrow} ${styles.activeArrow} ${
                  selectedEventIndex === 0 ? styles.disabled : ""
                }`}
                onClick={handlePreviousEvent}
              >
                <i className="fa-solid fa-angle-left"></i>
              </span>
              <span
                className={`ps-2 ${styles.arrow} ${styles.activeArrow} ${
                  !currentCursor && selectedEventIndex === events.length - 1
                    ? styles.disabled
                    : ""
                }`}
                onClick={handleNextEvent}
              >
                <i className="fa-solid fa-angle-right"></i>
                {isLoading && events.length >= selectedEventIndex - 1 ? (
                  <Spinner
                    className={`mx-2 ${styles.floatLeft}`}
                    animation="border"
                    size="sm"
                  />
                ) : null}
              </span>
            </div>
          </>
        )}
      </div>
      {/* Content Section */}
      <div>
        <span
          className={`${styles.content} pt-1`}
          onClick={() => handleRedirect(activeEvent?.url)}
        >
          {hasPlaceholder
            ? activeEvent.description?.replace("{{list}}", "")
            : activeEvent?.description}

          {hasPlaceholder && activeEvent?.listOptions && (
            <OverlayTrigger
              overlay={
                <Popover
                  style={{ zIndex: 99999 }}
                  onMouseOut={() => setShowOverlay(false)}
                  onMouseOver={() => setShowOverlay(true)}
                >
                  <div className={`${styles.listPopover}`}>
                    <ul>
                      {activeEvent.listOptions.map((val) => {
                        return <li>{val}</li>;
                      })}
                    </ul>
                  </div>
                </Popover>
              }
              show={showOverlay}
              placement="bottom"
              trigger="focus"
            >
              <span
                className={`ps-1 cursor-pointer ${styles.popoverList}`}
                onClick={() => setShowOverlay((prev) => !prev)}
              >
                {activeEvent.listOptions.map((val) => val).join(", ")}
              </span>
            </OverlayTrigger>
          )}
        </span>
      </div>
    </div>
  );
};

const Timeline: React.FC<TimelineProps> = ({
  date,
  icon,
  description,
  name,
  eventType,
  expanded,
  subEvents,
  ticketId,
  type,
  position,
  bgColor,
  index,
  cursor,
  url,
}) => {
  const { state } = useTimeline();
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);
  const [currentCursor, setCurrentCursor] = useState(cursor);
  const [hasError, setHasError] = useState(false);
  const [events, setEvents] = useState(subEvents);

  const {
    data,
    isError,
    refetch,
    isFetching: isLoading,
  } = useQuery(
    [
      "customer/timelineLoadMoreEvents",
      state.referenceId,
      state.referenceType,
      currentCursor,
    ],
    () =>
      getTimelineSubEvents({
        referenceId: state.referenceId,
        referenceType: state.referenceType,
        cursor: currentCursor ?? null,
      }),
    {
      enabled: false, // Disable automatic query execution
      cacheTime: 60000,
      staleTime: 60000,
    },
  );

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const mergeEvents = useCallback((newEvents: TimeLineSubEvent[]) => {
    if (!Array.isArray(newEvents)) {
      return;
    }

    setEvents((prevEvents) => {
      const combinedEvents = [...prevEvents, ...newEvents];
      const uniqueEvents = combinedEvents; /*.reduce<TimeLineSubEvent[]>(
        (acc, event) => {
          const uniqueKey = `${event.type}-${event.id}`;
          if (!acc.some((e) => `${e.type}-${e.id}` === uniqueKey)) {
            acc.push(event);
          }
          return acc;
        },
        [],
      );*/

      return uniqueEvents;
    });
  }, []);

  useEffect(() => {
    if (data) {
      setCurrentCursor(data.cursor);
      mergeEvents(data.events);
    }
  }, [data, mergeEvents]);

  const handleRedirect = useCallback((url: string | null | undefined) => {
    if (url && url.length) {
      window.open(url);
    }
  }, []);

  const renderContent = () => {
    try {
      return (
        <div
          className={`${styles.timelineContainer} ${
            position === "bottom" ? styles.bottom : ""
          } ${eventType === "sub" ? styles.subEvent : ""} ${
            expanded ? styles.expanded : styles.collapsed
          }`}
        >
          {eventType === "sub" ? (
            <div
              ref={target}
              onMouseOver={() => setShowTooltip(true)}
              onMouseOut={() => setShowTooltip(false)}
            >
              <OverlayTrigger
                trigger="hover"
                placement={"bottom"}
                show={showTooltip}
                overlay={
                  <Popover
                    id="popover-basic"
                    bsPrefix={`popover ${styles.popverWrapper}`}
                  >
                    <Popover.Body bsPrefix={`popover-body ${styles.popOver}`}>
                      <div className={`${styles.overlay} p-2`}>
                        <span
                          className={`${styles.overlayName}`}
                          dangerouslySetInnerHTML={{ __html: name }}
                        ></span>
                        <div
                          className={`overflow-auto p-1 ${styles.popOverScroll}`}
                        >
                          {events?.map((subEvent) => (
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              key={subEvent.id + "_" + subEvent.type}
                              onClick={() => handleRedirect(subEvent.url)}
                            >
                              <span>
                                <img
                                  src={clock}
                                  alt="clock"
                                  className={`${styles.clockImg}`}
                                />
                              </span>
                              <span className={`ms-1 ${styles.overlaytime}`}>
                                {subEvent.datetime
                                  ? formatMDYTimeStringDate(
                                      subEvent.datetime,
                                      userTimezone,
                                    )
                                  : ""}
                              </span>
                            </div>
                          ))}
                          {isError ? (
                            <span className={`${styles.error} text-danger`}>
                              Error loading more events
                            </span>
                          ) : currentCursor ? (
                            <div>
                              <span
                                className={`${styles.showMoreButton} me-2 ${styles.floatLeft}`}
                                style={{}}
                                onClick={() => refetch()}
                              >
                                Show More{" "}
                              </span>
                              {isLoading && (
                                <Spinner
                                  className={`m-auto ${styles.floatLeft}`}
                                  animation="border"
                                  size="sm"
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <div
                    className={`${styles.blackDot} d-flex align-items-center`}
                  >
                    <img
                      src={blackDot}
                      alt="info"
                      className={`cursor-pointer ${
                        expanded ? styles.expandImg : styles.normalImg
                      }`}
                    />
                  </div>
                </span>
              </OverlayTrigger>
            </div>
          ) : (
            <>
              {expanded ? (
                <div
                  className={`d-flex flex-column  justify-content-center position-relative ${styles.collapseUi}`}
                >
                  <div
                    className={`${
                      events?.length > 1 ? styles.majorEventContainer : ""
                    } ${position === "bottom" ? styles.rotated : ""}`}
                  >
                    <div
                      className={`${styles.timelineBox} position-relative`}
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      ref={target}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseOut={() => setShowTooltip(false)}
                      // onClick={() => {
                      //   if (eventType !== "sub" && url) {
                      //     handleRedirect(url);
                      //   }
                      // }}
                    >
                      <OverlayTrigger
                        trigger="hover"
                        placement="right"
                        flip={true}
                        show={showTooltip}
                        overlay={
                          <Tooltip
                            className={`${styles.toolTipCustom} ${styles.tabToolTip}`}
                          >
                            <div
                              className={`${styles.overlay} p-2`}
                              style={{
                                minWidth:
                                  events?.length >= 3 ? "360px" : undefined,
                              }}
                            >
                              <MajorEventPopOver
                                currentCursor={currentCursor}
                                events={events}
                                handleRedirect={handleRedirect}
                                isError={isError}
                                isLoading={isLoading}
                                refetch={refetch}
                              />
                            </div>
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="">
                            {icon ? (
                              <img
                                src={getIconForTimeline(icon)}
                                alt="icon"
                                className={`${styles.icon}`}
                              />
                            ) : (
                              <UserAvatar
                                name={name}
                                size={33}
                                colorScheme={{ fc: "white", bg: bgColor }}
                              />
                            )}
                            {/* <span className={`${styles.eventCount}`}>
                          {subEvents.length ? `+${subEvents.length}` : null}
                        </span> */}
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    {subEvents.length > 1 ? (
                      <div
                        className={`${styles.countShadow} ${
                          position === "bottom" ? styles.rotated : ""
                        }`}
                      >
                        <span
                          className={`${styles.eventCount}`}
                          style={{
                            color: bgColor,
                          }}
                        >
                          {subEvents.length ? `+${subEvents.length - 1}` : null}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`d-flex flex-column align-items-center ${styles.barWrapper}`}
                  >
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                    <div
                      style={{
                        border: bgColor,
                        backgroundColor: bgColor,
                      }}
                      className={`${styles.bar}`}
                    ></div>
                  </div>
                  {/* <div
                    className={`${styles.collapseDot} ${styles.flexEnd}`}
                    style={{
                      border: index == 0 ? "1px solid #6D6D6D" : bgColor,
                    }}
                  ></div> */}
                </div>
              ) : (
                <div className="d-flex align-items-center position-relative">
                  <div
                    className={`${styles.timelineBox} position-relative`}
                    style={{
                      border: bgColor,
                      backgroundColor: bgColor,
                    }}
                    ref={target}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                    // onClick={() => {
                    //   if (eventType !== "sub" && url) {
                    //     handleRedirect(url);
                    //   }
                    // }}
                  >
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      flip={true}
                      show={showTooltip}
                      overlay={
                        <Tooltip
                          className={`${styles.toolTipCustom} ${styles.tabToolTip}`}
                        >
                          <div
                            className={`${styles.overlay} p-2`}
                            style={{
                              minWidth:
                                events?.length >= 3 ? "360px" : undefined,
                            }}
                          >
                            <MajorEventPopOver
                              currentCursor={currentCursor}
                              events={events}
                              handleRedirect={handleRedirect}
                              isError={isError}
                              isLoading={isLoading}
                              refetch={refetch}
                            />
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="">
                        {icon ? (
                          <img
                            src={getIconForTimeline(icon)}
                            alt="icon"
                            className={`${styles.icon}`}
                          />
                        ) : (
                          <UserAvatar
                            name={name}
                            size={33}
                            colorScheme={{ fc: "white", bg: bgColor }}
                          />
                        )}
                        <span className={`${styles.eventCount}`}>
                          {subEvents.length > 1
                            ? `+${subEvents.length - 1}`
                            : null}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    } catch (error) {
      console.error("Error rendering Timeline component:", error);
      setHasError(true);
      return (
        <div className={`text-center ${styles.errorMsg}`}>
          Failed to load the resource
        </div>
      );
    }
  };

  return hasError ? <div>Failed to load the resource</div> : renderContent();
};

export default Timeline;
