import React, { createContext, useContext, useState, ReactNode } from "react";

interface ShowAddBrandContextType {
  showAddBrand: boolean;
  show: () => void;
  hide: () => void;
}

const ShowAddBrandContext = createContext<ShowAddBrandContextType | undefined>(
  undefined,
);

export const ShowAddBrandProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showAddBrand, setIsVisible] = useState<boolean>(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  return (
    <ShowAddBrandContext.Provider value={{ showAddBrand, show, hide }}>
      {children}
    </ShowAddBrandContext.Provider>
  );
};

export const useShowAddBrand = (): ShowAddBrandContextType => {
  const context = useContext(ShowAddBrandContext);
  if (context === undefined) {
    throw new Error("useShowAddBrand must be used within a ShowAddBrandProvider");
  }
  return context;
};
