import { useEffect, useState } from "react";
import { fetchUpdateEmailIntegration } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "../../../../ConnectAccount/ConnectAccount.module.scss";
import SetupTable from "../../../../FinalSetup/Children/SetupTable/SetupTable";

const SetupComplete = ({
  onNext,
  onPrevious,
}: {
  onPrevious: () => void;
  onNext: () => void;
}) => {
  const dispatch = useAppDispatch();

  const idForUpdate = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailIntegrationId;
  });

  const getName = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.userName;
  });
  const email = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailEnteredByUser;
  });
  const forwardEmail = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.defaultEmail;
  });
  const authDomainLength = useAppSelector(
    (state) => state.emailIntegration.selectedUser.domainAuthData.length,
  );

  const incomingSettingReceiveType = useAppSelector(
    (state) =>
      state.emailIntegration.selectedUser.incomingMailSettings?.receiveType,
  );

  const outgoingType = useAppSelector(
    (state) =>
      state.emailIntegration.selectedUser.outgoingMailSettings?.outgoingType,
  );

  const [name, setName] = useState(getName);

  useEffect(() => {
    setName(getName);
  }, [getName]);

  return (
    <>
      <div
        className={`pt-4 mt-2 ms-2 ms-lg-2 me-lg-4 `}
        style={{ width: "90%" }}
      >
        {/* Customer Details */}
        <div className={`pt-4 mt-2 ms-lg-2 me-lg-4`}>
          <h3 className={`mb-0 ${styles.heading}`}>{getName}</h3>
          <span className={`${styles.subHeading}`}>{email}</span>
        </div>

        {/* Saved Details */}
        <div>
          <div className={`mt-3 ${styles.formBox}`}>
            {/* Address Name */}
            <div className="mb-3">
              <label
                htmlFor="fName"
                className={`form-label ${styles.labelName}`}
              >
                Address Name
              </label>
              <input
                type="text"
                className={`form-control ${styles.formInput}`}
                id="fName"
                aria-describedby="fname"
                placeholder="Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <div
                id="fName"
                className={`form-text ${styles.formText}`}
              >
                {`The name that customers will see when they receive emails from you. Cannot contain these characters: @,;<>[]`}
              </div>
            </div>

            {/* Email Address */}
            <div className="mb-3">
              <label
                htmlFor="mail"
                className={`form-label ${styles.labelName}`}
              >
                Email Address
              </label>
              <input
                type="email"
                value={email}
                className={`form-control ${styles.formInput}`}
                id="mail"
                disabled
              />
            </div>
            {incomingSettingReceiveType !== "IMAP" ? (
              <>
                {/* Email Forwarding Details */}
                <div className="mb-3">
                  <label
                    htmlFor="mail-forwarded"
                    className={`form-label ${styles.labelName}`}
                  >
                    Email Forwarding Details
                  </label>
                  <input
                    type="email"
                    value={forwardEmail}
                    className={`form-control ${styles.formInput}`}
                    id="mail-forwarded"
                    disabled
                  />
                  <div
                    id="fName"
                    className={`form-text ${styles.formText}`}
                  >
                    {`Emails are being forwarded to the address above.`}
                  </div>
                </div>

                {/* Domain Authentication Details */}
                {outgoingType === "currentHostWithDNS" &&
                  authDomainLength > 0 && (
                    <div className="mb-3">
                      <span className={`mb-2 ${styles.labelName}`}>
                        Domain Authentication Details
                      </span>
                      <SetupTable />
                    </div>
                  )}
              </>
            ) : null}

            {/* Done / Save Button */}
            <div className={`d-flex justify-content-between `}>
              <button
                type="button"
                className={`btn py-2 ${styles.connectBtn} `}
                onClick={onPrevious}
              >
                Previous
              </button>
              <button
                type="submit"
                className={`btn py-2 ${styles.connectBtn} `}
                onClick={() => {
                  if (name.trim() === getName.trim()) {
                    onNext();
                  } else {
                    dispatch(
                      fetchUpdateEmailIntegration({
                        emailIntegrationId: idForUpdate,
                        addressName: name,
                      }),
                    );
                  }
                }}
              >
                {name.trim() === getName.trim() ? "Done" : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SetupComplete;
