import {
  CreateCampaignV2Params,
  ESequenceSectionType,
  SequenceStep,
  SequenceStepSection,
} from "src/services/Campaign/createCampaignV2.service";
import { validateURLNew } from "src/utils/utils";
import { AddCampaignContextState } from "../Hooks/useAddCampaignContext";

/**
 * Helper function to check if a string is non-empty.
 * Trims whitespace and checks if the resulting string has content.
 * @param value - The string to validate.
 * @returns Boolean indicating if the string has content.
 */
function isNonEmptyString(value: string | undefined): boolean {
  return Boolean(value?.trim());
}

/**
 * Helper function to validate if an array is defined and non-empty.
 * @param value - The array to validate.
 * @returns Boolean indicating if the array has elements.
 */
function isValidArray(value: Array<any> | undefined): boolean {
  return !!value && value.length > 0;
}

/**
 * Validates the primary details for a campaign.
 * Ensures that all required fields (name, user segment, channels, trigger, and categories) are present and valid.
 * @param state - The campaign state containing primary details.
 * @returns Boolean indicating if the primary details are valid.
 */
function validatePrimaryDetails(state: AddCampaignContextState): boolean {
  return (
    isNonEmptyString(state.name) &&
    !!state.userSegmentId &&
    isValidArray(state.availableChannels) &&
    !!state.triggerId &&
    !!state.categoryId &&
    !!state.subCategoryId &&
    (state.isCategoryDesctiptionRequired ? isNonEmptyString(state.categoryDescription) : true) &&
    (state.isSubCategoryDesctiptionRequired ? isNonEmptyString(state.subCategoryDescription) : true)
  );
}

/**
 * Validates the template selection for a campaign.
 * Checks that sequence, template ID, variant ID, and active template are present.
 * @param state - The campaign state containing template details.
 * @returns Boolean indicating if the template details are valid.
 */
function validateChooseTemplate(state: AddCampaignContextState): boolean {
  return (
    isValidArray(state.sequence) &&
    !!state.templateId &&
    !!state.variantId &&
    !!state.activeTemplate
  );
}

/**
 * Validates a single section within a sequence step based on its type.
 * Throws an error if the section type is unrecognized (exhaustive type-checking).
 * @param section - The section to validate, which includes type and relevant data fields.
 * @throws Error for unhandled section types.
 * @returns Boolean indicating if the section data is valid based on its type.
 */
export function validateStepSection(section: SequenceStepSection): boolean {
  const sectionType = section.sectionType;

  switch (sectionType) {
    case ESequenceSectionType.Text:
      return isNonEmptyString(section.description);

    case ESequenceSectionType.Blog:
      return (
        isValidArray(section.blogs) &&
        section.blogs.every((val) => !!validateURLNew(val))
      );

    case ESequenceSectionType.Feature:
      return (
        isValidArray(section.features) &&
        section.features.every((val) => isNonEmptyString(val))
      );

    case ESequenceSectionType.Product:
      return (
        isNonEmptyString(section.description) &&
        isNonEmptyString(section.name) &&
        !!validateURLNew(section.link)
      );

    case ESequenceSectionType.Closing:
      return isNonEmptyString(section.description);

    case ESequenceSectionType.Introduction:
      return isNonEmptyString(section.description);

    case ESequenceSectionType.Discount:
      return (
        isNonEmptyString(section.type) &&
        (section.type === "percentage" || section.type === "currency") &&
        section.amount >= 0
      );

    default:
      const exhaustiveCheck: never = sectionType; // Ensures type is exhaustive
      throw new Error(`Unhandled section type: ${exhaustiveCheck}`); // Throws an error for unhandled types
  }
}

/**
 * Validates all sequence steps within a campaign.
 * Each step must have a channel, a trigger interval within bounds, and valid sections.
 * @param state - The campaign state containing sequence steps.
 * @returns Boolean indicating if all sequence steps are valid.
 */
function validateSequenceSteps(state: AddCampaignContextState): boolean {
  const isValidStep = (step: SequenceStep) =>
    step.channel &&
    step.triggerAfter >= 0 &&
    step.triggerAfter <= 1000 &&
    state.activeTemplate?.isDefault ? step.sections?.every(validateStepSection) : true;

  return isValidArray(state.sequence) && state.sequence.every(isValidStep);
}

/**
 * Main function to validate each campaign step data based on the current step.
 * Routes validation to the appropriate function depending on the active step.
 * @param state - The current campaign state.
 * @param activeStep - The active step index, determining which validations to apply.
 * @returns Boolean indicating if the current step is valid.
 */
export function validateCampaignStep(
  state: AddCampaignContextState,
  activeStep: number,
): boolean {
  switch (activeStep) {
    case 1:
      // Step 1: Validate primary campaign details
      return validatePrimaryDetails(state);
    case 2:
      // Step 2: Validate template selection
      return validateChooseTemplate(state);
    case 3:
      // Step 3: Validate sequence steps
      return validateSequenceSteps(state);
    case 4:
      // Step 4: Success message step, considered always valid
      return true;
    default:
      // Default case if step is unrecognized
      return false;
  }
}

/**
 * Validates and sanitizes the `AddCampaignContextState` for API payload.
 * @param state - The `AddCampaignContextState` containing campaign data.
 * @returns - A sanitized and validated `CreateCampaignV2Params` payload.
 * @throws - Throws an error if validation fails for required fields.
 */
export function createCampaignPayload(
  state: AddCampaignContextState,
): CreateCampaignV2Params | null {
  const {
    brandId,
    name,
    userSegmentId,
    availableChannels,
    triggerId,
    categoryId,
    subCategoryId,
    sequence,
  } = state;

  // Validate essential fields
  if (
    !brandId ||
    !name ||
    !userSegmentId ||
    !availableChannels?.length ||
    !triggerId ||
    !categoryId
  ) {
    return null;
  }

  // Prepare the sanitized payload
  const payload: CreateCampaignV2Params = {
    brandId,
    name,
    userSegmentId,
    availableChannels,
    triggerId,
    categoryId,
    subCategoryId,
    sequence: sequence.map(sanitizeSequenceStep),
  };

  return payload;
}

/**
 * Sanitizes a sequence step by removing unnecessary frontend-only fields.
 * @param step - A sequence step object to sanitize.
 * @returns - A sanitized sequence step.
 */
function sanitizeSequenceStep(step: SequenceStep): SequenceStep {
  // Create a shallow copy to avoid mutating the original step
  const { id, sections, ...sanitizedStep } = step;

  return {
    ...sanitizedStep,
    sections: sections?.map(({ id, ...rest }) => {
      if (
        rest.sectionType === ESequenceSectionType.Blog ||
        rest.sectionType === ESequenceSectionType.Feature
      ) {
        delete rest.sections;
      }

      return rest;
    }), // Remove frontend-only fields
  };
}
