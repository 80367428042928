import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AddTagsButton from "src/components/AddTagsButton";
import { AssignToDropDown, User } from "src/components/AssignToDropDown";
import SearchBar from "src/components/SearchBar/SearchBar";
import TicketCurrentStatus from "src/components/TicketCurrentStatus";
import UserAvatar from "src/components/UserAvatar";
import { searchCustomers } from "src/services/Customer/searchCustomers";
import {
  fetchMessagesUpdates,
  filterInnerTicketsByActiveFilter,
  resetSearchAllCustomers,
  searchAllCustomers,
  setInnerTicketActiveTags,
  setInnerTicketStatus,
  setInnerTicketUserID,
  setSearchAllCustomersSearchText,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./TicketHeader.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import TicketCurrentStatusV2 from "src/components/TicketCurrentStatusV2";
import { fetchAllStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import saufterImg from "src/assets/images/saufter.gif";
import TicketCurrentStatusDropDown, {
  statusOptions,
} from "src/components/TicketCurrentStatusV2/TicketCurrentStatusDropDown";
import useDebounce from "src/hooks/useDebounce";
import { flushSync } from "react-dom";
import EditPipeline from "./Children/TimelineInfo/EditPipeline/EditPipeline";
import { LastUpdated } from "./Children/TimelineInfo/Children/LastUpdated/LastUpdated";
import { TimelineActionTypes, TimelineProvider, useTimeline } from "./Children/TimelineInfo/TimelineProvider/TimelineProvider";
import CustomerTimeline from "./Children/CustomerTimeline/CustomerTimeline";
import TimelineSelect from "./Children/TimelineInfo/Children/TimelineSelect/TimelineSelect";
import useTimelineScroll from "./Children/TimelineInfo/TimelineProvider/useTimelineScroll";

const AssignToUser = ({
  assignTo,
  ticketId,
  onSelect,
  canChange,
}: {
  assignTo: any;
  ticketId: string;
  onSelect: (assignTo: any) => void;
  canChange: boolean;
}) => {
  const [user, setUser]: [User, Function] = useState({
    id: 0,
    name: "NA",
    email: "NA",
  });
  const assignBtnRef: any = useRef(null);
  const [show, setShow] = useState(false);

  const onClickWindow = useCallback((e: any) => {
    if (assignBtnRef.current) {
      if (!assignBtnRef.current.contains(e.target)) {
        setShow(false);
      }
    }
  }, []);
  useEffect(() => {
    setUser({
      id: assignTo.id,
      name: assignTo.name,
      email: "NA",
      imageUrl: assignTo?.imgURL,
    });
  }, [assignTo]);
  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  return (
    <div ref={assignBtnRef}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className={` ${styles.toolTipCustom}`}>
            <span>Assigned to : {user.name}</span>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div
            {...triggerHandler}
            ref={ref}
          >
            <button
              className={`px-2 ms-2 ms-xl-3 ${styles.ProfileMain} ${
                canChange ? "" : "cursor-disabled"
              } d-flex align-items-center`}
              type="button"
              onClick={(e) => {
                if (canChange) {
                  setShow(!show);
                }
              }}
            >
              {user?.imageUrl && user?.imageUrl.trim().length !== 0 ? (
                <AxiosImg
                  className={`${styles.profileImg} m-auto`}
                  url={user.imageUrl}
                  isDirectURL={user?.isPublicAttachmentUrl}
                />
              ) : (
                <UserAvatar
                  name={user.name}
                  size={100}
                  className={`${styles.profileImg} m-auto`}
                />
              )}
              <span className={`my-auto mx-2`}>{user.name}</span>
              <span className={`${styles.arrowDrop}`}>
                <i
                  className={`fa-solid d-block m-auto fa-caret-${
                    show ? "up" : "down"
                  }`}
                ></i>
              </span>
            </button>
          </div>
        )}
      </OverlayTrigger>
      {canChange && (
        <AssignToDropDown
          selectedUser={assignTo.id}
          show={show}
          onSelect={(user) => {
            setUser(user);
            setShow(false);
            onSelect({ id: user.id, name: user.name, imgURL: user.imageUrl });
          }}
          ticketId={ticketId.split(",").map((id) => parseInt(id))}
        />
      )}
    </div>
  );
};

const TicketHeader = () => {
  const { tableType, ticketId, viewType }: any = useParams();
  // const [customerSearchText, setCustomerSearchText] = useState<string>("")
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const { id } = useAppSelector((state) => state.shopifySidebar.customerData);
  const activeTicketInfo: any = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo,
  );
  const [showTimeline, setShowTimeline] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  // const { state, dispatch: timeLineDispatch} = useTimeline();

  // const ticketListTotal = useAppSelector(
  //   (state) => state.innerTicket.ticketListTotal,
  // );
  const dispatch = useAppDispatch();
  const { allTicketStatuses, allTicketStatusesIds, fetchAllStatusesState } =
    useAppSelector((state) => state.ticketStatus);

  const {
    handleScrollLeft,
    handleScrollRight,
    handleResetScroll,
    hideFirstHeader,
    disableLeft,
    disableRight: disableRightScroll,
    isInitialScroll,
    scrollDivRef,
    checkScroll,
  } = useTimelineScroll(400, timelineRef);

  const disableRight = !hideFirstHeader ? false : disableRightScroll;

  // handle customer search
  /*const handleCustomerSearch = (searchTerm: any) => {
    dispatch(searchAllCustomers());
  };*/

  useEffect(() => {
    if (
      fetchAllStatusesState !== "pending" &&
      allTicketStatusesIds.length === 0
    ) {
      dispatch(fetchAllStatuses());
    }
  }, [fetchAllStatusesState, allTicketStatusesIds]);

  const isTimelineVisible = useMemo(() => {
    const enabled =
      currentUserData?.ui_visibility &&
      currentUserData.ui_visibility?.includes("customer_timeline");

    if (!enabled) {
      // Get the root element
      const r: any = document.querySelector(":root");
      if (r) {
        r.style.setProperty(
          "--inner-ticket-main-chat-container",
          "calc(100vh - 151px)",
        );
      }
    }
    return enabled;
  }, [currentUserData?.ui_visibility]);

  const ticketStatusStyling = useMemo(() => {
    const status = statusOptions.find(
      (option) =>
        option.value === activeTicketInfo?.ticket_status?.ticketStatusName,
    );

    if (status) {
      return {
        color: status.color,
        backgroundColor: status.backGround,
      };
    }

    return {
      color: "#589a38",
      backgroundColor: "#589a38",
    };
  }, [activeTicketInfo?.ticket_status?.ticketStatusName]);

  return (
    <TimelineProvider
      referenceId={id}
      referenceType="customer"
    >
      {" "}
      <div>
        {isTimelineVisible && hideFirstHeader ? (   // add this  state.filters.view === "monthly"
          <div className={`position-relative h-0`}>
            <div className={`d-flex align-items-center ${styles.backPipeline}`} onClick={() => {
              // timeLineDispatch({
              //   type: TimelineActionTypes.SET_FILTER,
              //   payload: {
              //     view: "yearly",
              //     year: 0,
              //   },
              // });
            }}>
              <span className={`${styles.backArrow}`}>
                <i className="fa-solid fa-angle-left"></i>
              </span>
              <span className="ps-1">Full pipeline</span>
            </div>{" "}
          </div>
        ): null}
        {isTimelineVisible && <EditPipeline customerId={id} firstOpenTab="events" editText="Edit pipeline" />}
        <div
          className={`d-flex align-items-center mb-2 py-2 ${styles.ticketHeaderMain}`}
        >
          {isTimelineVisible && (
            <div className="d-flex flex-column">
              <div
                className={`mx-2 ${styles.iconBox} ${
                  hideFirstHeader ? "cursor-pointer" : styles.btnDisabled
                }`}
                onClick={() => {
                  if (hideFirstHeader) {
                    handleScrollLeft();
                  }
                }}
              >
                <i className={`fa-solid fa-chevron-left`}></i>
              </div>
              {hideFirstHeader && (
                <div className="">
                  <div className={`${styles.userInfo}`}>
                    <div
                      className={`d-flex align-items-center ms-1 mb-1 cursor-pointer`}
                      onClick={handleResetScroll}
                    >
                      <div
                        className={`${styles.closeDot}`}
                        style={{
                          backgroundColor: ticketStatusStyling.backgroundColor,
                        }}
                      ></div>
                      <span
                        className={`ps-2 ${styles.ticketStatus}`}
                        style={{ color: ticketStatusStyling.color }}
                      >
                        {activeTicketInfo.ticket_status?.ticketStatusName}
                      </span>
                    </div>
                    {activeTicketInfo.ticket_assigned_to && (
                      <div>
                        <div
                          className="d-flex align-items-center cursor-pointer"
                          onClick={handleResetScroll}
                        >
                          {activeTicketInfo.ticket_assigned_to?.imgURL &&
                          activeTicketInfo.ticket_assigned_to?.imgURL.trim()
                            .length !== 0 ? (
                            <AxiosImg
                              className={`${styles.profileImg}`}
                              url={activeTicketInfo.ticket_assigned_to.imgURL}
                              isDirectURL={
                                activeTicketInfo.ticket_assigned_to
                                  ?.isPublicAttachmentUrl
                              }
                            />
                          ) : (
                            <UserAvatar
                              name={activeTicketInfo.ticket_assigned_to.name}
                              size={100}
                              className={`${styles.profileImg}`}
                            />
                          )}
                          <span className={`ps-1 ${styles.userName}`}>
                            {activeTicketInfo.ticket_assigned_to.name}
                          </span>
                        </div>
                      </div>
                    )}
                    {showTimeline ? <TimelineSelect /> : null}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            ref={scrollDivRef}
            className={`${isTimelineVisible
              ? styles.headerContent
              : styles.headerContentWithoutTimeline
              } scrollbar-hide d-flex ms-5 ${isInitialScroll ? styles.overflowXHidden : ""
              } `}
          >
            <div
              className={`px-2 d-flex justify-content-start align-items-center flex-shrink-0 ${
                styles.minWidth100
              } ${hideFirstHeader ? "d-none" : ""}`}
            >
              <div
                className={`d-flex align-items-center ${styles.infoWrapper}`}
              >
                <div>
                  {activeTicketInfo.ticket_status && (
                    <TicketCurrentStatusDropDown
                      ticketId={ticketId}
                      currentStatus={{
                        id: activeTicketInfo.ticket_status?.ticketStatusId,
                        statusName:
                          activeTicketInfo.ticket_status.ticketStatusName,
                      }}
                      onChange={(status) => {
                        dispatch(setInnerTicketStatus({ status, ticketId }));
                        dispatch(fetchMessagesUpdates());
                        dispatch(filterInnerTicketsByActiveFilter());
                      }}
                      canChange={
                        currentUserData &&
                        currentUserData.scopes &&
                        currentUserData.scopes.includes("change_status")
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
                {/* <div>
            <h5 className={`${styles.linkConv}`}>Linked Conversations </h5>
          </div> */}
                {/* <span className={` ${styles.activeConv}`}>
            <span>{ticketListTotal}</span>
          </span> */}
                {/* AI Handled Tag */}

                {/* {activeTicketInfo.is_ai_handled && (
            <div className="d-flex align-items-center me-3">
              <span className={`${styles.saufterText}`}>AI handled</span>
              <img
                src={saufterImg}
                alt=""
                className={`${styles.saufterImg}`}
              />
            </div>
          )} */}

                <div>
                  {activeTicketInfo.ticket_assigned_to && (
                    <AssignToUser
                      assignTo={activeTicketInfo.ticket_assigned_to}
                      ticketId={ticketId + ""}
                      onSelect={(assignedTo) => {
                        dispatch(
                          setInnerTicketUserID({ assignedTo, ticketId }),
                        );
                        dispatch(fetchMessagesUpdates());
                        dispatch(filterInnerTicketsByActiveFilter());
                      }}
                      canChange={
                        currentUserData &&
                        currentUserData.scopes &&
                        currentUserData.scopes.includes("reassign_ticket")
                          ? true
                          : false
                      }
                    />
                  )}
                </div>

                {activeTicketInfo.ticket_tags !== undefined && (
                  <AddTagsButton
                    ticketIds={ticketId}
                    activeTags={
                      activeTicketInfo.ticket_tags
                        ? activeTicketInfo.ticket_tags
                        : []
                    }
                    setActiveTags={(activeTags) => {
                      dispatch(
                        setInnerTicketActiveTags({
                          tags: activeTags,
                          ticketId,
                        }),
                      );
                      dispatch(fetchMessagesUpdates());
                    }}
                    canChange={
                      currentUserData &&
                      currentUserData.scopes &&
                      currentUserData.scopes.includes("add_tags")
                        ? true
                        : false
                    }
                  />
                )}
              </div>
            </div>
            {isTimelineVisible && (
              <CustomerTimeline
                timelineRef={timelineRef}
                showTimeline={showTimeline}
                onLoad={() => {
                  checkScroll();
                }}
              />
            )}
          </div>
          <div className="mx-4">
            <span
              className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
              onClick={() => setShowTimeline(!showTimeline)}
            >
              <i
                className={`fa-solid fa-chevron-${
                  showTimeline ? "up" : "down"
                }`}
              ></i>
            </span>
          </div>
          {isTimelineVisible && (
            <div
              className={`mx-2 ${styles.iconBox} ${
                disableRight ? styles.btnDisabled : "cursor-pointer"
              }`}
              onClick={handleScrollRight}
            >
              <i className={`fa-solid fa-chevron-right`}></i>
            </div>
          )}
          {/* <SearchBar
        className={`${styles.search} px-2`}
        inputClassName={`${styles.input}`}
        placeholder={`Search Customer`}
        value={customerSearchText}
        onChange={(e: any) => {
          setCustomerSearchText(e.target.value);
          }}
          onSearch={(value: any) => {
            dispatch(resetSearchAllCustomers());
            dispatch(setSearchAllCustomersSearchText({ searchText: value }));
            // handleCustomerSearch(value);
            // // console.log(value);
            }}
            /> */}
        </div>
        {isTimelineVisible && <LastUpdated />}
      </div>
    </TimelineProvider>
  );
};
export default TicketHeader;
