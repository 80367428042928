import styles from "./CampaignAdmin.module.scss";
import CampaignParentTable from "./Children/CampaignParentTable/CampaignParentTable";

const CampaignAdmin = () => {
  return (
    <div className={`w-100 ${styles.serviceWrapper}`}>
      <div className={`${styles.pageTitle} my-3 ms-3`}>Parent</div>
      <div className={`mx-3 pt-2 ps-2 ${styles.bgBox}`}>
        <CampaignParentTable />
      </div>
    </div>
  );
};

export default CampaignAdmin;
