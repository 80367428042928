import React from 'react';
import styles from "./components.module.scss"
interface Props {
  type: "number" | "text" | "decimal";
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
}

const InputElement: React.FC<Props> = ({ type, value, onChange, placeholder, name }) => {
  return (
    <div>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={`d-block px-2 ${styles.stagNameInput} 
            `}
      />
    </div>
  );
};

export default InputElement;
