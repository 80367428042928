import ActionBox from "./Children/ActionBox/ActionBox";
import styles from "./BugManagement.module.scss";
import FilterSection from "./Children/FilterSection/FilterSection";
import emptyBack from "src/assets/images/emptyBack.png";
import { useBugManagementFiltersCreate } from "./Hooks/useBugManagementFilter";
import BugCategoriesView from "./Children/BugCategoriesView/BugCategoriesView";
import useBugFilterAndPagination from "./useBugFilterAndPagination";
import { capitalizeFirstLetter } from "./Children/helper/helperFunction";
import Loader from "src/components/Loader";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import { useEffect, useState } from "react";
import BugBulkAction from "./Children/BugBulkAction/BugBulkAction";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
import { Modal } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import BugReportModal from "./Children/TableRow/Children/BugReportModal/BugReportModal";
import { useQuery } from "@tanstack/react-query";
import getBugById from "src/services/BugReport/getBugById.service";

const BugManagement = () => {
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const searchParamBugId = URLSearchParams.get("bugId");
  const searchParamCategory = URLSearchParams.get("categoryName");
  const [state, dispatch, Provider] = useBugManagementFiltersCreate();
  const [selectedBugId, setSelectedBugId] = useState<Set<number>>(new Set([]));
  const [selectedBugsData, setSelectedBugsData] = useState<BugData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const {
    currentPage,
    bugList,
    changePage,
    totalPages,
    addFilters,
    totalTicketCount,
    pendingForAction,
    setPendingForAction,
    addPending,
    isLoading,
    isError,
  } = useBugFilterAndPagination();

  const { data: modalBugData } = useQuery(["modalbugdata", searchParamBugId], {
    queryFn: () =>
      searchParamBugId ? getBugById({ bugId: searchParamBugId }) : null,
    onSuccess: () => {
      // Show Add seats modal
      setShowModal(true);
    },
    staleTime: 60000,
    cacheTime: 60000,
  });

  return (
    <Provider value={[state, dispatch]}>
      <div className={`${styles.bugManagementWrappper} position-relative`}>
        {selectedBugId.size > 1 && (
          <BugBulkAction
            selectedBugsData={selectedBugsData}
            setSelectedBugId={setSelectedBugId}
          />
        )}
        {/* Header */}
        <div className="d-flex align-items-center">
          {/* Heading */}
          <h2 className={`${styles.managementHead} saufter_h1`}>
            Bug management workflows
          </h2>
          {/* Ticket count */}
          <span className={`ps-2 ${styles.ticketCount}`}>
            {/* {`${state.metaData?.ticketCount ?? 0} Ticket${
            (state.metaData?.ticketCount ?? 0) > 1 ? "s" : ""
          }`} */}
            {totalTicketCount?.totalBugsTicketCount} Tickets
          </span>
        </div>
        <div className="mt-4">
          {/* top filter box */}
          <FilterSection addFilters={addFilters} />
          {/* top action btn */}
          <ActionBox
            addPending={addPending}
            pendingForAction={pendingForAction}
            setPendingForAction={setPendingForAction}
          />
        </div>

        <div className={`${styles.ordersWrapper}`}>
          {bugList?.data?.length === 0 &&
            bugList?.msg === "categories not found" && (
              <div
                className={`${styles.emptyBox} d-flex flex-column justify-content-center align-items-center`}
              >
                <img
                  src={emptyBack}
                  alt="empty bug management self service"
                  className={styles.emptyListImg}
                />
                <span className="my-3">No Bug Management Data Exists</span>
                <Link to={"../automations/bugManagement"}>
                  <button className={styles.emptyAutomationBtn}>
                    + New Bug Management Automation
                  </button>
                </Link>
              </div>
            )}
          {bugList?.data?.length === 0 &&
            bugList.msg === "No bugs pending for action" && (
              <div
                className={`${styles.emptyBox} d-flex flex-column justify-content-center align-items-center`}
              >
                <img
                  src={emptyBack}
                  alt="empty bug management self service"
                  className={styles.emptyListImg}
                />
                <span className="my-3">No bugs pending for action</span>
              </div>
            )}
          {isLoading || isError ? (
            isError ? (
              <h3 className="text-center">Something went wrong..</h3>
            ) : (
              <Loader />
            )
          ) : (
            <div>
              {/* categories of the bug view */}
              {bugList?.data.map((data) => (
                <BugCategoriesView
                  key={data.categoryId}
                  bugsData={data.bugsData}
                  totalBugs={data.bugsTotalCount}
                  categoryName={capitalizeFirstLetter(data.categoryName)}
                  categoryId={data.categoryId}
                  pendingForAction={pendingForAction}
                  selectedBugId={selectedBugId}
                  setSelectedBugId={setSelectedBugId}
                  setSelectedBugsData={setSelectedBugsData}
                />
              ))}

              {/* next and previour btn for bug list pagination for now. May be can change in future. If we have more than 10 categories than a pagination option will be visible */}
              {bugList?.data.length === 10 || currentPage > 1 ? (
                <div className={`ms-2 mt-3`}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={changePage}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {modalBugData && showModal && (
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
          >
            <BugReportModal
              onHide={() => setShowModal(false)}
              id={parseInt(searchParamBugId + "")}
              categoryName={searchParamCategory + ""}
              tableBugStatusUpdate={() => {}}
              bugData={modalBugData}
            />
          </Modal>
        )}
      </div>
    </Provider>
  );
};

export default BugManagement;
