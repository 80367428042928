import React, {
  createContext,
  useRef,
  ReactNode,
  Dispatch,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { CampaignData } from "src/services/Campaign/getCampaignById";

interface CampaignTemplateContextType {
  componentRef?: React.RefObject<HTMLHtmlElement>;
  brand?: CampaignData["brand"];
}

export const CampaignTemplateContext =
  createContext<CampaignTemplateContextType>({});

export const useCampaignTemplateCreate = ({
  brand,
}: {
  brand?: CampaignData["brand"];
}) => {
  const componentRef = useRef<HTMLHtmlElement>(null);

  // Return the context value and provider
  return [{ componentRef, brand }, CampaignTemplateContext.Provider] as [
    CampaignTemplateContextType,
    typeof CampaignTemplateContext.Provider,
  ];
};

export const CampaignTemplateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [contextValue, Provider] = useCampaignTemplateCreate({});

  return <Provider value={contextValue}>{children}</Provider>;
};

// Define the state type
interface CampaignState {
  campaignData: CampaignData | null;
  activeCampaignId: string | number | null;
  activeCampaignEmailId: string | number | null;
  showCernThread: boolean;
  activePromptElement: null | {
    elementType: "text" | "image";
    selectedValue: string;
    callback: (value: string) => void;
  };
  messageRef?: React.MutableRefObject<string>;
}

// Define an enum for action types
export enum CampaignActionType {
  SET_CAMPAIGN_DATA = "SET_CAMPAIGN_DATA",
  SET_ACTIVE_CAMPAIGN_ID = "SET_ACTIVE_CAMPAIGN_ID",
  SET_ACTIVE_CAMPAIGN_EMAIL_ID = "SET_ACTIVE_CAMPAIGN_EMAIL_ID",
  TOGGLE_SHOW_CERN_THREAD = "TOGGLE_SHOW_CERN_THREAD",
  SET_ACTIVE_PROMPT_ELEMENT = "SET_ACTIVE_PROMPT_ELEMENT",
}

// Define action types using the enum
type CampaignAction =
  | { type: CampaignActionType.SET_CAMPAIGN_DATA; payload: CampaignData }
  | {
      type: CampaignActionType.SET_ACTIVE_CAMPAIGN_ID;
      payload: string | number | null;
    }
  | {
      type: CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID;
      payload: string | number | null;
    }
  | { type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD; payload: boolean }
  | {
      type: CampaignActionType.SET_ACTIVE_PROMPT_ELEMENT;
      payload: null | {
        elementType: "text" | "image";
        selectedValue: string;
        callback: (value: string) => void;
      };
    };

// Initial state
const initialState: CampaignState = {
  campaignData: null,
  activeCampaignId: null,
  showCernThread: false,
  activePromptElement: null,
  activeCampaignEmailId: null,
};

// Reducer function
const campaignReducer = (
  state: CampaignState,
  action: CampaignAction,
): CampaignState => {
  switch (action.type) {
    case CampaignActionType.SET_CAMPAIGN_DATA:
      return { ...state, campaignData: action.payload };
    case CampaignActionType.SET_ACTIVE_CAMPAIGN_ID:
      return { ...state, activeCampaignId: action.payload };
    case CampaignActionType.SET_ACTIVE_CAMPAIGN_EMAIL_ID:
      return { ...state, activeCampaignEmailId: action.payload };
    case CampaignActionType.TOGGLE_SHOW_CERN_THREAD:
      return { ...state, showCernThread: action.payload };
    case CampaignActionType.SET_ACTIVE_PROMPT_ELEMENT:
      return { ...state, activePromptElement: action.payload };
    default:
      return state;
  }
};

// Define the context
export const CampaignContext = createContext<
  [CampaignState, React.Dispatch<CampaignAction>]
>([
  {
    campaignData: null,
    activeCampaignId: null,
    showCernThread: false,
    activePromptElement: null,
    activeCampaignEmailId: null,
  },
  () => {},
]);

// Custom hook to create context with a reducer
export const useCampaignCreate = () => {
  // Use the reducer to manage state
  const [state, dispatch] = useReducer(campaignReducer, initialState);
  const messageRef = useRef("");
  const componentRef = useRef<HTMLHtmlElement>(null);

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch = useCallback(
    (action: CampaignAction) => {
      dispatch(action);
    },
    [dispatch],
  );

  // // Example of using useMemo if you need to perform any side effects
  // useMemo(() => {
  //   // Example logic to initialize or update state
  //   if (state.activeCampaignId) {
  //     modifiedDispatch({
  //       type: "SET_ACTIVE_CAMPAIGN_ID",
  //       payload: state.activeCampaignId,
  //     });
  //   }
  // }, [state.activeCampaignId, modifiedDispatch]);

  // Return the state, modified dispatch, and context provider
  return [
    { ...state, messageRef },
    modifiedDispatch,
    CampaignContext.Provider,
  ] as [
    CampaignState,
    React.Dispatch<CampaignAction>,
    typeof CampaignContext.Provider,
  ];
};
