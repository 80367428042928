/**
 * This file defines a React component responsible for rendering single row of article data
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Anubhav-busibud
 */
import React, { useEffect } from "react";
import styles from "./ArticleData.module.scss";
import { Articles } from "src/services/Bot/AnswerSources/getAllExternal.service";
import { Link, useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ParseHTML from "src/components/ParseHTML";
import BotIndexingStatus, { IBotIndexingStatus } from "src/components/BotIndexingStatus/BotIndexingStatus";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import { useMutation } from "@tanstack/react-query";
import { stopImportSourceArticles } from "../../../../../External/stopImportExternalArticle.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { SelectArticlesResponseExternal } from "src/services/Bot/AnswerSources/selectSourceArticles.service";

interface Props {
  article: Articles;
  handleSelectArticle: (
    articleId: number | string,
    isSelected: boolean,

  ) => void;
  isAllArticleSelected: boolean;
  sourceId: number | string;
  updateArticleValue: (articleId: number, values: Partial<Article>) => void
  updateArticlebotTrainingStatus: (externalArticleResponse: SelectArticlesResponseExternal) => void

}

const ArticleData = ({
  article,
  handleSelectArticle,
  isAllArticleSelected,
  sourceId,
  updateArticleValue,
  updateArticlebotTrainingStatus

}: Props) => {

  const stopExternalArticleMutation = useMutation(stopImportSourceArticles, {
    onSuccess: (data) => {

      // Update External article selction 
      updateArticlebotTrainingStatus(data);
      updateArticleValue && updateArticleValue(parseInt(article.articleId + ""), { isSelected: false });
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to stop import",
        type: "danger",
      });
    }
  });
  const onStatusUpdate = () => {
    stopExternalArticleMutation.mutate({ sourceId, articleId: article.articleId });
  }

  return (
    <div
      className={`mt-3 d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}
    >
      <div className={`${styles.article}`}>
        <div className="form-check ms-1 d-flex align-items-center">
          <div>
            <input
              className={`form-check-input ${styles.checkbox}`}
              type="checkbox"
              checked={article.isSelected || isAllArticleSelected}
              onChange={(e) => {
                handleSelectArticle(
                  article.articleId,
                  !(article.isSelected || isAllArticleSelected),
                );
              }}
            />
          </div>
          <OverlayTrigger
            key={"title" + article.articleId}
            placement={"top"}
            overlay={
              <Tooltip className={`${styles.toolTipCustom}`}>
                <div className={`${styles.tooltipBody}`}>
                  <ParseHTML
                    // html={replaceImgTagWithFileName({
                    //   html: ticketCellData[2]?.text ?? "",
                    //   attachments:
                    //     ticketCellData[2]?.attachments ?? [],
                    // })}
                    html={article.articleTitle}
                  />
                </div>
              </Tooltip>
            }
          >
            <label
              className={`form-check-label pe-2 ${styles.articleName}`}
              htmlFor="flexCheckDefault"
            >
              {article.articleTitle}
            </label>
          </OverlayTrigger>
        </div>
      </div>
      <div className={`${styles.articleUrl} pe-2`}>
        <OverlayTrigger
          key={"url" + article.articleId}
          placement={"top"}
          overlay={
            <Tooltip className={`${styles.toolTipCustom}`}>
              <div className={`${styles.tooltipBody}`}>
                <ParseHTML
                  // html={replaceImgTagWithFileName({
                  //   html: ticketCellData[2]?.text ?? "",
                  //   attachments:
                  //     ticketCellData[2]?.attachments ?? [],
                  // })}
                  html={article.articleUrl}
                />
              </div>
            </Tooltip>
          }
        >
          <Link
            className={`${styles.tablePara}`}
            to={article.articleUrl}
            target="_blank"
          >
            {article.articleUrl}
          </Link>
        </OverlayTrigger>
      </div>
      <div className={`${styles.mainSource} pe-2`}>
        <span className={`${styles.articleName}`}>
          {article.articleMainSource}{" "}
        </span>
      </div>
      <div className={`${styles.appearance} pe-2`}>
        <span className={`${styles.articleName} ${styles.appearCount}`}>
          {article.noOfTimesAppeared}
        </span>
      </div>
      <BotIndexingStatus
        botStatus={article.botTrainingStatus}
        failedText={"Training failed"}
        loadingText={"This may take few minutes..."}
        syncedText={"page synced"}
        percentageLoaded={5}
        id={article.articleId}
        sourceId={sourceId}
        sourceType={'External'}
        onStatusUpdate={onStatusUpdate}
        updateArticlebotTrainingStatusEX={updateArticlebotTrainingStatus}
      />
    </div>
  );
};

export default ArticleData;
