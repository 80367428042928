import ConnectAccount from "../../../../ConnectAccount/ConnectAccount";
import EmailProcess from "../EmailProcess/EmailProcess";
import styles from "./ConnectEmailProcess.module.scss";
import backBtn from "src/assets/images/backArrow.png";

import Loader from "src/components/Loader";
import useConnectEmailProcess from "./useConnectEmailProcess";
import { useParams } from "react-router-dom";

function ConnectEmailProcess() {
  const { integrationId } = useParams();

  const { loader, selectedUser, navigate } = useConnectEmailProcess({
    integrationId,
  });

  return (
    <>
      {/* Loader */}
      {loader === "pending" ? (
        <div className={`d-flex flex-column m-auto`}>
          <Loader />
        </div>
      ) : (
        <div
          className={`d-flex-column justify-content-center ${styles.contentWidth}`}
        >
          <div className="d-flex">
            <div
              className={`${styles.backWidth} mt-3 ps-1 `}
              role="button"
              onClick={() => {
                navigate("/settings/integrations/email");
              }}
            >
              <img
                src={backBtn}
                className={`${styles.backBtn}`}
                alt="back"
              />
            </div>
            <div className={`${styles.connectWidth}`}>
              <EmailProcess
                currentStep={1}
                showDelete={false}
                isDomainVerified={selectedUser.isDomainVerified}
                isEmailAdded={selectedUser.isEmailAdded}
                isEmailVerified={selectedUser.isEmailVerified}
              />
              <ConnectAccount
                defaultEmail={selectedUser.emailEnteredByUser}
                defaultName={selectedUser.userName}
                isIntegrated={selectedUser.emailIntegrationId !== -1}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConnectEmailProcess;
