import { useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import blurredReport from "src/assets/images/blurredReport.jpg";
import Loader from "src/components/Loader";
import ReportBar from "src/components/svg/ReportBar";
import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import useTimelineScroll from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/useTimelineScroll";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./CampaignReport.module.scss";
import ReportTimeline from "./Children/ReportTimeline/ReportTimeline";
import { SegmentSection } from "./Children/SegmentSection/SegmentSection";
import CampaignData from "./Children/ViewCampaign/CampaignData/CampaignData";
import ViewCampaign from "./Children/ViewCampaign/ViewCampaign";
import useFetchCampaignReportUsers from "./Hooks/useFetchCampaignReportUsers";
import useFetchSegmentCampaign from "./Hooks/useFetchSegmentCampaign";

const LIMIT = 10;

const items = Array.from({ length: 6 }, (_, index) => index);

const CampaignReport = () => {
  const [showTimeline, setShowTimeline] = useState(true);
  const [viewCampaign, setShowCampaign] = useState(false);
  const [showSegment, setShowSegment] = useState(false);
  const [segmentId, setSegmentId] = useState<string | number | null>(null);

  const timelineRef = useRef<HTMLDivElement>(null);

  const { data: activeSegment, fetchStatus } =
    useFetchSegmentCampaign(segmentId);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    handleScrollLeft,
    handleScrollRight,
    disableLeft,
    disableRight,
    scrollDivRef,
    checkScroll,
  } = useTimelineScroll();

  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return LIMIT * currentPage - LIMIT;
  }, [currentPage]);

  const {
    data: usersData,
    isLoading: usersLoading,
    isError,
  } = useFetchCampaignReportUsers({
    start: start,
    limit: LIMIT,
    segmentId: activeSegment?.segmentId.toString() ?? "",
  });

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return usersData?.metaData?.totalCount
      ? Math.ceil(usersData?.metaData?.totalCount / LIMIT)
      : 0;
  }, [usersData?.metaData?.totalCount]);

  /**
   * Total count available
   */
  const totalCount = useMemo(() => {
    return usersData?.metaData?.totalCount ?? 0;
  }, [usersData?.metaData?.totalCount]);

  if (showSegment) {
    return (
      <SegmentSection
        onBack={(id?: string | number) => {
          setShowSegment(false);
          if (id) {
            setSegmentId(id);
            setCurrentPage(1);
          }
        }}
      />
    );
  }

  return (
    <div className="">
      <div className={`d-flex align-items-center`}>
        <span>
          <ReportBar />
        </span>
        <span className={`ms-2 ${styles.heading}`}>
          {fetchStatus === "pending"
            ? "Loading..."
            : activeSegment?.segmentName ?? "Default Segment"}
        </span>
      </div>
      <TimelineProvider
        referenceId={1}
        referenceType="customer"
      >
        <div className="d-flex align-items-center pe-3 ps-2">
          <div className={`${styles.verticalBar}`}></div>
          <div className="ms-2 me-4">
            <button
              className={`px-1 mt-2 ${styles.segmentBtn}`}
              onClick={() => setShowSegment(true)}
            >
              <span className="pe-1">
                <i className="fa-solid fa-reply"></i>
              </span>
              View all segments
            </button>
          </div>
          <div
            className={`d-flex align-items-center`}
            style={{
              width: "calc(100% - 180px)",
              marginTop: "10px",
            }}
          >
            <div>
              <div
                className={`mx-2 ${styles.iconBox} ${
                  disableLeft ? styles.btnDisabled : "cursor-pointer"
                }`}
                onClick={() => {
                  if (!disableLeft) {
                    handleScrollLeft();
                  }
                }}
              >
                <i className={`fa-solid fa-chevron-left`}></i>
              </div>
            </div>

            <div
              ref={scrollDivRef}
              className={`mt-2 ${styles.headerContent}`}
            >
              <ReportTimeline
                showTimeline={showTimeline}
                timelineRef={timelineRef}
                showLoader={fetchStatus === "pending"}
                timelineInfo={activeSegment?.timelineData}
                onLoad={checkScroll}
              />
            </div>
            <div className="mx-4">
              <span
                className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
                onClick={() => setShowTimeline((prev) => !prev)}
              >
                <i
                  className={`fa-solid fa-chevron-${
                    showTimeline ? "up" : "down"
                  }`}
                ></i>
              </span>
            </div>
            <div>
              <div
                className={`mx-2 ${styles.iconBox} ${
                  disableRight ? styles.btnDisabled : "cursor-pointer"
                }`}
                onClick={() => {
                  if (!disableRight) {
                    handleScrollRight();
                  }
                }}
              >
                <i className={`fa-solid fa-chevron-right`}></i>
              </div>
            </div>
          </div>
        </div>
      </TimelineProvider>
      <div className={`${styles.reportWrapper}`}>
        <p className={`${styles.camapaignHead}`}>
          Campaigns for this segment{" "}
          <span
            className={`ps-1 ${styles.viewBtn}`}
            onClick={() => {
              if (!activeSegment) {
                return;
              }
              setShowCampaign(true);
            }}
          >
            View all campaigns
          </span>
          {fetchStatus === "pending" && (
            <span>
              <Spinner
                className="ms-2"
                size="sm"
              />
            </span>
          )}
        </p>

        <div className={`${styles.reportData}`}>
          {/* loading ui  */}
          {usersLoading ? (
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${styles.loadingWrapper}`}
            >
              <div>
                <Loader className={`${styles.loading} `} />
              </div>
              <h6 className={`${styles.loaderText}`}>
                Hang tight! Loading your next set of users...
              </h6>
            </div>
          ) : isError ? (
            <div
              className={`p-3 ${styles.loadingWrapper} d-flex align-items-center justify-content-center text-danger`}
            >
              Something went wrong!
            </div>
          ) : totalCount > 0 ? (
            <div>
              {usersData?.data?.map((userData) => {
                return <CampaignData userData={userData} />;
              })}
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center gap-2">
              {items.map((val) => {
                return (
                  <div
                    className={`${styles.blurImg} mb-1`}
                    key={val}
                  >
                    <img
                      src={blurredReport}
                      alt="Blurred Report"
                      className="w-100"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {totalCount > 0 ? (
          <div className={`${styles.paginationWrapper} mt-2`}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={viewCampaign}
        onHide={() => setShowCampaign(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
        enforceFocus={false}
      >
        {activeSegment ? (
          <ViewCampaign
            onHide={() => setShowCampaign(false)}
            segmentId={activeSegment.segmentId}
          />
        ) : null}
      </Modal>
    </div>
  );
};

export default CampaignReport;
