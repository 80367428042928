import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import getAllFollowUpReasonsService, {
  AllFollowUpReasons,
} from "src/services/ReturnAutoWorkFlow/ConfigModals/getAllFollowUpReasons.service";
import updateFollowUpReasonsService, {
  IUpdateFollowUpReason,
} from "src/services/ReturnAutoWorkFlow/ConfigModals/updateFollowUpReasons.service";
import getReturnReasonOrderAndItemsFormElementOptions from "src/services/ReturnAutoWorkFlow/OldModals/returnReasonOrderAndItems/getReturnReasonOrderAndItemsFormElementOptions.service";
import { ReasonFollowUpQuestion } from "../../store/slices/ReturnReasonOrderAndItems/returnReasonOrderAndItems.slice";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnPreviewState } from "src/features/ReturnAutoWorkFlow/hooks/useReturnPreview";

export const handleValidateFollowUpQues = (
  data: AllFollowUpReasons,
): IUpdateFollowUpReason["reasons"] | null => {
  const { allReasons, allReasonIds } = data;
  const updatedData: IUpdateFollowUpReason["reasons"] = [];
  const reasonValuesSet = new Set<string>(); // Set to track duplicate values

  for (let i = 0; i < allReasonIds.length; i++) {
    const reason = allReasons[allReasonIds[i]];

    if (reason.reason.value.trim() === "") {
      return null;
    }
    // Check for duplicate reason values
    if (reasonValuesSet.has(reason.reason.value)) {
      return null; // Duplicate found, return null
    }
    reasonValuesSet.add(reason.reason.value.trim()); // Add the value to the set

    const newReason = {
      id: reason.isNew ? null : reason.id,
      value: reason.reason.value,
      moreQuestions: reason.reason.moreQuestions.map((question) => {
        const newQuestion = {
          elementTypeId: question.elementTypeId,
          elementId: question.isNewElement ? null : question.elementId,
          additionalOptions: question.additionalOptions.map((option) => {
            return {
              id: option.isNew ? null : option.id,
              value: option.value,
            };
          }),
          elementValue: question.elementValue,
        };
        return newQuestion;
      }),
    };

    updatedData.push(newReason);
  }

  return updatedData;
};

const useFollowUpQuesModal = (integrationId: string, onSave: () => void) => {
  const [allFollowUpData, setAllFollowUpData] = useState<AllFollowUpReasons>({
    allReasons: {},
    allReasonIds: [],
  });
  const [moreQuestions, setMoreQuestions] = useState<ReasonFollowUpQuestion[]>(
    [],
  );
  const [saved, setSaved] = useState(false);
  const { showPreview, updateReasonsData } = useReturnPreviewState();

  useMemo(() => {
    if (showPreview) {
      updateReasonsData({
        reasons: allFollowUpData.allReasons,
        reasonsIds: allFollowUpData.allReasonIds,
        moreQuestions: moreQuestions,
      });
    }
  }, [showPreview]);

  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ["getAllFollowUpReasonsService", integrationId],
    queryFn: async () => {
      const res = await getAllFollowUpReasonsService({ integrationId });
      return res;
    },
    networkMode: "always",
  });

  useEffect(() => {
    if (data) {
      setAllFollowUpData(data);
    }
  }, [data]);

  const { data: formElements, isLoading: isLoadingForm } = useQuery({
    queryKey: ["getReturnReasonOrderAndItemsFormElementOptions", integrationId],
    queryFn: async () => {
      const res = await getReturnReasonOrderAndItemsFormElementOptions({
        integrationId,
      });
      return res;
    },
    cacheTime: 600000,
    staleTime: 600000,
    networkMode: "always",
  });

  const {
    mutate,
    isLoading: isSaving,
    isError: isSavingError,
  } = useMutation<void, Error, IUpdateFollowUpReason, IUpdateFollowUpReason>({
    mutationKey: ["updateFollowUpReasonsService", integrationId],
    mutationFn: updateFollowUpReasonsService,
    onSuccess() {
      refetch();
      setSaved(true);
      pushTheToast({
        text: "Configuration saved successfully!",
        position: "top-right",
        type: "success",
      });
      onSave();
    },
    onError: (err) => {
      // Show a toast notification on error
      pushTheToast({
        position: "top-right",
        type: "danger",
        text:
          err.message ??
          "Could not update the automation data. Please reload the page.",
      });
    },
  });

  const updateQuestions = useCallback(
    (reasons: IUpdateFollowUpReason["reasons"]) => {
      mutate({ reasons, integrationId });
    },
    [integrationId, mutate],
  );
  return {
    isLoading: isLoading || isLoadingForm,
    formElements: formElements ?? [],
    isSaving,
    isSavingError,
    isError,
    allReasons: allFollowUpData.allReasons ?? {},
    allReasonIds: allFollowUpData.allReasonIds ?? [],
    updateQuestions,
    setAllFollowUpData,
    moreQuestions,
    setMoreQuestions,
    saved,
    setSaved,
  };
};

export default useFollowUpQuesModal;
