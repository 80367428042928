import { useEffect } from "react";
import styles from "./SuccessUi.module.scss";

const Successui = ({ onHide }: { onHide: () => void }) => {
  useEffect(() => {
    const timeout = setTimeout(onHide, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [onHide]);

  return (
    <div>
      <div>
        <div className={`${styles.successCheck}`}>
          <span>
            <i className="fa-solid fa-check"></i>
          </span>
        </div>
      </div>

      <span className={`${styles.doneText}`}>Done!</span>
    </div>
  );
};

export default Successui;
