import { CampaignSegmentKeyAttribute } from "src/services/Campaign/getCampaignById";
import styles from "./AttributeModal.module.scss";

interface Props {
  keyAttributes: Array<CampaignSegmentKeyAttribute>;
  onHide: () => void;
}

const AttributeModal = ({ keyAttributes, onHide }: Props) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-1">
        <h5 className={`${styles.modalHeader}`}>All key attributes</h5>
        <span
          className={`cursor-pointer ${styles.modalClose}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>

      {/* Header */}
      <div className={`mb-1 ${styles.tableHeader}`}>
        <div className={`${styles.attrName}`}>
          <span className={`${styles.headerElement}`}>Attribute name</span>
        </div>
        <div className={`${styles.attrValue}`}>
          <span className={`${styles.headerElement}`}>Value</span>
        </div>
      </div>

      {/* Rows */}
      <div className={`${styles.rowWrapper}`}>
        {keyAttributes.map(({ key, value }, idx) => {
          return (
            <div
              className={`mb-1 ${styles.tableRow} ${
                idx % 2 === 0 ? styles.alternateBg : ""
              }`}
              key={key}
            >
              <div className={`${styles.attrName}`}>
                <span className={`${styles.rowElement}`}>
                  {key[0].toUpperCase() + key.slice(1)}
                </span>
              </div>
              <div className={`${styles.attrValue}`}>
                <span className={`${styles.rowElement}`}>
                  {value ?? "unknown"}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttributeModal;
