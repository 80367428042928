import { axiosJSON } from "src/globals/axiosEndPoints";
import { VariablesData } from "./NewMessage/getMessageTypes.service";

interface GetMessageTypesResponse {
  attributes: Record<string, VariablesData>;
  attributeIds: string[];
}

const getAttributes = async () => {
  const { data: res } = await axiosJSON.get(`/api/segment/getAttributes`, {
    params: { cacheIt: true, cacheTime: Infinity },
  });

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetMessageTypesResponse = {
    attributes: {},
    attributeIds: [],
  };

  if (res.metaData.attributes) {
    Object.values<VariablesData>(res.metaData.attributes).forEach(
      (v: VariablesData) => {
        ret.attributes[v.id] = v;
        ret.attributeIds.push(v.id);
      },
    );
  }

  return ret;
};

export default getAttributes;
