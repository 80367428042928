import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { AJAXSTATUS } from "src/globals/constants";
import { LiveChatCustomerAllMessage } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import { LiveChatHistoryCustomerDataUI } from "./children/ChatCreated/ChatCreated";
import RenderInnerMsg from "./children/RenderInnerMsg/RenderInnerMsg";
import SendMessageWrapper from "./children/SendMessageWrapper/SendMessageWrapper";
import styles from "./HistoryInnerView.module.scss";
import useHistoryInnerMsg from "./hooks/useHistoryInnerMsg";
import { EChannel } from "src/enums/EChannel";
import { useCallback, useEffect, useRef } from "react";

interface HistoryInnerMsgProps {
  historyMessages: LiveChatCustomerAllMessage | null | undefined;
  customerData?: LiveChatHistoryCustomerDataUI;
  fetchNextData: () => void;
  fetchStatus: AJAXSTATUS;
  selectedHistoryId: null | string;
  hasMore: boolean;
  historyMessagesIds: Array<string>;
  customerId?: string;
  fetchLastMessages: () => void;
  smallWindowChat?: boolean;
  internalChat?: boolean;
  segmentListClass?: string | null;
}

function HistoryInnerView({
  historyMessages,
  fetchNextData,
  customerData,
  fetchStatus,
  selectedHistoryId,
  hasMore,
  historyMessagesIds,
  customerId,
  fetchLastMessages,
  smallWindowChat = false,
  internalChat = false,
  segmentListClass = null,
}: HistoryInnerMsgProps) {
  const {
    loader,
    errorMessage,
    scrollToRef,
    scrollClass,
    scrollTopFlag,
    scrollDependencyId,
  } = useHistoryInnerMsg({
    historyMessages,
    smallWindowChat,
    fetchStatus,
    selectedHistoryId,
    historyMessagesIds,
  });
  const scrollToBottomMsg = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef(0);

  //handle scroll and view of the page
  useEffect(() => {
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - prevScrollHeightRef.current;
    }
  }, [historyMessagesIds]);

  // fetch next page data for comment and handle div scroll
  const fetchNextPageHanlder = useCallback(() => {
    fetchNextData();
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      prevScrollHeightRef.current = scrollableDiv.scrollHeight;
    }
  }, [historyMessagesIds]);

  return (
    <div
      className={`${styles.mainChat} ${
        segmentListClass
          ? segmentListClass
          : smallWindowChat
            ? styles.mainChatPopup
            : ""
      } d-flex flex-column`}
    >
      <InfiniteScroll
        className={`p-2 mt-0 mb-auto mx-auto h-100 w-100 ${styles.scrollDiv} ${scrollClass}`}
        hasMoreTop={hasMore}
        loadMoreFromTop={fetchNextPageHanlder}
        scrollToChildRef={scrollToRef}
        root={scrollToBottomMsg}
        scrollToDependency={[scrollDependencyId]}
        scrollTopFlag={scrollTopFlag}
      >
        {loader ? (
          loader
        ) : errorMessage ? (
          errorMessage
        ) : (
          <div className="d-flex flex-column h-100 justify-content-between">
            <div>
              {historyMessagesIds.map((id) => {
                const messageData = historyMessages?.messages[id];
                const theScrollRef =
                  id === scrollDependencyId ? scrollToRef : undefined;
                if (messageData) {
                  return (
                    <RenderInnerMsg
                      scrollToRef={theScrollRef}
                      key={id}
                      messageData={messageData}
                      customerData={customerData}
                    />
                  );
                }

                return null;
              })}
            </div>
            <div className="mt-auto">
              {customerId && (
                <SendMessageWrapper
                  fetchLastMessages={fetchLastMessages}
                  customerId={customerId}
                  smallWindowChat={smallWindowChat}
                  internalChat={internalChat}
                />
              )}
            </div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default HistoryInnerView;
