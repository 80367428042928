import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import deleteIcon from "src/assets/images/deleteInt.png";
import doneIcon from "src/assets/images/doneProcess.png";
import DeleteIntegration from "../DeleteIntegration/DeleteIntegration";
import styles from "./EmailProcess.module.scss";
// import successImg from "src/assets/images/successMsg.png";

interface StepProps {
  stepNumber: number;
  title: string;
  done: boolean;
  active: boolean;
}

/**
 * Step component for rendering email integration step
 */
const EmailIntegrationStep = ({
  stepNumber,
  title,
  done,
  active,
}: StepProps) => (
  <div className={`d-flex align-items-center`}>
    {/* Step Details */}
    <div className={`d-flex align-items-center`}>
      {/* Step Status */}
      {done ? (
        <img
          src={active ? doneIcon : doneIcon}
          alt=""
          className={`${styles.doneImg}`}
        />
      ) : (
        <div>
          {/* Step Number */}
          <span
            className={`d-flex justify-content-center align-items-center ${
              styles.step2
            } ${active ? styles.active : ""}`}
          >
            {stepNumber}
          </span>
        </div>
      )}
      {/* Step Title */}
      <span
        className={`ps-2 ${styles.stepTitle} ${
          active ? styles.activeTitle : ""
        }`}
      >
        {title}
      </span>
    </div>
    {/* Dash between steps */}
    {stepNumber !== 4 && (
      <div
        className={`mx-3 ${styles.borderSpace} ${done && styles.activeBorder}`}
      ></div>
    )}
  </div>
);

interface Props {
  currentStep: number;
  showDelete: boolean;
  isEmailVerified: boolean;
  isDomainVerified: boolean;
  isEmailAdded: boolean;
  hiddenSteps?: Array<"addEmail" | "incoming" | "outgoing">;
}

interface EmailStep {
  key: "addEmail" | "incoming" | "outgoing";
  number: number;
  title: string;
  done: boolean;
}

const AllSteps: EmailStep[] = [
  { key: "addEmail", number: 1, title: "Add E-mail", done: false },
  { key: "incoming", number: 2, title: "Incoming mail settings", done: false },
  { key: "outgoing", number: 3, title: "Outgoing mail settings", done: false },
];

/**
 * Email Process Component to render email integration steps
 */
const EmailProcess = ({
  currentStep,
  showDelete,
  isDomainVerified,
  isEmailAdded,
  isEmailVerified,
  hiddenSteps = [],
}: Props) => {
  const [deleteIntegration, setDeleteIntegration] = useState(false);

  // Delete Integration modal
  const onHide = () => setDeleteIntegration(false);
  const onShow = () => setDeleteIntegration(true);

  /**
   * Memoized Step data
   */
  const steps = useMemo(() => {
    // Filter out hidden steps
    const visibleSteps = AllSteps.filter(
      (step) => !hiddenSteps.includes(step.key),
    );

    // Adjust the step numbering dynamically
    return [
      ...visibleSteps.map((step, index) => ({
        ...step,
        number: index + 1,
        done:
          step.key === "addEmail"
            ? isEmailAdded
            : step.key === "incoming"
              ? isEmailVerified
              : step.key === "outgoing"
                ? isDomainVerified
                : false,
      })),
      {
        number: visibleSteps.length + 1,
        title:
          isEmailVerified && isDomainVerified
            ? "Authentication complete !"
            : "Authentication",
        done: isEmailVerified && isDomainVerified,
      },
    ];
  }, [hiddenSteps, isDomainVerified, isEmailAdded, isEmailVerified]);

  return (
    <div
      className={`d-flex justify-content-between align-items-center py-2 py-md-3 px-2 px-md-3 ms-lg-2 me-0 me-lg-4 mt-2 ${styles.processMain}`}
    >
      {/* Steps */}
      <div className="d-flex align-items-center">
        {steps.map((step, index) => (
          <EmailIntegrationStep
            key={index}
            stepNumber={step.number}
            title={step.title}
            done={step.done}
            active={currentStep === step.number}
          />
        ))}
      </div>

      {/* Delete Button */}
      {showDelete && (
        <div className={`ms-2`}>
          <img
            src={deleteIcon}
            alt=""
            className={`cursor-pointer ${styles.delIcon}`}
            onClick={onShow}
          />
          <Modal
            backdropClassName={`${styles.modalBack}`}
            show={deleteIntegration}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
          >
            <DeleteIntegration onHide={onHide} />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default EmailProcess;
