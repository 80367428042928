import { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import styles from "./style.module.scss";

/**
 * Loader component for Infinite Scroll with loading and clickable load more states
 **/
export const LoadMoreLoader = ({
  status,
  fetchMore,
  variant,
  loadingText = "Loading...",
  loadMoreText = "Load More",
  loadingClassName,
  loadMoreClassName,
  spinnerClassName,
}: {
  /** Status of the loader */
  status: "pending" | "fulfilled" | null;
  /** Function called on clicking the load more button */
  fetchMore: () => void;
  /** Default Variant of color scheme */
  variant?: "blue" | "pink";
  /** Loading Text to be used. Default "Loading..." */
  loadingText?: string;
  /** Load More Button Text to be used. Default "Load More" */
  loadMoreText?: string;
  /** Style class to override the loading div styles */
  loadingClassName?: string;
  /** Style class to override the load more button styles */
  loadMoreClassName?: string;
  /** Style class to override the spinner styles */
  spinnerClassName?: string;
}) => {
  switch (status) {
    case "pending":
      return (
        <div
          className={`${
            loadingClassName ? loadingClassName : styles.loadingText
          }`}
        >
          <span>{loadingText}</span>
          <div>
            <Spinner
              className={`ms-1 ${
                spinnerClassName ? spinnerClassName : styles.spinner
              }`}
              size="sm"
              animation="border"
            />
          </div>
        </div>
      );

    case "fulfilled":
      return (
        <div
          className={`${
            loadMoreClassName ? loadMoreClassName : styles.loaderText
          } ${
            variant === "pink"
              ? styles.pinkBtn
              : variant === "blue"
                ? styles.blueBtn
                : ""
          }`}
          onClick={() => fetchMore()}
        >
          <span>{loadMoreText}</span>
          <i className="ms-1 fa-solid fa-rotate-right"></i>
        </div>
      );

    default:
      return <></>;
  }
};

interface InfiniteProps {
  /** Used to add the class to the scroll div. */
  className?: string;
  /** These are the data rendered inside scroll div. */
  children?: any;
  /** This is the callback function called when the scroller hits the top. */
  loadMoreFromTop?: () => void;
  /** The flag used to check if the loadMoreFromTop can be called. */
  hasMoreTop?: boolean;
  /** This is the callback function called when the scroller hits the bottom. */
  loadMoreFromBottom?: () => void;
  /** The flag used to check if the loadMoreFromBottom can be called. */
  hasMoreBottom?: boolean;
  /** The useRef element to apply on the scroll div. */
  root?: React.MutableRefObject<any>;
  /** It is used to override the loader shown on top or bottom. */
  infiniteLoader?: JSX.Element;
  /** It is used to override the loader shown on top. */
  infiniteLoaderTop?: JSX.Element;
  /** It is used to override the loader shown on bottom. */
  infiniteLoaderBottom?: JSX.Element;
  /** This is the useRef needs to be pre-applied on the element to which the scroll div needs to scroll after rendering. */
  scrollToChildRef?: React.MutableRefObject<any>;
  /** To scroll to the given element, need this dependencyfoe useEffect. */
  scrollToDependency?: Array<any>;
  /** Keep this true if you want to scroll even if scroll-top is not touched. */
  scrollTopFlag?: React.MutableRefObject<boolean>;
  /** Any other prop that can be applied on div element */
  [key: string]: any;
}

function InfiniteScroll({
  className,
  children,
  loadMoreFromTop,
  hasMoreTop,
  loadMoreFromBottom,
  hasMoreBottom,
  root,
  infiniteLoader = (
    <Spinner
      animation="border"
      variant="secondary"
    />
  ),
  infiniteLoaderTop,
  infiniteLoaderBottom,
  scrollToChildRef,
  scrollToDependency = [false],
  scrollTopFlag,
  ...props
}: InfiniteProps) {
  const thisRoot = useRef(null as any);
  const rootRef = root ?? thisRoot;
  const intersectionTopRef = useRef(null as any);
  const intersectionBottomRef = useRef(null as any);
  const intersectedTopRef = useRef(true);
  const intersectedBottomRef = useRef(true);

  useEffect(() => {
    let flag = scrollTopFlag?.current ?? false;
    if (
      scrollToChildRef?.current &&
      (flag || rootRef.current.scrollTop === 0)
    ) {
      scrollToChildRef.current.scrollIntoView();
    }
  }, [...scrollToDependency]);

  useEffect(() => {
    if (
      loadMoreFromTop &&
      hasMoreTop &&
      rootRef.current &&
      intersectionTopRef.current
    ) {
      const options = {
        root: rootRef.current,
        rootMargin: "0px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(() => {
        if (intersectedTopRef.current === false) {
          loadMoreFromTop();
          intersectedTopRef.current = true;
        } else {
          intersectedTopRef.current = false;
        }
      }, options);
      observer.observe(intersectionTopRef.current);
      return () => {
        intersectedTopRef.current = true;
        if (intersectionTopRef.current) {
          observer.unobserve(intersectionTopRef.current);
        }
      };
    }
  }, [hasMoreTop, loadMoreFromTop]);

  useEffect(() => {
    if (
      loadMoreFromBottom &&
      hasMoreBottom &&
      rootRef.current &&
      intersectionBottomRef.current
    ) {
      const options = {
        root: rootRef.current,
        rootMargin: "0px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(() => {
        if (intersectedBottomRef.current === false) {
          loadMoreFromBottom();
          intersectedBottomRef.current = true;
        } else {
          intersectedBottomRef.current = false;
        }
      }, options);
      observer.observe(intersectionBottomRef.current);
      return () => {
        intersectedBottomRef.current = true;
        if (intersectionBottomRef.current) {
          observer.unobserve(intersectionBottomRef.current);
        }
      };
    }
  }, [hasMoreBottom, loadMoreFromBottom]);

  return (
    <div
      className={`${className}`}
      {...props}
      ref={rootRef}
    >
      {hasMoreTop && (
        <>
          <div
            style={{ height: "0px", marginTop: "10px" }}
            ref={intersectionTopRef}
          ></div>
          <div className={`max-content-width m-auto`}>
            {infiniteLoaderTop ? infiniteLoaderTop : infiniteLoader}
          </div>
        </>
      )}
      {children}
      {hasMoreBottom && (
        <>
          <div className={`max-content-width m-auto`}>
            {infiniteLoaderBottom ? infiniteLoaderBottom : infiniteLoader}
          </div>
          <div
            style={{ height: "0px", marginBottom: "10px" }}
            ref={intersectionBottomRef}
          ></div>
        </>
      )}
    </div>
  );
}

export default InfiniteScroll;
