import HistoryInnerView from "src/routes/LiveChat/Children/MainChat/Children/ChatHistoryUpdated/Children/HistoryInnerView/HistoryInnerView";
import useLiveChatHistory from "src/routes/Ticket/children/MainChat/children/InternalBotChat/Children/BotView/useLiveChatHistory";
import styles from "./UserHistoryData.module.scss";

const UserHistoryData = ({
  customerId,
  segmentListClass,
}: {
  customerId: string;
  segmentListClass?: string | null;
}) => {
  const {
    fetchNextPage,
    fetchStatus,
    hasNextPage,
    limitReached,
    historyMessageIds,
    historyMessages,
    fetchLastMessages,
  } = useLiveChatHistory(customerId);

  return (
    <div className={`${styles.historyWrapper}`}>
      <HistoryInnerView
        historyMessages={{
          messages: historyMessages,
          messageIds: historyMessageIds,
          metaData: {
            currentCount: historyMessageIds.length,
            limitReached: limitReached,
          },
        }}
        customerData={undefined}
        fetchNextData={fetchNextPage}
        fetchStatus={fetchStatus}
        selectedHistoryId={null}
        hasMore={hasNextPage ?? false}
        historyMessagesIds={historyMessageIds}
        customerId={customerId}
        fetchLastMessages={fetchLastMessages}
        smallWindowChat={true}
        segmentListClass={segmentListClass}
        internalChat={false}
      />
    </div>
  );
};

export default UserHistoryData;
