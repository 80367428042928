import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentColumn } from "../getSegmentColumns.service";
import { SegmentValueItem } from "../getSegmentValues.service";

export interface MultipleSegmentValuesParams {
  segmentIds: Array<string>;
  start: number;
  limit: number;
  columnsRequired: boolean;
  columns: Array<string>;
}

export interface AllMultipleSegmentValues {
  segmentValues: Record<string, SegmentValueItem>;
  segmentValueIds: Array<string>;
  hasMoreSegmentValues: boolean;
  totalSegmentValues: number;
  allColumns: Record<string, SegmentColumn>;
  allColumnKeys: Array<string>;
}

const getMultipleSegmentValuesService = async (
  payload: MultipleSegmentValuesParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/getMultipleSegmentValues`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: AllMultipleSegmentValues = {
    segmentValues: {},
    segmentValueIds: [],
    hasMoreSegmentValues: false,
    totalSegmentValues: res.metaData?.totalCount ?? 0,
    allColumnKeys: [],
    allColumns: {},
  };

  res.data.values.forEach((item: SegmentValueItem) => {
    item.id = item.id + "";
    ret.segmentValues[item.id] = item;
    ret.segmentValueIds.push(item.id);
  });

  if (payload.columnsRequired) {
    res.data.columns.forEach((column: SegmentColumn) => {
      column.key = column.key + "";
      ret.allColumns[column.key] = column;
      ret.allColumnKeys.push(column.key);
    });
  }

  if (ret.segmentValueIds.length !== payload.limit) {
    ret.hasMoreSegmentValues = false;
  } else {
    ret.hasMoreSegmentValues = true;
  }

  return ret;
};

export default getMultipleSegmentValuesService;
