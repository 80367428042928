import { axiosJSON } from "src/globals/axiosEndPoints";
import { ITemplateVariant } from "./getAllEmailTemplate.service";

export interface GetAllTemplateVariantsParams {
  start: number;
  limit: number;
  categoryId: string | number;
  templateId: string | number;
}

export interface GetAllTemplateVariantsResponse {
  allTemplateVariants: Array<ITemplateVariant>;
  hasMore: boolean;
}

const getAllTemplateVariantsService = async (
  params: GetAllTemplateVariantsParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/templates/getAllVarients`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllTemplateVariantsResponse = {
    allTemplateVariants: res.data,
    hasMore: true,
  };

  if (ret.allTemplateVariants.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
};

export default getAllTemplateVariantsService;
