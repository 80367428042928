import styles from "./SendMsgCommon.module.scss";
import TicketMsg from "../TicketMsg/TicketMsg";
import TicketID from "../TicketID/TicketID";
import ShowMsg from "../ShowMsg/ShowMsg";
import UserAvatar from "src/components/UserAvatar";
import {
  IMessageData,
  clearIntegrationFailure,
  sendMessages,
} from "src/store/slices/innerTicket/innerTicket.slice";
import {
  getDateTimeWithTimeZoneOffset,
  getTimeFromNow,
} from "src/utils/dateLibrary";
import { getChannelFAIcon } from "src/utils/utils";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { ESendTypes } from "../SendMessage/children/SendingAs";
import ShowEventMsg from "../EventMsg/ShowEventMsg";
import DraftMsgComp from "../DraftMsgComp";
import AxiosImg from "src/components/AxiosImg";
import { useCallback, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AJAXSTATUS, POPPER_CONFIG } from "src/globals/constants";
import tryAgainIcon from "src/assets/images/tryAgainIcon.png";
import slack from "src/assets/images/slack.png";
import OtherTickets from "../OtherTickets/OtherTickets";
import MsgSent from "src/components/ShowReadUnread/Children/MsgSent/MsgSent";
import BotMessage from "../BotMessage/BotMessage";
import { BsExclamationDiamondFill } from "react-icons/bs";
import IntegrationFailureText from "src/components/IntegrationFailureModal/children/IntegrationFailureText/IntegrationFailureText";
import ForwardMessage from "../ForwardMessage/ForwardMessage";
import BugMgmtMsg from "../BugMgmtMsg/BugMgmtMsg";
import CernAIMsg from "../CernAIMsg/CernAIMsg";
import SlackEscalateMsg from "../SlackEscalateMsg/SlackEscalateMsg";

export const DropDownMenuData = ({
  messageData,
  labelBy,
}: {
  messageData: IMessageData;
  labelBy: string;
}) => {
  return (
    <Dropdown.Menu
      bsPrefix={`dropdown-menu ${styles.dropWrapper}`}
      popperConfig={POPPER_CONFIG}
    >
      <span className={`${styles.customDropdownItem}`}>
        <span>{new Date(messageData.messageDateTime).toLocaleString()}</span>
      </span>
      {messageData.to && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>To:</span>
          <span className={`${styles.customDropdownItemInner}`}>
            <span className={`${styles.sendMsgCC}`}>{messageData.to}</span>
          </span>
        </span>
      )}
      {messageData.from && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>From:</span>
          <span className={`${styles.customDropdownItemInner}`}>
            <span className={`${styles.sendMsgCC}`}>{messageData.from}</span>
          </span>
        </span>
      )}
      {typeof messageData.bcc === "string" && messageData.bcc && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>Bcc:</span>
          <span
            className={`${styles.customDropdownItemInner} d-flex flex-column`}
          >
            {messageData.bcc.split(",").map((bcc, index) => {
              const comma =
                messageData.bcc.split(",").length > index + 1 ? "," : "";
              return (
                <span
                  key={index}
                  className={`${styles.sendMsgCC}`}
                >
                  {bcc}
                  {comma}
                </span>
              );
            })}
          </span>
        </span>
      )}
      {typeof messageData.cc === "string" && messageData.cc && (
        <span className={`${styles.customDropdownItem} d-flex`}>
          <span className={`me-1 ${styles.prefixOfEmail}`}>Cc:</span>
          <span
            className={`${styles.customDropdownItemInner} d-flex flex-column`}
          >
            {messageData.cc.split(",").map((cc, index) => {
              const comma =
                messageData.cc.split(",").length > index + 1 ? "," : "";
              return (
                <span
                  key={index}
                  className={`${styles.sendMsgCC}`}
                >
                  {cc}
                  {comma}
                </span>
              );
            })}
          </span>
        </span>
      )}
    </Dropdown.Menu>
  );
};

interface Props {
  ticketId: number | string;
  ticketData: any;
  ticketType: "ot" | "ct" | "msg";
  messageId?: number;
  messageData?: IMessageData;
  handleCreatedTemplateCreatedAndSelectedFromMsg?: any;
}

const SendMsgCommon = ({
  ticketId,
  ticketData,
  ticketType,
  messageId,
  messageData,
  handleCreatedTemplateCreatedAndSelectedFromMsg,
}: Props) => {
  const [show, setShow] = useState(false);
  const channelList = useAppSelector((state) => state.innerTicket.channelList);
  const mergedTicketIds: number[] | undefined = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo.merged_tickets,
  );

  const dispatch = useAppDispatch();
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  // const [showCreateTemplateFromMsgModal, setShowCreateTemplateFromMsgModal] = useState<boolean>(false);

  const [toggleDropdownArrow, setToggleDropdownArrow] =
    useState<boolean>(false);

  const dropdownRef = useRef<any>();
  const dropdownArrowSpanRef = useRef<any>();

  const messageChannel = useMemo(
    () =>
      messageData
        ? messageData.channelId === ""
          ? "note"
          : typeof messageData.channelId === "string"
            ? messageData.channelId
            : channelList[messageData.channelId as any]
              ? channelList[messageData.channelId as any].name
              : ""
        : ticketData
          ? ticketData.ticket_channel
          : "",
    [messageData, ticketData, channelList],
  );

  const rightAlign =
    messageData !== undefined
      ? messageData.sentBy === "agent"
      : ticketData.ticket_created_by === "agent";
  const avatarName =
    ticketType === "ct" || messageData === undefined
      ? ticketData
        ? ticketData.ticket_customer_name
        : ""
      : // messageData.sentBy === "agent"?
        messageData.senderName;
  // : ticketData
  // ? ticketData.ticket_customer_name
  // : "";
  const avatarURL =
    ticketType === "ct" || messageData === undefined
      ? null
      : messageData.senderImgURL;
  // check the message belongs to a merged ticket
  let messageTicketId = messageData?.ticketId ?? -1;
  let isMergedTicketId = mergedTicketIds?.includes(messageTicketId);
  if (isMergedTicketId == true) {
    ticketType = "msg";
  } else {
  }
  // now event is showing only for current active ticket messages
  let isEvent = ticketType == "msg" && messageData?.messageType == "Event";

  // This is the function that is called when the user clicks on the "Try Again" button
  const handleSendTryAgainMessage = useCallback(() => {
    if (messageData?.tryAgainPayload) {
      // dispatch(messageSendTryAgainPressed());
      const unsentMessages = JSON.parse(
        localStorage.getItem("unsentMessages") || "[]",
      );
      const unsentMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.messageId === messageData.messageId,
      );
      if (unsentMessageIndex !== -1) {
        unsentMessages.splice(unsentMessageIndex, 1);
        localStorage.setItem("unsentMessages", JSON.stringify(unsentMessages));
      }
      // clearing previous integration failure before trying again
      dispatch(
        clearIntegrationFailure({
          uuid: messageData.messageUuid,
        }),
      );
      dispatch(
        sendMessages({
          ...messageData?.tryAgainPayload,
          message: messageData.message,
          isTryAgain: true,
        }),
      );
    }
  }, [messageData?.tryAgainPayload]);

  // This is the function that is called when the message fails to send due to a network error
  const handleSendFailedMessage = useCallback(() => {
    if (messageData) {
      const unsentMessagesString = localStorage.getItem("unsentMessages");
      const unsentMessages = unsentMessagesString
        ? JSON.parse(unsentMessagesString)
        : [];

      // Check if the array already contains a message with the same messageId
      const existingMessageIndex = unsentMessages.findIndex(
        (msg: any) => msg.messageId === messageData.messageId,
      );
      if (existingMessageIndex === -1) {
        // If the array does not contain a message with the same messageId, push the new message into the array
        unsentMessages.push(messageData);
        // Save the updated array to localStorage
        localStorage.setItem("unsentMessages", JSON.stringify(unsentMessages));
      } else {
        // If an existing message is found, check if integrationData has changed
        const existingMessage = unsentMessages[existingMessageIndex];
        const existingIntegrationData = existingMessage?.integrationFailure;
        const newIntegrationData = messageData?.integrationFailure;
        if (
          JSON.stringify(existingIntegrationData) !==
          JSON.stringify(newIntegrationData)
        ) {
          // If integrationData has changed, update the existing message
          unsentMessages[existingMessageIndex] = messageData;
          // Save the updated array to localStorage
          localStorage.setItem(
            "unsentMessages",
            JSON.stringify(unsentMessages),
          );
        }
      }
    }
  }, [messageData]);

  const tryAgainRendering = () => {
    handleSendFailedMessage();
    return (
      <div className={`d-flex justify-content-end mt-1`}>
        <span className={`${styles.failedText}`}>Failed....</span>
        {/* Show integration failure text if the message failed due to integration failure */}
        {messageData?.integrationFailure && (
          <span className="d-flex">
            <span className={styles.tryText}>
              {messageData?.integrationFailure?.brand?.email}
            </span>
            <IntegrationFailureText
              integrationFailure={messageData.integrationFailure}
            />
          </span>
        )}
        <button
          id="tryAgain"
          className={` border-0 bg-white d-flex align-items-center ${styles.tryText}`}
          onClick={() => {
            handleSendTryAgainMessage();
          }}
        >
          <img
            src={tryAgainIcon}
            alt=""
            className={`me-1 ${styles.tryAgainIcon}`}
          />
          <span> Try again</span>
        </button>
      </div>
    );
  };

  if (
    messageData &&
    (messageData.messageType === "MessageBot" ||
      messageData.messageType === "DraftMessageBot")
  ) {
    return <BotMessage messageData={messageData} />;
  } else if (
    messageData &&
    messageData.messageType === "Forward" &&
    ticketType == "msg"
  ) {
    return (
      <div>
        <ForwardMessage
          messageChannel={messageChannel}
          tryAgainRendering={tryAgainRendering}
          userTimezone={userTimezone}
          messageData={messageData}
        />
      </div>
    );
  } else if (
    messageData &&
    messageData.messageType === "MessageBugManagement"
  ) {
    return <BugMgmtMsg messageData={messageData} />;
  } else if (messageData && messageData.messageType === "NoteBotAiQuery") {
    return <CernAIMsg messageData={messageData} />;
  } else if (messageData && messageData.messageType === "BotAiEscalatedNote") {
    return <SlackEscalateMsg messageData={messageData} />;
  }

  if (
    ticketType === "msg" &&
    messageData?.isDraftMessage &&
    messageId !== undefined
  ) {
    return (
      <DraftMsgComp
        messageData={messageData}
        messageId={messageId}
      />
    );
  } else {
    return (
      <>
        {isEvent == false &&
        (messageData?.message !== "" || messageData?.attachments?.length
          ? true
          : false) ? (
          <div
            className={`d-flex justify-content-start align-items-center ${
              rightAlign ? "flex-row-reverse" : ""
            } ${styles.msgMargin} ${rightAlign ? styles.right : styles.left} ${
              styles.mSet
            }`}
          >
            <div
              className={`d-flex flex-column align-items-center ${
                styles.profileSec
              } ${ticketType === "ot" ? styles.ot : ""}`}
            >
              <div>
                {avatarURL ? (
                  <AxiosImg
                    url={avatarURL}
                    className={`${styles.profileIcon}`}
                    isDirectURL={messageData?.isPublicAttachmentUrl}
                  />
                ) : (
                  <UserAvatar
                    className={`${styles.profileIcon}`}
                    name={avatarName || "NA"}
                    size={35}
                  />
                )}
              </div>

              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  as="span"
                  ref={dropdownRef}
                  bsPrefix={`dropdown me-1 ${styles.insta}`}
                >
                  {messageData?.channelId === 222 ? ( // if channel is slack show this image
                    <img
                      src={slack}
                      alt="slack channel"
                      width={12}
                      height={12}
                    />
                  ) : (
                    <i
                      className={`d-block cursor-pointer dropdown-toggle ${getChannelFAIcon(
                        messageChannel,
                      )} override ${styles.dropdownToggle}`}
                      id={`dropdownMenuButton1`}
                      data-bs-toggle={`dropdown`}
                      aria-expanded={`false`}
                    ></i>
                  )}{" "}
                </Dropdown.Toggle>
                {messageData && (
                  <DropDownMenuData
                    messageData={messageData}
                    labelBy={"dropdownMenuButton1"}
                  />
                )}
              </Dropdown>
            </div>
            <div className={`mx-3 ${styles.messageParentEle}`}>
              <div
                style={{
                  width: "auto",
                  marginLeft: rightAlign ? "auto" : undefined,
                  marginRight: rightAlign ? undefined : "auto",
                }}
              >
                {/* {(ticketType === "msg" || isMergedTicketId) && <TicketMsg messageData={messageData} />} */}
                {ticketType === "msg" && (
                  <TicketMsg
                    messageData={messageData}
                    rightAlign={rightAlign}
                    handleCreatedTemplateCreatedAndSelectedFromMsg={(
                      selectedTemplateText: any,
                    ) => {
                      handleCreatedTemplateCreatedAndSelectedFromMsg(
                        selectedTemplateText,
                      );
                    }}
                  />
                )}
                {/* {ticketType === "bug_management" && (
                  <BugMgmtMsg messageData={messageData} />
                )} */}
                {ticketType !== "ct" && ticketType !== "ot" && messageData && (
                  <>
                    {messageData?.messageInfo && (
                      <div
                        className="mx-2 w-100 pe-2"
                        style={{
                          float: rightAlign ? "left" : "right",
                          height: "12px",
                          paddingTop: "5px",
                          paddingLeft: rightAlign ? "30px" : undefined,
                          paddingRight: rightAlign ? undefined : "30px",
                        }}
                      >
                        <MsgSent status={messageData.messageInfo} />
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* part that shows other ticket data */}
              {/* {ticketType === "ot" && !isMergedTicketId && messageData && (
          <ShowMsg right={rightAlign} messageData={messageData} />
        )} */}
              {ticketType === "ot" && messageData && (
                <OtherTickets
                  right={rightAlign}
                  messageData={messageData}
                />
              )}
              {/* Part that shows current ticket, ticket id date */}
              {ticketType === "ct" && ticketData && (
                <TicketID
                  right={rightAlign}
                  ticketId={ticketId}
                  date={ticketData.ticket_created_date}
                />
              )}
              {/* Dropdown with date, cc,bcc, to, from */}
              {/* {(ticketType !== "ct" && messageData || isMergedTicketId && messageData) && ( */}

              {ticketType !== "ct" && messageData && (
                <>
                  {messageData.messageStatus === "pending" ? (
                    <span
                      className={`d-flex justify-content-end mt-1 ${styles.sending}`}
                    >
                      Sending...
                    </span>
                  ) : messageData.messageStatus === "rejected" ? (
                    tryAgainRendering()
                  ) : (
                    <span
                      className={`${styles.created} d-flex justify-content-end`}
                      style={{ float: rightAlign ? "right" : "left" }}
                    >
                      <div
                        className={`dropdown d-flex ${
                          rightAlign
                            ? "justify-content-end"
                            : "justify-content-start"
                        }`}
                        ref={dropdownRef}
                      >
                        <div>
                          <Dropdown onToggle={setShow}>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              as="div"
                              ref={dropdownRef}
                              bsPrefix={`dropdown-toggle cursor-pointer ${styles.sendDrop} ${styles.dropdownToggle}`}
                            >
                              {`${getTimeFromNow({
                                date: new Date(messageData.messageDateTime),
                                timeZone: userTimezone,
                              })}${
                                ((messageData.messageType as any) ===
                                  ESendTypes[1] ||
                                  messageData.messageType === "Forward") &&
                                messageData.from
                                  ? `, Via: ${
                                      messageChannel === "Instagram"
                                        ? "Instagram DM"
                                        : messageData.from
                                    }`
                                  : ""
                              }`}
                              <span ref={dropdownArrowSpanRef}>
                                {" "}
                                {show ? (
                                  <i className={`fa-solid fa-caret-up`}></i>
                                ) : (
                                  <i className={`fa-solid fa-caret-down`}></i>
                                )}
                              </span>
                            </Dropdown.Toggle>
                            <DropDownMenuData
                              messageData={messageData}
                              labelBy={"dropdownMenuButton2"}
                            />
                          </Dropdown>
                          {/* if integration failure then show below ui  */}
                          {/* {tryAgainRendering()} */}
                        </div>
                      </div>
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          // show the event message
          messageData &&
          messageData?.message !== "" && (
            <ShowEventMsg messageData={messageData} />
          )
        )}
      </>
    );
  }
};

export default SendMsgCommon;
