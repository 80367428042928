import { axiosJSON } from "src/globals/axiosEndPoints";

export interface SegmentColumn {
  key: string;
  name: string;
  /**
   * Disable un-selecting this column and required in payload.
   */
  required?: boolean;
  selected?: boolean;
  canBeSorted?: boolean;
  description?: string;
}

export interface SegmentColumnsResponse {
  allColumns: Record<string, SegmentColumn>;
  allColumnKeys: Array<string>;
}

export interface SegmentColumnsPayload {
  segmentType: string;
  segmentId: string;
  columnType: "default" | "custom";
  start?: number;
  limit?: number;
}

const getSegmentColumnsService = async (
  payload: SegmentColumnsPayload,
): Promise<SegmentColumnsResponse> => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${payload.segmentType}/getColumns`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: SegmentColumnsResponse = {
    allColumnKeys: [],
    allColumns: {},
  };

  res.data.forEach((item: SegmentColumn) => {
    item.key = item.key + "";
    ret.allColumns[item.key] = item;
    ret.allColumnKeys.push(item.key);
  });

  return ret as SegmentColumnsResponse;
};

export default getSegmentColumnsService;
