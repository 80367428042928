import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import Select, { GroupBase, MultiValue, StylesConfig } from "react-select";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { getBugPriority } from "src/services/Automation/DispatchRulesConfigModal/getBugPriority.service";
import { ListOption } from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import {
  BugCategoryQuestionValue,
  ISimpleListOption,
  QuestionVisibility,
} from "src/services/Automation/getAutomationById.service";
import { useDispatchSectionContext } from "../../Hooks/useDispatchSectionContext";
import styles from "./DispatchConditions.module.scss";

interface Option {
  label: string;
  value: string;
}

const IS_MULTI = true;

const defaultStyles: StylesConfig<
  Option,
  typeof IS_MULTI,
  GroupBase<Option>
> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: "37px",
    "&:hover": {
      borderColor: "#BBBBBB",
    },
    border: state.isFocused ? "0.5px solid #0B68BB" : "1px solid #BBBBBB",
  }),
  multiValue: (provided, state) => {
    // Default styles for multi-value items
    const defaultStyles = {
      ...provided,
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
    };

    // Determine the background color based on the option's value
    let backgroundColor = "#F5F5F5";
    let color = "";
    switch (state.data.label) {
      case "I need this fixed right away":
        backgroundColor = "#FFD8D8";
        color = "#C53737";
        break;
      case "High":
        backgroundColor = "#D8FFDD";
        color = "#0E680E";
        break;
      case "Normal":
        backgroundColor = "#D8F4FF";
        color = "#181082";
        break;
      // Add more cases as needed for other option values
      default:
        break;
    }

    return {
      ...defaultStyles,
      backgroundColor: backgroundColor,
      color: color,
    };
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueRemove: (provided, state) => {
    // Default styles for the remove button
    const defaultStyles = {
      ...provided,
      ":hover": {
        color: "#707070", // Default hover color if no value is matched
      },
    };

    // Determine the color based on the option's value
    let color = ""; // Default color if no value is matched

    switch (state.data.value) {
      case "I need this fixed right away":
        color = "#C53737";
        break;
      case "High":
        color = "#0E680E";
        break;
      case "Normal":
        color = "#181082";
        break;
      // Add more cases as needed for other option values
      default:
        break;
    }

    return {
      ...defaultStyles,
      color: color,
      ":hover": {
        color: color, // Hover color matches the option's color
      },
    };
  },
  option: (provided, state) => {
    let backgroundColor = "#FFF";
    let color = "#000";

    // Custom styling based on option value
    if (state.isSelected) {
      backgroundColor = "#007bff";
      color = "#FFF";
    } else if (state.data.label === "I need this fixed right away") {
      backgroundColor = "#FFD8D8";
      color = "#C53737";
    } else if (state.data.label === "High") {
      backgroundColor = "#D8FFDD";
      color = "#0E680E";
    } else if (state.data.label === "Normal") {
      backgroundColor = "#D8F4FF";
      color = "#181082";
    }

    return {
      ...provided,
      backgroundColor,
      color,
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
      cursor: "pointer",
      width: "fit-content",
      borderRadius: "6px",
      marginBottom: "8px",
      ":hover": {
        backgroundColor: "",
        borderRadius: "6px",
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    padding: "10px",
    width: "fit-content",
    minWidth: "290px",
  }),
};

const transformSelectedValues = (selectedValues: ListOption[]): Option[] =>
  selectedValues.map(
    (value) => ({ label: value.name, value: value.id + "" }) as Option,
  );

const USER_SEGMENT_BUG_QUESTION_KEY = "allowedBugReportUserSegments";
const USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY =
  "allowedIssueDispatchUserSegments";
const CATEGORY_QUESTION_KEY = "bugCategories";

const useDispatchConditions = () => {
  const { updateState, state, automationType, showErrors, setShowErrors } =
    useDispatchSectionContext();
  const { formData } = useAutomationContext();

  const {
    data: bugPriorities,
    isLoading,
    isError,
  } = useQuery(["getBugPriority"], {
    queryFn: getBugPriority,
    staleTime: 360000,
    cacheTime: 360000,
    enabled: automationType === EAutomationType.BUG_MANAGEMENT,
  });

  // Get the user segments selected by user from main flow context
  const { userSegmentsFromContext, showUserSegment } = useMemo(() => {
    const questionKey =
      automationType === EAutomationType.BUG_MANAGEMENT
        ? USER_SEGMENT_BUG_QUESTION_KEY
        : automationType === EAutomationType.ISSUE_DISPATCH
          ? USER_SEGMENT_ISSUE_DISPATCH_QUESTION_KEY
          : "";

    const question = formData.questionsData[questionKey];

    return question
      ? {
          userSegmentsFromContext: question.listOfOptions
            ? (question.listOfOptions as ISimpleListOption[])
            : [],
          showUserSegment:
            question.visibility !== QuestionVisibility.RelativeHidden,
        }
      : {
          userSegmentsFromContext: [],
          showUserSegment: false,
        };
  }, [formData.questionsData, automationType]);

  // Get the categories selected by user from main flow context
  const categoriesFromContext = useMemo(() => {
    const question = formData.questionsData[CATEGORY_QUESTION_KEY];
    return question
      ? (question.questionValue as BugCategoryQuestionValue[])
      : [];
  }, [formData.questionsData]);

  const { userSegmentDetails, bugCategoryDetails, bugPriorityDetails } = state;

  const userSegments = useMemo(() => {
    return (
      userSegmentsFromContext?.map((segment) => ({
        label: segment.value,
        value: segment.id + "",
      })) || []
    );
  }, [userSegmentsFromContext]);

  const categories = useMemo(() => {
    return (
      categoriesFromContext?.map((category) => ({
        label: category.name,
        value: category.id ? category.id + "" : "",
      })) || []
    );
  }, [categoriesFromContext]);

  const urgencyList = useMemo(() => {
    if (!bugPriorities) {
      return [];
    }

    return (
      bugPriorities.allBugPriorityIds?.map((priority) => ({
        label: bugPriorities.allBugPriority[priority].name,
        value: bugPriorities.allBugPriority[priority].id + "",
      })) || []
    );
  }, [bugPriorities]);

  const selectedSegments = useMemo(
    () => transformSelectedValues(userSegmentDetails ?? []),
    [userSegmentDetails],
  );
  const selectedCategories = useMemo(
    () => transformSelectedValues(bugCategoryDetails ?? []),
    [bugCategoryDetails],
  );
  const selectedUrgencyList = useMemo(
    () => transformSelectedValues(bugPriorityDetails ?? []),
    [bugPriorityDetails],
  );

  const handleChange = useCallback(
    (key: "userSegmentDetails" | "bugCategoryDetails" | "bugPriorityDetails") =>
      (selectedOptions: MultiValue<Option>) => {
        const selectedValues: ListOption[] = selectedOptions.map((op) => ({
          id: op.value,
          name: op.label,
        }));

        setShowErrors(false);
        updateState({
          [key]: selectedValues,
        });
      },
    [setShowErrors, updateState],
  );

  return {
    selectedSegments,
    selectedCategories,
    selectedUrgencyList,
    automationType,
    urgencyList,
    categories,
    userSegments,
    showUserSegment,
    isLoading,
    isBugPriorityError: isError,
    handleChange,
    showErrors,
  };
};

const DispatchConditions = () => {
  const {
    automationType,
    categories,
    handleChange,
    isBugPriorityError,
    isLoading,
    selectedCategories,
    selectedSegments,
    selectedUrgencyList,
    showUserSegment,
    urgencyList,
    showErrors,
    userSegments,
  } = useDispatchConditions();

  return (
    <div className={`w-100 ${styles.ruleBox}`}>
      <div className={`mt-4 mb-4 ${styles.selectBox}`}>
        <h4 className={`${styles.dispatchHeader}`}>Dispatch if:</h4>

        {showUserSegment && (
          <div className="mb-3">
            <label
              htmlFor="bug_segmentSelect"
              className={`mb-2 ${styles.label}`}
            >
              Select user segments:
            </label>
            <div className={`${styles.selectBox}`}>
              <Select
                styles={defaultStyles}
                options={userSegments}
                placeholder="Choose user segments"
                onChange={handleChange("userSegmentDetails")}
                isSearchable={true}
                isMulti={IS_MULTI}
                id="bug_segmentSelect"
                value={selectedSegments}
                className={`${
                  showErrors && selectedSegments.length === 0
                    ? styles.errorBorder
                    : ""
                }`}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                }}
              />
            </div>
          </div>
        )}

        {automationType === EAutomationType.BUG_MANAGEMENT && (
          <div className="mb-3">
            <label
              htmlFor="bug_categorySelect"
              className={`mb-2 ${styles.label}`}
            >
              Category:
            </label>
            <div className={`${styles.selectBox}`}>
              <Select
                styles={defaultStyles}
                options={categories}
                placeholder="Choose category"
                onChange={handleChange("bugCategoryDetails")}
                isSearchable={true}
                isMulti={IS_MULTI}
                id="bug_categorySelect"
                value={selectedCategories}
                className={`${
                  showErrors && selectedCategories.length === 0
                    ? styles.errorBorder
                    : ""
                }`}
              />
            </div>
          </div>
        )}

        {automationType === EAutomationType.BUG_MANAGEMENT && (
          <div className="mb-3">
            <label
              htmlFor="bug_urgencySelect"
              className={`mb-2 ${styles.label}`}
            >
              Urgency reported by the user:
            </label>
            <div className={`${styles.selectBox}`}>
              <Select
                styles={defaultStyles}
                options={urgencyList}
                placeholder="Choose urgency"
                onChange={handleChange("bugPriorityDetails")}
                isSearchable={true}
                isMulti={IS_MULTI}
                id="bug_urgencySelect"
                isLoading={isLoading}
                value={selectedUrgencyList}
                className={`${
                  showErrors && selectedUrgencyList.length === 0
                    ? styles.errorBorder
                    : ""
                }`}
              />
            </div>
            {isBugPriorityError && (
              <p className="text-danger">Could not load Bugs Priority data</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DispatchConditions;
