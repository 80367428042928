import { CustomerData } from "../../Hooks/useUploadNewCsvModalContext";
import styles from "./AttrTableRows.module.scss";
const AttrTableRows = ({
  customers,
  customerIds,
}: {
  customers: {
    [customerId: string]: CustomerData;
  };
  customerIds: Array<string>;
}) => {
  return (
    <div className={`${styles.tableHeader} d-flex align-items-stretch`}>
      {/* User details cell */}
      <div
        className={`${styles.colHeadLabel} d-flex align-items-start flex-shrink-0 ${styles.productPreview}`}
      >
        <span className={`${styles.element} mt-3`}>User details</span>
      </div>

      {/* Rows data */}
      <div className={`${styles.rows}`}>
        {customerIds.map((customerId) => {
          const customer = customers[customerId];
          return (
            <div className={`${styles.tableHeader}`}>
              {customer.rowData.map((row, index) => (
                <div
                  key={index}
                  className={`${styles.colHead} ${styles.rowData}`}
                >
                  {row?.value as string}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttrTableRows;
