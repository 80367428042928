import backBtn from "src/assets/images/backArrow.png";
import Loader from "src/components/Loader";
// import EmailAddress from "../EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/EmailAddress/EmailAddress";
// import CurrentHost from "../EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailOption/CurrentHostOption/CurrentHost";
import OutgoingMailSetting from "../EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailSetting/OutgoingMailSetting";
import EmailProcess from "../EmailIntegration/children/AddEmail/Children/AddModal/EmailProcess/EmailProcess";
import SetupComplete from "../EmailIntegration/children/AddEmail/Children/AddModal/SetupComplete/SetupComplete";
import ConnectAccount from "../EmailIntegration/children/ConnectAccount/ConnectAccount";
import DomainVerification from "../EmailIntegration/children/DomainVerification/DomainVerification";
import styles from "./OutboundEmailIntegration.module.scss";
import outboundEmailIntegrationUtils from "./outboundEmailIntegration.utils";
import useOutboundEmailIntegration from "./useOutboundEmailIntegration";

export enum EOutboundEmailSteps {
  AddEmail = "addEmail",
  OutgoingEmails = "outgoingEmails",
  Complete = "complete",
}

export type OutboundEmailIntegrationParams = {
  activeStep: EOutboundEmailSteps;
};

const OutboundEmailIntegration = () => {
  const {
    activeStep,
    isLoading,
    selectedUser,
    disableNextButton,
    currentStep,
    selectedType,
    checkingAuth,
    handleSelect,
    navigate,
  } = useOutboundEmailIntegration();

  return (
    <div
      className={`d-flex-column justify-content-center ${styles.contentWidth}`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex">
            <div
              className={`${styles.backWidth} mt-3 ps-1 `}
              role="button"
              onClick={() => {
                navigate("/settings/browse-integration");
              }}
            >
              <img
                src={backBtn}
                className={`${styles.backBtn}`}
                alt="back"
              />
            </div>
            <div className={`${styles.connectWidth}`}>
              <EmailProcess
                currentStep={currentStep}
                showDelete={false}
                isDomainVerified={selectedUser.isDomainVerified}
                isEmailAdded={selectedUser.isEmailAdded}
                isEmailVerified={selectedUser.isEmailVerified}
                hiddenSteps={["incoming"]}
              />
              {activeStep === EOutboundEmailSteps.AddEmail && (
                <ConnectAccount
                  defaultEmail={selectedUser.emailEnteredByUser}
                  defaultName={selectedUser.userName}
                  isIntegrated={selectedUser.emailIntegrationId !== -1}
                  navigateCallback={() =>
                    navigate(
                      outboundEmailIntegrationUtils.getNavigation(
                        "next",
                        activeStep,
                      ),
                    )
                  }
                />
              )}
              {activeStep === EOutboundEmailSteps.OutgoingEmails && (
                <>
                  <OutgoingMailSetting
                    selectedType={selectedType}
                    preferred={
                      selectedUser.outgoingMailSettings?.preferredReceiveType ??
                      "currentHostWithDNS"
                    }
                    onSelect={handleSelect}
                    isOutboundEmail={true}
                  />
                  {checkingAuth ? (
                    <Loader />
                  ) : (
                    <>
                      {selectedType &&
                      (selectedType === "currentHostWithDNS" ||
                        selectedType === "gmail") ? (
                        <DomainVerification
                          isGmail={selectedType === "gmail"}
                          isOutboundEmail={true}
                        />
                      ) : (
                        // ) : selectedType === "SMTP" ? (
                        //   <EmailAddress
                        //     type="outgoing"
                        //     isStepDone={selectedUser.isDomainVerified}
                        //   />
                        // ) : selectedType === "currentHost" ? (
                        //   <CurrentHost />
                        <></>
                      )}
                    </>
                  )}
                </>
              )}

              {activeStep === EOutboundEmailSteps.OutgoingEmails && (
                <div
                  className={`ms-2 mt-3 d-flex justify-content-between align-items-center ${styles.pageWrap}`}
                >
                  <button
                    className={`py-2 px-md-3 ${styles.prevBtn}`}
                    onClick={() =>
                      navigate(
                        outboundEmailIntegrationUtils.getNavigation(
                          "previous",
                          activeStep,
                        ),
                      )
                    }
                  >
                    <span className="pe-1">
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                    Previous
                  </button>
                  <button
                    className={`py-2 px-md-3 ${styles.nextBtn}`}
                    disabled={disableNextButton}
                    onClick={() =>
                      navigate(
                        outboundEmailIntegrationUtils.getNavigation(
                          "next",
                          activeStep,
                        ),
                      )
                    }
                  >
                    Next
                    <span className="ps-1">
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            className={`${styles.connectWidth}`}
            style={{ marginLeft: "30px" }}
          >
            {activeStep === EOutboundEmailSteps.Complete && (
              <SetupComplete
                onNext={() => navigate("/settings/browse-integration")}
                onPrevious={() =>
                  navigate(
                    outboundEmailIntegrationUtils.getNavigation(
                      "previous",
                      activeStep,
                    ),
                  )
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OutboundEmailIntegration;
