import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export type TimelineReferenceType = "customer";
export interface TimelineProviderProps {
  children: ReactNode;
  referenceId: string | number;
  referenceType: TimelineReferenceType;
}
export interface TimelineState {
  timelineInfo: TimelineData | null | undefined;
  loading: boolean;
  error: string | null;
  referenceId: string | number;
  referenceType: TimelineReferenceType;
  filters: {
    view: "monthly" | "yearly" | "weekly" | "daily";
    year?: number | string;
  };
}

export type TimelineAction =
  | {
      type: TimelineActionTypes.SET_TIMELINE_DATA;
      payload: TimelineState["timelineInfo"];
    }
  | { type: TimelineActionTypes.SET_LOADING; payload: boolean }
  | { type: TimelineActionTypes.SET_ERROR; payload: string | null }
  | {
      type: TimelineActionTypes.SET_REFERENCE;
      payload: {
        referenceId: string | number;
        referenceType: TimelineReferenceType;
      };
    }
  | {
      type: TimelineActionTypes.SET_FILTER;
      payload: Partial<TimelineState["filters"]>;
    };

export interface TimelineContextProps {
  state: TimelineState;
  dispatch: React.Dispatch<TimelineAction>;
}

// Define an enum for action types
export enum TimelineActionTypes {
  SET_TIMELINE_DATA = "SET_TIMELINE_DATA",
  SET_LOADING = "SET_LOADING",
  SET_ERROR = "SET_ERROR",
  SET_REFERENCE = "SET_REFERENCE",
  SET_FILTER = "SET_FILTER",
}

// Create the context with a default value
const TimelineContext = createContext<TimelineContextProps | undefined>(
  undefined,
);

// Define the reducer
const timelineReducer = (state: TimelineState, action: TimelineAction) : TimelineState => {
  switch (action.type) {
    case TimelineActionTypes.SET_TIMELINE_DATA:
      return {
        ...state,
        timelineInfo: action.payload,
        filters: {
          ...state.filters,
          view: action.payload?.current_view ?? state.filters.view,
        },
      };
    case TimelineActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case TimelineActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case TimelineActionTypes.SET_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
};

// Context provider component
export const TimelineProvider = ({
  children,
  referenceId,
  referenceType,
}: TimelineProviderProps) => {
  const [state, dispatch] = useReducer(timelineReducer, {
    timelineInfo: null,
    loading: true,
    error: null,
    referenceId: referenceId,
    referenceType: referenceType,
    filters: {
      view: "monthly",
    },
  } as TimelineState);

  useEffect(() => {
    dispatch({
      type: TimelineActionTypes.SET_REFERENCE,
      payload: {
        referenceId: referenceId,
        referenceType: referenceType,
      },
    });
  }, [referenceId, referenceType]);

  return (
    <TimelineContext.Provider value={{ state, dispatch }}>
      {children}
    </TimelineContext.Provider>
  );
};

// Custom hook to use the Timeline context
export const useTimeline = () => {
  const context = useContext(TimelineContext);
  if (!context) {
    throw new Error("useTimeline must be used within a TimelineProvider");
  }
  return context;
};
