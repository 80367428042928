import { Modal, Spinner } from "react-bootstrap";
import styles from "./ErrorModal.module.scss";
import warningImg from "src/assets/images/warning.png";
const ErrorModal = ({
  showModal,
  onHide,
}: {
  showModal: boolean;
  onHide: () => void;
}) => {
  return (
    <div>
      <Modal
        show={showModal}
        onHide={onHide}
        contentClassName={`${styles.modalContent}`}
        dialogClassName={`${styles.dialogClass}`}
        backdropClassName={`${styles.backDropClass}`}
        id="MainSBPricingErrorModal"
        centered
      >
        <div className="px-4">
          <div className={`${styles.topIcon} mb-4 mx-auto`}>
            <img
              src={warningImg}
              alt="warning"
              width={64}
            />
          </div>
          <h3 className={`${styles.title} mb-4 pb-2 mx-auto`}>
            An error occurred. Please contact customer support.
          </h3>

          <div className="d-flex justify-content-center mb-2 px-2 pt-1">
            <button
              className={`px-3 ${styles.checkBtn} d-flex justify-content-center align-items-center`}
              onClick={onHide}
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorModal;
