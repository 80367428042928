import { useContext } from "react";
import { ModalContext } from "../../../Modal/ModalContext";
import { useAppDispatch, useAppSelector } from "src/store/store";
import FulfillItemModal from "./FulfillItemModal";
import styles from "./Unfulfilled.module.scss";
import OrderItem from "./children/FulFill Item/OrderItem/OrderItem";
import OrderItemTopRightMenu from "../ActionMenu/OrderItemTopRightMenu/OrderItemTopRightMenu";
import { resetGetFulfillmentData } from "src/store/slices/singleOrder/order.slice";
import { EFulfillmentStatus } from "src/enums/EFulfillmentStatus";

function OrderItemsMap({ orderItems }: any) {
  const modalContext = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { getOrderViewModel: order } = useAppSelector(
    (state) => state.singleOrder,
  );

  return (
    <>
      <div className={`${styles.unFulFilled} p-3 mb-3 d-flex flex-column ms-2`}>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex align-items-center flex-wrap">
            <div className={`${styles.cssdottedborder} me-2 `}></div>
            <div className={`${styles.lead}  mx-2 `}>
              {orderItems?.status?.fulfillmentStatus?.name}
            </div>
            <div className={`${styles.lead}   `}>
              {"(" + orderItems?.status?.itemCount + ")"}
            </div>
          </div>
          {/* Menu */}
          {/* Don't show actions when the items are removed/ refunded */}
          {orderItems?.status?.fulfillmentStatus?.id ===
          EFulfillmentStatus.fulfillmentStatusRemoved ? (
            <></>
          ) : (
            <OrderItemTopRightMenu
              orderId={order?.orderId}
              orderItemsInStatusGroup={orderItems}
            />
          )}
        </div>
        <div className={`${styles.lead} mt-3 `}>
          <div>Location</div>
          <div>{orderItems?.status?.locationName ?? "NA"}</div>
        </div>
        {/* Maps each item in a fulfillment status group */}
        {orderItems?.items?.map((item: any, index: number) => {
          // will be an order item
          return (
            <OrderItem
              orderItem={item}
              index={index}
              order={order}
            />
          );
        })}

        {/* if order is not cancelled and can create fulfillment */}
        {order?.orderCancelled === false &&
        orderItems?.status?.supportedActions &&
        orderItems?.status?.supportedActions?.includes("CREATE_FULFILLMENT") ? (
          <>
            {/* <div>
              <hr></hr>
            </div> */}
            <div>
              <div
                className={`${styles.fullFillItemAndCreateShoppingLabel} d-flex flex-row justify-content-end`}
              >
                {/* <div className="">
                  <button
                    type="button"
                    className={`btn border h-100   ${styles.fullfillItemBtn}  ${styles.lead}`}
                    onClick={() => {
                      dispatch(resetGetFulfillmentData());
                      modalContext.handleModal(
                        <FulfillItemModal
                          orderItemStatus={orderItems?.status}
                        />
                      );
                    }}
                  >
                    Fulfill item
                  </button>
                </div> */}

                {/* <div className={`${styles.shoppingLabelButton} `}>
                  <button
                    type="button"
                    className={`btn ${styles.btnprimary} btn-success ms-2`}
                  >
                    Create shipping label
                  </button>
                </div> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default OrderItemsMap;
