import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllUserSegmentParams {
  start: number;
  limit: number;
  searchText?: string;
  segmentType?: "user" | "default"; // By default it will return all segments
}

export interface UserSegmentData {
  id: string;
  value: string;
  segmentKey: string;
}

export interface GetAllUserSegmentResponse {
  allSegmentIds: string[];
  allSegments: Record<string, UserSegmentData>;
  hasMoreSegments: boolean;
}

const getAllUserSegmentService = async (payload: GetAllUserSegmentParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/getAllUserSegment`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllUserSegmentResponse = {
    allSegments: {},
    allSegmentIds: [],
    hasMoreSegments: false,
  };

  res.data.forEach((val: UserSegmentData) => {
    ret.allSegmentIds.push(val.id);
    ret.allSegments[val.id] = val;
  });

  if (ret.allSegmentIds.length !== payload.limit) {
    ret.hasMoreSegments = false;
  } else {
    ret.hasMoreSegments = true;
  }

  return ret;
};

export default getAllUserSegmentService;
