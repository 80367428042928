import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import getBotAiThreadmsg from "src/services/TicketService/InternalChatBot/getBotAiThreadMsg";
import {
  IMessageData,
  setCernMessageError,
  setCernRepliesCount,
  setRefetchCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useAppSelector } from "src/store/store";

export interface CernThreadMsgMap {
  [key: number | string]: IMessageData;
}

const useFetchCernThread = (
  messageId: number | undefined | null,
  isCampaign?: boolean,
) => {
  const { ticketId }: any = useParams();
  const { refetchCernThread } = useAppSelector((state) => state.innerTicket);
  const [cernThread, setCernThread] = useState<CernThreadMsgMap>({});
  const dispatch = useDispatch();
  const newRef = useRef(false);
  const initialPayload = useMemo(() => {
    return {
      start: 0,
      limit: 10,
      parentMessageId: messageId,
      ticketId: ticketId,
      sortBy: "desc",
    };
  }, [messageId, ticketId]);
  const [payload, setPayload] = useState(initialPayload);

  useEffect(() => {
    setPayload(initialPayload);
    setCernThread({});
  }, [initialPayload]);

  const { data, isLoading, isError, refetch } = useQuery(
    ["cernThread/getBotAiThreadmsg", payload],
    {
      queryFn: () => getBotAiThreadmsg(payload),
      staleTime: 60000,
      cacheTime: 60000,
      enabled: !isCampaign,
    },
  );

  useEffect(() => {
    newRef.current = refetchCernThread;
    if (newRef.current) {
      refetch();
      newRef.current = false;
      dispatch(setRefetchCernThread({ refetch: false }));
    }
  }, [refetchCernThread, refetch, dispatch]);

  useEffect(() => {
    const cernResData = data?.data;
    if (cernResData) {
      setCernThread((prevState) => {
        let mapData = { ...prevState };
        cernResData.forEach((thread) => {
          mapData = {
            ...mapData,
            [thread.messageId]: thread,
          };
        });
        return mapData;
      });
      const lastReply = data?.metaData;
      if (lastReply && messageId) {
        dispatch(
          setCernRepliesCount({
            date: lastReply.lastReplyDateGmt,
            count: lastReply.totalReplies,
            msgId: messageId,
          }),
        );
      }
    }
    if (
      cernResData?.length &&
      messageId &&
      (cernResData[0]?.messageType === "NoteBotAiMissingDetails" ||
        cernResData[0]?.messageType === "NoteBotAiOutOfScope")
    ) {
      dispatch(setCernMessageError({ msgId: messageId }));
    } else {
      dispatch(setCernMessageError({ msgId: undefined }));
    }
  }, [data]);

  // Change the current page and trigger a refetch
  const changePage = async () => {
    setPayload({
      ...payload,
      start: payload.start + payload.limit!,
    });
  };

  return {
    cernThread,
    changePage,
    isLoading: isLoading && !isCampaign,
    isError,
    setCernThread,
    hasNextPage: data?.data?.length === payload.limit,
  };
};

export default useFetchCernThread;
