import styles from "./TableHeader.module.scss";

const TableHeader = ({
  tableHeadings,
  tableHeadingIds,
}: {
  tableHeadings: Record<
    string,
    { tableHeadingId: string; tableHeadingName: string }
  >;
  tableHeadingIds: string[];
  changedTableHeadingId?: string | null | undefined;
}) => {
  return (
    <div className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}>
      {tableHeadingIds.map((id) => {
        const heading = tableHeadings[id];
        return (
          <div className={`${styles.headerContainer}`}>
            <div
              className={`text-truncate ${styles.headerName} ${
                heading?.tableHeadingId == "image" ? styles.csvImage : ""
              } d-flex`}
            >
              <span className={`${styles.tablePara} d-flex align-items-center`}>
                {heading?.tableHeadingName}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TableHeader;
