import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import {
  getAllEvents,
  GetAllEventsResponse,
  IgetAllEventsPayload,
} from "src/services/TimeLine/getAllEvents.service";

const LIMIT = 10;

const useGetAllEvents = (payload: IgetAllEventsPayload) => {
  const { data, fetchNextPage, status, isFetching, hasNextPage, refetch } =
    useInfiniteQuery<GetAllEventsResponse>(["getAllEvents", payload], {
      queryFn: async ({ pageParam = 1 }) => {
        const response = await getAllEvents({
          payload: {
            ...payload,
            start: (pageParam - 1) * (payload.limit ?? LIMIT),
            limit: payload.limit ?? LIMIT,
          },
        });
        return response;
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.meta.hasMore ? pages.length + 1 : undefined;
      },

      enabled: Boolean(payload.customerId && payload.eventType),
      keepPreviousData: true,
    });
  const eventData = useMemo(() => {
    return data?.pages.flatMap((page) => page.data) || [];
  }, [data]);

  const handleInfiniteScroll = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage().catch((error) => {
        console.error("Error fetching next page:", error);
      });
    }
  };

  const fetchStatus: AJAXSTATUS = useMemo(() => {
    return isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected";
  }, [isFetching, status]);

  return {
    eventData,
    fetchStatus,
    refetch,
    hasEventNextPage: hasNextPage,
    handleInfiniteScroll,
    isFetching,
    status,
  };
};

export default useGetAllEvents;
