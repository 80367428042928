import ParseHTML from "src/components/ParseHTML";

const string = `<html
  dir="ltr"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="und"
  style="
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    overflow-x: visible !important;
  "
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title></title>
    <!--[if (mso 16)]>
      <style type="text/css">
        a {
          text-decoration: none;
        }
      </style>
    <![endif]-->
    <!--[if gte mso 9
      ]><style>
        sup {
          font-size: 100% !important;
        }
      </style><!
    [endif]-->
    <!--[if gte mso 9]>
      <noscript>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG></o:AllowPNG>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      </noscript>
    <![endif]-->
    <!--[if !mso]><!-- -->
    <link
      href="https://fonts.googleapis.com/css2?family=Space+Grotesk&amp;display=swap"
      rel="stylesheet"
    />
    <!--<![endif]-->
    <!--[if !mso]><!-- -->
    <link
      href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i"
      rel="stylesheet"
    />
    <!--<![endif]-->
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
      .es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
      @media only screen and (max-width: 600px) {
        p,
        ul li,
        ol li,
        a {
          line-height: 150% !important;
        }
        h1,
        h2,
        h3,
        h1 a,
        h2 a,
        h3 a {
          line-height: 120%;
        }
        h1 {
          font-size: 40px !important;
          text-align: left;
        }
        h2 {
          font-size: 32px !important;
          text-align: left;
        }
        h3 {
          font-size: 24px !important;
          text-align: left;
        }
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 40px !important;
          text-align: left;
        }
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 32px !important;
          text-align: left;
        }
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 24px !important;
          text-align: left;
        }
        .es-menu td a {
          font-size: 14px !important;
        }
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li,
        .es-header-body a {
          font-size: 14px !important;
        }
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li,
        .es-content-body a {
          font-size: 14px !important;
        }
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li,
        .es-footer-body a {
          font-size: 12px !important;
        }
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li,
        .es-infoblock a {
          font-size: 12px !important;
        }
        *[class="gmail-fix"] {
          display: none !important;
        }
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3 {
          text-align: center !important;
        }
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3 {
          text-align: right !important;
        }
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3 {
          text-align: left !important;
        }
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important;
        }
        .es-button-border {
          display: inline-block !important;
        }
        a.es-button,
        button.es-button {
          font-size: 16px !important;
          display: inline-block !important;
        }
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important;
        }
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important;
        }
        .es-adapt-td {
          display: block !important;
          width: 100% !important;
        }
        .adapt-img {
          width: 100% !important;
          height: auto !important;
        }
        .es-m-p0 {
          padding: 0 !important;
        }
        .es-m-p0r {
          padding-right: 0 !important;
        }
        .es-m-p0l {
          padding-left: 0 !important;
        }
        .es-m-p0t {
          padding-top: 0 !important;
        }
        .es-m-p0b {
          padding-bottom: 0 !important;
        }
        .es-m-p20b {
          padding-bottom: 20px !important;
        }
        .es-mobile-hidden,
        .es-hidden {
          display: none !important;
        }
        tr.es-desk-hidden,
        td.es-desk-hidden,
        table.es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important;
        }
        tr.es-desk-hidden {
          display: table-row !important;
        }
        table.es-desk-hidden {
          display: table !important;
        }
        td.es-desk-menu-hidden {
          display: table-cell !important;
        }
        .es-menu td {
          width: 1% !important;
        }
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important;
        }
        table.es-social {
          display: inline-block !important;
        }
        table.es-social td {
          display: inline-block !important;
        }
        .es-desk-hidden {
          display: table-row !important;
          width: auto !important;
          overflow: visible !important;
          max-height: inherit !important;
        }
        .es-m-p5 {
          padding: 5px !important;
        }
        .es-m-p5t {
          padding-top: 5px !important;
        }
        .es-m-p5b {
          padding-bottom: 5px !important;
        }
        .es-m-p5r {
          padding-right: 5px !important;
        }
        .es-m-p5l {
          padding-left: 5px !important;
        }
        .es-m-p10 {
          padding: 10px !important;
        }
        .es-m-p10t {
          padding-top: 10px !important;
        }
        .es-m-p10b {
          padding-bottom: 10px !important;
        }
        .es-m-p10r {
          padding-right: 10px !important;
        }
        .es-m-p10l {
          padding-left: 10px !important;
        }
        .es-m-p15 {
          padding: 15px !important;
        }
        .es-m-p15t {
          padding-top: 15px !important;
        }
        .es-m-p15b {
          padding-bottom: 15px !important;
        }
        .es-m-p15r {
          padding-right: 15px !important;
        }
        .es-m-p15l {
          padding-left: 15px !important;
        }
        .es-m-p20 {
          padding: 20px !important;
        }
        .es-m-p20t {
          padding-top: 20px !important;
        }
        .es-m-p20r {
          padding-right: 20px !important;
        }
        .es-m-p20l {
          padding-left: 20px !important;
        }
        .es-m-p25 {
          padding: 25px !important;
        }
        .es-m-p25t {
          padding-top: 25px !important;
        }
        .es-m-p25b {
          padding-bottom: 25px !important;
        }
        .es-m-p25r {
          padding-right: 25px !important;
        }
        .es-m-p25l {
          padding-left: 25px !important;
        }
        .es-m-p30 {
          padding: 30px !important;
        }
        .es-m-p30t {
          padding-top: 30px !important;
        }
        .es-m-p30b {
          padding-bottom: 30px !important;
        }
        .es-m-p30r {
          padding-right: 30px !important;
        }
        .es-m-p30l {
          padding-left: 30px !important;
        }
        .es-m-p35 {
          padding: 35px !important;
        }
        .es-m-p35t {
          padding-top: 35px !important;
        }
        .es-m-p35b {
          padding-bottom: 35px !important;
        }
        .es-m-p35r {
          padding-right: 35px !important;
        }
        .es-m-p35l {
          padding-left: 35px !important;
        }
        .es-m-p40 {
          padding: 40px !important;
        }
        .es-m-p40t {
          padding-top: 40px !important;
        }
        .es-m-p40b {
          padding-bottom: 40px !important;
        }
        .es-m-p40r {
          padding-right: 40px !important;
        }
        .es-m-p40l {
          padding-left: 40px !important;
        }
      }
      @media screen and (max-width: 384px) {
        .mail-message-content {
          width: 414px !important;
        }
      }
    </style>
    <link
      href="assets/css/dev-custom-scroll.css"
      rel="stylesheet"
      type="text/css"
    />
    <base target="_blank" />
  </head>
  <body
    style="
      width: 100%;
      font-family: arial, 'helvetica neue', helvetica, sans-serif;
      text-size-adjust: 100%;
      padding: 0px;
      margin: 0px;
      overflow-y: scroll !important;
      visibility: visible !important;
    "
  >
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="und"
      style="background-color: #ffffff"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#ffffff"></v:fill>
        </v:background>
      <![endif]-->
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #ffffff;
        "
      >
        <tbody>
          <tr>
            <td valign="top" style="padding: 0; margin: 0">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                  background-color: transparent;
                  background-repeat: repeat;
                  background-position: center top;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        role="none"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          max-width: 600px;
                          width: 100%;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p0b"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 15px;
                                padding-bottom: 15px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p0r es-m-p20b"
                                      valign="top"
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                        max-width: 270px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                               
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #1f1f1f;
                                                  font-size: 14px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_8c56c00191036e35443af3784d2134e3/images/group_362.png"
                                                  alt="Logo"
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    width: 100%;
                                                    max-width: 270px;
                                                  "
                                                  title="Logo"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr class="es-mobile-hidden">
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                        max-width: 270px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="right"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                              "
                                            >
                                              <!--[if mso
                                                ]><a
                                                  href="https://viewstripo.email"
                                                  target="_blank"
                                                  hidden
                                                >
                                                  <v:roundrect
                                                    xmlns:v="urn:schemas-microsoft-com:vml"
                                                    xmlns:w="urn:schemas-microsoft-com:office:word"
                                                    esdevVmlButton
                                                    href="https://viewstripo.email"
                                                    style="
                                                      height: 39px;
                                                      v-text-anchor: middle;
                                                      width: 135px;
                                                    "
                                                    arcsize="15%"
                                                    stroke="f"
                                                    fillcolor="#00a6fb"
                                                  >
                                                    <w:anchorlock></w:anchorlock>
                                                    <center
                                                      style="
                                                        color: #ffffff;
                                                        font-family: 'Space Grotesk',
                                                          sans-serif;
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        line-height: 14px;
                                                        mso-text-raise: 1px;
                                                      "
                                                    >
                                                      Contact us
                                                    </center>
                                                  </v:roundrect></a
                                                >
                                              <![endif]-->
                                              <!--[if !mso]><!-- --><span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #00a6fb;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 6px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                 
                                                  class="es-button es-button-1668693070775"
                                                
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                    padding: 10px 25px 10px 25px;
                                                    display: inline-block;
                                                    background: #00a6fb;
                                                    border-radius: 6px;
                                                    font-family: 'Space Grotesk',
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 19.2px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #00a6fb;
                                                  "
                                                  >Contact us</span
                                                >
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding: 0; margin: 0">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100%;
                                        max-width: 600px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 2px solid
                                                          #a3cef1;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          max-width: 600px;
                          width: 100%;
                        "
                        cellspacing="0"
                        cellpadding="0"
                        bgcolor="#ffffff"
                        align="center"
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 30px;
                                padding-top: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 43.2px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: 'Space Grotesk',
                                                    sans-serif;
                                                  font-size: 36px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #051923;
                                                "
                                              >
                                                Hello
                                                <strong>Hilary Lambert,</strong>
                                              </h2>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                <br />
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua.
                                                Ut morbi tincidunt augue
                                                interdum velit euismod in
                                                pellentesque.
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                <br />
                                              </p>
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 43.2px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: 'Space Grotesk',
                                                    sans-serif;
                                                  font-size: 36px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #051923;
                                                "
                                              >
                                                We invite you for an
                                                <strong>interview:</strong>
                                              </h2>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p20b"
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 10px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                              
                                               
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #00a6fb;
                                                  font-size: 16px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_8c56c00191036e35443af3784d2134e3/images/clock_4.png"
                                                  alt=""
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="45"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      class="es-m-p20b"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          border-left: 2px solid #a3cef1;
                                          border-right: 2px solid #a3cef1;
                                          border-top: 2px solid #a3cef1;
                                          border-bottom: 2px solid #a3cef1;
                                          border-radius: 6px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                margin: 0;
                                                padding-bottom: 15px;
                                                padding-top: 20px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                Wednesday, November
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                20th, at 11 AM
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 10px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                               
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #00a6fb;
                                                  font-size: 16px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_8c56c00191036e35443af3784d2134e3/images/clock_3.png"
                                                  alt=""
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="45"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          border-left: 2px solid #a3cef1;
                                          border-right: 2px solid #a3cef1;
                                          border-top: 2px solid #a3cef1;
                                          border-bottom: 2px solid #a3cef1;
                                          border-radius: 6px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                margin: 0;
                                                padding-bottom: 15px;
                                                padding-top: 20px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                Approximate duration of the
                                                interviews is 2 hours
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td
                      align="center"
                      bgcolor="#003554"
                      style="padding: 0; margin: 0; background-color: #003554"
                    >
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          max-width: 600px;
                          width: 100%;
                        "
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 30px;
                                padding-top: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-c"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 43.2px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: 'Space Grotesk',
                                                    sans-serif;
                                                  font-size: 36px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                You will be
                                                <strong>interviewed by:</strong>
                                              </h2>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                  width: 100%;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        align="left"
                                        class="es-left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              valign="top"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 120px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <span
                                                      
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          class="adapt-img"
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_8c56c00191036e35443af3784d2134e3/images/juricakoletic7yvzyzeitc8unsplash_1_1.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            border-radius: 5px;
                                                          "
                                                          width="120"
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 10px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 140px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-bottom: 15px;
                                                      "
                                                    >
                                                      <h3
                                                        style="
                                                          margin: 0;
                                                          line-height: 28.8px;
                                                          mso-line-height-rule: exactly;
                                                          font-family: 'Space Grotesk',
                                                            sans-serif;
                                                          font-size: 24px;
                                                          font-style: normal;
                                                          font-weight: normal;
                                                          color: #ffffff;
                                                        "
                                                      >
                                                        Lionel Martin
                                                      </h3>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: lato,
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          line-height: 24px;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                      >
                                                        CEO and Founder,
                                                        Business Network
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      class="es-m-txt-c"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 40px;
                                                        font-size: 0;
                                                      "
                                                    >
                                                      <table
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        class="es-table-not-adapt es-social"
                                                        role="presentation"
                                                        style="
                                                          mso-table-lspace: 0pt;
                                                          mso-table-rspace: 0pt;
                                                          border-collapse: collapse;
                                                          border-spacing: 0px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                                padding-right: 5px;
                                                              "
                                                            >
                                                              <span
                                                                
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/x-logo-white.png"
                                                                  alt="Х"
                                                                  title="Х"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                                padding-right: 5px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/facebook-logo-white.png"
                                                                  alt="Fb"
                                                                  title="Facebook"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                              "
                                                            >
                                                              <span
                                                                
                                                                href="https://viewstripo.email"
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/linkedin-logo-white.png"
                                                                  alt="In"
                                                                  title="Linkedin"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 10px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 120px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          class="adapt-img"
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_8c56c00191036e35443af3784d2134e3/images/austinwadex6uj51n5ce8unsplash_1_1.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            border-radius: 5px;
                                                          "
                                                          width="120"
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 10px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 150px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-bottom: 15px;
                                                      "
                                                    >
                                                      <h3
                                                        style="
                                                          margin: 0;
                                                          line-height: 28.8px;
                                                          mso-line-height-rule: exactly;
                                                          font-family: 'Space Grotesk',
                                                            sans-serif;
                                                          font-size: 24px;
                                                          font-style: normal;
                                                          font-weight: normal;
                                                          color: #ffffff;
                                                        "
                                                      >
                                                        August Lindsey
                                                      </h3>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: lato,
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          line-height: 24px;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                      >
                                                        Technical Consultant,
                                                        Business Network
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      class="es-m-txt-c"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 40px;
                                                        font-size: 0;
                                                      "
                                                    >
                                                      <table
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        class="es-table-not-adapt es-social"
                                                        role="presentation"
                                                        style="
                                                          mso-table-lspace: 0pt;
                                                          mso-table-rspace: 0pt;
                                                          border-collapse: collapse;
                                                          border-spacing: 0px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                                padding-right: 5px;
                                                              "
                                                            >
                                                              <span
                                                                
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/x-logo-white.png"
                                                                  alt="Х"
                                                                  title="Х"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                                padding-right: 5px;
                                                              "
                                                            >
                                                              <span
                                                              
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/facebook-logo-white.png"
                                                                  alt="Fb"
                                                                  title="Facebook"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                            <td
                                                              align="center"
                                                              valign="top"
                                                              style="
                                                                padding: 0;
                                                                margin: 0;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                style="
                                                                  -webkit-text-size-adjust: none;
                                                                  -ms-text-size-adjust: none;
                                                                  mso-line-height-rule: exactly;
                                                                  text-decoration: underline;
                                                                  color: #00a6fb;
                                                                  font-size: 16px;
                                                                "
                                                                ><img
                                                                  src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/linkedin-logo-white.png"
                                                                  alt="In"
                                                                  title="Linkedin"
                                                                  height="24"
                                                                  style="
                                                                    display: block;
                                                                    border: 0;
                                                                    outline: none;
                                                                    text-decoration: none;
                                                                    -ms-interpolation-mode: bicubic;
                                                                  "
                                                              /></span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 30px;
                                              "
                                            >
                                              <!--[if mso
                                                ]><a
                                                  href="https://viewstripo.email"
                                                  target="_blank"
                                                  hidden
                                                >
                                                  <v:roundrect
                                                    xmlns:v="urn:schemas-microsoft-com:vml"
                                                    xmlns:w="urn:schemas-microsoft-com:office:word"
                                                    esdevVmlButton
                                                    href="https://viewstripo.email"
                                                    style="
                                                      height: 39px;
                                                      v-text-anchor: middle;
                                                      max-width: 234px;
                                                      width: 100%;
                                                    "
                                                    arcsize="15%"
                                                    stroke="f"
                                                    fillcolor="#00a6fb"
                                                  >
                                                    <w:anchorlock></w:anchorlock>
                                                    <center
                                                      style="
                                                        color: #ffffff;
                                                        font-family: 'Space Grotesk',
                                                          sans-serif;
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        line-height: 14px;
                                                        mso-text-raise: 1px;
                                                      "
                                                    >
                                                      Enter the meeting room
                                                    </center>
                                                  </v:roundrect></a
                                                >
                                              <![endif]-->
                                              <!--[if !mso]><!-- --><span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #00a6fb;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 6px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                
                                                  class="es-button"
                                                
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                    padding: 10px 25px 10px 25px;
                                                    display: inline-block;
                                                    background: #00a6fb;
                                                    border-radius: 6px;
                                                    font-family: 'Space Grotesk',
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 19.2px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #00a6fb;
                                                  "
                                                  >Enter the meeting room</span
                                                >
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          max-width: 600px;
                        "
                        cellspacing="0"
                        cellpadding="0"
                        align="center"
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-top: 30px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-l"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 43.2px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: 'Space Grotesk',
                                                    sans-serif;
                                                  font-size: 36px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #051923;
                                                "
                                              >
                                                David James
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                Recruiter
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:136px" valign="top"><![endif]-->
                              <table
                                cellspacing="0"
                                cellpadding="0"
                                align="left"
                                class="es-left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p0r"
                                      valign="top"
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 131px;
                                      "
                                    >
                                      <table
                                        width="100%"
                                        cellspacing="0"
                                        cellpadding="0"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                               
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #00a6fb;
                                                  font-size: 16px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_6276a3e52776c2d08d3f7a077d70c296/images/pexelsshyammishra13921929_3.png"
                                                  alt=""
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="90"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 5px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:236px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 236px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p10l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: lato,
                                                    'helvetica neue', helvetica,
                                                    arial, sans-serif;
                                                  line-height: 24px;
                                                  color: #1f1f1f;
                                                  font-size: 16px;
                                                "
                                              >
                                                4019 Middle Passage, Badwater,
                                                Manitoba, R1H-5T3, CA
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-l es-m-p15t es-m-p15b es-m-p0r es-m-p10l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-right: 40px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Facebook"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/circle-black-bordered/facebook-circle-black-bordered.png"
                                                          alt="Fb"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                        
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Х"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/circle-black-bordered/x-circle-black-bordered.png"
                                                          alt="Х"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                      
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Instagram"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/circle-black-bordered/instagram-circle-black-bordered.png"
                                                          alt="Inst"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <span
                                                     
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #00a6fb;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Youtube"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/circle-black-bordered/youtube-circle-black-bordered.png"
                                                          alt="Yt"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:5px"></td><td style="width:183px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 183px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="100%"
                                                      style="
                                                        margin: 0;
                                                        padding-right: 5px;
                                                        padding-top: 5px;
                                                        padding-bottom: 5px;
                                                        padding-left: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: lato,
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          color: #333333;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_43a1f25edeeba67e7304451dcecf7295/images/mail_1.png"
                                                          alt="+000 123 456 78"
                                                          title="+000 123 456 78"
                                                          align="absmiddle"
                                                          width="16"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 10px;
                                                            vertical-align: middle;
                                                          "
                                                        />+000 123 456 78</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="100%"
                                                      style="
                                                        margin: 0;
                                                        padding-right: 5px;
                                                        padding-top: 5px;
                                                        padding-bottom: 5px;
                                                        padding-left: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                      
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: lato,
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          color: #333333;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_43a1f25edeeba67e7304451dcecf7295/images/mail.png"
                                                          alt="David@network"
                                                          title="David@network"
                                                          align="absmiddle"
                                                          width="16"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 10px;
                                                            vertical-align: middle;
                                                          "
                                                        />David@network</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="100%"
                                                      style="
                                                        margin: 0;
                                                        padding-right: 5px;
                                                        padding-top: 5px;
                                                        padding-bottom: 10px;
                                                        padding-left: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: lato,
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          color: #333333;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_43a1f25edeeba67e7304451dcecf7295/images/mail_2.png"
                                                          alt="network.com"
                                                          title="network.com"
                                                          align="absmiddle"
                                                          width="16"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 10px;
                                                            vertical-align: middle;
                                                          "
                                                        />network.com</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
             
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <script
      data-cfasync="false"
      src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
    ></script>
  </body>
</html>
`;

const BusinessNetwork = () => {
  return <ParseHTML html={string} />;
};

export default BusinessNetwork;
