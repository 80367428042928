import { axiosJSON } from "src/globals/axiosEndPoints";
import { findAutomationContextFromURLPath } from "src/utils/utils";

/**
 * Interface for the parameters to be passed to the `getComboOptionsService` function.
 */
export interface ComboOptionsParams {
  /** Unique identifier for the integration. */
  integrationId: string;
  /** Unique identifier for the step. */
  stepId: string;
  /** Unique identifier for the question. */
  questionId: string;
  /** Key to be used for the value in the combo options. */
  valueKey: string;
  /** Optional search text to filter combo options. */
  searchText?: string;
  /** Starting index for pagination. */
  start: number;
  /** Number of records to fetch. */
  limit: number;
}

/**
 * Interface for the response returned by the `getComboOptionsService` function.
 */
export interface ComboOptionsRes {
  /** Array of options with label and value. */
  options: Array<{
    label: string;
    value: string;
  }>;
  /** Boolean flag to indicate if there are more options to fetch. */
  hasMore: boolean;
}

/**
 * Fetches condition select dropdown data for a given segment type and parameters.
 *
 * @param params - The parameters required to fetch the combo options.
 * @returns An object containing an array of combo options and a flag indicating if there are more options to fetch.
 * @throws Will throw an error if the response contains an error message.
 */
async function getComboOptionsService(
  params: ComboOptionsParams,
): Promise<ComboOptionsRes> {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/${automationContext}/getComboOptions`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: ComboOptionsRes = {
    options: res.data,
    hasMore: res.metaData?.completed ? false : true,
  };

  if (params.limit > ret.options.length) {
    ret.hasMore = false;
  }

  return ret;
}

export default getComboOptionsService;
