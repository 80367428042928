/**
 * This file defines a React component used to render the BotIndexing status on the UI
 * as Bot Training Status.
 *
 * @author @sohel-busibud
 */
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import importInProgress from "src/assets/images/importInProgress.gif";
import styles from "./BotIndexingStatus.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { stopimportCustomQA } from "src/services/Bot/CustomQA/stopimportCustomQA.service";
import { tryAgainCustomQA } from "src/services/Bot/CustomQA/reTryCustomQA.service";
import { refreshCustomQA } from "src/services/Bot/CustomQA/refreshCustomQA.service";
import { stopimportArticle } from "src/services/Bot/AnswerSources/stopImportKBArticle.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { reTryArticle } from "src/services/Bot/AnswerSources/reTryArticles.service";
import { refreshArticle } from "src/services/Bot/AnswerSources/refreshArticle";
import { stopImportSourceArticles } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/ActiveSource/Children/External/stopImportExternalArticle.service";
import { reTrySourceArticles } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/ActiveSource/Children/External/retryExternalArticle.service";
import { refreshSourceArticles } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/ActiveSource/Children/External/refreshExternalArticle.service";
import { useEffect, useState } from "react";
import { QuestionAns } from "src/services/Bot/CustomQA/getAllQuestionAnswer.service";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import metrocross from "../../assets/images/botstatus/metrocross.png";
import materialsync from "../../assets/images/botstatus/materialsync.png";
import { SelectArticlesResponse } from "src/services/Bot/AnswerSources/selectKbArticles.service";
import { SelectArticlesResponseExternal } from "src/services/Bot/AnswerSources/selectSourceArticles.service";

// Type definition for bot indexing status
export type IBotIndexingStatus = "loading" | "failed" | "synced" | "absent";
export type IsourceType = "customQA" | "kbArticle" | "External";

// Props interface for the BotIndexingStatus component
interface props {
  botStatus: IBotIndexingStatus;
  syncedText?: string;
  failedText?: string;
  loadingText?: string;
  percentageLoaded?: number;
  botProfileId?: string | number;
  // customQAId?: number;
  // articleId?: number;
  sourceId?: string | number;
  id: number;
  width?: number;
  sourceType: IsourceType;
  selectedArticles?: any;
  setSelectedArticles?: any;
  setchangedBotStatus?: React.Dispatch<
    React.SetStateAction<IBotIndexingStatus>
  >;

  updateCustomQA?: (id: number, customQA: Partial<QuestionAns>) => void;
  updateArticleValue?: (articleId: number, values: Partial<Article>) => void;
  onStatusUpdate?: () => void;
  updateArticlebotTrainingStatusKB?: (data: SelectArticlesResponse) => void;
  updateArticlebotTrainingStatusEX?: (
    externalArticleResponse: SelectArticlesResponseExternal,
  ) => void;
}

// Popover components for various actions
const stopPopover = (
  <Popover id="popover-basic">
    <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
      <span className={`${styles.popText}`}>Stop import</span>
    </Popover.Body>
  </Popover>
);

const tryAgainPopover = (
  <Popover id="popover-basic">
    <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
      <span className={`${styles.popText}`}>Try again</span>
    </Popover.Body>
  </Popover>
);

const refreshPopover = (
  <Popover id="popover-basic">
    <Popover.Body bsPrefix={`popver ${styles.popBody}`}>
      <span className={`${styles.popText}`}>Refresh</span>
    </Popover.Body>
  </Popover>
);

// Functional component to display bot indexing status
const BotIndexingStatus = ({
  botStatus,
  syncedText,
  failedText,
  loadingText,
  percentageLoaded,
  botProfileId,
  id,
  // customQAId,
  sourceType,
  // articleId,
  sourceId,
  updateCustomQA,
  updateArticleValue,
  selectedArticles,
  setSelectedArticles,
  setchangedBotStatus,
  onStatusUpdate,
  width,
  updateArticlebotTrainingStatusKB,
  updateArticlebotTrainingStatusEX,
}: props) => {
  // let botStatusChanged = botStatus
  // const [botStatusChanged, setchangedBotStatus] = useState<IBotIndexingStatus>("absent");

  // Effect to update state when botStatus prop changes

  const queryClient = useQueryClient();

  // Mutation hook for stopping the Custom QA import process
  // Mutation hook for stopping the Custom QA import process
  const stopCustomQAMutation = useMutation(stopimportCustomQA, {
    onSuccess: (data) => {
      updateCustomQA &&
        updateCustomQA(parseInt(id + ""), {
          status: "draft",
          botStatus: data.botTrainingStatus,
        });
      if (setchangedBotStatus) {
        setchangedBotStatus(data.botTrainingStatus);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to stop import",
        type: "danger",
      });
    },
  });

  // Mutation hook for stopping the KBArticle import process
  // const stopArticleMutation = useMutation(stopimportArticle, {
  //   onSuccess: (data) => {

  //     if (selectedArticles.includes(id)) {
  //       setSelectedArticles(
  //         selectedArticles.filter((articleId: any) => articleId !== id),
  //       );
  //     } else {
  //       setSelectedArticles([...selectedArticles, id]);
  //     }
  //     setchangedBotStatus(data.botTrainingStatus);
  //     console.log(data)
  //     console.log(botStatus)
  //   },
  //   onError: () => {
  //     pushTheToast({
  //       position: "top-right",
  //       text: "Failed to stop import",
  //       type: "danger",
  //     });
  //   }
  // });
  // Mutation hook for stopping the External Article import process
  // const stopExternalArticleMutation = useMutation(stopImportSourceArticles, {
  //   onSuccess: () => {

  //     // Update External article selction
  //     updateArticleValue && updateArticleValue(parseInt(id + ""), { isSelected: false });
  //     setchangedBotStatus("absent");

  //   },
  //   onError: () => {
  //     pushTheToast({
  //       position: "top-right",
  //       text: "Failed to stop import",
  //       type: "danger",
  //     });
  //   }
  // });

  // Handler function for stopping the import process
  const handleStopImport = () => {
    if (sourceType == "customQA" && botProfileId && id) {
      stopCustomQAMutation.mutate(
        { botProfileId, customQAId: id },
        {
          onSuccess: (data) => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else if (sourceType == "kbArticle" && onStatusUpdate) {
      // stopArticleMutation.mutate({ botProfileId, articleId: id }, {
      //   onSuccess: () => {
      //     updateArticleValue && updateArticleValue(parseInt(id + ""), { isSelected: false });
      //   },
      //   onError: (error) => {
      //     console.error('Error during stop import mutation:', error);
      //   }
      // });
      onStatusUpdate();
    } else if (sourceType == "External" && onStatusUpdate) {
      // stopExternalArticleMutation.mutate({ sourceId, articleId: id }, {
      //   onSuccess: (data) => {
      //     updateArticleValue && updateArticleValue(parseInt(id + ""), { isSelected: false });
      //     setchangedBotStatus(data.botTrainingStatus);
      //     console.log(botStatus);
      //   },
      //   onError: (error) => {
      //     console.error('Error during stop import mutation:', error);
      //   }
      // });
      onStatusUpdate();
    } else {
      // console.log(sourceType, botProfileId, customQAId, articleId, sourceId);
      console.error("Missing botProfileId or customQAId/articleId");
    }
  };

  // Mutation hook for retrying the import process for CustomQA
  const tryAgainMutationCustomQA = useMutation(tryAgainCustomQA, {
    onSuccess: (data) => {
      if (setchangedBotStatus) {
        setchangedBotStatus(data.botTrainingStatus);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to retry",
        type: "danger",
      });
    },
  });
  // Mutation hook for retrying the process for KBArticles
  const tryAgainMutationArticle = useMutation(reTryArticle, {
    onSuccess: (data) => {
      if (updateArticlebotTrainingStatusKB) {
        updateArticlebotTrainingStatusKB(data);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to retry",
        type: "danger",
      });
    },
  });

  // Mutation hook for retrying the process for ExternalArtiles
  const tryAgainMutationExternalArticle = useMutation(reTrySourceArticles, {
    onSuccess: (data) => {
      if (updateArticlebotTrainingStatusEX) {
        updateArticlebotTrainingStatusEX(data);
      }
      console.log(botStatus);
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to retry",
        type: "danger",
      });
    },
  });

  // Handler function for retrying the import process
  const handleTryAgain = () => {
    if (sourceType == "customQA" && botProfileId && id) {
      tryAgainMutationCustomQA.mutate(
        { botProfileId, customQAId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else if (sourceType == "kbArticle" && botProfileId && id) {
      tryAgainMutationArticle.mutate(
        { botProfileId, articleId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else if (sourceType == "External" && sourceId && id) {
      tryAgainMutationExternalArticle.mutate(
        { sourceId, articleId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else {
      // console.log(sourceType, botProfileId, customQAId, articleId);
      console.error("Missing botProfileId or customQAId/articleId");
    }
  };

  // Mutation hook for refreshing customQA
  const refreshMutationCustomQA = useMutation(refreshCustomQA, {
    onSuccess: (data) => {
      if (setchangedBotStatus) {
        setchangedBotStatus(data.botTrainingStatus);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to refresh",
        type: "danger",
      });
    },
  });

  // Mutation hook for refreshing KBArticle
  const refreshMutationArticle = useMutation(refreshArticle, {
    onSuccess: (data) => {
      if (updateArticlebotTrainingStatusKB) {
        updateArticlebotTrainingStatusKB(data);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to refresh",
        type: "danger",
      });
    },
  });
  // Mutation hook for refreshing KBArticle
  const refreshMutationExternalArticle = useMutation(refreshSourceArticles, {
    onSuccess: (data) => {
      if (updateArticlebotTrainingStatusEX) {
        updateArticlebotTrainingStatusEX(data);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to refresh",
        type: "danger",
      });
    },
  });

  // Handler function for refreshing  process
  const handleRefresh = () => {
    if (sourceType == "customQA" && botProfileId && id) {
      refreshMutationCustomQA.mutate(
        { botProfileId, customQAId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else if (sourceType == "kbArticle" && botProfileId && id) {
      refreshMutationArticle.mutate(
        { botProfileId, articleId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else if (sourceType == "External" && sourceId && id) {
      refreshMutationExternalArticle.mutate(
        { sourceId, articleId: id },
        {
          onSuccess: () => { },
          onError: (error) => {
            console.error("Error during stop import mutation:", error);
          },
        },
      );
    } else {
      // console.log(sourceType, botProfileId, customQAId, articleId);
      console.error("Missing botProfileId or customQAId/articleId");
    }
  };

  return (
    <div
      className={`${styles.botStatus}`}
      style={{ width: width ? `${width}%` : "22%" }}
    >
      <span>
        {botStatus === "synced" ? (
          <div>
            <span style={{ color: "#1CC003" }}>
              <i className={`fa-solid fa-circle-check`}></i>
            </span>
            <span className={`${styles.botStatusText} ps-1 pe-1`}>
              {syncedText}
            </span>
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={refreshPopover}
            >
              <div
                className={`${styles.IconDiv} pt-1`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRefresh();
                }}
              >
                <img
                  src={materialsync}
                  alt="cross"
                  height="14px"
                  width="10px"
                />
              </div>
            </OverlayTrigger>
          </div>
        ) : botStatus === "failed" ? (
          <div>
            <span style={{ color: "#BB0404" }}>
              <i className="fa-solid fa-circle-xmark"></i>
            </span>
            <span
              className={`${styles.botStatusText} ps-1 pe-1`}
              onClick={(e) => {
                e.stopPropagation();
                alert("Syncing failed.");
              }}
            >
              {failedText}
            </span>
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={tryAgainPopover}
            >
              <span
                className={`${styles.IconDiv} pt-1`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTryAgain();
                }}
              >
                <i className="fa-solid fa-rotate-right"></i>
              </span>
            </OverlayTrigger>
          </div>
        ) : botStatus === "loading" ? (
          <div>
            <div className="d-flex flex-row flex-shrink-1 align-items-center justify-content-center">
              {/* Dynamic loader showing the import progress based on percentage */}
              {/* <div className={`${styles.loadingStatusDiv} ms-3 me-3`}>
                <div
                  className={`${styles.percentageLoading}`}
                  style={{
                    width: `${percentageLoaded}%`,
                  }}
                ></div>
              </div> */}
              {/* GIF indicating import progress */}
              <img
                src={importInProgress}
                alt="Import In Progress"
                className={`${styles.importInProgress}`}
                height={"34px"}
                width={"109px"}
              />
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={stopPopover}
              >
                <div
                  className={`${styles.IconDiv} pt-1`}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log("stop import trigered");
                    handleStopImport();
                  }}
                >
                  <img
                    src={metrocross}
                    alt="cross"
                    height="8px"
                    width="8px"
                  />
                </div>
              </OverlayTrigger>
            </div>
            <div className={`${styles.botStatusText}`}>{loadingText}</div>
          </div>
        ) : null}
      </span>
    </div>
  );
};

export default BotIndexingStatus;
