import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./ConditionTypes.module.scss";
import {
  CompareDetail,
  ConditionType as IConditionType,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import { useConditionGroupState } from "../../../../../hooks/useConditionGroupState";
import { Group } from "src/services/Automation/getAutomationById.service";

interface SelectedConditionType {
  conditionTypeKey: string;
  conditionTypeName?: string;
}

interface Props {
  options: Array<IConditionType>;
  conditionIndex: number;
  groupIndex: number;
  prefix?: string;
}

const findSelectedConditionTypeValue = ({
  selected,
  options,
}: {
  options: Array<IConditionType>;
  selected: SelectedConditionType;
}): null | IConditionType => {
  let option: null | IConditionType = null;
  for (let i = 0; i < options.length; i++) {
    const value = options[i];
    if ((value as IConditionType).conditionTypeKey) {
      if (
        (value as IConditionType).conditionTypeKey === selected.conditionTypeKey
      ) {
        option = value as IConditionType;
        break;
      }
    }
  }
  return option;
};

export const useSelectedConditionType = ({
  options,
  conditionIndex,
  groupIndex,
}: Props) => {
  const { conditionGroup } = useConditionGroupState();
  const selected: SelectedConditionType | null = useMemo(() => {
    const ret = (conditionGroup?.conditions ?? [])[conditionIndex];
    if (ret?.conditionType) {
      return {
        conditionTypeKey: ret.conditionType,
      };
    } else {
      return null;
    }
  }, [conditionGroup, conditionIndex, groupIndex]);

  const selectedConditionType = useMemo(() => {
    return selected
      ? findSelectedConditionTypeValue({ selected, options })
      : null;
  }, [options, selected]);

  return { selectedConditionType };
};

interface PropsConditionType {
  option: IConditionType;
  selected: IConditionType | null;
  onChange: React.MouseEventHandler<HTMLElement>;
}
const ConditionType = ({ option, selected, onChange }: PropsConditionType) => {
  return (
    <div className="ps-2">
      {
        <span
          key={(option as IConditionType).conditionTypeKey}
          className={`me-1 ${styles.dropItem}`}
          onClick={onChange}
          {...((option as IConditionType).conditionTypeKey
            ? {
                "data-condition-key": (option as IConditionType)
                  .conditionTypeKey,
              }
            : {})}
        >
          {(option as IConditionType).conditionTypeName}
        </span>
      }
    </div>
  );
};

const ConditionTypes = ({
  options,
  conditionIndex,
  groupIndex,
  prefix,
}: Props) => {
  const { conditionGroup, updateState, showErrors } = useConditionGroupState();
  const { selectedConditionType } = useSelectedConditionType({
    options,
    conditionIndex,
    groupIndex,
  });
  const [showMenu, setShowMenu] = useState(false);

  const handleChange: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const conditionKey = e.currentTarget.getAttribute("data-condition-key");

      if (conditionKey) {
        if (conditionGroup) {
          const newGroup: Group = { ...conditionGroup };
          if (newGroup) {
            // Check if the group and condition at the specified indexes exist
            if (newGroup && newGroup.conditions?.length) {
              if (newGroup.conditions[conditionIndex]) {
                const selectedOption = options.find(
                  (option) => option.conditionTypeKey === conditionKey,
                );
                let time = null;
                let currency = null;
                if (
                  selectedOption?.compareDetails &&
                  (selectedOption?.compareDetails[0] as CompareDetail)
                    ?.fieldDataType === "hoursOrDaysNumeric"
                ) {
                  time = "hours";
                } else if (
                  selectedOption?.compareDetails &&
                  ((selectedOption?.compareDetails[0] as CompareDetail)
                    ?.fieldDataType === "currencyNumeric" ||
                    (selectedOption?.compareDetails[0] as CompareDetail)
                      ?.fieldDataType === "currencyRangeNumeric")
                ) {
                  currency = "percentage";
                }
                // Merge the existing condition data with the new payload
                newGroup.conditions[conditionIndex] = {
                  ...newGroup.conditions[conditionIndex],
                  conditionType: conditionKey ? conditionKey : null,
                  ...(time !== null && time !== undefined
                    ? { value: { value: "0", time: time } }
                    : {}),
                  ...(currency !== null && currency !== undefined
                    ? { value: { value: "0", currency: currency } }
                    : {}),
                  value: null,
                  // Add this line when value needs to persist even after condition type changes
                  // conditionGroup.conditions[conditionIndex].fieldDataType ===
                  // newGroup.conditions[conditionIndex].fieldDataType
                  //   ? newGroup.conditions[conditionIndex].value
                  //   : null,
                };
              }
            }
            updateState({
              conditionGroup: newGroup,
              showErrors: false,
              saveStatus: "idle",
            });
          }
        }
        setShowMenu(false);
      }
    },
    [
      selectedConditionType,
      conditionIndex,
      groupIndex,
      updateState,
      setShowMenu,
      conditionGroup,
    ],
  );

  return (
    <div className={`me-md-2 mb-2 mb-md-0 ${styles.resWidth}`}>
      <Dropdown
        show={showMenu}
        onToggle={(show) => setShowMenu(show)}
        className={`${styles.resWidth}`}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          className={`${styles.dropHead}`}
        >
          <div>
            {prefix ? (
              <span className={`me-1 ${styles.prefixText}`}>{prefix}</span>
            ) : null}
            <span>
              {selectedConditionType?.conditionTypeKey
                ? selectedConditionType.conditionTypeName
                : "Choose condition"}
            </span>
          </div>
          <span className={`ps-2 ${styles.arrowDown}`}>
            {showMenu ? (
              <i className="fa-solid fa-caret-up"></i>
            ) : (
              <i className="fa-solid fa-caret-down"></i>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
          {options.map((option) => {
            return (
              <ConditionType
                key={(option as IConditionType).conditionTypeKey}
                option={option}
                selected={selectedConditionType}
                onChange={handleChange}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {showErrors && !selectedConditionType?.conditionTypeKey && (
        <div className={styles.errText}>{"Please enter valid values!"}</div>
      )}
    </div>
  );
};
export default ConditionTypes;
