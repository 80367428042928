import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import Lottie from "lottie-light-react";
import liveDot from "src/assets/images/liveDot.json";
import styles from "./ConfiguredStep.module.scss";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useCallback, useState } from "react";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import PublishAutomation from "src/routes/BotSettings/Children/Automation/AutomationTabSettings/Children/ConfigureSteps/Children/Components/PublishAutomation/PublishAutomation";
import { Question } from "src/services/Automation/getAutomationById.service";

interface Props {
  index: number;
  title: string;
  isActive: boolean;
  isComplete: boolean;
  questions?: Question[];
  isGoLiveStep?: boolean;
}

/**
 * Configure Step Component for showing step status
 */
const ConfiguredStep = ({
  index,
  title,
  isActive,
  isComplete,
  isGoLiveStep,
  questions,
}: Props) => {
  const { formData, updateFormData, type, isSaving } = useAutomationContext();
  const [loading, setLoading] = useState(false);
  const [showUnPublishModal, setShowUnpublishModal] = useState(false);

  const handleUnpublish = useCallback(async () => {
    if (!formData.showGoLive || !type) {
      console.log("Some Error Occured");
      return;
    }
    if (!formData.published) {
      setShowUnpublishModal(true);
    } else {
      setLoading(true);
      const payload = {
        integrationId: formData.integrationId,
        publish: !formData.published,
      };
      try {
        await updateAutomationService({
          type: type,
          payload: payload,
        });

        updateFormData({
          published: payload.publish,
          configured: payload.publish ? true : formData.configured,
        });
      } catch (err) {
        pushTheToast({
          position: "top-right",
          type: "danger",
          text:
            typeof err === "string"
              ? err
              : payload.publish
                ? "Could not publish automation"
                : "Could not unpublish automation",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    formData.integrationId,
    formData.published,
    formData.showGoLive,
    type,
    updateFormData,
  ]);

  return (
    <div className={`${styles.steps} ${isActive ? styles.active : ""}`}>
      <div className="d-flex align-items-start px-2 w-100 my-2">
        <div className="pe-3">
          <div className="d-flex flex-column align-items-center">
            <div
              className={`${styles.number} ${
                isActive || isComplete ? styles.active : ""
              } mt-1 d-flex justify-content-center align-items-center`}
            >
              {isComplete ? (
                <span>
                  <i className="fa-solid fa-check" />
                </span>
              ) : (
                <span>{index + 1}</span>
              )}
            </div>

            {/* Dot and Line for Each Question */}
            {questions &&
              !isGoLiveStep &&
              isActive &&
              questions.map((_, index) => (
                <div
                  key={index}
                  className="d-flex flex-column align-items-center"
                >
                  <div
                    className={`${styles.dot}`}
                    style={{
                      marginTop: index == 0 ? 18 : 0,
                    }}
                  ></div>
                  {index !== questions.length - 1 && (
                    <div className={styles.activeLine}></div>
                  )}
                </div>
              ))}
          </div>
        </div>

        {/* Content */}
        <div className={`${styles.container}`}>
          {/* Title */}
          <span
            className={`${styles.stepHeading} ${
              isComplete ? styles.completed : ""
            } ${isActive ? styles.active : ""} mb-0`}
          >
            {title}
          </span>
          {isGoLiveStep ? (
            <div className="mb-2">
              <div className={`${styles.subText}`}>
                Ticket Routing automation flow is live for{" "}
                {formData?.brand?.name}
              </div>
              <div className="d-flex align-items-center">
                <button
                  className={`mt-2 ${styles.moveBtn}`}
                  id="automation_goLive_btn"
                  disabled={isSaving}
                  onClick={() => handleUnpublish()}
                >
                  <span className="pe-1">
                    <i className="fa-regular fa-eye"></i>
                  </span>
                  <span> {formData.published ? "Unpublish" : "Go live"} </span>
                  {loading && (
                    <Spinner
                      className={`mx-1 my-auto`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  )}
                </button>

                {formData.published ? (
                  <div className="ms-3 mt-2 d-flex align-items-center">
                    <span
                      style={{
                        height: 20,
                        width: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Lottie animationData={liveDot}></Lottie>
                    </span>
                    <div className="ps-1">
                      <span className={`${styles.liveText}`}>Live</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {questions && !isGoLiveStep && isActive ? (
            <div>
              {questions.map((question, index) => (
                <div key={index}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className={`${styles.toolTipCustom}`}>
                        <span className={styles.toolTipText}>
                          {question.questionTitle}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div className={`${styles.questionTitle} my-2`}>
                      {question.questionTitle}
                    </div>
                  </OverlayTrigger>
                  {index !== questions.length - 1 && <hr className="m-0" />}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <Modal
        backdropClassName={`${styles.modalBrandBack}`}
        show={showUnPublishModal}
        onHide={() => setShowUnpublishModal(false)}
        dialogClassName={`${styles.modalBrandDialog}`}
        contentClassName={`${styles.modalBrandContent}`}
        centered={true}
      >
        <PublishAutomation onHide={() => setShowUnpublishModal(false)} />
      </Modal>
    </div>
  );
};

export default ConfiguredStep;
