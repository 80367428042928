import { useCallback, useState } from "react";
import styles from "../SequenceSection.module.scss";
interface Props {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange: (value: string) => void;
}
const BaseInput = ({
  onChange,
  value,
  disabled,
  error,
  placeholder,
}: Props) => {
  const [val, setVal] = useState(value ?? "");
  const [isEditing, setIsEditing] = useState(false);
  const handleChange = useCallback(
    (e: any) => {
      setVal(e.target.value);
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    onChange(val);
  }, [onChange]);

  return (
    <div
      className={`ms-3 mb-3 ${styles.inputWrapper} w-100 d-flex flex-column`}
    >
      <input
        type="email"
        className={`form-control ps-3 pe-4 ${styles.inputCheck} ${
          error && error?.trim().length > 0 ? styles.errBorder : ""
        } ${disabled || !isEditing ? styles.disableInput : ""}`}
        id="intro"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={!isEditing}
        disabled={disabled || !isEditing}
      />
      {!isEditing ? (
        <span
          className={`${styles.editIcon} ${
            disabled ? styles.disabled : ""
          } cursor-pointer`}
          onClick={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.243"
            height="13.547"
            viewBox="0 0 15.243 13.547"
          >
            <path
              id="Icon_awesome-edit"
              data-name="Icon awesome-edit"
              d="M10.655,2.206l2.387,2.387a.259.259,0,0,1,0,.365l-5.78,5.78-2.456.273a.515.515,0,0,1-.569-.569L4.51,7.986l5.78-5.78A.259.259,0,0,1,10.655,2.206ZM14.942,1.6,13.65.309a1.035,1.035,0,0,0-1.461,0l-.937.937a.259.259,0,0,0,0,.365L13.64,4A.259.259,0,0,0,14,4l.937-.937a1.035,1.035,0,0,0,0-1.461ZM10.162,9.166V11.86H1.694V3.392H7.775A.325.325,0,0,0,8,3.3L9.059,2.241A.318.318,0,0,0,8.834,1.7H1.27A1.271,1.271,0,0,0,0,2.968v9.315a1.271,1.271,0,0,0,1.27,1.27h9.315a1.271,1.271,0,0,0,1.27-1.27V8.108a.318.318,0,0,0-.543-.225L10.255,8.941A.325.325,0,0,0,10.162,9.166Z"
              transform="translate(0 -0.007)"
              fill="#0b68bb"
            />
          </svg>
        </span>
      ) : null}
      {error && error?.trim().length > 0 ? (
        <div className={styles.errText}>{error}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BaseInput;
