import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserAvatar from "src/components/UserAvatar";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./CustomerInfo.module.scss";
import call from "src/assets/images/call-new.png";
import envelop from "src/assets/images/envelop.png";
import insta from "src/assets/images/insta-new.png";
import messenger from "src/assets/images/messenger-new.png";
import twitter from "src/assets/images/twitter.png";
import edit from "src/assets/images/editInfo.png";
import copy11 from "src/assets/images/copy1.png";
import { useEffect, useState } from "react";
import CustomerEdit from "./Children/CustomerEdit/CustomerEdit";
import { fetchCustomerData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import AxiosImg from "src/components/AxiosImg";
import InputFieldsContextProvider from "./Children/CustomerEdit/InputFieldsContextProvider";
import { highlightSearchedText } from "src/routes/TicketList/children/OuterTickets/Chidren/SearchedTickets/SearchedTickets";

function CustomerInfo() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
    // console.log("show");
  };
  const onHide = () => {
    setShowModal(false);
    // console.log("hide");
  };
  // hook for toggle copy
  const [copied, setCopied] = useState(false);
  const handleCopy = (copyMe: string) => {
    window.navigator.clipboard.writeText(copyMe);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const customerData = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );

  useEffect(() => {
    dispatch(
      fetchCustomerData({
        includeTimeline: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryMail = useAppSelector((state) => {
    return state.shopifySidebar.customerData.email;
  });

  const otherEmails = useAppSelector((state) => {
    return state.shopifySidebar.customerData.contacts?.otherEmails;
  });

  const phone = useAppSelector((state) => {
    return state.shopifySidebar.customerData.contacts?.phone;
  });

  const instagram = useAppSelector((state) => {
    return state.shopifySidebar.customerData.contacts?.instagram;
  });

  const facebook = useAppSelector((state) => {
    return state.shopifySidebar.customerData.contacts?.facebook;
  });

  const twitterData = useAppSelector((state) => {
    return state.shopifySidebar.customerData.contacts?.twitter;
  });

  const [showPopOverEmail, setShowPopoverEmail] = useState(false);
  const [showPopOverPhone, setShowPopoverPhone] = useState(false);
  const [showPopOverInsta, setShowPopoverInsta] = useState(false);
  const [showPopOverFB, setShowPopoverFB] = useState(false);
  const [showPopOverTwt, setShowPopoverTwt] = useState(false);

  function handleMouseEnterEmail() {
    setShowPopoverEmail(true);
  }
  function handleMouseLeaveEmail() {
    setShowPopoverEmail(false);
  }

  function handleMouseEnterPhone() {
    setShowPopoverPhone(true);
  }
  function handleMouseLeavePhone() {
    setShowPopoverPhone(false);
  }

  function handleMouseEnterInsta() {
    setShowPopoverInsta(true);
  }
  function handleMouseLeaveInsta() {
    setShowPopoverInsta(false);
  }

  function handleMouseEnterFB() {
    setShowPopoverFB(true);
  }
  function handleMouseLeaveFB() {
    setShowPopoverFB(false);
  }

  function handleMouseEnterTwt() {
    setShowPopoverTwt(true);
  }
  function handleMouseLeaveTwt() {
    setShowPopoverTwt(false);
  }

  const popover = (
    <div
      id="popover-basic"
      className={` dropdown-menu ${styles.customerDropDown}  mt-2 `}
    >
      <div className="d-flex">
        <div className={`${styles.hoverBar}`}></div>
        <span
          className={`py-0 px-1 ${styles.dropItem}`}
          onClick={onShow}
          role="button"
        >
          Edit Customer Details
        </span>
      </div>
    </div>
  );

  const searchTerm = useAppSelector(
    (state) => state.ticketGlobalSearch.searchText,
  );

  return (
    <div className={`${styles.customerInfo} my-1 pb-3 mb-2`}>
      {/* Profile Image */}
      <div className={`${styles.image2} pt-3 pb-1`}>
        {customerData.customerProfileImageUrl ? (
          <AxiosImg
            url={customerData.customerProfileImageUrl ?? ""}
            className="rounded-circle"
            isDirectURL={customerData.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={customerData?.name || "NA"}
            size={45}
          />
        )}

        <div className={`d-flex justify-content-center ${styles.editInfo}`}>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              delay={{ show: 150, hide: 200 }}
              rootClose={true}
            >
              <div style={{ maxHeight: "20px", maxWidth: "40px" }}>
                <div className={`${styles.customInfo}`}>
                  <img
                    src={edit}
                    alt=""
                    className={`${styles.editLogo}`}
                  />
                </div>
              </div>
            </OverlayTrigger>
          </div>
          <Modal
            show={showModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            backdropClassName={`${styles.backDrop}`}
            centered={true}
          >
            <InputFieldsContextProvider>
              <CustomerEdit onHide={onHide} />
            </InputFieldsContextProvider>
          </Modal>
        </div>
      </div>

      {/* Customer Name */}
      <div className={`${styles.name} mb-1 px-2`}>
        {highlightSearchedText(customerData?.name, searchTerm) || "NA"}
      </div>

      {/* Customer Email */}
      <div className={`${styles.email} mb-1 px-2`}>
        {highlightSearchedText(customerData?.email, searchTerm)}
      </div>

      {/* Updated ui for customer info */}
      <div className={`d-flex justify-content-center align-items-center mt-2`}>
        {/* Mail Icon */}

        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 1000 }}
          overlay={
            <Tooltip
              className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
              onMouseEnter={handleMouseEnterEmail}
              onMouseLeave={handleMouseLeaveEmail}
            >
              <div className={``}>
                {/* Primary Mail */}
                <div className={`mb-1 px-2 pt-2 pb-1`}>
                  <h6
                    className={`d-flex justify-content-start mb-1 ${styles.tipHead}`}
                  >
                    Primary email:
                  </h6>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <span className={`${styles.tipSpan} text-truncate`}>
                      {highlightSearchedText(primaryMail, searchTerm)}
                    </span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className={` ${styles.toolTipCustom} `}>
                          {copied ? "Copied" : "Copy"}
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <div
                          className={`ms-0 me-0`}
                          {...triggerHandler}
                          ref={ref}
                        >
                          <div
                            className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              handleCopy(primaryMail);
                            }}
                          >
                            <img
                              src={copy11}
                              alt=""
                              className={`${styles.editLogo}`}
                            />
                          </div>
                        </div>
                      )}
                    </OverlayTrigger>
                  </div>
                </div>

                {/* Other Mail */}

                {otherEmails !== undefined && otherEmails.length > 0 ? (
                  <div className={`mb-2 px-2 pt-2 pb-1`}>
                    <h6
                      className={`d-flex justify-content-start  mb-1 ${styles.tipHead}`}
                    >
                      Other emails:
                    </h6>
                    {otherEmails.map((mail) => {
                      return (
                        <div
                          className={`d-flex align-items-center justify-content-between mb-2`}
                          key={mail}
                        >
                          <span className={`${styles.tipSpan} text-truncate`}>
                            {highlightSearchedText(mail, searchTerm)}
                          </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className={` ${styles.toolTipCustom}`}>
                                {copied ? "Copied" : "Copy"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className={`ms-0 me-0`}
                                {...triggerHandler}
                                ref={ref}
                              >
                                <div
                                  className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    handleCopy(mail);
                                  }}
                                >
                                  <img
                                    src={copy11}
                                    alt=""
                                    className={`${styles.editLogo}`}
                                  />
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Tooltip>
          }
          show={showPopOverEmail}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              className={`ms-0 me-0`}
              {...triggerHandler}
              ref={ref}
            >
              <div
                className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onMouseEnter={handleMouseEnterEmail}
                onMouseLeave={handleMouseLeaveEmail}
              >
                <img
                  src={envelop}
                  alt=""
                  className={`${styles.envelop}`}
                />
              </div>
            </div>
          )}
        </OverlayTrigger>

        {/* Phone Icon */}

        {phone && phone?.length > 0 ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                onMouseEnter={handleMouseEnterPhone}
                onMouseLeave={handleMouseLeavePhone}
              >
                {phone !== undefined && phone.length > 0 ? (
                  <div className={``}>
                    {phone.map((phone) => {
                      return (
                        <div className={`mb-2 px-2 pt-2`}>
                          <div
                            className={`d-flex align-items-center justify-content-between mb-2`}
                          >
                            <span className={`${styles.tipSpan} text-truncate`}>
                              {highlightSearchedText(
                                phone.phoneNumber,
                                searchTerm,
                              )}
                            </span>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className={` ${styles.toolTipCustom}`}>
                                  {copied ? "Copied" : "Copy"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <div
                                  className={`ms-0 me-0`}
                                  {...triggerHandler}
                                  ref={ref}
                                >
                                  <div
                                    className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => {
                                      handleCopy(
                                        `${phone.countryCode} ${phone.phoneNumber}`,
                                      );
                                    }}
                                  >
                                    <img
                                      src={copy11}
                                      alt=""
                                      className={`${styles.editLogo}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </Tooltip>
            }
            show={showPopOverPhone}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`ms-0 me-0`}
                {...triggerHandler}
                ref={ref}
              >
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseEnterPhone}
                  onMouseLeave={handleMouseLeavePhone}
                >
                  <img
                    src={call}
                    alt=""
                    className={`${styles.callLogo}`}
                  />
                </div>
              </div>
            )}
          </OverlayTrigger>
        ) : (
          ""
        )}

        {/* Insta Icon */}
        {instagram && instagram.length > 0 ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                onMouseEnter={handleMouseEnterInsta}
                onMouseLeave={handleMouseLeaveInsta}
              >
                <div className={``}>
                  {instagram.map((ig) => {
                    return (
                      <div className={`mb-2 px-2 pt-1`}>
                        <div
                          className={`d-flex align-items-center justify-content-between mb-2`}
                        >
                          <span className={`${styles.tipSpan} text-truncate`}>
                            {highlightSearchedText(ig, searchTerm)}
                          </span>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className={` ${styles.toolTipCustom}`}>
                                {copied ? "Copied" : "Copy"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className={`ms-0 me-0`}
                                {...triggerHandler}
                                ref={ref}
                              >
                                <div
                                  className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    handleCopy(ig);
                                  }}
                                >
                                  <img
                                    src={copy11}
                                    alt=""
                                    className={`${styles.instaLogo}`}
                                  />
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Tooltip>
            }
            show={showPopOverInsta}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`ms-0 me-0`}
                {...triggerHandler}
                ref={ref}
              >
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseEnterInsta}
                  onMouseLeave={handleMouseLeaveInsta}
                >
                  <img
                    src={insta}
                    alt=""
                    className={`${styles.editLogo}`}
                  />
                </div>
              </div>
            )}
          </OverlayTrigger>
        ) : (
          ""
        )}

        {/* Messenger Icon */}
        {facebook && facebook.length > 0 ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                onMouseEnter={handleMouseEnterFB}
                onMouseLeave={handleMouseLeaveFB}
              >
                <div className={``}>
                  {facebook.map((fb) => {
                    return (
                      <div className={`mb-2 px-2 pt-1`}>
                        <div
                          className={`d-flex align-items-center justify-content-between mb-2`}
                        >
                          <span className={`${styles.tipSpan} text-truncate`}>
                            {highlightSearchedText(fb, searchTerm)}
                          </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                className={` Copy ${styles.toolTipCustom}`}
                              >
                                {copied ? "Copied" : "Copy"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className={`ms-0 me-0`}
                                {...triggerHandler}
                                ref={ref}
                              >
                                <div
                                  className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    handleCopy(fb);
                                  }}
                                >
                                  <img
                                    src={copy11}
                                    alt=""
                                    className={`${styles.editLogo}`}
                                  />
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Tooltip>
            }
            show={showPopOverFB}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`ms-0 me-0`}
                {...triggerHandler}
                ref={ref}
              >
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseEnterFB}
                  onMouseLeave={handleMouseLeaveFB}
                >
                  <img
                    src={messenger}
                    alt=""
                    className={`${styles.messengerLogo}`}
                  />
                </div>
              </div>
            )}
          </OverlayTrigger>
        ) : (
          ""
        )}

        {/* Twitter Icon */}
        {twitterData && twitterData.length > 0 ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                className={`override-white px-3 py-2  ${styles.toolTipCustom}`}
                onMouseEnter={handleMouseEnterTwt}
                onMouseLeave={handleMouseLeaveTwt}
              >
                {twitterData.map((twt) => {
                  return (
                    <div className={``}>
                      <div className={`mb-2 px-2 pt-1`}>
                        <div
                          className={`d-flex align-items-center justify-content-between `}
                        >
                          <span className={`${styles.tipSpan} text-truncate`}>
                            {highlightSearchedText(twt, searchTerm)}
                          </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className={` ${styles.toolTipCustom}`}>
                                {copied ? "Copied" : "Copy"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className={`ms-0 me-0`}
                                {...triggerHandler}
                                ref={ref}
                              >
                                <div
                                  className={`d-flex justify-content-center align-items-center mx-2 ${styles.customInfo} ${styles.customInfo3}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    handleCopy(twt);
                                  }}
                                >
                                  <img
                                    src={copy11}
                                    alt=""
                                    className={`${styles.editLogo}`}
                                  />
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  );
                })}
              </Tooltip>
            }
            show={showPopOverTwt}
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`ms-0 me-0`}
                {...triggerHandler}
                ref={ref}
              >
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.customInfo} ${styles.customInfo2} mx-2`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onMouseEnter={handleMouseEnterTwt}
                  onMouseLeave={handleMouseLeaveTwt}
                >
                  <img
                    src={twitter}
                    alt=""
                    className={`${styles.twit}`}
                  />
                </div>
              </div>
            )}
          </OverlayTrigger>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CustomerInfo;
