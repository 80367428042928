import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { CampaignCategory } from "src/services/Campaign/getAllCampaignCategories.service";
import { useAddCampaignState } from "../../../../../../../../Hooks/useAddCampaignContext";
import styles from "../../CampaignType.module.scss";
import CategorySelect, {
  CategorySelectOption,
} from "../CategorySelect/CategorySelect";

const CategorySelectDD = ({
  allCampaignCategories,
  fetchMore,
  fetchStatus,
}: {
  allCampaignCategories: CampaignCategory[];
  fetchMore: () => void;
  fetchStatus: AJAXSTATUS;
}) => {
  const { state, dispatch } = useAddCampaignState();

  const allOptions = useMemo(() => {
    return allCampaignCategories.map((val) => {
      return {
        label: val.name,
        value: val.id,
        isDescriptionRequired: val.isDescriptionRequired,
      } as CategorySelectOption;
    });
  }, [allCampaignCategories]);

  const selectedOption = useMemo(() => {
    return allOptions.find((val) => val.value === state.categoryId);
  }, [allOptions, state.categoryId]);

  const onChange = useCallback(
    (categoryOption: CategorySelectOption) => {
      if (categoryOption) {
        dispatch("updateState", {
          categoryId: categoryOption.value,
          isCategoryDesctiptionRequired: categoryOption.isDescriptionRequired,
          subCategoryId: undefined
        });
      }
    },
    [dispatch],
  );

  const handleChangeCategoryName = useCallback(
    (categoryDescription: string) => {
      dispatch("updateState", { categoryDescription });
    },
    [dispatch],
  );

  return (
    <div className="w-50 pe-4">
      <label
        htmlFor="campaignCategory"
        className={`form-label ${styles.label}`}
      >
        Category
      </label>

      <CategorySelect
        options={allOptions}
        value={selectedOption}
        inputId="campaignCategory"
        placeholder="Select category"
        onMenuScrollToBottom={fetchMore}
        onChange={(val) => {
          onChange(val as CategorySelectOption);
        }}
        isLoading={fetchStatus === "pending"}
        isMulti={false}
        isSearchable={false}
        isClearable={false}
        showError={state.showErrors && !selectedOption}
      />
      {selectedOption?.isDescriptionRequired && (
        <input
          type="text"
          className={`w-100 mt-1 ps-2 py-1 ${
            state.showErrors &&
            !state?.categoryDescription?.trim()?.length &&
            styles.borderRed
          } ${styles.inputText}`}
          value={state.categoryDescription}
          placeholder="name category"
          onChange={(e) => handleChangeCategoryName(e.target.value)}
        />
      )}
    </div>
  );
};

export default CategorySelectDD;
