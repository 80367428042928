import React, { useState } from "react";
import styles from "./EditPipeline.module.scss";
import editIcon from "src/assets/images/editIcon.png";
import { Modal } from "react-bootstrap";
import PipelineModal from "./Children/PipelineModal/PipelineModal";
interface Props {
  firstOpenTab: "events" | "timeline",
  customerId: string | number,
  editText: string,
}
const EditPipeline = ({ customerId, firstOpenTab, editText }: Props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={`position-relative h-0`}>
      <div className={`d-flex align-items-center ${styles.editPipeline}`} onClick={() => setShowModal(true)}>
        <img
          src={editIcon}
          alt="edit"
          className="cursor-pointer"
          width={11}
          
        />
        <span className="ps-1">{editText}</span>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdropClassName={`${styles.modalBackDrop}`}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <PipelineModal
          onHide={() => setShowModal(false)}
          customerId={customerId}
          firstOpenTab={firstOpenTab}
        />
      </Modal>
    </div>
  );
};

export default EditPipeline;
