/**
 * This file is the custom hook and context file.
 * It contains useSaveStepsAndQuestions custom hook.
 *
 * @author Yash Aditya
 * @fileoverview Custom hook is for saving return auto work flow steps and questions.
 *
 */

import { useCallback, useMemo, useRef, useState } from "react";
import { useReturnAutoWorkFlow } from "./useReturnAutoWorkFlow";
import saveBaseQuestionAndStepService, {
  SaveBaseQuestionAndStepParams,
  StepQuestionData,
} from "src/services/ReturnAutoWorkFlow/saveBaseQuestionAndStep.service";
import {
  EmailForwardingDetails,
  StepData,
  StepQuestion,
  StripeDetails,
} from "../ReturnAutoWorkFlow.types";
import { validateEmail } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

/**
 * Interface representing the payload data used to save step and question details.
 */
export interface SaveStepAndQuestionDataParams {
  /**
   * Indicates whether the "Next" button was clicked to move to the next step or question.
   * If true, triggers navigation to the next question or step after saving the data.
   *
   * @default false
   */
  moveToNextBtnClick?: boolean;

  /**
   * A reference object containing callback functions for handling success and error scenarios after attempting to save the data.
   * It can be created at the component where the function is being executed and can be passed as parameter.
   * It will execute the given callbacks on success or error, if any further operation needs to be handled.
   */
  callbackRef?: React.MutableRefObject<{
    /**
     * Callback function to be executed if the save api call operation is successful.
     */
    saveCallbackSuccess?: () => void;
    /**
     * Callback function to be executed if the save api call operation encounters an error.
     */
    saveCallbackError?: () => void;
  }>;

  /**
   * Partial data related to the current question. This can be used to update or save
   * specific fields within the step question without requiring the full question object.
   * Mostly used to save the question values.
   */
  questionDataValue?: Partial<StepQuestion>;

  /**
   * Specific data related to saving an spqcific questions, such as email forwarding details
   * or Stripe details.
   */
  specificQuestionSaveValue?: {
    /**
     * The specific question value data.
     */
    value: Partial<EmailForwardingDetails> | Partial<StripeDetails>;
    /**
     * If true, the active email address will be added to an array of saved emails.
     */
    putActiveEmailInArray?: boolean;
  };
}

const canShowExtraValue = (stepQuestion: StepQuestion) => {
  const options = stepQuestion.options ?? [];
  const values = (stepQuestion.value ?? []) as Array<string>;
  return (
    stepQuestion.valueType === "multiCheck" &&
    options.length !== values.length &&
    stepQuestion.extraTextQuestion
  );
};

/**
 * Helper function to validate steps and questions in the return auto workflow.
 *
 * @param stepQuestion - The data for the current question.
 *
 */
export const validateAndAddQuestionValuesInParams = ({
  stepQuestion,
}: {
  stepQuestion: StepQuestion;
}) => {
  /**
   * A flag to track if the data is valid, initially set to true.
   */
  let valid = true;
  let allowAPICallIfNotValid = false;

  const questionData: StepQuestionData = {
    // The ID of the current question.
    questionId: stepQuestion.questionId,
    // These are potential properties of StepQuestionData.
    // "comboValue","moveToNextQuestion","extraValue","specificQuestionValue","value"
  };

  // Switch case to handle different specific question types.
  switch (stepQuestion.specificQuestionType) {
    case "emailVerification": {
      // Type assertion that specificQuestionValue could be EmailForwardingDetails or undefined.
      // Extract and trim the activeEmailForVerification, or use an empty string if undefined.
      const email =
        (
          stepQuestion.specificQuestionValue as
            | EmailForwardingDetails
            | undefined
        )?.activeEmailForVerification?.trim() ?? "";

      if (validateEmail(email)) {
        // Check if the email is valid using validateEmail function.
        // If valid, assign the email to specificQuestionValue.
        questionData.specificQuestionValue = email;
      } else {
        // If invalid, set the valid flag to false.
        valid = false;
      }
      break;
    }
    case "stripeDetails": {
      if (
        // Type assertion that specificQuestionValue could be StripeDetails or undefined.
        (
          stepQuestion.specificQuestionValue as StripeDetails | undefined
        )?.secretKey?.trim() &&
        // Check if the secretKey exists and is non-empty after trimming.
        // Type assertion that specificQuestionValue could be StripeDetails or undefined.
        // Check if the secretPublishableKey exists and is non-empty after trimming.
        (
          stepQuestion.specificQuestionValue as StripeDetails | undefined
        )?.secretPublishableKey?.trim()
      ) {
        questionData.specificQuestionValue = {
          // Assign the secretKey from specificQuestionValue.
          secretKey: (stepQuestion.specificQuestionValue as StripeDetails)
            .secretKey,
          // Assign the secretPublishableKey from specificQuestionValue.
          secretPublishableKey: (
            stepQuestion.specificQuestionValue as StripeDetails
          ).secretPublishableKey,
        };
      } else {
        // If either key is invalid, set the valid flag to false.
        valid = false;
      }
      break;
    }
    case "webhookQuestion": {
      questionData.specificQuestionValue = null;
      break;
    }

    default: {
      // Switch case to handle different value types when the question is not specific.
      switch (stepQuestion.valueType) {
        case "combo": {
          // Check if both comboValue and comboOptions exist in stepQuestion
          if (stepQuestion.comboValue && stepQuestion.comboOptions) {
            // Initialize a flag to track the validity of the combo options
            let comboValid = true;

            // Loop through each option in the comboOptions array
            for (let i = 0; i < stepQuestion.comboOptions.length; i++) {
              const option = stepQuestion.comboOptions[i];

              // Determine the type of the value associated with the option
              switch (option.valueType) {
                case "numberButton": {
                  // Check if the value is a numberButton related and is not NaN (Not a Number)
                  if (
                    !(
                      typeof stepQuestion.comboValue[option.valueKey + ""] ===
                        "number" &&
                      !isNaN(
                        stepQuestion.comboValue[option.valueKey + ""] as number,
                      )
                    )
                  ) {
                    // If the value is invalid, mark comboValid as false
                    comboValid = false;
                  }
                  break;
                }

                case "number": {
                  // Check if the value is a number and is not NaN (Not a Number)
                  if (
                    !(
                      typeof stepQuestion.comboValue[option.valueKey + ""] ===
                        "number" &&
                      !isNaN(
                        stepQuestion.comboValue[option.valueKey + ""] as number,
                      )
                    )
                  ) {
                    // If the value is invalid, mark comboValid as false
                    comboValid = false;
                  }
                  break;
                }

                case "string": {
                  // Check if the value is a non-empty, trimmed string
                  if (
                    !(
                      stepQuestion.comboValue[option.valueKey + ""] as string
                    )?.trim()
                  ) {
                    // If the value is invalid, mark comboValid as false
                    comboValid = false;
                  }
                  break;
                }

                case "singleSelect": {
                  // Check if the value is a non-empty, trimmed string (single selection)
                  if (
                    !(
                      stepQuestion.comboValue[option.valueKey + ""] as string
                    )?.trim()
                  ) {
                    // If the value is invalid, mark comboValid as false
                    comboValid = false;
                  }
                  break;
                }

                case "multiSelect": {
                  // Check if the value is an array and has at least one element (multi-selection)
                  if (
                    !(
                      Array.isArray(
                        stepQuestion.comboValue[option.valueKey + ""],
                      ) &&
                      (
                        stepQuestion.comboValue[
                          option.valueKey + ""
                        ] as Array<string>
                      ).length
                    )
                  ) {
                    // If the value is invalid, mark comboValid as false
                    comboValid = false;
                  }
                  break;
                }

                // If the valueType doesn't match any known type, do nothing (default case)
                default: {
                  break;
                }
              }
            }

            // If all comboOptions are valid, update the questionData.comboValue
            if (comboValid) {
              questionData.comboValue = stepQuestion.comboValue;
            } else {
              // If any comboOption is invalid, mark the overall validation as false
              valid = false;
            }
          } else {
            // If either comboValue or comboOptions is missing, mark the overall validation as false
            valid = false;
          }

          break;
        }

        case "multiCheck": {
          if (Array.isArray(stepQuestion.value) && stepQuestion.value.length) {
            // Check if the value is an array.
            // If valid, assign the array to the value property.
            questionData.value = stepQuestion.value;
          } else {
            questionData.value = [];
            // If invalid, set the valid flag to false.
            valid = false;
            allowAPICallIfNotValid = true;
          }
          break;
        }

        case "multiSelect": {
          if (Array.isArray(stepQuestion.value) && stepQuestion.value.length) {
            // Check if the value is an array.
            // If valid, assign the array to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }

        case "multiSelectButtons": {
          if (Array.isArray(stepQuestion.value) && stepQuestion.value.length) {
            // Check if the value is an array.
            // If valid, assign the array to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }

        case "singleSelectButtons": {
          if (
            // Check if the value is a string.
            typeof stepQuestion.value === "string" &&
            // Ensure the string is non-empty after trimming.
            stepQuestion.value.trim()
          ) {
            // Check if the value is an array.
            // If valid, assign the array to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        case "none": {
          // No specific implementation, likely a placeholder for a no-value scenario.
          break;
        }
        case "number": {
          // No specific implementation, likely a placeholder for handling numeric values.
          break;
        }
        case "singleCheck": {
          if (
            // Check if the value is a string.
            typeof stepQuestion.value === "string" &&
            // Ensure the string is non-empty after trimming.
            stepQuestion.value.trim()
          ) {
            // If valid, assign the trimmed string to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        case "singleSelect": {
          if (
            // Check if the value is a string.
            typeof stepQuestion.value === "string" &&
            // Ensure the string is non-empty after trimming.
            stepQuestion.value.trim()
          ) {
            // If valid, assign the trimmed string to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        case "string": {
          if (
            // Check if the value is a string.
            typeof stepQuestion.value === "string" &&
            // Ensure the string is non-empty after trimming.
            stepQuestion.value.trim()
          ) {
            // If valid, assign the trimmed string to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        case "html": {
          if (
            // Check if the value is a string.
            typeof stepQuestion.value === "string" &&
            // Ensure the string is non-empty after trimming.
            stepQuestion.value.trim()
          ) {
            // If valid, assign the trimmed string to the value property.
            questionData.value = stepQuestion.value;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }

        case "stringList": {
          // Check if the stringListValue is an array.
          if (
            Array.isArray(stepQuestion.stringListValue) &&
            stepQuestion.stringListValue.length
          ) {
            let thisValid = true;
            for (let i = 0; i < stepQuestion.stringListValue.length; i++) {
              const v = stepQuestion.stringListValue[i];
              if (!v?.value?.trim()) {
                thisValid = false;
                break;
              }
            }
            if (thisValid) {
              // If valid, assign the array to the stringListValue property.
              questionData.stringListValue = stepQuestion.stringListValue;
            } else {
              valid = false;
            }
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        case "address": {
          if (
            // Check if the value is a object and adress data present in it.
            typeof stepQuestion.addressValue === "object" &&
            stepQuestion.addressValue &&
            stepQuestion.addressValue.addressLineOne?.trim() &&
            stepQuestion.addressValue.city?.trim() &&
            stepQuestion.addressValue.country?.trim() &&
            stepQuestion.addressValue.state?.trim() &&
            stepQuestion.addressValue.phoneNumber?.trim() &&
            stepQuestion.addressValue.pin?.trim()
          ) {
            // If valid, assign the trimmed string to the value property.
            questionData.addressValue = stepQuestion.addressValue;
          } else {
            // If invalid, set the valid flag to false.
            valid = false;
          }
          break;
        }
        default: {
          // Default case for any valueType not explicitly handled above.
          break;
        }
      }

      /**
       * Need to check if option for `nothing selected` exists then save the extraValue and validate it as well.
       * Also do it only if the `valid` is `false`.
       */
      if (canShowExtraValue(stepQuestion)) {
        if (stepQuestion.extraValue?.trim()) {
          questionData.extraValue = stepQuestion.extraValue;

          /**
           * If the `valid` is made `false` in any of the above cases, then need to make it `true` as `extraValue` exists.
           */
          valid = true;
        } else {
          valid = false;
        }
      }

      // Exit the outer switch case.
      break;
    }
  }

  return { valid, allowAPICallIfNotValid, questionData };
};

/**
 * It returns if any step in this question have any errors.
 * @param stepData
 */
export const questionsHaveErrorsInAStep = (stepData: StepData) => {
  let error = false;
  for (let i = 0; i < stepData.stepQuestionIds.length; i++) {
    const questionId = stepData.stepQuestionIds[i];
    const question = stepData.stepQuestions[questionId];
    const { valid } = validateAndAddQuestionValuesInParams({
      stepQuestion: question,
    });
    if (valid) {
      error = false;
    } else {
      error = true;
    }
  }
  return error;
};

/**
 * Helper function to save steps and questions in the return auto workflow.
 *
 * @param stepId - The ID of the current step.
 * @param stepQuestion - The data for the current question.
 * @param returnAutoWorkFlow - The return auto workflow state and actions.
 * @param dispatch - The dispatch function to update the state.
 *
 * @returns - A promise that resolves when the save operation is complete.
 */
const saveStepsAndQuestionsHelper = async ({
  stepId,
  stepQuestion,
  moveToNextBtnClick,
  questionContainsKeyInputs,
  returnAutoWorkFlow,
  dispatch,
}: {
  stepId: string;
  moveToNextBtnClick: boolean;
  questionContainsKeyInputs: boolean;
  stepQuestion: StepQuestion;
} & ReturnType<typeof useReturnAutoWorkFlow>) => {
  const params: SaveBaseQuestionAndStepParams = {
    // The integration ID from the workflow, converted to a string.
    integrationId: returnAutoWorkFlow.integrationId + "",
    // The ID of the current step.
    stepId,
    // An array to hold data related to the current step's questions, initialized as empty.
    stepData: [],
  };

  const {
    valid,
    allowAPICallIfNotValid,
    questionData,
  }: ReturnType<typeof validateAndAddQuestionValuesInParams> =
    // 1. Checking for text type not active and move to next active then only saving move to next only.
    !questionContainsKeyInputs && moveToNextBtnClick
      ? {
          valid: true,
          allowAPICallIfNotValid: false,
          questionData: {
            questionId: stepQuestion.questionId,
          },
        }
      : // 2. Else saving all data
        validateAndAddQuestionValuesInParams({
          stepQuestion,
        });

  // 3. Along with move to next.
  if (
    moveToNextBtnClick &&
    (stepQuestion.showMoveToNext === "question" ||
      stepQuestion.showMoveToNext === "step")
  ) {
    questionData.moveToNextQuestion = true;
  }

  if (!valid && !allowAPICallIfNotValid) {
    // Check if the data is invalid.
    // If invalid, return an object indicating the validity status.
    return { valid };
  }

  // Add the questionData to the stepData array in params.
  params.stepData.push(questionData);

  const res = await saveBaseQuestionAndStepService(params);

  // Update the returned response.
  dispatch("updateUsingSaveAPIResponse", res);

  if (stepQuestion.showMoveToNext === "step" && moveToNextBtnClick) {
    dispatch("setState", {
      activeStaticModal: "moveToNextModal",
      activeStaticModalStepId: stepId,
      activeStaticModalQuestionId: stepQuestion.questionId,
    });
  }

  // Return an object indicating the validity status after processing.
  return { valid } as { valid: boolean };
};

/**
 * Custom hook to manage saving steps and questions in the return auto workflow.
 *
 * @param stepId - The ID of the current step.
 * @param questionId - The ID of the current question.
 *
 * @returns - Returns various properties and methods related to the return auto workflow.
 */
function useSaveStepsAndQuestions({
  stepId,
  questionId,
}: {
  stepId: string;
  questionId: string;
}) {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  const integrationId = useMemo(() => {
    return returnAutoWorkFlow.integrationId ?? "";
  }, [returnAutoWorkFlow]);

  const allStepsConfigured = useMemo(
    () => (returnAutoWorkFlow.configured ? true : false),
    [returnAutoWorkFlow],
  );

  const questionNumber = useMemo(() => {
    let index = 0;
    if (returnAutoWorkFlow.savedStepAndQuestionIds?.length) {
      for (
        let i = 0;
        i < returnAutoWorkFlow.savedStepAndQuestionIds.length;
        i++
      ) {
        let breakIt = false;
        const stepValue = returnAutoWorkFlow.savedStepAndQuestionIds[i];
        for (let j = 0; j < stepValue.questionIds.length; j++) {
          index++;
          const questionIdThis = stepValue.questionIds[j];
          if (questionId + "" === questionIdThis + "") {
            breakIt = true;
            break;
          }
        }
        if (breakIt) {
          break;
        }
      }
    }
    return index;
  }, [returnAutoWorkFlow, questionId]);

  /**
   * Memoized values for determining if the next step exists, the current step data,
   * and the saved question IDs for the current step.
   */
  const { nextStepExists, stepData, savedQuestionIds } = useMemo(() => {
    /**
     * Flag to check if the next step exists
     */
    let exists = false;
    let savedQIds: Array<string> = [];

    // Check if there are saved step and question IDs in the returnAutoWorkFlow
    if (returnAutoWorkFlow.savedStepAndQuestionIds) {
      // Loop through each saved step and question ID
      for (
        let i = 0;
        i < returnAutoWorkFlow.savedStepAndQuestionIds.length;
        i++
      ) {
        const savedStep = returnAutoWorkFlow.savedStepAndQuestionIds[i];

        // Check if the current step ID matches the saved step ID
        if (savedStep.stepId + "" === stepId + "") {
          // Get the saved question IDs for this step
          savedQIds = savedStep.questionIds;

          // Check if there is a next step in the list
          if (i + 1 < returnAutoWorkFlow.savedStepAndQuestionIds.length) {
            exists = true;
          }
        }
      }
    }

    return {
      nextStepExists: exists,
      stepData: (returnAutoWorkFlow.configuredSteps ?? {})[stepId],
      savedQuestionIds: savedQIds,
    };
  }, [returnAutoWorkFlow, stepId]);

  /**
   * Memoized values for determining if the next question exists and the current step question data.
   */
  const { nextQuestionExists, stepQuestion } = useMemo(() => {
    /**
     * Flag to check if the next question exists
     */
    let exists = false;

    // Loop through each saved question ID for this step
    for (let i = 0; i < savedQuestionIds.length; i++) {
      const qId = savedQuestionIds[i];

      // Check if the current question ID matches the saved question ID
      if (qId + "" === questionId + "") {
        // Check if there is a next question in the list
        if (i + 1 < savedQuestionIds.length) {
          exists = true;
        }
      }
    }
    return {
      nextQuestionExists: exists,
      stepQuestion: stepData.stepQuestions[questionId],
    };
  }, [stepData, savedQuestionIds, questionId]);

  const showExtraValue = useMemo(
    () => canShowExtraValue(stepQuestion),
    [stepQuestion],
  );

  /**
   * `true` if valid.
   */
  const questionIsValid = useMemo(() => {
    const { valid } = validateAndAddQuestionValuesInParams({
      stepQuestion,
    });
    return valid;
  }, [stepQuestion]);

  /**
   * This variable is used to check if we can render move to next button for a question or not.
   */
  const questionContainsKeyInputs = useMemo(() => {
    /**
     * Checking for non value text.
     */
    if (
      stepQuestion.valueType === "multiCheck" &&
      stepQuestion.options &&
      stepQuestion.extraTextQuestion
    ) {
      return true;
    } else if (
      stepQuestion.valueType === "string" ||
      stepQuestion.valueType === "html" ||
      stepQuestion.valueType === "stringList" ||
      stepQuestion.valueType === "address"
    ) {
      /**
       * Checking all the string value types.
       */
      return true;
    } else if (
      stepQuestion.valueType === "combo" &&
      stepQuestion.comboOptions
    ) {
      // Checking combo value tipe if any text box field exists like, text or number. Then returning true elae false.
      const option = stepQuestion.comboOptions.find(
        (v) => v.valueType === "number" || v.valueType === "string",
      );
      if (option) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [stepQuestion, showExtraValue]);

  /**
   * This this the active modal config for the given button, if the button is visibal.
   * On clicking on that config button, config modals open.
   */
  const modalConfigDetails = useMemo(() => {
    if (stepQuestion.configureDetails) {
      return stepQuestion.configureDetails;
    } else {
      return null;
    }
  }, [stepQuestion]);

  const { savingStepAndQuestionData, showErrors, questionHaveUpdates } =
    useMemo(() => {
      return (
        returnAutoWorkFlow.questionStates[`${stepId}::${questionId}`] ?? {
          questionHaveUpdates: false,
          savingStepAndQuestionData: null,
          // Show errors if the workflow is configured
          showErrors: returnAutoWorkFlow.configured
            ? true
            : // Otherwise, show errors if the next step exists
              nextStepExists
              ? true
              : // Or show errors if the next question exists
                nextQuestionExists,
        }
      );
    }, [
      returnAutoWorkFlow,
      stepId,
      questionId,
      nextStepExists,
      nextQuestionExists,
    ]);

  const setSavingStepAndQuestionData = useCallback(
    (value: typeof savingStepAndQuestionData) => {
      dispatch("updateQuestionStates", {
        key: `${stepId}::${questionId}`,
        value: { savingStepAndQuestionData: value },
      });
    },
    [dispatch, stepId, questionId],
  );

  const setShowErrors = useCallback(
    (value: typeof showErrors) => {
      dispatch("updateQuestionStates", {
        key: `${stepId}::${questionId}`,
        value: { showErrors: value },
      });
    },
    [dispatch, stepId, questionId],
  );

  const setQuestionHaveUpdates = useCallback(
    (value: typeof questionHaveUpdates) => {
      dispatch("updateQuestionStates", {
        key: `${stepId}::${questionId}`,
        value: { questionHaveUpdates: value },
      });
    },
    [dispatch, stepId, questionId],
  );

  /**
   * Maintaining current state to use the updated value immediately after a dispatch.
   */
  const currentState = useRef({
    returnAutoWorkFlow,
    stepData,
    stepQuestion,
    questionContainsKeyInputs,
  });
  /**
   * updating current state to later get the updated value immediately after a dispatch.
   */
  useMemo(() => {
    currentState.current.returnAutoWorkFlow = returnAutoWorkFlow;
    currentState.current.stepData = stepData;
    currentState.current.stepQuestion = stepQuestion;
    currentState.current.questionContainsKeyInputs = questionContainsKeyInputs;
  }, [returnAutoWorkFlow, stepData, stepQuestion, questionContainsKeyInputs]);

  /**
   * Callback to save step and question data.
   * Handles setting the loading state and error handling.
   *
   * @param payload - Used to perform operations on the data to be saved.
   */
  const saveStepAndQuestionData = useCallback(
    (payload?: SaveStepAndQuestionDataParams) => {
      if (!payload) {
        payload = {};
      }
      const {
        moveToNextBtnClick = false,
        callbackRef,
        questionDataValue,
        specificQuestionSaveValue,
      } = payload;

      if (questionDataValue) {
        dispatch("updateStepQuestionData", {
          questionId: currentState.current.stepQuestion.questionId,
          stepId: currentState.current.stepData.stepId,
          value: questionDataValue,
        });
      }

      if (specificQuestionSaveValue) {
        dispatch("updateQuestionSpecificValue", {
          ...specificQuestionSaveValue,
          questionId: currentState.current.stepQuestion.questionId,
          stepId: currentState.current.stepData.stepId,
        });
      }

      if (
        moveToNextBtnClick ||
        !currentState.current.questionContainsKeyInputs
      ) {
        setShowErrors(true);

        dispatch("setState", { loadingStepsAndQuestionSaving: "pending" });

        setSavingStepAndQuestionData(
          moveToNextBtnClick ? "moveToNext" : "question",
        );

        setTimeout(() => {
          saveStepsAndQuestionsHelper({
            stepId: currentState.current.stepData.stepId,
            stepQuestion: currentState.current.stepQuestion,
            moveToNextBtnClick,
            questionContainsKeyInputs:
              currentState.current.questionContainsKeyInputs,
            returnAutoWorkFlow: currentState.current.returnAutoWorkFlow,
            dispatch,
          })
            .then((res) => {
              if (res.valid) {
                if (callbackRef?.current?.saveCallbackSuccess) {
                  callbackRef.current.saveCallbackSuccess();
                }
                setQuestionHaveUpdates(false);
              }
            })
            .catch((err) => {
              console.error(err);

              pushTheToast({
                type: "danger",
                text: "Unable to update. Please try again later...",
                position: "top-right",
              });

              if (callbackRef?.current?.saveCallbackError) {
                callbackRef.current.saveCallbackError();
              }
            })
            .finally(() => {
              setSavingStepAndQuestionData(null);

              dispatch("setState", {
                loadingStepsAndQuestionSaving: "fulfilled",
              });
            });
        }, 0);
      } else {
        setQuestionHaveUpdates(true);
        if (!currentState.current.questionContainsKeyInputs) {
          setShowErrors(true);
        }
      }
    },
    [dispatch],
  );

  return {
    returnAutoWorkFlow,
    dispatch,
    integrationId,
    allStepsConfigured,
    questionNumber,
    nextStepExists,
    stepData,
    savedQuestionIds,
    nextQuestionExists,
    stepQuestion,
    showExtraValue,
    questionIsValid,
    questionContainsKeyInputs,
    modalConfigDetails,
    savingStepAndQuestionData,
    showErrors,
    questionHaveUpdates,
    saveStepAndQuestionData,
  };
}

export default useSaveStepsAndQuestions;
