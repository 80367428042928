import Lottie from "lottie-light-react";
import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import { GroupBase, NoticeProps, StylesConfig } from "react-select";
import {
  addTicketApi,
  IAddTicketApi,
} from "src/apis/addTicket/insert/addTicket.api";
import LoadingAnimation from "src/assets/animation/Loading.json";
import alertIcon from "src/assets/images/alert.svg";
import emailIcon from "src/assets/images/emailIcon.png";
import sucessImg from "src/assets/images/successMsg.gif";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";
import Loader from "src/components/Loader";
import SendingInfo from "src/components/SendingInfo";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { FreePlanError, FreePlanErrorMessage } from "src/globals/constants";
import useIntegrationFailure from "src/hooks/useIntegrationFailure";
import { TinyMCEUploadFile } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import { v4 as uuid } from "uuid";
import styles from "./ExternalChat.module.scss";
import { getTicketsV2 } from "src/services/TicketService/getTicketsV2";
import { BotViewTicketData } from "../../InternalBotChat";
import { useSyncedState } from "src/hooks/useSyncedState";

type ModalType = "email" | "discard" | "success" | "sending";

interface Props {
  onHide: () => void;
  fromEmail: string;
  setTicketData: (ticketData: BotViewTicketData) => void;
  setShowChat: React.Dispatch<SetStateAction<boolean>>;
  linkedTicketId: string | number;
}

const CustomLoadingIndicator: React.ComponentType<
  NoticeProps<any, false, GroupBase<any>>
> = (props) => (
  <div {...props.innerProps}>
    <Loader className={`${styles.loader}`} />
  </div>
);

const ExternalChat = ({
  onHide,
  fromEmail,
  setShowChat,
  setTicketData,
  linkedTicketId,
}: Props) => {
  // const navigate = useNavigate();

  const [selectedValueto, setSelectedValueto] = useState<string>("");
  // const [selectedValuefrom, setSelectedValuefrom] = useState<string>(fromEmail);
  const [selectedValuecc, setSelectedValuecc] = useState<string>("");
  const [selectedValuebcc, setSelectedValuebcc] = useState<string>("");
  const [activeModal, setActiveModal] = useState<ModalType>("email");
  const [subjectValue, setSubjectValue] = useState("");
  // const [msgValue, setmsgValue] = useState("");
  const [msgValue, messageRef, setmsgValue] = useSyncedState("");

  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<TinyMCEUploadFile[]>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [customerName, getCustomerName] = useState("");
  const [showError, setShowErrors] = useState(false);
  // const [ticketId, setTicketId] = useState("");

  // INTEGRATION FAILURE STATES
  const { getIntegrationFailureData } = useIntegrationFailure();
  const [showCannotSendModal, setShowCannotSendModal] = useState(false);
  const [integrationFailureAPIData, setIntegrationFailureAPIData] =
    useState<IntegrationFailureMetaData | null>(null);
  /**
   * Integration failure data
   */
  const { integrationFailure, isIntegrationFailure } = useMemo(() => {
    return getIntegrationFailureData({
      fromEmail: fromEmail,
      integrationFailureAPIData: integrationFailureAPIData,
    });
  }, [getIntegrationFailureData, fromEmail, integrationFailureAPIData]);

  const validateFields = useCallback(() => {
    if (!selectedValueto) {
      return false;
    }

    // if (!selectedValuefrom) {
    //   return false;
    // }

    if (subjectValue.trim() === "") {
      return false;
    }

    if (msgValue.trim() === "") {
      return false;
    }

    return true;
  }, [msgValue, selectedValueto, subjectValue]);

  const handleSubmit = useCallback(async () => {
    const validated = validateFields();
    setShowErrors(validated);

    if (!validated) {
      // Handle invalid submission
      pushTheToast({
        text: "Please fill all required feilds!",
        type: "danger",
        position: "top-right",
      });
      return;
    }

    try {
      setActiveModal("sending"); // Show sending animation
      const ticketData: IAddTicketApi = {
        to: selectedValueto,
        cc: selectedValuecc,
        bcc: selectedValuebcc,
        ticket_description: msgValue,
        name: customerName,
        from: fromEmail,
        ticket_title: subjectValue,
        sendAs: "Open",
        isAddExternal: true,
        linkedTicketId,
      };

      // Include attachmentBatchNumber only if attachmentNumbers length > 0
      if (selectedFiles.length > 0) {
        ticketData.attachmentBatchNumber = selectedFiles.map(
          (file) => file.batchNumber + "",
        );
      }

      const res = await addTicketApi(ticketData);
      if (res.id) {
        //fetching New ticket data to show it.
        const payload = {
          start: 0,
          limit: 1,
          ticketIds: [res.id],
          filters: { tableType: "all" },
          columns: [
            "ticket_id",
            "ticket_subject",
            "last_message",
            "last_message_formatted",
            "last_message_date_gmt",
            "last_message_date",
            "is_read",
            "last_mention_message",
            "unread_mention_count",
            "ticket_brand",
            "ticket_channel",
            "ticket_customer_details",
          ],
        };
        const newTicketData = await getTicketsV2(payload, true, "ticket-view");
        if (newTicketData) {
          setTicketData(newTicketData.tickets[res.id] as BotViewTicketData);
        }
        setActiveModal("success");
        // setTicketId(res.id);
      } else {
        pushTheToast({
          type: "danger",
          text: "Error Creating Ticket!",
          position: "top-right",
        });
      }
    } catch (err: any) {
      setActiveModal("email");
      // Check if we get status code for free plan limit reached
      if (
        typeof err == "object" &&
        (err.statusCode === FreePlanError.LIMIT_REACHED ||
          err.statusCode === FreePlanError.RESTRICTED)
      ) {
        const statusCode = err.statusCode as keyof typeof FreePlanErrorMessage;
        pushTheToast({
          text: err.message ?? FreePlanErrorMessage[statusCode],
          type: "danger",
          position: "top-right",
        });
      } else if (
        typeof err == "object" &&
        err.statusCode === "integrationFailure"
      ) {
        setIntegrationFailureAPIData(err.failureMetaData);
      } else {
        pushTheToast({
          type: "danger",
          text: typeof err === "string" ? err : "Error Creating Ticket!",
          position: "top-right",
        });
      }
    }
  }, [
    validateFields,
    selectedValueto,
    selectedValuecc,
    selectedValuebcc,
    msgValue,
    customerName,
    fromEmail,
    subjectValue,
    selectedFiles,
    setTicketData,
    linkedTicketId,
  ]);

  const [shownFields, setShownFields] = useState<Array<"cc" | "bcc">>([]);

  return (
    <div>
      {activeModal === "email" && (
        <div className="d-block">
          <h3 className={`${styles.heading}`}>Add external chat</h3>
          <div>
            <div className="d-flex align-items-center mb-2">
              <span className={`${styles.selectLabel}`}>To:</span>
              <div
                className={`${styles.sendingInfoWrapper} d-flex align-items-center`}
              >
                <div
                  className={`${styles.emailIconContainer} d-flex justify-content-center align-items-center`}
                >
                  <img
                    src={emailIcon}
                    alt="email-icon"
                    width={17}
                    height={14}
                  />
                </div>
                <SendingInfo
                  name="to"
                  addUser={true}
                  currentValue={selectedValueto}
                  getValue={(val) => setSelectedValueto(val)}
                  getCustomerName={getCustomerName}
                  LabelComponent={(props) => (
                    <span className={styles.ddItem}>{props.email}</span>
                  )}
                  selectStylesObject={customStyles}
                  placeholder="Select the customer"
                  components={{
                    LoadingMessage: CustomLoadingIndicator,
                  }}
                  blueBtn={true}
                  componentTitle={"Adding user .."}
                  nameTitle={"Name"}
                  emailTitle={"E-mail"}
                  btnText={"Add User"}
                />
              </div>

              {shownFields.length === 0 && (
                <div className="ms-2 d-flex align-items-center">
                  <span
                    className={`pe-2 ${styles.cc}`}
                    onClick={() => setShownFields(["cc"])}
                  >
                    Cc
                  </span>
                  <span
                    className={`pe-2 ${styles.cc}`}
                    onClick={() => setShownFields(["bcc"])}
                  >
                    Bcc
                  </span>
                </div>
              )}
            </div>
            {/* <div className="d-flex align-items-center mb-2">
              <span className={`${styles.selectLabel}`}>From:</span>
              <div className={`${styles.sendingInfoWrapper}`}>
                <SendingInfo
                  name="from"
                  currentValue={selectedValuefrom}
                  isDisabled={true}
                  getValue={(val) => setSelectedValuefrom(val)}
                   LabelComponent={(props) => <span className={styles.ddItem}>{props.email}</span>}
                />
              </div>
            </div> */}

            {shownFields.map((val) => {
              if (val === "cc") {
                return (
                  <div className="d-flex align-items-center mb-2">
                    <span className={`${styles.selectLabel}`}>Cc:</span>
                    <div className={`${styles.sendingInfoWrapper}`}>
                      <SendingInfo
                        name="cc"
                        isMulti={true}
                        addUser={true}
                        currentValue={selectedValuecc}
                        getValue={(val) => setSelectedValuecc(val)}
                        LabelComponent={(props) => (
                          <span className={styles.ddItem}>{props.email}</span>
                        )}
                        selectStylesObject={customStyles}
                        placeholder="Select the customers...."
                        components={{
                          LoadingMessage: CustomLoadingIndicator,
                        }}
                        blueBtn={true}
                        componentTitle={"Adding user .."}
                        nameTitle={"Name"}
                        emailTitle={"E-mail"}
                        btnText={"Add User"}
                      />
                    </div>

                    {!shownFields.includes("bcc") && (
                      <div className="ms-2 d-flex align-items-center">
                        <span
                          className={`pe-2 ${styles.cc}`}
                          onClick={() =>
                            setShownFields((prev) => [...prev, "bcc"])
                          }
                        >
                          Bcc
                        </span>
                      </div>
                    )}
                  </div>
                );
              }

              return (
                <div className="d-flex align-items-center mb-2">
                  <span className={`${styles.selectLabel}`}>Bcc:</span>
                  <div className={`${styles.sendingInfoWrapper}`}>
                    <SendingInfo
                      name="bcc"
                      isMulti={true}
                      addUser={true}
                      currentValue={selectedValuebcc}
                      getValue={(val) => setSelectedValuebcc(val)}
                      LabelComponent={(props) => (
                        <span className={styles.ddItem}>{props.email}</span>
                      )}
                      selectStylesObject={customStyles}
                      placeholder="Select the customers...."
                      components={{
                        LoadingMessage: CustomLoadingIndicator,
                      }}
                      blueBtn={true}
                      componentTitle={"Adding user .."}
                      nameTitle={"Name"}
                      emailTitle={"E-mail"}
                      btnText={"Add User"}
                    />
                  </div>

                  {!shownFields.includes("cc") && (
                    <div className="ms-2 d-flex align-items-center">
                      <span
                        className={`pe-2 ${styles.cc}`}
                        onClick={() =>
                          setShownFields((prev) => [...prev, "cc"])
                        }
                      >
                        Cc
                      </span>
                    </div>
                  )}
                </div>
              );
            })}

            <div className="d-flex align-items-center mb-2">
              <span className={`me-2 ${styles.subjectLabel}`}>Subject :</span>
              <input
                type="text"
                className={`form-control ${styles.inputBox} ${
                  showError && subjectValue.trim() === ""
                    ? styles.errBorder
                    : null
                }`}
                id="subject"
                placeholder="Type the subject...."
                value={subjectValue}
                onChange={(e) => {
                  setSubjectValue(e.target.value);
                }}
              />
            </div>

            <div className={`${styles.editorBox} `}>
              <TinyMCEContainer
                value={messageRef.current}
                className={
                  showError && msgValue.trim() === "" ? styles.errBorder : ""
                }
                onChange={function (value: string): void {
                  setmsgValue(value);
                }}
                options={{
                  placeholder: "Type a quick message to customer.....",
                }}
                uniqueID={uuid()}
                readonly={false}
                disableAttachment={false}
                attachmentUploadData={attachmentUploadData}
                setAttachmentUploadData={setAttachmentUploadData}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                currentAttachmentBatchNumber={currentAttachmentBatchNumber}
                setCurrentAttachmentBatchNumber={
                  setCurrentAttachmentBatchNumber
                }
                isFileUploading={isFileUploading}
                setIsFileUploading={setIsFileUploading}
              />
            </div>
            <div
              className={`d-flex justify-content-between align-items-center mt-4`}
            >
              <button
                className={`${styles.cancelBtn}`}
                onClick={() => setActiveModal("discard")}
              >
                Cancel
              </button>
              {isIntegrationFailure && (
                <div
                  className={`d-flex align-items-center ms-lg-3 ${styles.integrationFail}`}
                >
                  <span className="me-1">
                    <img
                      src={alertIcon}
                      alt="alert"
                      width={12}
                      height={12}
                    />
                  </span>
                  <span>Cannot send</span>
                  <button onClick={() => setShowCannotSendModal(true)}>
                    See why?
                  </button>
                </div>
              )}
              <button
                className={`${styles.submitBtn}`}
                onClick={handleSubmit}
                disabled={isIntegrationFailure}
              >
                Send email
              </button>
            </div>
          </div>
        </div>
      )}
      {activeModal === "discard" && (
        <div className={`py-4  ${styles.successWrapper}`}>
          <span className={`text-center ${styles.exclamation}`}>
            <i className="fa-solid fa-triangle-exclamation"></i>
          </span>
          <h2 className={`text-center mb-4 mt-5 pb-2 ${styles.successHead}`}>
            Discard Changes?
          </h2>
          <p className={`text-center ${styles.successDesc}`}>
            Are you sure you want to cancel and discard all changes to this
            message?
          </p>
          <div
            className={`d-flex justify-content-center align-items-center mt-4`}
          >
            <button
              className={` me-4 ${styles.successCancelBtn}`}
              onClick={() => setActiveModal("email")}
            >
              No, go back
            </button>
            <button
              className={`ms-3 ${styles.successSubmitBtn}`}
              onClick={onHide}
            >
              Yes, discard
            </button>
          </div>
        </div>
      )}
      {activeModal === "sending" && (
        <div className={styles.sendingModal}>
          <div className={styles.sendingAnimationDiv}>
            <Lottie animationData={LoadingAnimation}></Lottie>
          </div>
        </div>
      )}
      {activeModal === "success" && (
        <div className={`py-4 ${styles.successWrapper}`}>
          <h3 className={`text-center ${styles.resHead}`}>
            Response sent successfully!
          </h3>
          <div className="d-flex justify-content-center">
            <img
              src={sucessImg}
              alt="sucessImg"
              className={`text-center mx-auto mb-4 ${styles.successGif}`}
            />
          </div>

          <p
            className={`text-center mb-4 ${styles.viewTicket}`}
            onClick={() => {
              setShowChat(true);
              onHide();
            }}
          >
            View ticket
          </p>
          <div className="d-flex justify-content-center">
            <button
              className={`${styles.closeBtn}`}
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Integration Failure Modal */}
      {isIntegrationFailure && integrationFailure && (
        <IntegrationFailureModal
          show={showCannotSendModal}
          onHide={() => setShowCannotSendModal(false)}
          data={integrationFailure}
        />
      )}
    </div>
  );
};

export default ExternalChat;

const customStyles: StylesConfig<any, boolean, GroupBase<any>> = {
  control: (provided: any, _: any) => ({
    ...provided,
    maxWidth: "100%",
    background: "#f5f5f5 0% 0% no-repeat padding-box",
    borderColor: "#9e9e9e",
    minHeight: "32px",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "5px",
    "&:active": {
      border: "1px solid red",
    },
  }),

  valueContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "32px",
    padding: "0 6px",
    fontSize: "12px",
  }),

  input: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided: any, _: any) => ({
    ...provided,
    minHeight: "24px",
    padding: "0px !important",
    marginRight: "6px",
  }),
  dropdownIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),
  menu: (provided: any, _: any) => ({
    ...provided,
    padding: "9px 8px",
    overflow: "hidden",
    maxHeight: "166px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 16px 48px #00000029",
    borderRadius: "10px",
    border: "1px solid #fff",
    marginLeft: "17px",
  }),
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    borderRadius: "5px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: "6px 12px",
    "&:hover": {
      background: "#0B68BB12 0% 0% no-repeat padding-box",
    },
  }),

  clearIndicator: (provided: any, _: any) => ({
    ...provided,
    padding: "0px !important",
  }),

  indicatorSeparator: (provided: any, _: any) => ({
    ...provided,
    display: "none",
  }),

  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    padding: "2px",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: " #402A2A",
    opacity: "1",
  }),

  multiValue: (provided: any, _: any) => ({
    ...provided,
    paddingRight: "2px",
    background: "white",
  }),

  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "normal normal 500 12px/18px Poppins",
    color: "#000000",
    opacity: "0.46",
  }),
};
