import { axiosJSON } from "src/globals/axiosEndPoints";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";

export interface GetSegmentUserByIdParams {
  customerSegmentId: string;
}

export interface IQualificationData {
  key: string;
  label: string;
  value: string | null;
  isEditable?: boolean | null;
}

export interface ISegmentUserData {
  id: string;
  customerId: string;

  // ----- Left Section -----

  /** Customer Metrics related data */
  metrics: {
    /** Total Request Count - Currently ticket count */
    supportRequestCount: number;

    /** Subsciption Preferences */
    subscriptionData?: {
      unsubscribedFromAllEmails: boolean;
      emailIssues?: string[] | null;
      smsIssues?: string[] | null;
    } | null;

    /** Total Revenue generated */
    totalRevenue?: {
      /** Amount generated */
      amount: number;
      /** Currency code */
      currencyCode: string;
    } | null;
  };

  // ----- Right Section -----

  /** Shows customer data */
  customerData: {
    email: string;
    name?: string | null;
    type: "user" | "lead";
    imageURL?: string | null;
    channels?: {
      otherEmails: Array<string>;
      phone: Phone[];
      whatsapp: Phone[];
      instagram: Array<string>;
      facebook: Array<string>;
      twitter: Array<string>;
      linkedIn: Array<string>;
    } | null;
    owner?: string | null;
    location?: {
      city?: string | null;
      region?: string | null;
      country?: string | null;
    } | null;
    company?: string | null;
    createdTimeGmt?: string | null;
  };

  /** More Data Section */
  segmentData?: Array<{
    /** Column Key */
    key: string;
    /** Column name */
    name: string;
    /** Value in column */
    value: string | null;
  }> | null;

  qualification?: Array<IQualificationData> | null;

  /** Hidden if null or not present */
  userSegments?: string[] | null;
  companyDetails?: string[] | null;
  recentPageViews?: string[] | null;
}

const getSegmentUserByIdService = async (params: GetSegmentUserByIdParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/getSegmentUserById`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as ISegmentUserData;
};

export default getSegmentUserByIdService;
