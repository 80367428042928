import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import TableHeader from "src/routes/CustomerSegments/children/SegmentView/Children/TableHeader/TableHeader";
import TableRow from "src/routes/CustomerSegments/children/SegmentView/Children/TableRow/TableRow";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./SelectSegmentTable.module.scss";
import useFetchSegmentValues from "./useFetchSegmentValues";

export const TOTAL_COUNT_PLACEHOLDER_TEXT = "{{TOTAL_USERS_COUNT}}";

const SelectSegmentTable = ({
  segmentIds,
  activeSegmentId,
  activeSegmentType,
  onHide,
  headerText = `Preview ${TOTAL_COUNT_PLACEHOLDER_TEXT} people who are in your audience right now`,
  headerPlaceholderText = `${TOTAL_COUNT_PLACEHOLDER_TEXT}`,
  columns = [],
  extraHorizontalSpacing = true,
  setIsRefetch,
  isRefetch,
}: {
  segmentIds: string[];
  activeSegmentType: string;
  activeSegmentId: string;
  onHide: () => void;
  /** Text to be used as header text for the preview */
  headerText?: string;
  /** Text to be replaced for total count in header text */
  headerPlaceholderText?: string;
  columns?: string[];
  extraHorizontalSpacing?: boolean;
  isRefetch?: boolean;
  setIsRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showTable, setShowTable] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    totalPages,
    setCurrentPage,
    currentPage,
    totalSegmentValues,
    allColumns,
    allColumnKeys,
    segmentValues,
    segmentValueIds,
    segmentsFetchStatus,
    refetch,
  } = useFetchSegmentValues(segmentIds, columns);

  useEffect(() => {
    if (isRefetch) {
      setCurrentPage(1);
      refetch();
      if (setIsRefetch) setIsRefetch(false);
    }
  }, [isRefetch]);

  const onClickHandler = useCallback(
    (id: string) => {
      navigate(
        customerSegmentsUtils.getNavigation(
          activeSegmentType,
          activeSegmentId,
          id,
        ),
      );
      onHide();
    },
    [activeSegmentId, activeSegmentType, navigate, onHide],
  );

  const header = useMemo(() => {
    if (headerPlaceholderText === TOTAL_COUNT_PLACEHOLDER_TEXT) {
      return headerText.replace(
        TOTAL_COUNT_PLACEHOLDER_TEXT,
        `${totalSegmentValues}`,
      );
    }

    return headerText;
  }, [headerPlaceholderText, headerText, totalSegmentValues]);

  return (
    <div
      className={`${styles.tableAccordion} ${
        extraHorizontalSpacing ? "mt-3" : "mt-1"
      }`}
    >
      <div
        className={`d-flex justify-content-between align-items-center px-2 py-2 cursor-pointer`}
        onClick={() => setShowTable(!showTable)}
      >
        <span
          className={`${extraHorizontalSpacing ? "mb-2" : ""} ${
            styles.tableAccordionHead
          }`}
        >
          {header}
        </span>
        <span
          className={`${
            showTable ? styles.activeToggleArrow : styles.toggleArrow
          }`}
        >
          <i className={`fa-solid fa-sort-${showTable ? "up" : "down"}`}></i>
        </span>
      </div>
      {showTable ? (
        <div>
          <div className="overflow-auto">
            <TableHeader
              allColumnKeys={allColumnKeys}
              allColumns={allColumns}
              showAttribute={false}
            />
            <div>
              {segmentsFetchStatus === "pending" ? (
                <div className="d-flex justify-content-center align-content-center align-items-center">
                  <Loader />
                </div>
              ) : segmentsFetchStatus === "rejected" &&
                segmentValueIds.length === 0 ? (
                <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
                  Error Loading Content...
                </div>
              ) : segmentValueIds.length === 0 ? (
                <div
                  className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
                >
                  <span> No Items Found</span>
                </div>
              ) : (
                segmentValueIds.map((id) => {
                  return (
                    <TableRow
                      key={id}
                      id={id}
                      allColumnKeys={allColumnKeys}
                      allColumns={allColumns}
                      segmentValues={segmentValues}
                      onClickHandler={onClickHandler}
                    />
                  );
                })
              )}
            </div>
          </div>
          <div className="my-2">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectSegmentTable;
