import OneTimeCampaignTable from "src/components/OneTimeCampaignTable/OneTimeCampaignTable";
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./OneTimeCampaign.module.scss";
import paperPlane from "src/assets/images/PaperPlaneLarge.png";
import Loader from "src/components/Loader";
import useOneTimeCampaign from "./useOneTimeCampaign";
import { IOneTimeCampaign } from "src/services/Campaign/getAllOneTimeCampaigns.service";

const CampaignTable = ({
  onAddCampaign,
  allCampaigns,
}: {
  onAddCampaign: () => void;
  allCampaigns: IOneTimeCampaign[];
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center w-100 mb-2">
        <SearchBar
          className={`mb-3 ${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder={`search by customer name , E-mail and any other keyword`}
        />
        <div>
          <button
            className={`mx-md-1 ${styles.msgBtn}`}
            id="newMsgBtn"
            onClick={onAddCampaign}
          >
            <span className="me-2">
              <i className="fa-solid fa-paper-plane"></i>
            </span>
            <span>Add one-time campaign</span>
          </button>
        </div>
      </div>
      <OneTimeCampaignTable allCampaigns={allCampaigns} />
    </div>
  );
};

const NoCampaign = ({ onConfigure }: { onConfigure: () => void }) => {
  return (
    <div className={`text-center mt-5 pt-4 ${styles.oneTimeCampaign} `}>
      <img
        src={paperPlane}
        alt="paper plane"
        width={56}
      />
      <p className={`mt-3 pt-1 ${styles.noTextPara}`}>
        No one-time campaign has been sent out till now. Please configure them
        here.
      </p>
      <button
        className={`mt-2 ${styles.configureBtn}`}
        onClick={onConfigure}
      >
        Configure one-time campaigns
      </button>
    </div>
  );
};

const OneTimeCampaign = ({
  showErrorModal,
}: {
  showErrorModal: () => void;
}) => {
  const { fetchStatus, allOneTimeCampaigns } = useOneTimeCampaign();

  return (
    <div>
      {fetchStatus === "pending" && allOneTimeCampaigns.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center w-100">
          <Loader />
        </div>
      ) : // NOTE - Enable this when API is added
      // fetchStatus === "rejected" ? (
      //   <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
      //     Error Loading Content...
      //   </div>
      // ) :
      allOneTimeCampaigns.length !== 0 ? (
        <CampaignTable
          onAddCampaign={showErrorModal}
          allCampaigns={allOneTimeCampaigns}
        />
      ) : (
        <NoCampaign onConfigure={showErrorModal} />
      )}
    </div>
  );
};

export default OneTimeCampaign;
