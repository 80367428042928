import { useCallback, useMemo, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { ReactComponent as EditIcon } from "src/assets/images/EditIcon.svg";
import Loader from "src/components/Loader";
import useFetchSequenceSteps from "src/routes/Campaigns/Children/hooks/useFetchSequenceSteps";
import useFetchTemplateVariant from "src/routes/Campaigns/Children/hooks/useFetchTemplateVariant";
import useUpdateTemplateVariant from "src/routes/Campaigns/Children/hooks/useUpdateTemplateVariant";
import { SequenceStep } from "src/services/Campaign/createCampaignV2.service";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import { GetSequenceStepResponse } from "src/services/Campaign/getSequenceSteps.service";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";
import EditDays from "../../../SequenceSummary/EditDays/EditDays";
import styles from "../../ChooseATemplate.module.scss";
import PreviewTemplate from "../PreviewTemplate/PreviewTemplate";

const TemplateDD = ({
  index,
  step,
  templateHtml,
}: {
  index: number;
  step: SequenceStep;
  templateHtml: string;
}) => {
  const [showDD, setShowDD] = useState(index === 0);
  const { dispatch } = useAddCampaignState();

  const handleTriggerChange = useCallback(
    (day: number) => {
      dispatch("updateTriggerAfter", { days: day, sectionIndex: index });
    },
    [dispatch, index],
  );

  return (
    <div className={styles.stepItem}>
      <div className={styles.stepHeader}>
        <span className={styles.innerText}>Step {index + 1}</span>
        <div className={`d-flex me-1 ${styles.dayWrap} ${styles.dayEdit}`}>
          <span className={`pe-1 ${styles.dayText}`}>Day</span>
          <EditDays
            day={step.triggerAfter}
            onChange={handleTriggerChange}
          />
        </div>
        <FaChevronDown
          className={`${styles.icon} ${showDD ? styles.iconExpanded : ""}`}
          onClick={() => setShowDD((prev) => !prev)}
        />
      </div>
      {showDD && (
        <div className={styles.stepContent}>
          <PreviewTemplate html={templateHtml} />
        </div>
      )}
    </div>
  );
};

const TemplatePreviewSection = () => {
  const { state, dispatch } = useAddCampaignState();
  const [isEditing, setIsEditing] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [variantNameError, setShowVariantNameError] = useState(false);
  const updateMutation = useUpdateTemplateVariant(state.allTemplatesQueryKey);

  const payload = useMemo(() => {
    if (!state.templateCategoryId || !state.variantId || !state.templateId) {
      return;
    }

    return {
      categoryId: state.templateCategoryId,
      templateId: state.templateId,
      variantId: state.variantId,
    };
  }, [state.templateCategoryId, state.templateId, state.variantId]);

  const onTemplateSuccess = useCallback(
    (data: IEmailTemplateVariant) => {
      dispatch("updateState", { activeTemplate: data });
    },
    [dispatch],
  );

  const onSequenceSuccess = useCallback(
    (data: GetSequenceStepResponse) => {
      dispatch("updateState", { sequence: data.data });
    },
    [dispatch],
  );

  const { template, templateFetchStatus } = useFetchTemplateVariant(
    payload,
    onTemplateSuccess,
  );

  const { sequence, sequenceFetchStatus } = useFetchSequenceSteps(
    payload,
    onSequenceSuccess,
  );

  const updateVariantName = useCallback(() => {
    if (variantName.trim() === "") {
      setShowVariantNameError(true);
      return;
    }

    dispatch("updateActiveTemplate", { variantName });

    if (payload) {
      updateMutation.mutate({ ...payload, variantName });
    }
  }, [dispatch, payload, updateMutation, variantName]);

  if (templateFetchStatus === "pending" || sequenceFetchStatus === "pending") {
    return (
      <div
        className={`d-flex align-items-center justify-content-center w-100 ${styles.daySequence}`}
      >
        <Loader />
      </div>
    );
  }

  if (
    templateFetchStatus === "rejected" ||
    sequenceFetchStatus === "rejected"
  ) {
    return (
      <div
        className={`${styles.daySequence} ${styles.noFound} d-flex justify-content-center align-content-center align-items-center text-danger`}
      >
        Error Loading Content...
      </div>
    );
  }

  if (!template || sequence.length === 0 || !payload) {
    return (
      <div
        className={`${styles.daySequence} ${styles.noFound} d-flex justify-content-center align-content-center align-items-center`}
      >
        {!payload
          ? "Please select a template variant!"
          : sequence.length === 0
            ? "No sequence steps available for this template!"
            : "No Template Found!"}
      </div>
    );
  }

  return (
    <div className={styles.daySequence}>
      <h3>
        {isEditing ? (
          <div>
            <div className={`position-relative`}>
              <input
                type="text"
                value={variantName}
                onChange={(e) => setVariantName(e.target.value)}
                onBlur={() => {
                  if (variantName?.trim().length !== 0) {
                    setIsEditing(false);
                    updateVariantName();
                  }
                }}
                className={`form-control ps-2 py-1 ${styles.editInput} ${
                  variantNameError &&
                  variantName?.trim().length === 0 &&
                  "border border-danger"
                }`}
                autoFocus
              />
              <div
                className={`${styles.checkBox} cursor-pointer ${
                  variantNameError &&
                  variantName?.trim().length === 0 &&
                  styles.newCheckBox
                }`}
                onClick={updateVariantName}
              >
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
            </div>
            {variantNameError && variantName?.trim().length === 0 && (
              <div className={`${styles.noFound} text-danger mt-1`}>
                Variant name is required.
              </div>
            )}
          </div>
        ) : (
          <div>
            <span>{state.activeTemplate?.variantName ?? "NA"}</span>
            <EditIcon
              className={`${styles.editIcon} ms-1 cursor-pointer`}
              onClick={() => setIsEditing(true)}
            />
          </div>
        )}
      </h3>
      <div className={styles.contain}>
        <h2 className={styles.daySequenceLabel}>Day sequence</h2>
        <div className={styles.sequenceList}>
          {state.sequence.map((step, index) => (
            <TemplateDD
              index={index}
              key={index}
              step={step}
              templateHtml={state.activeTemplate?.html ?? ""}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplatePreviewSection;
