import ParseHTML from "src/components/ParseHTML";

const string = `<body bgcolor="#473A8F" id="body" style="-moz-box-sizing:border-box;-moz-hyphens:none;-ms-hyphens:none;-ms-text-size-adjust:100%;-webkit-box-sizing:border-box;-webkit-hyphens:none;-webkit-text-size-adjust:100%;Margin:0;background:#473A8F!important;background-color:#473A8F!important;box-sizing:border-box;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;min-width:100%;padding:0;text-align:left;width:100%!important">
<style type="text/css">@media only screen and (max-width:615px){.footer-contact .social .social-holder{text-align:center!important;margin-right:0!important}}@media only screen{html{min-height:100%;background:0 0}}@media only screen and (max-width:615px){.small-float-center{margin:0 auto!important;float:none!important;text-align:center!important}.small-text-center{text-align:center!important}.small-text-left{text-align:left!important}.small-text-right{text-align:right!important}}@media only screen and (max-width:615px){.hide-for-large{display:block!important;width:auto!important;overflow:visible!important;max-height:none!important;font-size:inherit!important;line-height:inherit!important}}@media only screen and (max-width:615px){table.body table.container .hide-for-large,table.body table.container .row.hide-for-large{display:table!important;width:100%!important}}@media only screen and
(max-width:615px){table.body table.container .callout-inner.hide-for-large{display:table-cell!important;width:100%!important}}@media only screen and (max-width:615px){table.body table.container .show-for-large{display:none!important;width:0;mso-hide:all;overflow:hidden}}@media only screen and (max-width:615px){table.body img{width:auto;height:auto}table.body center{min-width:0!important}table.body .container{width:95%!important}table.body .column,table.body .columns{height:auto!important;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;padding-left:35px!important;padding-right:35px!important}table.body .column .column,table.body .column .columns,table.body .columns .column,table.body .columns .columns{padding-left:0!important;padding-right:0!important}table.body .collapse .column,table.body .collapse
.columns{padding-left:0!important;padding-right:0!important}td.small-1,th.small-1{display:inline-block!important;width:8.33333%!important}td.small-2,th.small-2{display:inline-block!important;width:16.66667%!important}td.small-3,th.small-3{display:inline-block!important;width:25%!important}td.small-4,th.small-4{display:inline-block!important;width:33.33333%!important}td.small-5,th.small-5{display:inline-block!important;width:41.66667%!important}td.small-6,th.small-6{display:inline-block!important;width:50%!important}td.small-7,th.small-7{display:inline-block!important;width:58.33333%!important}td.small-8,th.small-8{display:inline-block!important;width:66.66667%!important}td.small-9,th.small-9{display:inline-block!important;width:75%!important}td.small-10,th.small-10{display:inline-block!important;width:83.33333%!important}td.small-11,th.small-11{display:inline-block!important;width:91.66667%!important}td.small-12,th.small-12{display:inline-block!important;width:100%!
important}.column
td.small-12,.column th.small-12,.columns td.small-12,.columns th.small-12{display:block!important;width:100%!important}table.body td.small-offset-1,table.body th.small-offset-1{margin-left:8.33333%!important;Margin-left:8.33333%!important}table.body td.small-offset-2,table.body th.small-offset-2{margin-left:16.66667%!important;Margin-left:16.66667%!important}table.body td.small-offset-3,table.body th.small-offset-3{margin-left:25%!important;Margin-left:25%!important}table.body td.small-offset-4,table.body th.small-offset-4{margin-left:33.33333%!important;Margin-left:33.33333%!important}table.body td.small-offset-5,table.body th.small-offset-5{margin-left:41.66667%!important;Margin-left:41.66667%!important}table.body td.small-offset-6,table.body th.small-offset-6{margin-left:50%!important;Margin-left:50%!important}table.body td.small-offset-7,table.body
th.small-offset-7{margin-left:58.33333%!important;Margin-left:58.33333%!important}table.body td.small-offset-8,table.body th.small-offset-8{margin-left:66.66667%!important;Margin-left:66.66667%!important}table.body td.small-offset-9,table.body th.small-offset-9{margin-left:75%!important;Margin-left:75%!important}table.body td.small-offset-10,table.body th.small-offset-10{margin-left:83.33333%!important;Margin-left:83.33333%!important}table.body td.small-offset-11,table.body th.small-offset-11{margin-left:91.66667%!important;Margin-left:91.66667%!important}table.body table.columns td.expander,table.body table.columns th.expander{display:none!important}table.body .right-text-pad,table.body .text-pad-right{padding-left:10px!important}table.body .left-text-pad,table.body .text-pad-left{padding-right:10px!important}table.menu{width:100%!important}table.menu td,table.menu
th{width:auto!important;display:inline-block!important}table.menu.small-vertical td,table.menu.small-vertical th,table.menu.vertical td,table.menu.vertical th{display:block!important}table.menu[align=center]{width:auto!important}table.button.small-expand,table.button.small-expanded{width:100%!important}table.button.small-expand table,table.button.small-expanded table{width:100%}table.button.small-expand table a,table.button.small-expanded table a{text-align:center!important;width:100%!important;padding-left:0!important;padding-right:0!important}table.button.small-expand center,table.button.small-expanded center{min-width:0}}
</style>
<span class="preheader" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;color:transparent;display:none!important;font-size:1px;hyphens:none;line-height:1px;max-height:0;max-width:0;mso-hide:all!important;opacity:0;overflow:hidden;visibility:hidden">Take these simple steps to help you and your team get the most out of the Heroku platform as you build apps for your business.</span>
<table bgcolor="#473A8F" class="body" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;background:#473A8F!important;background-color:#473A8F!important;border-collapse:collapse;border-spacing:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;height:100%;hyphens:none;line-height:1.5;margin:0;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
	<tbody>
		<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
			<td align="center" class="center" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" valign="top">
			<center data-parsed="" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;max-width:92%; margin: 0 auto;"><!-- preheader -->
			<table align="center" class="container float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background:0 0;border-collapse:collapse;border-radius:6px;border-spacing:0;float:none;hyphens:none;margin:0 auto;padding:0;table-layout:fixed!important;text-align:center;vertical-align:top;width:100%;">
				<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
					<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
						<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word">
						<table class="row preheader-link collapse" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;background:#473A8F!important;background-color:#473A8F!important;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:0!important;padding-left:0;padding-right:0;text-align:left;width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- main content -->

			<table align="center" class="container header float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background:0 0;border-collapse:collapse;border-radius:6px 6px 0 0;border-spacing:0;float:none;hyphens:none;margin:0 auto;padding:0;table-layout:fixed!important;text-align:center;vertical-align:top;width:100%;">
				<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
					<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
						<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word">
						<table class="row collapse content-area-header-img content-top" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;background-color:#FBFAFF;border-collapse:collapse;border-radius:6px 6px 0 0!important;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;border-radius:6px 6px 0 0!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:0;padding-right:0;text-align:left;width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<center data-parsed="" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;max-width:92%;width:100%"><img align="center" alt="Heroku" class="float-center" src="https://go.pardot.com/l/36622/2016-10-31/b345m7/36622/117902/header_img_2x.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;Margin:0 auto;border:0;clear:both;display:block;float:none;height:auto!important;hyphens:none;margin:0 auto;max-width:100%;outline:0;text-align:center;text-decoration:none;max-width:100%!important"></center>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						</td>
					</tr>
				</tbody>
			</table>

			<table align="center" bgcolor="#ffffff" class="container onboarding float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background:0 0;border-collapse:collapse;border-radius:6px;border-spacing:0;float:none;hyphens:none;margin:0 auto;padding:0;table-layout:fixed!important;text-align:center;vertical-align:top;max-width:100%;">
				<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
					<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
						<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word"><!-- Start Header Content -->
						<table class="row header-content" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;background-color:#FBFAFF;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:35px;padding-right:35px;text-align:left;max-width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<p style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:left"><strong style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">Hello Jake,</strong></p>

												<p class="pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:0!important;text-align:left">Welcome to Heroku! Now you and your team can <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">build and run apps</a> on one integrated platform that combines compute, data, and workflow with a high-productivity developer experience.</p>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Header Content --><!-- Start Content Row -->

						<table class="row content border-top" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;border-top:1px solid #DBD9E5!important;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:35px;padding-right:35px;text-align:left;max-width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="35px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:400;hyphens:auto;line-height:35px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<h1 style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:26px;font-weight:400;hyphens:none!important;line-height:140%;margin:0!important;margin-bottom:0;padding:0 0 10px 0!important;text-align:center;word-wrap:inherit!important">Stay productive on Heroku</h1>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="10px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:10px;font-weight:400;hyphens:auto;line-height:10px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:0!important;text-align:left">Get started by <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">deploying an app</a> to Heroku and taking these simple steps to help you and your team rapidly deliver apps for your business.</p>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start Content Row -->

						<table class="row content icon-list" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-2 columns first" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:35px;padding-right:0;text-align:left;width:61.67px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><img alt="flow" src="https://hello.heroku.com/l/36622/2019-06-13/ljqdj1/36622/166554/business_flow.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:block;height:auto!important;hyphens:none;margin:0 auto;max-width:100%;outline:0;text-decoration:none;width:50px!important" width="50"></th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="small-12 large-10 columns last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:17.5px;padding-right:35px;text-align:left;width:720px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<h2 class="small-text-center pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:20px;font-weight:400;hyphens:none!important;line-height:120%;margin:0!important;margin-bottom:0;padding:0 0 10px 0!important;padding-bottom:0!important;text-align:left;word-wrap:inherit!important">Set up your CI/CD workflow</h2>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="5px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:5px;font-weight:400;hyphens:auto;line-height:5px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="small-text-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:left">Deploy apps using Heroku's easy-to-use, <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">structured workflow for continuous delivery</a>, including Heroku Pipelines, Heroku CI,* review apps, and GitHub
integration.</p>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="15px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:15px;font-weight:400;hyphens:auto;line-height:15px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start Content Row -->

						<table class="row content icon-list" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-2 columns first" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:35px;padding-right:0;text-align:left;width:61.67px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><img alt="2" src="https://hello.heroku.com/l/36622/2019-06-13/ljqdj7/36622/166558/business_team.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:block;height:auto!important;hyphens:none;margin:0 auto;max-width:100%;outline:0;text-decoration:none;width:50px!important" width="50"></th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="small-12 large-10 columns last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:17.5px;padding-right:35px;text-align:left;width:720px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<h2 class="small-text-center pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:20px;font-weight:400;hyphens:none!important;line-height:120%;margin:0!important;margin-bottom:0;padding:0 0 10px 0!important;padding-bottom:0!important;text-align:left;word-wrap:inherit!important">Create or join a Heroku Team</h2>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="5px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:5px;font-weight:400;hyphens:auto;line-height:5px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="small-text-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:left">Unify collaboration, administration, and billing with fine-grained control for all your apps with <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">Heroku Teams</a>.*</p>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="15px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:15px;font-weight:400;hyphens:auto;line-height:15px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start Content Row -->

						<table class="row content icon-list" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-2 columns first" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:35px;padding-right:0;text-align:left;width:61.67px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><img alt="3" src="https://hello.heroku.com/l/36622/2019-06-13/ljqdj3/36622/166552/business_data.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:block;height:auto!important;hyphens:none;margin:0 auto;max-width:100%;outline:0;text-decoration:none;width:50px!important" width="50"></th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="small-12 large-10 columns last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:10px;padding-left:17.5px;padding-right:35px;text-align:left;width:720px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<h2 class="small-text-center pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:20px;font-weight:400;hyphens:none!important;line-height:120%;margin:0!important;margin-bottom:0;padding:0 0 10px 0!important;padding-bottom:0!important;text-align:left;word-wrap:inherit!important">Integrate data</h2>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="5px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:5px;font-weight:400;hyphens:auto;line-height:5px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="small-text-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:left">Build data-driven apps more easily with <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">managed services from Heroku</a> and other ecosystem partners that are based on popular open source technologies, including
PostgreSQL, Apache Kafka, Redis, and more.</p>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="15px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:15px;font-weight:400;hyphens:auto;line-height:15px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start Content Row -->

						<table class="row content border-top" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;border-top:1px solid #DBD9E5!important;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:15px;padding-left:35px;padding-right:35px;text-align:left;max-width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="35px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:400;hyphens:auto;line-height:35px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<h1 style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:26px;font-weight:400;hyphens:none!important;line-height:140%;margin:0!important;margin-bottom:0;padding:0 0 10px 0!important;text-align:center;word-wrap:inherit!important">Find more tips and inspiration</h1>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start Content Row -->

						<table class="row content less-internal-padding" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-6 columns first" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:35px;padding-right:17.5px;text-align:left;max-width:420px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:none"><img alt="Customers" src="https://hello.heroku.com/l/36622/2020-03-27/lt9lmq/36622/168837/business_customers_inverted.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:block;height:auto!important;hyphens:none;margin:0
auto;max-width:100%;outline:0;text-decoration:none;width:240px!important" width="240"></a>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="20px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:20px;font-weight:400;hyphens:auto;line-height:20px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="small-text-center pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:0;padding:0;padding-bottom:0!important;text-align:left">Learn how Heroku helped <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">Goodshuffle</a> and <a
href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">other companies</a> quickly launch and scale their&nbsp;business.</p>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="small-12 large-6 columns last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:17.5px;padding-right:35px;text-align:left;max-width:420px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:none"><img alt="Video player with the text Heroku Deployment Methods (1 of 3): Git, GitHub, and Heroku Buttons" src="https://hello.heroku.com/l/36622/2020-03-27/lt9lms/36622/168839/business_videos_inverted.png"
style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:block;height:auto!important;hyphens:none;margin:0 auto;max-width:100%;outline:0;text-decoration:none;width:240px!important" width="240"></a>
												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="20px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:20px;font-weight:400;hyphens:auto;line-height:20px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<p class="small-text-center pb0" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:0;padding:0;padding-bottom:0!important;text-align:left">Watch this <a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">Heroku Showcase tutorial</a> to learn how to deploy your apps to Heroku, or <a
href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#408FEC;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:none">view&nbsp;all</a> Heroku videos.</p>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Content Row --><!-- Start CTA Row -->

						<table class="row content cta-text" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background-color:#fefefe;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:35px;padding-right:35px;text-align:left;max-width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<center data-parsed="" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;width:100%">
												<table class="button expanded radius blue float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 0 35px 0;border-collapse:collapse;border-spacing:0;float:none;hyphens:none;margin:0 0 35px 0;margin-bottom:0!important;padding:0;table-layout:fixed!important;text-align:center;vertical-align:top;width:100%!important">
													<tbody>
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word">
															<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
																<tbody>
																	<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
																		<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;background:#408FEC;background-color:#408FEC;border:none;border-collapse:collapse!important;border-radius:6px;color:#fff;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">
																		<center data-parsed="" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;min-width:0;width:100%"><a align="center" class="float-center" href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;background-color:#408FEC;border:1px solid #408FEC;border-radius:6px;color:#fff;display:inline-block;font-family:Helvetica,Arial,sans-serif;font-size:16px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:8px 16px 8px 16px;padding-left:0;padding-right:0;text-align:center;text-decoration:none;width:100%">Get Started on Heroku</a></center>
																		</td>
																	</tr>
																</tbody>
															</table>
															</td>
															<td class="expander" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0!important;text-align:left;vertical-align:top;visibility:hidden;width:0;word-wrap:break-word">&nbsp;</td>
														</tr>
													</tbody>
												</table>
												</center>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End CTA Row --><!-- Start Endcap -->

						<table class="row endcap" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;background-color:#fefefe;border-collapse:collapse;border-radius:0 0 6px 6px;border-spacing:0;border-top:1px solid #DBD9E5;display:table;hyphens:none;margin-top:-2px;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-12 columns first last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:35px;padding-right:35px;text-align:left;max-width:100%">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<p style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:700;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;padding-top:35px;text-align:left"><span style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;font-weight:400;hyphens:none">*Heroku allows you to get up and running quickly without additional cost. The platform grows with you—add paid services as you need them to scale or optimize your apps and teams, including Heroku CI, Heroku Teams (5+ users), and more.</span></p>

												<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
													<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
														<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
															<td height="10px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:10px;font-weight:400;hyphens:auto;line-height:10px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
														</tr>
													</tbody>
												</table>

												<h3 style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;Margin-bottom:10px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:23px;font-weight:400;hyphens:none!important;line-height:1.5;margin:0!important;margin-bottom:10px;padding:0 0 10px 0!important;padding-bottom:5px;text-align:left;word-wrap:inherit!important">Happy coding,</h3>

												<p style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#79589F;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:700;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:left">The Heroku Team</p>
												</th>
												<th class="expander" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0!important;text-align:left;visibility:hidden;width:0">&nbsp;</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						<!-- End Endcap --></td>
					</tr>
				</tbody>
			</table>

			<table align="center" class="container footer-purple footer-border float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;background:0 0;background-color:#473A8F!important;border-collapse:collapse;border-radius:6px;border-spacing:0;float:none;hyphens:none;margin:0 auto;padding:0;table-layout:fixed!important;text-align:center;vertical-align:top;max-width:100%;">
				<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
					<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
						<td style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:auto;line-height:1.5;margin:0;padding:0;text-align:left;vertical-align:top;word-wrap:break-word">
						<table class="spacer" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<td height="60px" style="-moz-hyphens:auto;-ms-hyphens:none;-webkit-hyphens:auto;Margin:0;border-collapse:collapse!important;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:60px;font-weight:400;hyphens:auto;line-height:60px;margin:0;mso-line-height-rule:exactly;padding:0;text-align:left;vertical-align:top;word-wrap:break-word" class="">&nbsp;</td>
								</tr>
							</tbody>
						</table>

						<table class="row footer-contact collapse" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;display:table;hyphens:none;padding:0;position:relative;table-layout:fixed!important;text-align:left;vertical-align:top;width:inherit!important">
							<tbody style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
								<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
									<th class="small-12 large-3 columns first" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:0;padding-right:0;text-align:left;width:162.5px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class=""><a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Salesforce + Heroku" height="46" src="https://hello.heroku.com/l/36622/2019-04-01/ld6zgb/36622/164963/salesforce_heroku_white.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:none;clear:both;display:block;height:46px;hyphens:none;margin:0
auto;max-width:100%;outline:0;text-decoration:none;width:auto!important"></a></th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="small-12 large-6 columns" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:0;padding-right:0;text-align:left;width:290px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<center data-parsed="" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;min-width:185px;width:100%">
												<p align="center" class="float-center" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0!important;Margin-bottom:30px;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-size:80%;font-weight:400;hyphens:none;line-height:1.5;margin:0!important;margin-bottom:30px;padding:0;padding-bottom:15px!important;text-align:center">Salesforce<br style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
												415 Mission Street, 3rd Floor<br style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
												San Francisco, CA 94105<br style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
												<a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:underline!important">Update your email preferences</a><br style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none">
												<a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;outline:0!important;padding:0;text-align:left;text-decoration:underline!important">Privacy</a></p>
												</center>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
									<th class="social small-12 large-3 columns last" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0 auto;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0 auto;padding:0;padding-bottom:35px;padding-left:0;padding-right:0;text-align:left;width:162.5px">
									<table style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;border-collapse:collapse;border-spacing:0;hyphens:none;padding:0;table-layout:fixed!important;text-align:left;vertical-align:top;width:100%">
										<tbody>
											<tr style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;padding:0;text-align:left;vertical-align:top">
												<th style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#3F3F44;font-family:Helvetica,Arial,sans-serif;font-size:14px;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left" class="">
												<div class="social-holder" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;hyphens:none;margin-right:20px; max-width: 100px; margin-left-40px"><a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku Blog" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vn2/36622/164933/footer_icon_rss_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Code[ish] Podcasts" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vmz/36622/164929/footer_icon_podcast_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku Twitter" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vn4/36622/164937/footer_icon_twitter_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku on Instagram" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vmq/36622/164923/footer_icon_instagram_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>
												<a href="#" style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku on YouTube" height="16" src="https://hello.heroku.com/l/36622/2020-03-11/lt599w/36622/168834/footer_icon_yt_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku on Facebook" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vmd/36622/164913/footer_icon_facebook_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku on GitHub" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vmg/36622/164917/footer_icon_github_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a>&nbsp; <a href="#"
style="-moz-hyphens:none;-ms-hyphens:none;-webkit-hyphens:none;Margin:0;color:#8a7ecb;font-family:Helvetica,Arial,sans-serif;font-weight:400;hyphens:none;line-height:1.5;margin:0;padding:0;text-align:left;text-decoration:underline!important"><img alt="Heroku on LinkedIn" height="16" src="https://hello.heroku.com/l/36622/2019-03-29/ld3vmn/36622/164925/footer_icon_linkedin_purple.png" style="-moz-hyphens:none;-ms-hyphens:none;-ms-interpolation-mode:bicubic;-webkit-hyphens:none;border:0;clear:both;display:inline;height:16px!important;hyphens:none;max-width:100%;outline:0;text-align:center;text-decoration:none;width:auto!important"></a></div>
												</th>
											</tr>
										</tbody>
									</table>
									</th>
								</tr>
							</tbody>
						</table>
						</td>
					</tr>
				</tbody>
			</table>
			</center>
			</td>
		</tr>
	</tbody>
</table>
<!-- prevent Gmail on iOS font size manipulation -->
</p>`;

const Heroku = () => {
  return <ParseHTML html={string} />;
};

export default Heroku;
