/**
 * This file defines a React component responsible for rendering form elements,
 * handling CRUD operations form elements, implementing drag-and-drop functionality,
 * and managing the process of saving and discarding changes in the campaign email.
 *
 */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import EditEmail, {
  IVariableB,
} from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import EmailPreview from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EmailPreview/EmailPreview";
import { EmailTemplateData } from "src/services/Campaign/getCampaignById";
import Upload from "../Upload/Upload";
import styles from "./ConfigureEmailTemplate.module.scss";
import useConfigureEmailTemplate from "./useConfigureEmailModal";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import InstructionsModal from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/InstructionsModal/InstructionsModal";
import { convertHtmlToJson } from "src/utils/unlayerEditorUtils";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import { MergeTags } from "state/types/types";
import { reCreateTemplateText } from "src/services/Campaign/reCreateTemplateText";
import { v4 as uuidv4 } from "uuid";
import Loader from "src/components/Loader";
import TemplateModal from "src/routes/CustomerSegments/children/SegmentView/Children/SendMessageModal/Children/MessageTabs/children/TemplateModal/TemplateModal";
import templateIcon from "src/assets/images/tempNote.png";
import ErrorModalNew from "src/routes/CustomerSegments/children/SegmentView/Children/SendMessageModal/Children/MessageTabs/children/ErrorModal/ErrorModal";
import editIcon from "src/assets/images/editIcon.png";
import {
  CampaignActionType,
  CampaignContext,
} from "../../../CampainContext/CampainContext";
import { useUpdateCampaignEmail } from "../../../hooks/useCampaignData";

interface Props {
  onHide: (e: any) => void;
  referenceId: number;
  data?: EmailTemplateData & { triggerAfter?: number };
  handleEmailModalDataChange: (payload: { [key: string]: any }) => void;
  variablesList?: MergeTags;
  variableIdsList?: string[];
  companyName: string;
  onBusinessClick: () => void;
  status: "loading" | "completed";
  saveLoader: "idle" | "error" | "loading" | "success";
  emailSubject?: string;
  isLoading: boolean;
}

const ConfigureEmailTemplate = ({
  onHide,
  referenceId,
  data,
  handleEmailModalDataChange,
  variablesList,
  variableIdsList,
  companyName,
  onBusinessClick,
  saveLoader,
  status,
  emailSubject = "",
  isLoading,
}: Props) => {
  const [preview, setPreview] = useState<"Desktop" | "Mobile">("Desktop");
  const emailEditorRef = useRef<EditorRef>(null);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [state, dispatch] = useContext(CampaignContext);
  const [editableDay, setEditableDay] = useState(data?.triggerAfter ?? 0);
  const [isFocused, setIsFocused] = useState(false);
  const currentState = useRef({ editorLoaded: false, designLoaded: false });
  const updateCampaignEmail = useUpdateCampaignEmail();

  const [subject, setSubject] = useState(emailSubject);

  const exportHtmlAndSave = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      handleEmailModalDataChange({
        design,
        html,
        heading: subject,
      });
    });
  };

  const reCreateCallbackRef = useRef<(text: string) => void>();
  const onDesignLoad = (data: any) => {};

  useEffect(() => {
    if (
      data?.design &&
      emailEditorRef.current &&
      currentState.current.editorLoaded &&
      !currentState.current.designLoaded
    ) {
      emailEditorRef.current.editor?.loadDesign(data.design);
    }
  }, [data?.design]);

  const onLoad: EmailEditorProps["onLoad"] = (unlayer) => {
    if (emailEditorRef.current?.editor?.frame?.iframe?.style) {
      emailEditorRef.current.editor.frame.iframe.style.minWidth = "1024px";
    }
    currentState.current.editorLoaded = true;

    if (data?.design) {
      unlayer.loadDesign(data.design);
      currentState.current.designLoaded = true;
      return;
    }
    if (!data?.html) {
      return;
    }

    let html = convertHtmlToJson(data?.html);
    unlayer.loadDesign(html);
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    setEditorLoading(false);
    if (unlayer) {
      // Add the event listener for design changes
      // unlayer.addEventListener("design:updated", exportHtmlAndSave);

      unlayer.registerCallback(
        "image",
        function (file: { attachments: Array<File> }, done: Function) {
          const abortController = new AbortController();
          uploadAttachment(
            {
              files: [file.attachments[0]],
              abortControllerSignal: abortController.signal,
            },
            (progressEvent: ProgressEvent) => {
              let progress = Math.round(
                (100 * progressEvent.loaded) / progressEvent.total,
              );
              // Handle progress if needed
              // done({ progress: progress });
            },
            true, //public
          )
            .then((uploadRes) => {
              if (uploadRes.attachments[0].status === true) {
                const fileObject = {
                  batchNumber: uploadRes.attachments[0].batchNumber,
                  attachmentId: uploadRes.attachments[0].attachmentId,
                  attachmentName: uploadRes.attachments[0].attachmentName,
                  isPublic: true,
                  attachmentURL: "",
                };

                if (uploadRes.attachments[0]?.publicURL) {
                  fileObject.isPublic = true;
                  fileObject.attachmentURL = uploadRes.attachments[0].publicURL;
                }

                done({
                  progress: 100,
                  url: fileObject.attachmentURL || "",
                });
              } else {
                // onFailed(fileObject.id);
                throw new Error("Failed to upload image");
              }
            })
            .catch((uploadErr) => {
              if (uploadErr?.message !== "canceled") {
                // onFailed(fileObject.id);
                throw new Error("Failed to upload image");
              }
            });

          // Handle file upload here
        },
      );
    }
  };
  console.log(data, isLoading);

  // useEffect(() => {
  //   emailEditorRef.current.editor.addEventListener("editor:ready", () => {
  //     // Callback to add custom field to a built-in tool

  // }, []);

  const [templateModal, setTemplateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  // Event to handle input blur (when the input loses focus)
  const handleBlur = () => {
    setIsEditing(false); // Exit editing mode
    // Optionally, you can trigger any save or update action here
  };

  // Event to toggle editing mode
  const handleEditClick = () => {
    setIsEditing(true); // Enter editing mode
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Use a regular expression to allow only numbers
    const numericValue = value.replace(/[^0-9]/g, "");
    let stateValue = 0;
    if (numericValue === "NaN" || !numericValue) {
      stateValue = 0;
    } else {
      stateValue = parseInt(numericValue);
      // setEditableDay(parseInt(numericValue));
    }

    if (stateValue > 1000) {
      stateValue = 1000;
    }

    setEditableDay(stateValue);

    if (state.activeCampaignEmailId && state.activeCampaignId) {
      updateCampaignEmail.mutate({
        campaignId: parseInt(state.activeCampaignId + ""),
        id: parseInt(state.activeCampaignEmailId + ""),
        triggerAfter: stateValue,
      });
    }
  };

  if (!isLoading && !data) {
    return (
      <div className={`${styles.modalWrapper}`}>
        <div className="text-danger text-center">Failed to Load Step</div>
      </div>
    );
  }

  return (
    <div className={`${styles.modalWrapper}`}>
      {/* Header */}
      <div
        className={`d-flex align-items-center justify-content-between py-2 ${styles.templateHead}`}
      >
        {/* Cross Button */}
        <div className="d-none">
          <div
            className={`d-flex justify-content-center align-items-center ${styles.backChat}`}
          >
            <span
              className="cursor-pointer"
              onClick={onHide}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>

          {/* Heading */}
          <h3 className={`mb-0 ms-2 ${styles.heading}`}>Edit template</h3>
        </div>
        <div className="d-flex align-items-center">
          <h4 className={`mb-0 ${styles.stepNum}`}>Step 1:</h4>
          <div className={`d-flex ms-1 ${styles.dayWrap}`}>
            <span className={`pe-1 ${styles.dayText}`}>Day</span>
            {isEditing ? (
              <div className={`position-relative`}>
                <input
                  type="text"
                  value={editableDay}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={`${styles.inputChange}`}
                  autoFocus
                />
                <div className={`${styles.checkBox} cursor-pointer`}>
                  <span>
                    <i className="fa-solid fa-check"></i>
                  </span>
                </div>
              </div>
            ) : (
              <span className="">{editableDay}</span>
            )}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleEditClick();
              }}
              className={`ps-1 cursor-pointer ${
                isEditing ? "d-none" : "d-block"
              }`}
            >
              <img
                src={editIcon}
                alt="edit"
                width={12}
              />
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            className={`px-1 ${styles.saveTempBtn}`}
            onClick={exportHtmlAndSave}
            disabled={saveLoader === "loading"}
          >
            {saveLoader === "loading"
              ? "Saving..."
              : saveLoader === "success"
                ? "Saved!"
                : "Save"}
          </button>
          <button
            className={`ms-2 ${styles.businessBtn}`}
            onClick={onBusinessClick}
          >
            Business 1
            <span className="ps-1">
              <i className={`fa-solid fa-chevron-down`}></i>
            </span>
          </button>
        </div>
      </div>
      {/* Content */}
      <div className={`${styles.mainContent}`}>
        {isLoading || editorLoading ? (
          <Loader style={{ position: "absolute", top: "0", left: "45%" }} />
        ) : null}
        <div
          className={`d-flex flex-column flex-md-row ${styles.contentWrapper}`}
          style={{
            visibility: isLoading || editorLoading ? "hidden" : undefined,
          }}
        >
          {/* Left Section */}
          {/* Right Section - Email Preview*/}
          <div
            className={`d-flex flex-column ${styles.emailWrap}`}
            style={{
              height: isLoading || editorLoading ? "0px !important" : "",
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="emailSub"
                className={`form-label ${styles.emailSubject}`}
              >
                Email subject:
              </label>
              <div
                className={styles.inputContainer}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => {
                  // Check if the related target is the button to prevent hiding
                  if (!e.currentTarget.contains(e.relatedTarget)) {
                    setIsFocused(false);
                  }
                }}
              >
                <input
                  type="text"
                  className={`form-control ${styles.emailInput}`}
                  id="emailSub"
                  placeholder="Update regarding our software"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                {isFocused && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch({
                        payload: {
                          callback: (text) => {
                            setSubject(text);
                          },
                          elementType: "text",
                          selectedValue: subject,
                        },
                        type: CampaignActionType.SET_ACTIVE_PROMPT_ELEMENT,
                      });
                      dispatch({
                        type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD,
                        payload: true,
                      });
                    }}
                    className={styles.editButton}
                  >
                    Edit with AI
                  </button>
                )}
              </div>
            </div>
            <div className={`${styles.editoWrapper}`}>
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
                style={{
                  minHeight: "100%",
                }}
                options={{
                  version: "latest",
                  appearance: {
                    theme: "modern_light",
                  },
                  displayMode: "email",
                  features: {
                    undoRedo: true,
                    textEditor: {
                      customButtons: [
                        {
                          name: "re-create", // Unique identifier for the button
                          text: `<a href="#" style="display: inline-block;padding: 10px 12px;background-color: black;color: white;font-family: Arial, sans-serif;font-size: 14px;text-align: center;border-radius: 8px;text-decoration: none;width: 100%;height: 100%;">
                                Edit with AI
                              </a>`, // The label that will appear on the button
                          icon: undefined as any, // Icon that will appear on the button (optional)
                          onSetup: () => {}, // Function executed when the button is set up
                          onAction: (data, callback) => {
                            // callback(data.text + " Updated"); // Perform action and update the text
                            const requestId = uuidv4(); // Generate a unique ID for the request
                            dispatch({
                              payload: {
                                callback: callback,
                                elementType: "text",
                                selectedValue: data.text,
                              },
                              type: CampaignActionType.SET_ACTIVE_PROMPT_ELEMENT,
                            });
                            dispatch({
                              type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD,
                              payload: true,
                            });

                            // callbackMap.current.set(requestId, {
                            //   selectedText: data.text,
                            //   callback,
                            // });
                            // setRequestId(requestId);
                            // setShowInstructionModal(true);
                          },
                        },
                      ],
                    },
                  },
                  mergeTags: variablesList,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        show={showInstructionModal}
        onHide={() => setShowInstructionModal(false)}
        contentClassName={`${styles.instructionContent}`}
        dialogClassName={`${styles.instructionDialogClass}`}
        backdropClassName={`${styles.backDropClass}`}
        id="instructionModal"
        centered={true}
        enforceFocus={false}
      >
        <InstructionsModal
          setShowInstructionModal={setShowInstructionModal}
          setShowError={setShowError}
          showLoading={showLoading}
          handleSubmit={(value) => handleRecreateSubmit(requestId, value)}
        />
      </Modal> */}
      <ErrorModal
        showModal={showError}
        onHide={() => setShowError(false)}
        back={() => setShowError(false)}
        retry={() => {
          setShowLoading(true);
          setTimeout(() => {
            setShowLoading(false);
          }, 1500);
        }}
        showLoader={showLoading}
      />
    </div>
  );
};

export default ConfigureEmailTemplate;
