import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getAllDripCampaignsService, {
  GetAllDripCampaignResponse,
} from "src/services/Campaign/getAllDripCampaigns.service";

const LIMIT = 10;

export type GetAllDripCampaignsData = InfiniteData<PageData>;
export const GetAllDripCampaignsQueryKey = ["getAllDripCampaignsService"];

type PageData = GetAllDripCampaignResponse & {
  page: number; // Current page number
};

const useDripCampaign = () => {
  const { data, status, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<PageData>(GetAllDripCampaignsQueryKey, {
      queryFn: async ({ pageParam = 1 }) => {
        const res = await getAllDripCampaignsService({
          limit: LIMIT,
          start: (pageParam - 1) * LIMIT,
        });

        return { ...res, page: pageParam } as PageData;
      },
      getNextPageParam: (lastPage) => {
        const { page, hasMore } = lastPage;
        return hasMore ? page + 1 : undefined;
      },
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 0,
    });

  const allCampaignSegments = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap((val) => val.data);
  }, [data]);

  const fetchStatus: AJAXSTATUS = useMemo(() => {
    return isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected";
  }, [isFetching, status]);

  const fetchMore = useCallback(() => {
    if (hasNextPage && fetchStatus !== "pending") {
      fetchNextPage();
    }
  }, [fetchNextPage, fetchStatus, hasNextPage]);

  return {
    allCampaignSegments,
    fetchMore,
    hasMore: hasNextPage,
    fetchStatus,
  };
};

export default useDripCampaign;
