import { useQuery } from "@tanstack/react-query";
import getCampaignReportUsersService, {
  GetCampaignReportUsersParams,
  GetCampaignReportUsersResponse,
} from "src/services/report/getCampaignReports.service";

const useFetchCampaignReportUsers = (params: GetCampaignReportUsersParams) => {
  const { data, isLoading, isError } = useQuery<GetCampaignReportUsersResponse>(
    {
      queryKey: ["campaignReportUsers", params],
      queryFn: () => getCampaignReportUsersService({ ...params }),
      enabled: !!params.segmentId,
    },
  );
  return {
    data,
    isLoading,
    isError,
  };
};

export default useFetchCampaignReportUsers;
