import { axiosJSON } from "src/globals/axiosEndPoints";
import { ESequenceSectionType } from "./createCampaignV2.service";

export interface GetSequenceSectionsParams {
  templateId: string | number;
  variantId: string | number;
}

export interface ISequenceStepSection {
  sectionType: ESequenceSectionType;
  htmlId: string;
}

const getSequenceSectionsService = async (
  params: GetSequenceSectionsParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/getSequenceSections`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res.data as ISequenceStepSection[];
};

export default getSequenceSectionsService;
