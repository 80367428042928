import { QueryKey } from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { ActionDispatch, AJAXSTATUS } from "src/globals/constants";
import { BrandOption } from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";
import { CreateCampaignV2Params } from "src/services/Campaign/createCampaignV2.service";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import { CampaignTrigger } from "src/services/Campaign/getTriggers.service";
import useFetchTriggers from "./useFetchTriggers";

export interface AddCampaignContextState extends CreateCampaignV2Params {
  showErrors: boolean;
  fetchStatusTriggers: AJAXSTATUS;
  brand?: BrandOption;
  activeTemplate?: IEmailTemplateVariant;
  allTriggers: CampaignTrigger[];
  templateId: string | number;
  variantId: string | number;
  templateCategoryId: string | number;
  /** max limit for number of features, sections or steps that can be added */
  seqLimit?: number;
  campaignId?: number | string;
  allTemplatesQueryKey?: QueryKey;
  lastCompletedStep: number;
  isCategoryDesctiptionRequired?: boolean;
  isSubCategoryDesctiptionRequired?: boolean;
}

const initialState: AddCampaignContextState = {
  availableChannels: [],
  brandId: "",
  categoryId: "",
  templateId: "",
  variantId: "",
  templateCategoryId: "",
  name: "",
  sequence: [],
  triggerId: "",
  userSegmentId: "",
  showErrors: false,
  fetchStatusTriggers: "pending",
  allTriggers: [],
  lastCompletedStep: 0,
};

const reducerMethod = (
  state: AddCampaignContextState,
  [action, payload]: [
    (value: AddCampaignContextState, payload?: any) => AddCampaignContextState,
    undefined | any,
  ],
): AddCampaignContextState => {
  // Execute the action function with current state and payload
  const newState = action(state, payload);

  // If the action returns a new state, use it, otherwise spread the old state
  return newState ? newState : { ...state };
};

const AddCampaignContext = createContext<
  [AddCampaignContextState, ActionDispatch<typeof AddCampaignActions>]
>([initialState, () => {}]);

export const useCreateAddCampaignProvider = () => {
  const { fetchStatus, triggers } = useFetchTriggers();

  const [state, dispatch] = useReducer(reducerMethod, { ...initialState });

  // Create a modified dispatch function that wraps the original dispatch with action and payload
  const modifiedDispatch: ActionDispatch<typeof AddCampaignActions> =
    useCallback(
      (action, payload) => {
        dispatch([AddCampaignActions[action], payload]);
      },
      [dispatch],
    );

  useMemo(() => {
    modifiedDispatch("updateState", {
      fetchStatusTriggers: fetchStatus,
      allTriggers: triggers,
      triggerId: triggers[0]?.id ?? "",
    });
  }, [fetchStatus, modifiedDispatch, triggers]);

  return [state, modifiedDispatch, AddCampaignContext.Provider] as [
    AddCampaignContextState,
    ActionDispatch<typeof AddCampaignActions>,
    typeof AddCampaignContext.Provider,
  ];
};

export const useAddCampaignState = () => {
  const [state, dispatch] = useContext(AddCampaignContext);

  if (!state || !dispatch) {
    throw new Error(
      "useSendMessageContext must be used within a MessageModalContext",
    );
  }

  return { state, dispatch };
};

const AddCampaignActions = {
  resetState: () => initialState,
  updateState: (
    state: AddCampaignContextState,
    payload: Partial<AddCampaignContextState>,
  ) => {
    if (payload.showErrors !== undefined) {
      state.showErrors = payload.showErrors;
    }

    if (payload.fetchStatusTriggers !== undefined) {
      state.fetchStatusTriggers = payload.fetchStatusTriggers;
    }

    if (payload.allTriggers !== undefined) {
      state.allTriggers = payload.allTriggers;
    }

    if (payload.triggerId !== undefined) {
      state.triggerId = payload.triggerId;
    }

    if (payload.brand !== undefined) {
      state.brand = payload.brand;
      state.brandId = payload.brand.value;
    }

    // if (payload.activeTemplate !== undefined) {
    state.activeTemplate = payload.activeTemplate;
    // }

    if (payload.name !== undefined) {
      state.name = payload.name;
    }

    if (payload.userSegmentId !== undefined) {
      state.userSegmentId = payload.userSegmentId;
    }

    if (payload.categoryId !== undefined) {
      state.categoryId = payload.categoryId;
    }

    if (payload.categoryDescription !== undefined) {
      state.categoryDescription = payload.categoryDescription;
    }

    if (payload.subCategoryDescription !== undefined) {
      state.subCategoryDescription = payload.subCategoryDescription;
    }

    if(payload.isCategoryDesctiptionRequired){
      state.isCategoryDesctiptionRequired =
        payload.isCategoryDesctiptionRequired;
    }

    if(payload.isSubCategoryDesctiptionRequired){
      state.isSubCategoryDesctiptionRequired =
        payload.isSubCategoryDesctiptionRequired;
    }

    if (payload.subCategoryId !== undefined) {
      state.subCategoryId = payload.subCategoryId;
    }

    if (payload.sequence !== undefined) {
      state.sequence = payload.sequence;
    }

    if (payload.availableChannels !== undefined) {
      state.availableChannels = payload.availableChannels;
    }

    if (payload.templateCategoryId !== undefined) {
      state.templateCategoryId = payload.templateCategoryId;
    }

    if (payload.templateId !== undefined) {
      state.templateId = payload.templateId;
    }

    if (payload.variantId !== undefined) {
      state.variantId = payload.variantId;
    }

    if (payload.activeTemplate !== undefined) {
      state.activeTemplate = payload.activeTemplate;
    }

    if (payload.allTemplatesQueryKey !== undefined) {
      state.allTemplatesQueryKey = payload.allTemplatesQueryKey;
    }

    if (payload.campaignId !== undefined) {
      state.campaignId = payload.campaignId;
    }

    if (payload.lastCompletedStep !== undefined) {
      state.lastCompletedStep = payload.lastCompletedStep;
    }

    return { ...state };
  },
  updateActiveTemplate: (
    state: AddCampaignContextState,
    payload: Partial<IEmailTemplateVariant>,
  ) => {
    if (state.activeTemplate) {
      state.activeTemplate = { ...state.activeTemplate, ...payload };
    }

    return { ...state };
  },
  updateTriggerAfter: (
    state: AddCampaignContextState,
    payload: { days: number; sectionIndex: number },
  ) => {
    // Update the triggerAfter property with the parsed integer value of editableDay
    state.sequence[payload.sectionIndex] = {
      ...state.sequence[payload.sectionIndex], // Spread other existing properties to retain them
      triggerAfter: parseInt(payload.days + ""), // Parse editableDay to an integer
    };
    return { ...state };
  },
} as const;
