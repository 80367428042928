import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteCampaignParams,
  deleteCampaignService,
} from "src/services/Campaign/deleteCampaign.service";
import {
  getAllCampaigns,
  GetAllCampaignsResponse,
} from "src/services/Campaign/getAllCampaigns";
import {
  CampaignData,
  CampaignEmail,
  getCampaignById,
} from "src/services/Campaign/getCampaignById";
import { getCampaignTimeline } from "src/services/Campaign/getCampaignTimeline";
import {
  updateCampaign,
  UpdateCampaignParam,
} from "src/services/Campaign/updateCampaign";
import {
  updateCampaignEmail,
  UpdateCampaignEmailParam,
} from "src/services/Campaign/updateCampaignEmail";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { convertHTMLToText } from "src/utils/utils";
import { initialTimelineData } from "../CampaignTimeline/CampaignTimeline";
import { GetAllDripCampaignsData } from "../Children/CampaignTabs/Children/DripCampaign/Hooks/useDripCampaign";
import { TemplateData, getTemplate } from "src/services/Campaign/getTemplate";
import { getCampaignEmailById } from "src/services/Campaign/getCampaignEmailById";

// Infinite query to fetch all campaigns with start and limit pagination
export const useFetchAllCampaigns = (
  limit: number,
  isSegmentDataRequired = false,
) => {
  return useInfiniteQuery(
    ["allCampaigns"],
    async ({ pageParam = 0 }) => {
      // Replace with your actual API endpoint
      const response = await getAllCampaigns({
        start: pageParam,
        limit: limit,
        isSegmentDataRequired,
      });
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // Assuming the API returns a boolean `hasMore` to indicate more data
        // return lastPage.total ? pages.length * limit : undefined;
      },
    },
  );
};
export const useCampaignData = (campaignId: number | undefined) => {
  return useQuery<CampaignData, Error>(
    ["campaignData", campaignId], // Include campaignId in the query key
    () => getCampaignById({ campaignId: campaignId! }), // Pass the id parameter
    {
      enabled: !!campaignId,
    },
  );
};

// Hook to fetch email template data based on campaignId and templateId
export const useCampaignTemplateData = (
  campaignId: number,
  templateId: number,
  ticketId: number,
) => {
  return useQuery<TemplateData, Error>(
    ["campaignData", campaignId, templateId, ticketId], // Include campaignId,templateId, ticketId in the query key
    () =>
      getTemplate({
        campaignId: campaignId,
        emailTemplateId: templateId,
        ticketId: ticketId,
      }), // Pass the id parameter
    {
      enabled: !!campaignId || !!templateId,
    },
  );
};

// Mutation to update campaign name
export const useUpdateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateCampaignParam) => {
      // Replace with your actual API endpoint
      await updateCampaign(params);
    },
    {
      onMutate: async ({ campaignId, name, status }) => {
        const campaignQueryKey = ["campaignData", campaignId];
        const allCampaignsQueryKey = ["allCampaigns"];
        const allDripCampaignsKey = ["getAllDripCampaignsService"];
        // const dripCampaignsBySegmentIdKey = ["getCampaignsBySegmentId"];

        await queryClient.cancelQueries(campaignQueryKey);
        await queryClient.cancelQueries(allCampaignsQueryKey);
        await queryClient.cancelQueries(allDripCampaignsKey);
        // await queryClient.cancelQueries(dripCampaignsBySegmentIdKey);

        const previousCampaignData =
          queryClient.getQueryData<CampaignData>(campaignQueryKey);
        const previousAllCampaigns =
          queryClient.getQueryData(allCampaignsQueryKey);
        const previousAllDripCampaignData =
          queryClient.getQueryData<GetAllDripCampaignsData>(
            allDripCampaignsKey,
          );
        // const previousDripCampaignBySegmentId =
        //   queryClient.getQueryData<GetDripCampaignsBySegmentId>(
        //     dripCampaignsBySegmentIdKey,
        //   );

        if (previousCampaignData) {
          queryClient.setQueryData<CampaignData>(campaignQueryKey, {
            ...previousCampaignData,
            name: name ? name : previousCampaignData.name,
            status: status ? status : previousCampaignData.status,
          });
        }

        if (previousAllCampaigns) {
          queryClient.setQueryData<
            InfiniteData<GetAllCampaignsResponse["data"]> | undefined
          >(allCampaignsQueryKey, (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((campaign: GetAllCampaignsResponse["data"][0]) =>
                  campaign.id === campaignId
                    ? {
                        ...campaign,
                        name: name ? name : campaign.name,
                        status: status ? status : campaign.status,
                      }
                    : campaign,
                ),
              ),
            };
          });
        }

        if (previousAllDripCampaignData) {
          queryClient.setQueryData<GetAllDripCampaignsData | undefined>(
            allDripCampaignsKey,
            (oldData) => {
              if (!oldData) return oldData;

              return {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((dripCampaign) => ({
                    ...dripCampaign,
                    campaignsData: dripCampaign.campaignsData.map((campaign) =>
                      campaign.id === campaignId
                        ? {
                            ...campaign,
                            name: name ? name : campaign.name,
                            status: status ? status : campaign.status,
                          }
                        : campaign,
                    ),
                  })),
                })),
              };
            },
          );
        }

        // if (previousDripCampaignBySegmentId) {
        //   queryClient.setQueryData<GetDripCampaignsBySegmentId | undefined>(
        //     dripCampaignsBySegmentIdKey,
        //     (oldData) => {
        //       if (!oldData) return oldData;

        //       return {
        //         ...oldData,
        //         pages: oldData.pages.map((page) => ({
        //           ...page,

        //           data: page.data.map((campaign) =>
        //             campaign.id === campaignId
        //               ? {
        //                   ...campaign,
        //                   name: name ? name : campaign.name,
        //                   status: status ? status : campaign.status,
        //                 }
        //               : campaign,
        //           ),
        //         })),
        //       };
        //     },
        //   );
        // }

        return {
          previousCampaignData,
          previousAllCampaigns,
          previousAllDripCampaignData,
          // previousDripCampaignBySegmentId,
        };
      },
      onError: (err, variables, context) => {
        const campaignQueryKey = ["campaignData", variables.campaignId];
        const allCampaignsQueryKey = ["allCampaigns"];
        const allDripCampaignsKey = ["getAllDripCampaignsService"];
        // const dripCampaignsBySegmentIdKey = ["getCampaignsBySegmentId"];

        if (context?.previousCampaignData) {
          queryClient.setQueryData(
            campaignQueryKey,
            context.previousCampaignData,
          );
        }

        if (context?.previousAllCampaigns) {
          queryClient.setQueryData(
            allCampaignsQueryKey,
            context.previousAllCampaigns,
          );
        }

        if (context?.previousAllDripCampaignData) {
          queryClient.setQueryData(
            allDripCampaignsKey,
            context.previousAllDripCampaignData,
          );
        }

        // if (context?.previousDripCampaignBySegmentId) {
        //   queryClient.setQueryData(
        //     dripCampaignsBySegmentIdKey,
        //     context.previousDripCampaignBySegmentId,
        //   );
        // }
      },
      onSettled: (variables) => {
        // const campaignQueryKey = ["campaignData", variables.campaignId];
        // const allCampaignsQueryKey = "allCampaigns";
        // queryClient.invalidateQueries(campaignQueryKey);
        // queryClient.invalidateQueries(allCampaignsQueryKey);
      },
    },
  );
};

// Mutation to update email details
export const useUpdateCampaignEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ templateInnerText, ...payload }: UpdateCampaignEmailParam) => {
      // Replace with your actual API endpoint
      await updateCampaignEmail(payload);
    },
    {
      onMutate: async ({ campaignId, id, ...updatedEmail }) => {
        const queryKey = ["campaignData", campaignId];
        await queryClient.cancelQueries(queryKey);

        const previousCampaignData =
          queryClient.getQueryData<CampaignData>(queryKey);

        if (previousCampaignData) {
          const updatedEmails = previousCampaignData.emails.map((email) =>
            email.id === id
              ? {
                  ...email,
                  channel: updatedEmail.channel
                    ? updatedEmail.channel
                    : email.channel,
                  triggerAfter: updatedEmail.triggerAfter
                    ? updatedEmail.triggerAfter
                    : email.triggerAfter,
                  heading: updatedEmail.heading
                    ? convertHTMLToText(updatedEmail.heading)
                    : email.heading,
                  description: updatedEmail.templateInnerText
                    ? updatedEmail.templateInnerText
                    : email.description,
                  emailTemplateData: {
                    ...email.emailTemplateData,
                    ...updatedEmail,
                    fullTemplate: undefined,
                  },
                }
              : email,
          );

          queryClient.setQueryData<CampaignData>(queryKey, {
            ...previousCampaignData,
            emails: updatedEmails,
          });
        }

        return { previousCampaignData };
      },
      onError: (err, variables, context) => {
        const queryKey = ["campaignData", variables.campaignId];
        if (context?.previousCampaignData) {
          queryClient.setQueryData(queryKey, context.previousCampaignData);
        }
      },
      onSettled: (variables) => {
        // const queryKey = ["campaignData", variables.campaignId];
        // queryClient.invalidateQueries(queryKey);
      },
    },
  );
};
export const useCampaignTimeline = (campaignId: number | undefined) => {
  return useQuery<TimelineData, Error>(
    ["campaign/timeline", campaignId], // Include campaignId in the query key
    {
      queryFn: async () => {
        if (campaignId) {
          return getCampaignTimeline({ campaignId: campaignId });
        }

        return initialTimelineData;
      },
      enabled: !!campaignId,
    },
  );
};

export const useDeleteCampaign = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async (params: DeleteCampaignParams) => {
      await deleteCampaignService(params);
    },
    {
      onMutate: async ({ campaignId }) => {
        const campaignQueryKey = ["campaignData", campaignId];
        const allCampaignsQueryKey = ["allCampaigns"];
        const allDripCampaignsKey = ["getAllDripCampaignsService"];
        // const dripCampaignsBySegmentIdKey = ["getCampaignsBySegmentId"];

        await queryClient.cancelQueries(allCampaignsQueryKey);
        await queryClient.cancelQueries(allDripCampaignsKey);
        // await queryClient.cancelQueries(dripCampaignsBySegmentIdKey);
        queryClient.removeQueries(campaignQueryKey);

        const previousAllCampaigns = queryClient.getQueryData<
          InfiniteData<GetAllCampaignsResponse["data"]> | undefined
        >(allCampaignsQueryKey);

        const previousAllDripCampaignData =
          queryClient.getQueryData<GetAllDripCampaignsData>(
            allDripCampaignsKey,
          );
        // const previousDripCampaignBySegmentId =
        //   queryClient.getQueryData<GetDripCampaignsBySegmentId>(
        //     dripCampaignsBySegmentIdKey,
        //   );

        if (previousAllCampaigns) {
          queryClient.setQueryData<
            InfiniteData<GetAllCampaignsResponse["data"]> | undefined
          >(allCampaignsQueryKey, (oldData) => {
            if (!oldData) return oldData;

            const filteredPages = oldData.pages.map((page) =>
              page.filter(
                (campaign: GetAllCampaignsResponse["data"][0]) =>
                  campaign.id !== campaignId,
              ),
            );

            return {
              ...oldData,
              pages: filteredPages,
            };
          });
        }

        if (previousAllDripCampaignData) {
          queryClient.setQueryData<GetAllDripCampaignsData | undefined>(
            allDripCampaignsKey,
            (oldData) => {
              if (!oldData) return oldData;

              return {
                ...oldData,
                pages: oldData.pages.map((page) => ({
                  ...page,
                  data: page.data.map((dripCampaign) => ({
                    ...dripCampaign,
                    campaignsData: dripCampaign.campaignsData.filter(
                      (campaign) => campaign.id !== campaignId,
                    ),
                  })),
                })),
              };
            },
          );
        }

        // if (previousDripCampaignBySegmentId) {
        //   queryClient.setQueryData<GetDripCampaignsBySegmentId | undefined>(
        //     dripCampaignsBySegmentIdKey,
        //     (oldData) => {
        //       if (!oldData) return oldData;

        //       return {
        //         ...oldData,
        //         pages: oldData.pages.map((page) => ({
        //           ...page,

        //           data: page.data.filter(
        //             (campaign) => campaign.id !== campaignId,
        //           ),
        //         })),
        //       };
        //     },
        //   );
        // }

        return {
          previousAllCampaigns,
          previousAllDripCampaignData,
          // previousDripCampaignBySegmentId,
        };
      },
      onError: (err, variables, context) => {
        const allCampaignsQueryKey = ["allCampaigns"];
        const allDripCampaignsKey = ["getAllDripCampaignsService"];
        // const dripCampaignsBySegmentIdKey = ["getCampaignsBySegmentId"];

        if (context?.previousAllCampaigns) {
          queryClient.setQueryData(
            allCampaignsQueryKey,
            context.previousAllCampaigns,
          );
        }

        if (context?.previousAllDripCampaignData) {
          queryClient.setQueryData(
            allDripCampaignsKey,
            context.previousAllDripCampaignData,
          );
        }

        // if (context?.previousDripCampaignBySegmentId) {
        //   queryClient.setQueryData(
        //     dripCampaignsBySegmentIdKey,
        //     context.previousDripCampaignBySegmentId,
        //   );
        // }
      },
      onSuccess: () => {
        navigate("/campaigns");
      },
    },
  );
};

interface UseActiveCampaignEmailProps {
  activeCampaignEmail: CampaignEmail | null;
  setActiveCampaignEmail: React.Dispatch<
    React.SetStateAction<CampaignEmail | null>
  >;
}

const useActiveCampaignEmail = ({
  activeCampaignEmail,
  setActiveCampaignEmail,
}: UseActiveCampaignEmailProps) => {
  const { stepId } = useParams();

  const { data, isLoading, error } = useQuery(
    ["campaign/email", stepId],
    () => getCampaignEmailById({ id: stepId + "" }),
    {
      enabled: stepId ? !activeCampaignEmail : false,
      onSuccess: (data) => {
        if (data) {
          setActiveCampaignEmail(data);
        }
      },
    },
  );

  return { data, isLoading, error, stepId };
};

export default useActiveCampaignEmail;
