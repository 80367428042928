import styles from "./NeedEdit.module.scss";
import saufterLogo from "src/assets/images/saufter.png";

interface Props {
  handleOnClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const NeedEdit = ({ handleOnClick }: Props) => {
  return (
    <div className={`d-flex align-items-center ${styles.editWrapper}`}>
      <img
        src={saufterLogo}
        alt="saufter"
        width={60}
      />
      <h4 className={`px-2 mb-0 ${styles.editText}`}>Need edits?</h4>
      <button
        className={`px-1 ${styles.tryBtn}`}
        onClick={handleOnClick}
      >
        Try AI
      </button>
    </div>
  );
};

export default NeedEdit;
