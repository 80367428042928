/**
 * This file is the service file used for making api request.
 * It contains the getSunBurstReportService function service which is explained below.
 *
 * @author Yash Aditya
 */

import { axiosJSON } from "src/globals/axiosEndPoints";
import { FilterInParam } from "src/routes/Report/hooks/reportFilters/useReportFilters";
import { SunBurstZoomableData } from "src/components/SunBurstZoomable/SunBurstZoomable";

export interface ReportTicketGraphParams {
  filters?: FilterInParam;
}

/**
 * This service is dynamic for all the graphs and brings graph data from backend based on the given parameters and endpoint.
 */
export const getSunBurstReportService = async (
  params: ReportTicketGraphParams,
) => {
  const { data: res } = await axiosJSON.post(`/api/reports/sunburst`, params);

  if (res.err || res.error) {
    throw res.msg;
  }

  return res.data as SunBurstZoomableData;
};

export default getSunBurstReportService;
