import ParseHTML from "src/components/ParseHTML";

const string = `
<p>
  <!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta
        name="viewport"
        content="initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <title></title>
      <style type="text/css">
        .responsive-image {
          width: 100% !important;
        }

        img {
          max-width: 100% !important;
          height: auto !important;
        }

        code {
          color: #db344b !important;
          font-size: 1.1em !important;
          padding: 3px !important;
          border: 1px solid #f7f7f9 !important;
          border-radius: 3px !important;
        }

        <!--ol,
        li,
        p {
          padding: 4px 0px !important;
        }
        -- > ol > li {
          padding: 4px 0px;
        }

        ul > li {
          padding: 4px 0px;
        }

        h3 {
          font-size: 16px !important;
          font-weight: bold !important;
          padding-top: 4px !important;
          padding-bottom: 1px !important;
        }

        @import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
      </style>
      <!--[if mso]>
        <style>
          span,
          td,
          table,
          div {
            font-family: Arial, serif !important;
          }
        </style>
      <![endif]-->
    </head>
    <body
      style="
        font-size: 1em;
        font-family: Verdana, sans-serif;
        color: #343434;
        max-width: 100vw;
        line-height: 200% !important;
      "
    >
      <style type="text/css">
        div#emailPreHeader {
          display: none !important;
        }
      </style>
      <div
        id="emailPreHeader"
        style="
          mso-hide: all;
          visibility: hidden;
          opacity: 0;
          color: transparent;
          mso-line-height-rule: exactly;
          line-height: 0;
          font-size: 0px;
          overflow: hidden;
          border-width: 0;
          display: none !important;
        "
      >
        Hi there! In this email, we'll show you how to improve productivity by
        optimizing your CI/CD pipeline for speed.
      </div>
      <div id="wrapper" style="text-align: center; background-color: white">
        <div
          id="main-content"
          style="
            width: 600px;
            max-width: 100%;
            background-color: white;
            display: inline-block;
            text-align: left;
            margin-top: 30px;
            margin-bottom: 30px;
          "
        >
        
          <div
            id="title-block"
            style="
              text-align: center;
              background-color: white;
              max-width: 100%;
              padding: 20px;
              border-bottom: 5px solid white;
            "
          >
            <img
              width="250"
              src="https://d15k2d11r6t6rl.cloudfront.net/pub/bfra/3jr7y8a7/urf/9gn/568/Screenshot%202024-10-23%20155735.png"
              alt="CircleCI"
            />
          </div>
          <div id="body-wrapper" style="padding: 0px">
            <table
              cellpadding="0"
              cellspacing="0"
              border="0"
              width="100%"
              class="mktoContainer"
              id="mkto-container"
              style="max-width: 100% !important"
            >
              <tr
                class="mktoModule"
                id="mkto-section"
                style="max-width: 100% !important"
              >
                <td class="module-wrapper" style="max-width: 100% !important">
                  <div
                    style="
                      color: #343434;
                      max-width: 100% !important;
                      padding: 15px 30px;
                      border: 1px solid #f1f1f1;
                      margin: 10px 0px !important;
                    "
                  >
                    <div
                      class="mktoText text-section"
                      id="mkto-editable-text"
                      style="color: #343434; max-width: 100% !important"
                    >
                      <br />
                      <div style="font-size: 26px; text-align: center">
                        <strong>How to make your builds faster</strong>
                      </div>
                      <br />
                      <div style="color: #969696; text-align: center">
                        <span style="font-size: 16px"
                          >Improve productivity by optimizing your CI/CD
                          pipelines for speed.</span
                        >
                      </div>
                      <br />
                    </div>
                    <div
                      class="mktoImg img-section"
                      id="mkto-editable-image"
                      mktolockimgsize="true"
                    >
                      <a
                        ><img
                          style="
                            max-width: 100% !important;
                            height: auto !important;
                          "
                          src="https://d15k2d11r6t6rl.cloudfront.net/pub/bfra/3jr7y8a7/etr/6tk/ohh/Screenshot%202024-10-23%20155728.png"
                          alt="workspace-caching-graphic-new.png"
                      /></a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                class="mktoModule"
                id="mkto-section7c2060bb-78d1-4578-93fc-428da6851971"
                style="max-width: 100% !important"
              >
                <td class="module-wrapper" style="max-width: 100% !important">
                  <div
                    style="
                      color: #343434;
                      max-width: 100% !important;
                      padding: 15px 30px;
                      border: 1px solid #f1f1f1;
                      margin: 10px 0px !important;
                    "
                  >
                    <div
                      class="mktoText text-section"
                      id="mkto-editable-text7c2060bb-78d1-4578-93fc-428da6851971"
                      style="color: #343434; max-width: 100% !important"
                    >
                      <span style="font-size: 20px">Advanced caching</span>
                      <p style="font-size: 16px">
                        <em
                          >Save time on fetching dependencies and building
                          artifacts.</em
                        >
                      </p>
                      <ul>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                                href="#"
                                target="_blank"
                                class="mktNoTrack"
                                id=""
                                >Caching dependencies</span
                              ></strong
                            >
                            - cache and restore dependencies using custom keys
                            rather than reinstalling them each time you run a
                            job.</span
                          >
                        </li>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                                href="#"
                                target="_blank"
                                class="mktNoTrack"
                                id=""
                                >Workspaces</span
                              ></strong
                            >
                            - persisting artifacts to a workspace allows
                            downstream jobs to access them as a workflow
                            progresses.</span
                          >
                        </li>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                                href="#"
                                target="_blank"
                                class="mktNoTrack"
                                id=""
                                >Docker layer caching</span
                              ></strong
                            >
                            - cache the individual layers of Docker images that
                            you&nbsp;<em>build</em> in a CircleCI job. Unchanged
                            layers will be restored next time you build a Docker
                            image. This feature requires a paid plan.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mktoImg img-section"
                      id="mkto-editable-image7c2060bb-78d1-4578-93fc-428da6851971"
                    >
                      <span href="#"
                        ><img
                          style="
                            max-width: 100% !important;
                            height: auto !important;
                          "
                      /></a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                class="mktoModule"
                id="mkto-section79f9c00b-191e-4295-9507-fc9d9c11366d"
                style="max-width: 100% !important"
              >
                <td class="module-wrapper" style="max-width: 100% !important">
                  <div
                    style="
                      color: #343434;
                      max-width: 100% !important;
                      padding: 15px 30px;
                      border: 1px solid #f1f1f1;
                      margin: 10px 0px !important;
                    "
                  >
                    <div
                      class="mktoText text-section"
                      id="mkto-editable-text79f9c00b-191e-4295-9507-fc9d9c11366d"
                      style="color: #343434; max-width: 100% !important"
                    >
                      <span style="font-size: 20px"
                        >More machines and more powerful machines.</span
                      >
                      <p style="font-size: 16px">
                        <em
                          >Use&nbsp;multiple&nbsp;machines concurrently
                          and&nbsp;increase compute power to speed up your
                          pipelines.</em
                        >
                      </p>
                      <ul>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                              
                                target="_blank"
                                class="mktNoTrack"
                                id=""
                                >Concurrency</span
                              ></strong
                            >
                            -&nbsp;split up your builds into multiple
                            independent jobs and use workflows to run them
                            simultaneously.</span
                          >
                        </li>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                               
                                class="mktNoTrack"
                                id=""
                                >Parallelism</span
                              ></strong
                            >
                            - run tests in parallel by splitting your test suite
                            across multiple machines.</span
                          >
                        </li>
                        <li style="font-size: 16px">
                          <span style="font-size: 16px"
                            ><strong
                              ><span
                              
                                class="mktNoTrack"
                                id=""
                                >Resource classes</span
                              ></strong
                            >
                            - customize the CPU and RAM for the machine running
                            a particular job. Note that resource classes larger
                            than <code>medium</code> require a
                            <span  class="mktNoTrack" id=""
                              >paid plan</span
                            >.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mktoImg img-section"
                      id="mkto-editable-image79f9c00b-191e-4295-9507-fc9d9c11366d"
                    >
                      <span 
                        ><img
                          style="
                            max-width: 100% !important;
                            height: auto !important;
                          "
                      /></span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div
            id="footer"
            style="
              color: #aaa !important;
              font-size: 0.8em !important;
              text-align: center !important;
              padding: 15px 30px !important;
            "
          >
            <p>
              You received this email because you signed up for
              <span style="color: #aaa; cursor: pointer"
                >CircleCI</span
              >, a cloud-native CI/CD platform to build, test, and deploy your
              code with confidence. To stop receiving these emails,
              <span style="color: #aaa; cursor: pointer">unsubscribe</span>.
            </p>
            <p>© 2020 Circle Internet Services, Inc., All Rights Reserved.</p>
          </div>
        </div>
      </div>

      <img
        src="https://go.circleci.com/trk?t=1&mid=NDg1LVpNSC02MjY6MDo0NzEwOjE1OTM0OjA6NDA4Mzo5OjI4NzMyOjE5Mzk1MzE0LTE6bnVsbA%3D%3D"
        width="1"
        height="1"
        style="display: none !important"
        alt=""
      />
    </body>
  </html>
</p>
`;

const Circleci = () => {
  return <ParseHTML html={string} />;
};

export default Circleci;
