import {
  Group,
  IConditionGroupQuestion,
  LogicalOperatorTypes,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";
import ConfigureBtn from "../ConfigureGroup/ConfigureGroup";
import styles from "./ConfigureGroupQuestion.module.scss";
import { useAutomationContext } from "../../../../Hooks/useAutomationContext";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";

interface QuestionsProps {
  questionData: IConditionGroupQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfigureGroupQuestion = ({
  questionData,
  stepId,
  setShowErrorModal,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const { type } = useParams<IAutomationListParams>();

  // Deletes a condition group identified by groupUUID
  const deleteGroup = (groupUUID: string) => {
    if (questionData?.conditionGroups?.length) {
      if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
        if (setShowErrorModal) setShowErrorModal(true);
        return;
      }
      // Filter out the group with the matching groupUUID
      const newGroups = (questionData.conditionGroups as Group[])
        .filter((group) => group.groupUUID !== groupUUID)
        .map((group, index, array) => {
          // Update nextGroupUUID and logicalOperator for remaining groups
          if (array[index + 1]) {
            group.nextGroupUUID = array[index + 1].groupUUID;
          } else {
            group.nextGroupUUID = null;
            group.logicalOperator = null;
          }
          return group;
        });

      const allGroupsCompleted = newGroups.every(
        (group) => group.status === StepStatus.Completed,
      );
      // Update and save the new groups
      updateQuestions({
        ...questionData,
        conditionGroups: newGroups,
        isAnswered: allGroupsCompleted,
      });

      saveQuestionsData({
        stepId,
        stepQuestions: {
          [questionData.questionKey]: newGroups,
        },
      });
    }
  };

  // Adds a new condition group
  const addGroup = () => {
    if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
      if (setShowErrorModal) setShowErrorModal(true);
      return;
    }
    if (questionData?.conditionGroups?.length) {
      // Define a new group with default values
      const newGroup: Group = {
        groupId: null,
        groupUUID: uuidv4(),
        nextGroupUUID: null,
        logicalOperator: null,
        conditionsLogicalOperator: "and",
        conditions: [],
        status: StepStatus.Pending,
      };

      // Copy current groups and update the last group's nextGroupUUID and logicalOperator
      const newGroups = [...questionData.conditionGroups];
      newGroups[newGroups.length - 1].nextGroupUUID = newGroup.groupUUID;
      newGroups[newGroups.length - 1].logicalOperator = "and";
      newGroups.push(newGroup);

      // Update and save the new groups
      updateQuestions({
        ...questionData,
        conditionGroups: newGroups as Group[],
      });

      saveQuestionsData({
        stepId,
        stepQuestions: {
          [questionData.questionKey]: newGroups as Group[],
        },
      });
    }
  };

  // Updates the logical operator of a specific group identified by groupUUID
  const updateLogicalOperator = (
    groupUUID: string,
    logicalOperator: LogicalOperatorTypes,
  ) => {
    if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
      if (setShowErrorModal) setShowErrorModal(true);
      return;
    }
    if (questionData?.conditionGroups?.length) {
      // Find the group with the matching groupUUID
      const newGroups = [...questionData.conditionGroups];
      const groupIndex = newGroups.findIndex(
        (group) => group.groupUUID === groupUUID,
      );

      // Update the logical operator if the group is found
      if (groupIndex !== -1) {
        newGroups[groupIndex].logicalOperator = logicalOperator;

        // Update and save the new groups
        updateQuestions({
          ...questionData,
          conditionGroups: newGroups as Group[],
        });

        saveQuestionsData({
          stepId,
          stepQuestions: {
            [questionData.questionKey]: newGroups as Group[],
          },
        });
      }
    }
  };

  const updateConditionGroup = (newGroup: Group) => {
    if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
      if (setShowErrorModal) setShowErrorModal(true);
      return;
    }
    if (questionData?.conditionGroups?.length) {
      // Find the group with the matching groupUUID
      const newGroups = [...questionData.conditionGroups];
      const groupIndex = newGroups.findIndex(
        (group) => group.groupUUID === newGroup?.groupUUID,
      );

      // Update the logical operator if the group is found
      if (groupIndex !== -1) {
        newGroups[groupIndex] = newGroup;
        const allGroupsCompleted = newGroups.every(
          (group) => group.status === StepStatus.Completed,
        );
        // Update and save the new groups
        updateQuestions({
          ...questionData,
          conditionGroups: newGroups as Group[],
          isAnswered: allGroupsCompleted,
        });

        // saveQuestionsData({
        //   stepId,
        //   stepQuestions: {
        //     [questionData.questionKey]: newGroups as Group[],
        //   },
        // });
      }
    }
  };

  return (
    <div>
      {questionData.conditionGroups &&
        questionData.conditionGroups.map((conditionGroup, index) => {
          return (
            <div>
              <div className={`mt-2 ${styles.configureBox}`}>
                <ConfigureBtn
                  showDelete={index != 0}
                  handleDelete={() => {
                    deleteGroup(conditionGroup.groupUUID);
                  }}
                  conditionGroup={conditionGroup as Group}
                  groupIndex={index}
                  updateConditionGroup={updateConditionGroup}
                  setShowErrorModal={setShowErrorModal}
                />
              </div>
              {conditionGroup.logicalOperator && (
                <div
                  className={`d-flex align-items-center mx-auto my-2 ${styles.andOrCondition}`}
                >
                  <button
                    className={`${styles.btn} ${
                      conditionGroup.logicalOperator === "and"
                        ? styles.activeBtn
                        : ""
                    }`}
                    onClick={() => {
                      updateLogicalOperator(conditionGroup.groupUUID, "and");
                    }}
                  >
                    AND
                  </button>
                  <button
                    className={`${styles.btn} ${
                      conditionGroup.logicalOperator === "or"
                        ? styles.activeBtn
                        : ""
                    }`}
                    onClick={() => {
                      updateLogicalOperator(conditionGroup.groupUUID, "or");
                    }}
                  >
                    OR
                  </button>
                </div>
              )}
            </div>
          );
        })}
      <div
        className="d-flex mt-4 mb-3 cursor-pointer"
        onClick={addGroup}
      >
        <div
          className={`me-2 d-flex align-items-center justify-content-center ${styles.addBox}`}
        >
          <span className="">
            {" "}
            <i className="fa-solid fa-plus"></i>
          </span>
        </div>
        <div className="d-flex align-items-center">
          <span className={`${styles.addCondtion}`}>
            Add another condition group
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfigureGroupQuestion;
