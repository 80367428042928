import { RouteNames } from "src/containers/SideBar/SideBar";

function getNavigation(
  segmentType: string,
  segmentId: string,
  segmentUserId?: string | null,
) {
  return `${RouteNames.customerSegments}/${segmentType}/${segmentId}${
    segmentUserId ? `/userProfile/${segmentUserId}` : ""
  }`;
}

const customerSegmentsUtils = { getNavigation };

export default customerSegmentsUtils;
