import { SingleValue } from "react-select";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface OptionType {
  label: string;
  value: string;
}
export interface AttributeData {
  id: string | null;
  name: SingleValue<OptionType> | null;
  type: SingleValue<OptionType> | null;
  date: Date | null;
  isError: boolean;
  isDuplicate: boolean;
}
export interface TimeLineData {
  stageId: string | null;
  eventId: string | undefined | null;
  attributeId: string | undefined;
  stageName: SingleValue<OptionType> | null;
  logo?: string;
  startDate: Date | null;
  isError: boolean;
  isDuplicate: boolean;
}
interface EventData {
  eventId?: string | null;
  stageId?: string;
  attributeId?: string;
  datetime?: string;
  name?: string;
  value?: any;
}
export interface UpdateTimelineEventPayload {
  customerId: string | number;
  deletedEventIds?: string[];
  eventData?: EventData[];
  eventType: string;
  eventId?: number; // Optional, if updating an existing event
  stageId?: string;
  attributeId?: string;
}

export async function updateTimeLineEvent({
  payload,
}: {
  payload: UpdateTimelineEventPayload;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/timeline/updateEvent`,
    payload,
  );
  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }
  return res.data;
}
