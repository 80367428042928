import styles from "./DeleteStage.module.scss";
import warningMsg from "src/assets/images/warning.svg";
interface Props {
  setActiveModal: React.Dispatch<React.SetStateAction<"modal" | "loader" | "success" | "delete">>,
  handleTimeLineDelete: (index: number) => void,
  handleAttributeDelete: (index: number) => void,
  deleteTimeLineIndex: number | undefined,
  deleteAttributeIndex: number | undefined,

}
const DeleteStage = ({ setActiveModal, handleTimeLineDelete, handleAttributeDelete, deleteTimeLineIndex, deleteAttributeIndex }: Props) => {
  return (
    <div className="py-5 px-5">
      <div className="d-flex justify-content-center mb-4">
        <img
          src={warningMsg}
          alt="warning"
          className={`${styles.warningImg}`}
        />
      </div>

      <h5 className={`mb-3 px-3 ${styles.heading}`}>
        Are you sure you want to delete this stage from the current user's
        timeline?
      </h5>
      <p className={`px-5 mx-2 ${styles.desc}`}>
        Once deleted, this stage will be permanently removed from the timeline
        and cannot be recovered.
      </p>
      <div
        className={`d-flex justify-content-center align-items-center pt-3 ${styles.btnWrapper}`}
      >
        <button className={`me-5 ${styles.cancelBtn}`} onClick={() => {
          setActiveModal("modal");
        }}>Cancel</button>
        <button className={`${styles.saveBtn}`} onClick={() => {
          if (deleteTimeLineIndex) {
            handleTimeLineDelete(deleteTimeLineIndex);
            setActiveModal("modal");
          } else if (deleteAttributeIndex) {
            handleAttributeDelete(deleteAttributeIndex);
            setActiveModal("modal");
          }

        }}>Yes, delete</button>
      </div>
    </div>
  );
};

export default DeleteStage;
