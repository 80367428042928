import Lottie from "lottie-light-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import liveDot from "src/assets/images/liveDot.json";
import Loader from "src/components/Loader";
import UserAvatar from "src/components/UserAvatar";
import {
  useAutomationContext,
  useAutomationProvider,
} from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { BrandOption } from "src/routes/BotSettings/Children/BotBox/BrandModal/BrandModal";
import BrandDropdown from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";
import AutomationSteps from "../ConfigureSteps/AutomationSteps";
import styles from "./SettingSection.module.scss";
import AxiosImg from "src/components/AxiosImg";

const namePlaceHolder = (type?: EAutomationType) => {
  switch (type) {
    case EAutomationType.BUG_MANAGEMENT:
      return "Untitled bug management";
    case EAutomationType.ISSUE_DISPATCH:
      return "Untitled Issue Dispatch";
  }
};

export const BrandDD = ({
  selectedBrandData,
  updateBrandChange,
  type,
  isDisabled,
}: {
  selectedBrandData?: {
    id: string | number;
    name: string;
    email: string;
    imgURL?: string | null | undefined;
  } | null;
  type?: EAutomationType;
  updateBrandChange: (brandId: string) => void;
  isDisabled?: boolean;
}) => {
  const [selectedBrand, setSelectedBrand] = useState<BrandOption | undefined>();

  useEffect(() => {
    setSelectedBrand(
      selectedBrandData
        ? {
            label: selectedBrandData.name,
            value: Number(selectedBrandData.id),
            imageUrl: selectedBrandData.imgURL,
          }
        : undefined,
    );
  }, [selectedBrandData]);

  return (
    <BrandDropdown
      selectedBrand={selectedBrand}
      setSelectedBrand={setSelectedBrand}
      updateBrandChange={updateBrandChange}
      isAutomation={true}
      automationType={type}
      isDisabled={isDisabled}
    />
  );
};

const SettingComponent = () => {
  const navigate = useNavigate();
  const {
    formData,
    type,
    updateFormData,
    saveData,
    refetchAutomation,
    isLoading,
    errorMessage: fetchConfigErrorMessage,
  } = useAutomationContext();

  const updateBrandChange = useCallback(
    (brandId: string) => {
      updateFormData({ brandId });
      saveData({ brandId });
      refetchAutomation();
    },
    [refetchAutomation, saveData, updateFormData],
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);

  if (isLoading) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <Loader />
      </div>
    );
  }

  if (fetchConfigErrorMessage) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <div className="d-flex align-items-center justify-content-center text-danger">
          {fetchConfigErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div className={`p-3 ${styles.settingWrap}`}>
      <div className="d-flex flex-column flex-md-row justify-content-between w-100">
        <div className="w-50">
          <div className="d-flex align-items-center ">
            <div
              className={`${styles.backArrow}`}
              onClick={() => navigate(`/automations/${type}`)}
              role="button"
            >
              <span>
                <i className="fa-solid fa-arrow-left"></i>
              </span>
            </div>
            <input
              type="text"
              className={`ps-3 ${styles.botName}`}
              value={formData.automationName}
              onChange={(e) => {
                updateFormData({ automationName: e.target.value });
                if (e.target.value) {
                  saveData({ automationName: e.target.value }, true);
                }
              }}
              placeholder={namePlaceHolder(type)}
            />
          </div>
          <span className={`${styles.subHead}`}>
            Only one bot profile will be matched per user.
          </span>
        </div>

        <div className={`${styles.brandDrop}`}>
          {!formData.published ? (
            <div>
              {/* <BrandDD
                selectedBrandData={formData.brand}
                updateBrandChange={updateBrandChange}
                type={type}
              /> */}
              {formData.brand === null ? (
                <BrandDD
                  updateBrandChange={updateBrandChange}
                  type={type}
                />
              ) : (
                <div className="mb-0 px-2 d-flex align-items-center">
                  <div className="px-1">
                    {formData.brand?.imageURL &&
                    formData.brand.imageURL.trim() !== "" ? (
                      <AxiosImg
                        url={formData.brand.imageURL}
                        className={`rounded-circle`}
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <UserAvatar
                        name={formData.brand?.name ?? "NA"}
                        size={20}
                      />
                    )}
                  </div>
                  <span className={`${styles.liveText}`}>
                    {formData.brand?.name}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="px-2 d-flex align-items-center">
              {/* <div className={`${styles.greenDot}`}></div> */}
              <span
                style={{
                  height: 20,
                  width: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Lottie animationData={liveDot}></Lottie>
              </span>
              <div className="mb-0 px-2 d-flex align-items-center">
                <span className={`${styles.liveText}`}>Live on</span>
                <div className="px-1">
                  {formData.brand?.imageURL &&
                  formData.brand.imageURL.trim() !== "" ? (
                    <AxiosImg
                      url={formData.brand.imageURL}
                      className={`rounded-circle`}
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <UserAvatar
                      name={formData.brand?.name ?? "NA"}
                      size={20}
                    />
                  )}
                </div>
                <span className={`${styles.liveText}`}>
                  {formData.brand?.name}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <AutomationSteps
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </div>
  );
};

const SettingSection = () => {
  const { Provider: ConfigProvider, ...contextValues } =
    useAutomationProvider();

  return (
    <ConfigProvider value={contextValues}>
      <SettingComponent />
    </ConfigProvider>
  );
};

export default SettingSection;
