import { useCallback, useMemo } from "react";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";
import styles from "../../PrimaryDetails.module.scss";
import UserSelect, { UserSelectBrandOption } from "../UserSelect/UserSelect";
import { ECampaignChannel } from "src/services/Campaign/createCampaignV2.service";

export const channelOptions: Record<ECampaignChannel, UserSelectBrandOption> = {
  [ECampaignChannel.Email]: { value: ECampaignChannel.Email, label: "Email" },
  [ECampaignChannel.LiveChat]: {
    value: ECampaignChannel.LiveChat,
    label: "Live chat",
  },
  // [ECampaignChannel.LinkedIn]: { value: ECampaignChannel.LinkedIn, label: "LinkedIn" },
};

const allChannels = Object.values(channelOptions);

const ChannelSelectDD = () => {
  const { state, dispatch } = useAddCampaignState();

  const selectedOptions = useMemo(() => {
    return state.availableChannels
      .map((channel) => channelOptions[channel])
      .filter(Boolean);
  }, [state.availableChannels]);

  const onChange = useCallback(
    (val: UserSelectBrandOption[]) => {
      if (val) {
        dispatch("updateState", {
          availableChannels: val.map((v) => v.value) as ECampaignChannel[],
          showErrors: false,
        });
      }
    },
    [dispatch],
  );

  return (
    <div className="mb-3">
      <label
        htmlFor="campaignChannel"
        className={`form-label ${styles.label}`}
      >
        Select the channels applicable
      </label>
      <UserSelect
        value={selectedOptions}
        options={allChannels}
        placeholder="Select channels..."
        className={`${
          state.showErrors &&
          (!selectedOptions || selectedOptions.length === 0) &&
          styles.errorBorder
        }`}
        onChange={(val) => {
          onChange(val as UserSelectBrandOption[]);
        }}
        isClearable={false}
        isSearchable={false}
        inputId="campaignChannel"
        isMulti={true}
        showError={
          state.showErrors && (!selectedOptions || selectedOptions.length === 0)
        }
      />
    </div>
  );
};

export default ChannelSelectDD;
