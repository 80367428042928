import styles from "./ARSReturnType.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import {
  IOrder,
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import { useState } from "react";
import ParseVariablesWithValue from "../ParseVariablesWithValue/ParseVariablesWithValue";
import cautionImg from "src/assets/images/caution.svg";
import useGetAutomationData, {
  IReturnReason,
} from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

const ARSReturnType = () => {
  const [showError, setShowError] = useState(false);
  const {
    selectedOrder,
    orders,
    updateState,
    followupModalData,
    ...contextValues
  } = useReturnPreviewState();
  const {
    arsResponse,
    getAllReturnTypes,
    getAllReasons,
    isReturnReasonsAvailable,
    explainAnswer,
  } = useGetAutomationData();
  const { allReturnTypes } = getAllReturnTypes();
  const allReasons = getAllReasons();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const { addedItemsLength, selectReturnTypeLength } =
    calculateItemLengths(selectedOrder);

  function calculateItemLengths(order: IOrder | null) {
    let addedItemsLength = 0;
    let selectReturnTypeLength = 0;
    if (order) {
      const addedItems = order?.addedItems ?? {};
      addedItemsLength = Object.keys(addedItems).length;
      selectReturnTypeLength = Object.values(addedItems).filter(
        (data) => data.newReturnType,
      ).length;
    }
    return { addedItemsLength, selectReturnTypeLength };
  }

  const handleNext = () => {
    if (selectedOrder) {
      const { addedItemsLength, selectReturnTypeLength } =
        calculateItemLengths(selectedOrder);

      if (
        selectReturnTypeLength > 0 &&
        addedItemsLength === selectReturnTypeLength
      ) {
        // Determine the nextPage based on newReturnType values
        let nextPage: StepName;
        const newReturnTypes = Object.values(
          selectedOrder.addedItems ?? {},
        ).map((item) => item.newReturnType);

        if (
          newReturnTypes.some(
            (type) =>
              type === "exchangeWithSameItems" ||
              type === "exchangeWithDifferentItems",
          )
        ) {
          nextPage = "arsExchange";
        } else if (newReturnTypes.every((type) => type === "proceedToReturn")) {
          nextPage = "configureNextSteps";
        } else {
          // Default case, though ideally shouldn't reach here based on current logic
          nextPage = "configureNextSteps";
        }

        let prevStep: StepName = "arsReturnTypes";
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: nextPage,
            prevStepForConfig: prevStep,
          },
        });
      } else {
        setShowError(true);
        return;
      }
    }
  };

  const handleBack = () => {
    let backPage: StepName = "addReturnItems";
    // Check if any item's selected reason has moreDetails length > 0
    const hasMoreDetails = Object.values(selectedOrder?.addedItems ?? {}).some(
      (data) => {
        if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
          "returnReasonOrderAndItems"
        ) {
          const reason = (allReasons as IReturnReason[])?.find(
            (reason) => reason.id === data.addedReasonId,
          );
          return (
            reason &&
            reason.moreQuestions &&
            reason.moreQuestions.length > 0 &&
            explainAnswer === "requiredAndCustom"
          );
        } else if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
            "followUpQuestion" &&
          data.addedReasonId
        ) {
          const reason = followupModalData.reasons[data.addedReasonId];
          return (
            (reason &&
              reason.reason.moreQuestions &&
              reason.reason.moreQuestions.length > 0) ||
            followupModalData.moreQuestions.length > 0
          );
        }
        return false;
      },
    );

    // calculating backPage
    if (arsResponse?.isOffersAvailable) {
      backPage = "arsOffers";
    } else if (arsResponse?.isInformationAvailable) {
      backPage = "arsInformation";
    } else if (hasMoreDetails) {
      backPage = "moreDetails";
    } else if (isReturnReasonsAvailable) {
      backPage = "reason";
    } else {
      backPage = "returnType";
    }

    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: backPage,
        },
      });
    }
  };

  const handleOnChange = (itemId: string, returnTypeId: string) => {
    if (itemId && returnTypeId && selectedOrder) {
      setShowError(false);
      let updatedItems = selectedOrder?.addedItems ?? {};
      if (Object.keys(updatedItems).length > 0) {
        if (updatedItems[itemId]) {
          updatedItems[itemId].newReturnType = returnTypeId;
        }
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center mb-3 pb-1">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>
            Select item to exchange
          </span>
        </div>
        {showError && (
          <span className={`p-1 mb-1 mt-2 ${styles.errorMsg}`}>
            * Please select a return type
          </span>
        )}
        <div>
          <p className={`${styles.selectText}`}>
            Would you prefer to exchange your order with another size or
            product, instead of a refund?
          </p>
          {arsResponse?.returnTypes?.map((item) => {
            const itemData = selectedOrder?.itemsInOrder?.find(
              (value) => value.id + "" === item.itemId + "",
            );
            // Get all item IDs from addedItems
            const items = Object.values(selectedOrder?.addedItems ?? {});
            const matchedItem = items.find((it) => it.itemId === item?.itemId);
            const returnType = allReturnTypes?.find(
              (returnType) =>
                returnType.type == matchedItem?.selectedReturnType,
            );

            return (
              <div className={`mb-2 ${styles.orderBox}`}>
                <span className={`${styles.subHeading}`}>Return Type:</span>
                <span className={`${styles.returnTypeLabel}`}>
                  {returnType?.name}
                </span>
                <p className={`mb-0 ${styles.orderHeading}`}>
                  {itemData?.name}
                </p>

                <div className={`d-flex mb-3`}>
                  <div className={`${styles.cart}`}>
                    <img
                      src={
                        itemData?.name == "Acme T-Shirt" ? orderImg : orderImg2
                      }
                      alt="order"
                      className={`${styles.orderImg}`}
                    />
                  </div>
                  <div className={`ms-2`}>
                    <span className={`${styles.orderName}`}>
                      {itemData?.unitPrice?.currencyCode}$
                      {itemData?.unitPrice?.amount} x {itemData?.quantity}
                    </span>
                    <span className={`${styles.orderDesc}`}>
                      SKU: {itemData?.sku}
                    </span>
                  </div>
                </div>
                <span className={`${styles.subHeading}`}>
                  Choose to proceed
                </span>
                {item?.types?.map((returnType) => {
                  return (
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioCheck} ${
                          showError && "border-danger"
                        }`}
                        type="radio"
                        name={`returnType--${item.itemId}`}
                        id={`return type--${item.itemId}--${returnType.id}`}
                        disabled={returnType.hidden}
                        checked={matchedItem?.newReturnType === returnType.type}
                        onClick={() => {
                          handleOnChange(item.itemId, returnType.type);
                        }}
                      />
                      <label
                        className={`form-check-label ${styles.radioLabel}`}
                        htmlFor={`return type--${item.itemId}--${returnType.id}`}
                      >
                        {returnType.name}
                      </label>
                    </div>
                  );
                })}
                {item?.message?.trim()?.length > 0 && (
                  <div
                    className={`d-flex align-items-start mb-3 ${styles.cautionBox}`}
                  >
                    <img
                      src={cautionImg}
                      alt="caution"
                      width={13}
                      height={13}
                      className="mt-1"
                    />
                    <p className={`ps-1 mb-0`}>
                      <ParseVariablesWithValue message={item?.message ?? ""} />
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default ARSReturnType;
