import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./AllSentMessage.module.scss";
import { Dropdown } from "react-bootstrap";
import { useMemo, useState } from "react";
import MessageTable from "./Children/MessageTable";
import getAllSentMessages, {
  AllMessagesTableData,
  GetAllSentMessagesParams,
  IContentType,
} from "src/services/Campaign/getAllSentMessages.service";
import { useQuery } from "@tanstack/react-query";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import Loader from "src/components/Loader";
const LIMIT = 10;

const AllSentMessage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [contentType, setContentType] = useState<IContentType | null>(null);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return LIMIT * currentPage - LIMIT;
  }, [currentPage]);

  const params: GetAllSentMessagesParams = {
    filters: {
      ...(contentType ? { contentType } : {}),
      searchText: searchText,
    },
    limit: LIMIT,
    start: start,
  };

  const { data, isLoading, isError } = useQuery<AllMessagesTableData>(
    ["getAllSentMessages", params],
    () => {
      const response = getAllSentMessages({ ...params });
      return response;
    },
  );
  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalMessages ? Math.ceil(data?.totalMessages / LIMIT) : 0;
  }, [data?.totalMessages]);

  return (
    <div>
      <div className={`w-100 ${styles.shadowBox}`}>
        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
          <div className="d-flex align-items-center">
            <Dropdown
              show={isDropdownOpen}
              onToggle={setIsDropdownOpen}
            >
              <Dropdown.Toggle
                id="contentType"
                as="div"
                bsPrefix={`dropdown-toggle px-2 ${styles.dropdownBtn} ${
                  isDropdownOpen ? styles.toggledBtn : ""
                }`}
              >
                <span className={`${styles.filter}`}>
                  <i className="fa-solid fa-filter"></i>
                </span>
                <span className="px-1">
                  {contentType === IContentType.Drip
                    ? `Drip campaign`
                    : contentType === IContentType.OneTime
                      ? `One-time campaign`
                      : `Campaign type`}
                </span>
                <span
                  className={`${
                    isDropdownOpen ? styles.upArrow : styles.arrow
                  }`}
                >
                  <i
                    className={`fa-solid fa-sort-${
                      isDropdownOpen ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix={`dropdown-menu px-1 ${styles.dropMenu}`}>
                <Dropdown.Item
                  bsPrefix={`dropdown-item ${styles.dropItem}`}
                  onClick={() => {
                    setContentType(IContentType.Drip);
                    if (currentPage !== 1) {
                      setCurrentPage(1);
                    }
                  }}
                >
                  <div className="">
                    <span className={`ps-1 ${styles.element}`}>
                      Drip campaign
                    </span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  bsPrefix={`dropdown-item ${styles.dropItem}`}
                  onClick={() => {
                    setContentType(IContentType.OneTime);
                    if (currentPage !== 1) {
                      setCurrentPage(1);
                    }
                  }}
                >
                  <div>
                    <span className={`ps-1 ${styles.element}`}>
                      One-time campaign
                    </span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <SearchBar
            className={`mb-3 ${styles.search} px-2`}
            inputClassName={`${styles.input}`}
            placeholder={`search by customer name`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            value={searchText}
            onSearch={(value: string) => {
              setSearchText(value);
              if (currentPage !== 1) {
                setCurrentPage(1);
              }
            }}
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <div className="d-flex align-items-center justify-content-center text-small text-danger">
            Something went wrong!
          </div>
        ) : data?.allMessageIds && data?.allMessageIds.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center text-small">
            No Messages found
          </div>
        ) : (
          <div>
            <MessageTable data={data} />
            {totalPages > 0 ? (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSentMessage;
