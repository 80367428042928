import { axiosJSON } from "src/globals/axiosEndPoints";

export enum SegmentMessageType {
  Chat = "chat",
  Email = "email",
  // Survey = "survey",
  // Banner = "banner",
}

interface ISegmentMessage<T extends SegmentMessageType> {
  /**
   * Defines the type of the segment message, as given in SegmentMessageType.
   */
  type: T;

  /**
   * The body of the segment message, which may include HTML formatting for
   * enhanced styling.
   */
  message: string;

  /**
   * An optional array of attachment batch numbers, which corresponds to the
   * attachments sent along with the message. This is typically used when
   * there are associated files or media that need to be delivered with
   * the message.
   */
  attachmentBatchNumber?: string[];

  /**
   * The email address from which the email will be sent. This should be a valid
   * and authorized email address for the brand or sender.
   */
  from: string;
}

export interface ChatSegmentMessage
  extends ISegmentMessage<SegmentMessageType.Chat> {}

export interface EmailSegmentMessage
  extends ISegmentMessage<SegmentMessageType.Email> {
  /**
   * An optional "blind carbon copy" email address, where recipients will receive
   * the email without other recipients knowing.
   */
  bcc?: string[];

  /**
   * An optional "carbon copy" email address, where recipients will be visible
   * to all other recipients.
   */
  cc?: string[];

  /**
   * The subject line of the email, which typically summarizes the content
   * or purpose of the message. This will appear prominently in the recipient's
   * inbox.
   */
  subject: string;

  /**
   * A attachment batch number to the brand or company logo to be displayed in the email
   * be displayed in the email header. This is part of the email's design and branding elements.
   */
  logoAttachment: string;

  /**
   * A heading or title for the email, which is often shown at the top of the
   * email body as part of its structure. This is part of the email's design
   * and branding elements.
   */
  emailHeading: string;
}

export type SegmentMessage = EmailSegmentMessage | ChatSegmentMessage;

export interface SendNewMessageParams {
  /**
   * An array of segment IDs that represent the groups or individuals
   * the message will be sent to. Each segment ID should correspond
   * to a valid segment in the system.
   */
  segmentIds: string[];

  /**
   * An array containing the message details, where each item specifies
   * the type of message (either chat or email) and its corresponding data,
   * including the message body, attachments, and any relevant metadata.
   */
  messages: SegmentMessage[];
}

/**
 * Sends a new message to the specified segments using the provided payload.
 * The payload must include an array of segment IDs and a list of messages,
 * which can be of type "chat" or "email". In case of an error in the response,
 * an exception is thrown with a detailed error message.
 *
 * @param payload - The data required to send the new message, including the segment IDs and message details.
 * @throws Will throw an error if the response contains an error flag or message.
 */
const sendNewMessageService = async (payload: SendNewMessageParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/sendNewMessage`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }
};

export default sendNewMessageService;
