import SegmentTab from "./Children/SegmentTab/SegmentTab";
import styles from "./SegmentSection.module.scss";

export const SegmentSection = ({
  onBack,
}: {
  onBack: (id?: string | number) => void;
}) => {
  return (
    <div>
      <div className={`d-flex mb-3`}>
        <div
          className={`${styles.backBtn}`}
          onClick={() => onBack()}
        >
          <span>
            <i className="fa-solid fa-arrow-left"></i>
          </span>
        </div>
        <div className="ms-2">
          <h5 className={`mb-0 ${styles.heading}`}>All segments</h5>
          <span className={` ${styles.subHeading}`}>
            Browse and manage all active segments and their associated campaigns
          </span>
        </div>
      </div>
      <div className={`${styles.segmentWrapper}`}>
        <SegmentTab onBack={onBack} />
      </div>
    </div>
  );
};
