import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  assignMultipleAgents,
  IAssignMultipleAgentsRes,
} from "src/services/LiveChat/messageExchange/assignMultipleAgents";
import { Customer } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { actions } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.slice";
import { fetchCustomerDataByChatId } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  areAllAgentSelectedForAssignToSelector,
  removeAllAgentForAssignToSelect,
  resetAssignToAgentList,
  setActiveCustomerInfo,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./AssignChatTo.module.scss";
import AssignChatToAgentList from "./Children/AssignChatToAgentList/AssignChatToAgentList";
import UnassignModal from "./Children/UnAssignModal/UnassignModal";
import botLogo from "src/assets/images/botImg.png";
import { removeCustomerByChatIdCache } from "src/services/LiveChat/messageExchange/getCustomerByChatId";

function AssignChatTo() {
  const {
    agentIds,
    agents,
    assignToAgentListAJAXStatus,
    agentMeta,
    activeCustomerInfo,
    selectedAgentIds,
  } = useAppSelector((state) => state.chatWidExhMsg);

  const selectedAgents = useAppSelector((state) => {
    const agentIds =
      state.chatWidExhMsg.activeCustomerInfo?.chatAssignedTo &&
      state.chatWidExhMsg.activeCustomerInfo?.chatAssignedTo.length !== 0
        ? state.chatWidExhMsg.activeCustomerInfo?.chatAssignedTo.map(
            (agentInfo) => parseInt(agentInfo.id + ""),
          )
        : [];

    return agentIds;
  });

  const dispatch = useAppDispatch();

  const [showAgentModal, setShowAgentModal] = useState(false);
  const [showConfimationModal, setShowConfimationModal] = useState(false);
  const [unAssign, setUnAssign] = useState(false);

  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const targetRef = useRef(null);
  const currentState = useRef({
    activeCustomerInfo,
    selectedAgentIds,
  });

  useEffect(() => {
    currentState.current = {
      activeCustomerInfo,
      selectedAgentIds,
    };
  }, [activeCustomerInfo, selectedAgentIds]);

  const handleCancel = useCallback(() => {
    onHide();
    setShowAgentModal(false);
    dispatch(resetAssignToAgentList());
    dispatch(removeAllAgentForAssignToSelect({ initialValue: selectedAgents }));
  }, []);

  const onShow = useCallback(() => {
    setShowConfimationModal(true);
  }, []);

  const onHide = useCallback(() => {
    setShowConfimationModal(false);
  }, []);

  const onHideUnAssignModal = useCallback(() => {
    setUnAssign(false);
  }, []);

  const handleSubmit = useCallback(() => {
    const payloadAgentIds = currentState.current.selectedAgentIds;
    if (!currentState.current.activeCustomerInfo?.chatId) {
      return;
    }

    const chatId = currentState.current.activeCustomerInfo.chatId;
    setShowSaveLoader(true);
    assignMultipleAgents({
      chatId: chatId,
      agentIds: payloadAgentIds,
    })
      .then((res: IAssignMultipleAgentsRes) => {
        removeCustomerByChatIdCache(chatId);
        // if (res.statusCode === ) {
        const removedAgentIds = res.agentsRemoved;
        const addedAgents = res.agentsAdded;
        const chatAssignedTo = res.chatAssignedTo;

        let toastText = "Chat assigned!";

        if (addedAgents.length) {
          const newlyAddedAgents = chatAssignedTo.filter((agentInfo) => {
            return addedAgents.includes(agentInfo.id);
          });

          if (newlyAddedAgents.length === 1) {
            toastText = `Assigned to ${newlyAddedAgents[0]?.name}`;
          } else {
            toastText = `Assigned to ${newlyAddedAgents[0]?.name} and others`;
          }
        } else if (removedAgentIds.length) {
          if (chatAssignedTo.length === 0) {
            toastText = "Unassigned all agents!";
          } else {
            toastText = "Unassigned Successfully!";
          }
        } else {
          if (chatAssignedTo.length) {
            toastText = "Chat assigned!";
          } else {
            toastText = "Unassigned all agents!";
          }
        }

        pushTheToast({
          position: "top-right",
          type: "success",
          text: toastText,
        });

        if (currentState.current.activeCustomerInfo) {
          const customer: Customer = {
            ...currentState.current.activeCustomerInfo,
          };

          customer["chatAssignedTo"] = res.chatAssignedTo.map(
            (agentInfo: any) => {
              return {
                id: agentInfo?.id,
                name: agentInfo?.name,
                imgUrl: agentInfo?.imageURL,
              };
            },
          );

          dispatch(
            actions.setCustomerData({
              customer: customer,
              checkForExistingCust: true,
            }),
          );
          dispatch(setActiveCustomerInfo({ customerInfo: customer }));
        }
        dispatch(resetAssignToAgentList());
        setShowSaveLoader(false);
        setShowAgentModal(false);
        onHide();
        // }
      })
      .catch((err) => {
        setShowSaveLoader(false);
        pushTheToast({
          position: "top-right",
          type: "danger",
          text: "Something went wrong",
        });
        onHide();
      });
  }, [selectedAgentIds]);

  const handleOnApply = useCallback(() => {
    //todo validate if there is no change in data
    if (currentState.current.selectedAgentIds.length === 0) {
      setUnAssign(true);
    } else if (
      currentState.current.activeCustomerInfo?.chatAssignedTo &&
      currentState.current.activeCustomerInfo?.chatAssignedTo?.length !== 0
    ) {
      //chat has been already assigned to someone so show the confimation modal
      onShow();
    } else {
      handleSubmit();
    }
  }, [handleSubmit]);

  const renderAssingedAgentInfo = (assignedAgents: Array<any>) => {
    if (assignedAgents.length > 2) {
      const assignedAgent = assignedAgents[0];

      return (
        <>
          <span className={`position-relative ${styles.chatImg}`}>
            {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
              <AxiosImg
                url={assignedAgent.imgUrl}
                className={`${styles.profileImg}`}
                isDirectURL={assignedAgent.isPublicAttachmentUrl}
              />
            ) : (
              <UserAvatar
                name={
                  assignedAgent.name && assignedAgent.name !== ""
                    ? assignedAgent.name
                    : "NA"
                }
                size={32}
                className={`img-fluid`}
              />
            )}
          </span>
          <span className={`position-relative ${styles.chatImg}`}>
            <span
              className={`d-flex align-items-center justify-content-center ${styles.agentImg} ${styles.agentImg2} ${styles.plusImageIcon}`}
            >
              +{assignedAgents.length - 1}
            </span>
          </span>
        </>
      );
    } else if (assignedAgents.length === 2) {
      return (
        <>
          {assignedAgents.map((assignedAgent: any, index: number) => {
            return (
              <span
                className={`position-relative ${styles.agentImg} ${
                  index !== 0 ? styles.agentImg2 : ""
                }`}
              >
                {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
                  <AxiosImg
                    url={assignedAgent.imgUrl}
                    className={`img-fluid ${styles.profileImg}`}
                    isDirectURL={assignedAgent.isPublicAttachmentUrl}
                  />
                ) : (
                  <UserAvatar
                    name={
                      assignedAgent.name && assignedAgent.name !== ""
                        ? assignedAgent.name
                        : "NA"
                    }
                    size={32}
                    className={`img-fluid`}
                  />
                )}
              </span>
            );
          })}
        </>
      );
    } else {
      const assignedAgent = assignedAgents[0];

      return (
        <span className={`position-relative ${styles.agentImg}`}>
          {assignedAgent.imgUrl && assignedAgent.imgUrl.length !== 0 ? (
            <AxiosImg
              url={assignedAgent.imgUrl}
              className={`${styles.profileImg}`}
              isDirectURL={assignedAgent.isPublicAttachmentUrl}
            />
          ) : (
            <UserAvatar
              name={
                assignedAgent.name && assignedAgent.name !== ""
                  ? assignedAgent.name
                  : "NA"
              }
              size={32}
              className={`img-fluid`}
            />
          )}
        </span>
      );
    }
  };

  const concatinateAgentNames = useCallback((assignedAgents: any) => {
    return assignedAgents
      .map((agentInfo: any) => agentInfo.name ?? "NA")
      .join(", ");
  }, []);

  return (
    <div ref={targetRef}>
      <div
        className={`${
          activeCustomerInfo?.chatStatus === "chat_in_progress_ai" &&
          (activeCustomerInfo.chatAssignedTo?.length === 0 ||
            activeCustomerInfo.chatAssignedTo === null)
            ? styles.cernAssigned
            : styles.assignToMenu
        }  d-flex`}
        onClick={(e) => {
          setShowAgentModal(!showAgentModal);
          if (selectedAgents.length !== 0) {
            dispatch(
              removeAllAgentForAssignToSelect({ initialValue: selectedAgents }),
            );
          }
        }}
      >
        {activeCustomerInfo?.chatStatus === "chat_in_progress_ai" &&
        (activeCustomerInfo.chatAssignedTo?.length === 0 ||
          activeCustomerInfo.chatAssignedTo === null) ? (
          <div className={`d-flex`}>
            <div
              className="d-flex"
              style={{ flexShrink: "0" }}
            >
              <img
                src={botLogo}
                alt=""
                className={`${styles.botImg}`}
              />
            </div>
            <span className={`my-auto mx-2 ${styles.assignedAgentsName}`}>
              Cern
            </span>
          </div>
        ) : (
          <div className={`${styles.leftSection}`}>
            <div
              className="d-flex"
              style={{ flexShrink: "0" }}
            >
              {activeCustomerInfo?.chatAssignedTo &&
              activeCustomerInfo.chatAssignedTo.length !== 0 ? (
                renderAssingedAgentInfo(activeCustomerInfo?.chatAssignedTo)
              ) : (
                <span className={`position-relative ${styles.chatImg}`}>
                  <UserAvatar
                    name={"NA"}
                    size={20}
                    className="img-fluid"
                  />
                </span>
              )}
            </div>
            <span className={`my-auto mx-2 ${styles.assignedAgentsName}`}>
              {activeCustomerInfo?.chatAssignedTo &&
              activeCustomerInfo.chatAssignedTo.length
                ? concatinateAgentNames(activeCustomerInfo?.chatAssignedTo)
                : "NA"}
            </span>
          </div>
        )}

        <i
          className={`fa-solid d-block m-auto fa-chevron-${
            showAgentModal ? "up" : "down"
          } ${styles.arrowSpan}`}
        ></i>
      </div>
      <div className={`${styles.relative}`}>
        {showAgentModal && (
          <AssignChatToAgentList
            targetRef={targetRef}
            searchText={searchText}
            setSearchText={setSearchText}
            showSaveLoader={showSaveLoader}
            setShowSaveLoader={setShowSaveLoader}
            handleCancel={handleCancel}
            handleOnApply={handleOnApply}
            setShowAgentModal={setShowAgentModal}
            cernAssigned={
              activeCustomerInfo?.chatStatus === "chat_in_progress_ai" &&
              (activeCustomerInfo.chatAssignedTo?.length === 0 ||
                activeCustomerInfo.chatAssignedTo === null)
            }
          />
        )}
      </div>
      <Modal
        className={``}
        show={showConfimationModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog2}`}
        contentClassName={`${styles.modalContent2}`}
      >
        <div className={`text-center `}>
          <h3 className={`mb-2 mb-lg-3 ${styles.modalHead}`}>
            Someone is already assigned to this conversation
          </h3>
          <span className={`d-inline-block mb-2 ${styles.modalSpan}`}>
            Would you like to add more agents to this conversation ?
          </span>
          <div
            className={`d-flex flex-column flex-md-row justify-content-center align-items-center mt-3 pt-2`}
          >
            <button
              className={`bg-white me-0 me-md-4 mb-2 mb-md-0 text-center ${styles.cancelBtn}`}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={`border-0 text-center ${styles.saveBtn}`}
              onClick={handleSubmit}
            >
              {showSaveLoader === true ? (
                <Spinner
                  className="my-auto mx-1"
                  animation="border"
                  color="white"
                  size="sm"
                />
              ) : (
                "Assign"
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/* Note to frontend developers :- Use below modal for implemetation of confimation for all unassignee in filters  */}
      <Modal
        className={``}
        show={unAssign}
        onHide={onHideUnAssignModal}
        dialogClassName={`${styles.modalUnassignDialog}`}
        contentClassName={`${styles.modalunassignContent}`}
      >
        <UnassignModal onHide={onHideUnAssignModal} />
      </Modal>
    </div>
  );
}
export default AssignChatTo;
