import ParseHTML from "src/components/ParseHTML";

const string = `<p>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><meta
    charset="utf-8"
  /><meta http-equiv="X-UA-Compatible" content="IE=edge" /><meta
    name="viewport"
    content="width=device-width, initial-scale=1"
  /><meta name="description" /><meta name="author" /> <title>Asana</title>
  <span
    style="
      color: transparent;
      visibility: hidden;
      display: none;
      opacity: 0;
      height: 0;
      width: 0;
      font-size: 0;
    "
    >Get a 30-day free trial of Asana Premium.</span
  ><img
    src="https://connect.asana.com/e/eo?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=yLqLmN-89cQA4_reKCeMDrzBXeBAL9Pl2jan-HAbExCFsHUiJ-ajCKWFQBHYlQ7adiX3zJgYQeBX18wQkVG_a_Vn7JwtWpIP_XSgOtUMgiCj-Rh6eOaNvhen4m7S5Ze_4Xx3ZkCVbK60YZ9MRWGsUr_RUB_TFH5jXf689V8f2n30sISabi1Qao14mCttXByh"
    style="
      border: 0;
      width: 1px;
      height: 1px;
      border-width: 0px !important;
      display: none !important;
      line-height: 0 !important;
    "
    width="1"
    height="1"
  /><style type="text/css">
    @media screen and (max-device-width: 716px) {
      .small-float-center {
        margin: 0 auto !important;
        float: none !important;
        text-align: center !important;
      }
      .hide-for-mobile {
        display: none !important;
        max-height: 0 !important;
        overflow: hidden !important;
        width: 0 !important;
        height: 0 !important;
      }
      .hide-for-desktop {
        display: block !important;
        max-height: auto !important;
        overflow: auto !important;
        width: auto !important;
        height: auto !important;
      }
      .show-for-mobile {
        display: table !important;
      }
      .text-adjust-two-tile,
      p[class="text-adjust-two-tile"],
      p.text-adjust-two-tile {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      .padding-fix-logo {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      .small-text-center {
        text-align: center !important;
      }
      .small-text-left {
        text-align: left !important;
      }
      .small-text-right {
        text-align: right !important;
      }
      .hide-for-large {
        display: block !important;
        width: auto !important;
        overflow: visible !important;
        max-height: none !important;
        font-size: inherit !important;
        line-height: inherit !important;
      }
      table.body table.container .hide-for-large {
        display: table !important;
        width: 100% !important;
      }
      table.body table.container .row.hide-for-large {
        display: table !important;
        width: 100% !important;
      }
      table.body table.container .callout-inner.hide-for-large {
        display: table-cell !important;
        width: 100% !important;
      }
      table.body table.container .show-for-large {
        display: none !important;
        width: 0;
        overflow: hidden;
      }
      .menu.small-vertical .menu-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body img {
        width: auto;
        height: auto;
      }
      table.body center {
        min-width: 0 !important;
      }
      table.body .container {
        width: 95% !important;
      }
      table.body .columns {
        height: auto !important;
        box-sizing: border-box;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
      table.body .column {
        height: auto !important;
        box-sizing: border-box;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
      table.body .columns .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body .columns .columns {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body .column .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body .column .columns {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body .collapse .columns {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.body .collapse .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      td.small-1 {
        display: inline-block !important;
        width: 8.33333% !important;
      }
      th.small-1 {
        display: inline-block !important;
        width: 8.33333% !important;
      }
      td.small-2 {
        display: inline-block !important;
        width: 16.66667% !important;
      }
      th.small-2 {
        display: inline-block !important;
        width: 16.66667% !important;
      }
      td.small-3 {
        display: inline-block !important;
        width: 25% !important;
      }
      th.small-3 {
        display: inline-block !important;
        width: 25% !important;
      }
      td.small-4 {
        display: inline-block !important;
        width: 33.33333% !important;
      }
      th.small-4 {
        display: inline-block !important;
        width: 33.33333% !important;
      }
      td.small-5 {
        display: inline-block !important;
        width: 41.66667% !important;
      }
      th.small-5 {
        display: inline-block !important;
        width: 41.66667% !important;
      }
      td.small-6 {
        display: inline-block !important;
        width: 50% !important;
      }
      th.small-6 {
        display: inline-block !important;
        width: 50% !important;
      }
      td.small-7 {
        display: inline-block !important;
        width: 58.33333% !important;
      }
      th.small-7 {
        display: inline-block !important;
        width: 58.33333% !important;
      }
      td.small-8 {
        display: inline-block !important;
        width: 66.66667% !important;
      }
      th.small-8 {
        display: inline-block !important;
        width: 66.66667% !important;
      }
      td.small-9 {
        display: inline-block !important;
        width: 75% !important;
      }
      th.small-9 {
        display: inline-block !important;
        width: 75% !important;
      }
      td.small-10 {
        display: inline-block !important;
        width: 83.33333% !important;
      }
      th.small-10 {
        display: inline-block !important;
        width: 83.33333% !important;
      }
      td.small-11 {
        display: inline-block !important;
        width: 91.66667% !important;
      }
      th.small-11 {
        display: inline-block !important;
        width: 91.66667% !important;
      }
      td.small-12 {
        display: inline-block !important;
        width: 100% !important;
      }
      th.small-12 {
        display: inline-block !important;
        width: 100% !important;
      }
      td.small-mw85 {
        display: inline-block !important;
        max-width: 84% !important;
      }
      .columns td.small-12 {
        display: block !important;
        width: 100% !important;
      }
      .column td.small-12 {
        display: block !important;
        width: 100% !important;
      }
      .columns th.small-12 {
        display: block !important;
        width: 100% !important;
      }
      .column th.small-12 {
        display: block !important;
        width: 100% !important;
      }
      table.button.small-expand table a {
        text-align: center !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.button.small-expanded table a {
        text-align: center !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      table.button.small-expand center {
        min-width: 0;
      }
      table.button.small-expanded center {
        min-width: 0;
      }
      .show-for-large {
        display: none !important;
        width: 0;
        overflow: hidden;
      }
      .hide-for-large {
        display: block !important;
        width: auto !important;
        overflow: visible !important;
        max-height: none !important;
        font-size: inherit !important;
        line-height: inherit !important;
      }
      .margin-auto-small {
        margin: 0 auto;
      }
      .padding-mobile-adjust {
        padding: 30px 0 36px 0 !important;
      }
      .padding-mobile-adjust-image-block {
        padding: 0 0 30px 0 !important;
      }
      .mw50 {
        max-width: 49% !important;
      }
      .padding-template-mobile {
        padding: 0 15px !important;
      }
      .txtcntr {
        text-align: center !important;
      }
    }
    .hide-for-desktop {
      display: none;
      max-height: 0;
      overflow: hidden;
      width: 0;
      height: 0;
    }
    @media screen and (max-device-width: 716px) {
      table.body td.small-offset-1 {
        margin-left: 8.33333% !important;
      }
      table.body th.small-offset-1 {
        margin-left: 8.33333% !important;
      }
      table.body td.small-offset-2 {
        margin-left: 16.66667% !important;
      }
      table.body th.small-offset-2 {
        margin-left: 16.66667% !important;
      }
      table.body td.small-offset-3 {
        margin-left: 25% !important;
      }
      table.body th.small-offset-3 {
        margin-left: 25% !important;
      }
      table.body td.small-offset-4 {
        margin-left: 33.33333% !important;
      }
      table.body th.small-offset-4 {
        margin-left: 33.33333% !important;
      }
      table.body td.small-offset-5 {
        margin-left: 41.66667% !important;
      }
      table.body th.small-offset-5 {
        margin-left: 41.66667% !important;
      }
      table.body td.small-offset-6 {
        margin-left: 50% !important;
      }
      table.body th.small-offset-6 {
        margin-left: 50% !important;
      }
      table.body td.small-offset-7 {
        margin-left: 58.33333% !important;
      }
      table.body th.small-offset-7 {
        margin-left: 58.33333% !important;
      }
      table.body td.small-offset-8 {
        margin-left: 66.66667% !important;
      }
      table.body th.small-offset-8 {
        margin-left: 66.66667% !important;
      }
      table.body td.small-offset-9 {
        margin-left: 75% !important;
      }
      table.body th.small-offset-9 {
        margin-left: 75% !important;
      }
      table.body td.small-offset-10 {
        margin-left: 83.33333% !important;
      }
      table.body th.small-offset-10 {
        margin-left: 83.33333% !important;
      }
      table.body td.small-offset-11 {
        margin-left: 91.66667% !important;
      }
      table.body th.small-offset-11 {
        margin-left: 91.66667% !important;
      }
      table.body table.columns td.expander {
        display: none !important;
      }
      table.body table.columns th.expander {
        display: none !important;
      }
      table.body .right-text-pad {
        padding-left: 10px !important;
      }
      table.body .text-pad-right {
        padding-left: 10px !important;
      }
      table.body .left-text-pad {
        padding-right: 10px !important;
      }
      table.body .text-pad-left {
        padding-right: 10px !important;
      }
      table.menu {
        width: 100% !important;
      }
      table.menu td {
        width: auto !important;
        display: inline-block !important;
      }
      table.menu th {
        width: auto !important;
        display: inline-block !important;
      }
      table.menu.vertical td {
        display: block !important;
      }
      table.menu.vertical th {
        display: block !important;
      }
      table.menu.small-vertical td {
        display: block !important;
      }
      table.menu.small-vertical th {
        display: block !important;
      }
      table.menu[align="center"] {
        width: auto !important;
      }
      table.button.small-expand {
        width: 100% !important;
      }
      table.button.small-expanded {
        width: 100% !important;
      }
      table.button.small-expand table {
        width: 100%;
      }
      table.button.small-expanded table {
        width: 100%;
      }
    }
    @media (min-width: 600px) {
      html {
        font-size: 13px;
      }
    }
    @media (min-width: 800px) {
      html {
        font-size: 14px;
      }
    }
    @media (min-width: 716px) {
      .w-card-block-text-2 {
        padding: 25px 30px 0;
      }
      .w-card:first-of-type {
        padding-right: 30px !important;
      }
      .w-card:nth-of-type(2) {
        padding-left: 30px !important;
      }
    }
    @media screen and (min-width: 716px) {
      .w-purple .wrapper-inner {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }
    @media screen and (max-width: 480px) {
      .imglink {
        max-width: 100% !important;
        width: 100% !important;
      }
      .mw100 {
        max-width: 100% !important;
      }
      .mw50 {
        max-width: 49% !important;
      }
      .mw55 {
        max-width: 55% !important;
      }
      .mw70 {
        max-width: 68% !important;
      }
      .mw30 {
        max-width: 30% !important;
      }
      .mw50 {
        max-width: 49% !important;
      }
      .mw60 {
        max-width: 60% !important;
      }
      .mw35 {
        max-width: 35% !important;
      }
      .w100 {
        width: 100% !important;
      }
      .w92 {
        width: 92% !important;
      }
      .w80 {
        width: 80% !important;
      }
      .txtcntr {
        text-align: center !important;
      }
      .txtlt {
        text-align: left !important;
      }
      .noPadLR {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .mbl-center {
        margin: 0 auto !important;
        float: none !important;
      }
      .mblPct {
        font-size: 120px !important;
        line-height: 120px !important;
      }
      .mblRise {
        font-size: 60px !important;
        line-height: 0 !important;
        vertical-align: 35px !important;
      }
      .mblHL {
        font-size: 30px !important;
      }
      table[class="center"],
      td[class="center"] {
        width: 100% !important;
        height: auto !important;
        text-align: center !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .hide {
        display: none !important;
      }
      div[class="mobilecontent"],
      .mobilecontent {
        display: block !important;
        max-height: none !important;
      }
    }
    @font-face {
      font-family: "Gordita";
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.eot");
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.eot?#iefix")
          format("embedded-opentype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.woff2")
          format("woff2"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.woff")
          format("woff"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.ttf")
          format("truetype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Regular.svg#Gordita-Regular")
          format("svg");
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: "Gordita";
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.eot");
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.eot?#iefix")
          format("embedded-opentype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.woff2")
          format("woff2"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.woff")
          format("woff"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.ttf")
          format("truetype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Light.svg#Gordita-Light")
          format("svg");
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: "Gordita";
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.eot");
      src: url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.eot?#iefix")
          format("embedded-opentype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.woff2")
          format("woff2"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.woff")
          format("woff"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.ttf")
          format("truetype"),
        url("https://fonts.luna1.co/gordita/hinted/hinted-Gordita-Medium.svg#Gordita-Medium")
          format("svg");
      font-weight: 500;
      font-style: normal;
    }
  </style>
  <!--[if mso
    ]><style type="text/css">
      body,
      table,
      td {
        font-family: "Gordita", Helvetica, Arial, sans-serif !important;
      }
      a {
        text-decoration: none;
      }
    </style><!
  [endif]-->
</p>

<table
  align="center"
  cellspacing="0"
  style="
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    font-family: helvetica, arial, sans-serif !important;
    margin: 0 auto;
    max-width: 600px;
    padding: 0;
    position: relative;
    text-align: inherit;
    vertical-align: top;
  "
  class="container"
>
  <tbody
    style="
      position: relative;
      box-sizing: border-box;
      font-family: Helvetica, Arial, sans-serif !important;
    "
  >
    <tr
      align="left"
      style="
        box-sizing: border-box;
        font-family: Helvetica, Arial, sans-serif !important;
        padding: 0;
        position: relative;
        text-align: left;
        vertical-align: top;
      "
    >
      <td
        align="left"
        style="
          -moz-hyphens: none;
          -webkit-hyphens: none;
          border-collapse: collapse !important;
          box-sizing: border-box;
          color: #0a0a0a;
          font-family: Helvetica, Arial, sans-serif !important;
          font-size: 16px;
          font-weight: normal;
          hyphens: none;
          line-height: 130%;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: left;
          vertical-align: top;
          word-wrap: break-word;
        "
      >
        <!-- ################################################### PREHEADER (view online) ################################################# -->
        <table
          align="center"
          cellspacing="0"
          style="
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            font-family: helvetica, arial, sans-serif !important;
            margin: 0;
            padding: 0;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 100%;
          "
          class="preheader w-no-margin w-no-padding wrapper"
        >
          <tbody></tbody>
        </table>
        <!-- ################################################### HEADER ################################################# -->
        <table
          align="center"
          cellspacing="0"
          style="
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            font-family: helvetica, arial, sans-serif !important;
            padding: 0;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 100%;
          "
          class="header w-outer wrapper"
        >
          <tbody>
            <tr
              align="left"
              style="
                box-sizing: border-box;
                font-family: Helvetica, Arial, sans-serif !important;
                padding: 0;
                position: relative;
                text-align: left;
                vertical-align: top;
              "
            >
              <td
                align="left"
                style="
                  -moz-hyphens: none;
                  -webkit-hyphens: none;
                  border-collapse: collapse !important;
                  box-sizing: border-box;
                  color: #0a0a0a;
                  font-family: Helvetica, Arial, sans-serif !important;
                  font-size: 16px;
                  font-weight: normal;
                  hyphens: none;
                  line-height: 130%;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  text-align: left;
                  vertical-align: top;
                  word-wrap: break-word;
                "
                class="wrapper-inner"
              >
                <table
                  align="center"
                  bgcolor="#fff"
                  cellspacing="0"
                  style="
                    background: #ffffff;
                    border-collapse: collapse;
                    border-spacing: 0;
                    box-sizing: border-box;
                    font-family: helvetica, arial, sans-serif !important;
                    margin: 0;
                    padding: 35px 0 0;
                    position: relative;
                    text-align: left;
                    vertical-align: top;
                    width: 100%;
                  "
                  class="header w-grey w-inner w-no-margin w-no-padding wrapper"
                >
                  <tbody>
                    <tr
                      align="left"
                      style="
                        box-sizing: border-box;
                        font-family: Helvetica, Arial, sans-serif !important;
                        padding: 0;
                        position: relative;
                        text-align: left;
                        vertical-align: top;
                      "
                    >
                      <td
                        align="left"
                        style="
                          -moz-hyphens: none;
                          -webkit-hyphens: none;
                          border-collapse: collapse !important;
                          box-sizing: border-box;
                          color: #0a0a0a;
                          font-family: Helvetica, Arial, sans-serif !important;
                          font-size: 16px;
                          font-weight: normal;
                          hyphens: none;
                          line-height: 130%;
                          margin: 0;
                          padding: 0;
                          position: relative;
                          text-align: left;
                          vertical-align: top;
                          word-wrap: break-word;
                        "
                        class="wrapper-inner"
                      >
                        <table
                          cellspacing="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: helvetica, arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <th
                                align="center"
                                style="
                                  max-width: 684px;
                                  text-align: center;
                                  color: #0a0a0a;
                                  font-family: Helvetica, Arial, sans-serif !important;
                                  font-weight: normal;
                                  line-height: 130%;
                                  font-size: 16px;
                                  position: relative;
                                  box-sizing: border-box;
                                  margin: 0 auto;
                                  padding: 40px;
                                "
                                class="w-logo text-center small-12 large-12 columns first last"
                              >
                                <table
                                  cellspacing="0"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    box-sizing: border-box;
                                    font-family: helvetica, arial, sans-serif !important;
                                    padding: 0;
                                    position: relative;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr
                                      align="left"
                                      style="
                                        box-sizing: border-box;
                                        font-family: Helvetica, Arial,
                                          sans-serif !important;
                                        padding: 0;
                                        position: relative;
                                        text-align: left;
                                        vertical-align: top;
                                      "
                                    >
                                      <th
                                        align="center"
                                        style="
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: center;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                      >
                                        <span 
                                          ><span style="display: inline-block"
                                            ><img
                                              alt="Asana"
                                              border="0"
                                              width="150"
                                              style="
                                                box-sizing: border-box;
                                                clear: both;
                                                display: inline-block;
                                                max-width: 100%;
                                                outline: none;
                                                position: relative;
                                                text-decoration: none;
                                                font-family: helvetica, arial,
                                                  sans-serif !important;
                                                width: 160px;
                                                border-width: 0px;
                                                border-style: solid;
                                              "
                                              class="img-logo"
                                              height="30"
                                              src="https://resources.asana.com/rs/784-XZD-582/images/Asana-Logo-Horizontal-Coral-Black.png" /></span
                                        ></span>
                                      </th>
                                      <th
                                        align="center"
                                        style="
                                          visibility: hidden;
                                          width: 0;
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: center;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                        class="expander"
                                      ></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ################################################### HERO BLOCK 01 ################################################# -->
        <table
          align="center"
          cellspacing="0"
          style="
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif !important;
            padding: 0;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 100%;
          "
          class="hero-block-1 w-outer wrapper"
        >
          <tbody>
            <tr
              align="left"
              style="
                box-sizing: border-box;
                font-family: Helvetica, Arial, sans-serif !important;
                padding: 0;
                position: relative;
                text-align: left;
                vertical-align: top;
              "
            >
              <td
                align="left"
                style="
                  -moz-hyphens: none;
                  -webkit-hyphens: none;
                  border-collapse: collapse !important;
                  box-sizing: border-box;
                  color: #0a0a0a;
                  font-family: Helvetica, Arial, sans-serif !important;
                  font-size: 16px;
                  font-weight: normal;
                  hyphens: none;
                  line-height: 130%;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  text-align: left;
                  vertical-align: top;
                  word-wrap: break-word;
                "
                class="wrapper-inner"
              >
                <table
                  align="center"
                  bgcolor="#fff"
                  border="1"
                  cellspacing="0"
                  style="
                    background: #ffffff;
                    border-collapse: collapse;
                    border-spacing: 0;
                    border: 1px solid #eff0f1;
                    box-sizing: border-box;
                    font-family: Helvetica, Arial, sans-serif !important;
                    padding: 35px 0 0;
                    position: relative;
                    text-align: center;
                    vertical-align: top;
                    width: 100%;
                  "
                  class="hero-block-1 text-center w-inner wrapper"
                >
                  <tbody>
                    <tr
                      align="left"
                      style="
                        box-sizing: border-box;
                        font-family: Helvetica, Arial, sans-serif !important;
                        padding: 0;
                        position: relative;
                        text-align: left;
                        vertical-align: top;
                      "
                    >
                      <td
                        align="left"
                        style="
                          -moz-hyphens: none;
                          -webkit-hyphens: none;
                          border-collapse: collapse !important;
                          box-sizing: border-box;
                          color: #0a0a0a;
                          font-family: Helvetica, Arial, sans-serif !important;
                          font-size: 16px;
                          font-weight: normal;
                          hyphens: none;
                          line-height: 130%;
                          margin: 0;
                          padding: 35px 0 36px;
                          position: relative;
                          text-align: left;
                          vertical-align: top;
                          word-wrap: break-word;
                        "
                        class="wrapper-inner"
                      >
                        <table
                          cellspacing="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: Helvetica, Arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <th
                                align="left"
                                text-center=""
                                style="
                                  max-width: 684px;
                                  color: #0a0a0a;
                                  font-family: Helvetica, Arial, sans-serif !important;
                                  font-weight: normal;
                                  text-align: left;
                                  line-height: 130%;
                                  font-size: 16px;
                                  position: relative;
                                  box-sizing: border-box;
                                  margin: 0 auto;
                                  padding: 0 33px;
                                "
                                class="w-text-spacing small-12 large-12 columns first last"
                              >
                                <table
                                  cellspacing="0"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    box-sizing: border-box;
                                    font-family: Helvetica, Arial, sans-serif !important;
                                    padding: 0;
                                    position: relative;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr
                                      align="left"
                                      style="
                                        box-sizing: border-box;
                                        font-family: Helvetica, Arial,
                                          sans-serif !important;
                                        padding: 0;
                                        position: relative;
                                        text-align: left;
                                        vertical-align: top;
                                      "
                                    >
                                      <th
                                        align="left"
                                        style="
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                      >
                                        <h1
                                          align="center"
                                          style="
                                            color: inherit;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                            font-weight: normal;
                                            text-align: center;
                                            line-height: 130%;
                                            word-wrap: normal;
                                            font-size: 30px;
                                            position: relative;
                                            box-sizing: border-box;
                                            margin: 0;
                                            padding: 0;
                                          "
                                          class="hl"
                                        >
                                          Premium is powerful and
                                          productive—just like you!
                                        </h1>
                                      </th>
                                      <th
                                        align="left"
                                        style="
                                          visibility: hidden;
                                          width: 0;
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                        class="expander"
                                      ></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          cellspacing="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: Helvetica, Arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <td align="center">
                                <img
                                    align="center"
                                    alt=""
                                    border="0"
                                    style="
                                      outline: none;
                                      text-decoration: none;
                                      width: 100%;
                                      max-width: 600px;
                                      clear: both;
                                      display: inline-block;
                                      text-align: center;
                                      position: relative;
                                      box-sizing: border-box;
                                      margin: 0px auto;
                                      border-width: 0px;
                                      border-style: solid;
                                      font-family: Helvetica, Arial, sans-serif !important;
                                    "
                                    class="img-hero-block-1 float-center"
                                    src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/0787efa0fd7c4f6592eaa6bd406f389d/Signups_8_Asana_Premium_2x.png"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        &nbsp;
                        <table
                          cellspacing="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: Helvetica, Arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <th
                                align="left"
                                style="
                                  max-width: 684px;
                                  color: #0a0a0a;
                                  font-family: Helvetica, Arial, sans-serif !important;
                                  font-weight: normal;
                                  text-align: left;
                                  line-height: 130%;
                                  font-size: 16px;
                                  position: relative;
                                  box-sizing: border-box;
                                  margin: 0 auto;
                                  padding: 0 33px;
                                "
                                class="w-text-spacing small-12 large-12 columns first last"
                              >
                                <table
                                  cellspacing="0"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    box-sizing: border-box;
                                    font-family: Helvetica, Arial, sans-serif !important;
                                    padding: 0;
                                    position: relative;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr
                                      align="left"
                                      style="
                                        box-sizing: border-box;
                                        font-family: Helvetica, Arial,
                                          sans-serif !important;
                                        padding: 0;
                                        position: relative;
                                        text-align: left;
                                        vertical-align: top;
                                      "
                                    >
                                      <th
                                        align="left"
                                        style="
                                          color: rgb(10, 10, 10);
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0px;
                                          padding: 0px;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                        "
                                      >
                                        <p
                                          align="left"
                                          style="
                                            font-weight: normal;
                                            color: rgb(103, 109, 118);
                                            text-align: left;
                                            line-height: 1.38;
                                            font-size: 18px;
                                            position: relative;
                                            box-sizing: border-box;
                                            margin: 0px 0px 10px;
                                            padding: 0px;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                          "
                                          class="text-light-grey spacing-xlarge"
                                        >
                                          Congrats on learning Asana! Now you're
                                          ready for Premium.
                                          <strong
                                            ><span
                                              style="
                                                text-decoration: none;
                                                color: rgb(103, 109, 118);
                                              "
                                             
                                              >Start a 30-day free trial</span
                                            >&nbsp;</strong
                                          >to unlock more powerful features and
                                          turn your work into
                                          a&nbsp;work<em>flow</em>.<br />
                                          <br />
                                          <strong
                                            >Here's a peek at Premium:</strong
                                          >
                                        </p>
                                        <ul
                                          style="
                                            color: rgb(103, 109, 118);
                                            text-align: left;
                                            line-height: 1.38;
                                            font-size: 18px;
                                            position: relative;
                                            box-sizing: border-box;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                          "
                                          class="text-light-grey spacing-xlarge"
                                        >
                                          <li style="font-weight: normal">
                                            <strong
                                              ><span
                                                style="
                                                  text-decoration: none;
                                                  color: rgb(103, 109, 118);
                                                "
                                                href="https://connect.asana.com/u/click?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=IBO1jG5LebbzZr4E9fDyH5L5gA6PUmrbtA3__QtnJLk-Bgp4JzcWSsnlnOg3DeO-84ZR7zhsw73o6uDboLToojs6RkTeB_LHPIX22rxmmd_hxNS8iFp8bzD8lHMWY-uV60PdKEdv8U20jqUUr1paHKNDhK7WNurIT43bl3X-uO8bPEJZW1Eq05T0v74KB_chrs1JCShGM40zWnoMu4kXCW5ylQzmN9zlWWvXaYVeOMertob_L4HHjJgi7IocwyNkOkBfnnLF6Ra5oNcHVMTQq70cwtrOVW-Dt8rZ70UX8ZcuSRI07szEp5Epgath4DEyxB2TXo7pUKX36X7p73iItAAmUDwOUmEKpr7g3XOpedltQJwF6ivHnLrhTsAK2TgWvi3xa7w8qx4mmizAk3ftY3KyUG4_6WgT8KGmyRoJVVsFkkSqaUBdwoSJP7ji1vjJ"
                                                >Project privacy</span
                                              ></strong
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <strong
                                              ><span
                                                style="
                                                  color: rgb(103, 109, 118);
                                                  text-decoration: none;
                                                "
                                                href="#"
                                                >Custom fields</span
                                              ></strong
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <span
                                              style="
                                                color: rgb(103, 109, 118);
                                                text-decoration: none;
                                              "
                                              href="https://connect.asana.com/u/click?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=IBO1jG5LebbzZr4E9fDyH5L5gA6PUmrbtA3__QtnJLkhw3nawZkchncgP-iO-k4CkP0Mrhm4R8_Zb8LLQnLX8VwHkPNiz_oHmjDyogVQprVfUgtM29Iphw_pDcTVStk48x3U9BEZUHcmryLT63IGKYZGu2FUlB48T5l9uTCwYaAO_eSLfVuHhgw22DjEtHUxt1VWNQuzCQfIXBWKHIyy68u2zh2hRDU8X9tZ-Hl-Gze1XhpMRnxdzVldNKWMEbG1wKTj6OyYDWv7bgFnY7appbWHREADFFO27cBMF4sZp6Ti4ZrlynvHpRAzBuCPRQoyxzBnOXmvxwOziwnP2u2a9bcEqWEvoubvD_B0abmlljeYH9RUS4aKm9QakxnoiaDyRr5BF3ZNm39OEvuC9ptm_MgLTetNNhnuncGHMCP08Yo%3D"
                                              ><strong>Dependencies</strong></span
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <span
                                              style="
                                                color: rgb(103, 109, 118);
                                                text-decoration: none;
                                              "
                                              href="#"
                                              ><strong
                                                >Custom templates</strong
                                              ></span
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <span
                                              style="
                                                color: rgb(103, 109, 118);
                                                text-decoration: none;
                                              "
                                              href="https://connect.asana.com/u/click?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=IBO1jG5LebbzZr4E9fDyH5L5gA6PUmrbtA3__QtnJLnX-gKnabwJvw_nvo8M8OH571BRT5nXpoudeqLXuHz2y44qG3p8fARye3QAJmS3imnjE8wjZ6tlRi1JAtfXA_Ue0yC5DYe4YZRiOeqa4VsZ77d_1hz-yhVf8ND0WEWfl5uSmUYMLpq6V8-ZI1WfSB69wDl7HfT03DGl9UWtDFdZua1myG9-0h2AP70Wb1pYkCCBdgslBW_gtJizTqAw6hfOxaTFS2oFVcrPV-zt8lw_xSqIzx-yXZzHhgOarQmoIi42sYuoyKz6IKxOJIH9K26ptIrAiFpzsR4tA4aUZA_f62AlOirlHu4qz1FYQ7v5NEkdoXmGCkVx6aUZ67tUIiCGJ1Hvjlv8sP4sHQdcQ0_Agkd1r5xK_q__YVizq3R8JKI%3D"
                                              ><strong>Start dates</strong></span
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <span
                                              style="
                                                text-decoration: none;
                                                color: rgb(103, 109, 118);
                                              "
                                              href="#"
                                              ><strong
                                                >Comment-only projects</strong
                                              ></span
                                            >
                                          </li>
                                          <li style="">
                                            <span
                                              style="
                                                color: rgb(103, 109, 118);
                                                text-decoration: none;
                                              "
                                              target="_blank"
                                              href="https://connect.asana.com/u/click?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=IBO1jG5LebbzZr4E9fDyHzyzOTVGvVFiYSZP9d0pHmi0DM53GzjNEGjPOuli2PmAr3zsNsFAoL16JIVaxoTG3z52cBPr8xqT4xK2uRUpP5hX9HqWO5SsYhDOziBAKd02oVgCZcOWAJ5R96XTjT_YvPS4uUxQIkMWg5_C70kLxsNIKCwCOR_kVV3CLIoaaufW3-xetwhVNjYuu4MGW58C0v-xRm9S7r32jkjajaearP5h1H-JY-KlrB7bg-v1tFokOts1jdWFG1mlt-Ly4HYIkXYiD5ToHi_hiw8crNd_GWjHZVM5tHIrcOjFNQtasSgSQuizR-CtkBk7E6Azq98dTSsQ8GootXoZsiXXy37_CgGaE1Ku-LxH0gfPJUFU7wk8"
                                              >Timeline</span
                                            >
                                          </li>
                                          <li style="font-weight: normal">
                                            <strong
                                              ><span
                                                style="
                                                  color: rgb(103, 109, 118);
                                                  text-decoration: none;
                                                "
                                                href="#"
                                                >Much more!</span
                                              ></strong
                                            >
                                          </li>
                                        </ul>
                                      </th>
                                      <th
                                        align="left"
                                        style="
                                          visibility: hidden;
                                          width: 0;
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                        class="expander"
                                      ></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          cellspacing="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: Helvetica, Arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <th
                                align="left"
                                style="
                                  max-width: 684px;
                                  color: #0a0a0a;
                                  font-family: Helvetica, Arial, sans-serif !important;
                                  font-weight: normal;
                                  text-align: left;
                                  line-height: 130%;
                                  font-size: 16px;
                                  position: relative;
                                  box-sizing: border-box;
                                  margin: 0 auto;
                                  padding: 0 16px 16px;
                                "
                                class="small-12 large-12 columns first last"
                              >
                                <table
                                  cellspacing="0"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    box-sizing: border-box;
                                    font-family: Helvetica, Arial, sans-serif !important;
                                    padding: 0;
                                    position: relative;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr
                                      align="left"
                                      style="
                                        box-sizing: border-box;
                                        font-family: Helvetica, Arial,
                                          sans-serif !important;
                                        padding: 0;
                                        position: relative;
                                        text-align: left;
                                        vertical-align: top;
                                      "
                                    >
                                      <th
                                        align="left"
                                        style="
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                      >
                                        <div
                                          align="center"
                                          style="
                                            position: relative;
                                            box-sizing: border-box;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                            text-align: center;
                                          "
                                          class="text-center"
                                        >
                                          <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="#" style="height:48px;v-text-anchor:middle;width:226px;" arcsize="7%" stroke="f" fillcolor="#7968FF"> <w:anchorlock></w:anchorlock> <center><!
                                          [endif]--><span
                                            style="
                                              border-radius: 3px;
                                              color: #ffffff;
                                              display: inline-block;
                                              font-size: 18px;
                                              font-weight: bold;
                                              line-height: 48px;
                                              text-align: center;
                                              text-decoration: none;
                                              width: 100%;
                                              max-width: 226px;
                                              -webkit-text-size-adjust: none;
                                              font-family: Helvetica, Arial,
                                                sans-serif !important;
                                              position: relative;
                                              box-sizing: border-box;
                                              background: #7968ff;
                                              padding: 0;
                                            "
                                            class="text-center"
                                           
                                            >Start My Trial</span
                                          >
                                          <!--[if mso]></center> </v:roundrect><![endif]-->
                                        </div>
                                      </th>
                                      <th
                                        align="left"
                                        style="
                                          visibility: hidden;
                                          width: 0;
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                        class="expander"
                                      ></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br
          style="
            position: relative;
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif !important;
          "
        />
        <!-- ################################################### HERO BLOCK 02 ################################################# -->
        <table
          align="center"
          cellspacing="0"
          style="
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif !important;
            padding: 0;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 100%;
          "
          class="hero-block-2 w-outer wrapper"
        >
          <tbody>
            <tr
              align="left"
              style="
                box-sizing: border-box;
                font-family: Helvetica, Arial, sans-serif !important;
                padding: 0;
                position: relative;
                text-align: left;
                vertical-align: top;
              "
            >
              <td
                align="left"
                style="
                  -moz-hyyphens: none;
                  -webkit-hyyphens: none;
                  border-collapse: collapse !important;
                  box-sizing: border-box;
                  color: #0a0a0a;
                  font-family: Helvetica, Arial, sans-serif !important;
                  font-size: 16px;
                  font-weight: normal;
                  hyyphens: none;
                  line-height: 130%;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  text-align: left;
                  vertical-align: top;
                  word-wrap: break-word;
                "
                class="wrapper-inner"
              >
                <table
                  align="center"
                  bgcolor="#fff"
                  border="1"
                  cellspacing="0"
                  style="
                    background: #ffffff;
                    border-collapse: collapse;
                    border-spacing: 0;
                    border: 1px solid #eff0f1;
                    box-sizing: border-box;
                    font-family: Helvetica, Arial, sans-serif !important;
                    padding: 35px 0 0;
                    position: relative;
                    text-align: left;
                    vertical-align: top;
                    width: 100%;
                  "
                  class="hero-block-2 w-inner w-no-padding w-padding-bottom wrapper"
                >
                  <tbody></tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br
          style="
            position: relative;
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif !important;
          "
        />
        <!-- ################################################### TEXT BANNER BLOCK 02 (Purple section with white text) ################################################# -->
        <table
          align="center"
          border="0"
          cellspacing="0"
          style="
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
            font-family: Helvetica, Arial, sans-serif !important;
            padding: 0;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 100%;
          "
          class="purple-section w-outer wrapper"
        >
          <tbody>
            <tr
              align="left"
              style="
                box-sizing: border-box;
                font-family: Helvetica, Arial, sans-serif !important;
                padding: 0;
                position: relative;
                text-align: left;
                vertical-align: top;
              "
            >
              <td
                align="left"
                style="
                  -moz-hyyphens: none;
                  -webkit-hyyphens: none;
                  border-collapse: collapse !important;
                  box-sizing: border-box;
                  color: #0a0a0a;
                  font-family: Helvetica, Arial, sans-serif !important;
                  font-size: 16px;
                  font-weight: normal;
                  hyyphens: none;
                  line-height: 130%;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  text-align: left;
                  vertical-align: top;
                  word-wrap: break-word;
                "
                class="wrapper-inner"
              >
                <table
                  align="center"
                  bgcolor="#9283FF"
                  border="0"
                  cellspacing="0"
                  style="
                    background: #9283ff;
                    border-bottom: 1px solid #9283ff;
                    border-collapse: collapse;
                    border-left: 1px solid #9283ff;
                    border-right: 1px solid #9283ff;
                    border-spacing: 0;
                    border-top: 1px solid #9283ff;
                    box-sizing: border-box;
                    font-family: Helvetica, Arial, sans-serif !important;
                    padding: 35px 0 0;
                    position: relative;
                    text-align: left;
                    vertical-align: top;
                    width: 100%;
                  "
                  class="purple-section w-inner w-purple wrapper"
                >
                  <tbody>
                    <tr
                      align="left"
                      style="
                        box-sizing: border-box;
                        font-family: Helvetica, Arial, sans-serif !important;
                        padding: 0;
                        position: relative;
                        text-align: left;
                        vertical-align: top;
                      "
                    >
                      <td
                        align="left"
                        style="
                          -moz-hyyphens: none;
                          -webkit-hyyphens: none;
                          border-collapse: collapse !important;
                          box-sizing: border-box;
                          color: #0a0a0a;
                          font-family: Helvetica, Arial, sans-serif !important;
                          font-size: 16px;
                          font-weight: normal;
                          hyyphens: none;
                          line-height: 130%;
                          margin: 0;
                          padding: 35px 15px 36px;
                          position: relative;
                          text-align: left;
                          vertical-align: top;
                          word-wrap: break-word;
                        "
                        class="wrapper-inner"
                      >
                        <table
                          cellspacing="0"
                          border="0"
                          style="
                            align-items: center;
                            border-collapse: collapse;
                            border-spacing: 0;
                            box-sizing: border-box;
                            display: table;
                            font-family: Helvetica, Arial, sans-serif !important;
                            padding: 0;
                            position: relative;
                            text-align: left;
                            vertical-align: top;
                            width: 100%;
                          "
                          class="row"
                        >
                          <tbody
                            style="
                              position: relative;
                              box-sizing: border-box;
                              font-family: Helvetica, Arial, sans-serif !important;
                            "
                          >
                            <tr
                              align="left"
                              style="
                                box-sizing: border-box;
                                font-family: Helvetica, Arial, sans-serif !important;
                                padding: 0;
                                position: relative;
                                text-align: left;
                                vertical-align: top;
                              "
                            >
                              <th
                                align="left"
                                style="
                                  max-width: 684px;
                                  color: #0a0a0a;
                                  font-family: Helvetica, Arial, sans-serif !important;
                                  font-weight: normal;
                                  text-align: left;
                                  line-height: 130%;
                                  font-size: 16px;
                                  position: relative;
                                  box-sizing: border-box;
                                  margin: 0 auto;
                                  padding: 0 16px 16px;
                                "
                                class="small-12 large-12 columns first last"
                              >
                                <table
                                  cellspacing="0"
                                  border="0"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0;
                                    box-sizing: border-box;
                                    font-family: Helvetica, Arial, sans-serif !important;
                                    padding: 0;
                                    position: relative;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <tbody>
                                    <tr
                                      align="center"
                                      style="
                                        box-sizing: border-box;
                                        font-family: Helvetica, Arial,
                                          sans-serif !important;
                                        padding: 0;
                                        position: relative;
                                        text-align: center;
                                        vertical-align: top;
                                      "
                                    >
                                      <th
                                        align="center"
                                        style="
                                          font-weight: normal;
                                          text-align: center;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0px;
                                          padding: 0px;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                        "
                                      >
                                        <h1
                                          align="center"
                                          style="
                                            font-weight: normal;
                                            text-align: center;
                                            line-height: 130%;
                                            word-wrap: normal;
                                            font-size: 30px;
                                            position: relative;
                                            box-sizing: border-box;
                                            margin: 0px;
                                            padding: 0px;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                          "
                                          class="hl"
                                        >
                                          <span
                                            style="text-decoration: none"
                                            href="#"
                                            ><font color="#ffffff"
                                              >Do your best work with
                                              Premium</font
                                            ></span
                                          >
                                        </h1>
                                        <p
                                          align="center"
                                          style="
                                            color: rgb(103, 109, 118);
                                            font-weight: normal;
                                            text-align: center;
                                            line-height: 1.38;
                                            font-size: 18px;
                                            position: relative;
                                            box-sizing: border-box;
                                            margin: 0px 0px 35px;
                                            padding: 0px;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                          "
                                          class="text-regular text-light-grey spacing-xlarge"
                                        >
                                          <span
                                            style="text-decoration: none"
                                            href="https://connect.asana.com/u/click?_t=8cb45ebcfb7c4c8189af4a5ff6ca1a98&amp;_m=a1cb64c1d2bd4274a40e778b9981f3c3&amp;_e=IBO1jG5LebbzZr4E9fDyHxKhfkHNcc7ad9OOPynliFkh8KaUho3vuVKtauP2SvzfBIbdek6xIb84QJk7PWtsVHB3jFzkYF3UYYll8vBE4BnQH620a_aoMOVHSavXXav5TZa64NvPQo53Bw9OdzdS9OoHX_8ef-8IvRkfDsgUYA9PsjnPIpImyGwq-gMIK0QiUKHlzRiRTg_hWImo6usfnZkutbNcsYHwtQtzfD5rBMue4V338SQcgcyKAZuaVdOiZIg2miWkIgD3gfsCdoP82TDnNs3eiwBae6FyRBuqOXwZZokuvWJKNuRhhU56m6MLsKJNINcG2B7SM_LEDXC8wsO7bvsMSmITMLh0AcF-3U10XK0hXrCCRA-Ab6XXIc1XL3l0LRuBHUbV6gDfCEKn8w%3D%3D"
                                            ><span style="color: #ffffff"
                                              >Teams are 45% more efficient with
                                              Premium. Yours will be, too.</span
                                            ></span
                                          >
                                        </p>
                                        <span
                                          style="
                                            font-weight: 600;
                                            text-align: center;
                                            line-height: 130%;
                                            position: relative;
                                            box-sizing: border-box;
                                            display: block;
                                            padding: 0px;
                                            font-family: Helvetica, Arial,
                                              sans-serif !important;
                                            width: 100% !important;
                                            text-decoration: none;
                                            color: rgb(103, 109, 118);
                                          "
                                          class="link link-purple-sec"
                                          href="#"
                                          ><span style="color: #ffffff"
                                            >Try it for free for 30 days ›</span
                                          ></span
                                        >
                                      </th>
                                      <th
                                        align="left"
                                        style="
                                          visibility: hidden;
                                          width: 0;
                                          color: #0a0a0a;
                                          font-family: Helvetica, Arial,
                                            sans-serif !important;
                                          font-weight: normal;
                                          text-align: left;
                                          line-height: 130%;
                                          font-size: 16px;
                                          position: relative;
                                          box-sizing: border-box;
                                          margin: 0;
                                          padding: 0;
                                        "
                                        class="expander"
                                      ></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <!-- ################################################### FOOTER ################################################# -->
      </td>
    </tr>
  </tbody>
</table>
`;

const Asana = () => {
  return <ParseHTML html={string} />;
};

export default Asana;
