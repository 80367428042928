import { useCallback, useState } from "react";
import editIcon from "src/assets/images/EditIcon.svg";
import styles from "./EditDays.module.scss";

const EditDays = ({
  day,
  onChange,
}: {
  day: number;
  onChange: (val: number) => void;
}) => {
  const [editableDay, setEditableDay] = useState(day);
  const [isEditing, setIsEditing] = useState(false);
  const [showTriggerError, setShowTriggerError] = useState(false);

  // Handle input change event for the editable day input field
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      setEditableDay(value);
      setShowTriggerError(false);
    },
    [],
  );

  // Handle when the input field loses focus (onBlur event)
  const handleBlur = useCallback(() => {
    // Check if the editable day is empty or null
    if (!Number.isInteger(editableDay) || editableDay < 0) {
      // Show an error if the input is invalid
      setShowTriggerError(true);
    } else {
      // Hide the error if the input is valid
      setShowTriggerError(false);

      // Exit editing mode
      setIsEditing(false);

      // Apply the change to the sequence
      onChange(editableDay);
    }
  }, [editableDay, onChange]);

  return (
    <div className="d-flex">
      {isEditing ? (
        <div className={`position-relative `}>
          <input
            type="number"
            value={editableDay}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className={`${styles.inputChange} ${
              showTriggerError ? "border border-danger" : ""
            }`}
            autoFocus
            max={1000}
            min={0}
          />
          <div
            className={`${styles.checkBox} cursor-pointer`}
            onClick={handleBlur}
          >
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
        </div>
      ) : (
        <span className="">{editableDay}</span>
      )}
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsEditing(true);
        }}
        className={`ps-1 cursor-pointer ${isEditing ? "d-none" : "d-block"}`}
      >
        <img
          src={editIcon}
          alt="edit"
          width={12}
        />
      </span>
    </div>
  );
};

export default EditDays;
