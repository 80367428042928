import { axiosJSON } from "src/globals/axiosEndPoints";

export const getUserSegmentPage = async ({ pageParam = 0, limit = 10 }) => {
  const url = "/api/segment/getAllUserSegment";
  try {
    const { data: res } = await axiosJSON.post(url, {
      start: pageParam,
      limit,
    });

    if (res.err || res.error) {
      throw new Error(
        res.msg || res.message || "Error fetching userSegment Data",
      );
    }

    return {
      data: res.data.map((item: { id: number; value: string }) => ({
        value: item.id.toString(),
        label: item.value,
      })),
      nextPage: pageParam + limit,
      hasNextPage: res.data.length === limit,
    };
  } catch (error) {
    console.error("Error in getUserSegmentPage:", error);
    throw error;
  }
};
