import { useState } from "react";
import styles from "./VariantSidebar.module.scss"
import EditVariant from "../EditVariant/EditVariant";

const VariantSidebar = ({
  onHide,
  selectedTemplateImg,
}: {
  onHide: () => void;
  selectedTemplateImg: string;
}) => {
  const [variants, setVariants] = useState([
    { id: 1, name: "Variant 1" },
  ]);

  // Function to update the name of a specific variant
  const updateVariantName = (id: number, newName: string) => {
    const updatedVariants = variants.map((variant) =>
      variant.id === id ? { ...variant, name: newName } : variant,
    );
    setVariants(updatedVariants);
  };
  return (
    <div className={`${styles.leftVariantSection}`}>
      <div className={`${styles.variantSection}`}>
        <div className={`px-3 pt-2 ${styles.imgSection}`}>
          <div
            onClick={onHide}
            role="button"
          >
            <span>
              <i className="fa-solid fa-angle-left"></i>
            </span>
            <span className={`ps-1 ${styles.backBtn}`}>Back</span>
          </div>
          <h4 className={`${styles.variantText}`}>Template {">"} Variants</h4>
          <div>
            <img
              src={selectedTemplateImg}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
        <div className={`px-3 ${styles.variantNames}`}>
          <h6 className={`${styles.heading}`}>Variants:</h6>
          {variants.map((variant) => (
            <EditVariant
              key={variant.id}
              variantName={variant.name}
              onNameChange={(newName) => updateVariantName(variant.id, newName)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VariantSidebar;
