import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Loader from "src/components/Loader";
import DeleteArticle from "src/routes/LiveChat/Children/ChatSetting/Children/KnowledgeBased/Children/CreateArticle/children/DeleteArticle/DeleteArticle";
import AddArticle from "../AddArticle/AddArticle";
import ArticleSetting from "../ArticleSetting/ArticleSetting";
import { SaveModal } from "../SaveModal/SaveModal";
import deleteArticleBtn from "src/assets/images/deleteArticle.png";
import styles from "./CreatePublish.module.scss";
import useCreatePublish from "./useCreatePublish";
export type IupdatedArticle = {
  id: number;
  isUpdated: boolean;
};
interface Props {
  articleId: number | string;
}

const CreatePublish = ({ articleId }: Props) => {
  const {
    navigate,
    HandleSubmit,
    TotalTextLimit,
    articleName,
    articleSettingAjaxStatus,
    articleStatus,
    currentAttachmentBatchNumber,
    description,
    handleDelete,
    onHide,
    pageIntegrationId,
    saveChangeModal,
    searchKeyword,
    selectedCategory,
    setArticleName,
    setArticleStatus,
    setCurrentAttachmentBatchNumber,
    setDescription,
    setSearchKeyword,
    setSelectedCategory,
    setShowDeleteModal,
    showDeleteModal,
    showSaveLoader,
    showDeleteLoader,
    selectedFiles,
    setSelectedFiles,
    showError,
    setShowError,
    keywordVolums,
    generatedBy
  } = useCreatePublish(articleId)

  return (
    <div className="p-1 p-md-2 p-lg-3 w-100">
      <div
        className={`d-flex flex-column flex-lg-row justify-content-between align-items-center`}
      >
        <div className={`d-flex flex-shrink-0 ${styles.leftContent}`}>
          {/* Back Arrow */}
          <div className={`${styles.backArrow}`}>
            <span
              onClick={(e) => {
                navigate(`/knowledge-base/${pageIntegrationId}/articles`);
              }}
            >
              <i
                className="fa-solid fa-arrow-left"
                role={"button"}
              ></i>{" "}
            </span>
          </div>

          {/* Article Name */}
          <div className={`${styles.heading}`}>
            <span>{articleName === "" ? "Untitled" : articleName}</span>
          </div>
        </div>
        <div className={`d-flex align-items-center ${styles.SaveDeletBtn}`}>
          <span className={`d-none ${styles.moreOption}`}>
            <i className="fa-solid fa-ellipsis"></i>{" "}
          </span>

          <div
            className={`${styles.delBox}`}
            onClick={(e) => setShowDeleteModal(true)}
          >
            <img
              src={deleteArticleBtn}
              alt=""
            />
          </div>

          <button
            className={`${styles.saveBtn}`}
            disabled={showSaveLoader === true}
            onClick={HandleSubmit}
          >
            {showSaveLoader === true ? (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            ) : (
              "Save Changes "
            )}
          </button>

          {/* Save Modal */}
          <Modal
            show={saveChangeModal}
            onHide={onHide}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            backdropClassName={`${styles.backDrop}`}
          >
            <SaveModal onHide={onHide} />
          </Modal>
        </div>
      </div>

      {/* Delete Modal */}

      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showDeleteModal}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        //   centered={true}
        enforceFocus={false}
      >
        <DeleteArticle
          HeadingText="Are you sure you want to remove this article?"
          articleId={articleId}
          sendArticleId={true}
          handleConfirm={handleDelete}
          onHide={onHide}
          cancelBtnText="No"
          submitBtnText="Yes"
          showDeleteLoader={showDeleteLoader}
        />
      </Modal>

      {/* Add Article & Article Setting */}
      {articleId !== "new" && articleSettingAjaxStatus === "pending" ? (
        <Loader />
      ) : (
        <div className="d-flex flex-wrap pt-5">
          <div className={`pe-4 pb-4 ${styles.leftBox}`}>
            <AddArticle
              headingText={articleName}
              setHeadingText={setArticleName}
              message={description}
              setMessage={setDescription}
              currentAttachmentBatchNumber={currentAttachmentBatchNumber}
              setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
              TotalTextLimit={TotalTextLimit}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              showError={showError}
              setShowError={setShowError}
            />
          </div>
          <div className={`${styles.rightBox}`}>
            <ArticleSetting
              integrationId={pageIntegrationId}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              articleStatus={articleStatus}
              setArticleStatus={setArticleStatus}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              isNew={articleId === "new"}
              showError={showError} //Added this prop to check and show validation once save is clicked
              setShowError={setShowError} //Added this prop to check and show validation once save is clicked
              keywordVolums={keywordVolums} // if article is generated by ai show pass keywordAndVolumn column
              generatedBy={generatedBy}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePublish;
