import UserAvatar from "src/components/UserAvatar";
import styles from "./BugReportModal.module.scss";
import closeModal from "src/assets/images/close(3).png";
import clockIcon from "src/assets/images/clockIcon.png";
import copyIcon from "src/assets/images/copyIcon.png";
import { NavLink } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import ResolutionUi from "../ResolutionUi/ResolutionUi";
import SuccessMessage from "./SuccessMessage/SuccessMessage";
import IssueDescription from "./IssueDescription/IssueDescription";
import { useMutation } from "@tanstack/react-query";
import getBugById, { Comment } from "src/services/BugReport/getBugById.service";
import Message from "./Children/Message/Message";
import TimeLine from "./Children/TimeLine/TimeLine";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { v4 as uuid } from "uuid";
import { IoIosInformationCircleOutline } from "react-icons/io";
import DropDownStaus from "../../../DropDownStatus/DropDownStatus";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import postAgentComment from "src/services/BugReport/postAgentComment.service";
import GeneralTooltip from "src/components/GeneralTooltip/GeneralTooltip";
import { dateFormate, dateFormateWithFullMonth } from "./Children/dateFormate";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
import useBugMessageList from "./Children/Message/useBugMessageList";
import { BugComments } from "src/services/BugReport/getBugComment";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { capitalizeFirstLetter } from "../../../helper/helperFunction";
import updateBugResolution, {
  BugResolve,
} from "src/services/BugReport/updateBugResolution";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ProfileImgGroup from "../../../ProfileImgGroup/ProfileImgGroup";
import { EChannel } from "src/enums/EChannel";
import useUTCToUserTime from "src/hooks/dateHooks/useUTCToUserTime";
import SendingAs, { ReplyOption } from "./SendingAs/SendingAs";
import { useSyncedState } from "src/hooks/useSyncedState";

export const checkContent = (htmlString: string) => {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a DOM Document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Get the text content of the first element
  const textContent = doc.body.textContent || "";

  // Check if there is any content inside the tag
  return textContent.trim() !== "";
};

interface Props {
  onHide: () => void;
  id: number | undefined;
  mergeModal?: boolean;
  tableBugStatusUpdate: (status: string, isResolved?: boolean) => void;
  bugData: BugData;
  categoryName: string;
}

const BugReportModal = ({
  onHide,
  id,
  mergeModal = false,
  tableBugStatusUpdate,
  bugData,
  categoryName,
}: Props) => {
  const { hasNextPage, nextPageLoad, bugComments, setBugComments } =
    useBugMessageList(id);

  const [timeLineStep, setTimeLineStep] = useState(
    bugData.bugResolveDetails ? 3 : 1,
  );
  // const [msgType, setMsgType] = useState<string>("");
  const [msgType, messageRef, setMsgType] = useSyncedState("");

  const [textBoxErr, setTextBoxErr] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  // const createDate = useUTCToUserTime({
  //   timeInUTC: bugData.bugCreatedAtGmt,
  // });
  const createDate = (() => {
    try {
      return new Date(bugData.bugCreatedAtGmt + "Z");
    } catch (error) {
      console.error("Invalid date for bugCreatedAtGmt:", error);
      return new Date(); // fallback to current date
    }
  })();
  // const resolvedDate = useUTCToUserTime({
  //   timeInUTC: bugData?.bugResolveDetails?.resolveDateGmt ?? "",
  // });
  const resolvedDate = (() => {
    try {
      return new Date((bugData.bugResolveDetails?.resolveDateGmt ?? "") + "Z");
    } catch (error) {
      console.error("Invalid date for resolveDateGmt:", error);
      return new Date(); // fallback to current date
    }
  })();
  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [bugResolved, setBugResolved] = useState<BugResolve>(); // bug resolved data store and send to success step
  const [addNewMsg, setAddNewMsg] = useState(false); // to check new message is added or not. If added then scroll div to bottom
  const scrollToBottomMsg = useRef<HTMLDivElement | null>(null);
  const prevScrollHeightRef = useRef(0);

  const [sendType, setSendType] = useState(1);
  const [replyOption, setReplyOption] = useState<ReplyOption>(
    ReplyOption.Reply,
  );

  // if bug is resolved by customer this object will send to success step
  let bugIsResolved = null;
  if (
    bugData.bugResolveDetails &&
    bugData.bugResolveDetails.bugResolveByType === "customer"
  ) {
    bugIsResolved = {
      name: bugData.customerDetails[0].customerName,
      resolvedDate: bugData.bugResolveDetails.resolveDateGmt,
      profileImg: bugData.customerDetails[0].imageUrl,
    };
  } else if (
    bugData.bugResolveDetails &&
    bugData.bugResolveDetails.bugResolveByType === "agent"
  ) {
    bugIsResolved = {
      name: bugData.bugResolveDetails.agentName,
      resolvedDate: bugData.bugResolveDetails.resolveDateGmt,
      profileImg: bugData.bugResolveDetails.agentImageUrl,
    };
  }

  // api call to add a new comment
  const mutation = useMutation({
    mutationFn: postAgentComment,
    onSuccess: (data) => {
      setBugComments((prevState: Array<BugComments>) => [
        ...prevState,
        {
          sentBy: {
            name: data.agentDetails.agentName,
            imageURL: data.agentDetails.agentProfileImgUrl,
          },
          commentId: data.bugCommentId,
          message: data.commentMessage,
          commentCreatedAtGmt: data.createdAtGmt,
          attachmentDetails: data.attachmentDetails,
        },
      ]);
      setMsgType("");
      setSelectedFiles([]);
      setAddNewMsg(true);
      bugData.bugStatusKey = "investigating";
      tableBugStatusUpdate("investigating");
    },
  });

  // bug resolved api call. (Time line 3)
  const { mutate, isLoading } = useMutation({
    mutationFn: updateBugResolution,
    onSuccess: (data) => {
      setBugResolved(data);
      setTimeLineStep((prevState) => prevState + 1);
      handleUpdatedstatus("resolved", true);
      bugData.bugResolveDetails = {
        agentImageUrl: data.bugResolveAgentDetails.agentProfileImgUrl,
        agentName: data.bugResolveAgentDetails.agentName,
        resolveDateGmt: data.bugResolveDateGmt,
        bugResolveByType: "agent",
      };
    },
  });

  // handle bug comment scroll. Add a new comment will show the latest comment and at the time of open scroll will be bottom
  useEffect(() => {
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - prevScrollHeightRef.current;
    }
    if (addNewMsg && scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      setAddNewMsg(false);
    }
  }, [bugComments]);

  // ticket id coppy function
  const handleCopy = (copyMe: string) => {
    window.navigator.clipboard.writeText(copyMe);
  };

  // fetch next page data for comment and handle div scroll
  const fetchNextPageHanlder = useCallback(() => {
    nextPageLoad();
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      prevScrollHeightRef.current = scrollableDiv.scrollHeight;
    }
  }, [bugComments]);

  // handle update status on modal and table both place
  const handleUpdatedstatus = useCallback(
    (newStatus: string, isResolved: boolean = false) => {
      if (newStatus !== "resolved" && bugData?.bugStatusKey) {
        bugData.bugStatusKey = newStatus;
        tableBugStatusUpdate(newStatus);
      }
      if (isResolved && bugData?.bugStatusKey) {
        bugData.bugStatusKey = newStatus;
        tableBugStatusUpdate(newStatus, true);
      }
    },
    [bugData?.bugStatusKey],
  );

  // handle msg submit action
  const handleMsgSubmit = () => {
    const attachmentBatchNumbers = selectedFiles.map(function (
      value: any,
      index: any,
    ) {
      return value.batchNumber;
    });
    if (!checkContent(msgType) && selectedFiles.length === 0) {
      setTextBoxErr(true);
      return;
    }
    const commentPayload: Comment = {
      message: msgType,
      bugId: bugData?.bugId ?? 0,
    };
    if (selectedFiles.length) {
      commentPayload.attachmentBatchNumber = attachmentBatchNumbers;
    }
    mutation.mutate(commentPayload);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className={`mb-0 ${styles.modalHead}`}>
          {" "}
          {timeLineStep === 2 ? "Resolution" : "Bug report"}
        </h4>
        <img
          src={closeModal}
          alt=""
          height={12}
          width={12}
          onClick={() => onHide()}
          className="cursor-pointer"
          id="bug_reportModal_hide"
        />
      </div>
      <div className="position-relative mb-2">
        <TimeLine
          stepText={["Bug report", "Resolution", "Resolved!"]}
          activeStep={timeLineStep}
        />
        <div>
          {timeLineStep === 1 && (
            <button
              className={`px-4 ${styles.resolveBtn}`}
              onClick={() => setTimeLineStep(timeLineStep + 1)}
              id="bug_reportModal_processBtn"
            >
              {bugData.bugStatusKey === "resolved"
                ? "View details"
                : "Proceed to resolve"}
              <span className="ps-1">
                <i className="fa-solid fa-angle-right"></i>
              </span>{" "}
            </button>
          )}
        </div>
      </div>

      {/* modal time line step 1 view */}
      {timeLineStep === 1 && (
        <div className={`pb-2 ${styles.contentWrap}`}>
          <div className="d-flex">
            {bugData.customerDetails.length === 1 && (
              <div>
                <UserAvatar
                  name={bugData.customerDetails[0].customerName}
                  size={21}
                />
              </div>
            )}

            <div className="ps-1 w-100">
              <div className="d-flex align-items-center">
                {bugData.customerDetails.length > 1 ? (
                  <ProfileImgGroup
                    customers={bugData.customerDetails}
                    companyName={bugData.companyName}
                  />
                ) : (
                  <span className={` ${styles.cName}`}>
                    {bugData.customerDetails[0].customerName}
                  </span>
                )}
                <span className={`ps-2 ${styles.time}`}>
                  <img
                    src={clockIcon}
                    alt=""
                    width={12}
                  />
                  <span className="ms-1">
                    {dateFormate(createDate.toString())}
                  </span>
                </span>
              </div>
              {/* ticket id view */}
              <div
                className={`d-flex align-items-center flex-wrap ${
                  bugData.ticketId.length > 1 ? "mt-3 mb-2" : ""
                }`}
              >
                {bugData.ticketId.length > 1 && (
                  <span className={`${styles.linkedTicketText}`}>
                    Linked tickets:
                  </span>
                )}
                {bugData.ticketId.map((id) => (
                  <div
                    key={id}
                    className="me-4"
                  >
                    <span className={`pe-1 ${styles.tivketId}`}>
                      {"Ticket ID: "}
                      <a
                        href={`${window.location.origin}/tickets/all/ticket-view/ticket/${id}/`}
                        target="_blank"
                        className={`cursor-pointer`}
                      >
                        {id}
                      </a>
                    </span>
                    <img
                      src={copyIcon}
                      alt=""
                      width={10}
                      onClick={() =>
                        handleCopy(
                          `${window.location.origin}/tickets/all/ticket-view/ticket/${id}/`,
                        )
                      }
                      className={styles.copyPng}
                    />
                  </div>
                ))}
              </div>

              {/* dropdown status view */}
              <div
                className={`d-flex align-items-center ${
                  bugData.ticketId.length === 1 ? "mb-3" : ""
                }`}
              >
                <span className={`pe-1 ${styles.statusText}`}>Status:</span>
                <DropDownStaus
                  status={bugData?.bugStatusKey}
                  id={bugData?.bugId}
                  updatedStatus={handleUpdatedstatus}
                  setTimeLineStep={setTimeLineStep}
                />
              </div>
              <div className="mb-3 d-flex">
                {/* for Priority : I need this fixed right away */}
                <div
                  className={`me-1 ${styles.priorityText} 
                    ${
                      bugData?.priorityKey === "normal"
                        ? styles.priorityBlue
                        : bugData?.priorityKey === "high"
                          ? styles.priorityGreen
                          : styles.priorityRed
                    }
                    `}
                >
                  <span>
                    Priority:{" "}
                    {bugData.priorityKey === "needThisFixedRightAway"
                      ? "I need this fixed right away"
                      : capitalizeFirstLetter(bugData?.priorityKey)}
                  </span>
                </div>
                <span className={`me-1 ${styles.categoryLogin}`}>
                  Category: {categoryName}
                </span>
                <span className={`me-1 ${styles.categoryLogin}`}>
                  URL reported bug from:{" "}
                  {bugData.bugReportedUrl.length === 1 ? (
                    <NavLink to={bugData.bugReportedUrl[0] ?? ""} target="blank">
                      {bugData.bugReportedUrl[0]}
                    </NavLink>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="tooltip-top"
                          className={`${styles.tooltip}`}
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                        >
                          {bugData.bugReportedUrl.map((url) => (
                            <NavLink to={url ?? ""} target="blank">{url}</NavLink>
                          ))}
                        </Tooltip>
                      }
                      show={showTooltip}
                    >
                      <NavLink
                        to={bugData.bugReportedUrl[0] ?? ""}
                        target="blank"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        {bugData.bugReportedUrl[0]}...
                      </NavLink>
                    </OverlayTrigger>
                  )}
                </span>
              </div>

              {/* image video and desctiption container */}
              <IssueDescription bugData={bugData} />
              <div className={`${styles.issueDesc}`}>
                {/* infinite scroll comment view */}
                <div>
                  <h4 className={`mb-3 ${styles.comment}`}>Comments</h4>
                  <InfiniteScroll
                    initialDataLoaded={true}
                    hasMoreTop={hasNextPage}
                    loadMoreFromTop={fetchNextPageHanlder}
                    className={`${styles.commentsList}`}
                    root={scrollToBottomMsg}
                    infiniteLoaderTop={
                      // Rendering the load button conditionally.
                      <div
                        className={`${styles.loaderText} mb-2`}
                        onClick={fetchNextPageHanlder}
                      >
                        <span>Load More</span>
                        <i className="ms-1 fa-solid fa-rotate-right"></i>
                      </div>
                    }
                  >
                    <div>
                      {bugComments?.map((msg: BugComments, index: number) => {
                        return (
                          <Message
                            key={msg.commentId}
                            messageData={msg}
                          />
                        );
                      })}
                    </div>
                  </InfiniteScroll>
                  {bugData?.bugResolveDetails &&
                  bugData.bugResolveDetails.bugResolveByType === "customer" &&
                  bugData.bugStatusKey === "resolved" ? (
                    <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
                      <div className={`${styles.blueCircle} me-2`}></div>
                      <div className={styles.resolvedMsg}>
                        This issue has been marked as resolved by{" "}
                        {bugData.customerDetails[0].customerName}.{" "}
                        <span className={styles.resolvedTime}>
                          {dateFormateWithFullMonth(resolvedDate.toString())}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`${styles.editContainer} p-3`}>
                  {/* <SendingAs
                    sendType={sendType}
                    setSendType={setSendType}
                    replyOption={replyOption}
                    setReplyOption={setReplyOption}
                    channelName={EChannel.email}
                    setChannelName={(channel) => {}}
                    allowedChannels={[EChannel.email]}
                  /> */}
                  <TinyMCEContainer
                    className={`${textBoxErr ? styles.redBorder : ""} ${
                      styles.textBorder
                    }`}
                    value={messageRef.current}
                    onChange={(value: string) => {
                      setMsgType(value);
                      setTextBoxErr(false);
                    }}
                    options={{
                      placeholder: "Type here...",
                      min_height: 120,
                    }}
                    disableAttachment={bugData.bugStatusKey === "resolved"}
                    readonly={bugData.bugStatusKey === "resolved"}
                    attachmentUploadData={attachmentUploadData}
                    setAttachmentUploadData={setAttachmentUploadData}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    currentAttachmentBatchNumber={currentAttachmentBatchNumber}
                    setCurrentAttachmentBatchNumber={
                      setCurrentAttachmentBatchNumber
                    }
                    isFileUploading={isFileUploading}
                    setIsFileUploading={setIsFileUploading}
                    areAttachmentsPublic={true}
                    uniqueID={uuid()}
                    buttons={
                      <div className={`d-flex me-2 ps-1`}>
                        {bugData.ticketId.length > 1 && (
                          <div className={`d-flex`}>
                            <GeneralTooltip
                              title="If this issue has been reported by multiple
                                customers, you might have multiple open tickets
                                for this, one for each customer, for example. If
                                you send a response here, the response will be
                                sent on each of those linked tickets
                                individually. Customers on one ticket will not
                                be able to know about a different open ticket
                                for this issue."
                              className={styles.generalTooltip}
                            >
                              <div>
                                <IoIosInformationCircleOutline size={11} />
                              </div>
                            </GeneralTooltip>
                            <p className={`mb-0 ms-1 ${styles.mergeMsg}`}>
                              Reply will be sent to all linked tickets
                              individually.
                            </p>
                          </div>
                        )}
                        <button
                          className={styles.saveEditDescriptionBtn}
                          onClick={handleMsgSubmit}
                          disabled={
                            isFileUploading ||
                            mutation.isLoading ||
                            bugData.bugStatusKey === "resolved"
                          }
                        >
                          <span> Send and mark pending</span>
                          {mutation.isLoading ? (
                            <Spinner
                              className="ms-1"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    }
                  />
                </div>
                {bugData.ticketId.length > 1 && (
                  <div className={`d-flex align-items-center flex-wrap mt-3`}>
                    <span className={`${styles.linkedTicketText}`}>
                      Linked tickets:
                    </span>
                    {bugData.ticketId.map((id) => (
                      <div
                        key={id}
                        className="me-4"
                      >
                        <span className={`pe-1 ${styles.tivketId}`}>
                          Ticket ID: {id}
                        </span>
                        <img
                          src={copyIcon}
                          alt=""
                          width={10}
                          onClick={() => handleCopy(String(id))}
                          className={styles.copyPng}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {timeLineStep === 2 && (
        <div className={`pb-2 ${styles.contentWrap}`}>
          <ResolutionUi
            id={bugData?.bugId}
            description={bugData?.bugDescription}
            severity={bugData?.priorityKey}
            status={bugData.bugStatusKey}
            isMerge={bugData.ticketId.length > 1}
            setTimeLineStep={setTimeLineStep}
            isLoading={isLoading}
            handleStatusUpdate={mutate}
          />
        </div>
      )}

      {timeLineStep === 3 && (
        <SuccessMessage
          bugResolved={bugResolved}
          bugIsResolved={bugIsResolved}
          setTimeLineStep={setTimeLineStep}
        />
      )}
    </div>
  );
};

export default BugReportModal;
