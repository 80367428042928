import UserAvatar from "src/components/UserAvatar";
import styles from "./InternalNote.module.scss";
import ParseHTML from "src/components/ParseHTML";
import AxiosImg from "src/components/AxiosImg";
import { useAppSelector } from "src/store/store";
import { getTimeFromNow } from "src/utils/dateLibrary";
import InnerChatAttachments from "src/components/InnerChatAttachments/InnerChatAttachments";
import saufterLogo from "src/assets/images/saufter.gif";
import cautionImg from "src/assets/images/errorImg.png";
import slack from "src/assets/images/slack.png";
interface Props {
  msg: any;
  direction?: "left" | "right";
}
const InternalNote = ({ msg, direction }: Props) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  const currentUserData = useAppSelector(
    (state) => state.globals?.currentUserData,
  );

  const cernErrorMessageId = useAppSelector(
    (state) => state.innerTicket?.cernErrorMessageId,
  );
  return (
    <div
      className={`d-flex justify-content-end mb-3 ${
        direction == "left" ? "flex-row-reverse" : ""
      } `}
    >
      <span
        className={`${
          cernErrorMessageId === msg.messageId ||
          msg?.cernThreadData?.isCernInstructionError
            ? direction == "left"
              ? "ms-2 mt-1"
              : "me-2 mt-1"
            : direction == "left"
              ? "ms-3"
              : "me-3"
        } ${styles.time}`}
      >
        {getTimeFromNow({
          date: new Date(msg.messageDateTime),
          timeZone: userTimezone,
        })}
      </span>
      {(cernErrorMessageId === msg.messageId ||
        msg?.isCernInstructionError) && (
        <div>
          <img
            src={cautionImg}
            alt="error img"
            className={`${styles.erroMsg}`}
          />
        </div>
      )}
      <div
        className={`${styles.msgBox} ${
          (cernErrorMessageId === msg.messageId ||
            msg?.isCernInstructionError) &&
          styles.errorBorder
        }`}
      >
        {/* <div className="d-flex align-items-center mb-1">
          <span className={`${styles.chatText}`}>Chat</span>
          <span className={`mx-1 ${styles.separator}`}></span>
          <span className={`${styles.actionText}`}>Actions</span>
        </div> */}
        <div className={`${styles.msgText}`}>
          <ParseHTML html={msg.message} />
        </div>
        {msg.attachments?.length ? (
          <InnerChatAttachments
            messageData={{ attachments: msg.attachments }}
          />
        ) : (
          ""
        )}
        {msg.messageType === "BotAiEscalatedNote" && (
          <div className="d-flex justify-content-end">
            <span className={`${styles.escalate}`}>Escalated!</span>
          </div>
        )}
      </div>
      <div
        className={`${
          direction == "left" ? "me-3" : "ms-3"
        } d-flex align-items-center flex-column`}
      >
        {msg.senderName === "Cern" ? (
          <img
            src={saufterLogo}
            className={`${styles.profileIcon}`}
            alt="saufter logo"
          />
        ) : msg.senderImgURL || currentUserData?.profileImageUrl ? (
          <AxiosImg
            url={msg.senderImgURL ?? currentUserData?.profileImageUrl}
            className={`${styles.profileIcon}`}
            isDirectURL={msg.isPublicAttachmentUrl}
          />
        ) : (
          <UserAvatar
            name={msg.senderName || "NA"}
            size={33}
          />
        )}
        {msg.messageType === "BotAiEscalatedNote" && (
          <div
            className={`${styles.iconContainer} d-flex justify-content-center align-items-center`}
          >
            <img
              src={slack}
              alt="slack channel"
              className={styles.slackIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InternalNote;
