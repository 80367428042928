import { useCallback, useMemo, useState } from "react";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  IRadioQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";
import styles from "./RadioQuestion.module.scss";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";

interface QuestionsProps {
  questionData: IRadioQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const isRadioQuestion = (question: Question): question is IRadioQuestion => {
  return question.questionType === QuestionType.Radio;
};

const RadioQuestion = ({
  questionData,
  stepId,
  setShowErrorModal,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const { type } = useParams<IAutomationListParams>();

  const onChangeHandler = useCallback(
    (optionKey: string) => {
      if (type === EAutomationType.TICKET_ROUTING && formData?.configured) {
        if (setShowErrorModal) setShowErrorModal(true);
        return;
      }

      if (isRadioQuestion(questionData)) {
        // Check if all questions in current step are answered
        const isAllStepQuestionsAnswered = formData.stepsData[
          stepId
        ].questionKeys.every((val) => formData.questionsData[val].isAnswered);

        updateQuestions(
          {
            ...questionData,
            questionValue: optionKey,
            isAnswered: optionKey !== "",
          },
          // Updated related questions if move to next is on another question
          // or if current step's all questions are answered (special case) - in case related question is relative hidden but also completed
          formData.moveToNextQuestionKey !== questionData.questionKey ||
            isAllStepQuestionsAnswered,
        );

        if (optionKey !== "") {
          saveQuestionsData({
            stepId,
            stepQuestions: {
              [questionData.questionKey]: optionKey,
            },
          });
        }
      }
    },
    [
      formData?.configured,
      formData.moveToNextQuestionKey,
      formData.questionsData,
      formData.stepsData,
      questionData,
      saveQuestionsData,
      setShowErrorModal,
      stepId,
      type,
      updateQuestions,
    ],
  );

  const showConfigButton = useMemo(
    () => {
      // Hiding this as it is added in exceptions
      return false;
      // return (
      //   questionData.questionKey === "issueMonitoring" &&
      //   questionData.questionValue === "RequestsForSpecificUserTypes"
      // );
    },
    [
      // questionData
    ],
  );

  const [isConfigClicked, setIsConfigClicked] = useState(false);

  return (
    <div>
      {questionData.listOfOptions &&
        questionData.listOfOptions.map(
          ({ optionKey, optionLabel, isDisabled, isHidden }, index) => {
            if (isHidden) {
              return null;
            }

            return (
              <div
                className="form-check"
                key={index}
              >
                <input
                  className={`form-check-input ${styles.checkBox}`}
                  type="radio"
                  id={`${optionKey}_${index}`}
                  disabled={
                    isDisabled ||
                    (questionData.isBrandRequired && formData.brand === null)
                  }
                  checked={questionData.questionValue === optionKey}
                  onChange={() => onChangeHandler(optionKey)}
                />
                <label
                  className={`form-check-label ${styles.inputCheck}`}
                  htmlFor={`${optionKey}_${index}`}
                >
                  {optionLabel}
                </label>
              </div>
            );
          },
        )}

      {type === EAutomationType.ISSUE_DISPATCH && showConfigButton ? (
        <div className={`mt-2 d-flex`}>
          <a
            className={`d-flex align-items-center justify-content-center ${
              styles.createSegmentLink
            } ${isConfigClicked ? styles.clicked : ""} `}
            href="/customer-segments"
            target="_blank"
            onClick={() => setIsConfigClicked(true)}
          >
            + Configure user segment
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default RadioQuestion;
