import { axiosFormData } from "src/globals/axiosEndPoints";
import { IEmailTemplateVariant } from "./getEmailTemplateVariant.service";
import { AxiosProgressEvent } from "axios";

export interface CreateTemplateParams {
  categoryId: string;
  templateName: string;
  html: File; // .html
  json: File; // .json
  previewImage?: File | null;
  templateId?: string | number; // Incase of "edit"
  abortControllerSignal?: AbortSignal;
}

const createTemplateService = async (
  params: CreateTemplateParams,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  const fData = new FormData();
  fData.append("categoryId", params.categoryId);
  fData.append("templateName", params.templateName);
  fData.append("html", params.html);
  if (params.previewImage) {
    fData.append("previewImage", params.previewImage);
  }
  fData.append("json", params.json);
  if (params.templateId) {
    fData.append("templateId", params.templateId.toString());
  }

  const { data: res } = await axiosFormData.post(
    `/api/campaign/templates/create`,
    fData,
    {
      signal: params.abortControllerSignal,
      onUploadProgress: onUploadProgress,
    },
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res.data as IEmailTemplateVariant;
};

export default createTemplateService;
