import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IActionForModal } from "src/services/Automation/getAutomationById.service";
import styles from "./ActionCondition.module.scss";
import { useActionsState } from "../../../../../hooks/useActionsState";
import ActionTypeDropdown from "../ActionTypeDropdown/ActionTypeDropdown";
import ActionValue from "../ActionValue/ActionValue";
import { ActionOption } from "src/services/Automation/AutomationTicketRouting/getActionOptions.service";

interface Props {
  condition: IActionForModal;
  conditionIndex: number;
  filteredActionOptions: ActionOption[];
}

const ActionCondition = ({
  condition,
  conditionIndex,
  filteredActionOptions,
}: Props) => {
  const { actionOptions, conditionGroup, updateState } = useActionsState();

  const findOptionByFieldKey = useCallback(
    (fieldKey: string | null) =>
      actionOptions?.actionOptions.find(
        (option) => option.fieldKey === fieldKey,
      ),
    [actionOptions],
  );

  const option = useMemo(
    () => findOptionByFieldKey(condition?.fieldKey),
    [findOptionByFieldKey, condition],
  );

  const handleDeleteCondition = () => {
    if (conditionGroup) {
      let newActions: IActionForModal[] = [...conditionGroup?.conditions];
      newActions.splice(conditionIndex, 1); // Remove the condition at the specified index
      newActions = newActions.map((value, index, array) => {
        if (array[index + 1]) {
          value.nextConditionUUID = array[index + 1].conditionUUID;
        } else {
          value.nextConditionUUID = null;
        }
        return value;
      });
      updateState({
        conditionGroup: {
          ...conditionGroup,
          conditions: newActions,
        },
        saveStatus: "idle",
      });
    }
  };

  return (
    <div className="mb-4">
      <div className={` ${styles.condition} p-3`}>
        <div className="w-100">
          <div
            className={`d-flex align-items-center justify-content-between ${styles.actionName}`}
          >
            {`Action ${conditionIndex + 1}`}
            <div onClick={handleDeleteCondition}>
              <span
                style={{ color: "#707070", width: "14px", height: "18px" }}
                className=""
                role="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                >
                  <path
                    id="Icon_material-delete"
                    data-name="Icon material-delete"
                    d="M8.5,20.5a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V8.5H8.5Zm13-15H18l-1-1H12l-1,1H7.5v2h14Z"
                    transform="translate(-7.5 -4.5)"
                    fill="#707070"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div
            className={`d-flex ${
              option?.fieldDataType === "select" ||
              !option ||
              !option.fieldDataType
                ? "flex-row"
                : "flex-column"
            } 
            align-items-start mb-3 mt-2 w-md-75`}
          >
            <ActionTypeDropdown
              conditionIndex={conditionIndex}
              options={actionOptions?.actionOptions ?? []}
              filteredActionOptions={filteredActionOptions ?? []}
            />
            {option?.subText ? (
              <div className={`${styles.subText} mt-3`}>{option?.subText}</div>
            ) : null}
            {
              option ? (
                <div
                  className={`${
                    option.fieldDataType === "select" ? "ms-1" : "mt-3 w-100"
                  }`}
                >
                  <ActionValue
                    conditionIndex={conditionIndex}
                    isMulti={option.isMulti}
                    isSearchable={option.isSearchable ?? false}
                    fieldDataType={option.fieldDataType}
                    fieldKey={option.fieldKey}
                  />
                </div>
              ) : null
              // <div className={`ms-1 ${styles.emptyCondition}`}></div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCondition;
