import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./AttributeDetails.module.scss";
import { TimelineEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

const AttributeDetails = ({
  event,
  position,
}: {
  event: TimelineEvent & {
    type: "minor" | "major";
    attribute?: TimelineEvent;
  };
  position?: "top" | "bottom";
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  if (!event.attribute) {
    return null;
  }
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${
        styles.attributewrapper
      } ${position === "top" ? styles.top : ""}`}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      {position === "bottom" && (
        <span className={`${styles.stage}`}>
          {`${event.attribute.details[0].subEvents.length}xU`}
        </span>
      )}
      <OverlayTrigger
        trigger="hover"
        show={showTooltip}
        placement="right"
        overlay={
          <Tooltip className={`${styles.toolTipCustom}`}>
            {event.attribute.details[0].subEvents.map(
              (attributeData, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.overlay} px-2 pt-1`}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <div
                          className={`d-flex align-items-center justify-content-center ${styles.stageImg}`}
                        >
                          <span className={`${styles.leftText}`}>U</span>
                          <div className="d-flex flex-column justify-content-center">
                            <span className={`${styles.rightText}`}>
                              {attributeData.name[0] || "n"}
                            </span>
                            <span className={`${styles.rightText}`}>
                              {attributeData.id || 1}
                            </span>
                          </div>
                        </div>
                        <span className={`ps-1 ${styles.stageHead}`}>
                          {attributeData.name}
                        </span>
                      </div>
                      <p className={`mb-0 ${styles.stageDescription}`}>
                        {attributeData.description}
                      </p>
                    </div>
                  </div>
                );
              },
            )}
          </Tooltip>
        }
      >
        <div className={`${styles.attributeLine}`}></div>
      </OverlayTrigger>
      {position !== "bottom" && (
        <span className={`${styles.stage}`}>
          {`${event.attribute.details[0].subEvents.length}xU`}
        </span>
      )}
    </div>
  );
};

export default AttributeDetails;
