import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
  fetchCustomerData,
  fetchShopifySidebarData,
  resetShopifySidebar,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import CustomerInfo from "./children/CustomerInfo/CustomerInfo";
import Shopify from "./children/Shopify/Shopify";
import styles from "./CustomerSB.module.scss";
import Generalinfo from "src/routes/LiveChat/Children/Generalinfo/Generalinfo";
import LiveCart from "src/routes/LiveChat/Children/LiveCart/LiveCart";
// import { searchAllCustomers } from "src/store/slices/innerTicket/innerTicket.slice";
import ListCustomer from "./children/ListCustomer/ListCustomer";
import InfiniteScroll from "src/components/InfiniteScroll";
import { Spinner } from "react-bootstrap";
import noCustomerFoundImg from "src/assets/images/chat/noCustomerFound.png";
import { resetAllOrders } from "src/store/slices/allOrders/allOrders.slice";

import SearchBar from "src/components/SearchBar/SearchBar";
import {
  fetchInnerTicketUpdates,
  resetSearchAllCustomers,
  searchAllCustomers,
  setSearchAllCustomersSearchText,
} from "src/store/slices/innerTicket/innerTicket.slice";
import UserNote from "./children/UserNote/UserNote";
function CustomerSB() {
  const [customerSearchText, setCustomerSearchText] = useState<string>("");
  const dispatch = useAppDispatch();
  const [showWhat, setShowWhat] = useState<string>(
    "customerSidebar" as "customerSidebar" | "customerSearchResult",
  );

  const {
    searchTerm,
    searchCustomersAjaxStatus,
    customersData,
    customersIdList,
    metaData,
  } = useAppSelector((state) => state.innerTicket.searchCustomersData);

  const { customer_id: customerId, ticket_id } = useAppSelector(
    (state) => state.innerTicket.activeTicketInfo,
  );

  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  const currentState = useRef({
    searchCustomersAjaxStatus,
    customersData,
    customersIdList,
    metaData,
    initialCustomer: true,
    ticket_id,
    customerId,
    ui_visibility,
  });

  useEffect(() => {
    currentState.current = {
      searchCustomersAjaxStatus,
      customersData,
      customersIdList,
      metaData,
      initialCustomer: currentState.current.initialCustomer,
      ticket_id,
      customerId,
      ui_visibility,
    };
  }, [
    searchCustomersAjaxStatus,
    customersData,
    customersIdList,
    metaData,
    customerId,
    ui_visibility,
  ]);

  useEffect(() => {
    if (customerId) {
      dispatch(resetShopifySidebar());
      dispatch(resetAllOrders()); // reset all orders data when the customer id is changed
      setShowWhat("customerSidebar");
      // sets the customer id for shopify in customer sidebar
      dispatch(setCustomerId({ id: customerId }));
      // fetch the customer data
      dispatch(
        fetchCustomerData({
          includeTimeline: true,
        }),
      );
      if (
        currentState.current.initialCustomer === false &&
        currentState.current.ticket_id
      ) {
        dispatch(
          fetchInnerTicketUpdates({
            ticketIds: [currentState.current.ticket_id],
          }),
        );
      }
      currentState.current.initialCustomer = false;

      // fetching shopify data for customer sidebar only if it is in ui visibility
      if (
        currentState.current.ui_visibility?.includes(
          "integration_shopify_view",
        ) ||
        currentState.current.ui_visibility?.includes(
          "automation_return_exchange_view",
        )
      ) {
        dispatch(fetchShopifySidebarData());
      }
    }
  }, [customerId]);

  // when the customer search text changes
  useEffect(() => {
    // if search term is not empty
    if (searchTerm.trim().length > 0) {
      // when search term is changed and not  empty, show search results
      setShowWhat("customerSearchResult");
      dispatch(searchAllCustomers());
    } else {
      dispatch(resetShopifySidebar());
      // search term is empty
      setShowWhat("customerSidebar");
      if (currentState.current.customerId) {
        dispatch(setCustomerId({ id: currentState.current.customerId })); // customer id related to inner ticket is set
        dispatch(
          fetchCustomerData({
            includeTimeline: true,
          }),
        );
        if (
          currentState.current.ui_visibility?.includes(
            "integration_shopify_view",
          ) ||
          currentState.current.ui_visibility?.includes(
            "automation_return_exchange_view",
          )
        ) {
          dispatch(fetchShopifySidebarData());
        }
      }
      // dispatch(fetchShopifySidebarData());
    }
  }, [searchTerm]);

  // handle infinite scroll for customers search
  const handleInfiniteScroll = useCallback(() => {
    if (
      currentState.current.metaData.totalCount !== null &&
      currentState.current.searchCustomersAjaxStatus === "fulfilled"
    ) {
      if (currentState.current.metaData.totalCount > 0) {
        dispatch(searchAllCustomers());
      }
    }
  }, [metaData.totalCount]);

  return (
    <div className={`${styles.customerSB} px-2`}>
      <SearchBar
        className={`${styles.search}  my-2`}
        inputClassName={`${styles.input}`}
        placeholder={`Search Customer`}
        value={customerSearchText}
        onChange={(e: any) => {
          setCustomerSearchText(e.target.value);
        }}
        onSearch={(value: any) => {
          dispatch(resetSearchAllCustomers());
          dispatch(setSearchAllCustomersSearchText({ searchText: value }));
          // handleCustomerSearch(value);
          // // console.log(value);
        }}
      />
      {/* Show search results */}
      {searchTerm.length !== 0 && showWhat === "customerSearchResult" && (
        <>
          <InfiniteScroll
            className={`p-2 ${styles.contentSB}`}
            loadMore={handleInfiniteScroll}
            loader={
              searchCustomersAjaxStatus === "pending" &&
              customersIdList.length === 0 ? (
                <div className={`${styles.loading} m-auto`}>
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    className={`d-block m-auto mb-2`}
                  />
                  <span className={styles.serachingCustomerText}>
                    Searching Customer...
                  </span>
                </div>
              ) : undefined
            }
            initialDataLoaded={true}
            errorMessage={
              metaData.totalCount === 0 ||
              searchCustomersAjaxStatus === "rejected" ? (
                <div className={`${styles.nothingFound} m-auto`}>
                  <img
                    src={noCustomerFoundImg}
                    className={`d-block m-auto mb-2 ${styles.nothingFoundImg}`}
                  />
                  <span className={`${styles.nothingFoundText}`}>
                    No customer found
                  </span>
                </div>
              ) : undefined
            }
            hasMore={
              searchCustomersAjaxStatus === "rejected"
                ? false
                : metaData.totalCount === null
                  ? true
                  : customersIdList.length < metaData.totalCount
            }
          >
            {customersIdList.map((id, index) => {
              return (
                <ListCustomer
                  key={id}
                  customerData={customersData[id]}
                  setShowWhat={setShowWhat}
                />
              );
            })}
          </InfiniteScroll>
        </>
      )}

      {/* Show customer sidebar data */}
      {showWhat === "customerSidebar" && (
        <>
          <CustomerInfo />
          <UserNote
            customerId={customerId}
            isInnerTicket={true}
          />
          {/* show shopify details only if it is in ui visibility */}
          {(currentState.current.ui_visibility?.includes(
            "integration_shopify_view",
          ) ||
            currentState.current.ui_visibility?.includes(
              "automation_return_exchange_view",
            )) && <Shopify />}

          {/* <Generalinfo />
              <LiveCart /> */}
        </>
      )}
    </div>
  );
}

export default CustomerSB;
