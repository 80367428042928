import ParseHTML from "src/components/ParseHTML";

const string = `<body
  class="clean-body"
  style="
    line-height: inherit;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    background-color: #ffffff;
  "
>
  <p>
    <!--[if gte mso 9
      ]><xml
        ><o:OfficeDocumentSettings
          ><o:AllowPNG /><o:PixelsPerInch
            >96</o:PixelsPerInch
          ></o:OfficeDocumentSettings
        ></xml
      ><!
    [endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width" /> <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" /> <!--<![endif]-->
    <title></title> <!--[if !mso]><!-->
    <link href="#" rel="stylesheet" type="text/css" />
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400&amp;display=swap"
      rel="stylesheet"
      type="text/css"
    />
    <!--<![endif]-->
    <style type="text/css">
      body {
        margin: 0;
        padding: 0;
      }
      table,
      td,
      tr {
        vertical-align: top;
        border-collapse: collapse;
      }
      * {
        line-height: inherit;
      }
      a[x-apple-data-detectors="true"] {
        color: inherit !important;
        text-decoration: none !important;
      }
    </style>
    <style type="text/css" id="media-query">
      @media (max-width: 620px) {
        .block-grid,
        .col {
          min-width: 320px !important;
          max-width: 100% !important;
          display: block !important;
        }
        .block-grid {
          width: 100% !important;
        }
        .col {
          width: 100% !important;
        }
        .col_cont {
          margin: 0 auto;
        }
        img.fullwidth,
        img.fullwidthOnMobile {
          width: 100% !important;
        }
        .no-stack .col {
          min-width: 0 !important;
          display: table-cell !important;
        }
        .no-stack.two-up .col {
          width: 50% !important;
        }
        .no-stack .col.num2 {
          width: 16.6% !important;
        }
        .no-stack .col.num3 {
          width: 25% !important;
        }
        .no-stack .col.num4 {
          width: 33% !important;
        }
        .no-stack .col.num5 {
          width: 41.6% !important;
        }
        .no-stack .col.num6 {
          width: 50% !important;
        }
        .no-stack .col.num7 {
          width: 58.3% !important;
        }
        .no-stack .col.num8 {
          width: 66.6% !important;
        }
        .no-stack .col.num9 {
          width: 75% !important;
        }
        .no-stack .col.num10 {
          width: 83.3% !important;
        }
        .video-block {
          max-width: none !important;
        }
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          display: none;
          overflow: hidden;
          font-size: 0;
        }
        .desktop_hide {
          display: block !important;
          max-height: none !important;
        }
        .img-container.big img {
          width: auto !important;
        }
      }
    </style>
  </p>
  <div
    style="
      color: transparent;
      visibility: hidden;
      opacity: 0;
      font-size: 0px;
      border: 0;
      max-height: 1px;
      width: 1px;
      margin: 0px;
      padding: 0px;
      border-width: 0px !important;
      display: none !important;
      line-height: 0px !important;
    "
  >
    <img
      border="0"
      width="1"
      height="1"
      src="https://opens.zapier.com/q/BS1UJ1d5kYaBpNBvWsy5dg~~/AAM_vwA~/RgRjJzoUPVcDc3BjQgphQhS1RGFSmXFqUhtqYWtlK3phcGllcmJsb2dAbWVzc2FnZWQuaW9YBAAAAAA~"
      alt=""
    />
  </div>
  <span
    style="
      color: transparent;
      visibility: hidden;
      display: none;
      opacity: 0;
      height: 0;
      width: 0;
      font-size: 0;
    "
    >Learn more about first-touch, last-touch, and linear attribution—and how to
    know when to use each one in your data analysis.</span
  ><img
    src="https://links.zapier.com/e/eo?_t=e2cfe0ff47af4b66b47d47e6db9ec57a&amp;_m=ee925a77f2834962bb405643f847a094&amp;_e=y0lwI_2NeQTZCUT6GVO57ruCEe_lfD8shftTSRq0Kuc99rL2g2445X5_fnbSwUBQ5FN7F9oR9EtoIr9dkyJRZzTIx5TJcgu1AyaDmTeMNjyMks33xkZDZJEbE1Oqow6HHj0ZS5gcOh1dPcD0yx5iTlqKkVV-30yNyJCVk1yYPSlNjF1BVmeT6RHFAl54AxzI"
    style="
      border: 0;
      width: 1px;
      height: 1px;
      border-width: 0px !important;
      display: none !important;
      line-height: 0 !important;
    "
    width="1"
    height="1"
  />
  <!--[if IE]><div class="ie-browser"><![endif]-->
  <table
    class="nl-container"
    style="
      line-height: inherit;
      table-layout: fixed;
      vertical-align: top;
      min-width: 320px;
      border-spacing: 0;
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      background-color: #ffffff;
      width: 100%;
    "
    cellpadding="0"
    cellspacing="0"
    role="presentation"
    width="100%"
    bgcolor="#ffffff"
    valign="top"
  >
    <tbody style="line-height: inherit">
      <tr
        style="
          line-height: inherit;
          border-collapse: collapse;
          vertical-align: top;
        "
        valign="top"
      >
        <td
          style="
            line-height: inherit;
            border-collapse: collapse;
            word-break: break-word;
            vertical-align: top;
          "
          valign="top"
        >
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#ffffff"><![endif]-->
          <div style="line-height: inherit; background-color: #ffffff">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffffff;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffffff;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffffff;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffffff"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffffff;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;background-color:#ffffff;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffffff;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                    >
                      <!--<![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 0px;
                          padding-right: 0px;
                          padding-bottom: 0px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 14px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <style type="text/css">
                              @media all and (max-width: 540px) {
                                body {
                                  padding: 10px !important;
                                  background: #f1f4f5 !important;
                                }
                                .header-logo {
                                  width: 23% !important;
                                }
                                .header-title {
                                  width: 26% !important;
                                }
                                .header-date {
                                  width: 50% !important;
                                }
                                .header-title td,
                                .header-title td div {
                                  text-align: left !important;
                                  padding: 0 0 3px !important;
                                }
                                .header-date td,
                                .header-date td div {
                                  text-align: right !important;
                                  padding: 0 0 3px !important;
                                }
                              }
                              @media all and (max-width: 540px) {
                                .zap-template .icon-container {
                                  width: 100% !important;
                                  max-width: 200px !important;
                                  text-align: center !important;
                                }
                                .zap-template .icon-container div {
                                  width: 20% !important;
                                }
                                .zap-template .text-container {
                                  width: 100% !important;
                                }
                                .zap-template .text-container div {
                                  text-align: center !important;
                                }
                                .zap-template .text-container .template-text {
                                  padding: 5px 0 !important;
                                }
                                .zap-template .button-container {
                                  width: 100% !important;
                                  padding-top: 15px !important;
                                  padding-bottom: 10px !important;
                                }
                              }
                              @media all and (max-width: 480px) {
                                .header-logo {
                                  width: 100% !important;
                                }
                                .header-title {
                                  width: 100% !important;
                                }
                                .header-date {
                                  width: 100% !important;
                                }
                                .header-title td,
                                .header-title td div {
                                  text-align: center !important;
                                  padding: 0 30px 5px !important;
                                }
                                .header-date td,
                                .header-date td div {
                                  text-align: center !important;
                                  padding: 0 30px !important;
                                }
                                .h1 div {
                                  font-size: 28px !important;
                                }
                                .h2 div {
                                  font-size: 24px !important;
                                }
                                .h3 div {
                                  font-size: 18px !important;
                                }
                                .h1,
                                .h2,
                                .h3,
                                .h4,
                                .meta,
                                .content-card .text,
                                .content-card .btn,
                                .btn-wrapper .btn,
                                .content-wrapper > table > tbody > tr > td,
                                .zap-wrapper > table > tbody > tr > td,
                                .callout-wrapper > table > tbody > tr > td,
                                .divider-wrapper > table > tbody > tr > td,
                                .footer td.text {
                                  padding-left: 10px !important;
                                  padding-right: 10px !important;
                                }
                                .content-wrapper .text {
                                  padding-left: 0 !important;
                                  padding-right: 0 !important;
                                }
                                .content-card .h1 div,
                                .content-card .h4 div,
                                .content-card .meta div,
                                .card-wrapper .meta div {
                                  text-align: center !important;
                                }
                                .btn table {
                                  width: 100% !important;
                                }
                                .btn table a {
                                  display: block !important;
                                }
                                .btn {
                                  padding: 10px 0 0 !important;
                                }
                                .btn-sm-important table a,
                                .btn-sm-secondary table a {
                                  padding: 20px 30px !important;
                                }
                                .img-list .image {
                                  width: 22% !important;
                                }
                                .img-list .content {
                                  width: 78% !important;
                                }
                                .icon-list .icon {
                                  width: 17% !important;
                                }
                                .icon-list .content {
                                  width: 80% !important;
                                }
                                .img-list > table > tbody > tr > td,
                                .icon-list > table > tbody > tr > td {
                                  padding-left: 10px !important;
                                  padding-right: 10px !important;
                                }
                                .zap-wrapper > table > tbody > tr > td,
                                .zap-task > table > tbody > tr > td {
                                  padding: 10px !important;
                                }
                                .zap-task .icon-container {
                                  width: 100% !important;
                                  max-width: 200px !important;
                                  text-align: center !important;
                                }
                                .zap-task .icon-container div {
                                  width: 20% !important;
                                }
                                .zap-task .text-container {
                                  width: 100% !important;
                                }
                                .zap-task .text-container div {
                                  text-align: center !important;
                                }
                                .zap-task .text-container .template-text {
                                  padding: 5px 0 5px 20px !important;
                                }
                                .footer > table > tbody > tr > td {
                                  padding: 0 !important;
                                }
                                .unsub-links div {
                                  text-align: center !important;
                                  padding: 3px 0 !important;
                                }
                              }
                              @media all and (max-width: 380px) {
                                .zap-task .icon-container {
                                  width: 100% !important;
                                }
                                .zap-task .text-container .template-text {
                                  padding: 5px 0 !important;
                                }
                              }
                              @media all and (max-width: 540px) {
                                .zap-template .icon-container {
                                  width: 100% !important;
                                  max-width: 200px !important;
                                  text-align: center !important;
                                }
                                .zap-template .icon-container div {
                                  width: 20% !important;
                                }
                                .zap-template .text-container {
                                  width: 100% !important;
                                }
                                .zap-template .text-container div {
                                  text-align: center !important;
                                }
                                .zap-template .text-container .template-text {
                                  padding: 5px 0 !important;
                                }
                                .zap-template .button-container {
                                  width: 100% !important;
                                  padding-top: 15px !important;
                                  padding-bottom: 10px !important;
                                }
                              }
                              @media all and (max-width: 480px) {
                                .zap-task .icon-container {
                                  width: 100% !important;
                                  max-width: 200px !important;
                                  text-align: center !important;
                                }
                                .zap-task .icon-container div {
                                  width: 20% !important;
                                }
                                .zap-task .text-container {
                                  width: 100% !important;
                                }
                                .zap-task .text-container div {
                                  text-align: center !important;
                                }
                                .zap-task .text-container .template-text {
                                  padding: 5px 0 5px 20px !important;
                                }
                              }
                              @media all and (max-width: 380px) {
                                .zap-task .icon-container {
                                  width: 100% !important;
                                }
                                .zap-task .text-container .template-text {
                                  padding: 5px 0 !important;
                                }
                              }</style
                            ><!-- START HEADER -->
                            <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-outlook" style="width:600px;" width="600"> <tr> <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                          </p>
                          <div
                            class="section"
                            style="margin: 0px auto; max-width: 600px"
                          >
                            <table
                              align="center"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="width: 100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style="
                                      direction: ltr;
                                      font-size: 0px;
                                      padding: 0px 40px;
                                      padding-bottom: 10px;
                                      text-align: center;
                                    "
                                  >
                                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"> <tr> <td class="" style="vertical-align:top;width:520px;"><![endif]-->
                                    <div
                                      class="mj-column-per-100 mj-outlook-group-fix"
                                      style="
                                        font-size: 0px;
                                        text-align: left;
                                        direction: ltr;
                                        display: inline-block;
                                        vertical-align: top;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                        style="vertical-align: top"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                font-size: 0px;
                                                padding: 25px 0px 20px;
                                                word-break: break-word;
                                              "
                                            >
                                              <table
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="width: 150px">
                                                      <span
                                                        style="
                                                          font-weight: 600;
                                                          color: #136bf5;
                                                          text-decoration: none;
                                                        "
                                                      >
                                                        <img
                                                          alt="Zapier blog"
                                                          height="auto"
                                                          src="https://cdn.zapier.com/storage/photos/e69823561238d9dad0e422a83e453f18.png"
                                                          style="
                                                            border: 0;
                                                            display: block;
                                                            outline: none;
                                                            text-decoration: none;
                                                            height: auto;
                                                            width: 100%;
                                                            font-size: 13px;
                                                          "
                                                          width="150"
                                                        />
                                                      </span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if mso | IE]></td> </tr> </table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!--[if mso | IE]></td> </tr> </table><![endif]-->
                          <!-- END HEADER -->
                          <p></p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffffff">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffffff;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffffff;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffffff;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffffff"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffffff;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:10px;background-color:#ffffff;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffffff;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 0px;
                        padding-bottom: 10px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                    >
                      <!--<![endif]-->
                      <div
                        class="img-container center autowidth fullwidth big"
                        align="center"
                        style="
                          line-height: inherit;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                        [endif]--><span
                          
                          style="line-height: inherit; outline: none"
                          tabindex="-1"
                          ><img
                            class="center autowidth fullwidth"
                            align="center"
                            border="0"
                            src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/e2cfe0ff47af4b66b47d47e6db9ec57a/editor_images/data-attribution-models-00-hero%20%281%29.jpeg"
                            style="
                              line-height: inherit;
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 600px;
                              max-width: 100%;
                              display: block;
                            "
                            width="600"
                        /></span>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffffff">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffffff;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffffff;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffffff;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffffff"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffffff;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 40px; padding-left: 40px; padding-top:0px; padding-bottom:40px;background-color:#ffffff;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffffff;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 0px;
                        padding-bottom: 40px;
                        padding-right: 40px;
                        padding-left: 40px;
                      "
                    >
                      <!--<![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 5px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              >Business tips</strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="font-size: 12px; line-height: 1.5; color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 24px;
                              line-height: 1.5;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 36px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span
                              style="
                                line-height: inherit;
                                font-size: 24px;
                                color: #333333;
                              "
                              ><strong style="line-height: inherit"
                                >A brief guide to data attribution
                                models</strong
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <table
                        class="divider"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          line-height: inherit;
                          table-layout: fixed;
                          vertical-align: top;
                          border-spacing: 0;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          min-width: 100%;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        "
                        role="presentation"
                        valign="top"
                      >
                        <tbody style="line-height: inherit">
                          <tr
                            style="
                              line-height: inherit;
                              border-collapse: collapse;
                              vertical-align: top;
                            "
                            valign="top"
                          >
                            <td
                              class="divider_inner"
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                word-break: break-word;
                                vertical-align: top;
                                min-width: 100%;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                                padding-top: 0px;
                                padding-right: 0px;
                                padding-bottom: 0px;
                                padding-left: 0px;
                              "
                              valign="top"
                            >
                              <table
                                class="divider_content"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  line-height: inherit;
                                  table-layout: fixed;
                                  vertical-align: top;
                                  border-spacing: 0;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-top: 0px solid transparent;
                                  height: 10px;
                                  width: 100%;
                                "
                                align="center"
                                role="presentation"
                                height="10"
                                valign="top"
                              >
                                <tbody style="line-height: inherit">
                                  <tr
                                    style="
                                      line-height: inherit;
                                      border-collapse: collapse;
                                      vertical-align: top;
                                    "
                                    valign="top"
                                  >
                                    <td
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        word-break: break-word;
                                        vertical-align: top;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      "
                                      height="10"
                                      valign="top"
                                    >
                                      <span style="line-height: inherit"></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="line-height: 1.5; font-size: 12px; color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              line-height: 1.5;
                              word-break: break-word;
                              font-size: 16px;
                              mso-line-height-alt: 24px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; font-size: 16px"
                              >Data-backed decisions are a must, but which data
                              should you use to make those decisions? According
                              to </span
                            ><span style="line-height: inherit; font-size: 16px"
                              >Zapier Data Manager Lukas Toma, there's no
                              right<em style="line-height: inherit">&nbsp;</em
                              >answer. (Please don't click away.)</span
                            >
                          </p>
                          <p
                            style="
                              margin: 0;
                              line-height: 1.5;
                              word-break: break-word;
                              mso-line-height-alt: 18px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            &nbsp;
                          </p>
                          <p
                            style="
                              margin: 0;
                              line-height: 1.5;
                              word-break: break-word;
                              font-size: 16px;
                              mso-line-height-alt: 24px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; font-size: 16px"
                              >There
                              <em style="line-height: inherit">are</em> some
                              guidelines you can follow. Here, Lukas walks
                              through first-touch, last-touch, and linear data
                              attribution, making them feel way less scary than
                              they sound—and showing when and how to use each
                              one in your data analysis.&nbsp;</span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <div
                        class="button-container"
                        align="left"
                        style="
                          line-height: inherit;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 20px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"><tr><td style="padding-top: 20px; padding-right: 0px; padding-bottom: 20px; padding-left: 0px" align="left"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="#" style="height:31.5pt;width:174pt;v-text-anchor:middle;" arcsize="24%" stroke="false" fillcolor="#136bf5"><w:anchorlock/><v:textbox inset="0,0,0,0"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><!
                        [endif]--><span
                         
                          style="
                            line-height: inherit;
                            -webkit-text-size-adjust: none;
                            text-decoration: none;
                            display: inline-block;
                            color: #ffffff;
                            background-color: #136bf5;
                            border-radius: 10px;
                            -webkit-border-radius: 10px;
                            -moz-border-radius: 10px;
                            width: auto;
                            width: auto;
                            border-top: 1px solid #136bf5;
                            border-right: 1px solid #136bf5;
                            border-bottom: 1px solid #136bf5;
                            border-left: 1px solid #136bf5;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            font-family: 'Open Sans', 'Helvetica Neue',
                              Helvetica, Arial, sans-serif;
                            text-align: center;
                            mso-border-alt: none;
                            word-break: keep-all;
                          "
                          ><span
                            style="
                              line-height: inherit;
                              padding-left: 30px;
                              padding-right: 30px;
                              font-size: 16px;
                              display: inline-block;
                              letter-spacing: undefined;
                            "
                            ><span
                              style="
                                font-size: 16px;
                                line-height: 2;
                                word-break: break-word;
                                mso-line-height-alt: 32px;
                              "
                              ><strong style="line-height: inherit"
                                >Choose the right data</strong
                              ></span
                            ></span
                          ></span
                        >
                        <!--[if mso]></center></v:textbox></v:roundrect></td></tr></table><![endif]-->
                      </div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffede6">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffede6;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffede6;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffede6;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffede6"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffede6;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top:20px; padding-bottom:15px;background-color:#ffede6;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffede6;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 20px;
                        padding-bottom: 15px;
                        padding-right: 10px;
                        padding-left: 10px;
                      "
                    >
                      <!--<![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 10px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="font-size: 12px; line-height: 1.5; color: #333333; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.5;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 21px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              ><span
                                style="line-height: inherit; font-size: 16px"
                                ><span
                                  style="line-height: inherit; color: #333333"
                                  >Recommended reading from the Zapier blog
                                  team</span
                                ></span
                              ></strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffede6">
            <div
              class="block-grid two-up"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffede6;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffede6;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffede6;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffede6"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="300" style="background-color:#ffede6;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top:5px; padding-bottom:30px;background-color:#ffede6;"><![endif]-->
                <div
                  class="col num6"
                  style="
                    line-height: inherit;
                    display: table-cell;
                    vertical-align: top;
                    max-width: 320px;
                    background-color: #ffede6;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 5px;
                        padding-bottom: 30px;
                        padding-right: 10px;
                        padding-left: 10px;
                      "
                    >
                      <!--<![endif]-->
                      <div
                        class="img-container center autowidth fullwidth"
                        align="center"
                        style="
                          line-height: inherit;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                        [endif]--><span
                         
                          style="line-height: inherit; outline: none"
                          tabindex="-1"
                          ><img
                            class="center autowidth fullwidth"
                            align="center"
                            border="0"
                            src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/e2cfe0ff47af4b66b47d47e6db9ec57a/editor_images/15-minute-break-ideas-00-hero.jpeg"
                            style="
                              line-height: inherit;
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 280px;
                              max-width: 100%;
                              display: block;
                            "
                            width="280"
                        /></span>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 5px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              >Productivity</strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="font-size: 12px; line-height: 1.5; color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.5;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 21px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; color: #333333"
                              ><span
                                style="line-height: inherit; font-size: 22px"
                                ><strong style="line-height: inherit"
                                  >6 better ways to spend a break on your
                                  phone</strong
                                ></span
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #555555;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 10px;
                          padding-right: 0px;
                          padding-bottom: 10px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            font-size: 12px;
                            line-height: 1.2;
                            color: #555555;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              ><span
                                style="line-height: inherit; font-size: 16px"
                                ><span
                                 
                                  style="
                                    line-height: inherit;
                                    text-decoration: none;
                                    color: #136bf5;
                                  "
                                  rel="noopener"
                                  >Stop doomscrolling</span
                                ></span
                              ></strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <table
                        class="divider"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          line-height: inherit;
                          table-layout: fixed;
                          vertical-align: top;
                          border-spacing: 0;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          min-width: 100%;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        "
                        role="presentation"
                        valign="top"
                      >
                        <tbody style="line-height: inherit">
                          <tr
                            style="
                              line-height: inherit;
                              border-collapse: collapse;
                              vertical-align: top;
                            "
                            valign="top"
                          >
                            <td
                              class="divider_inner"
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                word-break: break-word;
                                vertical-align: top;
                                min-width: 100%;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                                padding-top: 0px;
                                padding-right: 0px;
                                padding-bottom: 0px;
                                padding-left: 0px;
                              "
                              valign="top"
                            >
                              <table
                                class="divider_content"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  line-height: inherit;
                                  table-layout: fixed;
                                  vertical-align: top;
                                  border-spacing: 0;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-top: 20px solid transparent;
                                  height: 30px;
                                  width: 100%;
                                "
                                align="center"
                                role="presentation"
                                height="30"
                                valign="top"
                              >
                                <tbody style="line-height: inherit">
                                  <tr
                                    style="
                                      line-height: inherit;
                                      border-collapse: collapse;
                                      vertical-align: top;
                                    "
                                    valign="top"
                                  >
                                    <td
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        word-break: break-word;
                                        vertical-align: top;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      "
                                      height="30"
                                      valign="top"
                                    >
                                      <span style="line-height: inherit"></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="img-container center autowidth fullwidth"
                        align="center"
                        style="
                          line-height: inherit;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                        [endif]--><a
                          href="#"
                          target="_blank"
                          style="line-height: inherit; outline: none"
                          tabindex="-1"
                          ><img
                            class="center autowidth fullwidth"
                            align="center"
                            border="0"
                            src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/e2cfe0ff47af4b66b47d47e6db9ec57a/editor_images/data-reports-red-00-hero%20%282%29.jpeg"
                            style="
                              line-height: inherit;
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 280px;
                              max-width: 100%;
                              display: block;
                            "
                            width="280"
                        /></a>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 5px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; color: #333333"
                              ><strong style="line-height: inherit"
                                >Data reports</strong
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="font-size: 12px; line-height: 1.5; color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.5;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 21px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; color: #333333"
                              ><span
                                style="line-height: inherit; font-size: 22px"
                                ><strong style="line-height: inherit"
                                  >Canada trails the U.S. in automation
                                  use</strong
                                ></span
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #555555;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 10px;
                          padding-right: 0px;
                          padding-bottom: 10px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            font-size: 12px;
                            line-height: 1.2;
                            color: #555555;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              ><span
                                style="line-height: inherit; font-size: 16px"
                                ><span
                               
                                  style="
                                    line-height: inherit;
                                    text-decoration: none;
                                    color: #136bf5;
                                  "
                                  rel="noopener"
                                  >Read the report</span
                                ></span
                              ></strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if !mso]><!-->
                      <div
                        class="desktop_hide"
                        style="
                          line-height: inherit;
                          mso-hide: all;
                          display: none;
                          max-height: 0px;
                          overflow: hidden;
                        "
                      >
                        <table
                          class="divider"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="
                            line-height: inherit;
                            table-layout: fixed;
                            vertical-align: top;
                            border-spacing: 0;
                            border-collapse: collapse;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            min-width: 100%;
                            -ms-text-size-adjust: 100%;
                            -webkit-text-size-adjust: 100%;
                          "
                          role="presentation"
                          valign="top"
                        >
                          <tbody style="line-height: inherit">
                            <tr
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                vertical-align: top;
                              "
                              valign="top"
                            >
                              <td
                                class="divider_inner"
                                style="
                                  line-height: inherit;
                                  border-collapse: collapse;
                                  word-break: break-word;
                                  vertical-align: top;
                                  min-width: 100%;
                                  -ms-text-size-adjust: 100%;
                                  -webkit-text-size-adjust: 100%;
                                  padding-top: 0px;
                                  padding-right: 0px;
                                  padding-bottom: 0px;
                                  padding-left: 0px;
                                "
                                valign="top"
                              >
                                <table
                                  class="divider_content"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    line-height: inherit;
                                    table-layout: fixed;
                                    vertical-align: top;
                                    border-spacing: 0;
                                    border-collapse: collapse;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-top: 20px solid transparent;
                                    height: 20px;
                                    width: 100%;
                                  "
                                  align="center"
                                  role="presentation"
                                  height="20"
                                  valign="top"
                                >
                                  <tbody style="line-height: inherit">
                                    <tr
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        vertical-align: top;
                                      "
                                      valign="top"
                                    >
                                      <td
                                        style="
                                          line-height: inherit;
                                          border-collapse: collapse;
                                          word-break: break-word;
                                          vertical-align: top;
                                          -ms-text-size-adjust: 100%;
                                          -webkit-text-size-adjust: 100%;
                                        "
                                        height="20"
                                        valign="top"
                                      >
                                        <span
                                          style="line-height: inherit"
                                        ></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--<![endif]--> <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td><td align="center" width="300" style="background-color:#ffede6;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top:5px; padding-bottom:0px;background-color:#ffede6;"><![endif]-->
                <div
                  class="col num6"
                  style="
                    line-height: inherit;
                    display: table-cell;
                    vertical-align: top;
                    max-width: 320px;
                    background-color: #ffede6;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 5px;
                        padding-bottom: 0px;
                        padding-right: 10px;
                        padding-left: 10px;
                      "
                    >
                      <!--<![endif]-->
                      <div
                        class="img-container center autowidth fullwidth"
                        align="center"
                        style="
                          line-height: inherit;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                        [endif]--><a
                          href="#"
                          target="_blank"
                          style="line-height: inherit; outline: none"
                          tabindex="-1"
                          ><img
                            class="center autowidth fullwidth"
                            align="center"
                            border="0"
                            src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/e2cfe0ff47af4b66b47d47e6db9ec57a/editor_images/automate-video-calls-00-hero%20%282%29.jpeg"
                            style="
                              line-height: inherit;
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 280px;
                              max-width: 100%;
                              display: block;
                            "
                            width="280"
                        /></a>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 5px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              >Productivity</strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="line-height: 1.5; font-size: 12px; color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              line-height: 1.5;
                              font-size: 22px;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 33px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span
                              style="
                                line-height: inherit;
                                font-size: 22px;
                                color: #333333;
                              "
                              ><strong style="line-height: inherit"
                                >4 video meeting tips that work in the
                                office</strong
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #555555;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 10px;
                          padding-right: 0px;
                          padding-bottom: 10px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            font-size: 12px;
                            line-height: 1.2;
                            color: #555555;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              ><span
                                style="line-height: inherit; font-size: 16px"
                                ><span
                                  
                                  style="
                                    line-height: inherit;
                                    text-decoration: none;
                                    color: #136bf5;
                                  "
                                  rel="noopener"
                                  >Learn from WFH</span
                                ></span
                              ></strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <table
                        class="divider"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          line-height: inherit;
                          table-layout: fixed;
                          vertical-align: top;
                          border-spacing: 0;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          min-width: 100%;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        "
                        role="presentation"
                        valign="top"
                      >
                        <tbody style="line-height: inherit">
                          <tr
                            style="
                              line-height: inherit;
                              border-collapse: collapse;
                              vertical-align: top;
                            "
                            valign="top"
                          >
                            <td
                              class="divider_inner"
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                word-break: break-word;
                                vertical-align: top;
                                min-width: 100%;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                                padding-top: 0px;
                                padding-right: 0px;
                                padding-bottom: 0px;
                                padding-left: 0px;
                              "
                              valign="top"
                            >
                              <table
                                class="divider_content"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  line-height: inherit;
                                  table-layout: fixed;
                                  vertical-align: top;
                                  border-spacing: 0;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-top: 20px solid transparent;
                                  height: 30px;
                                  width: 100%;
                                "
                                align="center"
                                role="presentation"
                                height="30"
                                valign="top"
                              >
                                <tbody style="line-height: inherit">
                                  <tr
                                    style="
                                      line-height: inherit;
                                      border-collapse: collapse;
                                      vertical-align: top;
                                    "
                                    valign="top"
                                  >
                                    <td
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        word-break: break-word;
                                        vertical-align: top;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      "
                                      height="30"
                                      valign="top"
                                    >
                                      <span style="line-height: inherit"></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        class="img-container center autowidth fullwidth"
                        align="center"
                        style="
                          line-height: inherit;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                      >
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                        [endif]--><a
                          href="#"
                          target="_blank"
                          style="line-height: inherit; outline: none"
                          tabindex="-1"
                          ><img
                            class="center autowidth fullwidth"
                            align="center"
                            border="0"
                            src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/669d5713-9b6a-46bb-bd7e-c542cff6dd6a/e2cfe0ff47af4b66b47d47e6db9ec57a/editor_images/prioritize-by-dropping-tasks-00-hero.jpeg"
                            style="
                              line-height: inherit;
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 280px;
                              max-width: 100%;
                              display: block;
                            "
                            width="280"
                        /></a>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 20px;
                          padding-right: 0px;
                          padding-bottom: 5px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            line-height: 1.2;
                            font-size: 12px;
                            color: #333333;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              >Productivity</strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial"><![endif]-->
                      <div
                        style="color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; line-height: 1.5; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;"
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="font-size: 12px; line-height: 1.5; color: #354147; font-family: 'Open Sans', Helvetica, Arial, sans-serif&quot; mso-line-height-alt: 18px;"
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.5;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 21px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <span style="line-height: inherit; color: #333333"
                              ><span
                                style="line-height: inherit; font-size: 22px"
                                ><strong style="line-height: inherit"
                                  >Stop crossing things off your to-do
                                  list</strong
                                ></span
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #555555;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 10px;
                          padding-right: 0px;
                          padding-bottom: 10px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            font-size: 12px;
                            line-height: 1.2;
                            color: #555555;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              text-align: left;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <strong style="line-height: inherit"
                              ><span
                                style="line-height: inherit; font-size: 16px"
                                ><span
                                  
                                  style="
                                    line-height: inherit;
                                    text-decoration: none;
                                    color: #136bf5;
                                  "
                                  rel="noopener"
                                  >Highlight what's done</span
                                ></span
                              ></strong
                            >
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if !mso]><!-->
                      <div
                        class="desktop_hide"
                        style="
                          line-height: inherit;
                          mso-hide: all;
                          display: none;
                          max-height: 0px;
                          overflow: hidden;
                        "
                      >
                        <table
                          class="divider"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="
                            line-height: inherit;
                            table-layout: fixed;
                            vertical-align: top;
                            border-spacing: 0;
                            border-collapse: collapse;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            min-width: 100%;
                            -ms-text-size-adjust: 100%;
                            -webkit-text-size-adjust: 100%;
                          "
                          role="presentation"
                          valign="top"
                        >
                          <tbody style="line-height: inherit">
                            <tr
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                vertical-align: top;
                              "
                              valign="top"
                            >
                              <td
                                class="divider_inner"
                                style="
                                  line-height: inherit;
                                  border-collapse: collapse;
                                  word-break: break-word;
                                  vertical-align: top;
                                  min-width: 100%;
                                  -ms-text-size-adjust: 100%;
                                  -webkit-text-size-adjust: 100%;
                                  padding-top: 0px;
                                  padding-right: 0px;
                                  padding-bottom: 0px;
                                  padding-left: 0px;
                                "
                                valign="top"
                              >
                                <table
                                  class="divider_content"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    line-height: inherit;
                                    table-layout: fixed;
                                    vertical-align: top;
                                    border-spacing: 0;
                                    border-collapse: collapse;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-top: 0px solid transparent;
                                    height: 20px;
                                    width: 100%;
                                  "
                                  align="center"
                                  role="presentation"
                                  height="20"
                                  valign="top"
                                >
                                  <tbody style="line-height: inherit">
                                    <tr
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        vertical-align: top;
                                      "
                                      valign="top"
                                    >
                                      <td
                                        style="
                                          line-height: inherit;
                                          border-collapse: collapse;
                                          word-break: break-word;
                                          vertical-align: top;
                                          -ms-text-size-adjust: 100%;
                                          -webkit-text-size-adjust: 100%;
                                        "
                                        height="20"
                                        valign="top"
                                      >
                                        <span
                                          style="line-height: inherit"
                                        ></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!--<![endif]--> <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffffff">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffffff;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffffff;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffffff;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffffff"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffffff;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;background-color:#ffffff;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffffff;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                    >
                      <!--<![endif]-->
                      <table
                        class="divider"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          line-height: inherit;
                          table-layout: fixed;
                          vertical-align: top;
                          border-spacing: 0;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          min-width: 100%;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        "
                        role="presentation"
                        valign="top"
                      >
                        <tbody style="line-height: inherit">
                          <tr
                            style="
                              line-height: inherit;
                              border-collapse: collapse;
                              vertical-align: top;
                            "
                            valign="top"
                          >
                            <td
                              class="divider_inner"
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                word-break: break-word;
                                vertical-align: top;
                                min-width: 100%;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                                padding-top: 5px;
                                padding-right: 5px;
                                padding-bottom: 5px;
                                padding-left: 5px;
                              "
                              valign="top"
                            >
                              <table
                                class="divider_content"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  line-height: inherit;
                                  table-layout: fixed;
                                  vertical-align: top;
                                  border-spacing: 0;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-top: 0px solid transparent;
                                  height: 5px;
                                  width: 100%;
                                "
                                align="center"
                                role="presentation"
                                height="5"
                                valign="top"
                              >
                                <tbody style="line-height: inherit">
                                  <tr
                                    style="
                                      line-height: inherit;
                                      border-collapse: collapse;
                                      vertical-align: top;
                                    "
                                    valign="top"
                                  >
                                    <td
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        word-break: break-word;
                                        vertical-align: top;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      "
                                      height="5"
                                      valign="top"
                                    >
                                      <span style="line-height: inherit"></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        class="divider"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="
                          line-height: inherit;
                          table-layout: fixed;
                          vertical-align: top;
                          border-spacing: 0;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          min-width: 100%;
                          -ms-text-size-adjust: 100%;
                          -webkit-text-size-adjust: 100%;
                        "
                        role="presentation"
                        valign="top"
                      >
                        <tbody style="line-height: inherit">
                          <tr
                            style="
                              line-height: inherit;
                              border-collapse: collapse;
                              vertical-align: top;
                            "
                            valign="top"
                          >
                            <td
                              class="divider_inner"
                              style="
                                line-height: inherit;
                                border-collapse: collapse;
                                word-break: break-word;
                                vertical-align: top;
                                min-width: 100%;
                                -ms-text-size-adjust: 100%;
                                -webkit-text-size-adjust: 100%;
                                padding-top: 5px;
                                padding-right: 5px;
                                padding-bottom: 5px;
                                padding-left: 5px;
                              "
                              valign="top"
                            >
                              <table
                                class="divider_content"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  line-height: inherit;
                                  table-layout: fixed;
                                  vertical-align: top;
                                  border-spacing: 0;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-top: 0px solid transparent;
                                  height: 5px;
                                  width: 100%;
                                "
                                align="center"
                                role="presentation"
                                height="5"
                                valign="top"
                              >
                                <tbody style="line-height: inherit">
                                  <tr
                                    style="
                                      line-height: inherit;
                                      border-collapse: collapse;
                                      vertical-align: top;
                                    "
                                    valign="top"
                                  >
                                    <td
                                      style="
                                        line-height: inherit;
                                        border-collapse: collapse;
                                        word-break: break-word;
                                        vertical-align: top;
                                        -ms-text-size-adjust: 100%;
                                        -webkit-text-size-adjust: 100%;
                                      "
                                      height="5"
                                      valign="top"
                                    >
                                      <span style="line-height: inherit"></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="line-height: inherit; background-color: #ffffff">
            <div
              class="block-grid"
              style="
                line-height: inherit;
                min-width: 320px;
                max-width: 600px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
                margin: 0 auto;
                background-color: #ffffff;
              "
            >
              <div
                style="
                  line-height: inherit;
                  border-collapse: collapse;
                  display: table;
                  width: 100%;
                  background-color: #ffffff;
                "
              >
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#ffffff;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#ffffff"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#ffffff;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;background-color:#ffffff;"><![endif]-->
                <div
                  class="col num12"
                  style="
                    line-height: inherit;
                    min-width: 320px;
                    max-width: 600px;
                    display: table-cell;
                    vertical-align: top;
                    background-color: #ffffff;
                    width: 600px;
                  "
                >
                  <div
                    class="col_cont"
                    style="line-height: inherit; width: 100% !important"
                  >
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="
                        line-height: inherit;
                        border-top: 0px solid transparent;
                        border-left: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        border-right: 0px solid transparent;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                    >
                      <!--<![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
                      <div
                        style="
                          color: #333333;
                          font-family: Open Sans, Helvetica, Arial, sans-serif;
                          line-height: 1.2;
                          padding-top: 0px;
                          padding-right: 0px;
                          padding-bottom: 0px;
                          padding-left: 0px;
                        "
                      >
                        <div
                          class="txtTinyMce-wrapper"
                          style="
                            font-size: 12px;
                            line-height: 1.2;
                            font-family: Open Sans, Helvetica, Arial, sans-serif;
                            color: #333333;
                            mso-line-height-alt: 14px;
                          "
                        >
                          <p
                            style="
                              margin: 0;
                              font-size: 14px;
                              line-height: 1.2;
                              word-break: break-word;
                              mso-line-height-alt: 17px;
                              margin-top: 0;
                              margin-bottom: 0;
                            "
                          >
                            <!-- START FOOTER --><!-- Social Icons -->
                            <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-outlook" style="width:600px" width="600"> <tr> <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly"><![endif]-->
                          </p>
                          <div
                            class="section"
                            style="margin: 0 auto; max-width: 600px"
                          >
                            <table
                              align="center"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="width: 100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style="
                                      direction: ltr;
                                      font-size: 0;
                                      padding: 30px 0 0 0;
                                      text-align: center;
                                    "
                                  >
                                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"> <tr> <td class="column-outlook" style="vertical-align:top;width:600px"><![endif]-->
                                    <div
                                      class="mj-column-per-100 mj-outlook-group-fix column"
                                      style="
                                        font-size: 0px;
                                        text-align: left;
                                        direction: ltr;
                                        display: inline-block;
                                        vertical-align: top;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                        style="vertical-align: top"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                font-size: 0;
                                                padding: 10px 25px;
                                                word-break: break-word;
                                              "
                                            >
                                              <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"> <tr> <td><![endif]-->
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="padding: 0 10px">
                                                      <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="
                                                          border-radius: 3px;
                                                          width: 30px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style="
                                                                font-size: 0;
                                                                height: 30px;
                                                                vertical-align: middle;
                                                                width: 30px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                target="_blank"
                                                                style="
                                                                  font-weight: 600;
                                                                  color: #136bf5;
                                                                  text-decoration: none;
                                                                "
                                                              >
                                                                <img
                                                                  height="30"
                                                                  src="https://cdn.zapier.com/storage/photos/fa3665ee28f2c7b497885739a023e748.png"
                                                                  style="
                                                                    border-radius: 3px;
                                                                    display: block;
                                                                  "
                                                                  width="30"
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <!--[if mso | IE]></td> <td><![endif]-->
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="padding: 0 10px">
                                                      <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="
                                                          border-radius: 3px;
                                                          width: 30px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style="
                                                                font-size: 0;
                                                                height: 30px;
                                                                vertical-align: middle;
                                                                width: 30px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                target="_blank"
                                                                style="
                                                                  font-weight: 600;
                                                                  color: #136bf5;
                                                                  text-decoration: none;
                                                                "
                                                              >
                                                                <img
                                                                  height="30"
                                                                  src="https://cdn.zapier.com/storage/photos/1e4135b2dba28b0a8b2372e450fc2c25.png"
                                                                  style="
                                                                    border-radius: 3px;
                                                                    display: block;
                                                                  "
                                                                  width="30"
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <!--[if mso | IE]></td> <td><![endif]-->
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="padding: 0 10px">
                                                      <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="
                                                          border-radius: 3px;
                                                          width: 30px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style="
                                                                font-size: 0;
                                                                height: 30px;
                                                                vertical-align: middle;
                                                                width: 30px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                target="_blank"
                                                                style="
                                                                  font-weight: 600;
                                                                  color: #136bf5;
                                                                  text-decoration: none;
                                                                "
                                                              >
                                                                <img
                                                                  height="30"
                                                                  src="https://cdn.zapier.com/storage/photos/d5073b22813bade66d0f7865d0175686.png"
                                                                  style="
                                                                    border-radius: 3px;
                                                                    display: block;
                                                                  "
                                                                  width="30"
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <!--[if mso | IE]></td> <td><![endif]-->
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="padding: 0 10px">
                                                      <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="
                                                          border-radius: 3px;
                                                          width: 30px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style="
                                                                font-size: 0;
                                                                height: 30px;
                                                                vertical-align: middle;
                                                                width: 30px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                target="_blank"
                                                                style="
                                                                  font-weight: 600;
                                                                  color: #136bf5;
                                                                  text-decoration: none;
                                                                "
                                                              >
                                                                <img
                                                                  height="30"
                                                                  src="https://cdn.zapier.com/storage/photos/d1b6de9c3059007c1516f4dd9683fa75.png"
                                                                  style="
                                                                    border-radius: 3px;
                                                                    display: block;
                                                                  "
                                                                  width="30"
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <!--[if mso | IE]></td> <td><![endif]-->
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td style="padding: 0 10px">
                                                      <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="
                                                          border-radius: 3px;
                                                          width: 30px;
                                                        "
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style="
                                                                font-size: 0;
                                                                height: 30px;
                                                                vertical-align: middle;
                                                                width: 30px;
                                                              "
                                                            >
                                                              <span
                                                               
                                                                target="_blank"
                                                                style="
                                                                  font-weight: 600;
                                                                  color: #136bf5;
                                                                  text-decoration: none;
                                                                "
                                                              >
                                                                <img
                                                                  height="30"
                                                                  src="https://cdn.zapier.com/storage/photos/e79880fa7972a6a9996b73a71be44a02.png"
                                                                  style="
                                                                    border-radius: 3px;
                                                                    display: block;
                                                                  "
                                                                  width="30"
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <!--[if mso | IE]></td> </tr> </table><![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if mso | IE]></td> </tr> </table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!--[if mso | IE]></td> </tr> </table> <table align="center" border="0" cellpadding="0" cellspacing="0" class="footer-outlook" style="width:600px" width="600"> <tr> <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly"><![endif]-->
                          <div
                            class="footer"
                            style="margin: 0 auto; max-width: 600px"
                          >
                            <table
                              align="center"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="width: 100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style="
                                      direction: ltr;
                                      font-size: 0px;
                                      padding: 0px;
                                      text-align: center;
                                    "
                                  >
                                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><![endif]-->
                                    <!-- Support Info -->
                                    <!--[if mso | IE]><tr> <td class="section-outlook" width="600px"> <table align="center" border="0" cellpadding="0" cellspacing="0" class="section-outlook" style="width:600px" width="600"> <tr> <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly"><![endif]-->
                                    <div
                                      class="section"
                                      style="margin: 0 auto; max-width: 600px"
                                    >
                                      <table
                                        align="center"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                        style="width: 100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style="
                                                direction: ltr;
                                                font-size: 0;
                                                padding: 0 40px;
                                                text-align: center;
                                              "
                                            >
                                              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"> <tr> <td class="column-outlook" style="vertical-align:top;width:520px"><![endif]-->
                                              <div
                                                class="mj-column-per-100 mj-outlook-group-fix column"
                                                style="
                                                  font-size: 0px;
                                                  text-align: left;
                                                  direction: ltr;
                                                  display: inline-block;
                                                  vertical-align: top;
                                                  width: 100%;
                                                "
                                              >
                                                <table
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  role="presentation"
                                                  style="vertical-align: top"
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        class="text"
                                                        style="
                                                          font-size: 0;
                                                          padding: 10px 0;
                                                          word-break: break-word;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            font-family: 'Open Sans',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 13.33px;
                                                            font-weight: 600;
                                                            line-height: 150%;
                                                            text-align: center;
                                                            color: #333;
                                                          "
                                                        >
                                                          Got a question?
                                                          <span
                                                           
                                                              color: #136bf5;
                                                              text-decoration: none;
                                                              font-weight: 600;
                                                            "
                                                            >Ask in our
                                                            community</span
                                                          >
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        class="text"
                                                        style="
                                                          font-size: 0;
                                                          padding: 10px 0;
                                                          word-break: break-word;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            font-family: 'Open Sans',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 13.33px;
                                                            line-height: 150%;
                                                            text-align: center;
                                                            color: #333;
                                                          "
                                                        >
                                                          © 2021 Zapier, Inc.
                                                          All rights
                                                          reserved.<br />
                                                          <a
                                                            style="
                                                              font-weight: 400;
                                                              text-decoration: none;
                                                              pointer-events: none;
                                                              color: #333;
                                                            "
                                                            >548 Market St
                                                            #62411, San
                                                            Francisco, CA
                                                            <span
                                                              style="
                                                                white-space: nowrap;
                                                              "
                                                              >94104-5401</span
                                                            ></a
                                                          >
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <!--[if mso | IE]></td> </tr> </table><![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if mso | IE]></td> </tr> </table> </td> </tr><![endif]-->
                                    <!-- Unsubscribe Links -->
                                    <!--[if mso | IE]><tr> <td class="unsub-links-outlook" width="600px"> <table align="center" border="0" cellpadding="0" cellspacing="0" class="unsub-links-outlook" style="width:600px" width="600"> <tr> <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly"><![endif]-->
                                    <div
                                      class="unsub-links"
                                      style="margin: 0 auto; max-width: 600px"
                                    >
                                      <table
                                        align="center"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                        style="width: 100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style="
                                                direction: ltr;
                                                font-size: 0;
                                                padding: 0 40px;
                                                text-align: center;
                                              "
                                            >
                                              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"> <tr> <td class="column-outlook" style="vertical-align:top;width:520px"><![endif]-->
                                              <div
                                                class="mj-column-per-100 mj-outlook-group-fix column"
                                                style="
                                                  font-size: 0px;
                                                  text-align: left;
                                                  direction: ltr;
                                                  display: inline-block;
                                                  vertical-align: top;
                                                  width: 100%;
                                                "
                                              >
                                                <table
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  role="presentation"
                                                  style="vertical-align: top"
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        class="text"
                                                        style="
                                                          font-size: 0;
                                                          padding: 10px 0;
                                                          padding-bottom: 0;
                                                          word-break: break-word;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            font-family: 'Open Sans',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 13.33px;
                                                            line-height: 150%;
                                                            text-align: center;
                                                            color: #333;
                                                          "
                                                        >
                                                          You received this
                                                          email because you're
                                                          subscribed to the
                                                          <em
                                                            >Zapier Blog &amp;
                                                            Newsletter</em
                                                          >.
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        class="text"
                                                        style="
                                                          font-size: 0;
                                                          padding: 10px 0;
                                                          padding-top: 0;
                                                          padding-bottom: 30px;
                                                          word-break: break-word;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            font-family: 'Open Sans',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 13.33px;
                                                            line-height: 150%;
                                                            text-align: center;
                                                            color: #333;
                                                          "
                                                        >
                                                          Want to unsubscribe or
                                                          change which emails
                                                          your receive from us?
                                                          <br /><span
                                                           
                                                            style="
                                                              color: #136bf5;
                                                              text-decoration: none;
                                                              font-weight: 400;
                                                            "
                                                            >Update your email
                                                            preferences</span
                                                          >
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        class="text"
                                                        style="
                                                          font-size: 0;
                                                          padding: 10px 0;
                                                          padding-bottom: 30px;
                                                          word-break: break-word;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            font-family: 'Open Sans',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 13.33px;
                                                            line-height: 150%;
                                                            text-align: center;
                                                            color: #333;
                                                          "
                                                        >
                                                          <span
                                                            
                                                            style="
                                                              color: #136bf5;
                                                              text-decoration: none;
                                                              font-weight: 400;
                                                              white-space: nowrap;
                                                            "
                                                            >Privacy policy</a
                                                          >&nbsp;&nbsp;|&nbsp;&nbsp;<span
                                                           
                                                            style="
                                                              color: #136bf5;
                                                              text-decoration: none;
                                                              font-weight: 400;
                                                              white-space: nowrap;
                                                            "
                                                            >Contact us</span
                                                          >
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <!--[if mso | IE]></td> </tr> </table><![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if mso | IE]></td> </tr> </table> </td> </tr><![endif]-->
                                    <!-- END FOOTER -->
                                    <p></p>
                                    <!--[if mso]></td></tr></table><![endif]-->
                                    <!--[if (!mso)&(!IE)]><!--> <!--<![endif]-->
                                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                                    <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!--[if (IE)]></div><![endif]-->
                            <img
                              border="0"
                              width="1"
                              height="1"
                              alt=""
                              src="https://opens.zapier.com/q/4ONidV0dAZn426_dFWgnJw~~/AAM_vwA~/RgRjJzoUPlcDc3BjQgphQhS1RGFSmXFqUhtqYWtlK3phcGllcmJsb2dAbWVzc2FnZWQuaW9YBAAAAAA~"
                            />
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</body>
`;

const Zapier = () => {
  return <ParseHTML html={string} />;
};

export default Zapier;
