import TinyMCEContainer from "src/components/TinyMCEContainer";
import styles from "./InstructionsModal.module.scss";
import LoadingAnimation from "src/assets/animation/Loading.json";
import Lottie from "lottie-light-react";
import sucessImg from "src/assets/images/successMsg.gif";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import MentionBox from "src/routes/Ticket/children/MainChat/children/SendMessage/children/MentionBox/MentionBox";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import { Editor } from "tinymce";
import { Spinner } from "react-bootstrap";

const cernMentionSpan = `<span id="${uuid()}" class="mceNonEditable" style="color: #0055A1; background-color: #aad7ff; border-radius: 7px; font-size: 12px; font-weight: 600; padding: 0px 4px; outline: none; cursor: default; display: inline-block;" data-cern-mention-instruction-type="1">@Cern</span>&nbsp;`;

const defaultInstruction =
  "Create a 4 stage campaign. Stage 1 - Send this on day 0. Talk about our main USP from our homepage. Stage 2 - Send this on day 2. Talk about the best advice we can give to them regarding our industry. Stage 3 - Send this on day 4. Talk about a feature or value proposition you found on our website.";

const defaultMessage = `<p>${cernMentionSpan} ${defaultInstruction}</p>`;

interface Props {
  setShowInstructionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: (value: string) => void;
  showLoading?: boolean;
}
const InstructionsModal = ({
  setShowInstructionModal,
  setShowError,
  handleSubmit,
  showLoading,
}: Props) => {
  const [value, setValue] = useState("");
  const { fetchAllMentions, data } = useCustomMentions();
  const [editorObj, setEditorObj] = useState<Editor>();

  const customAutoCompleteOptions = useMemo(() => {
    return {
      delimiters: {
        "@": {
          fetchOptions: async () => {
            const data = await fetchAllMentions();
            return data?.filter(
              (mention) => mention.mentionCategoryDetails.key !== "slack",
            );
          },
          renderComponent: (props: any) => (
            <MentionBox
              {...props}
              showCernInstruction={true}
            />
          ),
        },
      },
    };
  }, [fetchAllMentions]);

  return (
    <>
      <div className="">
        <h4 className={`${styles.modalHead} mb-1`}>
          Give Instructions to Cern
        </h4>
        <span className={`${styles.subHeading}`}>
          Input the task you want Cern to execute.
        </span>
        <div className="mt-4">
          <label
            htmlFor=""
            className={`form-label ${styles.instructionLabel}`}
          >
            Instruction
          </label>
          <div className={`${styles.editorBox}`}>
            <TinyMCEContainer
              value={value}
              onChange={(val: string) => setValue(val)}
              uniqueID={uuid()}
              mentionsEnabled={false}
              customAutoCompleteOptions={customAutoCompleteOptions}
              setCurrentEditorObj={setEditorObj}
              disableAttachment={true}
              hideAllControls={true}
              showCustomFilePicker={true}
              toolbar="emoticons bold italic underline link bullist"
            />
          </div>
          <span className={`${styles.noteText}`}>
            Note: Be specific to get the best results.
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <button
            className={`px-2 ${styles.cancelBtn}`}
            onClick={() => {
              setShowInstructionModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`px-2 ${styles.submitBtn}`}
            disabled={showLoading}
            onClick={() => {
              if (handleSubmit) {
                handleSubmit(value);
              }
            }}
          >
            {showLoading ? (
              <Spinner
                className={`m-auto`}
                animation="border"
                size="sm"
                color="grey"
              />
            ) : (
              "Request Cern to do this"
            )}
          </button>
        </div>
      </div>
      {/* for loading ui  */}
      <div className={`py-3 d-none`}>
        <div className={styles.sendingAnimationDiv}>
          <Lottie animationData={LoadingAnimation}></Lottie>
        </div>
      </div>
      {/* for success messsage ui  */}
      <div className={`py-4 ${styles.successWrapper} d-none`}>
        <div className="d-flex justify-content-center">
          <img
            src={sucessImg}
            alt="sucessImg"
            className={`text-center mx-auto mb-4 ${styles.successGif}`}
          />
        </div>
        <h3 className={`text-center ${styles.resHead}`}>Request Successful!</h3>
        <p className={`text-center mb-4 ${styles.resSub}`}>
          Cern will be generating the content for you.
        </p>
        <div className="d-flex justify-content-center">
          <button
            className={`px-1 ${styles.okBtn}`}
            // onClick={onHide}
          >
            Okay
          </button>
        </div>
      </div>
    </>
  );
};

export default InstructionsModal;
