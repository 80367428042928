import { useCallback, useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ReactSelect, { components } from "react-select";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";
import { getLocalLoginCustomerInfo } from "src/services/LocalStorage/loginData";
import { User } from "src/services/Settings/Users/getAllUser";
import { UserRoleList } from "src/services/Settings/Users/getUserRoles";
import { resendUserInvite } from "src/services/Settings/Users/resendUserInvite";
import {
  deactivateUser,
  deleteUser,
  editUser,
  logoutUserFromAllSession,
} from "src/store/slices/settings/user/userSettings.slice";
import { useAppDispatch } from "src/store/store";
import styles from "../User.module.scss";
import style from "./UserItem.module.scss";

import DeleteModal from "../DeleteModal/DeleteModal";
import LogoutModal from "./Children/LogoutModal/LogoutModal";
import reload from "src/assets/images/reloadStatus.png";
import edit from "src/assets/images/editUser.png";
const ShowUserStatus = ({
  status,
  isDeactivated,
}: {
  status?: "active" | "pending";
  isDeactivated?: boolean;
}) => {
  return (
    <div
      className={`${styles.showUserStatus} ${
        status !== "active" && isDeactivated !== true ? styles.pendingText : ""
      }`}
    >
      {isDeactivated === true ? (
        <>
          {" "}
          <span> Deactivated </span>
        </>
      ) : status === "active" ? (
        <>
          <span> Active </span>
        </>
      ) : (
        <>
          <span> Pending </span>
        </>
      )}
    </div>
  );
};

interface Props {
  userData: User;
  userRoles: UserRoleList;
  handelEdit: (userId: number | string) => void;
  permission: boolean;
}

function UserItem({ userData, userRoles, handelEdit, permission }: Props) {
  const dispatch = useAppDispatch();
  const [selectedRole, setSelectedRole] = useState(null as any);
  const [loading, setLoading] = useState(
    "" as "" | "delete" | "resend" | "role",
  );
  const [deactiveLoader, setDeactiveLoader] = useState(false);
  const [logoutUserLoader, setLogoutUserLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const currentState = useRef({ userData, permission });
  useEffect(() => {
    currentState.current = { userData, permission };
  }, [userData, permission]);
  const handelRoleChange = (value: any) => {
    if (currentState.current.permission) {
      setLoading("role");
      dispatch(
        editUser({
          userId: currentState.current.userData.userId,
          userRole: value.value.id,
          callback: () => {
            setLoading("");
          },
        }),
      );
    }
  };
  const handelDelete = useCallback((e: any) => {
    if (currentState.current.permission) {
      setLoading("delete");
      dispatch(
        deleteUser({
          userId: parseInt(currentState.current.userData.userId + ""),
          callback: () => {
            pushTheToast({
              type: "success",
              text: SettingToast,
              customCompProps: { text: "Deleted User successfully !!" },
              isTextComponent: true,
              position: "top-center",
            });
            setLoading("");
          },
        }),
      ).finally(() => {
        setLoading("");
      });
    }
  }, []);
  const handelResend = useCallback((e: any) => {
    if (currentState.current.permission) {
      setLoading("resend");
      resendUserInvite({
        userId: parseInt(currentState.current.userData.userId + ""),
      })
        .then((res) => {
          setLoading("");
          pushTheToast({
            type: "success",
            text: "Invitation sent!",
            position: "top-right",
          });
        })
        .catch((err) => {
          setLoading("");
          pushTheToast({
            type: "success",
            text: "Invitation sent!",
            position: "top-right",
          });
        });
    }
  }, []);

  const handelEditData = (e: any) => {
    if (currentState.current.permission) {
      handelEdit(userData.userId);
    }
  };

  useEffect(() => {
    if (userData.userRole === null) {
      setSelectedRole(null);
    } else {
      const role = {
        label: userRoles.roles[userData.userRole].role,
        value: userRoles.roles[userData.userRole],
      };
      setSelectedRole(role);
    }
  }, [userData, userRoles]);

  const onShow = useCallback(() => {
    if (currentState.current.permission) {
      setShowDeactivateModal(true);
    }
  }, []);
  const onHide = useCallback(() => {
    setShowDeactivateModal(false);
  }, []);
  const handleShow = useCallback(() => {
    if (currentState.current.permission) {
      setShowModal(true);
    }
  }, []);
  const handleHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const handelDeactiveUser = useCallback((e: any) => {
    if (currentState.current.permission) {
      setDeactiveLoader(true);
      dispatch(
        deactivateUser({
          userId: parseInt(currentState.current.userData.userId + ""),
          isActivate: currentState.current.userData.isDeactivated
            ? true
            : false,
        }),
      )
        .then((res: any) => {
          if (res.meta.requestStatus && res.meta.requestStatus === "rejected") {
            pushTheToast({
              type: "danger",
              text: "Failed to deactivate user",
              position: "top-right",
            });
            return;
          }
          pushTheToast({
            type: "success",
            text: res.payload?.isActivate
              ? "Activated User Successfully"
              : "Deactivated User Successfully",
            position: "top-right",
          });
        })
        .finally(() => {
          setDeactiveLoader(false);
          setShowDeactivateModal(false);
        });
    }
  }, []);

  const handleLogoutFromAllSession = useCallback(() => {
    if (currentState.current.permission) {
      setLogoutUserLoader(true);
      dispatch(
        logoutUserFromAllSession({
          userId: parseInt(currentState.current.userData.userId + ""),
        }),
      )
        .then((res) => {
          if (res.meta.requestStatus && res.meta.requestStatus === "rejected") {
            pushTheToast({
              type: "danger",
              text: "Failed to logout user",
              position: "top-right",
            });
            return;
          }
          pushTheToast({
            type: "success",
            text: "Successfully logged out of all sessions",
            position: "top-right",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLogoutUserLoader(false);
          setShowModal(false);
        });
    }
  }, []);

  return (
    <div className={`d-flex align-items-center mb-3 ps-3 pe-2 `}>
      {/* <div className={`pe-2 ${styles.userInfo}`}>
        <div className="d-flex align-items-center">
          <div className={`${styles.status_image}`}>
            {userData.profileImageUrl == null ? (
              <UserAvatar
                className={`rounded-circle img-fluid ${styles.userAvatar}`}
                name={`${
                  `${userData.firstName} ${userData.lastName}`.trim()
                    ? `${userData.firstName} ${userData.lastName}`
                    : userData.email
                }`}
                size={50}
              />
            ) : (
              <AxiosImg
                className={`rounded-circle img-fluid ${styles.userImg}`}
                url={userData.profileImageUrl}
              />
            )}
          </div>
          <div className={`${styles.name_email}`}>
            <div className={`${styles.name}`}>
              {`${userData.firstName} ${userData.lastName}`}
            </div>
            <div className={`${styles.email}`}>{userData.email}</div>
          </div>
        </div>
      </div>
      <div className={`${styles.userStatusInfo}`}>
        <div className={`${styles.wrap_select_arrow} wrap-select-arrow`}>
          <div className={`${styles.select_width} select-width`}>
            <select
              className={`${styles.form_control} form-control mt-2 ps-2`}
              name="industry"
              disabled
            >
              <option value={0} selected className="">
                Online
              </option>
              <option value={1}>Employee</option>
            </select>
            <div className={`${styles.select_arrow} select-arrow`}>
              <div className={`${styles.arrow_up} arrow-up`} />
              <div className={`${styles.arrow_down} arrow-down`} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.userType}`}>
        <div className={`${styles.wrap_select_arrow} wrap-select-arrow`}>
          <div className={`${styles.select_width} select-width mt-2`}>
            <ReactSelect
              options={Object.values(userRoles.roles).map((data) => ({
                label: data.role,
                value: data,
              }))}
              value={selectedRole}
              styles={customStyles}
              onChange={handelRoleChange}
              isDisabled={!permission}
            />
            {loading === "role" && <Spinner animation="border" size="sm" />}
          </div>
        </div>
      </div>
      <div className={`${styles.userAction}`}>
        <div className={`w-100 d-flex`}>
          <div style={{ width: "35%" }} className={`${styles.actionStat}`}>
            <div className={`${styles.user_status} user-status mt-3 `}>
              <ShowUserStatus status={userData.userStatus as any} />
            </div>
          </div>
          <div className={`${styles.actionEdit}`}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  className={`${styles.toolTipCustom} ${
                    !permission ? "d-none" : ""
                  }`}
                >
                  Edit
                  <br />
                  User
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`${styles.listEdit} ${
                    !permission ? styles.disabled : ""
                  } d-flex flex-column justify-content-center mt-3`}
                  onClick={handelEditData}
                  {...triggerHandler}
                  ref={ref}
                >
                  <i className="material-icons m-auto">&#xe3c9;</i>
                </div>
              )}
            </OverlayTrigger>
          </div>
          <div className={`${styles.actionDelete}`}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  className={`${styles.toolTipCustom} ${
                    !permission ? "d-none" : ""
                  }`}
                >
                  Delete
                  <br />
                  User
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  className={`${styles.listDelete} ${
                    !permission ? styles.disabled : ""
                  } d-flex flex-column justify-content-center mt-3`}
                  onClick={onShow}
                  {...triggerHandler}
                  ref={ref}
                >
                  {loading === "delete" ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <i className="material-icons m-auto">&#xe872;</i>
                  )}
                </div>
              )}
            </OverlayTrigger>
          </div>
          <div className={`${styles.actionReload}`}>
            {userData.userStatus?.toLowerCase() !== "active" && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    className={`${styles.toolTipCustom} ${
                      !permission ? "d-none" : ""
                    }`}
                  >
                    Resend
                    <br />
                    Invitation
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    className={`${styles.listResend} ${
                      !permission ? styles.disabled : ""
                    } d-flex flex-column justify-content-center mt-3`}
                    onClick={handelResend}
                    {...triggerHandler}
                    ref={ref}
                  >
                    {loading === "resend" ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="material-icons m-auto">&#xe5d5;</i>
                    )}
                  </div>
                )}
              </OverlayTrigger>
            )}
          </div>
        </div>
        <Modal
        show={showModal}
        onHide={onHide}
        dialogClassName={`${styles.deleteDialog}`}
        contentClassName={`${styles.deleteContent}`}
        centered={false}
        enforceFocus={false}
      >
        <DeleteModal confirmText="Are you sure you want to delete this user?" handleCancel={onHide} handleSubmit={handelDelete} isLoading={loading === "delete"}/>
      </Modal>
      </div> */}
      <div className={`pe-2 ${style.userInfo}`}>
        <div className="d-flex align-items-center">
          <div className={`${style.status_image}`}>
            {userData.profileImageUrl == null ? (
              <UserAvatar
                className={`${styles.userAvatar}`}
                name={`${
                  `${userData.firstName} ${userData.lastName}`.trim()
                    ? `${userData.firstName} ${userData.lastName}`
                    : userData.email
                }`}
                size={30}
              />
            ) : (
              <AxiosImg
                className={`${styles.userImg}`}
                url={userData.profileImageUrl}
                isDirectURL={userData.isPublicAttachmentUrl}
              />
            )}
          </div>
          <div className={`${styles.name_email}`}>
            <div className={`${style.name}`}>
              {`${userData.firstName} ${userData.lastName}`}
            </div>
            <div className={`${style.email}`}>{userData.email}</div>
          </div>
        </div>
      </div>
      <div className={`${style.userRole} d-flex justify-content-center`}>
        <div className={`${styles.wrap_select_arrow} wrap-select-arrow`}>
          <div className={`${styles.select_width} select-width mt-2`}>
            <ReactSelect
              options={Object.values(userRoles.roles).map((data) => ({
                label: data.role,
                value: data,
              }))}
              value={selectedRole}
              styles={customStyles}
              onChange={handelRoleChange}
              isDisabled={!permission}
              components={{
                DropdownIndicator: (props) => (
                  <components.DropdownIndicator {...props}>
                    <i
                      className={`fa-solid ${
                        props.selectProps.menuIsOpen
                          ? "fa-caret-up"
                          : "fa-caret-down"
                      }`}
                    ></i>
                  </components.DropdownIndicator>
                ),
              }}
            />
            {loading === "role" && (
              <Spinner
                animation="border"
                size="sm"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${style.userActiveStatus} d-flex justify-content-center`}
      >
        <div className={``}>
          <div
            className={`${styles.user_status} user-status d-flex align-items-center`}
          >
            <div className={`${styles.actionReload} me-2`}>
              {userData.userStatus?.toLowerCase() !== "active" &&
                userData.isDeactivated !== true && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip
                        className={`${styles.toolTipCustom} ${
                          !permission ? "d-none" : ""
                        }`}
                      >
                        Resend Invitation
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <div
                        className={`${styles.listResend} ${
                          !permission ? styles.disabled : ""
                        } d-flex flex-column justify-content-center mt-1`}
                        onClick={handelResend}
                        {...triggerHandler}
                        ref={ref}
                      >
                        {loading === "resend" ? (
                          <Spinner
                            animation="border"
                            size="sm"
                          />
                        ) : (
                          <>
                            <img
                              src={reload}
                              alt=""
                            />
                          </>
                        )}
                      </div>
                    )}
                  </OverlayTrigger>
                )}
            </div>
            <ShowUserStatus
              status={userData.userStatus as any}
              isDeactivated={userData.isDeactivated}
            />
          </div>
        </div>
      </div>
      <div className={`${style.userEdit}`}>
        <div className={`d-flex justify-content-center`}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip
                className={`${styles.toolTipCustom} ${
                  !permission ? "d-none" : ""
                }`}
              >
                Edit User
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                className={`${styles.listEdit} ${
                  !permission ? styles.disabled : ""
                } d-flex justify-content-center`}
                onClick={handelEditData}
                {...triggerHandler}
                ref={ref}
              >
                <img
                  src={edit}
                  alt="edit"
                />
              </div>
            )}
          </OverlayTrigger>
        </div>
      </div>
      <div className={`${style.userAction} d-flex justify-content-center`}>
        <button
          className={`${style.deactivateBtn}`}
          onClick={onShow}
          disabled={!permission}
        >
          <span className="pe-1">
            <i className="fa-solid fa-eye"></i>
          </span>
          <span>
            {userData.isDeactivated ? "Activate User" : "De-activate User"}
          </span>
        </button>
        <Modal
          show={showDeactivateModal}
          onHide={onHide}
          dialogClassName={`${style.modalDialog}`}
          contentClassName={`${styles.deleteContent}`}
          centered={true}
          enforceFocus={false}
        >
          <DeleteModal
            confirmText={`Are you sure you want to ${
              userData.isDeactivated ? "Activate" : "Deactivate"
            } this user?`}
            headingClassName={style.heading}
            handleCancel={onHide}
            handleSubmit={handelDeactiveUser}
            isLoading={deactiveLoader}
          />
        </Modal>
      </div>
      <div className={`${style.loginStatus} d-flex justify-content-center`}>
        {userData.hasLoggedInSessions === true ? (
          <button
            className={`${style.logOutBtn}`}
            disabled={!permission}
            onClick={handleShow}
          >
            <span className="pe-1">
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
            <span>Log out of all sessions</span>
          </button>
        ) : (
          <span className={`${style.session}`}>No Login session found</span>
        )}
        <Modal
          show={showModal}
          onHide={handleHide}
          dialogClassName={`${style.modalDialog}`}
          contentClassName={`${style.modalContent}`}
          centered={true}
        >
          <LogoutModal
            isLoading={logoutUserLoader}
            handleLogoutFromAllSession={handleLogoutFromAllSession}
            onHide={handleHide}
          />
        </Modal>
      </div>
    </div>
  );
}

const customStyles = {
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    "&:hover": {
      background: "#F9F9F9 0% 0% no-repeat padding-box",
      borderRadius: "4px",
    },
    "&:active": {
      background: "#EEEEEE 0% 0% no-repeat padding-box",
    },
  }),
  control: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),
};

export default UserItem;
