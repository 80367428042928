/**
 * This file is the custom hook and context file.
 * It contains useReturnAutoWorkFlowCreate and useReturnAutoWorkFlow custom hooks.
 *
 * @author Yash Aditya
 * @fileoverview Custom hooks and context for managing return auto work flow state.
 */

import React, {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { ActionDispatch, AJAXSTATUS } from "src/globals/constants";
import {
  AllAutomationContexts,
  EmailForwardingDetails,
  IActiveSubModal,
  ModalConfigureDetails,
  ReturnAutomationWorkFlowDetails,
  SavedStepAndQuestionData,
  SavedStepAndQuestions,
  StepQuestion,
  StripeDetails,
  TransferToAgentType,
} from "../ReturnAutoWorkFlow.types";
import useFetchReturnAutoWorkFlow from "./useFetchReturnAutoWorkFlow";
import { GetDiscountCodes } from "src/services/ReturnAutoWorkFlow/OldModals/General/getDiscountCodes.service";
import {
  AllProducts,
  ReturnProduct,
} from "src/services/ReturnAutoWorkFlow/OldModals/General/getProducts.service";
import { AllProductTags } from "src/services/ReturnAutoWorkFlow/OldModals/General/getProductTags.service";
import { AllOrderTags } from "src/services/ReturnAutoWorkFlow/OldModals/General/getOrderTags.service";

/**
 * This is used in to keep the states of `useSaveStepsAndQuestions` hook to keep them shared between theis instances.
 */
export interface QuestionState {
  savingStepAndQuestionData: null | "question" | "moveToNext";
  questionHaveUpdates: boolean;
  showErrors: boolean;
}

export interface ReturnAutoWorkFlowState
  extends Partial<ReturnAutomationWorkFlowDetails> {
  automationContext: AllAutomationContexts;
  loadingReturnAutomationWorkFlow: AJAXSTATUS;
  loadingStepsAndQuestionSaving: AJAXSTATUS;
  /**
   * Here key of record is in format `${stepId}::${questionId}`
   */
  questionStates: Record<string, QuestionState>;
  activeModalConfigDetails: null | ModalConfigureDetails;
  activeModalConfigStepId: null | string;
  activeModalConfigQuestionId: null | string;
  activeStaticModal:
    | null
    | "emailForwardingOrderInfo"
    | "noEditErrorModal"
    | "noEditErrorQuestionModal"
    | "moveToNextModal";
  activeStaticModalStepId: null | string;
  activeStaticModalQuestionId: null | string;
  /**
   * Active SubModal details for fully automated
   */
  activeSubModalConfigDetails?: IActiveSubModal | null;
  allDiscountCodes: GetDiscountCodes | null;
  loadingDiscountCodes: AJAXSTATUS;
  allProducts: AllProducts | null;
  loadingAllProducts: AJAXSTATUS;
  allProductTags: AllProductTags | null;
  loadingAllProductTags: AJAXSTATUS;
  allOrderTags: AllOrderTags | null;
  loadingAllOrderTags: AJAXSTATUS;
}

/**
 * Initial state for the return auto work flow.
 */
const returnAutoWorkFlow: () => ReturnAutoWorkFlowState = () => ({
  automationContext: "return",
  loadingReturnAutomationWorkFlow: "pending",
  loadingStepsAndQuestionSaving: "fulfilled",
  questionStates: {},
  activeModalConfigDetails: null,
  activeModalConfigStepId: null,
  activeModalConfigQuestionId: null,
  activeStaticModal: null,
  activeStaticModalStepId: null,
  activeStaticModalQuestionId: null,
  allDiscountCodes: null,
  loadingDiscountCodes: "idle",
  allProducts: null,
  loadingAllProducts: "idle",
  allProductTags: null,
  loadingAllProductTags: "idle",
  allOrderTags: null,
  loadingAllOrderTags: "idle",
});

/**
 * Reducer function to handle state modifications.
 *
 * @param state - The current state.
 * @param actionPayload - Array containing the action and payload.
 * @returns The new state after applying the action.
 */
const reducerMethod = (
  state: ReturnAutoWorkFlowState,
  [action, payload]: [
    (value: ReturnAutoWorkFlowState, payload?: any) => void,
    undefined | any,
  ],
): ReturnAutoWorkFlowState => {
  // Execute the action function with current state and payload
  action(state, payload);
  // Return a new state object to ensure immutability
  return JSON.parse(JSON.stringify(state));
};

/**
 * Context for return auto work flow.
 */
const ReturnAutoWorkFlowContext = React.createContext([
  returnAutoWorkFlow(),
  () => {},
] as [
  ReturnAutoWorkFlowState,
  ActionDispatch<typeof ReturnAutoWorkFlowActions>,
]);

/**
 * Custom hook to create context with a reducer.
 *
 * @returns An array containing the state, modified dispatch function, and context provider.
 */
export const useReturnAutoWorkFlowCreate = () => {
  const {
    automationContext,
    integrationId,
    returnAutoWorkFlowDetails,
    returnAutoWorkFlowLoadingStatus,
  } = useFetchReturnAutoWorkFlow();

  // Use the reducer to manage state
  const [state, dispatch] = useReducer(reducerMethod, {
    // Initialize state with default values
    integrationId,
    ...returnAutoWorkFlow(),
    ...returnAutoWorkFlowDetails,
    loadingReturnAutomationWorkFlow: returnAutoWorkFlowLoadingStatus,
    automationContext,
  });

  // Create a ref to store current state
  const currentStateRef = useRef({ state });

  // Need to keep the updated state in useRef as it is being used in async useMemo below
  // To update the fetched data we need this and we can not put this in the useMemo dependency to resolve infinite renders
  useMemo(() => {
    currentStateRef.current.state = state;
  }, [state]);

  // Memoize the modified dispatch function using useCallback
  const modifiedDispatch: ActionDispatch<typeof ReturnAutoWorkFlowActions> =
    useCallback(
      (action, payload) => {
        // Wrap dispatch with action and payload
        dispatch([ReturnAutoWorkFlowActions[action], payload]);
      },
      [dispatch],
    );

  useMemo(() => {
    if (
      returnAutoWorkFlowLoadingStatus === "pending" &&
      currentStateRef.current.state.loadingReturnAutomationWorkFlow !==
        "pending"
    ) {
      modifiedDispatch("resetState");
    }
    modifiedDispatch("setState", {
      ...returnAutoWorkFlowDetails,
      loadingReturnAutomationWorkFlow: returnAutoWorkFlowLoadingStatus,
      integrationId,
      automationContext,
    });
  }, [
    returnAutoWorkFlowDetails,
    returnAutoWorkFlowLoadingStatus,
    integrationId,
    automationContext,
  ]);

  // Return the state, modified dispatch, and context provider
  return [state, modifiedDispatch, ReturnAutoWorkFlowContext.Provider] as [
    ReturnAutoWorkFlowState,
    ActionDispatch<typeof ReturnAutoWorkFlowActions>,
    typeof ReturnAutoWorkFlowContext.Provider,
  ];
};

/**
 * Custom hook to consume the return auto work flow context.
 *
 * @returns An object containing the return auto work flow state and the dispatch function.
 */
export const useReturnAutoWorkFlow = () => {
  // Destructure context values
  const [returnAutoWorkFlow, dispatch] = useContext(ReturnAutoWorkFlowContext);

  // Return context values
  return {
    returnAutoWorkFlow,
    dispatch,
  };
};

/**
 * Actions to modify the return auto work flow state.
 */
const ReturnAutoWorkFlowActions = {
  /**
   * Resets the return auto work flow state.
   *
   * @param state - The current state.
   */
  resetState: (state: ReturnAutoWorkFlowState) => {
    // Reset all ReturnAutoWorkFlow data
    const newState = returnAutoWorkFlow();
    for (const key in state) {
      delete (state as any)[key];
    }
    for (const key in newState) {
      (state as any)[key] = (newState as any)[key];
    }
  },
  /**
   * Sets the state with the provided payload.
   *
   * @param state - The current state.
   * @param payload - The new state to be merged with the current state.
   */
  setState: (
    state: ReturnAutoWorkFlowState,
    payload: Partial<ReturnAutoWorkFlowState>,
  ) => {
    for (const key in payload) {
      if ((payload as any)[key] !== undefined) {
        (state as any)[key] = (payload as any)[key];
      }
    }
  },
  /**
   * Changes the active step with the provided stepId.
   *
   * @param state - The current state.
   *
   * @param payload - To append or instantiate the data for a perticular question.
   */
  updateQuestionStates: (
    state: ReturnAutoWorkFlowState,
    payload: { key: string; value: Partial<QuestionState> },
  ) => {
    state.questionStates[payload.key] = {
      ...(state.questionStates[payload.key] ?? {}),
      ...payload.value,
    };
  },
  /**
   * Changes the active step with the provided stepId.
   *
   * @param state - The current state.
   *
   * @param payload - The stepId to make it active.
   */
  changeActiveStep: (state: ReturnAutoWorkFlowState, stepId: string) => {
    state.activeStepId = stepId;
  },
  /**
   * Changes the specific question value with the provided stepId, questionId and value.
   *
   * @param state - The current state.
   *
   * @param payload - The data to update.
   */
  updateQuestionSpecificValue: (
    state: ReturnAutoWorkFlowState,
    payload: {
      stepId: string;
      questionId: string;
      value: Partial<EmailForwardingDetails> | Partial<StripeDetails>;
      putActiveEmailInArray?: boolean;
    },
  ) => {
    if (
      state.configuredSteps &&
      state.configuredSteps[payload.stepId] &&
      state.configuredSteps[payload.stepId].stepQuestions[payload.questionId]
    ) {
      if (payload.value) {
        state.configuredSteps[payload.stepId].stepQuestions[
          payload.questionId
        ].specificQuestionValue = {
          ...(state.configuredSteps[payload.stepId].stepQuestions[
            payload.questionId
          ].specificQuestionValue as any),
          ...payload.value,
        };

        // Code to put the active email in the array.
        if (
          payload.putActiveEmailInArray &&
          (payload.value as Partial<EmailForwardingDetails>)
            .activeEmailForVerification
        ) {
          const activeEmailForVerification = (
            payload.value as Partial<EmailForwardingDetails>
          ).activeEmailForVerification;
          const savedEmails =
            (
              state.configuredSteps[payload.stepId].stepQuestions[
                payload.questionId
              ].specificQuestionValue as Partial<EmailForwardingDetails>
            ).savedEmails ?? [];
          if (
            activeEmailForVerification &&
            !savedEmails.includes(activeEmailForVerification)
          ) {
            (
              state.configuredSteps[payload.stepId].stepQuestions[
                payload.questionId
              ].specificQuestionValue as Partial<EmailForwardingDetails>
            ).savedEmails = [
              ...savedEmails,
              (payload.value as any).activeEmailForVerification,
            ];
          }
        }
      }
    }
  },
  /**
   * Changes the question data with the provided stepId, questionId and value.
   *
   * @param state - The current state.
   *
   * @param payload - The data to update.
   */
  updateStepQuestionData: (
    state: ReturnAutoWorkFlowState,
    payload: {
      stepId: string;
      questionId: string;
      value: Partial<StepQuestion>;
    },
  ) => {
    if (
      state.configuredSteps &&
      state.configuredSteps[payload.stepId] &&
      state.configuredSteps[payload.stepId].stepQuestions[payload.questionId]
    ) {
      if (payload.value) {
        state.configuredSteps[payload.stepId].stepQuestions[
          payload.questionId
        ] = {
          ...state.configuredSteps[payload.stepId].stepQuestions[
            payload.questionId
          ],
          ...payload.value,
        };
      }
    }
  },
  /**
   * Changes the step transfer data with the provided stepId and value.
   *
   * @param state - The current state.
   *
   * @param payload - The data to update.
   */
  updateTransferToAgent: (
    state: ReturnAutoWorkFlowState,
    payload: {
      stepId: string;
      value: Partial<TransferToAgentType>;
    },
  ) => {
    const currentValue =
      state.configuredSteps?.[payload.stepId]?.transferToAgent;

    if (
      state.configuredSteps &&
      state.configuredSteps[payload.stepId] &&
      currentValue
    ) {
      if (payload.value) {
        state.configuredSteps[payload.stepId].transferToAgent = {
          ...currentValue,
          ...payload.value,
        };
      }
    }
  },
  /**
   * Updates the configured steps using the response returned from API.
   *
   * @param state - The current state.
   *
   * @param payload - The data to update.
   */
  updateUsingSaveAPIResponse: (
    state: ReturnAutoWorkFlowState,
    payload: {
      savedStepAndQuestionIds: Array<SavedStepAndQuestionData>;
      configured?: boolean;
      published?: boolean;
      activeStepId?: null | string;
    },
  ) => {
    if (state.configuredSteps) {
      const savedStepAndQuestionIdsNew: Array<SavedStepAndQuestions> = [];

      for (let i = 0; i < payload.savedStepAndQuestionIds.length; i++) {
        const stepValue = payload.savedStepAndQuestionIds[i];

        savedStepAndQuestionIdsNew.push({
          stepId: stepValue.stepId,
          questionIds: stepValue.questionIds,
        });

        if (state.configuredSteps[stepValue.stepId]) {
          const configuredStep = state.configuredSteps[stepValue.stepId];
          if (stepValue.stepStatus) {
            configuredStep.stepStatus = stepValue.stepStatus;
          }
          if (stepValue.updateOrAddQuestionsData?.length) {
            for (
              let j = 0;
              j < stepValue.updateOrAddQuestionsData.length;
              j++
            ) {
              const updatedQuestionData = stepValue.updateOrAddQuestionsData[j];
              if (
                configuredStep.stepQuestions[updatedQuestionData.questionId]
              ) {
                configuredStep.stepQuestions[updatedQuestionData.questionId] = {
                  ...configuredStep.stepQuestions[
                    updatedQuestionData.questionId
                  ],
                  ...updatedQuestionData,
                };
              } else {
                configuredStep.stepQuestions[updatedQuestionData.questionId] =
                  updatedQuestionData as StepQuestion;
                configuredStep.stepQuestionIds.push(
                  updatedQuestionData.questionId,
                );
              }
            }
          }
        }
      }

      state.savedStepAndQuestionIds = savedStepAndQuestionIdsNew;

      // Chrecking if configured then applying change.
      if (typeof payload.configured === "boolean") {
        state.configured = payload.configured;
      }
      // Chrecking if published then applying change.
      if (typeof payload.published === "boolean") {
        state.published = payload.published;
      }
      // Chrecking if activeStepId then applying change.
      if (payload.activeStepId !== undefined) {
        state.activeStepId = payload.activeStepId;
      }
    }
  },
  /**
   * Updates the configured steps using the response returned from API.
   *
   * @param state - The current state.
   *
   * @param payload - The data to update.
   */
  updateConfiguredStepsAndWorkFlow: (
    state: ReturnAutoWorkFlowState,
    payload: ReturnAutomationWorkFlowDetails,
  ) => {
    if (state.configuredSteps) {
      if (payload.configured !== undefined) {
        state.configured = payload.configured;
      }
      if (payload.activeStepId !== undefined) {
        state.activeStepId = payload.activeStepId;
      }
      if (payload.published !== undefined) {
        state.published = payload.published;
      }
      if (payload.brand !== undefined) {
        state.brand = payload.brand;
      }
      if (payload.savedStepAndQuestionIds !== undefined) {
        ReturnAutoWorkFlowActions.updateUsingSaveAPIResponse(state, {
          savedStepAndQuestionIds: payload.savedStepAndQuestionIds,
        });
      }
      if (payload.configuredSteps !== undefined) {
        for (const stepId in payload.configuredSteps) {
          const stepValue = payload.configuredSteps[stepId] ?? {};
          const oldStepValue = state.configuredSteps[stepId] ?? {};
          const oldQuestions = {
            ...(oldStepValue.stepQuestions ?? {}),
            ...(stepValue.stepQuestions ?? {}),
          };
          const oldQuestionIds = oldStepValue.stepQuestionIds ?? [];
          state.configuredSteps[stepId] = {
            ...oldStepValue,
            ...stepValue,
            stepQuestionIds: oldQuestionIds,
            stepQuestions: oldQuestions,
          };
        }
      }
    }
  },
  updateDiscountCodes: (
    state: ReturnAutoWorkFlowState,
    payload: GetDiscountCodes,
  ) => {
    state.allDiscountCodes = {
      ...state.allDiscountCodes,
      data: [...(state.allDiscountCodes?.data ?? []), ...payload.data],
      metaData: payload.metaData,
    };
  },
  updateProducts: (state: ReturnAutoWorkFlowState, payload: AllProducts) => {
    const mergedProducts = [
      ...(state.allProducts?.data ?? []),
      ...payload.data,
    ];

    // Use a Map to ensure products are unique based on productId
    const uniqueProducts = Array.from(
      mergedProducts
        .reduce(
          (map, product) => map.set(product.productId, product),
          new Map<number, ReturnProduct>(),
        )
        .values(),
    );

    state.allProducts = {
      ...state.allProducts,
      data: uniqueProducts,
      metaData: payload.metaData,
    };
  },
  updateProductTags: (
    state: ReturnAutoWorkFlowState,
    payload: AllProductTags,
  ) => {
    state.allProductTags = {
      ...state.allProductTags,
      data: [...(state.allProductTags?.data ?? []), ...payload.data],
      metaData: payload.metaData,
    };
  },
  updateOrderTags: (state: ReturnAutoWorkFlowState, payload: AllOrderTags) => {
    state.allOrderTags = {
      ...state.allOrderTags,
      data: [...(state.allOrderTags?.data ?? []), ...payload.data],
      metaData: payload.metaData,
    };
  },
};
