import React, { useEffect, useState } from "react";
import styles from "./ProductAvatar.module.scss";
import greenBag from "src/assets/images/green-bag.png";
import orangeBag from "src/assets/images/orange-bag.png";
import yellowBag from "src/assets/images/yellow-bag.png";
import blueBag from "src/assets/images/blue-bag.png";

interface ProductAvatarProps {
  index?: number;
  className?: string;
  width?: string;
  height?: string;
}

const ProductAvatar: React.FC<ProductAvatarProps> = ({
  index = 0,
  className = "",
  width = "15px",
  height = "17px",
}) => {
  const [imgSrc, setImgSrc] = useState<string>("");

  const bagClasses = [
    "greenBag", // Corresponds to green bag
    "orangeBag", // Corresponds to orange bag
    "yellowBag", // Corresponds to yellow bag
    "blueBag", // Corresponds to blue bag
  ];

  const bagImages: { [key: string]: string } = {
    greenBag: greenBag,
    orangeBag: orangeBag,
    yellowBag: yellowBag,
    blueBag: blueBag,
  };

  const selectedBagClass = bagClasses[index % bagClasses.length];

  useEffect(() => {
    setImgSrc(bagImages[selectedBagClass]);
  }, [selectedBagClass]);

  return (
    <div
      className={`${className} ${styles[selectedBagClass]} d-flex align-items-center justify-content-center`}
    >
      <img
        src={imgSrc}
        className={imgSrc ? "" : "invisible"}
        alt="Bag image"
        width={width}
        height={height}
      />
    </div>
  );
};

export default ProductAvatar;
