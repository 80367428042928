import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { BrandDetail } from "src/services/EmailIntegration/getBrands";
import getAllSlackIntegrationService from "src/services/Integrations/Slack/getAllSlackIntegrations.service";
import getLinkableBrandSlackService from "src/services/Integrations/Slack/getLinkableBrandsSlack.service";

const initialPayload = {
  start: 0,
  limit: 10,
};

function useGetAllLinkableBrnad() {
  const [payload, setPayload] = useState({
    ...initialPayload,
  });
  const [integratedSlack, setIntegratedSlack] = useState<BrandDetail[]>([]);

  const { data, isError, isLoading, status } = useQuery(
    ["slackIntegration/getLinkableBrandSlackService", payload],
    {
      queryFn: () => getLinkableBrandSlackService(payload),
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  useEffect(() => {
    if(data?.data){
      setIntegratedSlack(prevstate => [...prevstate, ...data.data])
    }
  }, [data])

  // Change the current page and trigger a refetch
  const changePage = async () => {
    if(data?.metaData?.hasMore)
    setPayload({
      ...payload,
      start: payload.start + payload.limit!,
    });
  };

  return {
    linkableBrands: integratedSlack?.map((brand: any) => {
      return {
        value: brand.id,
        label: brand.name,
        imageURL: brand.imageURL,
      };
    }),
    setLinakbelBrands: setIntegratedSlack,
    changePage,
    isLoading,
    isError,
  };
}

export default useGetAllLinkableBrnad;
