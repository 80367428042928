import { useQuery } from "@tanstack/react-query";
import getMessagePreview from "src/services/Campaign/getMessagePreview.service";

const useMessagePreview = (messageId: string) => {
  const { data, isLoading, isError } = useQuery(
    ["campaignAllMessagePreive", messageId],
    {
      queryFn: () => getMessagePreview({ messageId }),
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useMessagePreview;
