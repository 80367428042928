import { CustomerData } from "../../../Hooks/useUploadNewCsvModalContext";
import styles from "./TableRow.module.scss";

const TableRow = ({ customerData }: { customerData: CustomerData }) => {
  return (
    <div className={`d-flex align-items-center px-2 mb-2 ${styles.tableHead}`}>
      <div className={`${styles.tableHead}`}>
        {customerData?.rowData?.map((row) => {
          return (
            <div
              className={`${styles.value} ${
                row?.type === "varientImage" ? styles.varientImg : ""
              }`}
            >
              <div className={`${styles.tablePara} text-truncate`}>
                {(row?.value as string) ?? ""}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableRow;
