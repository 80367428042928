import { Modal, Spinner } from "react-bootstrap";
import styles from "./DiscardCampModal.module.scss";

const DiscardCampModal = ({
  showModal,
  showLoader = false,
  onOkay,
  back,
  onHide,
}: {
  showModal: boolean;
  showLoader?: boolean;
  onOkay: () => void;
  back: () => void;
  onHide: () => void;
}) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      className={`${styles.backDropClass}`}
      id="AddCampaignDiscardErrorModal"
      centered
    >
      <div>
        <div className="px-4">
          <div className={`${styles.topIcon} mb-4 mx-auto`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="56"
              viewBox="0 0 64 56"
            >
              <g
                id="Group_17419"
                data-name="Group 17419"
                transform="translate(0.132)"
              >
                <path
                  id="Polygon_38"
                  data-name="Polygon 38"
                  d="M27.678,7.428a5,5,0,0,1,8.643,0L59.628,47.486A5,5,0,0,1,55.306,55H8.694a5,5,0,0,1-4.322-7.514Z"
                  transform="translate(-0.132)"
                  fill="#b70101"
                />
                <text
                  id="_"
                  data-name="!"
                  transform="translate(31.868 45)"
                  fill="#fff"
                  font-size="31"
                  font-family="Poppins-Medium, Poppins"
                  font-weight="500"
                >
                  <tspan
                    x="-4"
                    y="0"
                  >
                    !
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
          <div className={`${styles.title} mb-2 mx-auto`}>
            Are you sure you want to discard changes?
          </div>
          <p className={`${styles.subtitle} mb-4 mx-auto`}>
            You have unsaved changes in your campaign. Do you want to exit
            without saving{/* or save it as a draft */}?
          </p>

          <div className="d-flex justify-content-between mb-2 px-2 pt-1">
            <button
              className={`px-3 me-4 ${styles.cancelBtn}`}
              onClick={back}
            >
              {/* Discard Changes */}
              Discard Changes
            </button>
            <button
              className={`px-3 ${styles.checkBtn} d-flex justify-content-center= align-items-center`}
              onClick={onOkay}
            >
              {showLoader && (
                <Spinner
                  size="sm"
                  className="me-2"
                />
              )}
              {/* <span>Save as Draft</span> */}
              <span>Save as Draft</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardCampModal;
