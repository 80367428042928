import { axiosJSON } from "src/globals/axiosEndPoints";

export interface SegmentAttribute {
  id: string;
  name: string;
  eventType: "major" | "minor" | null;
  hyperlink?: {
    url: string;
    relatedFeatureName: string;
  } | null;
}

export interface GetSegmentSettingsParams {
  start: number;
  limit: number;
  type: "default" | "custom";
}

const getSegmentSettingsService = async (params: GetSegmentSettingsParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/setting/getUserTimelineSetting`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as SegmentAttribute[];
};

export default getSegmentSettingsService;
