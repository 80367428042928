import { useCallback, useState } from "react";
import styles from "./TimelineForm.module.scss";
import DatePicker from "react-datepicker";
import Select, { SingleValue } from "react-select";
import {
  StageData,
  useGetTimeLineEvents,
} from "../../../hooks/useFetchTimeLIneEvent";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { EventData } from "../PipelineModal";

// Define the type for the option
export interface OptionType {
  label: string;
  value: string;
}
export interface TimeLineData {
  stageId?: string | undefined;
  id: string | undefined;
  eventId: string | undefined | null;
  attributeId: string | undefined;
  stageName: SingleValue<OptionType> | null;
  logo?: string;
  startDate: Date | null;
  isError: boolean;
  errorType?:string;
  isDuplicate: boolean;
  value: string | undefined;
  isUpdated: boolean;
}

interface Props {
  setActiveModal: React.Dispatch<
    React.SetStateAction<"modal" | "loader" | "success" | "delete">
  >;
  timeLineData: TimeLineData[];
  setTimeLineData: React.Dispatch<React.SetStateAction<TimeLineData[]>>;
  setDeleteTimeLineIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  customerId: string | number;
  stageData: StageData[];
  options: OptionType[];
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>;
  hasNextPage: boolean | undefined;
  isDataLoading: boolean;
  hasEventNextPage: boolean | undefined;
  status: "success" | "loading" | "error";
  handleInfiniteScroll: () => void;
  isFetching: boolean;
  eventData: EventData[];
}

// Dummy data for the select options
// const options: OptionType[] = [
//   { label: "Email Verified", value: "email" },
//   { label: "Order Delivery Date", value: "order" },
//   { label: "Demo Scheduled", value: "Demo" },
//   { label: "Product Viewed", value: "view" },
//   { label: "Trial Started", value: "trial" },
// ];
const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#ffff",
    width: "177px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #707070",
    borderRadius: "2px",
    marginRight: "2px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "8px",
    maxHeight: "227px",
    overflowY: "scroll",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#000" : "#000",
    backgroundColor: isFocused ? "#F0F0F0" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "30px", // Adjust the height if needed
    fontSize: "12px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
    lineHeight: "18px", // Adjust line height
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 11px/17px Poppins",
  }),
};
const ClearIndicator = (props: any) => {
  const { innerProps } = props;

  return (
    <div
      {...innerProps}
      style={{ padding: "0 8px", cursor: "pointer", color: "#707070" }}
    >
      <span>
        <i className="fa-solid fa-xmark"></i>
      </span>
    </div>
  );
};
const TimelineForm = ({
  handleInfiniteScroll,
  status,
  hasEventNextPage,
  eventData,
  isFetching,
  isDataLoading,
  setActiveModal,
  timeLineData,
  setTimeLineData,
  hasNextPage,
  fetchNextPage,
  setDeleteTimeLineIndex,
  customerId,
  stageData,
  options,
}: Props) => {
  // Handle dropdown change
  const handleDropdownChange = (
    newValue: SingleValue<OptionType>,
    index: number,
  ) => {
    const isDuplicate = timeLineData.some(
      (data, i) => data.stageName?.label === newValue?.label && i !== index,
    );

    // Find the corresponding stage data for the selected option
    const selectedStage = stageData.find(
      (data) => data.stageName === newValue?.label,
    );

    // Extract stageId and attributeId or set to undefined if not found
    const stageId = selectedStage ? selectedStage.stageId : undefined;
    const attributeId = selectedStage
      ? selectedStage.attributeId.toString()
      : undefined;
    const updatedTimeLineData = timeLineData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          isError: isDuplicate,
          isDuplicate: isDuplicate,
          stageName: newValue,
          stageId: stageId, // Set the extracted stageId
          attributeId: attributeId, // Set the extracted attributeId
          isUpdated: true,
        };
      }
      return data; // No change for other items
    });

    setTimeLineData(updatedTimeLineData);
  };

  const onDateChange = (newDate: Date | null, index: number) => {
    const currentDate = new Date();

    // Remove the time component for accurate date-only comparison
    currentDate.setHours(0, 0, 0, 0);
    if (newDate !== null) {
      newDate.setHours(0, 0, 0, 0);
    }

    if (newDate === null || newDate < currentDate) {
      // If the new date is null or before today, mark it as an error
      const updatedTimeLineData = timeLineData.map((data: TimeLineData, i) =>
        i === index ? { ...data, startDate: null, isError: true, errorType: "date" } : data,
      );
      setTimeLineData(updatedTimeLineData);
    } else {
      // If the new date is valid (today or future), update the timeline
      const updatedTimeLineData = timeLineData.map((data: TimeLineData, i) =>
        i === index ? { ...data, startDate: newDate, isUpdated: true, isError: false } : data,
      );
      setTimeLineData(updatedTimeLineData);
    }
  };


  const handleAdd = useCallback(() => {
    const newTimeLineData: TimeLineData = {
      id: undefined,
      eventId: null,
      attributeId: undefined,
      stageName: null,
      startDate: null,
      isError: false,
      isDuplicate: false,
      value: "",
      isUpdated: false,
    };
    const updatedTimeLineData = [...(timeLineData || []), newTimeLineData];
    setTimeLineData(updatedTimeLineData);
  }, [setTimeLineData, timeLineData]);

  return (
    <div>
      <h4 className={`${styles.heading}`}>User signed up</h4>
      <div className={styles.labelDiv}>
        <div className={styles.stageName}>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeName`}
          >
            Stage name
          </label>
        </div>
        <div className={styles.iconDiv}>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeIcon`}
          >
            Icon
          </label>
        </div>
        <div>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeIcon`}
          >
            Date of reaching the stage
          </label>
        </div>
      </div>
      <div
        className={styles.timelineDiv}
        onScroll={() => {
          if (hasNextPage) {
            fetchNextPage();
          }
        }}
      >
        <InfiniteScroll
          className={`${styles.infiniteScrollDiv}`}
          hasMoreBottom={hasEventNextPage}
          loadMoreFromBottom={handleInfiniteScroll}
          infiniteLoader={
            isFetching && eventData?.length === 0 ? <Loader /> : undefined
          }
          errorMessage={
            status === "error" && eventData?.length === 0 ? (
              <div className={`d-flex flex-column m-auto`}>No Data Found</div>
            ) : (
              ""
            )
          }
        >
          {isDataLoading && <Loader />}
          {timeLineData.map((data: TimeLineData, index: number) => (
            <div
              key={index}
              className={`d-flex flex-column pb-2 mb-2 ${true ? styles.borderBottom : ""
                }`}
            >
              <div className="d-flex align-items-center">
                <div className="me-4">
                  <Select
                    value={data.isDuplicate ? null : data.stageName}
                    onChange={(opt) => {
                      handleDropdownChange(opt, index);
                    }}
                    options={options}
                    placeholder="Add stage name..."
                    isSearchable={true}
                    styles={customStyles}
                    isClearable={true} // Enables the clear indicator (X icon)
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: null,
                      ClearIndicator, // Use custom ClearIndicator
                    }}
                    onMenuScrollToBottom={() => {
                      if (hasNextPage) {
                        fetchNextPage();
                      }
                    }}
                    isLoading={isFetching}
                    loadingMessage={() => (
                      <div className={styles.loaderHeight}>
                        <Loader className={styles.loader} />
                      </div>
                    )}
                  />
                </div>

                <div className="me-4">
                  {/* {true && (
                <label
                  className={`mb-1 ${styles.timelineLabel}`}
                  htmlFor={`attributeIcon`}
                >
                  Icon
                </label>
              )} */}
                  <div className="d-flex align-items-center">
                    <div className={`${styles.iconWrapper} me-2`}>
                      <div
                        className={`${styles.iconBox} d-flex align-items-center justify-content-center`}
                      >
                        <span className={`${styles.leftText}`}>
                          {data.stageName !== null ? "U" : "-"}
                        </span>
                        <div className="d-flex flex-column justify-content-center">
                          <span className={`${styles.rightText}`}>
                            {/* {form.name?.trim()?.length ? form.name.trim()[0] : "n"} */}
                            {data.stageName?.label.trim()?.length
                              ? data.stageName.label.trim()[0]
                              : ""}
                          </span>
                          <span className={`${styles.rightText}`}>
                            {data.stageName !== null ? data.id || data.stageId : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  {/* <label
                className={`mb-1 ${styles.timelineLabel}`}
                htmlFor={`attributeIcon`}
              >
                Date of reaching the stage
              </label> */}
                  <div>
                    <DatePicker
                      selected={data.startDate} // The selected date in the date picker
                      onChange={(date: Date | null) =>
                        onDateChange(date, index)
                      } // Updates the selected date
                      placeholderText="MM/DD/YYYY" // Placeholder when no date is selected
                      dateFormat="MM/dd/yyyy" // Date format to display
                      className={`${styles.datePicker}`} // Custom class for the input field
                    />
                  </div>
                </div>
                {timeLineData.length > 1 && index !== 0 && (
                  <div className={`${styles.deleteWrapper}`}>
                    <span
                      className={`${styles.deleteBtn}`}
                      onClick={() => {
                        setActiveModal("loader");
                        setTimeout(() => {
                          setActiveModal("delete");
                        }, 1000);
                        setDeleteTimeLineIndex(index);
                      }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                )}
              </div>
              {data.isError && (
                <div className={styles.errorText}>
                  {data.isDuplicate 
                    ? data.stageName?.label!==undefined && `Stage ${data.stageName?.label} is already given. Please add another`
                    :data.errorType==="date"? "Invalid date! please select a valid date to continue": "*Please add the above details to move forward"}
                </div>
              )}
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <div className="d-flex align-items-center mb-3" onClick={() => {
        handleAdd();
      }}>
        <span
          className={`cursor-pointer ${styles.addBtn}`}
         
        >
          <i className="fa-solid fa-plus"></i>
        </span>
        <span className={`ps-2 cursor-pointer ${styles.addText}`}>
          Add More
        </span>
      </div>
    </div>
  );
};

export default TimelineForm;
