import { useState } from "react";
import styles from "./DynamicProgressBar.module.scss";

interface Props {
  filledPercentage: number;
  filledBox: number;
}
const DynamicProgressBar = ({ filledBox, filledPercentage }: Props) => {
  // Total number of boxes
  const totalBoxes = 10;

  // State to track the number of filled boxes
  // const [filledBoxes, setFilledBoxes] = useState(0);

  // Calculate the filled percentage
  // const filledPercentage = Math.round((filledBoxes / totalBoxes) * 100);

  return (
    <div className="d-flex align-items-center">
      {Array.from({ length: totalBoxes }, (_, index) => (
        <div
          key={index}
          className={`${styles.emptyBox} ${
            index < filledBox ? styles.filledBox : ""
          }`}
          // onClick={() => setFilledBoxes(Math.min(totalBoxes, filledBoxes + 1))}
          style={{ cursor: "pointer" }}
        ></div>
      ))}
      <span className={`ms-1 ${styles.filledPercent}`}>
        {filledPercentage}%
      </span>
    </div>
  );
};

export default DynamicProgressBar;
