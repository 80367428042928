import React, { useEffect } from "react";
import TimelineInfo from "../../../Ticket/children/TicketHeader/Children/TimelineInfo/TimelineInfo";
import {
  TimelineActionTypes,
  useTimeline,
} from "../../../Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import { useCampaignTimeline } from "../hooks/useCampaignData";

export const initialTimelineData: any = {
  timeline: [
    {
      year: "2024",
      label: "day 0",
      events: {
        minor: [],
        major: [
          {
            month: 1,
            label: "Oct 2024",
            logins: 0,
            details: [],
          },
        ],
        attribute: [],
      },
      bgColor: "#B1E3FF",
      position: "top",
    },
  ],
  last_updated: new Date().toDateString(),
};

const CampaignTimeline = ({
  showTimeline,
  timelineRef,
  campaignId,
  onLoad,
}: {
  showTimeline: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
  campaignId?: number;
  onLoad?: () => void;
}) => {
  const { data, isLoading } = useCampaignTimeline(campaignId);
  // const { timelineInfo, id } = useAppSelector(
  //   (state) => state.shopifySidebar.customerData,
  // );
  // const customerDataAJAXStatus = useAppSelector(
  //   (state) => state.shopifySidebar.customerDataStatus,
  // );

  // const showLoader = useMemo(() => {
  //   if (timelineInfo) {
  //     return false;
  //   }
  //   return customerDataAJAXStatus === "pending" ? true : false;
  // }, [customerDataAJAXStatus, timelineInfo]);

  const { dispatch } = useTimeline();

  useEffect(() => {
    if (data) {
      if (onLoad) {
        onLoad();
      }
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: data,
      });
      dispatch({
        type: TimelineActionTypes.SET_LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: TimelineActionTypes.SET_LOADING,
        payload: isLoading,
      });
    }
  }, [data, isLoading, dispatch]);

  // useEffect(() => {
  //   dispatch({
  //     type: TimelineActionTypes.SET_LOADING,
  //     payload: showLoader,
  //   });
  // }, [showLoader, dispatch]);

  return (
    <TimelineInfo
      timelineRef={timelineRef}
      showTimeline={showTimeline}
    />
  );
};

export default CampaignTimeline;
