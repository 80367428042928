import styles from "./TableData.module.scss";
import TableRow from "./Children/TableRow/TableRow";
import Loader from "src/components/Loader";
import { SegementData } from "src/services/Campaign/getAllSegments.serivce";
import { v4 as uuid } from "uuid";

const TableData = ({
  allSegments,
  isError,
  isLoading,
  onStatusChange,
  onBack,
}: {
  allSegments: SegementData[];
  isError: boolean;
  isLoading: boolean;
  onStatusChange: (segmentId: number) => void;
  onBack: (id?: string | number) => void;
}) => {
  return (
    <div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableHeader}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.element}`}>Segment name</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.element}`}>Timeline</span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.element}`}> Status</span>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : isError ? (
        <div
          className={`d-flex justify-content-center align-items-center text-danger`}
        >
          Something went wrong!
        </div>
      ) : !allSegments || allSegments.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center w-100">
          No Segments Found!
        </div>
      ) : (
        allSegments?.map((segment) => (
          <TableRow
            segmentData={segment}
            onStatusChange={onStatusChange}
            key={segment.id ?? uuid()}
            onBack={onBack}
          />
        ))
      )}
    </div>
  );
};

export default TableData;
