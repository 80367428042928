import editIcon from "src/assets/images/EditIcon.svg";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import DeleteTemplate from "./Children/DeleteTemplate/DeleteTemplate";
import styles from "./TemplateSection.module.scss";
import { useState } from "react";

const TemplateSection = ({
  template,
  onDelete,
  onEdit,
}: {
  template: IEmailTemplateVariant;
  onDelete: (templateId: string | number) => void;
  onEdit: () => void;
}) => {
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

  return (
    <div className="position-relative h-100">
      <div>
        <div className={`d-flex align-items-center mb-4 pb-3`}>
          <h6 className={`me-4 mb-0 ${styles.heading}`}>
            {template.variantName}
          </h6>
          <img
            src={editIcon}
            alt="edit"
            className={`cursor-pointer`}
            width={10}
          />
        </div>
        <div className="px-4">
          <div
            className={`d-flex justify-content-between align-items-center px-4 ${styles.templateData}`}
          >
            <p className={`mb-0 ${styles.tempName}`}>
              {template.fileName ?? "NA"}
            </p>
            <div className="d-flex align-items-center">
              <img
                src={editIcon}
                alt="edit"
                className={`cursor-pointer`}
                width={10}
                onClick={onEdit}
              />
              <span
                className={`ps-2 ${styles.deleteTemp}`}
                onClick={() => setShowDeleteOverlay(true)}
              >
                <i className="fa-solid fa-trash"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      {showDeleteOverlay && (
        <DeleteTemplate
          template={template}
          onHide={() => setShowDeleteOverlay(false)}
          onDelete={() => onDelete(template.templateId)}
        />
      )}
    </div>
  );
};

export default TemplateSection;
