import { useRef, useState } from "react"; // Importing useRef and useState hooks from React

/**
 * Custom hook that synchronizes state with a ref.
 *
 * @template T - The type of the state value.
 * @param {T} initialValue - The initial value of the state.
 * @returns {[T, React.MutableRefObject<T>, (value: T | ((prevState: T) => T)) => void]}
 *          - Returns the state, a ref object pointing to the state, and a function to update the state.
 */
export const useSyncedState = <T>(initialValue: T) => {
  const [state, setState] = useState<T>(initialValue); // Initializing state with the initial value
  const stateRef = useRef<T>(initialValue); // Creating a ref to hold the state value

  /**
   * Function to update the state and the ref.
   *
   * @param {T | ((prevState: T) => T)} value - The new state value or a function that returns the new state value.
   */
  const setSyncedState = (value: T | ((prevState: T) => T)) => {
    if (typeof value === "function") {
      // Check if the value is a function
      setState((prevState) => {
        // Update the state using the function
        const newValue = (value as (prevState: T) => T)(prevState); // Get the new state value
        stateRef.current = newValue; // Update the ref with the new state value
        return newValue; // Return the new state value
      });
    } else {
      // If the value is not a function
      stateRef.current = value; // Update the ref with the new state value
      setState(value); // Update the state with the new state value
    }
  };

  return [state, stateRef, setSyncedState] as const; // Return the state, ref, and the function to update them
};
