import { forwardRef, useEffect, useRef, useState } from "react";
import styles from "./Step.module.scss";
import { Dropdown, Form } from "react-bootstrap";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import { TemplateStep } from "src/services/LiveChat/ScheduleFollowups/getAllTemplates";
import { v4 as uuid } from "uuid";
import DatePicker from "react-datepicker";
import { formatDateTimeString } from "src/utils/dateLibrary";
import useStep from "./useStep";
import SendMessageIf from "src/components/MessageSchedule/Children/ScheduleBox/Children/SendMessageIf/SendMessageIf";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import {
  OnFileDeleteRes,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";

type Props = {
  step: TemplateStep;
  firstStep: boolean;
  lastStep: boolean;
  index: number;
  selectedTemplateId: string | null;
  setButtonText: (text: string) => void;
  resetApiState: () => void;
  scheduleMessageFor: "innerTicket" | "liveChat" | "userSegment";
  showValidationErrors: boolean;
  handleRemoveStep: (stepId: number | string) => void;
};

const CustomDatePicker = forwardRef(
  ({ value, onClick, faIcon, open }: any, ref: any) => (
    <div
      className={`d-flex align-items-center justify-content-between p-0 ${styles.dateDiv}`}
      onClick={onClick}
      ref={ref}
    >
      <span>
        <i className={`fa-regular ${faIcon} m-auto mx-1 me-2`} />
        {value}
      </span>
      <span>
        <i className={`fa-solid fa-chevron-${open ? "up" : "down"}`}></i>
      </span>
    </div>
  ),
);

const Step = ({
  step,
  firstStep,
  lastStep,
  index,
  selectedTemplateId,
  scheduleMessageFor,
  showValidationErrors,
  handleRemoveStep,
}: Props) => {
  const {
    template,
    isFileUploading,
    setIsFileUploading,
    setShow,
    show,
    Decrement,
    Increment,
    addAnotherStep,
    handleItemClick,
    seletectedFollowUpType,
    updateCondition,
    updateFollowUpValue,
    updateHeading,
    updateMessage,
    selectedFiles,
    setSelectedFiles,
    addRemovedAttachmentId,
  } = useStep({ selectedTemplateId, step });

  const [attachments, setAttachments] = useState(step?.attachments ?? []);
  const [message, setMessage] = useState(step.templateMessage);
  const [remove, setRemove] = useState(false);
  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current) {
      updateMessage(message);
    } else {
      initialized.current = true;
    }
  }, [message]);

  if (!template) {
    return null;
  }

  if (!selectedTemplateId) {
    return null;
  }
  return (
    <div>
      <div className={`mb-2 ${styles.stepsWrapper}`}>
        <div className="position-relative">
          <p className={`mb-1 text-center ${styles.heading}`}>
            Step {index + 1}
          </p>
          {index >= 1 && (
            <div className={`${styles.removeBtn}`}>
              <p
                className={`mb-0 ${styles.removeStep}`}
                onClick={() => handleRemoveStep(step.stepId)}
              >
                <span className={`pe-1 ${styles.removeIcon}`}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
                <span className="d-block">Remove step </span>
              </p>
            </div>
          )}
        </div>
        {remove ? (
          <>
            <div className={`${styles.removeWrap}`}>
              <span className={`d-block ${styles.removeStage}`}>
                Removing...
              </span>
              <div className="d-flex justify-content-center mt-2">
                <div
                  className={`spinner-grow text-primary ${styles.spinner}`}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className={`spinner-grow text-primary ${styles.spinner}`}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className={`spinner-grow text-primary ${styles.spinner}`}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className={`spinner-grow text-primary ${styles.spinner}`}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className={`spinner-grow text-primary ${styles.spinner}`}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={`p-2 ${styles.stepsWrapper2}`}>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className={`${styles.formLabel}`}>
                  Select when to send this follow-up
                </Form.Label>
                <div className={`${styles.followBox}`}>
                  <div className="p-2">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                      <p className={`mb-0 ${styles.text}`}>Send this after:</p>
                      <div className={`d-flex  ms-4 flex-wrap`}>
                        {step.scheduleFollowUpType !== "dateAndTime" ? (
                          <div className={` ms-4 d-flex align-items-center`}>
                            <p className="mb-0 d-flex align-items-center">
                              {step.scheduleFollowUpValue}
                            </p>
                            <div
                              className={`ms-2 d-flex flex-column ${styles.arrowBox}`}
                            >
                              <div
                                className={`${styles.arrow} pb-1 my-1`}
                                role="button"
                              >
                                <div onClick={Increment}>
                                  <i className="fa-solid fa-caret-up"></i>
                                </div>
                              </div>
                              <div
                                className={`${styles.arrow} pb-1 my-1`}
                                role="button"
                              >
                                <div onClick={Decrement}>
                                  <i className="fa-solid fa-caret-down"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              className={`${styles.datePick} ${styles.customBtn2}`}
                              role="button"
                            >
                              <DatePicker
                                selected={
                                  new Date(
                                    typeof step.scheduleFollowUpValue !==
                                    "number"
                                      ? step.scheduleFollowUpValue
                                      : "",
                                  )
                                }
                                onChange={(date) => {
                                  if (date) {
                                    const newDateTime = new Date(
                                      typeof step.scheduleFollowUpValue !==
                                      "number"
                                        ? step.scheduleFollowUpValue
                                        : "",
                                    );
                                    newDateTime.setFullYear(date.getFullYear());
                                    newDateTime.setMonth(date.getMonth());
                                    newDateTime.setDate(date.getDate());
                                    console.log(
                                      formatDateTimeString(
                                        newDateTime.toISOString(),
                                      ),
                                    );

                                    updateFollowUpValue(
                                      formatDateTimeString(
                                        newDateTime.toISOString(),
                                      ),
                                    );
                                  }
                                }}
                                dateFormat="MMM d, yyyy"
                                closeOnScroll={true}
                                customInput={
                                  <CustomDatePicker
                                    faIcon={"fa-calendar-days"}
                                  />
                                }
                                wrapperClassName={styles.wrapperDatepicker}
                              />
                            </div>
                            <div
                              className={`${styles.customBtn2} `}
                              role="button"
                            >
                              <DatePicker
                                selected={
                                  new Date(
                                    typeof step.scheduleFollowUpValue !==
                                    "number"
                                      ? step.scheduleFollowUpValue
                                      : "",
                                  )
                                }
                                onChange={(date) => {
                                  if (date) {
                                    const newDateTime = new Date(
                                      typeof step.scheduleFollowUpValue !==
                                      "number"
                                        ? step.scheduleFollowUpValue
                                        : "",
                                    );
                                    newDateTime.setHours(date.getHours());
                                    newDateTime.setMinutes(date.getMinutes());
                                    newDateTime.setSeconds(date.getSeconds());
                                    console.log(
                                      formatDateTimeString(
                                        newDateTime.toISOString(),
                                      ),
                                    );

                                    updateFollowUpValue(
                                      formatDateTimeString(
                                        newDateTime.toISOString(),
                                      ),
                                    );
                                  }
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="h:mm aa"
                                customInput={
                                  <CustomDatePicker faIcon={"fa-clock"} />
                                }
                                wrapperClassName={styles.wrapperDatepicker}
                              />
                            </div>
                          </>
                        )}

                        <Dropdown
                          onToggle={setShow}
                          show={show}
                        >
                          <Dropdown.Toggle
                            as="div"
                            id="dropdown-basic"
                            bsPrefix={`dropdown cursor-pointer ms-3 px-2 ${styles.customBtn}`}
                            className="d-flex flex-row justify-content-between"
                          >
                            <div>
                              {seletectedFollowUpType[
                                step.scheduleFollowUpType
                              ] ?? "Days"}
                            </div>
                            <div className="ms-2">
                              {show ? (
                                <span>
                                  <i className="fa-solid fa-caret-up"></i>
                                </span>
                              ) : (
                                <span>
                                  <i className="fa-solid fa-caret-down"></i>
                                </span>
                              )}
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            bsPrefix={`dropdown-menu ${styles.dropMenu}`}
                            show={show}
                          >
                            <span
                              className={`${styles.listItem}`}
                              onClick={() => {
                                handleItemClick("days");
                              }}
                            >
                              Days
                            </span>
                            <span
                              className={`${styles.listItem}`}
                              onClick={() => {
                                handleItemClick("businessDays");
                              }}
                            >
                              Business days
                            </span>
                            {/* <span
                            className={`${styles.listItem}`}
                            onClick={() => {
                              handleItemClick("weeks");
                            }}
                          >
                            Weeks
                          </span> */}
                            {/* <span
                            className={`${styles.listItem}`}
                            onClick={() => {
                              handleItemClick("months");
                            }}
                          >
                            Months
                          </span>
                          <span
                            className={`${styles.listItem}`}
                            onClick={() => {
                              handleItemClick("dateAndTime");
                            }}
                          >
                            Set date and time
                          </span> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <SendMessageIf
                      selected={step.sendMessageCondition ?? "none"}
                      onSelect={updateCondition}
                    />
                  </div>
                </div>
              </Form.Group>
              {/* {firstStep ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className={`${styles.formLabel}`}>
                  Template Heading
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={step.templateHeading}
                  onChange={(e) => updateHeading(e.target.value)}
                  className={`${styles.inputText}`}
                />
              </Form.Group>
            ) : null} */}

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className={`${styles.formLabel}`}>
                  Template Message
                </Form.Label>
                <TinyMCEContainer
                  value={message}
                  className={`${styles.quillBox} ${
                    showValidationErrors && message.trim() === ""
                      ? "border border-danger"
                      : ""
                  }`}
                  onChange={(value: string) => {
                    setMessage(value);
                  }}
                  disableAttachment={false}
                  // disableEmbedding={
                  //   scheduleMessageFor === "innerTicket" ? false : true
                  // }
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  isFileUploading={isFileUploading}
                  setIsFileUploading={setIsFileUploading}
                  uniqueID={uuid()}
                  canDeleteInline={false}
                  attachmentUploadData={attachments as any}
                  areAttachmentsPublic={
                    false
                    // scheduleMessageFor === "innerTicket" ? false : true
                  }
                  onFileDelete={(file: TinyMCEUploadFile) => {
                    return new Promise<OnFileDeleteRes>((resolve, reject) => {
                      if (file.attachmentId) {
                        addRemovedAttachmentId(file.attachmentId);
                      }
                      resolve({ file: file, status: true });
                    });
                  }}
                />
                {showValidationErrors && message.trim() === "" ? (
                  <span className={`${styles.formLabel} text-danger`}>
                    *Please enter your message here
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
            </Form>
          </div>
        )}

        {lastStep ? (
          <p
            className={`text-center mb-0 mt-2 ${styles.addSpan}`}
            onClick={addAnotherStep}
            role="button"
          >
            Add Another Step
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Step;
