import { useCallback, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ECampaignStatus } from "src/services/Campaign/getCampaignById";
import styles from "./ActionDropdown.module.scss";
import PauseModal from "./Children/PauseModal/PauseModal";

const ActionDropdown = ({
  handleUpdateStatus,
  handleDelete,
  status,
  campaignName,
}: {
  handleUpdateStatus: (status: ECampaignStatus) => Promise<void>;
  handleDelete: () => Promise<void>;
  status: ECampaignStatus;
  campaignName: string;
}) => {
  const [context, setContext] = useState<"pause" | "delete" | null>(null);
  const [showMenu, setShowMenu] = useState(false);

  const onPause = useCallback(() => {
    handleUpdateStatus(ECampaignStatus.Paused);
    setContext(null);
  }, [handleUpdateStatus]);

  const onDelete = useCallback(() => {
    handleDelete();
    setContext(null);
  }, [handleDelete]);

  return (
    <Dropdown
      show={showMenu}
      onToggle={(show) => setShowMenu(show)}
      drop="down"
    >
      <Dropdown.Toggle
        id="dropdown-basic"
        as="div"
        className={`ms-md-2 ${styles.ddToggle}`}
      >
        Action
        <span className={`ps-2`}>
          <i className={`fa-solid fa-chevron-${showMenu ? "up" : "down"}`}></i>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        bsPrefix={`dropdown-menu ${styles.ddMenu}`}
        flip={true}
      >
        <button
          className={`${styles.ddItem}`}
          onClick={() => setContext("delete")}
        >
          Delete compaign
        </button>
        <button
          className={`${styles.ddItem}`}
          onClick={() => setContext("pause")}
          disabled={status === ECampaignStatus.Paused}
        >
          Pause compaign
        </button>
      </Dropdown.Menu>

      <Modal
        show={context !== null}
        onHide={() => setContext(null)}
        dialogClassName={`${styles.pauseModalDialog}`}
        contentClassName={`${styles.pauseModalContent}`}
        centered={true}
        backdropClassName={`${styles.backDrop}`}
      >
        {context && (
          <PauseModal
            context={context}
            campaignName={campaignName}
            onHide={() => setContext(null)}
            onSubmit={context === "delete" ? onDelete : onPause}
          />
        )}
      </Modal>
    </Dropdown>
  );
};

export default ActionDropdown;
