import styles from "./UploadCsv.module.scss";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import { useEffect, useMemo, useState } from "react";
import Loader from "src/components/Loader/Loader";
import UploadBox from "./Children/UploadBox/UploadBox";
import ErrorsTable from "./Children/ErrorsTable/ErrorsTable";
import errorImg from "src/assets/images/bxs-error.svg";
import { useFileUploader } from "./useFileUploader";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  UploadCsvParams,
  UPLOAD_CSV_ERRORS_LIMIT,
} from "src/services/CustomerSegments/UploadCSV/uploadCsv.service";
import { useSegmentView } from "../../../hooks/useSegmentView";
import DownloadFile from "src/components/DownloadFile";

export interface Warning {
  show: boolean; // flag to show error
  type: "extensionsupport" | "empty" | null;
}

const UploadCsv = ({
  setNextBtnDisabled,
}: {
  setNextBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { state, showErrors, updateState } = useUploadNewCsvModalContext();
  const { integrationId } = useParams();
  const [showFileWarning, setShowFileWarning] = useState<Warning>({
    show: false, // flag to show error
    type: null,
  });
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (showErrors) {
      if (
        !state.uploadCSV ||
        !(state?.uploadCSV?.attachmentId || state?.uploadCSV?.uploadedFileLink)
      ) {
        setShowFileWarning({
          show: true,
          type: "empty",
        });
      }
    }
  }, [showErrors]);

  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return state.uploadCSV?.uploadErrors?.totalErrorCount
      ? Math.ceil(
          state.uploadCSV?.uploadErrors?.totalErrorCount /
            UPLOAD_CSV_ERRORS_LIMIT,
        )
      : 0;
  }, [state.uploadCSV?.uploadErrors]);

  const [params, setParams] = useState<UploadCsvParams>({
    start: UPLOAD_CSV_ERRORS_LIMIT * currentPage - UPLOAD_CSV_ERRORS_LIMIT,
    limit: UPLOAD_CSV_ERRORS_LIMIT,
  });
  const onSuccess = () => {
    delete params.file;
  };
  const { data, isLoading, isError, uploadFile } = useFileUploader({
    setIsFileUploading,
    onSuccess,
  });
  useEffect(() => {
    if (isError) {
      setNextBtnDisabled(true);
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while uploading!",
        type: "danger",
      });
    } else if (data) {
      setNextBtnDisabled(false);
    }
  }, [isError, data]);

  const onChangeAttachment = (file: File, abortController?: any) => {
    updateState({
      uploadCSV: {
        ...state.uploadCSV,
        sampleCSVTemplateLink: state.uploadCSV?.sampleCSVTemplateLink ?? "",
        uploadedFile: file,
        uploadedFileName: file.name,
      },
    });
    if (file) {
      setIsFileUploading(true);
      setParams((prev) => {
        return {
          ...params,
          file: file,
          abortControllerSignal: abortController?.signal,
        };
      });
      setShowFileWarning({ show: false, type: null });
    } else {
      return; // More than one file is not supported
    }
  };
  const { activeSegmentDetails } = useSegmentView();

  useEffect(() => {
    if (params.file || params.attachmentId || state?.uploadCSV?.attachmentId) {
      params.start =
        UPLOAD_CSV_ERRORS_LIMIT * currentPage - UPLOAD_CSV_ERRORS_LIMIT;
      if (!params.file) {
        params.attachmentId = params.attachmentId
          ? params.attachmentId
          : state?.uploadCSV?.attachmentId ?? undefined;
      } else {
        delete params.attachmentId;
      }
      uploadFile(params, activeSegmentDetails.activeSegmentType ?? "");
    }
  }, [params, currentPage]);

  useEffect(() => {
    if (data) {
      updateState({
        uploadCSV: {
          ...state.uploadCSV,
          sampleCSVTemplateLink: state.uploadCSV?.sampleCSVTemplateLink ?? "",
          uploadErrors: data,
          attachmentId: data.attachmentId,
        },
      });
    }
  }, [data]);

  return (
    <div>
      <h4 className={`mb-3 ${styles.heading}`}>Upload csv</h4>
      <ul className="ps-3 pb-3">
        <li className={`${styles.listItem}`}>
          Download the{" "}
          <DownloadFile
            fileName="Saufter_sample_template-Sheet1.csv"
            url={"/api/segment/userSegment/csv/downloadSampleCSV"}
            className={`${styles.downloadBtn}`}
          >
            <span className={`${styles.link}`}>sample users CSV template</span>
          </DownloadFile>{" "}
          for format guidance.
        </li>
        <li className={`${styles.listItem}`}>
          In the csv file, columns which are required: Full name, signup date,
          either email or user ID.
        </li>
        <li className={`${styles.listItem}`}>
          Import the CSV file to initiate the upload process.
        </li>
        <li className={`${styles.listItem}`}>
          Avoid editing or deleting template columns to prevent import errors.
        </li>
        <li className={`${styles.listItem}`}>
          Map attributes to align CSV data with platform fields.
        </li>
        <li className={`${styles.listItem}`}>
          Complete the import process to transfer data successfully.
        </li>
      </ul>
      <UploadBox
        setShowFileWarning={setShowFileWarning}
        showFileWarning={showFileWarning}
        uploadedAttachmentName={state?.uploadCSV?.uploadedFileName ?? ""}
        uploadedAttachmentUrl={state?.uploadCSV?.uploadedFileLink ?? null}
        onChangeAttachment={onChangeAttachment}
        attachment={state.uploadCSV?.uploadedFile}
      />
      {isFileUploading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Loader className={styles.fileLoading} />
        </div>
      ) : state?.uploadCSV?.uploadErrors ? (
        <div>
          {(state?.uploadCSV?.uploadErrors?.heading &&
            state.uploadCSV?.uploadErrors?.heading?.length > 0) ||
          (state?.uploadCSV?.uploadErrors?.subText &&
            state.uploadCSV?.uploadErrors?.subText?.length > 0) ? (
            <div
              className={`d-flex align-items-start mx-auto mt-4 ${styles.errorBox}`}
            >
              <div className="d-flex align-items-start mt-1">
                <img
                  src={errorImg}
                  alt="error"
                />
              </div>
              <div className="ps-1">
                {state?.uploadCSV?.uploadErrors?.heading && (
                  <h6 className="mb-1">
                    {state?.uploadCSV?.uploadErrors?.heading}
                  </h6>
                )}
                {state.uploadCSV?.uploadErrors?.subText && (
                  <span>{state.uploadCSV?.uploadErrors?.subText}</span>
                )}
              </div>
            </div>
          ) : null}
          {state?.uploadCSV?.uploadErrors?.errors &&
          state?.uploadCSV?.uploadErrors?.errorIds?.length ? (
            <div className="mt-2">
              <ErrorsTable
                uploadErrors={state?.uploadCSV?.uploadErrors}
                isLoading={isLoading}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                attachmentId={state?.uploadCSV?.attachmentId ?? ""}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default UploadCsv;
