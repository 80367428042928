import { useCallback, useMemo } from "react";
import ComparisonTypes from "../ComparisonTypes/ComparisonTypes";
import ConditionValue from "../ConditionValue/ConditionValue";
import { useParams } from "react-router-dom";
import {
  ConditionI,
  Group,
} from "src/services/Automation/getAutomationById.service";
import { ConditionOptionsResponse } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import { useConditionGroupState } from "../../../../../hooks/useConditionGroupState";
import ConditionTypes from "../ConditionTypes/ConditionTypes";
import FieldDropdown from "../FieldDropdown/FieldDropdown";
import styles from "./Condition.module.scss";
interface Props {
  condition: ConditionI;
  conditionIndex: number;
  groupIndex: number;
  conditionOptions: ConditionOptionsResponse | null;
}

const Condition = ({
  condition,
  conditionIndex,
  groupIndex,
  conditionOptions,
}: Props) => {
  const { type } = useParams();
  const { conditionGroup, updateState } = useConditionGroupState();

  const findOptionByFieldKey = useCallback(
    (fieldKey: string) =>
      conditionOptions?.conditionOptions?.find(
        (option) => option.fieldKey === fieldKey,
      ),
    [conditionOptions],
  );

  const option = useMemo(() => {
    if (findOptionByFieldKey) return findOptionByFieldKey(condition.fieldKey);
    return null;
  }, [findOptionByFieldKey, condition]);

  const selectedConditionType = useMemo(() => {
    if (condition.conditionType) {
      return option?.conditionTypes.find(
        (type) => type.conditionTypeKey === condition.conditionType,
      );
    } else {
      return option?.conditionTypes[0];
    }
  }, [condition, option]);

  const handleDeleteCondition = () => {
    if (conditionGroup) {
      const newGroup: Group = { ...conditionGroup };
      newGroup.conditions.splice(conditionIndex, 1); // Remove the condition at the specified index
      newGroup.conditions = newGroup.conditions.map((value, index, array) => {
        if (array[index + 1]) {
          value.nextConditionUUID = array[index + 1].conditionUUID;
        } else {
          value.nextConditionUUID = null;
        }
        return value;
      });
      updateState({
        conditionGroup: newGroup,
        saveStatus: "idle",
      });
    }
  };

  return (
    <div>
      <div className={` ${styles.condition} p-3`}>
        <div className="w-100">
          <div className="d-flex flex-column flex-md-row align-items-start mb-3 mt-2 flex-lg-row w-md-75">
            <FieldDropdown
              conditionIndex={conditionIndex}
              groupIndex={groupIndex}
              options={conditionOptions?.conditionOptions ?? []}
              prefix={"in"}
            />
            {option && (
              <>
                <ConditionTypes
                  conditionIndex={conditionIndex}
                  groupIndex={groupIndex}
                  options={option.conditionTypes}
                  prefix={"if"}
                />
                <ComparisonTypes
                  conditionIndex={conditionIndex}
                  groupIndex={groupIndex}
                  options={
                    condition.conditionType
                      ? option.conditionTypes.find(
                          (cond) =>
                            cond.conditionTypeKey === condition.conditionType,
                        )?.compareDetails ?? []
                      : option.conditionTypes[0].compareDetails ?? []
                  }
                  isMulti={selectedConditionType?.isMulti ?? false}
                />
              </>
            )}
            <div
              className="ms-auto"
              onClick={handleDeleteCondition}
            >
              {/* <MdDelete style={{ color: "#707070",width: "14px" ,height: "18px" }} className="" role="button" /> */}
              <span
                style={{ color: "#707070", width: "14px", height: "18px" }}
                className=""
                role="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                >
                  <path
                    id="Icon_material-delete"
                    data-name="Icon material-delete"
                    d="M8.5,20.5a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V8.5H8.5Zm13-15H18l-1-1H12l-1,1H7.5v2h14Z"
                    transform="translate(-7.5 -4.5)"
                    fill="#707070"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        {option && (
          <ConditionValue
            compareDetails={selectedConditionType?.compareDetails ?? []}
            conditionIndex={conditionIndex}
            groupIndex={groupIndex}
            fieldValues={[]}
            isMulti={selectedConditionType?.isMulti ?? false}
            fieldKey={selectedConditionType?.conditionTypeKey ?? ""}
          />
        )}
      </div>
      {conditionGroup?.conditionsLogicalOperator &&
      conditionGroup.conditions.length - 1 != conditionIndex ? (
        <div className="d-flex align-items-center justify-content-center my-2">
          <span
            className={`m-2 d-flex align-items-center justify-content-center ${styles.operator}`}
          >
            {conditionGroup?.conditionsLogicalOperator === "or"
              ? "OR"
              : conditionGroup?.conditionsLogicalOperator === "and"
                ? "AND"
                : ""}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Condition;
