import { axiosJSON } from "src/globals/axiosEndPoints";
import { CampaignSubCategory } from "./getAllCampaignCategories.service";

export interface GetAllCampaignSubCategoryParams {
  start: number;
  limit: number;
  categoryId: string | number;
}

export interface GetAllCampaignSubCategoryResponse {
  allSubCategories: Array<CampaignSubCategory>;
  hasMore: boolean;
}

const getAllCampaignSubCategoriesService = async (
  params: GetAllCampaignSubCategoryParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/getAllSubCategories`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllCampaignSubCategoryResponse = {
    allSubCategories: res.data,
    hasMore: true,
  };

  if (ret.allSubCategories.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
};

export default getAllCampaignSubCategoriesService;
