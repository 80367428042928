import React, { useRef, useState } from "react";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { Button, Spinner } from "react-bootstrap";
import ViewTemplateButtonV2 from "src/components/ViewTemplateButtonV2";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import { v4 as uuid } from "uuid";
import sendImg from "src/assets/images/sendIcon.svg";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import saufterLogo from "src/assets/images/saufter.gif";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import MentionBox from "../../../SendMessage/children/MentionBox/MentionBox";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { UserMentionData } from "src/services/TicketService/InternalChatBot/getAllMentions";
interface Props {
  sendMessageHandler: () => void;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  attachmentUploadData?: UploadAttachmentRes;
  setAttachmentUploadData: React.Dispatch<
    React.SetStateAction<UploadAttachmentRes | undefined>
  >;
  selectedFiles: any;
  setSelectedFiles: React.Dispatch<React.SetStateAction<any>>;
  currentAttachmentBatchNumber?: string;
  setCurrentAttachmentBatchNumber: React.Dispatch<React.SetStateAction<string>>;
  isFileUploading?: boolean;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
  err: boolean;
  setErr: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAllMentions: () => Promise<UserMentionData[] | undefined>;
  sendMsgLoading: boolean;
  styles: {
    [className: string]: string;
  };
  disableAttachment?: boolean;
}
const MessageEditor = ({
  sendMessageHandler,
  message,
  setMessage,
  attachmentUploadData,
  setAttachmentUploadData,
  selectedFiles,
  setSelectedFiles,
  currentAttachmentBatchNumber,
  setCurrentAttachmentBatchNumber,
  isFileUploading,
  setIsFileUploading,
  err,
  setErr,
  fetchAllMentions,
  sendMsgLoading,
  styles,
  disableAttachment,
}: Props) => {
  const editorRef = useRef(null);
  const [showingWhichModal, setShowingWhichModal] = useState<string>("");
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  return (
    <div className={`${styles.textEditorContainer} mt-auto px-2`}>
      <div className={`d-flex justify-content-end align-items-center mt-2`}>
        <span className={`${styles.templetesBtn}`}>
          {" "}
          <ViewTemplateButtonV2
            selectedTemplate={(templateText: string) => {
              setMessage((msg) => templateText + msg);
            }}
            showingWhichModal={showingWhichModal}
            setShowingWhichModal={setShowingWhichModal}
          />
        </span>
        <div className={`d-flex ms-3 p-1 ${styles.grayBox}`}>
          <div
            className={`d-flex justify-content-center align-items-centerpx-1 ${styles.active}`}
          >
            <div className={`px-1 ${styles.note} cursor-pointer`}>
              <span className={`${styles.noteText} ${styles.activeText}`}>
                +{" "}
              </span>
              {/* Conditionally display active note icon */}
              <img
                src={AddNoteActiveIcon}
                className={`${styles.noteIcon}`}
                alt="note"
              />
              <span className={`${styles.noteText} ${styles.activeText}`}>
                {" "}
                Internal note
              </span>
            </div>
            <div
              className={`${styles.note}  ${styles.cernText} ms-2 d-flex align-items-center cursor-pointer`}
            >
              <div className={`${styles.saufterLogoWrap}`}>
                <img
                  src={saufterLogo}
                  className={`${styles.noteIcon} me-1`}
                  alt="saufter"
                />
              </div>

              <span className="ps-1">Cern</span>
            </div>
          </div>
        </div>
      </div>
      <TinyMCEContainer
        name="message"
        tinymceEditorRef={editorRef}
        canDeleteInline={true}
        className={`${styles.textEditor} ${err && "border-danger"}`}
        value={message}
        mentionsEnabled={false}
        customAutoCompleteOptions={{
          delimiters: {
            "@": {
              fetchOptions: async () => {
                const data = await fetchAllMentions();
                return data?.filter(
                  (mention) => mention.mentionCategoryDetails.key !== "slack",
                );
              },
              renderComponent: (props: any) => <MentionBox {...props} />,
            },
          },
        }}
        onChange={(value) => {
          setMessage(value);
          setErr(false);
        }}
        options={{
          placeholder: "Please type a message....",
          max_height: 250,
          min_height: 110,
        }}
        // enable the attachment picker
        disableAttachment={disableAttachment}
        attachmentUploadData={attachmentUploadData}
        setAttachmentUploadData={setAttachmentUploadData}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        currentAttachmentBatchNumber={currentAttachmentBatchNumber}
        setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
        isFileUploading={isFileUploading}
        setIsFileUploading={setIsFileUploading}
        areAttachmentsPublic={true}
        uniqueID={uuid()}
        buttonContainerClass={`${styles.sendMessageBtn}`}
        buttons={
          <div className={`d-flex me-2 ps-1`}>
            <Button
              type="submit"
              className={`d-flex align-items-center my-auto mx-1 ${styles.sendBtn}`}
              disabled={sendMsgLoading}
              onClick={sendMessageHandler}
            >
              <span className={`my-auto ms-1`}>Send</span>
              {sendMsgLoading ? (
                <Spinner
                  className="ms-1"
                  size="sm"
                />
              ) : (
                <img
                  src={sendImg}
                  alt=""
                  className={`my-auto ${styles.sendImg}`}
                />
              )}
            </Button>
          </div>
        }
      />
      <ErrorModal
        showModal={showError}
        onHide={() => setShowError(false)}
        back={() => setShowError(false)}
        retry={() => {
          setShowLoading(true);
          setTimeout(() => {
            setShowLoading(false);
          }, 1500);
        }}
        showLoader={showLoading}
      />
    </div>
  );
};

export default MessageEditor;
