import styles from "./SelectReturnType.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import { useState } from "react";
import cautionImg from "src/assets/images/caution.svg";
import ParseHTML from "src/components/ParseHTML";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";

const SelectReturnType = () => {
  const [showError, setShowError] = useState(false);
  const { selectedOrder, orders, updateState, ...contextValues } =
    useReturnPreviewState();
  const {
    arsResponse,
    getAllReturnTypes,
    isReturnReasonsAvailable,
    isReturnReasonsAnswered,
    isReturnMethodAvailable,
  } = useGetAutomationData();

  const { allReturnTypes, message } = getAllReturnTypes();

  const handleNext = () => {
    if (selectedOrder) {
      // Get all added items from the selected order
      const addedItems = selectedOrder?.addedItems ?? {};
      const addedItemsLength = Object.keys(addedItems).length;

      // Check if there's at least one return type that is not hidden
      const hasVisibleReturnType = allReturnTypes.some(
        (returnType) => !returnType.hidden,
      );
      // Check if any item's selected exchange with same item or exchange with different item
      const isExchangeItems = Object.values(
        selectedOrder.addedItems ?? {},
      ).some((data) => {
        return (
          data.selectedReturnType === "exchangeWithSameItems" ||
          data.selectedReturnType === "exchangeWithDifferentItems"
        );
      });

      let nextPage: StepName = "configureNextSteps";
      if (isReturnReasonsAvailable) {
        nextPage = "reason";
      } else if (arsResponse?.isExchangeAvailable || isExchangeItems) {
        nextPage = "arsExchange";
      } else if (isReturnMethodAvailable) {
        nextPage = "returnMethod";
      }
      if (!isReturnReasonsAnswered) {
        nextPage = "configureNextSteps";
      }
      if (hasVisibleReturnType) {
        // Count how many items have a selected return type
        const selectReturnTypeLength = Object.values(addedItems).filter(
          (item) => item.selectedReturnType,
        ).length;

        // Proceed to the next step if all items have a selected return type
        if (
          selectReturnTypeLength > 0 &&
          addedItemsLength === selectReturnTypeLength
        ) {
          updateState({
            ...contextValues,
            selectedOrder: {
              ...selectedOrder,
              activeStep: nextPage,
              prevStepForConfig: "returnType",
            },
          });
        } else {
          // Show error if not all items have a selected return type
          setShowError(true);
          return;
        }
      } else {
        // Handle case when all return types are hidden
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: nextPage,
          },
        });
      }
    }
  };

  const handleBack = () => {
    if (selectedOrder) {
      // Go back to the previous step
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "addReturnItems",
        },
      });
    }
  };

  // Get all item IDs from addedItems
  const items = Object.values(selectedOrder?.addedItems ?? {});

  const handleOnChange = (itemId: string, returnTypeId: string) => {
    if (itemId && returnTypeId && selectedOrder) {
      // Hide error message
      setShowError(false);
      let updatedItems = selectedOrder?.addedItems ?? {};

      if (Object.keys(updatedItems).length > 0) {
        if (updatedItems[itemId]) {
          // Update the selected return type for the item
          updatedItems[itemId].selectedReturnType = returnTypeId;
        }
      }

      // Update the state with the modified items
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center mb-3 pb-1">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>Select Return Type</span>
        </div>
        {showError && (
          <span className={`p-1 mb-1 mt-2 ${styles.errorMsg}`}>
            * Please select a return type
          </span>
        )}
        <div>
          <p className={`${styles.selectText}`}>
            Select how do you want to return/exchange these items:
          </p>
          {items.map((item) => {
            const itemData = selectedOrder?.itemsInOrder?.find(
              (value) => value.id + "" === item.itemId + "",
            );

            return (
              <div className={`mb-2 ${styles.orderBox}`}>
                <p className={`mb-0 ${styles.orderHeading}`}>
                  {itemData?.name}
                </p>

                <div className={`d-flex mb-3`}>
                  <div className={`${styles.cart}`}>
                    <img
                      src={
                        itemData?.name === "Acme T-Shirt" ? orderImg : orderImg2
                      }
                      alt="order"
                      className={`${styles.orderImg}`}
                    />
                  </div>
                  <div className={`ms-2`}>
                    <span className={`${styles.orderName}`}>
                      {itemData?.unitPrice?.currencyCode}
                      {itemData?.unitPrice?.amount} x {item.quantity}
                    </span>
                    <span className={`${styles.orderDesc}`}>
                      SKU: {itemData?.sku}
                    </span>
                  </div>
                </div>
                {allReturnTypes?.map((returnType) => {
                  return (
                    <div className="form-check">
                      <input
                        className={`form-check-input ${styles.radioCheck} ${
                          showError &&
                          returnType.hidden === false &&
                          "border-danger"
                        }`}
                        type="radio"
                        name={`returnType--${item.itemId}`}
                        id={`return type--${item.itemId}--${returnType.id}`}
                        disabled={returnType.hidden}
                        checked={item?.selectedReturnType === returnType.type}
                        onClick={() => {
                          handleOnChange(item.itemId, returnType.type);
                        }}
                      />
                      <label
                        className={`form-check-label ${styles.radioLabel}`}
                        htmlFor={`return type--${item.itemId}--${returnType.id}`}
                      >
                        {returnType.name}
                      </label>
                    </div>
                  );
                })}
                {allReturnTypes.every((returnType) => returnType.hidden) && (
                  <div>
                    <div
                      className={`d-flex align-items-start mb-3 ${styles.cautionBox}`}
                    >
                      <img
                        src={cautionImg}
                        alt="caution"
                        width={13}
                        height={13}
                        className="mt-1"
                      />
                      <p className={`ps-1 mb-0`}>
                        <ParseHTML html={message} />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          {/* <div className={`mb-2 ${styles.orderBox}`}>
            <p className={`mb-0 ${styles.orderHeading}`}>Acme T-shirt</p>

            <div className={`d-flex mb-3`}>
              <div className={`${styles.cart}`}>
                <img
                  src={orderImg}
                  alt="order"
                  className={`${styles.orderImg}`}
                />
              </div>
              <div className={`ms-2`}>
                <span className={`${styles.orderName}`}>$ 789 x 2</span>
                <span className={`${styles.orderDesc}`}>SKU : 0101</span>
              </div>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className={`form-check-label ${styles.radioLabel}`}
                htmlFor="flexRadioDefault1"
              >
                Exchange with same item
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label
                className={`form-check-label ${styles.radioLabel}`}
                htmlFor="flexRadioDefault2"
              >
                Exchange with other items
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
              />
              <label
                className={`form-check-label ${styles.radioLabel}`}
                htmlFor="flexRadioDefault3"
              >
                Refund to Store credit
              </label>
            </div>
            <div className="form-check">
              <input
                className={`form-check-input ${styles.radioCheck}`}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault4"
              />
              <label
                className={`form-check-label ${styles.radioLabel}`}
                htmlFor="flexRadioDefault4"
              >
                Refund to original payment method
              </label>
            </div>
            <div className={`d-flex align-items-start ${styles.cautionBox}`}>
              <img
                src={cautionImg}
                alt="caution"
                width={13}
                height={13}
                className="mt-1"
              />
              <p className={`ps-1`}>
                Exchange with same item is not available right now as it is out
                of stock now.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default SelectReturnType;
