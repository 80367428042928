import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getSequenceStepsService, {
  GetSequenceStepParams,
  GetSequenceStepResponse,
} from "src/services/Campaign/getSequenceSteps.service";

const useFetchSequenceSteps = (
  params?: GetSequenceStepParams,
  onSuccess?: (data: GetSequenceStepResponse) => void,
) => {
  const enabled = useMemo(() => !!params, [params]);

  const { data, status } = useQuery<GetSequenceStepResponse>(
    [
      "getSequenceStepsService",
      params?.categoryId,
      params?.templateId,
      params?.variantId,
    ],
    {
      queryFn: () => getSequenceStepsService(params!),
      onSuccess,
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 60000,
      enabled,
    },
  );

  return {
    sequence: data?.data ?? [],
    sequenceFetchStatus: (!enabled
      ? "idle"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected") as AJAXSTATUS,
  };
};

export default useFetchSequenceSteps;
