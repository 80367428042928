import styles from "./NotReturnableItems.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import Lottie from "lottie-light-react";
import policyImg from "src/assets/images/PolicyImg.json";
import {
  IAddedItem,
  IOrderItem,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import orderImg2 from "src/assets/images/order2.png";
import orderImg from "src/assets/images/order1.png";
import info from "src/assets/images/Info.svg";

const NotReturnableItems = () => {
  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();

  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "addReturnItems",
        },
      });
    }
  };

  const handleNext = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "configureNextSteps",
          prevStepForConfig: "notReturnableItems",
        },
      });
    }
  };

  /**
   * Function to retrieve all non-returnable items from a selected order.
   * Returns an array of non-returnable items.
   */
  const allNonReturnableItemPresent = () => {
    // Check if selectedOrder or addedItems is undefined or null
    if (!selectedOrder || !selectedOrder.addedItems) {
      return []; // Return an empty array if selectedOrder or addedItems is not defined
    }

    // Initialize an empty array to store non-returnable items
    let nonReturnableItems: IAddedItem[] = [];

    // Get all item IDs from addedItems
    const itemIds = Object.keys(selectedOrder.addedItems);

    // Check if itemsInOrder has items and itemIds array is not empty
    if (selectedOrder.itemsInOrder?.length && itemIds.length) {
      // Iterate over each item ID
      itemIds.forEach((id) => {
        if (selectedOrder?.addedItems) {
          // Find the item in itemsInOrder that matches the current id
          const item = Object.values(selectedOrder.addedItems).find(
            (v) => v.itemId === id,
          );
          // Check if the item is non-returnable
          if (item?.isReturnExchangeApplicable === false) {
            // Get the quantity of the current item from addedItems
            const quantity = selectedOrder.addedItems[id]?.quantity ?? 0;

            // Add the non-returnable item to the list multiple times, based on quantity
            for (let i = 0; i < quantity; i++) {
              nonReturnableItems.push(item);
            }
          }
        }
      });
    }

    // Return the list of non-returnable items
    return nonReturnableItems;
  };

  // Invoke the function to get non-returnable items
  const nonReturnableItems = allNonReturnableItemPresent();

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="mt-2 d-flex align-items-center">
          <img
            src={returnImg}
            alt="Return/Exchange Policy"
          />
          <span className={`ps-1 ${styles.heading}`}>
            Return/Exchange Policy For Your Item(s)
          </span>
        </div>
        <div className="text-center">
          {nonReturnableItems.map((item, index) => {
            const itemData = selectedOrder?.itemsInOrder?.find(
              (value) => value.id.toString() === item.itemId.toString(),
            );

            return (
              <div key={index}>
                <div className="d-flex mt-3 mb-2">
                  <img
                    src={info}
                    alt="Caution"
                    className={`${styles.caution} me-2`}
                  />
                  <div className={`${styles.subHeading}`}>
                    We're sorry, this item is not eligible for return as per our
                    policies.
                  </div>
                </div>

                <div className={`px-3 py-2 mt-2 ${styles.cardBox}`}>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        itemData?.name === "Acme T-Shirt" ? orderImg : orderImg2
                      }
                      className={`${styles.orderImg}`}
                      alt="Item Image"
                    />
                    <div className="ms-1">
                      <span className={`d-block ${styles.orderName}`}>
                        {itemData?.name || "Unknown Item"}
                      </span>
                      <span className={`d-block ${styles.orderData}`}>
                        {`${itemData?.unitPrice?.currencyCode || "$"} ${
                          itemData?.unitPrice?.amount || "0"
                        } x 1`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <span className={`mt-4 d-block ${styles.celebrateImg}`}>
            <Lottie animationData={policyImg} />
          </span>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default NotReturnableItems;
