import { useCallback, useMemo } from "react";
import { useAddCampaignState } from "../../../../../../../../Hooks/useAddCampaignContext";
import styles from "../../CampaignType.module.scss";
import CategorySelect, {
  CategorySelectOption,
} from "../CategorySelect/CategorySelect";
import useFetchCampaignSubCategories from "../../../../../../../../Hooks/useFetchCampaignSubCategories";
import { CampaignSubCategory } from "src/services/Campaign/getAllCampaignCategories.service";

const SubCategorySelectDD = ({
  fetchedCampaignSubCategories,
  hasMore,
}: {
  fetchedCampaignSubCategories?: CampaignSubCategory[];
  hasMore?: boolean;
}) => {
  const { state, dispatch } = useAddCampaignState();
  const { allCampaignSubCategories, fetchMore, fetchStatus } =
    useFetchCampaignSubCategories({
      categoryId: state.categoryId,
      initialStart: fetchedCampaignSubCategories?.length,
      limit: 5,
      isDisabled: !hasMore,
    });

  const allOptions = useMemo(() => {
    return [
      ...(fetchedCampaignSubCategories ?? []),
      ...allCampaignSubCategories,
    ].map((val) => {
      return {
        label: val.name,
        value: val.id,
        isDescriptionRequired: val.isDescriptionRequired,
      } as CategorySelectOption;
    });
  }, [allCampaignSubCategories, fetchedCampaignSubCategories]);

  const selectedOption = useMemo(() => {
    return allOptions.find((val) => val.value === state.subCategoryId);
  }, [allOptions, state.subCategoryId]);

  const onChange = useCallback(
    (categoryOption: CategorySelectOption) => {
      if (categoryOption) {
        dispatch("updateState", {
          subCategoryId: categoryOption.value,
          isSubCategoryDesctiptionRequired:
            categoryOption.isDescriptionRequired,
        });
      }
    },
    [dispatch],
  );

  const handleChangeCategoryName = useCallback(
    (subCategoryDescription: string) => {
      dispatch("updateState", { subCategoryDescription });
    },
    [dispatch],
  );

  return (
    <div className="w-50 pe-4">
      <label
        htmlFor="campaignSubCategory"
        className={`form-label ${styles.label}`}
      >
        Sub-category
      </label>

      <CategorySelect
        options={allOptions}
        value={selectedOption}
        inputId="campaignSubCategory"
        placeholder="Select Sub-category"
        onMenuScrollToBottom={fetchMore}
        onChange={(val) => {
          onChange(val as CategorySelectOption);
        }}
        isLoading={fetchStatus === "pending"}
        isMulti={false}
        isSearchable={false}
        isClearable={false}
        showError={state.showErrors && !selectedOption}
      />
      {selectedOption?.isDescriptionRequired && (
        <input
          type="text"
          className={`w-100 mt-1 ps-2 py-1 ${
            state.showErrors &&
            !state?.subCategoryDescription?.trim()?.length &&
            styles.borderRed
          } ${styles.inputText}`}
          value={state.subCategoryDescription}
          placeholder="name Sub-category"
          onChange={(e) => handleChangeCategoryName(e.target.value)}
        />
      )}
    </div>
  );
};

export default SubCategorySelectDD;
