import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./TemplateModal.module.scss";
import ActionButtons from "../../../ActionButtons/ActionButtons";
import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import AronParker from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/AronParker";
import Asana from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Asana";
import BigCommerce from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/BigCommerce";
import BusinessNetwork from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/BusinessNetwork";
import Calendly from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Calendly";
import Circleci from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Circleci";
import Heroku from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Heroku";
import Webinar from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Webinar";
import Zapier from "src/routes/Campaigns/Children/CampaignWrapper/Children/SelectTemplateModal/StaticTemplates/Zapier";
import aronParkerTemplate from "src/assets/images/aronParkerTemplate.png";
import asanaTemplate from "src/assets/images/asanaTemplate.png";
import bigCommerceTemplate from "src/assets/images/bigCommerceTemplate.png";
import businessNetworkTemplate from "src/assets/images/businessNetworkTemplate.png";
import calendlyTemplate from "src/assets/images/calendlyTemplate.png";
import circleciTemplate from "src/assets/images/circleciTemplate.png";
import herokuTemplate from "src/assets/images/herokuEmailTemplate.png";
import note from "src/assets/images/tempNote.png";
import webinarTemplate from "src/assets/images/webinarTemplate.png";
import zapierTemplate from "src/assets/images/zapierTemplate.png";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import EditVariant from "./Children/EditVariant/EditVariant";
import EmailPreview from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EmailPreview/EmailPreview";
import { Modal } from "react-bootstrap";
import ErrorModal from "../ErrorModal/ErrorModal";
import DesktopMobilePreview from "../../../DesktopMobilePreview/DesktopMobilePreview";
import { SetState } from "immer/dist/internal";
import VariantSidebar from "./Children/VariantSidebar/VariantSidebar";
import { useSendMessage } from "../../../../Hooks/useSendMessageContext";
interface Category {
  id: string;
  name: string;
  templates: Array<{
    id: string;
    image: string;
    component: () => JSX.Element;
  }>;
}

const categories: Category[] = [
  {
    id: "1",
    name: "Welcome",
    templates: [
      { id: "1", image: herokuTemplate, component: Heroku },
      { id: "2", image: bigCommerceTemplate, component: BigCommerce },
      { id: "3", image: asanaTemplate, component: Asana },
    ],
  },
  {
    id: "2",
    name: "Nurture",
    templates: [
      { id: "1", image: circleciTemplate, component: Circleci },
      { id: "2", image: zapierTemplate, component: Zapier },
      { id: "3", image: calendlyTemplate, component: Calendly },
    ],
  },
  {
    id: "3",
    name: "Events",
    templates: [
      { id: "1", image: aronParkerTemplate, component: AronParker },
      { id: "2", image: webinarTemplate, component: Webinar },
      { id: "3", image: businessNetworkTemplate, component: BusinessNetwork },
    ],
  },
];

const TemplateDD = ({
  category,
  initialShow = false,
  onClickImage,
}: {
  category: Category;
  initialShow?: boolean;
  onClickImage: (id: string) => void;
}) => {
  const [showMenu, setShowMenu] = useState(initialShow);

  return (
    <div className="mb-2 ms-2">
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={() => setShowMenu((prev) => !prev)}
      >
        <span className={`w-100 text-truncate ${styles.categoryName}`}>
          {category.name}:
        </span>
        <span className={`ps-2 ${styles.arrowDown}`}>
          <i className={`fa-solid fa-chevron-${showMenu ? "up" : "down"}`}></i>
        </span>
      </div>
      {showMenu && (
        <div className={`d-flex flex-column justify-content-center gap-2`}>
          {category.templates.map(({ id, image }) => {
            return (
              <div
                className={`${styles.variantWrapper}`}
                key={id}
                onClick={() => onClickImage(id)}
              >
                <img
                  src={image}
                  alt=""
                  className={`${styles.templateImg} w-100 cursor-pointer mb-2`}
                />
                <button className={`${styles.variantBtn}`}>
                  View variants
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const TemplateModal = ({
  onHide,
  showErrorModal,
}: {
  onHide: () => void;
  showErrorModal: () => void;
}) => {
  const { state, dispatch } = useSendMessage();
  const [showVariantSidebar, setShowVariantSidebar] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(state.selectedTemplate.categoryId + "");
  const [searchInput, setSearchInput] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | number>(
    state.selectedTemplate.templateId + "",
  );

  const templateString = useMemo(() => {
    return categories
      .find((val) => val.id === selectedCategoryId)
      ?.templates.find((val) => val.id === selectedTemplateId)?.component;
    // const template = allTemplates[selectedTemplateId];
    // if (!template) {
    //   return;
    // }

    // return replaceKeyWithSpanB(template.template, variables);
  }, [selectedCategoryId, selectedTemplateId, state.selectedTemplate]);

  const selectedTemplateImg = useMemo(() => {
    return categories
      .find((val) => val.id === selectedCategoryId)
      ?.templates.find((val) => val.id === selectedTemplateId)?.image;
    // const template = allTemplates[selectedTemplateId];
    // if (!template) {
    //   return;
    // }

    // return replaceKeyWithSpanB(template.template, variables);
  }, [selectedCategoryId, selectedTemplateId]);

  const searchTemplate = useMemo(() => {
    return categories
      .map((category) => ({
        ...category,
        templates: category.templates.filter((template) =>
          template.image.toLowerCase().includes(searchInput.trim().toLowerCase()),
        ),
      }))
      .filter((category) => category.templates.length > 0);
  }, [searchInput]);

  return (
    <div>
      <div className="w-100 d-flex px-4 mb-4">
        <div
          onClick={onHide}
          role="button"
        >
          <span className={`pe-3 me-1 d-block ${styles.leftArrow}`}>
            <i className="fa-solid fa-angle-left"></i>
          </span>
        </div>
        <h3 className={`d-flex mb-0 align-items-center ${styles.heading}`}>
          <span className="pe-3 d-block">Choose an email template</span>
        </h3>
      </div>
      <div className={`${styles.templatewrapper} pt-1 px-4`}>
        {showVariantSidebar ? (
          <VariantSidebar
            onHide={() => setShowVariantSidebar(false)}
            selectedTemplateImg={selectedTemplateImg ?? ""}
          />
        ) : (
          <div className={`${styles.leftSection}`}>
            <p className={`mb-0 mt-1 ${styles.templateHead}`}>Templates</p>
            <SearchBar
              className={`w-100 ${styles.search} px-1 mt-1`}
              inputClassName={` ${styles.input}`}
              placeholder={`search`}
              value={searchInput}
              onChange={(e: any) => {
                setSearchInput(e.target.value);
              }}
              // onSearch={(value: string) => setSearchText(value)}
              id="templateSearch"
            />
            <div className={`mt-3 ${styles.devider}`}></div>
            {searchInput.trim().length ? (
              <div className={styles.scrollContainer}>
                {searchTemplate.map((category) =>
                  category.templates.map((template) => (
                    <div
                      className={`${styles.variantWrapper}`}
                      key={template.id}
                      onClick={() => {
                        setSelectedTemplateId(template.id);
                        setSelectedCategoryId(category.id);
                        setShowVariantSidebar(true);
                      }}
                    >
                      <img
                        src={template.image}
                        alt={`template image ${template.id}`}
                        className={`${styles.templateImg} w-100 cursor-pointer mb-2`}
                      />
                      <button
                        className={`${styles.variantBtn}`}
                      >
                        View variants
                      </button>
                    </div>
                  )),
                )}
              </div>
            ) : (
              <div className="mt-3">
                <InfiniteScroll
                  style={{ maxHeight: "490px", overflowX: "hidden" }}
                  // loadMore={fetchMore}
                  // hasMore={hasMore}
                >
                  {categories.map((category, idx) => {
                    return (
                      <TemplateDD
                        key={category.id}
                        category={category}
                        initialShow={
                          category.id == state.selectedTemplate.categoryId
                        }
                        onClickImage={(id) => {
                          setSelectedTemplateId(id);
                          setSelectedCategoryId(category.id);
                          setShowVariantSidebar(true);
                          dispatch("updateState", {
                            selectedTemplate: {
                              templateId: id,
                              categoryId: category.id,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </div>
        )}
        <div className={`${styles.rightSection}`}>
          <div className={styles.previewTag}>Preview</div>
          <DesktopMobilePreview>
            {templateString ? templateString() : <div></div>}
          </DesktopMobilePreview>
        </div>
      </div>
      <div
        className={`d-flex justify-content-end align-items-center ${styles.actionBtn} px-4`}
      >
        <button
          className={`px-1 me-2 ${styles.cancelBtn}`}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className={`px-1 ${styles.submitBtn}`}
          onClick={showErrorModal}
        >
          Use template
        </button>
      </div>
    </div>
  );
};

export default TemplateModal;
