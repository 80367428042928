import styles from "./OrderInformation.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import cautionImg from "src/assets/images/caution.svg";
import TimeLine from "../Timeline/Timeline";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import { useState } from "react";
import ParseVariablesWithValue from "../ParseVariablesWithValue/ParseVariablesWithValue";
import useGetAutomationData, {
  IReturnReason,
} from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
const OrderInformation = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const {
    selectedOrder,
    orders,
    updateState,
    followupModalData,
    ...contextValues
  } = useReturnPreviewState();
  const {
    arsResponse,
    getAllReasons,
    isReturnReasonsAvailable,
    explainAnswer,
  } = useGetAutomationData();
  const allReasons = getAllReasons();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  const handleNext = () => {
    if (selectedOrder) {
      const addedItemsLength = Object.keys(
        selectedOrder?.addedItems ?? {},
      ).length;
      const selectInformationLength = Object.values(
        selectedOrder?.addedItems ?? {},
      ).filter((data) => data.dontReturnOrExchangeInfo != undefined).length;

      if (
        selectInformationLength > 0 &&
        addedItemsLength === selectInformationLength
      ) {
        // Check if at least one item has dontReturnOrExchangeInfo set to true
        const hasDontReturnOrExchange = Object.values(
          selectedOrder.addedItems ?? {},
        ).some((item) => item.dontReturnOrExchangeInfo === true);
        if (hasDontReturnOrExchange) {
          updateState({
            ...contextValues,
            selectedOrder: {
              ...selectedOrder,
              activeStep: "antiRefundStagePreview",
            },
          });
        } else {
          // If the next offer exists, set that active page
          let nextPage: StepName = "configureNextSteps";
          let prevStep: StepName = "arsInformation";
          if (arsResponse?.isOffersAvailable) {
            nextPage = "arsOffers";
          } else if (arsResponse?.isExchangeAvailable) {
            if (
              arsResponse?.returnTypes &&
              arsResponse?.returnTypes?.length > 0
            ) {
              nextPage = "arsReturnTypes";
            } else {
              nextPage = "arsExchange";
            }
          }
          updateState({
            ...contextValues,
            selectedOrder: {
              ...selectedOrder,
              activeStep: nextPage,
              prevStepForConfig: prevStep,
            },
          });
        }
      } else {
        setShowError(true);
        return;
      }
    }
  };

  const handleBack = () => {
    let backPage: StepName = "addReturnItems";
    // Check if any item's selected reason has moreDetails length > 0
    const hasMoreDetails = Object.values(selectedOrder?.addedItems ?? {}).some(
      (data) => {
        if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
          "returnReasonOrderAndItems"
        ) {
          const reason = (allReasons as IReturnReason[])?.find(
            (reason) => reason.id === data.addedReasonId,
          );
          return (
            reason &&
            reason.moreQuestions &&
            reason.moreQuestions.length > 0 &&
            explainAnswer === "requiredAndCustom"
          );
        } else if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
            "followUpQuestion" &&
          data.addedReasonId
        ) {
          const reason = followupModalData.reasons[data.addedReasonId];
          return (
            (reason &&
              reason.reason.moreQuestions &&
              reason.reason.moreQuestions.length > 0) ||
            followupModalData.moreQuestions.length > 0
          );
        }
        return false;
      },
    );

    if (hasMoreDetails) {
      backPage = "moreDetails";
    } else if (isReturnReasonsAvailable) {
      backPage = "reason";
    } else {
      backPage = "returnType";
    }
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: backPage,
        },
      });
  };

  const handleOnChange = (
    dontReturnOrExchangeInfo: boolean,
    itemId?: string,
  ) => {
    if (itemId && selectedOrder) {
      setShowError(false);
      let updatedItems = selectedOrder?.addedItems ?? {};
      if (Object.keys(updatedItems).length > 0) {
        if (updatedItems[itemId]) {
          updatedItems[itemId].dontReturnOrExchangeInfo =
            dontReturnOrExchangeInfo;
        }
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="d-flex align-items-center mb-3 pb-1">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>Information</span>
        </div>
        {showError && (
          <span className={`p-1 mb-1 ${styles.errorMsg}`}>
            * Please add required details for this item
          </span>
        )}
        {arsResponse?.information?.map((info, index) => {
          const itemData = selectedOrder?.itemsInOrder?.find(
            (value) => value.id + "" === info.itemId + "",
          );
          const item = (selectedOrder?.addedItems ?? {})[info.itemId];

          return (
            <div>
              <div className={`mb-2 ${styles.orderBox}`}>
                <p className={`mb-0 ${styles.orderHeading}`}>
                  {itemData?.name ?? ""}
                </p>

                <div className={`d-flex mb-3`}>
                  <div className={`${styles.cart}`}>
                    <img
                      src={
                        itemData?.name == "Acme T-Shirt" ? orderImg : orderImg2
                      }
                      alt="order"
                      className={`${styles.orderImg}`}
                    />
                  </div>
                  <div className={`ms-2`}>
                    <span className={`${styles.orderName}`}>
                      {itemData?.unitPrice?.currencyCode}
                      {itemData?.unitPrice?.amount} x {item?.quantity}
                    </span>
                    <span className={`${styles.orderDesc}`}>
                      SKU: {itemData?.sku}
                    </span>
                  </div>
                </div>

                <div
                  className={`d-flex align-items-start mb-3 ${styles.cautionBox}`}
                >
                  <img
                    src={cautionImg}
                    alt="caution"
                    width={13}
                    height={13}
                    className="mt-1"
                  />
                  <p className={`ps-1 mb-0`}>
                    <ParseVariablesWithValue
                      message={info.description.trim()}
                    />
                  </p>
                </div>
                <div className="form-check">
                  <input
                    className={`form-check-input ${styles.radioCheck} ${
                      showError && "border-danger"
                    }`}
                    type="radio"
                    name={`info--${itemData?.id}`}
                    id={`info--${itemData?.id}--${index}--continue`}
                    checked={item?.dontReturnOrExchangeInfo === false}
                    onClick={() => {
                      handleOnChange(false, itemData?.id);
                    }}
                  />
                  <label
                    className={`form-check-label ${styles.radioLabel}`}
                    htmlFor={`info--${itemData?.id}--${index}--continue`}
                  >
                    Continue to return this item
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className={`form-check-input ${styles.radioCheck} ${
                      showError && "border-danger"
                    }`}
                    type="radio"
                    name={`info--${itemData?.id}`}
                    id={`info--${itemData?.id}--${index}--cancel`}
                    checked={item?.dontReturnOrExchangeInfo === true}
                    onClick={() => {
                      handleOnChange(true, itemData?.id);
                    }}
                  />
                  <label
                    className={`form-check-label ${styles.radioLabel}`}
                    htmlFor={`info--${itemData?.id}--${index}--cancel`}
                  >
                    I have changed my mind
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default OrderInformation;
