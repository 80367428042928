import {
  ErrorTypes,
  UploadedCSVErrors,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { axiosFormData } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface UploadCsvParams {
  file?: File; //.txt or .csv
  abortControllerSignal?: any;
  // For pagination
  attachmentId?: string;
  start?: number;
  limit?: number;
}

/**
 * Represents a table heading in the errors table.
 */
export interface TableHeading {
  /**
   * The unique identifier for the table heading.
   * This ID will be used in rows to render corresponding data.
   */
  tableHeadingId: string;
  /** The display name of the table heading. */
  tableHeadingName: string;
}

export interface ErrorDescription {
  description: string;
  info: string;
}
/**
 * The data for the corresponding row in the errors table.
 * - `type` can be `null`, `"string"`, `"description"` - for the field which have description.
 * - `value` can be `null`, a `string`, or an `ErrorDescription`.
 */
export interface ErrorRow {
  tableHeadingId: string;
  type: null | "string" | "description";
  value: null | string | ErrorDescription;
}
/**
 * Represents an individual error row.
 */
export interface IError {
  /** The unique identifier for the order. */
  errorId: string;
  rowData: ErrorRow[];
}

export interface UploadCsvResponse {
  attachmentId: string;
  /**
   * Reperesents Heading for the errors
   * Example - "Column Mismatch", "Your impot has 6 errors"
   */
  heading?: null | string;
  /**
   * Subtitle or additional text for the error summary.
   * Example - "The columns in the uploaded CSV file do not match..."
   * Will not present incase of errors table
   */
  subText?: string;
  /**
   * The array of table headings to be displayed in the errors table.
   */
  tableHeadings?: TableHeading[];
  /**
   * The list of all errors based on the uploaded csv.
   */
  errors?: IError[];
  /** This is used to check if the next button is clickable. */
  errorType?: ErrorTypes;
  /**
   * A link to download the CSV file with errors.
   */
  downloadErrorCSVLink?: string;
  /**
   * The total number of errors found in the CSV file.
   */
  totalErrorCount?: number;
}
export const UPLOAD_CSV_ERRORS_LIMIT = 7;

async function uploadCsv({
  params,
  activeSegmentType,
}: {
  params: UploadCsvParams;
  activeSegmentType: string;
}): Promise<UploadedCSVErrors> {
  const fData = new FormData();
  if (params.file) {
    fData.append("file", params.file);
  }
  if (params.attachmentId) {
    fData.append("attachmentId", params.attachmentId);
  }
  fData.append("start", params.start?.toString() ?? "0");
  fData.append(
    "limit",
    params.limit?.toString() ?? UPLOAD_CSV_ERRORS_LIMIT.toString(),
  );

  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/csv/upload`
    : `/api/segment/${activeSegmentType}/csv/upload`;

  const { data: res } = await axiosFormData.post(apiEndPoint, fData, {
    signal: params.abortControllerSignal,
  });

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
  const normalizedData = normalizeUploadErrors(res.data);
  return normalizedData as UploadedCSVErrors;
}
export default uploadCsv;

export const normalizeUploadErrors = (response: UploadCsvResponse) => {
  if (response) {
    const ret: UploadedCSVErrors = {
      attachmentId: response.attachmentId,
      downloadErrorCSVLink: response.downloadErrorCSVLink,
      heading: response.heading,
      subText: response.subText,
      tableHeadings: {},
      tableHeadingIds: [],
      errors: {},
      errorIds: [],
      errorType: response.errorType,
      totalErrorCount: response.totalErrorCount,
    };

    // Normalize table headings
    response.tableHeadings?.forEach((tableHeading) => {
      if (!ret.tableHeadings) {
        ret.tableHeadings = {};
      }
      ret.tableHeadings[tableHeading.tableHeadingId] = tableHeading;
      ret.tableHeadingIds.push(tableHeading.tableHeadingId);
    });

    // Normalize errors
    response.errors?.forEach((error) => {
      const errorRowData: Record<string, ErrorRow> = {};

      error.rowData.forEach((row) => {
        errorRowData[row.tableHeadingId] = row;
      });
      if (!ret.errors) {
        ret.errors = {};
      }
      if (!ret.errorIds) {
        ret.errorIds = [];
      }
      ret.errors[error.errorId] = {
        errorId: error.errorId,
        rowData: errorRowData,
      };
      ret.errorIds.push(error.errorId);
    });

    return ret as UploadedCSVErrors;
  }
  return {
    attachmentId: "",
    downloadErrorCSVLink: "",
    heading: null,
    subText: "",
    tableHeadings: {},
    tableHeadingIds: [],
    errors: {},
    errorIds: [],
    errorType: null,
    totalErrorCount: 0,
  } as UploadedCSVErrors;
};
