import { useReturnPreviewState } from "../../../../hooks/useReturnPreview";
import styles from "./SampleOrder.module.scss";
import settingIcon from "src/assets/images/botSetting.png";
const SampleOrder = ({
  setShowSelectedOrder,
}: {
  setShowSelectedOrder: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedOrder, orders, ...contextValues } = useReturnPreviewState();

  const handleSelectOrder = (orderId: string) => {
    const selectedOrder = orders.find((order) => order.orderId == orderId);
    if (selectedOrder) {
      setShowSelectedOrder(true);
      contextValues.updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "selectAction",
        },
      });
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-4">
        <img
          src={settingIcon}
          alt=""
          width={20}
          height={20}
        />
        <span className={`ps-2 mb-0 ${styles.heading}`}>Sample orders</span>
      </div>
      {orders.map((order, index) => {
        const isSelected = selectedOrder?.orderId === order.orderId;

        return (
          <div
            className={`${styles.orderBox} mb-1 cursor-pointer`}
            onClick={() => {
              handleSelectOrder(order.orderId);
            }}
          >
            {isSelected && <div className={styles.pinkBar}></div>}
            <p className={`${styles.orderTitle}`}>
              Sample order {index + 1}{" "}
              {index > 0 && (
                <span className="ps-4">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              )}
            </p>

            <div className="d-flex flex-wrap mb-2">
              <button
                className={`d-flex align-items-center mb-1 me-1 ${styles.paidBtn}`}
              >
                <div className={`me-1 ${styles.dot}`}></div> Paid.1h ago
              </button>
              <button
                className={`d-flex align-items-center mb-1 me-1 ${styles.paidBtn}`}
              >
                <div className={`me-1 ${styles.dot}`}></div>Fulfilled2h ago
              </button>
              <button
                className={`d-flex align-items-center me-1 ${styles.fullFilledBtn}`}
              >
                <div className={`me-1 ${styles.dot2}`}></div> Delivered.30m ago
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SampleOrder;
