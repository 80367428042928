import { ISegmentUserData } from "src/services/CustomerSegments/getSegmentUserById.service";
import styles from "./ProfileDetail.module.scss";

const ProfileDetail = ({
  segmentData,
  onHide,
}: {
  segmentData: ISegmentUserData["segmentData"];
  onHide: () => void;
}) => {
  if (!segmentData) {
    return <></>;
  }

  return (
    <div className="px-4 py-3">
      <div className={`d-flex justify-content-between align-items-center`}>
        <h6 className={`mb-0 ${styles.modalHead}`}>All details</h6>
        <span
          className={`cursor-pointer ${styles.closebtn}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className={`${styles.dataWrapper}`}>
        {segmentData.map(({ key, name, value }) => {
          return (
            <div
              className={`d-flex justify-content-between`}
              key={key}
            >
              <div className={`${styles.leftSection}`}>
                <span>{name}</span>
              </div>
              <div className={`${styles.rightSection} `}>
                <span className={`${!value ? styles.noData : ""}`}>
                  {value ? value : "No Data"}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileDetail;
