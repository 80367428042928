import { FaChevronLeft } from "react-icons/fa";
import styles from "./VariantDetails.module.scss";
import useFetchAllTemplateVariants from "src/routes/Campaigns/Children/hooks/useFetchAllTemplateVariants";
import { useMemo } from "react";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";

interface Variant {
  id: string | number;
  name: string;
}

interface VariantDetailsProps {
  templateName: string;
  categoryId: string | number;
  templateId: string | number;
  iconUrl: string | null;
  variants: Variant[];
  onBack: () => void;
  onTemplateChange: (variantId: string | number) => void;
}

const VariantDetails: React.FC<VariantDetailsProps> = ({
  templateName,
  iconUrl,
  categoryId,
  templateId,
  variants,
  onBack,
  onTemplateChange,
}) => {
  const { allTemplateVariants, fetchMore, fetchStatus, hasMore } =
    useFetchAllTemplateVariants({
      categoryId,
      templateId,
      initialStart: variants.length,
      isDisabled: variants.length < 10,
      limit: 10,
    });

  const allVariants = useMemo(() => {
    return [...variants, ...allTemplateVariants];
  }, [allTemplateVariants, variants]);

  return (
    <div className={styles.variantDetailsContainer}>
      <div className={`${styles.contentWrapper}`}>
        <div className={styles.header}>
          <button
            onClick={onBack}
            className={`px-0 ${styles.backButton}`}
          >
            <FaChevronLeft /> Back
          </button>
          <h3 className={`mb-0 ${styles.h3}`}>{templateName} &gt; Variants</h3>
        </div>
        <div className={styles.content}>
          <div className={styles.templatePreview}>
            <img
              src={iconUrl ?? ""}
              alt={templateName}
              className={styles.templateImage}
            />
          </div>
        </div>
      </div>

      <div className={`mt-2 ${styles.border}`}>
        <div className={`${styles.variantsTitle}`}>Variants:</div>
        <InfiniteScroll
          loadMoreFromBottom={fetchMore}
          hasMoreBottom={hasMore}
          infiniteLoader={
            <LoadMoreLoader
              fetchMore={fetchMore}
              variant="blue"
              status={
                fetchStatus === "fulfilled"
                  ? "fulfilled"
                  : fetchStatus === "pending"
                    ? "pending"
                    : null
              }
            />
          }
          className={styles.variantsList}
        >
          {allVariants.map((variant) => (
            <button
              key={variant.id}
              className={styles.variantButton}
              onClick={() => onTemplateChange(variant.id)}
            >
              {variant.name}
            </button>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default VariantDetails;
