import React, { useCallback, useState } from "react";
import styles from "./TicketRouteBox.module.scss";
import deleteIcon from "src/assets/images/delete.png";
import { Spinner } from "react-bootstrap";
import { BrandData } from "src/services/Automation/getAllAutomations.service";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { useParams } from "react-router-dom";
import { IAutomationListParams } from "../../AutomationList";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface TicketRouteBoxProps {
  title: string;
  description: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onRuleToggle: (integrationId: string, enabled: boolean) => void;
  trigger: string;
  integrationId: string;
  brand: BrandData | null;
  published: boolean;
  configured: boolean;
}

const TicketRouteBox: React.FC<TicketRouteBoxProps> = ({
  title,
  description,
  onEditClick,
  onDeleteClick,
  onRuleToggle,
  trigger,
  integrationId,
  brand,
  published,
  configured,
}) => {
  // const { toggleAutomation, isLoading: isRuleUpdating } =
  //   useToggleAutomation(onRuleToggle);
  const [loading, setLoading] = useState(false);
  const { type } = useParams<IAutomationListParams>();
  const handleGoLiveAndUnpublish = useCallback(
    async (publish: boolean) => {
      if (!type) {
        console.log("Some Error Occured");
        return;
      }
      if (publish) {
        if (!configured) {
          pushTheToast({
            position: "top-right",
            text: "Please complete settings",
            type: "danger",
          });
          return;
        }
      }
      setLoading(true);
      const payload = {
        integrationId: integrationId,
        publish: publish,
      };
      try {
        await updateAutomationService({
          type: type,
          payload: payload,
        }).then(() => {
          onRuleToggle(integrationId, publish);
        });
      } catch (err) {
        pushTheToast({
          position: "top-right",
          type: "danger",
          text:
            typeof err === "string"
              ? err
              : payload.publish
                ? "Could not publish automation"
                : "Could not unpublish automation",
        });
      } finally {
        setLoading(false);
      }
    },
    [type],
  );

  // const handleRuleToggle = useCallback(
  //   (enabled: boolean) => {
  //     if (integrationId != "-1") toggleAutomation(integrationId, enabled);
  //   },
  //   [toggleAutomation, integrationId],
  // );

  function truncateText(text: string, maxLength: number): string {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }

  return (
    <div className={`${styles.contentWrapper} mb-3`}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3">
        <div className="d-flex align-items-center">
          <p className="mb-0 d-flex me-2">
            <span>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
            <span>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </p>
          <h3 className={`mb-0 text-truncate ${styles.heading}`}>
            {title ? truncateText(title, 50) : ""}
          </h3>
          {trigger && (
            <div className={`px-2 ms-3 ${styles.actionText}`}>{trigger}</div>
          )}
          {published ? (
            <span className={`ms-2 ${styles.publishText}`}>Published</span>
          ) : (
            <div className="ps-2 pe-2">
              <span className={`${styles.draft}`}>Draft</span>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              className={`mt-2 mt-md-0 ${styles.editBtn}`}
              onClick={onEditClick}
            >
              <span className="pe-1">
                <i className="fa-solid fa-pen-to-square"></i>
              </span>
              Edit
            </button>

            <span
              onClick={onDeleteClick}
              style={{ cursor: "pointer" }}
              className="pe-4"
            >
              <img
                src={deleteIcon}
                alt="deleteIcon"
                height={18}
              />
            </span>
          </div>
        </div>
      </div>
      <p className={`mb-0 w-75 mb-3 text-truncate ${styles.subHeading}`}>
        {description}
      </p>
      <div className="mb-2">
        <span className={styles.brandText}>Brand Installed on:</span>
        <span className={`ps-1 ${styles.brandText}`}>
          {brand ? (
            <>
              {/* rendering brand image here, if exist, else rendering brand name image */}
              {brand.imageURL ? (
                <AxiosImg
                  className={`${styles.brandImage} rounded-circle`}
                  url={brand.imageURL}
                />
              ) : (
                <UserAvatar
                  name={brand.name ?? "NA"}
                  size={20}
                />
              )}
              <span className="ms-2">{brand.name ?? "NA"}</span>
            </>
          ) : (
            "---"
          )}
        </span>
      </div>
      <div className="d-flex align-items-center">
        <label
          htmlFor="flexSwitchCheckDefault"
          className={`pe-2 ${styles.switchLabel}`}
        >
          Rule Enable/Disable
        </label>
        <div className="form-check form-switch">
          <input
            className={`form-check-input ${styles.switchBox} cursor-pointer`}
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={published}
            onChange={(e) => {
              handleGoLiveAndUnpublish(e.target.checked);
            }}
            disabled={loading}
          />
          {loading && (
            <Spinner
              className="mx-2"
              size="sm"
              animation="border"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketRouteBox;
