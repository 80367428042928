import styles from "./MoreDetails.module.scss";
import refresh from "src/assets/images/chat/refreshButton.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import { useState } from "react";
import TimeLine from "../Timeline/Timeline";
import {
  ElementType,
  ElementTypes,
  PayloadFormDataValue,
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";

const MoreDetails = () => {
  const [showError, setShowError] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [itemId: string]: { [fieldId: string]: boolean };
  }>({});

  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();
  const {
    getAllReasons,
    getAllReturnTypes,
    arsResponse,
    getMoreDetailsByReasonId,
    isReturnMethodAvailable,
  } = useGetAutomationData();
  const reasons = getAllReasons();
  const { allReturnTypes } = getAllReturnTypes();

  const handleNext = () => {
    let allFieldsValid = true;
    const newValidationErrors: {
      [itemId: string]: { [fieldId: string]: boolean };
    } = {};

    // Get all item IDs from addedItems
    const items = Object.values(selectedOrder?.addedItems ?? {});

    // Validate each item
    items.forEach((item) => {
      const followUpAnswers = item.followUpAnswers ?? [];
      const requiredFields =
        getMoreDetailsByReasonId(item?.addedReasonId ?? "") ?? [];

      requiredFields.forEach((field) => {
        const answer = followUpAnswers.find((a) => a.key === field.elementId);

        // Check for empty answer or missing value for all types except multiple choice
        if (
          field.elementTypeId !== "multipleChoice" &&
          (!answer || !answer.value || answer.value === "")
        ) {
          allFieldsValid = false;
          newValidationErrors[item.itemId] = {
            ...newValidationErrors[item.itemId],
            [field.elementId]: true,
          };
        }

        // Special validation for multiple choice
        if (
          field.elementTypeId === "multipleChoice" &&
          (!answer || !Array.isArray(answer.value) || answer.value.length === 0)
        ) {
          allFieldsValid = false;
          newValidationErrors[item.itemId] = {
            ...newValidationErrors[item.itemId],
            [field.elementId]: true,
          };
        }
      });
    });

    setValidationErrors(newValidationErrors);
    if (allFieldsValid) {
      setShowError(false);
      // Proceed to the next step
      if (selectedOrder) {
        let nextPage: StepName = "configureNextSteps";
        let prevStep: StepName = "moreDetails";
        if (arsResponse?.isInformationAvailable) {
          nextPage = "arsInformation";
        } else if (arsResponse?.isOffersAvailable) {
          nextPage = "arsOffers";
        } else if (
          arsResponse?.returnTypes &&
          arsResponse?.returnTypes?.length > 0
        ) {
          nextPage = "arsReturnTypes";
        } else if (arsResponse?.isExchangeAvailable) {
          nextPage = "arsExchange";
        } else if (isReturnMethodAvailable) {
          nextPage = "returnMethod";
        }
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: nextPage,
            prevStepForConfig: prevStep,
          },
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "reason",
        },
      });
    }
  };

  const handleInputChange = (
    itemId: string,
    fieldId: string,
    value: string | string[] | boolean,
  ) => {
    if (!selectedOrder || !selectedOrder.addedItems) return;

    const updatedItems = { ...selectedOrder.addedItems };
    const item = updatedItems[itemId];
    if (!item) return;

    const followUpAnswers = item.followUpAnswers ?? [];
    updateAnswerValue(followUpAnswers, fieldId, value as string);

    // Update the item with the new followUpAnswers
    updatedItems[itemId] = {
      ...item,
      followUpAnswers: followUpAnswers,
    };

    // Update the state with the modified addedItems
    updateState({
      ...contextValues,
      selectedOrder: {
        ...selectedOrder,
        addedItems: updatedItems,
      },
    });

    // Validate and clear validation errors for the entire item if all fields are valid
    const allMoreDetails =
      getMoreDetailsByReasonId(item?.addedReasonId ?? "") ?? [];
    const isItemValid = validateItemFields(
      updatedItems[itemId],
      allMoreDetails,
    );
    if (isItemValid) {
      const newValidationErrors = { ...validationErrors };
      delete newValidationErrors[itemId];
      setValidationErrors(newValidationErrors);
    } else {
      setValidationErrors((prev) => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          [fieldId]: false,
        },
      }));
    }
  };

  // Function to handle the checking/unchecking of checkboxes
  const handleCheckUncheck = (
    itemId: string,
    fieldId: string,
    optionValue: string,
    checked: boolean,
  ) => {
    // Ensure that the selected order and added items are available
    if (!selectedOrder || !selectedOrder.addedItems) return;

    // Clone the addedItems object to avoid direct mutation
    const updatedItems = { ...selectedOrder.addedItems };

    // Retrieve the specific item using itemId
    const item = updatedItems[itemId];
    if (!item) return;

    // Retrieve or initialize followUpAnswers for the item
    const followUpAnswers = item.followUpAnswers ?? [];

    // Find the index of the answer corresponding to the fieldId
    const answerIndex = followUpAnswers.findIndex(
      (answer) => answer.key === fieldId,
    );

    if (answerIndex !== -1) {
      // If the answer already exists, update its value based on checkbox status
      const answer = followUpAnswers[answerIndex];

      if (Array.isArray(answer.value)) {
        // If the answer value is an array (multiple selections allowed)
        if (checked) {
          // Add the optionValue if checked and not already in the array
          if (!answer.value.includes(optionValue.toString())) {
            answer.value.push(optionValue);
          }
        } else {
          // Remove the optionValue if unchecked
          answer.value = answer.value.filter((val) => val !== optionValue);
        }
      } else {
        // Initialize the answer value as an array containing the optionValue if checked, or empty if unchecked
        answer.value = checked ? [optionValue] : [];
      }
    } else {
      // If the answer does not exist, create a new entry for it
      followUpAnswers.push({
        key: fieldId,
        value: checked ? [optionValue] : [],
      });
    }

    // Update the item with the new followUpAnswers
    updatedItems[itemId] = {
      ...item,
      followUpAnswers: followUpAnswers,
    };

    // Update the state with the modified addedItems
    updateState({
      ...contextValues,
      selectedOrder: {
        ...selectedOrder,
        addedItems: updatedItems,
      },
    });

    // Validate and clear validation errors for the entire item if all fields are valid
    const allMoreDetails =
      getMoreDetailsByReasonId(item?.addedReasonId ?? "") ?? [];
    const isItemValid = validateItemFields(
      updatedItems[itemId],
      allMoreDetails,
    );

    if (isItemValid) {
      // If the item is valid, remove any validation errors for the item
      const newValidationErrors = { ...validationErrors };
      delete newValidationErrors[itemId];
      setValidationErrors(newValidationErrors);
    } else {
      // Otherwise, mark the field as invalid
      setValidationErrors((prev) => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          [fieldId]: false,
        },
      }));
    }
  };

  // Helper function to update the value in followUpAnswers
  const updateAnswerValue = (
    followUpAnswers: PayloadFormDataValue[],
    fieldId: string,
    value: string | string[],
  ) => {
    const answerIndex = followUpAnswers.findIndex(
      (answer) => answer.key === fieldId,
    );
    if (answerIndex !== -1) {
      followUpAnswers[answerIndex].value = value;
    } else {
      followUpAnswers.push({ key: fieldId, value });
    }
  };

  // Helper function to validate all fields of an item
  const validateItemFields = (item: any, requiredFields: any[]) => {
    for (const field of requiredFields) {
      const answer = item?.followUpAnswers?.find(
        (a: PayloadFormDataValue) => a.key === field.elementId,
      );
      if (!answer || !answer.value || answer.value === "") {
        return false;
      }
    }
    return true;
  };

  const items = Object.values(selectedOrder?.addedItems ?? {});

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />

        <div className={`d-flex align-items-center`}>
          <div>
            <img
              src={refresh}
              alt="refresh"
              className={`${styles.refresh}`}
            />{" "}
          </div>
          <span className={`ms-1 ${styles.heading}`}>
            Initiate Return/Exchange
          </span>
        </div>

        <span className={`d-inline-block my-2 ${styles.detailSpan}`}>
          Give us more details:
        </span>
        <div>
          {items.map((item) => {
            const itemData = selectedOrder?.itemsInOrder?.find(
              (value) => value.id + "" === item.itemId + "",
            );
            const reason = reasons?.find(
              (reason) => reason.id === item?.addedReasonId,
            );
            const returnType = allReturnTypes?.find(
              (returnType) => returnType.type == item?.selectedReturnType,
            );
            const formValueObj: { [key: string]: PayloadFormDataValue } = {};
            item?.followUpAnswers?.forEach((value) => {
              formValueObj[value.key] = value;
            });
            const allMoreDetails =
              getMoreDetailsByReasonId(item?.addedReasonId ?? "") ?? [];
            return (
              <div>
                {allMoreDetails && allMoreDetails.length > 0 && (
                  <div
                    className="mt-2"
                    key={item.itemId}
                  >
                    <div>
                      {showError && validationErrors[item.itemId] && (
                        <div>
                          <span
                            className={`d-block px-1 my-2 py-1 ${styles.errormsg}`}
                          >
                            *Please add required details for this item
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={`${styles.cardBox}`}>
                      <div>
                        <span className={`pe-1 ${styles.orderName}`}>
                          {itemData?.name}
                        </span>
                        <span className={`d-inline-block ${styles.moreInfo}`}>
                          *{returnType?.name ?? "Exchange with same items"}
                        </span>
                      </div>
                      <div className="d-flex mb-3">
                        <img
                          src={
                            itemData?.name === "Acme T-Shirt"
                              ? orderImg
                              : orderImg2
                          }
                          className={`${styles.orderImg}`}
                        />
                        <div className="ms-1">
                          <span className={`d-block ${styles.orderData}`}>
                            {itemData?.unitPrice?.currencyCode}
                            {itemData?.unitPrice?.amount} x {item.quantity}
                          </span>

                          <span className={`d-block ${styles.orderData}`}>
                            SKU: {itemData?.sku}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className={`${styles.reason}`}> Reasons:</span>
                        <span className={`${styles.reasonIssue}`}>
                          {" "}
                          {reason?.value ?? ""}
                        </span>
                      </div>
                      <div>
                        <span className={`${styles.reason}`}>
                          More detailss:
                        </span>
                        {allMoreDetails?.map((field) => {
                          const {
                            elementId,
                            elementTypeId,
                            elementValue,
                            additionalOptions,
                          } = field;
                          const elementType = parseInt(
                            elementTypeId,
                          ) as ElementType;

                          switch (ElementTypes[elementType]) {
                            case "shortAnswer":
                              return (
                                <ShortAnswer
                                  key={`${item.itemId}--${elementId}--shortAnswer`}
                                  id={`${item.itemId}--${elementId}--shortAnswer`}
                                  elementValue={elementValue}
                                  value={
                                    (formValueObj[field.elementId]
                                      ?.value as string) ?? ""
                                  }
                                  options={additionalOptions}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.itemId,
                                      elementId,
                                      e.target.value,
                                    )
                                  }
                                  hasError={
                                    showError
                                      ? validationErrors[item.itemId]?.[
                                          elementId
                                        ] ?? false
                                      : false
                                  }
                                />
                              );
                            case "longAnswer":
                              return (
                                <LongAnswer
                                  key={`${item.itemId}--${elementId}--longAnswer`}
                                  id={`${item.itemId}--${elementId}--longAnswer`}
                                  elementValue={elementValue}
                                  value={
                                    (formValueObj[field.elementId]
                                      ?.value as string) ?? ""
                                  }
                                  options={additionalOptions}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.itemId,
                                      elementId,
                                      e.target.value,
                                    )
                                  }
                                  hasError={
                                    showError
                                      ? validationErrors[item.itemId]?.[
                                          elementId
                                        ] ?? false
                                      : false
                                  }
                                />
                              );
                            case "multipleChoice":
                              return (
                                <MultipleChoice
                                  key={`${item.itemId}--${elementId}--multiChoice`}
                                  id={`${item.itemId}--${elementId}--multiChoice`}
                                  elementValue={elementValue}
                                  options={additionalOptions}
                                  value={
                                    (formValueObj[field.elementId]
                                      ?.value as string[]) ?? []
                                  } // Ensure this is an array
                                  onChange={() => {}}
                                  handleCheckUncheck={(
                                    optionValue: string,
                                    checked: boolean,
                                  ) => {
                                    handleCheckUncheck(
                                      item.itemId,
                                      elementId,
                                      optionValue,
                                      checked,
                                    );
                                  }}
                                  hasError={
                                    showError
                                      ? validationErrors[item.itemId]?.[
                                          elementId
                                        ] ?? false
                                      : false
                                  }
                                />
                              );
                            case "dropdown":
                              return (
                                <Dropdown
                                  key={`${item.itemId}--${elementId}--dropdown`}
                                  id={`${item.itemId}--${elementId}--dropdown`}
                                  elementValue={elementValue}
                                  options={additionalOptions}
                                  value={
                                    (formValueObj[field.elementId]
                                      ?.value as string) ?? ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.itemId,
                                      elementId,
                                      e.target.value,
                                    )
                                  }
                                  hasError={
                                    showError
                                      ? validationErrors[item.itemId]?.[
                                          elementId
                                        ] ?? false
                                      : false
                                  }
                                />
                              );
                            default:
                              return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default MoreDetails;

interface Props {
  id: string;
  value: string | string[];
  options: { id: string; value: string }[];
  onChange: (e: any) => void;
  elementValue: string;
  hasError: boolean;
  handleCheckUncheck?: any;
}
const ShortAnswer = ({
  id,
  value,
  onChange,
  elementValue,
  hasError,
}: Props) => (
  <div className="mb-3">
    <label
      htmlFor={id}
      className={`form-label ${styles.textLabel}`}
    >
      {elementValue}
    </label>
    <input
      type="text"
      className={`form-control ${styles.inputBox} ${
        hasError ? "border-danger" : ""
      }`}
      id={id}
      value={value}
      onChange={onChange}
      placeholder="Type your answer here"
    />
  </div>
);

const LongAnswer = ({ id, value, onChange, elementValue, hasError }: Props) => (
  <div className="mb-3">
    <label
      htmlFor={id}
      className={`form-label ${styles.textLabel} ${
        hasError ? "border-danger" : ""
      }`}
    >
      {elementValue}
    </label>
    <textarea
      className={`form-control ${styles.desTextArea} ${
        hasError ? "border-danger" : ""
      }`}
      id={id}
      rows={3}
      value={value}
      onChange={onChange}
      placeholder="Type your detailed answer here"
    ></textarea>
  </div>
);
const MultipleChoice = ({
  id,
  options,
  value,
  elementValue,
  hasError,
  handleCheckUncheck,
}: Props) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: optionValue, checked } = e.target;
    handleCheckUncheck(optionValue, checked);
  };

  return (
    <div className="mb-3">
      <label className={`form-label ${styles.textLabel}`}>{elementValue}</label>
      {options.map((option, index) => (
        <div
          key={index}
          className="form-check"
        >
          <input
            className={`form-check-input ${styles.checkbox} ${
              hasError ? "border-danger" : ""
            }`}
            type="checkbox"
            id={`${id}-${index}`}
            value={option.id}
            checked={
              (value != null &&
                value !== undefined &&
                Array.isArray(value) &&
                value.includes(option?.id?.toString())) ??
              false
            }
            onChange={handleCheckboxChange}
          />
          <label
            className={`form-check-label ${styles.labelItem}`}
            htmlFor={`${id}-${index}`}
          >
            {option.value}
          </label>
        </div>
      ))}
    </div>
  );
};

const Dropdown = ({
  id,
  options,
  value,
  onChange,
  elementValue,
  hasError,
}: Props) => (
  <div className="mb-3">
    <label
      htmlFor={id}
      className={`form-label ${styles.textLabel}`}
    >
      {elementValue}
    </label>
    <select
      className={`form-select ${styles.inputBox} ${
        hasError ? "border-danger" : ""
      }`}
      id={id}
      value={value}
      onChange={onChange}
    >
      <option
        value=""
        disabled
      >
        Select an option
      </option>
      {options.map((option, index) => (
        <option
          key={index}
          value={option.id}
        >
          {option.value}
        </option>
      ))}
    </select>
  </div>
);
