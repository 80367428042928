import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export interface GetImportStatusParams {
  /**The unique identifier for the attachment */
  attachmentId: string;
  /**The type of import being performed, either "users" or "leads". */
  importType: "users" | "leads";
}

export interface GetImportStatusResponse {
  /**indicates status of the import */
  importStatus: "completed" | "pending" | "failed";
}
/**
 * Fetches the import status based on the provided attachment ID and import type.
 *
 * @param {GetImportStatusParams} params - The parameters to send with the request.
 * @returns {Promise<GetImportStatusResponse>} A promise that resolves to an object containing the import status
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 *
 */
async function getImportStatus({
  params,
  activeSegmentType,
}: {
  params: GetImportStatusParams;
  activeSegmentType: string;
}): Promise<GetImportStatusResponse> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/getAllImportedProducts`
    : `/api/segment/${activeSegmentType}/csv/import`;
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
  return res.data as GetImportStatusResponse;
}
export default getImportStatus;
