import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  updateTimeLineEvent,
  UpdateTimelineEventPayload,
} from "src/services/TimeLine/updateTimeLineEvent";

interface UseUpdateTimelineEvents {
  updateTimelineEvent: (payload: UpdateTimelineEventPayload) => Promise<void>;
  updateStatus: "error" | "idle" | "loading" | "success";
  error: Error | null;
}

export const useUpdateTimelineEvents = (): UseUpdateTimelineEvents => {
  const mutation = useMutation(async (payload: UpdateTimelineEventPayload) => {
    return await updateTimeLineEvent({ payload });
  });

  const updateTimelineEvent = useCallback(
    async (payload: UpdateTimelineEventPayload) => {
      const { eventType, eventData, deletedEventIds } = payload;
      const updatePayload = {
        ...payload,
        eventData,
        eventType,
        deletedEventIds,
      };

      await mutation.mutateAsync(updatePayload);
    },
    [mutation],
  );

  return {
    updateTimelineEvent,
    updateStatus: mutation.status,
    error: mutation.error as Error | null,
  };
};
