import { axiosJSON, clearCacheByUrl } from "src/globals/axiosEndPoints";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export default async function getTimeline(payload: {
  refrenceId: string | number;
  refrenceType: "customer" | "campaign";
  filter?: {
    year: number | string;
  }
}) {
  const { data } = await axiosJSON.post(`/api/timeline/get`, payload);
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  return data.data as TimelineData
}
