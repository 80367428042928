import templateIcon from "src/assets/images/tempNote.png";
import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import SendingInfo from "src/components/SendingInfo";
import EditEmail from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import {
  EmailSegmentMessage,
  SegmentMessageType,
} from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { generateSegmentMessage } from "../../../../Hooks/sendMessage.helpers";
import {
  Warning,
  useSendMessage,
} from "../../../../Hooks/useSendMessageContext";
import EmailPreviewText from "../../../EmailPreviewText/EmailPreviewText";
import UploadLogo from "../../../UploadLogo/UploadLogo";
import styles from "../../MessageTabs.module.scss";
import { Modal } from "react-bootstrap";
import TemplateModal from "../TemplateModal/TemplateModal";
import { replaceKeyWithVariableName } from "src/utils/utils";
import DesktopMobilePreview from "../../../DesktopMobilePreview/DesktopMobilePreview";
import DesktopView from "../../../DesktopView/DesktopView";

const initialMessage = generateSegmentMessage(
  SegmentMessageType.Email,
) as EmailSegmentMessage;

const EmailMessageTab = ({
  showErrorModal,
}: {
  showErrorModal: () => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  const { state, dispatch } = useSendMessage();
  const selectedMessage: EmailSegmentMessage | undefined = useMemo(() => {
    const message = state.messages.find(
      (val) => val.type === SegmentMessageType.Email,
    );
    return message ? (message as EmailSegmentMessage) : undefined;
  }, [state.messages]);

  const cCValue = useMemo(
    () => selectedMessage?.cc?.join(",") ?? "",
    [selectedMessage?.cc],
  );

  const bCCValue = useMemo(
    () => selectedMessage?.bcc?.join(",") ?? "",
    [selectedMessage?.bcc],
  );

  const updateEmailMessage = useCallback(
    (data: Partial<EmailSegmentMessage>, logoUrl?: string) => {
      dispatch("updateMessage", {
        data: { type: SegmentMessageType.Email, ...data },
        logoUrl,
        showErrors: false,
      });
    },
    [dispatch],
  );
  const body = useMemo(
    () =>
      replaceKeyWithVariableName(
        (selectedMessage ?? initialMessage).message,
        state.variables,
      ),
    [(selectedMessage ?? initialMessage).message, state.variables],
  );

  const setShowFileWarning = useCallback(
    (showFileWarning?: Warning) => {
      dispatch("updateState", {
        showFileWarning: showFileWarning,
      });
    },
    [dispatch],
  );

  return (
    <div className={`${styles.viaBox}`}>
      <h5 className={`${styles.viaHeading}`}>
        Via email: <span>This email will be sent as a company email</span>
      </h5>
      <div className={`${styles.msgBox} mb-2`}>
        {/* <button className={`px-2 mb-2 ${styles.templateBtn}`}>
          <img
            src={templateIcon}
            alt="template"
            width={12}
          />
          <span className="ps-1">View templates</span>
        </button> */}
        <div className="d-flex justify-content-between gap-2">
          <div className={`${styles.sendingBlock} w-100`}>
            <div className="d-flex align-items-center mb-2">
              <span className={`${styles.emailSubject}`}>From:</span>
              <div className="ps-2 w-100">
                <SendingInfo
                  name="from"
                  currentValue={selectedMessage?.from}
                  getValue={(val) => {
                    updateEmailMessage({ from: val });
                  }}
                  placeholder="Add From Email"
                  isError={
                    state.showErrors &&
                    (!selectedMessage?.from ||
                      selectedMessage?.from?.trim().length == 0)
                  }
                />
                {state.showErrors &&
                (!selectedMessage?.from ||
                  selectedMessage?.from?.trim().length == 0) ? (
                  <div className={styles.errorText}>
                    Please enter from email
                  </div>
                ) : null}
              </div>
            </div>
            {showAll && (
              <div className="d-flex align-items-center mb-2">
                <span className={`${styles.emailSubject}`}>Cc:</span>
                <div className="ps-2 w-100">
                  <SendingInfo
                    name="cc"
                    isMulti={true}
                    addUser={true}
                    currentValue={cCValue}
                    getValue={(value: string) => {
                      updateEmailMessage({ cc: value.split(",") });
                    }}
                    placeholder="Add Cc email"
                  />
                </div>
              </div>
            )}
            {showAll && (
              <div className="d-flex align-items-center mb-2">
                <span className={`${styles.emailSubject}`}>Bcc:</span>
                <div className="ps-2 w-100">
                  <SendingInfo
                    name="bcc"
                    isMulti={true}
                    addUser={true}
                    currentValue={bCCValue}
                    getValue={(value: string) => {
                      updateEmailMessage({ bcc: value.split(",") });
                    }}
                    placeholder="Add Bcc email"
                  />
                </div>
              </div>
            )}
          </div>
          <OpenClose
            onClick={() => setShowAll((prev) => !prev)}
            show={showAll}
          />
        </div>
        <button
          className={`px-2 mb-2 ${styles.templateBtn}`}
          onClick={() => setTemplateModal(true)}
        >
          <img
            src={templateIcon}
            alt="template"
            width={12}
          />
          <span className="ps-1">View templates</span>
        </button>
        <Modal
          backdropClassName={`${styles.modalBrandBack}`}
          show={templateModal}
          onHide={() => setTemplateModal(false)}
          dialogClassName={`${styles.tempDialog}`}
          contentClassName={`${styles.tempContent}`}
        >
          <TemplateModal
            onHide={() => setTemplateModal(false)}
            showErrorModal={showErrorModal}
          />
        </Modal>
        <div>
          <div className="mb-3">
            <label
              htmlFor="emailSubject"
              className={`form-label mb-1 ${styles.emailSubject}`}
            >
              Email subject
            </label>
            <input
              type="text"
              className={`form-control px-2 ${styles.emailInput} ${
                state.showErrors &&
                (!selectedMessage?.subject ||
                  selectedMessage?.subject?.trim().length === 0)
                  ? "border border-danger"
                  : ""
              }`}
              id="emailSubject"
              value={selectedMessage?.subject}
              onChange={(e) => {
                updateEmailMessage({ subject: e.target.value });
              }}
              placeholder="Write email subject"
              maxLength={250}
            />
            {state.showErrors &&
            (!selectedMessage?.subject ||
              selectedMessage?.subject?.trim().length === 0) ? (
              <div className={styles.errorText}>Please enter Email Subject</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`${styles.configureEmail}`}>
        <h5 className={`${styles.configureHead}`}>Configure email design:</h5>
        <div className={`${styles.emailDesignWrap} d-flex`}>
          <div className={`${styles.leftSection}`}>
            <h6 className={`${styles.heading}`}>Edit email</h6>
            <UploadLogo
              onChange={(batchNumber, logoUrl) => {
                updateEmailMessage({ logoAttachment: batchNumber }, logoUrl);
              }}
              initialUrl={state.logoUrl}
              setShowFileWarning={setShowFileWarning}
              showFileWarning={state.showFileWarning}
            />
            <EmailPreviewText
              id="email_heading"
              labelText="Email heading:"
              placeholder="Heading..."
              initialText={selectedMessage?.emailHeading}
              onTextChange={(val) => {
                updateEmailMessage({ emailHeading: val });
              }}
              showError={state.showErrors}
              errorMsg="Please enter Email Heading"
            />
            <EditEmail
              heading="Email body:"
              placeholder="Body..."
              section="userSegment"
              initialValue={selectedMessage?.message ?? ""}
              onChange={(val) => {
                updateEmailMessage({ message: val });
              }}
              showErrors={state.showErrors}
              areAttachmentsPublic={true}
              variablesList={state.variables}
              variableIdsList={state.variableIds}
              disableAttachment={false}
              disableEmbedding={false}
              hideAllControls={false}
              showCustomFilePicker={false}
              updateAttachmentBatchNumber={(val: string[]) => {
                updateEmailMessage({ attachmentBatchNumber: val });
              }}
            />
          </div>
          <div className={`${styles.rightSection}`}>
            <div className={styles.emailPreviewContainer}>
              <DesktopMobilePreview>
                <DesktopView
                  src={state.logoUrl}
                  body={body}
                  heading={(selectedMessage ?? initialMessage).emailHeading}
                />
              </DesktopMobilePreview>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailMessageTab;
