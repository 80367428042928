import { useMemo } from "react";
import { ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import devider from "src/assets/images/devider.png";
import bulb from "src/assets/images/light.png";
import paperPlane from "src/assets/images/PaperPlane.png";
import Loader from "src/components/Loader";
import { homeLink } from "src/services/TicketService/linkGenerator";
import { useAppSelector } from "src/store/store";
import styles from "./Home.module.scss";
import useHomePage from "./hooks/useHomePage";
import ToDoList from "./ToDoList/ToDoList";

/**
 * Progress Bar to show percentage of tickets closed
 */
const ClosedTicketsPercent = () => {
  // Get progress of closed tickets
  const progress = useAppSelector((state) => {
    // Get dropdown list from sidebar data
    const ddList =
      state.ticketSideBar.ticketBtnData[0]?.ticketBtnList[0]?.ddOuterList[1]
        .ddList;

    // Get total ticket count
    const totalTicketCount = ddList ? ddList[0]?.count ?? 0 : 0;
    // Get closed ticket count
    const closedTicketCount = ddList ? ddList[5]?.count ?? 0 : 0;

    // Return the percentage of tickets closed if we have total tickets
    return totalTicketCount !== 0
      ? (closedTicketCount / totalTicketCount) * 100
      : 0;
  });

  return (
    <div className="d-flex justify-content-end align-items-center w-50">
      <span className={`${styles.closeTicket} pe-2`}>
        {progress.toFixed(2)}% tickets closed
      </span>
      <div className="w-25 progressWrapper">
        <ProgressBar
          className="progressBar"
          now={progress}
        />
      </div>
    </div>
  );
};

const Home = () => {
  const {
    currentUserData,
    loading,
    showSocialMediaBlock,
    homePageData,
    setupData,
  } = useHomePage();

  const showCampaignHomePage = useMemo(() => {
    return (
      (currentUserData?.ui_visibility?.includes("campaign_view") ?? false) &&
      (homePageData?.showCampaignHome ?? false)
    );
  }, [currentUserData?.ui_visibility, homePageData?.showCampaignHome]);

  return (
    <div className={`${styles.homePage}`}>
      <div className={`${styles.headBox}`}>
        <div className="d-flex align-items-center">
          <h1 className={`mb-0 saufter_h1 w-50`}>
            {currentUserData &&
              `Hi ${
                currentUserData.firstName.toUpperCase()[0]
              }${currentUserData.firstName.toLowerCase().slice(1)}!`}
          </h1>
          {!currentUserData?.hiddenFeatures?.includes("ticket_view") &&
            homePageData &&
            !showCampaignHomePage && <ClosedTicketsPercent />}
        </div>

        <span className={`saufter_gray_h4 ${styles.saufterHeading}`}>
          Hope you're doing great
        </span>
      </div>
      {loading ? (
        <Loader />
      ) : homePageData ? (
        <>
          {showCampaignHomePage ? (
            <div className="d-flex flex-wrap pt-4">
              <div
                className={`d-flex align-items-center mb-2 ${styles.activeCampaign}`}
              >
                <div className="me-4 pe-2 mt-4">
                  <h4 className={`${styles.count}`}>
                    {homePageData.campaignCount?.active ?? 0}
                  </h4>
                  <span className={`${styles.statusText}`}>
                    Active Campaigns
                  </span>
                </div>
                <img
                  src={bulb}
                  alt="bulb"
                  width={46}
                  height={71}
                />
              </div>
              <div
                className={`d-flex align-items-center justify-content-between mb-2 ${styles.draftCampaign}`}
              >
                <div className="me-1 mt-4">
                  <h4 className={`${styles.count}`}>
                    {homePageData.campaignCount?.draft ?? 0}
                  </h4>
                  <span className={`${styles.statusText}`}>
                    Drafted Campaigns
                  </span>
                </div>
                <img
                  src={paperPlane}
                  alt="plane"
                  width={109.1}
                  height={67.96}
                />
              </div>
            </div>
          ) : (
            <div className="">
              {(!currentUserData?.hiddenFeatures?.includes("ticket_view") ||
                !currentUserData?.hiddenFeatures?.includes("live_chat")) &&
                setupData && <ToDoList setupData={setupData} />}
              {(!currentUserData?.hiddenFeatures?.includes("ticket_view") ||
                !currentUserData?.hiddenFeatures?.includes("live_chat")) && (
                <div className={setupData ? "mt-5" : ""}>
                  <h1 className={`mb-0 saufter_h3 `}>Overview</h1>
                  <span className={`saufter_medium_h4  ${styles.overviewSub}`}>
                    View an overview of{" "}
                    {!currentUserData?.hiddenFeatures?.includes("ticket_view")
                      ? "open tickets,"
                      : ""}{" "}
                    social media requests
                    {!currentUserData?.hiddenFeatures?.includes("live_chat")
                      ? ", live chat revenue, missed live chat"
                      : ""}
                    .
                  </span>
                  <div
                    className={`${styles.overview}`}
                    id="home_section"
                  >
                    {!currentUserData?.hiddenFeatures?.includes(
                      "ticket_view",
                    ) && (
                      <div className={`${styles.homeCard} ${styles.card1}`}>
                        <h4 className={`${styles.cardHeader} saufter_h4`}>
                          Total Number of Open Tickets
                        </h4>
                        <div className={`d-flex align-items-center`}>
                          <h2 className={`${styles.numOfTick}`}>
                            {homePageData.totalOpenTickets}
                          </h2>
                          <NavLink
                            className={`${styles.viewLink}`}
                            to={homeLink()}
                            id="home_section__viewBtn"
                          >
                            View details
                          </NavLink>
                        </div>
                      </div>
                    )}
                    {showSocialMediaBlock ? (
                      <div className={`${styles.homeCard} ${styles.card2}`}>
                        <h4 className={`${styles.cardHeader} saufter_h4`}>
                          Total Pending Social Media Requests
                        </h4>
                        <div
                          className={`d-flex align-items-center justify-content-between`}
                        >
                          {/** Messenger count */}
                          {homePageData.socialMediaRequestCount?.messenger !==
                          undefined ? (
                            <div className={`d-flex align-items-center`}>
                              <i
                                className={`${styles.messengerLogo} fa-brands fa-facebook-messenger`}
                              ></i>
                              <span className={`ms-2 ${styles.numOfTick}`}>
                                {
                                  homePageData.socialMediaRequestCount
                                    ?.messenger
                                }
                              </span>
                            </div>
                          ) : null}

                          {/** Divided line */}
                          {homePageData.socialMediaRequestCount?.instagram !==
                            undefined &&
                          homePageData.socialMediaRequestCount?.messenger !==
                            undefined ? (
                            <div>
                              <img
                                className={`${styles.devider}`}
                                src={devider}
                                alt={`msg`}
                              />
                            </div>
                          ) : null}

                          {/** Instagram count */}
                          {homePageData.socialMediaRequestCount?.instagram !==
                          undefined ? (
                            <div className={`d-flex align-items-center`}>
                              <i
                                className={`${styles.instaLogo} fa-brands fa-instagram`}
                              ></i>
                              <span className={`ms-2  ${styles.numOfTick}`}>
                                {0}
                              </span>
                            </div>
                          ) : null}
                          <div>
                            {/* <NavLink className={`${styles.viewLink2}`} to={homeLink()}>
                  View details
                </NavLink> */}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {!currentUserData?.hiddenFeatures?.includes(
                      "live_chat",
                    ) && (
                      <div className={`${styles.homeCard} ${styles.card3}`}>
                        <h4 className={`${styles.cardHeader} saufter_h4`}>
                          Live chat Revenue in Past 24 Hours
                        </h4>
                        <div className={`d-flex align-items-center`}>
                          <h2 className={`${styles.numOfTick}`}>$0</h2>
                        </div>
                      </div>
                    )}
                    {!currentUserData?.hiddenFeatures?.includes(
                      "live_chat",
                    ) && (
                      <div className={`${styles.homeCard} ${styles.card4}`}>
                        <h4 className={`${styles.cardHeader} saufter_h4`}>
                          Missed Live Chat Requests Past 24 hrs
                        </h4>
                        <div className={`d-flex align-items-center`}>
                          <h2 className={`${styles.numOfTick}`}>0</h2>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      {/* {!loading && (
        <div className={`text-center`}>
          <img className={`${styles.heroImg}`} src={heroImg} alt={``} />
        </div>
      )} */}
    </div>
  );
};
export default Home;
