import { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import CSSideBar from "./children/CSSideBar/CSSideBar";
import SegmentSettings from "./children/SegmentSettings/SegmentSettings";
import SegmentView from "./children/SegmentView/SegmentView";
import { useCustomerSegmentsCreate } from "./hooks/useCustomerSegments";

function CustomerSegments() {
  const [state, dispatch, Provider] = useCustomerSegmentsCreate();
  const location = useLocation();

  const isSettingsPage = useMemo(() => {
    return location.pathname === `${RouteNames.customerSegments}/settings`;
  }, [location.pathname]);

  const isFromCampaign = useRef(location.state?.isFromCampeign ?? false);

  return (
    <Provider value={[state, dispatch]}>
      <CSSideBar isFromCampaign={isFromCampaign.current} />
      {isSettingsPage ? <SegmentSettings /> : <SegmentView />}
    </Provider>
  );
}

export default CustomerSegments;
