import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import useDebounce from "src/hooks/useDebounce";

let scrollCheckDebounce = null;
/**
 * Custom hook to handle timeline scrolling with smooth animation and debounce.
 *
 * @param scrollDebounce - Debounce delay in milliseconds for initial scroll
 * @param timelineRef - Optional reference to a timeline container for scrolling into view
 */
const useTimelineScroll = (
  scrollDebounce = 0,
  timelineRef?: React.RefObject<HTMLDivElement>,
) => {
  // Reference to the scrollable div within the timeline
  const scrollDivRef = useRef<HTMLDivElement>(null);

  // Track whether the scroll is at its initial position
  const [isInitialScroll, setIsInitialScroll] = useState(true);

  // Debounce the initial scroll state
  const isInitialScrollDebounce = useDebounce(isInitialScroll, scrollDebounce);

  // Track whether scrolling right is disabled based on scroll position
  const [disableRight, setDisableRight] = useState(false);
  const [disableLeft, setDisableLeft] = useState(false);

  /**
   * Resets the scroll to the right end, then smoothly scrolls back to the start.
   */
  const handleResetScroll = useCallback(() => {
    if (scrollDivRef.current) {
      flushSync(() => {
        setIsInitialScroll(true);
      });
      const scrollAmount = scrollDivRef.current.clientWidth;
      scrollDivRef.current.scrollLeft = scrollAmount; // Scroll right 100% of width
      setTimeout(() => {
        if (scrollDivRef.current) {
          scrollDivRef.current.style.scrollBehavior = "smooth";
          scrollDivRef.current.scrollLeft = 0;
        }
      }, 0);
    }
  }, []);

  /**
   * Scrolls left by one-third of the container width, or resets if close to the start.
   */
  const handleScrollLeft = useCallback(() => {
    if (scrollDivRef.current) {
      let scrollAmount = scrollDivRef.current.clientWidth / 3;
      if (scrollDivRef.current.scrollLeft <= 10) {
        handleResetScroll();
      } else {
        scrollDivRef.current.style.scrollBehavior = "smooth";
        scrollDivRef.current.scrollLeft -= scrollAmount;
      }
    }
  }, [handleResetScroll]);

  /**
   * Scrolls right by one-third of the container width; handles initial scroll state and animation.
   */
  const handleScrollRight = useCallback(() => {
    if (scrollDivRef.current) {
      if (isInitialScroll) {
        setIsInitialScroll(false);

        // Scrolls the timeline header into view on first scroll if provided
        // Currently used in inner ticket view header
        if (timelineRef && timelineRef.current) {
          timelineRef.current.scrollIntoView({
            behavior: "smooth",
            inline: "start",
          });

          return;
        }
      }

      scrollDivRef.current.style.scrollBehavior = "smooth";
      const scrollAmount = scrollDivRef.current.clientWidth / 3;
      scrollDivRef.current.scrollLeft += scrollAmount; // Scroll right by 1/3 of width
    }
  }, [isInitialScroll, timelineRef]);

  /**
   * Memoized value to enable left scrolling if not at the initial position.
   */
  const hideFirstHeader = useMemo(() => {
    if (!isInitialScrollDebounce && !isInitialScroll) {
      if (scrollDivRef.current) {
        scrollDivRef.current.style.scrollBehavior = "unset";
        scrollDivRef.current.scrollLeft = 0;
      }

      // Enable left scrolling
      return true;
    }
    return false;
  }, [isInitialScrollDebounce, isInitialScroll]);

  const checkScroll = () => {
    setTimeout(() => {
      if (scrollDivRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current;
        setDisableRight(scrollLeft + clientWidth >= scrollWidth);
        setDisableLeft(scrollLeft <= 0 ? true : false);
      }
    }, 100);
  };

  /**
   * Sets up a scroll event listener to enable/disable right scrolling based on the position.
   */
  useEffect(() => {
    if (scrollDivRef.current) {
      checkScroll();
    }

    const currentScrollDiv = scrollDivRef.current;
    if (currentScrollDiv) {
      currentScrollDiv.addEventListener("scroll", checkScroll);
    }

    return () => {
      if (currentScrollDiv) {
        currentScrollDiv.removeEventListener("scroll", checkScroll);
      }
    };
  }, [scrollDivRef.current]);

  return {
    handleScrollLeft,
    handleScrollRight,
    handleResetScroll,
    disableLeft,
    isInitialScroll,
    disableRight: disableRight,
    scrollDivRef,
    hideFirstHeader,
    checkScroll,
  };
};

export default useTimelineScroll;
