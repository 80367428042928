import { useCallback, useMemo, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import AxiosImg from "src/components/AxiosImg";
import Loader from "src/components/Loader";
import ParseHTML from "src/components/ParseHTML";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import addCustomerNoteService from "src/services/CustomerSegments/CustomerNote/addCustomerNote.service";
import { ICustomerNote } from "src/services/CustomerSegments/CustomerNote/getAllCustomerNote.service";
import { useAppSelector } from "src/store/store";
import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";
import { v4 as uuid } from "uuid";
import NoteModal from "./NoteModal/NoteModal";
import styles from "./UserNote.module.scss";
import useUserNote from "./useUserNote";

const Editor = ({
  customerId,
  showEditor,
  onAdd,
  onHide,
}: {
  customerId: string;
  showEditor: boolean;
  onAdd: (addedNote: ICustomerNote) => void;
  onHide: () => void;
}) => {
  const currentUser = useAppSelector((state) => state.globals.currentUserData);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (isHTMLEmpty(trimPDIVBR(message?.trim()))) {
      setIsError(true);
      return;
    }

    try {
      setIsLoading(true);
      const res = await addCustomerNoteService({
        customerId,
        noteText: message,
      });

      onAdd(res);
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  }, [customerId, message, onAdd]);

  return (
    <div className={`${styles.noteBox} ${showEditor ? "" : "d-none"} mb-2`}>
      <div className="d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center">
          {currentUser?.profileImageUrl &&
          currentUser.profileImageUrl.trim() !== "" ? (
            <AxiosImg
              url={currentUser.profileImageUrl}
              className={`rounded-circle`}
              style={{ width: "14px", height: "14px" }}
            />
          ) : (
            <UserAvatar
              name={
                currentUser
                  ? `${currentUser.firstName} ${currentUser.lastName}`
                  : "NA"
              }
              size={14}
            />
          )}
          <span className={`ps-1 ${styles.userName}`}>
            {currentUser
              ? `${currentUser.firstName} ${currentUser.lastName}`
              : "NA"}
          </span>
        </div>
        {/* <div>
          <span className={`${styles.dateTime}`}>
            {getMonthNameDateAmPmTime(note.createdAtGmt, true)}
          </span>
        </div> */}
      </div>
      <div className={`${styles.separator}`}></div>
      <TinyMCEContainer
        className={`${styles.textArea} ${
          isError
            ? isHTMLEmpty(trimPDIVBR(message.trim()))
              ? "border-danger shadow-none"
              : ""
            : ""
        }`}
        value={message}
        onChange={(value) => {
          setMessage(value);
          setIsError(false);
        }}
        // Only used to remove the left margin from toolbar
        showCustomFilePicker={true}
        uniqueID={uuid()}
        hideAllControls={true}
        options={{
          placeholder: "Write your message...",
        }}
        toolbar="emoticons bold italic underline link bullist numlist"
      />
      <div className="d-flex justify-content-end p-2">
        <button
          onClick={onHide}
          className={`me-2 ${styles.cancelBtn}`}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className={`${styles.saveBtn}`}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

const UserNote = ({
  customerId,
  isInnerTicket,
}: {
  customerId: string;
  isInnerTicket?: boolean;
}) => {
  const {
    currentNoteIds,
    currentNotes,
    allNotes,
    allNoteIds,
    currentPage,
    isFetching,
    setCurrentPage,
    totalPages,
    onAddNote,
    fetchNextPage,
    hasNextPage,
  } = useUserNote({ customerId });

  const [showEditor, setShowEditor] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onAdd = useCallback(
    (addedNote: ICustomerNote) => {
      onAddNote(addedNote);
      setShowEditor(false);
    },
    [onAddNote],
  );

  const noteIds = useMemo(() => {
    return showMore ? currentNoteIds : currentNoteIds.slice(0, 3);
  }, [currentNoteIds, showMore]);

  return (
    <div>
      <div className={` ${styles.tagBox} mb-2 p-3`}>
        <h3 className={`${styles.heading}`}>User note</h3>

        <Editor
          onAdd={onAdd}
          customerId={customerId}
          onHide={() => setShowEditor(false)}
          showEditor={showEditor}
        />

        {!showEditor && (
          <button
            className={`px-2 mb-2 ${styles.addBtn} ${
              isInnerTicket ? styles.addNoteBtn : ""
            }`}
            onClick={() => setShowEditor(true)}
          >
            Add a note
          </button>
        )}

        <div className={`ms-0 me-0`}>
          {isFetching && !showModal ? (
            <Loader />
          ) : (
            noteIds.map((noteId) => {
              const note = currentNotes[noteId];

              if (!note) {
                return <></>;
              }

              return (
                <OverlayTrigger
                  placement="left"
                  trigger={"hover"}
                  key={noteId}
                  overlay={
                    <Tooltip className={`${styles.toolTipCustom}`}>
                      <div className={`${styles.noteBox} mb-2`}>
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div className="d-flex align-items-center">
                            {note.agentDetails.imageURL &&
                            note.agentDetails.imageURL.trim() !== "" ? (
                              <AxiosImg
                                url={note.agentDetails.imageURL}
                                className={`rounded-circle`}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <UserAvatar
                                name={note.agentDetails.name ?? "NA"}
                                size={16}
                              />
                            )}
                            <span className={`ps-1 ${styles.userName}`}>
                              {note.agentDetails.name}
                            </span>
                          </div>
                          <div className="ps-1">
                            <span className={`${styles.dateTime}`}>
                              {getMonthNameDateAmPmTime(
                                note.createdAtGmt,
                                true,
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={`${styles.separator}`}></div>
                        <div className="p-2">
                          <p className={`mb-0 ${styles.fullDesc}`}>
                            <ParseHTML
                              html={note.noteText}
                              className={`${styles.fullDesc}`}
                            />
                          </p>
                        </div>
                      </div>
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      className={`ms-0 me-0`}
                      {...triggerHandler}
                      key={noteId}
                      ref={ref}
                    >
                      <div
                        className={`${styles.noteBox} mb-2 cursor-pointer`}
                        onClick={() => setShowModal(true)}
                      >
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div className="d-flex align-items-center">
                            {note.agentDetails.imageURL &&
                            note.agentDetails.imageURL.trim() !== "" ? (
                              <AxiosImg
                                url={note.agentDetails.imageURL}
                                className={`rounded-circle`}
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <UserAvatar
                                name={note.agentDetails.name ?? "NA"}
                                size={16}
                              />
                            )}
                            <span className={`ps-1 ${styles.userName}`}>
                              {note.agentDetails.name}
                            </span>
                          </div>
                          <div className="ps-1">
                            <span className={`${styles.dateTime}`}>
                              {getMonthNameDateAmPmTime(
                                note.createdAtGmt,
                                true,
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={`${styles.separator}`}></div>
                        <div className="p-2">
                          <p
                            className={`mb-0 ${styles.truncate} ${styles.clamped}`}
                          >
                            <ParseHTML
                              html={note.noteText}
                              className={`${styles.clamped} ${styles.desc}`}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              );
            })
          )}

          <div className="d-flex align-items-center justify-content-center">
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>

          {currentNoteIds.length > 3 && (
            <div className="d-flex align-items-center justify-content-end">
              <span
                className={`${styles.showMoreBtn} cursor-pointer mt-2`}
                onClick={() => setShowMore((prev) => !prev)}
              >
                {showMore ? "Show Less" : "Show more"}
              </span>
            </div>
          )}
        </div>
      </div>

      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <NoteModal
          allNoteIds={allNoteIds}
          allNotes={allNotes}
          onAddNoteClick={() => {
            setShowModal(false);
            setShowEditor(true);
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage ?? false}
          isLoading={isFetching}
          onHide={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};

export default UserNote;
