/**
 * This file defines a custom hook responsible for CURD operation for source articles
 * also handles selecting articles, reindexing
 *
 * @author @yuvaraj-busibud
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useDebounce from "src/hooks/useDebounce";
import {
  Articles,
  Source,
} from "src/services/Bot/AnswerSources/getAllExternal.service";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import {
  GetSourceArticlesParams,
  GetSourceArticlesResponse,
  getSourceArticles,
} from "src/services/Bot/AnswerSources/getSourceArticles.service";
import { SelectArticlesResponseExternal } from "src/services/Bot/AnswerSources/selectSourceArticles.service";

import { updateSourceImportStatus } from "src/services/Bot/AnswerSources/updateSourceImportStatus";
import {
  calculateTotalPages,
  pageSlice,
  paginationLogic,
} from "src/services/TicketService/pagination";
interface Props {
  sourceId: number;
  totalArticleCount: number;
  updateSource: (id: number, values: Partial<Source>) => void;
  source: Source;
}
function useSourceArticles({
  sourceId,
  totalArticleCount,
  updateSource,
  source,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showArticles, setShowArticles] = useState(false);
  const [isAllArticleSelected, setIsAllArticleSelected] = useState(
    source.isAllArticleSelected,
  );

  const onShow = (e: any) => {
    e.stopPropagation();
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");

  const debouncedSearchTerm = useDebounce(search, 500);
  const lastSearchTermRef = useRef("");

  // const { profileId } = useParams();

  const payload = useMemo(() => {
    return {
      sourceId: sourceId + "",
      pageNo: currentPage,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    };
  }, [currentPage, debouncedSearchTerm]);

  const totalPagesRef = useRef(0);

  const currentState = useRef({
    payload: payload,
    currentPage: currentPage,
  });

  useMemo(() => {
    currentState.current.payload = payload;
    currentState.current.currentPage = currentPage;
  }, [payload, search, currentPage]);

  const cachedDataRef = useRef<{
    data: { [key: number]: Articles[] };
    metaData: { count: number; total: number };
  }>({ data: {}, metaData: { count: 0, total: 0 } });

  const fetchDataForPage = useCallback(async (payload: any) => {
    const perAjaxFetchLimit = 5;
    const pageNo = payload.pageNo;
    if (lastSearchTermRef.current !== (payload.searchTerm ?? "")) {
      //clearing the cache if search applied
      cachedDataRef.current = { data: {}, metaData: { count: 0, total: 0 } };
      lastSearchTermRef.current = payload.searchTerm ?? "";
    }
    const cachedData = cachedDataRef.current.data;

    if (cachedData[pageNo]) {
      // If data for the requested page is already cached, return it.
      return {
        data: cachedData[pageNo],
        metaData: cachedDataRef.current.metaData,
      } as GetSourceArticlesResponse;
    } else {
      const { startPage, endPage, start, limit } = paginationLogic(
        pageNo,
        perAjaxFetchLimit,
        cachedDataRef.current.metaData.total,
      );

      const sourceArticlePayload: GetSourceArticlesParams = {
        start,
        limit,
        sourceId: payload.sourceId,
      };

      if (payload.searchTerm?.trim()) {
        sourceArticlePayload.searchTerm = payload.searchTerm;
      }

      const sourceArticles = await getSourceArticles(sourceArticlePayload);
      // If data is not in the cache, fetch it and store it in the cache.
      const totalPages = calculateTotalPages(
        sourceArticles.metaData.total,
        perAjaxFetchLimit,
      );
      totalPagesRef.current = totalPages;

      let count = 0;
      for (let i = startPage; i <= endPage; i++) {
        cachedData[i] = pageSlice(
          sourceArticles.data,
          perAjaxFetchLimit,
          count,
        );
        count++;
      }
      cachedDataRef.current.metaData = sourceArticles.metaData;
      if (sourceArticles.metaData.isAllArticleSelected) {
        setIsAllArticleSelected(sourceArticles.metaData.isAllArticleSelected);
      }

      return {
        data: cachedData[pageNo],
        metaData: cachedDataRef.current.metaData,
      } as GetSourceArticlesResponse;
    }
  }, []);

  const { data, isLoading, status, fetchStatus } = useQuery({
    queryKey: [`getSourceArticles/${payload.sourceId}`, payload],
    queryFn: () => {
      const dataForPage = fetchDataForPage(payload);
      return dataForPage;
    },
    enabled: showArticles && totalArticleCount !== 0,
  });

  //function to update article data in the react-query state
  const updateArticleValue = useCallback(
    (articleId: number, values: Partial<Article>) => {
      const updatedData = cachedDataRef.current.data[currentPage]?.map(
        (article) =>
          article.articleId === articleId ? { ...article, ...values } : article,
      );

      if (updatedData) {
        cachedDataRef.current.data[currentPage] = updatedData;
      }

      let queryData: GetSourceArticlesResponse | undefined =
        queryClient.getQueryData([
          `getSourceArticles/${payload.sourceId}`,
          currentState.current.payload,
        ]);

      if (queryData) {
        const articlesData = queryData?.data.map((article) =>
          article.articleId === articleId ? { ...article, ...values } : article,
        );
        // 2. Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(
          [
            `getSourceArticles/${payload.sourceId}`,
            currentState.current.payload,
          ],
          {
            ...queryData,
            data: articlesData,
          },
        );
      }
    },
    [data, payload, currentPage],
  );
  //function to update article data in the react-query state for botTrainingStatus
  const updateArticlebotTrainingStatus = useCallback(
    (externalArticleResponse: SelectArticlesResponseExternal) => {
      // Ensure the external response contains trainingStatuse
      if (
        externalArticleResponse.trainingStatuses &&
        externalArticleResponse.trainingStatuses.length === 0
      ) {
        return;
      }

      if (data && data.data) {
        // console.log("Original data:", data);
        // console.log(cachedDataRef.current.data);
        const updatedData = Object.values(cachedDataRef.current.data).map(
          (articleArray) =>
            articleArray.map((article) => {
              // Find the matching training status for the article
              const trainingStatus =
                externalArticleResponse.trainingStatuses.find(
                  (status) => status.articleId === article.articleId,
                );

              // If a matching training status is found, update the article
              if (trainingStatus) {
                return {
                  ...article,
                  botTrainingStatus: trainingStatus.botTrainingStatus,
                };
              }
              // If no match is found, return the original article
              return article;
            }),
        );
        // Update cachedDataRef with the new updated data if necessary
        cachedDataRef.current.data = updatedData;

        // Map through the articles and update botTrainingStatus if articleId matches
        const updatedArticleData = data.data.map((article: any) => {
          const trainingStatus = externalArticleResponse.trainingStatuses.find(
            (status) => status.articleId === article.articleId,
          );
          if (trainingStatus) {
            // Update botTrainingStatus for the matching article
            return {
              ...article,
              botTrainingStatus: trainingStatus.botTrainingStatus,
            };
          }

          // Return the original article if no match is found
          return article;
        });

        // Log the updated data for debugging purposes
        // console.log("Updated article data:", updatedArticleData);

        // Update the cache with the new data
        // console.log(
        //   queryClient.getQueryData([
        //     `getSourceArticles/${payload.sourceId}`,
        //     currentState.current.payload,
        //   ]),
        // );
        queryClient.setQueryData(
          [
            `getSourceArticles/${payload.sourceId}`,
            currentState.current.payload,
          ],
          {
            ...data,
            data: updatedArticleData,
          },
        );
      }
      // queryClient.invalidateQueries({
      //   queryKey: [`getSourceArticles/${payload.sourceId}`],
      //   exact: false,
      // });
    },
    [queryClient, payload, data],
  );

  //function to change article selected state in the react-query state
  const unSelectAllArticle = useCallback(
    (isSelected: boolean) => {
      const updatedData = Object.values(cachedDataRef.current.data).forEach(
        (articles) =>
          articles.map((article) => (article.isSelected = isSelected)),
      );

      let queryData: GetSourceArticlesResponse | undefined =
        queryClient.getQueryData([
          `getSourceArticles/${payload.sourceId}`,
          currentState.current.payload,
        ]);

      if (queryData) {
        const articlesData = queryData?.data.map((article) => {
          article.isSelected = isSelected;
          return article;
        });

        // 2. Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(
          [
            `getSourceArticles/${payload.sourceId}`,
            currentState.current.payload,
          ],
          {
            ...queryData,
            data: articlesData,
          },
        );
      }
    },
    [data, payload, currentPage],
  );

  const selectedArticleIds = useMemo(() => {
    const allSelectedArticleIds = [];

    // Iterate through all pages and collect selected article IDs
    for (const pageData of Object.values(cachedDataRef.current.data)) {
      const selectedArticleIds = pageData
        .filter((article) => article.isSelected)
        .map((article) => article.articleId);

      allSelectedArticleIds.push(...selectedArticleIds);
    }

    return allSelectedArticleIds;
  }, [data]);

  const stopAndResumeImportMutation = useMutation({
    mutationFn: updateSourceImportStatus,
  });

  const reindexSourceMutation = useMutation({
    mutationFn: updateSourceImportStatus,
  });

  //function that handles stop importing of an source articles
  const handleStopImport = useCallback(() => {
    stopAndResumeImportMutation.mutate(
      {
        action: "stop",
        sourceId: sourceId,
      },
      {
        onSuccess: (result) => {
          let source: Partial<Source> = {
            status: result.status,
            noOfPagesBeingImported: result.numberOfPages,
            pagesImported: result.numberOfPages,
          };

          updateSource(result.sourceId, source);
        },
        onError: (error: any) => {
          // console.log(error);
          pushTheToast({
            type: "danger",
            text: error?.displayableError
              ? error?.displayableError
              : "Failed to stop the import!",
            position: "top-right",
          });
        },
      },
    );
  }, [sourceId]);

  //function that handles resume import of an source articles
  const handleResumeImport = useCallback(() => {
    stopAndResumeImportMutation.mutate(
      {
        action: "resume",
        sourceId: sourceId,
      },
      {
        onSuccess: (result) => {
          let source: Partial<Source> = {
            status: result.status,
            noOfPagesBeingImported: result.numberOfPages,
          };
          updateSource(result.sourceId, source);
        },
        onError: (error: any) => {
          console.log(JSON.stringify(error), error.displayableError);

          pushTheToast({
            type: "danger",
            text: error?.displayableError
              ? error?.displayableError
              : "Failed to resume import!",
            position: "top-right",
          });
        },
      },
    );
  }, [sourceId]);

  //function that handles refreshing import of an source articles
  const handleRefreshImport = useCallback(() => {
    stopAndResumeImportMutation.mutate(
      {
        action: "refresh",
        sourceId: sourceId,
      },
      {
        onSuccess: (result) => {
          let source: Partial<Source> = {
            status: result.status,
            noOfPagesBeingImported: result.numberOfPages,
          };
          updateSource(result.sourceId, source);
        },
        onError: (error: any) => {
          pushTheToast({
            type: "danger",
            text: error?.displayableError
              ? error?.displayableError
              : "Failed to refresh source!",
            position: "top-right",
          });
        },
      },
    );
  }, [sourceId]);

  //function that handles reindex import of an source articles
  const handleReindexSource = useCallback(() => {
    reindexSourceMutation.mutate(
      {
        action: "reindex",
        sourceId: sourceId,
      },
      {
        onSuccess: (result) => {
          let source: Partial<Source> = {
            status: result.status,
            noOfPagesBeingImported: result.numberOfPages,
          };
          updateSource(result.sourceId, source);
        },
        onError: (error: any) => {
          pushTheToast({
            type: "danger",
            text: error?.displayableError
              ? error?.displayableError
              : "Failed to reindex source!",
            position: "top-right",
          });
        },
      },
    );
  }, [sourceId]);

  // useEffect(() => {
  //   console.log("selectedArticleIds.length", selectedArticleIds);
  //   console.log("source.totalArticleCount", source.totalArticleCount);

  //   if (
  //     selectedArticleIds.length &&
  //     source.totalArticleCount == selectedArticleIds.length
  //   ) {
  //     setIsAllArticleSelected(true);
  //   } else {
  //     setIsAllArticleSelected(false);
  //   }
  // }, [selectedArticleIds, source.totalArticleCount]);

  return {
    showModal,
    onShow,
    onHide,
    search,
    setSearch,
    articles: data?.data,
    isLoading,
    debouncedSearchTerm,
    currentPage,
    setCurrentPage,
    totalPages: totalPagesRef.current,
    showArticles,
    setShowArticles,
    updateArticleValue,
    fetchStatus,
    status,
    unSelectAllArticle,
    selectedArticleIds,
    handleRefreshImport,
    handleReindexSource,
    handleResumeImport,
    handleStopImport,
    isStopOrResumeImportLoading: stopAndResumeImportMutation.isLoading,
    isReindexingLoading: reindexSourceMutation.isLoading,
    isAllArticleSelected,
    setIsAllArticleSelected,
    updateArticlebotTrainingStatus,
  };
}

export default useSourceArticles;
