import React, { SetStateAction, useEffect, useState } from "react";
import { SegmentMessageType } from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { useSendMessage } from "../../Hooks/useSendMessageContext";
import styles from "./MessageTabs.module.scss";
import ChatMessageTab from "./children/ChatMessageTab/ChatMessageTab";
import EmailMessageTab from "./children/EmailMessageTab/EmailMessageTab";

const MessageTabs = ({
  showErrorModal,
  setShowAttributeModal,
}: {
  showErrorModal: () => void;
  setShowAttributeModal: () => void;
}) => {
  const { state } = useSendMessage();
  const [activeTab, setActiveTab] = useState<SegmentMessageType | null>(null);

  // Effect for initialising activeTab when updated
  useEffect(() => {
    // Check if activeTab is removed from selected types
    if (activeTab) {
      if (!state.selectedTypes.includes(activeTab)) {
        setActiveTab(
          state.selectedTypes.length ? state.selectedTypes[0] : null,
        );
      }
    }
    if (activeTab === null && state.selectedTypes.length > 0) {
      setActiveTab(state.selectedTypes[0]);
    }
  }, [activeTab, state.selectedTypes]);

  if (state.selectedTypes.length === 0) {
    return null;
  }

  return (
    <div className={`${styles.viaChatWrapper}`}>
      <div className="d-flex ms-2">
        {state.selectedTypes.includes(SegmentMessageType.Chat) && (
          <button
            className={`px-2 ${styles.viaBtn} ${
              activeTab === SegmentMessageType.Chat ? styles.active : ""
            }`}
            onClick={() => setActiveTab(SegmentMessageType.Chat)}
          >
            Via Chat
          </button>
        )}

        {state.selectedTypes.includes(SegmentMessageType.Email) && (
          <button
            className={`px-2 ${styles.viaBtn} ${
              activeTab === SegmentMessageType.Email ? styles.active : ""
            }`}
            onClick={() => setActiveTab(SegmentMessageType.Email)}
          >
            Via Email
          </button>
        )}
      </div>

      {activeTab === SegmentMessageType.Chat && (
        <ChatMessageTab setShowAttributeModal={setShowAttributeModal} />
      )}

      {activeTab === SegmentMessageType.Email && (
        <EmailMessageTab showErrorModal={showErrorModal} />
      )}
    </div>
  );
};

export default MessageTabs;
