import { IAllSegmentType } from "src/services/CustomerSegments/getSegmentTypes.service";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";
import { NavLink, useLocation } from "react-router-dom";
import NavItemToggleList from "../NavItemToggleList/NavItemToggleList";
import styles from "../../CSSideBar.module.scss";
import { useCallback, useEffect } from "react";
import CustomSegmentList from "../CustomSegmentList/CustomSegmentList";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { useAppSelector } from "src/store/store";

const SegmentTypeItem = ({
  segmentTypeData,
}: {
  segmentTypeData: IAllSegmentType;
}) => {
  const { customerSegments, dispatch } = useCustomerSegments();
  const location = useLocation();

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const createNewSegment = useCallback(() => {
    dispatch("setUpdateSegmentDetails", {
      segmentType: segmentTypeData.segmentType,
      action: "create",
    });
  }, [dispatch, customerSegments]);

  useEffect(() => {
    // Parse URL parameters to check if `action=create` is present
    const params = new URLSearchParams(location.search);
    const action = params.get("action");

    if (action && action === "create") {
      createNewSegment();
    }
  }, [location.search, createNewSegment]);

  return (
    <li className={`${styles.nav_item}`}>
      <NavItemToggleList
        id={`${segmentTypeData.segmentType}ToggleBtn`}
        title={segmentTypeData.name}
        isHeading={true}
        initialOpenState={
          customerSegments.activeSegmentType === segmentTypeData.segmentType
        }
      >
        <div>
          {/* Segment Types List */}
          <ul
            id={`${segmentTypeData.segmentType}List`}
            className={`${styles.nav_content}`}
          >
            {/* No Sub-Segment Type available */}
            {segmentTypeData.segmentGroups.length === 0 && (
              <span>No items found</span>
            )}

            {/* Segment Types List Items */}
            {segmentTypeData.segmentGroups.map((segmentGroup, idx) => {
              return (
                <li key={`${segmentGroup.name}_${idx}`}>
                  {/* Custom Segments Sub List */}
                  {segmentGroup.segmentId === undefined &&
                  segmentGroup.segments === undefined ? (
                    <CustomSegmentList
                      name={segmentGroup.name}
                      segmentType={segmentTypeData.segmentType}
                    />
                  ) : segmentGroup.segments &&
                    segmentGroup.segments.length > 0 ? (
                    // Default Segments Sub List
                    <div
                      className={`${styles.a}`}
                      id={`${segmentGroup.name}_${idx}`}
                    >
                      <NavItemToggleList
                        id={`${segmentGroup.name}_${idx}_sublist`}
                        title={segmentGroup.name}
                        initialOpenState={
                          segmentTypeData.defaultSegmentIds.includes(
                            customerSegments.activeSegmentId + "",
                          ) &&
                          segmentTypeData.segmentType ===
                            customerSegments.activeSegmentType
                        }
                      >
                        <ul
                          id={`${segmentGroup.name}_${idx}_sublist_list`}
                          className={`${styles.nav_content}`}
                        >
                          {segmentGroup.segments.map((segment) => {
                            return (
                              <li key={segment.segmentId}>
                                <NavLink
                                  to={customerSegmentsUtils.getNavigation(
                                    segmentTypeData.segmentType,
                                    segment.segmentId,
                                  )}
                                  className={`${styles.a}`}
                                  id={segment.segmentId}
                                  style={({ isActive }) => ({
                                    color: isActive ? "#fff" : "",
                                    background: isActive ? "#ff8499" : "",
                                  })}
                                >
                                  <span>{segment.name}</span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </NavItemToggleList>
                    </div>
                  ) : segmentGroup.segmentId ? (
                    // Segment Type - all
                    <NavLink
                      to={customerSegmentsUtils.getNavigation(
                        segmentTypeData.segmentType,
                        segmentGroup.segmentId,
                      )}
                      className={`${styles.a}`}
                      id={`${segmentGroup.name}_${idx}`}
                      style={({ isActive }) => ({
                        color: isActive ? "#fff" : "",
                        background: isActive ? "#ff8499" : "",
                      })}
                    >
                      <span>{segmentGroup.name}</span>
                    </NavLink>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}

            {currentUserData?.ui_visibility?.includes("customer_timeline") &&
              currentUserData?.userRole === "31" &&
              segmentTypeData.segmentType === "userSegment" && (
                <li>
                  <NavLink
                    to={`${RouteNames.customerSegments}/settings`}
                    className={`${styles.a}`}
                    style={({ isActive }) => ({
                      color: isActive ? "#fff" : "",
                      background: isActive ? "#ff8499" : "",
                    })}
                  >
                    <span>Settings</span>
                  </NavLink>
                </li>
              )}
          </ul>

          {/* Create Segment Button */}
          <button
            className={`mt-3 ${styles.createSegment}`}
            id="createSegment"
            onClick={createNewSegment}
          >
            Create segment
          </button>
        </div>
      </NavItemToggleList>
    </li>
  );
};

export default SegmentTypeItem;
