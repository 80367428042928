import { useReturnPreviewState } from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import styles from "./ConfigureSetting.module.scss";
import configureSet from "src/assets/images/configureSetting.svg";
const ConfigureSetting = () => {
  const { updateState, selectedOrder, setShowPreview } =
    useReturnPreviewState();

  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        selectedOrder: {
          ...selectedOrder,
          activeStep: selectedOrder?.prevStepForConfig ?? "selectAction",
        },
      });
    }
  };

  return (
    <div>
      <div
        className={`text-center mt-2 pt-5 ${styles.widgetBody} d-flex align-items-center justify-content-center`}
      >
        <div>
          <img
            src={configureSet}
            alt=""
            width={32}
            height={32}
          />
          <h4 className={`${styles.heading}`}>
            Configure more steps to see upcoming screens.
          </h4>
          <button
            className={`${styles.continueBtn}`}
            id="configureBtn"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            Continue settings
          </button>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        showNext={false}
      />
      <WidgetFooter />
    </div>
  );
};

export default ConfigureSetting;
