import { useMemo, useRef, useState } from "react";
import styles from "../../TimelineInfo.module.scss";
import { TimelineEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import AttributeDetailExpanded from "../../../AttributeDetailExpanded/AttributeDetailExpanded";
import AttributeDetails from "../../../AttributeDetails/AttributeDetails";
import Timeline from "../../Timeline/Timeline";
export interface Props {
  event: TimelineEvent & { type: "minor" | "major"; attribute?: TimelineEvent };
  index: number;
  bgColor: string;
  position?: "bottom" | "top";
  showTimeline: boolean;
  currentState: React.MutableRefObject<{ lastTimelineBgColor: string }>;
}
const EventRenderer = ({
  event,
  index,
  bgColor,
  position,
  showTimeline,
  currentState,
}: Props) => {
  if (event.details?.length === 0) {
    return (
      <div
        key={index}
        className={`test ${styles.timelineWrapper} d-flex ${
          showTimeline ? styles.expanded : ""
        }`}
      >
        <div className={`d-flex flex-column ${styles.flexOne}`}>
          <div className="d-flex">
            <div className="position-relative d-flex align-items-center w-100">
              {event.attribute &&
                event.attribute.details &&
                (showTimeline ? (
                  <AttributeDetailExpanded event={event} />
                ) : (
                  <AttributeDetails
                    event={event}
                    position={position}
                  />
                ))}
              <div
                style={{
                  border: bgColor,
                  backgroundColor: bgColor,
                }}
                className={`${styles.verticalLine} ${
                  event.attribute && event.attribute.details
                    ? styles.hasAttributes
                    : ""
                } ${
                  event.type === "minor"
                    ? styles.minorEvent
                    : styles.majorEventEmpty
                } ${showTimeline ? styles.expanded : ""}`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      key={index}
      className={`${styles.timelineWrapper} d-flex ${
        showTimeline ? styles.expanded : ""
      }`}
    >
      <div className="d-flex flex-column">
        <div className="d-flex">
          {event.details?.map((detail, detailIndex) => {
            currentState.current.lastTimelineBgColor =
              bgColor || currentState.current.lastTimelineBgColor;
            return (
              <div
                key={detailIndex}
                className="position-relative d-flex align-items-center justify-content-center"
              >
                <Timeline
                  date={detail.datetime}
                  expanded={showTimeline}
                  {...detail}
                  index={detailIndex}
                />
                {event.attribute &&
                  event.attribute.details &&
                  (showTimeline ? (
                    <AttributeDetailExpanded
                      event={event}
                      majarEventExist={true}
                      position={position}
                    />
                  ) : (
                    <AttributeDetails
                      event={event}
                      position={position}
                    />
                  ))}
                <div
                  style={{
                    border: bgColor,
                    backgroundColor: bgColor,
                  }}
                  className={`${styles.verticalLine}  ${
                    event.attribute && event.attribute.details
                      ? styles.hasAttributes
                      : ""
                  } ${showTimeline ? styles.expanded : ""} ${
                    event.type === "minor" ? styles.minorEvent : ""
                  }`}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default EventRenderer;
