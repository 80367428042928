import styles from "./AddReasons.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import { useState } from "react";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import TimeLine from "../Timeline/Timeline";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import useGetAutomationData, {
  IReturnReason,
} from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
const AddReasons = () => {
  const [showError, setShowError] = useState(false);
  const {
    selectedOrder,
    orders,
    updateState,
    followupModalData,
    ...contextValues
  } = useReturnPreviewState();
  const { getAllReasons, arsResponse, isReturnMethodAvailable, explainAnswer } =
    useGetAutomationData();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const allReasons = getAllReasons();

  const handleNext = () => {
    if (selectedOrder) {
      const addedItemsLength = Object.keys(
        selectedOrder?.addedItems ?? {},
      ).length;
      const selectReasonsLength = Object.values(
        selectedOrder?.addedItems ?? {},
      ).filter((data) => data.addedReasonId).length;

      if (selectReasonsLength > 0 && addedItemsLength === selectReasonsLength) {
        let nextPage: StepName = "configureNextSteps";
        let prevPage: StepName = "reason";

        // Check if any item's selected reason has moreDetails length > 0
        const hasMoreDetails = Object.values(
          selectedOrder.addedItems ?? {},
        ).some((data) => {
          if (
            returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
            "returnReasonOrderAndItems"
          ) {
            const reason = (allReasons as IReturnReason[])?.find(
              (reason) => reason.id === data.addedReasonId,
            );
            return (
              reason &&
              reason.moreQuestions &&
              reason.moreQuestions.length > 0 &&
              explainAnswer === "requiredAndCustom"
            );
          } else if (
            returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
              "followUpQuestion" &&
            data.addedReasonId
          ) {
            const reason = followupModalData.reasons[data.addedReasonId];
            return (
              (reason &&
                reason.reason.moreQuestions &&
                reason.reason.moreQuestions.length > 0) ||
              followupModalData.moreQuestions.length > 0
            );
          }
          return false;
        });

        // Check if any item's selected exchange with same item or exchange with different item
        const isExchangeItems = Object.values(
          selectedOrder.addedItems ?? {},
        ).some((data) => {
          return (
            data.selectedReturnType === "exchangeWithSameItems" ||
            data.selectedReturnType === "exchangeWithDifferentItems"
          );
        });

        if (hasMoreDetails) {
          nextPage = "moreDetails";
        } else if (arsResponse?.isInformationAvailable) {
          nextPage = "arsInformation";
        } else if (arsResponse?.isOffersAvailable) {
          nextPage = "arsOffers";
        } else if (
          arsResponse?.returnTypes &&
          arsResponse?.returnTypes?.length > 0
        ) {
          nextPage = "arsReturnTypes";
        } else if (arsResponse?.isExchangeAvailable || isExchangeItems) {
          nextPage = "arsExchange";
        } else if (isReturnMethodAvailable) {
          nextPage = "returnMethod";
        }
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: nextPage,
            prevStepForConfig: prevPage,
          },
        });
      } else {
        setShowError(true);
        return;
      }
    }
  };

  const handleBack = () => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "returnType",
        },
      });
    }
  };

  // Get all item IDs from addedItems
  const items = Object.values(selectedOrder?.addedItems ?? {});

  const handleOnChange = (itemId: string, reasonId: string) => {
    if (itemId && reasonId && selectedOrder) {
      setShowError(false);
      let updatedItems = selectedOrder?.addedItems ?? {};
      if (Object.keys(updatedItems).length > 0) {
        if (updatedItems[itemId]) {
          updatedItems[itemId].addedReasonId = reasonId;
        }
      }
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: updatedItems,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className="d-flex align-items-center mb-3 pb-1">
          <img
            src={returnImg}
            alt=""
          />
          <span className={`ps-1 ${styles.heading}`}>Add Reasons</span>
        </div>
        {showError && (
          <span className={`p-1 mb-1 mt-2 ${styles.errorMsg}`}>
            * Please select a return reason
          </span>
        )}
        <div>
          <p className={`${styles.selectText}`}>
            Please tell us why you want to return/exchange these items:
          </p>
          {items.map((item) => {
            const itemData = selectedOrder?.itemsInOrder?.find(
              (value) => value.id + "" === item.itemId + "",
            );

            return (
              <div
                className={`mb-2 ${styles.orderBox}`}
                key={item.itemId}
              >
                <p className={`mb-0 ${styles.orderHeading}`}>
                  {itemData?.name}
                </p>

                <div className={`d-flex mb-3`}>
                  <div className={`${styles.cart}`}>
                    <img
                      src={
                        itemData?.name === "Acme T-Shirt" ? orderImg : orderImg2
                      }
                      alt="order"
                      className={`${styles.orderImg}`}
                    />
                  </div>
                  <div className={`ms-2`}>
                    <span className={`${styles.orderName}`}>
                      {itemData?.unitPrice?.currencyCode}
                      {itemData?.unitPrice?.amount} x {item.quantity}
                    </span>
                    <span className={`${styles.orderDesc}`}>
                      SKU: {itemData?.sku}
                    </span>
                  </div>
                </div>
                {allReasons?.map((reason, idx) => {
                  return (
                    <div
                      className="form-check d-flex align-items-start"
                      key={reason.id}
                    >
                      <input
                        className={`form-check-input me-2 flex-shrink-0 ${
                          styles.radioCheck
                        } ${showError && "border-danger"}`}
                        type="radio"
                        name={`returnType--${item.itemId}`}
                        id={`return type--${item.itemId}--${reason.id}`}
                        checked={
                          item?.addedReasonId === reason.id ||
                          item?.addedReasonId === idx.toString()
                        }
                        onClick={() => {
                          handleOnChange(
                            item.itemId,
                            reason.id ?? idx.toString(),
                          );
                        }}
                      />
                      <label
                        className={`form-check-label ${styles.radioLabel}`}
                        htmlFor={`return type--${item.itemId}--${reason.id}`}
                      >
                        {reason?.value}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default AddReasons;
