import { axiosJSON } from "src/globals/axiosEndPoints";

export interface UpdateSegmentUserParams {
  customerSegmentId: string;
  segmentData: Array<{
    key: string;
    value: string;
  }>;
}

const updateSegmentUserService = async (params: UpdateSegmentUserParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/updateCustomerSegmentationData`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }
};

export default updateSegmentUserService;
