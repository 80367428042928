import { useCallback, useEffect, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useDebounce from "src/hooks/useDebounce";
import {
  IQualificationData,
  ISegmentUserData,
} from "src/services/CustomerSegments/getSegmentUserById.service";
import updateSegmentUserService from "src/services/CustomerSegments/updateSegmentUser.service";
import styles from "./Qualification.module.scss";

const QualificationField = ({
  data,
  segmentUserId,
}: {
  data: IQualificationData;
  segmentUserId: string;
}) => {
  const { key, label, value, isEditable } = data;
  const [saved, setSaved] = useState(value !== null);
  // const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const debouncedValue = useDebounce(newValue, 1000);

  const updateData = useCallback(async () => {
    // Not edited yet
    if (debouncedValue === null || !isEditable) {
      return;
    }

    // Edited but data is incorrect
    if (debouncedValue.trim() === "") {
      setIsError(true);
      return;
    }

    try {
      // setIsSaving(true);
      await updateSegmentUserService({
        customerSegmentId: segmentUserId,
        segmentData: [{ key, value: debouncedValue }],
      });

      setSaved(true);
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: err?.message ?? "Something Unexpected Occured!",
        type: "danger",
      });
    } finally {
      // setIsSaving(false);
    }
  }, [debouncedValue, isEditable, segmentUserId, key]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    <div
      key={key}
      className="d-flex align-items-center mb-1"
    >
      <div>
        {saved ? (
          <div className={`${styles.checkBox}`}>
            <span>
              <i className="fa-solid fa-check"></i>
            </span>
          </div>
        ) : (
          <div className={`${styles.unCheckBox}`}></div>
        )}
      </div>
      <label className={`px-1 ${styles.key}`}>{label}:</label>
      <input
        type="text"
        value={newValue ?? ""}
        onChange={(e) => setNewValue(e.target.value)}
        placeholder="Add"
        disabled={!isEditable}
        className={`p-1 ${styles.value} ${
          isError && newValue?.trim() === "" ? "border-danger" : ""
        }`}
      />
      {/* <span className={`p-1 ${styles.value} ${newValue ? "" : styles.noValue}`}>
        {newValue ? newValue : "Add"}
      </span> */}
    </div>
  );
};

const Qualification = ({
  qualification,
  segmentUserId,
}: {
  qualification: ISegmentUserData["qualification"];
  segmentUserId: string;
}) => {
  if (!qualification) {
    return <></>;
  }

  return (
    <div className={`p-3 ${styles.boxWrapper}`}>
      <h6 className={`${styles.heading}`}>Qualification</h6>
      {qualification.map((data) => {
        return (
          <QualificationField
            data={data}
            segmentUserId={segmentUserId}
          />
        );
      })}
    </div>
  );
};

export default Qualification;
