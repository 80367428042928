import styles from "./PromptMessage.module.scss";
const PromptMessage = () => {
  return (
    <div>
      <div className={`${styles.propmptWrapper}`}>
        <span className={`${styles.warningIcon}`}>
          <i className="fa-solid fa-triangle-exclamation"></i>
        </span>
        <span className={`ps-1 ${styles.promptText}`}>
          Prompts can only be applied to the selected segment of the email. Make
          sure you’ve selected the section you want to edit before giving a
          command.
        </span>
      </div>
    </div>
  );
};

export default PromptMessage;
