import { useMutation } from "@tanstack/react-query";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import uploadCsv, {
  UploadCsvParams,
} from "src/services/CustomerSegments/UploadCSV/uploadCsv.service";

//interface for file upload hander function
export interface FileUploadHandler {
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
}

export const useFileUploader = ({
  setIsFileUploading,
  onSuccess,
}: FileUploadHandler) => {
  // Use react-query's useMutation to handle the service call
  const mutation = useMutation(
    ({
      params,
      activeSegmentType,
    }: {
      params: UploadCsvParams;
      activeSegmentType: string;
    }) =>
      uploadCsv({
        params: params,
        activeSegmentType: activeSegmentType,
      }),
    {
      onMutate: () => {
        // Optionally set some state when the mutation is triggered
        setIsFileUploading(true);
      },
      onSuccess: (uploadRes, params) => {
        // Handle successful upload
        if (params.params.file) {
          setIsFileUploading(false);
        }
        onSuccess();
      },
      onError: () => {
        // Handle errors
        pushTheToast({
          position: "top-right",
          text: "Something went wrong while uploading file",
          type: "danger",
        });
        setIsFileUploading(false);
      },
      onSettled: () => {
        // Reset the uploading state regardless of success or failure
        setIsFileUploading(false);
      },
    },
  );

  const { mutate, isLoading, isError, data, error } = mutation;

  // Call the mutate function to initiate the file upload with params
  const uploadFile = (params: UploadCsvParams, activeSegmentType: string) => {
    mutate({ params: params, activeSegmentType: activeSegmentType });
  };

  return { uploadFile, isLoading, isError, data, error };
};
