import { useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import Select, { GroupBase, MultiValue, StylesConfig } from "react-select";
import AxiosImg from "src/components/AxiosImg";
import IntlTelInput from "src/components/IntlTelInput/IntlTelInput";
import UserAvatar from "src/components/UserAvatar";
import {
  DispatchChannels,
  UserAgent,
} from "src/services/Automation/DispatchRulesConfigModal/getAllAgents.service";
import { DispatchToDetail } from "src/services/Automation/DispatchRulesConfigModal/getDispatchRuleConfigById.service";
import { DispatchRuleTeamData } from "src/services/Automation/DispatchRulesConfigModal/getTeamsData.service";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";
import { useDispatchSectionContext } from "../../../Hooks/useDispatchSectionContext";
import styles from "./DispatchToRule.module.scss";

interface Option {
  value: string;
  label: string;
}

const socialOptions: Option[] = [
  { value: DispatchChannels.Email, label: "Email" },
  { value: DispatchChannels.SMS, label: "SMS" },
  // {
  //   value: DispatchChannels.Whatsapp,
  //   label: "Whatsapp messenger",
  // },
  // { value: DispatchChannels.Facebook, label: "Facebook comments" },
  // { value: DispatchChannels.Instagram, label: "Instagram comments" },
  // { value: DispatchChannels.InstagramDM, label: "Instagram DM" },
];

const AgentSelector = ({
  agentDetail,
  selectedAgents,
  handleAgentSelect,
  isDisabled,
}: {
  agentDetail: UserAgent[];
  selectedAgents: string[];
  handleAgentSelect: (agent: UserAgent, checked: boolean) => void;
  isDisabled: boolean;
}) => {
  const selectedAgent = useMemo(() => {
    return agentDetail[0] ? agentDetail[0] : null;
  }, [agentDetail]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        as="div"
        bsPrefix={`dropdown-toggle cursor-pointer ${styles.dropBtn}`}
        disabled={isDisabled}
      >
        <div className={`d-flex align-items-center`}>
          {selectedAgent?.imageUrl ? (
            <AxiosImg
              url={selectedAgent.imageUrl}
              className={`rounded-circle`}
              style={{ width: "20px", height: "20px" }}
            />
          ) : (
            <UserAvatar
              name={selectedAgent?.name ?? "N A"}
              size={20}
            />
          )}
          <span className={`ps-1 ${styles.userName}`}>
            {selectedAgent?.name ?? "N A"}
          </span>
          <span className={`ps-1 ${styles.arrow}`}>
            <i className="fa-solid fa-sort-down"></i>
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
        <div>
          <div className="">
            <div>
              <h4 className={`mb-1 ${styles.assignText}`}>Assign to</h4>
              {agentDetail.map((agent, index) => (
                <div
                  className={`d-flex align-items-center mb-1 ${styles.userData}`}
                  key={index}
                >
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    id={`team-agent-${agent.name}-${agent.id}`}
                    onChange={(e) => handleAgentSelect(agent, e.target.checked)}
                    checked={selectedAgents.includes(agent.id + "")}
                  />
                  <label htmlFor={`team-agent-${agent.name}-${agent.id}`}>
                    {agent.imageUrl && agent.imageUrl.trim() !== "" ? (
                      <AxiosImg
                        url={agent.imageUrl}
                        className={`rounded-circle`}
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <UserAvatar
                        name={agent.name}
                        size={20}
                      />
                    )}
                    <span className={`${styles.userName}`}>{agent.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const EmailChannel = ({ emailOptions }: { emailOptions: string[] }) => {
  if (emailOptions.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-2 d-flex align-items-center">
      <span className={`${styles.label}`}>Email:</span>
      <div className={`${styles.mailBox} d-flex flex-wrap`}>
        {emailOptions.map((emailId, index) => {
          return (
            <div
              className={`${styles.mailText} px-2 me-1`}
              key={`${emailId}_${index}`}
            >
              {emailId}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PhoneChannel = ({ phoneOptions }: { phoneOptions: Phone[] }) => {
  if (phoneOptions.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-2 d-flex align-items-center">
      <span className={`${styles.label} pe-3 mb-0`}>Phone:</span>
      {phoneOptions.slice(0, 4).map((phone, index) => {
        return (
          <div
            className={`${styles.telInput} me-1`}
            key={index}
          >
            <div className="w-100">
              <IntlTelInput
                phoneNumber={
                  typeof phone === "string" ? phone : phone.phoneNumber
                }
                countryCode={typeof phone === "string" ? "" : phone.countryCode}
                disabled={true}
              >
                {(params) => {
                  return (
                    <small className={`${styles.errorMsg} d-none`}>
                      {params.eventType !== "initial" && params.errorMessage
                        ? params.errorMessage
                        : ""}
                    </small>
                  );
                }}
              </IntlTelInput>
            </div>
          </div>
        );
      })}
      {phoneOptions.length - 4 > 0 && (
        <span className={`${styles.morePhones}`}>
          + {phoneOptions.length - 4} more
        </span>
      )}
    </div>
  );
};

const InstagramChannel = ({
  instagramOptions,
}: {
  instagramOptions: Option[];
}) => {
  return (
    <div className="mb-2 d-flex align-items-center">
      <span className={`${styles.label} pe-3`}>Instagram handle:</span>
      <div>
        <Select
          className={``}
          styles={defaultStyles}
          options={instagramOptions}
          isDisabled={true}
          placeholder="Search Instagram account"
          isSearchable={true}
          isMulti={true}
        />
      </div>
    </div>
  );
};

const FacebookChannel = ({
  facebookOptions,
}: {
  facebookOptions: Option[];
}) => {
  return (
    <div className="mb-2 d-flex align-items-center">
      <span className={`${styles.label} pe-3 `}>Facebook handle:</span>
      <div>
        <Select
          className={``}
          styles={defaultStyles}
          options={facebookOptions}
          isDisabled={true}
          placeholder="Search fb account"
          isSearchable={true}
          isMulti={true}
        />
      </div>
    </div>
  );
};

const DispatchToRule = ({
  dispatchRule,
  index,
  availableChannelIds,
  allAgents,
  allTeams,
  isDisabled,
}: {
  dispatchRule: DispatchToDetail;
  index: number;
  availableChannelIds: string[];
  allAgents: Record<string, UserAgent>;
  allTeams: Record<string, DispatchRuleTeamData>;
  isDisabled: boolean;
}) => {
  const { updateState, state, showErrors, setShowErrors } =
    useDispatchSectionContext();
  const { dispatchToDetails } = state;

  const selectedAgent = useMemo(() => {
    if (dispatchRule.teamDetails) {
      return null;
    }

    const firstAgent = allAgents[(dispatchRule.agentDetails[0]?.id ?? "") + ""];

    if (firstAgent) {
      return firstAgent;
    }

    return null;
  }, [allAgents, dispatchRule.agentDetails, dispatchRule.teamDetails]);

  const selectedChannels: Option[] = useMemo(() => {
    return socialOptions.filter(({ value }) =>
      dispatchRule.channelDetails.some((val) => val.id + "" === value + ""),
    );
  }, [dispatchRule.channelDetails]);

  const availableChannels = useMemo(() => {
    return socialOptions.filter(({ value }) =>
      availableChannelIds.includes(value),
    );
  }, [availableChannelIds]);

  const { emailOptions, phoneOptions, facebookOptions, instagramOptions } =
    useMemo(() => {
      const emailOptions: string[] = [];
      const phoneOptions: Phone[] = [];
      const facebookOptions: Option[] = [];
      const instagramOptions: Option[] = [];

      if (dispatchRule.agentDetails.length !== 0) {
        dispatchRule.agentDetails.forEach(({ id }) => {
          if (allAgents[id]) {
            const { email, phone } = allAgents[id];

            if (email) {
              emailOptions.push(email);
            }

            if (phone) {
              phoneOptions.push(phone);
            }
          }
        });
      }

      return {
        emailOptions,
        phoneOptions,
        facebookOptions,
        instagramOptions,
      };
    }, [allAgents, dispatchRule.agentDetails]);

  const handleAgentSelect = useCallback(
    (agent: UserAgent, checked: boolean) => {
      const dispatchDetails = dispatchToDetails.map((rule, idx) => {
        if (idx === index) {
          const newAgentDetail = { id: agent.id + "", name: agent.name };
          if (!checked) {
            rule.agentDetails = rule.agentDetails.filter(
              (val) => val.id !== agent.id,
            );
          } else if (checked && !rule.agentDetails.includes(newAgentDetail)) {
            rule.agentDetails = [...rule.agentDetails, newAgentDetail];
          }
        }

        return rule;
      });

      updateState({ dispatchToDetails: dispatchDetails });
      setShowErrors(false);
    },
    [dispatchToDetails, index, setShowErrors, updateState],
  );

  const handleDelete = useCallback(() => {
    const dispatchDetails = [
      ...dispatchToDetails.slice(0, index),
      ...dispatchToDetails.slice(index + 1),
    ];

    updateState({ dispatchToDetails: dispatchDetails });
    setShowErrors(false);
  }, [dispatchToDetails, index, setShowErrors, updateState]);

  const handleChannelSelection = useCallback(
    (options: MultiValue<Option>) => {
      const dispatchDetails = dispatchToDetails.map((rule, idx) => {
        if (idx === index) {
          rule.channelDetails = options.map((channelOp) => ({
            id: channelOp.value + "",
            name: channelOp.label,
          }));
        }

        return rule;
      });

      updateState({ dispatchToDetails: dispatchDetails });
      setShowErrors(false);
    },
    [dispatchToDetails, index, setShowErrors, updateState],
  );

  const showEmail = useMemo(
    () =>
      selectedChannels.findIndex(
        (op) => op.value === DispatchChannels.Email,
      ) !== -1,
    [selectedChannels],
  );

  const showPhone = useMemo(
    () =>
      selectedChannels.findIndex(
        (op) =>
          op.value === DispatchChannels.Whatsapp ||
          op.value === DispatchChannels.SMS,
      ) !== -1,
    [selectedChannels],
  );

  const showFacebook = useMemo(
    () =>
      selectedChannels.findIndex(
        (op) => op.value === DispatchChannels.Facebook,
      ) !== -1,
    [selectedChannels],
  );

  const showInstagram = useMemo(
    () =>
      selectedChannels.findIndex(
        (op) =>
          op.value === DispatchChannels.Instagram ||
          op.value === DispatchChannels.InstagramDM,
      ) !== -1,
    [selectedChannels],
  );

  const isSMSAvailable = useMemo(() => {
    return (
      availableChannels.findIndex(
        (val) => val.value === DispatchChannels.SMS,
      ) !== -1
    );
  }, [availableChannels]);

  return (
    <div
      className={`${styles.dispatchTicketBox} mb-3 d-flex justify-content-between`}
    >
      <div className={`${styles.leftSection}`}>
        <div className="mb-2 d-flex align-items-center">
          <span className={`${styles.label} pe-3`}>Team:</span>
          {!dispatchRule.teamDetails ? (
            <div className={`d-flex align-items-center ${styles.dropBtn}`}>
              <div>
                {selectedAgent?.imageUrl &&
                selectedAgent?.imageUrl.trim() !== "" ? (
                  <AxiosImg
                    url={selectedAgent?.imageUrl}
                    className={`rounded-circle`}
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  <UserAvatar
                    name={selectedAgent?.name ?? "N A"}
                    size={20}
                  />
                )}
              </div>
              <span className={`ps-1 ${styles.userName}`}>
                {selectedAgent?.name ?? "N A"}
              </span>
            </div>
          ) : (
            <AgentSelector
              agentDetail={
                allTeams[dispatchRule.teamDetails.id]?.userDetails ?? []
              }
              handleAgentSelect={handleAgentSelect}
              selectedAgents={dispatchRule.agentDetails.map(
                (val) => val.id + "",
              )}
              isDisabled={isDisabled}
            />
          )}
        </div>

        {showErrors &&
          dispatchRule.teamDetails &&
          dispatchRule.agentDetails.length === 0 && (
            <small className={styles.errorMsg}>Please select agents</small>
          )}

        {showEmail && <EmailChannel emailOptions={emailOptions} />}
        {showPhone && <PhoneChannel phoneOptions={phoneOptions} />}
        {showFacebook && <FacebookChannel facebookOptions={facebookOptions} />}
        {showInstagram && (
          <InstagramChannel instagramOptions={instagramOptions} />
        )}

        <div className="mb-2 w-100">
          <span className={`d-block ${styles.label} pe-3 mb-1`}>
            Channels :
          </span>
          <div className="w-100">
            <Select
              className={`${styles.channelDropdown}`}
              styles={defaultStyles}
              options={availableChannels}
              value={selectedChannels}
              placeholder="Search social media"
              onChange={handleChannelSelection}
              isSearchable={true}
              isMulti={true}
              isDisabled={isDisabled}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: null,
              }}
            />
          </div>
          {!isSMSAvailable && (
            <a
              href="/settings/User"
              target="_blank"
            >
              <span className={styles.errorMsg}>
                Dispatch to SMS is only available if a phone number has been
                added to an agent's profile.
              </span>
            </a>
          )}
        </div>

        {showErrors && !selectedChannels.length && (
          <small className={styles.errorMsg}>
            Please add atleast one channel
          </small>
        )}
      </div>
      <div>
        <button
          className={`${styles.deleteDetails}`}
          onClick={handleDelete}
          disabled={isDisabled}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

const defaultStyles: StylesConfig<Option, true, GroupBase<Option>> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: "37px",
    width: "359px",
    "&:hover": {
      borderColor: "#BBBBBB",
    },
    border: state.isFocused ? "0.5px solid #0B68BB" : "1px solid #BBBBBB",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5",
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#707070",
    ":hover": {
      color: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#FFF" : "#000",
    backgroundColor: state.isSelected ? "#007bff" : "#FFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#F5F5F5",
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: "10px",
  }),
};

export default DispatchToRule;
