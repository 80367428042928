import Lottie from "lottie-light-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import clock from "src/assets/images/clock.png";
import liveDot from "src/assets/images/liveDot.json";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IAutomationListItem } from "src/services/Automation/getAllAutomations.service";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import { useAppSelector } from "src/store/store";
import { formatMDYTimeStringDate } from "src/utils/dateLibrary";
import { IAutomationListParams } from "../../AutomationList";
import styles from "./AutomationListItem.module.scss";
import { useDeleteAutomation } from "../../../Automation/Hooks/useAutomationManager";
import deleteIcon from "src/assets/images/delete.png";

const AutomationListItem = ({
  item,
  onDelete,
}: {
  item: IAutomationListItem;
  onDelete: (integrationId: string) => void;
}) => {
  const navigate = useNavigate();
  const { type } = useParams<IAutomationListParams>();

  const { brand, configured, integrationId, published, lastEditedAtGmt } = item;
  const [isPublished, setIsPublished] = useState<boolean>(published);
  const [errorAutomationPublish, setErrorAutomationPublish] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { deleteAutomation, isLoading: isDeleting } =
    useDeleteAutomation(onDelete);

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  const lastEditedTime = useMemo(() => {
    if (lastEditedAtGmt) {
      return formatMDYTimeStringDate(lastEditedAtGmt, userTimezone);
    }
  }, [lastEditedAtGmt, userTimezone]);

  const openSettingsHandler = useCallback(() => {
    navigate(`/automations/${type}/${integrationId}`);
  }, [integrationId, navigate, type]);

  const handleDelete = useCallback(() => {
    deleteAutomation(integrationId);
  }, [deleteAutomation, integrationId]);

  const handleStatusChange = useCallback(async () => {
    if (!configured || !type) {
      return;
    }

    setLoading(true);
    const payload = {
      integrationId,
      publish: !isPublished,
    };
    try {
      await updateAutomationService({
        type: type,
        payload: payload,
      });
      setIsPublished(payload.publish);
      setErrorAutomationPublish(false);
    } catch (err) {
      if (
        typeof err === "object" &&
        err &&
        (err as any).message === "automation_already_exist"
      ) {
        setErrorAutomationPublish(true);
        return;
      }
      pushTheToast({
        position: "top-right",
        type: "danger",
        text:
          typeof err === "string"
            ? err
            : payload.publish
              ? "Could not publish automation"
              : "Could not unpublish automation",
      });
    } finally {
      setLoading(false);
    }
  }, [configured, integrationId, isPublished, type]);

  useEffect(() => {
    if (brand === null) {
      setErrorAutomationPublish(true);
    } else {
      setErrorAutomationPublish(false);
    }
  }, [brand]);

  return (
    <div className={`${styles.bugBox} mb-3`}>
      <div className={`${styles.contentWrapper}`}>
        <div className="d-flex justify-content-between">
          <div>
            <span className={`${styles.title}`}>
              Current automation status :
            </span>
            <span className={`ps-1 ${styles.subText}`}>
              {isPublished ? "Published" : "Unpublished"}
            </span>
          </div>

          <div className="d-flex flex-column align-items-end">
            <div className="d-flex">
              <button
                className={`${styles.plublishBtn}`}
                onClick={handleStatusChange}
                disabled={
                  !configured || loading || isDeleting || brand === null
                }
              >
                <p className="mb-0">
                  <span className="pe-1">
                    <i className="fa-regular fa-eye"></i>
                  </span>
                  <span>{isPublished ? "Unpublish" : "Publish"} </span>
                  {loading && (
                    <Spinner
                      className="mx-2"
                      size="sm"
                      animation="border"
                    />
                  )}
                </p>
              </button>

              {!configured && (
                <div className="ms-3">
                  <ExclamationDiamondSVG />
                  <span className={`${styles.completeSetup} ms-2`}>
                    Complete setup
                  </span>
                </div>
              )}

              <button
                className={`ms-3 ${styles.deleteBtn}`}
                onClick={handleDelete}
                disabled={isDeleting || loading}
              >
                {isDeleting ? (
                  <Spinner
                    className="mx-2"
                    size="sm"
                    animation="border"
                  />
                ) : (
                  <img
                    src={deleteIcon}
                    alt="delete"
                    width={14}
                    height={19}
                  />
                )}
              </button>
            </div>
            {errorAutomationPublish ? (
              <span className={`${styles.errorSpan} mx-2 mt-2`}>
                {brand === null ? (
                  "Please add a brand to publish this automation."
                ) : (
                  <>
                    This kind of automation has already been published for{" "}
                    <strong>{brand.name ?? "brand"}</strong>. Please unpublish
                    the existing version in order to proceed with publishing the
                    new version.
                  </>
                )}
              </span>
            ) : null}
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <span className={`${styles.title}`}>Brand Installed on:</span>
          <div className="mb-0 px-2 d-flex align-items-center">
            <div className="px-1">
              {brand?.imageURL && brand.imageURL.trim() ? (
                <AxiosImg
                  url={brand.imageURL}
                  className={`rounded-circle`}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <UserAvatar
                  name={brand?.name ?? "NA"}
                  size={20}
                />
              )}
            </div>

            <span className={`${styles.liveText}`}>{brand?.name ?? "NA"}</span>

            {isPublished && (
              <div className="d-flex align-items-center">
                <span
                  style={{
                    height: 20,
                    width: 20,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Lottie animationData={liveDot}></Lottie>
                </span>
                <span className={`${styles.subText}`}>Live</span>
              </div>
            )}
          </div>
        </div>

        {lastEditedTime && (
          <div className="d-flex align-items-center">
            <span className={`${styles.title}`}>Last edited:</span>
            <div className={`ps-2 `}>
              <span className="me-1">
                <img
                  src={clock}
                  alt="clock"
                  height={15}
                  width={15}
                />
              </span>
              <span className={`${styles.time}`}>{lastEditedTime}</span>
            </div>
          </div>
        )}
      </div>

      <div
        className={`${styles.goSetting}`}
        onClick={openSettingsHandler}
      >
        <span className={`${styles.goHead}`}>Go to settings</span>
        <span className={`${styles.goArrow}`}>
          <i className="fa-solid fa-angle-right"></i>
        </span>
      </div>
    </div>
  );
};

const ExclamationDiamondSVG = () => {
  return (
    <svg
      id="Component_321_4"
      data-name="Component 321 – 4"
      xmlns="http://www.w3.org/2000/svg"
      width="22.627"
      height="22.627"
      viewBox="0 0 22.627 22.627"
    >
      <g
        id="Group_4775"
        data-name="Group 4775"
        transform="translate(-379.687 -154.686)"
      >
        <rect
          id="Rectangle_2022"
          data-name="Rectangle 2022"
          width="16"
          height="16"
          rx="2"
          transform="translate(379.687 166) rotate(-45)"
          fill="#b90202"
        />
        <rect
          id="Rectangle_2023"
          data-name="Rectangle 2023"
          width="10.188"
          height="9.188"
          transform="translate(384.149 166) rotate(-45)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-exclamation-circle"
          data-name="Icon awesome-exclamation-circle"
          d="M15.426,7.994A7.432,7.432,0,1,1,7.994.563,7.431,7.431,0,0,1,15.426,7.994Zm-7.432,1.5a1.378,1.378,0,1,0,1.378,1.378A1.378,1.378,0,0,0,7.994,9.492ZM6.685,4.538l.222,4.075a.36.36,0,0,0,.359.34H8.722a.36.36,0,0,0,.359-.34L9.3,4.538a.36.36,0,0,0-.359-.379h-1.9A.36.36,0,0,0,6.685,4.538Z"
          transform="translate(383.006 158.233)"
          fill="#b90202"
        />
      </g>
    </svg>
  );
};

export default AutomationListItem;
