import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactSelect, {
  ActionMeta,
  components,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from "react-select";
import {
  items,
  RefundScenario,
} from "src/features/ReturnAutoWorkFlow/children/ConfigModals/store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import styles from "./ItemsAndTagsSelect.module.scss";

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: "338px",
    margin: "8px 0px",
    backgroundColor: "white",
    borderColor: "#707070",
    "&:hover": {
      borderColor: "#707070",
      boxShadow: "unset !important",
    },
  }),
  container: () => ({
    borderRadius: "5px",
    color: "black",
    // fontSize: "10px",
    lineHeight: "16px",
    // fontWeight: "600",
    font: "normal normal 500 10px/16px Poppins",
    padding: "0 17px",
  }),
  menu: () => ({ boxShadow: "none" }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: "black",
    font: "normal normal 500 10px/16px Poppins",
    backgroundColor: state.isFocused ? "#F0F0F0" : null,
    "&:hover": {
      borderRadius: "4px",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0px",
    color: "#707070",
  }),
};

interface Item {
  value: string;
  label: string;
  price: {
    //Changing format as per backend
    amount: string;
    currencyCode: string;
  };
  imgSrc: string;
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div>
          {/* Adding codium pr suggestions */}
          {["Tags", "Items"].includes(props.data.value) ? (
            <label
              className={`${styles.tagsNdItemsLabel} disable`}
              role="none"
            >
              {props.label}
            </label>
          ) : (
            <div
              className={`d-flex ${
                props.data.type === "item"
                  ? "gap-3"
                  : "align-items-center gap-3"
              }`}
            >
              <div>
                <input
                  type="checkbox"
                  checked={props.isSelected}
                  className="form-check-input"
                  onChange={() => null}
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "6px",
                  }}
                />
              </div>

              {props.data.imgSrc && (
                <img
                  alt=""
                  src={props.data.imgSrc}
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    objectFit: "cover",
                    alignSelf: "center",
                    borderRadius: "5px",
                  }}
                />
              )}

              <div className="d-flex flex-column w-100">
                {props.label && (
                  <label className={`${styles.optionCss}`}>{props.label}</label>
                )}
                {props.data.price?.amount && props.data.price?.currencyCode && (
                  <label className={`${styles.price}`}>
                    {props.data.price.amount} {props.data.price.currencyCode}
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
      </components.Option>
    </div>
  );
};

export function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

function ItemsAndTagsSelect(props: {
  id: string | number;
  allOptions: OptionsOrGroups<any, GroupBase<any>>;
  intialSelectedOptions: OptionsOrGroups<any, GroupBase<any>>;
  loadOptions: (
    searchTerm: string,
    loadOptions: any,
    additional: any,
  ) => Promise<{
    options: any;
    hasMore: boolean;
    additional: {
      page: any;
    };
  }>;
  scenario: RefundScenario;
  itemError?: boolean;
  setItemError?: any;
  items: items[];
  tags: string[];
  itemsAndTagAllSelected: boolean;
  isLoading?: boolean;
  onUpdateItems: (items: any[]) => void;
  onUpdateTags: (tags: any[]) => void;
  onUpdateAllItemsAndTagsSelected: (selected: boolean) => void;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<
    OptionsOrGroups<any, GroupBase<any>>
  >(
    props.intialSelectedOptions.filter(
      (option) => option.type === "item" || option.type === "tag",
    ),
  );

  const prevSelectedOptions = usePrevious(selectedOptions);
  const [isOptionsInitial, setIsOptionsInitial] = useState(true);
  const [dropdownMessage, setDropdownMessage] = useState("All items and tags");
  const [isInitial, setIsInitial] = useState(false);

  const allOptions = useMemo(
    () => [...props.allOptions] as OptionsOrGroups<any, GroupBase<any>>,
    [props.allOptions],
  );

  //Added to set items and tags initially
  useEffect(() => {
    setIsInitial(true);
  }, []);
  useEffect(() => {
    if (isOptionsInitial && selectedOptions.length === 0) {
      return;
    }

    // Code For Updating the Redux Store
    if (prevSelectedOptions?.length !== selectedOptions.length) {
      props.onUpdateItems(
        selectedOptions.filter((option) => option.type === "item"),
      );
      props.onUpdateTags(
        selectedOptions.filter((option) => option.type === "tag"),
      );
    }

    // Code For Updating the Dropdown Message
    if (selectedOptions.length === 1 && selectedOptions[0]?.value !== "*") {
      setDropdownMessage(`${selectedOptions[0]?.label}`);
    } else if (
      selectedOptions.length + 2 === allOptions.length &&
      selectedOptions.length !== 0
    ) {
      setDropdownMessage("All items and tags selected");
    } else if (selectedOptions.length > 1) {
      setDropdownMessage(`${selectedOptions.length} items and tags selected`);
    } else {
      setDropdownMessage("All items and tags");
    }

    setIsOptionsInitial(false);
  }, [selectedOptions]);

  //Added to set scenario items and tags initially
  useEffect(() => {
    if (isInitial) {
      const combinedOptions = [
        ...props.items,
        ...props.tags.map((tag) => ({
          label: tag,
          value: tag,
          type: "tag",
        })),
      ];
      setSelectedOptions(combinedOptions);
      setIsInitial(false);
    }
  }, [isInitial, props.items, props.tags]);

  useEffect(() => {
    if (props.itemsAndTagAllSelected) {
      setSelectedOptions(
        allOptions.filter(
          (option) =>
            option.type === "item" ||
            option.type === "tag" ||
            option.value === "*",
        ),
      );
    }
  }, [allOptions, props.intialSelectedOptions, props.itemsAndTagAllSelected]);

  const isMulti = true;

  const onSelectChange = (
    newValues: SingleValue<any> | MultiValue<any>,
    actionMeta: ActionMeta<any>,
  ) => {
    let newSelectedOptions =
      (Array.isArray(newValues) ? [...newValues] : newValues) ?? [];

    let option;

    if (actionMeta.action === "remove-value") {
      option = actionMeta.removedValue;
    } else {
      option = actionMeta.option;
    }

    //If  For All Selected
    if (
      isMulti &&
      (newSelectedOptions as MultiValue<Item>).length &&
      (option as Item).value === "*"
    ) {
      if (actionMeta.action === "select-option") {
        setSelectedOptions(
          allOptions.filter(
            (option) =>
              option.type === "item" ||
              option.type === "tag" ||
              option.value === "*",
          ),
        );
        props.onUpdateAllItemsAndTagsSelected(true);
      } else if (actionMeta.action === "deselect-option") {
        setSelectedOptions([]);
        props.onUpdateAllItemsAndTagsSelected(false);
      }
    } else {
      if (!isMulti) {
        // Single value
        setSelectedOptions((newSelectedOptions && newSelectedOptions) || null);
      } else {
        // Multi value
        const allSelectedOptions = newSelectedOptions.filter(
          (option: any) => option.value !== "*",
        );
        const filteredOptions = allOptions.filter((option) => {
          return (
            option.value !== "*" &&
            option.value !== "Tags" &&
            option.value !== "Items"
          );
        });
        if (allSelectedOptions.length === filteredOptions.length) {
          setSelectedOptions(
            allSelectedOptions.concat({
              label: "Select all items and tags",
              value: "*",
              imgSrc: null,
            }),
          );
          props.onUpdateAllItemsAndTagsSelected(true);
        } else {
          setSelectedOptions(allSelectedOptions);
          props.onUpdateAllItemsAndTagsSelected(false);
        }
      }
    }
  };

  const additional = useRef(null as any);
  const timeoutRef = useRef(null as any);

  const loadOptions = useCallback(
    async (st: string) => {
      const res = await props.loadOptions(st, undefined, additional.current);
      additional.current = res.additional;
      setHasMore(res.hasMore);
      return res.options;
    },
    [props.loadOptions],
  );

  const searchLoadOptions = useCallback(
    (st: string) => {
      setSearchInput(st);
      additional.current = null;
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        loadOptions(st);
      }, 1000);
    },
    [loadOptions],
  );

  useEffect(() => {
    loadOptions("");
  }, [false]);

  return (
    <div
      className={`ms-lg-3 ${styles.thirdSelect}`}
      style={{
        maxWidth: "20rem",
        minWidth: "16rem",
      }}
    >
      <Dropdown onToggle={(nextShow) => setMenuOpen(nextShow)}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          bsPrefix={`d-flex align-items-center justify-content-between ${
            styles.selectDropdown
          }  ${
            props.itemError != undefined && props.itemError === true //Added to show error only after clicked on save
              ? " border border-danger "
              : " "
          } `}
        >
          <span className={`${styles.message}`}> {dropdownMessage} </span>{" "}
          {/* Dec 19 changing dropdown icon as per xd */}
          {menuOpen ? (
            <span className={`ps-2 pe-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-caret-up"></i>
            </span>
          ) : (
            <span className={`ps-2 pe-2 ${styles.downArrow}`}>
              <i className="fa-solid fa-caret-down"></i>
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.menuBox}`}>
          <ReactSelect
            options={allOptions}
            autoFocus
            backspaceRemovesValue={false}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: null,
              Option,
            }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            inputValue={searchInput}
            onInputChange={(inputValue, actionMeta) => {
              if (
                actionMeta.action !== "input-blur" &&
                actionMeta.action !== "menu-close" &&
                actionMeta.action !== "set-value"
              ) {
                searchLoadOptions(inputValue);
              }
            }}
            menuIsOpen={menuOpen}
            isMulti
            onChange={(
              newValue: MultiValue<any>,
              actionMeta: ActionMeta<any>,
            ) => {
              props.setItemError(false); //set show error on change of selected items or tags
              onSelectChange(newValue, actionMeta);
            }}
            placeholder="Search by SKU , Tag or item name ..."
            styles={selectStyles}
            tabSelectsValue={false}
            value={selectedOptions}
            onMenuScrollToBottom={
              hasMore
                ? () => {
                    loadOptions(searchInput);
                  }
                : undefined
            }
            isLoading={props?.isLoading}
          />
        </Dropdown.Menu>
      </Dropdown>
      {/* show error message */}
      {props.itemError != undefined && props.itemError === true && (
        <div className={`${styles.error}`}>
          {"Please select a specfic item or tag"}
        </div>
      )}
    </div>
  );
}

export default ItemsAndTagsSelect;
