import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetTimelineStagesPayload {
  customerId: string | number;
  eventData?: any[];
  start?: number;
  limit?: number;
}
interface StageData {
  attributeId: number;
  stageId: string;
  stageName: string;
  value: number;
}
export interface GetTimelineStagesResponse {
  err: boolean;
  msg: string;
  data: StageData[];
}

export async function getTimeLineEvents({
  payload,
}: {
  payload: GetTimelineStagesPayload;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/timeline/getStages`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data;
}
