import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  CompareTypes,
  FieldDataTypes,
  LogicalOperatorTypes,
} from "./getConditionOptions.service";

export interface BetweenRange<T = string | number | null> {
  // In case of string it may be date-time. Can not be null while in payload.
  start: T;
  end: T;
}

export interface ConditionI {
  conditionId: string | null;
  conditionUUID: string;
  nextConditionUUID: string | null;
  fieldKey: string;
  /**
   * This is not required in payload.
   */
  fieldDataType?: FieldDataTypes;
  /**
   * This is not required in payload.
   * Used to get max limit defined by backend if any
   */
  maxLimit?: number;
  /**
   * This is not required in payload.
   * Used to get min limit defined by backend if any
   */
  minLimit?: number;
  /**
   * This is the initial dropdown selection to see what kind of value needs to go.
   * While being sent with payload is should never be null.
   */
  compareType: CompareTypes | null;
  /**
   * compareKey is given for the `compareType`. Future implementation.
   */
  compareKey?: string;
  /**
   * Format of string - "key(1)::key(2)::key(n)::lastKey" - If n=0 then only "lastKey"
   * If multiple dropdowns levels are there.
   */
  value: Array<string | number> | string | number | BetweenRange | null;
  isSpecificTime?: boolean;
  type: string;
  /**
   * Should not be present in payload. Added just for checking validations.
   */
  valueIndex?: Array<1>;
  /**
   * This is not required in payload. It is only for response of getSegmentById API.
   * That too only for the select or multi select dropdown.
   *
   * Example - {
   *    "key1::key2::key3::lastKey": ["forkey1", "forkey2", "forkey3", "forlastKey"],
   *    "key1::key2::key3::lastKey2": ["forkey1", "forkey2", "forkey3", "forlastKey2"]
   *  }
   */
  valuesOfSelectKeys?: Record<string, Array<string>>;
  // Must be present and not null if next element is there in array.
  logicalOperator: LogicalOperatorTypes | null;
}

export interface Group {
  // Must be present and not null if next element is there in array.
  groupId: string | null;
  groupUUID: string;
  nextGroupUUID: string | null;
  logicalOperator: LogicalOperatorTypes | null;
  conditions: ConditionI[];
}

/**
 * Represents the details of a custom segment.
 */
export interface SegmentDetails {
  segmentId: string;
  segmentType: string;
  segmentName: string;
  segmentDescription: string;
  groupConditions: Group[]; // Need to update it for both people and company.
}

export interface CreateSegmentParams
  extends Omit<SegmentDetails, "segmentId"> {}

async function createSegmentService({
  segmentType,
  ...params
}: CreateSegmentParams) {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/create`,
    params,
  );

  if (res.statusCode === "duplicateSegmentName") {
    throw new Error("Given segment name already exists.");
  }
  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: SegmentDetails = {
    ...res.data,
    segmentType,
  };
  return ret;
}

export default createSegmentService;
