import { useCallback, useMemo, useState } from "react";
import Select, { components, GroupBase, StylesConfig } from "react-select";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useFetchConditionOptions from "src/routes/CustomerSegments/children/UpdateSegmentDetails/hooks/useFetchConditionOptions";
import addNewAttributeService, {
  AddNewAttributeParams,
} from "src/services/CustomerSegments/addNewAttribute.service";
import { v4 as uuid } from "uuid";
import TimelineAttributeForm from "../TimelineAttributeForm/TimelineAttributeForm";
import styles from "./AddNewAttribute.module.scss";

interface Option {
  label: string;
  value: AddNewAttributeParams["type"];
}

const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#EFF4FB",
    borderRadius: 1,
    border: "1px solid #0B68BB",
    borderTop: "none",
    marginTop: "1px",
    minHeight: "min-content",
    overflow: "auto",
  }),
  control: (provided, state) => ({
    ...provided,
    // height: 30,
    minHeight: 27,
    borderRadius: 1,
    boxShadow: "none",
    border: "0.5px solid #0B68BB",
    "&:hover": {
      border: "0.5px solid #0B68BB",
    },
    backgroundColor: "#EFF4FB",
    font: "normal normal normal 12px/18px Poppins",
    cursor: "pointer",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "134px",
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    zIndex: 1055,
  }),
  option: (provided) => ({
    ...provided,
    width: "134px",
    // height: "17px",
    background: "#EFF4FB 0% 0 % no - repeat padding- box",
    borderRadius: "3px",
    cursor: "pointer",
    font: "normal normal normal 12px / 18px Poppins",
    backgroundColor: "#EFF4FB",
    "&:hover": {
      backgroundColor: "#FFF5F6",
      color: "#000000",
    },
    color: "#000000",
    padding: 2,
    paddingLeft: 10,
  }),
  // input: (provided) => ({
  //   ...provided,
  //   caretColor: "transparent",
  // }),
  // placeholder: (provided) => ({
  //   ...provided,
  //   color: "#0B68BB",
  //   font: "normal normal normal 12px/18px Poppins",
  //   // paddingBottom: 22,
  // }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px 8px", // Adds padding inside the container for better spacing
    cursor: "pointer", // Changes cursor to pointer for better UX
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "#0B68BB",
    fontSize: "16px",
    font: "normal normal normal 12px / 18px Poppins",
    letterSpacing: "0.4px",
    textAlign: "left",
    // paddingBottom: "20px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 1055,
  }),
};

const options: Option[] = [
  { value: "text", label: "Text" },
  { value: "timeline", label: "Timeline" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
  { value: "list", label: "List" },
  { value: "decimal", label: "Decimal number" },
  { value: "boolean", label: "True and False" },
];

const AddNewAttribute = ({
  segmentType,
  segmentId,
  onHide,
  showNewMessageModal,
}: {
  segmentType: string;
  segmentId?: string;
  onHide: () => void;
  showNewMessageModal?: () => void;
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState<Option | null>(options[0]);
  const [description, setDescription] = useState<string>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [forms, setForms] = useState([
    {
      name: "",
      value: 0,
      id: uuid(),
      notSureOfDuration: true,
      isDuplicate: false,
    },
  ]);
  const [isError, setIsError] = useState(false);
  const { refetch } = useFetchConditionOptions(segmentType, segmentId);

  const addForm = useCallback(() => {
    setForms((prev) => [
      ...prev,
      {
        name: "",
        value: 0,
        id: uuid(),
        notSureOfDuration: true,
        isDuplicate: false,
      },
    ]);
  }, []);

  const handleFormChange = useCallback(
    (
      index: number,
      name: string,
      value: number,
      notSureOfDuration: boolean,
    ) => {
      setForms((prev) => {
        const updatedForms = prev.map((form, i) => {
          const isDuplicate = prev.some(
            (otherForm, otherIndex) =>
              otherIndex !== i && otherForm.name.trim() === name.trim(),
          );
          return i === index
            ? { ...form, name, value, notSureOfDuration, isDuplicate }
            : {
                ...form,
                isDuplicate: prev.some(
                  (otherForm, otherIndex) =>
                    otherIndex !== i &&
                    otherForm.name.trim() === form.name.trim(),
                ),
              };
        });
        return updatedForms;
      });
    },
    [],
  );

  const handleFormDelete = useCallback((index: number) => {
    setForms((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const validateInputs = useCallback(() => {
    if (name.trim() === "" || type === null) {
      return false;
    }

    if (
      type.value === "timeline" &&
      forms.some((form) => form.name.trim() === "")
    ) {
      return false;
    }

    if (type.value === "timeline" && forms.some((form) => form.isDuplicate)) {
      return false;
    }

    return true;
  }, [name, type, forms]);

  const onSave = useCallback(async () => {
    if (!validateInputs() || type === null) {
      setIsError(true);
      return;
    }

    try {
      setShowLoader(true);
      await addNewAttributeService({
        segmentType,
        params: {
          name,
          type: type.value,
          description,
          timelines:
            type.value === "timeline"
              ? forms.map((val) => ({ ...val, isDuplicate: undefined }))
              : undefined,
        },
      });

      pushTheToast({
        position: "top-right",
        text: "Successfully Created!",
        type: "success",
      });

      refetch({
        refetchPage: (_, index, allPages) => index === allPages.length - 1,
      });
      if (showNewMessageModal) {
        showNewMessageModal();
      }

      onHide();
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: err?.message ?? "Something Unexpected Occured!",
        type: "danger",
      });
    } finally {
      setShowLoader(false);
    }
  }, [
    description,
    forms,
    name,
    onHide,
    refetch,
    segmentType,
    type,
    validateInputs,
  ]);

  const availableOptions = useMemo(() => {
    return options.filter((option) => option.value !== type?.value);
  }, [type?.value]);

  if (showLoader) {
    return (
      <div className={`py-5 ${styles.loaderDiv}`}>
        <h2 className={`${styles.loaderText}`}>Creating please wait...</h2>
        <Loader />
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} position-relative`}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className={`mb-0 ${styles.heading} mb-0`}>Create new attribute</h6>
        <span
          className={`${styles.close} cursor-pointer`}
          onClick={() => {
            if (showNewMessageModal) {
              showNewMessageModal();
            }
            onHide();
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div>
        <div className="mb-2 pe-4">
          <label
            className={`mb-1 ${styles.subHeading}`}
            htmlFor="attributeName"
          >
            Name
          </label>
          <div className={`${styles.selectContainer} d-flex`}>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setIsError(false);
              }}
              id="attributeName"
              placeholder="Revenue"
              className={`${styles.inputField} ${
                isError && name.trim() === "" ? "border-danger" : ""
              }`}
            />
            <div
              className={`${styles.selectField}  ${
                isError && type === null ? "border-danger" : ""
              }`}
            >
              <Select
                value={options.find((option) => type?.value === option.value)}
                options={availableOptions}
                styles={customStyles}
                onChange={(val) => {
                  setType(val);
                  setIsError(false);
                }}
                placeholder="Text"
                onMenuOpen={() => setIsMenuOpen(true)} // Set menu open state
                onMenuClose={() => setIsMenuOpen(false)} // Set menu close state
                isSearchable={false}
                components={{
                  DropdownIndicator: (props) => (
                    <components.DropdownIndicator {...props}>
                      <span className={styles.customDropDown}>
                        <i
                          className={`fa-solid fa-chevron-${
                            isMenuOpen ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </components.DropdownIndicator>
                  ),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className={styles.para}>
            Along with the attribute name select the format type from right side
            menu
          </div>
        </div>
        <div className="mb-2 pe-4">
          <label
            className={`mb-1 ${styles.subHeading}`}
            htmlFor="attributeDescription"
          >{`Description(optional)`}</label>
          <input
            className={`w-100 ${styles.revenuInput}`}
            id="attributeDescription"
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setIsError(false);
            }}
            placeholder="Revenue generated by user"
          />
        </div>

        <div className={`${styles.contentWrapper}`}>
          {type?.value === "timeline" &&
            forms.map((form, index) => (
              <TimelineAttributeForm
                key={index}
                index={index}
                onChange={handleFormChange}
                length={forms.length}
                form={form}
                onDelete={handleFormDelete}
                error={isError}
                isDuplicate={form.isDuplicate}
              />
            ))}
        </div>
      </div>

      <div className="d-flex">
        {type?.value === "timeline" && (
          <div
            className="d-flex align-items-center mt-3 cursor-pointer"
            onClick={addForm}
          >
            <div className={`${styles.addBox}`}>
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>
            </div>
            <span className={`ps-2 ${styles.addMoreText}`}>Add More</span>
          </div>
        )}
      </div>

      <div
        className={`d-flex justify-content-between align-items-center ${styles.btnWrapper}`}
      >
        <button
          className={`${styles.cancelBtn}`}
          onClick={() => {
            if (showNewMessageModal) {
              showNewMessageModal();
            }
            onHide();
          }}
        >
          Cancel
        </button>
        <button
          className={`${styles.saveBtn}`}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddNewAttribute;
