import { useCallback } from "react";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import {
  ICheckBoxQuestion,
  Question,
  QuestionType,
} from "src/services/Automation/getAutomationById.service";
import styles from "./CheckBoxQuestion.module.scss";
import { useParams } from "react-router-dom";
import {
  EAutomationType,
  IAutomationListParams,
} from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface QuestionsProps {
  questionData: ICheckBoxQuestion;
  stepId: string;
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const isCheckBoxQuestion = (
  question: Question,
): question is ICheckBoxQuestion => {
  return question.questionType === QuestionType.Checkbox;
};

const CheckQuestion = ({
  questionData,
  stepId,
  setShowErrorModal,
}: QuestionsProps) => {
  const { updateQuestions, saveQuestionsData, formData } =
    useAutomationContext();
  const { type } = useParams<IAutomationListParams>();

  const onChangeHandler = useCallback(
    (optionKey: string) => {
      if (isCheckBoxQuestion(questionData)) {
        if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
          if (setShowErrorModal) setShowErrorModal(true);
          return;
        }
        const updatedQuestionValue = (
          questionData.questionValue ?? []
        ).includes(optionKey)
          ? (questionData.questionValue ?? []).filter(
              (key: string) => key !== optionKey,
            )
          : [...(questionData.questionValue ?? []), optionKey];
        // 06 September 24 Adding toast message when resulting value array length is 0
        if (
          type === EAutomationType.TICKET_ROUTING &&
          updatedQuestionValue.length == 0 &&
          (questionData.questionValue ?? []).includes(optionKey)
        ) {
          pushTheToast({
            position: "top-right",
            text: "Atleast one option needs to be selected",
            type: "warning",
          });
          return;
        }
        updateQuestions({
          ...questionData,
          questionValue: updatedQuestionValue,
          isAnswered: updatedQuestionValue.length > 0,
        });

        saveQuestionsData({
          stepId,
          stepQuestions: {
            [questionData.questionKey]: updatedQuestionValue,
          },
        });
      }
    },
    [questionData, saveQuestionsData, stepId, updateQuestions],
  );

  return (
    <div>
      {questionData.listOfOptions &&
        questionData.listOfOptions.map(
          ({ optionKey, optionLabel, isDisabled, isHidden }, index) => {
            if (isHidden) {
              return null;
            }

            return (
              <div
                className="form-check"
                key={index}
              >
                <input
                  className={`form-check-input ${styles.checkBox} ${
                    type == EAutomationType.TICKET_ROUTING
                      ? styles.checkBoxSmall
                      : ""
                  }`}
                  type="checkbox"
                  id={`${optionKey}_${index}`}
                  disabled={
                    isDisabled ||
                    (questionData.isBrandRequired && formData.brand === null)
                  }
                  checked={(questionData.questionValue ?? []).includes(
                    optionKey,
                  )}
                  onChange={() => onChangeHandler(optionKey)}
                />
                <label
                  className={`form-check-label ${styles.inputCheck}`}
                  htmlFor={`${optionKey}_${index}`}
                >
                  {optionLabel}
                </label>
              </div>
            );
          },
        )}
    </div>
  );
};

export default CheckQuestion;
