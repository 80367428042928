import { useCallback, useId, useMemo } from "react";
import ReactSelect, { SingleValue } from "react-select";
import { AddressQuestionValue } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./Address.module.scss";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { getCountryListV2 } from "src/store/slices/globals/globals.slice";

/**
 * Component for displaying error text based on the specific field.
 *
 * @param keyName - The field name from AddressQuestionValue which is used to determine the error message.
 * @returns JSX element for the error message.
 */
function ErrorText({ keyName }: { keyName: keyof AddressQuestionValue }) {
  return (
    <div className={`${styles.errorText}`}>
      {keyName === "addressLineOne"
        ? "Please enter your street address"
        : keyName === "addressLineTwo"
          ? ""
          : keyName === "city"
            ? "Please enter your city"
            : keyName === "country"
              ? "Please select your country"
              : keyName === "phoneNumber"
                ? "Please enter the valid number"
                : keyName === "pin"
                  ? "Please enter a valid zip or postal code"
                  : keyName === "state"
                    ? "Please enter your state"
                    : ""}
    </div>
  );
}

/**
 * Input field component for entering various parts of the address.
 *
 * @param label - The label for the input field.
 * @param placeholder - Optional placeholder for the input field.
 * @param value - Current value of the input field.
 * @param keyName - The field name from AddressQuestionValue representing the input field.
 * @param showErrors - Boolean flag to determine if errors should be shown.
 * @param onChange - Callback function triggered when the input field value changes.
 * @returns JSX element for the input field.
 */
function AddressItem({
  label,
  placeholder,
  value,
  keyName,
  showErrors,
  onChange,
}: {
  label: string;
  placeholder?: string;
  value: string;
  keyName: keyof AddressQuestionValue;
  showErrors: boolean;
  onChange: (value: string, keyName: keyof AddressQuestionValue) => void;
}) {
  const labelId = useId();

  const isError = useMemo(() => {
    return showErrors && !value.trim() && keyName !== "addressLineTwo";
  }, [showErrors, value, keyName]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value, keyName);
    },
    [onChange, keyName],
  );

  return (
    <div className={`${styles.addressItem} me-3 mb-2`}>
      <label
        className={`${styles.addressItemLabel}`}
        htmlFor={labelId}
      >
        {label}
      </label>
      <input
        className={`px-2 py-1 ${styles.addressItemInput} ${
          isError ? styles.error : ""
        }`}
        type="text"
        placeholder={placeholder ? placeholder : label}
        id={labelId}
        value={value}
        onChange={handleChange}
      />
      {isError && <ErrorText keyName={keyName} />}
    </div>
  );
}

/**
 * Interface for the option value used in the country select dropdown.
 */
interface OptionValue {
  label: string;
  value: string;
}

/**
 * Dropdown select component for choosing the country in the address form.
 *
 * @param label - The label for the select field.
 * @param placeholder - Optional placeholder for the select field.
 * @param value - Current value of the select field, representing the country.
 * @param keyName - The field name from AddressQuestionValue representing the select field.
 * @param showErrors - Boolean flag to determine if errors should be shown.
 * @param onChange - Callback function triggered when the select field value changes.
 * @returns JSX element for the select field.
 */
function AddressSelectItem({
  label,
  placeholder,
  value,
  keyName,
  showErrors,
  onChange,
}: {
  label: string;
  placeholder?: string;
  value: AddressQuestionValue["country"] | null;
  keyName: keyof AddressQuestionValue;
  showErrors: boolean;
  onChange: (
    value: AddressQuestionValue["country"],
    keyName: keyof AddressQuestionValue,
  ) => void;
}) {
  const reduxDispatch = useAppDispatch();
  const countryList = useAppSelector((state) => state.globals.countryList);

  const options: Array<OptionValue> = useMemo(() => {
    return (
      countryList.data?.map((v) => ({ value: v.code, label: v.name })) ?? []
    );
  }, [countryList]);

  const selectedvalue: OptionValue | null = useMemo(() => {
    return (
      options.find((v) => v.value === value) ??
      (value ? { label: value, value } : null)
    );
  }, [options, value]);

  const isError = useMemo(() => {
    return showErrors && !value?.trim() && keyName !== "addressLineTwo";
  }, [showErrors, value, keyName]);

  // Fetch the country list if it hasn't been loaded yet.
  useMemo(() => {
    if (options.length === 0) {
      reduxDispatch(getCountryListV2());
    }
  }, [false]);

  const handleChange: (newValue: SingleValue<OptionValue>) => void =
    useCallback(
      (value) => {
        if (value) {
          onChange(value.value, keyName);
        }
      },
      [onChange, keyName],
    );

  return (
    <div className={`${styles.addressItem} me-3 mb-2`}>
      <label className={`${styles.addressItemLabel}`}>{label}</label>
      <ReactSelect
        className={`${styles.addressItemInput} ${isError ? styles.error : ""}`}
        options={options}
        placeholder={placeholder ? placeholder : label}
        value={selectedvalue}
        isMulti={false}
        isClearable={false}
        onChange={handleChange}
        styles={{
          valueContainer: (v) => ({
            ...v,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          dropdownIndicator: (v) => ({
            ...v,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          control: (v) => ({ ...v, minHeight: "unset", borderWidth: "0px" }),
        }}
      />
      {isError && <ErrorText keyName={keyName} />}
    </div>
  );
}

/**
 * Main component for rendering the address form.
 *
 * @param value - The current address value object.
 * @param onChange - Callback function triggered when any part of the address changes.
 * @param showErrors - Boolean flag to determine if errors should be shown for empty fields.
 * @param addressHeadLabel - Optional label for the header of the address form. Defaults to "Add address".
 * @returns JSX element for the full address form.
 */
function Address({
  value,
  onChange,
  showErrors,
  addressHeadLabel = "Add address",
}: {
  value: AddressQuestionValue | null;
  onChange: (val: AddressQuestionValue) => void;
  showErrors: boolean;
  addressHeadLabel?: string;
}) {
  // Handle changes in any of the address fields.
  const handleAddresChange = useCallback(
    (v: string, keyName: keyof AddressQuestionValue) => {
      onChange({
        addressLineOne: value?.addressLineOne ?? "",
        addressLineTwo: value?.addressLineTwo ?? "",
        phoneNumber: value?.phoneNumber ?? "",
        city: value?.city ?? "",
        state: value?.state ?? "",
        country: value?.country ?? "",
        pin: value?.pin ?? "",
        [keyName]: v,
      });
    },
    [value, onChange],
  );

  return (
    <div className={`pt-2 pb-3 px-2 ${styles.addressWrap}`}>
      <div className={`${styles.addressHead}`}>{addressHeadLabel}</div>
      <div className="d-flex flex-wrap">
        <AddressItem
          label={"Address line 1"}
          value={value?.addressLineOne ?? ""}
          keyName="addressLineOne"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressItem
          label={"Address line 2"}
          value={value?.addressLineTwo ?? ""}
          keyName="addressLineTwo"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressItem
          label={"Phone number"}
          value={value?.phoneNumber ?? ""}
          keyName="phoneNumber"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressItem
          label={"City"}
          value={value?.city ?? ""}
          keyName="city"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressItem
          label={"State"}
          value={value?.state ?? ""}
          keyName="state"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressSelectItem
          label={"Country"}
          value={value?.country ?? null}
          placeholder="Select Country"
          keyName="country"
          showErrors={showErrors}
          onChange={handleAddresChange}
        />
        <AddressItem
          label={"Zip/Postal code"}
          value={value?.pin ?? ""}
          placeholder="Zip code"
          showErrors={showErrors}
          keyName="pin"
          onChange={handleAddresChange}
        />
      </div>
    </div>
  );
}

export default Address;
