import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  StepQuestion,
  WebhookQuestionDetails,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import VerificationModals, {
  CopyText,
} from "../VerificationModals/VerificationModals";
import MoveToNext from "../../MoveToNext/MoveToNext";
import { ShowVerifiedOrPending } from "../EmailForwarding/EmailForwarding";
import WebHookStepsVideoModal from "./children/WebHookStepsVideoModal/WebHookStepsVideoModal";
import styles from "../EmailForwarding/EmailForwarding.module.scss";
import playIcon from "src/assets/images/play.png";
import VerifyProcessModal from "./children/VerifyProcessModal/VerifyProcessModal";
import stepDetailsStyles from "../../../../StepDetails/StepDetails.module.scss";

const StepsVideo = ({ stepsVideoURL }: { stepsVideoURL: string }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className="d-inline-flex align-items-center pb-2 cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <img
          src={playIcon}
          alt="video"
          width={12}
        />
        <span className={`ps-1 ${styles.clickText}`}>
          Click here to view the tutorial
        </span>
      </div>
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <WebHookStepsVideoModal url={stepsVideoURL} />
      </Modal>
    </>
  );
};

const WebhookSteps = ({
  stepsVideoURL,
  webhook,
}: {
  stepsVideoURL?: null | string;
  webhook: string;
}) => {
  const [showVerificationSteps, setShowVerificationSteps] = useState(false);
  const toggleVerificationSteps = () => {
    setShowVerificationSteps(!showVerificationSteps);
  };
  const [activeTab, setActiveTab] = useState<number>(0); // State to keep track of active tab
  // Function to handle tab click
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="">
      <div className={`${styles.tabsContainer}`}>
        <div className={`${styles.tabs}`}>
          <div
            className={`px-4 py-2 ${styles.tab} ${
              activeTab === 0 ? styles.active : ""
            }`}
            onClick={() => handleTabClick(0)}
          >
            Shopify
          </div>
          <div
            className={`px-4 py-2 ${styles.tab} ${
              activeTab === 1 ? styles.active : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Magento
          </div>
          <div
            className={`px-4 py-2 ${styles.tab} ${
              activeTab === 2 ? styles.active : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            WooCommerce
          </div>
        </div>
        <div className={`px-3 ${styles.tabContent}`}>
          {activeTab === 0 && (
            <div>
              <div
                className={`cursor-pointer d-flex justify-content-between py-2`}
                onClick={toggleVerificationSteps}
                id="toggleSetup"
              >
                <span className={`pe-2 ${styles.setupSpan}`}>
                  How to set up webhooks for your Shopify
                </span>
                <span className={`cursor-pointer ${styles.setupSpan}`}>
                  <i
                    className={`fa-solid fa-chevron-${
                      showVerificationSteps ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </div>
              {showVerificationSteps ? (
                <div>
                  <div className={`py-2`}>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 1:
                      </span>
                      <span className={` mb-1  ${styles.msgDiv}`}>
                        <p className={`mb-0 ${styles.stepHead}`}>
                          {`Access your platform's webhook settings`}
                        </p>
                        <span className={`${styles.stepSpan}`}>
                          {`Go to your platform’s admin panel (e.g., Shopify). Navigate to the webhooks section under settings.`}
                        </span>
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 2:
                      </span>
                      <span className={` mb-1  ${styles.msgDiv}`}>
                        <p className={`mb-0 ${styles.stepHead}`}>
                          {`Create a new webhook`}
                        </p>
                        <span className={`${styles.stepSpan} `}>
                          {`Click on "Create Webhook" or "Add Webhook". Select the event types for order confirmation, order fulfillment, and order delivery.`}
                        </span>
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 3:
                      </span>
                      <span className={` mb-1  ${styles.msgDiv}`}>
                        <p className={`mb-0 ${styles.stepHead}`}>
                          {`Enter the webhook URL`}
                        </p>
                        <span className={`${styles.stepSpan} `}>
                          {`Copy and paste the following webhook URL into the designated field.`}
                        </span>
                        <div
                          className={`ps-2 mt-1 w-100 ${styles.webhookInput}`}
                        >
                          <div
                            className={`text-truncate me-auto ${styles.url}`}
                          >
                            {webhook}
                          </div>
                          <span
                            className={`ms-auto me-0 ${styles.copyBtn}`}
                            onClick={(e) => {
                              window.navigator.clipboard.writeText(webhook);
                              const span = e.currentTarget;
                              if (span) {
                                span.innerText = "Copied!";
                                setTimeout(() => {
                                  span.innerText = "Copy";
                                }, 2000);
                              }
                            }}
                          >
                            Copy
                          </span>
                        </div>
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 4:
                      </span>
                      <span className={` mb-1  ${styles.msgDiv}`}>
                        <p className={`mb-0 ${styles.stepHead}`}>
                          {`Save the webhook configuration`}
                        </p>
                        <span className={`${styles.stepSpan} `}>
                          {`Save the webhook settings to activate real-time updates.`}
                        </span>
                      </span>
                    </div>
                    <div className={`d-flex`}>
                      <span
                        className={`mb-1 ${styles.stepSpan} ${styles.stepDiv}`}
                      >
                        Step 5:
                      </span>
                      <span className={` mb-1  ${styles.msgDiv}`}>
                        <p className={`mb-0 ${styles.stepHead}`}>
                          {`Verify webhook setup`}
                        </p>
                        <div>
                          <span className={`d-block ${styles.stepSpan} `}>
                            {`After setting up the webhook, you'll need to verify that it's correctly configured by completing two steps:`}
                          </span>
                          <span className={`d-block ${styles.stepSpan} `}>
                            {`i) Add a new order: Create a new order in your platform (you can create a test order if necessary). Once the order is added, Saufter will verify if the order confirmation event has been triggered.`}
                          </span>
                          <span className={`d-block ${styles.stepSpan} `}>
                            {`ii) Mark the order as fulfilled: Mark the newly created order as fulfilled in your platform. Saufter will verify if the order fulfillment event has been triggered.`}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                  {stepsVideoURL?.trim() ? (
                    <StepsVideo stepsVideoURL={stepsVideoURL} />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <div className={`py-1 ${styles.tabContent}`}>
                <span className={`${styles.comingText}`}>Coming soon</span>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <div className={`py-1 ${styles.tabContent}`}>
                <span className={`${styles.comingText}`}>Coming soon</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Main component for managing email forwarding steps.
 * @param stepId - The ID of the current step.
 * @param questionId - The ID of the current question.
 *
 * @returns A JSX element with the complete email forwarding UI.
 */
function WebhookVerify({
  stepId,
  questionId,
  updateReturnPreview,
}: {
  stepId: string;
  questionId: string;
  updateReturnPreview: ({
    stepQuestion,
  }: {
    stepQuestion: StepQuestion | null;
  }) => void;
}) {
  const {
    dispatch,
    savingStepAndQuestionData,
    questionNumber,
    stepQuestion,
    showErrors,
    saveStepAndQuestionData,
  } = useSaveStepsAndQuestions({
    stepId,
    questionId,
  });

  const webhookValue = useMemo(() => {
    return stepQuestion.specificQuestionValue as WebhookQuestionDetails | null;
  }, [stepQuestion]);

  const [showModal, setShowModal] = useState(false);
  const [showInitiate, setShowInitiate] = useState(true);

  const showVerifiyModal: null | "initiate" | "loading" | "error" | "done" =
    useMemo(() => {
      if (savingStepAndQuestionData === "question") {
        return "loading";
      } else if (webhookValue?.errors) {
        return "error";
      } else if (webhookValue?.orderStatus) {
        return "initiate";
      } else if (webhookValue?.verified) {
        return "done";
      } else {
        return null;
      }
    }, [savingStepAndQuestionData, webhookValue]);

  const verifyWebhook = useCallback(() => {
    setShowInitiate(false);
    saveStepAndQuestionData();
    updateReturnPreview({
      stepQuestion: { ...stepQuestion },
    });
  }, [saveStepAndQuestionData]);

  const initiateForwarding = useCallback(() => {
    setShowModal(true);
    setShowInitiate(true);
  }, []);

  const setOrderInfoModal = useCallback(() => {
    dispatch("setState", {
      activeStaticModal: "emailForwardingOrderInfo",
    });
  }, [dispatch]);

  return (
    <div
      className={`pt-3 mb-2 ${styles.contentWrapper} ${
        stepDetailsStyles.query
      } ${webhookValue?.verified ? stepDetailsStyles.activeLine : ""} ${
        showErrors && !webhookValue?.verified ? stepDetailsStyles.errorLine : ""
      }`}
    >
      <div className="px-3">
        <p className={`mb-1 ${styles.queHead}`}>
          Q{questionNumber}. {stepQuestion.questionTitle}
        </p>
        <p className={`mb-1 ${styles.queSubHead}`}>
          {`Paste this URL into your platform’s webhook settings to enable real-time order updates.`}
        </p>
        {webhookValue?.webhook ? (
          <CopyText
            copyButtonNormalText="Copy URL"
            textToCopy={webhookValue.webhook}
            truncate={true}
          />
        ) : (
          ""
        )}
        <div className="mt-3">
          <button
            className={`px-2 ${styles.verifyBtn}`}
            onClick={initiateForwarding}
            disabled={webhookValue?.verified ? true : false}
          >
            Verify webhook
          </button>
          <ShowVerifiedOrPending
            verified={webhookValue?.verified ? true : false}
          />
          {webhookValue?.verified ? (
            <button
              className={`px-2 ${styles.viewOrderBtn}`}
              onClick={setOrderInfoModal}
            >
              View order information received
            </button>
          ) : (
            ""
          )}
          {webhookValue?.verified && stepQuestion.showMoveToNext ? (
            <div className="ms-0 my-2">
              <MoveToNext
                stepId={stepId}
                questionId={questionId}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <WebhookSteps
        webhook={webhookValue?.webhook ?? ""}
        stepsVideoURL={webhookValue?.stepsVideoURL}
      />
      <Modal
        show={showModal}
        onHide={
          showVerifiyModal === "loading"
            ? undefined
            : () => {
                setShowModal(false);
              }
        }
        contentClassName={`${styles.modalContent}`}
        dialogClassName={`${styles.dialogClass}`}
        backdropClassName={`${styles.backDropClass}`}
        id="mailModal"
        centered
      >
        {webhookValue ? (
          <>
            {showInitiate || showVerifiyModal === "initiate" ? (
              <VerifyProcessModal
                orderStatus={webhookValue?.orderStatus}
                back={() => {
                  setShowModal(false);
                }}
                verify={verifyWebhook}
              />
            ) : showVerifiyModal === "error" && webhookValue.errors ? (
              <VerificationModals.Error
                errors={webhookValue.errors}
                retry={verifyWebhook}
                back={() => {
                  setShowModal(false);
                }}
              />
            ) : showVerifiyModal === "loading" ? (
              <VerificationModals.Loading subText="We are checking to see if we have received an order related email from your platform." />
            ) : showVerifiyModal === "done" ? (
              <VerificationModals.Done
                heading="Webhook setup complete"
                subHeading={`Your webhook setup is complete. Saufter will now automatically receive all order updates in real-time, ensuring a smooth and efficient partial return process. Proceed with setting up your partial return automation.`}
                done={() => {
                  setShowModal(false);
                }}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}

export default WebhookVerify;
