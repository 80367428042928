/**
 * This file contains the custom hook for managing article creation and publishing.
 *
 * @author Yash Aditya
 * @author Yuvaraj
 * @author Anubhav Jain
 */

import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import {
  addArticle,
  addArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/addArticle";
import {
  deleteArticle,
  deleteArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/deleteArticle";
import { getKeywordsAndVolums, KeywordData } from "src/services/LiveChat/Settings/knowledgeBased/article/getKeywordsAndVolums";
import {
  updateArticle,
  updateArticleParams,
} from "src/services/LiveChat/Settings/knowledgeBased/article/updateArticle";
import { getArticleCategory } from "src/services/LiveChat/Settings/knowledgeBased/articleCategory/getArticleCategory";
import {
  fetchAllCategoryData,
  resetArticleCategories,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/articleCategory/articleCategory.slice";
import {
  fetchArticleSetting,
  resetArticleSetting,
} from "src/store/slices/liveChatSetting/chatWidgetSetting/articleSetting/articleSetting.slice";
import { fetchAllArticleCategories } from "src/store/slices/liveChatSetting/chatWidgetSetting/knowledgeBased/knowledgeBasedSetting.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";

/**
 * This custom hook is used for creating and publishing articles
 */
function useCreatePublish(articleId: number | string) {
  // Constants and States
  const { integrationId } = useParams();
  const pageIntegrationId = integrationId
    ? decodeURIComponent(integrationId)
    : "";

  const { articleData, articleSettingAjaxStatus } = useAppSelector(
    (state) => state.articleSetting,
  );

  const TotalTextLimit = 225;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // State variables for article details
  const [articleName, setArticleName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>({
    label: "",
    value: 0,
  });
  const [description, setDescription] = useState("");
  const [articleStatus, setArticleStatus] = useState<string>("draft");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState("");
  const [selectedFiles, setSelectedFiles] = useState([] as any);
  const [searchParam, setSearchParam] = useSearchParams();
  const [showError, setShowError] = useState(false);
  const [keywordVolums, setKeywordVolums] = useState<KeywordData[]>([]);

  // UseEffect for fetching article data
  useEffect(() => {
    if (articleId !== null && articleId !== undefined && articleId !== "new") {
      dispatch(resetArticleSetting());
      dispatch(
        fetchArticleSetting({
          articleId: articleId,
          integrationId: pageIntegrationId,
        }),
      );
    }

    if (articleId === "new") {
      const categoryId = searchParam.get("categoryId");

      // Api Call to get category
      if (categoryId !== null) {
        getArticleCategory({
          integrationId: pageIntegrationId,
          categoryId: categoryId,
        })
          .then((res) => {
            setSelectedCategory({
              label: res.categoryName,
              value: res.categoryId,
            });
          })
          .catch((err) => {
            console.log("failed to select category");
          });
      }
    }
    dispatch(
      fetchAllArticleCategories({
        integrationId: pageIntegrationId,
        start: 0,
        limit: 15,
      }),
    );
  }, [false]);

  useEffect(() => {
    if (articleData !== null && articleId !== "new") {
      setArticleName(articleData.articleName);
      setSelectedCategory({
        value: articleData.categoryId,
        label: articleData.categoryName,
      });
      setDescription(articleData.articleDescription);
      setArticleStatus(articleData.articleStatus);
      setSearchKeyword(articleData.searchKeyword);
    }
  }, [articleData]);

  useEffect(() => {
    if (articleData.generatedBy === "ai") {
      getKeywordsAndVolums({
        articleId,
        integrationId: pageIntegrationId,
      })
        .then((res) => {
          setKeywordVolums(res);
        })
        .catch((err) => {
          console.log("failed to load keywords and volums");
        });
    } else {
      setKeywordVolums([]);
    }
  }, [articleData, articleId])
  

  // Function to hide modals
  const onHide = () => {
    setShowDeleteModal(false);
    setSaveChangesModal(false);
  };

  // Variables For Save Changes Modal
  const [saveChangeModal, setSaveChangesModal] = useState(false);

  // Function to show Save Changes Modal
  const onShow = (e: any) => {
    e.stopPropagation();
    setSaveChangesModal(true);
  };

  // Function to hide Save Changes Modal
  const onSaveHide = () => {
    setSaveChangesModal(false);
  };

  // Function for validating input
  const validateInput = () => {
    if (articleName.trim().length === 0) {
      //Added trim to remove spaces
      // pushTheToast({
      //   type: "warning",
      //   text: "Please enter an article name!",
      //   position: "top-right",
      // });
      setShowError(true);
      return false;
    }

    if (articleStatus === "") {
      pushTheToast({
        type: "warning",
        text: "Please select an article status!",
        position: "top-right",
      });
      setShowError(true);
      return false;
    }
    // Nov 30 2023 Adding validation for the message and it will check description is empty
    if (isHTMLEmpty(trimPDIVBR(description.trim()))) {
      setShowError(true);
      return false;
    }

    if (selectedCategory.value === "" || selectedCategory.value === 0) {
      // pushTheToast({
      //   type: "warning",
      //   text: "Please select a category!",
      //   position: "top-right",
      // });
      setShowError(true);
      return false;
    }

    return true;
  };

  // Function for handling submit
  const HandleSubmit = (e: any) => {
    e.preventDefault();

    if (!validateInput()) {
      return false;
    }

    setShowSaveLoader(true);
    if (articleId === "new") {
      let payload: addArticleParams = {
        articleName: articleName,
        articleDescription: description,
        articleStatus: articleStatus,
        categoryId: selectedCategory.value,
        integrationId: pageIntegrationId,
      };

      if (searchKeyword !== "") {
        payload["searchKeyword"] = searchKeyword;
      }

      if (
        articleData?.attachments?.length ||
        (selectedFiles && selectedFiles.length)
      ) {
        let attachments: Array<any> = [
          ...(articleData?.attachments ?? []),
          ...selectedFiles,
        ];
        payload["attachmentBatchNumber"] = attachments
          .map(
            (attachment) =>
              attachment?.batchNumber || attachment?.attachmentBatchNumber,
          )
          .filter((value) => value);
        if (payload.attachmentBatchNumber.length === 0) {
          delete payload.attachmentBatchNumber;
        }
      }

      addArticle(payload)
        .then((res) => {
          dispatch(resetArticleSetting());
          setShowError(false);
          // dispatch(fetchAllRoutingRulesData(pageIntegrationId));
          navigate(`/knowledge-base/${pageIntegrationId}/articles`);
          setTimeout(() => {
            pushTheToast({
              type: "success",
              text: `Article "${res.articleName}" Created!`,
              position: "top-right",
            });
          }, 0);

          setShowDeleteLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowDeleteLoader(false);
        });
    } else {
      let payload: updateArticleParams = {
        articleId: articleId,
        articleName: articleName,
        articleDescription: description,
        articleStatus: articleStatus,
        categoryId: selectedCategory.value,
        integrationId: pageIntegrationId,
        searchKeyword: searchKeyword,
      };
      if (
        articleData?.attachments?.length ||
        (selectedFiles && selectedFiles.length)
      ) {
        let attachments: Array<any> = [
          ...(articleData?.attachments ?? []),
          ...selectedFiles,
        ];
        payload["attachmentBatchNumber"] = attachments
          .map(
            (attachment) =>
              attachment?.batchNumber || attachment?.attachmentBatchNumber,
          )
          .filter((value) => value);
        if (payload.attachmentBatchNumber.length === 0) {
          delete payload.attachmentBatchNumber;
        }
      }

      updateArticle(payload)
        .then((res) => {
          // dispatch(resetArticleSetting());
          // dispatch(fetchAllRoutingRulesData(pageIntegrationId));
          // navigate(
          //   "/live-chat/settings/" + pageIntegrationId + "/Knowledge/articles"
          // );
          // setTimeout(() => {
          //   pushTheToast({
          //     type: "success",
          //     text: "Article Updated!",
          //     position: "top-right",
          //   });
          // }, 0);
          setSaveChangesModal(true);
          setShowSaveLoader(false);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowSaveLoader(false);
        });
    }
  };

  // Function for handling delete
  const handleDelete = () => {
    let payload: deleteArticleParams = {
      integrationId: pageIntegrationId,
      articleId: articleData.articleId,
    };
    setShowDeleteLoader(true);
    if (articleId === "new") {
      navigate(`/knowledge-base/${pageIntegrationId}/articles`);
      setShowDeleteLoader(false);
    } else {
      deleteArticle(payload)
        .then((res) => {
          pushTheToast({
            type: "success",
            text: `Deleted Article "${articleData.articleName}" `,
            position: "top-right",
          });
          dispatch(resetArticleSetting());
          dispatch(resetArticleCategories());
          onHide();
          setShowDeleteLoader(false);
          navigate(`/knowledge-base/${pageIntegrationId}/articles`);
        })
        .catch((err) => {
          pushTheToast({
            type: "danger",
            text: "Something went wrong!",
            position: "top-right",
          });
          setShowDeleteLoader(false);
        });
    }
  };

  // Return the necessary values and functions for the component using this hook
  return {
    navigate,
    pageIntegrationId,
    articleName,
    setShowDeleteModal,
    showSaveLoader,
    HandleSubmit,
    saveChangeModal,
    onHide,
    showDeleteModal,
    handleDelete,
    showDeleteLoader,
    articleSettingAjaxStatus,
    setArticleName,
    description,
    setDescription,
    currentAttachmentBatchNumber,
    setCurrentAttachmentBatchNumber,
    TotalTextLimit,
    selectedCategory,
    setSelectedCategory,
    articleStatus,
    setArticleStatus,
    searchKeyword,
    setSearchKeyword,
    selectedFiles,
    setSelectedFiles,
    showError,
    setShowError,
    keywordVolums,
    generatedBy: articleData.generatedBy
  };
}

export default useCreatePublish;
