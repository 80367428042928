import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "../../TimelineInfo.module.scss";
import { useTimeline } from "../../TimelineProvider/TimelineProvider";
import { useAppSelector } from "src/store/store";

function formatDateTime(
  inputDateTime: string,
  userTimezone: string | undefined,
) {
  const dateObj = new Date(inputDateTime + "Z");
  const day = dateObj.getDate();
  const month = dateObj
    .toLocaleString("en-US", { month: "short" })
    .toUpperCase();
  const time = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimezone,
  });
  return `${day} ${month} ${time}`;
}

export const LastUpdated = () => {
  const { state } = useTimeline();
  const { timelineInfo } = state;
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  if (!timelineInfo?.last_updated) {
    return <></>;
  }

  return (
    <div className={`${styles.lastUpdateWrap}`}>
      <OverlayTrigger
        placement="top"
        trigger={"hover"}
        overlay={
          <Tooltip className={`${styles.toolTipCustom}`}>
            <span>
              Last updated on{" "}
              {formatDateTime(timelineInfo.last_updated, userTimezone)}
            </span>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div
            {...triggerHandler}
            className={`${styles.lastUpdated}`}
            ref={ref}
          >
            Last updated
          </div>
        )}
      </OverlayTrigger>
    </div>
  );
};
