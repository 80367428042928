import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import backArrow from "src/assets/images/backArrow.png";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import startSlackIntegrationService from "src/services/Integrations/Slack/startSlackIntegration.service";
import useGetAllLinkableBrnad from "../../useGetAllLinkableBrnad";
import styles from "./AddNewSlack.module.scss";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import AddBrand from "../../../../../EmailIntegration/children/ConnectAccount/AddBrand/AddBrand";
import { useShowAddBrand } from "./ShowAddBrandContext";
import useClickAway from "src/hooks/useClickAway";

// Custom Option component to show image along with the label
const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      {props.data.imageURL ? (
        <AxiosImg
          url={props.data.imageURL}
          className={`${styles.profileIcon}`}
        />
      ) : (
        <UserAvatar
          name={props.data.label || "NA"}
          size={27}
        />
      )}
      <span className={`ms-2 ${styles.optionBrand}`}>{props.data.label}</span>
    </components.Option>
  );
};

// Custom SingleValue component to show image along with the label after selection
const CustomSingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      {props.data.imageURL ? (
        <AxiosImg
          url={props.data.imageURL}
          className={`${styles.profileIcon}`}
        />
      ) : (
        <UserAvatar
          name={props.data.label || "NA"}
          size={27}
        />
      )}
      <span className={`ms-2 ${styles.selectedBrand}`}>{props.data.label}</span>
    </components.SingleValue>
  );
};

// Custom MenuList component to show loading indicator at the bottom of the list
const CustomMenuList = (props: any) => {
  const { show } = useShowAddBrand();

  const handleAddBrandClick = () => {
    props.selectProps.onMenuClose(); 
    show();
  };
  return (
    <div>
      <components.MenuList {...props}>
        {props.children}
        {props.selectProps.error && (
          <div style={{ color: "red", textAlign: "center", padding: "10px" }}>
            {props.selectProps.error}
          </div>
        )}
        {props.selectProps.isLoading && (
          <div
            className={`${styles.optionBrand} text-center p-2 ${styles.laodMoreBg}`}
          >
            <span>Loading...</span>
          </div>
        )}
      </components.MenuList>
      <div
        className={`p-2 ms-2 cursor-pointer ${styles.optionBrand}`}
        onClick={handleAddBrandClick}
      >
        + Add new brand
      </div>
    </div>
  );
};

// Custom Placeholder component to apply styles
const CustomPlaceholder = (props: any) => {
  return (
    <components.Placeholder {...props}>
      <span className={styles.selectPlaceHolder}>{props.children}</span>
    </components.Placeholder>
  );
};

const customComponents = {
  Option: CustomOption,
  SingleValue: CustomSingleValue,
  MenuList: CustomMenuList,
  Placeholder: CustomPlaceholder,
  IndicatorSeparator: () => null,
  DropdownIndicator: ({ selectProps }: any) => (
    <div style={{ paddingRight: "17px" }}>
      <i
        className={`${
          selectProps.menuIsOpen
            ? "fa-solid fa-caret-up"
            : "fa-solid fa-caret-down"
        } `}
      ></i>
    </div>
  ),
};

const AddNewSlack = () => {
  const navigate = useNavigate();
  const [selectedBrandId, setselectedBrandId] = useState<string | number>("");
  const [selectedOption, setSelectedOption] = useState<{value: string; label: string;} | null>(null);
  const { linkableBrands, setLinakbelBrands, changePage, isLoading } =
    useGetAllLinkableBrnad();
  const targetRef = useRef<HTMLDivElement>(null);
  const { showAddBrand, hide } = useShowAddBrand();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleAddSlack = useCallback(async () => {
    try {
      if (selectedBrandId) {
        const url = await startSlackIntegrationService({
          brandId: selectedBrandId,
        });
        if (url) {
          window.open(url, "_self");
        }
      }
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes("brand is not verified")
      ) {
        pushTheToast({
          position: "top-right",
          type: "danger",
          text: "Brand is not verified",
        });
        setErrMsg("Brand is not verified");
        setError(true);
      } else {
        pushTheToast({
          position: "top-right",
          type: "danger",
          text: typeof err === "string" ? err : "SomeThing went wrong",
        });
      }
    }
  }, [selectedBrandId]);

  /**
   * Handler for setting new brand after adding new brand
   */
  const handleAddBrand = useCallback(
    (id: string | number, brandName: string, brandEmail: string) => {
      // Add brand to brand list
      setLinakbelBrands((prev) => [
        ...prev,
        {
          id: id + "",
          brandEmail,
          brandName,
        },
      ]);

      // Set the new brand as the currently selected option
      setSelectedOption({
        value: id + "",
        label: brandName,
      });

      // Set the new brand as the currently selected option
      setselectedBrandId(id + "");
    },
    [selectedBrandId],
  );

  const handleMenuClose = () => {
    setMenuIsOpen(false); // Close dropdown
  };

  return (
    <div className={styles.mainWrapper}>
      <div className="d-flex w-100">
        <div
          className="edit-icons"
          style={{ marginTop: "" }}
        >
          <img
            src={backArrow}
            alt="backArrow"
            width={25}
            onClick={() => navigate("../settings/integrations/slack")}
          />
        </div>
        <div className="w-100 ms-3">
          <div className={styles.headingWrapper}>
            <h1 className={styles.heading}>Slack</h1>
            <span className={`${styles.subHeading}`}>
              Connect your helpdesk with Slack to manage customer queries in
              real-time.
            </span>
          </div>
          <div className={`${styles.chooseBoxMain}`}>
            <div className={`${styles.chooseBox} py-3 px-4`}>
              <div className="position-relative">
                <h3 className={`${styles.chooseBoxHead} mb-2`}>Brand</h3>
                {showAddBrand && (
                  <AddBrand
                    targetRef={targetRef}
                    onHide={hide}
                    selectedBrand={handleAddBrand}
                  />
                )}
              </div>
              <div className={styles.selectBrand}>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isMulti={false}
                  components={customComponents}
                  name="select-brand"
                  value={selectedOption ? selectedOption : ""}
                  placeholder={"Choose brand"}
                  onMenuScrollToBottom={changePage}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={() => setMenuIsOpen(true)} // Open the dropdown
                  onMenuClose={handleMenuClose} // Close the dropdown
                  isLoading={isLoading}
                  options={linkableBrands}
                  onChange={(option: any) => {
                    setselectedBrandId(option.value);
                    setSelectedOption(option);
                    setError(false);
                  }}
                />
              </div>
              {error && <span className={`text-danger ${styles.errorMsg}`}>{errMsg}</span>}
              <div className={styles.connectSlackBtnDiv}>
                <button
                  className={`${styles.addSlackBtn} mt-4 px-2 d-flex justify-content-center align-items-center mb-2`}
                  onClick={handleAddSlack}
                >
                  <span>Connect to slack</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSlack;
