import { axiosJSON } from "src/globals/axiosEndPoints";

interface Params {
  articleId: number | string;
  integrationId: number | string;
}

export interface KeywordData {
  keyword: string;
  volume: number;
}

export async function getKeywordsAndVolums(params: Params) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/getKeywordsAndVolums",
    params,
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  //   console.log("getAllRoutingRules service ret:: ", JSON.stringify(ret));
  return res.data as KeywordData[];
}
