import { useMemo } from "react";
import { SegmentDataForUpdateI } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { ConditionI } from "src/services/CustomerSegments/createSegment.service";
import useFetchConditionOptions from "../../hooks/useFetchConditionOptions";
import { useUpdateSegmentDetails } from "../../hooks/useUpdateSegmentDetails";
import styles from "../../UpdateSegmentDetails.module.scss";
import ComparisonTypes from "../ComparisonTypes/ComparisonTypes";
import LogicalOperator from "../LogicalOperator/LogicalOperator";
import ValueBox from "../ValueBox/ValueBox";

interface Props {
  condition: ConditionI;
  conditionIndex: number;
  groupIndex: number;
  segmentDataForUpdate: SegmentDataForUpdateI | null;
}

function Condition({
  condition,
  conditionIndex,
  groupIndex,
  segmentDataForUpdate,
}: Props) {
  const { dispatch } = useUpdateSegmentDetails();
  const { findOptionByFieldKey } = useFetchConditionOptions(
    segmentDataForUpdate?.segmentType ?? "",
    segmentDataForUpdate?.segmentId,
  );

  const option = useMemo(
    () => findOptionByFieldKey(condition.fieldKey),
    [findOptionByFieldKey, condition],
  );

  if (option) {
    return (
      <div>
        <div
          className={`d-flex flex-column flex-md-row align-items-start mb-2 ${styles.condition} m-2`}
          key={condition.conditionUUID}
        >
          <div
            className="d-flex align-items-center"
            style={{ height: "37px" }}
          >
            <span className={`${styles.companyName}`}>{option.fieldName}</span>
          </div>
          <ComparisonTypes
            options={option.compareDetails ?? []}
            conditionIndex={conditionIndex}
            groupIndex={groupIndex}
            type={option.type}
          />
          <ValueBox
            compareDetails={option.compareDetails ?? []}
            conditionIndex={conditionIndex}
            groupIndex={groupIndex}
            placeholder={option.placeholder}
            fieldValues={option.fieldValues}
            isMulti={option.isMulti}
            fieldKey={option.fieldKey}
            isSearchable={option.isSearchable}
            segmentDataForUpdate={segmentDataForUpdate}
          />
        </div>
        {condition.logicalOperator && (
          <div
            className={`d-flex align-items-center justify-content-md-start mb-2 ms-lg-5`}
          >
            <LogicalOperator
              selectedValue={condition.logicalOperator}
              onChange={(value) => {
                dispatch("updateSegmentCondition", {
                  data: { logicalOperator: value },
                  groupIndex,
                  conditionIndex,
                });
              }}
            />
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

export default Condition;
