import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import useTimelineScroll from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/useTimelineScroll";
import { getBrandAndSignatureThunk } from "src/store/slices/globals/globals.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import CampaignCernThread from "../CampaignCernThread/CampaignCernThread";
import CampaignSelect from "../CampaignSelect/CampaignSelect";
import CampaignTimeline from "../CampaignTimeline/CampaignTimeline";
import CampaignWrapper from "../CampaignWrapper/CampaignWrapper";
import {
  CampaignActionType,
  useCampaignCreate,
} from "../CampainContext/CampainContext";
import CampaignTabs from "../Children/CampaignTabs/CampaignTabs";
import { useCampaignData } from "../hooks/useCampaignData";
import NeedEdit from "../NeedEdit/NeedEdit";
import PromptModal from "../PromptModal/PromptModal";
import StatusSelect from "../StatusSelect/StatusSelect";
import styles from "./Campaign.module.scss";

const Campaign = () => {
  const dispatch = useAppDispatch();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const initialRef = useRef(true);

  useEffect(() => {
    if (initialRef.current) {
      dispatch(getBrandAndSignatureThunk());
      initialRef.current = false;
    }
  }, [dispatch]);

  const [showAllCampaign, setShowAllCampaign] = useState(false);
  const [showTimeline, setShowTimeline] = useState(true);
  const navigate = useNavigate();

  const timelineRef = useRef<HTMLDivElement>(null);
  const [activeCampaignId, setCampaignId] = useState<number | undefined>();

  const {
    data: campaignData,
    isLoading,
    error,
    refetch,
  } = useCampaignData(activeCampaignId);

  const [contextValue, campaignDispatch, Provider] = useCampaignCreate();
  const [promptModal, setPromptModal] = useState(false);

  const setActiveCampaignId = useCallback((id: number | undefined) => {
    setCampaignId(id);
    campaignDispatch({
      type: CampaignActionType.SET_ACTIVE_CAMPAIGN_ID,
      payload: id ?? null,
    });
  }, []);

  const {
    handleScrollLeft,
    handleScrollRight,
    disableLeft,
    disableRight,
    scrollDivRef,
    checkScroll,
  } = useTimelineScroll();

  const { campaignId, tenantId, stepId } = useParams();

  useMemo(() => {
    if (campaignId && parseInt(campaignId)) {
      setActiveCampaignId(parseInt(campaignId));
      setShowAllCampaign(false);
    } else {
      setShowAllCampaign(true);
    }
  }, [campaignId]);

  return (
    <Provider value={[contextValue, campaignDispatch]}>
      <div className={`${styles.mainContent} w-100`}>
        <h3 className={`mb-1 ${styles.heading}`}>
          {showAllCampaign ? "All campaigns" : "Campaigns"}
        </h3>
        {showAllCampaign ? (
          <span className={`d-block mb-2 ${styles.subHeading}`}>
            Manage your drip and one-time campaigns across various segments
          </span>
        ) : (
          ""
        )}

        {showAllCampaign ? (
          <div className={`${styles.campaignData}`}>
            <CampaignTabs />
          </div>
        ) : (
          <div>
            <div className={`d-flex ${styles.campaignWrapper}`}>
              {/* <CampaignSideBar /> */}
              <div className={`${styles.leftSection} position-relative w-100`}>
                <TimelineProvider
                  referenceId={1}
                  referenceType="customer"
                >
                  <div className="d-flex align-items-center px-3">
                    <div
                      className={`d-flex flex-column ${styles.selectTransform}`}
                    >
                      <div
                        className={`${styles.backBtn} d-flex align-items-center mb-2`}
                        onClick={() => {
                          if (stepId) {
                            navigate(
                              `/campaigns${
                                tenantId
                                  ? `/admin/${tenantId}/${campaignId}`
                                  : `/${campaignId}`
                              }`,
                            );
                          } else {
                            navigate(
                              `/campaigns${
                                tenantId ? `/admin/${tenantId}` : ""
                              }`,
                            );
                          }
                        }}
                      >
                        <span className="pe-1">
                          <i className="fa-solid fa-reply"></i>
                        </span>
                        <span>
                          {!stepId ? `View all campaigns` : `View all steps`}
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className={`me-1 ${styles.statusText}`}>
                          Status:
                        </span>
                        <StatusSelect
                          campaignId={activeCampaignId}
                          status={campaignData?.status}
                          disabled={
                            (tenantId &&
                              (campaignData?.status as any) !==
                                "pendingAdmin") ||
                            currentUserData?.userRole !== "31"
                          }
                          isAdmin={!!tenantId}
                        />
                      </div>
                      <CampaignSelect
                        setActiveCampaignId={setActiveCampaignId}
                        activeCampaignId={activeCampaignId}
                      />
                    </div>

                    <div
                      className={`d-flex align-items-center ${styles.mobTimelimeWidth}`}
                      style={{
                        width: tenantId
                          ? "calc(100% - 200px)"
                          : "calc(100% - 117px)",
                        marginTop: showTimeline ? "10px" : "65px",
                      }}
                    >
                      <div>
                        <div
                          className={`mx-2 ${styles.iconBox} ${
                            !disableLeft ? "cursor-pointer" : styles.btnDisabled
                          }`}
                          onClick={() => {
                            if (!disableLeft) {
                              handleScrollLeft();
                            }
                          }}
                        >
                          <i className={`fa-solid fa-chevron-left`}></i>
                        </div>
                      </div>

                      <div
                        ref={scrollDivRef}
                        className={`mt-2 ${styles.headerContent}`}
                      >
                        <CampaignTimeline
                          showTimeline={showTimeline}
                          timelineRef={timelineRef}
                          campaignId={activeCampaignId}
                          onLoad={() => {
                            checkScroll();
                          }}
                        />
                      </div>
                      <div className="mx-4">
                        <span
                          className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
                          onClick={() => setShowTimeline(!showTimeline)}
                        >
                          <i
                            className={`fa-solid fa-chevron-${
                              showTimeline ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      </div>
                      <div>
                        <div
                          className={`mx-2 ${styles.iconBox} ${
                            disableRight ? styles.btnDisabled : "cursor-pointer"
                          }`}
                          onClick={handleScrollRight}
                        >
                          <i className={`fa-solid fa-chevron-right`}></i>
                        </div>
                      </div>
                      {contextValue?.activeCampaignEmailId ? (
                        <div>
                          <NeedEdit
                            handleOnClick={() => {
                              campaignDispatch({
                                type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD,
                                payload: true,
                              });
                            }}
                          />
                          {currentUserData?.ui_visibility?.includes(
                            "campaign_admin",
                          ) && (
                            <div
                              className={`mt-2 cursor-pointer ${styles.seePrompts}`}
                              onClick={() => setPromptModal(true)}
                            >
                              <span>See all prompts</span>
                            </div>
                          )}
                          <Modal
                            show={promptModal}
                            onHide={() => setPromptModal(false)}
                            contentClassName={`${styles.promptContent}`}
                            dialogClassName={`${styles.promptDialog}`}
                            backdropClassName={`${styles.promptBack}`}
                            id="promptModal"
                            centered={true}
                          >
                            <PromptModal />
                          </Modal>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </TimelineProvider>
                {/* template visibility toggle button display none for now */}
                <div className="d-flex align-items-center px-3 d-none">
                  <label
                    className={`form-check-label me-3 ${styles.checkLabel}`}
                    htmlFor="global"
                  >
                    Make Global
                  </label>
                  <div className="form-check form-switch">
                    <input
                      className={`form-check-input ${styles.switch}`}
                      type="checkbox"
                      id="global"
                    />
                  </div>
                </div>
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Loader />
                  </div>
                ) : error ? (
                  <div className="d-flex align-items-center justify-content-center text-danger">
                    Error Loading Content...
                  </div>
                ) : (
                  campaignData && (
                    <CampaignWrapper
                      id={campaignData.id}
                      name={campaignData.name}
                      emails={campaignData.emails}
                      refetchCampaign={refetch}
                      userSegment={campaignData.userSegment}
                      brand={campaignData.brand}
                      status={campaignData.status}
                      trigger={campaignData.trigger}
                    />
                  )
                )}
              </div>

              {contextValue.showCernThread && campaignData && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <div className={`${styles.cernThread}`}>
                    <CampaignCernThread
                      handleClose={() => {
                        campaignDispatch({
                          type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD,
                          payload: false,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Provider>
  );
};

export default Campaign;
