import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentMessageType } from "./sendNewMessage.service";

export interface VariablesData {
  id: string;
  /**
   * The name of the variable, which may be displayed in the text editor interface.
   */
  variableName: string;
  /**
   * The key associated with the variable, used for referencing the variable within the text editor.
   */
  variableKey: string;
  /**
   * An example usage of the variable text, which can help users understand how to use it in the text editor.
   */
  example: string;
}

interface GetMessageTypesResponse {
  messageTypes: SegmentMessageType[];
  variables: Record<string, VariablesData>;
  variableIds: string[];
}

const getMessageTypesService = async () => {
  const { data: res } = await axiosJSON.get(`/api/segment/getMessageTypes`, {
    params: { cacheIt: true, cacheTime: Infinity },
  });

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetMessageTypesResponse = {
    messageTypes: res.data,
    variables: {},
    variableIds: [],
  };

  if (res.metaData.variables) {
    Object.values<VariablesData>(res.metaData.variables).forEach(
      (v: VariablesData) => {
        ret.variables[v.id] = v;
        ret.variableIds.push(v.id);
      },
    );
  }

  return ret;
};

export default getMessageTypesService;
