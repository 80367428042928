import {
  ESequenceSectionType,
  SequenceStepSection,
} from "src/services/Campaign/createCampaignV2.service";
import TextSection from "./Children/TextSection";
import { useAddCampaignState } from "../../../../../Hooks/useAddCampaignContext";
import { useCallback } from "react";
import MultiTextSection from "./Children/MultiTextSection";
import DiscountSection from "./Children/DiscountSection";
import ProductSection from "./Children/ProductSection";

const SequenceSection = ({
  section,
  index,
  handleDeleteSection,
  handleSectionsChange,
}: {
  section: SequenceStepSection;
  index: number;
  handleSectionsChange: (
    sectionIdx: number,
    section: SequenceStepSection,
  ) => void;
  handleDeleteSection: () => void;
}) => {
  const { state, dispatch } = useAddCampaignState();

  const handleTextChange = useCallback(
    (value: string) => {
      const newSection = {
        ...section,
        description: value,
      };
      handleSectionsChange(index, newSection);
    },
    [state, dispatch, section],
  );

  switch (section.sectionType) {
    case ESequenceSectionType.Introduction: {
      return (
        <TextSection
          section={section}
          heading="Introductory Section"
          placeholder="an introductory text for the welcome email for {brand_website}"
          errorMsg={
            state.showErrors && section.description?.trim().length === 0
              ? "* Introduction cannot be empty. Please enter a brief description to proceed."
              : ""
          }
          onChange={handleTextChange}
          handleDelete={handleDeleteSection}
        />
      );
    }
    case ESequenceSectionType.Text: {
      return (
        <TextSection
          section={section}
          heading="Text section"
          placeholder="Add a description......"
          errorMsg={
            state.showErrors && section.description?.trim().length === 0
              ? "* Text cannot be empty. Please enter a brief description to proceed."
              : ""
          }
          onChange={handleTextChange}
          handleDelete={handleDeleteSection}
        />
      );
    }
    case ESequenceSectionType.Closing: {
      return (
        <TextSection
          section={section}
          heading="Text section"
          placeholder="a summary for the email of the company {brand_website} is for, summarizing the core features of the company"
          errorMsg={
            state.showErrors && section.description?.trim().length === 0
              ? "* Closing cannot be empty. Please enter a brief description to proceed."
              : ""
          }
          onChange={handleTextChange}
          handleDelete={handleDeleteSection}
        />
      );
    }
    case ESequenceSectionType.Feature: {
      return (
        <MultiTextSection
          section={section}
          sectionIndex={index}
          heading="Feature description section"
          placeholder="Add a description....."
          sideHeading="Feature"
          sectionType={section.sectionType}
          sidesubHeading="Will describe"
          errorMsg={
            "* Feature description cannot be empty. Please enter a brief description to proceed."
          }
          showErrors={state.showErrors}
          handleDeleteSection={handleDeleteSection}
          addBtnText="Add feature"
          handleSectionsChange={handleSectionsChange}
        />
      );
    }
    case ESequenceSectionType.Blog: {
      return (
        <MultiTextSection
          section={section}
          sectionIndex={index}
          sectionType={section.sectionType}
          heading="Blog Showcase"
          placeholder="Add a description....."
          sideHeading="Blog"
          sidesubHeading="Will describe"
          errorMsg={
            "* Blog is not valid. Please enter a valid blog to proceed."
          }
          showErrors={state.showErrors}
          handleDeleteSection={handleDeleteSection}
          addBtnText="Add blog"
          handleSectionsChange={handleSectionsChange}
          seqLimit={state.seqLimit}
        />
      );
    }
    case ESequenceSectionType.Discount: {
      return (
        <DiscountSection
          section={section}
          handleSectionsChange={(section) =>
            handleSectionsChange(index, section)
          }
          showErrors={state.showErrors}
          handleDelete={handleDeleteSection}
        />
      );
    }
    case ESequenceSectionType.Product: {
      return (
        <ProductSection
          section={section}
          handleSectionsChange={(section) =>
            handleSectionsChange(index, section)
          }
          showErrors={state.showErrors}
          handleDelete={handleDeleteSection}
        />
      );
    }
    default:
      return <></>;
  }
};

export default SequenceSection;
