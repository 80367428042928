import { createSlice } from "@reduxjs/toolkit";

import { AddEmailRes } from "src/services/EmailIntegration/addEmail";
import {
  EmailIntegrationData,
  EmailIntegrationMetaData,
  GetAllEmailIntegrations,
} from "src/services/EmailIntegration/getAllEmailIntegrations";
import {
  BrandDetail,
  GetBrands,
} from "src/services/EmailIntegration/getBrands";
import {
  DomainAuthData,
  GetEmailIntegrationById,
  IncomingMailSettings,
  OutgoingMailSettings,
  OutgoingRecieveType,
} from "src/services/EmailIntegration/getEmailIntegrationById";
import { VerifyDomainEmailAuth } from "src/services/EmailIntegration/verifyEmailDomainAuth";
import {
  fetchDeleteEmailIntegration,
  fetchGetAllEmailIntegration,
  fetchGetEmailIntegrationById,
  fetchGetEmailThunk,
  fetchResendVerificationCode,
  fetchUpdateEmailIntegration,
  fetchVerifyDomainAuth,
  fetchVerifyEmailForwarding,
} from "./emailIntegration.thunks";

export enum MailTypeEnum {
  gmail = 155,
  outlook = 153,
  others = 154,
}

export interface MailType {
  id: number;
  typeName: string;
}

export type EmailTypes = "gmail" | "outlook" | "others";

export interface EmailIntegrationUser {
  emailIntegrationId: number; // id
  defaultEmail: string;
  userName: string;
  emailEnteredByUser: string;
  isEmailAdded: boolean;
  isEmailVerified: boolean;
  isDomainVerified: boolean;
  isDomainAddedForAuth: boolean;
  domainAuthData: Array<DomainAuthData>;
  emailIntegrationChannel: string;
  mailType: MailType;
  incomingMailSettings: IncomingMailSettings;
  outgoingMailSettings: OutgoingMailSettings;
  isIncommingGmailVerified?: boolean;
}

export interface IEmailIntegration {
  selectedUser: EmailIntegrationUser;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  allEmailLoading: "idle" | "pending" | "fulfilled" | "rejected";
  metaData: EmailIntegrationMetaData;
  domainLoading: "idle" | "pending" | "fulfilled" | "rejected";
  allEmailIntegrations: Array<EmailIntegrationData>;
  getBrands: Array<BrandDetail>;
  selectedEmailId: number;
  verifyEmailLoading: "idle" | "pending" | "fulfilled" | "rejected";
  emailLoading: "idle" | "pending" | "fulfilled" | "rejected"; // Added to get the status of fetchGetEmailIntegrationById
  verifyEmailSent: boolean;
  selectedEmailType: EmailTypes;
}

const InitialSelectedUser: IEmailIntegration["selectedUser"] = {
  emailIntegrationId: -1,
  defaultEmail: "",
  userName: "",
  emailEnteredByUser: "",
  isEmailAdded: false,
  isEmailVerified: false,
  isDomainVerified: false,
  isDomainAddedForAuth: false,
  domainAuthData: [],
  emailIntegrationChannel: "",
  mailType: {
    id: -1,
    typeName: "Others",
  },
  incomingMailSettings: {
    preferredReceiveType: "currentHost",
    receiveType: "currentHost",
  },
  outgoingMailSettings: {
    outgoingType: "currentHostWithDNS",
    preferredReceiveType: "currentHostWithDNS",
  },
};

export const initialState: IEmailIntegration = {
  loading: "idle",
  allEmailLoading: "idle",
  metaData: {
    count: 0,
    total: 0,
  },
  selectedUser: InitialSelectedUser,
  allEmailIntegrations: [],
  domainLoading: "idle",
  selectedEmailId: -1,
  getBrands: [],
  verifyEmailLoading: "idle",
  emailLoading: "idle",
  verifyEmailSent: false,
  selectedEmailType: "others",
};

export const emailIntegrationSlice = createSlice({
  name: "emailIntegration",
  initialState,
  reducers: {
    getAllEmailIntegration: (
      state,
      {
        payload,
      }: {
        payload: GetAllEmailIntegrations;
      },
    ) => {
      state.metaData = payload.metaData;
      state.allEmailIntegrations = payload.data;
    },

    getBrands: (
      state,
      {
        payload,
      }: {
        payload: GetBrands;
      },
    ) => {
      state.getBrands = payload.data;
    },
    addBrandToBrandList: (
      state,
      {
        payload,
      }: {
        payload: BrandDetail;
      },
    ) => {
      state.getBrands.push(payload);
    },

    addEmail: (state, { payload }: { payload: AddEmailRes }) => {
      state.selectedUser.emailIntegrationId = payload.emailIntegrationId;
      state.selectedUser.defaultEmail = payload.defaultEmail;
      state.selectedUser.userName = payload.emailToBeAddedName;
      state.selectedUser.emailEnteredByUser = payload.emailToBeAddedAddress;
      state.selectedUser.isEmailAdded = payload.isEmailAdded;
      state.selectedUser.isEmailVerified = payload.isEmailVerified;
      state.selectedUser.domainAuthData = [];
      state.selectedUser.emailIntegrationChannel =
        payload.emailIntegrationChannel;
      state.selectedUser.isDomainVerified = false;
      state.selectedUser.mailType = {
        id: -1,
        typeName: "Others",
      };
      state.selectedUser.defaultEmail = payload.defaultEmail;
    },

    getEmailIntegrationById: (
      state,
      { payload }: { payload: GetEmailIntegrationById },
    ) => {
      state.selectedUser.emailIntegrationId = payload.id;
      state.selectedUser.userName = payload.emailName;
      state.selectedUser.emailEnteredByUser = payload.emailAddress;
      state.selectedUser.isEmailAdded = payload.isEmailAdded;
      state.selectedUser.isEmailVerified = payload.isEmailVerified;
      state.selectedUser.isDomainVerified = payload.isDomainVerified;
      state.selectedUser.isDomainAddedForAuth = payload.isDomainAddedForAuth;
      state.selectedUser.domainAuthData = payload.domainAuthData;
      state.selectedUser.isIncommingGmailVerified =
        payload.isIncommingGmailVerified;
      state.selectedUser.emailIntegrationChannel =
        payload.emailIntegrationChannel;
      state.selectedUser.mailType = payload.mailType;
      state.selectedUser.defaultEmail = payload.defaultEmail;
      state.selectedUser.incomingMailSettings = payload.incomingMailSettings;
      state.selectedUser.outgoingMailSettings = payload.outgoingMailSettings;
      state.selectedEmailType = payload.mailType.typeName as any;
      state.selectedEmailId = payload.mailType.id;
    },

    resetSelecteduser: (state) => {
      state.selectedUser = InitialSelectedUser;
    },

    updateEmailType: (state, { payload }: { payload: EmailTypes }) => {
      state.selectedEmailType = payload;
    },

    deleteEmail: (
      state,
      { payload }: { payload: { ememailIntegrationId: number } },
    ) => {
      if (payload.ememailIntegrationId) {
        // state.emailAddress = "";
        state = initialState;
      }
    },

    // updateStep: (state, { payload }: { payload: { currentStep: number } }) => {
    //   state.selectedUser.currentStep = payload.currentStep;
    // },

    updateDomainAuth: (
      state,
      { payload }: { payload: VerifyDomainEmailAuth },
    ) => {
      state.selectedUser.isDomainVerified = payload.isDomainVerified;
      state.selectedUser.domainAuthData = payload.domainAuthData;
    },

    setDomainLoading: (
      state,
      {
        payload,
      }: {
        payload: {
          domainLoading: "idle" | "pending" | "fulfilled" | "rejected";
        };
      },
    ) => {
      state.domainLoading = payload.domainLoading;
    },
    setOutgoingType: (
      state,
      {
        payload,
      }: {
        payload: {
          outgoingType: OutgoingRecieveType;
        };
      },
    ) => {
      if (state.selectedUser.outgoingMailSettings) {
        state.selectedUser.outgoingMailSettings.outgoingType =
          payload.outgoingType;
      }
    },
    setverifyEmailLoadingIdle: (state) => {
      state.verifyEmailLoading = "idle";
    },

    emailVerified: (state) => {
      state.selectedUser.isEmailVerified = true;
    },

    domainVerified: (state) => {
      state.selectedUser.isDomainVerified = true;
    },
  },

  extraReducers: (builder) => {
    // Cases for Add Email
    builder.addCase(fetchGetEmailThunk.fulfilled, (state, action) => {
      state.loading = "fulfilled";
    });
    builder.addCase(fetchGetEmailThunk.rejected, (state, action) => {
      state.loading = "rejected";
    });
    builder.addCase(fetchGetEmailThunk.pending, (state, action) => {
      state.loading = "pending";
    });

    // Cases for VerifyDomainEmailAuth
    builder.addCase(fetchVerifyDomainAuth.fulfilled, (state, actions) => {
      state.domainLoading = "fulfilled";
    });

    builder.addCase(fetchVerifyDomainAuth.rejected, (state, actions) => {
      state.domainLoading = "rejected";
    });

    builder.addCase(fetchVerifyDomainAuth.pending, (state, actions) => {
      state.domainLoading = "pending";
    });

    // // Cases for Verify Email
    builder.addCase(fetchVerifyEmailForwarding.fulfilled, (state, action) => {
      state.verifyEmailLoading = "fulfilled";
    });

    builder.addCase(fetchVerifyEmailForwarding.rejected, (state, action) => {
      state.verifyEmailLoading = "rejected";
    });

    builder.addCase(fetchVerifyEmailForwarding.pending, (state, action) => {
      state.verifyEmailLoading = "pending";
    });

    // Cases for Resend Verification
    builder.addCase(fetchResendVerificationCode.fulfilled, (state, action) => {
      state.loading = "fulfilled";
    });

    builder.addCase(fetchResendVerificationCode.rejected, (state, action) => {
      state.loading = "rejected";
    });

    builder.addCase(fetchResendVerificationCode.pending, (state, action) => {
      state.loading = "pending";
    });

    // Cases for Delete Email
    builder.addCase(fetchDeleteEmailIntegration.fulfilled, (state, action) => {
      state.loading = "fulfilled";
    });

    builder.addCase(fetchDeleteEmailIntegration.rejected, (state, action) => {
      state.loading = "rejected";
    });

    builder.addCase(fetchDeleteEmailIntegration.pending, (state, action) => {
      state.loading = "pending";
    });

    // Cases for Update Email
    builder.addCase(fetchUpdateEmailIntegration.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.selectedUser.userName = action.payload.addressName + "";
    });

    builder.addCase(fetchUpdateEmailIntegration.rejected, (state, action) => {
      state.loading = "rejected";
    });

    builder.addCase(fetchUpdateEmailIntegration.pending, (state, action) => {
      state.loading = "pending";
    });

    // Cases for GetAllEmailIntegration
    builder.addCase(fetchGetAllEmailIntegration.fulfilled, (state, action) => {
      state.allEmailLoading = "fulfilled";
    });

    builder.addCase(fetchGetAllEmailIntegration.rejected, (state, action) => {
      state.allEmailLoading = "rejected";
    });

    builder.addCase(fetchGetAllEmailIntegration.pending, (state, action) => {
      state.allEmailLoading = "pending";
    });

    // Cases for GetEmailIntegrationById
    builder.addCase(fetchGetEmailIntegrationById.fulfilled, (state, action) => {
      // Set email integration loading status to "fulfilled" after fetching all details.
      state.emailLoading = "fulfilled";
    });

    builder.addCase(fetchGetEmailIntegrationById.rejected, (state, action) => {
      // Set email integration loading status to "rejected" if api call failed
      state.emailLoading = "rejected";
    });

    builder.addCase(fetchGetEmailIntegrationById.pending, (state, action) => {
      // Set email integration loading status to "pending" for loader display.
      state.emailLoading = "pending";
    });
  },
});

export const actions = emailIntegrationSlice.actions;
export default emailIntegrationSlice.reducer;
