import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import aronParkerTemplate from "src/assets/images/aronParkerTemplate.png";
import asanaTemplate from "src/assets/images/asanaTemplate.png";
import bigCommerceTemplate from "src/assets/images/bigCommerceTemplate.png";
import businessNetworkTemplate from "src/assets/images/businessNetworkTemplate.png";
import calendlyTemplate from "src/assets/images/calendlyTemplate.png";
import circleciTemplate from "src/assets/images/circleciTemplate.png";
import herokuTemplate from "src/assets/images/herokuEmailTemplate.png";
import note from "src/assets/images/tempNote.png";
import webinarTemplate from "src/assets/images/webinarTemplate.png";
import zapierTemplate from "src/assets/images/zapierTemplate.png";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import addCampaignStepService, {
  CampaignStepData,
} from "src/services/Campaign/addCampaignStep.service";
import styles from "./SelectTemplateModal.module.scss";
import AronParker from "./StaticTemplates/AronParker";
import Asana from "./StaticTemplates/Asana";
import BigCommerce from "./StaticTemplates/BigCommerce";
import BusinessNetwork from "./StaticTemplates/BusinessNetwork";
import Calendly from "./StaticTemplates/Calendly";
import Circleci from "./StaticTemplates/Circleci";
import Heroku from "./StaticTemplates/Heroku";
import Webinar from "./StaticTemplates/Webinar";
import Zapier from "./StaticTemplates/Zapier";
// import ParseHTML from "src/components/ParseHTML";
// import SearchBar from "src/components/SearchBar/SearchBar";
// import clock from "src/assets/images/temClock.png";
// import useSelectTemplate from "./useSelectTemplate";
// import { replaceKeyWithSpanB } from "src/utils/utils";

const HIDE_FUNCTIONALITY = true;

interface Category {
  id: string;
  name: string;
  templates: Array<{
    id: string;
    image: string;
    component: () => JSX.Element;
    enabled?: boolean;
  }>;
}

const categories: Category[] = [
  {
    id: "1",
    name: "Welcome",
    templates: [
      { id: "1", image: herokuTemplate, component: Heroku, enabled: true },
      { id: "2", image: bigCommerceTemplate, component: BigCommerce },
      { id: "3", image: asanaTemplate, component: Asana },
    ],
  },
  {
    id: "2",
    name: "Nurture",
    templates: [
      { id: "3", image: circleciTemplate, component: Circleci },
      { id: "4", image: zapierTemplate, component: Zapier },
      { id: "5", image: calendlyTemplate, component: Calendly },
    ],
  },
  {
    id: "3",
    name: "Events",
    templates: [
      {
        id: "2",
        image: aronParkerTemplate,
        component: AronParker,
        enabled: true,
      },
      { id: "8", image: webinarTemplate, component: Webinar },
      { id: "9", image: businessNetworkTemplate, component: BusinessNetwork },
    ],
  },
];

const TemplateDD = ({
  category,
  initialShow = false,
  onClickImage,
}: {
  category: Category;
  initialShow?: boolean;
  onClickImage: (id: string) => void;
}) => {
  const [showMenu, setShowMenu] = useState(initialShow);

  return (
    <div className="mb-2 ms-2">
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={() => setShowMenu((prev) => !prev)}
      >
        <span className={`w-100 text-truncate ${styles.categoryName}`}>
          {category.name}:
        </span>
        <span className={`ps-2 ${styles.arrowDown}`}>
          <i className={`fa-solid fa-chevron-${showMenu ? "up" : "down"}`}></i>
        </span>
      </div>
      {showMenu && (
        <div className={`ms-2 d-flex flex-column justify-content-center gap-2`}>
          {category.templates.map(({ id, image }) => {
            return (
              <img
                src={image}
                key={id}
                alt=""
                className={`${styles.templateImg} w-100 cursor-pointer mb-2`}
                onClick={() => onClickImage(id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const SelectTemplateModal = ({
  campaignStepData,
  onHide,
  onSave,
  variables,
  setShowErrorModal,
  setShowTemplateModal,
}: {
  campaignStepData?: CampaignStepData;
  onHide: () => void;
  onSave: () => void;
  variables: Record<string, IVariableB>;
  setShowErrorModal: React.Dispatch<SetStateAction<boolean>>;
  setShowTemplateModal: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const [whichTab, setWhichTab] = useState<"recent" | "all">("all");
  // const [templateSearchText, setTemplateSearchText] = useState<string>("");
  // const [searchText, setSearchText] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | number>(
    "1",
  );
  const [saving, setSaving] = useState(false);
  const [savedId, setSavedId] = useState<string | number | null>(null);

  // const onInitialFetch = useCallback((id: string | number) => {
  //   setSelectedTemplateId(id);
  // }, []);

  // const {
  //   allTemplateIds,
  //   allTemplates,
  //   fetchMore,
  //   hasMore,
  //   templatesFetchStatus,
  // } = useSelectTemplate(onInitialFetch);

  const onSelectTemplate = useCallback(async () => {
    if (!selectedTemplateId || !campaignStepData) {
      setShowErrorModal(true);
      setShowTemplateModal(false);
      return;
    }

    if (
      !categories
        .find((val) => val.id === selectedCategoryId)
        ?.templates.find((val) => val.id === selectedTemplateId)?.enabled
    ) {
      setShowErrorModal(true);
      setShowTemplateModal(false);
      return;
    }

    try {
      setSaving(true);
      const payload = {
        ...campaignStepData,
        templateId: selectedTemplateId,
      };
      await addCampaignStepService(payload);
      onSave();
      setSavedId(payload.templateId);
    } catch (error) {
      const err = error as Error;
      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
    } finally {
      setSaving(false);
    }
  }, [
    campaignStepData,
    onSave,
    selectedTemplateId,
    setShowErrorModal,
    setShowTemplateModal,
  ]);

  const templateString = useMemo(() => {
    return categories
      .find((val) => val.id === selectedCategoryId)
      ?.templates.find((val) => val.id === selectedTemplateId)?.component;
    // const template = allTemplates[selectedTemplateId];
    // if (!template) {
    //   return;
    // }

    // return replaceKeyWithSpanB(template.template, variables);
  }, [selectedCategoryId, selectedTemplateId]);

  return (
    <div>
      <div
        className={`h-0 w-100 d-flex justify-content-end ${styles.btnPosition}`}
        id="closeViewTemplateModal"
      >
        <span
          className={`${styles.crossBtn}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div
        className="h-100"
        id="viewTemplateModalBody"
      >
        <div className="h-100 d-flex flex-column">
          <div
            className={`d-flex justify-content-between align-items-center mb-2 mb-md-0`}
          >
            <p className={`${styles.chooseTemplate}`}>Select Email Template</p>
          </div>
          {/* <div className="position-relative">
            <SearchBar
              className={`w-100 ${styles.search} px-2 mt-1`}
              inputClassName={`${styles.input}`}
              placeholder={`Search templates by name or text`}
              onChange={(e: any) => {
                setTemplateSearchText(e.target.value);
              }}
              value={templateSearchText}
              onSearch={(value: string) => setSearchText(value)}
            />
          </div> */}

          {false ? (
            <div
              style={{ height: "60vh" }}
              className="mt-3"
            >
              <Loader />
            </div>
          ) : (
            <div className={`mt-3 d-flex flex-column h-100`}>
              <div className="d-flex flex-wrap">
                {/* <div
                  className={`${styles.tab} ${
                    whichTab === "recent" ? styles.active : ""
                  }`}
                  onClick={() => {
                    setWhichTab("recent");
                  }}
                  id="recentTab"
                >
                  <span className="pe-1">
                    <img
                      src={clock}
                      alt=""
                      className={`${styles.clockImg}`}
                    />
                  </span>
                  <span> Recently Used</span>
                </div> */}
                <div
                  className={`${styles.tab}   ${
                    whichTab === "all" ? styles.active : ""
                  } `}
                  onClick={() => {
                    setWhichTab("all");
                  }}
                  id="allTemplateTab"
                >
                  <span className="pe-1">
                    <img
                      src={note}
                      alt=""
                      className={`${styles.noteImg}`}
                    />
                  </span>
                  <span>All Templates</span>
                </div>
              </div>

              <div
                className={`p-2 ${styles.templateWrapper}`}
                id="allTemplate"
              >
                <div className="h-100">
                  <div className="d-flex flex-column flex-md-row">
                    <div
                      className={`pe-3 ${styles.sidebarWrapper}`}
                      id="templateCategory"
                    >
                      <div className="mt-3">
                        <InfiniteScroll
                          style={{ maxHeight: "490px", overflowX: "hidden" }}
                          // loadMore={fetchMore}
                          // hasMore={hasMore}
                        >
                          {categories.map((category, idx) => {
                            return (
                              <TemplateDD
                                key={category.id}
                                category={category}
                                initialShow={idx === 0}
                                onClickImage={(id) => {
                                  setSelectedTemplateId(id);
                                  setSelectedCategoryId(category.id);
                                }}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                    <div
                      className={`p-2 d-flex flex-column align-items-end gap-2 ${styles.resWrapper}`}
                      id="resCategory"
                    >
                      <div className={`w-100 ${styles.scrollWrapper}`}>
                        {/* {templateString && <ParseHTML html={templateString} />} */}
                        {templateString ? templateString() : null}
                      </div>
                      <button
                        className={`${
                          savedId === selectedTemplateId
                            ? styles.selectedBtn
                            : styles.selectBtn
                        }`}
                        onClick={onSelectTemplate}
                        disabled={
                          !selectedTemplateId || savedId === selectedTemplateId
                        }
                      >
                        {saving ? (
                          "Selecting..."
                        ) : savedId === selectedTemplateId ? (
                          <span>
                            <span className="pe-1">
                              <i className="fa-solid fa-check"></i>
                            </span>
                            Selected
                          </span>
                        ) : (
                          "Select"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectTemplateModal;
