import React from "react";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  DropdownIndicatorProps,
  StylesConfig,
  Props,
} from "react-select";
import styles from "./CategorySelect.module.scss";

export interface CategorySelectOption {
  value: number;
  label: string;
  isDescriptionRequired?: boolean;
}

// Custom option component
const CustomOption: React.FC<OptionProps<CategorySelectOption>> = (props) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer `}>
      <span
        className={`form-check-label ps-2 cursor-pointer ${styles.brandName}`}
      >
        {props.label}
      </span>
    </div>
  </components.Option>
);

const CustomSingleValue: React.FC<
  SingleValueProps<CategorySelectOption, boolean>
> = (props) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data.label}</span>
    </div>
  </components.SingleValue>
);

const CustomDropdownIndicator: React.FC<
  DropdownIndicatorProps<CategorySelectOption, boolean>
> = (props) => (
  <components.DropdownIndicator {...props}>
    <span className={`${styles.indicator}`}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </span>
  </components.DropdownIndicator>
);
// Custom styles for the Select component
const customStyles: StylesConfig<CategorySelectOption, boolean> = {
  control: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #A5A5A5",
    width: "100%",
    borderRadius: "4px",
    minHeight: "30px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000000",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0px !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
    color: "#000",
    padding: "0 10px",
    minHeight: "31px",
    display: "flex",
    alignItems: "center",
  }),
  menuList: (provided) => ({
    ...provided,
    margin: "0px 10px",
    cursor: "pointer",
    fontSize: "11px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 3px 20px #00000029",
    borderRadius: "5px",
    padding: "8px 0",
  }),
};

interface CategorySelectProps extends Props<CategorySelectOption, boolean> {
  showError?: boolean;
}

// UserSelect component with dynamic options and placeholder
const CategorySelect: React.FC<CategorySelectProps> = ({
  showError,
  ...props
}) => {
  return (
    <Select
      className={`w-100 cursor-pointer ${showError ? styles.errorBorder : ""}`}
      classNamePrefix="dropdown"
      isSearchable={false}
      menuPortalTarget={document.body}
      styles={customStyles}
      isMulti={false}
      menuPosition="fixed"
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        DropdownIndicator: CustomDropdownIndicator,
        IndicatorSeparator: null,
      }}
      {...props}
    />
  );
};

export default CategorySelect;
