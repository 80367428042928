import { useCallback, useState } from "react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import createCampaignV2Service, {
  CreateCampaignV2Params,
} from "src/services/Campaign/createCampaignV2.service";
import {
  createCampaignPayload,
  validateCampaignStep,
} from "../../Helpers/AddCampaign.helpers";
import { useAddCampaignState } from "../../Hooks/useAddCampaignContext";
import styles from "./AddCampaignModal.module.scss";
import ChooseTemplate from "./Children/ChooseATemplate/ChooseATemplate";
import DiscardCampModal from "./Children/DiscardCampModal/DiscardCampModal";
import HeaderTimeline from "./Children/HeaderTimeline/HeaderTimeline";
import PrimaryDetails from "./Children/PrimaryDetails/PrimaryDetails";
import SequenceSummary from "./Children/SequenceSummary/SequenceSummary";
import SuccessMessage from "./Children/SucessMessage/SuccessMessage";
import { DripCampaignData } from "src/services/Campaign/getAllDripCampaigns.service";
import { useQueryClient } from "@tanstack/react-query";
import {
  GetAllDripCampaignsData,
  GetAllDripCampaignsQueryKey,
} from "../../../../Hooks/useDripCampaign";
import { ECampaignStatus } from "src/services/Campaign/getCampaignById";

const steps = [
  { number: 1, name: "Primary details" },
  { number: 2, name: "Choose a template" },
  { number: 3, name: "Sequence summary" },
];

const AddCampaignModal = ({
  onHide,
  onPrev,
}: {
  onHide: (successCampaign?: DripCampaignData) => void;
  onPrev: () => void;
}) => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useAddCampaignState();

  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const onAddCampaign = useCallback(
    (payload: CreateCampaignV2Params, campaignId: string | number) => {
      queryClient.setQueryData<GetAllDripCampaignsData>(
        GetAllDripCampaignsQueryKey,
        (prev) => {
          if (!prev) {
            return prev;
          }

          return {
            ...prev,
            pages: prev.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((segment) => {
                  if (segment.segmentId === payload.userSegmentId) {
                    return {
                      ...segment,
                      campaignTotalCount: segment.campaignTotalCount + 1,
                      campaignsData: [
                        ...segment.campaignsData,
                        {
                          emailSendCount: 0,
                          id: campaignId,
                          name: payload.name,
                          status: ECampaignStatus.Draft,
                        } as DripCampaignData,
                      ],
                    };
                  }

                  return segment;
                }),
              };
            }),
          };
        },
      );
    },
    [queryClient],
  );

  const handleAddCampaign = useCallback(async () => {
    const payload: CreateCampaignV2Params | null = createCampaignPayload(state);
    if (!payload) {
      dispatch("updateState", { showErrors: true });
      return;
    }

    try {
      setShowLoader(true);
      const res = await createCampaignV2Service(payload);
      dispatch("updateState", { campaignId: res.campaignId });
      onAddCampaign(payload, res.campaignId);
    } catch (e) {
      const err = e as Error;
      setActiveStep(3);

      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
    } finally {
      setShowLoader(false);
    }
  }, [dispatch, onAddCampaign, state]);

  const handleNextStep = useCallback(
    () =>
      setActiveStep((currentStep) => {
        if (!validateCampaignStep(state, currentStep)) {
          dispatch("updateState", { showErrors: true });
          return currentStep;
        }

        dispatch("updateState", {
          lastCompletedStep: currentStep,
        });

        if (currentStep === 3) {
          handleAddCampaign();
          return 4;
        }

        return Math.min(currentStep + 1, steps.length + 1);
      }),
    [dispatch, handleAddCampaign, state],
  );

  const handlePreviousStep = useCallback(
    () =>
      setActiveStep((currentStep) => {
        if (currentStep === 1) {
          onPrev();
        }

        if (currentStep === 3 && !validateCampaignStep(state, 3)) {
          dispatch("updateState", { showErrors: true });
          return currentStep;
        }

        return Math.max(currentStep - 1, 1);
      }),
    [dispatch, onPrev, state],
  );

  return (
    <div>
      <div className="p-3">
        <div className="text-center position-relative mb-4">
          <h4 className={`${styles.modalHead}`}>Add new campaign</h4>
          <div
            className={`${styles.closeModal} cursor-pointer`}
            onClick={() => {
              if (activeStep === 4) {
                onHide();
                return;
              }

              setShowModal(true);
            }}
          >
            <span>
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <DiscardCampModal
            showModal={showModal}
            onOkay={onHide}
            back={() => setShowModal(false)}
            onHide={() => setShowModal(false)}
          />
        </div>

        <HeaderTimeline
          steps={steps}
          activeStep={activeStep}
          lastCompletedStep={state.lastCompletedStep}
        />
        {activeStep !== 4 ? (
          <div>
            {" "}
            <div className="d-flex justify-content-center mt-4 pt-3 me-5">
              <button
                className={`${styles.prevBtn} me-2`}
                onClick={handlePreviousStep}
                disabled={showLoader || activeStep === 4}
              >
                <span className="pe-1">
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                Previous
              </button>
              <button
                className={`${styles.prevBtn}`}
                onClick={handleNextStep}
                disabled={showLoader || activeStep === 4}
              >
                Next
                <span className="ps-1">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </div>
            <div className={`d-flex justify-content-end`}>
              <div
                className={`px-3 ${styles.brandBox} ${styles.translateBrand}`}
              >
                {state.brand?.imageUrl && state.brand.imageUrl.trim() !== "" ? (
                  <AxiosImg
                    url={state.brand.imageUrl}
                    className={`rounded-circle`}
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  <UserAvatar
                    name={state.brand?.label ?? "NA"}
                    size={20}
                  />
                )}
                <span className="ps-1">{state.brand?.label ?? "NA"}</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={`${
            activeStep === 4 ? styles.successContainer : styles.stepContainer
          }`}
        >
          {activeStep === 1 && <PrimaryDetails />}
          {activeStep === 2 && <ChooseTemplate />}
          {activeStep === 3 && <SequenceSummary />}
          {activeStep === 4 && (
            <SuccessMessage
              isLoading={showLoader}
              onHide={onHide}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCampaignModal;
