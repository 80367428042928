import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./LiveSendMessage.module.scss";
import sendLogo from "src/assets/images/sendIcon.svg";
import LiveSendingAs, { ESendTypes } from "../LiveSendingAs";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import { v4 as uuid } from "uuid";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { selectedCustomerSelector } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.selectors";
import axios from "axios";
import {
  fetchMsgData,
  sendLiveMsg,
  SendLiveMsgP,
} from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.thunks";
import SendMailDetails from "./Children/SendMailDetails/SendMailDetails";
import { getAllCannedResponse } from "src/services/LiveChat/Settings/cannedResponses/getAllCannedResponse";
import { getInnerText, isHTMLEmpty, trimPDIVBR } from "src/utils/utils";
import { appendMessageBeforeSending } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import SendingInfoWrapper from "../../../LiveCustomerSB/Children/StartChatModal/Children/StartChatEditor/children/SendingInfoWrapper/SendingInfoWrapper";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import IntegrationFailureModal from "src/components/IntegrationFailureModal/IntegrationFailureModal";
import { deleteTinyMCEDraft } from "src/components/TinyMCEContainer/Children/TinyMCEEditor";
import { TemplateProvider } from "src/components/ScheduleModal/Children/MainSection/TemplateContext";
import ScheduleModal from "src/components/ScheduleModal/ScheduleModal";
import PlanModal from "src/components/PlanUpgrade/Children/PlanModal/PlanModal";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";
import alertIcon from "src/assets/images/alert.svg";
import { SOCIAL_CHANNELS } from "src/services/LiveChat/messageExchange/getAllCustomers";
import { TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { ReplyOption } from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAsV2";
import { EChannel } from "src/enums/EChannel";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Editor as TinyMceEditor } from "tinymce";
import { useSyncedState } from "src/hooks/useSyncedState";

interface Props {
  updateSidebarCount: () => void;
  canReply?: boolean;
  isConnected?: boolean;
  showScheduleMsgModal: (e: any) => void;
  scheduleMessageCount?: number;
}

function LiveSendMessage({
  updateSidebarCount,
  canReply,
  isConnected,
  scheduleMessageCount,
  showScheduleMsgModal,
}: Props) {
  const formRef: any = useRef(null);
  const sendMailDetailRef: any = useRef();
  const [sendType, setSendType] = useState<ESendTypes>(ESendTypes.Message);
  const [replyOption, setReplyOption] = useState<ReplyOption>(
    ReplyOption.Reply,
  );

  // const [message, setMessage]: any = useState("");
  const [message, messageRef, setMessage] = useSyncedState("");

  const [showReplyType, setShowReplyType]: any = useState(true);
  const [showError, setShowError] = useState(false);
  const errorTimeout = useRef(null as any);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  const scheduleMessageVisible = useMemo(() => {
    return ui_visibility?.includes("live_chat_schedule_message");
  }, [ui_visibility]);
  const { activeCustomerInfo, sendMsgAJAXStatus, integrationFailure } =
    useAppSelector((state) => state.chatWidExhMsg);
  const dispatch = useAppDispatch();
  const [showSending, setShowSending] = useState(false);
  const [canDeleteInline, setCanDeleteInline] = useState(true);
  const editorRef = useRef<TinyMceEditor | null>(null);
  const [messageChannel, setMessageChannel] = useState<EChannel>(
    activeCustomerInfo?.chatChannelType ?? EChannel.email,
  );

  const [to, setTo] = useState<string>(
    activeCustomerInfo?.chatChannelType != EChannel.email
      ? ""
      : activeCustomerInfo.email,
  );

  useEffect(() => {
    if (activeCustomerInfo?.email) {
      setTo(
        activeCustomerInfo?.chatChannelType &&
          activeCustomerInfo?.chatChannelType != EChannel.email
          ? ""
          : activeCustomerInfo.email,
      );
    }

    if (
      activeCustomerInfo?.chatChannelType &&
      activeCustomerInfo.chatChannelType == EChannel.email
    ) {
      // If the active customer's chat channel type is email, allow inline deletion
      setCanDeleteInline(true);
    }
  }, [activeCustomerInfo?.email, activeCustomerInfo?.chatChannelType]);

  /**
   * Calculate if integration is failed
   */
  const isIntegrationFailed = useMemo(() => {
    // Check if integration failure has happened
    if (!integrationFailure) {
      return false;
    }

    // Check if active ticket selected from is same as failed brand email
    if (integrationFailure.brand?.email !== activeCustomerInfo?.brand?.email) {
      return false;
    }

    return true;
  }, [activeCustomerInfo?.brand?.email, integrationFailure]);

  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  function sendButtonHandler(ev: React.FormEvent) {
    ev.preventDefault();
    const form = ev.target as HTMLFormElement;

    const msgTrimed = trimPDIVBR(message);

    if (
      activeCustomerInfo &&
      activeCustomerInfo.chatId != "" &&
      (!isHTMLEmpty(msgTrimed) ||
        (selectedFiles && selectedFiles.length !== 0)) && //checking either message and selected files not empty, then only allowing it to send message
      isFileUploading === false
    ) {
      if (
        messageChannel == EChannel.email &&
        (replyOption === ReplyOption.Forward ||
          !activeCustomerInfo.isAnonymousCustomer) &&
        to === ""
      ) {
        // Show a toast notification if the message channel is email, the reply option is forward or the customer is not anonymous, and the 'to' field is empty
        pushTheToast({
          type: "warning",
          text: "Please select 'to' email address!",
          position: "top-right",
        });
        setShowError(true);
        return;
      }
      setShowError(false);
      const newUuid = uuid();
      const sendMessagePayload: SendLiveMsgP = {
        chat_id: activeCustomerInfo.chatId,
        message: msgTrimed,
        to: messageChannel === EChannel.email ? to : undefined,
        channel: messageChannel,
        messageType:
          sendType == ESendTypes.Note
            ? "note"
            : replyOption === ReplyOption.Forward
              ? "forward"
              : "message",
        attachmentBatchNumber: selectedFiles
          ? selectedFiles.map(function (value: any, index: any) {
              return value.batchNumber;
            })
          : [],
        uuid: newUuid, // this is the unique id for the message that is being sent
        attachments: selectedFiles
          ? selectedFiles.map((attachment: any, index: number) => {
              return {
                attachmentId: attachment.attachmentId,
                attachmentName: attachment.attachmentName,
                attachmentBatchNumber: attachment.batchNumber,
                attachmentType:
                  attachment.file?.type || attachment.attachmentType,
                attachmentUrl: attachment.attachmentURL,
                embedded: attachment.embedded,
                contentId: attachment.contentId,
              };
            })
          : [],
      };
      dispatch(
        sendLiveMsg({
          ...sendMessagePayload,
          callback: () => {
            updateSidebarCount();
          },
        }),
      );
      setMessage("");
      setSelectedFiles([]); // clear the attachments selected
    } else {
      setShowError(true);
      clearTimeout(errorTimeout.current);
      errorTimeout.current = setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }

  const handleSendMessageType = (type: ESendTypes) => {
    if (showReplyType) {
      setSendType(type);
    } else {
      setSendType(ESendTypes.Note);
    }
  };

  useEffect(() => {
    if (canReply) {
      setShowReplyType(true);
      setSendType(ESendTypes.Message);
    } else {
      setShowReplyType(false);
      setSendType(ESendTypes.Note);
    }
  }, [canReply]);

  const [automatedFolloupModal, setAutomatedFolloupModal] = useState(false);
  const [scheduleWith, setScheduleWith] = useState<string | null>(null);

  const onShow = (e: any, value: string | null) => {
    e.stopPropagation();
    setScheduleWith(value);
    setAutomatedFolloupModal(true);
    setSchedulePopoverState(false);
  };
  const onHide = () => {
    setAutomatedFolloupModal(false);
  };

  const popover = (
    <Popover
      id="popover-basic"
      bsPrefix={`popover ${styles.popWrapper}`}
    >
      <Popover.Body className={`${styles.popoverBody}`}>
        <div>
          {scheduleMessageCount && scheduleMessageCount > 0 ? (
            <div
              className={`${styles.scheduleList}`}
              onClick={(e) => {
                showScheduleMsgModal(e);
                setSchedulePopoverState(false);
              }}
            >
              Edit scheduled message
            </div>
          ) : (
            <>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, null)}
              >
                Schedule
              </div>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, "resolveChat")}
              >
                Schedule and resolve
              </div>
              <div
                className={`${styles.scheduleList}`}
                onClick={(e) => onShow(e, "resolveAndCreateTicket")}
              >
                Schedule and resolve + create ticket
              </div>
            </>
          )}
        </div>
      </Popover.Body>
    </Popover>
  );

  const [schedulePopoverState, setSchedulePopoverState] = useState(false);
  const autoCompleteConfigs = useMemo(() => {
    // cache object to store the search results
    const cache: { [key: string]: any } = {};

    return [
      {
        prefix: "#",
        fetchHandler: async (
          searchTerm: string,
          maxLimit: number,
          meta: any,
        ) => {
          try {
            // Check if the search results exist in the cache
            if (cache[searchTerm]) {
              return cache[searchTerm];
            }

            //fetching canned response from backend
            const cannedResponseData: getAllCannedResponse =
              await getAllCannedResponse({
                start: 0,
                limit: maxLimit,
                integrationId: activeCustomerInfo?.integrationId + "",
                searchTerm: searchTerm,
                searchShortCutOnly: true,
              });

            //getting the required values from backend response
            const searchResults = Object.values(
              cannedResponseData.cannedResponses,
            ).map((cannedResponse) => {
              return {
                id: cannedResponse.cannedResponseId,
                value: cannedResponse.messageText,
                label: getInnerText(cannedResponse.messageText),
                classes: [styles.textWrap],
              };
            });

            // Store the search results in the cache
            cache[searchTerm] = searchResults;

            return searchResults;
          } catch (e: any) {
            console.log(e.message);
            return [];
          }
        },
      },
    ];
  }, [activeCustomerInfo?.integrationId]);

  const disabledLiveChat = useMemo(() => {
    return disabledFeatures?.includes("send_chat") ?? false;
  }, [disabledFeatures]);

  const [showCannotSendModal, setShowCannotSendModal] = useState(false);

  const handleCannotSend: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowCannotSendModal(true);
    }, []);

  const handleRemoveImages = () => {
    // Social chats do not support embedded attachments, so when the user switches channel, it will delete the inline images and keep attachments in list
    const editorWindow = editorRef.current?.getBody();
    const images = editorWindow?.querySelectorAll("img");
    images?.forEach((img) => img.remove());
  };

  return (
    <div className={`p-2 ${styles.sendMessage}`}>
      <Form
        ref={formRef}
        onSubmit={sendButtonHandler}
      >
        {/* Jan 27 Adding To and From when it is a whatsapp chat */}
        {sendType === ESendTypes.Message &&
        activeCustomerInfo &&
        messageChannel &&
        SOCIAL_CHANNELS.includes(messageChannel) ? (
          <div>
            <div
              className={`d-flex ${styles.sendingDetails} justify-content-between`}
            >
              <div className={`${styles.sendingBlock}`}>
                <SendingInfoWrapper
                  activeMessageType={messageChannel}
                  showSending={showSending}
                  selectedToPhone={activeCustomerInfo.chatChannelToDetail}
                  setSelectedToPhone={() => {}}
                  selectedFromPhone={activeCustomerInfo.chatChannelFromDetail}
                  setSelectedFromToPhone={() => {}}
                  showError={false}
                  canFetchInitial={false}
                  isDisabled={true}
                />
              </div>
              <OpenClose
                onClick={() => setShowSending((prev) => !prev)}
                show={showSending}
              />
            </div>
          </div>
        ) : (
          sendType === ESendTypes.Message &&
          (isConnected === false ||
            replyOption === ReplyOption.Forward ||
            (SOCIAL_CHANNELS.includes(
              activeCustomerInfo?.chatChannelType + "",
            ) &&
              messageChannel == EChannel.email)) && (
            <SendMailDetails
              src={sendMailDetailRef}
              isInModal={false}
              isIntegrationFailed={isIntegrationFailed}
              integrationFailType={integrationFailure?.type}
              from={
                activeCustomerInfo?.brand
                  ? (activeCustomerInfo.brand.email as string)
                  : ""
              }
              to={
                replyOption !== ReplyOption.Forward
                  ? activeCustomerInfo?.isAnonymousCustomer
                    ? activeCustomerInfo.name
                    : to
                  : to
              }
              setTo={setTo}
              disableToInput={
                replyOption !== ReplyOption.Forward &&
                (!activeCustomerInfo?.chatChannelType ||
                  activeCustomerInfo.chatChannelType == EChannel.email)
              }
            />
          )
        )}
        <LiveSendingAs
          showReply={showReplyType}
          sendType={sendType}
          setSendType={handleSendMessageType}
          selectedTemplate={(templateText: string) => {
            setMessage((msg: string) => templateText + msg);
          }}
          showAddTemplates={true}
          replyOption={replyOption}
          setReplyOption={(option) => {
            setReplyOption(option);
            if (option === ReplyOption.Forward) {
              if (activeCustomerInfo?.email) {
                setTo(
                  activeCustomerInfo?.chatChannelType &&
                    activeCustomerInfo?.chatChannelType != EChannel.email
                    ? to === ""
                      ? ""
                      : to
                    : activeCustomerInfo.email,
                );
              } else {
                setTo("");
              }
              setMessageChannel(EChannel.email);
            } else {
              if (activeCustomerInfo?.email) {
                setTo(
                  activeCustomerInfo?.chatChannelType &&
                    activeCustomerInfo?.chatChannelType != EChannel.email
                    ? to === ""
                      ? ""
                      : to
                    : activeCustomerInfo.email,
                );
              }
            }
          }}
          channel={messageChannel}
          setChannel={(channel) => {
            // Update the message channel state with the selected channel
            setMessageChannel(channel);

            // If the selected channel is not email, disable inline deletion and remove images
            if (channel !== EChannel.email) {
              setCanDeleteInline(false);
              handleRemoveImages();
            } else {
              // If the selected channel is email, enable inline deletion
              setCanDeleteInline(true);
            }
          }}
          allowedChannels={activeCustomerInfo?.replyOptions ?? [EChannel.email]}
          disableChannel={replyOption == ReplyOption.Forward}
        />
        <TinyMCEContainer
          name="message"
          className={`${styles.message} ${showError ? "redBorder" : ""} ${
            sendType === ESendTypes.Note ? styles.active : ""
          }`}
          // editorClassName={`${styles.editor}`}
          autosavePrefix={`HELPDESK_LIVE_CHAT_CONV_${activeCustomerInfo?.chatId}`}
          value={messageRef.current}
          onChange={(value) => {
            setMessage(value);
          }}
          mentionsEnabled={sendType === ESendTypes.Note}
          options={{
            placeholder:
              sendType !== ESendTypes.Note
                ? `Use "#" to recall related templates`
                : "Use @ to mention your team members",
            max_height: 150,
          }}
          uniqueID={uuid()}
          buttons={
            <>
              {disabledLiveChat && sendType !== ESendTypes.Note ? (
                <PlanUpgrade showOnlyButton={true} />
              ) : (
                <div className={`d-flex`}>
                  {isIntegrationFailed && sendType !== ESendTypes.Note && (
                    <div
                      className={`d-flex align-items-center ${styles.integrationFail}`}
                    >
                      <span className="me-1">
                        <img
                          src={alertIcon}
                          alt="alert"
                          width={12}
                          height={12}
                        />
                      </span>
                      <span>Cannot send</span>
                      <button onClick={handleCannotSend}>See why?</button>
                    </div>
                  )}
                  <div className={`d-flex overflow-hidden`}>
                    <Button
                      type="submit"
                      className={`d-flex align-items-center mx-1 ${styles.sendBtn} ${styles["translate-up"]}`}
                      disabled={
                        (isIntegrationFailed && sendType !== ESendTypes.Note) ||
                        isFileUploading === true
                      }
                    >
                      <span className={`my-auto pe-2`}>Send</span>
                      <img
                        src={sendLogo}
                        className={`my-auto`}
                        alt="send"
                      />
                    </Button>
                    {scheduleMessageVisible &&
                    (activeCustomerInfo?.isConnected !== true ||
                      activeCustomerInfo?.isCustomerOnline !== true) ? (
                      <OverlayTrigger
                        trigger="click"
                        placement="top-end"
                        overlay={popover}
                        onToggle={(nextShow) => {
                          setSchedulePopoverState(nextShow);
                        }}
                        // onEntering={() => setSchedulePopoverState(true)}
                        // onExiting={() => setSchedulePopoverState(false)}
                        show={schedulePopoverState}
                        rootClose={true}
                      >
                        <Button
                          className={`d-flex align-items-center my-auto mx-1 ${
                            styles.sendBtn
                          } ${schedulePopoverState ? styles.active : ""}  ${
                            styles["translate-up"]
                          } px-2 py-2`}
                          role="button"
                        >
                          <span>
                            <i className="fa-regular fa-clock"></i>
                          </span>
                          <span className={`mx-2 ${styles.line}`}></span>
                          <span>
                            <i
                              className={`fa-solid fa-chevron-${
                                schedulePopoverState ? "up" : "down"
                              }`}
                            ></i>
                          </span>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              <Modal
                backdropClassName={`${styles.modalBack}`}
                show={automatedFolloupModal}
                className={`${styles.modalClass}`}
                onHide={onHide}
                dialogClassName={`${styles.modalScheduleDialog}`}
                contentClassName={`${styles.modalScheduleContent}`}
                enforceFocus={false}
              >
                <TemplateProvider scheduleMessageFor="liveChat">
                  <ScheduleModal
                    onHide={onHide}
                    scheduleMessageFor="liveChat"
                    scheduleWith={scheduleWith}
                  />
                </TemplateProvider>
              </Modal>
            </>
          }
          disableAttachment={false}
          areAttachmentsPublic={true}
          disableEmbedding={
            //Added to disable embedding attachments for whatsapp as it does not support
            activeCustomerInfo &&
            messageChannel &&
            SOCIAL_CHANNELS.includes(messageChannel)
              ? true
              : false
          }
          disableFormatingsAndEmbedding={
            //Added to disable embedding attachments for whatsapp as it does not support
            activeCustomerInfo &&
            messageChannel &&
            SOCIAL_CHANNELS.includes(messageChannel) &&
            messageChannel !== "whatsapp"
              ? true
              : false
          }
          customAllowedAttachmentTypeForFilePicker={
            activeCustomerInfo && messageChannel === "instagram"
              ? TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES
              : undefined
          }
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          autoCompleteMenuConfigs={autoCompleteConfigs}
          tinymceEditorRef={editorRef}
          canDeleteInline={canDeleteInline}
        />
      </Form>

      {/* Integration Failure Modal  */}
      {isIntegrationFailed && integrationFailure && (
        <IntegrationFailureModal
          show={showCannotSendModal}
          onHide={() => setShowCannotSendModal(false)}
          data={integrationFailure}
        />
      )}
    </div>
  );
}

export default LiveSendMessage;
