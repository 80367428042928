import { axiosJSON } from "src/globals/axiosEndPoints";
import { TimeLineSubEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export interface TimelineSubEventsParam {
  referenceType: string | number;
  referenceId: string | number;
  cursor: string | null;
}

interface TimeLineSubEventResponse {
  events: TimeLineSubEvent[];
  cursor: string | null;
}

export async function getTimelineSubEvents(payload: TimelineSubEventsParam) {
  const { data: res } = await axiosJSON.post(
    `/api/timeline/getTimelineSubEvents`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as TimeLineSubEventResponse;
}
