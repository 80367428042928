import { ReactElement, useState } from "react";
import styles from "./DesktopMobilePreview.module.scss"

const DesktopMobilePreview = ({children}: {children: ReactElement}) => {
  const [view, setView] = useState<"Desktop" | "Mobile">("Desktop");
  return (
    <div
      className={`${styles.emailPreviewWrapper} d-flex justify-content-center align-item-center flex-column mt-4 pb-2`}
    >
      <div>
        <div
          className={`d-flex flex-md-row align-items-md-center justify-content-center ${styles.btnWrapper}`}
        >
          <button
            className={`mb-2 mb-md-0 ${styles.tabsBtn} ${
              view === "Desktop" && styles.active
            }`}
            onClick={() => setView("Desktop")}
          >
            <span className="pe-1"></span>
            Desktop
          </button>
          <button
            className={`mb-2 mb-md-0 ${styles.mobileTabsBtn} ${
              view === "Mobile" && styles.active
            }`}
            onClick={() => setView("Mobile")}
          >
            <span className="pe-1"></span>
            Mobile
          </button>
        </div>
      </div>
      <div
        className={`${styles.previewWrapper} ${
          view === "Mobile" && styles.mobilePreviewSection
        }`}
      >
        <div className={`py-4 px-4 ${styles.previewSection} `}>{children}</div>
      </div>
    </div>
  );
}

export default DesktopMobilePreview;