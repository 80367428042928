import { useCallback, useState } from "react";
import styles from "./DeleteTemplate.module.scss";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import deleteTemplateService from "src/services/Campaign/deleteTemplate.service";
import { Spinner } from "react-bootstrap";

const DeleteTemplate = ({
  template,
  onHide,
  onDelete,
}: {
  template: IEmailTemplateVariant;
  onHide: () => void;
  onDelete: () => void;
}) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteTemplate = useCallback(async () => {
    try {
      setIsLoading(true);
      await deleteTemplateService({
        categoryId: template.categoryId,
        templateId: template.templateId,
        variantId: template.variantId,
      });
      setSuccess(true);
      onDelete();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, [template]);

  return (
    <div className={`${styles.overlayWrapper}`}>
      <div className={`${styles.deleteOverlay}`}>
        {success ? (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div>
              <div className={`${styles.successCheck}`}>
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
            </div>
            <span className={`mt-2 ${styles.doneText}`}>
              {template.fileName} deleted
            </span>{" "}
          </div>
        ) : (
          <div>
            <h6 className={`text-center mb-3 ${styles.overlayHead}`}>
              Are you sure to delete {template.fileName}?
            </h6>
            <div className="d-flex justify-content-center align-items-center">
              <button
                className={`me-2 ${styles.cancelBtn}`}
                onClick={onHide}
              >
                No
              </button>
              <button
                className={`${styles.submitBtn}`}
                onClick={onDeleteTemplate}
              >
                Yes
                {isLoading ? (
                  <Spinner
                    size="sm"
                    variant="basic"
                  />
                ) : null}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteTemplate;
