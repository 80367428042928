import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import updateEmailTemplateVariantService from "src/services/Campaign/updateEmailTemplateVariant.service";
import { InfiniteGetAllEmailTemplate } from "./useFetchCampaignTemplates";

const updateTemplateVariant = (
  data: InfiniteGetAllEmailTemplate,
  categoryId: string | number,
  templateId: string | number,
  variantId: string | number,
  newVariantName?: string,
): InfiniteGetAllEmailTemplate => ({
  ...data,
  pages: data.pages.map((page) => ({
    ...page,
    allTemplateCategories: page.allTemplateCategories.map((category) =>
      category.id === categoryId
        ? {
            ...category,
            templates: category.templates.map((template) =>
              template.id === templateId
                ? {
                    ...template,
                    variants: template.variants.map((variant) =>
                      variant.id === variantId
                        ? { ...variant, name: newVariantName || variant.name }
                        : variant,
                    ),
                  }
                : template,
            ),
          }
        : category,
    ),
  })),
});

const useUpdateTemplateVariant = (queryKeyAllTemplates?: QueryKey) => {
  const queryClient = useQueryClient();

  return useMutation(updateEmailTemplateVariantService, {
    onMutate: async ({ categoryId, templateId, variantId, ...rest }) => {
      let previousAllTemplatesData: InfiniteGetAllEmailTemplate | undefined =
        undefined;

      const templateVariantKey = [
        "getEmailTemplateVariantService",
        categoryId,
        templateId,
        variantId,
      ];
      await queryClient.cancelQueries(templateVariantKey);

      const previousVariantData =
        queryClient.getQueryData<IEmailTemplateVariant>(templateVariantKey);

      if (previousVariantData) {
        queryClient.setQueryData<IEmailTemplateVariant>(templateVariantKey, {
          ...previousVariantData,
          heading: rest.heading ? rest.heading : previousVariantData.heading,
          design: rest.design ? rest.design : previousVariantData.design,
          html: rest.html ? rest.html : previousVariantData.html,
          variantName: rest.variantName
            ? rest.variantName
            : previousVariantData.variantName,
          logoUrl: rest.logoUrl ? rest.logoUrl : previousVariantData.logoUrl,
        });
      }

      if (queryKeyAllTemplates) {
        await queryClient.cancelQueries(queryKeyAllTemplates);

        previousAllTemplatesData =
          queryClient.getQueryData<InfiniteGetAllEmailTemplate>(
            queryKeyAllTemplates,
          );

        if (previousAllTemplatesData) {
          queryClient.setQueryData<InfiniteGetAllEmailTemplate>(
            queryKeyAllTemplates,
            updateTemplateVariant(
              previousAllTemplatesData,
              categoryId,
              templateId,
              variantId,
              rest.variantName,
            ),
          );
        }
      }

      return { previousAllTemplatesData, previousVariantData };
    },
    onError: (err, { categoryId, templateId, variantId }, context) => {
      if (context?.previousAllTemplatesData && queryKeyAllTemplates) {
        queryClient.setQueryData(
          queryKeyAllTemplates,
          context.previousAllTemplatesData,
        );
      }

      if (context?.previousVariantData) {
        const templateVariantKey = [
          "getEmailTemplateVariantService",
          categoryId,
          templateId,
          variantId,
        ];

        queryClient.setQueryData(
          templateVariantKey,
          context.previousVariantData,
        );
      }
    },
  });
};

export default useUpdateTemplateVariant;
