import { Modal, Spinner } from "react-bootstrap";
import errorIcon from "src/assets/images/errorCircle.png";
import styles from "./ErrorModal.module.scss";

const ErrorModal = ({
  showModal,
  showLoader,
  retry,
  back,
  onHide,
}: {
  showModal: boolean;
  showLoader: boolean;
  retry: () => void;
  back: () => void;
  onHide: () => void;
}) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      id="MainSBPricingErrorModal"
      centered
    >
      <div className="px-4">
        <div className={`${styles.topIcon} mb-3 mx-auto`}>
          <img
            src={errorIcon}
            alt="error"
            width={35}
            height={35}
          />
        </div>
        <div
          className={`${styles.title} mb-2 mx-auto`}
        >{`Oops! Something went wrong`}</div>
        <div className={`${styles.subtitle} mx-auto mb-3`}>
          {`We're unable to process your upgrade request at the moment. Please try again later or contact support if the issue persists.`}
        </div>
        <div className="d-flex justify-content-center mb-2 px-2 pt-1">
          <button
            className={`px-2 me-2 ${styles.cancelBtn}`}
            onClick={back}
          >
            Back
          </button>
          <button
            className={`px-2 ${styles.checkBtn} d-flex justify-content-center align-items-center`}
            onClick={retry}
          >
            {showLoader && (
              <Spinner
                size="sm"
                className="me-2"
              />
            )}
            <span>Retry</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
