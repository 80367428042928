import { axiosJSON } from "src/globals/axiosEndPoints";
import { GetAllArticles } from "./getAllArticles";

interface ArticleData {
  articleId: number | string;
  articleName: string;
  integrationId: number | string;
  articleStatus: "public" | "draft";
  createdAt: Date;
  createdAtGmt: Date;
  generatedBy?: "ai" | "manual";
}

interface IArticleCategoryData {
  categoryId: number | string;
  categoryName: string;
  integrationId: number | string;
  articles: Array<ArticleData>;
  articleCount: number;
  isUserCanActionPerformCategory: boolean;
}

export interface IArticleCategoryDataV2 extends GetAllArticles {
  categoryId: number | string;
  categoryName: string;
  integrationId: number | string;
  searchTerm?: string;
  isUserCanActionPerformCategory: boolean;
}

export interface IGetAllArticleCategoryV2 {
  categories: { [categoryId: string | number]: IArticleCategoryDataV2 };
  categoryIds: Array<number | string>;
  metaData: {
    count: number;
    total: number;
  };
}

export interface GetAllArticleCategoriesParamsV2 {
  searchTerm?: string;
  integrationId: number | string;
  start: number;
  limit: number;
  hasArticleOnly?: boolean;
}

export async function getAllArticleCategoryV2(
  params: GetAllArticleCategoriesParamsV2
) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/getAllCategoryV2",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  const ret: IGetAllArticleCategoryV2 = {
    categories: {},
    categoryIds: [],
    metaData: {
      count: 0,
      total: 0,
    },
  };
  ret.metaData.count = res.metaData.currentCount;
  ret.metaData.total = res.metaData.totalCount;
  res.data.forEach((category: IArticleCategoryData) => {
    const allArticles = normalizeArticleData(
      category.articles ?? [],
      category.articleCount
    );
    ret.categories[category.categoryId] = {
      ...category,
      ...allArticles,
    };
    ret.categoryIds.push(category.categoryId);
  });
  return ret;
}

function normalizeArticleData(data: any, total: any) {
  const allArticles: GetAllArticles = {
    articles: {},
    articlesIds: [],
    ajaxStatus: "fulfilled",
    metaData: {
      count: 0,
      total: 0,
    },
  };

  data.forEach((article: ArticleData, index: number) => {
    const id = parseInt(article.articleId + "");
    allArticles.articlesIds.push(article.articleId);
    allArticles.articles[id] = article;
  });
  allArticles.metaData = {
    count: allArticles.articlesIds.length,
    total: total,
  };

  return allArticles;
}
