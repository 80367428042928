import { useEffect, useRef, useState } from "react";
import styles from "./PreviewTemplate.module.scss";

const PreviewTemplate = ({ html }: { html: string }) => {
  const [activeTab, setActiveTab] = useState<"desktop" | "mobile">("desktop");
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument;
      if (iframeDoc) {
        // Write the HTML content into the iframe
        iframeDoc.open();
        iframeDoc.write(html);
        iframeDoc.close();

        // Apply custom scrollbar styles to the iframe body
        const iframeBody = iframeDoc.body;
        if (iframeBody) {
          const style = iframeDoc.createElement("style");
          style.innerHTML = `
            * {
              margin: 0;
              scrollbar-width: thin;
              scrollbar-color: transparent transparent;
            }
            *:hover {
              scrollbar-color: #ff849988 rgba(145, 145, 145, 0.5);
            }
            *::-webkit-scrollbar {
              width: 0.4rem;
              height: 0.5rem;
            }
            *::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px transparent;
              border-radius: 10px;
            }
            *::-webkit-scrollbar-thumb {
              background-color: transparent;
              border-radius: 10px;
              outline: none;
            }
            *:hover::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(145, 145, 145, 0.5);
            }
            *:hover::-webkit-scrollbar-thumb {
              background-color: #ff849988;
            }
          `;
          iframeDoc.head.appendChild(style);
        }
      }
    }
  }, [html]);

  return (
    <div className={styles.container}>
      <div className={styles.tabButtons}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "desktop" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("desktop")}
        >
          Desktop
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "mobile" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("mobile")}
        >
          Mobile
        </button>
      </div>
      <div
        className={`${styles.content} ${
          activeTab === "mobile" ? `${styles.mobilePreviewSection} mx-auto` : ""
        }`}
      >
        <iframe
          ref={iframeRef}
          className={styles.iframe}
          style={{
            width: activeTab === "mobile" ? "360px" : "100%",
            height: "100%",
            border: "none",
          }}
          title="Email Preview"
        ></iframe>
      </div>
    </div>
  );
};

export default PreviewTemplate;
