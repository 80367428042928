import { useCallback, useMemo, useState } from "react";
import styles from "./StripeQuestion.module.scss";
import check from "src/assets/images/check.png";
import eyeOpen from "src/assets/images/eyeOpen.png";
import eyeClosed from "src/assets/images/eyeClose.png";
import useSaveStepsAndQuestions from "src/features/ReturnAutoWorkFlow/hooks/useSaveStepsAndQuestions";
import {
  StepQuestion,
  StripeDetails,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { Spinner } from "react-bootstrap";
import MoveToNext from "../../MoveToNext/MoveToNext";
import stepDetailsStyles from "../../../../StepDetails/StepDetails.module.scss";

const StripeQuestion = ({
  stepId,
  questionId,
  updateReturnPreview,
}: {
  stepId: string;
  questionId: string;
  updateReturnPreview: ({
    stepQuestion,
  }: {
    stepQuestion: StepQuestion | null;
  }) => void;
}) => {
  const {
    questionNumber,
    stepQuestion,
    savingStepAndQuestionData,
    showErrors,
    saveStepAndQuestionData,
  } = useSaveStepsAndQuestions({ stepId, questionId });

  const stripeDetails = useMemo(() => {
    return stepQuestion.specificQuestionValue as null | StripeDetails;
  }, [stepQuestion]);

  const [showStripeErrors, setStripeShowErrors] = useState(showErrors);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [showPublishableKey, setShowPublishableKey] = useState(false);
  const [secretKey, setSecretKey] = useState(stripeDetails?.secretKey ?? "");
  const [publishableKey, setPublishableKey] = useState(
    stripeDetails?.secretPublishableKey ?? "",
  );

  const toggleSecretKeyVisibility = useCallback(() => {
    setShowSecretKey((prevState) => !prevState);
  }, []);

  const togglePublishableKeyVisibility = useCallback(() => {
    setShowPublishableKey((prevState) => !prevState);
  }, []);

  const saveStripeDetails = useCallback(() => {
    if (
      (secretKey !== stripeDetails?.secretKey ||
        publishableKey !== stripeDetails?.secretPublishableKey ||
        stripeDetails.error ||
        !stripeDetails.verified) &&
      secretKey.trim() &&
      publishableKey.trim()
    ) {
      saveStepAndQuestionData({
        specificQuestionSaveValue: {
          value: { secretKey, secretPublishableKey: publishableKey },
        },
      });
      updateReturnPreview({
        stepQuestion: { ...stepQuestion },
      });
    } else {
      setStripeShowErrors(true);
    }
  }, [
    stepId,
    questionId,
    stripeDetails,
    secretKey,
    publishableKey,
    saveStepAndQuestionData,
  ]);

  return (
    <div
      className={`pt-3 mb-2 ${styles.contentWrapper} ${
        stepDetailsStyles.query
      } ${stripeDetails?.verified ? stepDetailsStyles.activeLine : ""} ${
        showErrors && !stripeDetails?.verified
          ? stepDetailsStyles.errorLine
          : ""
      }`}
    >
      <div className="px-3">
        <p className={`${styles.queHead}`}>
          Q{questionNumber}. {stepQuestion.questionTitle}
        </p>
        <div className="mb-3">
          <label
            htmlFor="secretKeyInput"
            className={`form-label ${styles.formLabel}`}
          >
            Secret Key
          </label>
          <div className="position-relative">
            <input
              type={showSecretKey ? "text" : "password"}
              className={`form-control ${styles.formBox} ${
                showStripeErrors && !secretKey.trim() ? styles.error : ""
              }`}
              id="secretKeyInput"
              placeholder="Enter secret key here..."
              value={secretKey}
              onChange={(e) => {
                setSecretKey(e.target.value);
              }}
            />
            {showStripeErrors && !secretKey.trim() ? (
              <span className={`${styles.errorMsg}`}>
                Action Required: Please enter the secret key
              </span>
            ) : (
              ""
            )}
            <img
              src={showSecretKey ? eyeOpen : eyeClosed}
              alt="eye"
              className={`${
                showSecretKey ? styles.showText : styles.showUpdatedText
              }`}
              width={12}
              onClick={toggleSecretKeyVisibility}
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="publishableKeyInput"
            className={`form-label ${styles.formLabel}`}
          >
            Publishable Key
          </label>
          <div className="position-relative">
            <input
              type={showPublishableKey ? "text" : "password"}
              className={`form-control ${styles.formBox} ${
                showStripeErrors && !publishableKey.trim() ? styles.error : ""
              }`}
              id="publishableKeyInput"
              placeholder="Enter publishable key here..."
              value={publishableKey}
              onChange={(e) => {
                setPublishableKey(e.target.value);
              }}
            />
            {showStripeErrors && !publishableKey.trim() ? (
              <span className={`${styles.errorMsg}`}>
                Action Required: Please enter the publishable key
              </span>
            ) : (
              ""
            )}
            <img
              src={showPublishableKey ? eyeOpen : eyeClosed}
              alt="eye"
              className={`${
                showPublishableKey ? styles.showText : styles.showUpdatedText
              }`}
              width={12}
              onClick={togglePublishableKeyVisibility}
            />
          </div>
        </div>
        <div className="mt-3">
          <button
            className={`px-2 ${styles.verifyBtn}`}
            onClick={saveStripeDetails}
          >
            {savingStepAndQuestionData === "question" ? (
              <Spinner size="sm" />
            ) : (
              "Save and verify integration"
            )}
          </button>
          {savingStepAndQuestionData !== "question" && (
            <>
              {stripeDetails?.verified !== true && (
                <p className={`d-block py-2 mb-0 ${styles.pendingText}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.855"
                    height="13.855"
                    viewBox="0 0 13.855 13.855"
                  >
                    <path
                      id="Icon_ionic-ios-alert"
                      data-name="Icon ionic-ios-alert"
                      d="M10.3,3.375A6.928,6.928,0,1,0,17.23,10.3,6.927,6.927,0,0,0,10.3,3.375Zm.573,3.65-.1,3.834a.477.477,0,0,1-.47.493h0a.477.477,0,0,1-.47-.493l-.1-3.834a.574.574,0,0,1,.573-.586h0A.574.574,0,0,1,10.876,7.025ZM10.3,13.567a.613.613,0,1,1,.636-.613A.619.619,0,0,1,10.3,13.567Z"
                      transform="translate(-3.375 -3.375)"
                      fill="#b90202"
                    />
                  </svg>
                  <span className="ps-1">
                    {stripeDetails?.error ? "Verification failed" : "Pending"}
                  </span>
                </p>
              )}
              {stripeDetails?.error === true && (
                <div className={`p-2 ${styles.errorBox}`}>
                  <p className="mb-1">
                    Please check the following and try again:
                  </p>
                  <p className="mb-1">
                    <span>Correct Keys</span>: Ensure that the Publishable Key
                    and Secret Key you entered are correct.
                  </p>

                  <p className="mb-1">
                    <span> Permissions</span>: Verify that your Stripe account
                    has the necessary permissions to perform payment processing.
                  </p>

                  <p className="mb-1">
                    <span>Network Issues</span>: Ensure that there are no
                    network issues affecting the connection to Stripe.
                  </p>
                </div>
              )}
              {stripeDetails?.verified === true && (
                <>
                  <div className="d-flex align-items-center py-1">
                    <div className={`${styles.successCheck}`}>
                      <img
                        src={check}
                        alt="check"
                        width={15}
                      />
                      <span className={`ps-1 ${styles.checkText}`}>
                        Verified!
                      </span>
                    </div>
                  </div>
                  {stepQuestion.showMoveToNext ? (
                    <div className="ms-0 my-2">
                      <MoveToNext
                        stepId={stepId}
                        questionId={questionId}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className={`px-3 py-2 mt-2  ${styles.setupBox}`}>
          <div className={`cursor-pointer d-flex justify-content-between`}>
            <span className={`pe-2 ${styles.setupSpan}`}>
              You can find these details in your stripe dashboard page
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeQuestion;
