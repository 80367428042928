import { useCallback, useMemo } from "react";
import styles from "../ConditionValue.module.scss";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  isError: boolean;
  customErrorString?: string;
  suffix?: string;
  min?: number;
  max?: number;
  showArrows?: boolean;
}
const NumberInput = ({
  value,
  onChange,
  placeholder,
  isError,
  customErrorString = "",
  suffix = "",
  min,
  max,
  showArrows = true,
}: Props) => {
  // Memoize the sanitized value to avoid unnecessary re-renders
  const sanitizedValue = useMemo(() => {
    const parsedValue = value;

    // Return empty string if value is an object or a date string
    if (typeof parsedValue === "object") {
      return "";
    }

    // Check if it a date value - "4444-44-44" and return empty string
    if (
      parsedValue &&
      typeof parsedValue === "string" &&
      parsedValue.split("-").length >= 3
    ) {
      return "";
    }

    return parsedValue;
  }, [value]);

  // Callback to handle input change
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  // Callback to handle increment on buttons
  const handleIncrement = useCallback(() => {
    let newValue = parseFloat(sanitizedValue ? sanitizedValue : "0") + 1;
    if (max !== undefined) {
      if (newValue > max) {
        newValue = max;
      }
    }
    onChange(`${newValue}`);
  }, [onChange, max, sanitizedValue]);

  // Callback to handle decrement on buttons
  const handleDecrement = useCallback(() => {
    let newValue = parseFloat(sanitizedValue ? sanitizedValue : "0") - 1;
    if (min !== undefined) {
      if (newValue < min) {
        newValue = min;
      }
    }
    onChange(`${newValue}`);
  }, [onChange, min, sanitizedValue]);

  // Memoize the errorString to avoid unnecessary re-renders
  const errorString = useMemo(() => {
    // Check the parsed string
    const parseValue = parseFloat(sanitizedValue);

    // If we have to show the error
    if (isError) {
      if (sanitizedValue === "") {
        return "This field is required";
      }

      if (isNaN(parseValue)) {
        return "Please enter a number";
      }

      if (parseValue <= 0) {
        return "Please enter valid number";
      }

      if (min !== undefined && parseValue < min) {
        return `Number cannot be less than ${min}`;
      }

      if (max !== undefined && parseValue > max) {
        return `Number cannot be greater than ${max}`;
      }

      if (customErrorString) {
        return customErrorString;
      }
    }

    return null;
  }, [customErrorString, isError, max, min, sanitizedValue]);

  return (
    <div className={`${styles.textBoxWrapper}`}>
      <div className={`${"d-flex position-relative"} `}>
        <input
          type="number"
          value={sanitizedValue ?? ""}
          className={`${styles.textBox} ${
            errorString ? styles.errBorder : ""
          } ${styles.numberBox}`}
          placeholder={placeholder}
          onChange={handleChange}
          {...(min !== undefined ? { min } : {})}
          {...(max !== undefined ? { max } : {})}
        />
        {showArrows ? (
          <div className="">
            <div
              className={`${
                errorString
                  ? styles.errArrow
                  : suffix
                    ? styles.daysArrow
                    : styles.arrow
              }`}
              style={{ top: "2px" }}
              onClick={handleIncrement}
            >
              <i className="fa-solid fa-caret-up"></i>
            </div>
            <div
              className={`${
                errorString
                  ? styles.errArrow
                  : suffix
                    ? styles.daysArrow
                    : styles.arrow
              }`}
              style={{ top: "15px" }}
              onClick={handleDecrement}
            >
              <i className="fa-solid fa-caret-down"></i>
            </div>
          </div>
        ) : (
          ""
        )}
        {suffix && (
          <div
            className={`${styles.daysAgoText} ms-2 d-flex align-items-center`}
          >
            {suffix}
          </div>
        )}
      </div>
      {errorString ? (
        <div className={`${styles.errText} mb-1`}>{errorString}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NumberInput;
