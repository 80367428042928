/* eslint-disable react-hooks/exhaustive-deps */
import {
  useInfiniteQuery,
  QueryClient,
  useQuery,
  useQueryClient,
  InfiniteData,
} from "@tanstack/react-query";
import { getBotProfile } from "src/services/Bot/BotProfiles/getBotProfile.service";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDebounce from "src/hooks/useDebounce";
import {
  Article,
  GetAllArticlesParams,
  GetAllArticlesResponse,
  getAllArticles,
} from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { setBackButtonRedirectPath } from "src/store/slices/botProfiles/botProfiles.slice";
import { SelectArticlesResponse } from "src/services/Bot/AnswerSources/selectKbArticles.service";

function useKbArticleSource() {
  const navigate = useNavigate();
  const { profileId: selectedBotId } = useParams();

  const [search, setSearch] = useState("");

  const debouncedSearchTerm = useDebounce(search, 500);

  const pageIntegrationId = selectedBotId
    ? decodeURIComponent(selectedBotId)
    : "";

  const dispatch = useAppDispatch();
  const backRedirectPath = useAppSelector(
    (state) => state.botProfiles.backRedirectPath,
  );
  //Callback to handle back click
  const handleBackClick = useCallback(() => {
    navigate("/bot/botProfiles");
    if (backRedirectPath.trim()) {
      dispatch(
        setBackButtonRedirectPath({
          path: "",
        }),
      );
      navigate(backRedirectPath);
    } else {
      navigate("/bot/botProfiles");
    }
  }, [backRedirectPath]);

  // Fetching disabledFeatures from the Redux store
  const disabledFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.disabledFeatures,
  );

  //Get the botProfile data
  const { data: botData, isLoading: isbotLoading } = useQuery({
    queryKey: ["getBotProfile", selectedBotId],
    queryFn: () => {
      if (selectedBotId === "new") {
        return null;
      }
      return getBotProfile({
        id: parseInt(selectedBotId + ""),
      });
    },
    enabled: !(disabledFeatures && disabledFeatures.includes("bot_profile")),
  });

  //initial params for pagination
  const [payload, setPayload] = useState<GetAllArticlesParams>({
    botProfileId: pageIntegrationId,
    start: 0,
    limit: 10,
  });

  //Fetch the articles using useInfiniteQuery
  const { data, isLoading, hasNextPage, fetchNextPage, refetch, isRefetching } =
    useInfiniteQuery({
      queryKey: ["getAllArticles", payload],
      queryFn: ({ pageParam = payload }) => getAllArticles(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const data = allPages.flatMap((data: any) => data.data);
        if (data.length < lastPage.metaData.totalCount) {
          const nextPageParam = {
            ...payload,
            start: data.length,
          };
          return nextPageParam;
        }
        return null;
      },
      enabled: !(disabledFeatures && disabledFeatures.includes("bot_profile")),
    });

  // Update the payload when the searchTerm is provided or changes
  useEffect(() => {
    setPayload({
      ...payload,
      searchTerm: debouncedSearchTerm ? debouncedSearchTerm : undefined,
    });
  }, [debouncedSearchTerm]);

  function onSearch() {
    refetch();
  }

  //Calculate the currentPage and totalPages for pagination
  const currentPage = Math.floor(payload.start! / payload.limit!) + 1;
  const totalPages = Math.ceil(data?.pages[0].metaData.total / payload.limit!);

  // Create a query client instance
  const queryClient = useQueryClient();
  const updateArticlebotTrainingStatus = useCallback(
    (articleResponse: SelectArticlesResponse) => {
      if (
        articleResponse.trainingStatuses &&
        articleResponse.trainingStatuses.length === 0
      ) {
        return;
      }
      console.log(articleResponse);
      if (data) {
        console.log(data);
        // Update the `trainingStatuses` array with new values

        const pages = data.pages.map((page) => {
          const articles = page.articles.map((article: any) => {
            const id = article.articleId;
            const index = articleResponse.trainingStatuses.findIndex(
              (val) => val.articleId === id,
            );
            // console.log(article,id,index);
            if (index !== -1) {
              return {
                ...article,
                botTrainingStatus:
                  articleResponse.trainingStatuses[index].botTrainingStatus,
              };
            }
            return article;
          });
          return { ...page, articles };
        });
        // Update the data in the cache using queryClient.setQueryData
        queryClient.setQueryData(["getAllArticles", payload], {
          ...data,
          pages: pages,
        });
      }
    },
    [queryClient, payload, data],
  );
  // Change the current page and trigger a refetch
  const changePage = async (page: number) => {
    // Calculate the new start based on the page number and limit
    setPayload({
      ...payload,
      start: (page - 1) * payload.limit!,
    });
    // refetch();

    // const newPayload = {
    //   ...payload,
    //   start: (page - 1) * payload.limit!,
    // };
    // setPayload(newPayload);
    // Clear the existing query cache
    // await queryClient.invalidateQueries(["getAllArticles", payload]);

    // fetchNextPage({ pageParam: newPayload });

    // const newPayload = {
    //   ...payload,
    //   start: (page - 1) * payload.limit!,
    // };
    //   // Invalidate the existing query for getAllArticles with the old payload
    //   queryClient.invalidateQueries(["getAllArticles", payload]);

    //   // Refetch the query with the new payload
    //   queryClient.fetchQuery(["getAllArticles", newPayload], () => getAllArticles(newPayload));
  };

  const articles = data?.pages.flatMap(
    (data: any) => data.articles,
  ) as Article[];

  //Open a new window when clicked on Add New Article
  const handleButtonClick = () => {
    window.open(`/knowledge-base/${botData?.integrationId}/articles/new/`);
  };

  return {
    handleBackClick,
    search,
    setSearch,
    onSearch,
    articles,
    isLoading,
    hasNextPage,
    fetchNextPage,
    handleButtonClick,
    isRefetching,
    currentPage,
    totalPages,
    changePage,
    isbotLoading,
    integrationId: botData?.integrationId,
    disabledFeatures,
    updateArticlebotTrainingStatus,
  };
}

export default useKbArticleSource;
