import { VarientData } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import styles from "./AttrTableRows.module.scss";
const AttrTableRows = ({
  varients,
  varientIds,
}: {
  varients: {
    [varientId: string]: VarientData;
  };
  varientIds: Array<string>;
}) => {
  return (
    <div className={`${styles.tableHeader} d-flex align-items-stretch`}>
      {/* Product preview cell */}
      <div
        className={`${styles.colHeadLabel} d-flex align-items-start flex-shrink-0 ${styles.productPreview}`}
      >
        <span className={`${styles.element} mt-3`}>Product preview</span>
      </div>

      {/* Rows data */}
      <div className={`${styles.rows}`}>
        {varientIds.map((varientId) => {
          const varient = varients[varientId];
          return (
            <div className={`${styles.tableHeader}`}>
              {varient.rowData.map((row, index) => (
                <div
                  key={index}
                  className={`${styles.colHead} ${styles.rowData} d-flex`}
                >
                  {row?.value == null ||
                  (typeof row?.value === "string" &&
                    row?.value.trim() === "") ? (
                    <div className={styles.empty}></div>
                  ) : (
                    <span>{row?.value as string}</span>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttrTableRows;
