import UserAvatar from "src/components/UserAvatar";
import styles from "./IssueDescription.module.scss";
import { useCallback, useMemo, useState } from "react";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { v4 as uuid } from "uuid";
import { BugData } from "src/services/BugReport/getBugByCategory.service";
import ParseHTML from "src/components/ParseHTML";
import DropDownStaus from "../../../../DropDownStatus/DropDownStatus";
import downloadFile from "src/services/BugReport/downloadFile.service";
import { capitalizeFirstLetter } from "../../../../helper/helperFunction";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { MergeBugReport } from "src/services/BugReport/mergeBug.service";
import MergeModalView from "./MergeModalView/MergeModalView";
import { useMutation } from "@tanstack/react-query";
import updateBug from "src/services/BugReport/updateBug.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { Spinner } from "react-bootstrap";
import ProfileImgGroup from "../../../../ProfileImgGroup/ProfileImgGroup";
import CompanyProfileImg from "../../CompanyProfileImg/CompanyProfileImg";
import { checkContent } from "../BugReportModal";
import AxiosImg from "src/components/AxiosImg";
interface Props {
  isUserShow?: boolean;
  bugData?: BugData;
  mergeData?: MergeBugReport;
  mergeModal?: boolean;
  isSelectedForMerge?: boolean;
  setSelectedBugId?: React.Dispatch<React.SetStateAction<Set<number>>>;
  hanndleShowModel?: () => void;
}

const IssueDescription = ({
  isUserShow = false,
  bugData,
  mergeData,
  mergeModal = false,
  isSelectedForMerge,
  setSelectedBugId,
  hanndleShowModel,
}: Props) => {
  const [downloadLoading, setDownloadLoading] = useState<string | null>(null);
  const [editDescription, setEditDescription] = useState(false);
  const [description, setDescription] = useState(
    capitalizeFirstLetter(
      bugData?.bugDescription ?? mergeData?.bugDescription ?? "",
    ),
  );
  const [initialDescription, setInitialDescription] = useState(
    capitalizeFirstLetter(
      bugData?.bugDescription ?? mergeData?.bugDescription ?? "",
    ),
  );
  const [textBoxErr, setTextBoxErr] = useState(false);
  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const attachmentDetails = useMemo(
    () =>
      bugData?.bugAttachmentDetails
        ? bugData.bugAttachmentDetails
        : mergeData?.bugAttachmentsDetails,
    [bugData?.bugAttachmentDetails, mergeData?.bugAttachmentsDetails],
  );

  const { mutate: updateDescription, isLoading: updateDescriptionLoading } =
    useMutation({
      mutationFn: updateBug,
      onSuccess: () => {
        pushTheToast({
          type: "success",
          text: "Successfully description updated",
          position: "top-right",
        });
        setEditDescription(!editDescription);

        selectedFiles.forEach(
          (attachment: any) =>
            mergeData?.bugAttachmentsDetails.push({
              attachmentId: attachment.attachmentId,
              attachmentName: attachment.attachmentName,
              attachmentUrl: attachment.attachmentURL,
              attachmentBatchNumber: attachment.batchNumber,
              attachmentSize: attachment.attachmentSize,
              attachmentType: attachment.file.type,
              isPublicAttachmentUrl: attachment.isPublic,
            }),
        );
        setSelectedFiles([]);
        setTextBoxErr(false);
      },
      onError: () => {
        pushTheToast({
          type: "danger",
          text: "Unable to updated!!",
          position: "top-right",
        });
      },
    });

  const handleDescriptionUpdate = useCallback(() => {
    if (!checkContent(description) && selectedFiles.length === 0) {
      setTextBoxErr(true);
      return;
    }
    setInitialDescription(description);
    const attachmentBatchNumbers = selectedFiles.map(function (
      value: any,
      index: any,
    ) {
      return value.batchNumber;
    });
    const payload: {
      id: number | undefined;
      description: string;
      attachmentBatchNumber?: string[];
    } = {
      id: mergeData?.bugId,
      description,
    };
    if (attachmentBatchNumbers.length) {
      payload.attachmentBatchNumber = attachmentBatchNumbers;
    }
    updateDescription(payload);
  }, [description, selectedFiles]);

  const handleDownloadURL: React.MouseEventHandler<HTMLSpanElement> =
    useCallback(
      (e) => {
        if (!downloadLoading) {
          const ele = e.currentTarget;
          setDownloadLoading(ele.getAttribute("data-file-url") + "");
          downloadFile(ele.getAttribute("data-file-url") + "", true)
            .then((res) => {})
            .catch((err) => {
              console.error(err);
              pushTheToast({
                type: "danger",
                text: "Couldn't download right now! Please check your network and try again later.",
                position: "top-right",
              });
            })
            .finally(() => {
              setDownloadLoading(null);
            });
        }
      },
      [downloadLoading],
    );

  return (
    <div>
      <div className={`mb-3 ${styles.issueDesc}`}>
        {isUserShow && (
          <div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={isSelectedForMerge}
                id="flexCheckDefault"
                onChange={() => {
                  if (typeof setSelectedBugId === "function") {
                    setSelectedBugId((prevSelectedBugs) => {
                      const newSelectedBugs = new Set(prevSelectedBugs);
                      if (newSelectedBugs.has(bugData?.bugId ?? 0)) {
                        newSelectedBugs.delete(bugData?.bugId ?? 0);
                      } else {
                        newSelectedBugs.add(bugData?.bugId ?? 0);
                      }
                      return newSelectedBugs;
                    });
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
              >
                <div className="d-flex align-items-center">
                  {bugData?.customerDetails.length === 1 ? (
                    <div>
                      <UserAvatar
                        name={bugData.customerDetails[0].customerName}
                        size={21}
                      />
                      <span className={`ps-2 ${styles.userName}`}>
                        {bugData.customerDetails[0].customerName}
                      </span>
                    </div>
                  ) : (
                    <ProfileImgGroup
                      customers={bugData?.customerDetails ?? []}
                      companyName={bugData?.companyName}
                    />
                  )}
                  <div className="ms-2">
                    <CompanyProfileImg
                      companyName={bugData?.companyName ?? []}
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}

        {/* if merge model than show it */}
        {mergeModal ? <MergeModalView mergeData={mergeData} /> : ""}
        <p className={`mb-0 mt-1 d-flex align-items-center ${styles.heading}`}>
          <span>Issue description: </span>
          {/* if merege model then show edti description option */}
          {!editDescription &&
            mergeModal &&
            mergeData?.bugStatusKey !== "resolved" && (
              <span
                className={`ps-1 ${styles.edit}`}
                onClick={() => setEditDescription(!editDescription)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 13.685 13.685"
                >
                  <g
                    id="Group_8381"
                    data-name="Group 8381"
                    transform="translate(-242.188 -603)"
                  >
                    <g
                      id="Group_8380"
                      data-name="Group 8380"
                    >
                      <path
                        id="Icon_material-outline-mode-edit-outline"
                        data-name="Icon material-outline-mode-edit-outline"
                        d="M4.5,18.181H7.351l8.407-8.407L12.907,6.923,4.5,15.33Zm1.52-2.22,6.887-6.887.7.7L6.72,16.661h-.7v-.7ZM16.184,4.719a.757.757,0,0,0-1.072,0L13.721,6.11,16.571,8.96l1.391-1.391a.757.757,0,0,0,0-1.072L16.184,4.719Z"
                        transform="translate(237.688 598.504)"
                        fill="#005aae"
                      />
                    </g>
                  </g>
                </svg>
                <span className="ps-1">Edit</span>
              </span>
            )}
        </p>
        <span className={`${styles.subHeading}`}>
          {editDescription ? (
            <div>
              <TinyMCEContainer
                className={`${styles.textEditor} ${
                  textBoxErr ? "border-danger" : ""
                } mt-2`}
                value={description ?? ""}
                onChange={(value: string) => {
                  setDescription(value);
                  setTextBoxErr(false);
                }}
                options={{
                  placeholder: "Type your text here...",
                  min_height: 115,
                }}
                uniqueID={uuid()}
                buttons={
                  <div>
                    <button
                      className={`${styles.saveEditDescriptionBtn} me-2`}
                      onClick={() => {
                        setEditDescription(false);
                        setDescription(initialDescription);
                      }}
                      disabled={updateDescriptionLoading}
                    >
                      Cancel
                    </button>
                    <button
                      className={styles.saveEditDescriptionBtn}
                      onClick={handleDescriptionUpdate}
                      disabled={isFileUploading || updateDescriptionLoading}
                    >
                      Save
                      {updateDescriptionLoading && (
                        <Spinner
                          size="sm"
                          className="ms-1"
                        />
                      )}
                    </button>
                  </div>
                }
                disableAttachment={false}
                attachmentUploadData={attachmentUploadData}
                setAttachmentUploadData={setAttachmentUploadData}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                currentAttachmentBatchNumber={currentAttachmentBatchNumber}
                setCurrentAttachmentBatchNumber={
                  setCurrentAttachmentBatchNumber
                }
                isFileUploading={isFileUploading}
                setIsFileUploading={setIsFileUploading}
                areAttachmentsPublic={true}
              />
            </div>
          ) : (
            <ParseHTML html={description ?? ""} />
          )}
        </span>

        {/* show attach images and videos */}
        <div className="mt-2 d-flex flex-wrap">
          {attachmentDetails?.map((file) => (
            <div
              className="me-2"
              key={file.attachmentId}
            >
              <span className={`pb-1 ${styles.playText}`}>
                {file.attachmentName.slice(0, 15)}.
                {file.attachmentType.split("/")[1]}
              </span>
              {file.attachmentType.split("/")[0] === "video" && (
                <video className={styles.playImg}>
                  <source
                    src={file.attachmentUrl}
                    type="video/webm"
                  />
                  <source
                    src={file.attachmentUrl}
                    type="video/mp4"
                  />
                </video>
              )}
              {file.attachmentType.split("/")[0] === "image" && (
                <AxiosImg
                  url={file.attachmentUrl}
                  alt={file.attachmentName}
                  className={`${styles.playImg} mb-1`}
                />
              )}
              <div>
                <span
                  data-file-url={file.attachmentUrl}
                  className={`${styles.actionText}${
                    downloadLoading === file.attachmentUrl
                      ? " cursor-disabled"
                      : " cursor-pointer"
                  }`}
                  onClick={handleDownloadURL}
                >
                  <span>
                    {downloadLoading === file.attachmentUrl
                      ? "Downloading..."
                      : "Download"}
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
        {/* if merge model show dropdown chnage status */}
        {mergeModal && (
          <div className="d-flex align-items-center mt-3">
            <DropDownStaus
              status={mergeData?.bugStatusKey}
              id={mergeData?.bugId}
              updatedStatus={hanndleShowModel ?? (() => {})}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IssueDescription;
