import { useNavigate } from "react-router-dom";
import minimize from "src/assets/images/minimize.png";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import HistoryInnerView from "src/routes/LiveChat/Children/MainChat/Children/ChatHistoryUpdated/Children/HistoryInnerView/HistoryInnerView";
import styles from "./BotView.module.scss";
import useLiveChatHistory from "./useLiveChatHistory";
import { CustomerInfo } from "src/services/TicketService/InternalChatBot/getAllCustomers.service";

export type CustomerDetails = Omit<CustomerInfo, "moreDetails">;

interface Props {
  customerDetails?: CustomerDetails | null;
  onHide: () => void;
  internalChat: boolean;
}

const BotView = ({ onHide, customerDetails, internalChat }: Props) => {
  const navigate = useNavigate();
  const {
    customerLiveChatId,
    isCustomerOnline,
    fetchNextPage,
    fetchStatus,
    hasNextPage,
    limitReached,
    historyMessageIds,
    historyMessages,
    fetchLastMessages,
  } = useLiveChatHistory(customerDetails?.id ?? "");

  if (!customerDetails) {
    return <></>;
  }

  return (
    <div className={`${styles.botView}`}>
      <div
        className={`${styles.header} px-4 d-flex justify-content-between align-items-center`}
      >
        <div className="d-flex align-items-center">
          {customerDetails?.imgURL ? (
            <AxiosImg
              url={customerDetails.imgURL}
              className={styles.userLogo}
            />
          ) : (
            <UserAvatar
              name={customerDetails?.name ?? "NA"}
              size={34}
            />
          )}
          <div className="ps-3">
            <h5 className={`mb-0 ${styles.userName}`}>
              {customerDetails?.name ?? "NA"}
            </h5>
            <span className={`d-block ${styles.status}`}>
              {isCustomerOnline ? "online" : "offline"}
            </span>
          </div>
        </div>
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={onHide}
        >
          <span className={`pe-2`}>
            <img
              src={minimize}
              alt="minimize"
              width={11}
            />
          </span>
        </div>
      </div>
      <HistoryInnerView
        historyMessages={{
          messages: historyMessages,
          messageIds: historyMessageIds,
          metaData: {
            currentCount: historyMessageIds.length,
            limitReached: limitReached,
          },
        }}
        customerData={
          customerDetails
            ? {
                email: customerDetails.email,
                id: customerDetails.id,
                name: customerDetails.name,
                img: customerDetails.imgURL,
                isPublicAttachmentUrl: customerDetails.isPublicAttachmentUrl,
              }
            : undefined
        }
        fetchNextData={fetchNextPage}
        fetchStatus={fetchStatus}
        selectedHistoryId={null}
        hasMore={hasNextPage ?? false}
        historyMessagesIds={historyMessageIds}
        customerId={customerDetails?.id}
        fetchLastMessages={fetchLastMessages}
        smallWindowChat={true}
        internalChat={internalChat}
      />
      <div
        className={`${styles.footer} ${
          customerLiveChatId ? "cursor-pointer" : ""
        }`}
        onClick={() => {
          if (customerLiveChatId) {
            navigate(
              `/live-chat/chats/live/${customerLiveChatId}/chattingTab/history`,
            );
          }
        }}
      >
        <span className={`${styles.viewText}`}>
          {customerLiveChatId ? "View in live chat" : "No Live Chat Available"}
        </span>
        {customerLiveChatId && (
          <span className={`ms-2 ${styles.arrow}`}>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default BotView;
