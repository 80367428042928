import { useCallback, useMemo } from "react";
import NumberInput from "./NumberInput";
import { BetweenRange } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";

interface Props {
  value: BetweenRange<string>;
  onChange: (value: BetweenRange<string>) => void;
  placeholder: string;
  isError: boolean;
  suffix?: string;
  min?: number;
  max?: number;
  showArrows?: boolean;
}
const NumberInputRange = ({
  value,
  onChange,
  placeholder,
  isError,
  suffix = "",
  min,
  max,
  showArrows = true,
}: Props) => {
  const handleStartChange = useCallback(
    (startValue: string) => {
      onChange({ ...value, start: startValue });
    },
    [onChange, value],
  );
  const handleEndChange = useCallback(
    (endValue: string) => {
      onChange({ ...value, end: endValue });
    },
    [onChange, value],
  );

  const { startError, endError } = useMemo(() => {
    const startValue = parseFloat(value?.start ?? "");
    const endValue = parseFloat(value?.end ?? "");

    if (isError) {
      if (endValue < startValue) {
        return {
          endError: "End value cannot be smaller than Start value",
          startError: "Start value cannot be greater than End value",
        };
      }

      if (endValue === startValue) {
        return {
          endError: "End value cannot be same as Start value",
          startError: "Start value cannot be same as End value",
        };
      }

      return {
        endError: undefined,
        startError: undefined,
      };
    }

    return {
      endError: undefined,
      startError: undefined,
    };
  }, [isError, value?.end, value?.start]);

  return (
    <div className="d-flex">
      <NumberInput
        value={value.start}
        onChange={handleStartChange}
        placeholder={placeholder}
        isError={isError}
        customErrorString={startError}
        suffix={suffix}
        min={min}
        max={max}
        showArrows={showArrows}
      />
      <span className="me-2 mt-2">-</span>
      <NumberInput
        value={value.end}
        onChange={handleEndChange}
        placeholder={placeholder}
        isError={isError}
        customErrorString={endError}
        suffix={suffix}
        min={min}
        max={max}
        showArrows={showArrows}
      />
    </div>
  );
};

export default NumberInputRange;
