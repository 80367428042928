import ParseHTML from "src/components/ParseHTML";

const string = `<html
  dir="ltr"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="und"
  style="
    font-family: arial, 'helvetica neue', helvetica, sans-serif;
    overflow-x: visible !important;
  "
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title></title>
    <!--[if (mso 16)]>
      <style type="text/css">
        a {
          text-decoration: none;
        }
      </style>
    <![endif]-->
    <!--[if gte mso 9
      ]><style>
        sup {
          font-size: 100% !important;
        }
      </style><!
    [endif]-->
    <!--[if gte mso 9]>
      <noscript>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG></o:AllowPNG>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      </noscript>
    <![endif]-->
    <!--[if !mso]><!-- -->
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins&amp;display=swap"
      rel="stylesheet"
    />
    <!--<![endif]-->
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
      .es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
      @media only screen and (max-width: 600px) {
        p,
        ul li,
        ol li,
        a {
          line-height: 150% !important;
        }
        h1,
        h2,
        h3,
        h1 a,
        h2 a,
        h3 a {
          line-height: 120%;
        }
        h1 {
          font-size: 45px !important;
          text-align: center;
        }
        h2 {
          font-size: 24px !important;
          text-align: left;
        }
        h3 {
          font-size: 20px !important;
          text-align: left;
        }
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 45px !important;
          text-align: center;
        }
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 24px !important;
          text-align: left;
        }
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 20px !important;
          text-align: left;
        }
        .es-menu td a {
          font-size: 14px !important;
        }
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li,
        .es-header-body a {
          font-size: 12px !important;
        }
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li,
        .es-content-body a {
          font-size: 14px !important;
        }
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li,
        .es-footer-body a {
          font-size: 14px !important;
        }
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li,
        .es-infoblock a {
          font-size: 12px !important;
        }
        *[class="gmail-fix"] {
          display: none !important;
        }
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3 {
          text-align: center !important;
        }
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3 {
          text-align: right !important;
        }
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3 {
          text-align: left !important;
        }
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important;
        }
        .es-button-border {
          display: inline-block !important;
        }
        a.es-button,
        button.es-button {
          font-size: 18px !important;
          display: inline-block !important;
        }
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important;
        }
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important;
        }
        .es-adapt-td {
          display: block !important;
          width: 100% !important;
        }
        .adapt-img {
          width: 100% !important;
          height: auto !important;
        }
        .es-m-p0 {
          padding: 0 !important;
        }
        .es-m-p0r {
          padding-right: 0 !important;
        }
        .es-m-p0l {
          padding-left: 0 !important;
        }
        .es-m-p0t {
          padding-top: 0 !important;
        }
        .es-m-p0b {
          padding-bottom: 0 !important;
        }
        .es-m-p20b {
          padding-bottom: 20px !important;
        }
        .es-mobile-hidden,
        .es-hidden {
          display: none !important;
        }
        tr.es-desk-hidden,
        td.es-desk-hidden,
        table.es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important;
        }
        tr.es-desk-hidden {
          display: table-row !important;
        }
        table.es-desk-hidden {
          display: table !important;
        }
        td.es-desk-menu-hidden {
          display: table-cell !important;
        }
        .es-menu td {
          width: 1% !important;
        }
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important;
        }
        table.es-social {
          display: inline-block !important;
        }
        table.es-social td {
          display: inline-block !important;
        }
        .es-desk-hidden {
          display: table-row !important;
          width: auto !important;
          overflow: visible !important;
          max-height: inherit !important;
        }
        .es-m-p5 {
          padding: 5px !important;
        }
        .es-m-p5t {
          padding-top: 5px !important;
        }
        .es-m-p5b {
          padding-bottom: 5px !important;
        }
        .es-m-p5r {
          padding-right: 5px !important;
        }
        .es-m-p5l {
          padding-left: 5px !important;
        }
        .es-m-p10 {
          padding: 10px !important;
        }
        .es-m-p10t {
          padding-top: 10px !important;
        }
        .es-m-p10b {
          padding-bottom: 10px !important;
        }
        .es-m-p10r {
          padding-right: 10px !important;
        }
        .es-m-p10l {
          padding-left: 10px !important;
        }
        .es-m-p15 {
          padding: 15px !important;
        }
        .es-m-p15t {
          padding-top: 15px !important;
        }
        .es-m-p15b {
          padding-bottom: 15px !important;
        }
        .es-m-p15r {
          padding-right: 15px !important;
        }
        .es-m-p15l {
          padding-left: 15px !important;
        }
        .es-m-p20 {
          padding: 20px !important;
        }
        .es-m-p20t {
          padding-top: 20px !important;
        }
        .es-m-p20r {
          padding-right: 20px !important;
        }
        .es-m-p20l {
          padding-left: 20px !important;
        }
        .es-m-p25 {
          padding: 25px !important;
        }
        .es-m-p25t {
          padding-top: 25px !important;
        }
        .es-m-p25b {
          padding-bottom: 25px !important;
        }
        .es-m-p25r {
          padding-right: 25px !important;
        }
        .es-m-p25l {
          padding-left: 25px !important;
        }
        .es-m-p30 {
          padding: 30px !important;
        }
        .es-m-p30t {
          padding-top: 30px !important;
        }
        .es-m-p30b {
          padding-bottom: 30px !important;
        }
        .es-m-p30r {
          padding-right: 30px !important;
        }
        .es-m-p30l {
          padding-left: 30px !important;
        }
        .es-m-p35 {
          padding: 35px !important;
        }
        .es-m-p35t {
          padding-top: 35px !important;
        }
        .es-m-p35b {
          padding-bottom: 35px !important;
        }
        .es-m-p35r {
          padding-right: 35px !important;
        }
        .es-m-p35l {
          padding-left: 35px !important;
        }
        .es-m-p40 {
          padding: 40px !important;
        }
        .es-m-p40t {
          padding-top: 40px !important;
        }
        .es-m-p40b {
          padding-bottom: 40px !important;
        }
        .es-m-p40r {
          padding-right: 40px !important;
        }
        .es-m-p40l {
          padding-left: 40px !important;
        }
      }
      @media screen and (max-width: 384px) {
        .mail-message-content {
          width: 414px !important;
        }
      }
    </style>
    <link
      href="assets/css/dev-custom-scroll.css"
      rel="stylesheet"
      type="text/css"
    />
    <base target="_blank" />
  </head>
  <body
    style="
      width: 100%;
      font-family: arial, 'helvetica neue', helvetica, sans-serif;
      text-size-adjust: 100%;
      padding: 0px;
      margin: 0px;
      overflow-y: scroll !important;
      visibility: visible !important;
    "
  >
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="und"
      style="background-color: #3a3d4c"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#3A3D4C"></v:fill>
        </v:background>
      <![endif]-->
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #3a3d4c;
        "
      >
        <tbody>
          <tr>
            <td valign="top" style="padding: 0; margin: 0">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                  background-color: transparent;
                  background-repeat: repeat;
                  background-position: center top;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          max-width: 600px;
                          width: 600px;
                        "
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              bgcolor="#ffffff"
                              style="
                                margin: 0;
                                padding-bottom: 10px;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                background-color: #ffffff;
                                border-radius: 20px 20px 0px 0px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="es-m-p0r"
                                              valign="top"
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 104px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      class="es-m-txt-c"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 13px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_0a3b53f994ef8ee9aefbba8a873dc486/images/group_343.png"
                                                          alt="Logo"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="50"
                                                          title="Logo"
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 222px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="right"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 15px;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 19.5px;
                                                          color: #3a3d4c;
                                                          font-size: 13px;
                                                        "
                                                      >
                                                        Newsletter
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 94px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="right"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 15px;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 19.5px;
                                                          color: #3a3d4c;
                                                          font-size: 13px;
                                                        "
                                                      >
                                                        Apr&nbsp;10
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 80px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="right"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 15px;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 19.5px;
                                                          color: #3a3d4c;
                                                          font-size: 13px;
                                                        "
                                                      >
                                                        2023
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="es-content"
                cellspacing="0"
                cellpadding="0"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          max-width: 600px;
                          width: 100%;
                        "
                        cellspacing="0"
                        cellpadding="0"
                        bgcolor="#ffffff"
                        align="center"
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                                padding-bottom: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #686c7e;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h1
                                                style="
                                                  margin: 0;
                                                  line-height: 78px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 65px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                It Was Nice Meeting You
                                              </h1>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #686c7e;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                               
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/mask_group.png"
                                                  alt="Aaron Parker"
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="90"
                                                  title="Aaron Parker"
                                              /></span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 28.8px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 24px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                Aaron Parker
                                              </h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                Head of Statistics
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #686c7e;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 57.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 48px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                Hi Jody Collins!
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 30px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                It was really nice to meet you
                                                at {EVENT}! I loved learning
                                                more about {COMPANY NAME} and
                                                how you {DESCRIBE WHAT YOU
                                                ADMIRES ABOUT THIS COMPANY}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 57.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 48px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                What was discussed
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                I know that {COMPANY NEEDS OR
                                                OBJECTIVES}&nbsp;is really
                                                important to you and your team.
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                We agreed&nbsp;that&nbsp;our
                                                {YOUR COMPANY’S PRODUCT OR
                                                SERVICE}&nbsp;can help you
                                                achieve those goals:
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_610__v7u.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Build a mobile app that
                                                        will help you track your
                                                        orders.<br />
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_610__v7u.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      ></p>
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Open 150 new offline
                                                        stores/showrooms&nbsp;all
                                                        over the world.
                                                        So&nbsp;customers&nbsp;can
                                                        come and try on new
                                                        pieces
                                                        of&nbsp;clothing&nbsp;at
                                                        any time.
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_610__v7u.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      ></p>
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Pack and
                                                        ship&nbsp;orders 24/7.
                                                        Users book&nbsp;a dress
                                                        on Saturday, get it on
                                                        Sunday.
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 57.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 48px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                Further steps
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                We agreed to schedule a new
                                                appointment so we discuss the
                                                conditions of our collaboration.
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                Here are some times I’m
                                                available:
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_609__RcR.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Tuesday&nbsp;9:00
                                                        AM-11:00 AM<span
                                                          style="
                                                            text-align: center;
                                                            font-family: arial,
                                                              'helvetica neue',
                                                              helvetica,
                                                              sans-serif;
                                                          "
                                                        ></span>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_609__RcR.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Wednesday&nbsp;10:00
                                                        AM-1:00 PM
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esdev-adapt-off"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="esdev-mso-table"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  max-width: 560px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-left"
                                        align="left"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: left;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                width: 30px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        font-size: 0px;
                                                      "
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://viewstripo.email"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/xmlid_609__RcR.png"
                                                          alt=""
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                          width="20"
                                                      /></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                    <td
                                      class="esdev-mso-td"
                                      valign="top"
                                      style="padding: 0; margin: 0"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="es-right"
                                        align="right"
                                        role="none"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                          float: right;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                max-width: 510px;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <p
                                                        style="
                                                          margin: 0;
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          line-height: 21px;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                      >
                                                        Thursday at any time
                                                        starting from 2:00 PM
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                What time suits you best? If
                                                none of those works, let me know
                                                your availability.
                                              </p>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                <br />I look forward to
                                                meeting&nbsp;you!
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        role="none"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          max-width: 600px;
                          width: 100%;
                        "
                      >
                        <tbody>
                          <tr>
                            <td align="left" style="padding: 0; margin: 0">
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 600px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 20px;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #686c7e;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                align="left"
                                class="es-left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p20b"
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <!--[if mso
                                                ]><a
                                                  href="https://viewstripo.email"
                                                  target="_blank"
                                                  hidden
                                                >
                                                  <v:roundrect
                                                    xmlns:v="urn:schemas-microsoft-com:vml"
                                                    xmlns:w="urn:schemas-microsoft-com:office:word"
                                                    esdevVmlButton
                                                    href="https://viewstripo.email"
                                                    style="
                                                      height: 41px;
                                                      v-text-anchor: middle;
                                                      max-width: 163px;
                                                    "
                                                    arcsize="50%"
                                                    stroke="f"
                                                    fillcolor="#1dd1e3"
                                                  >
                                                    <w:anchorlock></w:anchorlock>
                                                    <center
                                                      style="
                                                        color: #ffffff;
                                                        font-family: Poppins,
                                                          sans-serif;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        line-height: 15px;
                                                        mso-text-raise: 1px;
                                                      "
                                                    >
                                                      View report
                                                    </center>
                                                  </v:roundrect></a
                                                >
                                              <![endif]-->
                                              <!--[if !mso]><!-- --><span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #1dd1e3;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  
                                                  class="es-button"
                                                 
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 18px;
                                                    padding: 10px 30px 10px 30px;
                                                    display: inline-block;
                                                    background: #1dd1e3;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 21.6px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #1dd1e3;
                                                  "
                                                  >View report</span
                                                >
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:270px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 270px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <!--[if mso
                                                ]><a
                                                  href="https://viewstripo.email"
                                                  target="_blank"
                                                  hidden
                                                >
                                                  <v:roundrect
                                                    xmlns:v="urn:schemas-microsoft-com:vml"
                                                    xmlns:w="urn:schemas-microsoft-com:office:word"
                                                    esdevVmlButton
                                                    href="https://viewstripo.email"
                                                    style="
                                                      height: 41px;
                                                      v-text-anchor: middle;
                                                      max-width: 211px;
                                                    "
                                                    arcsize="50%"
                                                    stroke="f"
                                                    fillcolor="#5b5f72"
                                                  >
                                                    <w:anchorlock></w:anchorlock>
                                                    <center
                                                      style="
                                                        color: #ffffff;
                                                        font-family: Poppins,
                                                          sans-serif;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        line-height: 15px;
                                                        mso-text-raise: 1px;
                                                      "
                                                    >
                                                      Download report
                                                    </center>
                                                  </v:roundrect></a
                                                >
                                              <![endif]-->
                                              <!--[if !mso]><!-- --><span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #5b5f72;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                 
                                                  class="es-button"
                                               
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 18px;
                                                    padding: 10px 30px 10px 30px;
                                                    display: inline-block;
                                                    background: #5b5f72;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 21.6px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #5b5f72;
                                                  "
                                                  >Download report</span
                                                >
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 28.8px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 24px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                If you are still interested<br />in
                                                our product let me know!
                                              </h3>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          max-width: 600px;
                          width: 100%;
                        "
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              bgcolor="#ffffff"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-bottom: 30px;
                                background-color: #ffffff;
                                border-radius: 0px 0px 20px 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                font-size: 0px;
                                              "
                                            >
                                              <a
                                                target="_blank"
                                                href="https://viewstripo.email"
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/mask_group.png"
                                                  alt="Aaron Parker"
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="90"
                                                  title="Aaron Parker"
                                              /></a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 10px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 48px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 24px;
                                                  font-style: normal;
                                                  font-weight: bold;
                                                  color: #3a3d4c;
                                                "
                                              >
                                                Aaron Parker
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #3a3d4c;
                                                  font-size: 14px;
                                                "
                                              >
                                                Head of Statistics<a
                                                  target="_blank"
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #333333;
                                                    font-size: 14px;
                                                    line-height: 21px;
                                                  "
                                                  href=""
                                                ></a>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="100%"
                                                      id="esd-menu-id-0"
                                                      style="
                                                        margin: 0;
                                                        padding-left: 5px;
                                                        padding-right: 5px;
                                                        padding-top: 10px;
                                                        padding-bottom: 0px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                     
                                                        href="tel:+000123456789"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/mask_group1.png"
                                                          alt="+000 123 456 789"
                                                          title="+000 123 456 789"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                          "
                                                        />+000 123 456 789</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="100%"
                                                      id="esd-menu-id-1"
                                                      style="
                                                        margin: 0;
                                                        padding-left: 5px;
                                                        padding-right: 5px;
                                                        padding-top: 5px;
                                                        padding-bottom: 5px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        
                                                        href="/cdn-cgi/l/email-protection#5f2f3e2d343a2d1f3a323e3633713c3032"
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: Poppins,
                                                            sans-serif;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_4ff4b6418918124e73680e8a73ec0afc/images/mask_group_7eb.png"
                                                          alt="parker@email.com"
                                                          title="parker@email.com"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                          "
                                                        /><span
                                                          class="__cf_email__"
                                                          data-cfemail="adddccdfc6c8dfedc8c0ccc4c183cec2c0"
                                                          >[email&#160;protected]</span
                                                        ></span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                margin: 0;
                                                padding-bottom: 5px;
                                                padding-top: 20px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                        
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          title="Facebook"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                          alt="Fb"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          title="Х"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-black/x-logo-black.png"
                                                          alt="Х"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 10px;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          title="Instagram"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                                          alt="Inst"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <span
                                                       
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #3a3d4c;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          title="Youtube"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-black/youtube-logo-black.png"
                                                          alt="Yt"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: transparent;
                          max-width: 600px;
                          width: 100%;
                        "
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 20px;
                                padding-right: 20px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 560px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              height="40"
                                              style="padding: 0; margin: 0"
                                            ></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
             
           
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <script
      data-cfasync="false"
      src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
    ></script>
  </body>
</html>
`;

const AronParker = () => {
  return <ParseHTML html={string} />;
};

export default AronParker;
