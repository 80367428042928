import React from "react";
import Select from "react-select";
import timelineImg from "src/assets/images/timelineImg.png";
import styles from "./TimelineStage.module.scss";

// Dummy data for the select options with images
const options = [
  {
    label: "Registered Users",
    value: "1",
  },
  {
    label: " Non-registered Users",
    value: "2",
  },
  {
    label: "All Users",
    value: "3",
  },
];

const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#f5f5f5",
    minWidth: "190px",
    minHeight: "29px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    marginRight: "2px",
    font: "normal normal normal 12px/18px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "8px 0 0 0",
    width: "190px",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#000" : "#000",
    backgroundColor: isFocused ? "#F0F0F0" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#5548FF",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "29px", // Adjust the height if needed
    fontSize: "12px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#000000",
    font: "normal normal normal 12px/18px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};

// Custom Option component to display both image and text
// const CustomOption = (props: any) => {
//   const { data, innerRef, innerProps } = props;
//   return (
//     <div
//       ref={innerRef}
//       {...innerProps}
//       style={{
//         display: "flex",
//         alignItems: "center",
//         cursor: "pointer",
//         margin: "0 6px",
//         minHeight: "35px",
//       }}
//       className={`px-2 py-1 mb-1 ${styles.customOption}`}
//     >
//       <div
//         className={`${styles.iconBox} d-flex align-items-center justify-content-center me-1`}
//       >
//         <span className={`${styles.leftText}`}>U</span>
//         <div className="d-flex flex-column justify-content-center">
//           <span className={`${styles.rightText}`}>a</span>
//           <span className={`${styles.rightText}`}>1</span>
//         </div>
//       </div>
//       <span className={`${styles.optionLabel}`}>{data.label}</span>
//     </div>
//   );
// };

// Custom SingleValue component to display both image and text in the selected value
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        className={`${styles.iconBox} d-flex align-items-center justify-content-center me-1 d-none`}
      >
        <span className={`${styles.leftText}`}>U</span>
        <div className="d-flex flex-column justify-content-center">
          <span className={`${styles.rightText}`}>a</span>
          <span className={`${styles.rightText}`}>1</span>
        </div>
      </div>
      <span className={`${styles.selectedoptionLabel}`}>{data.label}</span>
    </div>
  );
};
const CustomIndicator = (props: any) => {
  const { selectProps } = props;
  const isOpen = selectProps.menuIsOpen;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i
          className={`fa-solid ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
        ></i>
      </span>
    </div>
  );
};

const TimelineStage = () => {
  return (
    <div className="ms-2">
      <Select
        options={options}
        // placeholder="Select timeline stage"
        placeholder="Select User segment"
        isSearchable={false}
        styles={customStyles}
        components={{
          IndicatorSeparator: null,
          // Option: CustomOption,
          DropdownIndicator: CustomIndicator,
          SingleValue: CustomSingleValue,
        }}
      />
    </div>
  );
};

export default TimelineStage;
