import React, { SetStateAction } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import insta from "src/assets/images/browseInsta.png";
import messenger from "src/assets/images/messenger-new.png";
import whatsapp from "src/assets/images/Whastapp.svg";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import envelop from "src/assets/images/envelop.png";
import { useAppSelector } from "src/store/store";
import { getTimeFromNow } from "src/utils/dateLibrary";
import styles from "./BotUser.module.scss";
import { CustomerInfo } from "src/services/TicketService/InternalChatBot/getAllCustomers.service";
import ParseHTML from "src/components/ParseHTML";

// Define the interface for props
interface BotUserProps {
  setCustomerInfo: () => void;
  customer: Omit<CustomerInfo, "moreDetails"> | null;
  socialIcon: string;
  lastMsg?: string;
  lastMsgDate?: string;
  missedChatCount: number;
  activeChatCount: number;
  showInfo?: boolean;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
  showOverlay?: boolean;
  internalChat: boolean;
  setInternalChat: React.Dispatch<SetStateAction<boolean>>;
}

const socialLogo: { [key: string]: any } = {
  Email: envelop,
  Insta: insta,
  Whatsapp: whatsapp,
  Messenger: messenger,
};

const BotUser: React.FC<BotUserProps> = ({
  setCustomerInfo,
  socialIcon,
  lastMsg,
  lastMsgDate,
  missedChatCount,
  activeChatCount,
  setShowChat,
  customer,
  showOverlay,
  internalChat = false,
  setInternalChat,
}) => {
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );
  return (
    <div className="d-flex justify-content-center align-items-center mb-3 position-relative">
      <OverlayTrigger
        placement="left"
        overlay={
          <Popover
            id="popover-basic"
            bsPrefix={`popover ${styles.popoverWrapper}`}
          >
            <Popover.Body
              as="div"
              bsPrefix={`popover-body ${styles.botView}`}
            >
              <div className="p-2 d-flex flex-column">
                <span className={styles.overlaymsg}>
                  <ParseHTML html={lastMsg ?? ""} />
                </span>
                <span className={styles.overlayTime}>
                  {getTimeFromNow({
                    date: new Date(lastMsgDate ?? ""),
                    timeZone: userTimezone,
                  })}
                </span>
              </div>
            </Popover.Body>
          </Popover>
        }
        trigger={["hover", "focus"]}
        delay={200}
        show={showOverlay}
      >
        <div
          onClick={() => {
            setShowChat(true);
            setCustomerInfo();
            internalChat ? setInternalChat(true) : setInternalChat(false);
          }}
          className="cursor-pointer"
        >
          {customer?.imgURL ? (
            <AxiosImg
              url={customer.imgURL}
              className={styles.userName}
            />
          ) : (
            <UserAvatar
              name={customer?.name ?? "NA"}
              size={32}
            />
          )}
        </div>
      </OverlayTrigger>

      {/* {missedChatCount > 0 ? (
        <span className={styles.pendingMsg}>{missedChatCount}</span>
      ) : activeChatCount > 0 ? (
        <span className={styles.activeChatMsg}>{activeChatCount}</span>
      ) : (
        ""
      )} */}

      {socialIcon ? (
        <div className={styles.socialIcon}>
          <img src={socialLogo[socialIcon]} />
        </div>
      ) : ""}
    </div>
  );
};

export default BotUser;
