import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./PipelineModal.module.scss";
import TimelineForm, {
  OptionType,
  TimeLineData,
} from "./TimelineForm/TimelineForm";
import loader from "src/assets/animation/pipelineLoader.json";
import Lottie from "lottie-light-react";
import DeleteStage from "./DeleteStage/DeleteStage";
import SuccessMsg from "./SuccessMsg/SuccessMsg";
import AttributeForm, { AttributeData } from "./AttributeForm/AttributeForm";
import { useGetTimeLineEvents } from "../../hooks/useFetchTimeLIneEvent";
import { IgetAllEventsPayload } from "src/services/TimeLine/getAllEvents.service";

import { useUpdateTimelineEvents } from "../../hooks/useUpdateTimeLine";
import { UpdateTimelineEventPayload } from "src/services/TimeLine/updateTimeLineEvent";
import { generateDateOnly } from "src/utils/dateLibrary";
import { useConditionOptions } from "../../hooks/useConditionOption";
import { SingleValue } from "react-select";
import useGetAllEvents from "../../hooks/useGetAllEvents";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

interface Props {
  onHide: () => void;
  customerId: string | number;
  firstOpenTab: "events" | "timeline";
}
export interface EventData {
  listOptions?: [];
  typeKey?: any;
  eventId?: string | null;
  stageId?: string;
  attributeId?: string;
  datetime?: string;
  name?: string;
  stageName?: string;
  value?: any;
  type?: "custom_attribute" | "user_attribute";
  id?: number;
}
const PipelineModal = ({ onHide, customerId, firstOpenTab }: Props) => {
  const [activeButton, setActiveButton] = useState<"events" | "timeline">(
    firstOpenTab,
  );
  const [actiModal, setActiveModal] = useState<
    "loader" | "success" | "delete" | "modal"
  >("modal");
  const [deleteTimeLineIndex, setDeleteTimeLineIndex] = useState<number>();
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [deleteAttributeIndex, setDeleteAttributeIndex] = useState<number>();
  const getAllPayload: IgetAllEventsPayload = {
    customerId: customerId,
    eventType:
      activeButton === "events" ? "custom_attribute" : "user_attribute",
  };
  const {
    eventData,
    fetchStatus,
    handleInfiniteScroll,
    isFetching,
    status,
    hasEventNextPage,
  } = useGetAllEvents(getAllPayload);
  // console.log("isFetching", isFetching);

  const updateTimeLineData = useCallback(
    (data: EventData[]): TimeLineData[] => {
      return data.map((event) => ({
        id: event.id?.toString(),
        attributeId: event.attributeId,
        stageName: {
          label: event.name || "",
          value: event.name || "",
        },
        startDate: event.datetime ? new Date(event.datetime) : null,
        isError: false,
        isDuplicate: false,
        value: event.value,
        eventId: event.eventId,
        isUpdated: false,
        stageId: event.id?.toString(),
      }));
    },
    [],
  );

  const updateAttributeData = useCallback(
    (data: EventData[]): AttributeData[] => {
      return data.map((event) => ({
        attributeId: event.id?.toString(),
        name: {
          label: event.name || "",
          value: event.name || "",
        },
        isError: false,
        isDuplicate: false,
        value: event.value,
        type: event.typeKey,
        date: new Date(event.datetime || ""),
        eventId: event.eventId ?? null,
        isUpdated: false,
        listOptions: event.listOptions || [],
      }));
    },
    [],
  );

  const [timeLineData, setTimeLineData] = useState<TimeLineData[]>(
    updateTimeLineData(eventData),
  );
  const [attributeData, setAttributeData] = useState<AttributeData[]>(
    updateAttributeData(eventData),
  );
  const lastEventData = useRef<EventData[]>([]);

  useEffect(() => {
    if (
      fetchStatus === "fulfilled" &&
      eventData &&
      eventData.length > 0 &&
      eventData !== lastEventData.current // Ensure only new eventData is processed
    ) {
      lastEventData.current = eventData; // Update ref to latest eventData

      if (activeButton === "timeline") {
        setTimeLineData((prevData) =>
          prevData.length > 0
            ? [...updateTimeLineData(eventData)]
            : updateTimeLineData(eventData),
        );
      } else if (activeButton === "events") {
        setAttributeData((prevData) =>
          prevData.length > 0
            ? [...updateAttributeData(eventData)]
            : updateAttributeData(eventData),
        );
      }
    }
  }, [
    activeButton,
    eventData,
    fetchStatus,
    updateAttributeData,
    updateTimeLineData,
  ]);

  const { updateStatus, updateTimelineEvent } = useUpdateTimelineEvents();
  const handleSubmit = useCallback(async () => {
    let error = false;
    let newEventDataArray: EventData[] = []; // Collect all event data here

    const payLoad: UpdateTimelineEventPayload = {
      customerId: customerId,
      eventData: [],
      eventType:
        activeButton === "events" ? "custom_attribute" : "user_attribute",
      deletedEventIds: deletedIds,
    };

    if (activeButton === "events") {
      const updatedAttributeData = attributeData.map((data: AttributeData) => {
        // Validation for required fields and types
        let errorType=(data.type==="number" || data.type==="decimal") ?"number":"";
        if (
          data.name == null ||
          (data.type === "date" && data.date == null) ||
          (data.type === "number" && !/^\d*$/.test(data.value)) || // Validating integer numbers
          (data.type === "decimal" && !/^\d+(\.\d+)?$/.test(data.value)) || // Updated decimal validation
          data.value === ""
        ) {
          error = true;
          return { ...data, isError: true, errorType: errorType };
        }

        // Create EventData if the validation passes
        const newEventData: EventData = {
          attributeId: data.attributeId,
          datetime: generateDateOnly(data.date),
          name: data.name.label,
          value: data.value,
          type: "custom_attribute",
        };

        // Push to newEventDataArray if the data was updated
        if (data.isUpdated) {
          newEventDataArray.push(newEventData);
        }

        // Return the data with no error
        return { ...data, isError: false };
      });

      setAttributeData(updatedAttributeData);
    } else if (activeButton === "timeline") {
      const updatedTimeLineData = timeLineData.map((data) => {
        if (data.stageName == null || data.startDate == null) {
          error = true;
          return { ...data, isError: true };
        }
        const newEventData: EventData = {
          attributeId: data.attributeId,
          stageId: data.stageId,
          datetime: generateDateOnly(data.startDate),
          stageName: data.stageName.value,
          value: generateDateOnly(data.startDate),
          eventId: data.eventId?.toString() ?? null,
          type: "user_attribute",
        };

        if (data.isUpdated) {
          newEventDataArray.push(newEventData);
        }
        return { ...data, isError: false };
      });

      setTimeLineData(updatedTimeLineData);
    }

    if (!error) {
      payLoad.eventData = newEventDataArray;
      await updateTimelineEvent(payLoad);
    }
  }, [
    customerId,
    activeButton,
    deletedIds,
    attributeData,
    timeLineData,
    updateTimelineEvent,
  ]);

  // UseEffect to handle updateStatus changes
  useEffect(() => {
    if (updateStatus === "loading") {
      setActiveModal("loader");
    } else if (updateStatus === "success") {
      setActiveModal("success");
    } else if (updateStatus === "error") {
      onHide();
      pushTheToast({
        position: "top-right",
        text: "Error in adding data please try after some time!",
        type: "danger",
      });
    }
  }, [updateStatus]);

  const { stageData, options, fetchNextPage, hasNextPage } =
    useGetTimeLineEvents({
      customerId: customerId,
    });
  const handleTimeLineDelete = useCallback(
    (index: number) => {
      const updatedTimeLineData = timeLineData.filter((_, i) => i !== index);

      const id = timeLineData[index].eventId;

      if (id) {
        setDeletedIds((prev) => [...prev, id]); // Update the state with the new ID
      }
      setTimeLineData(updatedTimeLineData);
    },
    [timeLineData],
  );

  const handleAttributeDelete = useCallback(
    (index: number) => {
      const updatedAttributeData = attributeData.filter((_, i) => i !== index);
      const id = attributeData[index].eventId;

      if (id) {
        setDeletedIds((prev) => [...prev, id]); // Update the state with the new ID
      }
      setAttributeData(updatedAttributeData);
    },
    [attributeData],
  );
  //fetch options for attributeData
  const {
    attributeOptions,
    attributeTypeOptions,
    attributeCombinedOptions,
    attributeOptionFetchNextPage,
    attributeOptionhasNextPage,
  } = useConditionOptions({
    segmentType: "userSegment",
    initialPayload: {
      start: 0,
      limit: 10,
      isDefaultRequired: false,
      segmentId: "allUsers",
    },
  });
  const findTypeAndTypeKey = (newValue: SingleValue<OptionType>) => {
    const matchedOption = attributeCombinedOptions?.find(
      (opt) => opt.name === newValue?.label,
    );
    if (matchedOption) {
      const { type, typeKey } = matchedOption;
      return { type, typeKey };
    }

    return null; // Return null or handle case where no match is found
  };

  return (
    <div className={`${styles.modalWrapper}`}>
      {actiModal === "modal" ? (
        <div className="">
          <div className={`${styles.contentWrapper}`}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className={`mb-0 ${styles.heading}`}>
                Edit Attributes & Timeline
              </h4>
              <span
                className={`${styles.closeBtn}`}
                onClick={onHide}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
            <div
              className={`d-flex justify-content-between mb-3 w-100 ${styles.tabsWrapper}`}
            >
              <button
                className={`${styles.tab} ${
                  activeButton === "events" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveButton("events")}
              >
                Attributes
              </button>
              <button
                className={`${styles.tab} ${
                  activeButton === "timeline" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveButton("timeline")}
              >
                Timeline
              </button>
            </div>
            {/* Content/UI Section */}
            <div
            // className={`${styles.infiniteScrollDiv}`}
            >
              {activeButton === "events" ? (
                <div className={styles.ui1}>
                  <AttributeForm
                    setActiveModal={setActiveModal}
                    attributeData={attributeData}
                    setAttributeData={setAttributeData}
                    setDeleteAttributeIndex={setDeleteAttributeIndex}
                    customerId={customerId}
                    isDataLoading={fetchStatus === "pending"}
                    options={attributeOptions ?? []}
                    typeOptions={attributeTypeOptions ?? []}
                    combinedOptions={attributeCombinedOptions ?? []}
                    fetchNextPage={attributeOptionFetchNextPage}
                    hasNextPage={attributeOptionhasNextPage}
                    findTypeAndTypeKey={findTypeAndTypeKey}
                    status={status}
                    eventData={eventData}
                    handleInfiniteScroll={handleInfiniteScroll}
                    isFetching={isFetching}
                    hasEventNextPage={hasEventNextPage}
                  />
                </div>
              ) : (
                <div className={styles.ui2}>
                  <TimelineForm
                    setActiveModal={setActiveModal}
                    timeLineData={timeLineData}
                    setTimeLineData={setTimeLineData}
                    setDeleteTimeLineIndex={setDeleteTimeLineIndex}
                    customerId={customerId}
                    stageData={stageData}
                    options={options}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isDataLoading={fetchStatus === "pending"}
                    status={status}
                    eventData={eventData}
                    handleInfiniteScroll={handleInfiniteScroll}
                    isFetching={isFetching}
                    hasEventNextPage={hasEventNextPage}
                  />
                </div>
              )}
            </div>
            <div className={styles.footerDiv}>
              <div className={`${styles.modalFooter}`}>
                <button
                  className={`${styles.cancelBtn}`}
                  onClick={onHide}
                >
                  Cancel
                </button>
                <button
                  className={`${styles.saveBtn}`}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : actiModal === "loader" ? (
        <div className={`${styles.loader}`}>
          <Lottie
            animationData={loader}
            width={200}
            height={200}
          />
        </div>
      ) : actiModal === "delete" ? (
        <div className="">
          <DeleteStage
            setActiveModal={setActiveModal}
            handleTimeLineDelete={handleTimeLineDelete}
            deleteTimeLineIndex={deleteTimeLineIndex}
            handleAttributeDelete={handleAttributeDelete}
            deleteAttributeIndex={deleteAttributeIndex}
          />
        </div>
      ) : (
        <div className="">
          <SuccessMsg
            onHide={onHide}
            timeLineData={timeLineData}
            setTimeLineData={setTimeLineData}
          />
        </div>
      )}
    </div>
  );
};

export default PipelineModal;
