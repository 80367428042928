import { JSONTemplate } from "state/types/types";

interface JsonContent {
  id: string;
  type: string;
  values: any;
  styles?: Record<string, string>;
}

interface JsonColumn {
  id: string;
  contents: JsonContent[];
  values: any;
}

interface JsonRow {
  id: string;
  cells: number[];
  columns: JsonColumn[];
  values: any;
}

function parseStyles(styleString: string): Record<string, string> {
  return styleString.split(";").reduce(
    (styles, style) => {
      const [key, value] = style.split(":").map((s) => s.trim());
      if (key && value) {
        styles[key] = value;
      }
      return styles;
    },
    {} as Record<string, string>,
  );
}

function generateUniqueId(): string {
  return Math.random().toString(36).substr(2, 9);
}

function parseCssRules(
  cssText: string,
): Record<string, Record<string, string>> {
  const styleSheet = new CSSStyleSheet();
  styleSheet.replaceSync(cssText);
  const rules: Record<string, Record<string, string>> = {};

  for (const rule of styleSheet.cssRules) {
    if (rule instanceof CSSStyleRule) {
      rules[rule.selectorText] = parseStyles(rule.style.cssText);
    }
  }

  return rules;
}

export function convertHtmlToJson(html: string): JSONTemplate {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Extract styles from <style> tags
  const styleElements = doc.querySelectorAll("style");
  let cssRules: Record<string, Record<string, string>> = {};
  styleElements.forEach((styleElement) => {
    cssRules = {
      ...cssRules,
      ...parseCssRules(styleElement.textContent || ""),
    };
  });

  const body: JSONTemplate["body"] = {
    id: generateUniqueId(),
    rows: [],
    values: {},
    footers: [],
    headers: [],
  };

  const traverseElement = (element: Element): JsonContent => {
    const tagName = element.tagName.toLowerCase();
    const inlineStyles = element.getAttribute("style")
      ? parseStyles(element.getAttribute("style")!)
      : {};

    // Apply CSS rules from <style> tags
    const computedStyles = {
      ...cssRules[element.tagName.toLowerCase()],
      ...inlineStyles,
    };

    const styleValues = {
      containerPadding: computedStyles["padding"] || "0px",
      fontSize: computedStyles["font-size"] || "14px",
      textAlign: computedStyles["text-align"] || "left",
      lineHeight: computedStyles["line-height"] || "normal",
      // Add other style mappings as needed
    };

    switch (tagName) {
      case "img":
        return {
          id: generateUniqueId(),
          type: "image",
          values: {
            src: {
              url: element.getAttribute("src") || "",
              width: parseInt(element.getAttribute("width") || "0"),
              height: parseInt(element.getAttribute("height") || "0"),
            },
            altText: element.getAttribute("alt") || "",
            ...styleValues,
          },
        };
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6":
        return {
          id: generateUniqueId(),
          type: "heading",
          values: {
            headingType: tagName,
            text: element.innerHTML || "",
            ...styleValues,
          },
        };
      case "p":
      case "span":
      case "div":
      case "ul":
      case "ol":
      case "li":
        return {
          id: generateUniqueId(),
          type: "text",
          values: {
            text: element.innerHTML || "",
            ...styleValues,
          },
        };
      case "a":
        return {
          id: generateUniqueId(),
          type: "link",
          values: {
            href: element.getAttribute("href") || "",
            text: element.textContent || "",
            ...styleValues,
          },
        };
      default:
        return {
          id: generateUniqueId(),
          type: "text",
          values: {
            text: element.innerHTML || "",
            ...styleValues,
          },
        };
    }
  };
  const rows: JsonRow[] = [];
  doc.body.childNodes.forEach((node) => {
    if (node instanceof Element) {
      const content = traverseElement(node);
      rows.push({
        id: generateUniqueId(),
        cells: [1],
        columns: [
          {
            id: generateUniqueId(),
            contents: [content],
            values: {},
          },
        ],
        values: {
          _styleGuide: null,
          popupPosition: "center",
          popupWidth: "600px",
          popupHeight: "auto",
          borderRadius: "10px",
          contentAlign: "center",
          contentVerticalAlign: "center",
          contentWidth: 600,
          fontFamily: {
            label: "Arial",
            value: "arial,helvetica,sans-serif",
          },
          textColor: "#000000",
          popupBackgroundColor: "#FFFFFF",
          popupBackgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "cover",
            position: "center",
          },
          popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
          popupCloseButton_position: "top-right",
          popupCloseButton_backgroundColor: "#DDDDDD",
          popupCloseButton_iconColor: "#000000",
          popupCloseButton_borderRadius: "0px",
          popupCloseButton_margin: "0px",
          popupCloseButton_action: {
            name: "close_popup",
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          language: [],
          backgroundColor: "#F7F8F9",
          preheaderText: "",
          linkStyle: {
            body: true,
            linkColor: "#0000ee",
            linkHoverColor: "#0000ee",
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          _meta: {
            htmlID: "u_body",
            htmlClassNames: "u_body",
          },
        },
      });
    }
  });

  body.rows = rows;

  return {
    counters: {}, // Implement counters logic
    body: body,
    schemaVersion: 12,
  };
}

export const extractHeading = (design: JSONTemplate) => {
  for (const row of design.body.rows) {
    const headingContent = row.columns
      .flatMap((column) => column.contents)
      .find((content) => content.type === "heading");

    if (headingContent) {
      return headingContent.values.text;
    }
  }
  return "";
};
