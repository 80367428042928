import { axiosJSON } from "src/globals/axiosEndPoints";
import { findAutomationContextFromURLPath } from "src/utils/utils";

async function deleteReturnIntegrationService(params: {
  integrationId: string;
}) {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const { data: res } = await axiosJSON.delete(
    `/api/setting/automation/${automationContext}/delete`,
    { params },
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some Error Occurred while fetching data",
    );
  }

  return true;
}

export default deleteReturnIntegrationService;
