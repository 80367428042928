import { useCallback, useState } from "react";
import TemplatePreviewSection from "./Children/TemplatePreviewSection/TemplatePreviewSection";
import TemplateSidebar from "./Children/TemplateSidebar/TemplateSidebar";
import styles from "./ChooseATemplate.module.scss";
import CreateNewTemplate from "./Children/CreateNewTemplate/CreateNewTemplate";
import Successui from "./Children/SuccessUi/Successui";
import TemplateSection from "./Children/TemplateSection/TemplateSection";
import { useAddCampaignState } from "../../../../Hooks/useAddCampaignContext";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import { useQueryClient } from "@tanstack/react-query";
import { InfiniteGetAllEmailTemplate } from "src/routes/Campaigns/Children/hooks/useFetchCampaignTemplates";
import { ITemplateCategory } from "src/services/Campaign/getAllEmailTemplate.service";

const tabs = [
  { label: "Email" },
  // { label: "In-app Message" },
  // { label: "LinkedIn" },
];

const ChooseTemplate = () => {
  const { state, dispatch } = useAddCampaignState();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [newTemplate, setNewTemplate] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [action, setAction] = useState<"create" | "edit" | null>(null);

  const addTemplate = useCallback(
    (template: IEmailTemplateVariant) => {
      dispatch("updateState", {
        activeTemplate: template,
      });

      if (state.allTemplatesQueryKey) {
        // Update the cached query data by creating a new category
        queryClient.setQueryData<InfiniteGetAllEmailTemplate>(
          state.allTemplatesQueryKey,
          (oldData) => {
            if (!oldData) return;

            // Create a copy of the old data
            const newData = { ...oldData };

            // Define a new category
            const newCategory: ITemplateCategory = {
              id: template.categoryId,
              name: template.categoryName ?? "",

              templates: [
                {
                  id: template.templateId,
                  name: template.templateName ?? "",
                  iconUrl: template.iconUrl ?? "",
                  variants: [
                    {
                      id: template.variantId,
                      name: template.variantName,
                    },
                  ],
                },
              ],
            };

            // Add the new category to the first page of allTemplateCategories
            newData.pages[0] = {
              ...newData.pages[0],
              allTemplateCategories: [
                newCategory,
                ...newData.pages[0].allTemplateCategories,
              ],
            };

            return newData;
          },
        );
      }
    },
    [dispatch, queryClient, state.allTemplatesQueryKey],
  );

  const onDeleteTemplate = useCallback(
    (templateId: string | number) => {
      if (state.allTemplatesQueryKey) {
        // Get the current query data
        const queryData = queryClient.getQueryData<InfiniteGetAllEmailTemplate>(
          state.allTemplatesQueryKey,
        );

        if (!queryData || queryData.pages.length === 0) {
          console.error("No templates available.");
          return;
        }

        // Find the first category, first template, and first variant
        const firstCategory = queryData.pages[0]?.allTemplateCategories[0];
        const firstTemplate = firstCategory?.templates[0];
        const firstVariant = firstTemplate?.variants[0];

        // Set activeTemplate to the first variant of the first template in the first category
        if (firstVariant) {
          dispatch("updateState", {
            templateId: firstTemplate.id,
            variantId: firstVariant.id,
            categoryId: firstCategory.id,
          });
        }
      }
    },
    [state, dispatch, queryClient],
  );

  const createHtml = useCallback(() => {
    if (state.activeTemplate?.html && state.activeTemplate?.templateName) {
      const blob = new Blob([state.activeTemplate?.html], {
        type: "text/html",
      });

      // Create a File object from the Blob
      const file = new File([blob], state.activeTemplate?.templateName, {
        type: "text/html",
      });
      return file;
    }
    return null;
  }, [state.activeTemplate]);

  const createJson = useCallback(() => {
    // Check if `design` and `templateName` are available in the active template
    if (state.activeTemplate?.design && state.activeTemplate?.templateName) {
      // Convert the design object to a JSON string
      const jsonString = JSON.stringify(state.activeTemplate.design, null, 2);

      // Create a Blob with the JSON data
      const blob = new Blob([jsonString], { type: "application/json" });

      // Create a File object from the Blob
      const file = new File(
        [blob],
        `${state.activeTemplate.templateName}.json`, // Filename with .json extension
        { type: "application/json" },
      );

      return file;
    }

    // Return null if design data is not available
    return null;
  }, [state.activeTemplate]);

  return (
    <div className={styles.container}>
      {/* Tabs */}
      <div className={`ms-2 ${styles.tabContainer}`}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${styles.tabButton} ${
              activeTab === index ? styles.active : ""
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Email Template Section */}
      <div className={styles.content}>
        <TemplateSidebar
          onUseNewTemplate={() => setNewTemplate((prev) => !prev)}
        />
        {newTemplate ? (
          !showSuccess ? (
            <div
              className={`${styles.rightSection} d-flex justify-content-center align-items-center`}
            >
              <CreateNewTemplate
                onSuccess={(template) => {
                  setShowSuccess(true);
                  addTemplate(template);
                }}
                createJson={createJson}
                createHtml={createHtml}
                action={action}
                templateId={state.activeTemplate?.templateId ?? ""}
              />
            </div>
          ) : (
            <div className={` ${styles.successUi}`}>
              <div className="d-flex justify-content-center align-items-center h-100">
                <Successui
                  onHide={() => {
                    setShowSuccess(false);
                  }}
                />
              </div>
            </div>
          )
        ) : (
          <>
            {state.activeTemplate && !state.activeTemplate.isDefault ? (
              <div className={` ${styles.successUi}`}>
                <TemplateSection
                  template={state.activeTemplate}
                  onDelete={onDeleteTemplate}
                  onEdit={() => {
                    setNewTemplate(true);
                    setAction("edit");
                  }}
                />
              </div>
            ) : (
              <div className={`${styles.successUi}`}>
                <TemplatePreviewSection />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChooseTemplate;
