// File: TimelineAttributeForm.tsx
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { TemplateProvider } from "src/components/ScheduleModal/Children/MainSection/TemplateContext";
import ScheduleModal from "src/components/ScheduleModal/ScheduleModal";
import styles from "./TimelineAttributeForm.module.scss";

interface TimelineAttributeFormProps {
  onChange: (
    index: number,
    name: string,
    value: number,
    notSureOfDuration: boolean,
  ) => void;
  index: number;
  length: number;
  onDelete: (index: number) => void;
  form: { name: string; value: number; id: string; notSureOfDuration: boolean };
  error: boolean;
  isDuplicate: boolean;
}

const TimelineAttributeForm: React.FC<TimelineAttributeFormProps> = ({
  onChange,
  index,
  length,
  form,
  onDelete,
  error,
  isDuplicate,
}) => {
  const [name, setName] = useState(form.name);
  const [value, setValue] = useState(form.value);
  const [isError, setIsError] = useState(false);
  const [schedulePopoverState, setSchedulePopoverState] = useState(false);
  // const [scheduleWith, setScheduleWith] = useState<string | null>(null);
  const [automatedFolloupModal, setAutomatedFolloupModal] = useState(false);
  const [notSureOfDuration, setnotSureOfDuration] = useState(
    form.notSureOfDuration,
  );

  useEffect(() => {
    setIsError(error);
  }, [error]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (notSureOfDuration) {
      return;
    }
    const newValue = Number(e.target.value);
    if (newValue < 0) {
      setValue(0);
      onChange(index, name, 0, notSureOfDuration);
      return;
    }
    setValue(newValue);
    onChange(index, name, newValue, notSureOfDuration);
  };

  const handleIncrement = () => {
    if (notSureOfDuration) {
      return;
    }
    const newValue = value + 1;
    setValue(newValue);
    onChange(index, name, newValue, notSureOfDuration);
  };

  const handleDecrement = () => {
    if (notSureOfDuration) {
      return;
    }
    const newValue = value - 1;
    if (newValue < 0) {
      setValue(0);
      onChange(index, name, 0, notSureOfDuration);
      return;
    }
    setValue(newValue);
    onChange(index, name, newValue, notSureOfDuration);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    if (newName.trim()) {
      setIsError(false);
    } else if (error) {
      setIsError(true);
    }
    onChange(index, newName, value, notSureOfDuration);
  };

  const handlenotSureOfDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setnotSureOfDuration(checked);
    onChange(index, name, value, checked);
  };

  const onShow = (e: any, value: string | null) => {
    e.stopPropagation();
    // setScheduleWith(value);
    setAutomatedFolloupModal(true);
    setSchedulePopoverState(false);
  };
  const onHide = () => {
    setAutomatedFolloupModal(false);
  };

  const popover = (
    <Popover
      id="popover-basic"
      bsPrefix={`popover ${styles.popWrapper}`}
    >
      <Popover.Body className={`${styles.popoverBody}`}>
        <div>
          <div
            className={`${styles.scheduleList}`}
            onClick={(e) => onShow(e, null)}
          >
            Schedule
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      className={`d-flex align-items-center pb-3 mb-2 pe-4  ${
        length !== 1 ? styles.borderBottom : ""
      }`}
    >
      <div className="me-3">
        {index === 0 && (
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeName-${index}`}
          >
            Stage name
          </label>
        )}
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          id={`attributeName-${index}`}
          placeholder="Add stage name..."
          className={`d-block px-2 ${styles.stagNameInput} ${
            isError && form.name.trim() === "" ? "border-danger" : ""
          }`}
        />
        {isError && (form.name.trim() === "" || isDuplicate) && (
          <span className={styles.errorText}>
            {isDuplicate
              ? `Stage ${form.name} is already given. Please add another`
              : "*Please add the above details to move forward"}
          </span>
        )}
      </div>
      <div className="me-2">
        {index === 0 && (
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeDuration-${index}`}
          >
            Approximate stage duration
          </label>
        )}
        <div className="d-flex">
          <div className="d-flex align-items-center me-3">
            <div className="position-relative">
              <input
                type="number"
                value={value}
                disabled={form.notSureOfDuration}
                min={0}
                onChange={handleChange}
                placeholder="00"
                className={`ps-2 ${styles.daysBox}`}
              />
              <div
                className={`d-flex flex-column justify-content-center ${styles.arrowWrapper}`}
              >
                <span
                  className={`${styles.arrows} ${
                    notSureOfDuration ? styles.disabled : ""
                  }`}
                  onClick={handleIncrement}
                >
                  <i className="fa-solid fa-angle-up"></i>
                </span>
                <span
                  className={`${styles.downArrow} ${
                    notSureOfDuration ? styles.disabled : ""
                  }`}
                  onClick={handleDecrement}
                >
                  <i className="fa-solid fa-angle-down"></i>
                </span>
              </div>
            </div>
            <span className={`${styles.daysText} ps-1`}>Days</span>
          </div>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={form.notSureOfDuration}
                id={`flexCheckDefault-${index}`}
                onChange={handlenotSureOfDuration}
              />

              <label
                className={`form-check-label ${styles.durationText}`}
                htmlFor={`flexCheckDefault-${index}`}
              >
                Not sure of the duration
              </label>
            </div>
          </div>
        </div>
      </div>
      <div>
        {index === 0 && (
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeIcon-${index}`}
          >
            Icon
          </label>
        )}
        <div className="d-flex align-items-center">
          <div className={`${styles.iconWrapper} me-2`}>
            <div
              className={`${styles.iconBox} d-flex align-items-center justify-content-center`}
            >
              <span className={`${styles.leftText}`}>U</span>
              <div className="d-flex flex-column justify-content-center">
                <span className={`${styles.rightText}`}>
                  {form.name?.trim()?.length ? form.name.trim()[0] : "n"}
                </span>
                <span className={`${styles.rightText}`}>{index + 1}</span>
              </div>
            </div>
          </div>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="top-end"
              overlay={popover}
              onToggle={(nextShow) => {
                setSchedulePopoverState(nextShow);
              }}
              // onEntering={() => setSchedulePopoverState(true)}
              // onExiting={() => setSchedulePopoverState(false)}
              show={schedulePopoverState}
              rootClose={true}
            >
              <div
                className={`btn d-flex  my-auto ms-1 ${styles.sendBtn} ${styles["translate-up"]}`}
              >
                <span className={`my-auto`}>
                  <i className="fa-regular fa-clock"></i>
                </span>
                <span className={`mx-2 my-auto ${styles.line}`}></span>
                <span className={`${styles.toggleIcon}`}>
                  <i
                    className={`fa-solid fa-chevron-${
                      schedulePopoverState ? "up" : "down"
                    }`}
                  ></i>
                </span>
              </div>
            </OverlayTrigger>
          </div>
          {index !== 0 && (
            <div>
              <span
                className={`w-0 ${styles.deleteBtn}`}
                onClick={() => onDelete(index)}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
        // backdropClassName={`${styles.modalBack}`}
        show={automatedFolloupModal}
        className={`${styles.modalClass}`}
        onHide={onHide}
        dialogClassName={`${styles.modalScheduleDialog}`}
        contentClassName={`${styles.modalScheduleContent}`}
        enforceFocus={false}
      >
        <TemplateProvider scheduleMessageFor="userSegment">
          <ScheduleModal
            ticketId={form.id}
            onHide={onHide}
            scheduleMessageFor="userSegment"
            scheduleWith={"open"}
          />
        </TemplateProvider>
      </Modal>
    </div>
  );
};

export default TimelineAttributeForm;
