import {
  BetweenRange,
  Currency,
  CurrencyBetweenRange,
  HoursDays,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import {
  ConditionI,
  IActionForModal,
} from "src/services/Automation/getAutomationById.service";
import { isMySQLDateStringValid } from "src/utils/dateLibrary";

export const emailConditionTypes = [
  "email",
  "fromEmailAddress",
  "toEmailAddress",
  "toEmailAddress",
  "ccBccEmailAddress",
  "contactEmail",
];

export const emailCompareType = ["is", "isNot"];

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email));
}

/**
 * This function validates the payload and update the condition with with correct value or error value
 *
 * @param condition Condition to validate
 * @returns Value or error depending on validations
 */
export const validatePayloadConditions = (
  condition: ConditionI | IActionForModal,
) => {
  if (condition.fieldDataType === "string") {
    // Check if value is a non-empty string
    if (
      !Array.isArray(condition.value) &&
      !(typeof condition.value === "string" && condition.value.trim() !== "")
    ) {
      return { error: true };
    }

    // Handle multi text string input (if value is an array)
    if (Array.isArray(condition.value)) {
      if (condition.value.length === 0) return { error: true };
      if (
        (condition as ConditionI).conditionType &&
        emailConditionTypes.includes(
          (condition as ConditionI).conditionType ?? "",
        ) &&
        emailCompareType.includes(
          (condition as ConditionI).compareType ?? "",
        ) &&
        condition.value.some((email) => !validateEmail(email + ""))
      ) {
        return { error: true };
      }
    }
  } else if (condition.fieldDataType === "number") {
    const parsedValue = parseFloat(`${condition.value}`);

    // Check if value is a valid number and >= 0
    if (
      (typeof condition.value === "string" ||
        typeof condition.value === "number") &&
      !isNaN(parsedValue) &&
      parsedValue > 0
    ) {
      return {
        value: parsedValue,
      };
    } else {
      return { error: true };
    }
  } else if (condition.fieldDataType === "numberRange") {
    const parsedStart = parseFloat(
      `${(condition.value as BetweenRange)?.start}`,
    );
    const parsedEnd = parseFloat(`${(condition.value as BetweenRange)?.end}`);
    // Check if start and end are valid numbers and start < end
    if (
      typeof condition.value === "object"
        ? (typeof (condition.value as BetweenRange)?.start === "string" ||
            typeof (condition.value as BetweenRange)?.start === "number") &&
          !isNaN(parsedStart) &&
          parsedStart >= 0 &&
          (typeof (condition.value as BetweenRange)?.end === "string" ||
            typeof (condition.value as BetweenRange)?.end === "number") &&
          !isNaN(parsedEnd) &&
          parsedEnd >= 0 &&
          parsedStart < parsedEnd
        : false
    ) {
      return {
        value: {
          start: parseFloat(`${(condition.value as BetweenRange).start}`),
          end: parseFloat(`${(condition.value as BetweenRange).end}`),
        },
      };
    } else {
      return { error: true };
    }
  } else if (condition.fieldDataType === "select") {
    switch (typeof condition.value) {
      case "string": {
        // Check if value is a non-empty string
        if (condition.value.trim() === "") {
          return { error: true };
        } else if (condition.valueIndex?.length) {
          const v = condition.value.split("::");
          // Check if all parts of the value are present
          for (let i = 0; i < (condition.valueIndex?.length ?? 0); i++) {
            const data = v[i];
            if (!data) {
              return { error: true };
            }
          }
        }
        break;
      }
      case "object": {
        // Check if value is an array of non-empty strings
        if (Array.isArray(condition.value)) {
          for (let i = 0; i < condition.value.length; i++) {
            const v = condition.value[i];
            if (!(typeof v === "string" && v.trim() !== "")) {
              return { error: true };
            } else if (condition.valueIndex?.length) {
              const v2 = v.split("::");
              // Check if all parts of the value are present
              for (let j = 0; j < (condition.valueIndex?.length ?? 0); j++) {
                const data = v2[j];
                if (!data) {
                  return { error: true };
                }
              }
            }
          }
        } else {
          return { error: true };
        }
        break;
      }
      default: {
        return { error: true };
      }
    }
  } else if (
    condition.fieldDataType === "date" ||
    condition.fieldDataType === "dateOrDateTime" ||
    condition.fieldDataType === "dateTime"
  ) {
    // Check if value is a valid date string
    if (
      !isMySQLDateStringValid(condition.value + "", condition.fieldDataType)
    ) {
      return { error: true };
    }
  } else if (
    condition.fieldDataType === "dateRange" ||
    condition.fieldDataType === "dateOrDateTimeRange" ||
    condition.fieldDataType === "dateTimeRange"
  ) {
    // Check if start and end are valid date strings
    if (
      typeof condition.value !== "object" ||
      !isMySQLDateStringValid(
        (condition.value as BetweenRange<string>)?.start ?? "",
        condition.fieldDataType.replace("Range", "") as any,
      ) ||
      !isMySQLDateStringValid(
        (condition.value as BetweenRange<string>)?.end ?? "",
        condition.fieldDataType.replace("Range", "") as any,
      )
    ) {
      return { error: true };
    }
  } else if (condition.fieldDataType === "hoursOrDaysNumeric") {
    if (
      !(condition?.value as HoursDays)?.value ||
      !(condition?.value as HoursDays)?.time
    ) {
      return { error: true };
    }
  } else if (condition.fieldDataType === "currencyNumeric") {
    if (
      !(condition?.value as Currency)?.value ||
      !(condition?.value as Currency)?.currency
    ) {
      return { error: true };
    }
  } else if (condition.fieldDataType === "currencyRangeNumeric") {
    if (
      !(condition?.value as Currency)?.value ||
      !(condition?.value as Currency)?.currency
    ) {
      return { error: true };
    } else {
      const parsedStart = parseFloat(
        `${(
          (condition?.value as CurrencyBetweenRange)?.value as
            | BetweenRange<string>
            | undefined
        )?.start}`,
      );
      const parsedEnd = parseFloat(
        `${(
          (condition?.value as CurrencyBetweenRange)?.value as
            | BetweenRange<string>
            | undefined
        )?.end}`,
      );
      // Check if start and end are valid numbers and start < end
      if (
        typeof condition.value === "object"
          ? (typeof (
              (condition?.value as CurrencyBetweenRange)?.value as BetweenRange
            )?.start === "string" ||
              typeof (
                (condition?.value as CurrencyBetweenRange)
                  ?.value as BetweenRange
              )?.start === "number") &&
            !isNaN(parsedStart) &&
            parsedStart >= 0 &&
            (typeof (
              (condition?.value as CurrencyBetweenRange)?.value as BetweenRange
            )?.end === "string" ||
              typeof (
                (condition?.value as CurrencyBetweenRange)
                  ?.value as BetweenRange
              )?.end === "number") &&
            !isNaN(parsedEnd) &&
            parsedEnd >= 0 &&
            parsedStart < parsedEnd
          : false
      ) {
        return {
          value: {
            value: {
              start: parseFloat(
                `${(
                  (condition?.value as CurrencyBetweenRange)
                    ?.value as BetweenRange
                )?.start}`,
              ),
              end: parseFloat(
                `${(
                  (condition?.value as CurrencyBetweenRange)
                    ?.value as BetweenRange
                )?.end}`,
              ),
            },
            currency: (condition?.value as CurrencyBetweenRange)?.currency,
          },
        };
      } else {
        return { error: true };
      }
    }
  }
};
