/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

interface GetCampaignById {
  campaignId: number;
}

export const getCampaignTimeline = async (params: GetCampaignById) => {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/getTimeline",
    params,
  );
  if (res.err) {
    throw res.msg;
  }

  return res.data?.timelineInfo as TimelineData;
};
