import { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./Attributes.module.scss";
import AttributeModal from "./Children/AttributeModal/AttributeModal";
import { CampaignSegmentKeyAttribute } from "src/services/Campaign/getCampaignById";

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const maxVisibleItems = 4;

const Attributes = ({
  keyAttributes,
}: {
  keyAttributes: Array<CampaignSegmentKeyAttribute>;
}) => {
  const [attributeModal, setAttributeModal] = useState(false);

  return (
    <div className="d-flex flex-wrap">
      {keyAttributes
        .slice(0, maxVisibleItems)
        .map(({ key, condition, value }) => (
          <span
            key={key}
            className={`me-1 mb-1 ${styles.attribute}`}
          >
            {truncateText(
              `${key[0].toUpperCase() + key.slice(1)}= ${condition} ${
                value ?? ""
              }`,
              31,
            )}
          </span>
        ))}
      {keyAttributes.length > maxVisibleItems && (
        <>
          <span
            className={`me-1 mb-1 ${styles.attribute} text-decoration-underline cursor-pointer`}
            onClick={() => setAttributeModal(true)}
          >
            +{keyAttributes.length - maxVisibleItems}
          </span>
          <Modal
            show={attributeModal}
            onHide={() => setAttributeModal(false)}
            dialogClassName={`${styles.attrModalDialog}`}
            contentClassName={`${styles.attrModalContent}`}
            centered={true}
            backdropClassName={`${styles.backDrop}`}
          >
            <AttributeModal
              onHide={() => setAttributeModal(false)}
              keyAttributes={keyAttributes}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default Attributes;
