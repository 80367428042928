import ParseHTML from "src/components/ParseHTML";

const string = `<table bgcolor="#f6f6f6" border="0" cellpadding="0" cellspacing="0" class="gwfw" width="100%">
	<tbody>
		<tr>
			<td align="center" valign="top">
			<table border="0" cellpadding="0" cellspacing="0" class="m-shell" style="max-width: 600px; width: 100%;">
				<tbody>
					<tr>
						<td class="td" style="width: 100%; max-width: 600px; font-size: 0pt; line-height: 0pt; font-weight: normal; margin: 0; padding: 0;">
						<table border="0" cellpadding="0" cellspacing="0" width="100%">
							<tbody>
								<tr>
									<td class="plr15" style="padding: 0 10px;">
									<table border="0" cellpadding="0" cellspacing="0" width="100%"><!-- Header -->
										<tbody>
											<tr>
												<td class="img pt-30" style="font-size: 0pt; line-height: 0pt; padding: 20px 8px 8px;" align="left"><img alt="" border="0" height="36" src="https://appboy-images.com/appboy/communication/assets/image_assets/images/6092baae9fbeb84e286f14b9/original.png?1620228782" width="150" style="-ms-interpolation-mode: bicubic;"></td>
											</tr>
											<!-- END Header --><!-- Section 1 -->
											<tr>
												<td style="padding-bottom: 30px;">
												<table border="0" cellpadding="0" cellspacing="0" width="100%">
													<tbody>
														<tr>
															<td bgcolor="#ffffff" style="border-radius: 6px; border: 1px solid #ececee;">
															<table border="0" cellpadding="0" cellspacing="0" width="100%">
																<tbody>
																	<tr>
																		<td class="fluid-img" style="padding-bottom: 28px; font-size: 0pt; line-height: 0pt;" align="left"><img alt="" border="0" height="72" src="https://appboy-images.com/appboy/communication/assets/image_assets/images/607d9d89d6021066e0b9038b/original.png?1618845065" style="border-radius: 6px 6px 0 0; max-width: 600px; width: 100%; -ms-interpolation-mode: bicubic;"></td>
																	</tr>
																	<tr>
																		<td class="plr-15" style="padding: 0 30px;">
																		<table border="0" cellpadding="0" cellspacing="0" width="100%">
																			<tbody>
																				<tr>
																					<td class="text" style="padding-bottom: 18px; color: #1a1a1a; font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; min-width: auto !important;" align="left">Hi Jake,<br>
																					<br>
																					<span style="color: #006BFF; text-decoration: none;">Check out our guide</span> to maximizing your free Calendly account so you can save as much time as possible without spending a dime.&nbsp;<br>
																					<br>
																					Also, you may have missed it, but our <span  style="color: #006BFF; text-decoration: none;">Premium and Pro plans</span> include automated reminders, unlimited event types and multiple calendar connections—plus integrations with Salesforce, Zapier, Google Analytics and more.<br>
																					<br>
																					See how millions of people simplify scheduling with Calendly!</td>
																				</tr>
																				<tr>
																					<td align="center" style="padding-bottom: 32px;">
																					<table border="0" cellpadding="0" cellspacing="0">
																						<tbody>
																							<tr>
																								<td bgcolor="#00a2ff" class="text-btn" style="border-radius: 23px; mso-padding-alt: 14px 35px; color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 16px; line-height: 20px; min-width: auto !important;" align="center"><span class="link-white"  style="display: block; color: #ffffff; text-decoration: none; padding: 14px 35px;" target="_blank">Read the guide</span></td>
																							</tr>
																						</tbody>
																					</table>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																		</td>
																	</tr>
																</tbody>
															</table>
															</td>
														</tr>
													</tbody>
												</table>
												</td>
											</tr>
											<!-- END Section 7 --><!-- Footer -->
											<tr>
												<td style="padding-left: 10px;">
												
<table border="0" cellpadding="0" cellspacing="0" width="100%">
	<tbody>
		<tr>
			<td class="text-footer" style="border-bottom-width: 1px; border-bottom-color: #ececee; border-bottom-style: solid; padding-bottom: 5px; color: #a8a8a8; font-family: Helvetica, Arial, sans-serif; font-size: 12px; line-height: 16px; min-width: auto !important;" align="left">Sent from Calendly<br>
			271 17th St. NW<br>
			Suite 1000<br>
			At&zwnj;lan&zwnj;ta,&zwnj; GA&zwnj; 30&zwnj;30&zwnj;9</td>
		</tr>
		<tr>
			<td class="text-footer pb-32" style="color: #a8a8a8; font-family: Helvetica, Arial, sans-serif; font-size: 12px; line-height: 16px; min-width: auto !important; padding: 8px 0 20px;" align="left"><span class="link3"  style="color: #666a73; text-decoration: none;"><span class="link3" style="color: #666a73; text-decoration: none;">Unsubscribe</span></span> from our emails</td>
		</tr>
	</tbody>
</table>

												</td>
											</tr>
											<!-- END Footer -->
										</tbody>
									</table>
									</td>
								</tr>
							</tbody>
						</table>
						</td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
	</tbody>
</table>`;

const Calendly = () => {
  return <ParseHTML html={string} />;
};

export default Calendly;
