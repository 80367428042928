import { useMemo, useState } from "react";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { SegmentColumn } from "src/services/CustomerSegments/getSegmentColumns.service";
import { SegmentValueItem } from "src/services/CustomerSegments/getSegmentValues.service";
import { getTimeFromNow } from "src/utils/dateLibrary";
import styles from "./TableRow.module.scss";

const TableRow = ({
  id,
  segmentValues,
  allColumnKeys,
  allColumns,
  onClickHandler,
}: {
  id: string;
  segmentValues: Record<string, SegmentValueItem>;
  allColumnKeys: string[];
  allColumns: Record<string, SegmentColumn>;
  onClickHandler: (id: string) => void;
}) => {
  const segmentValue = useMemo(() => {
    return segmentValues[id];
  }, [segmentValues, id]);

  const [hover, setHover] = useState<boolean>(false);

  const columns = useMemo(() => {
    const columns: SegmentColumn[] = [];
    allColumnKeys.forEach((key) => {
      const column = allColumns[key];
      if (column?.selected || column?.required) {
        columns.push(column);
      }
    });
    return columns;
  }, [allColumnKeys, allColumns]);

  return (
    <div
      className={` mb-2 position-relative ${styles.tableHeader}`}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      onClick={() => onClickHandler(id)}
    >
      <div className={`${styles.hoverBar}`}></div>
      {columns.map((column, index) => {
        const segmentColValue = segmentValue[column.key];
        const isKnownType =
          typeof segmentColValue === "number" ||
          (typeof segmentColValue === "string" &&
            segmentColValue.trim() !== "") ||
          typeof segmentColValue === "boolean";

        return (
          <div
            className={`${styles.headerElement} ${
              hover ? styles.hoverClass : ""
            } ${index === 0 ? styles.firstEle : ""} ${
              index === columns.length - 1 ? styles.lastEle : ""
            }`}
          >
            {index === 0 && (
              <div className="me-2">
                {segmentValue.imageUrl &&
                segmentValue.imageUrl?.trim() !== "" ? (
                  <AxiosImg
                    url={segmentValue.imageUrl}
                    className={`rounded-circle`}
                    style={{ width: "19px", height: "19px" }}
                  />
                ) : (
                  <UserAvatar
                    name={segmentValue[column.key] ?? "NA"}
                    size={19}
                  />
                )}
              </div>
            )}

            <div className={`pe-2 ${styles.tableText}`}>
              <span className={`${!isKnownType ? styles.clock : ""}`}>
                {isKnownType
                  ? column.key === "lastSeen"
                    ? getTimeFromNow({
                        date: new Date(segmentValue[column.key]),
                        showJustNow: false,
                        need: "both",
                      })
                    : `${segmentValue[column.key]}`
                  : "Unknown"}
              </span>
            </div>
          </div>
        );
      })}
      {/* <div className={`${styles.headerElement}`}>
        <div className={`${styles.tableText}`}>
          <span>
            <i className="fa-regular fa-building"></i>
          </span>
          <span className="ps-1">{name} </span>
        </div>
      </div> */}
    </div>
  );
};

export default TableRow;
