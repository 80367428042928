import { useMemo } from "react";
import style from "./Outgoingmailsetting.module.scss";
import { OutgoingRecieveType } from "src/services/EmailIntegration/getEmailIntegrationById";
import { MailTypeEnum } from "src/store/slices/emailIntegration/emailIntegration.slices";
import { useAppSelector } from "src/store/store";

interface Props {
  selectedType: OutgoingRecieveType;
  preferred: OutgoingRecieveType;
  onSelect: (selected: OutgoingRecieveType) => void;
  isEmailSetup?: boolean;
  isOutboundEmail?: boolean;
}

const OutgoingMailSetting = ({
  selectedType,
  preferred,
  onSelect,
  isEmailSetup,
  isOutboundEmail = false,
}: Props) => {
  const { mailType, isDomainVerified, outgoingMailSettings } = useAppSelector(
    (state) => {
      return state.emailIntegration.selectedUser;
    },
  );

  const allowSwitchOutgoing = useMemo(() => {
    return (
      outgoingMailSettings?.integrations &&
      Object.keys(outgoingMailSettings.integrations).length
    );
  }, [outgoingMailSettings?.integrations]);

  return (
    <>
      <div
        className={`ms-2 mt-5 ${style.container} ${isEmailSetup && "w-100"}`}
      >
        {/* Heading */}
        <div className={`${style.topContainer}`}>
          <p>
            How would you like to configure Saufter to receive your outgoing
            emails?
          </p>
        </div>

        {/* Buttons */}
        <div className={style.bottomContainer}>
          {/* Gmail */}
          {(mailType.id === MailTypeEnum.gmail || allowSwitchOutgoing) && (
            <div className="form-check mt-3">
              <input
                className={`form-check-input ${style.inputRadio}`}
                type="radio"
                name="gmail"
                id="gmail"
                // disabled={isDomainVerified}
                checked={selectedType === "gmail"}
                onChange={() => onSelect("gmail")}
              />
              <label
                className={`form-check-label ${style.formLabel}`}
                htmlFor="gmail"
              >
                Please send using Gmail's API. This will only require to enter
                the username and password for Gmail.
                <span className={`ps-2 ${style.link} d-none`}>
                  See how it works
                </span>
                {preferred === "gmail" && (
                  <div className={`${style.preferred}`}>Preferred</div>
                )}
              </label>
            </div>
          )}

          {/* Current Host */}
          <div className="d-none form-check mt-3">
            <input
              className={`form-check-input ${style.inputRadio}`}
              type="radio"
              // disabled={isDomainVerified}
              name="currentHost"
              id="currentHost"
              checked={selectedType === "currentHost"}
              onChange={() => onSelect("currentHost")}
            />
            <label
              className={`form-check-label ${style.formLabel}`}
              htmlFor="currentHost"
            >
              I'm happy to use Saufter's domain to send out emails for now.
              <span className={`ps-2 ${style.link} d-none`}>
                See how it works
              </span>
              {preferred === "currentHost" && (
                <div className={`${style.preferred} d-inline-flex ms-2`}>
                  Preferred
                </div>
              )}
            </label>
          </div>

          {/* Current Host With Custom DNS */}
          {(mailType.id === MailTypeEnum.others || allowSwitchOutgoing) && (
            <div className="form-check mt-3">
              <input
                className={`form-check-input ${style.inputRadio}`}
                type="radio"
                name="currentHostWithDNS"
                id="currentHostWithDNS"
                // disabled={isDomainVerified}
                checked={selectedType === "currentHostWithDNS"}
                onChange={() => onSelect("currentHostWithDNS")}
              />
              <label
                className={`form-check-label ${style.formLabel}`}
                htmlFor="currentHostWithDNS"
              >
                I want emails to be sent out showing my brand's domain. But, I
                will make changes to my DNS settings so that Saufter's server
                can send it out.
                <span className={`ps-2 ${style.link} d-none`}>
                  See how it works
                </span>
                {preferred === "currentHostWithDNS" && (
                  <div className={`${style.preferred} ms-2 mt-1`}>
                    Preferred
                  </div>
                )}
              </label>
            </div>
          )}

          {/* SMTP */}
          {!isOutboundEmail && (
            <div className="form-check mt-3">
              <input
                className={`form-check-input ${style.inputRadio}`}
                type="radio"
                name="SMTP"
                id="SMTP"
                // disabled={isDomainVerified}
                checked={selectedType === "SMTP"}
                onChange={() => onSelect("SMTP")}
              />
              <label
                className={`form-check-label ${style.formLabel}`}
                htmlFor="SMTP"
              >
                I would not like to change the DNS settings. However, Saufter
                can send emails using my current email host using SMTP
                authentication. This might require me to make changes in the
                settings of the email host.
                <span className={`ps-2 ${style.link} d-none`}>
                  See how it works
                </span>
                {preferred === "SMTP" && (
                  <div className={`${style.preferred}`}>Preferred</div>
                )}
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OutgoingMailSetting;
