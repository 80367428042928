import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./AddCampaign.module.scss";
import AddCampaignBrandModal from "./Children/AddCampaignBrandModal/AddCampaignBrandModal";
import AddCampaignModal from "./Children/AddCampaignModal/AddCampaignModal";
import { useCreateAddCampaignProvider } from "./Hooks/useAddCampaignContext";

const ProviderComponent = ({
  step,
  onHide,
  onNext,
  openBrand,
}: {
  step: "brand" | "campaignSteps" | null;
  onHide: () => void;
  onNext: () => void;
  openBrand: () => void;
}) => {
  const [state, dispatch, Provider] = useCreateAddCampaignProvider();

  return (
    <Provider value={[state, dispatch]}>
      {step === "brand" ? (
        <AddCampaignBrandModal
          onHide={onHide}
          onNext={onNext}
        />
      ) : step === "campaignSteps" ? (
        <AddCampaignModal
          onHide={onHide}
          onPrev={openBrand}
        />
      ) : null}
    </Provider>
  );
};

const AddCampaign = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) => {
  const [modalStage, setModalStage] = useState<
    "brand" | "campaignSteps" | null
  >(null);

  const onHideCallback = useCallback(() => {
    onHide();
    setModalStage(null);
  }, [onHide]);

  useEffect(() => {
    if (show) {
      setModalStage("brand");
    }
  }, [show]);

  return (
    <Modal
      show={modalStage !== null}
      onHide={onHideCallback}
      dialogClassName={`${
        modalStage === "brand"
          ? styles.brandDialog
          : modalStage === "campaignSteps"
            ? styles.campaignModalDialog
            : ""
      }`}
      contentClassName={`${
        modalStage === "brand"
          ? styles.brandContent
          : modalStage === "campaignSteps"
            ? styles.campaignModalContent
            : ""
      }`}
      centered={true}
    >
      <ProviderComponent
        onHide={onHideCallback}
        onNext={() => setModalStage("campaignSteps")}
        openBrand={() => setModalStage("brand")}
        step={modalStage}
      />
    </Modal>
  );
};

export default AddCampaign;
