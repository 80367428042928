import { useState } from "react";
import UpgradePlan, {
  AllPricingModalTypes,
} from "src/features/SelectPricing/UpgradePlan";
import AllSentMessage from "../AllSentMessage/AllSentMessage";
import styles from "./FollowUpMessage.module.scss";

const ShadowUI = () => {
  const [showPricingModal, setShowPricingModal] = useState(
    null as AllPricingModalTypes,
  );

  return (
    <div className={`${styles.limitWrapper}`}>
      <div className={`${styles.limitBox}`}>
        <UpgradePlan
          icon={null}
          showModal={showPricingModal}
          setShowModal={setShowPricingModal}
          upgradeType="component"
        />
      </div>
    </div>
  );
};

const FollowUpMessage = () => {
  return (
    <div className={`${styles.followUpWrapper}`}>
      {/* Follow Up Table */}
      <AllSentMessage />

      {/* Shadow Overlay UI */}
      <ShadowUI />
    </div>
  );
};

export default FollowUpMessage;
