import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getAllCampaignCategoriesService, {
  GetAllCampaignCategoryResponse,
} from "src/services/Campaign/getAllCampaignCategories.service";

const LIMIT = 10;

type PageData = GetAllCampaignCategoryResponse & {
  page: number;
};

const useFetchCampaignCategories = () => {
  const { data, status, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<PageData>(["getAllCampaignCategoriesService"], {
      queryFn: async ({ pageParam = 1 }) => {
        const res = await getAllCampaignCategoriesService({
          limit: LIMIT,
          start: (pageParam - 1) * LIMIT,
        });

        return { ...res, page: pageParam } as PageData;
      },
      getNextPageParam: (lastPage) => {
        const { page, hasMore } = lastPage;
        return hasMore ? page + 1 : undefined;
      },
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 60000,
    });

  // Memoized computation to merge data across all pages.
  const allCampaignCategories = useMemo(() => {
    if (!data) {
      return [];
    }

    // Reduce the paginated data into a single structure containing all segment ids and data
    return data.pages.flatMap((val) => val.allCategories);
  }, [data]);

  const fetchMore = useCallback(() => {
    if (hasNextPage && status !== "loading" && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, status]);

  return {
    allCampaignCategories,
    fetchMore,
    hasMore: hasNextPage,
    fetchStatus: (isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected") as AJAXSTATUS,
  };
};

export default useFetchCampaignCategories;
