import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getEmailTemplateVariantService, {
  GetEmailTemplateVariantParams,
  IEmailTemplateVariant,
} from "src/services/Campaign/getEmailTemplateVariant.service";

const useFetchTemplateVariant = (
  params?: GetEmailTemplateVariantParams,
  onSuccess?: (data: IEmailTemplateVariant) => void,
) => {
  const enabled = useMemo(() => !!params, [params]);

  const { data, status } = useQuery<IEmailTemplateVariant>(
    [
      "getEmailTemplateVariantService",
      params?.categoryId,
      params?.templateId,
      params?.variantId,
    ],
    
    {
      queryFn: ()  => getEmailTemplateVariantService(params!),
      onSuccess,
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 60000,
      enabled,
    },
  );

  return {
    template: data,
    templateFetchStatus: (!enabled
      ? "idle"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected") as AJAXSTATUS,
  };
};

export default useFetchTemplateVariant;
