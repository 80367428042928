import { Dispatch, SetStateAction } from "react";
import styles from "./DeleteSuccess.module.scss";

const DeleteSuccess = ({
  hideModal,
  setIsDeleted,
  dispatchRuleName,
}: {
  hideModal: () => void;
  setIsDeleted: Dispatch<SetStateAction<boolean>>;
  dispatchRuleName: string;
}) => {
  const closeModal = () => {
    setIsDeleted(false);
    hideModal();
  };
  return (
    <div
      className={`${styles.innerContainer} px-2 py-4 d-flex justify-content-center align-items-center flex-column`}
    >
      <div
        className={`${styles.checkIcon} d-flex justify-content-center align-items-center`}
      >
        <i
          className="fa fa-check"
          aria-hidden="true"
        ></i>
      </div>
      <div className={` px-4 ${styles.title} mb-3 mt-2`}>
        "{dispatchRuleName}" dispatching rules deleted!
      </div>
      <button
        className={`${styles.deleteBtn} px-3`}
        onClick={closeModal}
      >
        Close
      </button>
    </div>
  );
};

export default DeleteSuccess;
