import { axiosJSON } from "src/globals/axiosEndPoints";
import { IEmailTemplateVariant } from "./getEmailTemplateVariant.service";

export interface UpdateEmailTemplateVariantParams
  extends Partial<IEmailTemplateVariant> {
  categoryId: string | number;
  variantId: string | number;
  templateId: string | number;
}

const updateEmailTemplateVariantService = async (
  params: UpdateEmailTemplateVariantParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/templates/updateVarient`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }
};

export default updateEmailTemplateVariantService;
