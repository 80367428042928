import { memo } from "react";

interface ColorScheme {
  bg: string;
  fc: string;
}

type Props = {
  name?: string;
  size: number;
  className?: string;
  removePAndM?: boolean;
  colorScheme?: ColorScheme;
  [key: string]: any;
};

const alphabetColors: Record<string, ColorScheme> = {
  A: { bg: "#d63384", fc: "white" },
  B: { bg: "#198754", fc: "white" },
  C: { bg: "#05a3d5", fc: "white" },
  D: { bg: "#856262", fc: "white" },
  E: { bg: "#6f42c1", fc: "white" },
  F: { bg: "#ffc107", fc: "white" },
  G: { bg: "#0d6efd", fc: "white" },
  H: { bg: "#d63384", fc: "white" },
  I: { bg: "#198754", fc: "white" },
  J: { bg: "#05a3d5", fc: "white" },
  K: { bg: "#856262", fc: "white" },
  L: { bg: "#6f42c1", fc: "white" },
  M: { bg: "#ffc107", fc: "white" },
  N: { bg: "#0d6efd", fc: "white" },
  O: { bg: "#d63384", fc: "white" },
  P: { bg: "#198754", fc: "white" },
  Q: { bg: "#05a3d5", fc: "white" },
  R: { bg: "#856262", fc: "white" },
  S: { bg: "#6f42c1", fc: "white" },
  T: { bg: "#ffc107", fc: "white" },
  U: { bg: "#0d6efd", fc: "white" },
  V: { bg: "#d63384", fc: "white" },
  W: { bg: "#856262", fc: "white" },
  X: { bg: "#05a3d5", fc: "white" },
  Y: { bg: "#198754", fc: "white" },
  Z: { bg: "#6f42c1", fc: "white" },
};

const getNameInitials = (name: string) => {
  let initials = "NA";
  const nameSplit = typeof name === "string" ? name.split(" ") : "";
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  }
  return initials.toUpperCase();
};

const getRandomColor = (letter: string) => {
  return alphabetColors[letter] ? alphabetColors[letter] : alphabetColors["A"];
};

const createImageFromInitials = (
  size: number,
  text: string,
  { fc: foregroundColor, bg: backgroundColor }: ColorScheme,
) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = size;
  canvas.height = size;
  if (context) {
    context.beginPath();
    context.arc(canvas.width / 2, canvas.height / 2, size / 2, 0, 2 * Math.PI);
    context.fillStyle = backgroundColor;
    context.fill();
    context.font = `bold ${size / 2.2}px Poppins, sans-serif`;
    context.fillStyle = foregroundColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 1.9);
  }
  return canvas.toDataURL("image/png");
};

export function AvatarURL({
  name,
  size = 30,
}: {
  name: string;
  size?: number;
}) {
  return createImageFromInitials(
    size,
    getNameInitials(name),
    getRandomColor(name ? name[0].toUpperCase() : "N"),
  );
}

function UserAvatar({
  name = "N A",
  size,
  className = "",
  removePAndM = false,
  src,
  colorScheme,
  ...props
}: Props) {
  return (
    <img
      src={createImageFromInitials(
        size,
        getNameInitials(name),
        colorScheme
          ? colorScheme
          : getRandomColor(name ? name[0].toUpperCase() : "N"),
      )}
      className={`${removePAndM ? "" : "p-0 m-0"} ${className}`}
      alt="profile-pic"
      {...props}
    />
  );
}

export default memo(UserAvatar);
