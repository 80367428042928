import { axiosJSON } from "src/globals/axiosEndPoints";
import { ICustomerNote } from "./getAllCustomerNote.service";

export interface AddCustomerNoteParams {
  customerId: string;
  noteText: string;
}

async function addCustomerNoteService(params: AddCustomerNoteParams) {
  const { data: res } = await axiosJSON.post(`/api/customer/note/add`, params);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as ICustomerNote;
}

export default addCustomerNoteService;
