import { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import {
  useFetchReturnOrderTags,
  useFetchReturnProductTags,
} from "src/features/ReturnAutoWorkFlow/hooks/useFetchReturnConditions";
import styles from "./ComponentStyles.module.scss";

interface Option {
  label: string;
  value: string;
}

const LIMIT = 25;

const ItemTags = ({
  props,
  setMenuOpen,
}: {
  props: any;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { fetchProductTags, loadingStatus, productTags } =
    useFetchReturnProductTags();
  const allOptions = useMemo(() => {
    return productTags.map((tag) => {
      return {
        label: tag,
        value: tag,
      } as Option;
    });
  }, [productTags]);

  const selectedValue = useMemo(() => {
    const arr: Option[] = [];

    props.tags.forEach((val: string) => {
      arr.push({
        value: val,
        label: val,
      });
    });

    return arr;
  }, [props.tags]);

  return (
    <Select
      defaultValue={selectedValue.map((tag) => tag)}
      value={selectedValue.map((tag) => tag)}
      isMulti
      name="tags"
      options={allOptions}
      className={`basic-multi-select ${
        props.error ? styles.borderDanger : ""
      } ${props.showValidation && "border border-danger rounded"}`}
      classNamePrefix="select"
      isClearable={false}
      placeholder="search tag"
      isLoading={loadingStatus === "pending"}
      onChange={(e) => props.onChange(e.map((s) => s.value))}
      onMenuClose={() => {
        setMenuOpen(false);
      }}
      onMenuOpen={() => {
        setMenuOpen(true);

        if (loadingStatus !== "pending" && allOptions.length === 0) {
          fetchProductTags(LIMIT);
        }
      }}
      onMenuScrollToBottom={() => fetchProductTags(LIMIT)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      }}
    />
  );
};

const OrderTags = ({
  props,
  setMenuOpen,
}: {
  props: any;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { fetchOrderTags, orderTags, isLoading } = useFetchReturnOrderTags();

  const allOptions = useMemo(() => {
    return orderTags.map((tag) => {
      return {
        label: tag,
        value: tag,
      } as Option;
    });
  }, [orderTags]);

  const selectedValue = useMemo(() => {
    const arr: Option[] = [];

    props.tags.forEach((val: string) => {
      arr.push({
        value: val,
        label: val,
      });
    });

    return arr;
  }, [props.tags]);

  return (
    <Select
      defaultValue={selectedValue.map((tag) => tag)}
      value={selectedValue.map((tag) => tag)}
      isMulti
      name="tags"
      options={allOptions}
      className={`basic-multi-select ${
        props.error ? styles.borderDanger : ""
      } ${props.showValidation && "border border-danger rounded"}`}
      classNamePrefix="select"
      isClearable={false}
      placeholder="search tag"
      isLoading={isLoading === "pending"}
      onChange={(e) => props.onChange(e.map((s) => s.value))}
      onMenuClose={() => {
        setMenuOpen(false);
      }}
      onMenuOpen={() => {
        setMenuOpen(true);

        if (isLoading !== "pending" && allOptions.length === 0) {
          fetchOrderTags(LIMIT);
        }
      }}
      onMenuScrollToBottom={() => fetchOrderTags(LIMIT)}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      }}
    />
  );
};

function TagsNew(props: any) {
  const [showPopover, setShowPopover] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (props.error) {
      setShowPopover(true);
    } else {
      setShowPopover(false);
    }
  }, [props.error]);

  const popover =
    props.error && props.error.length > 0 ? (
      <Popover
        id="popover-basic"
        className="rounded-3"
      >
        <Popover.Body className="d-flex flex-row">
          <span className="border px-2 bg-warning text-white me-2 rounded-3 m-auto mt-1">
            !
          </span>
          <div className="mt-1">{props.error}</div>
        </Popover.Body>
      </Popover>
    ) : (
      <></>
    );

  return (
    <OverlayTrigger
      show={showPopover}
      placement="bottom"
      overlay={popover}
      rootClose={true}
      rootCloseEvent="mousedown"
      onToggle={(isShown) => {
        // Set the state variable based on the popover visibility
        if (!menuOpen) {
          setShowPopover(isShown);
        }
      }}
    >
      <div
        className={`ms-lg-2 ${styles.thirdSelect}`}
        onClick={() => {
          setShowPopover(false);
        }}
        style={{
          maxWidth: "20rem",
          minWidth: "16rem",
        }}
      >
        {props?.isItemTag ? (
          <ItemTags
            props={props}
            setMenuOpen={setMenuOpen}
          />
        ) : (
          <OrderTags
            props={props}
            setMenuOpen={setMenuOpen}
          />
        )}
        <span className={styles.errorText}>
          {props.error && props.error.length > 0 && props.error}
        </span>
      </div>
    </OverlayTrigger>
  );
}

export default TagsNew;
