import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IOneTimeCampaign } from "src/services/Campaign/getAllOneTimeCampaigns.service";
import styles from "./OneTimeCampaignTable.module.scss";
import OneTimeCampaignTableRow from "./OneTimeCampaignTableRow/OneTimeCampaignTableRow";

const OneTimeCampaignTable = ({
  allCampaigns,
}: {
  allCampaigns: IOneTimeCampaign[];
}) => {
  return (
    <div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableHeader}`}>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Title</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Status</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}> Sender</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement}`}>Content type</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement}`}>Clicked</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement}`}> Viewed</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement} pe-1`}> Unsubscribed</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className={` ${styles.toolTipCustom}`}>
                <span className={`${styles.tooltipCss}`}>
                  The number of users who have unsubscribed from the email
                  campaign.
                </span>
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <span
                {...triggerHandler}
                ref={ref}
              >
                <span className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="ios-information-circle-outline"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <path
                      id="Path_6838"
                      data-name="Path 6838"
                      d="M5.518,4.036A.483.483,0,1,1,6,4.505.469.469,0,0,1,5.518,4.036Zm.033.837h.9V8.251h-.9V4.873Z"
                      transform="translate(-1.001 -1.005)"
                      fill="#0b68bb"
                    />
                    <path
                      id="Path_6839"
                      data-name="Path 6839"
                      d="M6.125,1.8a4.325,4.325,0,1,1-3.06,1.267A4.3,4.3,0,0,1,6.125,1.8m0-.673a5,5,0,1,0,5,5A5,5,0,0,0,6.125,1.125Z"
                      transform="translate(-1.125 -1.125)"
                      fill="#0b68bb"
                    />
                  </svg>
                </span>
              </span>
            )}
          </OverlayTrigger>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement}`}> People type</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.rowElement}`}> Created at</span>
        </div>
      </div>
      <div>
        {allCampaigns.map((data, index) => (
          <OneTimeCampaignTableRow
            key={index}
            {...data}
          />
        ))}
      </div>
    </div>
  );
};

export default OneTimeCampaignTable;
