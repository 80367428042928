import { useEffect, useMemo, useRef, useState } from "react";
import Loader from "src/components/Loader";
import {
  fetchCustomerData,
  resetShopifySidebar,
  setCustomerId,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import ProfileSegmentData from "./ProfileSegmentData/ProfileSegmentData";
import UserHistoryData from "./UserHistoryData/UserHistoryData";
import UserMetrics from "./UserMetrics/UserMetrics";
import styles from "./UserProfile.module.scss";
import useSegmentUserProfile from "./useSegmentUserProfile";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "src/containers/SideBar/SideBar";
import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import CustomerTimeline from "src/routes/Ticket/children/TicketHeader/Children/CustomerTimeline/CustomerTimeline";
import { LastUpdated } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/Children/LastUpdated/LastUpdated";

const UserProfile = ({ segmentUserId }: { segmentUserId: string }) => {
  const { data, isError, isLoading } = useSegmentUserProfile(segmentUserId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  const SHOW_TIMELINE = useMemo(() => {
    return (
      currentUserData?.ui_visibility?.includes("customer_timeline") ?? false
    );
  }, [currentUserData?.ui_visibility]);

  const [showTimeline, setShowTimeline] = useState(true);
  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && SHOW_TIMELINE) {
      dispatch(resetShopifySidebar());
      dispatch(setCustomerId({ id: parseInt(data.customerId) }));
      dispatch(
        fetchCustomerData({
          includeTimeline: true,
        }),
      );
    }
  }, [SHOW_TIMELINE, data, dispatch]);

  return (
    <TimelineProvider
      referenceId={data?.customerId ? data.customerId : ""}
      referenceType="customer"
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-content-center align-items-center">
          <Loader />
        </div>
      ) : isError || !data ? (
        <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
          Error Loading Content...
        </div>
      ) : (
        <div>
          {SHOW_TIMELINE ? (
            <>
              {showTimeline && currentUserData?.userRole === "31" && (
                <div className={`position-relative`}>
                  <span
                    className={`${styles.gearIcon} cursor-pointer`}
                    onClick={() => {
                      navigate(`${RouteNames.customerSegments}/settings`);
                    }}
                  >
                    <i className="fa-solid fa-gear"></i>
                  </span>
                </div>
              )}

              <div className={`${styles.timelineSection}`}>
                <div className="d-flex flex-column mx-5">
                  <div className="d-flex">
                    <div className={`${styles.headerContent} w-100`}>
                      <CustomerTimeline
                        showTimeline={showTimeline}
                        timelineRef={timelineRef}
                      />
                    </div>
                    <div className="ms-3 d-flex align-items-center justify-content-center">
                      <span
                        className={`cursor-pointer ${styles.toggleBtn}`}
                        onClick={() => setShowTimeline(!showTimeline)}
                      >
                        <i
                          className={`fa-solid fa-chevron-${
                            showTimeline ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <LastUpdated />
            </>
          ) : null}

          <div className={`d-flex ${styles.mainSection}`}>
            <div
              className={`${styles.leftSection} ${
                SHOW_TIMELINE
                  ? showTimeline
                    ? styles.timelineHeightOpen
                    : styles.timelineHeight
                  : styles.timelineNotVisible
              } p-3`}
            >
              <UserMetrics metricsData={data.metrics} />
            </div>
            <div
              className={`${styles.middleSection} ${
                SHOW_TIMELINE
                  ? showTimeline
                    ? styles.timelineHeightOpen
                    : styles.timelineHeight
                  : styles.timelineNotVisible
              }`}
            >
              <UserHistoryData
                customerId={data.customerId}
                segmentListClass={`${
                  SHOW_TIMELINE
                    ? showTimeline
                      ? styles.historyHeightOpen
                      : styles.historyHeight
                    : styles.historyNotVisibleHeight
                }`}
              />
            </div>
            <div
              className={`${styles.rightSection} ${
                SHOW_TIMELINE
                  ? showTimeline
                    ? styles.timelineHeightOpen
                    : styles.timelineHeight
                  : styles.timelineNotVisible
              } p-3`}
            >
              <ProfileSegmentData
                data={data}
                segmentUserId={segmentUserId}
              />
            </div>
          </div>
        </div>
      )}
    </TimelineProvider>
  );
};

export default UserProfile;
