import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllCampaignCategoryParams {
  start: number;
  limit: number;
}

export interface CampaignSubCategory {
  id: string | number;
  name: string;
  isDescriptionRequired?: boolean;
}

export interface CampaignCategory {
  id: string | number;
  name: string;
  isDescriptionRequired?: boolean;
  subCategories?: CampaignSubCategory[];
  subCategoryTotalCount?: number;
}

export interface GetAllCampaignCategoryResponse {
  allCategories: Array<CampaignCategory>;
  hasMore: boolean;
}

const getAllCampaignCategoriesService = async (
  params: GetAllCampaignCategoryParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/getAllCategories`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllCampaignCategoryResponse = {
    allCategories: res.data,
    hasMore: true,
  };

  if (ret.allCategories.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
};

export default getAllCampaignCategoriesService;
