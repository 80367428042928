import { axiosJSON } from "src/globals/axiosEndPoints";

export interface DeleteCampaignParams {
  campaignId: number;
}

export async function deleteCampaignService(params: DeleteCampaignParams) {
  const { data: res } = await axiosJSON.delete("/api/campaign/delete", {
    data: params,
  });

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something Unexpected Occured!";
  }
}
