import { axiosJSON } from "src/globals/axiosEndPoints";

export interface AddNewAttributeParams {
  name: string;
  description?: string;
  timelines?: Array<{
    name: string;
    value: number;
    id: string;
    notSureOfDuration: boolean;
  }>;
  type:
    | "number"
    | "decimal"
    | "date"
    | "list"
    | "boolean"
    | "text"
    | "timeline";
}

async function addNewAttributeService({
  params,
  segmentType,
}: {
  segmentType: string;
  params: AddNewAttributeParams;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/addAttribute`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }
}

export default addNewAttributeService;
