import styles from "./ReviewRestockingFee.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../Timeline/Timeline";
import { useReturnPreviewState } from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import ParseVariablesWithValue from "../ParseVariablesWithValue/ParseVariablesWithValue";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { getCurrencySymbol } from "src/utils/utils";

const ReviewRestockingFee = () => {
  const { selectedOrder, orders, updateState, ...contextValues } =
    useReturnPreviewState();
  const { getAllReturnTypes, getRestockingFeeData } = useGetAutomationData();
  const { restockMessage, restockingFee, currency } = getRestockingFeeData();
  const { allReturnTypes } = getAllReturnTypes();
  // Get all item IDs from addedItems
  const items = Object.values(selectedOrder?.addedItems ?? {});

  const handleNext = () => {
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "orderSummary",
        },
      });
  };

  const handleBack = () => {
    if (selectedOrder)
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "returnMethod",
        },
      });
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <div>
          <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
          <div className="mt-3">
            <div className="d-flex align-items-center mb-3 pb-1">
              <img
                src={returnImg}
                alt=""
              />
              <span className={`ps-1 ${styles.heading}`}>
                Review Re-Stocking Fee
              </span>
            </div>
            {items?.map((item) => {
              const itemData = selectedOrder?.itemsInOrder?.find(
                (value) => value.id + "" === item.itemId + "",
              );
              const returnType = allReturnTypes?.find(
                (returnType) => returnType.type === item?.selectedReturnType,
              );

              return (
                <div
                  key={item.itemId}
                  className={`mb-2 ${styles.orderBox}`}
                >
                  <p className={`${styles.orderType}`}>
                    {itemData?.name} <span>*{returnType?.name}</span>
                  </p>
                  <span className={`${styles.radioLabel}`}></span>
                  <div className={`d-flex mb-3`}>
                    <div className={`${styles.cart}`}>
                      <img
                        src={
                          itemData?.name === "Acme T-Shirt"
                            ? orderImg
                            : orderImg2
                        }
                        alt="order"
                        className={`${styles.orderImg}`}
                      />
                    </div>
                    <div className={`ms-2`}>
                      <span className={`${styles.orderName}`}>
                        {itemData?.unitPrice?.currencyCode}
                        {itemData?.unitPrice?.amount} x {itemData?.quantity}
                      </span>
                      <span className={`${styles.orderDesc}`}>
                        SKU: {itemData?.sku}
                      </span>
                    </div>
                  </div>
                  <div className={`d-flex justify-content-between my-2`}>
                    <span className={styles.selectText}>Restocking Fee</span>
                    <span className={styles.selectText}>
                      {restockingFee?.priceType === "percentageOfOrderValue" ? (
                        <span>{restockingFee?.price ?? 0} %</span>
                      ) : restockingFee?.priceType === "fixedAmount" ? (
                        <div className="d-flex">
                          <div>
                            {restockingFee?.priceType === "fixedAmount" &&
                              getCurrencySymbol(currency)}
                          </div>
                          <div>{restockingFee?.price ?? 0}</div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                  {restockMessage && restockMessage?.length > 0 ? (
                    <div
                      className={`d-flex justify-content-between ${styles.selectBox}`}
                    >
                      <span className={styles.selectText}>
                        <ParseVariablesWithValue
                          message={restockMessage ?? ""}
                        />
                      </span>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default ReviewRestockingFee;
