import { useRef, useState } from "react";
import styles from "./CampaignData.module.scss";
import { TimelineProvider } from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/TimelineProvider";
import { ICampaignReportUser } from "src/services/report/getCampaignReports.service";
import ReportTimeline from "../../ReportTimeline/ReportTimeline";
import useTimelineScroll from "src/routes/Ticket/children/TicketHeader/Children/TimelineInfo/TimelineProvider/useTimelineScroll";
import { useNavigate } from "react-router-dom";

const CampaignData = ({ userData }: { userData: ICampaignReportUser }) => {
  const [showTimeline, setShowTimeline] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const {
    handleScrollLeft,
    handleScrollRight,
    disableLeft,
    disableRight,
    scrollDivRef,
    checkScroll,
  } = useTimelineScroll();

  return (
    <div
      className={`${styles.campaignDataWrapper} ${styles.blurWrapper} position-relative mb-2`}
    >
      <TimelineProvider
        referenceId={userData?.customerId}
        referenceType="customer"
      >
        <div className="d-flex align-items-center px-3">
          <div
            className={`mt-3 cursor-pointer ${styles.userNameContainer} d-flex align-items-center justify-content-start`}
            onClick={() =>
              navigate(
                `/customer-segments/userSegment/allUsers/userProfile/${userData?.id}`,
              )
            }
          >
            <span className={`${styles.userName}`}>{userData?.name}</span>
          </div>
          <div
            className={`d-flex align-items-center`}
            style={{
              width: "80%",
              marginTop: "13px",
            }}
          >
            <div>
              <div
                className={`mx-2 ${styles.iconBox} ${
                  !disableLeft ? "cursor-pointer" : styles.btnDisabled
                }`}
                onClick={() => {
                  if (!disableLeft) {
                    handleScrollLeft();
                  }
                }}
              >
                <i className={`fa-solid fa-chevron-left`}></i>
              </div>
            </div>

            <div
              ref={scrollDivRef}
              className={`mt-2 ${styles.headerContent}`}
            >
              {userData?.timelineData ? (
                <ReportTimeline
                  showTimeline={showTimeline}
                  timelineRef={timelineRef}
                  showLoader={false}
                  timelineInfo={userData?.timelineData}
                  onLoad={() => {
                    checkScroll();
                  }}
                />
              ) : null}
            </div>
            <div className="mx-2 mt-2">
              <span
                className={`cursor-pointer ms-2 ${styles.toggleBtn}`}
                onClick={() => setShowTimeline(!showTimeline)}
              >
                <i
                  className={`fa-solid fa-chevron-${
                    !showTimeline ? "up" : "down"
                  }`}
                ></i>
              </span>
            </div>
            <div>
              <div
                className={`mx-2 ${styles.iconBox} ${
                  disableRight ? styles.btnDisabled : "cursor-pointer"
                }`}
                onClick={handleScrollRight}
              >
                <i className={`fa-solid fa-chevron-right`}></i>
              </div>
            </div>
          </div>
        </div>
      </TimelineProvider>
    </div>
  );
};

export default CampaignData;
