import styles from "./PricingBox.module.scss";
import unCheck from "src/assets/images/unCheck.png";
import check from "src/assets/images/checkViolet.svg";
import rocketIcon from "src/assets/images/rocket.svg";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";

export interface IPaymentPlan {
  planId: string;
  shortName: string; //FREE | PRO | SUPER
  price: string;
}

const PricingBox = ({
  showModal,
  upgrade,
  onHide,
}: {
  showModal: boolean;
  upgrade: () => void;
  onHide: () => void;
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState(null as null | string);

  const firstPlan: IPaymentPlan = useMemo(
    () => ({ planId: "1", shortName: "Byte Bot", price: "$8" }),
    [],
  );

  const secondPlan: IPaymentPlan = useMemo(
    () => ({ planId: "2", shortName: "Kilo Bot", price: "$98" }),
    [],
  );

  const thirdPlan: IPaymentPlan = useMemo(
    () => ({ planId: "3", shortName: "Mega Bot", price: "$398" }),
    [],
  );

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      id="MainSBPricingErrorModal"
      centered
    >
      <div>
        <div style={{ float: "right" }}>
          <i
            className="fa-solid fa-xmark cursor-pointer"
            onClick={onHide}
          ></i>
        </div>
        <h2 className={`${styles.signupHeading} mb-3`}>Upgrade Plan</h2>
        <div className="d-flex justify-content-center align-items-center my-4">
          <div
            className={`d-flex justify-content-center align-items-center ${styles.planningType} px-2`}
          >
            <div
              className={`d-flex justify-content-center align-items-center ${styles.type} ${styles.active}`}
            >
              <span>Billed Monthly</span>
            </div>
            {/* <div
              className={`d-flex justify-content-center align-items-center ${styles.type} p-2`}
            >
              <span>Billed Anually</span>
            </div> */}
          </div>
        </div>
        <div
          className={`${styles.pricingWrapper} d-flex justify-content-between flex-column d-md-flex flex-md-row`}
        >
          <div className={`${styles.labelBox}`}>
            {/* header section - Hidden */}
            <div className={`mb-2 ${styles.headerSection}`}>
              <div>
                <input
                  className={`form-check-input mb-2 ${styles.checkBox}`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>

              <div className={`px-1 mb-2 ${styles.planByteName}`}>
                <span>Byte Bot</span>
              </div>
              <p className={`${styles.priceValue}`}>$8/month</p>
              <div className={`${styles.separator}`}></div>
            </div>
            {/* seat included section  */}
            <div>
              <p className={`mb-4 ${styles.element}`}>Seats included</p>
            </div>
            {/* FOR THE PRE-SALE JOURNEY */}
            <div>
              <p className={`mb-3 ${styles.journeyHead}`}>
                FOR THE PRE-SALE JOURNEY
              </p>
            </div>
            {/* FOR THE Behavior Tracking */}
            <div className="mb-1">
              <p className={`${styles.element}`}>Behavior Tracking</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* Campaign suggestions*/}
            <div className="mb-1">
              <p className={`${styles.element}`}>Campaign suggestions</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* FOR Words */}
            <div className="mb-1">
              <p className={`${styles.element}`}>Words</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* In-app Messages */}
            <div className="mb-1">
              <p className={`${styles.element}`}>In-app Messages</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* social media */}
            <div className={`mb-1 ${styles.socialMedia}`}>
              <p className={`${styles.element}`}>Social Media</p>
              <span className={`px-1 ${styles.comingSoon}`}>Coming soon</span>
              <div className="ms-4 mt-3">
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>Facebook AI bot</p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>
                    Instagram AI bot
                  </p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>WhatsApp AI bot</p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
              </div>
            </div>
            {/* Automated Acquisition Cohort Creation */}
            <div className="mb-1">
              <p className={`${styles.twoLineElement}`}>
                Automated Acquisition Cohort Creation
              </p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* Find 'Stuck' Users */}
            <div className="mb-1">
              <p className={`${styles.element}`}>Find 'Stuck' Users</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/*Automate Blog Publishing/SEO */}
            <div className="mb-1">
              <p className={`${styles.element}`}>
                Automate Blog Publishing/SEO
              </p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Image Generation</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Bot Product Training</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div>
              <p className={`mb-3 mt-3 ${styles.journeyHead}`}>
                FOR YOUR POST-SALE JOURNEY
              </p>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Journey Tracker</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Slack Integration</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Live Chat</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Email Helpdesk</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            {/* live chat bot  */}
            <div className={`mb-1 ${styles.liveChatBot}`}>
              <p className={`${styles.element}`}>Live Chat Bot</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* social media */}
            <div className={`mb-1 ${styles.socialMedia}`}>
              <p className={`${styles.element}`}>Social Media</p>
              <span className={`px-1 ${styles.comingSoon}`}>Coming soon</span>
              <div className="ms-4 mt-3">
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>Facebook AI bot</p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>
                    Instagram AI bot
                  </p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
                <div>
                  <p className={`mb-1 ${styles.subElement}`}>WhatsApp AI bot</p>
                  <div className={`${styles.separator} invisible`}></div>
                </div>
              </div>
            </div>
            {/* live chat bot  */}
            <div className={`mb-1 ${styles.liveChatBot}`}>
              <p className={`${styles.element}`}>Email Bot</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            {/* AI Training knowledge base  */}
            <div className={`mb-1 ${styles.liveChatBot}`}>
              <p className={`${styles.element}`}>AI Training knowledge base</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Product based bot</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>Auto Escalations</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>AI Escalations to Slack</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>Journey Intelligence</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>Bug Tracker</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>Churn Prediction</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>
                Automated Retention Cohort Creation
              </p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div className="mb-1">
              <p className={`${styles.element}`}>
                Automated Upselling Campaigns
              </p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            <div>
              <p className={`mb-3 mt-3 ${styles.journeyHead}`}>THE AI APPS</p>
            </div>
            <div className="mb-1">
              <p className={`${styles.element}`}>Industry Specific AI Apps</p>
              <div className={`${styles.separator} invisible`}></div>
            </div>{" "}
            {/* live chat bot  */}
            <div className={`mb-1 ${styles.liveChatBot}`}>
              <p className={`${styles.element}`}>
                Journey Tracking Integration
              </p>
              <div className={`${styles.separator} invisible`}></div>
            </div>
          </div>
          {firstPlan && (
            <div className={`${styles.pricingBox} max-content-height`}>
              {/* header section  */}
              <div className={`mb-2`}>
                <div className="d-flex align-items-center mb-2 mb-lg-1">
                  <input
                    className={`form-check-input mb-2 ${styles.checkBox} ${styles.byteCheckBox}`}
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={
                      selectedPlanId &&
                      firstPlan.planId + "" === selectedPlanId + ""
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setSelectedPlanId(null);
                      } else {
                        setSelectedPlanId(firstPlan.planId + "");
                      }
                    }}
                  />
                  <div
                    className={`${styles.trial} d-flex align-items-center justify-content-center ms-2`}
                  >
                    In your trial
                  </div>
                </div>

                <div className={`px-1 mb-2 ${styles.planByteName}`}>
                  <span>{firstPlan.shortName}</span>
                </div>
                <p className={`${styles.priceValue}`}>
                  {firstPlan.price}/month
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* seat included section  */}
              <div>
                <p className={`text-center mb-4 ${styles.element}`}>
                  Unlimited
                </p>
              </div>
              {/* FOR THE PRE-SALE JOURNEY */}
              <div>
                <p className={`mb-3 ${styles.journeyHead} invisible`}>
                  FOR THE PRE-SALE JOURNEY
                </p>
              </div>
              {/* FOR THE Behavior Tracking */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  Yes
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Campaign suggestions */}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* FOR Words */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  25000
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* IN APP MESSAGES */}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1 ${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Find 'Stuck' Users */}
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Automate Blog Publishing/SEO */}
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div>
                <p className={`mb-4 mt-3 ${styles.journeyHead} invisible`}>
                  FOR YOUR POST-SALE JOURNEY
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* live chat bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText}`}>
                  (Upto 10 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1 ${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              {/* email bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} invisible`}>
                  (Upto 10 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* AI Training knowledge base  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} `}>
                  (Upto 50 pages)
                </span>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1  ${styles.upsellingCheck}`}>
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator} invisible`}></div>
              </div>
              <div>
                <p
                  className={`mt-3 ${styles.journeyHead} ${styles.aICustomHeight} invisible`}
                >
                  THE AI APPS
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Journey Tracking Integration */}
              <div className={`mb-1 pt-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={unCheck}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText}`}>
                  Check available apps!!
                </span>
              </div>
            </div>
          )}
          {secondPlan && (
            <div className={`${styles.pricingBox} max-content-height`}>
              {/* header section  */}
              <div className="mb-2">
                <div className="d-flex align-items-center mb-2 mb-lg-1">
                  <input
                    className={`form-check-input mb-2 ${styles.checkBox} ${styles.kbCheckBox}`}
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={
                      selectedPlanId &&
                      secondPlan.planId + "" === selectedPlanId + ""
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setSelectedPlanId(null);
                      } else {
                        setSelectedPlanId(secondPlan.planId + "");
                      }
                    }}
                  />
                  <div
                    className={`${styles.trial} d-flex align-items-center justify-content-center ms-2 invisible`}
                  >
                    In your trial
                  </div>
                </div>

                <div className={`px-1 mb-2 ${styles.planKiloName}`}>
                  <span>{secondPlan.shortName}</span>
                </div>
                <p className={`${styles.priceValue}`}>
                  {secondPlan.price}/month
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* seat included section  */}
              <div>
                <p className={`text-center mb-4 ${styles.element}`}>
                  Unlimited
                </p>
              </div>
              {/* FOR THE PRE-SALE JOURNEY */}
              <div>
                <p className={`mb-3 ${styles.journeyHead} invisible`}>
                  FOR THE PRE-SALE JOURNEY
                </p>
              </div>
              {/* FOR THE Behavior Tracking */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  Yes
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Campaign suggestions*/}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* FOR Words */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  300000
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* IN APP MESSAGES */}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div>
                <p className={`mb-4 mt-3 ${styles.journeyHead} invisible`}>
                  FOR YOUR POST-SALE JOURNEY
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* live chat bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText}`}>
                  (Upto 100 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1 ${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              {/* email bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText}`}>
                  (Upto 10 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* AI Training knowledge base  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} `}>
                  (Upto 200 pages)
                </span>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1 ${styles.upsellingCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator} invisible`}></div>
              </div>
              <div>
                <p
                  className={`mt-3 ${styles.journeyHead}  ${styles.aICustomHeight} invisible`}
                >
                  THE AI APPS
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={unCheck}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Journey Tracking Integration */}
              <div className={`mb-1  pt-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={unCheck}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText}`}>
                  Check available apps!!
                </span>
              </div>
            </div>
          )}
          {thirdPlan && (
            <div className={`${styles.pricingBox} max-content-height`}>
              {/* header section  */}
              <div className="mb-2">
                <div className="d-flex align-items-center mb-2 mb-lg-1">
                  <input
                    className={`form-check-input mb-2 ${styles.checkBox} ${styles.megaCheckBox}`}
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={
                      selectedPlanId &&
                      thirdPlan.planId + "" === selectedPlanId + ""
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setSelectedPlanId(null);
                      } else {
                        setSelectedPlanId(thirdPlan.planId + "");
                      }
                    }}
                  />
                  <div
                    className={`${styles.trial} d-flex align-items-center justify-content-center ms-2 invisible`}
                  >
                    In your trial
                  </div>
                </div>

                <div className={`px-1 mb-2 ${styles.planMegaName}`}>
                  <span>{thirdPlan.shortName}</span>
                </div>
                <p className={`${styles.priceValue}`}>
                  {thirdPlan.price}/month
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* seat included section  */}
              <div>
                <p className={`text-center mb-4 ${styles.element}`}>
                  Unlimited
                </p>
              </div>
              {/* FOR THE PRE-SALE JOURNEY */}
              <div>
                <p className={`mb-3 ${styles.journeyHead} invisible`}>
                  FOR THE PRE-SALE JOURNEY
                </p>
              </div>
              {/* FOR THE Behavior Tracking */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  Yes
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Campaign suggestions */}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* FOR Words */}
              <div className="mb-1">
                <p className={`text-center ${styles.element} ${styles.bold}`}>
                  1.5 million
                </p>
                <div className={`${styles.separator}`}></div>
              </div>
              {/* IN APP MESSAGES */}
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1 ${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div>
                <p className={`mb-4 mt-3 ${styles.journeyHead} invisible`}>
                  FOR YOUR POST-SALE JOURNEY
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* live chat bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} invisible`}>
                  (Upto 100 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* social media */}
              <div className={`${styles.socialMedia}`}>
                <p className={`${styles.element} invisible`}>Social Media</p>
                <span className={`px-1 ${styles.comingSoon} invisible`}>
                  Coming soon
                </span>
                <div className="mt-2">
                  <div>
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator}`}></div>
                  </div>
                  <div className="mt-1">
                    <div className="text-center">
                      <img
                        src={check}
                        alt="check"
                        width={24}
                        className="mb-1"
                      />
                    </div>
                    <div className={`${styles.separator} invisible`}></div>
                  </div>
                </div>
              </div>
              {/* email bot  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} invisible`}>
                  (Upto 10 conversations $0.30 per additional useful
                  conversation)
                </span>
              </div>
              {/* AI Training knowledge base  */}
              <div className={`mb-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} invisible`}>
                  (Upto 50 pages)
                </span>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1 ${styles.twoLineCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              <div className={`text-center mb-1 ${styles.upsellingCheck}`}>
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator} invisible`}></div>
              </div>
              <div>
                <p
                  className={`mt-3 ${styles.journeyHead}  ${styles.aICustomHeight} invisible`}
                >
                  THE AI APPS
                </p>
              </div>
              <div className="text-center mb-1">
                <img
                  src={check}
                  alt="check"
                  width={24}
                  className="mb-1"
                />
                <div className={`${styles.separator}`}></div>
              </div>
              {/* Journey Tracking Integration */}
              <div className={`mb-1  pt-1 ${styles.liveChatBot}`}>
                <div className="text-center mb-1">
                  <img
                    src={check}
                    alt="check"
                    width={24}
                    className="mb-1"
                  />
                </div>
                <span className={`${styles.conversationText} invisible`}>
                  Check available apps!!
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mb-2 px-2 pt-1 mt-3">
          <button
            className={`px-2 ${styles.upgradeBtn} justify-content-center align-items-center`}
            onClick={upgrade}
          >
            <img
              src={rocketIcon}
              className="me-1 my-auto"
            />
            <span className="my-auto">Upgrade Plan</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PricingBox;
