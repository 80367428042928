import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PlanModal from "src/components/PlanUpgrade/Children/PlanModal/PlanModal";
import styles from "./BrowseCard.module.scss";

type Props = {
  cardName: string;
  cardSubHead: string;
  integrationImage: Array<string>;
  integrationTo: string;
  connectionCount: number;
  disabled?: boolean;
};

const BrowseCard = ({
  cardName,
  cardSubHead,
  integrationImage,
  integrationTo,
  connectionCount,
  disabled,
}: Props) => {
  const navigate = useNavigate();
  // To show plan modal
  const [showPlanModal, setShowPlanModal] = useState(false);

  // when the card clicked
  const handleOnClickCard = () => {
    if (disabled) {
      setShowPlanModal(true);
    } else {
      navigate(integrationTo);
    }
  };

  return (
    <>
      <div
        className={`col-12 col-md-4 mb-3 mb-md-4 ${styles.browseCard}`}
        onClick={(e) => handleOnClickCard()}
      >
        <div
          className={`d-flex flex-column justify-content-between ${styles.card}`}
        >
          <div className={`${styles.cardHeader}`}>
            {integrationImage.map((value, key) => {
              return (
                <img
                  key={key}
                  className={`mt-1 me-1 ${styles.logoImg}`}
                  src={value}
                  alt=""
                />
              );
            })}

            <h4 className={`${styles.cardHead}`}>{cardName}</h4>
            <p className={`${styles.cardSpan}`}>{cardSubHead}</p>
          </div>
          <div className={`${styles.connection}`}>
            {disabled ? (
              <div>
                <span className={`${styles.upgradeIcon} pe-1`}>
                  <i className="fa-solid fa-rocket"></i>
                </span>
                <span className={`${styles.upgradeText} pe-1`}>
                  Upgrade to integrate
                </span>
                <span className={`${styles.upgradeIcon}`}>
                  <i className="fa-solid fa-angle-right ms-2"></i>
                </span>
              </div>
            ) : (
              <span className={`${styles.connectionHead}`}>
                {connectionCount === 0
                  ? "No connections"
                  : `${connectionCount} connections`}
                <span>
                  <i className="fa-solid fa-angle-right ms-2"></i>
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      {disabled && (
        <PlanModal
          showModal={showPlanModal}
          hideModal={() => setShowPlanModal(false)}
        />
      )}
    </>
  );
};
export default BrowseCard;
