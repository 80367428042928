import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { DripCampaignData } from "src/services/Campaign/getAllDripCampaigns.service";
import getCampaignsBySegmentId from "src/services/Campaign/getCampaignsBySegmentId.service";

export type GetDripCampaignsBySegmentId = InfiniteData<SegmentPageData>;

type SegmentPageData = {
  data: DripCampaignData[];
  metaData: { totalCount: number };
  page: number;
};

const useCampaignsBySegment = (
  segmentId: string | number,
  startLength = 0,
  isDisabled = false,
  limit = 10,
) => {
  const { data, status, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<SegmentPageData>(["getCampaignsBySegmentId", segmentId], {
      queryFn: async ({ pageParam = startLength }) => {
        const res = await getCampaignsBySegmentId({
          segmentId,
          limit: limit,
          start: pageParam * limit,
        });

        return { ...res, page: pageParam } as SegmentPageData;
      },
      getNextPageParam: (lastPage) => {
        const totalFetched = (lastPage.page + 1) * limit;
        return totalFetched < lastPage.metaData.totalCount
          ? lastPage.page + 1
          : undefined;
      },
      enabled: !isDisabled,
      keepPreviousData: true,
      cacheTime: 60000,
      staleTime: 60000,
    });

  const campaigns = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pages.flatMap((page) => page.data);
  }, [data]);

  const fetchStatus: AJAXSTATUS = useMemo(() => {
    return isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected";
  }, [isFetching, status]);

  const fetchMore = useCallback(() => {
    if (hasNextPage && fetchStatus !== "pending") {
      fetchNextPage();
    }
  }, [fetchNextPage, fetchStatus, hasNextPage]);

  return {
    campaigns,
    fetchMore,
    hasMore: hasNextPage,
    fetchStatus,
  };
};

export default useCampaignsBySegment;
