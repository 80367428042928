import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  generateDateOnly,
  isMySQLDateStringValid,
} from "src/utils/dateLibrary";
import { FieldDataDateTypes } from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import styles from "../ConditionValue.module.scss";

interface Props {
  value: string;
  onChange: (
    value: string,
    labels?: Record<string, string[]>,
    isSpecificTime?: boolean,
  ) => void;
  placeholder?: string;
  dateType: FieldDataDateTypes;
  error?: string;
  suffix?: string;
  isSpecificTime?: boolean;
  onSpecificTimeChange?: (isSpecificTime: boolean) => void;
}
const DateTimeSelector = ({
  value,
  onChange,
  placeholder = "Enter date",
  dateType,
  error,
  suffix,
  isSpecificTime,
  onSpecificTimeChange = () => {},
}: Props) => {
  const [showSelectSpecificTimeMenu, setShowSelectSpecificTimeMenu] =
    useState(false);
  const [selectSpecificTime, setSelectSpecificTime] = useState(false);

  const { date, time } = useMemo(() => {
    let parsedValue = value;

    // Reset the value if we have number input value
    if (typeof parsedValue === "number") {
      parsedValue = "";
    }

    const [date = "", time = ""] = parsedValue.split(" ");

    // If we have time initially then set the specific time true
    if (isSpecificTime !== undefined) {
      setSelectSpecificTime(isSpecificTime);
    } else {
      setSelectSpecificTime(false);
    }

    return { date, time };
  }, [value, isSpecificTime, dateType]);

  const handleDateChange = useCallback(
    (value: Date | null) => {
      if (value) {
        onChange(`${generateDateOnly(value)} ${time}`.trim());
        onSpecificTimeChange(selectSpecificTime);
      } else {
        onChange("");
      }
    },
    [onChange, onSpecificTimeChange, selectSpecificTime, time],
  );

  const isError = useMemo(
    () =>
      error &&
      !isMySQLDateStringValid(value, dateType.replace("Range", "") as any),
    [error, value, dateType],
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-md-row">
        <div className="">
          <DatePicker
            // Here the time is added in date to fix the UTC time error
            selected={date ? new Date(`${date} ${time}`) : null} // Use the Date object in the DatePicker
            onChange={handleDateChange}
            placeholderText={placeholder}
            className={`${isError ? styles.errBorder : ""}`}
            isClearable={false}
            dateFormat="MMM d, yyyy"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            customInput={
              <input
                type="text"
                value={date}
                placeholder={placeholder}
                className={`${styles.datePickerInput}`}
              />
            }
          />
        </div>
        <Dropdown
          show={showSelectSpecificTimeMenu}
          onToggle={(show) => setShowSelectSpecificTimeMenu(show)}
          className=""
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            as="div"
            className={`${styles.timeOptionContainer} ${styles.dropText} cursor-pointer px-2 mt-2 mt-md-0 ms-md-2 align-items-center justify-content-between`}
          >
            <span>{selectSpecificTime ? "Set specific time" : "Any time"}</span>
            <span className={`ps-2 ${styles.arrowDown}`}>
              <i className="fa-solid fa-chevron-down"></i>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu px-1 ${styles.dropMenuTime}`}>
            <div>
              <span
                className={`${styles.dropItemTime} p-2 d-flex align-items-center justify-content-start`}
                onClick={() => {
                  setSelectSpecificTime(false);
                  setShowSelectSpecificTimeMenu(false);
                  onChange(
                    `${date}${isSpecificTime !== undefined ? " 00:00:00" : ""}`,
                  );
                  onSpecificTimeChange(false);
                }}
              >
                Any time
              </span>
              <span
                className={`${styles.dropItemTime} p-2 d-flex align-items-center justify-content-start`}
                onClick={() => {
                  setSelectSpecificTime(true);
                  setShowSelectSpecificTimeMenu(false);
                  onChange(`${date} 00:00:00`);
                  onSpecificTimeChange(true);
                }}
              >
                Set specific time
              </span>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div>
          {/* Time */}
          {(dateType === "dateTime" || selectSpecificTime) && (
            <div
              className={`${
                styles.timeContainer
              } ms-2 d-flex align-items-center justify-content-between px-2 ${
                isError ? styles.errBorder : ""
              }`}
            >
              <TimePicker
                date={date}
                time={time}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      </div>
      {isError ? <div className={`${styles.errText} ms-2`}>{error}</div> : ""}
    </div>
  );
};

interface TimePickerProps {
  date: string;
  time: string;
  onChange: (
    value: string,
    labels?: Record<string, string[]>,
    isSpecificTime?: boolean,
  ) => void;
}

const TimePicker = ({ date, time, onChange }: TimePickerProps) => {
  // State for managing the visibility of AM/PM Dropdown
  const [showAMPMMenu, setShowAMPMMenu] = useState(false);

  // State to handle the local hour, minute, and whether it's AM or PM
  const [localHour, setLocalHour] = useState<string>("12");
  const [localMinute, setLocalMinute] = useState<string>("00");
  const [isAM, setIsAM] = useState(true);

  /**
   * Effect that runs when the `time` prop changes.
   * This time is in 24 hour format
   * It updates the local state (hour, minute, isAM) based on the provided time value.
   */
  useEffect(() => {
    if (time) {
      const [hh = "12", mm = "00"] = time.split(":");
      const hour = parseInt(hh);

      // Convert 24-hour format to 12-hour format and update states
      setLocalHour(
        `${
          hour > 24 ? 12 : hour > 12 ? hour - 12 : hour === 0 ? 12 : hour
        }`.padStart(2, "0"),
      );
      setLocalMinute(mm);
      setIsAM(hour < 12);
    }
  }, [time]);

  /**
   * Handler that formats and saves the time when the input loses focus (onBlur).
   * It ensures that the hour and minute are valid and updates the time in 24-hour format.
   */
  const handleBlur = useCallback(() => {
    let hour = parseInt(localHour);
    if (isNaN(hour) || hour < 1) hour = 0; // Default to 0 (midnight) if invalid
    if (hour > 12) hour = 12; // Cap at 12 for 12-hour format

    let minute = parseInt(localMinute);
    if (isNaN(minute) || minute < 0) minute = 0; // Default to 0 if invalid
    if (minute > 59) minute = 59; // Cap at 59 for minutes

    // Adjust the hour based on AM/PM selection
    const adjustedHour = isAM
      ? hour === 12
        ? 0
        : hour // Midnight if 12 AM, otherwise same hour for AM
      : hour === 12
        ? 12
        : hour + 12; // Noon if 12 PM, otherwise add 12 for PM

    // Set fallback values for localHour and localMinute if input is empty
    if ((adjustedHour === 0 && isAM) || localHour.trim() === "") {
      setLocalHour("12");
    } else {
      setLocalHour(`${adjustedHour.toString().padStart(2, "0")}`);
    }

    if (localMinute.trim() === "") {
      setLocalMinute("00");
    } else {
      setLocalMinute(`${minute.toString().padStart(2, "0")}`);
    }

    onChange(
      `${date} ${adjustedHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:00`,
    );
  }, [localHour, localMinute, isAM, onChange, date]);

  const handleAMPMChange = useCallback(
    (ampm: "AM" | "PM") => {
      setIsAM(ampm === "AM");
      let hour = parseInt(localHour);
      if (ampm === "PM" && hour < 12) {
        hour += 12;
      } else if (ampm === "AM" && hour === 12) {
        hour = 0; // Midnight
      } else if (ampm === "PM" && hour === 12) {
        hour = 12; // Noon
      }

      onChange(`${date} ${hour.toString().padStart(2, "0")}:${localMinute}:00`);
      setShowAMPMMenu(false);
    },
    [onChange, localHour, localMinute, date],
  );

  return (
    <>
      {/* Hour Input */}
      <div>
        <input
          type="number"
          value={localHour}
          onChange={(e) => setLocalHour(e.target.value)}
          onBlur={handleBlur}
          min={1}
          max={12}
          style={{ maxWidth: "19px" }}
          className={`${styles.timeBox} border-0`}
        />
        <span>{" : "}</span>
        {/* Minute Input */}
        <input
          type="number"
          value={localMinute}
          onChange={(e) => setLocalMinute(e.target.value)}
          onBlur={handleBlur}
          className={`${styles.timeBox} border-0`}
          min={0}
          max={59}
          style={{ maxWidth: "19px" }}
        />
      </div>

      {/* AM/PM Dropdown */}
      <Dropdown
        show={showAMPMMenu}
        onToggle={(show) => setShowAMPMMenu(show)}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          as="div"
          className={`ms-2 me-1 ps-1 pe-1 ${styles.timeDrop}`}
        >
          {isAM ? "AM" : "PM"}
          <span className={`ps-2 ${styles.arrowDown}`}>
            <i
              className={`fa-solid fa-chevron-${showAMPMMenu ? "up" : "down"}`}
            ></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          bsPrefix={`dropdown-menu ${styles.dropMenuTime}`}
          style={{ minWidth: "53px" }}
        >
          <div>
            <span
              className={`${styles.dropItemTime} d-flex align-items-center justify-content-center`}
              onClick={() => handleAMPMChange("AM")}
            >
              AM
            </span>
            <span
              className={`${styles.dropItemTime} d-flex align-items-center justify-content-center`}
              onClick={() => handleAMPMChange("PM")}
            >
              PM
            </span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DateTimeSelector;
