import { useMemo, useRef, useState } from "react";
import styles from "../../TimelineInfo.module.scss";
import {
  Timeline,
  TimelineEvent,
} from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import {
  TimelineActionTypes,
  useTimeline,
} from "../../TimelineProvider/TimelineProvider";
import EventRenderer from "../EventRenderer/EventRenderer";

export interface Props {
  yearData: Timeline;
  showTimeline: boolean;
  currentState: React.MutableRefObject<{ lastTimelineBgColor: string }>;
}
const SingleTimelineSegment = ({
  yearData,
  showTimeline,
  currentState,
}: Props) => {
  const { state, dispatch } = useTimeline();

  const interleaveEvents = (
    minor: TimelineEvent[],
    major: TimelineEvent[],
    attributes: TimelineEvent[],
  ) => {
    const interleaved: (TimelineEvent & { type: "minor" | "major" })[] = [];
    const minorGroups: (TimelineEvent & { type: "minor" | "major" })[] = [
      { ...minor[0], type: "minor" }, // Group 1: January, February, March
      { ...minor[1], type: "minor" }, // Group 2: April, May, June
      { ...minor[2], type: "minor" }, // Group 3: July, August, September
      { ...minor[3], type: "minor" }, // Group 4: October, November, December
    ];
    const majorGroups: (TimelineEvent & {
      type: "minor" | "major";
      attribute?: TimelineEvent;
    })[] = [
      { ...major[0], type: "major" }, // First 6 months
      { ...major[1], type: "major" }, // Last 6 months
    ];

    attributes.forEach((attribute) => {
      if (
        attribute.details &&
        attribute.details.length > 0 &&
        attribute.details[0].datetime
      ) {
        const firstDetailDate = new Date(attribute.details[0].datetime);
        const majorGroupIndex = firstDetailDate.getDate() > 17 ? 1 : 0;
        if (!majorGroups[majorGroupIndex]?.attribute) {
          majorGroups[majorGroupIndex].attribute = { ...attribute };
        } else {
          const majorGroupAttribute = majorGroups[majorGroupIndex]?.attribute;
          if (
            majorGroupAttribute?.details &&
            majorGroupAttribute.details[0]?.subEvents &&
            attribute.details[0]?.subEvents
          ) {
            const updatedMajorGroupAttribute = {
              ...majorGroupAttribute,
              details: [
                {
                  ...majorGroupAttribute.details[0],
                  subEvents: [
                    ...majorGroupAttribute.details[0].subEvents,
                    ...attribute.details[0].subEvents,
                  ],
                },
              ],
            };
            majorGroups[majorGroupIndex].attribute = updatedMajorGroupAttribute;
          }
        }
      }
    });

    interleaved.push(minorGroups[0]);
    interleaved.push(majorGroups[0]);
    interleaved.push(minorGroups[1]);
    interleaved.push(minorGroups[2]);
    interleaved.push(majorGroups[1]);
    interleaved.push(minorGroups[3]);

    return interleaved;
  };

  try {
    const interleavedEvents = interleaveEvents(
      yearData.events.minor,
      yearData.events.major,
      yearData.events.attribute ?? [],
    );
    return (
      <div className="d-flex align-items-center justify-content-center">
        <p
          className={`mb-0 ${styles.dateTime} ${
            yearData.position === "bottom" ? styles.bottom : ""
          } ${showTimeline ? styles.activeDateTime : ""}`}
          onClick={() => {
            if (
              state.referenceType === "customer" &&
              state.filters.view === "yearly"
            ) {
              dispatch({
                type: TimelineActionTypes.SET_FILTER,
                payload: {
                  view: "monthly",
                  year: yearData.label,
                },
              });
            }
          }}
        >
          {yearData.label}
        </p>
        {interleavedEvents.map((event, index) => (
          <EventRenderer
            key={index}
            event={event}
            index={index}
            bgColor={yearData.bgColor || ""}
            position={yearData.position}
            showTimeline={showTimeline}
            currentState={currentState}
          />
        ))}
      </div>
    );
  } catch (e) {
    dispatch({
      type: TimelineActionTypes.SET_ERROR,
      payload: "Failed to load the resource",
    });
    console.log("Failed to load the resource", e);
    return null;
  }
};

export default SingleTimelineSegment;
