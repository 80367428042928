import { useMemo } from "react";
import useFetchCampaignCategories from "../../../../../../Hooks/useFetchCampaignCategories";
import styles from "./CampaignType.module.scss";
import CategorySelectDD from "./Children/CategorySelectDD/CategorySelectDD";
import SubCategorySelectDD from "./Children/SubCategorySelectDD/SubCategorySelectDD";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";

const CampaignType = () => {
  const { state } = useAddCampaignState();
  const { allCampaignCategories, fetchMore, fetchStatus } =
    useFetchCampaignCategories();

  const selectedCategory = useMemo(() => {
    return allCampaignCategories.find((v) => v.id === state.categoryId);
  }, [allCampaignCategories, state.categoryId]);

  return (
    <div>
      <h3 className={`${styles.heading}`}>Type of Campaign</h3>
      <div className="d-flex">
        <CategorySelectDD
          allCampaignCategories={allCampaignCategories}
          fetchMore={fetchMore}
          fetchStatus={fetchStatus}
        />
        <SubCategorySelectDD
          fetchedCampaignSubCategories={selectedCategory?.subCategories}
          hasMore={
            !!selectedCategory?.subCategories &&
            !!selectedCategory?.subCategoryTotalCount &&
            selectedCategory.subCategoryTotalCount >
              selectedCategory.subCategories?.length
          }
        />
      </div>
    </div>
  );
};

export default CampaignType;
