import Lottie from "lottie-light-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import liveDot from "src/assets/images/liveDot.json";
import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { EAutomationType } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import {
  Question,
  QuestionVisibility,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import AutomationError from "../../../BugManagement/Children/ConfigureSteps/Children/Components/AutoamtionError/AutomationError";
import {
  findMoveToNextButtonPosition,
  updateVisibilityAndStatus,
} from "../../../helpers/Automation.helpers";
import MoveNextModel from "../../../TicketRoute/Children/Components/MoveNextModel/MoveNextModel";
import styles from "./AutomationSteps.module.scss";
import PublishAutomation from "./Children/Components/PublishAutomation/PublishAutomation";
import StepQuestion from "./Children/Question/Question";
import Segment from "./Children/Segment/Segment";

type QuestionType = Question & {
  questionNumber: number;
  stepId: string;
};

const getHeading = (type?: EAutomationType) => {
  switch (type) {
    case EAutomationType.BUG_MANAGEMENT:
      return "Please provide us with the following details to set up bug management on your live chat";
    case EAutomationType.ISSUE_DISPATCH:
      return "Please provide us with the following details to set up issue management for your system";
    default:
      return "";
  }
};

const getLiveStepSubHeading = (type?: EAutomationType) => {
  switch (type) {
    case EAutomationType.BUG_MANAGEMENT:
      return "The setup is complete you. Test the flow and go live with bug management automation flow.";
    case EAutomationType.ISSUE_DISPATCH:
      return "The setup is complete you. Test the flow and go live with automated returns flow.";
    case EAutomationType.TICKET_ROUTING:
      return "The setup is complete you. Test the flow and go live with automated ticket routing flow.";
    default:
      return "";
  }
};

const AutomationSteps = ({
  showQuestionNumber = true,
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  showQuestionNumber?: boolean;
}) => {
  const {
    formData,
    type,
    updateFormData,
    isSaving,
    saveData,
    isLoading,
    errorMessage: fetchConfigErrorMessage,
  } = useAutomationContext();
  const [showUnPublishModal, setShowUnpublishModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorAutomationPublish, setErrorAutomationPublish] =
    useState<boolean>(false);

  const moveToNextStep = useCallback(() => {
    const updatedData = updateVisibilityAndStatus(formData, true);
    const moveToNextQuestionKey = findMoveToNextButtonPosition(
      updatedData.stepsData,
      updatedData.questionsData,
      true,
    );

    updateFormData({ ...updatedData, moveToNextQuestionKey });
  }, [formData, updateFormData]);

  const [loading, setLoading] = useState(false);
  const [moveNext, setMoveNext] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleUnpublish = useCallback(async () => {
    if (!formData.showGoLive || !type) {
      console.log("Some Error Occured");
      return;
    }
    if (!formData.published && type !== "bugManagement") {
      setShowUnpublishModal(true);
    } else {
      setLoading(true);
      const payload = {
        integrationId: formData.integrationId,
        publish: !formData.published,
      };
      try {
        await updateAutomationService({
          type: type,
          payload: payload,
        });

        updateFormData({
          published: payload.publish,
          configured: payload.publish ? true : formData.configured,
        });

        if (type === "bugManagement" && payload.publish) {
          setShowUnpublishModal(true);
        }
        setErrorAutomationPublish(false);
      } catch (err) {
        if (
          typeof err === "object" &&
          err &&
          (err as any).message === "automation_already_exist"
        ) {
          setErrorAutomationPublish(true);
          return;
        }
        pushTheToast({
          position: "top-right",
          type: "danger",
          text:
            typeof err === "string"
              ? err
              : payload.publish
                ? "Could not publish automation"
                : "Could not unpublish automation",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    formData.integrationId,
    formData.published,
    formData.showGoLive,
    type,
    updateFormData,
  ]);

  // Use useMemo to calculate the visible questions and their numbers
  const visibleQuestions = useMemo(() => {
    let questionCounter = 0; // Initialize the question counter
    const questionsWithNumbers: QuestionType[] = []; // Store questions with their numbers

    formData.stepIds.forEach((stepId) => {
      const step = formData.stepsData[stepId];
      if (!step.isVisible) return;

      step.questionKeys.forEach((questionKey) => {
        const question = formData.questionsData[questionKey];
        if (question.visibility === QuestionVisibility.Show) {
          questionCounter += 1; // Increment the counter for visible questions
          questionsWithNumbers.push({
            ...question,
            questionNumber: questionCounter, // Assign the current counter as question number
            stepId: stepId,
          });
        }
      });
    });

    return questionsWithNumbers;
  }, [formData]);

  useEffect(() => {
    if (formData.brand === null) {
      setErrorAutomationPublish(true);
    } else {
      setErrorAutomationPublish(false);
    }
  }, [formData.brand]);

  if (isLoading) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <Loader />
      </div>
    );
  }

  if (fetchConfigErrorMessage) {
    return (
      <div className={`p-3 ${styles.settingWrap}`}>
        <div className="d-flex align-items-center justify-content-center text-danger">
          {fetchConfigErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="mt-4">
        <h4 className={`mb-4 ${styles.heading}`}>{getHeading(type)}</h4>
      </div>
      <div>
        {formData.stepIds.map((id, idx) => {
          const step = formData.stepsData[id];
          if (!step.isVisible) {
            return null;
          }

          return (
            <Segment
              heading={step.stepTitle}
              subHeading={step.stepSubTitle}
              key={`${idx}_${step.stepId}`}
              stepNumber={idx + 1}
              stepDone={step.stepStatus === StepStatus.Completed}
              showStep={activeIndex === idx}
              onShow={setActiveIndex}
              showError={showError}
            >
              {visibleQuestions
                .filter((question) => question.stepId === id)
                .map((question) => (
                  <StepQuestion
                    key={question.questionKey}
                    questionData={question}
                    stepId={question.stepId}
                    questionNumber={question.questionNumber}
                    showQuestionNumber={showQuestionNumber}
                    setShowErrorModal={setShowErrorModal}
                    updateActiveStep={setActiveIndex}
                    setMoveNext={setMoveNext}
                    showError={showError}
                    setShowError={setShowError}
                  />
                ))}
            </Segment>
          );
        })}
        {formData.showGoLive && (
          <Segment
            heading={"You're ready to go! Go live."}
            headingColor="#0b68bb"
            subHeading={getLiveStepSubHeading(type)}
            stepNumber={(formData.stepIds?.length ?? 0) + 1}
            key={formData.stepIds?.length ?? 0}
            stepDone={formData.published === true}
            showStep={activeIndex === formData.stepIds.length}
            onShow={setActiveIndex}
          >
            <div className="d-flex flex-column">
              {/* Sep 6 commenting as there is no interaction */}
              {/* {type === EAutomationType.TICKET_ROUTING ? (
              <div className={styles.testBtn}>Test complete workflow</div>
            ) : (
              ""
            )} */}
              <div className="d-flex align-items-center">
                <button
                  className={`mt-2 ${styles.moveBtn}`}
                  id="automation_goLive_btn"
                  disabled={
                    isSaving ||
                    (formData.brand === null &&
                      type === EAutomationType.BUG_MANAGEMENT)
                  }
                  onClick={() => handleUnpublish()}
                >
                  <span className="pe-1">
                    <i className="fa-regular fa-eye"></i>
                  </span>
                  <span> {formData.published ? "Unpublish" : "Go live"} </span>
                  {loading && (
                    <Spinner
                      className={`mx-1 my-auto`}
                      animation="border"
                      color="white"
                      size="sm"
                    />
                  )}
                </button>

                {formData.published ? (
                  <div className="ms-3 mt-2 d-flex align-items-center">
                    <span
                      style={{
                        height: 20,
                        width: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Lottie animationData={liveDot}></Lottie>
                    </span>
                    <div className="ps-1">
                      <span className={`${styles.liveText}`}>Live</span>
                    </div>
                  </div>
                ) : null}
              </div>
              {errorAutomationPublish ? (
                <div className={`${styles.errorSpan} mx-2 mt-2`}>
                  {formData.brand === null &&
                  type === EAutomationType.BUG_MANAGEMENT ? (
                    "Please add a brand to publish this automation."
                  ) : (
                    <>
                      This kind of automation has already been published for{" "}
                      <strong>{formData?.brand?.name ?? "brand"}</strong>.
                      Please unpublish the existing version in order to proceed
                      with publishing the new version.
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </Segment>
        )}

        <Modal
          backdropClassName={`${styles.modalBrandBack}`}
          show={showUnPublishModal}
          onHide={() => setShowUnpublishModal(false)}
          dialogClassName={`${styles.modalBrandDialog}`}
          contentClassName={`${styles.modalBrandContent}`}
          centered={true}
        >
          <PublishAutomation onHide={() => setShowUnpublishModal(false)} />
        </Modal>
        <Modal
          backdropClassName={`${styles.modalErrorBack}`}
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          dialogClassName={`${styles.modalBrandDialog}`}
          contentClassName={`${styles.modalErroContent}`}
          style={{ zIndex: 99999 }}
        >
          <AutomationError onHide={() => setShowErrorModal(false)} />
        </Modal>
        <Modal
          show={moveNext}
          onHide={() => {
            setMoveNext(false);
          }}
          dialogClassName={`${styles.modalBrandDialog}`}
          contentClassName={`${styles.modalBrandContent}`}
          centered={true}
          enforceFocus={false}
          backdropClassName={`${styles.modalBrandBack}`}
        >
          <MoveNextModel
            onHide={() => {
              setMoveNext(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AutomationSteps;
