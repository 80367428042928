import styles from "./UploadedFilesList.module.scss";
import ShowImgVid from "./ShowImgVid/ShowImgVid";
import ShowOtherFiles from "./ShowOtherFiles/ShowOtherFiles";
// import "./HorizontalScrollStyles.scss";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useState } from "react";
import {
  TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES,
  TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
  TinyMCEUploadFile,
} from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { getTypeFromFileName } from "src/utils/utils";

function UploadedFilesList({ selectedFiles, onDeleteFile }: any) {
  let scrl = useRef<any>();
  // const [scrollX, setscrollX] = useState(0);
  // const [scrolEnd, setscrolEnd] = useState(false);

  // const scrollX = useRef(0);
  // const scrollEnd = useRef(false);
  const [showScrollBtn, setShowScrollBtn] = useState(
    "" as "both" | "left" | "right" | "",
  );

  useEffect(() => {
    slide(0);
  }, [Object.values(selectedFiles).length]);

  //Slide click
  const slide = (shift: any) => {
    scrl.current.scrollLeft += shift;
    // setscrollX(scrollX + shift);
    // scrollX.current+= shift;
    if (scrl.current.scrollWidth === scrl.current.offsetWidth) {
      setShowScrollBtn("");
    } else if (
      !(scrl.current.scrollLeft === 0) &&
      !(
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      )
    ) {
      setShowScrollBtn("both");
    } else if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      // setscrolEnd(true);
      // scrollEnd.current = true;
      setShowScrollBtn("left");
    } else if (scrl.current.scrollLeft === 0) {
      setShowScrollBtn("right");
    } else {
      // setscrolEnd(false);
      // scrollEnd.current = false;
      setShowScrollBtn("");
    }
  };

  const getFileType = (file: TinyMCEUploadFile) => {
    let fileType = "";
    if (file.file && file.file.type) {
      fileType = file.file.type;
    } else if (file && file.attachmentType) {
      fileType = file.attachmentType;
    }
    return fileType;
  };

  return (
    // justify-content-between w-100
    <div
      className={`container d-flex flex-row position-relative ${styles.showAttachmentOverlay}`}
    >
      {/* {console.log(
        "show Attachment overlay selected files::: ",
        JSON.stringify(selectedFiles)
      )} */}
      <div
        style={{
          width: "0%",
          zIndex: "80",
          transform: "translate(-24px, 10px)",
        }}
      >
        {(showScrollBtn === "both" || showScrollBtn === "left") && (
          <div
            className={`m-2  ${styles.leftRightArrowSpan}`}
            onClick={() => slide(-80)}
          >
            <i className={`fa-solid fa-arrow-left ${styles.arrow}`}></i>
          </div>
        )}
      </div>
      <div
        className={`d-flex flex-row text-center pt-2 `}
        style={{ width: "100%", overflowX: "hidden", overflowY: "visible" }}
        ref={scrl}
        // onScroll={scrollCheck}
      >
        {/* <div
        className={`text-center`} /style={{height:"78px", maxHeight: "78px"}}/
      > */}

        {Object.values(selectedFiles)?.map((file: any) => {
          return TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(
            getTypeFromFileName(
              (file?.attachmentName ?? file?.file?.name) + "",
            ),
          ) ? (
            <ShowImgVid
              key={file.id}
              file={file}
              onDeleteFile={onDeleteFile}
            />
          ) : (
            <ShowOtherFiles
              key={file.id}
              file={file}
              onDeleteFile={onDeleteFile}
            />
          );
        })}
      </div>
      <div style={{ width: "0%", transform: "translate(-16px ,10px)" }}>
        {(showScrollBtn === "both" || showScrollBtn === "right") && (
          <div
            className={`m-2 ${styles.leftRightArrowSpan}`}
            onClick={() => slide(+80)}
          >
            <i className={`fa-solid fa-arrow-right ${styles.arrow}`}></i>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadedFilesList;
