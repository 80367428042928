import React from "react";
import Select, {
  components,
  OptionProps,
  Props,
  SingleValueProps,
  StylesConfig,
} from "react-select";
import styles from "./UserSelect.module.scss";

export interface UserSelectBrandOption {
  value: number | string;
  label: string;
}

// Custom option component
const CustomOption: React.FC<OptionProps<UserSelectBrandOption, boolean>> = (
  props,
) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer `}>
      <div className="form-check">
        {props.isMulti && (
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            value=""
            checked={props.isSelected}
            id={`checkbox-${props.data.value}`}
          />
        )}

        <label
          className={`form-check-label ${
            props.isMulti ? "ps-2" : ""
          } cursor-pointer ${styles.brandName}`}
          htmlFor={`checkbox-${props.data.value}`}
        >
          {props.label}
        </label>
      </div>
    </div>
  </components.Option>
);

// Custom single value component
const CustomSingleValue: React.FC<
  SingleValueProps<UserSelectBrandOption, boolean>
> = (props) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data.label}</span>
    </div>
  </components.SingleValue>
);

// Custom styles for the Select component
const customStyles: StylesConfig<UserSelectBrandOption, boolean> = {
  control: (provided) => ({
    ...provided,
    background: "#F9F9F9",
    border: "1px solid #A5A5A5",
    width: "100%",
    borderRadius: "5px",
    minHeight: "35px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "35px",
    padding: "0 6px",
    font: "normal normal normal 12px/18px Poppins",
    color: "#000000",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: "35px",
    padding: "0px !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f0f0f0" : "#fff",
    color: "#000",
    padding: "0 10px",
    minHeight: "31px",
    display: "flex",
    alignItems: "center",
  }),
  menuList: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 3px 20px #00000029",
    borderRadius: "13px",
    padding: "8px 0",
  }),
};

interface UserSelectProps extends Props<UserSelectBrandOption, boolean> {
  showError?: boolean;
}

// UserSelect component with dynamic options and placeholder
const UserSelect: React.FC<UserSelectProps> = ({ showError, ...props }) => {
  return (
    <Select
      className={`w-100 cursor-pointer ${
        showError ? "border border-danger" : ""
      }`}
      classNamePrefix="dropdown"
      isSearchable={false}
      menuPortalTarget={document.body}
      styles={customStyles}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      {...props}
    />
  );
};

export default UserSelect;
