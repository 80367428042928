import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import SignInWithGoogle from "src/components/SignInWithGoogle/SignInWithGoogle";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import emailIntWithGmailService from "src/services/EmailIntegration/emailIntWithGmail.service";
import { useAppSelector } from "src/store/store";
import styles from "./Gmail.module.scss";

export const HELPDESK_REDIRECT_OUTBOUND = "HELPDESK_REDIRECT_OUTBOUND";

const Gmail = ({
  isIncommingSetting,
  isOutboundEmail = false,
}: {
  isIncommingSetting?: boolean;
  isOutboundEmail?: boolean;
}) => {
  const [gmailVerificationLoading, setGmailVerificationLoading] =
    useState(false);

  const selectedUserDetail = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );

  const { isIncommingGmailVerified } = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );
  const isDomainVerified = useMemo(() => {
    if (selectedUserDetail.outgoingMailSettings?.integrations) {
      return !!selectedUserDetail.outgoingMailSettings.integrations["gmail"]
        ?.verified;
    }

    return selectedUserDetail.isDomainVerified;
  }, [
    selectedUserDetail.isDomainVerified,
    selectedUserDetail.outgoingMailSettings.integrations,
  ]);

  const handleSignInWithGoogle = useCallback(() => {
    if (isDomainVerified && (!isIncommingSetting || isIncommingGmailVerified)) {
      return;
    }

    if (selectedUserDetail.emailIntegrationId && !gmailVerificationLoading) {
      setGmailVerificationLoading(true);
      emailIntWithGmailService({
        emailIntegrationId: selectedUserDetail.emailIntegrationId + "",
        incomming: isIncommingSetting ? true : undefined,
      })
        .then((res) => {
          if (isOutboundEmail) {
            localStorage.setItem(HELPDESK_REDIRECT_OUTBOUND, "true");
          }
          window.location.replace(res);
        })
        .catch((err) => {
          console.error(err);
          pushTheToast({
            text: "Something went wrong. Please try again later...",
            type: "danger",
            position: "top-right",
          });
        })
        .finally(() => {
          setGmailVerificationLoading(false);
        });
    }
  }, [
    isDomainVerified,
    isIncommingSetting,
    isIncommingGmailVerified,
    selectedUserDetail.emailIntegrationId,
    gmailVerificationLoading,
    isOutboundEmail,
  ]);

  return (
    <>
      <div className={`ms-2 mt-5 ${styles.container}`}>
        {/* Heading */}
        <div className={`${styles.topContainer}`}>
          <p>Login details</p>
        </div>
        <div className={`d-flex ${styles.bottomContainer}`}>
          <SignInWithGoogle
            className="my-auto"
            isDisabled={
              isIncommingSetting ? !!isIncommingGmailVerified : isDomainVerified
            }
            onClick={handleSignInWithGoogle}
          />
          {gmailVerificationLoading ? (
            <div className="my-auto">
              <Spinner size="sm" />
            </div>
          ) : null}
          {isDomainVerified &&
            (!isIncommingSetting || isIncommingGmailVerified) && (
              <div className="ms-4 d-flex align-items-center">
                <div className={`${styles.successCheck}`}>
                  <span>
                    <i className="fa-solid fa-check"></i>
                  </span>
                </div>
                <span className={`ms-2 ${styles.statusText}`}>
                  Authenticated!
                </span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Gmail;
