import {
  ClosingSectionSequence,
  IntroductionSectionSequence,
  TextSectionSequence,
} from "src/services/Campaign/createCampaignV2.service";
import styles from "../SequenceSection.module.scss";
import BaseInput from "./BaseInput";
import dots from "src/assets/images/sixDots.png";
const TextSection = ({
  heading,
  placeholder,
  section,
  onChange,
  errorMsg,
  handleDelete,
}: {
  heading: string;
  placeholder: string;
  section:
    | TextSectionSequence
    | ClosingSectionSequence
    | IntroductionSectionSequence;
  onChange: (value: string) => void;
  errorMsg: string;
  handleDelete: () => void;
}) => {
  return (
    <div className={`w-100 d-flex ${styles.introBox} `}>
      <div className="d-flex align-items-center me-2">
        <p className="mb-0 d-flex">
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </p>
      </div>
      <div className="w-100">
        <div className={`mt-2 w-100`}>
          <div className="d-flex justify-content-between">
            <h5 className={`${styles.introHead}`}>{heading}</h5>
            <span
              className={`${styles.deleteText} cursor-pointer`}
              onClick={handleDelete}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>
          <div className="d-flex align-items-center w-100">
            <p className={`${styles.formLabel}`}>Will be</p>
            <div className={`ms-3 me-3 ${styles.inputWrapper} w-100`}>
              <BaseInput
                onChange={onChange}
                value={section.description}
                error={errorMsg}
                placeholder={placeholder}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextSection;
