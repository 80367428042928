import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CustomerInfo {
  id: string;
  name: string;
  email: string;
  imgURL?: string | null;
  isPublicAttachmentUrl?: boolean;
  moreDetails: {
    channel: string;
    lastMessage: string;
    lastMessageDate: string;
    lastMessageDateGmt: string;
    missedChatCount: number;
    activeChatCount: number;
  };
}

async function getAllCustomersService(params: {
  start?: number;
  limit?: number;
  customerIds?: Array<string | number>;
}) {
  const { data: res } = await axiosJSON.post(
    "/api/customer/getAllCustomers",
    params,
  );
  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  const ret: {
    customers: Record<string, CustomerInfo>;
    customerIds: Array<string>;
    totalCustomers: number;
  } = {
    customers: {},
    customerIds: [],
    totalCustomers: res.metaData?.totalCustomers ?? res.data.length,
  };

  (res.data as Array<CustomerInfo>).forEach((v) => {
    v.id = v.id + "";
    ret.customers[v.id] = v;
    ret.customerIds.push(v.id);
  });

  return ret;
}

export default getAllCustomersService;
