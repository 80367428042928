import { Channel } from "pusher-js";
import { channelSubscriber, channelUnsubscriber } from "src/globals/pusher";
import {
  addChatWidgetInstalledEvent,
  ChatWidgetInstalledEvent,
} from "src/pusherServices/livechat/websiteSettings";
import {
  checkLCInstallation,
  checkPostLCInstallation,
} from "src/services/LiveChat/Settings/website/checkLCInstallation";
import { createWebIntegration } from "src/services/LiveChat/Settings/website/createWebIntegration";
import {
  getallWebIntegrations,
  getIntegrationDetails,
  WebsiteIntegDetParams,
} from "src/services/LiveChat/Settings/website/getWebIntegrations";
import {
  sendWidgetScriptEmail,
  SendWidgetScriptEmailPayload,
} from "src/services/LiveChat/Settings/website/sendWidgetScriptEmail";
import {
  updateWebIntegration,
  UpdateWebIntParams,
} from "src/services/LiveChat/Settings/website/updateWebIntegration";
import { RootState } from "src/store/store";
import {
  fetchallWebIntegrations,
  fetchWebIntegration,
  installShopifyWidget,
  setInstalledWebInt,
  setIntegrationInstalledStatus,
} from "./websiteSettings.slice";
import {
  InstallShopifyWidgetParam,
  installShopifyWidgetService,
} from "src/services/LiveChat/Settings/website/installShopifyWidgetService";

const fetchallWebIntegrationsThunk = async () => {
  const data = await getallWebIntegrations();
  return data;
};

interface FetchWebsiteIntegDetParams extends WebsiteIntegDetParams {
  navigateCallBack?: () => void;
}

const fetchWebIntegrationThunk = async ({
  navigateCallBack = () => {},
  ...payload
}: FetchWebsiteIntegDetParams) => {
  try {
    const data = await getIntegrationDetails(payload);
    return data;
  } catch (e) {
    console.error(e);
    navigateCallBack();
    throw e;
  }
};

const createNewWebIntegrationThunk = async () => {
  const data = await createWebIntegration();
  return data;
};

export interface FetchUpdateWebIntParams extends UpdateWebIntParams {
  callback?: any;
  preventFetchAll?: boolean;
}

const updateTheWebIntegrationThunk = async (
  { callback, preventFetchAll, ...payload }: FetchUpdateWebIntParams,
  { dispatch }: any,
) => {
  const data = await updateWebIntegration(payload);
  if (!preventFetchAll) {
    dispatch(fetchallWebIntegrations());
  }
  await dispatch(
    fetchWebIntegration({ integration_id: payload.integration_id }),
  );
  return payload;
};

const checkInstallationPromise = (
  channel: Channel | null,
  getState: any,
  timeout: any,
) => {
  return new Promise((res, rej) => {
    timeout = setTimeout(() => {
      const currentState: RootState = getState();
      const integrationId =
        currentState.websiteSettings.activeWebIntegration.integration_id;
      checkPostLCInstallation({ integrationId })
        .then((data) => {
          res({
            installed_successfully: true,
            integration_id: integrationId,
            enabled: data?.enabled, //updating enabled property from api response, it will later updated using reducer method in wibsiteSettings slice
          });
        })
        .catch((err) => {
          rej("Installation failed!");
        });
    }, 25000);
    if (channel) {
      addChatWidgetInstalledEvent(channel, (eventRes) => {
        clearTimeout(timeout);
        res(eventRes);
      });
    }
  }) as Promise<ChatWidgetInstalledEvent>;
};

const checkLCInstallationThunk = async (
  payload: undefined,
  { getState, dispatch }: any,
) => {
  const currentState: RootState = getState();
  const channelName =
    currentState.websiteSettings.activeWebIntegration
      .integration_web_socket_channel_name;
  const integrationId =
    currentState.websiteSettings.activeWebIntegration.integration_id;
  if (channelName && integrationId) {
    const channel = channelSubscriber(channelName);
    let timeout = null as any;
    checkLCInstallation({ integration_id: integrationId }).then((res) => {
      // Check if the shopify extension is not installed
      if (res === "extension_not_installed") {
        // Dispatch action to set integration installed status
        dispatch(
          setIntegrationInstalledStatus({
            integration_id: integrationId,
            installed_successfully: false,
          }),
        );
      } else if (res === "installed") {
        // Dispatch action to set integration installed status
        dispatch(
          setInstalledWebInt({
            integration_id: integrationId,
            installed_successfully: true,
            enabled: true,
            lcInstallationStatus: "fulfilled",
          }),
        );
      }
    });
    const installData = await checkInstallationPromise(
      channel,
      getState,
      timeout,
    );
    channelUnsubscriber(channelName);
    return installData;
  }
};

interface SendWidgetScriptEmailP extends SendWidgetScriptEmailPayload {
  callback?: () => void;
}

//thunk to call send widget script email api
const sendWidgetScriptEmailThunk = async ({
  callback = () => {},
  ...payload
}: SendWidgetScriptEmailP) => {
  const data = await sendWidgetScriptEmail(payload);

  setTimeout(() => {
    callback();
  }, 0);

  return data;
};

const installShopifyWidgetThunk = async (
  { ...payload }: InstallShopifyWidgetParam,
  { dispatch }: any,
) => {
  // Call the service to install the Shopify widget
  const data = await installShopifyWidgetService({
    integrationId: payload.integrationId,
  });
  // dispatch(fetchWebIntegration({ integration_id: payload.integrationId }));
  return { data, payload };
};

export default {
  fetchallWebIntegrationsThunk,
  fetchWebIntegrationThunk,
  createNewWebIntegrationThunk,
  updateTheWebIntegrationThunk,
  installShopifyWidgetThunk,
  checkLCInstallationThunk,
  sendWidgetScriptEmailThunk,
};
