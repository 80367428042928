import NavItemToggleList from "../NavItemToggleList/NavItemToggleList";
import styles from "../../CSSideBar.module.scss";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { useCallback, useMemo } from "react";
import getCustomSegmentsService from "src/services/CustomerSegments/getCustomSegments.service";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";
import { NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";

/**
 * Props interface for CustomSegmentList component.
 */
interface Props {
  name: string;
  segmentType: string;
}

const CUSTOM_SEGMENT_LIMIT = 50;

/**
 * Custom hook to handle fetching and state management of custom segments.
 * @param segmentType - The type of segment to fetch.
 * @returns Object containing custom segments, customer segments, fetchNextPage function, and the total custom segment length.
 */
const useCustomSegments = ({ segmentType }: { segmentType: string }) => {
  const { customerSegments, dispatch } = useCustomerSegments();

  // Memoize custom segments based on segmentType.
  const customSegmet = useMemo(
    () =>
      customerSegments.customSegments[segmentType]
        ? customerSegments.customSegments[segmentType]
        : null,
    [customerSegments, segmentType],
  );

  // Calculate total custom segment length and if the active segment is fetched.
  const { allCustomSegmentLength, isActiveSegmentFetched } = useMemo(() => {
    const allCustomSegmentLength =
      customSegmet?.allCustomSegmentIds?.length ?? 0;

    const isActiveSegmentFetched =
      allCustomSegmentLength === 1 &&
      customSegmet?.allCustomSegments[customerSegments.activeSegmentId + ""]
        ? true
        : false;

    return { allCustomSegmentLength, isActiveSegmentFetched };
  }, [customSegmet, customerSegments]);

  // Callback to fetch custom segments starting from a given index.
  const fetchCustomSegment = useCallback(
    (start: number) => {
      // Check if custom segments are not already loading.
      if (customSegmet?.customSegmentsLoading !== "pending") {
        dispatch("appendCustomSegments", {
          segmentType,
          loading: "pending",
        });
        getCustomSegmentsService({
          segmentType,
          start,
          limit: CUSTOM_SEGMENT_LIMIT,
        })
          .then((res) => {
            dispatch("appendCustomSegments", {
              segmentType,
              customSegments: res,
              loading: "fulfilled",
              hasNextPage:
                res.allCustomSegmentIds.length < CUSTOM_SEGMENT_LIMIT
                  ? false
                  : true,
            });
          })
          .catch((err) => {
            console.error(err);
            dispatch("appendCustomSegments", {
              segmentType,
              loading: "rejected",
            });
          });
      }
    },
    [customSegmet?.customSegmentsLoading, segmentType, dispatch],
  );

  // Callback to fetch the next page of custom segments.
  const fetchNextPage = useCallback(() => {
    fetchCustomSegment(isActiveSegmentFetched ? 0 : allCustomSegmentLength);
  }, [fetchCustomSegment, allCustomSegmentLength, isActiveSegmentFetched]);

  return {
    customSegmet,
    customerSegments,
    fetchNextPage,
    allCustomSegmentLength,
  };
};

/**
 * Component to render a list of custom segments with infinite scrolling.
 * @param name - The name of the segment.
 * @param segmentType - The type of segment.
 * @returns JSX.Element to render the custom segment list.
 */
const CustomSegmentList = ({ name, segmentType }: Props) => {
  const {
    customSegmet,
    customerSegments,
    fetchNextPage,
    allCustomSegmentLength,
  } = useCustomSegments({
    segmentType,
  });

  // Check if custom segments are available.
  if (customSegmet) {
    return (
      <div className={`${styles.a}`}>
        <NavItemToggleList
          id={`${segmentType}_${name}_sublist`}
          title={name}
          initialOpenState={customerSegments.activeSegmentType === segmentType}
        >
          {/* Display error message if loading is rejected and no segments are available */}
          {customSegmet.customSegmentsLoading === "rejected" &&
          customSegmet.allCustomSegmentIds.length === 0 ? (
            <span
              className={`d-flex align-items-center justify-content-center mt-3 text-danger ${styles.errorMessage}`}
            >
              Error Loading Content
            </span>
          ) : customSegmet.customSegmentsLoading === "fulfilled" &&
            customSegmet.hasNextPage === false &&
            customSegmet.allCustomSegmentIds.length === 0 ? (
            // Display message if no data is found
            <span
              className={`d-flex align-items-center justify-content-center mt-3 ${styles.errorMessage}`}
            >
              No Data Found
            </span>
          ) : (
            // Render the infinite scroll component with load more functionality
            <InfiniteScroll
              loadMoreFromBottom={
                // Added this check to prevent the initial data fetching
                customSegmet.hasNextPage && allCustomSegmentLength > 1
                  ? fetchNextPage
                  : undefined
              }
              hasMoreBottom={customSegmet.hasNextPage}
              className={`${styles.customSegmentsList}`}
              infiniteLoader={
                <LoadMoreLoader
                  fetchMore={fetchNextPage}
                  variant="pink"
                  status={
                    customSegmet.customSegmentsLoading !== "pending"
                      ? "fulfilled"
                      : "pending"
                  }
                  loadMoreText={`Load ${
                    allCustomSegmentLength === 0 ? "Data" : "More"
                  }`}
                />
              }
            >
              <ul
                id={`${segmentType}_${name}_sublist_list`}
                className={`${styles.nav_content}`}
              >
                {/* Map over custom segment IDs to render each segment item */}
                {customSegmet.allCustomSegmentIds.map((id) => {
                  const item = customSegmet.allCustomSegments[id];
                  if (item) {
                    return (
                      <li key={item.segmentId}>
                        <NavLink
                          to={customerSegmentsUtils.getNavigation(
                            segmentType,
                            id,
                          )}
                          className={`${styles.a}`}
                          id={item.segmentId}
                          style={({ isActive }) => ({
                            color: isActive ? "#fff" : "",
                            background: isActive ? "#ff8499" : "",
                          })}
                        >
                          <span>{item.name}</span>
                        </NavLink>
                      </li>
                    );
                  }
                })}
              </ul>
            </InfiniteScroll>
          )}
        </NavItemToggleList>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomSegmentList;
