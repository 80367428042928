import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "src/store/store";
import {
  TimelineActionTypes,
  useTimeline,
} from "../TimelineInfo/TimelineProvider/TimelineProvider";
import TimelineInfo from "../TimelineInfo/TimelineInfo";
import { useTimeLineMonthData } from "../TimelineInfo/Children/SingleTimelineSegment/useTimeLineMonthData";

const CustomerTimeline = ({
  showTimeline,
  timelineRef,
  onLoad,
}: {
  showTimeline: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
  onLoad?: () => void;
}) => {
  const { timelineInfo, id } = useAppSelector(
    (state) => state.shopifySidebar.customerData,
  );
  const customerDataAJAXStatus = useAppSelector(
    (state) => state.shopifySidebar.customerDataStatus,
  );

  const showLoader = useMemo(() => {
    if (timelineInfo) {
      return false;
    }
    return customerDataAJAXStatus === "pending" ? true : false;
  }, [customerDataAJAXStatus, timelineInfo]);

  const { state, dispatch } = useTimeline();

  const { data } = useTimeLineMonthData(state.filters?.year);

  useEffect(() => {
    if(state.filters.view === "monthly"){
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: data
      })
    }
  }, [data, state.filters.view, dispatch])

  useEffect(() => {
    if (timelineInfo) {
      if (onLoad) {
        onLoad();
      }
      dispatch({
        type: TimelineActionTypes.SET_TIMELINE_DATA,
        payload: timelineInfo,
      });
    }
  }, [timelineInfo, dispatch]);

  useEffect(() => {
    dispatch({
      type: TimelineActionTypes.SET_LOADING,
      payload: showLoader,
    });
  }, [showLoader, dispatch]);

  return (
    <TimelineInfo
      timelineRef={timelineRef}
      showTimeline={showTimeline}
    />
  );
};

export default CustomerTimeline;
