import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Overlay } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/store/store";
import styles from "./MessageContainer.module.scss";
import sendLogo from "src/assets/images/send.png";
import QuillEditorContainer from "src/components/QuillEditorContainer";
import SendingAs, {
  ESendTypes,
} from "src/routes/Ticket/children/MainChat/children/SendMessage/children/SendingAs";
import OpenClose from "src/routes/Ticket/children/MainChat/children/SendMessage/children/OpenClose";
import { getAllStatuses } from "src/services/TicketService/getAllStatuses";
import { checkBrandSignature, isSigEdited, selectFile } from "src/utils/utils";
import { uploadAttachment } from "src/services/Attachment/uploadAttachment";
import { v4 as uuid } from "uuid";
import { getSingleBrandData } from "src/store/slices/globals/globals.slice";
import { useBrandSignature } from "src/hooks/brandSignature/useBrandSignature";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import alertIcon from "src/assets/images/alert.svg";
import { useSyncedState } from "src/hooks/useSyncedState";
function MessageContainer({
  sendAs,
  setSendAs,
  sendType,
  setSendType,
  /*attachmentBatchId, setAttachmentBatchId*/
  attachmentUploadData,
  setAttachmentUploadData,
  selectedFiles,
  setSelectedFiles,
  currentAttachmentBatchNumber,
  setCurrentAttachmentBatchNumber,
  fromValue,
  showError,
  setShowError,
  handleCannotSend,
  isIntegrationFailure,
}: any) {
  const target: any = useRef(null);
  const overlayRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [ticketStatusList, setTicketStatusList]: any = useState({});
  // const [message, setMessage] = useState("" as string);
  const [message, messageRef, setMessage] = useSyncedState("");

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [sigEdited, setSigEdited] = useState(false);
  const scopes = useAppSelector(
    (state) => state.globals.currentUserData?.scopes,
  );
  // the signature isfetched based on the brand email (fromValue)
  const { signatureText } = useBrandSignature(fromValue ?? "");
  // const [sendType, setSendType] = useState(1);

  //created memo to check weather signature can be shown or not
  const canShowSignatureText = useMemo(() => {
    return ESendTypes.Note != sendType;
  }, [sendType]);

  const currentState = useRef({
    sendType,
    signatureText,
  });
  useEffect(() => {
    currentState.current = {
      sendType,
      signatureText,
    };
  }, [sendType, signatureText]);
  useEffect(() => {
    getAllStatuses()
      .then((res) => {
        setTicketStatusList(res.allTicketStatuses);
      })
      .catch((err) => {});
  }, [false]);

  const onClickWindow = useCallback((e: any) => {
    if (e.target !== target.current && !target.current.contains(e.target)) {
      setShowOverlay(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("click", onClickWindow);
    return () => {
      window.removeEventListener("click", onClickWindow);
    };
  }, [onClickWindow]);

  return (
    <div className={`px-2 mb-2 mx-auto w-100`}>
      <div className={`p-2 ${styles.sendMessage}`}>
        <SendingAs
          sendType={sendType}
          setSendType={setSendType}
          selectedTemplate={(templateText: string) => {
            setMessage((msg) => templateText + msg);
          }}
          showES={scopes && scopes.includes("send_message") ? true : false}
        />
        <TinyMCEContainer
          name="ticket_description"
          className={`${styles.message} ${
            sendType === ESendTypes.Note ? styles.active : ""
          } ${showError && message.trim() === "" ? "redBorder" : ""}`}
          // editorClassName={`${styles.editor}`}
          value={messageRef.current}
          onChange={(value) => {
            setMessage(value);
          }}
          autosavePrefix={`HELPDESK_ADD_NEW_TICKET`}
          signatureText={canShowSignatureText ? signatureText : ""}
          setSigEdited={setSigEdited}
          sigEdited={sigEdited}
          uniqueID={uuid()}
          disableAttachment={false}
          attachmentUploadData={attachmentUploadData}
          setAttachmentUploadData={setAttachmentUploadData}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentAttachmentBatchNumber={currentAttachmentBatchNumber}
          setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
          options={{
            placeholder:
              sendType === ESendTypes.Note
                ? // "Use @ to mention your team members"
                  "Enter your message here"
                : "Enter your message here",
            min_height: 135,
            max_height: 200,
          }}
          buttons={
            <div
              className={`d-flex`}
              id="msgContainerBtn"
            >
              {isIntegrationFailure && (
                <div
                  className={`d-flex align-items-center ms-lg-3 ${styles.integrationFail}`}
                >
                  <span className="me-1">
                    <img
                      src={alertIcon}
                      alt="alert"
                      width={12}
                      height={12}
                    />
                  </span>
                  <span>Cannot send</span>
                  <button onClick={handleCannotSend}>See why?</button>
                </div>
              )}
              <Button
                type="submit"
                className={`d-flex my-auto mx-1 ${styles.sendBtn} ${styles["translate-up"]}`}
                disabled={isIntegrationFailure || isFileUploading} // disable the btn when file is uploading or message is empty
              >
                <span className={`my-auto`}>
                  {sendType === ESendTypes.Note ? "Add Note" : "Send"} As{" "}
                  {sendAs}
                </span>
                <span className={`mx-2 my-auto`}></span>
                <img
                  src={sendLogo}
                  className={`my-auto`}
                />
              </Button>
              <OpenClose
                ref={target}
                onClick={(e: any) => {
                  setShowOverlay(!showOverlay);
                }}
                show={showOverlay}
                className={`my-auto mx-2 ${styles.openC} ${
                  showOverlay ? styles.active : ""
                }`}
              />
              <Overlay
                target={target}
                show={showOverlay}
                ref={overlayRef}
                placement="top-end"
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <div
                    {...props}
                    className={`p-2 ${styles.overlay}`}
                  >
                    {Object.entries(ticketStatusList).map(
                      ([index, data]: any, key) => {
                        return (
                          <div
                            key={key}
                            className={`btn d-flex px-1 py-0 ${styles.item} ${
                              sendAs === data.statusName ? styles.active : ""
                            }`}
                            onClick={(e) => {
                              setSendAs(data.statusName);
                            }}
                          >
                            <span className={`${styles.hoverBar}`}></span>
                            <span>
                              {sendType === ESendTypes.Note
                                ? "Add Note"
                                : "Send"}{" "}
                              As {data.statusName}
                            </span>
                          </div>
                        );
                      },
                    )}
                  </div>
                )}
              </Overlay>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default MessageContainer;
