import UserAvatar from "src/components/UserAvatar";
import styles from "./PreviewModal.module.scss";
import useMessagePreview from "./useMessagePreview";
import AxiosImg from "src/components/AxiosImg";
import Loader from "src/components/Loader";
import ParseHTML from "src/components/ParseHTML";
interface Props {
  onHide: () => void;
  messageId: string;
}
const PreviewModal = ({ onHide, messageId }: Props) => {
  const { data, isLoading, isError } = useMessagePreview(messageId);

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.modalHeader}`}
      >
        <div className="d-flex align-items-center">
          {data?.senderImgURL ? (
            <AxiosImg
              className={`${styles.profileImg}`}
              url={data.senderImgURL}
              size={49}
            />
          ) : (
            <UserAvatar
              className={`${styles.profileImg}`}
              name={data?.senderName ?? "NA"}
              size={49}
            />
          )}
          <span className={`px-2 ${styles.userName}`}>
            {data?.senderName ?? "NA"}
          </span>
          <span className={`px-1 ${styles.userText}`}>User</span>
        </div>
        <span
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className={`${styles.modalBody}`}>
        {isLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        ) : isError ? (
          <div className="w-100 d-flex align-items-center justify-content-center text-danger">
            Something went wrong...
          </div>
        ) : (
          <div>
            <h5 className={`d-flex align-items-center ${styles.brandText}`}>
              Drip campaign sent from brand:
              <span className="mx-1 d-block">
                {" "}
                {data?.brand.imageUrl ? (
                  <AxiosImg
                    className={`${styles.BrandProfileImg}`}
                    url={data.brand.imageUrl}
                    size={19}
                  />
                ) : (
                  <UserAvatar
                    className={`${styles.brandProfileImg}`}
                    name={data?.brand.name ?? "NA"}
                    size={19}
                  />
                )}
              </span>
              <span className={`${styles.brandName}`}>
                {data?.brand.name ?? "NA"}
              </span>
            </h5>
            <div className={`${styles.msgWrap}`}>
              <ParseHTML html={data?.html ?? ""} className={styles.messageBox} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewModal;
