import { ChangeEvent, useCallback, useEffect, useState } from "react";
import styles from "./AttributeForm.module.scss";
import DatePicker from "react-datepicker";
import Select, { ActionMeta, SingleValue } from "react-select";
import { useConditionOptions } from "../../../hooks/useConditionOption";
import Loader from "src/components/Loader";
import InputElement from "../Components/InputElement";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { ConditionOptionsResponse } from "src/services/CustomerSegments/getConditionOptions.service";
import GenericListItem from "../Components/ListElement";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { EventData } from "../PipelineModal";
import { generateDateOnly } from "src/utils/dateLibrary";

// Define the type for the option
interface OptionType {
  label: string;
  value: string;
}
export interface AttributeData {
  listOptions?: [];
  eventId: string | null;
  name: SingleValue<OptionType> | null;
  type: string;
  date?: Date | null;
  isError: boolean;
  errorType?: string;
  errMsg?:string;
  isDuplicate: boolean;
  value: any;
  attributeId: string | undefined;
  isUpdated: boolean;
}
interface ListOption {
  id: number;
  value: string;
}
interface Props {
  attributeData: AttributeData[];
  setActiveModal: React.Dispatch<
    React.SetStateAction<"loader" | "success" | "delete" | "modal">
  >;
  setAttributeData: React.Dispatch<React.SetStateAction<AttributeData[]>>;
  setDeleteAttributeIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  customerId: string | number;
  isDataLoading: boolean;
  options: OptionType[];
  typeOptions: any[];
  eventData: EventData[];
  combinedOptions: any[];
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<ConditionOptionsResponse, unknown>>;
  hasNextPage: boolean | undefined;
  findTypeAndTypeKey: (newValue: SingleValue<OptionType>) => {
    type: string;
    typeKey: string | undefined;
  } | null;
  hasEventNextPage: boolean | undefined;
  status: "success" | "loading" | "error";
  handleInfiniteScroll: () => void;
  isFetching: boolean;
}

const customStyles = {
  control: (styles: any, { data }: any) => ({
    ...styles,
    background: "#ffff",
    width: "129px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #707070",
    borderRadius: "2px",
    marginRight: "2px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "8px 0",
    maxHeight: "227px",
    overflowY: "scroll",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "0px",
    color: isFocused ? "#fff" : "#000",
    backgroundColor: isFocused ? "#0B68BB" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "80px",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    wordBreak: "break-all",
  }),

  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any, { data }: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "30px", // Adjust the height if needed
    fontSize: "12px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
    lineHeight: "18px", // Adjust line height
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: "100%",
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 11px/17px Poppins",
  }),
};

const ClearIndicator = (props: any) => {
  const { innerProps } = props;

  return (
    <div
      {...innerProps}
      style={{ padding: "0 8px", cursor: "pointer", color: "#707070" }}
    >
      <span>
        <i className="fa-solid fa-xmark"></i>
      </span>
    </div>
  );
};
const AttributeForm = ({
  hasEventNextPage,
  isFetching,
  handleInfiniteScroll,
  eventData,
  status,
  isDataLoading,
  attributeData,
  setAttributeData,
  setDeleteAttributeIndex,
  setActiveModal,
  options,
  combinedOptions,
  fetchNextPage,
  hasNextPage,
  findTypeAndTypeKey,
}: Props) => {
  const [listOptions, setListOptions] = useState<
    { name: string | undefined; value: { id: number; value: string }[] }[]
  >([]);

  useEffect(() => {
    if (listOptions.length === 0) {
      const newList = attributeData.flatMap((data) => {
        if (Array.isArray(data.listOptions)) {
          // If data.listOptions is an array, include the name and map over the listOptions
          return {
            name: data.name?.label,
            value: data.listOptions.map((opt, index) => ({
              id: index,
              value: opt,
            })),
          };
        } else {
          // If data.listOptions is not an array, return an empty array
          return [];
        }
      });

      setListOptions((prevOptions) => [...prevOptions, ...newList]);
    }
  }, [attributeData, listOptions]);

  // Handle dropdown change
  const handleDropdownChange = (
    newValue: SingleValue<OptionType>,
    index: number,
  ) => {
    // Check for duplicate name
    const isDuplicate = attributeData.some(
      (data, i) => data.name?.label === newValue?.label && i !== index,
    );
    const selectedAttribute = combinedOptions.find(
      (data) => data.name === newValue?.label,
    );
    const attributeId = selectedAttribute?.attributeId;
    const newType = findTypeAndTypeKey(newValue);
    const updatedAttributeData = attributeData.map((data, i) => {
      if (i === index) {
        return isDuplicate
          ? {
              ...data,
              name: newValue,
              attributeId: attributeId,
              type: newType?.typeKey || "",
              isError: true,
              value: "",
              isDuplicate: true,
            }
          : {
              ...data,
              name: newValue,
              attributeId: attributeId,
              type: newType?.typeKey || "",
              value: "",
              isError: false,
              isDuplicate: false,
              isUpdated: true,
            };
      }
      return data;
    });

    setAttributeData(updatedAttributeData);
  };
  function getValuesByName(name: string | undefined, listOptions: any) {
    // Find the object in listOptions that matches the given name
    const matchedItem = listOptions.find((item: any) => item.name === name);

    // If a matching item is found, return the array of objects with id and value; otherwise, return an empty array
    return matchedItem ? matchedItem.value : [];
  }

  const onDateChange = (newDate: Date | null, index: number) => {
    const currentDate = new Date();

    // Remove the time component for accurate date-only comparison
    currentDate.setHours(0, 0, 0, 0);
    if (newDate !== null) {
      newDate.setHours(0, 0, 0, 0);
    }

    if (newDate === null || newDate < currentDate) {
      const updatedAttributeData = attributeData.map(
        (data: AttributeData, i) =>
          i === index
            ? {
                ...data,
                date: null,
                value: "",
                isError: true,
                errorType: "date",
              errMsg:"Invalid date! please select a valid date to continue"
              }
            : data,
      );
      setAttributeData(updatedAttributeData);
    } else {
      const updatedAttributeData = attributeData.map(
        (data: AttributeData, i) =>
          i === index
            ? {
                ...data,
                date: newDate,
                value: generateDateOnly(newDate),
                isUpdated: true,
                isError: false,
              }
            : data,
      );
      setAttributeData(updatedAttributeData);
    }
  };
 const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  type: string,
) => {
  let value=e.target.value;
  const updatedAttributeData = attributeData.map((data: AttributeData, i) =>
    i === index
      ? { ...data, value, isUpdated: true, isError: false }
      : data,
  );

  setAttributeData(updatedAttributeData);
};



  const handleDropdownChangeBoolean = (
    newValue: SingleValue<OptionType>,
    index: number,
  ) => {
    const updatedAttributeData = attributeData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          value: newValue?.value,
          isError: false,
          isDuplicate: false,
          isUpdated: true,
        };
      }
      return data;
    });

    setAttributeData(updatedAttributeData);
  };
  const handleListChange = (listOptions: ListOption[], index: number) => {
    const updatedListOptions = listOptions.map((opt) => {
      return opt.value;
    });
    const updatedAttributeData = attributeData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          value: updatedListOptions || [],
          isError: false,
          isDuplicate: false,
          isUpdated: true,
        };
      }
      return data;
    });

    setAttributeData(updatedAttributeData);
  };

  const handleAdd = useCallback(() => {
    const newAttributeData: AttributeData = {
      eventId: null,
      name: null,
      type: "",
      date: null,
      isError: false,
      isDuplicate: false,
      attributeId: "",
      value: "",
      isUpdated: false,
    };
    const updatedAttributeData = [...(attributeData || []), newAttributeData];
    setAttributeData(updatedAttributeData);
  }, [attributeData, setAttributeData]);
  return (
    <div>
      <div className="d-flex w-auto">
        <div className={styles.nameLabel}>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeName`}
          >
            Name
          </label>
        </div>
        <div className={styles.typeLabel}>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeName`}
          >
            Type
          </label>
        </div>
        <div>
          <label
            className={`mb-1 ${styles.timelineLabel}`}
            htmlFor={`attributeName`}
          >
            Value
          </label>
        </div>
      </div>
      <InfiniteScroll
        className={`${styles.infiniteScrollDiv}`}
        hasMoreBottom={hasEventNextPage}
        loadMoreFromBottom={handleInfiniteScroll}
        infiniteLoader={
          isFetching && eventData?.length === 0 ? <Loader /> : undefined
        }
        errorMessage={
          status === "error" && eventData?.length === 0 ? (
            <div className={`d-flex flex-column m-auto`}>No Data Found</div>
          ) : (
            ""
          )
        }
      >
        {isDataLoading && <Loader />}
        {attributeData.map((data, index) => (
          <div
            className={`d-flex pb-2 mb-2 position-relative  ${
              true ? styles.borderBottom : ""
            }`}
            key={index}
          >
            <div className="d-flex flex-column ">
              <div className="d-flex">
                <div className="me-3">
                  <Select
                    value={data.isDuplicate ? null : data.name}
                    onChange={(opt) => {
                      handleDropdownChange(opt, index);
                    }}
                    options={options}
                    placeholder="Add name...  "
                    isSearchable={false}
                    styles={customStyles}
                    isClearable={true} // Enables the clear indicator (X icon)
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: null,
                      ClearIndicator, // Use custom ClearIndicator
                    }}
                    onMenuScrollToBottom={() => {
                      if (hasNextPage) {
                        fetchNextPage();
                      }
                    }}
                  />
                </div>
                <div className={`me-3 opacity-50`}>
                  <Select
                    value={{ label: data.type, value: data.type }}
                    // onChange={(opt) => {
                    //   handleDropdownChange(opt, index)
                    // }}
                    // options={typeKey}
                    placeholder="Select"
                    isSearchable={false}
                    styles={customStyles}
                    isClearable={true} // Enables the clear indicator (X icon)
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: null,
                      ClearIndicator: () => null, // Use custom ClearIndicator
                    }}
                    isDisabled={true}
                  />
                </div>
                <div className="me-3">
                  {data.type === "date" ? (
                    <DatePicker
                      selected={data.date} // The selected date in the date picker
                      onChange={(date: Date | null) =>
                        onDateChange(date, index)
                      } // Updates the selected date
                      placeholderText="MM/DD/YYYY" // Placeholder when no date is selected
                      dateFormat="MM/dd/yyyy" // Date format to display
                      className={`${styles.datePicker}`} // Custom class for the input field
                    />
                  ) : data.type === "text" ? (
                    <InputElement
                      type={data.type}
                      value={data.value}
                      onChange={(e) => {
                        handleInputChange(e, index, data.type,);
                      }}
                    />
                  ) : data.type === "number" || data.type === "decimal" ? (
                    <InputElement
                      type={"number"}
                          value={data.value.includes("-")?"":data.value}
                      onChange={(e) => {
                        handleInputChange(e, index, data.type,);
                      }}
                    />
                  ) : data.type === "boolean" ? (
                    <Select
                      value={{
                        label: data.value.toString(),
                        value: data.value,
                      }}
                      onChange={(opt) => {
                        handleDropdownChangeBoolean(opt, index);
                      }}
                      options={[
                        { label: "True", value: true },
                        { label: "False", value: false },
                      ]}
                      placeholder="Select"
                      isSearchable={false}
                      styles={customStyles}
                      isClearable={true} // Enables the clear indicator (X icon)
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                        ClearIndicator: () => null, // Use custom ClearIndicator
                      }}
                    />
                  ) : data.type === "list" ? (
                    <GenericListItem
                      listOptions={getValuesByName(
                        data.name?.label,
                        listOptions,
                      )}
                      onOptionsChange={(listOptions) => {
                        handleListChange(listOptions, index);
                      }}
                      status={status}
                    />
                  ) : null}
                </div>
                {attributeData.length > 1 && index !== 0 && (
                  <div className={`${styles.deleteWrapper}`}>
                    <span
                      className={`${styles.deleteBtn}`}
                      onClick={() => {
                        setActiveModal("loader");
                        setTimeout(() => {
                          setActiveModal("delete");
                        }, 1000);
                        setDeleteAttributeIndex(index);
                      }}
                    >
                      {/* <div className={`${data.type==="" ? styles.emptyX :(data.type === "list" && listOptions.length >1) ? styles.listX : ""} `}><i className="fa-solid fa-xmark"></i></div> */}
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                )}
              </div>
              {data.isError && (
                <div className={styles.errorText}>
                  {data.isDuplicate
                    ? data.name?.label !== undefined &&
                      `Stage ${data.name?.label} is already given. Please add another`
                    : data.errorType === "date"
                      ? "Invalid date! please select a valid date to continue"
                      : data.errorType === "number"
                        ? "Invalid number! please add a valid number to continue"
                        : "*Please add the above details to move forward"}
                </div>
              )}
            </div>
          </div>
        ))}
      </InfiniteScroll>

      <div
        className="d-flex align-items-center mb-2"
        onClick={handleAdd}
      >
        <span className={`cursor-pointer ${styles.addBtn}`}>
          <i className="fa-solid fa-plus"></i>
        </span>
        <span className={`ps-2 cursor-pointer ${styles.addText}`}>
          Add More
        </span>
      </div>
    </div>
  );
};

export default AttributeForm;
