import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getMultipleSegmentValuesService, {
  AllMultipleSegmentValues,
} from "src/services/CustomerSegments/NewMessage/getMultipleSegmentValues.service";

const LIMIT = 5;

type PageData = AllMultipleSegmentValues & {
  page: number;
};

const useFetchSegmentValues = (segmentIds: string[], columns?: string[]) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    status,
    isFetching,
    refetch,
  } = useInfiniteQuery<PageData>(
    ["getMultipleSegmentValuesService", segmentIds, columns],
    {
      queryFn: async ({ pageParam = 1 }) => {
        const res = await getMultipleSegmentValuesService({
          limit: LIMIT,
          start: (pageParam - 1) * LIMIT,
          segmentIds,
          columnsRequired: pageParam === 1 ? true : false,
          columns: columns ?? [],
        });

        return { ...res, page: pageParam } as PageData;
      },
      getNextPageParam: (lastPage) => {
        const { page } = lastPage;
        const totalPages = Math.ceil(lastPage.totalSegmentValues / LIMIT);
        return page < totalPages ? page + 1 : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const { page } = firstPage;
        return page > 1 ? page - 1 : undefined;
      },
      keepPreviousData: true,
      // cacheTime: 60000,
      // staleTime: 60000,
      enabled: segmentIds.length !== 0,
    },
  );

  const totalSegmentValues = useMemo(() => {
    return data?.pages[data.pages.length - 1]?.totalSegmentValues;
  }, [data?.pages]);

  const totalPages = useMemo(() => {
    return Math.ceil((totalSegmentValues ?? 0) / LIMIT);
  }, [totalSegmentValues]);

  const { segmentValues, segmentValueIds } = useMemo(() => {
    if (!data) {
      return { segmentValues: {}, segmentValueIds: [] };
    }

    const currentPageData = data.pages.find((p) => p.page === currentPage);

    return {
      segmentValues: currentPageData?.segmentValues ?? {},
      segmentValueIds: currentPageData?.segmentValueIds ?? [],
    };
  }, [currentPage, data]);

  const { allColumnKeys, allColumns } = useMemo(() => {
    if (!data) {
      return { allColumns: {}, allColumnKeys: [] };
    }

    const currentPageData = data.pages[0];

    return {
      allColumns: currentPageData?.allColumns ?? {},
      allColumnKeys: currentPageData?.allColumnKeys ?? [],
    };
  }, [data]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > currentPage) {
        if (!data?.pages.find((page) => page.page === newPage)) {
          fetchNextPage({ pageParam: newPage });
        }
      } else if (newPage < currentPage) {
        if (!data?.pages.find((page) => page.page === newPage)) {
          fetchPreviousPage({ pageParam: newPage });
        }
      }
      setCurrentPage(newPage);
    },
    [currentPage, data?.pages, fetchNextPage, fetchPreviousPage],
  );

  const segmentsFetchStatus: AJAXSTATUS = useMemo(() => {
    return isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected";
  }, [isFetching, status]);

  return {
    totalPages,
    currentPage,
    totalSegmentValues,
    setCurrentPage: handlePageChange,
    segmentValues,
    segmentValueIds,
    allColumnKeys,
    allColumns,
    segmentsFetchStatus,
    refetch,
  };
};

export default useFetchSegmentValues;
