import { createContext, useCallback, useContext, useState } from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
/**
 * Enumeration for the different upload csv modal steps.
 * These are the keys used to map steps to their corresponding components.
 */
export enum EUploadSteps {
  ConfigureType = "configureType",
  UploadCSV = "uploadCSV",
  MapAttributes = "mapAttributes",
  Import = "import",
  Completed = "completed",
}
/**
 * Default steps list for upload new csv modal
 */
export const stepsList = [
  {
    key: EUploadSteps.ConfigureType,
    name: "Configure type",
    index: 0,
  },
  {
    key: EUploadSteps.UploadCSV,
    name: "Upload CSV",
    index: 1,
  },
  {
    key: EUploadSteps.MapAttributes,
    name: "Map attributes",
    index: 2,
  },
  {
    key: EUploadSteps.Import,
    name: "Import",
    index: 3,
  },
  {
    key: EUploadSteps.Completed,
    name: "Successful",
    index: 4,
  },
];
export type EConfigureCsvActiveStep =
  | "configureType"
  | "uploadCSV"
  | "mapAttributes"
  | "import"
  | "completed";
export interface UploadStepsType {
  /**
   * Key representing the step in the upload csv flow.
   */
  key: EUploadSteps;

  /**
   * Name of the step, used for display purposes.
   */
  name: string;
  /**
   * Index of the step, used for display purposes.
   */
  index: number;
}

export interface ErrorDescription {
  description: string;
  info: string;
}
/**
 * The data for the corresponding row in the errors table.
 * - `type` can be `null`, `"string"`, `"description"` - for the field which have description.
 * - `value` can be `null`, a `string`, or an `ErrorDescription`.
 */
export interface ErrorRow {
  tableHeadingId: string;
  type: null | "string" | "description";
  value: null | string | ErrorDescription;
}

export interface IError {
  /** The unique identifier for the order. */
  errorId: string;
  rowData: { [tableHeadingId: string]: ErrorRow };
}

export type ErrorTypes = null | "error" | "warning";

/**
 * Represents a collection of errors found in an uploaded CSV file.
 */
export interface UploadedCSVErrors {
  attachmentId?: string;
  /**
   * Reperesents Heading for the errors
   * Example - "Column Mismatch", "Your impot has 6 errors"
   */
  heading?: null | string;
  /**
   * Subtitle or additional text for the error summary.
   * Example - "The columns in the uploaded CSV file do not match..."
   * Will not present incase of errors table
   */
  subText?: string;
  /**
   * The array of table headings to be displayed in the errors table.
   */
  tableHeadings?: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
    }
  >;
  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: string[];
  /**
   * The list of all errors based on the uploaded csv.
   */
  errors?: Record<string, IError>;
  /**
   * List of errorIds
   */
  errorIds?: Array<string>;
  /** This is used to check if the next button is clickable. */
  errorType?: ErrorTypes;
  /**
   * A link to download the CSV file with errors.
   */
  downloadErrorCSVLink?: string;
  /**
   * The total number of errors found in the CSV file.
   */
  totalErrorCount?: number;
}
export interface CSVTableVarientImage {
  imageId: string;
  imageURL: string;
  name: string;
}

export interface CustomerRow {
  type?: null | "varientImage";
  value: null | CSVTableVarientImage | string;
}

export interface CustomerData {
  customerId: string;
  rowData: CustomerRow[];
}

export interface CSVTableMappedData {
  /**
   * A record of heading objects for the table, each containing a heading id and heading name mapped to heading id.
   */
  tableHeadings: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
      /**
       * This is needed for the map attributes feature.
       * Here after changing the heading with anutoer one and on submit API the changedTableHeadingId will be mapped to tableHeadingId.
       */
      changedTableHeadingId?: null | string;
    }
  >;

  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: Array<string>;
  /**
   * Array of all the heading ids.
   */
  updatedTableHeadingIds?: Array<string>;

  /**
   * An object representing the variants in the table.
   * The `key` is the variant ID, and the value is an object where keys are the column headings and values are the cell data.
   * This is read only for map attributes and import step.
   * Only image will added or deleted in import step.
   */
  customers: {
    [customerId: string]: CustomerData;
  };

  /** An array of all variant IDs. */
  customerIds: Array<string>;
}
export interface MappedAttributeError {
  tableHeadingId: string;
  errorId: string;
  errorDescription: string;
}

/**
 * Represents the attribute mapping to change the column headings while configuring a CSV upload.
 *
 */
export interface ConfigureCSVTableMappedAttributes extends CSVTableMappedData {
  /**
   * The total number of rows, which is the same as the total number of variants.
   */
  totalCustomers: number;
  mapAttributeErrors: Record<string, MappedAttributeError>;
}
/**
 * Interface representing an (uploaded or imported) and mapped CSV table, extending the uploaded CSV table data.
 */
export interface ImportedCSVTable extends CSVTableMappedData {
  /** The name of the imported file. */
  fileName: string;
  /** The total number of products in the table. */
  totalProducts: number;
  /** The total number of rows, which is the same as the total number of variants. */
  totalVarients: number;
  /** The total calculated SKU (Stock Keeping Unit). */
  totalSKU: number;
}

export interface ConfigureCSVUploadDetails {
  /**
   * The current active step in the upload process.
   * Can be one of the following: "configureType", "uploadCSV", "mapAttributes", "import", or "completed".
   */
  activeStep: EConfigureCsvActiveStep;
  /**
   * Required details for the configureType step.
   */
  configureType: null | "users" | "leads";

  /**
   * Optional details for the uploadCSV step.
   */
  uploadCSV?: null | {
    /**
     * A link to a sample CSV template.
     */
    sampleCSVTemplateLink: string;
    /**
     * An optional link to the uploaded file.
     * Can be null.
     */
    uploadedFileLink?: null | string;
    /**
     * Uploaded file name.
     */
    uploadedFileName?: null | string;
    /**
     * Optional errors found during the upload.
     * Can be null.
     */
    uploadErrors?: null | UploadedCSVErrors;
    /**
     * An optional file uploaded by the user.
     * Required for the uploadCSV page but used only on the frontend.
     * Can be null.
     */
    uploadedFile?: null | File;
    attachmentId?: null | string;
  };

  /**
   * For mapping the attributes, we can change the headimg of the columns among all the processed headings.
   * It can be null or undefined initially.
   */
  mapAttributes?: null | ConfigureCSVTableMappedAttributes;

  /**
   * For the import step of the CSV upload process.
   * It can be null or undefined initially.
   */
  importedCSV?: null | ImportedCSVTable;
}
export interface UploadNewCsvState {
  state: ConfigureCSVUploadDetails;
  originalData?: ConfigureCSVUploadDetails;
  updateState: (data: Partial<ConfigureCSVUploadDetails>) => void;
  isLoading: boolean;
  completedSteps: string[];
  setCompletedSteps: React.Dispatch<React.SetStateAction<string[]>>;
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  validateStep: ({
    stepType,
    state,
  }: {
    stepType: EUploadSteps;
    state?: ConfigureCSVUploadDetails;
  }) => boolean;
}

const initialState: ConfigureCSVUploadDetails = {
  activeStep: "configureType",
  configureType: "users",
  importedCSV: null,
  mapAttributes: null,
  uploadCSV: null,
};

const initialContext: UploadNewCsvState = {
  state: initialState,
  updateState: () => {},
  completedSteps: [],
  setCompletedSteps: () => {},
  showErrors: false,
  setShowErrors: () => {},
  validateStep: () => false,
  isLoading: false,
};

const UploadNewCsvContext = createContext<UploadNewCsvState>(initialContext);

export const useUploadNewCsvModalContext = () => {
  const context = useContext(UploadNewCsvContext);

  if (!context) {
    throw new Error(
      "useUploadNewCsvModalContext must be used within a UploadNewCsvProvider",
    );
  }

  return context;
};

export const useUploadNewCsvModalProvider = (integrationId: string) => {
  const [state, setState] = useState<ConfigureCSVUploadDetails>({
    ...initialState,
  });

  const [completedSteps, setCompletedSteps] = useState<string[]>([
    ...initialContext.completedSteps,
  ]);

  const [showErrors, setShowErrors] = useState(false);

  // const { data, isLoading } = useQuery<ConfigureCSVUploadDetails, Error>({
  //   queryKey: ["getConfigureCSVUploadDetails", integrationId],
  //   queryFn: () => {
  //     if (integrationId) {
  //       return getConfigureCSVUploadDetails({ integrationId });
  //     }
  //     throw new Error("Integration ID is missing");
  //   },
  //   onSuccess: (res) => {
  //     const activeStep = stepsList.find((step) => step.key == res?.activeStep);
  //     if (activeStep) {
  //       const completedStepKeys = stepsList
  //         .filter((step) => step.index < activeStep.index) // Filter steps before the active step
  //         .map((step) => step.key); // Get the keys of the completed steps
  //       setCompletedSteps(completedStepKeys);
  //     }
  //   },
  // });

  // useEffect(() => {
  //   if (data) {
  //     setState(data);
  //   }
  // }, [data]);

  const updateState = useCallback(
    (data: Partial<ConfigureCSVUploadDetails>) => {
      setState((currentData) => ({
        ...currentData,
        ...data,
      }));
    },
    [],
  );
  // Validate steps
  const validateStep = useCallback(
    ({
      stepType,
      state,
    }: {
      stepType: EUploadSteps;
      state?: ConfigureCSVUploadDetails;
    }) => {
      if (state) {
        switch (stepType) {
          case EUploadSteps.ConfigureType: {
            // Check if all questions are answered
            const isValid = state.configureType ? true : false;
            return isValid;
          }
          case EUploadSteps.UploadCSV: {
            let isValid = true;
            if (
              (!state?.uploadCSV?.attachmentId &&
                !state?.uploadCSV?.uploadedFileLink) ||
              (state?.uploadCSV?.uploadErrors?.errorIds &&
                state?.uploadCSV?.uploadErrors?.errorIds?.length > 0 &&
                state?.uploadCSV?.uploadErrors?.errorType !== "warning")
            ) {
              isValid = false;
              pushTheToast({
                position: "top-right",
                text: "Your import has errors!",
                type: "danger",
              });
            }
            return isValid;
          }
          case EUploadSteps.MapAttributes: {
            let isValid = true;

            // Detect duplicates in table heading IDs
            const duplicates =
              state.mapAttributes?.updatedTableHeadingIds?.reduce(
                (acc, id) => {
                  acc[id] = (acc[id] || 0) + 1;
                  return acc;
                },
                {} as Record<string, number>,
              ) ?? {}; // Provide a fallback empty object if undefined

            const isDuplicates = Object.keys(duplicates).some(
              (id) => duplicates[id] > 1,
            );

            // Check for errors and duplicates
            if (
              (state.mapAttributes &&
                Object.keys(state.mapAttributes?.mapAttributeErrors ?? {})
                  .length > 0) ||
              isDuplicates
            ) {
              isValid = false;
              pushTheToast({
                position: "top-right",
                text: "There are errors in map attributes!",
                type: "danger",
              });
            }

            return isValid;
          }
          default:
            // return true for other steps
            return true;
        }
      }
      return false;
    },
    [state],
  );

  return {
    state,
    completedSteps,
    setCompletedSteps,
    showErrors,
    setShowErrors,
    originalData: state,
    updateState,
    validateStep,
    isLoading: false,
    UploadNewCsvProvider: UploadNewCsvContext.Provider,
  };
};
