import { useCallback, useEffect, useState } from "react";

import Loader from "src/components/Loader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import AddModal from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/AddModal";
import EmailAddress from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/EmailAddress/EmailAddress";
import CurrentHost from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailOption/CurrentHostOption/CurrentHost";
import OutgoingMailSetting from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailSetting/OutgoingMailSetting";
import EmailProcess from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/EmailProcess/EmailProcess";
import IncomingMailSetting from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/VerfiyEmailProcess/Children/IncomingMailSetting/IncomingMailSetting";
import DomainVerification from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/DomainVerification/DomainVerification";
import EmailForwarding from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/EmailForwarding/EmailForwarding";

import useDomainIntegration from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/DomainIntegration/useDomainIntegration";
import useVerifyEmailProcess from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/VerfiyEmailProcess/useVerifyEmailProcess";
import useToDoList from "../../../hooks/useToDoList";

import styles from "../../ListStep.module.scss";
import useConnectEmailProcess from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/AddEmail/Children/AddModal/ConnectEmailProcess/useConnectEmailProcess";
import ConnectAccount from "src/routes/Setting/children/MainSetting/children/EmailIntegration/children/ConnectAccount/ConnectAccount";
import { useAppSelector } from "src/store/store";

interface Props {
  integrationId?: string;
  hideModal: () => void;
  stepNumber: number;
}

const Buttons = ({
  handleNext,
  handlePrev,
  disableNext = false,
}: {
  handleNext?: () => void;
  handlePrev?: () => void;
  disableNext?: boolean;
}) => {
  return (
    <div
      className={`mt-3 d-flex justify-content-between align-items-center ${styles.pageWrap}`}
    >
      {handlePrev && (
        <button
          className={`py-2 px-md-3 ${styles.prevBtn}`}
          onClick={handlePrev}
          id="previousBtn"
        >
          <span className="pe-1">
            <i className="fa-solid fa-arrow-left"></i>
          </span>
          Previous
        </button>
      )}

      {handleNext && (
        <button
          className={`py-2 px-md-3 ${styles.nextBtn}`}
          disabled={disableNext}
          onClick={handleNext}
          id="nextBtn"
        >
          Next{" "}
          <span className="ps-1">
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      )}
    </div>
  );
};

const SelectEmailType = ({
  hideModal,
  changeStepHandler,
}: {
  hideModal: Props["hideModal"];
  changeStepHandler: (val: number) => void;
}) => {
  return (
    <div>
      <AddModal
        isEmailSetup={true}
        onHide={hideModal}
        navCallback={() => changeStepHandler(1)}
      />
    </div>
  );
};
const Step1 = ({
  changeStepHandler,
  integrationId,
  updateIntegrationId,
  hideModal,
}: {
  changeStepHandler: (val: number) => void;
  integrationId?: string;
  updateIntegrationId: (id: string) => void;
  hideModal: Props["hideModal"];
}) => {
  /**
   * Callback for handling integration not found navigation
   */
  const navigateCallBack = useCallback(() => {
    pushTheToast({
      position: "top-right",
      text: "Integration not found!",
      type: "danger",
    });
    hideModal();
  }, [hideModal]);

  const { loader, selectedUser } = useConnectEmailProcess({
    integrationId,
    navigateCallBack,
  });

  // Get the setup data update function from provider hook
  const { initializeEmailSetup } = useToDoList();

  const handleNextStep = useCallback(
    (id?: string) => {
      if (id) {
        updateIntegrationId(id);
        initializeEmailSetup(id);
        changeStepHandler(2);
      } else {
        changeStepHandler(2);
      }
    },
    [changeStepHandler, initializeEmailSetup, updateIntegrationId],
  );

  // If Loading integration data, show loader
  if (loader === "pending") {
    return <Loader />;
  }

  return (
    <ConnectAccount
      defaultEmail={selectedUser.emailEnteredByUser}
      defaultName={selectedUser.userName}
      isIntegrated={selectedUser.emailIntegrationId !== -1}
      navigateCallback={handleNextStep}
      isEmailTodo={true}
    />
  );
};

const Step2 = ({
  changeStepHandler,
  integrationId,
  hideModal,
}: {
  changeStepHandler: (val: number) => void;
  integrationId?: string;
  hideModal: Props["hideModal"];
}) => {
  /**
   * Callback for handling integration not found navigation
   */
  const navigateCallback = useCallback(() => {
    pushTheToast({
      position: "top-right",
      text: "Integration not found!",
      type: "danger",
    });
    hideModal();
  }, [hideModal]);

  const [hideElements, setHideElements] = useState(false);

  const { loader, selectedType, selectedUser, setSelectedType } =
    useVerifyEmailProcess(integrationId, navigateCallback);

  const hideElementsHandler = useCallback((val: boolean) => {
    setHideElements(val);
  }, []);

  // Get the setup data update function from provider hook
  const { updateEmailStep } = useToDoList();

  /**
   * Effect for updating the setup data when email is verified in Step 2
   */
  useEffect(() => {
    updateEmailStep({ isEmailVerified: selectedUser.isEmailVerified });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser.isEmailVerified]);

  // If Loading integration data, show loader
  if (loader === "pending") {
    return <Loader />;
  }

  return (
    <>
      {/* Radio buttons */}
      {!hideElements && (
        <IncomingMailSetting
          selectedType={selectedType}
          preferred={
            selectedUser.incomingMailSettings?.preferredReceiveType ??
            "currentHost"
          }
          onSelect={setSelectedType}
          isEmailSetup={true}
        />
      )}

      {/* Verification UI */}
      {selectedType === "currentHost" ? (
        <EmailForwarding
          isEmailToDo={true}
          hideElements={hideElementsHandler}
        />
      ) : selectedType === "IMAP" ? (
        <EmailAddress
          type="incoming"
          isStepDone={selectedUser.isEmailVerified}
        />
      ) : (
        <></>
      )}

      {/* Next and previous buttons */}
      {!hideElements && (
        <Buttons
          handleNext={() => changeStepHandler(3)}
          handlePrev={() => changeStepHandler(1)}
          disableNext={!selectedUser.isEmailVerified}
        />
      )}
    </>
  );
};

const Step3 = ({
  changeStepHandler,
  integrationId,
  hideModal,
}: {
  changeStepHandler: (val: number) => void;
  integrationId?: string;
  hideModal: Props["hideModal"];
}) => {
  /**
   * Callback for handling integration navigation
   */
  const navigateCallback = useCallback(
    (val: number) => {
      // If integration not found close modal and show error
      if (val === -1) {
        pushTheToast({
          position: "top-right",
          text: "Integration not found!",
          type: "danger",
        });
        hideModal();
      } else {
        changeStepHandler(val);
      }
    },
    [changeStepHandler, hideModal],
  );

  const [hideElements, setHideElements] = useState(false);

  const hideElementsHandler = useCallback((val: boolean) => {
    setHideElements(val);
  }, []);

  const {
    loader,
    checkingAuth,
    disableNextButton,
    handleSelect,
    selectedType,
    selectedUser,
  } = useDomainIntegration(integrationId, navigateCallback);

  // Get the setup data update function from provider hook
  const { updateEmailStep } = useToDoList();

  /**
   * Effect for updating the setup data when domain is verified in Step 3
   */
  useEffect(() => {
    updateEmailStep({ isDomainVerified: selectedUser.isDomainVerified });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser.isDomainVerified]);

  if (loader === "pending") {
    return <Loader />;
  }

  return (
    <>
      {/* Radio buttons */}
      {!hideElements && (
        <OutgoingMailSetting
          selectedType={selectedType}
          preferred={
            selectedUser.outgoingMailSettings?.preferredReceiveType ??
            "currentHostWithDNS"
          }
          onSelect={handleSelect}
          isEmailSetup={true}
        />
      )}

      {/* Verification UI */}
      {checkingAuth ? (
        <Loader />
      ) : (
        <>
          {selectedType &&
          (selectedType === "currentHostWithDNS" ||
            selectedType === "gmail") ? (
            <DomainVerification
              isGmail={selectedType === "gmail"}
              hideElements={hideElementsHandler}
              isEmailToDo={true}
            />
          ) : selectedType === "SMTP" ? (
            <EmailAddress
              type="outgoing"
              isStepDone={selectedUser.isDomainVerified}
            />
          ) : selectedType === "currentHost" ? (
            <CurrentHost />
          ) : (
            <></>
          )}
        </>
      )}

      {/* Next and previous buttons */}
      {!hideElements && (
        <Buttons
          handlePrev={() => changeStepHandler(2)}
          disableNext={disableNextButton}
        />
      )}
    </>
  );
};

const EmailToDo = ({ hideModal, stepNumber, integrationId: id }: Props) => {
  const [activeStep, setActiveStep] = useState(stepNumber);
  const [integrationId, setIntegrationId] = useState(id);

  /**
   * Handler for changing steps
   */
  const changeStepHandler = useCallback((val: number) => {
    if (val < 0 || val > 3) {
      return;
    }
    setActiveStep(val);
  }, []);

  const { isEmailVerified, isDomainVerified, isEmailAdded } = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );

  return (
    <div>
      {/* Select Email Type Step  */}
      {activeStep === 0 && (
        <SelectEmailType
          hideModal={hideModal}
          changeStepHandler={changeStepHandler}
        />
      )}

      {activeStep > 0 && (
        <div>
          {/* Process Steps and Close button */}
          <div className="d-flex align-items-center">
            <EmailProcess
              currentStep={activeStep}
              showDelete={false}
              isDomainVerified={isDomainVerified}
              isEmailAdded={isEmailAdded}
              isEmailVerified={isEmailVerified}
            />
            <span
              role="button"
              onClick={hideModal}
              id="emailModalClose"
            >
              <svg
                id="Component_163_1"
                data-name="Component 163 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 20 20"
              >
                <circle
                  id="Ellipse_844"
                  data-name="Ellipse 844"
                  cx="10"
                  cy="10"
                  r="10"
                  fill="#707070"
                  opacity="0"
                />
                <path
                  id="Icon_ionic-ios-close"
                  data-name="Icon ionic-ios-close"
                  d="M16.466,15.476l2.992-2.992a.7.7,0,0,0-.991-.991l-2.992,2.992-2.992-2.992a.7.7,0,1,0-.991.991l2.992,2.992-2.992,2.992a.7.7,0,1,0,.991.991l2.992-2.992,2.992,2.992a.7.7,0,1,0,.991-.991Z"
                  transform="translate(-5.559 -5.562)"
                  fill="#707070"
                />
              </svg>
            </span>
          </div>

          {activeStep === 1 && (
            <Step1
              changeStepHandler={changeStepHandler}
              integrationId={integrationId}
              hideModal={hideModal}
              updateIntegrationId={setIntegrationId}
            />
          )}

          {/* Incoming Mail Settings Step */}
          {activeStep === 2 && (
            <Step2
              changeStepHandler={changeStepHandler}
              integrationId={integrationId}
              hideModal={hideModal}
            />
          )}

          {/* Outgoing Mail Settings Step */}
          {activeStep === 3 && (
            <Step3
              changeStepHandler={changeStepHandler}
              integrationId={integrationId}
              hideModal={hideModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EmailToDo;
