/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { JSONTemplate } from "state/types/types";
import { ECampaignChannel } from "./createCampaignV2.service";

interface GetCampaignById {
  campaignId: number;
}

export interface EmailTemplateData {
  html: string;
  body?: string;
  heading?: string;
  logoId?: string;
  logoUrl?: string;
  design?: JSONTemplate;
}

export interface CampaignEmail {
  id: number;
  description: string;
  heading: string;
  triggerAfter: number;
  channel: ECampaignChannel;
  emailTemplateData: EmailTemplateData;
  status: "loading" | "completed";
}

export enum ECampaignStatus {
  Published = "published",
  Draft = "draft",
  Paused = "paused",
}

export interface CampaignSegmentKeyAttribute {
  key: string;
  value: string | null;
  condition: string;
  columnKey?: string | null;
}

export interface CampaignData {
  id: number;
  name: string;
  emails: CampaignEmail[];
  trigger: {
    id: string | number;
    value: string;
  };
  brand?: {
    id: number | string;
    name: string;
    email: string;
  };
  userSegment?: {
    id: number | string;
    name: string;
    keyAttributes?: Array<CampaignSegmentKeyAttribute>;
  };
  status: ECampaignStatus;
}

export const getCampaignById = async (params: GetCampaignById) => {
  const { data: res } = await axiosJSON.post("/api/campaign/get", params);
  if (res.err) {
    throw res.msg;
  }

  return res.data as CampaignData;
};
