import { axiosJSON } from "src/globals/axiosEndPoints";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";

export interface GetCampaignReportUsersParams {
  start: number;
  limit: number;
  segmentId: string;
}

export interface ICampaignReportUser {
  id: string | number;
  name: string;
  timelineData: TimelineData;
  customerId: string;
}

export interface GetCampaignReportUsersResponse {
  err: boolean;
  data: Array<ICampaignReportUser>;
  metaData: {
    totalCount: number;
  };
}

async function getCampaignReportUsersService(
  params: GetCampaignReportUsersParams,
): Promise<GetCampaignReportUsersResponse> {
  const { data: res } = await axiosJSON.post(
    `/api/reports/segment/getSegmentUsers`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res as GetCampaignReportUsersResponse;
}

export default getCampaignReportUsersService;
