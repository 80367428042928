import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { v4 as uuid } from "uuid";
import {
  AntiRefundStrategiesReason,
  AntiRefundStrategiesState,
  RefundScenario,
} from "./antiRefundStrategies.slice";
import {
  fetchAntiRefundStrategies,
  fetchRefundRequestFollowUpQuestionsThunk,
  updateAntiRefundStrategies,
  updateRefundRequestSelectedItem,
} from "./antiRefundStrategies.thunk";

const antiRefundStrategiesBuilders = (
  builder: ActionReducerMapBuilder<AntiRefundStrategiesState>,
) => {
  builder.addCase(
    fetchAntiRefundStrategies.pending,
    (state: AntiRefundStrategiesState) => {
      state.fetchAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchAntiRefundStrategies.rejected,
    (state: AntiRefundStrategiesState) => {
      state.fetchAjaxStatus = "rejected";
    },
  );

  builder.addCase(
    fetchAntiRefundStrategies.fulfilled,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.fetchAjaxStatus = "fulfilled";
      updateAntiRefundStrategiesSlice(state, payload);
    },
  );

  builder.addCase(
    fetchRefundRequestFollowUpQuestionsThunk.pending,
    (state: AntiRefundStrategiesState) => {
      state.followUpQuestionsAjaxStatus = "pending";
    },
  );

  builder.addCase(
    fetchRefundRequestFollowUpQuestionsThunk.rejected,
    (state: AntiRefundStrategiesState) => {
      state.followUpQuestionsAjaxStatus = "rejected";
    },
  );

  builder.addCase(
    fetchRefundRequestFollowUpQuestionsThunk.fulfilled,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.followUpQuestionsAjaxStatus = "fulfilled";
      updateRefundRequestFollowUpQuestions(state, payload);
    },
  );

  builder.addCase(
    updateAntiRefundStrategies.pending,
    (state: AntiRefundStrategiesState) => {
      state.updateAntiRefundRequestAjaxStatus = "pending";
    },
  );

  builder.addCase(
    updateAntiRefundStrategies.rejected,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.updateAntiRefundRequestAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while updating the anti refund request",
        type: "danger",
      });
    },
  );

  builder.addCase(
    updateAntiRefundStrategies.fulfilled,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.updateAntiRefundRequestAjaxStatus = "fulfilled";
      pushTheToast({
        type: "success",
        text: "Configuration Saved",
        position: "top-right",
      });
    },
  );
  builder.addCase(
    updateRefundRequestSelectedItem.fulfilled,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.updateAjaxStatus = "fulfilled";
    },
  );
  builder.addCase(
    updateRefundRequestSelectedItem.pending,
    (state: AntiRefundStrategiesState) => {
      state.updateAjaxStatus = "pending";
    },
  );

  builder.addCase(
    updateRefundRequestSelectedItem.rejected,
    (state: AntiRefundStrategiesState, { payload }) => {
      state.updateAjaxStatus = "rejected";
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while updating the anti refund request",
        type: "danger",
      });
    },
  );
};

const updateAntiRefundStrategiesSlice = (
  state: AntiRefundStrategiesState,
  payload: any,
) => {
  const updatedRefundRequestList: AntiRefundStrategiesReason[] = payload.map(
    (reason: AntiRefundStrategiesReason) => {
      let addRemainScenario = false;

      const updatedScenarios = reason.scenarios.map((scenario) => {
        let updatedScenario: RefundScenario = { ...scenario };

        const areAllItemsSelected =
          Array.isArray(scenario.items) &&
          scenario.items.length === 1 &&
          typeof scenario.items[0] === "string" &&
          scenario.items[0] === "*";

        const areAllTagsSelected =
          Array.isArray(scenario.tags) &&
          scenario.tags.length === 1 &&
          scenario.tags[0] === "*";

        if (areAllItemsSelected && areAllTagsSelected) {
          updatedScenario.itemsAndTagAllSelected = true;
        } else {
          if (scenario.items.length) {
            updatedScenario.items = scenario.items.map((item: any) => ({
              id: item.productId,
              label: item.productName,
              type: "item",
              value: item.productId?.toString(),
              imgSrc: item.imageUrl,
              price: item.productSku,
            }));
          }
          if (scenario.tags.length) {
            updatedScenario.tags = scenario.tags.map((tag) => tag);
          }
        }

        if (
          (updatedScenario.items.length === 0 || areAllItemsSelected) &&
          (updatedScenario.tags.length === 0 || areAllTagsSelected) &&
          (updatedScenario.followUpQuestions.length === 0 ||
            (updatedScenario.followUpQuestions as Array<any>).includes("*"))
        ) {
          updatedScenario.itemsAndTagAllSelected = true;
          addRemainScenario = true;
          updatedScenario.items = [];
          updatedScenario.tags = [];
        }

        if (
          updatedScenario.followUpQuestions.length === 1 &&
          updatedScenario.followUpQuestions[0] === ("*" as any)
        ) {
          updatedScenario.followUpQuestions =
            state.refundRequests.refundRequestFollowUpQuestions.map(
              (element) => ({
                elementId: element.elementId,
                elementTypeId: element.elementTypeId,
                elementValue: element.elementValue,
                additionalOptions: element.additionalOptions,
                selectedValue: "",
              }),
            );
        }

        updatedScenario.followUpQuestions =
          updatedScenario.followUpQuestions.map((followUpQuestion) => {
            let updatedFollowUpQuestion = { ...followUpQuestion };
            if (
              updatedFollowUpQuestion.selectedValue.length === 1 &&
              updatedFollowUpQuestion.selectedValue[0] === "*"
            ) {
              updatedFollowUpQuestion.selectedValue =
                updatedFollowUpQuestion.additionalOptions.map(
                  (option) => option.id,
                );
            }
            return updatedFollowUpQuestion;
          });

        return updatedScenario;
      });

      if (!addRemainScenario) {
        updatedScenarios.push({
          id: uuid(),
          items: [],
          tags: [],
          followUpQuestions: [],
          enabled: false,
          itemsAndTagAllSelected: true,
          isNewScenario: true,
        });
      }

      return {
        ...reason,
        scenarios: updatedScenarios,
      };
    },
  );

  state.refundRequests.reasons = updatedRefundRequestList;
};

const updateRefundRequestFollowUpQuestions = (
  state: AntiRefundStrategiesState,
  payload: any,
) => {
  state.refundRequests.refundRequestFollowUpQuestions = payload;

  state.refundRequests.reasons.forEach((reason: AntiRefundStrategiesReason) => {
    reason.scenarios.forEach((scenario) => {
      if (
        scenario.followUpQuestions.length === 1 &&
        scenario.followUpQuestions[0] === ("*" as any)
      ) {
        scenario.followUpQuestions =
          state.refundRequests.refundRequestFollowUpQuestions.map((element) => {
            return {
              elementId: element.elementId,
              elementTypeId: element.elementTypeId,
              elementValue: element.elementValue,
              additionalOptions: element.additionalOptions,
              selectedValue: "",
              // selectedId: "",
            };
          });
      }

      scenario.followUpQuestions.forEach((followUpQuestion) => {
        if (
          followUpQuestion.selectedValue.length === 1 &&
          followUpQuestion.selectedValue[0] === "*"
        ) {
          followUpQuestion.selectedValue =
            followUpQuestion.additionalOptions.map((option) => option.id);
          // followUpQuestion.selectedId =
          //   followUpQuestion.additionalOptions.map(
          //     (option) => option.id
          //   );
        }
      });
    });
  });
};

export default antiRefundStrategiesBuilders;
