import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCampaignThreadMsg } from "src/services/Campaign/getCampaignThreadMsg";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";

import { useAppSelector } from "src/store/store";

export interface CernThreadMsgMap {
  [key: number | string]: IMessageData;
}

const useCampaignCernThread = (
  campaignEmailId: number | string | undefined | null,
  campaignId: number | string | undefined | null,
) => {
  const { ticketId }: any = useParams();
  const { refetchCernThread } = useAppSelector((state) => state.innerTicket);
  const [cernThread, setCernThread] = useState<CernThreadMsgMap>({});
  const dispatch = useDispatch();
  const newRef = useRef(false);
  const initialPayload = useMemo(() => {
    return {
      start: 0,
      limit: 10,
      campaignEmailId: campaignEmailId ?? 0,
      campaignId: campaignId ?? 0,
      sortBy: "desc",
    };
  }, [campaignEmailId, campaignId]);
  const [payload, setPayload] = useState(initialPayload);

  useEffect(() => {
    setPayload(initialPayload);
    setCernThread({});
  }, [initialPayload]);

  const { data, isLoading, isError, refetch } = useQuery(
    ["campaign/cernThread", payload],
    {
      queryFn: async () => {
        const res = await getCampaignThreadMsg(payload);
        return res;
      },
      enabled: !!(campaignEmailId && campaignId),
    },
  );

  useEffect(() => {
    const cernResData = data?.data;
    if (cernResData) {
      setCernThread((prevState) => {
        let mapData = { ...prevState };
        cernResData.forEach((thread) => {
          mapData = {
            ...mapData,
            [thread.messageId]: thread,
          };
        });
        return mapData;
      });
    }
  }, [data]);

  // Change the current page and trigger a refetch
  const changePage = async () => {
    setPayload({
      ...payload,
      start: payload.start + payload.limit!,
    });
  };

  return {
    cernThread,
    changePage,
    isLoading: !!campaignEmailId && isLoading,
    isError,
    setCernThread,
    hasNextPage: data?.data?.length === payload.limit,
  };
};

export default useCampaignCernThread;
