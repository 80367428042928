import { axiosJSON } from "src/globals/axiosEndPoints";
import { SequenceStep, ESequenceSectionType } from "./createCampaignV2.service";
import { v4 as uuid } from "uuid";

export interface GetSequenceStepParams {
  categoryId: string | number;
  templateId: string | number;
  variantId: string | number;
}

export interface GetSequenceStepResponse {
  data: SequenceStep[];
  metaData: {
    limit: number;
  };
}

const getSequenceStepService = async (params: GetSequenceStepParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/getSequenceStep`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetSequenceStepResponse = {
    data: res.data.map((val: SequenceStep, index: number) => {
      let sections = val.sections;

      // Add additional fields conditionally based on section type
      sections = sections.map((section) => {
        const baseSection = {
          ...section,
          id: uuid(),
        }; // Unique ID for frontend use

        // Add default descriptions if the section type is Blog or Feature
        if (section.sectionType === ESequenceSectionType.Blog) {
          return {
            ...baseSection,
            sections: section.blogs.map((v) => {
              return {
                id: uuid(),
                value: v,
                isDefault: true,
              };
            }),
          };
        }

        if (section.sectionType === ESequenceSectionType.Feature) {
          return {
            ...baseSection,
            sections: section.features.map((v) => {
              return {
                id: uuid(),
                value: v,
                isDefault: true,
              };
            }),
          };
        }

        return baseSection;
      });

      return {
        ...val,
        id: uuid(), // Assign frontend-only ID
        sections,
      };
    }),
    metaData: res.metaData,
  };

  return ret as GetSequenceStepResponse;
};

export default getSequenceStepService;
