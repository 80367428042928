import Lottie from "lottie-light-react";
import LoaderAnimation from "src/assets/animation/white-loader.json";
import styles from "./PauseModal.module.scss";
import cautionImg from "src/assets/images/warningIcon.png";

const PauseModal = ({
  context,
  onHide,
  onSubmit,
  campaignName,
  isLoading,
}: {
  context: "pause" | "delete";
  onHide: () => void;
  onSubmit: () => void;
  campaignName: string;
  isLoading?: boolean;
}) => {
  return (
    <div className="text-center">
      <img
        src={cautionImg}
        alt="caution"
        width={35}
        className="mx-auto"
      />
      <h5 className={`text-center mt-2 ${styles.modalHead}`}>
        {context === "delete"
          ? `Are you sure you want to delete "${campaignName}" campaign?`
          : `Pause "${campaignName}" campaign?`}
      </h5>
      <p className={`text-center mb-4 ${styles.desc}`}>
        {context === "delete"
          ? `Deleting this campaign will permanently remove it from your system. This action cannot be undone, and all campaign data, including its settings, performance reports, and user interactions, will be lost.`
          : `Pausing this campaign will temporarily stop its execution. No messages will be sent to users, and the campaign status will change to "Draft" until approved. You can resume the campaign anytime from the campaigns page.`}
      </p>
      <div className="d-flex justify-content-center align-items-center">
        <button
          className={`me-2 ${styles.cancelBtn}`}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className={`${styles.submitBtn}`}
          onClick={onSubmit}
        >
          {isLoading ? (
            <div className={`${styles.loader}`}>
              <Lottie animationData={LoaderAnimation} />
            </div>
          ) : context === "delete" ? (
            "Delete campaign"
          ) : (
            "Pause campaign"
          )}
        </button>
      </div>
    </div>
  );
};

export default PauseModal;
