import { useCallback, useRef, useEffect, useState } from "react";
import useDebounce from "src/hooks/useDebounce";
import styles from "./SearchBar.module.scss";

// interface ISearchBar {
//   className: string;
//   inputClassName: string;
//   placeholder: string;
//   onSearch: (value: string) => void;
// }

function SearchBar({
  className = "",
  inputClassName = "",
  placeholder = "Search here...",
  onSearch = (value: string, callback: any) => {},
  value = "",
  isSearchIcon = true,
  debounceValue = 1000,
  ...props
}: any) {
  const debouncedSearchTerm = useDebounce(value, debounceValue);
  const [readOnly, setReadOnly] = useState(false);
  const [hasInput, setHasInput] = useState(false);
  const inputRef: any = useRef(null);
  useEffect(
    () => {
      onSearch(value, () => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      });
    },
    [debouncedSearchTerm], // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (value) {
      setHasInput(true);
    } else {
      setHasInput(false);
    }
  }, [value]);

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setHasInput(false); // Clear input and update state
      if (typeof props.onChange === "function") {
        const event = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(event);

        //manually dispatching on change event
        props.onChange(event);
      }
    }
  };

  return (
    <div className={`${styles.searchBar} ${className}`}>
      {isSearchIcon && <i className={`fa-solid fa-magnifying-glass mx-2`}></i>}
      <textarea
        ref={inputRef}
        data-lpignore="true"
        autoComplete="off"
        data-form-type="other"
        rows={1}
        type="search"
        className={`${inputClassName} px-2`}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === "enter") {
            e.preventDefault();
          }
          if (typeof props.onKeyDown === "function") {
            props.onKeyDown(e);
          }
        }}
        onKeyUp={(e) => {
          if (e.key.toLowerCase() === "enter") {
            e.preventDefault();
          }
          if (typeof props.onKeyDown === "function") {
            props.onKeyUp(e);
          }
        }}
        {...props}
      ></textarea>
      {hasInput && (
        <i
          className={`fa-solid fa-xmark ms-1 ${styles.resetBtn}`}
          onClick={clearInput}
        ></i>
      )}
    </div>
  );
}

export default SearchBar;
