import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { actions } from "src/store/slices/emailIntegration/emailIntegration.slices";
import { fetchVerifyDomainAuth } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { HELPDESK_REDIRECT_OUTBOUND } from "../AddEmail/Children/AddModal/DomainIntegration/children/OutgoingMailOption/Gmail/Gmail";
import { EOutboundEmailSteps } from "../../../OutboundEmailIntegration/OutboundEmailIntegration";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook for managing all the states for domain verification process
 */
const useDomainVerification = (hideElements?: (val: boolean) => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Modal States
  const [verifyDomainShowModal, setVerifyDomainShowModal] = useState(false);
  const [domainHasBeenVerified, setDomainHasBeenVerified] = useState(false);
  const [couldntVerifyShowModal, setCouldntVerifyShowModal] = useState(false);

  // Integration data selectors
  const name = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.userName;
  });

  const email = useAppSelector((state) => {
    return state.emailIntegration.selectedUser.emailEnteredByUser;
  });

  const selectedUserDetail = useAppSelector(
    (state) => state.emailIntegration.selectedUser,
  );

  const { domainLoading: loading, emailLoading } = useAppSelector(
    (state) => state.emailIntegration,
  );
  /**
   * Effect for updating modal states
   */
  useEffect(() => {
    switch (loading) {
      // Show the verify connection modal
      case "pending": {
        setVerifyDomainShowModal(true);
        setDomainHasBeenVerified(false);
        setCouldntVerifyShowModal(false);
        if (hideElements) {
          hideElements(true);
        }
        break;
      }
      // Show the domain verified modal if verified or show couldn't verify modal
      case "fulfilled": {
        setCouldntVerifyShowModal(!selectedUserDetail.isDomainVerified);
        setVerifyDomainShowModal(false);
        setDomainHasBeenVerified(selectedUserDetail.isDomainVerified);
        dispatch(actions.setDomainLoading({ domainLoading: "idle" }));
        if (hideElements) {
          hideElements(true);
        }
        break;
      }
      // Show Couldn't verify modal
      case "rejected": {
        setVerifyDomainShowModal(false);
        setDomainHasBeenVerified(false);
        setCouldntVerifyShowModal(true);
        dispatch(actions.setDomainLoading({ domainLoading: "idle" }));
        if (hideElements) {
          hideElements(true);
        }
        break;
      }
    }
  }, [dispatch, hideElements, loading, selectedUserDetail.isDomainVerified]);

  /**
   * Click Handler for starting verification
   */
  const handleVerifySetup: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        fetchVerifyDomainAuth({
          emailIntegrationId: selectedUserDetail.emailIntegrationId,
        }),
      );
    },
    [dispatch, selectedUserDetail.emailIntegrationId],
  );

  const [domainVerification, setDomainVerification] = useState<boolean>(
    selectedUserDetail.isDomainVerified,
  );

  /**
   * Callback handler for hiding couldn't verify modal
   */
  const onHideCouldntVerify = useCallback(() => {
    setCouldntVerifyShowModal(false);
    setDomainVerification(true);
    if (hideElements) {
      hideElements(false);
    }
  }, [hideElements]);

  /**
   * Callback for hiding and navigating after verification
   */
  const onHideDomainVerified = useCallback(() => {
    setDomainHasBeenVerified(false);
    dispatch(actions.domainVerified());
    setDomainVerification(true);
    // navigate(
    //   `/settings/integrations/email/${selectedUserDetail.emailIntegrationId}/complete/?success=true`
    // );
    if (hideElements) {
      hideElements(false);
    }
  }, [dispatch, hideElements]);

  useEffect(() => {
    const item = localStorage.getItem(HELPDESK_REDIRECT_OUTBOUND);
    if (item) {
      localStorage.removeItem(HELPDESK_REDIRECT_OUTBOUND);

      navigate(
        `/settings/integrations/outboundEmail/${EOutboundEmailSteps.OutgoingEmails}${window.location.search}`,
      );

      return;
    }

    const searchparams = new URLSearchParams(window.location.search);
    if (
      emailLoading !== "pending" &&
      emailLoading !== "idle" &&
      searchparams.get("statusCode") === "failed"
    ) {
      if (searchparams.get("outgoingType")) {
        dispatch(
          actions.setOutgoingType({
            outgoingType: searchparams.get("outgoingType") as any,
          }),
        );
        searchparams.delete("outgoingType");
      }
      dispatch(actions.setDomainLoading({ domainLoading: "rejected" }));

      searchparams.delete("statusCode");
      const searchString = searchparams.toString();
      window.history.pushState(
        {},
        "",
        window.location.pathname + (searchString ? "?" + searchString : ""),
      );
    }
  }, [dispatch, emailLoading]);

  const isDomainVerified = useMemo(() => {
    if (selectedUserDetail.outgoingMailSettings?.integrations) {
      return selectedUserDetail.outgoingMailSettings.integrations[
        "currentHostWithDNS"
      ]?.verified;
    }

    return selectedUserDetail.isDomainVerified;
  }, [
    selectedUserDetail.isDomainVerified,
    selectedUserDetail.outgoingMailSettings.integrations,
  ]);

  return {
    name,
    email,
    verifyDomainShowModal,
    domainHasBeenVerified,
    couldntVerifyShowModal,
    domainVerification,
    isDomainVerified: isDomainVerified,
    handleVerifySetup,
    onHideCouldntVerify,
    onHideDomainVerified,
  };
};

export default useDomainVerification;
