import { useState } from "react";
import styles from "./ConversationsTabs.module.scss";
import ReportBar from "src/components/svg/ReportBar";
import SunBurstZoomable, {
  SunBurstZoomableData,
} from "src/components/SunBurstZoomable/SunBurstZoomable";
import { useEffect, useMemo } from "react";
// import FilterWrapper from "../FilterWrapper/FilterWrapper";
import {
  useReportFilters,
  useReportFiltersCreate,
} from "../../../../hooks/reportFilters/useReportFilters";
import { useQuery } from "@tanstack/react-query";
import getSunBurstReportService from "src/services/report/getSunBurstReport.service";
import Loader from "src/components/Loader";
import AttributeTable from "../AttributeTable/AttributeTable";
const ConversationsTabs = () => {
  // const [activeTab, setActiveTab] = useState("successfull");
  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };
  const Report = () => {
    const { filtersForAPI } = useReportFilters();

    const { data, isError, isLoading } = useQuery(
      ["getSunBurstReportService", filtersForAPI],
      {
        queryFn: async function () {
          return getSunBurstReportService({ filters: filtersForAPI });
        },
        staleTime: 0,
        cacheTime: 0,
      },
    );

    if (isLoading) {
      return (
        <span className="d-flex align-items-center justify-content-center mt-3 text-danger">
          <Loader />
        </span>
      );
    } else if (isError) {
      return (
        <span className="d-flex align-items-center justify-content-center mt-3 text-danger">
          Error loading content
        </span>
      );
    }
    if (data?.name) {
      return <SunBurstZoomable data={data} />;
    } else {
      return (
        <span className="d-flex align-items-center justify-content-center mt-3 text-secondary">
          No Data Found
        </span>
      );
    }
  };
  return (
    <div className={`${styles.tabContainer} pt-4`}>
      {/* Tab Headers */}
      <div className={`${styles.tabs} px-4`}>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "all" ? styles.activeTab : ""
          }`}
          onClick={() => handleTabClick("all")}
        >
          All conversions
        </button>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "successfull" ? styles.activeTab : ""
          } d-none`}
          onClick={() => handleTabClick("successfull")}
        >
          Successful Conversions
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === "unSuccessfull" ? styles.activeTab : ""
          } d-none`}
          onClick={() => handleTabClick("unSuccessfull")}
        >
          Unsuccessful Conversions
        </button>
      </div>

      {/* Tab Content */}
      <div className="tab-content px-4 pt-3">
        {activeTab === "all" && (
          <div className={`${styles.tabsWrapper}`}>
            <div
              className={`d-flex justify-content-center ${styles.reportWrapper} mt-2`}
            >
              <Report />
            </div>
            <div>
              <h5 className={`${styles.tableHead}`}>
                Attributes correlating with all conversions
              </h5>
              <AttributeTable />
            </div>
          </div>
        )}
        {activeTab === "successfull" && (
          <div className={`${styles.tabsWrapper}`}>
            <div
              className={`d-flex justify-content-center ${styles.reportWrapper} mt-2`}
            >
              <Report />
            </div>
            <div>
              <h5 className={`${styles.tableHead}`}>
                Attributes correlating with success but missing in unsuccessful
                conversions
              </h5>
              <AttributeTable />
            </div>
          </div>
        )}
        {activeTab === "unSuccessfull" && (
          <div className={`${styles.tabsWrapper}`}>
            <div
              className={`d-flex justify-content-center ${styles.reportWrapper} mt-2`}
            >
              <Report />
            </div>
            <div>
              <h5 className={`${styles.tableHead}`}>
                Attributes correlating with unsuccess but missing in successful
                conversions
              </h5>
              <AttributeTable />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationsTabs;
