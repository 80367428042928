import TableRow from "../TableRow/TableRow";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
import styles from "./ProductsTable.module.scss";
import { ConfigureCSVTableMappedAttributes } from "../../../Hooks/useUploadNewCsvModalContext";
import TableHeader from "src/features/ReturnAutoWorkFlow/children/ConfigModals/NewModals/UploadedCsvModal/TableHeader/TableHeader";

const ProductsTable = ({
  data,
  currentPage,
  totalPages,
  setCurrentPage,
  viewContext = "oldUploads",
}: {
  data: ConfigureCSVTableMappedAttributes;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (currentPage: number) => void;
  viewContext?: "oldUploads" | "newUploads";
}) => {
  return (
    <div>
      <div
        className={`${styles.container} ${
          viewContext === "newUploads" ? styles.forNewUpload : ""
        } mb-1`}
      >
        <TableHeader
          tableHeadings={data?.tableHeadings ?? {}}
          tableHeadingIds={data?.tableHeadingIds ?? []}
        />
        {data?.customerIds && data?.customerIds?.length > 0 ? (
          <div>
            {data?.customerIds.map((id) => {
              const customerData = data?.customers[id];
              return (
                <TableRow
                  key={id}
                  customerData={customerData ?? null}
                />
              );
            })}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            No Users Found
          </div>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ProductsTable;
