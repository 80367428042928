import Lottie from "lottie-light-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderAnimation from "src/assets/animation/white-loader.json";
import { AJAXSTATUS } from "src/globals/constants";
import updateSegmentSettingsService, {
  UpdateSegmentAttribute,
} from "src/services/CustomerSegments/Settings/updateSegmentSettings.service";
import { clearGetCustomerByIdCache } from "src/services/ShopifySidebar/getData";
import SegmentEvent from "./children/SegmentEvent/SegmentEvent";
import styles from "./SegmentSettings.module.scss";

const SegmentSettings = () => {
  const navigate = useNavigate();
  const [updatedAttributes, setUpdatedAttributes] = useState<
    Map<string, UpdateSegmentAttribute>
  >(new Map());
  const [savingStatus, setSavingStatus] = useState<AJAXSTATUS>("idle");
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const resetSavingStatus = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setSavingStatus("idle");
    }, 3000);
  }, []);

  const onSave = useCallback(async () => {
    if (updatedAttributes.size === 0) {
      return;
    }

    try {
      setSavingStatus("pending");
      await updateSegmentSettingsService({
        attributes: Array.from(updatedAttributes, ([_, value]) => value),
      });
      setSavingStatus("fulfilled");

      // Clear cached timeline data
      clearGetCustomerByIdCache();

      resetSavingStatus();
    } catch (err) {
      setSavingStatus("rejected");
    }
  }, [resetSavingStatus, updatedAttributes]);

  const onDiscard = useCallback(() => {
    setUpdatedAttributes(new Map());
  }, []);

  const onAssignAttribute = useCallback(
    (id: string, val: UpdateSegmentAttribute) => {
      setUpdatedAttributes((prev) => {
        prev.set(id, { ...val });

        return prev;
      });
    },
    [],
  );

  useEffect(() => {
    const timeout = timerRef.current;

    // Cleanup timeout on unmount or re-render
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <div
      className={`${styles.customerWrap}`}
      id="customerWrapper"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start">
          <div
            className={`me-3 cursor-pointer ${styles.backbtn}`}
            id="goBckBtn"
            onClick={() => navigate(-1)}
          >
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>
          <div>
            <h1 className={`saufter_h1 mb-0 ${styles.heading}`}>
              User timeline setting
            </h1>
          </div>
        </div>
      </div>

      <div className={`${styles.companyDetails} mt-3 d-flex flex-column gap-2`}>
        <SegmentEvent
          type="default"
          updatedAttributes={updatedAttributes}
          onAssignAttribute={onAssignAttribute}
        />
        <SegmentEvent
          type="custom"
          updatedAttributes={updatedAttributes}
          onAssignAttribute={onAssignAttribute}
        />
      </div>
      <div
        className={`${styles.footerBtns} d-flex align-items-center justify-content-start gap-2`}
      >
        <button
          className={`${styles.cancelBtn}`}
          onClick={onDiscard}
        >
          Discard changes
        </button>
        <button
          className={`${styles.saveBtn}`}
          onClick={onSave}
          disabled={savingStatus === "pending"}
        >
          {savingStatus === "pending" ? (
            <div className={`${styles.loader}`}>
              <Lottie animationData={LoaderAnimation} />
            </div>
          ) : savingStatus === "fulfilled" ? (
            "Saved!"
          ) : savingStatus === "rejected" ? (
            "Saving Failed!"
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default SegmentSettings;
