import { EOutboundEmailSteps } from "./OutboundEmailIntegration";

/**
 * Generates the navigation url based on active step and buttona action
 *
 * @returns The route to navigate to based on action and current step provided.
 * @throws Error if the step is unrecognized or not handled.
 */
const getNavigation = (
  action: "next" | "previous",
  currentStep?: EOutboundEmailSteps,
) => {
  if (!currentStep) {
    return `/settings/integrations/outboundEmail`;
  }

  switch (currentStep) {
    case EOutboundEmailSteps.AddEmail:
      return `/settings/integrations/outboundEmail/${
        action === "next"
          ? EOutboundEmailSteps.OutgoingEmails
          : EOutboundEmailSteps.AddEmail
      }`;
    case EOutboundEmailSteps.OutgoingEmails:
      return `/settings/integrations/outboundEmail/${
        action === "next"
          ? EOutboundEmailSteps.Complete
          : EOutboundEmailSteps.AddEmail
      }`;
    case EOutboundEmailSteps.Complete:
      return `/settings/integrations/outboundEmail/${
        action === "next"
          ? EOutboundEmailSteps.Complete
          : EOutboundEmailSteps.OutgoingEmails
      }`;

    // Default case to ensure all types are handled
    default:
      const exhaustiveCheck: never = currentStep; // Ensures type is exhaustive
      console.error(`Unhandled active step: ${exhaustiveCheck}`);
      return `/settings/integrations/outboundEmail`; // Throws an error for unhandled types
  }
};

const outboundEmailIntegrationUtils = { getNavigation };

export default outboundEmailIntegrationUtils;
