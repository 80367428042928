import { EventHandler } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { SetStateAction } from "react";
import { AdditionalOption } from "src/components/TinyMCEContainer/Children/AdditionalOptionList/AdditionalOptionList";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { Editor, RawEditorOptions, EditorEvent } from "tinymce";

interface AutoCompleteData {
  id: number | string;
  label: string;
  value: string;
  classes?: string[];
}

interface AutoCompleteConfig {
  prefix: string;
  fetchHandler: (
    searchTerm: string,
    maxResults: number,
    fetchOptions: Record<string, any>,
  ) => Promise<AutoCompleteData[]>;
}
// Define the props interface for renderComponent
export interface AutoCompleteRenderComponentProps {
  match: string;
  cleanUp: (rollback: boolean) => void;
  select: ({
    value,
    elementSelector,
  }: {
    value: string;
    elementSelector?: string;
  }) => void;
  data: any;
  popoverContainer: HTMLSpanElement | null;
  editor: Editor;
}

export interface CustomAutoCompleteOptions {
  delimiters: {
    [key: string]: {
      fetchOptions: (value: string) => Promise<any>;
      renderComponent: React.ComponentType<AutoCompleteRenderComponentProps>;
    };
  };
}
export interface TinyMCEEditorProps {
  name?: string; //{form name} of the textarea
  scrollHeightClassName?: string; // the height of editor when scrolled
  className?: string;
  options?: RawEditorOptions; //editor settings for overiding default
  value: string; //will contain the value of editor inner html
  onChange: (value: string) => void; //this method will be called on change of editor content
  showToolbar?: boolean; //by default toolbar will be shown, using this prop it can be overwritten
  uniqueID: string;
  attachmentUploadData?: UploadAttachmentRes;
  threadData?: { threadText?: string; attachments?: any };
  sendThread?: boolean;
  setSendThread?: (value: boolean) => void;
  showThread?: boolean;
  setShowThread?: (value: boolean | ((value: boolean) => boolean)) => void;
  editorRef: React.MutableRefObject<Editor | null>; //useRef for storing the instance of editor, which can be used later
  toolbar?: any; //can be used to override toolbar buttons
  inline?: boolean; //which toggles editor more "inline" | "standard"
  onDrop?: (event: EditorEvent<DragEvent>, editor: any) => void; //this function will be call on file dropped on editor
  signatureText?: string;
  readonly?: boolean; //to set editor readonly or editable
  editorClassName?: string;
  hidePlusIcon?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  autoCompleteMenuConfigs?: AutoCompleteConfig[]; //to handle autocomplete, it will show popover like emoji if prefix text matches editor text, Note: use useMemo or anything that doesn't redefine this constant unnessarily on every render which may case some wired behaviour
  autosavePrefix?: string;
  mentionsEnabled?: boolean;
  setSigEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  sigEdited?: boolean;
  onDragOver?: (event: EditorEvent<DragEvent>, editor: any) => void;
  customAutoCompleteOptions?: CustomAutoCompleteOptions;
  setCurrentEditorObj?: React.Dispatch<SetStateAction<Editor | undefined>>;
}

export interface TinyMCEEditorContainerProps {
  name?: string; //{form name} for the editor
  scrollHeightClassName?: string; // the height of editor when scrolled
  className?: string;
  value: string; //will contain the value of editor inner html as string
  options?: RawEditorOptions; //to override defaultConfig variable
  onChange: (value: string) => void; //this function will be called on editor content change
  editorClassName?: string;
  showToolbar?: boolean; //flag for show or hide toolbar
  disableFormatingsAndEmbedding?: boolean;
  uniqueID: string;
  buttons?: any;
  attachmentUploadData?: UploadAttachmentRes;
  threadData?: { threadText?: string; attachments?: any };
  sendThread?: boolean;
  setSendThread?: (value: boolean) => void;
  showThread?: boolean;
  setShowThread?: (value: boolean | ((value: boolean) => boolean)) => void;
  disableAttachment?: boolean; // used to enable or disable attachment picker
  setAttachmentUploadData?: any; // function that sets the uploaded attachment res in parent component
  isFileUploading?: any; // status of overall uploading
  setIsFileUploading?: any; // updates the overall file upload status
  selectedFiles?: TinyMCEUploadFile[]; //useState to store uploaded attachments info
  setSelectedFiles?: Function; //state function to update selectedFiles
  currentAttachmentBatchNumber?: any;
  setCurrentAttachmentBatchNumber?: any;
  canDeleteInline?: boolean;
  additionalOptions?: Array<AdditionalOption>; //menu options that is shown on click of + button
  /**
   * Editor toolbar options, can be used to add custom buttons or override default buttons visibility or alignment
   *
   * When adding Tiny MCE inside bootstrap modal component add enforceFocus to false to enable edit/add link modal
   *
   * Available values - "emoticons bold italic underline link bullist numlist removeformat"
   */
  toolbar?: any; //editor toolbar options, can be used to add custom buttons or override default buttons visibility or alignment
  inline?: boolean; //editor mode "inline" | "standard"
  fileUploadHandler?: ({
    //upload handler function which will be called on every file upload
    files,
    onProgress,
    onFailed,
    onSuccess,
  }: FileUploadHandler) => void;
  areAttachmentsPublic?: boolean; //flag that denotes uploading attachments will be public or private
  inlineAttachmentOnly?: boolean; //to only allow inline attachments
  showAttachmentOverlay?: boolean; //show or hide uploaded attachment list
  onFileDelete?: (file: TinyMCEUploadFile) => Promise<OnFileDeleteRes>; //handler which will be called on file delete
  showCustomFilePicker?: boolean;
  signatureText?: string;
  getMentionRef?: (data: { addMentionRef: any }) => void; //function to get useRef of addMention function which can be used to insert placholder variables inside editor
  readonly?: boolean;
  allowedEmbbedTypes?: Array<"image" | "video" | "gif">; //allowed embedded attachment type
  disableEmbedding?: boolean;
  autoCompleteMenuConfigs?: AutoCompleteConfig[];
  autosavePrefix?: string;
  mentionsEnabled?: boolean;
  setSigEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  sigEdited?: boolean;
  hideAllControls?: boolean; //flag to hide all controls
  compareOverAllAttachmentSize?: boolean; //flag resposible for calculating the overall upload file limit
  buttonContainerClass?: string; //className for sendmessage buttons or bottons container div
  customAllowedAttachmentTypeForFilePicker?: Array<string>;
  tinymceEditorRef?: React.MutableRefObject<Editor | null>; // Reference to the TinyMCE editor instance, which can be used to access the editor's methods and properties
  customAutoCompleteOptions?: CustomAutoCompleteOptions;
  setCurrentEditorObj?: React.Dispatch<SetStateAction<Editor | undefined>>;
}

//interface for response type of onFileDelete handler function
export interface OnFileDeleteRes {
  status: boolean;
  file: TinyMCEUploadFile;
}

//file upload status of uploadedFiles
export const STATUS_UPLOAD = {
  initialized: 0,
  uploading: 1,
  success: 2,
  failed: 3,
};

//interface for uploaded file object
export interface TinyMCEUploadFile {
  id: number | string; //unique id
  attachmentId?: number | string; //after uploading attachment id should be assigned
  attachmentName?: string;
  attachmentType?: string;
  batchNumber?: string; //after uploading batchnumber will be updated by file handler
  attachmentURL?: string; //assigned by file handler
  embedded?: boolean; //inline | standard
  isPublic?: boolean;
  contentId?: string | null;
  attachmentSize: number;
  file?: File; //file obeject
  target?: any;
  progress: number; //file uploaded percentage
  abortController?: AbortController; //abort controller for calling the upload
  status: number; //file upload status from STATUS_UPLOAD
  notificationBar?: any; //for showing progress bar in tinymce editor
}

//interface for file upload hander function
export interface FileUploadHandler {
  files: TinyMCEUploadFile[];
  onProgress: (fileId: number | string, value: number) => void;
  onFailed: (fileId: number | string, message?: string) => void;
  onSuccess: (file: TinyMCEUploadFile) => void;
  areAttachmentsPublic: boolean;
}

export const TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES = [
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "svg",
  "webp",
  "gif",
];
export const TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES = [
  "mp4",
  "mkv",
  "avi",
  "mpg",
  "mpeg",
  "webm",
];
export const TINY_MCE_ATTACHEMENT_SUPPORTED_AUDIO_TYPES = [
  "mp3",
  "wav",
  "mpa",
  "wma",
  "ogg",
  "midi",
  "mid",
];
export const TINY_MCE_ATTACHEMENT_SUPPORTED_COMPRESSION_TYPES = [
  "rar",
  "zip",
  "7z",
  "tar",
  "tar.gz",
];
export const TINY_MCE_ATTACHEMENT_SUPPORTED_DOC_TYPES = [
  "txt",
  "rtf",
  "pdf",
  "doc",
  "docx",
  "odt",
  "tex",
  "wpd",
  "xls",
  "xlsx",
  "ods",
  "xlsm",
  "csv",
  "ppt",
  "pptx",
  "odp",
  "pps",
  "xml",
  "log",
  "tiff",
  "ico",
];
export const TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES = [
  ...TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES,
  ...TINY_MCE_ATTACHEMENT_SUPPORTED_VIDEO_TYPES,
  ...TINY_MCE_ATTACHEMENT_SUPPORTED_AUDIO_TYPES,
  ...TINY_MCE_ATTACHEMENT_SUPPORTED_COMPRESSION_TYPES,
  ...TINY_MCE_ATTACHEMENT_SUPPORTED_DOC_TYPES,
];

export const isFileAttachmentSupportedTinyMCE = (
  type: string,
  additionalAllowedTypes: string[] = [],
) => {
  const SUPPORTED_TYPES = TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES.slice(); // Create a copy of the original supported types array
  if (additionalAllowedTypes.includes("gif")) {
    SUPPORTED_TYPES.push("gif");
  }
  return SUPPORTED_TYPES.includes(type.toLowerCase());
};

export const isFileImgSupportedTinyMCE = (
  type: string,
  additionalAllowedTypes: string[] = [],
) => {
  const SUPPORTED_TYPES = TINY_MCE_ATTACHEMENT_SUPPORTED_TYPES.slice();
  if (additionalAllowedTypes.includes("gif")) {
    SUPPORTED_TYPES.push("gif");
  }
  return TINY_MCE_ATTACHEMENT_SUPPORTED_IMG_TYPES.includes(type.toLowerCase());
};

export const TINY_MCE_TOOLBAR_CUSTOM_IMAGE_UPLOAD_BTN = "customImgUploadBtn";
export const TINY_MCE_TOOLBAR_CUSTOM_MEDIA_UPLOAD_BTN = "customMediaUploadBtn";

//this function will return generated video tag string which will be inserted in editor
export const getEmbeddedVideoTemplate = (
  id: string,
  url: String,
  data: any,
) => {
  return (
    '<video data-id="' +
    id +
    '" controls="controls">\n' +
    '<source src="' +
    url +
    '"' +
    " />\n" +
    "</video>"
  );
};

//this function will return generated img tag string which will be inserted in editor
export const getEmbeddedImageTemplate = (id: string, file: File, data: any) => {
  const objectURL = URL.createObjectURL(file);
  return {
    content:
      '<img src="' +
      objectURL +
      '" content-id="' +
      id +
      '" width="' +
      data?.width +
      '" height="' +
      data?.height +
      '"/>',
    url: objectURL,
  };
};

//attachment upload size limit
export const TINY_MCE_UPLOAD_FILE_SIZE_LIMIT_MB = 20;
