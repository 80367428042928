import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./CampaignBox.module.scss";
import editIcon from "src/assets/images/editIcon.png";
import merge from "src/assets/images/merge.png";
import SendingOption from "../SendingOption/SendingOption";
import { useUpdateCampaignEmail } from "../../../hooks/useCampaignData";
import { CampaignEmail } from "src/services/Campaign/getCampaignById";
import ParseHTML from "src/components/ParseHTML";
import Loader from "src/components/Loader";
import { ECampaignChannel } from "src/services/Campaign/createCampaignV2.service";

interface CampaignBoxProps extends CampaignEmail {
  handleCampaignClick: Function;
  campaignId: number;
}

const CampaignBox: React.FC<CampaignBoxProps> = ({
  triggerAfter,
  heading,
  description,
  channel,
  handleCampaignClick,
  emailTemplateData,
  id,
  campaignId,
  status,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableDay, setEditableDay] = useState(triggerAfter);
  const updateCampaignEmail = useUpdateCampaignEmail();
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Use a regular expression to allow only numbers
    const numericValue = value.replace(/[^0-9]/g, "");
    let stateValue = 0;
    if (numericValue === "NaN" || !numericValue) {
      stateValue = 0;
    } else {
      stateValue = parseInt(numericValue);
      // setEditableDay(parseInt(numericValue));
    }

    if (stateValue > 1000) {
      stateValue = 1000;
    }

    setEditableDay(stateValue);
  };

  const handleCampaignChannelChange = (value: ECampaignChannel) => {
    updateCampaignEmail.mutate({
      campaignId: campaignId,
      id: id,
      channel: value,
    });
  };

  const handleBlur = () => {
    updateCampaignEmail.mutate({
      campaignId: id,
      id: id,
      triggerAfter: editableDay,
    });
    setIsEditing(false);
  };

  return (
    <div className={`mb-3 ${styles.campaignBox}`}>
      <div className="d-flex justify-content-start w-100">
        <div className={`d-flex me-1 ${styles.dayWrap}`}>
          <span className={`pe-1 ${styles.dayText}`}>Day</span>
          {isEditing ? (
            <div className={`position-relative`}>
              <input
                type="text"
                value={editableDay}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`${styles.inputChange}`}
                autoFocus
              />
              <div
                className={`${styles.checkBox} cursor-pointer`}
              >
                <span>
                  <i className="fa-solid fa-check"></i>
                </span>
              </div>
            </div>
          ) : (
            <span className="">{editableDay}</span>
          )}
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditClick();
            }}
            className={`ps-1 cursor-pointer ${isEditing ? "d-none" : "d-block"
              }`}
          >
            <img
              src={editIcon}
              alt="edit"
              width={12}
            />
          </span>
        </div>
        {status === "loading" ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 me-5 cursor-pointer"
            onClick={() => handleCampaignClick()}
          >
            <div>
              <Loader className={`${styles.loader}`} />
            </div>
            <div className={`${styles.loaderText}`}>
              Cern is still generating the email for you. We will send you{" "}
              <br /> an email notification once it is ready
            </div>
          </div>
        ) : (
          <>
            <div
              className={`me-1 pt-2 px-2 px-lg-2 ${styles.msgWrap} cursor-pointer`}
              onClick={() => handleCampaignClick()}
            >
              <div
                className={`d-flex flex-column flex-lg-row justify-content-between ${styles.msgHead}`}
              >
                <ParseHTML html={heading || ""} />
                {channel === "livechat" && (
                  <div className={`${styles.conversation}`}>Conversation</div>
                )}
              </div>
              <div
                className={`${channel === "livechat" ? styles.liveChatMsg : ""
                  }`}
              >
                <p className={`${styles.msgSDesc}`}>
                  <ParseHTML html={description || ""} />
                </p>
              </div>
            </div>
            <div className="d-flex me-1 d-none">
              <span className={`me-1`}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      Snooze
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      {...triggerHandler}
                      ref={ref}
                      className={`${styles.snoozeImg}`}
                    >
                      <i
                        className={`material-icons  ${false ? styles.blue : ""
                          }`}
                      >
                        &#xe046;
                      </i>
                    </div>
                  )}
                </OverlayTrigger>
              </span>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className={`override-white ${styles.toolTipCustom}`}>
                    Merge Other tickets here
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    className={`${styles.mergeIconSpan} ms-0 me-0`}
                    {...triggerHandler}
                    ref={ref}
                  >
                    <img
                      src={merge}
                      className={`${styles.mergeIcon}`}
                      alt="merge"
                      width={12}
                      height={15}
                    />
                  </div>
                )}
              </OverlayTrigger>
            </div>

            <div className={`${styles.sendingOption}`}>
              <SendingOption
                value={channel}
                setCampaignChannel={handleCampaignChannelChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CampaignBox;
