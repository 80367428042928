import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useCampaignsBySegment from "src/routes/Campaigns/Children/Children/CampaignTabs/Children/DripCampaign/Hooks/useCampaignsBySegment";
import { ECampaignStatus } from "src/services/Campaign/getCampaignById";
import styles from "./ViewCampaign.module.scss";
import Loader from "src/components/Loader";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";

const ViewCampaign = ({
  onHide,
  segmentId,
}: {
  onHide: () => void;
  segmentId: string | number;
}) => {
  const { campaigns, fetchMore, fetchStatus, hasMore } =
    useCampaignsBySegment(segmentId);

  const navigate = useNavigate();

  const navigateCallback = useCallback(
    (id: string) => {
      navigate(`/campaigns/${id}`);
    },
    [navigate],
  );

  return (
    <div>
      <h4 className={`mb-3 ${styles.modalHead}`}>Campaigns for this segment</h4>
      <div className={`${styles.tableHead} mb-3 px-2`}>
        <div className={`${styles.itemOne}`}>
          <span>Campaign Name</span>
        </div>
        <div className={`${styles.itemTwo}`}>
          <span>Status</span>
        </div>
      </div>
      {fetchStatus === "pending" && campaigns.length === 0 ? (
        <div
          className={`d-flex align-items-center justify-content-center w-100`}
        >
          <Loader imgClassName={"pt-0"} />
        </div>
      ) : fetchStatus === "rejected" ? (
        <div
          className={`d-flex align-items-center justify-content-center w-100 text-danger text-center ${styles.minHeight}`}
        >
          Error Loading Campaigns..
        </div>
      ) : campaigns.length === 0 ? (
        <div
          className={`d-flex align-items-center justify-content-center w-100 text-center ${styles.minHeight}`}
        >
          No Campaigns available in this segment..
        </div>
      ) : (
        <InfiniteScroll
          hasMoreBottom={hasMore}
          loadMoreFromBottom={fetchMore}
          className={`${styles.infiniteScroll}`}
        >
          {campaigns.map(({ id, name, status }) => {
            return (
              <div
                key={id}
                className={`${styles.tableRow} mb-2 px-2`}
                onClick={() => navigateCallback(id + "")}
              >
                <div className={`${styles.itemOne}`}>
                  <span className={`${styles.element}`}>{name}</span>
                </div>
                <div className={`${styles.itemTwo}`}>
                  <div
                    className={`px-2 ${
                      status === ECampaignStatus.Published
                        ? styles.publicStatus
                        : status === ECampaignStatus.Paused
                          ? styles.pauseStatus
                          : styles.draftStatus
                    }`}
                  >
                    <span>
                      {status === ECampaignStatus.Published
                        ? "Published"
                        : status === ECampaignStatus.Paused
                          ? "Paused"
                          : "Draft"}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      )}

      <div className="d-flex justify-content-center align-items-center mt-4">
        <button
          className={`px-1 ${styles.submitBtn}`}
          onClick={onHide}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default ViewCampaign;
