import React, { useEffect, useMemo, useState } from "react";
import ReactSelect, {
  ActionMeta,
  components,
  GroupBase,
  MultiValue,
  MultiValueProps,
  OptionProps,
  StylesConfig,
} from "react-select";
import styles from "./selectUserSegment.module.scss";
import { useUserSegment } from "./useUserSegment";
import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

type OptionType = {
  label: string;
  value: string;
};

interface Props {
  name: string;
  userSegmentOptions: BotProfile["userSegmentsDetails"];
  onchange: (newValue: { value: string }[]) => void;
}

const SelectUserSegment = ({
  name,
  userSegmentOptions,
  onchange
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(
    userSegmentOptions ?? []
  );

  const IS_MULTI = true;

  // Fetch options from API
  const { options, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useUserSegment();

  // filter the options before passing it 
  const allOptions = useMemo(() => {
    return options.filter((opt) => !selectedOptions.some((val) => opt.value == val.value));
  }, [options, selectedOptions])

  const OptionComponent: React.ComponentType<
    OptionProps<OptionType, typeof IS_MULTI, GroupBase<OptionType>>
  > = useMemo(
    () => (props) => {

      return (
        <components.Option {...props}>
        </components.Option>
      );
    },
    []
  );


  // Custom MultiValue component
  const MultiValueComponent: React.ComponentType<
    MultiValueProps<OptionType, typeof IS_MULTI, GroupBase<OptionType>>
  > = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  // Custom dropdown indicator component with conditional icon
  const CustomDropdownIndicator = (props: any) => {
    const { selectProps } = props;

    return (
      <components.DropdownIndicator {...props}>
        <i
          className={`fa-solid ${selectProps.menuIsOpen ? "fa-chevron-up" : "fa-chevron-down"
            }`}
        ></i>
      </components.DropdownIndicator>
    );
  };

  // Custom MenuList component to add new segment button
  const MenuList: React.ComponentType<any> = (props) => (
    <div className={styles.optionHeader}>
      <a className="pt-1" href="/customer-segments" target="_blank">
        <div className={styles.addNewDiv}>
          <div className={styles.plusDiv}>+</div>{" "}
          <span className={styles.addnewText}>Add new segment</span>
        </div>
      </a>

      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );

  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    // Check if the new selection exceeds 3 items
    if (Array.isArray(newValue) && newValue.length > 3) {
      pushTheToast({
        text: "You can select a maximum of 3 user segments",
        position: "top-right",
        type: "warning",
      });
      return; // Prevent further action if the limit is reached
    }
    setSelectedOptions([...newValue]); // Update selected options
    onchange([...newValue]); // Trigger external onchange
  };


  const defaultStyles: StylesConfig<
    OptionType,
    typeof IS_MULTI,
    GroupBase<OptionType>
  > = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FCFCFC",
      color: "#000",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
      minHeight: "37px",
      borderRadius: "2px",
      "&:hover": {
        borderColor: "#ECECEC",
      },
      border: "1px solid #ECECEC",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      color: "#000",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#707070",
      ":hover": {
        color: "#707070",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#000",
      maxWidth: "285px",
      height: "34px",
      borderRadius: "2px",
      backgroundColor: "#FFFFFF",
      marginTop: "5px",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#707070",
      },
      padding: 2,
      paddingLeft: "15px"
    }),
    menu: (provided) => ({
      ...provided,
      padding: "0px",
      width: "292px",
      maxHeight: "247px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 16px #00000029",
      borderRadius: "5px",
      opacity: 1,
      marginBottom: "5px",
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      padding: 0,
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
      marginBottom: 5,
    }),
  };

  return (
    <div>
      <ReactSelect
        closeMenuOnSelect={false}
        hideSelectedOptions={true}
        name={name}
        options={allOptions}
        className="border-danger"
        components={{
          Option: OptionComponent,
          MultiValue: MultiValueComponent,
          MenuList,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        isMulti={IS_MULTI}
        value={selectedOptions}
        placeholder={"Select user segment"}
        onChange={handleChange}
        styles={defaultStyles}
        isLoading={false}
        onMenuScrollToBottom={() => {
          if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        }}
      />
    </div>
  );
};

export default SelectUserSegment;
