import { useMemo } from "react";
import CampaignTemplate2 from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EmailPreview/Children/CampaignEmail/Children/CampaignTemplate2/CampaignTemplate2";
import CampaignTemplate1 from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EmailPreview/Children/CampaignEmail/Children/CampaignTemplate1/CampaignTemplate1";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { useCampaignTemplateData } from "src/routes/Campaigns/Children/hooks/useCampaignData";
import { replaceKeyWithVariableName } from "src/utils/utils";
import imgUpload from "src/assets/images/imgUpload.png";
import styles from "./CampaignModal.module.scss";
import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";
import Loader from "src/components/Loader";
import { useNavigate } from "react-router-dom";
import ParseHTML from "src/components/ParseHTML";

interface Props {
  onHide: () => void;
  campaignId: number;
  templateId: number;
  stepName: string;
  sentAt: string;
  ticketId: number;
}
const CampaignModal = ({
  onHide,
  campaignId,
  templateId,
  stepName,
  sentAt,
  ticketId,
}: Props) => {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useCampaignTemplateData(
    campaignId,
    templateId,
    ticketId,
  );
  const colors = {
    backgroundColor: "#FBFAFF",
    textColor: "#3F3F44",
    headingColor: "#000",
    borderColor: "#DBD9E5",
    featureBackgroundColor: "#fefefe",
    containerBackgroundColor: "#f4f4f4",
    footerBackgroundColor: "#f4f4f4",
  };

  // const commonVariablesList = useMemo(() => {
  //   return {
  //     1: {
  //       id: "1",
  //       variableName: "First Name",
  //       example: "John",
  //       variableKey: "{{first_name}}",
  //     },
  //     2: {
  //       id: "2",
  //       variableName: "acme",
  //       example: data?.brand?.name ?? "acme",
  //       variableKey: "{{brand}}",
  //     },
  //     3: {
  //       id: "3",
  //       variableName: "Brand Email",
  //       example: data?.brand?.email ?? "example@example.com",
  //       variableKey: "{{brand_email}}",
  //     },
  //   } as { [x: string]: IVariableB };
  // }, [data?.brand]);

  // const commonVariableIdsList: string[] = ["1", "2"];

  // const salutation = useMemo(
  //   () =>
  //     replaceKeyWithVariableName(
  //       data?.emailTemplate?.emailTemplateData?.salutation || "",
  //       commonVariablesList,
  //     ),
  //   [data?.emailTemplate?.emailTemplateData?.salutation, commonVariablesList],
  // );

  // const introduction = useMemo(
  //   () =>
  //     replaceKeyWithVariableName(
  //       data?.emailTemplate?.emailTemplateData?.introduction || "",
  //       commonVariablesList,
  //     ),
  //   [data?.emailTemplate?.emailTemplateData?.introduction, commonVariablesList],
  // );

  // const body = useMemo(
  //   () =>
  //     replaceKeyWithVariableName(
  //       data?.emailTemplate?.emailTemplateData?.body || "",
  //       commonVariablesList,
  //     ),
  //   [data?.emailTemplate?.emailTemplateData?.body, commonVariablesList],
  // );
  // const heading = useMemo(
  //   () =>
  //     replaceKeyWithVariableName(
  //       data?.emailTemplate?.emailTemplateData?.heading || "",
  //       commonVariablesList,
  //     ),
  //   [data?.emailTemplate?.emailTemplateData?.heading, commonVariablesList],
  // );

  // const logoUrl =
  //   data?.emailTemplate?.emailTemplateData?.logoUrl &&
  //   data?.emailTemplate?.emailTemplateData?.logoUrl?.trim()
  //     ? data?.emailTemplate?.emailTemplateData?.logoUrl
  //     : imgUpload;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className={`mb-0 ${styles.modalHead}`}>{stepName}</h5>
        <span
          className={`cursor-pointer ${styles.modalClose}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <div className="d-flex align-items-center justify-content-center text-danger">
          Something Went Wrong!
        </div>
      ) : (
        <div>
          <div>
            <p
              className={`mb-1 ${styles.campaignName} cursor-pointer`}
              onClick={() => navigate(`/campaigns/${campaignId}`)}
            >
              Name:{" "}
              <span className={`${styles.name}`}>
                {data?.campaignName ?? ""}
              </span>
            </p>
            <div className="d-flex align-items-center mb-3">
              <span className={`${styles.sentText}`}>Campaign sent:</span>
              <span className={`px-1 ${styles.sentText}`}>
                <i className="fa-solid fa-clock"></i>
              </span>
              <span className={`${styles.sentText}`}>
                {getMonthNameDateAmPmTime(sentAt)}
              </span>
            </div>
          </div>
          <div className={`${styles.templatewrapper}`}>
            <ParseHTML html={data?.html ?? ""} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignModal;
