import { axiosJSON } from "src/globals/axiosEndPoints";

export type CompareTypes =
  | "is"
  | "isNot"
  | "startsWith"
  | "endsWith"
  | "contains"
  | "containsExactWord"
  | "doesNotContain"
  | "isUnknown"
  | "greaterThan"
  | "moreThan"
  | "lessThan"
  | "isBetween"
  | "exactly"
  | "after"
  | "on"
  | "before";

export type LogicalOperatorTypes = "or" | "and";

export type FieldDataDateTypes =
  | "date"
  | "dateTime"
  | "dateOrDateTime"
  | "dateRange"
  | "dateTimeRange"
  | "dateOrDateTimeRange";

export type FieldDataTypes =
  | "string"
  | "number"
  | "numberRange"
  | "noValue"
  | "select"
  | FieldDataDateTypes;

export interface CompareDetail {
  compareType: CompareTypes;
  compareName: string;
  compareKey?: string;
  fieldDataType: FieldDataTypes;
  min?: number;
  max?: number;
  suffix?: string;
}

export interface CompareData {
  groupName: string;
  compareDetails: Array<CompareDetail | CompareData>;
}

export interface FieldValueInstance {
  key: string;
  name: string;
  fieldValues?: Array<FieldValueInstance>;
  isMulti?: boolean;
}

export interface ConditionOption {
  fieldKey: string;
  fieldName: string;
  type: string;
  placeholder?: string | null;
  fieldDescription: string;
  compareDetails: Array<CompareDetail | CompareData>;
  fieldValues?: Array<FieldValueInstance>;
  isMulti?: boolean;
  isSearchable?: boolean;
  typeKey?: string;
}

export interface ConditionOptionsResponse {
  defaultConditions?: ConditionOption[];
  customeConditions?: ConditionOption[];
}

interface GetConditionOptionsPayload {
  start?: number;
  limit?: number;
  isDefaultRequired: boolean;
  segmentId?: string;
}

export async function getConditionOptionsService({
  segmentType,
  payload,
}: {
  segmentType: string;
  payload: GetConditionOptionsPayload;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/segment/${segmentType}/getConditionOptions`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as ConditionOptionsResponse;
}
