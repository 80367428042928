import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getSegmentCampaignService, {
  ISegmentCampaign,
} from "src/services/report/getSegmentCampaign.service";

const useFetchSegmentCampaign = (segmentId: string | number | null) => {
  const { data, status, isFetching } = useQuery<ISegmentCampaign, Error>(
    ["getSegmentCampaignService", segmentId],
    {
      queryFn: async () => getSegmentCampaignService({ segmentId }),
      cacheTime: 60000,
      staleTime: 60000,
    },
  );

  const fetchStatus: AJAXSTATUS = useMemo(
    () =>
      isFetching
        ? "pending"
        : status === "success"
          ? "fulfilled"
          : status === "loading"
            ? "pending"
            : "rejected",
    [isFetching, status],
  );

  return { data, fetchStatus };
};

export default useFetchSegmentCampaign;
