import { useCallback, useMemo, useState } from "react";
import styles from "./AddStepModal.module.scss";
import { CampaignStepData } from "src/services/Campaign/addCampaignStep.service";

const MIN_DURATION = 0;
const MAX_DURATION = 1000;

const AddStepModal = ({
  campaignId,
  onSave,
  onHide,
}: {
  campaignId: number;
  onSave: (val: CampaignStepData) => void;
  onHide: () => void;
}) => {
  const [duration, setDuration] = useState<string>("0");
  const [channel, setChannel] = useState<"email" | "livechat" | null>(null);
  const [draftDetails, setDraftDetails] = useState<string>("");

  const [showError, setShowError] = useState<boolean>(false);

  const parsedDuration = useMemo(() => parseInt(duration), [duration]);

  const onSubmit = useCallback(() => {
    if (
      isNaN(parsedDuration) ||
      parsedDuration < MIN_DURATION ||
      parsedDuration > MAX_DURATION
    ) {
      setShowError(true);
      return;
    }

    if (!channel) {
      setShowError(true);
      return;
    }

    onSave({
      campaignId,
      channel,
      draftDetails,
      duration: parsedDuration,
      templateId: "",
    });
  }, [campaignId, channel, draftDetails, onSave, parsedDuration]);

  return (
    <div>
      <h4 className={`mb-4 ${styles.modalHead}`}>Add Step</h4>
      <div className="mb-3">
        <label
          htmlFor="exampleFormControlInput1"
          className={`form-label ${styles.questionLabel}`}
        >
          Q1. When to fire this step?
        </label>
        <div className="d-flex align-items-center me-3">
          <div className="position-relative">
            <input
              type="number"
              value={duration}
              min={MIN_DURATION}
              max={MAX_DURATION}
              onChange={(e) => setDuration(e.target.value)}
              placeholder="00"
              className={`ps-2 ${styles.daysBox} ${
                showError &&
                (isNaN(parsedDuration) ||
                  parsedDuration < MIN_DURATION ||
                  parsedDuration > MAX_DURATION)
                  ? styles.errorBorder
                  : ""
              }`}
            />
            <div
              className={`d-flex flex-column justify-content-center ${styles.arrowWrapper}`}
            >
              <span
                className={`${styles.arrows}`}
                onClick={() =>
                  setDuration(
                    parsedDuration >= MAX_DURATION
                      ? MAX_DURATION + ""
                      : parsedDuration + 1 + "",
                  )
                }
              >
                <i className="fa-solid fa-angle-up"></i>
              </span>
              <span
                className={`${styles.downArrow}`}
                onClick={() =>
                  setDuration(
                    parsedDuration <= MIN_DURATION
                      ? MIN_DURATION + ""
                      : parsedDuration - 1 + "",
                  )
                }
              >
                <i className="fa-solid fa-angle-down"></i>
              </span>
            </div>
          </div>
          <span className={`${styles.daysText} ps-1`}>Days after trigger</span>
        </div>
      </div>
      <div className="mb-3">
        <label
          htmlFor="exampleFormControlInput1"
          className={`form-label ${styles.questionLabel}`}
        >
          Q2. What is the channel for this step?
        </label>

        <div className="form-check">
          <input
            className={`form-check-input ${styles.radioCheck}  ${
              showError && channel === null ? styles.errorBorder : ""
            }`}
            type="radio"
            name="channle_radio"
            id="email_channel_radio"
            checked={channel === "email"}
            onClick={() => setChannel("email")}
          />
          <label
            className={`form-check-label ${styles.radioLabel}`}
            htmlFor="email_channel_radio"
          >
            Email
          </label>
        </div>
        <div className="form-check">
          <input
            className={`form-check-input ${styles.radioCheck} ${
              showError && channel === null ? styles.errorBorder : ""
            }`}
            type="radio"
            name="channle_radio"
            id="chat_channel_radio"
            checked={channel === "livechat"}
            onClick={() => setChannel("livechat")}
          />
          <label
            className={`form-check-label ${styles.radioLabel}`}
            htmlFor="chat_channel_radio"
          >
            Live chat
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label
          htmlFor="draft_details_text"
          className={`form-label ${styles.questionLabel}`}
        >
          Q3. Do you wish to provide details to the bot for drafting for this
          step? <span className={`${styles.optionalText}`}>(optional) </span>
        </label>
        <textarea
          className={`form-control ${styles.textBox}`}
          id="draft_details_text"
          rows={3}
          placeholder="Add details here..."
          value={draftDetails}
          onChange={(e) => setDraftDetails(e.target.value)}
        ></textarea>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <button
          className={`px-1 ${styles.cancelBtn}`}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className={`px-1 ${styles.submitBtn}`}
          onClick={onSubmit}
        >
          View email
        </button>
      </div>
    </div>
  );
};

export default AddStepModal;
