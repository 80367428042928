import { useMemo, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "src/components/Loader";
import { TimelineEvent } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import AttributeDetailExpanded from "../AttributeDetailExpanded/AttributeDetailExpanded";
import AttributeDetails from "../AttributeDetails/AttributeDetails";
import Timeline from "./Timeline/Timeline";
import styles from "./TimelineInfo.module.scss";
import { useTimeline } from "./TimelineProvider/TimelineProvider";
import SingleTimelineSegment from "./Children/SingleTimelineSegment/SingleTimelineSegment";

const TimelineInfo = ({
  showTimeline,
  timelineRef,
}: {
  showTimeline: boolean;
  timelineRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { state, dispatch } = useTimeline();
  const { timelineInfo, loading, error } = state;
  const showTimelineRef = useRef(showTimeline);

  const showLoader = useMemo(() => {
    if (timelineInfo) {
      return false;
    }
    return loading;
  }, [loading, timelineInfo]);

  const currentState = useRef({
    lastTimelineBgColor: "#B1E3FF",
  });

  useMemo(() => {
    try {
      const r: any = document.querySelector(":root");
      if (r) {
        if (showTimeline) {
          r.style.setProperty(
            "--inner-ticket-main-chat-container",
            "calc(100vh - 271px)",
          );
        } else {
          r.style.setProperty(
            "--inner-ticket-main-chat-container",
            "calc(100vh - 216px)",
          );
        }
      }
    } catch (e) {}
    showTimelineRef.current = showTimeline;
  }, [showTimeline]);

  if (showLoader) {
    return (
      <div className={`${styles.loader}`}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className={`${styles.error}`}>{error}</div>;
  }

  if (!timelineInfo) {
    return null;
  }

  return (
    <div
      className={`d-flex align-items-center position-relative justify-content-between pe-2 flex-shrink-0 ${styles.minWidth100}`}
      ref={timelineRef}
    >
      <div
        className={`d-flex align-items-center w-100`}
        style={{
          height: `${showTimeline ? "180px" : "100px"}`,
          padding: "0 0 0 0px",
        }}
      >
        <div className="d-flex w-100">
          <div className="position-relative d-flex align-items-center w-100">
            <div
              style={{
                borderTop: "8px dashed #B1B1B1",
                backgroundColor: "#fff",
              }}
              className={`${styles.verticalLine} w-100 ${
                showTimeline ? styles.expanded : ""
              }`}
            ></div>
          </div>
        </div>
        {timelineInfo.timeline && timelineInfo.timeline.length ? (
          <span className={`px-2 d-none ${styles.startYear}`}>
            {timelineInfo.timeline[0].year}
          </span>
        ) : null}
        {timelineInfo.timeline?.map((yearData, index) => (
          <SingleTimelineSegment
            key={yearData.label + index}
            yearData={yearData}
            showTimeline={showTimeline}
            currentState={currentState}
          />
        ))}
        <div className="d-flex w-100">
          <div className="position-relative d-flex align-items-center w-100">
            <div
              style={{
                borderTop: "8px dashed #B1B1B1",
                backgroundColor: "#fff",
              }}
              className={`${styles.verticalLine} w-100 ${
                showTimeline ? styles.expanded : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineInfo;
