import { Modal } from "react-bootstrap";
import rocketIcon from "src/assets/images/rocket.svg";
import styles from "./UpgradeModal.module.scss";
import { PossibleHiddenFeatures } from "src/services/Settings/Users/getCurrentUser";
import {
  LiveChatIconMainSB,
  ReportsIconMainSB,
  TicketIconMainSB,
} from "src/containers/SideBar/children/MainSB/MainSB";

const RocketSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23.98"
      viewBox="0 0 24 23.98"
    >
      <g
        id="Group_23301"
        data-name="Group 23301"
        transform="translate(-974.637 -16.001)"
      >
        <g
          id="Icon_ionic-ios-rocket"
          data-name="Icon ionic-ios-rocket"
          transform="translate(974.637 16.001)"
        >
          <path
            id="Path_246"
            data-name="Path 246"
            d="M27.239,3.7a.255.255,0,0,0-.19-.2C23.912,2.734,16.662,5.468,12.734,9.4a16.978,16.978,0,0,0-1.909,2.255,7.1,7.1,0,0,0-3.455.433c-2.913,1.28-3.76,4.626-4,6.062a.517.517,0,0,0,.565.6l4.677-.513a10.4,10.4,0,0,0,.063,1.055,1.047,1.047,0,0,0,.306.64L10.8,21.738a1.047,1.047,0,0,0,.64.306,10.378,10.378,0,0,0,1.05.063l-.513,4.672a.517.517,0,0,0,.6.565c1.436-.231,4.787-1.079,6.062-3.991a7.136,7.136,0,0,0,.438-3.443A17,17,0,0,0,21.334,18C25.279,14.079,28,6.991,27.239,3.7ZM17.827,12.92a2.463,2.463,0,1,1,3.484,0A2.461,2.461,0,0,1,17.827,12.92Z"
            transform="translate(-3.366 -3.372)"
            fill="#ff8499"
          />
          <path
            id="Path_247"
            data-name="Path 247"
            d="M10.631,25.892h0a1.993,1.993,0,0,1-1.04.42,1.414,1.414,0,0,1-1.629-1.629,1.954,1.954,0,0,1,.415-1.035l.005-.005a.176.176,0,0,0-.147-.3,2.415,2.415,0,0,0-1.413.688c-.946.946-1.035,4.456-1.035,4.456S9.3,28.4,10.248,27.452a2.4,2.4,0,0,0,.688-1.413A.18.18,0,0,0,10.631,25.892Z"
            transform="translate(-3.682 -6.591)"
            fill="#ff8499"
          />
        </g>
      </g>
    </svg>
  );
};

export const UpgradeBytePlanUI = ({
  icon,
  upgrade,
}: {
  icon: null | PossibleHiddenFeatures;
  upgrade: () => void;
}) => {
  return (
    <div className="px-4">
      <div className={`${styles.topIcon} mb-3 mx-auto`}>
        {icon === "knowledge_base" && (
          <i
            className="fa-solid fa-book-open"
            style={{ color: "#FF8499", fontSize: "1.5rem" }}
          ></i>
        )}
        {icon === "ticket_view" && <TicketIconMainSB iconType="pink" />}
        {icon === "self_service" && (
          <i
            className="fa-solid fa-code-pull-request"
            style={{ color: "#FF8499", fontSize: "1.5rem" }}
          ></i>
        )}
        {icon === "customers" && (
          <i
            className="fa-solid fa-users"
            style={{ color: "#FF8499", fontSize: "1.5rem" }}
          ></i>
        )}
        {icon === "reports" && <ReportsIconMainSB iconType="pink" />}
        {icon === "call_recording" && (
          <i
            className="fa-solid fa-phone"
            style={{ color: "#FF8499", fontSize: "1.5rem" }}
          ></i>
        )}
        {icon === "live_chat" && <LiveChatIconMainSB iconType="pink" />}
        {icon === "automations" && (
          <i
            className="fa-solid fa-code-fork"
            style={{ color: "#FF8499", fontSize: "1.5rem" }}
          ></i>
        )}
        {icon === null && <RocketSVG />}
      </div>

      <div className={`${styles.title} mb-2 mx-auto`}>{`Upgrade Required`}</div>
      <div className={`${styles.subtitle} mx-auto mb-3`}>
        {`You're currently on the Byte Bot plan. This feature is available in higher plans. Upgrade now to unlock this functionality and take your experience to the next level!`}
      </div>
      <div className="d-flex justify-content-center mb-2 px-2 pt-1">
        <button
          className={`px-2 ${styles.upgradeBtn}`}
          onClick={upgrade}
        >
          <img
            src={rocketIcon}
            className="me-1 my-auto"
            alt=""
          />
          <span className="my-auto">Upgrade Plan</span>
        </button>
      </div>
    </div>
  );
};

const UpgradeModal = ({
  icon,
  showModal,
  upgrade,
  onHide,
}: {
  icon: null | PossibleHiddenFeatures;
  showModal: boolean;
  upgrade: () => void;
  onHide: () => void;
}) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      contentClassName={`${styles.modalContent}`}
      dialogClassName={`${styles.dialogClass}`}
      backdropClassName={`${styles.backDropClass}`}
      id="MainSBPricingUpgradeModal"
      centered
    >
      <UpgradeBytePlanUI
        upgrade={upgrade}
        icon={icon}
      />
    </Modal>
  );
};

export default UpgradeModal;
