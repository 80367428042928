import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AutomationAPIPayload } from "src/services/Automation/createAutomation.service";
import {
  GetAllAutomationParams,
  GetAllAutomationsResponse,
  getAllAutomationsService,
} from "src/services/Automation/getAllAutomations.service";
import { IAutomationListParams } from "./AutomationList";

/**
 * Initial payload params
 */
const INITIAL_PAYLOAD: GetAllAutomationParams = {
  start: 0,
  limit: 10,
};

const useAutomationList = () => {
  const { type } = useParams<IAutomationListParams>();
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    return [`getAllAutomationsService`, type, INITIAL_PAYLOAD];
  }, [type]);

  const { status, data, hasNextPage, fetchNextPage, isLoading, fetchStatus } =
    useInfiniteQuery({
      queryKey: queryKey,
      queryFn: ({ pageParam = { type, payload: INITIAL_PAYLOAD } }) =>
        getAllAutomationsService(pageParam),
      getNextPageParam: (
        prevPage: GetAllAutomationsResponse,
        _allPages: GetAllAutomationsResponse[],
      ) => {
        // Return next page params if we have more pages
        if (prevPage.metaData.hasMore) {
          return {
            type,
            payload: {
              limit: INITIAL_PAYLOAD.limit,
              start: prevPage.metaData.currentCount,
            },
          } as AutomationAPIPayload<GetAllAutomationParams>;
        }
        return null;
      },
    });

  const automations = useMemo(() => {
    let automationsData: GetAllAutomationsResponse["allAutomations"] = {};
    data?.pages.forEach((page) => {
      automationsData = { ...automationsData, ...page.allAutomations };
    });
    return automationsData;
  }, [data]);

  const automationIds = useMemo(() => {
    const ids = data?.pages.flatMap((page) => page.allAutomationIds) ?? [];
    return Array.from(new Set([...ids]));
  }, [data]);

  const hasError = useMemo(() => {
    if (
      status === "error" ||
      (status !== "success" && fetchStatus !== "fetching") ||
      (status !== "loading" && automationIds.length === 0)
    ) {
      return true;
    }

    return false;
  }, [fetchStatus, automationIds.length, status]);

  const onDelete = useCallback(
    (integrationId: string) => {
      queryClient.setQueryData(queryKey, (oldData: any) => {
        if (!oldData) return oldData;

        const newPages = oldData.pages.map(
          (page: GetAllAutomationsResponse) => {
            const { allAutomations, allAutomationIds } = page;

            // Remove the automation from allAutomations
            const updatedAutomations = { ...allAutomations };
            delete updatedAutomations[integrationId];

            // Remove the automation ID from allAutomationIds
            const updatedAutomationIds = allAutomationIds.filter(
              (id) => id !== integrationId,
            );

            return {
              ...page,
              allAutomations: updatedAutomations,
              allAutomationIds: updatedAutomationIds,
            };
          },
        );

        return { ...oldData, pages: newPages };
      });

      // Invalidate and refetch the query to keep the data in sync with the server
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey],
  );

  // Specifically Used for Ticket routing , to enable/disable rule
  const onRuleToggle = useCallback(
    (integrationId: string, enabled: boolean) => {
      queryClient.setQueryData(queryKey, (oldData: any) => {
        if (!oldData) return oldData;

        const newPages = oldData.pages.map(
          (page: GetAllAutomationsResponse) => {
            const { allAutomations, allAutomationIds } = page;

            // Update the automation in allAutomations
            const updatedAutomations = {
              ...allAutomations,
              [integrationId]: {
                ...allAutomations[integrationId],
                published: enabled,
              },
            };

            return {
              ...page,
              allAutomations: updatedAutomations,
              allAutomationIds,
            };
          },
        );

        return { ...oldData, pages: newPages };
      });

      // Invalidate and refetch the query to keep the data in sync with the server
      queryClient.refetchQueries(queryKey);
    },
    [queryClient, queryKey],
  );

  /**
   * Updates the position of an integration within the `allAutomationIds` array.
   *
   * This function is used in drag-and-drop functionality to
   * reorder integrations within a list.
   *
   * @param {Object} params - Parameters for updating the integration position.
   * @param {string} params.integrationId - The ID of the integration to move.
   * @param {number | undefined} params.destinationIndex - The new index position of the integration in the `allAutomationIds` array.
   */
  const onPriorityUpdate = useCallback(
    ({
      integrationId,
      destinationIndex,
    }: {
      integrationId: string;
      destinationIndex: number | undefined;
    }) => {
      queryClient.setQueryData(queryKey, (oldData: any) => {
        if (!oldData) return oldData;

        const newPages = oldData.pages.map(
          (page: GetAllAutomationsResponse) => {
            const { allAutomations, allAutomationIds } = page;

            // Remove the integration from its current position
            const updatedAutomationIds = [...allAutomationIds].filter(
              (id) => id !== integrationId,
            );

            // Insert the integration at the new destination index
            if (destinationIndex !== undefined) {
              updatedAutomationIds.splice(destinationIndex, 0, integrationId);
            }

            return {
              ...page,
              allAutomations,
              allAutomationIds: updatedAutomationIds, // Update with the new order
            };
          },
        );

        return { ...oldData, pages: newPages };
      });

      // Invalidate and refetch the query to keep the data in sync with the server
      // queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey],
  );
  const refetchAutomations = useCallback(() => {
    // Invalidate and refetch the query to keep the data in sync with the server
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  return {
    type,
    automations,
    automationIds,
    fetchNextPage,
    onDelete,
    hasNextPage,
    hasError,
    isLoading,
    onRuleToggle,
    onPriorityUpdate,
    refetchAutomations,
  };
};

export default useAutomationList;
