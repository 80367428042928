import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import {
  ConditionOption,
  ConditionOptionsResponse,
  getConditionOptionsService,
} from "src/services/CustomerSegments/getConditionOptions.service";

const LIMIT = 10;

/**
 * Custom hook to fetch condition options based on the provided segment type.
 *
 * This hook utilizes the `useInfiniteQuery` hook from `@tanstack/react-query` to fetch
 * condition options from the `getConditionOptionsService` based on the specified
 * segment type. The query result is cached for an hour (`cacheTime` and `staleTime`
 * set to 3600000 ms), ensuring that the data remains fresh for the specified duration.
 *
 * @param segmentType - The type of the segment for which condition options are to be fetched.
 * @returns An object containing the fetched condition options and the status of the request.
 * conditionOptions - The fetched condition options, or null if the request failed.
 * conditionOptionsStatus - The status of the request, which can be one of "fulfilled", "pending", or "rejected".
 *
 * @example
 * const { conditionOptions, conditionOptionsStatus } = useFetchConditionOptions('segmentType');
 *
 * @see {@link getConditionOptionsService} for the service used to fetch condition options.
 */
function useFetchConditionOptions(segmentType: string, segmentId?: string) {
  const { data, status, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<
      ConditionOptionsResponse,
      unknown,
      ConditionOptionsResponse
    >(["useFetchConditionOptions", segmentType, segmentId], {
      queryFn: async ({
        pageParam = {
          isDefaultRequired: true,
          segmentId,
          start: 0,
          limit: LIMIT,
        },
      }) => {
        return await getConditionOptionsService({
          segmentType,
          payload: pageParam,
        });
      },
      getNextPageParam: (currentPage, allPages) => {
        if (
          !currentPage.customeConditions ||
          currentPage.customeConditions.length < LIMIT
        ) {
          return null;
        }

        return {
          isDefaultRequired: !allPages.some(
            (val) => val.defaultConditions !== undefined,
          ),
          start: allPages.flatMap((val) => val.customeConditions ?? []).length,
          limit: LIMIT,
        };
      },
      cacheTime: 3600000,
      staleTime: 3600000,
      networkMode: "always",
    });

  const conditionOptions = useMemo(() => {
    if (!data) return null;

    const defaultConditions = data.pages.flatMap(
      (val) => val.defaultConditions ?? [],
    );

    // Create a Map to ensure unique customeConditions based on fieldKey
    const customeConditionsMap = new Map<string, ConditionOption>();

    data.pages
      .flatMap((val) => val.customeConditions ?? [])
      .forEach((condition) => {
        customeConditionsMap.set(condition.fieldKey, condition);
      });

    // Convert Map to array and sort by fieldKey in ascending order
    const customeConditions = Array.from(customeConditionsMap.values()).sort(
      (a, b) => {
        return parseInt(a.fieldKey, 10) - parseInt(b.fieldKey, 10);
      },
    );

    return {
      defaultConditions,
      customeConditions,
    };
  }, [data]);

  const findOptionByFieldKey = useCallback(
    (fieldKey: string) => {
      return (
        conditionOptions?.defaultConditions?.find(
          (v) => v.fieldKey === fieldKey,
        ) ||
        conditionOptions?.customeConditions?.find(
          (v) => v.fieldKey === fieldKey,
        ) ||
        null
      );
    },
    [conditionOptions],
  );

  return {
    conditionOptions: conditionOptions
      ? conditionOptions
      : (null as ConditionOptionsResponse | null),
    conditionOptionsStatus:
      status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : ("rejected" as AJAXSTATUS),
    findOptionByFieldKey,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
}

export default useFetchConditionOptions;
