/**
 * This file defines a React component responsible for rendering kb articles in answer source section
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Jayalakshmi-busibud
 * @author @Anubhav-busibud
 */
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./KBArticleSource.module.scss";
import ArticleBrand from "../ImportModal/Children/ArticleBrand/ArticleBrand";
import { SetStateAction } from "react";
import useKbArticleSource from "./useKbArticleSource";
import { useAppSelector } from "src/store/store";
import PlanUpgrade from "src/components/PlanUpgrade/PlanUpgrade";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function KBArticleSource() {
  const {
    handleBackClick,
    search,
    setSearch,
    onSearch,
    articles,
    isLoading,
    hasNextPage,
    fetchNextPage,
    handleButtonClick,
    isRefetching,
    currentPage,
    totalPages,
    changePage,
    isbotLoading,
    integrationId,
    disabledFeatures,
    updateArticlebotTrainingStatus,
  } = useKbArticleSource();

  return (
    <div className={`w-100 p-3 ${styles.mainWrapper}`}>
      <div className="justify-content-between  w-100 d-flex">
        <div className="d-flex">
          <div
            className={`cursor-pointer ${styles.backArrow}`}
            onClick={handleBackClick}
          >
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
          </div>
          <div className="ms-2">
            <h3 className={`mb-0 ${styles.heading}`}>
              Knowledge base articles
            </h3>
            <span className={`d-block ${styles.subHeading}`}>
              Cern will use all knowledgebase articles here to answer customer
              questions
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {articles?.length !== 0 &&
            !(disabledFeatures && disabledFeatures?.includes("bot_profile")) ? (
            <SearchBar
              className={`${styles.search} px-2`}
              inputClassName={`${styles.input}`}
              placeholder={`Search page`}
              value={search}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setSearch(e.target.value)
              }
            />
          ) : null}
          <OverlayTrigger
            trigger={"hover"}
            placement="top-start"
            overlay={
              disabledFeatures?.includes("bot_profile") ? (
                <Tooltip className={`${styles.toolTipCustom}`}>
                  <div className={`${styles.tooltipBody} p-1`}>
                    <span>Upgrade to train knowledge base articles</span>
                  </div>
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <div>
              <button
                className={`ms-2 ${styles.importBtn} ${isbotLoading ||
                  (disabledFeatures && disabledFeatures.includes("bot_profile"))
                  ? styles.buttonDisabled
                  : ""
                  } ${articles?.length === 0 ? `d-none` : `d-block`}`}
                onClick={handleButtonClick}
              >
                <span className="pe-1">
                  <i className="fa-solid fa-plus"></i>
                </span>{" "}
                Add new article
              </button>
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div>
        {disabledFeatures && disabledFeatures.includes("bot_profile") ? (
          <div className={`${styles.upgradeBox}`}>
            <PlanUpgrade
              upgradeText={"Upgrade to paid plan"}
              subText={
                "To train on knowledge base articles upgrade to paid plan"
              }
            />
          </div>
        ) : (
          <ArticleBrand
            articles={articles}
            isLoading={isLoading}
            isRefetching={isRefetching}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            onSearch={onSearch}
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={changePage}
            integrationId={integrationId}
            updateArticlebotTrainingStatus={updateArticlebotTrainingStatus}
            isbotLoading={isbotLoading}
            disabledFeatures={disabledFeatures}
            handleButtonClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
}

export default KBArticleSource;
