import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllCustomerNoteParams {
  start: number;
  limit: number;
  sortBy: "asc" | "desc";
  customerId: string;
}

export interface ICustomerNote {
  id: string;
  noteText: string;
  createdAtGmt: string;
  agentDetails: {
    id: string;
    name: string;
    imageURL?: string | null;
  };
}

export interface GetAllCustomerNoteResponse {
  allNotes: Record<string, ICustomerNote>;
  allNoteIds: string[];
  metaData: {
    totalCount: number;
  };
}

const getAllCustomerNoteService = async (params: GetAllCustomerNoteParams) => {
  const { data: res } = await axiosJSON.post(
    `/api/customer/note/getAll`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllCustomerNoteResponse = {
    allNotes: {},
    allNoteIds: [],
    metaData: res.metaData,
  };

  res.data.forEach((note: ICustomerNote) => {
    const id = note.id;
    ret.allNoteIds.push(id);
    ret.allNotes[id] = note;
  });

  return ret;
};

export default getAllCustomerNoteService;
