import { useCallback, useMemo } from "react";
import { useSelectedComparisionType } from "../ComparisonTypes/ComparisonTypes";
import MultiSelectStringInput from "./children/MultiSelectStringInput";
import NumberInput from "./children/NumberInput";
import StringInput from "./children/StringInput";
import {
  CompareDetail,
  ConditionI,
  Group,
} from "src/services/Automation/getAutomationById.service";
import { useConditionGroupState } from "../../../../../hooks/useConditionGroupState";
import {
  BetweenRange,
  CompareData,
  Currency,
  CurrencyBetweenRange,
  FieldValueInstance,
  HoursDays,
} from "src/services/Automation/AutomationTicketRouting/getConditionOptions.service";
import SelectInput from "./children/SelectInput";
import DateTimeSelector from "./children/DateTimeSelector";
import DateTimeSelectorRange from "./children/DateTimeSelectorRange";
import {
  emailCompareType,
  emailConditionTypes,
} from "../../../../../utils/validations";
import { validateEmail } from "src/utils/utils";
import NumberInputRange from "./children/NumberInputRange";
interface Props {
  compareDetails: Array<CompareDetail | CompareData>;
  fieldValues?: Array<FieldValueInstance>;
  fieldKey: string;
  isMulti?: boolean;
  conditionIndex: number;
  groupIndex: number;
}

const ConditionValue = ({
  compareDetails,
  fieldValues = [],
  fieldKey,
  isMulti = false,
  conditionIndex,
  groupIndex,
}: Props) => {
  const { conditionGroup, updateState, showErrors } = useConditionGroupState();
  const { selectedComparisionType } = useSelectedComparisionType({
    options: compareDetails,
    conditionIndex,
    groupIndex,
  });

  const selectedvalue = useMemo(() => {
    const value = (conditionGroup?.conditions ?? [])[conditionIndex];
    if (value !== undefined) {
      return value;
    } else {
      return null;
    }
  }, [conditionGroup, groupIndex, conditionIndex]);

  const handleValueUpdate = useCallback(
    function (v: ConditionI["value"], labels?: Record<string, string[]>) {
      if (selectedComparisionType) {
        if (conditionGroup) {
          const newGroup: Group = { ...conditionGroup };
          if (newGroup) {
            // Check if the group and condition at the specified indexes exist
            if (newGroup && newGroup.conditions?.length) {
              if (newGroup.conditions[conditionIndex]) {
                // Merge the existing condition data with the new payload
                newGroup.conditions[conditionIndex] = {
                  ...newGroup.conditions[conditionIndex],
                  value: v,
                  valuesOfSelectKeys: labels,
                  fieldDataType: selectedComparisionType.fieldDataType,
                };
              }
            }
            updateState({
              conditionGroup: newGroup,
              showErrors: false,
              saveStatus: "idle",
            });
          }
        }
      }
    },
    [
      updateState,
      conditionIndex,
      groupIndex,
      conditionGroup,
      selectedComparisionType,
    ],
  );
  const hnadleHoursDaysUpdate = useCallback(
    (value: ConditionI["value"]) => {
      const val = {
        time: value,
        value: (selectedvalue?.value as HoursDays)?.value,
      } as HoursDays;
      handleValueUpdate(val);
    },
    [selectedvalue],
  );
  const handleCurrencyUpdate = useCallback(
    (value: ConditionI["value"]) => {
      const val = {
        currency: value,
        value: (selectedvalue?.value as Currency | CurrencyBetweenRange)?.value,
      } as Currency | CurrencyBetweenRange;
      handleValueUpdate(val);
    },
    [selectedvalue],
  );
  const handleCurrencyValueUpdate = useCallback(
    (value: ConditionI["value"]) => {
      const val = {
        currency: (selectedvalue?.value as Currency | CurrencyBetweenRange)
          ?.currency,
        value: value,
      } as Currency | CurrencyBetweenRange;
      handleValueUpdate(val);
    },
    [selectedvalue],
  );
  const hnadleHoursDaysValueUpdate = useCallback(
    (value: ConditionI["value"]) => {
      const val = {
        time: (selectedvalue?.value as HoursDays)?.time,
        value: value,
      } as HoursDays;
      handleValueUpdate(val);
    },
    [selectedvalue],
  );

  const handlePushIndex = useCallback(
    function (index: number) {
      if (conditionGroup) {
        const newGroup: Group = { ...conditionGroup };
        if (newGroup.conditions[conditionIndex]?.valueIndex) {
          (newGroup.conditions[conditionIndex].valueIndex as 1[])[index] = 1;
        } else {
          newGroup.conditions[conditionIndex].valueIndex = [1];
        }
        updateState({
          conditionGroup: newGroup,
          saveStatus: "idle",
        });
      }
    },
    [updateState, conditionIndex, groupIndex, conditionGroup],
  );
  const handlePopIndex = useCallback(
    function (index: number) {
      if (conditionGroup) {
        const newGroup: Group = { ...conditionGroup };
        if (newGroup.conditions[conditionIndex]?.valueIndex) {
          if (
            (newGroup.conditions[conditionIndex].valueIndex as 1[])[index] === 1
          ) {
            (newGroup.conditions[conditionIndex].valueIndex as 1[]).pop();
            if (newGroup.conditions[conditionIndex].valueIndex?.length === 0) {
              newGroup.conditions[conditionIndex].valueIndex = undefined;
            }
          }
        }
        updateState({
          conditionGroup: newGroup,
          saveStatus: "idle",
        });
      }
    },
    [updateState, conditionIndex, groupIndex, conditionGroup],
  );

  const handleSpecificTimeUpdate = useCallback(
    (isSpecificTime: boolean) => {
      if (selectedComparisionType) {
        if (conditionGroup) {
          const newGroup: Group = { ...conditionGroup };
          if (newGroup) {
            // Check if the group and condition at the specified indexes exist
            if (newGroup && newGroup.conditions?.length) {
              if (newGroup.conditions[conditionIndex]) {
                // Merge the existing condition data with the new payload
                newGroup.conditions[conditionIndex] = {
                  ...newGroup.conditions[conditionIndex],
                  isSpecificTime: isSpecificTime,
                };
              }
            }
            updateState({
              conditionGroup: newGroup,
              showErrors: false,
              saveStatus: "idle",
            });
          }
        }
      }
    },
    [
      updateState,
      conditionIndex,
      groupIndex,
      conditionGroup,
      selectedComparisionType,
    ],
  );

  const hoursDaysOptions = [
    {
      key: "hours",
      name: "Hours",
    },
    {
      key: "days",
      name: "Days",
    },
  ];
  const currencyOptions = [
    {
      key: "fixed",
      name: "USD",
    },
    {
      key: "percentage",
      name: "Percentage",
    },
  ];
  const isEmailError = useMemo(() => {
    const currentCondition = conditionGroup?.conditions?.[conditionIndex];

    if (
      currentCondition &&
      currentCondition?.conditionType &&
      emailConditionTypes.includes(currentCondition.conditionType) &&
      emailCompareType.includes(currentCondition.compareType ?? "") &&
      currentCondition.value &&
      (currentCondition.value as string[])?.some(
        (email) => !validateEmail(email ?? ""),
      )
    ) {
      return true;
    }
    return false;
  }, [conditionGroup, conditionIndex, emailConditionTypes, emailCompareType]);

  if (selectedComparisionType && selectedComparisionType.fieldDataType) {
    if (selectedComparisionType.fieldDataType === "string") {
      if (isMulti) {
        const value = selectedvalue?.value as Array<string> | null | undefined;
        return (
          <MultiSelectStringInput
            onChange={handleValueUpdate}
            placeholder={"Enter text here..."}
            isEmailError={isEmailError}
            value={value}
            error={showErrors ? "This field is required" : undefined}
          />
        );
      } else {
        return (
          <StringInput
            value={(selectedvalue?.value as string | null | undefined) ?? ""}
            onChange={handleValueUpdate}
            placeholder={"Enter text here..."}
            suffix={selectedComparisionType.suffix}
            error={showErrors ? "This field is required" : undefined}
          />
        );
      }
    } else if (selectedComparisionType.fieldDataType === "number") {
      return (
        <NumberInput
          value={(selectedvalue?.value as string | undefined) ?? ""}
          onChange={handleValueUpdate}
          placeholder={"0"}
          min={0}
          suffix={selectedComparisionType.suffix}
          isError={showErrors}
          showArrows={true}
        />
      );
    } else if (selectedComparisionType.fieldDataType === "currencyNumeric") {
      return (
        <div className="d-flex flex-column flex-md-row align-items-start mb-3 mt-2 flex-lg-row">
          <NumberInput
            value={(selectedvalue?.value as Currency)?.value ?? ""}
            onChange={handleCurrencyValueUpdate}
            placeholder={"0"}
            min={0}
            suffix={selectedComparisionType.suffix}
            isError={showErrors}
            showArrows={false}
          />
          <SelectInput
            fieldValues={currencyOptions}
            fieldKey={fieldKey}
            value={(selectedvalue?.value as Currency)?.currency ?? null}
            onChange={handleCurrencyUpdate}
            valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
            placeholder={"select"}
            isMulti={isMulti}
            error={showErrors ? "This field is required" : undefined}
            handlePush={() => {}}
            handlePop={() => {}}
            doNotFetchFromApi={true}
          />
        </div>
      );
    } else if (
      selectedComparisionType.fieldDataType === "currencyRangeNumeric"
    ) {
      return (
        <div className="d-flex flex-column flex-md-row align-items-start mb-3 mt-2 flex-lg-row">
          <NumberInputRange
            value={{
              start:
                (
                  (selectedvalue?.value as CurrencyBetweenRange)?.value as
                    | BetweenRange<string>
                    | undefined
                )?.start ?? "",
              end:
                (
                  (selectedvalue?.value as CurrencyBetweenRange)?.value as
                    | BetweenRange<string>
                    | undefined
                )?.end ?? "",
            }}
            onChange={handleCurrencyValueUpdate}
            placeholder={"0"}
            max={fieldKey === "conversationRating" ? 5 : undefined}
            min={0}
            suffix={selectedComparisionType.suffix}
            isError={showErrors}
            showArrows={false}
          />
          <SelectInput
            fieldValues={currencyOptions}
            fieldKey={fieldKey}
            value={
              (selectedvalue?.value as CurrencyBetweenRange)?.currency ?? null
            }
            onChange={handleCurrencyUpdate}
            valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
            placeholder={"select"}
            isMulti={isMulti}
            error={showErrors ? "This field is required" : undefined}
            handlePush={() => {}}
            handlePop={() => {}}
            doNotFetchFromApi={true}
          />
        </div>
      );
    } else if (selectedComparisionType.fieldDataType === "numberRange") {
      return (
        <NumberInputRange
          value={{
            start:
              (selectedvalue?.value as BetweenRange<string> | undefined)
                ?.start ?? "",
            end:
              (selectedvalue?.value as BetweenRange<string> | undefined)?.end ??
              "",
          }}
          onChange={handleValueUpdate}
          placeholder={"0"}
          max={fieldKey === "conversationRating" ? 5 : undefined}
          min={0}
          suffix={selectedComparisionType.suffix}
          isError={showErrors}
        />
      );
    } else if (selectedComparisionType.fieldDataType === "select") {
      return (
        <SelectInput
          fieldValues={fieldValues}
          fieldKey={fieldKey}
          value={
            (selectedvalue?.value as string | Array<string> | undefined) ?? null
          }
          onChange={handleValueUpdate}
          valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
          placeholder={"Choose option"}
          suffix={selectedComparisionType.suffix}
          isMulti={isMulti}
          error={showErrors ? "This field is required" : undefined}
          handlePush={handlePushIndex}
          handlePop={handlePopIndex}
        />
      );
    } else if (
      selectedComparisionType.fieldDataType === "dateTimeRange" ||
      selectedComparisionType.fieldDataType === "dateRange" ||
      selectedComparisionType.fieldDataType === "dateOrDateTimeRange"
    ) {
      return (
        <DateTimeSelectorRange
          value={{
            start:
              (selectedvalue?.value as BetweenRange<string> | undefined)
                ?.start ?? "",
            end:
              (selectedvalue?.value as BetweenRange<string> | undefined)?.end ??
              "",
          }}
          onChange={handleValueUpdate}
          dateType={selectedComparisionType.fieldDataType}
          suffix={selectedComparisionType.suffix}
          error={showErrors ? "This field is required" : undefined}
        />
      );
    } else if (
      selectedComparisionType.fieldDataType === "dateTime" ||
      selectedComparisionType.fieldDataType === "date" ||
      selectedComparisionType.fieldDataType === "dateOrDateTime"
    ) {
      return (
        <DateTimeSelector
          value={(selectedvalue?.value as string | undefined) ?? ""}
          onChange={handleValueUpdate}
          dateType={selectedComparisionType.fieldDataType}
          suffix={selectedComparisionType.suffix}
          onSpecificTimeChange={handleSpecificTimeUpdate}
          isSpecificTime={selectedvalue?.isSpecificTime}
          error={showErrors ? "This field is required" : undefined}
        />
      );
    } else if (selectedComparisionType.fieldDataType === "hoursOrDaysNumeric") {
      return (
        <div className="d-flex flex-column flex-md-row align-items-start mb-3 mt-2 flex-lg-row">
          <NumberInput
            value={(selectedvalue?.value as HoursDays)?.value ?? ""}
            onChange={hnadleHoursDaysValueUpdate}
            placeholder={"0"}
            min={0}
            suffix={selectedComparisionType.suffix}
            isError={showErrors}
          />
          <SelectInput
            fieldValues={hoursDaysOptions}
            fieldKey={fieldKey}
            value={(selectedvalue?.value as HoursDays)?.time ?? null}
            onChange={hnadleHoursDaysUpdate}
            valuesOfSelectKeys={selectedvalue?.valuesOfSelectKeys}
            placeholder={"hours"}
            isMulti={isMulti}
            error={showErrors ? "This field is required" : undefined}
            handlePush={() => {}}
            handlePop={() => {}}
            doNotFetchFromApi={true}
          />
        </div>
      );
    }
  }
  return null;
};

export default ConditionValue;
