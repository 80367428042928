import { axiosJSON, deleteDataFromCache } from "src/globals/axiosEndPoints";

export interface GetCustomerByChatIdParmas {
  chatId: number | string;
}

export async function getCustomerByChatId(params: GetCustomerByChatIdParmas) {
  if (params.chatId) {
    params.chatId = params.chatId + "";
  }
  const { data, status } = await axiosJSON.get(
    `/api/liveChat/agent/getLiveChatCustomer`,
    { params: { ...params, cacheIt: true, cacheTime: 30000 } },
  );

  if (data.error === true) {
    throw new Error(data.message as string);
  }
  // console.log(normalizeData);
  return { data: data.data, metaData: data.metaData };
}

export function removeCustomerByChatIdCache(chatId: number | string) {
  const url = `/api/liveChat/agent/getLiveChatCustomer`;

  deleteDataFromCache(url, { chatId: chatId + "" });
}
