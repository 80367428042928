import { axiosJSON } from "src/globals/axiosEndPoints";

export interface GetAllEmailTemplateParams {
  start: number;
  limit: number;
  search?: string;
}

export interface ITemplateVariant {
  id: string | number;
  name: string;
}

interface ITemplate {
  id: string | number;
  name: string;
  iconUrl: string | null;
  variants: ITemplateVariant[];
}

export interface ITemplateCategory {
  id: string | number;
  name: string;
  templates: ITemplate[];
}

export interface GetAllEmailTemplateResponse {
  allTemplateCategories: Array<ITemplateCategory>;
  hasMore: boolean;
}

const getAllEmailTemplateService = async (
  params: GetAllEmailTemplateParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/templates/getAll`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllEmailTemplateResponse = {
    allTemplateCategories: res.data,
    hasMore: true,
  };

  if (ret.allTemplateCategories.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
};

export default getAllEmailTemplateService;
