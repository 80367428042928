import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
export enum IContentType {
  Drip = "drip",
  OneTime = "oneTime",
}
/**
 * Parameters for fetching all sent messages.
 */
export interface GetAllSentMessagesParams {
  /**  The starting index for the messages to be fetched, used for pagination. */
  start: number;

  /**  The maximum number of messages to be fetched in a single request, used for pagination. */
  limit: number;

  /**  The filters applied to the messages. */
  filters: {
    /** The search text to filter messages. */
    searchText?: string;
    /**  The content type dropdown filter.*/
    contentType?: IContentType;
  };
}

/**
 * Represents a table heading in the table.
 */
export interface TableHeading {
  /**
   * The unique identifier for the table heading.
   * This ID will be used in rows to render corresponding data.
   */
  tableHeadingId: string;

  /** The display name of the table heading.*/
  tableHeadingName: string;
}

/**
 * The type of the channel.
 */
export type IChannel = "email" | "chat";

export interface ICustomer {
  id: string;
  name: string;
  imageUrl?: string | null;
}
/**
 * Represents a row in the message table.
 */
export interface Row {
  type:
    | null
    | "string"
    | "number"
    | "date"
    | "channel"
    | "preview"
    | "customer";

  value: null | string | number | IChannel | ICustomer;
}

/**
 * Represents a single message in the table.
 */
export interface IMessage {
  /**
   * The unique identifier for the message row.
   */
  messageId: string;

  /**
   * An array of row data objects for this message.
   */
  rowData: Row[];
}

/**
 * Response format for fetching all sent messages.
 */
export interface GetAllSentMessagesResponse {
  /**
   * The array of table headings to be displayed in the table.
   */
  tableHeadings: TableHeading[];

  /**
   * The array of all messages fetched based on the parameters.
   */
  allMessages: IMessage[];

  /**
   * The total number of messages available.
   */
  totalMessages: number;
}

export interface AllMessagesTableData {
  /**
   * A record of heading objects for the table, each containing a heading id and heading name mapped to heading id.
   */
  tableHeadings: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
    }
  >;
  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: Array<string>;
  allMessages: {
    [messageId: string]: IMessage;
  };
  allMessageIds: Array<string>;
  /**
   * The total number of messages available.
   */
  totalMessages: number;
}

/**
 * Fetches all sent messages based on the given parameters.
 * @param params - The parameters to fetch messages including pagination and filters.
 * @returns The response data containing table headings, all messages, and total message count.
 * @throws Error if the request fails or an unexpected error occurs.
 */
async function getAllSentMessages(params: GetAllSentMessagesParams) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/campaign/getAllSentMessages`
    : "/api/campaign/getAllSentMessages";

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occurred!");
  }
  const ret = normalizeData(res.data as GetAllSentMessagesResponse);
  return ret as AllMessagesTableData;
}

const normalizeData = (data: GetAllSentMessagesResponse) => {
  const ret: AllMessagesTableData = {
    allMessageIds: [],
    allMessages: {},
    tableHeadingIds: [],
    tableHeadings: {},
    totalMessages: data.totalMessages ?? 0,
  };
  data?.tableHeadings.forEach((tableHeading) => {
    ret.tableHeadings[tableHeading.tableHeadingId] = tableHeading;
    ret.tableHeadingIds.push(tableHeading.tableHeadingId);
  });

  data.allMessages.forEach((message) => {
    ret.allMessages[message.messageId] = {
      messageId: message.messageId,
      rowData: message.rowData,
    };

    ret.allMessageIds.push(message.messageId);
  });

  return ret;
};
export default getAllSentMessages;
