import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getAllTemplateVariantsService, {
  GetAllTemplateVariantsResponse,
} from "src/services/Campaign/getAllTemplateVariants.service";

type PageData = GetAllTemplateVariantsResponse & {
  page: number;
};

const useFetchAllTemplateVariants = ({
  categoryId,
  templateId,
  initialStart = 0,
  isDisabled = false,
  limit = 10,
}: {
  categoryId?: string | number;
  templateId?: string | number;
  initialStart?: number;
  isDisabled?: boolean;
  limit?: number;
}) => {
  const { data, status, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery<PageData>(
      ["getAllTemplateVariantsService", categoryId, templateId],
      {
        queryFn: async ({ pageParam = initialStart }) => {
          const res = await getAllTemplateVariantsService({
            limit: limit,
            start: pageParam * limit,
            categoryId: categoryId!,
            templateId: templateId!,
          });

          return { ...res, page: pageParam } as PageData;
        },
        getNextPageParam: (lastPage) => {
          const { page, hasMore } = lastPage;
          return hasMore ? page + 1 : undefined;
        },
        keepPreviousData: true,
        enabled: !isDisabled && !!categoryId && !!templateId,
        cacheTime: 60000,
        staleTime: 60000,
      },
    );

  // Memoized computation to merge data across all pages.
  const allTemplateVariants = useMemo(() => {
    if (!data) {
      return [];
    }

    // Reduce the paginated data into a single structure containing all segment ids and data
    return data.pages.flatMap((val) => val.allTemplateVariants);
  }, [data]);

  const fetchMore = useCallback(() => {
    if (hasNextPage && status !== "loading" && !isFetching && !isDisabled) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isDisabled, isFetching, status]);

  return {
    allTemplateVariants,
    fetchMore,
    hasMore: hasNextPage,
    fetchStatus: (isDisabled
      ? "idle"
      : isFetching
        ? "pending"
        : status === "success"
          ? "fulfilled"
          : status === "loading"
            ? "pending"
            : "rejected") as AJAXSTATUS,
  };
};

export default useFetchAllTemplateVariants;
