/**
 * This file defines a custom hook responsible for configuring and managing
 * the steps in the bot setup process. It handles the validation of questions and
 * answers, updating values with api calls, and managing the overall configuration state.
 *
 * @author @Anubhav-busibud
 * @author @yuvaraj-busibud
 * @author @Jayalakshmi-busibud
 *
 */
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { botProfileReducer } from "./ConfigureSteps.reducers";
import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { UseMutateFunction } from "@tanstack/react-query";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { BrandOption } from "../../../../BrandModal/BrandModal";
import useDebounce from "src/hooks/useDebounce";
import { getAllBrandsInfiScroll } from "src/store/slices/settings/brands/brandSettings.slice";
import { useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { IVariableB } from "./Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";
import { useAppSelector } from "src/store/store";

interface Props {
  botSettings: BotProfile;
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  selectedBrand: BrandOption | undefined;
  refetchGetBot: () => void;
  setSelectedBrand: Function;
}

export type stepType =
  | "channels"
  | "audience"
  | "useAdditionalCustomerInformation"
  | "isConfigureAdvancedBotSettings"
  | "user_segment"
  | "introMessage"
  | "answerOnceOrMultiple"
  | "handoverToHuman"
  | "bottyAnswerContent"
  | "readyToGoLive"
  | "email_inbound"
  | "email_handle_ticket_assigned_to_agents"
  | "email_automatically_close_ticket"
  | "email_configure"
  | "email_followup_configure"
  | "email_inbound_option_3";

export type ConfigureSteps = ReturnType<typeof useConfigureSteps>;

function useConfigureSteps({
  botSettings,
  updateBotSettings,
  refetchGetBot,
  selectedBrand,
  setSelectedBrand,
}: Props) {
  const { profileId } = useParams();

  const [state, dispatch] = useReducer(botProfileReducer, {
    botProfile: {
      ...botSettings,
      channels: botSettings.channels
        ?.filter((channel: string | number) => channel !== "")
        .map((channel: string | number) => channel.toString()),
      eligibleUsers: botSettings.eligibleUsers
        ?.filter((user: string | number) => user !== "")
        .map((user: string | number) => user.toString()),
      answerContent: botSettings.answerContent
        ?.filter((content: string | number) => content !== "")
        .map((content: string | number) => Number(content)),
      botAnswerStrategy: botSettings.botAnswerStrategy?.toString(),
    },
  });
  const prevName = useRef<string | undefined>(undefined);

  const [currentActiveStep, setCurrentActiveStep] = useState(
    1 as 0 | 1 | 2 | 3 | 4,
  );

  // State variable indicating whether to show the main configuration modal.
  const [showModal, setShowModal] = useState(false);
  // State variable indicating whether to show the audience from modal.
  const [showformModal, setShowFormModal] = useState(false);
  // * State variable indicating whether to show the brand selection modal.
  const [showbrandModal, setShowBrandModal] = useState(false);
  const [showUnPlublishModal, setShowUnPlublishModal] = useState(false);
  const { id } = botSettings;

  const [publishLoader, setPublishLoader] = useState(false);

  // const isConfigureEmailCompleted = useMemo(() => {
  //   if (state.botProfile.emailConfigureModel) {
  //     if (
  //       state?.botProfile?.emailConfigureModel?.introduction?.length > 0 &&
  //       state?.botProfile?.emailConfigureModel?.questionRegardingResolution
  //         ?.length > 0 &&
  //       state?.botProfile?.emailConfigureModel?.salutation?.length > 0
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }, [state]);

  // const isFollowupEmailCompleted = useMemo(() => {
  //   if (state?.botProfile.emailFollowupConfigureModel) {
  //     if (
  //       state?.botProfile.emailFollowupConfigureModel?.body?.length > 0 &&
  //       state?.botProfile.emailFollowupConfigureModel?.salutation?.length > 0
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }, [state]);

  /**
   * Get the variables and ids list from email variables
   */
  const { variablesList, variableIdsList } = useMemo(() => {
    const variablesList: Record<string, IVariableB> = {};
    const variableIdsList: string[] = [];

    if (state.botProfile?.emailVariables) {
      state.botProfile.emailVariables.forEach((variable) => {
        variablesList[variable.id] = variable;
        variableIdsList.push(variable.id);
      });
    }

    return { variablesList, variableIdsList };
  }, [state.botProfile.emailVariables]);

  const [showSetup, setShowSetup] = useState(false);
  const [showAlreadyPublishedModal, setShowAlreadyPublishedModal] =
    useState(false);

  // Callback to show the Configure more audience modal
  const onShow = useCallback((e: any) => {
    e.stopPropagation();
    setShowModal(true);
  }, []);

  // Callback to hide the Configure more audience modal
  const onHide = useCallback(() => {
    setShowModal(false);
  }, []);

  // Callback to show the Configure form modal to collect additional details
  const modalShow = useCallback((e: any) => {
    e.stopPropagation();
    setShowFormModal(true);
  }, []);

  // Callback to hide the Configure form modal to collect additional details
  const modalHide = useCallback(() => {
    setShowFormModal(false);
  }, []);

  // Callback to show the unpublish modal
  const publishShow = useCallback((e: any) => {
    // e.stopPropagation();
    // e.preventDefault();
    setShowUnPlublishModal(true);
  }, []);

  const brandHide = useCallback(() => {
    setShowBrandModal(false);
    // setShowAlreadyPublishedModal(false);
    // refetchGetBot();
  }, []);

  // const onComplete = useCallback( () => {
  //   setShowBrandModal( false );
  //   // refetchGetBot();
  // }, [refetchGetBot] );

  // Callback to hide the unpublish modal
  const unPublishHide = useCallback(() => {
    setShowUnPlublishModal(false);
    // refetchGetBot();
  }, []);

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  // Callback to handle moveToNextStep button at each step.based on the current step previous steps will be hide
  const handleMoveToNextStep = useCallback(
    (current: stepType) => {
      switch (current) {
        // TODO : Add step that enables introMessage and Step 3
        case "channels":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "channels",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "channels" });
          setCurrentActiveStep(1);
          break;

        case "bottyAnswerContent":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "answer_content",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "answer_content" });
          // setCurrentActiveStep(
          //   state.botProfile.channels.includes("5") &&
          //     currentUserData?.ui_visibility?.includes("email_bot_enabled")
          //     ? 2
          //     : 3,
          // );
          break;
        case "useAdditionalCustomerInformation":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "useAdditionalCustomerInformation",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "useAdditionalCustomerInformation",
          });
          break;
        case "isConfigureAdvancedBotSettings":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "isConfigureAdvancedBotSettings",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "isConfigureAdvancedBotSettings",
          });
          // setCurrentActiveStep(
          //   state.botProfile.channels.includes("5") &&
          //     currentUserData?.ui_visibility?.includes("email_bot_enabled")
          //     ? 2
          //     : 3,
          // );
          break;
        case "user_segment":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "user_segment",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "user_segment",
          });
          setCurrentActiveStep(
            state.botProfile.channels.includes("5") &&
              currentUserData?.ui_visibility?.includes("email_bot_enabled")
              ? 2
              : 3,
          );
          break;

        case "email_inbound":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_inbound",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "email_inbound" });
          setCurrentActiveStep(2);
          break;

        case "email_inbound_option_3":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_inbound",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "email_inbound" });
          setCurrentActiveStep(state.botProfile.channels.includes("4") ? 3 : 2);
          break;

        case "email_handle_ticket_assigned_to_agents":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_handle_ticket_assigned_to_agents",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "email_handle_ticket_assigned_to_agents",
          });
          setCurrentActiveStep(2);
          break;

        case "email_automatically_close_ticket":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_automatically_close_ticket",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "email_automatically_close_ticket",
          });
          setCurrentActiveStep(2);
          break;

        case "email_configure":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_configure",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "email_configure",
          });
          setCurrentActiveStep(2);
          break;

        case "email_followup_configure":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "email_followup_configure",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "email_followup_configure",
          });
          setCurrentActiveStep(state.botProfile.channels.includes("4") ? 3 : 4);
          break;

        case "introMessage":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "introduction_message",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "introduction_message",
          });
          setCurrentActiveStep(3);
          break;

        case "audience":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "eligible_users",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "eligible_users" });
          setCurrentActiveStep(3);
          break;

        case "answerOnceOrMultiple":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "bot_answer_strategy",
          });
          dispatch({
            type: "UPDATE_VISIBILITY",
            payload: "bot_answer_strategy",
          });
          setCurrentActiveStep(3);
          break;

        case "handoverToHuman":
          updateBotSettings({
            id: state.botProfile.id,
            move_to_next: "need_user_details",
          });
          dispatch({ type: "UPDATE_VISIBILITY", payload: "need_user_details" });
          setCurrentActiveStep(4);
          break;

        default:
          break;
      }
    },
    [updateBotSettings, state.botProfile.id, state.botProfile.channels],
  );

  // Create a debounced version of the bot's introduction message
  const debouncedIntroMessage = useDebounce(
    state.botProfile.introductionMessage,
    500,
  );

  //Callback function to handle the publishing of bot settings
  const handlePublish = useCallback(
    (force = false) => {
      // Check if a brand is selected
      // if (selectedBrand) {
      // Display a loading indicator while updating bot settings
      setPublishLoader(true);
      setShowAlreadyPublishedModal(false);
      // Update bot settings with the selected brand and set status to "public"
      updateBotSettings(
        {
          id,
          brand_id: botSettings.brandId.toString(),
          status: "public",
          force: force ? force : undefined,
        },
        {
          onSuccess: (data) => {
            // Hide the loading indicator
            setPublishLoader(false);

            if (
              data.statusCode === "bot_with_similar_config_already_published" ||
              data["status-code"] ===
                "bot_with_similar_config_already_published"
            ) {
              setShowAlreadyPublishedModal(true);
              setShowBrandModal(true);
            } else {
              setShowBrandModal(true);
              // setSelectedBrand(selectedBrand);
              // refetchGetBot();
              setShowSetup(true);
              setShowAlreadyPublishedModal(false);
              dispatch({
                type: "UPDATE_STATUS",
                payload: "public",
              });
            }
            // refetchGetBot();
          },
        },
      );
      // }
    },
    [botSettings.brandId, id, updateBotSettings],
  );

  //Callback to show modal to select a brand for publishing
  const brandShow = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      setShowUnPlublishModal(false);
      setShowSetup(false);
      handlePublish();
      // setPublishLoader(false);
    },
    [handlePublish],
  );

  //Callback function to handle the unpublishing of bot settings
  const handleUnpublish = useCallback(
    (e: any) => {
      //hide the brand modal and show unpublish modal
      brandHide();
      publishShow(e);
      // Display a loading indicator while updating bot settings
      setPublishLoader(true);
      // Dispatch an action to set status as draft
      updateBotSettings(
        {
          id,
          status: "draft",
        },
        {
          onSuccess: () => {
            // Display a loading indicator while updating bot settings
            setPublishLoader(false);
            publishShow(e);
            dispatch({
              type: "UPDATE_STATUS",
              payload: "draft",
            });
            // refetchGetBot();
          },
        },
      );
    },
    [brandHide, id, publishShow, updateBotSettings],
  );

  // Fetch initial data on component mount
  useEffect(() => {
    dispatch(getAllBrandsInfiScroll({ start: 0 }));
  }, []);

  // Set up an effect that triggers when the debounced introduction message changes
  useEffect(() => {
    // Check if the previous message is different from the current debounced message
    if (
      prevName.current !== undefined &&
      prevName.current !== debouncedIntroMessage
    ) {
      // Update bot settings with the new debounced introduction message
      updateBotSettings(
        {
          id: botSettings.id,
          introduction_message: debouncedIntroMessage,
        },
        {
          onSuccess: (data) => {
            if (data.statusCode && data.statusCode !== undefined) {
              // Dispatch an action to set status as draft
              dispatch({
                type: "UPDATE_STATUS",
                payload: "draft",
              });
            }
          },
        },
      );
    }
    // Update the previous message with the current debounced message
    prevName.current = debouncedIntroMessage;
  }, [botSettings.id, debouncedIntroMessage, updateBotSettings]);

  // Callback function to handle the Go Live action
  const handleGoLive = useCallback(
    (e: any) => {
      const { botProfile } = state;
      const {
        channels,
        eligibleUsers,
        introductionMessage,
        botAnswerStrategy,
        needUserDetails,
        answerContent,
        status,
      } = botProfile;

      if (channels.length === 0) {
        pushTheToast({
          text: "Please select atleast one channel",
          position: "top-right",
          type: "warning",
        });
      } else if (eligibleUsers.length === 0) {
        pushTheToast({
          text: "Please select atleast one audience",
          position: "top-right",
          type: "warning",
        });
      } else if (introductionMessage.length === 0) {
        pushTheToast({
          text: "Please enter introduction message",
          position: "top-right",
          type: "warning",
        });
      } else if (botAnswerStrategy === null) {
        pushTheToast({
          text: "Please select atleast one answer strategy",
          position: "top-right",
          type: "warning",
        });
      } else if (needUserDetails === null) {
        pushTheToast({
          text: "Please select wether u like to collect additional details form user or not !!",
          position: "top-right",
          type: "warning",
        });
      } else if (
        needUserDetails === true &&
        botProfile?.userDetails.length <= 0
      ) {
        pushTheToast({
          text: "Please configure additional details to collect form user or select no !!",
          position: "top-right",
          type: "warning",
        });
      } else if (answerContent.length === 0) {
        pushTheToast({
          text: "Please select atleast one answer content",
          position: "top-right",
          type: "warning",
        });
      } else {
        status === "draft" ? brandShow(e) : handleUnpublish(e);
      }
    },
    [brandShow, handleUnpublish, state],
  );

  return {
    state,
    dispatch,
    currentActiveStep,
    setCurrentActiveStep,
    showModal,
    onShow,
    onHide,
    modalShow,
    modalHide,
    brandShow,
    publishShow,
    brandHide,
    unPublishHide,
    showformModal,
    showbrandModal,
    showUnPlublishModal,
    handleMoveToNextStep,
    handlePublish,
    publishLoader,
    showSetup,
    setShowSetup,
    handleUnpublish,
    profileId,
    id,
    handleGoLive,
    showAlreadyPublishedModal,
    setShowAlreadyPublishedModal,
    // isConfigureEmailCompleted,
    // isFollowupEmailCompleted,
    variablesList,
    variableIdsList,
    // onComplete,
  };
}

export default useConfigureSteps;
