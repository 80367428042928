import Lottie from "lottie-light-react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import celebration from "src/assets/images/celebration.json";
import cross from "src/assets/images/featuredArticledCross.png";
import saufterLogo from "src/assets/images/saufter-logo.svg";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useAutomationContext } from "src/routes/BotSettings/Children/Automation/Hooks/useAutomationContext";
import { IAutomationListParams } from "src/routes/BotSettings/Children/AutomationList/AutomationList";
import { updateAutomationService } from "src/services/Automation/updateAutomation.service";
import { BrandDD } from "../../../../SettingSection/SettingSection";
import styles from "./PublishAutomation.module.scss";

const PublishAutomation = ({ onHide }: { onHide: () => void }) => {
  const { type } = useParams<IAutomationListParams>();

  const { formData, updateFormData, saveData, refetchAutomation } =
    useAutomationContext();
  const [loading, setLoading] = useState(false);
  const [errorAutomationPublish, setErrorAutomationPublish] =
    useState<boolean>(false);

  const updateBrandChange = useCallback(
    (brandId: string) => {
      updateFormData({ brandId });
      saveData({ brandId });
      refetchAutomation();
    },
    [refetchAutomation, saveData, updateFormData],
  );

  const handleGoLiveAndUnpublish = useCallback(async () => {
    if (!formData.showGoLive || !type) {
      console.log("Some Error Occured");
      return;
    }

    setLoading(true);
    const payload = {
      integrationId: formData.integrationId,
      publish: !formData.published,
    };
    try {
      await updateAutomationService({
        type: type,
        payload: payload,
      });

      updateFormData({
        published: payload.publish,
        configured: payload.publish ? true : formData.configured,
      });

      setErrorAutomationPublish(false);
    } catch (err) {
      if (
        typeof err === "object" &&
        err &&
        (err as any).message === "automation_already_exist"
      ) {
        setErrorAutomationPublish(true);
        return;
      }
      pushTheToast({
        position: "top-right",
        type: "danger",
        text:
          typeof err === "string"
            ? err
            : payload.publish
              ? "Could not publish automation"
              : "Could not unpublish automation",
      });
    } finally {
      setLoading(false);
    }
  }, [
    formData.configured,
    formData.integrationId,
    formData.published,
    formData.showGoLive,
    type,
    updateFormData,
  ]);

  return (
    <div className="p-3">
      {loading ? (
        <div className={`${styles.load}`}>
          <div className={`position-relative ${styles.loaderWrapper}`}>
            <span
              className="spinner-border"
              role="status"
            ></span>
            <img
              src={saufterLogo}
              alt=""
              className={`${styles.loaderImage} position-absolute`}
            />
          </div>
        </div>
      ) : !formData.published ? (
        <div className="">
          <div className="d-flex justify-content-between">
            <div className={`${styles.headingModel} ms-2`}>Choose a brand</div>
            <div onClick={onHide}>
              <span className="cursor-pointer">
                <img
                  src={cross}
                  alt="close"
                  className={`${styles.closeBtn}`}
                />
              </span>
            </div>
          </div>
          <div className={`mt-2 ms-2 ${styles.subheadingModel}`}>
            This will appear in your live chat for the following brand:
          </div>
          <div className={`mt-2 mx-2 ${styles.dropDown}`}>
            <BrandDD
              updateBrandChange={updateBrandChange}
              isDisabled={true}
              selectedBrandData={formData.brand}
              type={type}
            />
          </div>
          <div className={`${styles.btnModel} d-flex justify-content-end`}>
            {errorAutomationPublish ? (
              <div className={`${styles.errorSpan} mx-2`}>
                This kind of automation has already been published for{" "}
                <strong>{formData?.brand?.name ?? "brand"}</strong>. Please
                unpublish the existing version in order to proceed with
                publishing the new version.
              </div>
            ) : null}
            <button
              className={`${styles.btn} ${styles.publishBtn} mb-2`}
              onClick={() => handleGoLiveAndUnpublish()}
            >
              Publish
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="text-center py-2">
            <div className={`${styles.checkBox} mb-4`}>
              <Lottie
                loop={false}
                className={`${styles.celebrationAnimation}`}
                animationData={celebration}
              ></Lottie>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            </div>
            <p className={`mb-1 pt-2 ${styles.setup}`}>
              The setup is completed!
            </p>
            <span className={`${styles.successSpan}`}>
              {formData.automationName ?? "Untitled"} automation flow is live
              for "{formData.brand && (formData.brand.name ?? "NA")}"{" "}
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-2 pt-2">
            <button
              className={`me-4 ${styles.unPublishBtn}`}
              onClick={() => handleGoLiveAndUnpublish()}
            >
              <span>
                <i className="fa-solid fa-eye"></i>
              </span>
              <span className="ps-1">Unpublish</span>
            </button>
            <button
              className={`${styles.okBtn}`}
              onClick={onHide}
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublishAutomation;
