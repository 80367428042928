import { createContext, useCallback, useContext, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import { ActionOptionsResponse } from "src/services/Automation/AutomationTicketRouting/getActionOptions.service";
import {
  ActionGroup,
  IActionForModal,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";
import { validatePayloadConditions } from "../utils/validations";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import updateActions from "src/services/Automation/AutomationTicketRouting/updateActions.service";
import { useParams } from "react-router-dom";
import { EAutomationType } from "../../../AutomationList/AutomationList";
import { useAutomationContext } from "../../Hooks/useAutomationContext";

export interface DispatchState {
  conditionGroup: ActionGroup | null;
  updateState: (data: Partial<DispatchState>) => void;
  handleSave: (
    type: string,
    updateGroup: (
      newGroup: ActionGroup,
      setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => void,
  ) => void;
  resetState: () => void;
  showErrors: boolean;
  actionOptions: ActionOptionsResponse | null;
  saveStatus: AJAXSTATUS;
}

const initialState: DispatchState = {
  updateState: () => {},
  resetState: () => {},
  handleSave: () => {},
  conditionGroup: null,
  showErrors: false,
  actionOptions: null,
  saveStatus: "idle",
};

const ActionsContext = createContext(initialState);

export const useActionsState = () => {
  const context = useContext(ActionsContext);

  if (!context) {
    throw new Error("State not found!");
  }

  return context;
};

export const useActionsProvider = () => {
  const [state, setState] = useState({
    ...initialState,
  });
  const { formData } = useAutomationContext();

  const { integrationId } = useParams();
  const updateState = useCallback((data: Partial<DispatchState>) => {
    setState((currentData: DispatchState) => {
      return {
        ...currentData,
        ...data,
      };
    });
  }, []);
  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  /**
   * Validates the payload for updating the segment.
   *
   * @returns Validated payload or null if validation fails.
   */
  const validatePayload = useCallback(() => {
    // Create a payload object excluding 'segmentId' and 'segmentType'
    let payload: ActionGroup | null = null;
    if (state.conditionGroup) {
      payload = {
        ...state.conditionGroup,
        conditions: JSON.parse(
          JSON.stringify(state.conditionGroup?.conditions ?? []),
        ),
      };
    }
    if (payload) {
      let error = false;
      if (payload.conditions.length == 0) {
        error = true;
        pushTheToast({
          position: "top-right",
          text: "Please add atleast one action",
          type: "danger",
        });
      }
      // Validate each group condition.
      for (let i = 0; i < payload?.conditions.length; i++) {
        // Check if the group has any conditions.
        if (payload?.conditions?.length) {
          for (let j = 0; j < payload?.conditions.length; j++) {
            const condition = payload?.conditions[j];
            // Validate condition's compare type.
            if (!condition.fieldKey) {
              error = true;
            } else {
              if (condition?.value === null) {
                error = true;
              } else {
                // Validate condition values.
                const ret = validatePayloadConditions(condition);
                if (ret?.error) {
                  error = true;
                }
                if (ret?.value !== undefined) {
                  condition.value = ret.value;
                }
                // Remove unnecessary fields.
                delete condition.fieldDataType;
                delete condition.valuesOfSelectKeys;
                delete condition.valueIndex;
              }
            }
          }
        } else {
          error = true;
        }
      }
      // If there are any errors, dispatch to show errors.
      if (error) {
        updateState({
          showErrors: true,
        });
        return null;
      } else {
        return payload;
      }
    }
  }, [updateState, state]);

  /**
   * Handles the save action for the condition group.
   */
  const handleSave = useCallback(
    (
      type: string,
      updateGroup: (newGroup: ActionGroup) => void,
      setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      if (type == EAutomationType.TICKET_ROUTING && formData?.configured) {
        if (setShowErrorModal) setShowErrorModal(true);
        return;
      }
      if (
        // Check if saving is not already in progress.
        state.saveStatus !== "pending" &&
        // Check if data is available.
        state.conditionGroup
      ) {
        // Validate the payload.
        const payload = validatePayload();
        if (payload && payload !== null) {
          if (
            true
            // TODO - deep compare
            // !deepCompare(
            //   payload,
            //   updateSegmentDetails.segmentDetailsSaved,
            //   // Keys to ignore in comparison.
            //   [
            //     "segmentId",
            //     "segmentType",
            //     "valuesOfSelectKeys",
            //     "fieldDataType",
            //     "valueIndex",
            //   ],
            // )
          ) {
            updateState({
              saveStatus: "pending",
              showErrors: false,
            });
            updateActions({
              type: type,
              integrationId: integrationId ?? "",
              ...payload,
            })
              .then((res) => {
                //save group data in main state
                if (state.conditionGroup)
                  updateGroup({
                    ...state.conditionGroup,
                    status: StepStatus.Completed,
                  });
                // Stop the loader
                updateState({
                  saveStatus: "fulfilled",
                  showErrors: false,
                });
                pushTheToast({
                  type: "success",
                  position: "top-right",
                  text: "Configuration saved!",
                });
                //TODO - fetch after saving
                // fetchAfterSaving();
              })
              .catch((err) => {
                console.error(err);
                // Update state for showing errors
                updateState({
                  saveStatus: "rejected",
                });
                pushTheToast({
                  type: "danger",
                  position: "top-right",
                  text: err?.message ?? "Something went wrong!",
                });
              });
          }
        }
      }
    },
    [state, updateState, validatePayload],
  );

  return {
    ...state,
    updateState,
    resetState,
    handleSave,
    ActionsProvider: ActionsContext.Provider,
  };
};
