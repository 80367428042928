import { useCallback, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import upArrow from "src/assets/images/blueUpArrow.png";
import donwArrow from "src/assets/images/greyDownArrow.png";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import SearchBar from "src/components/SearchBar/SearchBar";
import SelectSegmentTable from "src/components/SelectSegmentTable/SelectSegmentTable";
import { useSegmentView } from "../../../../hooks/useSegmentView";
import useFetchSegments from "../../Hooks/useFetchSegments";
import { useSendMessage } from "../../Hooks/useSendMessageContext";
import styles from "../../SendMessageModal.module.scss";
import customerSegmentsUtils from "src/routes/CustomerSegments/utils/customerSegments.utils";
import { RouteNames } from "src/containers/SideBar/SideBar";

const SelectSegments = ({ onHide }: { onHide: () => void }) => {
  const [accordion, setAccordion] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isAddNewSegment, setIsAddNewSegmet] = useState<boolean>(false);
  const { state, dispatch } = useSendMessage();
  const [searchText, setSearchText] = useState("");
  const { activeSegmentDetails } = useSegmentView();
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const onInitialFetch = useCallback(
    (id: string) => {
      dispatch("updateSegmentId", { action: "create", data: [id] });
      dispatch("updateState", { showErrors: false });
    },
    [dispatch],
  );

  const {
    allSegments,
    allSegmentIds,
    setSearchText: updateSearch,
    fetchMore,
    hasMore,
    segmentsFetchStatus,
    searchLoading,
    refetch,
  } = useFetchSegments(onInitialFetch);

  const onSearch = useCallback(
    (value: string) => {
      updateSearch(value);
    },
    [updateSearch],
  );

  const deleteSegments = useCallback(
    (data: string[]) => {
      dispatch("updateSegmentId", { action: "delete", data });
      dispatch("updateState", { showErrors: false });
      setIsRefetch(true);
    },
    [dispatch],
  );

  const updateSegmentHandler = useCallback(
    (id: string, isChecked: boolean) => {
      dispatch("updateSegmentId", {
        action: isChecked ? "delete" : "update",
        data: [id],
      });
      dispatch("updateState", { showErrors: false });
      setIsRefetch(true);
    },
    [dispatch],
  );
  /**
   * Callback function to handle navigation when Add new segment button is clicked ,
   * it will add search param in url and open in new tab
   * and will close dropdown and set isAddNewSegment as true to refetch the segments
   */
  const handleAddNewSegment = useCallback(() => {
    setShowMenu(false);
    setIsAddNewSegmet(true);
    const url = `${customerSegmentsUtils.getNavigation(
      activeSegmentDetails.activeSegmentType ?? RouteNames.customerSegments,
      activeSegmentDetails.activeSegmentId ?? "",
      activeSegmentDetails.activeSegmentUserId,
    )}?action=create`;
    window.open(url, "_blank");
  }, [customerSegmentsUtils, activeSegmentDetails]);

  return (
    <div
      className={`${styles.segmentAccordion} mb-4 ${
        state.showErrors && state.segmentIds.length === 0
          ? styles.errorBorder
          : ""
      }`}
    >
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => setAccordion(!accordion)}
      >
        <div className="d-flex align-items-center">
          <span className={`${styles.accordHead} pe-3`}>User segment</span>
          {!accordion && (
            <div className="d-flex align-items-center gap-1">
              {state.segmentIds.map((id) => {
                const segment = allSegments[id];
                if (!segment) {
                  return null;
                }

                return (
                  <div
                    key={id}
                    className={`px-2 ${styles.addedItem}`}
                  >
                    <span>{segment.value}</span>
                    <span
                      className="ps-1 cursor-pointer"
                      onClick={() => deleteSegments([id])}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div>
          {accordion ? (
            <img
              src={upArrow}
              alt="down"
              width={10}
              // onClick={() => setAccordion(false)}
              // className="cursor-pointer"
            />
          ) : (
            <img
              src={donwArrow}
              alt="down"
              width={10}
              // onClick={() => setAccordion(true)}
              // className="cursor-pointer"
            />
          )}
        </div>
      </div>
      {accordion ? (
        <div className="mt-3">
          <Dropdown
            show={showMenu}
            onToggle={(show) => {
              setShowMenu(show);
              if (isAddNewSegment) {
                refetch();
                setIsAddNewSegmet(false);
              }
            }}
            align="start"
            bsPrefix="mb-2"
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              as="div"
              bsPrefix={`px-2 ${styles.dropBtn}`}
            >
              <div className="d-flex justify-content-between align-items-center w-100 cursor-pointer">
                <span>
                  {state.segmentIds.length > 0
                    ? `${state.segmentIds.length} user segment selected`
                    : "No Segments Selected"}
                </span>
                <span className={`${styles.arrowBtn}`}>
                  <i
                    className={`fa-solid fa-caret-${
                      showMenu ? "up" : "down"
                    } ms-2`}
                  />
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              bsPrefix={`p-2 ${styles.dropMenu} ${
                showMenu ? "d-block" : "d-none"
              }`}
            >
              <SearchBar
                className={`${styles.search} px-2 mb-3`}
                inputClassName={`${styles.input}`}
                placeholder={`Search...`}
                isSearchIcon={false}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
                onSearch={onSearch}
              />
              <div
                className={`d-flex align-items-center mb-2`}
                onClick={handleAddNewSegment}
              >
                <div className={`${styles.addBtn}`}>
                  <span>
                    <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
                <span className={`ps-2 ${styles.addText}`}>
                  Add new segment
                </span>
              </div>
              <InfiniteScroll
                className={`mb-1 ${styles.infiniteScroll}`}
                hasMoreBottom={hasMore}
                loadMoreFromBottom={fetchMore}
                infiniteLoader={
                  segmentsFetchStatus === "fulfilled" ? (
                    // Rendering the load button conditionally.
                    <div
                      className={`${styles.loaderText}`}
                      onClick={fetchMore}
                    >
                      <span>Load More</span>
                      <i className="ms-1 fa-solid fa-rotate-right"></i>
                    </div>
                  ) : segmentsFetchStatus === "pending" ? (
                    // Rendering the load text conditionally.
                    <div className={`${styles.loadingText}`}>
                      <span>Loading...</span>
                      <div>
                        <Spinner
                          className={`ms-1 ${styles.spinner}`}
                          size="sm"
                          animation="border"
                        />
                      </div>
                    </div>
                  ) : undefined
                }
              >
                {searchLoading && searchText?.trim().length > 0 ? (
                  <div
                    className={`d-flex justify-content-center ${styles.loadingSpinner}`}
                  >
                    <Spinner
                      size="sm"
                      variant="basic"
                    />
                  </div>
                ) : allSegmentIds.length === 0 ? (
                  <div
                    className={`d-flex align-items-center justify-content-center ${styles.checkLabel}`}
                  >
                    <span> No Items Found</span>
                  </div>
                ) : (
                  allSegmentIds.map((id) => {
                    const segment = allSegments[id];
                    if (!segment) {
                      return null;
                    }

                    const isChecked = state.segmentIds.includes(id);

                    return (
                      <div
                        key={id}
                        className="form-check"
                      >
                        <input
                          className={`form-check-input cursor-pointer ${styles.inputCheck}`}
                          type="checkbox"
                          checked={isChecked}
                          onClick={() => updateSegmentHandler(id, isChecked)}
                          id={id}
                        />
                        <label
                          className={`form-check-label cursor-pointer ps-2 ${styles.checkLabel}`}
                          htmlFor={id}
                        >
                          {segment.value}
                        </label>
                      </div>
                    );
                  })
                )}
              </InfiniteScroll>
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex align-items-center gap-1">
            {state.segmentIds.map((id) => {
              const segment = allSegments[id];
              if (!segment) {
                return null;
              }

              return (
                <div
                  key={id}
                  className={`px-2 ${styles.addedItem}`}
                >
                  <span>{segment.value}</span>
                  <span
                    className="ps-1 cursor-pointer"
                    onClick={() => deleteSegments([id])}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </div>
              );
            })}
          </div>
          {state.segmentIds && state.segmentIds.length ? (
            <SelectSegmentTable
              onHide={onHide}
              segmentIds={state.segmentIds}
              activeSegmentId={activeSegmentDetails.activeSegmentId ?? ""}
              activeSegmentType={activeSegmentDetails.activeSegmentType ?? ""}
              setIsRefetch={setIsRefetch}
              isRefetch={isRefetch}
            />
          ) : null}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectSegments;
