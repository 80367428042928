import { axiosJSON } from "src/globals/axiosEndPoints";
import { findAutomationContextFromURLPath } from "src/utils/utils";

/**
 * Interface representing the parameters for saving step transfer to agent settings.
 */
export interface SaveStepTrensferToAgentParams {
  /**
   * The ID of the integration.
   */
  integrationId: string;
  /**
   * The ID of the step.
   */
  stepId: string;
  /**
   * Object containing transfer to agent settings.
   */
  transferToAgent: {
    /**
     * Flag indicating if transfer to agent is enabled.
     */
    enabled?: boolean;
    /**
     * Confirmation message for transferring to agent after this step.
     */
    confirmationMessage?: string;
  };
}

/**
 * Saves the step transfer to agent settings for a given integration ID and step ID.
 *
 * @param params - The parameters object containing the integration ID, step ID, and transfer to agent settings.
 *
 * @returns A promise that resolves to `true` if the data is saved successfully.
 *
 * @throws Will throw an error if the request fails or if the response contains an error message.
 *
 */
async function saveStepTrensferToAgentService(
  params: SaveStepTrensferToAgentParams,
) {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/${automationContext}/saveStepTrensferToAgent`,
    params,
  );

  if (res.err || res.error) {
    throw new Error(
      res.msg ?? res.message ?? "Some Error Occurred while saving data.",
    );
  }

  return true;
}

export default saveStepTrensferToAgentService;
