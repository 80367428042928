import { axiosJSON } from "src/globals/axiosEndPoints";
import {
  GetSegmentSettingsParams,
  SegmentAttribute,
} from "./getSegmentSettings.service";

export interface UpdateSegmentAttribute {
  id: string;
  type: GetSegmentSettingsParams["type"];
  eventType: SegmentAttribute["eventType"];
}

export interface UpdateSegmentSettingsParams {
  attributes: Array<UpdateSegmentAttribute>;
}

const updateSegmentSettingsService = async (
  params: UpdateSegmentSettingsParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/segment/setting/updateUserTimelineSetting`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }
};

export default updateSegmentSettingsService;
