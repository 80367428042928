import { useCallback } from "react";
import closeBtn from "src/assets/images/closeModal.png";
import BrandDropdown, {
  BrandOption,
} from "src/routes/BotSettings/Children/BrandDropdown/BrandDropdown";
import { useAddCampaignState } from "../../Hooks/useAddCampaignContext";
import styles from "./AddCampaignBrandModal.module.scss";

const AddCampaignBrandModal = ({
  onHide,
  onNext,
}: {
  onHide: () => void;
  onNext: () => void;
}) => {
  const { state, dispatch } = useAddCampaignState();

  const onBrandChange = useCallback(
    (brand: BrandOption) => {
      dispatch("updateState", {
        brand,
        showErrors: false,
      });
    },
    [dispatch],
  );

  return (
    <div>
      <div className={`d-flex align-items-center justify-content-between`}>
        <h4 className={`mb-0 ${styles.modalHead}`}>Choose a brand</h4>
        <span
          className={`${styles.closeBtn}`}
          onClick={() => onHide()}
          id="closeBrandModal"
        >
          <img
            src={closeBtn}
            alt="closeModal"
            width={11}
            height={11}
          />
        </span>
      </div>
      <div className="mt-4">
        <p className={`mb-1 ${styles.subHeading}`}>
          Select a brand to personalize and proceed with your new campaign:
        </p>
        <BrandDropdown
          selectedBrand={state.brand}
          setSelectedBrand={onBrandChange}
          className={`${
            state.showErrors && !state.brand ? "border border-danger" : ""
          }`}
          error={
            state.showErrors && !state.brand ? "Please select a brand" : ""
          }
          isForCampaign={true}
        />
        <div className={`d-flex justify-content-end mt-4 pt-3 mb-2`}>
          <button
            className={`${styles.nextBtn}`}
            id="nextBtn"
            disabled={!state.brand}
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCampaignBrandModal;
