import { useState } from "react";
import ErrorModal from "../../ErrorModal/ErrorModal";
import styles from "./CampaignTabs.module.scss";
import AllSentMessage from "./Children/AllSentMessage/AllSentMessage";
import DripCampaign from "./Children/DripCampaign/DripCampaign";
import FollowUpMessage from "./Children/FollowUpMessage/FollowUpMessage";
import OneTimeCampaign from "./Children/OneTimeCampaign/OneTimeCampaign";

const CampaignTabs = () => {
  const [activeTab, setActiveTab] = useState<
    "drip" | "oneTime" | "followUp" | "msgs"
  >("drip");
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${styles.tabContainer} py-5`}>
      <div className={`${styles.tabs} px-4`}>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "drip" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("drip")}
        >
          Drip campaign
        </button>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "oneTime" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("oneTime")}
        >
          One-time campaign
        </button>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "followUp" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("followUp")}
        >
          Sales followups
        </button>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === "msgs" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("msgs")}
        >
          All sent messages
        </button>
      </div>
      <div className="tab-content px-4 py-3">
        {activeTab === "drip" ? (
          <DripCampaign />
        ) : activeTab === "oneTime" ? (
          <OneTimeCampaign showErrorModal={() => setShowModal(true)} />
        ) : activeTab === "followUp" ? (
          <FollowUpMessage />
        ) : activeTab === "msgs" ? (
          <AllSentMessage />
        ) : (
          ""
        )}

        {/* ErrorModal component */}
        <ErrorModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </div>
    </div>
  );
};

export default CampaignTabs;
