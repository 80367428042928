import templateIcon from "src/assets/images/tempNote.png";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import SendingInfo from "src/components/SendingInfo";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import {
  addVariableOnClick,
  replaceHtmlWithVariableKeys,
  replaceVariableKeysWithHtml,
} from "src/features/ReturnAutoWorkFlow/children/BaseSteps/children/StepQuestion/children/genericAnswers/TextBox/TextBox";
import { TinyMCEUploadFile } from "src/interfaces/TinyMCEContainer/ITinyMCEContainer";
import { VariablesData } from "src/services/CustomerSegments/NewMessage/getMessageTypes.service";
import {
  ChatSegmentMessage,
  SegmentMessageType,
} from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";
import { v4 as uuid } from "uuid";
import { useSendMessage } from "../../../../Hooks/useSendMessageContext";
import styles from "../../MessageTabs.module.scss";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import MentionVariables from "../MentionVariables/MentionVariables";
import useCustomVariables from "../MentionVariables/useCustomVariables";

const ChatMessageTab = ({setShowAttributeModal}: {setShowAttributeModal: () => void}) => {
  const { state, dispatch } = useSendMessage();
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<TinyMCEUploadFile[]>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [selectedVariables, setSelectedVariables] = useState<
    Array<VariablesData>
  >([]);
  const placeholderRef = useRef(null as any);
  const { attributes, attributeIds } = useCustomVariables();

  const selectedMessage: ChatSegmentMessage | undefined = useMemo(() => {
    const message = state.messages.find(
      (val) => val.type === SegmentMessageType.Chat,
    );
    return message ? (message as ChatSegmentMessage) : undefined;
  }, [state.messages]);

  const htmlValue = useMemo(() => {
    if (selectedMessage?.message && state.variableIds.length !== 0) {
      // Initialize the data by replacing Variable Key if any present with proper styling
      const valueUpdated = replaceVariableKeysWithHtml(
        selectedMessage.message,
        state.variables,
      );
      return valueUpdated;
    } else {
      return selectedMessage?.message;
    }
  }, [selectedMessage?.message, state.variableIds.length, state.variables]);

  const updateChatMessage = useCallback(
    (msg: Partial<ChatSegmentMessage>) => {
      dispatch("updateMessage", {
        data: { type: SegmentMessageType.Chat, ...msg },
        showErrors: false,
      });
    },
    [dispatch],
  );

  const onChange = useCallback(
    (value: string) => {
      updateChatMessage({ message: replaceHtmlWithVariableKeys(value) });
    },
    [updateChatMessage],
  );
  const [templateModal, setTemplateModal] = useState(false);

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      updateChatMessage({
        attachmentBatchNumber: selectedFiles.map(
          (file) => file.batchNumber + "",
        ),
      });
    }
  }, [selectedFiles, updateChatMessage]);

  /**
   * Example Variable for showing example
   */
  const exampleVariable = useMemo(() => {
    if (state.variableIds.length === 0) return "";
    const variableKey = state.variables[state.variableIds[0]]?.variableKey ?? "";
    return `${variableKey} Eg: ${state.variables[state.variableIds[0]].example}`;
  }, [state.variables, state.variableIds]);

  const customAutoCompleteOptions = useMemo(() => {
    return {
      delimiters: {
        "{": {
          fetchOptions: async () => [],
          renderComponent: (props: any) => (
            <MentionVariables
              {...props}
              placeholderRef={placeholderRef}
              variables={state.variables}
              variableIds={state.variableIds}
              setSelectedVariables={setSelectedVariables}
              initialShow={true}
              showToggleBtn={false}
              setShowAttributeModal={setShowAttributeModal}
              attributes={attributes}
              attributeIds={attributeIds}
            />
          ),
        },
      },
    };
  }, [state.variableIds, state.variables]);

  return (
    <div className={`${styles.viaBox}`}>
      <h5 className={`${styles.viaHeading}`}>
        Via chat: <span>Message will appear when the user logs in next</span>
      </h5>
      <div className={`${styles.msgBox}`}>
        <button
          className={`px-2 mb-2 ${styles.templateBtn} d-none`}
          onClick={() => setTemplateModal(true)}
        >
          <img
            src={templateIcon}
            alt="template"
            width={12}
          />
          <span className="ps-1">View templates</span>
        </button>
        {/* add view template modal here */}
        <Modal
          backdropClassName={`${styles.modalBrandBack}`}
          show={templateModal}
          onHide={() => setTemplateModal(false)}
          dialogClassName={`${styles.tempDialog}`}
          contentClassName={`${styles.tempContent}`}
        >
          <div></div>
        </Modal>
        <div className="d-flex align-items-center mb-2">
          <span className={`${styles.emailSubject}`}>From:</span>
          <div className="ps-2 w-100">
            <SendingInfo
              name="from"
              currentValue={selectedMessage?.from}
              getValue={(val) => {
                updateChatMessage({ from: val });
              }}
              placeholder="Add From Email"
              isError={
                state.showErrors &&
                (!selectedMessage?.from ||
                  selectedMessage?.from?.trim().length == 0)
              }
            />
            {state.showErrors &&
            (!selectedMessage?.from ||
              selectedMessage?.from?.trim().length == 0) ? (
              <div className={styles.errorText}>Please enter from email</div>
            ) : null}
          </div>
        </div>

        <div className={`${styles.editorBox}`}>
          <TinyMCEContainer
            className={`border ${
              state.showErrors
                ? isHTMLEmpty(trimPDIVBR(selectedMessage?.message.trim() ?? ""))
                  ? "border-danger shadow-none"
                  : ""
                : ""
            }`}
            value={htmlValue ?? ""}
            onChange={onChange}
            uniqueID={uuid()}
            getMentionRef={(data) => {
              if (placeholderRef) {
                placeholderRef.current = data;
              }
            }}
            areAttachmentsPublic={true}
            disableAttachment={false}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            attachmentUploadData={attachmentUploadData}
            setAttachmentUploadData={setAttachmentUploadData}
            currentAttachmentBatchNumber={currentAttachmentBatchNumber}
            setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
            isFileUploading={isFileUploading}
            setIsFileUploading={setIsFileUploading}
            customAutoCompleteOptions={customAutoCompleteOptions}
          />
          {state.showErrors &&
          isHTMLEmpty(trimPDIVBR(selectedMessage?.message.trim() ?? "")) ? (
            <div className={styles.errorText}>Please enter message</div>
          ) : null}
        </div>
        <div>
          <div className={`${styles.variableHeading}`}>
            Available variables:
          </div>
          <div className={`d-flex align-items-center flex-wrap`}>
            {state.variables &&
              state.variableIds &&
              state.variableIds.length !== 0 && (
                <div
                  className={`me-2 p-1 ${styles.variableText}`}
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (state.variableIds && state.variableIds.length !== 0) {
                      addVariableOnClick(
                        state.variables[state.variableIds[0]],
                        placeholderRef,
                      );
                    }
                  }}
                >
                  {" "}
                  <code> {exampleVariable}</code>{" "}
                </div>
              )}{" "}
            <MentionVariables
              placeholderRef={placeholderRef}
              variables={state.variables}
              variableIds={state.variableIds}
              setSelectedVariables={setSelectedVariables}
              setShowAttributeModal={setShowAttributeModal}
              attributes={attributes}
              attributeIds={attributeIds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageTab;
