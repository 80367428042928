import styles from "./Unfulfilled.module.scss";
import { useAppSelector } from "src/store/store";
import OrderItemsMap from "./OrderItemsMap";
import { GetOrderViewModel } from "src/services/ShopifySidebar/SingleOrder/BasicOrder/getOrderViewModel";
import Loader from "src/components/Loader";
import { v4 as uuidv4 } from "uuid";

function Unfulfilled() {
  const {
    loading,
    error,
    getOrderViewModel: order,
  } = useAppSelector((state) => state.singleOrder);

  function renderData(order: GetOrderViewModel) {
    return (
      <>
        {/*Maps different card for different fulfillment status group  */}
        {order?.orderItems?.map((item: any) => {
          // will be a card with orders based on fulfillment status
          // item contains objects items and status
          return (
            <OrderItemsMap
              key={uuidv4()}
              orderItems={item}
            />
          );
        })}
      </>
    );
  }
  return (
    <div>
      {loading && <Loader />}
      {!loading && error ? <div>Something went wrong!</div> : null}
      {!loading && order && renderData(order)}
    </div>
  );
}
export default Unfulfilled;
