import { axiosJSON } from "src/globals/axiosEndPoints";

export interface HomePageData {
  totalOpenTickets: number;
  totalUserOpenTickets: number;
  socialMediaRequestCount?: {
    instagram?: number;
    twitter?: number;
    messenger?: number;
  };
  campaignCount?: {
    active: number;
    draft: number;
  };
  showCampaignHome?: boolean;
  missedLiveChatRequestCount: number;
  liveChatRevenue: number;
}

export async function getHomePageData() {
  const { data: res } = await axiosJSON.get(`/api/getHomePageData`);

  if (res.error || res.err) {
    throw res.message ?? res.msg ?? "Something Unexpected occured!";
  }

  return res.data as HomePageData;
}
