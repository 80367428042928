/**
 * This file defines a react component responsible for rendering single row of article data
 *
 * @author @yuvaraj-busibud
 * @author @navjyot-busibud
 * @author @Jayalakshmi-busibud
 */
import { useEffect, useState } from "react";
import styles from "./ArticleBrandData.module.scss";
import { Article } from "src/services/Bot/AnswerSources/getAllKbArticles.service";
import { useNavigate, useParams } from "react-router-dom";
import ParseHTML from "src/components/ParseHTML";
import BotIndexingStatus, {
  IBotIndexingStatus,
} from "src/components/BotIndexingStatus/BotIndexingStatus";
import React from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useMutation } from "@tanstack/react-query";
import { stopimportArticle } from "src/services/Bot/AnswerSources/stopImportKBArticle.service";
import { SelectArticlesResponse } from "src/services/Bot/AnswerSources/selectKbArticles.service";

interface Props {
  article: Article;
  selectedArticles: any;
  setSelectedArticles: any;
  // setUnselectedArticles: any;
  // setPayloadArticles:any
  integrationId: number | string | undefined;
  handleChange: (articleIds: [number], isSelected: boolean) => void;
  articles: Article[];
  updateArticlebotTrainingStatus: (data: SelectArticlesResponse) => void;
}
const ArticleBrandData = ({
  article,
  selectedArticles,
  setSelectedArticles,
  // setUnselectedArticles,
  // setPayloadArticles,
  integrationId,
  handleChange,
  articles,
  updateArticlebotTrainingStatus,
}: Props) => {
  const { profileId } = useParams();
  const handleArticleClick = () => {
    window.open(
      // `/live-chat/settings/${integrationId}/Knowledge/articles/${article.articleId}`
      `${article.articleUrl}`,
    );
  };
  // Mutation hook for stopping the KBArticle import process
  const stopArticleMutation = useMutation(stopimportArticle, {
    onSuccess: (data) => {
      // Remove or replace with proper logging mechanism
      updateArticlebotTrainingStatus(data);
      let unselectId = data.trainingStatuses
        ? data.trainingStatuses[0].articleId
        : undefined;
      if (selectedArticles.includes(unselectId)) {
        setSelectedArticles(
          selectedArticles.filter((id: any) => id !== article.articleId),
        );
      } else {
        setSelectedArticles([...selectedArticles, article.articleId]);
      }
    },
    onError: () => {
      pushTheToast({
        position: "top-right",
        text: "Failed to stop import",
        type: "danger",
      });
    },
  });
  const onStatusUpdate = () => {
    stopArticleMutation.mutate(
      { botProfileId: profileId, articleId: article.articleId },
      {},
    );
  };

  const handleInputChange = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (selectedArticles.includes(article.articleId)) {
      setSelectedArticles(
        selectedArticles.filter((id: any) => id !== article.articleId),
      );

    } else {
      setSelectedArticles([...selectedArticles, article.articleId]);
    }
  };
  return (
    <div
      role="button"
      className={`mt-3 d-flex align-items-center justify-content-between px-3 mb-2 ${styles.tableHead}`}
      onClick={handleArticleClick}
    >
      <div
        className={`${styles.article}`}
        role="button"
      >
        <div className="form-check">
          <div>
            <input
              className={`form-check-input ${styles.checkbox}`}
              type="checkbox"
              onClick={(e) => {
                handleInputChange(e);
                handleChange([article.articleId], e.currentTarget.checked);
              }}
              checked={selectedArticles.includes(article.articleId)}
            />
          </div>

          <label className={`form-check-label pe-2 pt-1 ${styles.articleName}`}>
            <ParseHTML html={article.articleTitle} />
          </label>
        </div>
      </div>
      <div
        className={`${styles.articleUrl} pe-2`}
        role="button"
      >
        <span className={`${styles.articleName}`}>{article.publishedOn} </span>
      </div>
      <div
        className={`${styles.mainSource} pe-2`}
        role="button"
      >
        <span className={`${styles.articleName}`}>{article.articleBrand} </span>
      </div>
      <div
        className={`${styles.appearance} pe-2`}
        role="button"
      >
        <span className={`${styles.articleName} ${styles.appearCount}`}>
          {article.noOfTimesAppeared}
        </span>
      </div>

      {/* bot training status */}
      <BotIndexingStatus
        // botStatus={"loading"}
        botStatus={article.botTrainingStatus}
        failedText={"Training failed"}
        loadingText={"This may take few minutes..."}
        syncedText={"Article synced"}
        percentageLoaded={10}
        sourceType={"kbArticle"}
        id={article.articleId}
        botProfileId={profileId}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
        onStatusUpdate={onStatusUpdate}
        width={15}
        updateArticlebotTrainingStatusKB={updateArticlebotTrainingStatus}
      />
    </div>

  );
};

export default ArticleBrandData;
