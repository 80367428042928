import { axiosJSON, clearCacheByUrl } from "src/globals/axiosEndPoints";

export async function getShopifySidebarDataApi(servicePayload: {
  customerId: number;
  start: number;
  limit: number;
}) {
  const { data } = await axiosJSON.post(
    `/api/local/shopify/getSidebarData`,
    servicePayload,
  );
  if (data.error === true) {
    throw new Error(data.msg as string);
  }
  return {
    data: data.data,
    metaData: { count: data.count, totalCount: data.totalCount },
  };
}

export async function getCustomerDataApi(servicePayload: {
  id: number;
  includeTimeline?: boolean;
}) {
  const { data } = await axiosJSON.post(`/api/customer/getById`, {
    ...servicePayload,
    cacheIt: true,
    cacheTime: 30000,
  });
  if (data.err === true || data.error === true) {
    throw new Error(data.msg as string);
  }

  // console.log(data);

  return {
    data: data.data,
  };
}

export function clearGetCustomerByIdCache() {
  clearCacheByUrl("/api/customer/getById");
}
