import { Modal } from "react-bootstrap";

import CouldntVerifyModal from "../AddEmail/Children/AddModal/CouldntVerifyModal/CouldntVerifyModal";
import HasBeenVerified from "../AddEmail/Children/AddModal/HasBeenVerified/HasBeenVerified";
import VerifyingTheConnection from "../AddEmail/Children/AddModal/VerifyingTheConnection/VerifyingTheConnection";
import FinalSetup from "../FinalSetup/FinalSetup";

import styles from "./DomainVerification.module.scss";
import useDomainVerification from "./useDomainVerification";

/**
 * Component for showing verify domain in email integration
 */
const DomainVerification = ({
  isGmail,
  isEmailToDo = false,
  hideElements,
  isIncommingSetting = false,
  isOutboundEmail = false,
}: {
  isGmail: boolean;
  isEmailToDo?: boolean;
  hideElements?: (val: boolean) => void;
  isIncommingSetting?: boolean;
  isOutboundEmail?: boolean;
}) => {
  const {
    couldntVerifyShowModal,
    domainHasBeenVerified,
    email,
    name,
    verifyDomainShowModal,
    domainVerification,
    isDomainVerified,
    handleVerifySetup,
    onHideCouldntVerify,
    onHideDomainVerified,
  } = useDomainVerification(hideElements);

  if (isEmailToDo && verifyDomainShowModal) {
    return (
      <div className={`my-5 py-5`}>
        <VerifyingTheConnection
          text={"Domain Setup "}
          subText={""}
        />
      </div>
    );
  }

  if (isEmailToDo && couldntVerifyShowModal) {
    return (
      <div className={`my-5 py-5`}>
        <CouldntVerifyModal
          onHide={onHideCouldntVerify}
          verifyText="Domain Setup"
          setupName="domain"
        />
      </div>
    );
  }

  if (isEmailToDo && domainHasBeenVerified) {
    return (
      <div className={`my-5 py-5`}>
        <HasBeenVerified
          onHide={onHideDomainVerified}
          text={"Domain"}
          hideCloseButton={true}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={`ms-2 mt-3 ${isEmailToDo && "w-100"} ${
          !isGmail && styles.wrapper
        }`}
      >
        {/* User Info */}
        <div className={`pt-4 mt-2 ms-2 me-lg-4 d-none`}>
          <h3 className={`mb-0 ${styles.heading}`}>{name}</h3>
          <span className={`${styles.subHeading}`}>{email}</span>
        </div>

        {/* Setup Info */}
        <div>
          <FinalSetup
            isGmail={isGmail}
            isIncommingSetting={isIncommingSetting}
            isOutboundEmail={isOutboundEmail}
          />
        </div>
        {!isGmail ? (
          <>
            {/* Verify Setup Button */}
            <div
              className={`mt-3 pb-3 px-3 ms-lg-3 me-0 me-lg-3 d-flex flex-column flex-md-row align-items-center ${styles.emailHead}`}
            >
              <button
                className={`px-3 me-3 py-1 px-2 ${styles.verifyBtn}`}
                onClick={handleVerifySetup}
                disabled={isDomainVerified}
                id="verifySetup"
              >
                Verify Setup
              </button>
              {domainVerification && (
                <div className="ms-1 mt-2 mt-md-0 d-flex align-items-center">
                  {isDomainVerified ? (
                    <div className={`${styles.successCheck}`}>
                      <span>
                        <i className="fa-solid fa-check"></i>
                      </span>
                    </div>
                  ) : (
                    <div className={`${styles.failedCheck}`}>
                      <span>
                        <i className="fa-solid fa-close"></i>
                      </span>
                    </div>
                  )}
                  <span className={`ms-2 ${styles.statusText}`}>
                    {isDomainVerified ? "Verified !" : "Verification failed !"}
                  </span>
                </div>
              )}
            </div>

            {/* Verification Modals */}
            {/* Pending State */}
            {!isEmailToDo && (
              <Modal
                show={verifyDomainShowModal}
                backdropClassName={`${styles.modalBack}`}
                dialogClassName={`${styles.modalDialog}`}
                contentClassName={`${styles.modalContent}`}
                centered={true}
                enforceFocus={false}
              >
                <VerifyingTheConnection
                  text={"Domain Setup "}
                  subText={""}
                />
              </Modal>
            )}

            {/* Fullfilled State */}
            {!isEmailToDo && (
              <Modal
                backdropClassName={`${styles.modalBack}`}
                dialogClassName={`${styles.modalDialog}`}
                show={domainHasBeenVerified}
                contentClassName={`${styles.modalContent}`}
                centered={true}
                enforceFocus={false}
              >
                <HasBeenVerified
                  onHide={onHideDomainVerified}
                  text={"Domain"}
                />
              </Modal>
            )}
          </>
        ) : (
          <></>
        )}

        {/* Rejected State */}
        {!isEmailToDo && (
          <Modal
            show={couldntVerifyShowModal}
            backdropClassName={`${styles.modalBack}`}
            dialogClassName={`${styles.modalDialog}`}
            contentClassName={`${styles.modalContent}`}
            centered={true}
            enforceFocus={false}
          >
            <CouldntVerifyModal
              onHide={onHideCouldntVerify}
              verifyText="Domain Setup"
              setupName="domain"
            />
          </Modal>
        )}
      </div>
    </>
  );
};
export default DomainVerification;
