import { Dropdown, Spinner } from "react-bootstrap";
import { ESequenceSectionType } from "src/services/Campaign/createCampaignV2.service";
import styles from "./AddSectionSelect.module.scss";
import getSequenceSectionsService from "src/services/Campaign/getSequenceSections.service";
import { useQuery } from "@tanstack/react-query";

const AddSectionSelect = ({
  handleAddSection,
  templateId,
  variantId,
}: {
  handleAddSection: (sectionType: ESequenceSectionType, htmlId: string) => void;
  templateId?: string | number;
  variantId?: string | number;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["getSequenceStepService", templateId, variantId],
    queryFn: () =>
      getSequenceSectionsService({
        templateId: templateId ?? "",
        variantId: variantId ?? "",
      }),
    // Disable the query if any of the parameters are missing
    enabled: !!templateId && !!variantId,
  });
  // Helper to get the label based on sectionType
  const getSectionLabel = (sectionType: ESequenceSectionType): string => {
    switch (sectionType) {
      case ESequenceSectionType.Text:
        return "Text Section";
      case ESequenceSectionType.Blog:
        return "Blog Showcase Section";
      case ESequenceSectionType.Introduction:
        return "Introductory Section";
      case ESequenceSectionType.Feature:
        return "Feature Showcase Section";
      case ESequenceSectionType.Closing:
        return "Closing Section";
      case ESequenceSectionType.Discount:
        return "Offering Discount";
      case ESequenceSectionType.Product:
        return "Feature/Product Suggestion";
      default:
        return "Unknown Section";
    }
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as="div"
          className={`px-2 mt-3 ${styles.sectionBtn} `}
        >
          <div className="d-flex align-items-center">
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>
            <span className="px-2">Add Section</span>
            <div className={`${styles.devider}`}></div>
            <span className={`ps-1 ${styles.arrow}`}>
              <i className="fa-solid fa-chevron-down"></i>
            </span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={`dropdown-menu ${styles.mainSelectMenu} mt-1`}
        >
          {isLoading ? (
            <div
              className={`d-flex align-items-center justify-content-center ${styles.loader}`}
            >
              <Spinner
                size="sm"
                variant="basic"
              />
            </div>
          ) : isError ? (
            <div
              className={`d-flex align-items-center justify-content-center small text-danger ${styles.loader}`}
            >
              Something went wrong
            </div>
          ) : data?.length === 0 ? (
            <div
              className={`d-flex align-items-center justify-content-center ${styles.loader}`}
            >
              No options available
            </div>
          ) : (
            <div>
              {data.map((option) => {
                return (
                  <Dropdown.Item
                    as="span"
                    className={`dropdown-item ${styles.dropItem}`}
                    onClick={() =>
                      handleAddSection(option.sectionType, option.htmlId)
                    }
                  >
                    {getSectionLabel(option.sectionType)}
                  </Dropdown.Item>
                );
              })}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AddSectionSelect;
