import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

export type FieldDataTypes = "string" | "number" | "select";

export interface IOption {
  key: string;
  name: string;
}
export interface ActionOption {
  fieldKey: string;
  fieldName: string;
  fieldDataType: FieldDataTypes;
  isMulti?: boolean;
  isSearchable?: boolean;
  listOfOptions?: IOption[]; // In case of dropdown
  subText?: string; // In case of addNote
}

export interface ActionOptionsResponse {
  actionOptions: ActionOption[];
}

export async function getActionOptions(type: string, integrationId: string) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/${type}/getActionOptions`
    : `/api/automation/${type}/getActionOptions`;

  const { data: res } = await axiosJSON.post(apiEndPoint, {
    integrationId: integrationId,
  });

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res.data as ActionOptionsResponse;
}
