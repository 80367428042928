import React, { useState } from "react";
import styles from "./ToggleSwitch.module.scss"; // Import your custom styles

interface Props {
  initialSelect: boolean;
}
const ToggleSwitch = ({ initialSelect }: Props) => {
  const [isYesSelected, setIsYesSelected] = useState(initialSelect);

  // Toggle function
  const toggleSelection = () => {
    setIsYesSelected((prevState) => !prevState);
  };

  return (
    <div className={styles.toggleContainer}>
      <div
        className={`${styles.option} ${
          isYesSelected ? styles.yesSelected : ""
        }`}
        onClick={toggleSelection}
      >
        <i className="fa-solid fa-check"></i>
        <span className="ps-1">Yes</span>
      </div>

      <div
        className={`${styles.option} ${
          !isYesSelected ? styles.noSelected : ""
        }`}
        onClick={toggleSelection}
      >
        <i className="fa-solid fa-xmark"></i>
        <span className="ps-1">No</span>
      </div>
    </div>
  );
};

export default ToggleSwitch;
