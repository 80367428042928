import { useCallback, useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import userSegment from "src/assets/images/userSegment.png";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import {
  SegmentTypes,
  useCustomerSegments,
} from "../../hooks/useCustomerSegments";
import customerSegmentsUtils from "../../utils/customerSegments.utils";
import UpdateSegmentDetails, {
  EditCreateSegmentParams,
} from "../UpdateSegmentDetails/UpdateSegmentDetails";
import UserProfile from "../UserProfile/UserProfile";
import CustomFilters from "./Children/CustomFilters/CustomFilters";
import Filters from "./Children/Filters/Filters";
import MoreCols from "./Children/MoreCols/MoreCols";
import SendMessageModal from "./Children/SendMessageModal/SendMessageModal";
import TableHeader from "./Children/TableHeader/TableHeader";
import TableRow from "./Children/TableRow/TableRow";
import UploadNewCsvModal from "./Children/UploadCSVModal/UploadNewCsvModal";
import UserModal from "./Children/UserModal/UserModal";
import styles from "./SegmentView.module.scss";
import { useSegmentView, useSegmentViewCreate } from "./hooks/useSegmentView";
import ErrorModal from "./Children/SendMessageModal/Children/MessageTabs/children/ErrorModal/ErrorModal";
import AddNewAttribute from "./Children/TableHeader/children/AddNewAttribute/AddNewAttribute";
import { useCreateSendMessageProvider } from "./Children/SendMessageModal/Hooks/useSendMessageContext";

const getSegmentCountName = (
  segmentType: SegmentTypes | null,
  isMulti = false,
) => {
  switch (segmentType) {
    case "companySegment":
      return isMulti ? `companies` : `company`;
    case "userSegment":
      return isMulti ? `users` : `user`;
    default:
      return "";
  }
};

const SegmentTable = () => {
  const navigate = useNavigate();
  const {
    segmentView,
    activeSegmentDetails,
    dispatch: segmentDispatch,
    customerDispatch,
    fetchMoreSegmentView,
  } = useSegmentView();

  const [searchValueText, setSearchValueText] = useState(
    segmentView.searchText,
  );

  const searchInit = useRef(false);

  const totalCount = useMemo(() => {
    return `${segmentView.totalSegmentValues} ${getSegmentCountName(
      activeSegmentDetails.activeSegmentType,
      segmentView.totalSegmentValues > 1,
    )}`;
  }, [segmentView, activeSegmentDetails]);

  const onSearch = useCallback(
    (value: string) => {
      if (searchInit.current) {
        segmentDispatch("setSegmentView", { searchText: value });

        // Need to put it in set time out because untill this function will stop execution the state will be stale.
        setTimeout(() => {
          fetchMoreSegmentView();
        }, 0);
      } else {
        searchInit.current = true;
      }
    },
    [segmentDispatch, fetchMoreSegmentView],
  );

  const onClickHandler = useCallback(
    (id: string) =>
      navigate(
        customerSegmentsUtils.getNavigation(
          activeSegmentDetails.activeSegmentType ?? "",
          activeSegmentDetails.activeSegmentId ?? "",
          id,
        ),
      ),
    [
      activeSegmentDetails.activeSegmentId,
      activeSegmentDetails.activeSegmentType,
      navigate,
    ],
  );

  const [msgModal, setMgModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [showAttributeModa, setShowAttributeModal] = useState(false);

  const [state, dispatch, Provider] = useCreateSendMessageProvider();

  return (
    <div>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
        <div className="d-flex align-items-md-center flex-column flex-md-row">
          <h3 className={`saufter_h4 mb-0 me-2 ${styles.companyHead}`}>
            {segmentView.segmentValuesFetching === "pending" ? (
              <Spinner size="sm" />
            ) : (
              `${totalCount}`
            )}
          </h3>
          <MoreCols />
          {activeSegmentDetails.isCustomSegment ? (
            <CustomFilters />
          ) : (
            <Filters />
          )}
          <button
            className={`mx-md-1 ${styles.msgBtn}`}
            id="newMsgBtn"
            onClick={() => setMgModal(true)}
          >
            <span className="me-2">
              <i className="fa-solid fa-paper-plane"></i>
            </span>
            <span>New message</span>
          </button>
          <Provider value={[state, dispatch]}>
            <Modal
              show={msgModal}
              onHide={() => setMgModal(false)}
              dialogClassName={`${styles.msgModalDialog}`}
              contentClassName={`${styles.msgModalContent}`}
              enforceFocus={false}
            >
              <SendMessageModal
                onHide={() => setMgModal(false)}
                showErrorModal={() => {
                  setMgModal(false);
                  setShowErrorModal(true);
                }}
                setShowAttributeModal={() => {
                  setMgModal(false);
                  setShowAttributeModal(true);
                }}
              />
            </Modal>
          </Provider>
          <Modal
            backdropClassName={`${styles.attributeModalBack}`}
            show={showAttributeModa}
            onHide={() => setShowAttributeModal(false)}
            dialogClassName={`${styles.attributeModalDialog}`}
            contentClassName={`${styles.attributeModalContent}`}
            centered={true}
          >
            <AddNewAttribute
              onHide={() => setShowAttributeModal(false)}
              segmentType={activeSegmentDetails.activeSegmentType ?? ""}
              segmentId={activeSegmentDetails.activeSegmentId ?? undefined}
              showNewMessageModal={() => setMgModal(true)}
            />
          </Modal>
          <ErrorModal
            showModal={showErrorModal}
            onHide={() => {
              setShowErrorModal(false);
              setMgModal(true);
            }}
          />
        </div>
        <div>
          {activeSegmentDetails.segmentTypeName !== "All" && (
            <button
              className={`mt-2 mt-md-0 ${styles.segmentBtn}`}
              id={`segmentBtn`}
              onClick={() => {
                if (
                  activeSegmentDetails.activeSegmentId &&
                  activeSegmentDetails.activeSegmentType
                ) {
                  customerDispatch("setUpdateSegmentDetails", {
                    action: activeSegmentDetails.isCustomSegment
                      ? "update"
                      : "duplicate",
                    segmentId: activeSegmentDetails.activeSegmentId,
                    segmentType: activeSegmentDetails.activeSegmentType,
                  });
                }
              }}
            >
              <span className="pe-1">
                <i className="fa-solid fa-chart-pie"></i>
              </span>
              <span>
                {activeSegmentDetails.isCustomSegment
                  ? "Edit segment"
                  : "Duplicate segment"}
              </span>
            </button>
          )}
        </div>
      </div>
      <SearchBar
        className={`${styles.search} px-2 mt-2 mb-3`}
        inputClassName={`${styles.input}`}
        placeholder={`search by customer name , E-mail and any other keyword`}
        value={searchValueText}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setSearchValueText(e.target.value)
        }
        onSearch={onSearch}
      />

      <div className={`${styles.segementTable}`}>
        <div className={`${styles.ticketHeader}`}>
          <TableHeader
            allColumnKeys={segmentView.allColumnKeys}
            allColumns={segmentView.allColumns}
            showAttribute={true}
            activeSegmentId={activeSegmentDetails.activeSegmentId}
            activeSegmentType={activeSegmentDetails.activeSegmentType}
          />
        </div>
        {segmentView.segmentValuesFetching === "rejected" &&
        segmentView.segmentValueIds.length === 0 ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.segmentValuesFetching === "pending" &&
          segmentView.localSegmentFetching !== "pending" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : segmentView.segmentValueIds.length === 0 ? (
          <div
            className={`mt-3 d-flex align-items-center justify-content-center ${styles.noChatFound}`}
          >
            <span> No Items Found</span>
          </div>
        ) : (
          <InfiniteScroll
            loadMoreFromBottom={fetchMoreSegmentView}
            hasMoreBottom={segmentView.hasMoreSegmentView}
            className={`${styles.segmentsWrapper}`}
            infiniteLoader={
              <LoadMoreLoader
                fetchMore={fetchMoreSegmentView}
                variant="pink"
                status={
                  segmentView.localSegmentFetching === "fulfilled"
                    ? "fulfilled"
                    : segmentView.localSegmentFetching === "pending"
                      ? "pending"
                      : null
                }
              />
            }
          >
            {segmentView.segmentValueIds.map((id) => {
              return (
                <TableRow
                  key={id}
                  id={id}
                  onClickHandler={onClickHandler}
                  allColumnKeys={segmentView.allColumnKeys}
                  allColumns={segmentView.allColumns}
                  segmentValues={segmentView.segmentValues}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const SegmentViewComponent = () => {
  const { segmentView, activeSegmentDetails, fetchMoreSegmentView } =
    useSegmentView();
  const navigate = useNavigate();

  useMemo(fetchMoreSegmentView, [
    `${activeSegmentDetails.activeSegmentId}::${activeSegmentDetails.activeSegmentType}::${segmentView.moreColsFetching}`,
  ]);
  const [userModal, setUserModal] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [showUploadCSVBox, setShowUploadCSVBox] = useState(true);
  return (
    <div
      className={`${styles.customerWrap}`}
      id="customerWrapper"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start w-100">
          {activeSegmentDetails.activeSegmentUserId && (
            <div
              className={`me-3 cursor-pointer ${styles.backbtn}`}
              id="goBckBtn"
              onClick={() => navigate(-1)}
            >
              <span>
                <i className="fa-solid fa-arrow-left"></i>
              </span>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <h1 className={`saufter_h1 mb-0 ${styles.heading}`}>
                {activeSegmentDetails.activeSegmentUserId
                  ? "User profile"
                  : `${activeSegmentDetails.segmentTypeName}/
              ${activeSegmentDetails.segmentName}`}
              </h1>
              {activeSegmentDetails.segmentDescription &&
                !activeSegmentDetails.activeSegmentUserId && (
                  <span className={`${styles.description}`}>
                    {activeSegmentDetails.segmentDescription}
                  </span>
                )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {!activeSegmentDetails.activeSegmentUserId &&
                activeSegmentDetails.activeSegmentType === "userSegment" && (
                  <button
                    className={`d-flex align-items-center px-2 ${styles.addUserBtn}`}
                    onClick={() => setUserModal(true)}
                  >
                    <span className="pe-1">
                      <i className="fa-solid fa-plus"></i>
                    </span>
                    <span>Add New User</span>
                  </button>
                )}
            </div>
          </div>
          <Modal
            show={userModal}
            onHide={() => setUserModal(false)}
            dialogClassName={`${styles.userModalDialog}`}
            contentClassName={`${styles.userModalContent}`}
            centered={true}
          >
            <UserModal
              onHide={() => setUserModal(false)}
              setShowCSVModal={setShowCSVModal}
            />
          </Modal>
          <Modal
            show={showCSVModal}
            onHide={() => setShowCSVModal(false)}
            dialogClassName={`${styles.uploadModalDialog}`}
            contentClassName={`${styles.uploadModalContent}`}
            centered={true}
          >
            <UploadNewCsvModal onHide={() => setShowCSVModal(false)} />
          </Modal>
        </div>
      </div>

      <div
        className={`${styles.companyDetails} ${
          activeSegmentDetails.activeSegmentUserId ? "mt-3" : "p-4 mt-4"
        }`}
      >
        {!activeSegmentDetails.activeSegmentUserId &&
        activeSegmentDetails.activeSegmentType === "userSegment" &&
        showUploadCSVBox ? (
          <div className={`p-2 mb-3 ${styles.uploadBox}`}>
            <div className="d-flex justify-content-end">
              <span
                className="cursor-pointer"
                onClick={() => setShowUploadCSVBox(false)}
              >
                <i className="fa-solid fa-xmark" />
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="me-4">
                <div className={styles.header}>Add users to create magic!</div>
                <div className={`mt-2 ${styles.subText}`}>
                  Start building a powerful helpdesk experience by adding your
                  users. Whether you're uploading a list or tracking users in
                  real-time, it all begins here!
                </div>
                <div className="d-flex mt-3">
                  <button
                    className={`d-flex align-items-center px-2  me-2 ${styles.addUserBtn} ${styles.uploadBtn}`}
                    onClick={() => setShowCSVModal(true)}
                  >
                    <span>Upload CSV</span>
                  </button>
                  {/* remove d-none when add code button is needed */}
                  {/* <button
                    className={`d-flex align-items-center px-2 me-2 ${styles.addCodeBtn}`}
                    onClick={() => setShowCSVModal(true)}
                  >
                    <span>Add Code</span>
                  </button> */}
                </div>
              </div>
              <img
                src={userSegment}
                className={`me-3 ${styles.segmentImg}`}
                alt="user segment"
              />
            </div>
          </div>
        ) : null}
        {segmentView.moreColsFetching === "rejected" ||
        activeSegmentDetails.allSegmentsLoading === "rejected" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4 text-danger">
            Error Loading Content...
          </div>
        ) : segmentView.moreColsFetching === "pending" &&
          activeSegmentDetails.allSegmentsLoading !== "fulfilled" ? (
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <Loader />
          </div>
        ) : activeSegmentDetails.activeSegmentUserId ? (
          <UserProfile
            segmentUserId={activeSegmentDetails.activeSegmentUserId}
          />
        ) : (
          <SegmentTable />
        )}
      </div>
    </div>
  );
};

const SegmentView = () => {
  const [state, dispatch, Provider] = useSegmentViewCreate();
  const { dispatch: csDispatch, segmentDataForUpdate } = useCustomerSegments();

  const onHide = useCallback(() => {
    csDispatch("removeUpdateSegmentDetails");
  }, [csDispatch]);

  const onSubmit = useCallback(
    (res: EditCreateSegmentParams) => {
      // Unshift the segment name in sidebar.
      csDispatch("unshiftCustomSegment", {
        segmentType: res.segmentType,
        segment: {
          segmentId: res.segmentId + "",
          name: res.name,
          description: res.description,
        },
      });

      // Convert the action to update.
      csDispatch("updateUpdateSegmentDetails", {
        segmentId: res.segmentId,
        action: "update",
      });
    },
    [csDispatch],
  );

  return (
    <Provider value={[state, dispatch]}>
      <SegmentViewComponent />

      {/* Create Segment Modal */}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={segmentDataForUpdate !== null ? true : false}
        onHide={onHide}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <UpdateSegmentDetails
          onHideUpdateSegmentModal={onHide}
          segmentDataForUpdate={segmentDataForUpdate}
          onSubmit={onSubmit}
        />
      </Modal>
    </Provider>
  );
};

export default SegmentView;
