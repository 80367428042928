import ProductAvatar from "src/components/ProductAvatar/ProductAvatar";
import styles from "../../../Unfulfilled.module.scss";
import noData from "src/assets/images/no-data.png";

const OrderItem = ({ orderItem, order, index }: any) => {
  return (
    <div className={`${styles.orderBreifDetail} mt-3 row w-100`}>
      <div className={`col-lg-2 ${styles.detail}`}>
        <div className="position-relative">
          {orderItem?.imageUrl ? (
            <img
              src={orderItem?.imageUrl}
              className={`${styles.img}`}
            />
          ) : (
            <ProductAvatar
              className={styles.img}
              index={index || 0}
            />
          )}
          <span
            className={`${styles.badge} position-absolute border border-light`}
          >
            {orderItem?.quantity}
          </span>
        </div>
      </div>

      <div
        className={`col-lg-6 my-1 my-lg-0 ${styles.lead} ${styles.productDetail} `}
      >
        <div
          className={`link-primary ${styles.bootstrapColorChange}`}
          role="button"
        >
          <u>{orderItem?.itemName ?? "NA"}</u>
        </div>
        <div className={` my-1 my-lg-0`}>{orderItem?.sku ?? "NA"}</div>
        {/* <div className="mt-1">Prescription Type: Non-Prescription</div>
        <div className="mt-2">
          _mw_option_relation: 37525013299367_164507711
        </div> */}
      </div>

      <div
        className={`col-lg-3  my-1 my-lg-0 ${styles.lead} ${styles.amountCalculating} `}
      >
        <div>
          {orderItem?.unitPrice?.currencyCode} {orderItem?.unitPrice?.amount} x{" "}
          {orderItem?.quantity}{" "}
          {/* {order?.paymentDetails?.totalPrice?.amount} x {orderItem?.quantity}{" "} */}
        </div>
      </div>

      <div
        className={`col-lg-1  my-1 my-lg-0 ${styles.finalAmount} ${styles.lead}`}
      >
        {/* <span>{order?.paymentDetails?.totalPrice?.amount}</span> */}
        <span>
          {orderItem?.totalPrice?.currencyCode} {orderItem?.totalPrice?.amount}
        </span>
      </div>
    </div>
  );
};

export default OrderItem;
