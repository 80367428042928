import styles from "./SequenceSteps.module.scss";
import ChannelSelect from "../ChannelSelect/ChannelSelect";
import { useCallback, useMemo, useState } from "react";
import DeleteSeqModal from "../DeleteSeqModal/DeleteSeqModal";
import {
  ECampaignChannel,
  ESequenceSectionType,
  SequenceStep,
  SequenceStepSection,
} from "src/services/Campaign/createCampaignV2.service";
import { useAddCampaignState } from "../../../../../Hooks/useAddCampaignContext";
import { channelOptions } from "../../PrimaryDetails/Children/ChannelSelectDD/ChannelSelectDD";
import { UserSelectBrandOption } from "../../PrimaryDetails/Children/UserSelect/UserSelect";
import SequenceSection from "../SequenceSection/SequenceSection";
import { v4 as uuid } from "uuid";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddSectionSelect from "../AddSectionSelect/AddSectionSelect";
import EditDays from "../EditDays/EditDays";

const SequenceSteps = ({
  sequence,
  index,
}: {
  sequence: SequenceStep;
  index: number;
}) => {
  const [accordion, setAccordion] = useState(false);
  const [showDeleteSequence, setShowDeleteSequence] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<UserSelectBrandOption | null>(
      sequence.channel ? channelOptions[sequence.channel] : null,
    );
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  const { state, dispatch } = useAddCampaignState();

  // Memoized list of available channels based on the current state
  const channels = useMemo(() => {
    return state.availableChannels
      .map((channel) => channelOptions[channel])
      .filter(Boolean); // Filter out any undefined or null values
  }, [state.availableChannels]);

  // Handle changes in the channel dropdown selection
  const handleChannelChange = useCallback(
    (selected: UserSelectBrandOption) => {
      // Update the local state with the newly selected option
      setSelectedOption(selected);

      // Create a copy of the current sequence to avoid direct mutation of state
      const updatedSequence = [...state.sequence];

      // Update the channel property for the specific sequence step at the given index
      updatedSequence[index] = {
        ...updatedSequence[index],
        channel: selected.value as ECampaignChannel,
      };

      dispatch("updateState", { sequence: updatedSequence });
    },
    [state, index, dispatch],
  );

  // Handle the deletion of a sequence step
  const handleDeleteSequence = useCallback(() => {
    // Filter out the sequence step with the matching ID to delete it
    const updatedSequence = state.sequence.filter(
      (seq) => seq.id !== sequence.id,
    );

    // Dispatch the updated sequence list
    dispatch("updateState", { sequence: updatedSequence });
    pushTheToast({
      text: "Step deleted successfully!",
      position: "top-right",
      type: "success",
    });
    // Close the delete confirmation modal
    setShowDeleteSequence(false);
  }, [state, index, dispatch]);

  // Handle changes to the trigger day (delay in days for the sequence step)
  const handleTriggerChange = useCallback(
    (day: number) => {
      dispatch("updateTriggerAfter", { days: day, sectionIndex: index });
    },
    [state, dispatch],
  );

  const handleAddSection = useCallback(
    (sectionType: ESequenceSectionType, htmlId: string) => {
      // Define the new section based on the section type
      let newSection: SequenceStepSection;

      switch (sectionType) {
        case ESequenceSectionType.Text:
          newSection = {
            sectionType: ESequenceSectionType.Text,
            id: uuid(),
            description: "", // default empty description
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Blog:
          newSection = {
            sectionType: ESequenceSectionType.Blog,
            id: uuid(),
            blogs: [], // default empty array of blogs
            sections: [], // default empty array of blogs
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Feature:
          newSection = {
            sectionType: ESequenceSectionType.Feature,
            id: uuid(),
            features: [], // default empty array of features
            sections: [], // default empty array of features
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Product:
          newSection = {
            sectionType: ESequenceSectionType.Product,
            id: uuid(),
            name: "",
            description: "",
            link: "",
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Closing:
          newSection = {
            sectionType: ESequenceSectionType.Closing,
            id: uuid(),
            description: "", // default empty description
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Introduction:
          newSection = {
            sectionType: ESequenceSectionType.Introduction,
            id: uuid(),
            description: "", // default empty description
            htmlId: htmlId,
          };
          break;

        case ESequenceSectionType.Discount:
          newSection = {
            sectionType: ESequenceSectionType.Discount,
            id: uuid(),
            amount: 0, // default amount
            type: "percentage", // default type
            htmlId: htmlId,
          };
          break;

        default:
          const exhaustiveCheck: never = sectionType;
          console.error(`Unsupported section type ${exhaustiveCheck}`);
          return;
      }

      // Create a copy of the current sequence
      const updatedSequence = state.sequence;

      // Update the sections property for the specific sequence step at the given index
      updatedSequence[index] = {
        ...updatedSequence[index],
        sections: [...sequence.sections, newSection],
      };

      // Dispatch the updated sequence to the global state
      dispatch("updateState", { sequence: updatedSequence });
    },
    [state, sequence, dispatch],
  );
  const handleOnDragEnd = useCallback(
    (result: any) => {
      // Destructure the 'destination' and 'source' properties from the 'result' object.
      const { destination, source } = result;
      // If there's no valid destination, return (no action needed).
      if (!destination) {
        return;
      }
      // If the item is dropped in the same location, return (no action needed)
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // Create a copy of the current sequence
      const updatedSequence = [...state.sequence];
      const newSections = [...sequence.sections];
      // Remove the item from the source index and insert it at the destination index.
      const [removed] = newSections.splice(source.index, 1);
      newSections.splice(destination.index, 0, removed);

      // Update the sections property for the specific sequence step at the given index
      updatedSequence[index] = {
        ...updatedSequence[index],
        sections: [...newSections],
      };

      // Dispatch the updated sequence to the global state
      dispatch("updateState", { sequence: updatedSequence });
    },
    [state, sequence, dispatch],
  );
  const handleSectionsChange = useCallback(
    (sectionIdx: number, section: SequenceStepSection) => {
      // Create a copy of the current sequence
      const updatedSequence = [...state.sequence];
      const newSections = [...sequence.sections];
      newSections[sectionIdx] = section;
      // Update the sections property for the specific sequence step at the given index
      updatedSequence[index] = {
        ...updatedSequence[index],
        sections: [...newSections],
      };

      // Dispatch the updated sequence to the global state
      dispatch("updateState", { sequence: updatedSequence });
    },
    [state, sequence, dispatch],
  );
  const handleDeleteSection = useCallback(
    (sectionId: string) => {
      // Create a copy of the current sequence
      const updatedSequence = [...state.sequence];
      const newSections = sequence.sections.filter(
        (section) => section.id !== sectionId,
      );
      // Update the sections property for the specific sequence step at the given index
      updatedSequence[index] = {
        ...updatedSequence[index],
        sections: [...newSections],
      };

      // Dispatch the updated sequence to the global state
      dispatch("updateState", { sequence: updatedSequence });
      setShowDeleteSection(false);
    },
    [state, sequence, dispatch],
  );

  const isCustom = useMemo(() => {
    return !state.activeTemplate?.isDefault
  }, [state.activeTemplate?.isDefault]);

  return (
    <div className="mb-2">
      <div className={`position-relative ${styles.sequenceWrapper}`}>
        <div
          className={`${styles.deleteSequence}`}
          onClick={() => setShowDeleteSequence(true)}
        >
          <span className={`${styles.close}`}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center w-75">
            <span className={`${styles.sequenceStep}`}>
              Sequence step {index + 1}
            </span>
            <div
              className={`mx-4 ${accordion ? "d-none" : styles.separator}`}
            ></div>
            <div className={`${accordion ? "d-none" : "d-block"}`}>
              <ChannelSelect
                options={channels}
                placeholder="Select channel"
                selectedOption={selectedOption}
                handleChange={handleChannelChange}
              />
            </div>
          </div>
          <div className="w-25 d-flex align-items-center justify-content-end">
            <div
              className={`d-flex align-items-center me-1 ${styles.dayWrap} pe-5`}
            >
              <span className={`pe-1 ${styles.dayText}`}>Day</span>
              <EditDays
                day={sequence.triggerAfter}
                onChange={handleTriggerChange}
              />
            </div>
            <div
              className="ms-1"
              onClick={() => setAccordion(!accordion)}
            >
              <span className={`cursor-pointer`}>
                <i
                  className={`fa-solid fa-chevron-${accordion ? "up" : "down"}`}
                ></i>
              </span>
            </div>
          </div>
        </div>
        {accordion ? (
          <div className="mt-3">
            <div className="mb-2">
              <ChannelSelect
                options={channels}
                placeholder="Select channel"
                selectedOption={selectedOption}
                handleChange={handleChannelChange}
              />
            </div>
            {selectedOption ? (
              isCustom ? (
                <div
                  className={`${styles.sectionMsgBox} w-100 d-flex align-items-center justify-content-center`}
                >
                  <div className={`${styles.sectionMsg} p-3`}>
                    <div className={`p-3 mx-2 ${styles.innerTextBox}`}>Sections cannot be edited for new email templates</div>
                  </div>
                </div>
              ) : (
                <div>
                  <DragDropContext
                    onDragEnd={(result) => handleOnDragEnd(result)}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {sequence.sections.map(
                            (section: SequenceStepSection, idx: number) => {
                              return (
                                <Draggable
                                  key={section.id}
                                  draggableId={
                                    section.id ? section.id.toString() : ""
                                  }
                                  index={idx}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="d-flex align-items-center mb-2"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {section.id && (
                                        <SequenceSection
                                          key={section.id}
                                          section={section}
                                          index={idx}
                                          handleSectionsChange={
                                            handleSectionsChange
                                          }
                                          handleDeleteSection={() =>
                                            setShowDeleteSection(true)
                                          }
                                        />
                                      )}
                                      <DeleteSeqModal
                                        heading="Are you sure you want to delete this section?"
                                        showModal={showDeleteSection}
                                        showLoader={showLoader}
                                        handleDelete={() =>
                                          handleDeleteSection(section.id ?? "")
                                        }
                                        onHide={() =>
                                          setShowDeleteSection(false)
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            },
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {!state.seqLimit ||
                  (sequence.sections ?? []).length <= state.seqLimit ? (
                    <AddSectionSelect
                      handleAddSection={handleAddSection}
                      templateId={sequence?.templateId}
                      variantId={sequence?.variantId}
                    />
                  ) : null}
                </div>
              )
            ) : null}
          </div>
        ) : (
          ""
        )}
      </div>
      <DeleteSeqModal
        heading="Are you sure you want to delete this step?"
        subHeading="You can always add a new step later on."
        showModal={showDeleteSequence}
        showLoader={showLoader}
        handleDelete={handleDeleteSequence}
        onHide={() => setShowDeleteSequence(false)}
      />
    </div>
  );
};

export default SequenceSteps;
