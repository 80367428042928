import { MappedAttributeError } from "src/services/ReturnAutoWorkFlow/ConfigModals/updateMappedAttributes.service";

export type AllAutomationContexts = "return" | "survey";

/**
 * Interface representing the data structure of a variable text for a text editor.
 */
export interface VariableTextData {
  /**
   * A unique identifier for the variable text used in the text editor.
   */
  id: string;
  /**
   * The name of the variable, which may be displayed in the text editor interface.
   */
  variableName: string;
  /**
   * The key associated with the variable, used for referencing the variable within the text editor.
   */
  variableKey: string;
  /**
   * An example usage of the variable text, which can help users understand how to use it in the text editor.
   */
  example: string;
  /**
   * If user is allowed the delete the variable.
   */
  allowDelete?: boolean;
}

/**
 * Interface representing the collection of all variable texts for a text editor.
 */
export interface AllVariableTexts {
  /**
   * An array of strings representing the unique identifiers for each variable text in the text editor.
   */
  variableIds: Array<string>;
  /**
   * A record where each key is a unique identifier and the value is a VariableTextData object, providing
   * detailed information about each variable text available in the text editor.
   */
  variables: Record<string, VariableTextData>;
  /**
   * A boolean flag indicating if there are more variable texts available beyond the current collection in the text editor,
   * which can be useful for paginating or loading additional variable texts.
   */
  hasMore: boolean;
}

/**
 * Base Questions Start
 */

/**
 * Used for showing error modal for webhook question.
 */
export interface WebhookQuestionErrors {
  heading: string;
  subHeading: string;
}

/**
 * Interface representing the details required for webhook question.
 */
export interface WebhookQuestionDetails {
  /**
   * The email address to forward emails to.
   */
  webhook: string;
  stepsVideoURL?: null | string;
  verified: boolean;
  orderStatus?: null | "notCreated" | "created" | "fulfilled";
  errors?: null | WebhookQuestionErrors;
}

/**
 * Used for showing error modal.
 */
export interface EmailForwardingErrors {
  heading: string;
  subHeading: string;
}

/**
 * Interface representing the details required for email forwarding.
 */
export interface EmailForwardingDetails {
  /**
   * The email address to forward emails to.
   */
  forwardEmailsTo: string;
  /**
   * An array of verified email addresses.
   */
  savedEmails: Array<string>;
  /**
   * The email address currently active for verification.
   */
  activeEmailForVerification: string;
  /**
   * A boolean indicating whether the active email is verified.
   */
  activeEmailVerified: boolean;
}

/**
 * Stripe dewtails for payments.
 */
export interface StripeDetails {
  secretKey: string;
  secretPublishableKey: string;
  verified: boolean;
  error: boolean;
}

/**
 * This is an specific question to get users address.
 */
export interface AddressQuestionValue {
  addressLineOne: string;
  addressLineTwo?: string;
  phoneNumber: string;
  city: string;
  state: string;
  country: string;
  pin: string;
}

/**
 * Interface representing the details of an uploaded CSV file.
 */
export interface UploadedCSVDetails {
  /**
   * The name of the last uploaded file.
   */
  lastFileUploadedName: string;
  /**
   * The URL of the last uploaded file.
   */
  lastFileUploadedURL: string;
  /**
   * The key associated with the uploaded file.
   */
  uploadedFileKey: string;
  /**
   * The UTC time of file uploaded.
   */
  lastFileUploadedTimeUTC: string;
}

export type IConfigureDetailsModalKeys =
  | "uploadCSV"
  | "antiRefundStrategiesOrders"
  | "nonEligibleItems"
  | "nonEligibleOrders"
  | "returnReasonOrderAndItems"
  | "returnTypeBasedOnOrderOrItems"
  | "returnMethod"
  | "restockingFee"
  | "antiRefundStrategies"
  | "followUpQuestion";
export interface IActiveSubModal {
  name: "moreQuestions" | "followupQuestions" | "arsStages";
  reasonId?: string;
}
export interface ModalConfigureDetails {
  /**
   * string for fully automated, will add them later.
   * For csv upload if metaData is given then use the previousely uploaded file otherwise just show the uppload button.
   *
   */
  configureKey: IConfigureDetailsModalKeys;
  /**
   * Text for the label shown before the config button.
   */
  labelTextBeforeBtn?: null | string;
  /**
   * Text for the button in the modal.
   */
  btnText: string;
  /**
   * Text for the button in the modal.
   */
  btnBackgroundColor: string;
  /**
   * Subtext for the button in the modal.
   */
  btnSubText: string;
  /**
   * Status of the modal configuration.
   */
  status: "pending" | "completed";
  /**
   * Required for some of the configureKeys like uploadCSV and null if not required.
   */
  metaData?: null | UploadedCSVDetails;
  /**
   * If and only if `false`, then stops modal from editing.
   */
  editableAfterCompleted?: null | boolean;
}

/**
 * Possible types defined for the value of an option of all combo options.
 * The value `null` is not accepted by backend.
 */
export type StepQComboValueAllTypes = null | number | string | Array<string>;

/**
 * This is the value for the comboOptions.
 */
export interface StepQComboValue {
  // directly get the valueKey here
  [valueKey: string]: StepQComboValueAllTypes;
}

/**
 * This is part of different types of options to show the text then value select then again text and again value select.
 */
export interface StepQComboOption {
  valueType:
    | "showText"
    | "string"
    | "number"
    | "numberButton"
    | "multiSelect"
    | "singleSelect";

  /**
   * It will only be defined if the value type is not `showText`.
   *
   */
  valueKey?: null | string;
  /**
   * If valueType is showText the only this will come other wise this will be null.
   *
   */
  text?: null | string;
  /**
   * This property is used to define the available options for dropdown
   * that have a valueType of either "multiSelect" or "singleSelect"
   * It will always have the data for the values which are selected.
   */
  options?: null | Array<{
    label: string;
    value: string;
  }>;
  /**
   * It is needed as a check if we need to load more options.
   */
  loadOptions?: null | boolean;
  /**
   * Place holder for possible `valueType`.
   */
  placeholder?: null | string;
  /**
   * Added to make react select searchable
   */
  isSearchable?: boolean;
}

export interface SelectButtonDataType {
  /**
   * Inner text of button
   */
  label: string;
  /**
   * Value to be returned
   */
  value: string;
  /**
   * buttonName needed to show the icon, otherwise button will show without icon.
   */
  buttonName:
    | "automateWithoutInt"
    | "shopify"
    | "bigCommerce"
    | "wooCommerce"
    | "magento";
  /**
   * It will be false to disable the button. EX:- If integration with shopify is not done then that will be disabled.
   */
  disabled?: boolean;
}

export interface DropdownDetailsQuestion {
  dropdownId: string;
  dropdownHeading: string;
  sections: Array<{
    sectionId: string;
    heading?: null | string;
    valueType: "string" | "html";
    /**
     * Key to fetch and show or add the variables in the editor.
     * It can be `null` if we do not need to show variables at all.
     */
    editorVariablesKey?: null | string;
    /**
     * Should be false if adding variables is not allowed.
     */
    allowAddEditorVariablesKey?: boolean;
  }>;
}

export type DropdownDetailsValue = {
  sections: Record<
    // sectionId
    string,
    string
  >;
};

export interface StringListValueType {
  id: null | string;
  value: string;
}

export type AutomationQuestionKeys =
  | "returnWindow"
  | "returnNotEligibleResponseMessage"
  | "isReturnPolicySameForAllOrders"
  | "isReturnPolicySameForAllItem"
  | "resolutionOffer"
  | "resolutionOfferMessage"
  | "isResolutionOfferedSameForAllOrders"
  | "selectReturnReason"
  | "retunReasonList"
  | "isReturnReasonSameForAll"
  | "returnMethodForMajorityOfOrders"
  | "returnMethodDisplayMessage"
  | "antiReturnStrategies"
  | "configureAntiReturnStrategies"
  | "isReturnReasonOrFollowUpQuestionsSame"
  | "isReturnMethodSameForAllOrders"
  | "isRestockingFeeChargedForMajorityOrders"
  | "usuallyChargedRestockingFee"
  | "restockingFeeDisplayMessage"
  | "isRestockingFeeSameForAll"
  | "storeCurrency";

export interface StepQuestionAndValues {
  /** Unique identifier for the question. */
  questionId: string;
  /** Value provided for the question. */
  value?: null | number | string | Array<string>;
  /**
   * Value added for type stringList
   */
  stringListValue?: null | Array<StringListValueType>;
  /** Reason for any of the non-selection in string format. */
  extraValue?: null | string;
  /** For comboOptions */
  comboValue?: null | StepQComboValue;

  addressValue?: null | AddressQuestionValue;

  dropdownQuestionValue?: null | Record<
    // dropdownId
    string,
    DropdownDetailsValue
  >;

  /** This is for specific question types. */
  specificQuestionValue?:
    | null
    | EmailForwardingDetails
    | StripeDetails
    | WebhookQuestionDetails;
}

/**
 * Interface representing a question in a step.
 */
export interface StepQuestion extends StepQuestionAndValues {
  /** Title of the question. */
  questionTitle: string;

  questionKey: null | AutomationQuestionKeys;

  /**
   * If and only if `false`, then stops question from editing.
   * Right now it will only work with `valueTypes` = "multiSelectButtons" | "singleSelectButtons"
   */
  isEditable?: null | boolean;

  /**
   * Required in some questions to show some specific type of UI.
   * For specific questions valueType will be none and not be used.
   * Options will be fetched seperately by using the specificQuestionType.
   * value format will be detected using specificQuestionType only and will be saved as specifiiedValue.
   */
  specificQuestionType?:
    | null
    | "webhookQuestion"
    | "emailVerification"
    | "stripeDetails";

  /**
   * Configuration button details for showing the modal, if applicable for this question without any options.
   */
  configureDetails?: null | ModalConfigureDetails;

  /**
   * Flag to show the option to move to the next step or question, or just save question.
   * For fields where text updates are present. We will show move to next button by default. And the button's innerText depends on the value given by backend.
   * This is done to save the text data on button click.
   * If backend sends null. Auto save will work.
   */
  showMoveToNext?: null | "step" | "question" | "save";

  /** Type of value expected for the question. */
  valueType:
    | "string" /**For textarea */
    | "html" /**For TinyMCE */
    | "stringList" /** For stringList the Array<string> order will be the updated order. */
    | "number"
    | "multiCheck"
    | "singleCheck"
    | "multiSelect"
    | "singleSelect"
    | "multiSelectButtons"
    | "singleSelectButtons"
    | "combo"
    | "address"
    | "dropdownQuestions"
    | "none";

  dropdownQuestionDetails?: null | Array<DropdownDetailsQuestion>;

  stringListAddButtonLabel?: null | string;

  /**
   * It will work for `value` and `stringListValue`
   */
  placeholder?: null | string;

  /** Options for selection-based questions. */
  options?: null | Array<{
    /** Label for the option. */
    label: string;
    /** Value for the option. */
    value: string;

    // added keys required for options
    /** Opption Key for the option. */
    optionKey?:
      | null
      | "notRequired"
      | "yes"
      | "exchangeWithSameItems"
      | "exchangeWithDifferentItems"
      | "refundToStoreCredit"
      | "refundOriginalPayment"
      | "returnRequiredWithLabels";
  }>;
  /**
   * Reason for non-selection when any of the options or no options is selected.
   */
  extraTextQuestion?: null | {
    /** Heading for the reason. */
    heading: string;
    /** Label for the reason. */
    label: string;
    /** Placeholder for the reason. */
    placeholder?: null | string;
  };
  multiSelectButtonOptions?: null | Array<SelectButtonDataType>;
  /**
   * These are different types of options to show the text then value select then again text and again value select.
   */
  comboOptions?: null | Array<StepQComboOption>;

  /**
   * This is used to navigate to the integrations page in settings.
   */
  showLinkToNavigateToIntegrationSettings?: boolean;

  /**
   * Key to fetch and show or add the variables in the editor.
   * It can be `null` if we do not need to show variables at all.
   */
  editorVariablesKey?: null | string;
  /**
   * Should be false if adding variables is not allowed.
   */
  allowAddEditorVariablesKey?: boolean;
}

export interface TransferToAgentType {
  /** Flag indicating if transfer to agent is enabled. */
  enabled: boolean;
  /** Confirmation message for transferring to agent after this step. */
  confirmationMessage: string;
}

/**
 * Interface representing a step in a workflow.
 */
export interface StepData {
  /** Unique identifier for the step. */
  stepId: string;
  /** Title of the step. */
  stepTitle: string;
  /** Subtitle of the step. */
  stepSubTitle: string;
  /** Status of the step. */
  stepStatus: "pending" | "completed";
  /** Record of questions in the step indexed with questionId. */
  stepQuestions: Record<string, StepQuestion>;
  /** Array of questionIds in the step. */
  stepQuestionIds: Array<string>;
  /** Flag indicating if workflow can be tested after this step completed. */
  canTestWorkFlowAfterThisStepCompleted: boolean;
  /** Flag indicating if we can show it in steps configured. */
  showInStepsConfigured: boolean;
  /** Configuration for transferring to an agent, if applicable. */
  transferToAgent?: null | TransferToAgentType;
  /** Step Preview Key of the step. */
  stepKey: string;
}

/**
 * Interface representing the saved data for steps and their associated questions.
 */
export interface SavedStepAndQuestions {
  /**
   * The unique identifier for the step.
   * If not sent from backend this step will not be viewed in the UI.
   */
  stepId: string;

  showContext?: "workflow" | "stepsConfigured" | "both";

  /**
   * An array of question IDs associated with the step.
   * If any id not sent from backend then that question will not be viewed in the UI.
   *
   */
  questionIds: Array<string>;
  futureQuestionIds?: Array<string>;
}

/**
 * Interface representing the saved data for steps and their associated questions.
 * Here extra details are added to be returned from the API, if anything we need to update.
 */
export interface SavedStepAndQuestionData extends SavedStepAndQuestions {
  /**
   * Indicates whether the step is marked as completed. It can be `null` or `undefined`, or `StepData["stepStatus"]`.
   * Only needed if we need to change the status of step to completed, otherwise can be `null` or `undefined`.
   */
  stepStatus?: null | StepData["stepStatus"];
  /**
   * Array of questions in the step to be added or updated.
   * Anything sent as defined in StepQuestion will be updated.
   * Things which are not sent will not be updated.
   */
  updateOrAddQuestionsData?: null | Array<
    Partial<Omit<StepQuestion, "questionId">> & { questionId: string }
  >;
}

export interface ReturnAutoWorkFlowBrand {
  id: string;
  name: string;
  email: string;
  imageURL?: null | string;
}

/**
 * Interface representing the details of an automation workflow.
 */
export interface ReturnAutomationWorkFlowDetails {
  /** Unique identifier for the integration. */
  integrationId: string;
  /** Flag indicating if the workflow is configured. */
  configured: boolean;
  /** Flag indicating if the workflow is published. */
  published: boolean;
  /** Details of the brand associated with the workflow. */
  brand?: null | ReturnAutoWorkFlowBrand;
  /** Record of configured steps in the workflow indexed with stepId. */
  configuredSteps: Record<string, StepData>;
  /** Array of configured step ids in the workflow. */
  configuredStepIds: Array<string>;
  /**
   * An array of SavedStepAndQuestions objects.
   */
  savedStepAndQuestionIds: Array<SavedStepAndQuestions>;
  /**
   * For the active step.
   */
  activeStepId?: null | string;
}

/**
 * Base Questions End
 */

/**
 * Config Modal Starts
 */

export interface IAmount {
  /** The currency code for the price (e.g., $, USD, EUR). */
  currencyCode: string;
  /** The amount of the price. */
  amount: number;
}
export interface OrderRowData {
  type: null | "string" | "stringArray" | "number" | "price" | "date";
  value: null | string | number | IAmount;
}

export interface OrderData {
  orderId: string;
  rowData: OrderRowData[];
}

/**
 * Interface representing the details of all orders for email forwarding.
 */
export interface OrderInfoDetailsForEmailForwarding {
  /**
   * A record of heading objects for the table, each containing a heading id and heading name mapped to heading id.
   */
  tableHeadings: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
      /**
       * Represent the column is sortable or not
       */
      isSortable: boolean;
    }
  >;
  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: Array<string>;
  /**
   * An object representing the orders in the table.
   *
   * The `key` is the order ID, and the value is an object where keys are the column headings and values are the cell data.
   *
   */
  allOrders: {
    [orderId: string]: OrderData;
  };

  /** An array of all order IDs. */
  allOrderIds: Array<string>;

  /** The total number of orders. */
  totalOrders: number;
}

/**
 * Interface representing the filters that can be applied to the order information for email forwarding.
 */
export interface OrderInfoFiltersForEmailForwarding {
  /** The search text to filter orders by customer name or item names. */
  searchText?: string;
  /**
   * The sorting options for the orders.
   *
   * The key is the `tableHeadingId` and the value is the sort order (`asc` or `desc`).
   */
  sortBy?: Record<string, "asc" | "desc">;
}

/**
 * Interface representing the data of order information for email forwarding,
 * which includes the details of all orders and the applied filters.
 */
export interface OrderInfoDataForEmailForwarding
  extends OrderInfoDetailsForEmailForwarding {
  /** The filters applied to the order information. */
  filters: OrderInfoFiltersForEmailForwarding;
  /** The starting index for the orders to be fetched, used for pagination. */
  start: number;
  /** The maximum number of orders to be fetched in a single request, used for pagination. */
  limit: number;
}

// Use from here
export interface CSVTableVarientImage {
  imageId: string;
  imageURL: string;
  name: string;
}

export interface VarientRow {
  type?: null | "varientImage";
  value: null | CSVTableVarientImage | string;
}

export interface VarientData {
  varientId: string;
  rowData: VarientRow[];
}
/**
 * Interface representing the data of an uploaded CSV table.
 */
export interface CSVTableMappedData {
  /**
   * A record of heading objects for the table, each containing a heading id and heading name mapped to heading id.
   */
  tableHeadings: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
      /**
       * This is needed for the map attributes feature.
       * Here after changing the heading with anutoer one and on submit API the changedTableHeadingId will be mapped to tableHeadingId.
       */
      changedTableHeadingId?: null | string;
    }
  >;

  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: Array<string>;
  /**
   * Array of all the heading ids.
   */
  updatedTableHeadingIds?: Array<string>;

  /**
   * An object representing the variants in the table.
   * The `key` is the variant ID, and the value is an object where keys are the column headings and values are the cell data.
   * This is read only for map attributes and import step.
   * Only image will added or deleted in import step.
   */
  varients: {
    [varientId: string]: VarientData;
  };

  /** An array of all variant IDs. */
  varientIds: Array<string>;
}

/**
 * Interface representing an (uploaded or imported) and mapped CSV table, extending the uploaded CSV table data.
 */
export interface ImportedCSVTable extends CSVTableMappedData {
  /** The name of the imported file. */
  fileName: string;
  /** The total number of products in the table. */
  totalProducts: number;
  /** The total number of rows, which is the same as the total number of variants. */
  totalVarients: number;
  /** The total calculated SKU (Stock Keeping Unit). */
  totalSKU: number;
}

export interface ErrorDescription {
  description: string;
  info: string;
}
/**
 * The data for the corresponding row in the errors table.
 * - `type` can be `null`, `"string"`, `"description"` - for the field which have description.
 * - `value` can be `null`, a `string`, or an `ErrorDescription`.
 */
export interface ErrorRow {
  tableHeadingId: string;
  type: null | "string" | "description";
  value: null | string | ErrorDescription;
}
/**
 * Represents an individual error row.
 */
export interface IError {
  /** The unique identifier for the order. */
  errorId: string;
  rowData: { [tableHeadingId: string]: ErrorRow };
}

export type ErrorTypes = null | "error" | "warning";

/**
 * Represents a collection of errors found in an uploaded CSV file.
 */
export interface UploadedCSVErrors {
  attachmentId?: string;
  /**
   * Reperesents Heading for the errors
   * Example - "Column Mismatch", "Your impot has 6 errors"
   */
  heading?: null | string;
  /**
   * Subtitle or additional text for the error summary.
   * Example - "The columns in the uploaded CSV file do not match..."
   * Will not present incase of errors table
   */
  subText?: string;
  /**
   * The array of table headings to be displayed in the errors table.
   */
  tableHeadings?: Record<
    string,
    {
      /**
       * The `tableHeadingId` associated with the heading will be used in rows to render them.
       */
      tableHeadingId: string;
      /** The name of the heading. */
      tableHeadingName: string;
    }
  >;
  /**
   * Array of all the heading ids.
   */
  tableHeadingIds: string[];
  /**
   * The list of all errors based on the uploaded csv.
   */
  errors?: Record<string, IError>;
  /**
   * List of errorIds
   */
  errorIds?: Array<string>;
  /** This is used to check if the next button is clickable. */
  errorType?: ErrorTypes;
  /**
   * A link to download the CSV file with errors.
   */
  downloadErrorCSVLink?: string;
  /**
   * The total number of errors found in the CSV file.
   */
  totalErrorCount?: number;
}

/**
 * Represents a question used in configuring a CSV upload.
 */
export interface ConfigureCSVQuestion {
  /**
   * Unique identifier for the question.
   */
  questionId: string;
  /**
   * The title or text of the question.
   */
  questionTitle: string;
  /**
   * The value the question.
   */
  questionValue?: string;
  /**
   * An array of options available for the question.
   * Each option has a label and a value.
   */
  options: Array<{
    /**
     * The label of the option.
     */
    label: string;
    /**
     * The value of the option.
     */
    value: string;
  }>;
}

/**
 * Represents the attribute mapping to change the column headings while configuring a CSV upload.
 *
 */
export interface ConfigureCSVTableMappedAttributes extends CSVTableMappedData {
  /**
   * The total number of rows, which is the same as the total number of variants.
   */
  totalVarients: number;
  mapAttributeErrors: Record<string, MappedAttributeError>;
}

/**
 * Enumeration for the different upload csv modal steps.
 * These are the keys used to map steps to their corresponding components.
 */
export enum EUploadSteps {
  ConfigureUpload = "configureUpload",
  UploadCSV = "uploadCSV",
  MapAttributes = "mapAttributes",
  Import = "import",
  Completed = "completed",
}
export interface UploadStepsType {
  /**
   * Key representing the step in the upload csv flow.
   */
  key: EUploadSteps;

  /**
   * Name of the step, used for display purposes.
   */
  name: string;
  /**
   * Index of the step, used for display purposes.
   */
  index: number;
}
export type EConfigureCsvActiveStep =
  | "configureUpload"
  | "uploadCSV"
  | "mapAttributes"
  | "import"
  | "completed";

/**
 * Default steps list for upload new csv modal
 */
export const stepsList = [
  {
    key: EUploadSteps.ConfigureUpload,
    name: "Configure upload",
    index: 0,
  },
  {
    key: EUploadSteps.UploadCSV,
    name: "Upload CSV",
    index: 1,
  },
  {
    key: EUploadSteps.MapAttributes,
    name: "Map attributes",
    index: 2,
  },
  {
    key: EUploadSteps.Import,
    name: "Import",
    index: 3,
  },
  {
    key: EUploadSteps.Completed,
    name: "Successful",
    index: 4,
  },
];

/**
 * Represents the details of a CSV upload configuration process.
 *
 */
export interface ConfigureCSVUploadDetails {
  /**
   * The current active step in the upload process.
   * Can be one of the following: "configureUpload", "uploadCSV", "mapAttributes", "import", or "completed".
   */
  activeStep: EConfigureCsvActiveStep;
  /**
   * Required details for the configureUpload step.
   */
  configureUpload: {
    /**
     * An array of questions for configuring the CSV upload.
     */
    configureCSVQuestions: Array<ConfigureCSVQuestion>;
  };

  /**
   * Optional details for the uploadCSV step.
   */
  uploadCSV?: null | {
    /**
     * A link to a sample CSV template.
     */
    sampleCSVTemplateLink: string;
    /**
     * An optional link to the uploaded file.
     * Can be null.
     */
    uploadedFileLink?: null | string;
    /**
     * Uploaded file name.
     */
    uploadedFileName?: null | string;
    /**
     * Optional errors found during the upload.
     * Can be null.
     */
    uploadErrors?: null | UploadedCSVErrors;
    /**
     * An optional file uploaded by the user.
     * Required for the uploadCSV page but used only on the frontend.
     * Can be null.
     */
    uploadedFile?: null | File;
    attachmentId?: null | string;
  };

  /**
   * For mapping the attributes, we can change the headimg of the columns among all the processed headings.
   * It can be null or undefined initially.
   */
  mapAttributes?: null | ConfigureCSVTableMappedAttributes;

  /**
   * For the import step of the CSV upload process.
   * It can be null or undefined initially.
   */
  importedCSV?: null | ImportedCSVTable;
}
// Use till here

/**
 * Represents the details of an email scenario, including the visual and content elements
 * that will be displayed in the email.
 */
export interface EmailScenarioDetails {
  /**
   * The title of the view to be displayed.
   */
  viewTitle: string;
  /**
   * The URL or path to the logo image to be displayed in the email.
   */
  emailLogo: string;
  /**
   * The subject line of the email.
   */
  emailSubject: string;
  /**
   * The main heading of the email content.
   */
  emailHeading: string;
  /**
   * An array of paragraphs that make up the body content of the email.
   */

  emailBody: Array<{
    /**
     * The heading of the paragraph.
     * @example "First paragraph"
     * @example "Closing paragraph"
     */
    paraHeading: string;
    /**
     * The text content of the paragraph.
     */
    paraValue: string;
    /**
     * Key to fetch and show or add the variables in the editor.
     * It can be `null` if we do not need to show variables at all.
     */
    editorVariablesKey?: null | string;
    /**
     * Should be false if adding variables is not allowed.
     */
    allowAddEditorVariablesKey?: boolean;
    /**
     * Flag indicating whether the paragraph is editable.
     */
    isEditable: boolean;
    /**
     * Determines where the paragraph should be shown:
     * - "edit" for editing in sidesection.
     * - "view" for viewing in desktop or mobile view.
     * - "both" for showing in both the views.
     */
    showIn: "edit" | "view" | "both";
    /**
     * Determines if the non-editable view of the paragraph should be shown on hover or always.
     * - "hover" to show on hover
     * - "always" to always show over the paragraph.
     */
    showNonEditableView: "hover" | "always";
    /**
     * Flag indicating whether a shaded background should be shown in the view mode for the paragraph.
     */
    showShadedBGInView: boolean;
  }>;

  /**
   * The email address to which the preview of the email should be sent.
   */
  sendPreviewOnEmail: string;
}

/**
 * Represents a sub-scenario of an email scenario, containing its own unique ID, title, subtitle, and detailed view.
 */
export interface SubEmailScenario {
  /**
   * The unique identifier for the sub-scenario.
   */
  subScenarioId: string;
  /**
   * The side title for the sub-scenario, typically displayed in the navigation or overview.
   */
  subSideTitle: string;
  /**
   * The side subtitle providing additional context or description for the sub-scenario.
   */
  subSideSubtitle: string;
  /**
   * The detailed view settings and content for the sub-scenario.
   */
  viewDetails: EmailScenarioDetails;
}

/**
 * Represents an email scenario, which may include multiple sub-scenarios and detailed view settings.
 */
export interface EmailScenario {
  /**
   * The unique identifier for the scenario.
   */
  scenarioId: string;
  /**
   * The side title for the scenario, typically displayed in the navigation or overview.
   */
  sideTitle: string;
  /**
   * The side subtitle providing additional context or description for the scenario.
   */
  sideSubtitle: string;

  /**
   * Optional sub-scenarios included within the main email scenario.
   * If present, it contains records of sub-scenarios and their IDs.
   */
  subSecnarios?: null | {
    /**
     * A record of sub-scenarios by their unique identifiers.
     */
    emailSubScenarios: Record<string, SubEmailScenario>;
    /**
     * An array of sub-scenario IDs for ordering or reference.
     */
    emailSubScenarioIds: Array<string>;
  };

  /**
   * Optional detailed view settings and content for the email scenario.
   */
  viewDetails?: null | EmailScenarioDetails;
}

/**
 * Represents the configuration data structure for managing multiple email scenarios.
 */
export interface ConfigureEmailScenariosData {
  /**
   * A record of email scenarios by their unique identifiers.
   */
  emailScenarios: Record<string, EmailScenario>;
  /**
   * An array of email scenario IDs for ordering or reference.
   */
  emailScenarioIds: Array<string>;
}

/**
 * Config Modal Ends
 */
