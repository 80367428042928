import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { addEmailDomainService } from "src/services/EmailIntegration/addEmailDomain.service";
import { OutgoingRecieveType } from "src/services/EmailIntegration/getEmailIntegrationById";
import { updateEmailIntegration } from "src/services/EmailIntegration/updateEmailIntegration";
import { MailTypeEnum } from "src/store/slices/emailIntegration/emailIntegration.slices";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  EOutboundEmailSteps,
  OutboundEmailIntegrationParams,
} from "./OutboundEmailIntegration";

const useOutboundEmailIntegration = () => {
  const { activeStep } = useParams<OutboundEmailIntegrationParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { brands, loading } = useAppSelector(
    (state) => state.globals.brandSignatureData,
  );

  const integrationId = useMemo(
    () => brands?.find((val) => val.isDefault)?.emailIntegrationId,
    [brands],
  );

  const { selectedUser, emailLoading } = useAppSelector(
    (state) => state.emailIntegration,
  );

  const currentState = useRef({
    outgoingMailSettings: selectedUser.outgoingMailSettings,
    emailLoading,
    loading,
  });

  useMemo(() => {
    currentState.current.outgoingMailSettings =
      selectedUser.outgoingMailSettings;
    currentState.current.emailLoading = emailLoading;
    currentState.current.loading = loading;
  }, [emailLoading, loading, selectedUser.outgoingMailSettings]);

  const [selectedType, setSelectedType] = useState<OutgoingRecieveType>(
    selectedUser.outgoingMailSettings?.outgoingType ?? "currentHostWithDNS",
  );
  const [checkingAuth, setCheckingAuth] = useState(false);
  const fetching = useRef<boolean>(false);
  const initialFetch = useRef<boolean>(true);

  const currentStep = useMemo(() => {
    if (!activeStep) {
      return 0;
    }

    switch (activeStep) {
      case EOutboundEmailSteps.AddEmail:
        return 1;
      case EOutboundEmailSteps.OutgoingEmails:
        return 2;
      case EOutboundEmailSteps.Complete:
        return 3;
      default:
        const exhaustiveCheck: never = activeStep; // Ensures type is exhaustive
        console.error(`Unhandled active step: ${exhaustiveCheck}`);
        return 0;
    }
  }, [activeStep]);

  /**
   * Disable next button or not
   */
  const disableNextButton = useMemo(() => {
    return (
      !selectedUser.isDomainVerified ||
      !(
        selectedUser.outgoingMailSettings?.integrations &&
        selectedUser.outgoingMailSettings.integrations[selectedType]?.verified
      )
    );
  }, [
    selectedType,
    selectedUser.isDomainVerified,
    selectedUser.outgoingMailSettings?.integrations,
  ]);

  const handleSelect = useCallback(
    (selectedType: OutgoingRecieveType) => {
      setSelectedType(selectedType);
      if (
        selectedUser.outgoingMailSettings?.integrations &&
        selectedUser.outgoingMailSettings?.integrations[selectedType]?.verified
      ) {
        updateEmailIntegration({
          emailIntegrationId: Number(selectedUser.emailIntegrationId),
          outgoingMailSettingType: selectedType,
        });
      }
    },
    [
      selectedUser.emailIntegrationId,
      selectedUser.outgoingMailSettings?.integrations,
    ],
  );

  const handleCheckAuth = useCallback(async () => {
    if (fetching.current) {
      return;
    }
    try {
      // Check if Domain Auth is added
      setCheckingAuth(true);
      fetching.current = true;
      await addEmailDomainService({
        emailIntegrationId: selectedUser.emailIntegrationId,
      });
      // Update auth data
      dispatch(
        fetchGetEmailIntegrationById({
          emailIntegrationId: Number(selectedUser.emailIntegrationId),
        }),
      );
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });

      navigate(
        `/settings/integrations/outboundEmail/${EOutboundEmailSteps.AddEmail}`,
      );
    } finally {
      setCheckingAuth(false);
      fetching.current = false;
    }
  }, [dispatch, navigate, selectedUser.emailIntegrationId]);

  const redirectCallback = useCallback(
    (status: string | number | undefined) => {
      if (!initialFetch.current) {
        return;
      }

      initialFetch.current = false;

      if (status === "email_not_verified") {
        navigate(
          `/settings/integrations/outboundEmail/${EOutboundEmailSteps.AddEmail}`,
        );
      } else if (status === "domain_not_verified") {
        navigate(
          `/settings/integrations/outboundEmail/${EOutboundEmailSteps.OutgoingEmails}`,
        );
      } else if (status === undefined) {
        navigate(`/settings/browse-integration`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (integrationId && currentState.current.emailLoading !== "pending") {
      dispatch(
        fetchGetEmailIntegrationById({
          emailIntegrationId: Number(integrationId),
          // Callback for redirecting user
          callback: redirectCallback,
        }),
      );
    }
  }, [dispatch, integrationId, redirectCallback]);

  useEffect(() => {
    // Check data is loaded and domain auth is not updated
    if (selectedUser.emailIntegrationId !== -1) {
      if (
        (!selectedUser.isDomainVerified &&
          !selectedUser.isDomainAddedForAuth) ||
        (!(
          currentState.current.outgoingMailSettings?.integrations &&
          currentState.current.outgoingMailSettings.integrations[
            "currentHostWithDNS"
          ]?.verified
        ) &&
          !selectedUser.isDomainAddedForAuth)
      ) {
        handleCheckAuth();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser.emailIntegrationId, selectedUser.isDomainAddedForAuth]);

  useEffect(() => {
    setSelectedType(
      selectedUser.outgoingMailSettings?.outgoingType ??
        (selectedUser.mailType.id === MailTypeEnum.gmail
          ? "gmail"
          : "currentHostWithDNS"),
    );
  }, [
    selectedUser.mailType.id,
    selectedUser.outgoingMailSettings?.outgoingType,
  ]);

  return {
    activeStep,
    currentStep,
    checkingAuth,
    isLoading:
      currentState.current.emailLoading === "pending" ||
      currentState.current.loading,
    selectedUser,
    selectedType,
    disableNextButton,
    handleSelect,
    navigate,
  };
};

export default useOutboundEmailIntegration;
