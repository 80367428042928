/**
 * This file defines the main component for managing automations.
 * It includes a sidebar for navigation and displays various automation-related sections
 * using React Router.
 *
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 * @author @ayush-busibud
 */
import { useMemo, useState } from "react";
import { Route, Routes, useLocation, useMatch } from "react-router-dom";
import ComingSoonBots from "src/components/ComingSoonScreens/ComingSoonBots/ComingSoonBots";
import { ComingSoonFeatureTypes } from "src/services/ComingSoon/reqCmngSnFeature.service";
import { useAppSelector } from "src/store/store";
import Return from "../Setting/children/MainSetting/children/SetRulesNew/childerns/Return";
import AddAutomation from "../Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/AddAutomation";
import { WidgetPreviewSection } from "../Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/WidgetPreviewSection";
import { useReturnPreviewProvider } from "../Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/WidgetPreviewSection/hooks/useReturnPreview";
import styles from "./BotSettings.module.scss";
import Automation from "./Children/Automation/Automation";
import AutomationsList, {
  EAutomationType,
} from "./Children/AutomationList/AutomationList";
import AutomationSidebar from "./Children/AutomationSidebar/AutomationSidebar";
import ChatBot from "./Children/BotBox/ChatBot/ChatBot";
import BotProfiles from "./Children/BotProfiles/BotProfiles";
import ReturnAutoWorkFlow from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow";

// Define a union type for all valid paths.
export type BotSettingsActivePath =
  | "bot"
  | "warranty"
  | "tracking"
  | "modification"
  | "monitoring"
  | "automations";

// Define a union type for all valid paths.
export type BotSettingsActiveSubPath =
  | "bugManagement"
  | "return"
  | "issueDispatch"
  | "ticketRouting";

/**
 * Utility function to determine the active path based on the URL segments.
 * @param paths - Array of URL segments.
 * @returns - The determined active path or undefined if no match found.
 */
const getActivePath = (paths: string[]): BotSettingsActivePath | undefined => {
  const validPaths: BotSettingsActivePath[] = [
    "bot",
    "warranty",
    "tracking",
    "modification",
    "monitoring",
    "automations",
  ];

  // Iterate backwards through the paths array
  for (let i = paths.length - 1; i >= 0; i--) {
    if (validPaths.includes(paths[i] as BotSettingsActivePath)) {
      return paths[i] as BotSettingsActivePath;
    }
  }

  return undefined; // Return undefined if no match is found
};

const getActiveSubPath = (
  paths: string[],
): BotSettingsActiveSubPath | undefined => {
  const validPaths: BotSettingsActiveSubPath[] = [
    "bugManagement",
    "return",
    "issueDispatch",
    "ticketRouting",
  ];

  // Iterate backwards through the paths array
  for (let i = paths.length - 1; i >= 0; i--) {
    if (validPaths.includes(paths[i] as BotSettingsActiveSubPath)) {
      return paths[i] as BotSettingsActiveSubPath;
    }
  }

  return undefined; // Return undefined if no match is found
};

const BotSettings = () => {
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const [sidebarHover, setSidebarHover] = useState(false);
  const location = useLocation();
  const { ReturnPreviewProvider, ...contexValue } = useReturnPreviewProvider();

  const paths = useMemo(() => {
    return location.pathname.split("/");
  }, [location]);

  const activePath = useMemo(() => getActivePath(paths), [paths]);
  const activeSubPath = useMemo(() => getActiveSubPath(paths), [paths]);

  const showComingSoon = useMemo(() => {
    const comingSoonKeys = [
      "warranty",
      "tracking",
      "modification",
      "monitoring",
      "return",
    ];

    return comingSoonKeys.includes(activePath ?? "");
  }, [activePath]);

  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  const showAnalytics = useMemo(() => {
    if (!ui_visibility) {
      return false;
    }

    const analyticsPermissions = [
      "bot_percentageCernFoundUseful",
      "bot_volumeOfChartsByCern",
      "bot_chartsCernDidNotFindAnswersByTime",
      "bot_mostUsedArticlesByCernToAnswerQuestions",
      "bot_mostUsedCustomAnsByCernToAnswerQuestions",
    ] as typeof ui_visibility;

    return analyticsPermissions.some((permission) =>
      ui_visibility.includes(permission),
    );
  }, [ui_visibility]);

  const { pathname } = location;
  // Check if the URL does not include "/" and "/botProfiles"
  const shouldAddClass = pathname !== "/bot" && pathname !== "/bot/botProfiles";
  // Match the current route against a specified path
  const matchReturnRoute = useMatch("/automations/return/:integrationId/*");

  return (
    <>
      <div
        className={`${styles.botWrap} position-relative`}
        id="botSettings"
      >
        <h3 className={`mb-3 ms-4 ps-3 mt-4  ${styles.heading}`}>
          {showComingSoon ? "Decision maker" : "Automations"}
        </h3>

        <div className={`d-flex w-100`}>
          {/* Sidebar */}
          <AutomationSidebar
            activePath={activePath}
            activeSubPath={activeSubPath}
            sidebarHover={sidebarHover}
            setSidebarHover={setSidebarHover}
          />

          <div
            className={`ms-auto me-3 ${styles.mainSection} ${
              sidebarHover ? styles.hover : ""
            } ${shouldAddClass ? styles.contentClass : ""} `}
            style={{
              paddingLeft: showComingSoon ? "0px" : undefined,
            }}
          >
            {activePath === "bot" &&
              currentUserData?.ui_visibility &&
              currentUserData?.ui_visibility?.includes("chat_bot_view") && (
                <BotRoutes showAnalytics={showAnalytics} />
              )}
            {/* coming soon routes  */}
            {showComingSoon && (
              <ComingSoonBots
                activePath={activePath as ComingSoonFeatureTypes}
              />
            )}
            {activePath === "automations" &&
              (activeSubPath !== EAutomationType.RETURN ? (
                <AutomationRoutes />
              ) : (
                <div>
                  {currentUserData?.ui_visibility &&
                    currentUserData?.ui_visibility?.includes(
                      "automation_return_exchange_view",
                    ) && (
                      // Return preview context
                      // <ReturnPreviewProvider value={contexValue}>
                      //   <Routes>
                      //     {/* Added By Dhiraj for Automation settings */}
                      //     <Route
                      //       path="/return"
                      //       element={<Return />}
                      //     />
                      //     <Route
                      //       path="/return/:integrationId"
                      //       element={<AddAutomation />}
                      //     />
                      //   </Routes>
                      //   {matchReturnRoute && <WidgetPreviewSection />}
                      // </ReturnPreviewProvider>

                      <Routes>
                        <Route
                          path="/return"
                          element={<Return />}
                        />
                        <Route
                          path="/:automationContext/:integrationId"
                          element={<ReturnAutoWorkFlow />}
                        />
                      </Routes>
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const AutomationRoutes = () => {
  return (
    <Routes>
      <Route
        path={`/:type`}
        element={<AutomationsList />}
      />
      <Route
        path={`/:type/:integrationId`}
        element={<Automation />}
      />
    </Routes>
  );
};

/**
 * BotRoutes Component
 * Handles the routing for bot-related pages.
 */
const BotRoutes = ({ showAnalytics }: { showAnalytics?: boolean }) => {
  return (
    <Routes>
      <Route
        path={`/`}
        element={<BotProfiles />}
      />
      <Route
        path={`/botProfiles`}
        element={<BotProfiles />}
      />
      <Route
        path={`/botProfiles/:profileId/:tab`}
        element={<ChatBot />}
      />
      <Route
        path={`/botProfiles/:profileId/:tab/:subTab`}
        element={<ChatBot />}
      />
      <Route
        path={`/botProfiles/:profileId/:tab/:subTab/:subTabSection`}
        element={<ChatBot />}
      />
      {/* {showAnalytics && (
        <Route
          path={`/botProfiles/:profileId/analytics`}
          element={<ChatBot />}
        />
      )} */}
    </Routes>
  );
};

export default BotSettings;
