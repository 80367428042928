import ParseHTML from "src/components/ParseHTML";

const string = `<html
  dir="ltr"
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="und"
  style="overflow-x: visible !important"
>
  <head>
    <meta charset="UTF-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta content="telephone=no" name="format-detection" />
    <title></title>
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins&amp;display=swap"
      rel="stylesheet"
    />
    <!--<![endif]-->
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
      .es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
      @media only screen and (max-width: 600px) {
        p,
        ul li,
        ol li,
        a {
          line-height: 150% !important;
        }
        h1,
        h2,
        h3,
        h1 a,
        h2 a,
        h3 a {
          line-height: 120%;
        }
        h1 {
          font-size: 30px !important;
          text-align: left;
        }
        h2 {
          font-size: 24px !important;
          text-align: left;
        }
        h3 {
          font-size: 20px !important;
          text-align: left;
        }
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 30px !important;
          text-align: left;
        }
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 24px !important;
          text-align: left;
        }
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 20px !important;
          text-align: left;
        }
        .es-menu td a {
          font-size: 12px !important;
        }
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li,
        .es-header-body a {
          font-size: 14px !important;
        }
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li,
        .es-content-body a {
          font-size: 14px !important;
        }
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li,
        .es-footer-body a {
          font-size: 14px !important;
        }
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li,
        .es-infoblock a {
          font-size: 12px !important;
        }
        *[class="gmail-fix"] {
          display: none !important;
        }
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3 {
          text-align: center !important;
        }
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3 {
          text-align: right !important;
        }
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3 {
          text-align: left !important;
        }
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important;
        }
        .es-button-border {
          display: inline-block !important;
        }
        a.es-button,
        button.es-button {
          font-size: 18px !important;
          display: inline-block !important;
        }
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important;
        }
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important;
        }
        .es-adapt-td {
          display: block !important;
          width: 100% !important;
        }
        .adapt-img {
          width: 100% !important;
          height: auto !important;
        }
        .es-m-p0 {
          padding: 0 !important;
        }
        .es-m-p0r {
          padding-right: 0 !important;
        }
        .es-m-p0l {
          padding-left: 0 !important;
        }
        .es-m-p0t {
          padding-top: 0 !important;
        }
        .es-m-p0b {
          padding-bottom: 0 !important;
        }
        .es-m-p20b {
          padding-bottom: 20px !important;
        }
        .es-mobile-hidden,
        .es-hidden {
          display: none !important;
        }
        tr.es-desk-hidden,
        td.es-desk-hidden,
        table.es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important;
        }
        tr.es-desk-hidden {
          display: table-row !important;
        }
        table.es-desk-hidden {
          display: table !important;
        }
        td.es-desk-menu-hidden {
          display: table-cell !important;
        }
        .es-menu td {
          width: 1% !important;
        }
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important;
        }
        table.es-social {
          display: inline-block !important;
        }
        table.es-social td {
          display: inline-block !important;
        }
        .es-desk-hidden {
          display: table-row !important;
          width: auto !important;
          overflow: visible !important;
          max-height: inherit !important;
        }
        .es-m-p5 {
          padding: 5px !important;
        }
        .es-m-p5t {
          padding-top: 5px !important;
        }
        .es-m-p5b {
          padding-bottom: 5px !important;
        }
        .es-m-p5r {
          padding-right: 5px !important;
        }
        .es-m-p5l {
          padding-left: 5px !important;
        }
        .es-m-p10 {
          padding: 10px !important;
        }
        .es-m-p10t {
          padding-top: 10px !important;
        }
        .es-m-p10b {
          padding-bottom: 10px !important;
        }
        .es-m-p10r {
          padding-right: 10px !important;
        }
        .es-m-p10l {
          padding-left: 10px !important;
        }
        .es-m-p15 {
          padding: 15px !important;
        }
        .es-m-p15t {
          padding-top: 15px !important;
        }
        .es-m-p15b {
          padding-bottom: 15px !important;
        }
        .es-m-p15r {
          padding-right: 15px !important;
        }
        .es-m-p15l {
          padding-left: 15px !important;
        }
        .es-m-p20 {
          padding: 20px !important;
        }
        .es-m-p20t {
          padding-top: 20px !important;
        }
        .es-m-p20r {
          padding-right: 20px !important;
        }
        .es-m-p20l {
          padding-left: 20px !important;
        }
        .es-m-p25 {
          padding: 25px !important;
        }
        .es-m-p25t {
          padding-top: 25px !important;
        }
        .es-m-p25b {
          padding-bottom: 25px !important;
        }
        .es-m-p25r {
          padding-right: 25px !important;
        }
        .es-m-p25l {
          padding-left: 25px !important;
        }
        .es-m-p30 {
          padding: 30px !important;
        }
        .es-m-p30t {
          padding-top: 30px !important;
        }
        .es-m-p30b {
          padding-bottom: 30px !important;
        }
        .es-m-p30r {
          padding-right: 30px !important;
        }
        .es-m-p30l {
          padding-left: 30px !important;
        }
        .es-m-p35 {
          padding: 35px !important;
        }
        .es-m-p35t {
          padding-top: 35px !important;
        }
        .es-m-p35b {
          padding-bottom: 35px !important;
        }
        .es-m-p35r {
          padding-right: 35px !important;
        }
        .es-m-p35l {
          padding-left: 35px !important;
        }
        .es-m-p40 {
          padding: 40px !important;
        }
        .es-m-p40t {
          padding-top: 40px !important;
        }
        .es-m-p40b {
          padding-bottom: 40px !important;
        }
        .es-m-p40r {
          padding-right: 40px !important;
        }
        .es-m-p40l {
          padding-left: 40px !important;
        }
      }
      @media screen and (max-width: 384px) {
        .mail-message-content {
          width: 414px !important;
        }
      }
    </style>
    <link
      href="assets/css/dev-custom-scroll.css"
      rel="stylesheet"
      type="text/css"
    />
    <base target="_blank" />
  </head>
  <body
    style="
      width: 100%;
      font-family: arial, 'helvetica neue', helvetica, sans-serif;
      text-size-adjust: 100%;
      padding: 0px;
      margin: 0px;
      overflow-y: scroll !important;
      visibility: visible !important;
    "
  >
    <div
      dir="ltr"
      class="es-wrapper-color"
      lang="und"
      style="background-color: #f6f6f6"
    >
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#f6f6f6"></v:fill>
        </v:background>
      <![endif]-->
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
          background-color: #f6f6f6;
        "
      >
        <tbody>
          <tr>
            <td valign="top" style="padding: 0; margin: 0">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                  background-color: transparent;
                  background-repeat: repeat;
                  background-position: center top;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #3d85c6;
                          border-radius: 20px 20px 0 0;
                          max-width: 600px;
                          width: 100%
                        "
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:520px" cellpadding="0"
                            cellspacing="0"><tr><td style="width:156px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p0r es-m-p20b"
                                      valign="top"
                                      align="center"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 156px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #ffffff;
                                                  font-size: 12px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_32d55458cc42a293bf7e1691e2c3cf9c/images/group_381.png"
                                                  alt="Logo"
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                  "
                                                  width="40"
                                                  title="Logo"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:344px" valign="top"><![endif]-->
                              <table
                                class="es-right"
                                cellpadding="0"
                                cellspacing="0"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 344px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links">
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="33%"
                                                      id="esd-menu-id-0"
                                                      style="
                                                        margin: 0;
                                                        padding-left: 5px;
                                                        padding-right: 5px;
                                                        padding-top: 15px;
                                                        padding-bottom: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 12px;
                                                        "
                                                        >Home</span
                                                      >
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="33%"
                                                      id="esd-menu-id-1"
                                                      style="
                                                        margin: 0;
                                                        padding-left: 5px;
                                                        padding-right: 5px;
                                                        padding-top: 15px;
                                                        padding-bottom: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 12px;
                                                        "
                                                        >Blog</span
                                                      >
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="33%"
                                                      id="esd-menu-id-2"
                                                      style="
                                                        margin: 0;
                                                        padding-left: 5px;
                                                        padding-right: 5px;
                                                        padding-top: 15px;
                                                        padding-bottom: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 12px;
                                                        "
                                                        >Contact</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        role="none"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #3d85c6;
                          max-width: 600px;
                          width: 100%;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              background="https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/frame_367_QCr.png"
                              style="
                                margin: 0;
                                padding-top: 30px;
                                padding-bottom: 30px;
                                padding-left: 40px;
                                padding-right: 40px;
                                background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/frame_367_QCr.png);
                                background-repeat: no-repeat;
                                background-position: center bottom;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 30px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 20px; margin: 0"
                                            >
                                              <h1
                                                style="
                                                  margin: 0;
                                                  line-height: 60px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 50px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                You're invited to join our
                                                <strong>webinars</strong>
                                              </h1>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 20px;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                Hello&nbsp;Stewart,<br /><br />Duis
                                                aute irure dolor in
                                                reprehenderit in voluptate velit
                                                esse cillum dolore eu fugiat
                                                nulla pariatur. Excepteur sint
                                                occaecat cupidatat non proident,
                                                sunt in culpa qui officia
                                                deserunt mollit anim id.
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        role="none"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #3d85c6;
                          max-width: 600px;
                          width: 100%;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              style="
                                margin: 0;
                                padding-bottom: 10px;
                                padding-top: 40px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 33.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 28px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Upcoming
                                                <strong>Webinars</strong>
                                              </h2>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:520px" cellpadding="0" cellspacing="0"><tr><td style="width:111px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="padding: 0; margin: 0; width: 91px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 30px 30px 0px;
                                          background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png);
                                          background-repeat: no-repeat;
                                          background-position: right bottom;
                                        "
                                        background="https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-p5t es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 5px;
                                                padding-top: 10px;
                                              "
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 33.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 28px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                10
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="45%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                  width: 45% !important;
                                                  display: inline-table;
                                                "
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 10px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Jan&nbsp;
                                              </h3>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:236px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 236px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p15t"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 25px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Q&amp;A Sessions
                                              </h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p5t"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                Tuesday •
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >11AM PT</span
                                                >&nbsp;|&nbsp;<span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >2PM ET</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:153px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 153px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="right"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 30px;
                                              "
                                            >
                                              <span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #e69138;
                                                  background: #ffffff;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  class="es-button"
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #e69138;
                                                    font-size: 16px;
                                                    padding: 10px 20px 10px 20px;
                                                    display: inline-block;
                                                    background: #ffffff;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 19.2px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #ffffff;
                                                  "
                                                  >Register now</span
                                                >
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:520px" cellpadding="0" cellspacing="0"><tr><td style="width:111px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="padding: 0; margin: 0; width: 91px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 30px 30px 0px;
                                          background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png);
                                          background-repeat: no-repeat;
                                          background-position: right bottom;
                                        "
                                        background="https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-p5t es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 5px;
                                                padding-top: 10px;
                                              "
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 33.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 28px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                23
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="45%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                  width: 45% !important;
                                                  display: inline-table;
                                                "
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 10px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Feb
                                              </h3>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:236px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 236px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p15t"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Case Studies and Success Stories
                                              </h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p5t"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                Wednesday •
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >11AM PT</span
                                                >&nbsp;|&nbsp;<span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >2PM ET</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:153px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 153px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="right"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 30px;
                                              "
                                            >
                                              <span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #e69138;
                                                  background: #ffffff;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  class="es-button"
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #e69138;
                                                    font-size: 16px;
                                                    padding: 10px 20px 10px 20px;
                                                    display: inline-block;
                                                    background: #ffffff;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 19.2px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #ffffff;
                                                  "
                                                  >Register now</span
                                                >
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <!--[if mso]><table style="width:520px" cellpadding="0" cellspacing="0"><tr><td style="width:111px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="padding: 0; margin: 0; width: 91px"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 30px 30px 0px;
                                          background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png);
                                          background-repeat: no-repeat;
                                          background-position: right bottom;
                                        "
                                        background="https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/rectangle_5597_bEP.png"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-p5t es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 5px;
                                                padding-top: 10px;
                                              "
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 33.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 28px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                17
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="45%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                  width: 45% !important;
                                                  display: inline-table;
                                                "
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 10px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Mar
                                              </h3>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 20px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:236px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 236px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p15t es-m-p0r"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-right: 10px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Integrations with Other Tools
                                              </h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-p5t"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 10px;
                                              "
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                Tuesday •
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >11AM P</span
                                                >&nbsp;|&nbsp;<span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >2PM ET</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:153px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 153px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="right"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 30px;
                                              "
                                            >
                                              <span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #e69138;
                                                  background: #ffffff;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  class="es-button"
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #e69138;
                                                    font-size: 16px;
                                                    padding: 10px 20px 10px 20px;
                                                    display: inline-block;
                                                    background: #ffffff;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 19.2px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #ffffff;
                                                  "
                                                  >Register now</span
                                                >
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="100%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #9fc5e8;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 40px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #e69138;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  class="es-button"
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 18px;
                                                    padding: 10px 20px 10px 20px;
                                                    display: inline-block;
                                                    background: #e69138;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 21.6px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #e69138;
                                                  "
                                                  >All webinars</span
                                                >
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        role="none"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #3d85c6;
                          max-width: 600px;
                          width: 100%;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-bottom: 5px;
                                padding-top: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-bottom: 20px;
                                              "
                                            >
                                              <h2
                                                style="
                                                  margin: 0;
                                                  line-height: 33.6px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 28px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Book a
                                                <strong>live demo</strong>
                                              </h2>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#ffffff"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #ffffff;
                                          border-radius: 15px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 5px;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                                ><img
                                                  class="adapt-img"
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_1298dc8aa01fe34f7c62e4093dd0ee11c9d95a479ff5bbf11dd3d0b4ae8fa7d2/images/playvideobuttonsignsymboliconbubblespeechchat3drenderingremovebgpreview_1.png"
                                                  alt=""
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    max-width: 230px;
                                                    width: 100%;
                                                  "
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-bottom: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <!--[if mso]><table style="max-width:520px" cellpadding="0" cellspacing="0"><tr><td style="max-width:105px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                align="left"
                                class="es-left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="es-m-p5b"
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 15px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 10px; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 30px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Mon
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >11AM</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 5px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:105px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      class="es-m-p5b"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#e6f3fe"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #e6f3fe;
                                          border-radius: 15px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 10px; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 30px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #0b5394;
                                                "
                                              >
                                                Tue
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #0b5394;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #0b5394;
                                                    font-size: 16px;
                                                  "
                                                  >2PM
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 5px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:105px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      class="es-m-p5b"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 15px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 10px; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 30px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Wed
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >2PM
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      class="es-hidden"
                                      style="padding: 0; margin: 0; width: 5px"
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:100px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      class="es-m-p5b"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#e6f3fe"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #e6f3fe;
                                          border-radius: 15px;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 10px; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 30px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #0b5394;
                                                "
                                              >
                                                Thu
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #0b5394;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #0b5394;
                                                    font-size: 16px;
                                                  "
                                                  >11AM</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:5px"></td><td style="width:100px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        width: 100px;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          border-radius: 15px;
                                          background-color: #0b5394;
                                        "
                                        bgcolor="#0b5394"
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-txt-c"
                                              style="padding: 10px; margin: 0"
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 30px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Fri
                                              </h3>
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #ffffff;
                                                    font-size: 16px;
                                                  "
                                                  >11AM</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              style="
                                margin: 0;
                                padding-top: 20px;
                                padding-bottom: 30px;
                                padding-left: 40px;
                                padding-right: 40px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <span
                                                class="msohide es-button-border"
                                                style="
                                                  border-style: solid;
                                                  border-color: #2cb543;
                                                  background: #e69138;
                                                  border-width: 0px;
                                                  display: inline-block;
                                                  border-radius: 30px;
                                                  width: auto;
                                                  mso-hide: all;
                                                "
                                                ><span
                                                  class="es-button"
                                                  style="
                                                    mso-style-priority: 100 !important;
                                                    text-decoration: none;
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    color: #ffffff;
                                                    font-size: 18px;
                                                    padding: 10px 20px 10px 20px;
                                                    display: inline-block;
                                                    background: #e69138;
                                                    border-radius: 30px;
                                                    font-family: Poppins,
                                                      sans-serif;
                                                    font-weight: normal;
                                                    font-style: normal;
                                                    line-height: 21.6px;
                                                    width: auto;
                                                    text-align: center;
                                                    mso-padding-alt: 0;
                                                    mso-border-alt: 10px solid
                                                      #e69138;
                                                  "
                                                  >Book a demo</span
                                                >
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #ffffff;
                          max-width: 600px;
                          width: 100%;
                        "
                        bgcolor="#ffffff"
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              bgcolor="#3d85c6"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 20px;
                                padding-left: 40px;
                                padding-right: 40px;
                                background-color: #3d85c6;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="padding: 0; margin: 0"
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 24px;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                              >
                                                Here to help,
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="es-m-p20r es-m-p20l"
                              align="left"
                              bgcolor="#3d85c6"
                              style="
                                padding: 0;
                                margin: 0;
                                padding-top: 30px;
                                padding-left: 40px;
                                padding-right: 40px;
                                background-color: #3d85c6;
                                border-radius: 0px 0px 0px 20px;
                                background-image: url(https://tlr.stripocdn.email/content/guids/CABINET_32d55458cc42a293bf7e1691e2c3cf9c/images/frame_367_bYc.png);
                                background-repeat: no-repeat;
                                background-position: center bottom;
                              "
                              background="https://tlr.stripocdn.email/content/guids/CABINET_32d55458cc42a293bf7e1691e2c3cf9c/images/frame_367_bYc.png"
                            >
                              <!--[if mso]><table dir="ltr" cellpadding="0" cellspacing="0"><tr><td><table dir="rtl" style="max-width:520px" cellpadding="0" cellspacing="0"><tr><td dir="ltr" style="width:340px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: right;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      class="es-m-p20b"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 340px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="es-m-txt-l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                              "
                                            >
                                              <h3
                                                style="
                                                  margin: 0;
                                                  line-height: 24px;
                                                  mso-line-height-rule: exactly;
                                                  font-family: Poppins,
                                                    sans-serif;
                                                  font-size: 20px;
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  color: #ffffff;
                                                "
                                              >
                                                Jada Nelson<strong
                                                ></strong>|&nbsp;HR
                                              </h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="50%"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 20px;
                                                        padding-bottom: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_3b670d78779801705eef224a1b9fbd70/images/95711614763048218.png"
                                                          alt="+1 345 789 90"
                                                          title="+1 345 789 90"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                          "
                                                        />+1 345 789 90</span
                                                      >
                                                    </td>
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="50%"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 20px;
                                                        padding-bottom: 10px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_3b670d78779801705eef224a1b9fbd70/images/69541614947093393.png"
                                                          alt="+1 345 789 90"
                                                          title="+1 345 789 90"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                          "
                                                        />+1 345 789 90</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style="padding: 0; margin: 0">
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr class="links-images-left">
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="50%"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 5px;
                                                        padding-bottom: 20px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_3b670d78779801705eef224a1b9fbd70/images/58641614773761370.png"
                                                          alt="Lorem ipsum"
                                                          title="Lorem ipsum"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                            font-size: 12px;
                                                          "
                                                        />Lorem ipsum</span
                                                      >
                                                    </td>
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="50%"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-top: 5px;
                                                        padding-bottom: 20px;
                                                        border: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: none;
                                                          display: block;
                                                          font-family: arial,
                                                            'helvetica neue',
                                                            helvetica,
                                                            sans-serif;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/guids/CABINET_3b670d78779801705eef224a1b9fbd70/images/60191614948456055.png"
                                                          alt="123 Lorem ipsum"
                                                          title="123 Lorem ipsum"
                                                          align="absmiddle"
                                                          width="20"
                                                          style="
                                                            display: inline-block !important;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                            padding-right: 5px;
                                                            vertical-align: middle;
                                                          "
                                                        />123 Lorem ipsum</span
                                                      >
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 5px;
                                                padding-bottom: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 20px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Facebook"
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-white/facebook-logo-white.png"
                                                          alt="Fb"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 20px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Slack"
                                                          src="https://tlr.stripocdn.email/content/assets/img/messenger-icons/logo-white/slack-logo-white.png"
                                                          alt="Slack"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 20px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #ffffff;
                                                          font-size: 16px;
                                                        "
                                                        ><img
                                                          title="Telegram"
                                                          src="https://tlr.stripocdn.email/content/assets/img/messenger-icons/logo-white/telegram-logo-white.png"
                                                          alt="Telegram"
                                                          width="24"
                                                          height="24"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <img
                                                        title="Skype"
                                                        src="https://tlr.stripocdn.email/content/assets/img/messenger-icons/logo-white/skype-logo-white.png"
                                                        alt="Skype"
                                                        width="24"
                                                        height="24"
                                                        style="
                                                          display: block;
                                                          border: 0;
                                                          outline: none;
                                                          text-decoration: none;
                                                          -ms-interpolation-mode: bicubic;
                                                        "
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td dir="ltr" style="width:20px"></td><td dir="ltr" style="width:160px" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                  float: left;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 160px;
                                        width; 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        bgcolor="#0b5394"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: separate;
                                          border-spacing: 0px;
                                          background-color: #0b5394;
                                          border-radius: 30px 30px 0 0;
                                        "
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="es-m-p10l"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0px;
                                              "
                                            >
                                              <span
                                                style="
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: underline;
                                                  color: #ffffff;
                                                  font-size: 16px;
                                                "
                                                ><img
                                                  src="https://tlr.stripocdn.email/content/guids/CABINET_32d55458cc42a293bf7e1691e2c3cf9c/images/pexelsfauxels3184405removebgpreview_1.png"
                                                  alt="Jada Nelson"
                                                  style="
                                                    display: block;
                                                    border: 0;
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    max-width: 160px;
                                                    width: 100%;
                                                  "
                                                  title="Jada Nelson"
                                              /></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-footer"
                align="center"
                role="none"
                style="
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse;
                  border-spacing: 0px;
                  table-layout: fixed !important;
                  width: 100%;
                  background-color: transparent;
                  background-repeat: repeat;
                  background-position: center top;
                "
              >
                <tbody>
                  <tr>
                    <td align="center" style="padding: 0; margin: 0">
                      <table
                        class="es-footer-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          border-spacing: 0px;
                          background-color: #3d85c6;
                          max-width: 600px;
                          width: 100%;
                        "
                        bgcolor="#3d85c6"
                        role="none"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="es-m-p30t es-m-p30b es-m-p20r es-m-p20l"
                              align="left"
                              bgcolor="#ffffff"
                              style="
                                padding: 40px;
                                margin: 0;
                                background-color: #ffffff;
                                border-radius: 0px 20px 0px 0px;
                              "
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                role="none"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        padding: 0;
                                        margin: 0;
                                        max-width: 520px;
                                        width: 100%;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        role="presentation"
                                        style="
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        "
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 30px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #999999;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-gray/facebook-logo-gray.png"
                                                          alt="Fb"
                                                          title="Facebook"
                                                          width="32"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 30px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #999999;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-gray/x-logo-gray.png"
                                                          alt="Х"
                                                          title="Х"
                                                          width="32"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        padding-right: 30px;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #999999;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-gray/instagram-logo-gray.png"
                                                          alt="Ig"
                                                          title="Instagram"
                                                          width="32"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                      "
                                                    >
                                                      <span
                                                        style="
                                                          -webkit-text-size-adjust: none;
                                                          -ms-text-size-adjust: none;
                                                          mso-line-height-rule: exactly;
                                                          text-decoration: underline;
                                                          color: #999999;
                                                          font-size: 14px;
                                                        "
                                                        ><img
                                                          src="https://tlr.stripocdn.email/content/assets/img/social-icons/logo-gray/youtube-logo-gray.png"
                                                          alt="Yt"
                                                          title="Youtube"
                                                          width="32"
                                                          style="
                                                            display: block;
                                                            border: 0;
                                                            outline: none;
                                                            text-decoration: none;
                                                            -ms-interpolation-mode: bicubic;
                                                          "
                                                      /></span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="
                                                padding: 0;
                                                margin: 0;
                                                padding-top: 20px;
                                                padding-bottom: 20px;
                                                font-size: 0;
                                              "
                                            >
                                              <table
                                                border="0"
                                                width="45%"
                                                height="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                  border-collapse: collapse;
                                                  border-spacing: 0px;
                                                "
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="
                                                        padding: 0;
                                                        margin: 0;
                                                        border-bottom: 1px solid
                                                          #cccccc;
                                                        background: unset;
                                                        height: 1px;
                                                        width: 100%;
                                                        margin: 0px;
                                                      "
                                                    ></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              style="padding: 0; margin: 0"
                                            >
                                              <p
                                                style="
                                                  margin: 0;
                                                  -webkit-text-size-adjust: none;
                                                  -ms-text-size-adjust: none;
                                                  mso-line-height-rule: exactly;
                                                  font-family: arial,
                                                    'helvetica neue', helvetica,
                                                    sans-serif;
                                                  line-height: 21px;
                                                  color: #999999;
                                                  font-size: 14px;
                                                "
                                              >
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #999999;
                                                    font-size: 14px;
                                                  "
                                                  >View Web&nbsp;Version</span
                                                >&nbsp;•&nbsp;<span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #999999;
                                                    font-size: 14px;
                                                  "
                                                  >Email Preferences</span
                                                >&nbsp;•
                                                <span
                                                  style="
                                                    -webkit-text-size-adjust: none;
                                                    -ms-text-size-adjust: none;
                                                    mso-line-height-rule: exactly;
                                                    text-decoration: none;
                                                    color: #999999;
                                                    font-size: 14px;
                                                  "
                                                  >Unsubscribe</span
                                                >
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</html>
`;

const Webinar = () => {
  return <ParseHTML html={string} />;
};

export default Webinar;
