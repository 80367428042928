import styles from "./PromptModal.module.scss";
import saufterImg from "src/assets/images/saufter.png";
import spark from "src/assets/images/sparkler.png";
import forwardArrow from "src/assets/images/forward.png";
const PromptModal = () => {
  return (
    <div>
      <div className="d-flex align-items-center mb-4 pb-2">
        <img
          src={saufterImg}
          alt="saufter"
          width={24}
        />
        <span className={`ms-2 ${styles.modalHead}`}>Prompts</span>
      </div>
      <div className="mb-4">
        <h4 className={`mb-0 ${styles.templateUrl}`}>
          Template URL <span>https://click.ly/tools/fake-link-generator</span>
        </h4>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`${styles.leftSection}`}>
          <span>How can I assist you today with your tasks?</span>
        </div>
        <div>
          <img
            src={forwardArrow}
            alt="forward"
          />
        </div>
        <div className={`${styles.rightSection}`}>
          <img
            src={spark}
            alt="spark"
            width={17}
          />
          <span className="ps-1">What would you like help with today?</span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`${styles.leftSection}`}>
          <span>Upload your data, and I'll help you find key insights.</span>
        </div>
        <div>
          <img
            src={forwardArrow}
            alt="forward"
          />
        </div>
        <div className={`${styles.rightSection}`}>
          <img
            src={spark}
            alt="spark"
            width={17}
          />
          <span className="ps-1">
            Send over your data, and I'll assist in identifying key trends.
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`${styles.leftSection}`}>
          <span>What issue are you facing today? I’m here to help!</span>
        </div>
        <div>
          <img
            src={forwardArrow}
            alt="forward"
          />
        </div>
        <div className={`${styles.rightSection}`}>
          <img
            src={spark}
            alt="spark"
            width={17}
          />
          <span className="ps-1">
            How can I help you resolve any issues today?
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`${styles.leftSection}`}>
          <span>Want to schedule an event or set a reminder? Just ask!</span>
        </div>
        <div>
          <img
            src={forwardArrow}
            alt="forward"
          />
        </div>
        <div className={`${styles.rightSection}`}>
          <img
            src={spark}
            alt="spark"
            width={17}
          />
          <span className="ps-1">
            Need to schedule an event or set a reminder? Just let me know!
          </span>
        </div>
      </div>
    </div>
  );
};

export default PromptModal;
