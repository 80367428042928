import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { AJAXSTATUS } from "src/globals/constants";
import { IChannelList } from "src/services/InnerTicket/getChannelList";
import { TicketStatus } from "src/services/TicketService/getAllStatuses";
import innerTicketExtraReducers from "./innerTicket.extraReducers";
import innerTicketReducers from "./innerTicket.reducers";
import {
  areAllMergeTicketsSelected,
  getTicketDescriptionMessageObject,
  isCurrentTicketMergedWithDifferent,
  isMergeTicketIdSelected,
  showDateMessageId,
  totalMergeTicketsSelected,
} from "./innerTicket.selectors";
import innerTicketThunk from "./innerTicket.thunk";
import { ChangeCustomerProps } from "src/services/SendMessageService";
import { Message } from "src/services/LiveChat/messageExchange/getMessages";
import { MessagesData } from "src/services/LiveChat/liveChatHistory/getLiveChatCustomerAllMessages.service";
import { IntegrationFailureMetaData } from "src/types/IntegrationFailure";
import { EChannel } from "src/enums/EChannel";

export const fetchInnerTickets = createAsyncThunk(
  "innerTicket/fetchInnerTickets",
  innerTicketThunk.fetchTicketsThunk,
);
export const fetchAllInnerTickets = createAsyncThunk(
  "innerTicket/fetchAllInnerTickets",
  innerTicketThunk.fetchAllTicketsThunk,
);
export const fetchInnerTicketMessages = createAsyncThunk(
  "innerTicket/fetchInnerTicketMessages",
  innerTicketThunk.fetchMessagesThunk,
);

export const sendMessages = createAsyncThunk(
  "innerTicket/sendMessages",
  innerTicketThunk.sendMessageThunk,
);

export const editDraftMsg = createAsyncThunk(
  "innerTicket/editDraftMsg",
  innerTicketThunk.editDraftMsgThunk,
);

export const fetchMessageByIds = createAsyncThunk(
  "innerTicket/fetchMessageByIds",
  innerTicketThunk.fetchMessageByIdsThunk,
);

// for merging ticket
export const mergeTickets = createAsyncThunk(
  "innerTicket/mergeTickets",
  innerTicketThunk.mergeTicketThunk,
);

export const fetchAllMergableTickets = createAsyncThunk(
  "innerTicket/fetchAllMergableTickets",
  innerTicketThunk.fetchMergableTicketsThunk,
);

export const fetchMessagesUpdates = createAsyncThunk(
  "innerTicket/fetchMessagesUpdates",
  innerTicketThunk.fetchMessagesUpdatesThunk,
);

export const fetchIsChannelIntegrated = createAsyncThunk(
  "innerTicket/fetchIsChannelIntegrated",
  innerTicketThunk.fetchIsChannelIntegratedThunk,
);

// to fetch all ticket id's
export const fetchAllTicketsIdList = createAsyncThunk(
  "innerTicket/fetchAllTicketsIdList",
  innerTicketThunk.fetchAllTicketsIdListThunk,
);
export const changeTheCustomer = createAsyncThunk(
  "innerTicket/changeTheCustomer",
  innerTicketThunk.changeTheCustomerThunk,
);

export const searchAllCustomers = createAsyncThunk(
  "innerTicket/searchAllCustomers",
  innerTicketThunk.searchCustomersThunk,
);

export const fetchSnooze = createAsyncThunk(
  "innerTicket/fetchSnooze",
  innerTicketThunk.getSnoozeThunk,
);

export const fetchInnerTicketUpdates = createAsyncThunk(
  "innerTicket/fetchInnerTicketUpdates",
  innerTicketThunk.fetchInnerTicketUpdatesThunk,
);

export interface InnerTicketFilterType {
  customerId: number;
  ticketStatus: number;
  tableType: string;
  currentView: string;
  searchText: string;
  ticketId: number;
}

export interface IMessageData {
  messageId: number | string;
  ticketId: number;
  messageDateTime: string;
  // messageDateTimeGmt: string;
  isDraftMessage: boolean;
  draftRemarkCount?: number | null;
  draftStatusData?: {
    draftStatusId: number;
    draftStatusName: string;
  };
  subject: string;
  channelId: number | string;
  message: string;
  to: string;
  toName?: string;
  from: string;
  cc: string;
  bcc: string;
  sentBy: string;
  messageType: string; // "Message" | "Event" | "chat" | "MessageBot" | "DraftMessageBot"
  senderName: string;
  senderImgURL: null | string;
  isPublicAttachmentUrl?: boolean;
  attachments?: Array<any>;
  chatMeta?: MessagesData;
  messageUuid?: string;
  messageStatus?: AJAXSTATUS; //message sent status, only used on frontend to identify if message is sent (try again feature)
  tryAgainPayload?: any;
  /**
   * Used to Render feedback UI in Email Bot Messages
   */
  customerFeedback?: {
    feedback: "resolved" | "connectToHuman" | null;
    feedbackDateTime: string;
  };
  /**
   * Used to Render Follow Up Bot Email or Feedback UI
   */
  isFollowUpBotMail?: boolean;
  /**
   * Used to Render Bot Information
   */
  botInfo?: {
    id: string | number;
    name: string;
    brandName: string;
    sourceURL?: string;
  };
  messageInfo?: {
    id: number | string;
    displayName: string;
    icon: string;
    color: string;
    dateTimeGMT: null | string;
  };
  integrationFailure?: IntegrationFailureMetaData; // For integration failure
  /**
   * Used to show or hide Answer Source button in Ai draft message
   */
  answerSource?: {
    type?: "customQA" | "url";
    url?: string | null;
    questions?: string[];
    botProfileId?: string;
  };
  eventMetaData?: {
    ruleId: string;
    ruleName: string;
  };
  eventSubType?: EventSubType;
  cernThreadData?: {
    totalReplies: number;
    lastReplyDateGmt: string;
  };
  escalateDetails?: {
    isEscalateMsgExist?: boolean;
    escalateMsg?: string;
    escalateMsgStatus?: "pending" | "paused" | "cancelled" | "completed";
  };
  campaignDetails?: {
    campaignId: number;
    campaignName: string;
    emailTemplateId: number;
    stepName: string;
    sentAt: string;
  };
}

export interface IMessageMetaData {
  presistChangeCustomer?: boolean;
  replyOptions?: Array<EChannel>;
  noteOptions?: EChannel[];
}
export interface IActiveTicketInfo {
  ticket_subject?: any;
  ticket_channel?: any;
  ticket_customer_name?: any;
  not_replied_since?: any;
  ticket_id?: any;
  ticket_assigned_to?: any;
  last_message_date?: any;
  ticket_created_date?: any;
  ticket_created_by?: any;
  ticket_status?: any;
  customer_id?: any;
  ticket_to?: any;
  ticket_brand?: any;
  received_email_by?: any;
  received_email_from?: any;
  ticket_from?: any;
  ticket_bcc?: any;
  ticket_cc?: any;
  ticket_description?: any;
  ticket_tags?: any;
  can_add_new_draft?: any;
  transferredTicketUrl?: string;
  merged_tickets?: Array<number>; // added for merge tickets
  merged_parent_tickets?: Array<number>;
  ticket_attachments?: Array<any>;
  message_thread?: {
    content_ids: string;
    attachments: any;
    threadText: string;
  };
  forward_message_thread?: {
    content_ids: string;
    attachments: any;
    threadText: string;
  };
  ticket_schedule_message_count?: number; // added for snooze
  last_mention_message?: string;
  mentioned_by?: { id: string; name: string; email: string; imgUrl?: string };
  unread_mention_count?: null | number;
  unread_mention_message_id?: null | string;
  // Used in case of facebook integration failure
  failureMetadata?: IntegrationFailureMetaData;
  ticket_customer_details?: {
    id: string;
    name: string;
    email: string;
    imgURL?: string | null;
    isPublicAttachmentUrl?: boolean;
  };
}

export interface ICustomerData {
  name: string;
  email: string;
}

export interface ISearchCustomerData {
  id: string | number;
  name: string;
  email: string;
}

export interface IGlobalSearch {
  searchText: string;
  tableType: string;
}
type EventSubType =
  | 213 //Change Status applied
  | 214 //Assign agent applied
  | 215 //Add Tags applied
  | 227
  | 230 // For campaign stage event
  | 233 // For campaign unsubscribe event
  | 234 // For campaign opened event
  | 235; // For camapign clicked event

export interface ITicketMerge {
  allSelectedTicketIds: Array<number | string>;
  mergeTicketAjaxStatus: "pending" | "fulfilled" | "rejected"; // not using
  mergableTicketFetchAjaxStatus: "pending" | "fulfilled" | "rejected";
  ticketData: { [ticketId: number | string]: any };
  ticketList: Array<number>;
  ticketListTotal: number | null;
  ticketDataLimit: number;
}

export type InnerTicketType = {
  ticketData: { [ticketId: number | string]: any };
  ticketList: Array<number>;
  ticketListFlag: number;
  ticketListTotal: number | null;
  ticketDataLimit: number;
  active: { index: number; ticketId: number | string };
  activeTicketInfo: IActiveTicketInfo;
  filters: InnerTicketFilterType;
  ticketDataAjaxStatus: "pending" | "fulfilled" | "rejected";
  ticketStatusList: { [key: number | string]: TicketStatus };
  messageData: {
    [messageId: number | string]: IMessageData;
  };
  messageList: Array<number | string>;
  messageMetaData: IMessageMetaData;
  hasMoreMessage: boolean;
  messageDataLimit: number;
  showOtherTicketMsg: boolean;
  showOtherTicketNotes: boolean;
  showCernThread: boolean;
  refetchCernThread: boolean;
  sendCernMsgId?: number | null;
  cernErrorMessageId?: number;
  isCernTyping: {
    status: boolean;
    parentMessageId: null | number;
  };
  messageDataAjaxStatus: "pending" | "fulfilled" | "rejected";
  permissionStatus?: { status: string; message?: string };
  sendMessageAjaxStatus: "idle" | "pending" | "fulfilled" | "rejected";
  editDraftMsgStatus: AJAXSTATUS;
  isChannelIntegratedStatus: AJAXSTATUS;
  isSlackIntegrated: boolean;
  channelList: IChannelList;
  customerData: ICustomerData;
  globalSearch: IGlobalSearch;
  ticketMerge: ITicketMerge;
  allTicketsNav: {
    allTicketsIdList: Array<number>;
    allTicketsIdAjaxStatus: AJAXSTATUS;
    hasPrev: boolean;
    prevTicketId: number | null;
    hasNext: boolean;
    nextTicketId: number | null;
  };

  searchCustomersData: {
    customersData: {
      [customerId: number | string]: ISearchCustomerData;
    };
    customersIdList: Array<number>;
    searchTerm: string;
    fetchCustomersDataLimit: number;
    searchCustomersAjaxStatus: AJAXSTATUS;
    metaData: {
      count: number;
      totalCount: number | null;
    };
  };

  activeSnooze: {
    snoozeData: {
      id: number;
      ticketId: number;
      tillCustomerReplies: number;
      expireAt: string;
      expireAtInString: string;
      snoozedAt: string;
      expireAtGmt: string;
    };
    snoozeDataAjaxStatus: AJAXSTATUS;
  };
  lastGlobalSearchUrl: {
    ticketRoute: string;
    searchUrlIsApplied: boolean;
    searchRoute: string;
  };
  lastActiveTableType?: string | null;
  copiedAiDraft?: string | undefined; // Used to store the copied ai draft message
  aiDraftMsgId?: string | undefined; // Used to trigger useeffect when ai draft message is copied
  integrationFailure: IntegrationFailureMetaData | null;
};

export const initialState: InnerTicketType = {
  ticketData: {},
  ticketList: [],
  ticketListFlag: 0,
  ticketListTotal: null,
  ticketDataLimit: 50,
  active: {
    index: 0,
    ticketId: 0,
  },
  activeTicketInfo: {},
  filters: {
    customerId: 0,
    ticketStatus: 0,
    tableType: "all",
    searchText: "",
    currentView: "",
    ticketId: 0,
  },
  ticketDataAjaxStatus: "pending",
  ticketStatusList: {},
  messageData: {},
  messageList: [],
  messageMetaData: {
    presistChangeCustomer: true,
    replyOptions: [EChannel.email],
  },
  hasMoreMessage: true,
  messageDataLimit: 25,
  showOtherTicketMsg: false,
  showOtherTicketNotes: false,
  showCernThread: false,
  refetchCernThread: false,
  isCernTyping: {
    status: false,
    parentMessageId: null,
  },
  messageDataAjaxStatus: "pending",
  sendMessageAjaxStatus: "idle",
  editDraftMsgStatus: "idle",
  isChannelIntegratedStatus: "idle",
  isSlackIntegrated: false,
  channelList: {},
  customerData: {
    name: "",
    email: "",
  },
  globalSearch: {
    searchText: "",
    tableType: "all",
  },
  lastGlobalSearchUrl: {
    searchRoute: "",
    searchUrlIsApplied: false,
    ticketRoute: "",
  },
  lastActiveTableType: null,
  ticketMerge: {
    allSelectedTicketIds: [],
    mergeTicketAjaxStatus: "pending", // not using
    mergableTicketFetchAjaxStatus: "pending",
    ticketData: {},
    ticketList: [],
    ticketListTotal: null,
    ticketDataLimit: 10,
  },
  allTicketsNav: {
    allTicketsIdList: [],
    allTicketsIdAjaxStatus: "pending",
    hasPrev: false,
    prevTicketId: null,
    hasNext: false,
    nextTicketId: null,
  },

  searchCustomersData: {
    customersData: {},
    customersIdList: [],
    searchTerm: "",
    fetchCustomersDataLimit: 25,
    searchCustomersAjaxStatus: "pending",
    metaData: {
      count: 0,
      totalCount: null,
    },
  },

  activeSnooze: {
    snoozeData: {
      id: 0,
      ticketId: 0,
      tillCustomerReplies: 0,
      expireAt: "",
      expireAtInString: "",
      snoozedAt: "",
      expireAtGmt: "",
    },
    snoozeDataAjaxStatus: "idle",
  },
  integrationFailure: null,
};

export const innerTicketSlice = createSlice({
  name: "innerTicket",
  initialState,
  reducers: innerTicketReducers,
  extraReducers: (builder) => {
    builder.addCase(
      fetchInnerTickets.fulfilled,
      innerTicketExtraReducers.fetchInnerTicketsFulfilled,
    );
    builder.addCase(fetchInnerTickets.rejected, (state) => {
      state.ticketDataAjaxStatus = "rejected";
    });
    builder.addCase(fetchInnerTickets.pending, (state) => {
      state.ticketDataAjaxStatus = "pending";
    });
    builder.addCase(
      fetchAllInnerTickets.fulfilled,
      innerTicketExtraReducers.fetchAllInnerTicketsFulfilled,
    );
    builder.addCase(fetchAllInnerTickets.rejected, (state) => {
      state.ticketDataAjaxStatus = "rejected";
    });
    builder.addCase(fetchAllInnerTickets.pending, (state) => {
      state.ticketDataAjaxStatus = "pending";
    });
    builder.addCase(
      fetchInnerTicketMessages.fulfilled,
      innerTicketExtraReducers.fetchInnerTicketMessagesFulfilled,
    );
    builder.addCase(
      fetchInnerTicketMessages.rejected,
      (state, { payload, error }) => {
        if (payload) {
          state.messageDataAjaxStatus = "rejected";
          // Update status and message in store
          const status: string = (payload as any).status;
          const message: string = (payload as any).statusMessage;
          state.permissionStatus = {
            status,
            message,
          };
        }
        // state.messageDataAjaxStatus = "rejected";
      },
    );
    builder.addCase(fetchInnerTicketMessages.pending, (state) => {
      state.messageDataAjaxStatus = "pending";
    });
    builder.addCase(
      sendMessages.fulfilled,
      innerTicketExtraReducers.sendMessagesFulfilled,
    );
    builder.addCase(sendMessages.rejected, (state, action) => {
      //  failed to send message, show error toast
      state.sendMessageAjaxStatus = "rejected";
      if (action.meta.arg.uuid) {
        if (state.messageData[action.meta.arg.uuid]) {
          state.messageData[action.meta.arg.uuid].messageStatus = "rejected";
        }
      }
      const payload = action.payload as any;

      if (payload === undefined) {
        pushTheToast({
          text: "Couldn't send message! Please reload and try again!",
          type: "danger",
          position: "top-right",
        });
      } else if (
        typeof payload === "object" &&
        payload?.statusCode === "integrationFailure"
      ) {
        state.integrationFailure = payload.failureMetaData;
        // Adding integration failure data for the message
        if (action.meta.arg.uuid) {
          state.messageData[action.meta.arg.uuid].integrationFailure =
            payload.failureMetaData;
        }
      }
    });

    builder.addCase(sendMessages.pending, (state) => {
      state.sendMessageAjaxStatus = "pending";
    });
    // cases for merge tickets, not used
    builder.addCase(mergeTickets.fulfilled, (state) => {
      // use from extra reducers
      state.ticketMerge.mergeTicketAjaxStatus = "fulfilled";
    });
    builder.addCase(mergeTickets.rejected, (state) => {
      state.ticketMerge.mergeTicketAjaxStatus = "rejected";
    });
    builder.addCase(mergeTickets.pending, (state) => {
      state.ticketMerge.mergeTicketAjaxStatus = "pending";
    });
    // cases for fetching mergable tickets
    builder.addCase(
      fetchAllMergableTickets.fulfilled,
      innerTicketExtraReducers.fetchAllMergableTicketsFulfilled,
    );
    builder.addCase(fetchAllMergableTickets.rejected, (state) => {
      state.ticketMerge.mergableTicketFetchAjaxStatus = "rejected";
    });
    builder.addCase(fetchAllMergableTickets.pending, (state) => {
      state.ticketMerge.mergableTicketFetchAjaxStatus = "pending";
    });
    //edit daraft message
    builder.addCase(
      editDraftMsg.fulfilled,
      innerTicketExtraReducers.editDraftMsgFulfilled,
    );
    builder.addCase(editDraftMsg.rejected, (state) => {
      state.editDraftMsgStatus = "rejected";
    });
    builder.addCase(editDraftMsg.pending, (state) => {
      state.editDraftMsgStatus = "pending";
    });
    builder.addCase(
      fetchMessagesUpdates.fulfilled,
      innerTicketExtraReducers.fetchMessagesUpdatesFulfilled,
    );
    builder.addCase(
      fetchAllTicketsIdList.fulfilled,
      innerTicketExtraReducers.fetchAllTicketsIdListFulfilled,
    );
    builder.addCase(fetchAllTicketsIdList.rejected, (state) => {
      state.allTicketsNav.allTicketsIdAjaxStatus = "rejected";
    });
    builder.addCase(fetchAllTicketsIdList.pending, (state) => {
      state.allTicketsNav.allTicketsIdAjaxStatus = "pending";
    });
    builder.addCase(
      fetchMessageByIds.fulfilled,
      innerTicketExtraReducers.fetchMessageByIdsFulfilled,
    );
    // cases for searching customers
    builder.addCase(
      searchAllCustomers.fulfilled,
      innerTicketExtraReducers.searchCustomersFulfilled,
    );
    builder.addCase(searchAllCustomers.rejected, (state) => {
      state.searchCustomersData.searchCustomersAjaxStatus = "rejected";
    });
    builder.addCase(searchAllCustomers.pending, (state) => {
      state.searchCustomersData.searchCustomersAjaxStatus = "pending";
    });

    builder.addCase(
      fetchIsChannelIntegrated.fulfilled,
      (state, { payload }) => {
        state.isChannelIntegratedStatus = "fulfilled";
        state.isSlackIntegrated = payload.some(
          (val) => val.channelName === EChannel.slack && val.isIntegrated,
        );
      },
    );
    builder.addCase(fetchIsChannelIntegrated.rejected, (state) => {
      state.isChannelIntegratedStatus = "rejected";
    });
    builder.addCase(fetchIsChannelIntegrated.pending, (state) => {
      state.isChannelIntegratedStatus = "pending";
    });

    builder.addCase(changeTheCustomer.pending, (state, actions) => {
      if (actions.meta.arg.customerEmail !== undefined) {
        state.activeTicketInfo.ticket_to = actions.meta.arg.customerEmail;
      }
      if (actions.meta.arg.from) {
        state.activeTicketInfo.ticket_from = actions.meta.arg.from;
      }
      if (actions.meta.arg.cc !== undefined) {
        const cc = actions.meta.arg.cc.join(",");
        if (cc) {
          state.activeTicketInfo.ticket_cc = cc;
        } else {
          state.activeTicketInfo.ticket_cc = null;
        }
      }
    });

    //cases for snooze
    builder.addCase(
      fetchSnooze.fulfilled,
      innerTicketExtraReducers.fetchSnoozeDataFulfilled,
    );
    builder.addCase(fetchSnooze.rejected, (state) => {
      state.activeSnooze.snoozeDataAjaxStatus = "rejected";
    });
    builder.addCase(fetchSnooze.pending, (state) => {
      state.activeSnooze.snoozeDataAjaxStatus = "pending";
    });

    //extra reducer for inner ticket sidebar ticket updates
    builder.addCase(
      fetchInnerTicketUpdates.fulfilled,
      innerTicketExtraReducers.fetchInnerTicketUpdatesFulfilled,
    );
  },
});

export const messageDataTicketSelector = createSelector(
  [getTicketDescriptionMessageObject],
  (messageDataTicket) => messageDataTicket,
);

export const dateMessageIdListSelector = createSelector(
  [showDateMessageId],
  (messageDataTicket) => messageDataTicket,
);

// selector to check a ticket is selected or not
export const isMergedTicketIdSelectedSelector = createSelector(
  [isMergeTicketIdSelected],
  (isTicketSelected) => isTicketSelected,
);

// selector to check all tickets are selected or not
export const areAllMergeTicketsSelectedSelector = createSelector(
  [areAllMergeTicketsSelected],
  (isAllTicketSelected) => isAllTicketSelected,
);

export const totalMergeTicketsSelectedSelector = createSelector(
  [totalMergeTicketsSelected],
  (totalTicketsSelected) => totalTicketsSelected,
);

export const isCurrentTicketMergedWithDifferentSelector = createSelector(
  [isCurrentTicketMergedWithDifferent],
  (isCurrentTicketMergedWithDifferent) => isCurrentTicketMergedWithDifferent,
);

export const {
  resetInnerTicket,
  setInnerTicketFilters,
  resetInnerTicketData,
  resetInnerTicketMessage,
  setInnerTicketActive,
  setInnerTicketUserID,
  setActiveTicketInfoFromTicketData,
  setSearchTextTicketSb,
  setInnerTicketActiveTags,
  setGlobalData,
  selectMergeTicketById,
  removeSelectedMergeTicketById,
  selectMergeAllTicketsById,
  removeAllSelectedMergeTickets,
  resetTicketMerge,
  setInnerTicketStatus,
  setTicketSubject,
  resetAllTicketsNav,
  setHasNextTicket,
  setHasPrevTicket,
  // for customers search in inner ticket
  resetSearchAllCustomers,
  setSearchAllCustomersSearchText,
  resetActiveMessageThread,
  resetSnoozeData,
  setActiveCustomerName,
  updateSideBarTicketsCustomerName,
  setSnoozeData,
  removeTicketFromSidebar,
  filterInnerTicketsByActiveFilter,
  setSidebarTicketStatus,
  setActiveScheduleMessageCount,
  setLastSearchURL,
  setLastActiveTableType,
  addMessageForTryAgain,
  updateTryAgainMessageStatus,
  setShowOtherTicketMessages,
  setShowCernThread,
  setRefetchCernThread,
  setSendCernMsgId,
  setCernMessageError,
  setCernRepliesCount,
  setIsCernTyping,
  addMessage,
  updateMessageInfo,
  clearIntegrationFailure, // For integration failure for try again
  updateCustomerFeedback,
  setCopiedAiDraft,
} = innerTicketSlice.actions;

export default innerTicketSlice.reducer;
