import { GetConfigModalParams } from "src/features/ReturnAutoWorkFlow/children/ConfigModals/oldReturnModals.types";
import { axiosJSON } from "src/globals/axiosEndPoints";

export interface FormElementOption {
  elementId: number;
  elementLabel: string;
  elementType: string;
  optionsRequired?: boolean;
}

async function getReturnReasonOrderAndItemsFormElementOptions(
  params: GetConfigModalParams,
) {
  const { data: res } = await axiosJSON.get(
    "/api/setting/automation/refund/step/12/getFormElementOptions",
    { params },
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as FormElementOption[];
}

export default getReturnReasonOrderAndItemsFormElementOptions;
