import { useMemo, useState } from "react";
import styles from "./WidgetPreviewSection.module.scss";
import OrderSection from "./Children/OrderSection/OrderSection";
import WidgetPreview from "./Children/Widgetpreview/WidgetPreview";
import SampleOrder from "./Children/SampleOrder/SampleOrder";
import AskPreview from "./Children/Widgetpreview/Children/AskPreview/AskPreview";
import eyeOpen from "src/assets/images/EyeOpen.svg";
import eyeClose from "src/assets/images/EyeClose.svg";
import arrowUp from "src/assets/images/UpArrow.svg";
import arrowDown from "src/assets/images/DownArrow.svg";
import { useReturnPreviewState } from "../../hooks/useReturnPreview";

export const WidgetPreviewSection = () => {
  const [showSelectedOrder, setShowSelectedOrder] = useState(true);

  const {
    selectedOrder,
    showPreview,
    setShowPreview,
    updateSelectedOrder,
    showAntiRefundPreview,
    setShowAntiRefundPreview,
  } = useReturnPreviewState();

  useMemo(() => {
    if (showPreview) {
      updateSelectedOrder();
    }
  }, [showPreview]);

  return (
    <div
      className={`${styles.previewSection} ${
        showPreview && styles.active
      } mt-2`}
    >
      <div className="">
        <div className="text-center">
          <button
            className={`${styles.previewBtn} mx-auto`}
            onClick={() => setShowPreview(!showPreview)}
            id="previewBtn"
          >
            <span>
              {showPreview ? (
                <img
                  src={eyeClose}
                  alt="eye"
                  width={13}
                  height={13}
                />
              ) : (
                <img
                  src={eyeOpen}
                  alt="eye"
                  width={15}
                  height={11}
                />
              )}
            </span>
            <span className="px-2">
              {showPreview ? "Hide" : "Show "} Customer's Preview
            </span>
            {showPreview ? (
              <img
                src={arrowDown}
                alt="arrow"
              />
            ) : (
              <img
                src={arrowUp}
                alt="arrow"
              />
            )}
          </button>
        </div>
        {showPreview && (
          <div>
            {showAntiRefundPreview && selectedOrder !== null ? (
              <div className="d-flex mt-2 justify-content-center">
                <AskPreview />
              </div>
            ) : (
              <div className={`d-flex mt-2`}>
                <div className="d-flex">
                  <div className={`${styles.contentWrapper}`}>
                    {selectedOrder != null && showSelectedOrder ? (
                      <OrderSection
                        setShowSelectedOrder={setShowSelectedOrder}
                      />
                    ) : (
                      <SampleOrder
                        setShowSelectedOrder={setShowSelectedOrder}
                      />
                    )}
                  </div>
                  <div className="mx-auto">
                    <WidgetPreview />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
