import styles from "./CernThread.module.scss";
import closeBtn from "src/assets/images/close(3).png";
import InternalNote from "./Children/InternalNote/InternalNote";
import EditEscalation from "./Children/EditEscalation/EditEscalation";
import AvailableTask from "./Children/AvailableTask/AvailableTask";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { Editor as TinyMceEditor } from "tinymce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import sendImg from "src/assets/images/sendIcon.svg";
import ViewTemplateButtonV2 from "src/components/ViewTemplateButtonV2";
import AddNoteActiveIcon from "src/assets/images/note-add-active.png";
import saufter from "src/assets/images/saufter.png";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  setIsCernTyping,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useParams } from "react-router-dom";
import useFetchCernThread from "./usefetchCernThread";
import saufterLogo from "src/assets/images/saufter.gif";
import { useMutation } from "@tanstack/react-query";
import {
  ISendMessageService,
  sendMessageService,
} from "src/services/SendMessageService";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import MentionBox from "../SendMessage/children/MentionBox/MentionBox";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { useSyncedState } from "src/hooks/useSyncedState";
import { Editor } from "tinymce";
import { sendCernThreadMessage } from "src/services/Campaign/sendCernThreadMessage.service";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import PromptMessage from "./Children/PromptMessage/PromptMessage";
import InstructionMessage from "./Children/PromptMessage/InstructionMessage/InstructionMessage";
import MessageRenderer from "./Children/MessageRenderer/MessageRenderer";
import MessageEditor from "./Children/MessageEditor/MessageEditor";

const CernThread = ({
  messageId,
  ticket_from,
  isCampaign,
  ticketId,
  handleClose,
}: {
  messageId?: number | null;
  ticket_from?: string;
  isCampaign?: boolean;
  ticketId?: string;
  handleClose?: () => void;
}) => {
  const [err, setErr] = useState(false);
  // const [message, setMessage] = useState("");
  const [message, messageRef, setMessage] = useSyncedState("");
  const [showingWhichModal, setShowingWhichModal] = useState<string>(
    "" as "template" | "escalateDraft",
  );
  const { messageData: messageList, isCernTyping } = useAppSelector(
    (state) => state.innerTicket,
  );
  const dispatch = useAppDispatch();

  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const scrollToBottomMsg = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef(0);
  const [addNewMsg, setAddNewMsg] = useState(false); // to check new message is added or not. If added then scroll div to bottom

  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { cernThread, isLoading, setCernThread, hasNextPage, changePage } =
    useFetchCernThread(messageId, isCampaign);
  const [editorObj, setEditorObj] = useState<Editor>();

  const { fetchAllMentions, data } = useCustomMentions(ticket_from);

  const customAutoCompleteOptions = useMemo(() => {
    return {
      delimiters: {
        "@": {
          fetchOptions: async () => {
            const data = await fetchAllMentions();
            return data?.filter(
              (mention) => mention.mentionCategoryDetails.key !== "slack",
            );
          },
          renderComponent: (props: any) => (
            <MentionBox
              {...props}
              showCernInstruction={true}
            />
          ),
        },
      },
    };
  }, []);

  useEffect(() => {
    if (editorObj && data && !messageId && !isCampaign) {
      if (customAutoCompleteOptions && customAutoCompleteOptions?.delimiters) {
        editorObj.fire("OpenCustomMentionPlugin", {
          enabled: true,
          autoCompletedData: customAutoCompleteOptions,
        });
      }
    }
  }, [editorObj, data, customAutoCompleteOptions]);

  useEffect(() => {
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - prevScrollHeightRef.current;
    }
    if (addNewMsg && scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      setAddNewMsg(false);
    }
  }, [cernThread]);

  const { mutate, isLoading: sendMsgLoading } = useMutation({
    mutationFn: sendMessageService,
    onSuccess: (data) => {
      setCernThread((prev) => {
        return {
          ...prev,
          [data.messageId]: data,
        };
      });
      setSelectedFiles([]); // clear the attachments selected
      setCurrentAttachmentBatchNumber("");
      setAddNewMsg(true);
    },
    onError: () => {
      dispatch(setIsCernTyping({ isCernTyping: false }));
    },
  });

  const sendMessageHandler = useCallback(() => {
    if (ticketId) {
      if (selectedFiles.length > 0 || message.length > 0) {
        const messageUuid = uuid();
        const payload: ISendMessageService & { attachments?: Array<any> } = {
          ticketId,
          message: message,
          messageType: "NoteBotAiQuery",
          sendAs: "Open",
          parentMessageId: messageId ?? undefined,
          attachmentBatchNumber: selectedFiles
            ? selectedFiles.map(function (value: any, index: any) {
                return value.batchNumber;
              })
            : [],
          uuid: messageUuid,
          channel: "Email",
        };
        dispatch(setIsCernTyping({ isCernTyping: true }));
        setMessage("");
        mutate(payload);
      } else {
        pushTheToast({
          text: "Please add text or attachment!",
          type: "warning",
          position: "top-right",
        });
      }
    }
  }, [ticketId, selectedFiles, message, messageId, isCampaign, mutate]);

  const fetchNextPageHandler = useCallback(() => {
    changePage();
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      prevScrollHeightRef.current = scrollableDiv.scrollHeight;
    }
  }, [cernThread]);

  return (
    <div
      className={`${styles.cernThread} p-3 ${
        isCampaign ? styles.campaignWrapper : ""
      }`}
    >
      <div className={`d-flex justify-content-between align-items-center mb-3`}>
        <p className={`mb-0 d-flex align-items-center ${styles.heading}`}>
          {isCampaign && (
            <>
              {" "}
              <span
                className={`${styles.rightArrow}`}
                onClick={() => handleClose && handleClose()}
              >
                <i className="fa-solid fa-angle-right"></i>
              </span>
              <img
                src={saufter}
                alt="saufter"
                width={24}
                className="mx-2"
              />
            </>
          )}

          <span>Cern's thread</span>
        </p>
        {!isCampaign && (
          <img
            src={closeBtn}
            alt="close button"
            className={`${styles.closeBtn} cursor-pointer`}
            onClick={() => dispatch(setShowCernThread({ show: false }))}
          />
        )}
      </div>
      <div
        className={`${styles.msgContainer} ${
          isCampaign ? styles.campaignContainer : ""
        } d-flex flex-column`}
      >
        <MessageRenderer
          cernThread={cernThread}
          messageId={messageId}
          messageList={messageList}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPageHandler={fetchNextPageHandler}
          isCampaign={isCampaign}
          scrollToBottomMsg={scrollToBottomMsg}
          setMessage={setMessage}
          styles={styles}
        />
        <MessageEditor
          sendMessageHandler={sendMessageHandler}
          message={message}
          setMessage={setMessage}
          attachmentUploadData={attachmentUploadData}
          setAttachmentUploadData={setAttachmentUploadData}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentAttachmentBatchNumber={currentAttachmentBatchNumber}
          setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          err={err}
          setErr={setErr}
          fetchAllMentions={fetchAllMentions}
          sendMsgLoading={sendMsgLoading}
          styles={styles}
        />
      </div>
    </div>
  );
};

export default CernThread;
