import { useCallback, useMemo, useRef, useState } from "react";
import styles from "../ConditionValue.module.scss";
import TinyMCEContainer from "src/components/TinyMCEContainer";
import { isHTMLEmpty, trimPDIVBR } from "src/utils/utils";
import { v4 as uuid } from "uuid";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  error?: string;
}
const StringTinymcInput = ({
  value: initialValue,
  onChange,
  placeholder,
  error = "",
}: Props) => {
  const ref = useRef(null as any);
  const [value, setValue] = useState(initialValue);

  /**
   * Change handler for setting values
   */
  const onChangeHandler = (val: string) => {
    setValue(val);
    onChange(val);
  };

  const isError = useMemo(
    () => error && isHTMLEmpty(trimPDIVBR(value?.trim())),
    [error, value],
  );

  return (
    <div className={``}>
      <div className={``}>
        <TinyMCEContainer
          className={`border ${isError ? "border-danger shadow-none" : ""}`}
          value={value}
          onChange={onChangeHandler}
          // Only used to remove the left margin from toolbar
          showCustomFilePicker={true}
          uniqueID={uuid()}
          // Hide all controls that are outside of editor
          hideAllControls={true}
          options={{
            placeholder,
          }}
          toolbar="emoticons bold italic underline link numlist bullist"
          getMentionRef={(data) => {
            if (ref) {
              ref.current = data;
            }
          }}
        />
      </div>
      {isError ? <div className={`${styles.errText} ms-2`}>{error}</div> : ""}
    </div>
  );
};

export default StringTinymcInput;
