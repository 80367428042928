import {
  useUploadNewCsvModalContext,
  UploadStepsType,
} from "../Hooks/useUploadNewCsvModalContext";
import styles from "./UploadNewCsvHeader.module.scss";

const Step = ({
  stepNumber,
  label,
  isActive,
  isPrevious,
  showSeperator,
  isCompleted,
}: {
  stepNumber: number;
  label: string;
  isActive: boolean;
  showSeperator: boolean;
  isPrevious: boolean;
  isCompleted: boolean;
}) => {
  return (
    <div className="d-flex align-items-center me-4">
      <div
        className={`${styles.step} ${
          isCompleted || isActive || isPrevious ? styles.doneStep : ""
        } ${isPrevious && !isCompleted ? styles.skipped : ""}`}
      >
        {isCompleted ? (
          <i className={`${styles.icon} fa-solid fa-check`}></i>
        ) : (
          <span>{stepNumber}</span>
        )}
      </div>
      <span
        className={`ps-1 ${styles.stepText} ${
          isCompleted || isActive ? styles.doneTask : ""
        }`}
      >
        {label}
      </span>
      {showSeperator && (
        <div
          className={`ms-4 ${styles.horizontalLine} ${
            isCompleted || isPrevious ? styles.doneLine : ""
          } ${isPrevious && !isCompleted ? styles.skipped : ""}`}
        ></div>
      )}
    </div>
  );
};

const UploadNewCsvHeader = ({
  steps,
  activeStep,
}: {
  steps: Array<UploadStepsType>;
  activeStep: UploadStepsType;
}) => {
  const { completedSteps } = useUploadNewCsvModalContext();

  return (
    <div className="d-flex align-items-center mt-2 ms-5">
      {steps.map((step, idx) => {
        // if (idx === steps.length - 1 && step.key !== state?.activeStep) {
        //   return null;
        // }

        return (
          <Step
            key={step.key}
            stepNumber={idx + 1}
            label={step.name}
            showSeperator={idx !== steps.length - 1}
            isPrevious={idx < activeStep.index}
            isActive={activeStep.index === idx}
            isCompleted={completedSteps.includes(step.key)}
          />
        );
      })}
    </div>
  );
};

export default UploadNewCsvHeader;
