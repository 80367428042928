import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "src/components/InfiniteScroll";
import Loader from "src/components/Loader";
import { useAppSelector } from "src/store/store";
import { useCreateAutomation } from "../Automation/Hooks/useAutomationManager";
import BrandModal from "../BotBox/BrandModal/BrandModal";
import styles from "./AutomationList.module.scss";
import AutomationListItem from "./Children/AutomationListItem/AutomationListItem";
import NoAutomation from "./Children/NoAutomation/NoAutomation";
import useAutomationList from "./useAutomationList";
import settingIcon from "src/assets/images/botSetting.png";
import TicketRouteBox from "./Children/TicketRouteBox/TicketRouteBox";
import DeleteRuleModal from "./Children/TicketRouteBox/DeleteRuleModal/DeleteRuleModal";
import StepsSlider from "../Automation/TicketRoute/Children/Components/StepsSlider/StepsSlider";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { updatePriorityService } from "src/services/Automation/updatePriority.service";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
export enum EAutomationType {
  BUG_MANAGEMENT = "bugManagement",
  ISSUE_DISPATCH = "issueDispatch",
  TICKET_ROUTING = "ticketRouting",
  // SUBSCRIPTION_CANCELLATION = "subscriptionCancellation",
  RETURN = "return",
  // ORDER_MODIFICATION = "orderModification",
  // TRACK_ORDER = "trackOrder",
  // WARRANTY_ISSUES = "warrantyIssues",
}

/**
 * Gives the Automation Heading Data
 */
const getAutomationHeadingData = (type: EAutomationType) => {
  switch (type) {
    case EAutomationType.BUG_MANAGEMENT:
      return {
        heading: "Bug management",
        subHeading: " Gets bugs report from your users",
      };
    case EAutomationType.ISSUE_DISPATCH:
      return {
        heading: "Issue Dispatch",
        subHeading: "Get issue report from your users",
      };
    case EAutomationType.TICKET_ROUTING:
      return {
        heading: "Ticket Routing",
        subHeading: "",
      };
    // case EAutomationType.SUBSCRIPTION_CANCELLATION:
    //   return "Subscription Cancellation";
    // case EAutomationType.RETURN:
    //   return "Return/Refund Automation";
    // case EAutomationType.ORDER_MODIFICATION:
    //   return "Order Modification";
    // case EAutomationType.TRACK_ORDER:
    //   return "Track Order";
    // case EAutomationType.WARRANTY_ISSUES:
    //   return "Warranty Issues";
    default:
      return {
        heading: "Automation",
        subHeading: "Setup Automation Data",
      };
  }
};

export type IAutomationListParams = {
  type: EAutomationType;
};

export type IAutomationParams = {
  type: EAutomationType;
  integrationId: string;
};

/**
 * Automations List
 *
 * @returns UI for Bot Profile type configuration UI
 */
const AutomationListUI = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    type,
    automationIds,
    automations,
    fetchNextPage,
    onDelete,
    hasError,
    hasNextPage,
    isLoading,
  } = useAutomationList();
  const { addNewAutomation, isLoading: isCreating } = useCreateAutomation();

  const handleAddAutomation = useCallback(
    (brandId: string | number) => addNewAutomation({ brandId }),
    [addNewAutomation],
  );

  const { heading, subHeading } = useMemo(() => {
    return getAutomationHeadingData(type ?? EAutomationType.BUG_MANAGEMENT);
  }, [type]);

  return (
    <div className={`w-100 ${styles.contentWrapper}`}>
      <div className="d-flex justify-content-between">
        <div>
          <h4 className={`mb-1 ${styles.botHeading}`}>{heading}</h4>
          <span className={`pb-3 pt-2 pt-md-0 d-block ${styles.subHeading}`}>
            {subHeading}
          </span>
        </div>
        <div>
          <button
            className={`ms-1 ms-md-0 me-md-2 px-3 d-flex align-items-center justify-content-center ${styles.editBtn} `}
            onClick={() => setShowModal(true)}
          >
            Add new automation
          </button>
        </div>
      </div>

      {isLoading && automationIds.length === 0 ? (
        <Loader />
      ) : (
        <InfiniteScroll
          loadMore={fetchNextPage}
          hasMore={hasNextPage}
          initialDataLoaded={true}
          className={`${styles.infiniteScroll}`}
        >
          {hasError ? (
            <NoAutomation setShow={setShowModal} />
          ) : (
            automationIds.map((automationId) => {
              const automationData = automations[automationId];

              // Add additional UI for automationListItem here based on type of automation
              return (
                <AutomationListItem
                  key={automationId}
                  item={automationData}
                  onDelete={onDelete}
                />
              );
            })
          )}
        </InfiniteScroll>
      )}

      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
      >
        <BrandModal
          onHide={() => setShowModal(false)}
          handleNextClick={handleAddAutomation}
          isCreating={isCreating}
          type={type}
        />
      </Modal>
    </div>
  );
};
const TicketRoute = () => {
  const {
    type,
    automationIds,
    automations,
    fetchNextPage,
    onDelete,
    onRuleToggle,
    onPriorityUpdate,
    refetchAutomations,
    hasError,
    hasNextPage,
    isLoading,
  } = useAutomationList();

  const { heading, subHeading } = useMemo(() => {
    return getAutomationHeadingData(type ?? EAutomationType.BUG_MANAGEMENT);
  }, [type]);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeIntegrationId, setActiveIntegrationId] = useState<string>("-1");
  const handleOnDragEnd = async (result: any) => {
    const { destination, source, draggableId } = result;
    // First update in state
    onPriorityUpdate({
      integrationId: draggableId,
      destinationIndex: destination?.index as number,
    });
    try {
      await updatePriorityService({
        type: type ?? "",
        id: draggableId,
        priority: destination?.index + 1,
      }).finally(() => {
        refetchAutomations();
      });
    } catch (err) {
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: typeof err === "string" ? err : "Something went wrong!",
      });
    }
  };
  return (
    <div className={`w-100 ${styles.ticketWrapper}`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div className="ps-2">
            <h4 className={`${styles.botHeading}`}>{heading}</h4>
            <span className={`pb-3 pt-2 pt-md-0 d-block ${styles.subHeading}`}>
              {subHeading}
            </span>
          </div>
        </div>
        <div>
          <button
            className={`ms-1 ms-md-0 me-md-2 px-3 d-flex align-items-center justify-content-center ${styles.editBtn}`}
            onClick={() => setShowModal(true)}
          >
            Create new rule automation
          </button>
          <Modal
            show={showModal}
            centered
            dialogClassName={`${styles.modalDialogSlider}`}
            contentClassName={`${styles.modalContentSlider}`}
            onHide={() => setShowModal(false)}
            enforceFocus={false}
          >
            <StepsSlider onHide={() => setShowModal(false)} />
          </Modal>
        </div>
      </div>
      {automationIds && automationIds.length ? (
        <div className="d-flex align-items-center mb-3">
          <img
            src={settingIcon}
            alt="setting"
            width={12}
          />
          <span className={`ps-1 ${styles.setText}`}>
            Executing first matching rule
          </span>
        </div>
      ) : (
        ""
      )}
      <div className={`${styles.ticketcontentWrapper}`}>
        <div>
          <div>
            {isLoading && automationIds.length === 0 ? (
              <Loader />
            ) : (
              <InfiniteScroll
                loadMore={fetchNextPage}
                hasMore={hasNextPage}
                initialDataLoaded
                className={`${styles.infiniteScroll}`}
              >
                {hasError ? (
                  <NoAutomation setShow={setShowModal} />
                ) : (
                  <div>
                    <DragDropContext
                      onDragEnd={(result) => handleOnDragEnd(result)}
                    >
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {automationIds.map((automationId, idx) => {
                              const automationData = automations[automationId];
                              return (
                                <Draggable
                                  key={automationData?.integrationId}
                                  draggableId={
                                    automationData?.integrationId
                                      ? automationData?.integrationId.toString()
                                      : ""
                                  }
                                  index={idx}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="d-flex align-items-center mb-2"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="w-100">
                                        <TicketRouteBox
                                          title={automationData.automationName}
                                          description={
                                            automationData.automationDescription ??
                                            ""
                                          }
                                          onEditClick={() => {
                                            navigate(
                                              `/automations/ticketRouting/${automationData.integrationId}`,
                                            );
                                          }}
                                          onRuleToggle={onRuleToggle}
                                          onDeleteClick={() => {
                                            setShowDeleteModal(true);
                                            setActiveIntegrationId(
                                              automationData.integrationId,
                                            );
                                          }}
                                          trigger={
                                            automationData?.trigger ?? ""
                                          }
                                          integrationId={
                                            automationData.integrationId ?? ""
                                          }
                                          brand={automationData?.brand ?? null}
                                          published={
                                            automationData?.published ?? false
                                          }
                                          configured={
                                            automationData?.configured ?? false
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </InfiniteScroll>
            )}
          </div>
          {/* Modal for delete rule */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            contentClassName={`${styles.ruleModalContent}`}
            dialogClassName={`${styles.ruleModalDialog}`}
            backdropClassName={`${styles.backDropClass}`}
            id="deleteRule"
            centered
          >
            <DeleteRuleModal
              onHide={() => setShowDeleteModal(false)}
              onDelete={(integrationId: string) => {
                onDelete(integrationId);
                setShowDeleteModal(false);
              }}
              integrationId={activeIntegrationId}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const AutomationsList = () => {
  const { type } = useParams<IAutomationListParams>();

  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  return (
    <div>
      {/* Bug Management List Type of Automation UI */}
      {type === EAutomationType.BUG_MANAGEMENT &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes("bug_management") && (
          <AutomationListUI />
        )}

      {type === EAutomationType.ISSUE_DISPATCH &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes("issue_dispatch") && (
          <AutomationListUI />
        )}
      {type === EAutomationType.TICKET_ROUTING &&
        currentUserData?.ui_visibility &&
        currentUserData?.ui_visibility?.includes(
          "automation_ticket_routing",
        ) && <TicketRoute />}
    </div>
  );
};

export default AutomationsList;
