import { useCallback, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import SearchBar from "src/components/SearchBar/SearchBar";
import useFetchCampaignTemplates from "src/routes/Campaigns/Children/hooks/useFetchCampaignTemplates";
import {
  GetAllEmailTemplateResponse,
  ITemplateCategory,
} from "src/services/Campaign/getAllEmailTemplate.service";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";
import styles from "../../ChooseATemplate.module.scss";
import VariantDetails from "../VariantDetails/VariantDetails";

const CategoryDD = ({
  children,
  initialState = false,
  category,
}: {
  children: React.ReactNode;
  initialState?: boolean;
  category: ITemplateCategory;
}) => {
  const [showDD, setShowDD] = useState(initialState);

  return (
    <div
      key={category.id}
      className={styles.categoryContainer}
    >
      {/* Category Header */}
      <div
        className={styles.categoryHeader}
        onClick={() => setShowDD((prev) => !prev)}
      >
        <h4>{category.name}</h4>
        <FaChevronDown
          className={`${styles.icon} ${showDD ? styles.iconExpanded : ""}`}
        />
      </div>

      {showDD && children}
    </div>
  );
};

const TemplateSidebar = ({
  onUseNewTemplate,
}: {
  onUseNewTemplate: () => void;
}) => {
  const { dispatch } = useAddCampaignState();

  const [activeCategory, setActiveCategory] =
    useState<ITemplateCategory | null>(null);
  const [activeTemplate, setActiveTemplate] = useState<
    ITemplateCategory["templates"][0] | null
  >(null);

  const [search, setSearch] = useState("");

  const onInitialFetch = useCallback(
    (categories: GetAllEmailTemplateResponse["allTemplateCategories"]) => {
      const category = categories[0];
      if (!category) {
        return;
      }

      const template = category.templates[0];
      if (!template) {
        return;
      }

      const variant = template.variants[0];

      if (!variant) {
        return;
      }

      dispatch("updateState", {
        templateCategoryId: category.id,
        templateId: template.id,
        variantId: variant.id,
      });
    },
    [dispatch],
  );

  const {
    allTemplateCategories,
    setSearch: updateSearch,
    fetchMore,
    hasMore,
    templatesFetchStatus,
    queryKey,
  } = useFetchCampaignTemplates(onInitialFetch);

  useEffect(() => {
    if (queryKey) {
      dispatch("updateState", { allTemplatesQueryKey: queryKey });
    }
  }, [dispatch, queryKey]);

  const onTemplateChange = useCallback(
    (categoryIdx: number, templateIdx: number) => {
      setActiveTemplate(
        allTemplateCategories[categoryIdx]?.templates[templateIdx] ?? null,
      );
      setActiveCategory(allTemplateCategories[categoryIdx] ?? null);
    },
    [allTemplateCategories],
  );

  const onSearch = useCallback(
    (value: string) => {
      updateSearch(value);
    },
    [updateSearch],
  );

  const onTemplateVariantChange = useCallback(
    (variantId: string | number) => {
      if (!activeTemplate || !activeCategory) {
        return;
      }

      dispatch("updateState", {
        templateCategoryId: activeCategory.id,
        templateId: activeTemplate.id,
        variantId,
        showErrors: false,
      });
    },
    [activeCategory, activeTemplate, dispatch],
  );

  return (
    <div className={styles.left}>
      <div className={styles.campaignStrategy}>
        <h3>Campaign strategy</h3>
        {activeTemplate && activeCategory ? (
          <VariantDetails
            templateName={activeTemplate.name}
            iconUrl={activeTemplate.iconUrl ?? ""}
            variants={activeTemplate.variants}
            categoryId={activeCategory.id}
            templateId={activeTemplate.id}
            onBack={() => {
              setActiveTemplate(null);
              setActiveCategory(null);
            }}
            onTemplateChange={onTemplateVariantChange}
          />
        ) : (
          <>
            <h5>Templates</h5>
            <SearchBar
              className={`${styles.search} px-2 mt-2 mb-3`}
              inputClassName={`${styles.input}`}
              placeholder={`Search...`}
              value={search}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setSearch(e.target.value)
              }
              onSearch={onSearch}
            />
            <div
              className={`${styles.templateBtn} cursor-pointer`}
              onClick={onUseNewTemplate}
            >
              <span>
                <i className="fa-solid fa-cloud-arrow-up"></i>
              </span>
              <span className="ps-2">Use a new template</span>
            </div>
            <div className={styles.underline}></div>

            <div className={styles.templateCard}>
              {templatesFetchStatus === "rejected" &&
              allTemplateCategories.length === 0 ? (
                <div
                  className={`${styles.noFound} d-flex justify-content-center align-content-center align-items-center pt-4 text-danger`}
                >
                  Error Loading Content...
                </div>
              ) : templatesFetchStatus === "pending" &&
                allTemplateCategories.length === 0 ? (
                <div
                  className={`d-flex justify-content-center align-content-center align-items-center ${styles.loaderWrapper}`}
                >
                  <Loader imgClassName="pt-0" />
                </div>
              ) : allTemplateCategories.length === 0 ? (
                <div
                  className={`mt-3 d-flex align-items-center justify-content-center ${styles.noFound}`}
                >
                  <span> No Templates Found</span>
                </div>
              ) : (
                <InfiniteScroll
                  loadMoreFromBottom={fetchMore}
                  hasMoreBottom={hasMore}
                  className={`${styles.sidebarScroll}`}
                  infiniteLoader={
                    <LoadMoreLoader
                      fetchMore={fetchMore}
                      variant="blue"
                      status={
                        templatesFetchStatus === "fulfilled"
                          ? "fulfilled"
                          : templatesFetchStatus === "pending"
                            ? "pending"
                            : null
                      }
                    />
                  }
                >
                  {allTemplateCategories.map((category, categoryIdx) => (
                    <div className={`${styles.dropdownHeader}`}>
                      <CategoryDD
                        category={category}
                        key={category.id}
                        initialState={categoryIdx === 0}
                      >
                        <div className={styles.categoryDropdown}>
                          {category.templates.map((template, templateIdx) => (
                            <div
                              key={template.id}
                              className={styles.templateCard}
                            >
                              <div className={styles.templateHeader}>
                                <img
                                  src={template.iconUrl ?? ""}
                                  alt={template.name}
                                  className={styles.templateImage}
                                />
                              </div>
                              <button
                                className={`mt-0 ${styles.viewVariantsButton}`}
                                onClick={() =>
                                  onTemplateChange(categoryIdx, templateIdx)
                                }
                              >
                                View variants
                              </button>
                            </div>
                          ))}
                        </div>
                      </CategoryDD>
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateSidebar;
