import React from "react";
import SearchBar from "src/components/SearchBar/SearchBar";
import styles from "./UserDetailsModal.module.scss";

const tableData = [
  {
    id: 1,
    username: "JohnDoe",
    email: "johndoe@example.com",
    customerId: "C12345",
    viewDate: "2024-01-15",
  },
  {
    id: 2,
    username: "JaneSmith",
    email: "janesmith@example.com",
    customerId: "C67890",
    viewDate: "2024-02-10",
  },
  {
    id: 3,
    username: "AliceBrown",
    email: "alicebrown@example.com",
    customerId: "C11223",
    viewDate: "2024-03-05",
  },
  {
    id: 4,
    username: "BobGreen",
    email: "bobgreen@example.com",
    customerId: "C44556",
    viewDate: "2024-04-20",
  },
  {
    id: 5,
    username: "CharlieBlue",
    email: "charlieblue@example.com",
    customerId: "C77889",
    viewDate: "2024-05-15",
  },
];

interface Props {
  id: number;
  username: string;
  email: string;
  customerId: string;
  viewDate: string;
  isOdd: boolean;
}

const TableData = ({
  id,
  username,
  email,
  customerId,
  viewDate,
  isOdd,
}: Props) => {
  return (
    <div
      className={`d-flex align-items-center mb-3 ${
        isOdd ? styles.oddRow : styles.tableData
      }`}
    >
      <div className={`${styles.col}`}>
        <span className={`${styles.link} text-decoration-underline`}>
          {username}
        </span>
      </div>
      <div className={`${styles.emailCol}`}>
        <span className={`${styles.element}`}>{email}</span>
      </div>
      <div className={`${styles.col}`}>
        <span className={`${styles.element}`}>{customerId}</span>
      </div>
      <div className={`${styles.col}`}>
        <span className={`${styles.element}`}>{viewDate}</span>
      </div>
    </div>
  );
};

interface ModalProps {
  onHide: () => void;
  modalType: "viewed" | "clicked" | "unsubscribe";
}

const UserDetailsModal = ({ onHide, modalType }: ModalProps) => {
  // Title and header based on modalType
  const getModalTitle = () => {
    switch (modalType) {
      case "clicked":
        return "Users who clicked this message";
      case "unsubscribe":
        return "Users who have unsubscribed from the email campaign";
      default:
        return "Users who viewed this message";
    }
  };

  const getDateLabel = () => {
    switch (modalType) {
      case "clicked":
        return "Clicked date";
      case "unsubscribe":
        return "Unsubscribed date";
      default:
        return "View date";
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <h4 className={`${styles.modalHead}`}>{getModalTitle()}</h4>
        <span
          className={`${styles.closeModal}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div>
        <SearchBar
          className={`mb-3 ${styles.search} px-2`}
          inputClassName={`${styles.input}`}
          placeholder="Search by user name, email, or customer ID"
        />
        <div>
          <div
            className={`d-flex align-items-center mb-3 ${styles.tableHeader}`}
          >
            <div className={`${styles.col}`}>
              <span className={`${styles.element}`}>User name</span>
            </div>
            <div className={`${styles.emailCol}`}>
              <span className={`${styles.element}`}>Email</span>
            </div>
            <div className={`${styles.col}`}>
              <span className={`${styles.element}`}>Customer ID</span>
            </div>
            <div className={`${styles.col}`}>
              <span className={`${styles.element}`}>{getDateLabel()}</span>
            </div>
          </div>
          <div className={`${styles.dataWrapper}`}>
            {tableData.map((data, index) => (
              <TableData
                key={data.id}
                id={data.id}
                username={data.username}
                email={data.email}
                customerId={data.customerId}
                viewDate={data.viewDate}
                isOdd={index % 2 === 0} // Alternate row background color
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;
