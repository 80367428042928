import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getAllUserSegmentService, {
  GetAllUserSegmentResponse,
  UserSegmentData,
} from "src/services/CustomerSegments/getAllUserSegment.service";
import { useSegmentView } from "../../../hooks/useSegmentView";

// Limit of items per page
const LIMIT = 10;

/**
 * Page data type extending GetAllUserSegmentResponse with pagination.
 */
type PageData = GetAllUserSegmentResponse & {
  page: number; // Current page number
};

/**
 * Custom hook to fetch user segments with infinite scrolling and search functionality.
 *
 * Returns an object containing:
 * - `allSegments`: A record of all segment data, keyed by segment ID.
 * - `allSegmentIds`: An array of all segment IDs.
 * - `searchText`: The current search text.
 * - `setSearchText`: A function to update the search text.
 * - `fetchMore`: A function to fetch the next page of segments.
 * - `hasMore`: A boolean indicating if there are more segments to load.
 * - `segmentsFetchStatus`: A string representing the current fetch status, mapped to AJAXSTATUS.
 */
const useFetchSegments = (
  onInitialFetch?: (id: string) => void,
  segmentType?: "user" | "default",
) => {
  // State to store the search text
  const [searchText, setSearchText] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState(false);

  // Ref to keep track of whether onInitialFetch has been called
  const hasFetchedInitial = useRef<boolean>(false);

  const { activeSegmentDetails } = useSegmentView();

  //  Infinite query hook for fetching user segments with pagination and search.
  const { data, status, fetchNextPage, hasNextPage, isFetching, refetch } =
    useInfiniteQuery<PageData>(
      // Query key includes searchText (trimmed or undefined if empty) for cache identification
      ["getAllUserSegmentService", searchText.trim() || undefined, segmentType],
      {
        queryFn: async ({ pageParam = 1 }) => {
          const res = await getAllUserSegmentService({
            limit: LIMIT,
            start: (pageParam - 1) * LIMIT,
            searchText: searchText.trim() === "" ? undefined : searchText,
            ...(segmentType !== undefined && { segmentType }),
          });

          return { ...res, page: pageParam } as PageData;
        },
        getNextPageParam: (lastPage) => {
          const { page, hasMoreSegments } = lastPage;
          return hasMoreSegments ? page + 1 : undefined;
        },
        keepPreviousData: true,
        cacheTime: 60000,
        staleTime: 60000,
      },
    );

  // Memoized computation to merge data across all pages.
  const { allSegments, allSegmentIds } = useMemo(() => {
    if (!data) {
      return { allSegments: {}, allSegmentIds: [] };
    }

    // Reduce the paginated data into a single structure containing all segment ids and data
    return data.pages.reduce(
      (acc, res) => {
        return {
          allSegmentIds: [...acc.allSegmentIds, ...res.allSegmentIds],
          allSegments: { ...acc.allSegments, ...res.allSegments },
        };
      },
      {
        allSegmentIds: [] as string[],
        allSegments: {} as Record<string, UserSegmentData>,
      },
    );
  }, [data]);

  /**
   * Function to load more segments when needed.
   * Only triggers the fetch if there are more pages to load and the query is not already loading.
   */
  const fetchMore = useCallback(() => {
    if (hasNextPage && status !== "loading" && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, status]);

  // Detect when a search is in progress and show loader
  useEffect(() => {
    if (searchText.trim()) {
      setSearchLoading(true); // Start loader when search text changes
    }
  }, [searchText]);

  useEffect(() => {
    if (!isFetching) {
      setSearchLoading(false); // Stop loader when fetch completes
    }
  }, [isFetching]);

  // useEffect to call onInitialFetch once the first set of allSegmentIds is available
  useEffect(() => {
    if (
      onInitialFetch &&
      !hasFetchedInitial.current &&
      allSegmentIds.length > 0
    ) {
      // Find the id in allsegmentIds where segmentKey matches activeSegmentId
      const segmentId = allSegmentIds.find((id) => {
        const segment = allSegments[id];
        return (
          segment && segment.segmentKey == activeSegmentDetails?.activeSegmentId
        );
      });
      onInitialFetch(segmentId ?? allSegmentIds[0]); // Call with the first segment ID
      hasFetchedInitial.current = true; // Ensure this runs only once
    }
  }, [allSegmentIds, onInitialFetch]);

  const segmentsFetchStatus: AJAXSTATUS = useMemo(() => {
    return isFetching
      ? "pending"
      : status === "success"
        ? "fulfilled"
        : status === "loading"
          ? "pending"
          : "rejected";
  }, [isFetching, status]);

  return {
    allSegments,
    allSegmentIds,
    searchText,
    setSearchText,
    fetchMore,
    hasMore: hasNextPage,
    segmentsFetchStatus,
    searchLoading,
    refetch,
  };
};

export default useFetchSegments;
