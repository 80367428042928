import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { createTemplateLiveChat } from "src/services/LiveChat/ScheduleFollowups/createTemplates";
import { updateTemplateLiveChat } from "src/services/LiveChat/ScheduleFollowups/updateTemplates";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  ExtendedTemplateContext,
  useTemplateDispatch,
  useTemplates,
} from "./TemplateContext";
import { useParams } from "react-router-dom";
import { findAndReplaceCID, trimPDIVBR } from "src/utils/utils";
import {
  convertUserTimeZoneToAnother,
  formatUTCDate,
} from "src/utils/dateLibrary";
import objectHash from "object-hash";
// import {updateTemplateInnerTicket} from "src/services/InnerTicket/ScheduleFollowups/updateTemplates";
// import {createTemplateInnerTicket} from "src/services/InnerTicket/ScheduleFollowups/createTemplates";
import { fetchCustomerDataByChatId } from "src/store/slices/liveChatSetting/chatWidExchCust/chatWidExchCust.thunks";
import {
  fetchMessagesUpdates,
  setActiveScheduleMessageCount,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { setActiveChatScheduledMessageCount } from "src/store/slices/liveChatSetting/chatWidExchMsg/chatWidExchMsg.slice";
import { removeCustomerByChatIdCache } from "src/services/LiveChat/messageExchange/getCustomerByChatId";

type Props = {
  selectedTemplateId: string | null;
  onHide?: () => void;
  scheduleMessageFor: "innerTicket" | "liveChat" | "userSegment";
  setSelectedTemplateId: (value: string | null) => void;
  ticketId?: number | string;
  scheduleWith?: string | null;
};

function useMainSection({
  selectedTemplateId,
  setSelectedTemplateId,
  scheduleMessageFor,
  ticketId,
  onHide,
  scheduleWith,
}: Props) {
  const [buttonText, setButtonText] = useState("");
  const [bigButtonText, setBigButtonText] = useState("");
  const [scheduleMessageFlag, setScheduleMessageFlag] = useState(false);
  const [scheduleMessageOnlyFlag, setScheduleMessageOnlyFlag] = useState(false);
  const [onlyScheduleBtnText, setOnlyScheduleBtnText] = useState(
    "Schedule Message(s)",
  );
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const template = useTemplates();

  const dispatch = useAppDispatch();

  const { chatId } = useParams();

  const templateDispatch = useTemplateDispatch();

  const selectedTemplateStep = template[selectedTemplateId ?? ""];

  const { updateTemplateSteps } = useContext(ExtendedTemplateContext);

  const updateTemplates = useMutation({
    mutationFn: updateTemplateLiveChat,
    onSuccess: (data, variables, context) => {
      if (data?.err) {
        pushTheToast({
          type: "danger",
          text: "Failed to update",
          position: "top-right",
        });
        return;
      }
      updateTemplateSteps(variables.templateId + "", data.data.templateSteps);

      if (variables.scheduleMessage === true) {
        if (scheduleMessageFor === "innerTicket") {
          dispatch(fetchMessagesUpdates());
          if (data.metaData && data.metaData.scheduledMessagesCount) {
            dispatch(
              setActiveScheduleMessageCount({
                count: data.metaData?.scheduledMessagesCount,
              }),
            );
          }
        } else if (scheduleMessageFor === "liveChat") {
          removeCustomerByChatIdCache(chatId + "");
          dispatch(
            fetchCustomerDataByChatId({
              payload: chatId as string,
            }),
          );
          if (data.metaData && data.metaData.scheduledMessagesCount) {
            dispatch(
              setActiveChatScheduledMessageCount({
                chatId: parseInt(variables.chatId + ""),
                count: data.metaData?.scheduledMessagesCount,
              }),
            );
          }
        }

        if (
          variables.templateSteps?.length &&
          variables.templateSteps.length !== 0
        ) {
          pushTheToast({
            type: "success",
            text: "Scheduled And Updated successfully!",
            position: "top-right",
          });
          if (onHide) {
            onHide();
          }
        } else {
          pushTheToast({
            type: "success",
            text: "Scheduled Message successfully!",
            position: "top-right",
          });
          if (onHide) {
            onHide();
          }
        }
      } else {
        pushTheToast({
          type: "success",
          text: "Updated successfully!",
          position: "top-right",
        });
      }
    },
  });

  const queryClient = useQueryClient();

  const createTemplates = useMutation({
    mutationFn: createTemplateLiveChat,
    onSuccess: (data, variables, context) => {
      if (data?.err) {
        pushTheToast({
          type: "danger",
          text: "Failed to create template",
          position: "top-right",
        });
        return;
      }

      queryClient.refetchQueries([`getAllTemplates`]);

      templateDispatch({
        type: "Update_Template_Id",
        payload: {
          templateId: "new",
          value: data.data.templateId + "",
          stepId: "",
        },
      });

      if (selectedTemplateId === "new") {
        setSelectedTemplateId(data.data.templateId + "");
      }

      // updateTemplateSteps(data.data.id+"", data.data.templateSteps);

      if (variables.scheduleMessage === true) {
        pushTheToast({
          type: "success",
          text: "Created And Scheduled Message successfully!",
          position: "top-right",
        });

        if (scheduleMessageFor === "innerTicket") {
          dispatch(fetchMessagesUpdates());
          if (data.metaData && data.metaData.scheduledMessagesCount) {
            dispatch(
              setActiveScheduleMessageCount({
                count: data.metaData?.scheduledMessagesCount,
              }),
            );
          }
        } else if (scheduleMessageFor === "liveChat") {
          removeCustomerByChatIdCache(chatId + "");
          dispatch(
            fetchCustomerDataByChatId({
              payload: chatId as string,
            }),
          );
          if (data.metaData && data.metaData.scheduledMessagesCount) {
            dispatch(
              setActiveChatScheduledMessageCount({
                chatId: parseInt(variables.chatId + ""),
                count: data.metaData?.scheduledMessagesCount,
              }),
            );
          }
        }
        if (onHide) {
          onHide();
        }
      } else {
        pushTheToast({
          type: "success",
          text: "Created successfully!",
          position: "top-right",
        });
      }
    },
  });

  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  function convertingDataTime(dateInTimezone: string) {
    const selectedTimeInUTC = convertUserTimeZoneToAnother(
      dateInTimezone,
      userTimezone,
      "UTC",
    );

    if (new Date(selectedTimeInUTC).getTime() > new Date().getTime()) {
    } else {
      pushTheToast({
        type: "warning",
        text: "Reschedule date and time should be greater than current!",
        position: "top-right",
      });

      throw new Error(
        "Reschedule date and time should be greater than current!",
      );
    }

    const dateInUserTimezone = formatUTCDate(new Date(selectedTimeInUTC));

    return dateInUserTimezone;
  }

  function createTemplateClick(scheduleMessage: boolean) {
    if (scheduleMessage) {
      setScheduleMessageFlag(true);
    } else {
      setScheduleMessageFlag(false);
    }

    let isPayloadValid = true;
    selectedTemplateStep.every((step, index) => {
      if (index === 0 && step.templateHeading?.trim() === "") {
        pushTheToast({
          type: "warning",
          text: "Template heading is required!",
          position: "top-right",
        });
        isPayloadValid = false;
        return false;
      }

      if (step.templateMessage.trim() === "") {
        pushTheToast({
          type: "warning",
          text: "Template message is required!",
          position: "top-right",
        });
        isPayloadValid = false;
        return false;
      }

      return true;
    });

    if (!isPayloadValid) {
      setShowValidationErrors(true);
      return false;
    }

    try {
      createTemplates.mutate({
        templateSteps: selectedTemplateStep.map((step, index) => {
          if (index === 0) {
            const temp = {
              templateHeading: step.templateHeading,
              templateMessage: trimPDIVBR(
                findAndReplaceCID(step.templateMessage),
              ),
              // scheduleMessageFor === "innerTicket"
              //   ? trimPDIVBR(findAndReplaceCID(step.templateMessage))
              //   : step.templateMessage,
              scheduleFollowUpType: step.scheduleFollowUpType,
              scheduleFollowUpValue:
                step.scheduleFollowUpType === "dateAndTime"
                  ? convertingDataTime(step.scheduleFollowUpValue + "")
                  : step.scheduleFollowUpValue,
              sendMessageCondition: step.sendMessageCondition,
              attachmentBatchNumber: step.attachments
                ? step.attachments?.map(
                    (attachment: any) =>
                      attachment.attachmentBatchNumber ||
                      attachment.batchNumber,
                  )
                : undefined,
            };

            if (
              temp.attachmentBatchNumber === undefined ||
              temp.attachmentBatchNumber.length === 0
            ) {
              delete temp.attachmentBatchNumber;
            }

            return temp;
          } else {
            const temp = {
              templateMessage: trimPDIVBR(
                findAndReplaceCID(step.templateMessage),
              ),
              // scheduleMessageFor === "innerTicket"
              //   ? trimPDIVBR(findAndReplaceCID(step.templateMessage))
              //   : step.templateMessage,
              scheduleFollowUpType: step.scheduleFollowUpType,
              scheduleFollowUpValue:
                step.scheduleFollowUpType === "dateAndTime"
                  ? convertingDataTime(step.scheduleFollowUpValue + "")
                  : step.scheduleFollowUpValue,
              sendMessageCondition: step.sendMessageCondition,
              attachmentBatchNumber: step.attachments
                ? step.attachments?.map(
                    (attachment: any) =>
                      attachment.attachmentBatchNumber ||
                      attachment.batchNumber,
                  )
                : undefined,
            };

            if (
              temp.attachmentBatchNumber === undefined ||
              temp.attachmentBatchNumber.length === 0
            ) {
              delete temp.attachmentBatchNumber;
            }

            return temp;
          }
        }),
        chatId: scheduleMessageFor === "innerTicket" ? undefined : chatId,
        ticketId: scheduleMessageFor === "innerTicket" ? ticketId : undefined,
        stageId: scheduleMessageFor === "userSegment" ? ticketId : undefined,
        scheduleMessage: scheduleMessage,
        templateType:
          scheduleMessageFor === "innerTicket"
            ? "inner_ticket_template"
            : "live_chat_template",
        scheduleWith: scheduleWith,
      });
    } catch (err: any) {
      console.error(err.message);
    } finally {
      setShowValidationErrors(false);
    }
  }

  function updateTemplateClick(scheduleMessage: boolean) {
    if (scheduleMessage) {
      setScheduleMessageFlag(true);
    } else {
      setScheduleMessageFlag(false);
    }

    let isPayloadValid = true;
    selectedTemplateStep.every((step, index) => {
      if (index === 0 && step.templateHeading?.trim() === "") {
        pushTheToast({
          type: "warning",
          text: "Template heading is required!",
          position: "top-right",
        });
        isPayloadValid = false;
        return false;
      }

      if (step.templateMessage.trim() === "") {
        pushTheToast({
          type: "warning",
          text: "Template message is required!",
          position: "top-right",
        });
        isPayloadValid = false;
        return false;
      }

      return true;
    });

    if (!isPayloadValid) {
      setShowValidationErrors(true);
      return false;
    }
    try {
      updateTemplates.mutate({
        templateId: selectedTemplateId,
        templateSteps: selectedTemplateStep.map((step, index) => {
          if (index === 0) {
            const temp = {
              templateHeading: step.templateHeading,
              templateMessage: trimPDIVBR(
                findAndReplaceCID(step.templateMessage),
              ),
              // scheduleMessageFor === "innerTicket"
              //   ? trimPDIVBR(findAndReplaceCID(step.templateMessage))
              //   : step.templateMessage,
              scheduleFollowUpType: step.scheduleFollowUpType,
              scheduleFollowUpValue:
                step.scheduleFollowUpType === "dateAndTime"
                  ? convertingDataTime(step.scheduleFollowUpValue + "")
                  : step.scheduleFollowUpValue,
              sendMessageCondition: step.sendMessageCondition,
              stepId: step.stepId,
              attachmentBatchNumber: step.attachments
                ? step.attachments?.map(
                    (attachment: any) =>
                      attachment.attachmentBatchNumber ||
                      attachment.batchNumber,
                  )
                : undefined,
              removedAttachmentIds: step.removedAttachmentIds ?? undefined,
            };

            if (
              temp.attachmentBatchNumber === undefined ||
              temp.attachmentBatchNumber.length === 0
            ) {
              delete temp.attachmentBatchNumber;
            }

            return temp;
          } else {
            const temp = {
              templateMessage: trimPDIVBR(
                findAndReplaceCID(step.templateMessage),
              ),
              // scheduleMessageFor === "innerTicket"
              //   ? trimPDIVBR(findAndReplaceCID(step.templateMessage))
              //   : step.templateMessage,
              scheduleFollowUpType: step.scheduleFollowUpType,
              scheduleFollowUpValue:
                step.scheduleFollowUpType === "dateAndTime"
                  ? convertingDataTime(step.scheduleFollowUpValue + "")
                  : step.scheduleFollowUpValue,
              sendMessageCondition: step.sendMessageCondition,
              stepId: step.stepId,
              attachmentBatchNumber: step.attachments
                ? step.attachments?.map(
                    (attachment: any) =>
                      attachment.attachmentBatchNumber ||
                      attachment.batchNumber,
                  )
                : undefined,
              removedAttachmentIds: step.removedAttachmentIds ?? undefined,
            };

            if (
              temp.attachmentBatchNumber === undefined ||
              temp.attachmentBatchNumber.length === 0
            ) {
              delete temp.attachmentBatchNumber;
            }
            return temp;
          }
        }),
        chatId: scheduleMessageFor === "innerTicket" ? undefined : chatId,
        ticketId: scheduleMessageFor === "innerTicket" ? ticketId : undefined,
        stageId: scheduleMessageFor === "userSegment" ? ticketId : undefined,
        scheduleMessage: scheduleMessage,
        scheduleWith: scheduleWith,
      });
    } catch (err: any) {
      console.error(err.message);
    } finally {
      setShowValidationErrors(false);
    }
  }

  function scheduleMessageOnly() {
    setScheduleMessageOnlyFlag(true);
    setScheduleMessageFlag(false);
    updateTemplates.mutate({
      templateId: selectedTemplateId,
      chatId: scheduleMessageFor === "liveChat" ? chatId : undefined,
      ticketId: scheduleMessageFor === "innerTicket" ? ticketId : undefined,
      stageId: scheduleMessageFor === "userSegment" ? ticketId : undefined,
      scheduleMessage: true,
      scheduleWith: scheduleWith,
    });
  }

  // useEffect(() => {
  //   if (
  //     updateTemplates.isSuccess &&
  //     (scheduleMessageOnlyFlag || scheduleMessageFlag)
  //   ) {
  //     pushTheToast({
  //       type: "success",
  //       text: "Scheduled Message successfully!",
  //       position: "top-right",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateTemplates.isSuccess]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCreateTemplate = useCallback(createTemplateClick, [
    chatId,
    createTemplates,
    selectedTemplateStep,
    scheduleWith,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedUpdateTemplate = useCallback(updateTemplateClick, [
    chatId,
    selectedTemplateId,
    selectedTemplateStep,
    updateTemplates,
    scheduleWith,
  ]);

  useEffect(() => {
    if (
      (updateTemplates.isLoading || createTemplates.isLoading) &&
      !scheduleMessageFlag &&
      !scheduleMessageOnlyFlag
    )
      setButtonText("Updating Template");
    else if (
      (updateTemplates.isSuccess || createTemplates.isSuccess) &&
      !scheduleMessageFlag &&
      !scheduleMessageOnlyFlag
    ) {
      setButtonText("Updated");
    } else if (selectedTemplateId !== "new") {
      setButtonText("Update Template Only");
      setBigButtonText("Schedule Message(s) & Update Template");
    } else {
      setBigButtonText("Schedule Message(s) & Create Template");
      setButtonText("Create Template Only");
    }

    if (
      (updateTemplates.isLoading || createTemplates.isLoading) &&
      scheduleMessageFlag &&
      !scheduleMessageOnlyFlag
    ) {
      setBigButtonText("Scheduling Message...");
    } else if (
      (updateTemplates.isSuccess || createTemplates.isSuccess) &&
      scheduleMessageFlag &&
      !scheduleMessageOnlyFlag
    ) {
      if (selectedTemplateId !== "new") {
        setBigButtonText("Schedule Message(s) & Update Template");
      } else {
        setBigButtonText("Schedule Message(s) & Create Template");
      }
    }

    if (
      (updateTemplates.isLoading || createTemplates.isLoading) &&
      scheduleMessageOnlyFlag
    ) {
      setOnlyScheduleBtnText("Scheduling...");
    } else if (
      (updateTemplates.isSuccess || createTemplates.isSuccess) &&
      scheduleMessageOnlyFlag
    ) {
      setOnlyScheduleBtnText("Schedule Message(s)");
    }
  }, [
    selectedTemplateId,
    updateTemplates,
    createTemplates,
    scheduleMessageFlag,
    scheduleMessageOnlyFlag,
  ]);

  function resetApiState() {
    updateTemplates.reset();
    createTemplates.reset();
  }

  const [stepHash, setStepHash] = useState<string>(
    objectHash(template, {
      excludeKeys: function (key) {
        let excludeKey = ["attachments"];
        if (excludeKey.includes(key)) {
          return true;
        }
        return false;
      },
    }),
  );

  useEffect(() => {
    if (
      stepHash !==
      objectHash(template, {
        excludeKeys: function (key) {
          let excludeKey = ["attachments"];
          if (excludeKey.includes(key)) {
            return true;
          }
          return false;
        },
      })
    ) {
      resetApiState();
      setStepHash(
        objectHash(template, {
          excludeKeys: function (key) {
            let excludeKey = ["attachments"];
            if (excludeKey.includes(key)) {
              return true;
            }
            return false;
          },
        }),
      );
      // setButtonText("Update Template Only");
      // setBigButtonText("Schedule Message(s) & Update Template");
      // setOnlyScheduleBtnText("Schedule Message(s)");
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId, template, selectedTemplateStep]);

  const isUpdateLoading =
    updateTemplates.isLoading || createTemplates.isLoading;

  return {
    updateTemplates,
    createTemplates,
    userTimezone,
    buttonText,
    setButtonText,
    template,
    chatId,
    selectedTemplateStep,
    convertingDataTime,
    createTemplateClick: memoizedCreateTemplate,
    updateTemplateClick: memoizedUpdateTemplate,
    resetApiState,
    scheduleMessageOnly,
    bigButtonText,
    setBigButtonText,
    setScheduleMessageOnlyFlag,
    setScheduleMessageFlag,
    setOnlyScheduleBtnText,
    onlyScheduleBtnText,
    isUpdateLoading,
    showValidationErrors,
  };
}

export default useMainSection;
