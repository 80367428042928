import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CampaignStepData {
  campaignId: number;
  duration: number;
  channel: "email" | "livechat";
  draftDetails?: string;
  templateId: string | number;
}

const addCampaignStepService = async (params: CampaignStepData) => {
  const { data: res } = await axiosJSON.post(`/api/campaign/addStep`, params);

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }
};

export default addCampaignStepService;
