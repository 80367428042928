import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/store/store";
import { fetchGetEmailIntegrationById } from "src/store/slices/emailIntegration/emailIntegration.thunks";

import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import Loader from "src/components/Loader";
import backBtn from "src/assets/images/backArrow.png";
import EmailProcess from "../EmailProcess/EmailProcess";
import SetupComplete from "../SetupComplete/SetupComplete";

import styles from "./Complete.module.scss";
import { HELPDESK_REDIRECT_OUTBOUND } from "../DomainIntegration/children/OutgoingMailOption/Gmail/Gmail";
import { EOutboundEmailSteps } from "src/routes/Setting/children/MainSetting/children/OutboundEmailIntegration/OutboundEmailIntegration";

/**
 * Wrapper Component for Complete Step in Email Integration
 */
function Complete() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { integrationId } = useParams();

  /**
   * Effect for redirecting user and updating user data
   */
  useEffect(() => {
    const item = localStorage.getItem(HELPDESK_REDIRECT_OUTBOUND);
    if (item) {
      localStorage.removeItem(HELPDESK_REDIRECT_OUTBOUND);

      navigate(
        `/settings/integrations/outboundEmail/${EOutboundEmailSteps.Complete}${window.location.search}`,
      );

      return;
    }

    dispatch(
      fetchGetEmailIntegrationById({
        emailIntegrationId: Number(integrationId),
        callback: (status) => {
          // Callback for redirecting user
          if (status === "email_not_verified") {
            navigate(
              `/settings/integrations/email/${integrationId}/verifyEmail`,
            );
          } else if (status === "domain_not_verified") {
            navigate(
              `/settings/integrations/email/${integrationId}/verifyDomain`,
            );
          } else if (status === undefined) {
            navigate(`/settings/integrations/email/connectEmail`);
          }
        },
      }),
    );

    const searchparams = new URLSearchParams(window.location.search);
    if (searchparams.get("success") === "true") {
      pushTheToast({
        type: "success",
        text: "E-mail Setup Complete !",
        position: "top-right",
      });
      searchparams.delete("success");
      const searchString = searchparams.toString();
      window.history.pushState(
        {},
        "",
        window.location.pathname + (searchString ? "?" + searchString : ""),
      );
    }
  }, [integrationId]);

  const { emailLoading: loader, selectedUser } = useAppSelector((state) => {
    return state.emailIntegration;
  });

  return (
    <div
      className={`d-flex-column justify-content-center ${styles.contentWidth}`}
    >
      {loader === "pending" ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex">
            <div
              className={`${styles.backWidth} mt-3 ps-1 `}
              role="button"
              onClick={() => {
                navigate("/settings/integrations/email");
              }}
            >
              <img
                src={backBtn}
                className={`${styles.backBtn}`}
                alt="back"
              />
            </div>
            <div className={`${styles.connectWidth}`}>
              <EmailProcess
                currentStep={4}
                showDelete={false}
                isDomainVerified={selectedUser.isDomainVerified}
                isEmailAdded={selectedUser.isEmailAdded}
                isEmailVerified={selectedUser.isEmailVerified}
              />
            </div>
          </div>
          <div
            className={`${styles.connectWidth}`}
            style={{ marginLeft: "30px" }}
          >
            <SetupComplete
              onPrevious={() =>
                navigate(
                  `/settings/integrations/email/${integrationId}/verifyDomain`,
                )
              }
              onNext={() => navigate("/settings/integrations/email")}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Complete;
