import { useCallback, useMemo, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import {
  StepData,
  TransferToAgentType,
} from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import TransferToAgentModel from "src/routes/Setting/children/MainSetting/children/SetRulesNew/childerns/childern/AddAutomations/StepsConfigured/Childern/TransferToAgent/TransferToAgentModel";
import saveStepTrensferToAgentService from "src/services/ReturnAutoWorkFlow/saveStepTrensferToAgent.service";
import GoLiveButton from "../../../BaseSteps/children/GoLiveStep/children/GoLiveButton/GoLiveButton";
import styles from "./ConfiguredActiveStep.module.scss";
import StepTimeline from "./Children/StepTimeline/StepTimeline";

/**
 * Step component represents an individual step in a workflow with
 * optional transfer to an agent feature.
 *
 * @param stepData - The data associated with this step.
 * @param activeQIds - The active question IDs for the step.
 * @param integrationId - The integration ID for the workflow.
 * @param isStepActive - Whether this step is currently active.
 * @param stepNumber - The step number in the workflow.
 * @param updateData - Function to update the step's transfer-to-agent configuration.
 */
const Step = ({
  stepData,
  activeQIds,
  integrationId,
  isStepActive,
  stepNumber,
  updateData,
}: {
  stepData: StepData;
  activeQIds: string[];
  integrationId: string;
  isStepActive: boolean;
  stepNumber: number;
  updateData: (
    stepId: string,
    transferToAgent: Partial<TransferToAgentType>,
  ) => void;
}) => {
  /**
   * Filters question IDs to find the questions that follow the last active question.
   * Returns the remaining questions to display.
   */
  const filteredQuestionIds = useMemo(() => {
    const qIds = stepData.stepQuestionIds.map((id) => id + "");
    // Get the last active question ID.
    const lastAQId = activeQIds[activeQIds.length - 1];

    if (qIds.includes(lastAQId)) {
      // Split the questions by the last active one.
      const split = qIds.join(",").split(lastAQId);
      // Filter out the remaining questions.
      return (split[split.length - 1] ?? []).split(",").filter((v) => v);
    } else {
      return qIds;
    }
  }, [activeQIds, stepData]);

  /**
   * Determines if there are errors in the transfer-to-agent configuration.
   * Errors occur if the transfer-to-agent is enabled but lacks a confirmation message.
   */
  const transferToAgentHaveErrors = useMemo(() => {
    if (stepData.transferToAgent?.enabled) {
      // If transfer-to-agent is enabled, check if a confirmation message is provided.
      if (stepData.transferToAgent.confirmationMessage?.trim()) {
        return false;
      } else {
        // Error if no confirmation message is present.
        return true;
      }
    } else {
      // No error if transfer-to-agent is not enabled.
      return false;
    }
  }, [stepData]);

  // State to control visibility of transfer-to-agent modal.
  const [showTransferModal, setShowTransferModal] = useState<boolean>(false);

  /**
   * Handles the submission of the transfer-to-agent configuration.
   *
   * @param confirmationMessage - The confirmation message for the transfer-to-agent action.
   */
  const onSubmitTransfer = useCallback(
    async (confirmationMessage: string) => {
      try {
        // Save the transfer-to-agent configuration via the service.
        await saveStepTrensferToAgentService({
          integrationId,
          stepId: stepData.stepId,
          transferToAgent: { confirmationMessage, enabled: true },
        });
        // Update the step's transfer-to-agent configuration.
        updateData(stepData.stepId, { confirmationMessage, enabled: true });
        // Close the modal.
        setShowTransferModal(false);
      } catch (err) {
        // Show error toast if submission fails.
        pushTheToast({
          position: "top-right",
          text: (err as Error)?.message ?? "Could not transfer to agent",
          type: "danger",
        });
      }
    },
    [integrationId, stepData.stepId, updateData],
  );

  /**
   * Toggles the transfer-to-agent feature on or off.
   *
   * @param checked - Whether the transfer-to-agent feature is enabled.
   */
  const onChangeTransferAgent = useCallback(
    async (checked: boolean) => {
      try {
        // Save the updated transfer-to-agent state.
        await saveStepTrensferToAgentService({
          integrationId,
          stepId: stepData.stepId,
          transferToAgent: { enabled: checked },
        });
        // Update the step's transfer-to-agent state.
        updateData(stepData.stepId, { enabled: checked });
        // Close the modal.
        setShowTransferModal(false);
      } catch (err) {
        // Show error toast if the update fails.
        pushTheToast({
          position: "top-right",
          text: (err as Error)?.message ?? "Could not update transfer to agent",
          type: "danger",
        });
      }
    },
    [integrationId, stepData.stepId, updateData],
  );

  return (
    <div className={`${styles.steps} mb-2`}>
      <div
        className={`d-flex py-2 ${styles.stepData} ${
          stepData.transferToAgent?.enabled ? "cursor-pointer" : ""
        }`}
        onClick={() => {
          if (stepData.transferToAgent?.enabled) {
            // Show modal when transfer-to-agent is enabled and step is clicked.
            setShowTransferModal(true);
          }
        }}
      >
        <div className={`d-flex align-items-center justify-content-center`}>
          <div
            className={`${
              transferToAgentHaveErrors
                ? styles.numberRed
                : isStepActive || stepData.stepStatus === "completed"
                  ? styles.numberBlue
                  : styles.number
            } m-auto d-flex justify-content-center align-items-center`}
          >
            {transferToAgentHaveErrors === true ? (
              // Red cross for error.
              <i className="fa-solid fa-x"></i>
            ) : stepData.stepStatus === "completed" ? (
              // Check mark for completed step.
              <i className="fa-solid fa-check"></i>
            ) : (
              // Show the step number.
              `${stepNumber}`
            )}
          </div>
        </div>
        <div className={`${styles.stepTitle}`}>
          <span
            className={`${styles.stepHeading} mb-0 ${
              isStepActive || stepData.stepStatus === "completed"
                ? styles.doneSegment
                : ""
            }`}
          >
            {/* Display step title */}
            {stepData.stepTitle}
          </span>

          {stepData.transferToAgent && (
            <div className="d-flex">
              <div className={`col-md-9 ${styles.stepSubHeading}`}>
                {/* Sub-heading for transfer-to-agent */}
                Transfer to an agent after this step
              </div>
              <div className="form-check form-switch col-md-5 ms-0">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={` ${styles.toolTipCustom}`}>
                      {stepData.transferToAgent?.enabled
                        ? "Enabled"
                        : "Disabled"}
                    </Tooltip>
                  }
                  trigger="hover"
                >
                  <input
                    className={`form-check-input cursor-pointer ${styles.switchBox}`}
                    type="checkbox"
                    role="switch"
                    id={`step_transfer_${stepData.stepId}`}
                    checked={stepData.transferToAgent?.enabled}
                    onChange={(e) => {
                      // Prevent event bubbling to avoid opening the modal.
                      e.stopPropagation();
                      // Toggle the transfer-to-agent feature.
                      onChangeTransferAgent(e.target.checked);
                    }}
                  />
                </OverlayTrigger>
              </div>
              <Modal
                className={`shadow-sm rounded`}
                show={showTransferModal}
                onHide={() => setShowTransferModal(false)}
                dialogClassName={`${styles.modalDialog2}`}
                contentClassName={`${styles.modalContent2}`}
                backdropClassName={`${styles.modalBackDrop}`}
                centered={true}
              >
                <TransferToAgentModel
                  onCancel={() => setShowTransferModal(false)}
                  onSubmit={onSubmitTransfer}
                  confirmationMsg={stepData.transferToAgent.confirmationMessage}
                />
              </Modal>
            </div>
          )}
        </div>
      </div>
      {isStepActive && (
        <>
          {activeQIds.map((id, index) => {
            if (stepData.stepQuestions[id]) {
              return (
                <StepTimeline
                  key={id}
                  stepQuestion={stepData.stepQuestions[id]}
                  isActive={true}
                  isLast={
                    filteredQuestionIds.length
                      ? false
                      : index + 1 === activeQIds.length
                  }
                />
              );
            }
          })}
          {filteredQuestionIds.map((id, index) => {
            if (stepData.stepQuestions[id]) {
              return (
                <StepTimeline
                  key={id}
                  stepQuestion={stepData.stepQuestions[id]}
                  isActive={false}
                  isLast={index + 1 === filteredQuestionIds.length}
                />
              );
            }
          })}
        </>
      )}
    </div>
  );
};

/**
 * LiveStep component represents the final step in the workflow
 * which indicates that the workflow is live or ready to go live.
 *
 * @param stepNumber - The number of the final step.
 */
const LiveStep = ({ stepNumber }: { stepNumber: number }) => {
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();

  return (
    <div className={`${styles.steps}`}>
      <div className={`d-flex py-2 px-2`}>
        <div className={`col-md-2`}>
          <div
            className={`${styles.number} ${
              returnAutoWorkFlow.published === true ? styles.numberBlue : ""
            } m-auto mt-2 d-flex justify-content-center align-items-center`}
          >
            {returnAutoWorkFlow.published === true ? (
              // Check mark if workflow is live.
              <i className="fa-solid fa-check"></i>
            ) : (
              // Step number if workflow is not live.
              `${stepNumber}`
            )}
          </div>
        </div>
        <div className={`col-md-10 ps-2 ps-lg-0`}>
          <span
            className={`${
              returnAutoWorkFlow.published
                ? styles.doneSegment
                : styles.stepHeading
            }`}
          >
            {/* Message to go live */}
            {returnAutoWorkFlow.published
              ? "You're Live"
              : "You're ready to go! Go Live"}
          </span>
          <div className={`col-md-9 ${styles.stepSubHeading}`}>
            {`Returns automation flow ${
              returnAutoWorkFlow.published ? "is" : "will be"
            } live for ${returnAutoWorkFlow.brand?.name ?? "NA"}`}
          </div>
          {/* GoLive button component */}
          <GoLiveButton useModal={false} />
        </div>
      </div>
    </div>
  );
};

/**
 * ConfiguredActiveStep component is responsible for rendering the
 * list of all configured steps and handling the transfer-to-agent feature.
 */
const ConfiguredActiveStep = () => {
  const { returnAutoWorkFlow, dispatch } = useReturnAutoWorkFlow();

  /**
   * Determines if a step is active by comparing the active step ID with the provided step ID.
   *
   * @param stepId - The ID of the step to check.
   * @returns boolean - True if the step is active, otherwise false.
   */
  const stepIsActive = useCallback(
    (stepId: string) => {
      return returnAutoWorkFlow.activeStepId &&
        returnAutoWorkFlow.activeStepId + "" === stepId + ""
        ? true
        : false;
    },
    [returnAutoWorkFlow],
  );

  /**
   * Updates the transfer-to-agent configuration for a given step.
   *
   * @param stepId - The ID of the step to update.
   * @param transferToAgent - Partial transfer-to-agent configuration.
   */
  const updateData = useCallback(
    (stepId: string, transferToAgent: Partial<TransferToAgentType>) => {
      dispatch("updateTransferToAgent", { stepId, value: transferToAgent });
    },
    [dispatch],
  );

  /**
   * Memoized function to get all configured steps and check if any step has
   * the transfer-to-agent feature available.
   */
  const { allSteps, transFerToAgentAvailable } = useMemo(() => {
    const steps: (StepData & { activeQIds: string[] })[] = [];

    if (
      returnAutoWorkFlow.configuredSteps &&
      returnAutoWorkFlow.configuredStepIds?.length
    ) {
      returnAutoWorkFlow.configuredStepIds.forEach((stepId) => {
        const data = returnAutoWorkFlow.configuredSteps?.[stepId];
        const savedStep = returnAutoWorkFlow.savedStepAndQuestionIds?.find(
          (v) => v.stepId + "" === stepId + "",
        );
        if (data?.showInStepsConfigured) {
          steps.push({
            ...data,
            activeQIds: (savedStep?.questionIds ?? []).map((id) => id + ""),
          });
        }
      });
    }

    let transFerToAgentAvailable = false;

    // Check if any step has the transfer-to-agent feature enabled.
    for (const stepId in returnAutoWorkFlow.configuredSteps) {
      const step = returnAutoWorkFlow.configuredSteps[stepId];
      if (step.transferToAgent) {
        transFerToAgentAvailable = true;
        break;
      }
    }

    return { allSteps: steps, transFerToAgentAvailable };
  }, [
    returnAutoWorkFlow.configuredSteps,
    returnAutoWorkFlow.savedStepAndQuestionIds,
  ]);

  return (
    <div>
      {transFerToAgentAvailable && (
        <div>
          <p className={`mt-3 ${styles.subHeading}`}>
            Note: You can transfer any complex scenario to the agent to handle
            complex portions of steps.
          </p>
        </div>
      )}

      <div className={`mt-3`}>
        {allSteps.map(({ activeQIds, ...stepData }, idx) => {
          return (
            <Step
              stepData={stepData}
              activeQIds={activeQIds}
              key={stepData.stepId}
              isStepActive={stepIsActive(stepData.stepId)}
              stepNumber={idx + 1}
              updateData={updateData}
              integrationId={returnAutoWorkFlow.integrationId ?? ""}
            />
          );
        })}

        {returnAutoWorkFlow.configured ? (
          // Show the GoLive step if workflow is configured.
          <LiveStep stepNumber={allSteps.length + 1} />
        ) : null}
      </div>
    </div>
  );
};

export default ConfiguredActiveStep;
