import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { ActionDispatch, AJAXSTATUS } from "src/globals/constants";
import { VariablesData } from "src/services/CustomerSegments/NewMessage/getMessageTypes.service";
import {
  SegmentMessage,
  SegmentMessageType,
  SendNewMessageParams,
} from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { generateSegmentMessage } from "./sendMessage.helpers";
import useFetchMessageTypes from "./useFetchMessageTypes";
export interface Warning {
  show: boolean; // flag to show error
  type: "extensionsupport" | "sizelimit" | "empty" | null;
}
export interface SendMessageState extends SendNewMessageParams {
  selectedTypes: SegmentMessageType[];
  showErrors: boolean;
  showFileWarning: Warning;
  sendMessageStatus: AJAXSTATUS;
  allMessageTypes: SegmentMessageType[];
  messageTypesFetchStatus: AJAXSTATUS;
  variables: Record<string, VariablesData>;
  variableIds: string[];
  logoUrl: string;
  selectedTemplate: {
    categoryId: number | string,
    templateId: number | string,
  },
}

const initialState: SendMessageState = {
  messages: [],
  segmentIds: [],
  selectedTypes: [],
  showErrors: false,
  showFileWarning: {
    show: false,
    type: null,
  },
  sendMessageStatus: "idle",
  allMessageTypes: [],
  messageTypesFetchStatus: "pending",
  variableIds: [],
  variables: {},
  logoUrl: "",
  selectedTemplate: {
    categoryId: 1,
    templateId: 1,
  },
};

const reducerMethod = (
  state: SendMessageState,
  [action, payload]: [
    (value: SendMessageState, payload?: any) => SendMessageState,
    undefined | any,
  ],
): SendMessageState => {
  // Execute the action function with current state and payload
  const newState = action(state, payload);

  // If the action returns a new state, use it, otherwise spread the old state
  return newState ? newState : { ...state };
};

/**
 * Create a context for sending messages with the current state and dispatch function.
 */
const SendMessageContext = createContext<
  [SendMessageState, ActionDispatch<typeof SendMessageActions>]
>([initialState, () => {}]);

/**
 * Custom hook to provide state and dispatch function for sending messages.
 *
 * @returns An array containing the current state, a modified dispatch function, and the SendMessageContext provider.
 */
export const useCreateSendMessageProvider = () => {
  const { messageTypes, messageTypesFetchStatus, variables, variableIds } =
    useFetchMessageTypes();

  const [state, dispatch] = useReducer(reducerMethod, { ...initialState });

  // Create a modified dispatch function that wraps the original dispatch with action and payload
  const modifiedDispatch: ActionDispatch<typeof SendMessageActions> =
    useCallback(
      (action, payload) => {
        dispatch([SendMessageActions[action], payload]);
      },
      [dispatch],
    );

  useMemo(() => {
    modifiedDispatch("updateState", {
      allMessageTypes: messageTypes,
      messageTypesFetchStatus,
      variables,
      variableIds,
    });
  }, [
    messageTypes,
    messageTypesFetchStatus,
    modifiedDispatch,
    variableIds,
    variables,
  ]);

  return [state, modifiedDispatch, SendMessageContext.Provider] as [
    SendMessageState,
    ActionDispatch<typeof SendMessageActions>,
    typeof SendMessageContext.Provider,
  ];
};

/**
 * Custom hook to access the SendMessageContext.
 *
 * @throws Will throw an error if used outside of a SendMessageContext.Provider.
 * @returns The context value, which includes the current state and dispatch function.
 */
export const useSendMessage = () => {
  const [state, dispatch] = useContext(SendMessageContext);

  if (!state || !dispatch) {
    throw new Error(
      "useSendMessageContext must be used within a MessageModalContext",
    );
  }

  return { state, dispatch };
};

const SendMessageActions = {
  /**
   * Resets the state of messages, segment IDs, and selected message types to their initial values.
   *
   * @param state - The current state of SendMessageState.
   */
  resetState: () => initialState,

  updateState: (
    state: SendMessageState,
    payload: Partial<SendMessageState>,
  ) => {
    if (payload.showErrors !== undefined) {
      state.showErrors = payload.showErrors;
    }

    if (payload.sendMessageStatus !== undefined) {
      state.sendMessageStatus = payload.sendMessageStatus;
    }

    if (payload.allMessageTypes !== undefined) {
      state.allMessageTypes = payload.allMessageTypes;
    }

    if (payload.messageTypesFetchStatus !== undefined) {
      state.messageTypesFetchStatus = payload.messageTypesFetchStatus;
    }

    if (payload.variables !== undefined) {
      state.variables = payload.variables;
    }

    if (payload.variableIds !== undefined) {
      state.variableIds = payload.variableIds;
    }

    if (payload.logoUrl !== undefined) {
      state.logoUrl = payload.logoUrl;
    }

    if (payload.showFileWarning !== undefined) {
      state.showFileWarning = payload.showFileWarning;
    }
    
    if (payload.selectedTemplate !== undefined) {
      state.selectedTemplate = payload.selectedTemplate;
    }

    return { ...state };
  },

  /**
   * Updates the segment IDs based on the specified action.
   *
   * @param state - The current state of SendMessageState.
   * @param payload - An object containing the data and action type.
   *    - data: An array of segment IDs to be processed.
   *    - action: A string that can be "create", "update", or "delete".
   */
  updateSegmentId: (
    state: SendMessageState,
    payload: { data: string[]; action: "create" | "update" | "delete" },
  ) => {
    let segmentIds = [...state.segmentIds];
    const { data, action } = payload;

    switch (action) {
      case "create":
        // Replace the current segment IDs with unique values from the provided data
        segmentIds = [...new Set(data)];
        break;
      case "update":
        // Update the existing segment IDs with unique values from the provided data
        segmentIds = [...new Set([...segmentIds, ...data])];
        break;
      case "delete":
        // Remove any segment IDs present in the provided data from the current state
        segmentIds = segmentIds.filter((val) => !data.includes(val));
        break;
    }

    return { ...state, segmentIds };
  },

  /**
   * Updates the selected message type and handles the addition/removal of messages accordingly.
   *
   * @param state - The current state of SendMessageState.
   * @param payload - An object containing the message type to update and an optional flag to remove the message on deselection.
   *    - messageType: The type of message to update (either "chat" or "email").
   *    - removeOnDeselect: A boolean indicating whether to remove the message if the type is deselected (default is false).
   */
  updateMessageType: (
    state: SendMessageState,
    {
      messageType,
      removeOnDeselect = false,
    }: { messageType: SegmentMessageType; removeOnDeselect?: boolean },
  ) => {
    let selectedTypes = [...state.selectedTypes];
    let messages = [...state.messages];

    const messageExists = state.messages.some(
      (val) => val.type === messageType,
    );

    if (selectedTypes.includes(messageType)) {
      // Deselect the message type if it is already selected
      selectedTypes = selectedTypes.filter((val) => val !== messageType);

      // If the message type is deselected, you might want to delete its message.
      if (messageExists && removeOnDeselect) {
        messages = messages.filter((val) => val.type !== messageType);
      }
    } else {
      // Select the message type if it is not already selected
      selectedTypes = [...selectedTypes, messageType];

      // Generate and add the message to the state if it doesn't exist
      if (!messageExists) {
        messages = [...messages, generateSegmentMessage(messageType)];
      }
    }

    // Return a new state object
    return {
      ...state,
      selectedTypes,
      messages,
    };
  },
  updateMessage: (
    state: SendMessageState,
    {
      data,
      logoUrl,
      showErrors,
    }: {
      data: Partial<SegmentMessage> & { type: SegmentMessageType };
      logoUrl?: string;
      showErrors?: boolean;
    },
  ) => {
    // Use map to create a new messages array
    const messages = state.messages.map((message) =>
      message.type === data.type
        ? { ...message, ...(data as SegmentMessage) }
        : message,
    );

    if (logoUrl !== undefined) {
      state.logoUrl = logoUrl;
    }

    if (showErrors !== undefined) {
      state.showErrors = showErrors;
    }

    // Return a new state object with updated messages
    return { ...state, messages };
  },
} as const;
