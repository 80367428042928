import { useQuery } from "@tanstack/react-query";
import getSegmentUserByIdService from "src/services/CustomerSegments/getSegmentUserById.service";

const useSegmentUserProfile = (segmentUserId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["getSegmentUserByIdService", segmentUserId],
    queryFn: async () => {
      const res = await getSegmentUserByIdService({
        customerSegmentId: segmentUserId,
      });

      return res;
    },
    networkMode: "always",
  });

  return { data, isLoading, isError };
};

export default useSegmentUserProfile;
