import AxiosImg from "src/components/AxiosImg";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import ParseHTML from "src/components/ParseHTML";
import UserAvatar from "src/components/UserAvatar";
import { ICustomerNote } from "src/services/CustomerSegments/CustomerNote/getAllCustomerNote.service";
import { getMonthNameDateAmPmTime } from "src/utils/dateLibrary";
import styles from "./NoteModal.module.scss";

const NoteModal = ({
  allNotes,
  allNoteIds,
  onAddNoteClick,
  onHide,
  hasNextPage,
  fetchNextPage,
  isLoading,
}: {
  allNotes: Record<string, ICustomerNote>;
  allNoteIds: string[];
  onAddNoteClick: () => void;
  onHide: () => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
}) => {
  return (
    <div className="p-3">
      <div className={`d-flex justify-content-between align-items-center`}>
        <h6 className={`mb-0 ${styles.modalHead}`}>User note</h6>
        <span
          className={`cursor-pointer ${styles.closebtn}`}
          onClick={onHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <InfiniteScroll
        className={`mb-2 ${styles.noteWrapper}`}
        hasMoreBottom={hasNextPage}
        loadMoreFromBottom={fetchNextPage}
        infiniteLoader={
          <LoadMoreLoader
            fetchMore={fetchNextPage}
            variant="blue"
            status={isLoading ? "pending" : "fulfilled"}
          />
        }
      >
        {allNoteIds.map((noteId) => {
          const note = allNotes[noteId];

          if (!note) {
            return null;
          }

          return (
            <div className={`${styles.noteBox} mb-2`}>
              <div className="d-flex justify-content-between align-items-center p-2">
                <div className="d-flex align-items-center">
                  {note.agentDetails.imageURL &&
                  note.agentDetails.imageURL.trim() !== "" ? (
                    <AxiosImg
                      url={note.agentDetails.imageURL}
                      className={`rounded-circle`}
                      style={{ width: "25px", height: "26px" }}
                    />
                  ) : (
                    <UserAvatar
                      name={note.agentDetails.name ?? "NA"}
                      size={26}
                    />
                  )}
                  <span className={`ps-1 ${styles.userName}`}>
                    {note.agentDetails.name}
                  </span>
                </div>
                <div className="ps-1">
                  <span className={`${styles.dateTime}`}>
                    {getMonthNameDateAmPmTime(note.createdAtGmt, true)}
                  </span>
                </div>
              </div>
              <div className={`${styles.separator}`}></div>
              <div className="p-2">
                <p className={`mb-0 ${styles.desc}`}>
                  <ParseHTML
                    html={note.noteText}
                    className={`${styles.desc}`}
                  />
                </p>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
      <div className="d-flex justify-content-center">
        <button
          className={`px-2 ${styles.addNoteBtn}`}
          onClick={onAddNoteClick}
        >
          + Add a note
        </button>
      </div>
    </div>
  );
};

export default NoteModal;
