import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import overlayImg from "src/assets/images/dummyOverlay.png";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import StatusSelect from "src/routes/Campaigns/Children/StatusSelect/StatusSelect";
import { DripCampaignData } from "src/services/Campaign/getAllDripCampaigns.service";
import { ECampaignStatus } from "src/services/Campaign/getCampaignById";
import useCampaignsBySegment from "../../Hooks/useCampaignsBySegment";
import styles from "./DripCampaignTableData.module.scss";

const ShadowUI = () => {
  return (
    <div className={`${styles.shadowBox}`}>
      <div className={`d-flex align-items-center mb-3 ${styles.tableRow}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.rowElement}`}>Demo</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.rowElement}`}>
            <StatusSelect
              campaignId={-1}
              status={ECampaignStatus.Published}
              disabled={true}
            />
          </span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.rowElement}`}>100</span>
        </div>
      </div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableRow}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.rowElement}`}>Demo</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.rowElement}`}>
            <StatusSelect
              campaignId={-1}
              status={ECampaignStatus.Published}
              disabled={true}
            />
          </span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.rowElement}`}>100</span>
        </div>
      </div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableRow}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.rowElement}`}>Demo</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.rowElement}`}>
            <StatusSelect
              campaignId={-1}
              status={ECampaignStatus.Published}
              disabled={true}
            />
          </span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.rowElement}`}>100</span>
        </div>
      </div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableRow}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.rowElement}`}>Demo</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.rowElement}`}>
            <StatusSelect
              campaignId={-1}
              status={ECampaignStatus.Published}
              disabled={true}
            />
          </span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.rowElement}`}>100</span>
        </div>
      </div>
      <div className={`${styles.limitWrapper}`}>
        <div className={`${styles.limitBox}`}>
          <div className={`mt-1 ${styles.limitMsg}`}>
            <p className={`mb-0 ${styles.limitText}`}>
              Campaigns for any new segments will appear here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DripCampaignTableData = ({
  isShadowSegment = false,
  segmentId,
  dripCampaignData,
}: {
  isShadowSegment?: boolean;
  segmentId: string | number;
  dripCampaignData: DripCampaignData[];
}) => {
  const navigate = useNavigate();
  const { campaigns, fetchMore, fetchStatus, hasMore } = useCampaignsBySegment(
    segmentId,
    dripCampaignData.length,
    isShadowSegment || dripCampaignData.length < 10,
  );
  const { tenantId } = useParams();

  const allCampaigns = useMemo(() => {
    return [...dripCampaignData, ...campaigns];
  }, [campaigns, dripCampaignData]);

  const handleShowCampaign = useCallback(
    (id: string | number) => {
      navigate(`/campaigns${tenantId ? `/admin/${tenantId}` : ""}/${id}`);
    },
    [navigate],
  );

  return (
    <div>
      <div className={`d-flex align-items-center mb-3 ${styles.tableHeader}`}>
        <div className={`${styles.segmentName}`}>
          <span className={`${styles.element}`}>Campaign Name</span>
        </div>
        <div className={`${styles.timeline}`}>
          <span className={`${styles.element}`}>Status</span>
        </div>
        <div className={`${styles.status}`}>
          <span className={`${styles.element}`}>Emails Sent</span>
        </div>
      </div>

      {isShadowSegment ? (
        <ShadowUI />
      ) : (
        <InfiniteScroll
          className={`mb-1 ${styles.infiniteScroll}`}
          hasMoreBottom={hasMore}
          loadMoreFromBottom={fetchMore}
          infiniteLoader={
            <LoadMoreLoader
              fetchMore={fetchMore}
              variant="blue"
              status={
                fetchStatus === "fulfilled"
                  ? "fulfilled"
                  : fetchStatus === "pending"
                    ? "pending"
                    : null
              }
            />
          }
        >
          {allCampaigns.map((campaign) => {
            return (
              <div
                key={campaign.id}
                className={`d-flex align-items-center mb-3 ${styles.tableRow}`}
              >
                <div
                  className={`${styles.segmentName} cursor-pointer`}
                  onClick={() => handleShowCampaign(campaign.id)}
                >
                  <span className={`${styles.rowElement}`}>
                    {campaign.name}
                  </span>
                </div>
                <div className={`${styles.timeline}`}>
                  <span className={`${styles.rowElement}`}>
                    <StatusSelect
                      campaignId={parseInt(campaign.id + "")}
                      status={campaign.status}
                      disabled={
                        typeof tenantId === "string" &&
                        (campaign?.status as any) !== "pendingAdmin"
                      }
                      isAdmin={!!tenantId}
                    />
                  </span>
                </div>
                <div className={`${styles.status}`}>
                  <span className={`${styles.rowElement}`}>
                    {campaign.emailSendCount}
                  </span>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default DripCampaignTableData;
