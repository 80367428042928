/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";
import { ECampaignChannel } from "./createCampaignV2.service";

export interface UpdateCampaignEmailParam {
  campaignId: number;
  id: number;
  triggerAfter?: number;
  salutation?: string;
  introduction?: string;
  questionRegardingResolution?: string;
  body?: string;
  heading?: string;
  channel?: ECampaignChannel;
  logoId?: string;
  templateInnerText?: string; //just for frontend
}

export const updateCampaignEmail = async (params: UpdateCampaignEmailParam) => {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/email/update",
    params,
  );
  if (res.err) {
    throw res.msg;
  }

  return res.data;
};
