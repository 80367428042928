import { useEffect, useMemo, useState } from "react";
import Select, { ActionMeta, MenuListProps, SingleValue } from "react-select";
import timelineImg from "src/assets/images/timelineImg.png";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import { useFetchAllCampaigns } from "../hooks/useCampaignData";
import styles from "./CampaignSelect.module.scss";
import { useNavigate } from "react-router-dom";
import AddCampaign from "../Children/CampaignTabs/Children/DripCampaign/Children/AddCampaign/AddCampaign";
import { Modal } from "react-bootstrap";
// Define the type for option items
interface OptionType {
  label: string;
  value: string;
  imageUrl: string; // Add an image URL property for the icon
}
interface CustomMenuListProps<OptionType>
  extends MenuListProps<OptionType, false> {
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Dummy data for the select options with images
// const options: OptionType[] = [
//   {
//     label: "Journey type 1",
//     value: "email",
//     imageUrl: timelineImg,
//   },
// ];
const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#f5f5f5",
    minWidth: "137px",
    minHeight: "24px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    marginRight: "2px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000",
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    border: "none",
    font: "normal normal normal 11px/17px Poppins",
    boxShadow: "0px 0px 16px #00000029",
    color: "#000",
    padding: "8px 0 0 0",
    width: "200px",
    overflow: "auto",
  }),
  option: (styles: any, { data, isFocused }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    color: isFocused ? "#000" : "#000",
    backgroundColor: isFocused ? "#F0F0F0" : undefined,
    cursor: "pointer",
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    color: "#000000",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: "0",
    cursor: "pointer",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  // Add custom styles for valueContainer here
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px", // Custom padding
    display: "flex",
    alignItems: "center",
    height: "24px", // Adjust the height if needed
    fontSize: "11px", // Custom font size
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "normal", // Font weight
  }),
  menuList: (provided: any) => ({
    ...provided,
  }),
  menuPortal: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    font: "normal normal normal 11px/17px Poppins",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};
// Custom Option component to display both image and text
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        margin: "0 6px",
      }}
      className={`px-2 py-1 mb-1 ${styles.customOption}`}
    >
      <img
        src={data.imageUrl}
        alt={data.label}
        style={{ width: "29px", height: "15px", marginRight: "8px" }}
      />
      <span className={`${styles.optionLabel}`}>{data.label}</span>
    </div>
  );
};
// Custom SingleValue component to display both image and text in selected value
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {/* <img
        src={data.imageUrl}
        alt={data.label}
        style={{ width: "29px", height: "15px", marginRight: "8px" }}
      /> */}
      <span className={`${styles.selectedoptionLabel}`}>{data.label}</span>
    </div>
  );
};
const CustomIndicator = (props: any) => {
  const { selectProps } = props;
  const isOpen = selectProps.menuIsOpen;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className={`pe-2 ${styles.dropdownIndicator}`}>
        <i
          className={`fa-solid ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
        ></i>
      </span>
    </div>
  );
};

// Custom MenuList component
const CustomMenuList = (props: CustomMenuListProps<OptionType>) => {
  const { innerRef, setShowAddModal, innerProps } = props;

  const handleAddNewOption = () => {
    setShowAddModal(true); // This will trigger the modal
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
    >
      {props.children}
      <div
        className={`px-2 py-1 ${styles.addOption}`}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <span className="pe-1">
          <i className="fa-solid fa-plus"></i>
        </span>
        <span
          className={`${styles.optionLabel}`}
          onClick={handleAddNewOption}
        >
          Add new campaign
        </span>
      </div>
    </div>
  );
};

interface Props {
  setActiveCampaignId: (id: number | undefined) => void;
  activeCampaignId?: number;
}
const CampaignSelect = ({ setActiveCampaignId, activeCampaignId }: Props) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>();
  const { data, isLoading } = useFetchAllCampaigns(20);
  const [showError, setShowError] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();

  // {
  //     label: "Journey type 1",
  //     value: "email",
  //     imageUrl: timelineImg,
  //   }

  const options = useMemo(() => {
    let list = data
      ? data.pages.flatMap((page) =>
          page.map((option) => ({
            label: option.name,
            value: option.id,
            imageUrl: timelineImg,
          })),
        )
      : ([] as OptionType[]);

    return list as OptionType[];
  }, [data]);

  useEffect(() => {
    if (options && selectedOption) {
      const updatedName = options.find(
        (campaign) => campaign.value === selectedOption?.value,
      );
      setSelectedOption(updatedName);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedOption) {
      const active = options?.find(
        (option) => option.value == activeCampaignId?.toString(),
      );
      if (activeCampaignId && active) {
        setSelectedOption(active);
        setActiveCampaignId(activeCampaignId);
      } else {
        if (options[0]?.value) {
          setSelectedOption(options[0]);
          setActiveCampaignId(parseInt(options[0].value + ""));
        }
      }
    }
  }, [options, selectedOption]);

  // Handle dropdown change
  const handleDropdownChange = (
    newValue: SingleValue<OptionType> | null,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    if (!newValue) return; // If there's no value, just return
    navigate(`/campaigns/${parseInt(newValue.value)}`);
    setSelectedOption(newValue); // Set the selected option
    setActiveCampaignId(parseInt(newValue.value)); // Set the active campaign ID
  };

  return (
    <div className="mt-2">
      <Select<OptionType, false>
        value={selectedOption}
        onChange={handleDropdownChange}
        options={options}
        placeholder=""
        isSearchable={false}
        styles={customStyles}
        menuPortalTarget={document.body}
        isClearable={false} // Enables the clear indicator (X icon)
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomIndicator,
          Option: CustomOption, // Use custom Option component
          SingleValue: CustomSingleValue, // Use custom SingleValue component
          MenuList: (props) => (
            <CustomMenuList
              {...props}
              setShowError={setShowError}
              setShowAddModal={setShowAddModal}
            />
          ),
        }}
        isLoading={isLoading}
      />
      <ErrorModal
        showModal={showError}
        onHide={() => setShowError(false)}
        back={() => setShowError(false)}
        retry={() => {
          setShowLoading(true);
          setTimeout(() => {
            setShowLoading(false);
          }, 1500);
        }}
        showLoader={showLoading}
      />

      <AddCampaign
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
      />
    </div>
  );
};

export default CampaignSelect;
