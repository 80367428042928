import { ChatWidgetInstalledEvent } from "src/pusherServices/livechat/websiteSettings";
import { initialState, WebsiteSettingsData } from "./websiteSettings.slice";
import { AJAXSTATUS } from "src/globals/constants";

const resetWebsiteSettings = () => {
  return initialState;
};

const setInstalledWebInt = (
  state: WebsiteSettingsData,
  {
    payload,
  }: {
    payload: ChatWidgetInstalledEvent & { lcInstallationStatus?: AJAXSTATUS };
  },
) => {
  if (payload.integration_id === state.activeWebIntegration.integration_id) {
    // Checking if the integration_id in the payload matches the active integration_id
    if (state.activeWebIntegration.live_chat_integration_type === "shopify") {
      // If the active integration is Shopify
      state.activeWebIntegration.check_installation_success =
        payload.installed_successfully; // Update installation success status
    } else {
      // If the active integration is not Shopify
      state.activeWebIntegration.installed_successfully =
        payload.installed_successfully; // Update installation success status
    }
    if (payload?.enabled !== undefined) {
      // Checking if 'enabled' property is passed in the payload
      state.activeWebIntegration.live_chat_enabled = payload.enabled; // Update live_chat_enabled state with the value passed in payload
    }

    if (payload.lcInstallationStatus !== undefined) {
      state.lcInstallationStatus = "fulfilled";
    }
  }
};

const setIntegrationInstalledStatus = (
  state: WebsiteSettingsData,
  { payload }: { payload: Partial<ChatWidgetInstalledEvent> },
) => {
  if (payload.integration_id === state.activeWebIntegration.integration_id) {
    // Checking if the integration_id in the payload matches the active integration_id
    if (payload.installed_successfully !== undefined) {
      state.activeWebIntegration.installed_successfully =
        payload.installed_successfully;
    }
  }
};

export default {
  resetWebsiteSettings,
  setInstalledWebInt,
  setIntegrationInstalledStatus,
};
