import { useState } from "react";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { DripCampaign } from "src/services/Campaign/getAllDripCampaigns.service";
import AddCampaign from "./Children/AddCampaign/AddCampaign";
import DripCampaignTableData from "./Children/DripCampaignTableData/DripCampaignTableData";
import styles from "./DripCampaign.module.scss";
import useDripCampaign from "./Hooks/useDripCampaign";

const SegmentComponent = ({
  dripCampaign,
  showShadowSegment = false,
  defaultOpen = false,
}: {
  dripCampaign: DripCampaign;
  showShadowSegment?: boolean;
  defaultOpen?: boolean;
}) => {
  const [accordion, setAccordion] = useState(defaultOpen);
  const [addCampaign, setAddCampaign] = useState<boolean>(false);

  return (
    <div className={`${styles.accordion} mb-3`}>
      <div
        className={`d-flex justify-content-between align-items-center mb-2 cursor-pointer`}
        onClick={() => setAccordion(!accordion)}
      >
        <span className={`${styles.accordText}`}>
          <span className={`${styles.bold}`}>{dripCampaign.segmentName} </span>
          <span className={`${styles.count}`}>
            {" "}
            ({dripCampaign.campaignTotalCount} campaign)
          </span>
        </span>
        <span className={`${styles.arrow}`}>
          <i className={`fa-solid fa-chevron-${accordion ? "up" : "down"}`}></i>
        </span>
      </div>

      {accordion && (
        <div>
          <DripCampaignTableData
            dripCampaignData={dripCampaign.campaignsData}
            isShadowSegment={showShadowSegment}
            segmentId={dripCampaign.segmentId}
          />
          <div className={`d-flex align-items-center`}>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => setAddCampaign(true)}
            >
              <div className={`${styles.addIcon}`}>
                <span>
                  <i className="fa-solid fa-plus"></i>
                </span>
              </div>
              <span className={`ps-2 ${styles.addText}`}>Add campaign</span>
            </div>
            <AddCampaign
              show={addCampaign}
              onHide={() => setAddCampaign(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const DripCampaignC = () => {
  const { allCampaignSegments, fetchMore, fetchStatus, hasMore } =
    useDripCampaign();

  return (
    <InfiniteScroll
      className={`mb-1 ${styles.infiniteScroll}`}
      hasMoreBottom={hasMore}
      loadMoreFromBottom={fetchMore}
      infiniteLoader={
        <LoadMoreLoader
          fetchMore={fetchMore}
          variant="blue"
          status={
            fetchStatus === "fulfilled"
              ? "fulfilled"
              : fetchStatus === "pending"
                ? "pending"
                : null
          }
        />
      }
    >
      {allCampaignSegments.length === 0 && fetchStatus === "pending" ? (
        <div className="d-flex justify-content-center align-content-center align-items-center">
          <Loader />
        </div>
      ) : allCampaignSegments.length === 0 ? (
        <SegmentComponent
          dripCampaign={{
            segmentId: "",
            segmentName: "Default Segment",
            campaignTotalCount: 0,
            campaignsData: [],
          }}
          showShadowSegment={true}
        />
      ) : (
        allCampaignSegments.map((val, idx) => {
          return (
            <SegmentComponent
              key={val.segmentId}
              dripCampaign={val}
              defaultOpen={idx === 0}
            />
          );
        })
      )}
    </InfiniteScroll>
  );
};

export default DripCampaignC;
