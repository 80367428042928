import { axiosJSON } from "src/globals/axiosEndPoints";
import { findAutomationContextFromURLPath } from "src/utils/utils";

/**
 * Interface representing the parameters required to delete a text variable.
 */
export interface DeleteTextVariablesParams {
  /**
   * A key used to identify the set of editor variables.
   */
  editorVariablesKey: string;
  /**
   * The id of the variable to be deleted.
   */
  variableId: string;
}

/**
 * Deletes a text variable from the server.
 *
 * @param params - The parameters required to delete the text variable.
 * @returns `true`.
 * @throws An error if the server response contains an error.
 */
async function deleteTextVariablesService(params: DeleteTextVariablesParams) {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const { data: res } = await axiosJSON.post(
    `/api/setting/automation/${automationContext}/deleteTextVariables`,
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return true;
}

export default deleteTextVariablesService;
