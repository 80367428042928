import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showGlobalLoader } from "src/components/GlobalLoader/GlobalLoader";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import { axiosJSON, setAxiosAuth } from "src/globals/axiosEndPoints";
import {
  channelSubscriber,
  channelUnsubscriber,
  setNewPusher,
} from "src/globals/pusher";
import { getAccessToken } from "src/services/Auth/getAccessToken";
import { getCurrentUser } from "src/services/Settings/Users/getCurrentUser";
import {
  getBrandAndSignatureThunk,
  setCurrentUserData,
} from "src/store/slices/globals/globals.slice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { useLiveChatMessageBinder } from "../liveChatHooks/useLiveChatMessageBinder";
import { useLiveChatTrafficBinder } from "../liveChatHooks/useLiveChatTrafficBinder";
import { useTicketEventBinder } from "../ticketPusherHook/useTicketEventBinder";
import { flushSync } from "react-dom";
import useLoginRedirect from "./useLoginRedirect";

function checkRoute() {
  return (
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/reset-password") ||
    window.location.pathname.includes("/link-expired") ||
    window.location.pathname.includes("/login")
  );
}

export interface ILoginData {
  status: string;
  message: string;
  expires_in: number;
  token_type: string;
  access_token: string;
  auth_header_name: string;
  current_user_info: {
    userId: string | number;
    name: string;
    email: string;
  };
  first_login?: boolean;
  action?: {
    type: "user_redirect";
    url: string;
  };
}
export let logoutUser: (
  callback: () => void,
  callAxios?: boolean,
) => void = () => {};

let lastLoginData: any = JSON.parse(
  window.localStorage.getItem("loginData") as any,
);

function useLogin() {
  const { loginDataRedirect, initializedRedirect } = useLoginRedirect();

  useMemo(() => {
    if (loginDataRedirect) {
      //manully dispatching storage updated event to trigger the store listner to update the states and axios headers
      //widlow.localStorage.setItem is not triggering the storage lister if same value is updated in storage (could be in my case only), so added manual dipatch to trigger storage listner
      const event = new StorageEvent("storage", {
        key: "loginData",
        oldValue: lastLoginData ? JSON.stringify(lastLoginData) : lastLoginData, // Store the old login data if it exists, otherwise store null
        newValue: JSON.stringify(loginDataRedirect),
      });
      lastLoginData = loginDataRedirect;
      window.localStorage.setItem(
        "loginData",
        JSON.stringify(loginDataRedirect),
      );
      setTimeout(() => {
        window.dispatchEvent(event);
      }, 0);
    } else {
      if (initializedRedirect === false) {
        lastLoginData = null;
      }
    }
  }, [initializedRedirect, loginDataRedirect]);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const [loginData, setLoginData] = useState(
    lastLoginData as null | ILoginData,
  );
  // used to check weather the axios headers are updated and global data are fetched.
  const [initialized, setInitialized] = useState(false);
  const { bindAllEvents } = useLiveChatMessageBinder();
  const { bindAllTrafficEvents } = useLiveChatTrafficBinder();
  const { bindAllTicketEvents } = useTicketEventBinder();
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const navigate = useNavigate();
  // used to prevent the stale state issue.
  const currentState = useRef({ currentUserData, initialized, loginData });

  const logout = useCallback((callback: () => void, callAxios = true) => {
    channelUnsubscriber(
      currentState.current.currentUserData?.websocket_channel_name,
    );
    channelUnsubscriber(
      currentState.current.currentUserData?.websocket_private_channel_name,
    );
    if (callAxios) {
      axiosJSON
        .get(`/api/logout`)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          setLoginData(null);
          dispatch(setCurrentUserData(null));
          // navigate("/login");
          callback();
          showGlobalLoader("Normal");
          localStorage.removeItem("loginData");
          window.location.reload();
        });
    } else {
      setLoginData(null);
      dispatch(setCurrentUserData(null));
      // navigate("/login");
      callback();
      localStorage.removeItem("loginData");
      window.location.reload();
    }
  }, []);

  const currentUserCallBack = useCallback(
    (isLogin = false) => {
      // if loginData exists then fetch the current loged in user data otherwise set it to null.
      if (loginData) {
        getCurrentUser()
          .then((res) => {
            dispatch(setCurrentUserData(res));
            if (isLogin) {
              if (
                res?.socket_credentials?.cluster &&
                res.socket_credentials?.key
              ) {
                setNewPusher({
                  name: loginData.auth_header_name,
                  value: `${loginData.token_type} ${loginData.access_token}`,
                  pusherCluster: res.socket_credentials.cluster,
                  pusherKey: res.socket_credentials.key,
                });
                const globalChannel = channelSubscriber(
                  res?.websocket_channel_name,
                );
                const privateChannel = channelSubscriber(
                  res?.websocket_private_channel_name,
                );
                bindAllEvents(globalChannel, true);
                bindAllTrafficEvents(globalChannel, true);
                bindAllEvents(privateChannel, false);
                bindAllTicketEvents(globalChannel, true);
                bindAllTicketEvents(privateChannel, false);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            setTimeout(() => {
              showGlobalLoader("None");
            }, 0);
          })
          .finally(() => {
            setInitialized(true);
          });
      } else {
        dispatch(setCurrentUserData(null));
      }
    },
    [location.pathname, loginData],
  );

  const storageEvent = useCallback(() => {
    // this event is use to detect if the access token and login data are removed from localstorage then we are clearing the states and navigating the user to the correct route.
    let loginDataTemp: any = window.localStorage.getItem("loginData");

    if (loginDataTemp) {
      // Convert `lastLoginData` to a JSON string if it exists, otherwise keep it as is.
      let lastLoginDataStringify = lastLoginData
        ? JSON.stringify(lastLoginData)
        : lastLoginData;

      if (lastLoginDataStringify !== loginDataTemp) {
        flushSync(() => {
          setLoginData(JSON.parse(loginDataTemp) as any);
        });
        let parsedLoginData = JSON.parse(loginDataTemp);
        if (parsedLoginData.first_login && window.location.pathname === "/") {
          window.location.replace(
            (process.env.REACT_APP_FIRST_LOGIN_ROUTE ?? "/") +
              (window.location.hostname.includes("localhost")
                ? `?loginData=${encodeURIComponent(loginDataTemp)}`
                : ""),
          );
        } else if (checkRoute()) {
          navigate("/");
        } else {
          navigate(window.location.pathname + window.location.search);
        }
      }
    } else {
      channelUnsubscriber(
        currentState.current.currentUserData?.websocket_channel_name,
      );
      channelUnsubscriber(
        currentState.current.currentUserData?.websocket_private_channel_name,
      );
      setLoginData(null);
      navigate("/login");
    }
    // Parse the `loginDataTemp` from localStorage if it exists, otherwise assign `loginDataTemp` directly to `lastLoginData`
    lastLoginData = loginDataTemp ? JSON.parse(loginDataTemp) : loginDataTemp;
  }, []);

  useEffect(() => {
    currentState.current = { currentUserData, initialized, loginData };
  }, [currentUserData, initialized, loginData]);

  useEffect(() => {
    window.addEventListener("storage", storageEvent);
    return () => {
      window.removeEventListener("storage", storageEvent);
    };
  }, [storageEvent]);

  useEffect(() => {
    // making logout function globally available
    logoutUser = logout;
    return () => {
      logoutUser = (callback: () => void) => {};
    };
  }, [logout]);

  useEffect(() => {
    if (initializedRedirect) {
      // initially checking if user islogged in the movin it to correct route from login route and vice-versa
      if (lastLoginData) {
        setAxiosAuth({
          name: lastLoginData.auth_header_name,
          value: `${lastLoginData.token_type} ${lastLoginData.access_token}`,
        });
        if (lastLoginData.first_login && window.location.pathname === "/") {
          window.location.replace(
            (process.env.REACT_APP_FIRST_LOGIN_ROUTE ?? "/") +
              (window.location.hostname.includes("localhost")
                ? `?loginData=${encodeURIComponent(
                    JSON.stringify(lastLoginData),
                  )}`
                : ""),
          );
        } else if (checkRoute()) {
          navigate("/");
        } else {
          navigate(window.location.pathname + window.location.search);
        }
      } else {
        if (checkRoute()) {
          navigate(window.location.pathname + window.location.search);
        } else {
          navigate("/login");
        }
        setInitialized(true);
      }
    }
  }, [initializedRedirect]);

  useEffect(() => {
    // on changing login data updating axios headers and subscribing the channels again with new token also one timeout is added tocheck weather the if access tocken expires
    let timeout: any = null;
    if (loginData === null) {
      window.localStorage.removeItem("loginData");
      if ((window as any).SAUFTER_USER) {
        (window as any).SAUFTER_USER.currentUser = undefined;
      }
    } else {
      window.localStorage.setItem("loginData", JSON.stringify(loginData));
      setAxiosAuth({
        name: loginData.auth_header_name,
        value: `${loginData.token_type} ${loginData.access_token}`,
      });
      dispatch(getBrandAndSignatureThunk());
      currentUserCallBack(true);
      timeout = setTimeout(
        () => {
          // getting the access token and updating the pusher and axios headers
          getAccessToken()
            .then((res) => {
              if (loginData) {
                setAxiosAuth({
                  name: loginData.auth_header_name,
                  value: `${loginData.token_type} ${res.access_token}`,
                });
                dispatch(getBrandAndSignatureThunk());
                currentUserCallBack(true);
                const loginDataTemp = JSON.parse(JSON.stringify(loginData));
                loginDataTemp.access_token = res.access_token;
                setLoginData(loginDataTemp);
              }
            })
            .catch((err) => {
              channelUnsubscriber(
                currentState.current.currentUserData?.websocket_channel_name,
              );
              channelUnsubscriber(
                currentState.current.currentUserData
                  ?.websocket_private_channel_name,
              );
              setLoginData(null);
              navigate("/login");
            });
        },
        loginData.expires_in * 1000 - 1000,
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [loginData]);

  useEffect(() => {
    // bringing the updated currentUserData on location.
    if (currentState.current.initialized && currentState.current.loginData) {
      currentUserCallBack(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentUserData) {
      const loginDataTemp = window.localStorage.getItem("loginData");
      if (loginDataTemp) {
        let loginData = JSON.parse(loginDataTemp) as any;

        (window as any).SAUFTER_USER = {
          currentUser: {
            id: currentUserData?.userId,
            firstName: currentUserData?.firstName,
            lastName: currentUserData?.lastName,
            email: currentUserData?.email,
          },
        };

        const showFirstLogin = currentUserData.showFirstLogin ? true : false;

        if (loginData.first_login !== showFirstLogin) {
          //manully dispatching storage updated event to trigger the store listner to update the states and axios headers
          //widlow.localStorage.setItem is not triggering the storage lister if same value is updated in storage (could be in my case only), so added manual dipatch to trigger storage listner
          loginData.first_login = showFirstLogin;
          const event = new StorageEvent("storage", {
            key: "loginData",
            oldValue: loginDataTemp,
            newValue: JSON.stringify(loginData),
          });

          window.localStorage.setItem("loginData", JSON.stringify(loginData));
          //no need to stringify as lastLoginData is expected as object or null
          lastLoginData = loginData;
          setTimeout(() => {
            window.dispatchEvent(event);
          }, 0);
        }
      }
    }
  }, [currentUserData?.showFirstLogin]);

  return { loginData, setLoginData, logout, initialized };
}

export default useLogin;
