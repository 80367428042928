import styles from "./SelectAction.module.scss";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import refreshBtn from "src/assets/images/refresh-button.png";
import {
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import TimeLine from "../Timeline/Timeline";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";

const SelectAction = () => {
  const { selectedOrder, updateState, ...contextValues } =
    useReturnPreviewState();

  const { getReturnWindowData, isReturnWindowMessageAvailable } =
    useGetAutomationData();

  const { returnWindow } = getReturnWindowData();

  const handleNext = () => {
    if (selectedOrder) {
      // Default next page
      let nextPage: StepName = "selectAction";

      // Determine the next step based on return window and return exchange applicability
      if (returnWindow && returnWindow > 0 && isReturnWindowMessageAvailable) {
        if (selectedOrder?.returnExchangeApplicable) {
          // If return exchange is applicable, navigate to 'addReturnItems'
          nextPage = "addReturnItems";
        } else {
          // If return exchange is not applicable, navigate to 'notReturnable'
          nextPage = "notReturnable";
        }
      } else {
        // If no return window or message is available, navigate to 'configureNextSteps'
        nextPage = "configureNextSteps";
      }

      // Update state with the determined next page
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: nextPage,
          prevStepForConfig: "selectAction",
        },
      });
    }
  };

  const handleBack = () => {
    if (selectedOrder) {
      // Navigate back to 'selectOrder' step
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          activeStep: "selectOrder",
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
        <div className={`mt-2 ${styles.selectedOrder}`}>
          <div className="d-flex align-items-center justify-content-between w-100 mb-2">
            <div>
              <h6 className={`mb-0 ${styles.orderHead}`}>Order ID: #6979890</h6>
              <span className={`d-block ${styles.orderSubHead}`}>
                {selectedOrder?.itemsInOrderCount} items ordered on 03/07/2022
              </span>
            </div>
            <div>
              <span className={`${styles.sampleOrder}`}>
                {selectedOrder?.orderName}
              </span>
            </div>
          </div>
          <div>
            {selectedOrder && selectedOrder?.itemsInOrder?.length
              ? selectedOrder.itemsInOrder.slice(0, 2).map((item) => {
                  return (
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div>
                        <img
                          src={
                            item.name == "Acme T-Shirt" ? orderImg : orderImg2
                          }
                          alt="orderImg"
                          className={`${styles.orderImg}`}
                        />
                        <span className={`ps-1 ${styles.orderTitle}`}>
                          {item.name}
                        </span>
                      </div>
                      <p className={`mb-0 ${styles.orderDetails}`}>
                        {item.unitPrice.currencyCode}
                        {item.unitPrice.amount} x {item.quantity}
                      </p>
                    </div>
                  );
                })
              : null}
            <div>
              <span className={`${styles.moreItems}`}>
                8 more items{" "}
                <span>
                  <i className="fa-solid fa-angle-down"></i>
                </span>
              </span>
            </div>
            <div className={`d-flex justify-content-end ${styles.orderTitle}`}>
              Order total: {selectedOrder?.orderTotal?.currencyCode}
              {selectedOrder?.orderTotal?.amount}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <h3 className={`${styles.selectText}`}>
            Select action for the order:
          </h3>
          <button
            className={`${styles.actionBtn}`}
            id="initiateReturnBtn"
          >
            <img
              src={refreshBtn}
              alt=""
              width={21}
              height={20}
            />
            <span>Initiate Return Order</span>
          </button>
        </div>
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default SelectAction;
