import saufterLogo from "src/assets/images/saufter.gif";
import cautionImg from "src/assets/images/errorImg.png";
import slack from "src/assets/images/slack.png";
import styles from "./InstructionMessage.module.scss";
import editorGif from "src/assets/images/emailEdit.gif";
import { Modal } from "react-bootstrap";
import { useRef, useState } from "react";
import { getTimeFromNow } from "src/utils/dateLibrary";
const InstructionMessage = ({ dateTime }: { dateTime: string }) => {
  const [instruction, setInstruction] = useState(false);
  const modalContainerRef = useRef(null); // Create a ref for the container
  return (
    <div
      className="d-flex justify-content-end mb-3 mt-3"
      ref={modalContainerRef}
    >
      <div
        className={`${styles.iconContainer} d-flex justify-content-center align-items-center`}
      >
        <img
          src={saufterLogo}
          className={styles.profileIcon}
          alt="saufter logo"
        />
      </div>

      <div className={`mx-2 ${styles.msgBox}`}>
        <div className={styles.msgText}>
          <p className={`mb-1 ${styles.instructionHead}`}>
            Instructions for Using Cern AI
          </p>
          <div>
            <h6
              className={`mb-1 ${styles.listHead}`}
            >{`1) Select email section:`}</h6>
            <p className={`mb-1 ${styles.listSub}`}>
              Click on the part of the email you’d like to edit (e.g., subject
              line or body content).
            </p>
            <h6
              className={`mb-1 ${styles.listHead}`}
            >{`2) Click "Edit with AI":`}</h6>
            <p className={`mb-1 ${styles.listSub}`}>
              Once selected, click "Edit with AI" in the editing toolbar.
            </p>
            <h6
              className={`mb-1 ${styles.listHead}`}
            >{`3) Customize & review:`}</h6>
            <p className={`mb-1 ${styles.listSub}`}>
              Use the CERN text field below to make adjustments—generate new
              ideas, rephrase, or enhance the content based on your selected
              section. Within seconds, CERN will generate content for the
              selected section based on your prompt.
            </p>
            <img
              src={editorGif}
              alt="editor"
              className="img-fluid cursor-pointer"
              onClick={() => setInstruction(true)}
            />

            {/* Note to dev - Please update modal container as current cern ui to show inside it  */}
            <Modal
              backdropClassName={`${styles.modalBack}`}
              show={instruction}
              onHide={() => setInstruction(false)}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              centered={true}
              enforceFocus={false}
            >
              <div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className={`mb-0 ${styles.modalHead}`}>
                    Instructions for using Cern AI
                  </p>
                  <span
                    className={`${styles.closeModal} cursor-pointer`}
                    onClick={() => setInstruction(false)}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </span>
                </div>
                <img
                  src={editorGif}
                  alt="editor"
                  className="img-fluid"
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <span className={`me-2 mt-1 ${styles.time}`}>
        {getTimeFromNow({
          date: new Date(dateTime),
        })}
      </span>
    </div>
  );
};

export default InstructionMessage;
