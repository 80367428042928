/* eslint-disable no-throw-literal */
import { axiosJSON } from "src/globals/axiosEndPoints";

interface GetTemplateParams {
  campaignId: number;
  emailTemplateId: number;
  ticketId: number;
}

export interface EmailTemplateData {
  salutation: string;
  introduction: string;
  questionRegardingResolution: string;
  body: string;
  heading: string;
  logoId: string;
  logoUrl: string;
}

export interface CampaignEmail {
  id: number;
  emailTemplateData: EmailTemplateData;
}

export interface TemplateData {
  campaignName: string;
  html: string;
  // brand?: {
  //   id: number | string;
  //   name: string;
  //   email: string;
  // };
}

export const getTemplate = async (params: GetTemplateParams) => {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/getTemplate",
    params,
  );
  if (res.err) {
    throw res.msg;
  }

  return res.data as TemplateData;
};
