import { axiosJSON } from "src/globals/axiosEndPoints";

export interface IgetAllEventsPayload {
  customerId: string | number;
  eventType: "user_attribute" | "custom_attribute";
  start?: number;
  limit?: number;
}
interface EventData {
  attributeId: string;
  stageId: string;
  datetime: string;
  stageName: string;
  value: string;
  eventId: string;
  type: "custom_attribute" | "user_attribute";
  typeKey: string;
}
export interface GetAllEventsResponse {
  data: EventData[];
  meta: {
    hasMore: boolean;
    totalCount: number;
  };
}
export async function getAllEvents({
  payload,
}: {
  payload: IgetAllEventsPayload;
}) {
  const { data: res } = await axiosJSON.post(
    `/api/timeline/getAllEvents`,
    payload,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something went wrong!");
  }

  return res as GetAllEventsResponse;
}
