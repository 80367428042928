import { axiosJSON } from "src/globals/axiosEndPoints";

export enum ECampaignChannel {
  Email = "email",
  LiveChat = "livechat",
}

export enum ESequenceSectionType {
  Text = "text",
  Blog = "blog",
  Feature = "feature",
  Product = "product",
  Closing = "closing",
  Introduction = "introduction",
  Discount = "discount",
}

interface SequenceStepSectionBase<T extends ESequenceSectionType> {
  sectionType: T;
  htmlId: string;
  /** Only For frontend */
  id?: string;
}
export interface Description {
  id?: string;
  value: string;
  isDefault?: boolean;
}
export interface TextSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Text> {
  description: string;
}

export interface BlogSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Blog> {
  blogs: string[];
  /** Only for frontend */
  sections?: Description[];
}

export interface FeatureSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Feature> {
  features: string[];
  /** Only for frontend */
  sections?: Description[];
}

export interface ProductSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Product> {
  name: string;
  description: string;
  link: string;
}

export interface ClosingSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Closing> {
  description: string;
}

export interface IntroductionSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Introduction> {
  description: string;
}

export interface DiscountSectionSequence
  extends SequenceStepSectionBase<ESequenceSectionType.Discount> {
  amount: number;
  type: "percentage" | "currency";
}

export type SequenceStepSection =
  | TextSectionSequence
  | BlogSectionSequence
  | FeatureSectionSequence
  | ProductSectionSequence
  | ClosingSectionSequence
  | IntroductionSectionSequence
  | DiscountSectionSequence;

/**
 * Represents a single step within a campaign's sequence, with a specific template,
 * associated content sections, and delivery configurations.
 */
export interface SequenceStep {
  /** Only for frontend */
  id?: string;
  /** Identifier for the selected template for this step */
  templateId: string | number;
  /** Identifier for the variant of the selected template */
  variantId: string | number;
  /** Indicates if the template's structure has been edited by the user */
  isEdited?: boolean;
  /** Delay in days after which this step is triggered in the sequence */
  triggerAfter: number;
  /** Default communication channel chosen for this sequence step */
  channel: ECampaignChannel | null;
  /** Ordered array of sections defining the content of this sequence step */
  sections: SequenceStepSection[];
}

/**
 * Parameters required for creating a campaign (version 2) with structured sequence steps.
 */
export interface CreateCampaignV2Params {
  /** Identifier for the brand that the campaign is associated with */
  brandId: number | string;
  /** Name of the campaign */
  name: string;
  /** Identifier for the user segment targeted by this campaign */
  userSegmentId: string | number;
  /** Array of available communication channels for this campaign */
  availableChannels: ECampaignChannel[];
  /** Identifier for the trigger type, defining when the campaign sequence initiates */
  triggerId: string | number;
  /** Main category identifier for the selected template */
  categoryId: string | number;
  /**category name if other category selected */
  categoryDescription?: string;
  /** Optional sub-category identifier for further template classification */
  subCategoryId?: string | number;
  /**sub cateogry name if other selected */
  subCategoryDescription?: string;
  /** Array of steps defining the campaign sequence, each with its own template and content sections */
  sequence: SequenceStep[];
}

/**
 * Response structure after successfully creating a campaign (version 2) via the API.
 */
export interface CreateCampaignV2Response {
  /** Unique identifier for the newly created campaign */
  campaignId: number | string;
}

/**
 * Service function to create a new campaign with the provided parameters.
 * Makes a POST request to the campaign creation API endpoint and returns the response data.
 *
 * @param params - The parameters needed to create a campaign, including brand details,
 * user segments, sequence steps, and template specifications.
 * @returns A promise that resolves to `CreateCampaignV2Response` containing the campaign ID.
 * @throws An error message if the request fails or the API response contains an error.
 */
async function createCampaignV2Service(
  params: CreateCampaignV2Params,
): Promise<CreateCampaignV2Response> {
  const { data: res } = await axiosJSON.post("/api/campaign/createV2", params);

  if (res.err || res.error) {
    throw res.msg ?? res.message ?? "Something Unexpected Occured!";
  }

  return res.data as CreateCampaignV2Response;
}

export default createCampaignV2Service;
