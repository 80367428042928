import { styled } from "@tanstack/react-query-devtools/build/lib/utils";
import styles from "./AttributeTable.module.scss";
import DynamicProgressBar from "../DynamicProgressBar/DynamicProgressBar";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
const AttributeTable = () => {
  return (
    <div>
      <div className={`px-5 ${styles.tableHeader}`}>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Attribute name</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Attribute type</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Correlation with Success</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`${styles.element}`}>Confirm Correlation</span>
        </div>
      </div>
      <div className={`px-5 ${styles.tableData}`}>
        <div className={`${styles.col}`}>
          <span className={`text-truncate ${styles.rowElement}`}>Settings</span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`text-truncate ${styles.rowElement}`}>Activity</span>
        </div>
        <div className={`${styles.col}`}>
          <DynamicProgressBar
            filledBox={3}
            filledPercentage={30}
          />
        </div>
        <div className={`${styles.col} ps-2`}>
          <ToggleSwitch initialSelect={false} />
        </div>
      </div>
      <div className={`px-5 ${styles.nextTableData}`}>
        <div className={`${styles.col}`}>
          <span className={`text-truncate ${styles.rowElement}`}>
            Frequency
          </span>
        </div>
        <div className={`${styles.col}`}>
          <span className={`text-truncate ${styles.rowElement}`}>Behavior</span>
        </div>
        <div className={`${styles.col}`}>
          <DynamicProgressBar
            filledBox={8}
            filledPercentage={80}
          />
        </div>
        <div className={`${styles.col} ps-2`}>
          <ToggleSwitch initialSelect={true} />
        </div>
      </div>
    </div>
  );
};

export default AttributeTable;
