import { memo } from "react";
import { SegmentDataForUpdateI } from "../../hooks/useCustomerSegments";
import styles from "./UpdateSegmentDetails.module.scss";
import ActionBtns from "./children/ActionBtns/ActionBtns";
import SegmentGroup from "./children/SegmentGroup/SegmentGroup";
import SegmentName from "./children/SegmentName/SegmentName";
import { useUpdateSegmentDetailsCreate } from "./hooks/useUpdateSegmentDetails";

export interface EditCreateSegmentParams {
  segmentType: string;
  segmentId: string;
  name: string;
  description: string;
}

/**
 * Component to display and manage the update segment details UI.
 *
 * @returns The UpdateSegmentDetails component.
 */
function UpdateSegmentDetails({
  onHideUpdateSegmentModal,
  segmentDataForUpdate,
  onSubmit,
}: {
  onHideUpdateSegmentModal: () => void;
  segmentDataForUpdate: SegmentDataForUpdateI | null;
  onSubmit?: (params: EditCreateSegmentParams) => void;
}) {
  const [state, dispatch, Provider] =
    useUpdateSegmentDetailsCreate(segmentDataForUpdate);

  return (
    <Provider value={[state, dispatch]}>
      {segmentDataForUpdate && (
        <div>
          <div
            className="d-flex align-items-start w-100"
            id="segmentModal"
          >
            <div
              className={`${styles.closeModal} flex-shrink-0 ms-3`}
              onClick={onHideUpdateSegmentModal}
              id="segmentModalHide"
            >
              <span className="cursor-pointer">
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
            <div className="w-100">
              <div className="ms-2 px-2">
                <h3 className={`mb-0  ${styles.heading}`}>
                  {`${
                    segmentDataForUpdate.action === "create"
                      ? "Create"
                      : segmentDataForUpdate.action === "duplicate"
                        ? "Duplicate"
                        : "Edit"
                  } Segment`}
                </h3>
                <span className={`${styles.subHeading} pe-2`}>
                  The segment you create will be visible only in your
                  sidebar/account. Other team members will need to modify their
                  sidebar settings to display it.
                </span>
              </div>
              <div className={`${styles.mainContent}`}>
                <SegmentName />
                <SegmentGroup segmentDataForUpdate={segmentDataForUpdate} />
              </div>
            </div>
          </div>
          <ActionBtns
            onSubmit={onSubmit}
            onHideUpdateSegmentModal={onHideUpdateSegmentModal}
            segmentDataForUpdate={segmentDataForUpdate}
          />
        </div>
      )}
    </Provider>
  );
}

export default memo(UpdateSegmentDetails);
