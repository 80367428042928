import { axiosJSON } from "src/globals/axiosEndPoints";
import { DripCampaignData } from "./getAllDripCampaigns.service";

export interface GetCampaignsBySegmentIdParams {
  start: number;
  limit: number;
  segmentId: string | number;
}

async function getCampaignsBySegmentId(params: GetCampaignsBySegmentIdParams) {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/getCampaignsDataBySegmentId",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return {
    data: res.data as DripCampaignData[],
    metaData: res.metaData as { totalCount: number },
  };
}

export default getCampaignsBySegmentId;
