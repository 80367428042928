import FilterSection from "./children/FilterSection/FilterSection";
import Header from "./children/Header/Header";
import TicketTabs from "./children/TicketTabs/TicketTabs";
import TicketView from "./children/TicketView/TicketView";
import styles from "./TicketList.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  checkAllViewSelectedCols,
  checkUncheckColumnIds,
  fetchMoreCol,
  fetchTickets,
  fetchTicketUpdates,
  getSelectedColIdIndexForAllViewsSelector,
  removeAllTickets,
  resetAllMoreColumns,
  resetBulkActionFilters,
  resetCachedTickets,
  resetCurrentView,
  resetMoreColumns,
  resetPagination,
  resetTickets,
  setAllColumnList,
  setBulkActionFilters,
  setCurrColumnList,
  setCurrentPage,
  setCurrentView,
  setDefaultColumnList,
  setFilterSearchString,
} from "src/store/slices/tickets/tickets.slice";
import Pagination from "./children/Pagination/Pagination";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  fetchMultipleSidebarData,
  saveTicketAppliedData,
  saveTicketAppliedViewCols,
  setSelectedBtn,
  ticketSidebarActiveFilters,
} from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import BulkActionSection from "src/containers/BulkActionSection/BulkActionSection";
import { totalTicketsSelectedSeletector } from "src/store/slices/tickets/tickets.slice";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Loader from "src/components/Loader";
import FoundNoTickets from "src/components/FoundNoTickets";
import useTicketViews from "src/hooks/useTicketViews";
import { fetchAllMsgDraftStatuses } from "src/store/slices/ticketMsgDraftStatus/ticketMsgDraftStatus.slice";
import { resetAddBulkTags } from "src/store/slices/ticketTags/ticketTags.slice";
import { resetBulkAssignTickets } from "src/store/slices/ticketUsers/ticketUsers.slice";
import { resetbulkAddRemarks } from "src/store/slices/ticketRemarks/ticketRemarks.slice";
import { resetBulkTicketsStatuses } from "src/store/slices/ticketStatus/ticketStatus.slice";
import { resetbulkSendMessage } from "src/store/slices/ticketSendMessage/ticketSendMessage.slice";
import {
  getBulkSelectedTicketIds,
  getSelectedColIdIndexForAllViews,
} from "src/store/slices/tickets/tickets.selectors";
import {
  getActiveListName,
  getSavedTicketAppliedData,
} from "src/store/slices/ticketSidebar/ticketSidebar.selectors";
import OuterTickets from "./children/OuterTickets/OuterTickets";
import {
  setFiltersFromURL,
  resetFilters,
  initialFilters,
} from "src/store/slices/ticketFilters/ticketFilters.slice";
import ViewsFilter from "./children/FilterSection/children/ViewsFilter/ViewsFilter";
import BulkSelectModel from "src/containers/BulkActionSection/childern/BulkSelectModel";
import { Modal } from "react-bootstrap";
import { resetTicketGlobalSearch } from "src/store/slices/ticketGlobalSearch/ticketGlobalSearch.slice";
import { setLastActiveTableType } from "src/store/slices/innerTicket/innerTicket.slice";

let filterToggleStateG = false;
// Flag to prevent multiple sidebar ajax after initial
let initialSideBarFetch = true;

// Object to store the last applied filters for tickets and active sidebar filters
let lastAppliedFilter = { ticketFilter: "", activeTicketFilter: "" };

export const setFilterToggleStateG = (state: boolean) => {
  filterToggleStateG = state;
};

function TicketList() {
  // const location = useLocation();
  const dispatch = useAppDispatch();
  const updatingIntervals = useRef(true);
  const { tableType, viewType }: any = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allColumnList, defaultColumnsList, currentColumnsList } =
    useTicketViews();
  const allTicketsIds = useAppSelector((state) => state.ticket.allTicketsIds);
  const totalTicketsSelected = useAppSelector(totalTicketsSelectedSeletector);
  const bulkSelectedTicketIds = useAppSelector(getBulkSelectedTicketIds);
  const currentPage = useAppSelector((state) => state.ticket.currentPage);
  const totalPages = useAppSelector((state) => state.ticket.totalPages);
  const sidebarActiveFilters = useAppSelector(ticketSidebarActiveFilters);

  const { totalTicketsCount, ticketsPerPageLimit } = useAppSelector(
    (state) => state.ticket,
  );
  const ticketsLoadingState = useAppSelector(
    (state) => state.ticket.ticketsLoadingState,
  );
  const sidebarAjaxStatus = useAppSelector(
    (state) => state.ticketSideBar.ajaxStatus,
  );

  const selectedAiBtn = useAppSelector(
    (state) => state.ticketSideBar.selectedAiBtn,
  );
  const aiOpenCount = useAppSelector(
    (state) => state.ticketSideBar.aiOpenTicketCount,
  );
  const aiClosedCount = useAppSelector(
    (state) => state.ticketSideBar.aiClosedTicketCount,
  );
  // const moreColAjaxStatus = useAppSelector(
  //   (state) => state.ticket.moreColAjaxStatus
  // );
  // const filterSearchString = useAppSelector(
  //   (state) => state.ticket.filterSearchString
  // );
  const activeListName = useAppSelector(getActiveListName);
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );
  const ticketFilters = useAppSelector(
    (state) => state.ticketFilter.activeFilters,
  );
  const allViewSelectedCols = useAppSelector(
    getSelectedColIdIndexForAllViewsSelector,
  );
  const outerTicketColumn = useAppSelector(
    (state) => state.globals.currentUserData?.outerTicketColumn,
  );
  // get the saved saved search query if there is any
  const savedTicketAppliedData = useAppSelector((state) =>
    getSavedTicketAppliedData(state.ticketSideBar, tableType),
  );

  // declared to check weather the pre required ajax is complete or not before executing the dependent useeffects
  const [initialization, setInitialization] = useState<
    Array<"sidebar" | "draftStatus">
  >([]);
  // it is used to prevent the stale state issue.
  const initializationRef = useRef(initialization.length);

  //getting the show modal flag for select all ticket bulk action modal from ticket slice
  const { showSelectAllTicketModal, selectedAllTickets } = useAppSelector(
    (state) => state.ticket.bulkActionFilters,
  );

  const allBulkSelectedTicketIdsRef = useRef(bulkSelectedTicketIds);
  //flag to keep track of previous selectAllTicket state
  const allBulkSelectAllTicketsRef = useRef(selectedAllTickets);

  // useEffect(() => {
  //   if (
  //     (bulkSelectedTicketIds.length === 0 && selectedAllTickets === false) &&
  //     (allBulkSelectedTicketIdsRef.current.length || allBulkSelectAllTicketsRef.current) //checking if select all is unselected and selectedTicketIds also empty then rest the page
  //   ) {
  //     dispatch(resetCachedTickets());
  //     dispatch(fetchTickets());
  //     dispatch(fetchMultipleSidebarData());
  //     allBulkSelectedTicketIdsRef.current = [];
  //     allBulkSelectAllTicketsRef.current = false;
  //   } else {
  //     allBulkSelectedTicketIdsRef.current = [
  //       ...new Set([
  //         ...allBulkSelectedTicketIdsRef.current,
  //         ...bulkSelectedTicketIds,
  //       ]),
  //     ];
  //     allBulkSelectAllTicketsRef.current = selectedAllTickets; //updating useRef with state value
  //   }
  // }, [bulkSelectedTicketIds]);
  useEffect(() => {
    initializationRef.current = initialization.length;
  }, [initialization]);

  const searchText = useAppSelector((state) =>
    state.ticketGlobalSearch.searchText.trim(),
  );

  // saving some data in current state to avoide the stale state issue.
  const currentState = useRef({
    viewType,
    tableType,
    outerTicketColumn,
    savedTicketAppliedData,
    allViewSelectedCols,
    // Adding ticketFilters and sidebarActiveFilters to the dependency array
    ticketFilters,
    sidebarActiveFilters,
  });

  // Using useMemo instead of useEffect to memoize the current state
  useMemo(() => {
    currentState.current = {
      viewType,
      tableType,
      outerTicketColumn,
      savedTicketAppliedData,
      allViewSelectedCols,
      // Adding ticketFilters and sidebarActiveFilters to the current state
      ticketFilters,
      sidebarActiveFilters,
    };
  }, [
    viewType,
    tableType,
    outerTicketColumn,
    savedTicketAppliedData,
    allViewSelectedCols,
    // Adding ticketFilters and sidebarActiveFilters to the dependency array
    ticketFilters,
    sidebarActiveFilters,
  ]);

  // fill the redux with all the initial columns data
  useEffect(() => {
    dispatch(setAllColumnList(allColumnList));
    dispatch(setDefaultColumnList(defaultColumnsList));
    dispatch(setCurrColumnList(currentColumnsList));
    dispatch(setLastActiveTableType({ lastActiveTableType: null }));
    dispatch(
      fetchAllMsgDraftStatuses(() => {
        // increamenting the initialization value on prerequired ajax fulfilled
        setInitialization((prev) => {
          //intead of updating count, updating type string of which has completed
          if (!prev.includes("draftStatus")) {
            const newState = [...prev];
            newState.push("draftStatus");
            return newState;
          }
          return prev;
        });
      }),
    );
  }, [false]);

  useEffect(() => {
    if (initializationRef.current < 2 && sidebarAjaxStatus === "fulfilled") {
      // increamenting the initialization value on prerequired ajax fulfilled
      setInitialization((prev) => {
        //intead of updating count, updating type string of which has completed
        if (!prev.includes("sidebar")) {
          const newState = [...prev];
          newState.push("sidebar");
          return newState;
        }
        return prev;
      });
    }
  }, [sidebarAjaxStatus]);

  useEffect(() => {
    // saving the updated applied filters for current view type.
    const SavedOuterTicketColumn = currentState.current.outerTicketColumn
      ? JSON.parse(currentState.current.outerTicketColumn)
      : {};

    let allViewSelectedColData = allViewSelectedCols;

    if (
      SavedOuterTicketColumn &&
      SavedOuterTicketColumn[currentState.current.tableType] &&
      SavedOuterTicketColumn[currentState.current.tableType]
        .allViewSelectedCols &&
      SavedOuterTicketColumn[currentState.current.tableType].allViewSelectedCols
    ) {
      allViewSelectedColData =
        SavedOuterTicketColumn[currentState.current.tableType]
          .allViewSelectedCols;
    }
    dispatch(
      saveTicketAppliedData({
        tableType: currentState.current.tableType,
        ticketAppliedData: JSON.stringify({
          ticketFilters,
          allViewSelectedCols: allViewSelectedColData,
        }),
      }),
    );
  }, [JSON.stringify(ticketFilters), allViewSelectedCols]);

  useEffect(() => {
    if (outerTicketColumn && outerTicketColumn.trim()) {
      dispatch(
        saveTicketAppliedViewCols({
          savedTicketAppliedData: JSON.parse(outerTicketColumn),
        }),
      );
    }
  }, [outerTicketColumn]);

  useEffect(() => {
    // setting the selected buton in sidebar based on the table type from URL
    dispatch(setSelectedBtn({ tableType }));
    if (initialization.length === 2) {
      // if all ajx initialized the changing the current filters based on selected table type.
      const tf = currentState.current.savedTicketAppliedData.ticketFilters;
      const moreCols =
        currentState.current.savedTicketAppliedData.allViewSelectedCols;
      if (moreCols) {
        dispatch(checkAllViewSelectedCols(moreCols));
      } else {
        dispatch(resetAllMoreColumns());
      }
      if (tf) {
        dispatch(setFiltersFromURL(tf));
      } else {
        dispatch(
          resetFilters({
            removeFilterByLastUpdated:
              tableType !== "my" && tableType !== "all",
            resetActiveFilters: true,
          }),
        );
      }
    }
  }, [tableType, initialization, selectedAiBtn]);

  useEffect(() => {
    // used to disable polling if the bulk action is active or current page is not 1st or search is not acive
    const ba =
      totalTicketsSelected !== 0 &&
      currentUserData &&
      currentUserData.scopes &&
      currentUserData.scopes.includes("bulk_edit");
    if (ba || currentPage > 1 || searchText?.trim()) {
      updatingIntervals.current = false;
    } else {
      updatingIntervals.current = true;
    }
  }, [
    currentPage,
    currentUserData,
    totalTicketsSelected,
    currentUserData?.scopes,
    searchText,
  ]);

  const getCurrentViewNameFormTableType = useCallback(() => {
    if (
      currentState.current.tableType &&
      currentState.current.tableType.trim() !== ""
    ) {
      return [currentState.current.tableType];
    } else {
      return [];
    }
  }, []);

  const fetchTicketsEffect = useCallback((update: boolean) => {
    if (initializationRef.current === 2) {
      console.log(lastAppliedFilter);
      // if the sidebar filters are lost when we go to some other place
      // set the sidebar filters saved in redux
      dispatch(resetCachedTickets());
      if (update) {
        dispatch(fetchTicketUpdates());
      } else {
        try {
          // Creating a new filter object with the current ticket and sidebar active filters
          const newAppliedFilter = {
            ticketFilter: JSON.stringify(currentState.current.ticketFilters),
            activeTicketFilter: JSON.stringify(
              currentState.current.sidebarActiveFilters,
            ),
          };
          // Checking if the new filters are different from the last applied filters
          if (
            newAppliedFilter.activeTicketFilter !==
              lastAppliedFilter.activeTicketFilter ||
            newAppliedFilter.ticketFilter !== lastAppliedFilter.ticketFilter
          ) {
            // Resetting the pagination if the filters have changed
            dispatch(resetPagination());
          }
          // Updating the last applied filter with the new filter
          lastAppliedFilter = newAppliedFilter;
        } catch (e: unknown) {
          console.error("Ticket List - Unexpected error occured : ", e);
        }
        dispatch(fetchTickets());
      }
      // refetch the updated sidebar counts
      if (initialSideBarFetch) {
        dispatch(
          fetchMultipleSidebarData({
            views: [
              "all",
              "my",
              "mentions",
              "ai_handled_open",
              "ai_handled_closed", // 08 May 2024 Added these to the views to get the ai closed and open tickets count only on ticket list page
              // "allViews", //Mar 07/24 commented fetching for list view counts on initial load as it was already getting the count from list views api
            ],
          }),
        );
      } else {
        const views: string[] = getCurrentViewNameFormTableType();
        views.push("ai_handled_open");
        views.push("ai_handled_closed"); // 08 May 2024 Added these to the views to get the ai closed and open tickets count only on ticket list page
        dispatch(fetchMultipleSidebarData({ views: views }));
      }
      initialSideBarFetch = false;
    }
  }, []);

  useEffect(() => {
    // fetching tickets based on filters change or sidebar change
    fetchTicketsEffect(false);
  }, [ticketFilters, JSON.stringify(sidebarActiveFilters)]); //to prevent multiple rerendering using json.stringfy

  useEffect(() => {
    dispatch(setCurrentView(viewType));
    if (initializationRef.current === 2) {
      dispatch(removeAllTickets());
      dispatch(resetAddBulkTags());
      dispatch(resetBulkAssignTickets());
      dispatch(resetbulkAddRemarks());
      dispatch(resetBulkTicketsStatuses());
      dispatch(resetbulkSendMessage());
      dispatch(resetPagination());
      dispatch(resetCachedTickets());
      dispatch(fetchTickets());
      // refetch the updated sidebar counts
      if (initialSideBarFetch) {
        dispatch(
          fetchMultipleSidebarData({
            views: [
              "all",
              "my",
              "mentions",
              "ai_handled_open",
              "ai_handled_closed", // 08 May 2024 Added these to the views to get the ai closed and open tickets count only on ticket list page
              // "allViews", //Mar 07/24 commented fetching for list view counts on initial load as it was already getting the count from list views api
            ],
          }),
        );
      } else {
        const views: string[] = getCurrentViewNameFormTableType();
        views.push("ai_handled_open");
        views.push("ai_handled_closed"); // 08 May 2024 Added these to the views to get the ai closed and open tickets count only on ticket list page
        dispatch(fetchMultipleSidebarData({ views: views }));
      }
      initialSideBarFetch = false;
      dispatch(resetBulkActionFilters());
    }
  }, [viewType]);

  /**
   * Checks if currently open view is default view or not
   */
  const isDefaultView = useMemo(() => {
    // If table is my or all
    if (tableType === "my" || tableType === "all") {
      // If the filters are same as initial filters
      if (JSON.stringify(ticketFilters) === JSON.stringify(initialFilters)) {
        // It is default view and we need to show blurred section
        return true;
      }
    }
    // Not default view
    return false;
  }, [tableType, ticketFilters]);

  const dispatchCurrentPage = useCallback(
    (selectedPageNumber: any) => {
      dispatch(setCurrentPage(selectedPageNumber));

      // This condition is true when we access limiter page for showing blurred tickets
      if (isDefaultView && selectedPageNumber === totalPages + 1) {
        return;
      } else {
        dispatch(fetchTickets());
      }
    },
    [isDefaultView, totalPages],
  );

  // useEffect(() => {
  //   const int = setInterval(() => {
  //     if (updatingIntervals.current && filterToggleStateG === false) {
  //       fetchTicketsEffect(true);
  //     }
  //   }, 15000);
  //   return () => {
  //     clearInterval(int);
  //   };
  // }, [ticketFilters, sidebarActiveFilters]);

  //this function will be called on closing of select all ticket bulk action modal
  const onHide = () => {
    //updating the showSelectAllTicketModal property to false on ticket slice to hide the modal
    dispatch(setBulkActionFilters({ showSelectAllTicketModal: false }));
  };
  useEffect(() => {
    // if (!window.location.pathname.includes("/tickets")) {
    // dispatch(resetTicketGlobalSearch());
    // }
  }, [false]);

  // Memoized boolean to check if we need to add limiter page or not
  const addExtraPage = useMemo(() => {
    // If it is not default view
    if (!isDefaultView) {
      return false;
    }

    // This tells remaining tickets on last page ranging from 0 to per page limit - 1
    const remainingTicketsOnLastPage = totalTicketsCount % ticketsPerPageLimit;

    // If last page has tickets equal to per page ticket limit
    if (remainingTicketsOnLastPage === 0) {
      return false;
    }

    // If we have less than 50% tickets on last page
    if (remainingTicketsOnLastPage <= ticketsPerPageLimit * 0.5) {
      return false;
    }

    // Add the extra limiter page
    return true;
  }, [isDefaultView, totalTicketsCount, ticketsPerPageLimit]);

  return (
    <div className="main__content">
      {(totalTicketsSelected !== 0 || selectedAllTickets === true) &&
        currentUserData &&
        currentUserData.scopes &&
        currentUserData.scopes.includes("bulk_edit") && (
          <BulkActionSection totalTicketsSelected={totalTicketsSelected} />
        )}
      <Header
        count={
          tableType == "ai_handled"
            ? selectedAiBtn == "Open"
              ? aiOpenCount +
                `${
                  viewType === "customer-view"
                    ? ` Customer${aiOpenCount > 1 ? "s" : ""}`
                    : ` Ticket${aiOpenCount > 1 ? "s" : ""}`
                }`
              : selectedAiBtn == "Closed"
              ? aiClosedCount +
                `${
                  viewType === "customer-view"
                    ? ` Customer${aiClosedCount > 1 ? "s" : ""}`
                    : ` Ticket${aiClosedCount > 1 ? "s" : ""}`
                }`
              : aiClosedCount +
                aiOpenCount +
                `${
                  viewType === "customer-view"
                    ? ` Customer${aiClosedCount + aiOpenCount > 1 ? "s" : ""}`
                    : ` Ticket${aiClosedCount + aiOpenCount > 1 ? "s" : ""}`
                }`
            : totalTicketsCount === null
            ? 0 + `${viewType === "customer-view" ? " Customer" : " Ticket"}`
            : totalTicketsCount +
              `${
                viewType === "customer-view"
                  ? ` Customer${totalTicketsCount > 1 ? "s" : ""}`
                  : ` Ticket${totalTicketsCount > 1 ? "s" : ""}`
              }`
        }
        name={activeListName}
      />
      {searchText?.trim() === "" ? (
        <>
          <TicketTabs />
          <div
            className={`${styles.main_table} pt-4 pe-4`}
            style={
              totalTicketsSelected !== 0 || selectedAllTickets === true
                ? { marginBottom: "125px" }
                : {}
            }
          >
            <FilterSection />
            <ViewsFilter />
            {ticketsLoadingState === "pending" ? (
              <Loader />
            ) : (
              <>
                {/* <FilterSection /> */}
                {allTicketsIds.length !== 0 ? (
                  <>
                    <TicketView
                      showBlurredSection={
                        isDefaultView && currentPage === totalPages
                      } // Show blurred section if we have default view and current page is last page
                      showLimiterPage={currentPage === totalPages + 1} // Used for limiter page
                    />
                    <div className="pb-5">
                      <Pagination
                        currentPage={currentPage}
                        // Add limiter page if its default view
                        totalPages={addExtraPage ? totalPages + 1 : totalPages}
                        setCurrentPage={dispatchCurrentPage}
                        showLastTwoPages={addExtraPage ? true : false}
                      />
                    </div>
                  </>
                ) : (
                  <FoundNoTickets />
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {/* Developing v2 Search Outer ticket View 03.09.2022 */}
          <div
            className={`${styles.main_table} ${styles.searchTable} pt-2 pe-4 mt-3`}
            id="searchedTickets"
          >
            <OuterTickets />
          </div>
        </>
      )}

      <Modal
        show={showSelectAllTicketModal}
        className="mt-5"
        onHide={onHide}
        contentClassName={`${styles.modalContent}`}
        dialogClassName={`${styles.modalDialog}`}
        centered
        id="bulkSelectModal"
      >
        <BulkSelectModel onHide={onHide} />
      </Modal>
    </div>
  );
}
export default TicketList;
