import Lottie from "lottie-light-react";
import { useNavigate } from "react-router-dom";
import celebration from "src/assets/images/celebration.json";
import Loader from "src/components/Loader";
import { useAddCampaignState } from "../../../../Hooks/useAddCampaignContext";
import styles from "./SuccessMessage.module.scss";

const SuccessMessage = ({
  isLoading,
  onHide,
}: {
  isLoading: boolean;
  onHide: () => void;
}) => {
  const { state } = useAddCampaignState();
  const navigate = useNavigate();

  return (
    <div>
      {!isLoading ? (
        <div className="position-relative">
          <div className="text-center py-2">
            <div className={`${styles.checkBox} mb-4`}>
              <Lottie
                loop={true}
                className={`${styles.celebrationAnimation}`}
                animationData={celebration}
              ></Lottie>
              <span>
                <i className="fa-solid fa-check"></i>
              </span>
            </div>
            <p className={`mb-3 pt-2 ${styles.setup}`}>
              Campaign Created Successfully!
            </p>
            <span className={`${styles.successSpan}`}>
              Your new campaign has been added and is ready to launch. You can
              review the campaign <br /> details, make edits, or track its
              performance from the Campaigns Dashboard.
            </span>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-2 pt-2">
            <button
              className={`${styles.okBtn}`}
              onClick={() => {
                if (state.campaignId) {
                  navigate(`/campaigns/${state.campaignId}`);
                }

                onHide();
              }}
            >
              View campaign
            </button>
          </div>
        </div>
      ) : (
        <div className={`${styles.loadingSection}`}>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Loader />
            <p className={`${styles.loaderText}`}>
              Baking your campaign now...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessMessage;
