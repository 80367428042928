import { IAmount } from "src/features/ReturnAutoWorkFlow/ReturnAutoWorkFlow.types";
import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { findAutomationContextFromURLPath } from "src/utils/utils";

/**
 * Parameters for fetching the order modal information.
 */
export interface GetOrderModalInfoParams {
  integrationId: string;
  /** The filters applied to the order information. */
  filters: {
    /** The search text to filter orders */
    searchText?: string;
    /**
     * The sorting options for the orders.
     *
     * The key is the `tableHeadingId` and the value is the sort order (`asc` or `desc`).
     */
    sortBy?: Record<string, "asc" | "desc">;
  };
  /** The starting index for the orders to be fetched, used for pagination. */
  start: number;
  /** The maximum number of orders to be fetched in a single request, used for pagination. */
  limit: number;
}

/**
 * Represents a table heading in the orders table.
 */
export interface TableHeading {
  /**
   * The unique identifier for the table heading.
   * This ID will be used in rows to render corresponding data.
   */
  tableHeadingId: string;
  /** The display name of the table heading. */
  tableHeadingName: string;
  /**
   * Indicates whether the column is sortable.
   */
  isSortable: boolean;
}

/**
 * The data for the corresponding row in the orders table.
 * - `type` can be `null`, `"string"`, `"number"`, or `"price" or `"date"`.
 * - `value` can be `null`, a `string`, a `number`, or an `IAmount`.
 */
export interface OrderRow {
  // tableHeadingId: string;
  type: null | "string" | "stringArray" | "number" | "price" | "date";
  value: null | string | number | IAmount;
}
/**
 * Represents an individual order.
 */
export interface IOrder {
  /** The unique identifier for the order. */
  orderId: string;
  rowData: OrderRow[];
}

/**
 * The response structure for the `getOrderModalInfo` function.
 */
export interface GetOrderModalInfoResponse {
  /**
   * The array of table headings to be displayed in the orders table.
   */
  tableHeadings: TableHeading[];
  /**
   * The list of all orders retrieved based on the provided parameters.
   */
  allOrders: IOrder[];
  /**
   * The total number of orders available.
   */
  totalOrders: number;
}

/**
 * Fetches the order data for the Order Info modal.
 *
 * This function retrieves the order table data based on the provided parameters,
 * including filtering, sorting, and pagination details.
 *
 * @param {GetOrderModalInfoParams} params - The parameters for fetching the order modal information.
 * @returns {Promise<GetOrderModalInfoResponse>} A promise that resolves to the order modal information, including
 * the table headings, list of orders, and total number of orders.
 * @throws {Error} If the server response contains an error.
 */
async function getOrderModalInfo(
  params: GetOrderModalInfoParams,
): Promise<GetOrderModalInfoResponse> {
  let automationContext = findAutomationContextFromURLPath() as
    | ReturnType<typeof findAutomationContextFromURLPath>
    | "refund";

  // Making it refund as return automations uses refund key in URL.
  if (automationContext === "return") {
    automationContext = "refund";
  }

  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/getOrderModalInfo`
    : `/api/setting/automation/${automationContext}/order/getAll`;
  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }

  return res.data as GetOrderModalInfoResponse;
}

export default getOrderModalInfo;
