import { Dispatch, createContext, useContext, useMemo } from "react";
import { TemplateStep } from "src/services/LiveChat/ScheduleFollowups/getAllTemplates";
import useScheduleModal from "../CustomHooks/useScheduleModal";
import { v4 as uuid } from "uuid";
import { FetchStatus } from "@tanstack/react-query";

const TemplateContext = createContext<{
  [key: string]: TemplateStep[];
}>({});

export const ExtendedTemplateContext = createContext<{
  templateIds: number[];
  isLoading: boolean;
  isError: boolean;
  selectedId: string | null;
  setSelectedId: (value: string | null) => void;
  fetchStatus: FetchStatus;
  handleInfiniteScroll: () => void;
  hasNextPage?: boolean;
  loader?: JSX.Element;
  errorMessage?: JSX.Element;
  updateTemplateSteps: (templateId: string, steps: TemplateStep[]) => void;
  totalCount?: number;
}>({
  templateIds: [],
  isLoading: false,
  isError: false,
  selectedId: null,
  setSelectedId: () => {},
  fetchStatus: "idle",
  handleInfiniteScroll: () => {},
  hasNextPage: undefined,
  loader: undefined,
  errorMessage: undefined,
  updateTemplateSteps: () => {},
  totalCount: 0,
});

const TemplateDispatchContext = createContext<
  Dispatch<{
    type: string;
    payload: any;
  }>
>(() => {});

export function TemplateProvider({
  children,
  scheduleMessageFor,
}: {
  children: React.ReactNode;
  scheduleMessageFor: "innerTicket" | "liveChat" | "userSegment";
}) {
  const {
    templates,
    data,
    dispatch,
    templateIds,
    isLoading,
    isError,
    selectedId,
    setSelectedId,
    fetchStatus,
    handleInfiniteScroll,
    hasNextPage,
    loader,
    errorMessage,
    updateTemplateSteps,
    totalCount,
  } = useScheduleModal(scheduleMessageFor);

  return (
    <TemplateContext.Provider value={templates}>
      <TemplateDispatchContext.Provider value={dispatch}>
        <ExtendedTemplateContext.Provider
          value={{
            templateIds,
            isLoading,
            isError,
            selectedId,
            setSelectedId,
            fetchStatus,
            handleInfiniteScroll,
            hasNextPage,
            loader,
            errorMessage,
            updateTemplateSteps,
            totalCount,
          }}
        >
          {children}
        </ExtendedTemplateContext.Provider>
      </TemplateDispatchContext.Provider>
    </TemplateContext.Provider>
  );
}

export function useTemplates() {
  return useContext(TemplateContext);
}

export function useTemplateDispatch() {
  return useContext(TemplateDispatchContext);
}

export function templateReducer(
  templates: {
    [key: string]: TemplateStep[];
  },
  action: {
    type: string;
    payload:
      | {
          templateId: string;
          stepId: string;
          value: any;
        }
      | any;
  },
) {
  switch (action.type) {
    case "SET_TEMPLATES":
      return { ...templates, ...action.payload };

    case "Update_ScheduleFollowUpValue":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        const step = template.find(
          (step) => step.stepId === action.payload.stepId,
        );
        if (step) {
          step.scheduleFollowUpValue = action.payload.value;
        }
      }
      return Object.assign({}, templates);

    case "Update_ScheduleFollowUpType":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        const step = template.find(
          (step) => step.stepId === action.payload.stepId,
        );
        if (step) {
          step.scheduleFollowUpType = action.payload.value;
          if (action.payload?.typeValue) {
            step.scheduleFollowUpValue = action.payload.typeValue;
          }
        }
      }
      return Object.assign({}, templates);

    case "Update_SendMessageCondition":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        const step = template.find(
          (step) => step.stepId === action.payload.stepId,
        );
        if (step) {
          step.sendMessageCondition = action.payload.value;
        }
      }
      return Object.assign({}, templates);

    case "Update_TemplateHeading":
      if (action.payload.templateId && action.payload.stepId) {
        return {
          ...templates,
          [action.payload.templateId]: templates[action.payload.templateId].map(
            (step) => {
              if (step.stepId === action.payload.stepId) {
                return {
                  ...step,
                  templateHeading: action.payload.value,
                };
              }
              return step;
            },
          ),
        };
      }
      return templates;

    case "Update_TemplateMessage":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        if (template) {
          const step = template.find(
            (step) => step.stepId === action.payload.stepId,
          );
          if (step) {
            step.templateMessage = action.payload.value;
          }
        }
      }
      return Object.assign({}, templates);

    case "Update_TemplateAttachments":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        if (template) {
          const step = template.find(
            (step) => step.stepId === action.payload.stepId,
          );
          if (step) {
            step.attachments = action.payload.value;
          }
        }
      }
      return Object.assign({}, templates);

    case "Create_New_Template":
      if (action.payload.templateId && action.payload.stepId) {
        if (action.payload.templateId === "new") {
          const newTemplate = [
            {
              stepId: action.payload.stepId,
              templateHeading: action.payload.templateName ?? "",
              templateMessage: "",
              attachments: [],
              deletedAttachmentIds: [],
              scheduleFollowUpType: "days",
              scheduleFollowUpValue: 1,
              sendMessageCondition: "none",
            } as TemplateStep,
          ];
          templates = {
            [action.payload.templateId]: newTemplate,
            ...templates,
          };
        }
      }
      return Object.assign({}, templates);

    case "Add_Another_Step":
      if (action.payload.templateId && action.payload.stepId === "") {
        const template = [...templates[action.payload.templateId]];
        const newStep = {
          stepId: uuid(),
          templateHeading: "",
          templateMessage: "",
          attachments: [],
          deletedAttachmentIds: [],
          scheduleFollowUpType: "days",
          scheduleFollowUpValue: 1,
          sendMessageCondition: "none",
        } as TemplateStep;
        template.push(newStep);
        return Object.assign({}, templates, {
          [action.payload.templateId]: template,
        });
      }
      return templates;

    case "ConvertTime":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        const step = template.find(
          (step) => step.stepId === action.payload.stepId,
        );
        if (step) {
          step.scheduleFollowUpValue = action.payload.value;
        }
      }
      return Object.assign({}, templates);

    case "Update_Template_Id":
      if (action.payload.templateId && action.payload.value) {
        const template = [...templates[action.payload.templateId]];
        if (template) {
          templates = {
            [action.payload.value]: template,
            ...templates,
          };

          delete templates[action.payload.templateId];
          return Object.assign({}, templates, {
            [action.payload.value]: template,
          });
        }
      }
      return templates;

    case "Refresh_Templates":
      if (action.payload.templateId && action.payload.value) {
        return {
          ...templates,
          [action.payload.templateId]: action.payload.value,
        };
      }
      return templates;
    case "Remove_Step":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        if (template) {
          const step = template.filter(
            (step) => step.stepId != action.payload.stepId,
          );
          templates[action.payload.templateId] = step;
        }
        return Object.assign({}, templates);
      }
      return templates;
    case "add_RemovedAttachmentId":
      if (action.payload.templateId && action.payload.stepId) {
        const template = templates[action.payload.templateId];
        if (template) {
          const step = template.find(
            (step) => step.stepId === action.payload.stepId,
          );
          if (step) {
            step.removedAttachmentIds = step.removedAttachmentIds
              ? [...step.removedAttachmentIds, action.payload.value]
              : [action.payload.value];
          }
        }
      }
      return Object.assign({}, templates);
    default:
      return templates;
  }
}
