import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./UserModal.module.scss";
import ReactSelect from "react-select";
import { useAppDispatch, useAppSelector } from "src/store/store";
import SelectTeams from "src/components/SelectTeams";
import {
  addUser,
  editUser,
} from "src/store/slices/settings/user/userSettings.slice";
import { Spinner } from "react-bootstrap";
import { validateEmail } from "src/utils/utils";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import SettingToast from "src/routes/Setting/children/SettingToast/SettingToast";
import IntlTelInput, { IntlTelInputParams } from "src/components/IntlTelInput/IntlTelInput";
import { Phone } from "src/store/slices/editCustomerDetails/editCustomerDetails.declarations";


function UserModal({
  OnHide,
  editUserId,
}: {
  OnHide: () => void;
  editUserId: null | number | string;
}) {
  const dispatch = useAppDispatch();
  function handleOnHide() {
    OnHide();
  }
  const { addUserAjaxStatus, userRoles, users } = useAppSelector(
    (state) => state.userSettings
  );
  const currentUser = useAppSelector(
    (state) => state.globals.currentUserData
  );

  const { activeTeam } = useAppSelector((state) => state.teamSettings);

  const [selectedRole, setSelectedRole] = useState(null as any);
  const [selectedTeams, setSelectedTeams] = useState([] as any);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState<Phone | null>(null);
  const [phoneError, setPhoneError] = useState(false);
  const currentState = useRef({ addUserAjaxStatus, userRoles, users });

  useEffect(() => {
    currentState.current = { addUserAjaxStatus, userRoles, users };
  }, [addUserAjaxStatus, userRoles, users]);

  // const handelSubmit = () => {
  //   if (
  //     currentState.current.addUserAjaxStatus !== "pending" &&
  //     selectedRole !== null &&
  //     selectedTeams.length !== 0 &&
  //     validateEmail(email)
  //   ) {
  //     if (editUserId === null ) {
  //       dispatch(
  //         addUser({
  //           callback: () => {
  //             pushTheToast({
  //               type: "success",
  //               text: SettingToast,
  //               customCompProps: { text: "User added successfully !!" },
  //               isTextComponent: true,
  //               position: "top-center",
  //             });
  //             handleOnHide();
  //           },
  //           email,
  //           phone,
  //           userRole: selectedRole.value.id,
  //           teams: selectedTeams.map((data: any) => data.teamId),

  //         })
  //       );
  //     } else {
  //       dispatch(
  //         editUser({
  //           callback: () => {
  //             pushTheToast({
  //               type: "success",
  //               text: SettingToast,
  //               customCompProps: { text: "User updated successfully !!" },
  //               isTextComponent: true,
  //               position: "top-center",
  //             });
  //             handleOnHide();
  //           },
  //           userId: parseInt(editUserId + ""),
  //           userRole: selectedRole.value.id,
  //           phone,
  //           teamIds: selectedTeams.map((data: any) => data.teamId),

  //         })
  //       );
  //     }
  //   } else {
  //     pushTheToast({
  //       type: "warning",
  //       text: "All fields required!",
  //       position: "top-right",
  //     });
  //   }
  // };
  const handelSubmit = () => {
    let isValid = true;

    // Check if action is still pending
    if (currentState.current.addUserAjaxStatus === "pending") {
      isValid = false;
      pushTheToast({
        type: "warning",
        text: "Action is still pending. Please wait.",
        position: "top-right",
      });
    }

    // Validate selected role for both add and update
    if (!selectedRole) {
      isValid = false;
      pushTheToast({
        type: "warning",
        text: "User role is required!",
        position: "top-right",
      });
    }

    // Validation for adding a user (editUserId === null)
    if (editUserId === null) {
      // Email is required for adding a user
      if (!validateEmail(email)) {
        isValid = false;
        pushTheToast({
          type: "warning",
          text: "A valid email is required!",
          position: "top-right",
        });
      }

      // Teams is required for adding a user
      if (selectedTeams.length === 0) {
        isValid = false;
        pushTheToast({
          type: "warning",
          text: "At least one team must be selected!",
          position: "top-right",
        });
      }
    }

    // If all validations pass, proceed to dispatch the action
    if (isValid) {
      const userPayload: any = {
        callback: () => {
          pushTheToast({
            type: "success",
            text: SettingToast,
            customCompProps: { text: editUserId === null ? "User added successfully !!" : "User updated successfully !!" },
            isTextComponent: true,
            position: "top-center",
          });
          handleOnHide();
        },
        userRole: selectedRole.value.id,
      };

      // Conditionally add email and teams for adding a user
      if (editUserId === null) {
        userPayload.email = email;
        userPayload.teams = selectedTeams.map((data: any) => data.teamId);
      } else {
        userPayload.userId = parseInt(editUserId + "");
        if (selectedTeams.length > 0) {
          userPayload.teamIds = selectedTeams.map((data: any) => data.teamId);
        }
      }

      // Conditionally add phone if it's not null or undefined
      if (phone !== null && phone !== undefined) {
        userPayload.phone = phone;
      }

      if (editUserId === null) {
        dispatch(addUser(userPayload));
      } else {
        dispatch(editUser(userPayload));
      }
    }
  };



  useEffect(() => {
    if (editUserId === null) {
      setSelectedRole(null);
      /* Modified to use this component in add user of teams page*/
      if (activeTeam.teamId != 0) {
        setSelectedTeams([activeTeam]);
      } else {
        setSelectedTeams([]);
      }
      setEmail("");
    } else {
      const userRole = currentState.current.users[editUserId].userRole;
      if (userRole !== null) {
        setSelectedRole({
          label: currentState.current.userRoles.roles[userRole].role,
          value: currentState.current.userRoles.roles[userRole],
        });
      }
      setSelectedTeams(
        currentState.current.users[editUserId].teams === null
          ? []
          : currentState.current.users[editUserId].teams
      );
      setEmail(currentState.current.users[editUserId].email);
      // console.log(currentState.current.users[editUserId]);
      const phoneValue = currentState.current.users[editUserId].phone;
      setPhone(
        typeof phoneValue === "string"
          ? { countryCode: "", phoneNumber: phoneValue }
          : phoneValue && "phoneNumber" in phoneValue
            ? phoneValue as Phone
            : null
      );

    }
  }, [editUserId]);

  return (
    <div className={`${styles.main}`}>
      <div
        className={`${styles.headerBox} d-flex  justify-content-between align-items-center mb-2`}
      >
        <div
          className={` d-flex justify-content-between align-items-center w-100 px-4`}
        >
          <h5 className={`mb-0 ${styles.userHead}`}>
            {editUserId !== null ? "Update" : "Add"} a User
          </h5>

          <span
            onClick={handleOnHide}
            className={`cursor-pointer ${styles.closeButton} ms-1`}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-12">
            <div className="m-4 ">
              <label className={`${styles.formLabel} form-label`}>
                Email Address
              </label>
              <div className="input-box d-flex align-items-center me-2">
                <input
                  data-lpignore="true"
                  type="email"
                  placeholder="Enter email"
                  className="form-control"
                  value={email}
                  disabled={editUserId !== null}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="m-4">
                <label
                  htmlFor="phone"
                  className={`${styles.formLabel} form-label`}
                >
                  Contact Number
                </label>
                <IntlTelInput
                  phoneNumber={phone?.phoneNumber ?? ""}
                  countryCode={phone?.countryCode ?? ""}
                  disabled={currentUser?.userRole !== "31"}
                  showError={true}
                  className={`${styles.form_control}`}
                  initialValidate={true}
                  onChange={(params: IntlTelInputParams) => {
                    setPhone({
                      phoneNumber: params.phoneNumber,
                      countryCode: params.countryData.dialCode,
                    });
                    setPhoneError(!params.isValidNumber);
                  }}
                >
                  {(params) => {
                    setPhoneError(!params.isValidNumber);

                    return (
                      <small className={`${styles.errorMsg}`}>
                        {params.errorMessage
                          ? params.errorMessage
                          : ""}
                      </small>
                    );
                  }}
                </IntlTelInput>
                {/* <input
                        data-lpignore="true"
                        type="tel"
                        className={`${styles.form_control} form-control border `}
                        placeholder="Phone"
                        readOnly={!editData}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      /> */}
              </div>
            </div>
            <div className="m-4 ">
              <div className="wrap-select-arrow">
                <label
                  htmlFor="exampleSelect1"
                  className={`${styles.formLabel} form-label`}
                >
                  Choose Teams
                </label>
                <div className="me-2">
                  <SelectTeams
                    value={selectedTeams}
                    setValue={setSelectedTeams}
                    canBDisabled={editUserId === null && activeTeam.teamId != 0} // modified to use for Create user from team page
                  />
                </div>
              </div>
            </div>

            <div className="mx-4 mt-4">
              <div className="wrap-select-arrow">
                <label
                  htmlFor="exampleSelect1"
                  className={`${styles.formLabel} form-label`}
                >
                  Role
                </label>
                <div className="position-relative me-2">
                  <ReactSelect
                    options={Object.values(userRoles.roles).map((data) => ({
                      label: data.role,
                      value: data,
                    }))}
                    styles={customStyles}
                    value={selectedRole}
                    onChange={(value) => {
                      setSelectedRole(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="add-user d-flex flex-row-reverse mt-2 mb-1 me-4 p-2 ">
          <button
            type="submit"
            className={`d-flex justify-content-center align-items-center ${styles.pink_btn_submit}`}
            onClick={handelSubmit}
          >
            {addUserAjaxStatus === "pending" && (
              <Spinner
                className="my-auto mx-1"
                animation="border"
                color="white"
                size="sm"
              />
            )}
            <span className="my-auto mx-1">
              {editUserId !== null ? "Update" : "Add"} User
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  option: (provided: any, _: any) => ({
    ...provided,
    textAlign: "left",
    font: "normal normal 500 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#000000",
    "&:hover": {
      background: "#F9F9F9 0% 0% no-repeat padding-box",
      borderRadius: "4px",
    },
    "&:active": {
      background: "#EEEEEE 0% 0% no-repeat padding-box",
    },
  }),
  control: (provided: any, _: any) => ({
    ...provided,
    margin: "0px",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
  }),
};

export default UserModal;
