import { useState, useMemo, useRef, useEffect } from "react";
import ParseHTML from "src/components/ParseHTML";
import styles from "./CampaignEmail.module.scss";
import { replaceKeyWithVariableName } from "src/utils/utils";
import imgUpload from "src/assets/images/imgUpload.png";
import CampaignTemplate1 from "./Children/CampaignTemplate1/CampaignTemplate1";
import CampaignTemplate2 from "./Children/CampaignTemplate2/CampaignTemplate2";
import Loader from "src/components/Loader";
import EmailEditor, { EditorRef } from "react-email-editor";

const colors = {
  backgroundColor: "#FBFAFF",
  textColor: "#3F3F44",
  headingColor: "#000",
  borderColor: "#DBD9E5",
  featureBackgroundColor: "#fefefe",
  containerBackgroundColor: "#f4f4f4",
  footerBackgroundColor: "#f4f4f4",
};

const CampaignEmail = ({
  companyName,
  data,
  variableList,
  view,
  status,
}: {
  companyName: string;
  data: any;
  variableList?: any;
  view: "Desktop" | "Mobile";
  status?: "loading" | "completed";
}) => {
  const emailEditorRef = useRef<EditorRef>(null);

  // State to manage multiple text segments
  const [textSegments, setTextSegments] = useState({
    salutation: data?.salutation || "",
    introduction: data?.introduction || "",
    body: data?.body || "",
  });

  const logoUrl =
    data?.logoUrl && data?.logoUrl?.trim() ? data?.logoUrl : imgUpload;

  // Effect to update EmailEditor whenever textSegments changes
  useEffect(() => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor?.saveDesign((currentDesign: any) => {
        const updatedDesign = { ...currentDesign };

        // Iterate through the text segments to update them in the editor design
        updatedDesign?.body.rows.forEach((row: any) => {
          row.columns.forEach((column: any) => {
            column.contents.forEach((content: any) => {
              // Map content to the specific text segment fields
              if (content.type === "text") {
                if (content.values.text === "{{salutation}}") {
                  content.values.text = textSegments.salutation;
                } else if (content.values.text === "{{introduction}}") {
                  content.values.text = textSegments.introduction;
                } else if (content.values.text === "{{body}}") {
                  content.values.text = textSegments.body;
                }
              } else if (content.type === "image") {
                content.values.src = logoUrl;
              }
            });
          });
        });

        // Load the updated design back into the editor
        emailEditorRef.current?.editor?.loadDesign(updatedDesign);
      });
    }
  }, [textSegments, logoUrl]);

  // Handler for updating text segments from textarea changes
  const handleTextChange = (field: any, value: any) => {
    setTextSegments((prevSegments) => ({
      ...prevSegments,
      [field]: value,
    }));
  };

  // Effect to sync changes from the EmailEditor to textSegments
  const handleEditorChange = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor?.exportHtml(({ design }) => {
        const newSegments = { ...textSegments };

        design?.body.rows.forEach((row) => {
          row.columns.forEach((column) => {
            column.contents.forEach((content) => {
              if (content.type === "text") {
                if (content.values.text === textSegments.salutation) {
                  newSegments.salutation = content.values.text;
                } else if (content.values.text === textSegments.introduction) {
                  newSegments.introduction = content.values.text;
                } else if (content.values.text === textSegments.body) {
                  newSegments.body = content.values.text;
                }
              }
            });
          });
        });

        setTextSegments(newSegments);
      });
    }
  };

  // Register onLoad and onChange event listeners for EmailEditor
  useEffect(() => {
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.addEventListener(
        "design:loaded",
        handleEditorChange,
      );
      emailEditorRef.current.editor.addEventListener(
        "design:updated",
        handleEditorChange,
      );
    }

    return () => {
      if (emailEditorRef.current?.editor) {
        // emailEditorRef.current.editor.removeEventListener(
        //   "design:loaded",
        //   handleEditorChange,
        // );
        // emailEditorRef.current.editor.removeEventListener(
        //   "design:updated",
        //   handleEditorChange,
        // );
      }
    };
  }, []);

  return (
    <div className={`${styles.deskTopView}`}>
      {status && status === "loading" ? (
        <div className={`${styles.loaderWrap} p-2`}>
          <Loader
            className={"pt-0"}
            imgClassName={"pt-0"}
          />
          <div className={styles.loadingText}>
            Cern is still generating the email for you. We will send you an
            email notification once it is ready.
          </div>
        </div>
      ) : (
        <div className={`${styles.emailWrap}`}>
          <EmailEditor
            ref={emailEditorRef}
            style={{ minHeight: "500px" }}
            onLoad={handleEditorChange} // Initialize design on load
            // onChange={handleEditorChange} // Sync on editor change
          />
        </div>
      )}
    </div>
  );
};

export default CampaignEmail;
