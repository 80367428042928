import { useCallback, useMemo, useState } from "react";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import cautionImg from "src/assets/images/caution.png";
import ActionButton from "src/components/ActionButton";
import { SegmentTypes } from "src/routes/CustomerSegments/hooks/useCustomerSegments";
import { useSegmentView } from "../../hooks/useSegmentView";
import styles from "./MoreCols.module.scss";
import InfiniteScroll, {
  LoadMoreLoader,
} from "src/components/InfiniteScrollBothSide";
import useFetchColumns from "../../hooks/useFetchColumns";
import { SegmentColumnsResponse } from "src/services/CustomerSegments/getSegmentColumns.service";

const ColumnSVG = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.5"
      height="10.559"
      viewBox="0 0 13.5 10.559"
    >
      <path
        id="Icon_material-view-column"
        data-name="Icon material-view-column"
        d="M10.412,17.059h3.676V7.5H10.412ZM6,17.059H9.676V7.5H6ZM14.824,7.5v9.559H18.5V7.5Z"
        transform="translate(-5.5 -7)"
        fill="none"
        stroke={color}
        stroke-width="1"
      />
    </svg>
  );
};

const getHeadingName = (segmentType: SegmentTypes | null): string => {
  switch (segmentType) {
    case "companySegment":
      return `Display Company attributes`;
    case "userSegment":
      return `Display User attributes`;
    default:
      return `Display Table attributes`;
  }
};

/**
 * Renders a dropdown menu with a list of columns.
 *
 * @returns The rendered dropdown menu.
 */
function MoreCols() {
  const { activeSegmentDetails, segmentView, dispatch } = useSegmentView();

  const onPageFetch = useCallback(
    (res: SegmentColumnsResponse) => {
      dispatch("setSegmentView", {
        allColumnKeys: res.allColumnKeys ?? [],
        allColumns: res.allColumns ?? {},
      });
    },
    [dispatch],
  );

  const { fetchNextPage, hasNextPage, columnsStatus } = useFetchColumns(
    activeSegmentDetails.activeSegmentType ?? "",
    activeSegmentDetails.activeSegmentId ?? "",
    onPageFetch,
  );

  const fetchMoreColumns = useCallback(() => {
    if (columnsStatus !== "pending" && hasNextPage) {
      fetchNextPage();
    }
  }, [columnsStatus, fetchNextPage, hasNextPage]);

  const [toggleState, setToggleState] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const heading = useMemo(() => {
    return getHeadingName(activeSegmentDetails.activeSegmentType);
  }, [activeSegmentDetails]);

  return (
    <Dropdown
      show={toggleState}
      onToggle={setToggleState}
      id="moreColsBtnCompany"
    >
      <Dropdown.Toggle
        as="div"
        id="dropdown-custom-components"
        bsPrefix={styles.caret}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <ActionButton className={`me-1`}>
          <span className="me-1">
            <ColumnSVG color={isHover ? "#fff" : "#000"} />
          </span>
          More Columns
          <span>
            <i
              className={`fa-solid fa-caret-${
                toggleState ? "up" : "down"
              } ms-2`}
            />
          </span>
        </ActionButton>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        <InfiniteScroll
          className={`mb-1 ${styles.columnDropdown}`}
          hasMoreBottom={hasNextPage}
          loadMoreFromBottom={fetchMoreColumns}
          infiniteLoader={
            <LoadMoreLoader
              fetchMore={fetchMoreColumns}
              variant="blue"
              status={
                columnsStatus === "fulfilled"
                  ? "fulfilled"
                  : columnsStatus === "pending"
                    ? "pending"
                    : null
              }
            />
          }
        >
          <div className={`ms-2 mt-1 ${styles.moreColsHead}`}>{heading}</div>
          {segmentView.allColumnKeys.map((key, idx) => {
            const col = segmentView.allColumns[key];
            return (
              <div
                key={key}
                className={`ms-2 mb-1 ${idx === 0 ? "mt-1" : ""}`}
              >
                {/* Column Check box */}
                <input
                  data-lpignore="true"
                  type="checkbox"
                  className={`align-middle form-check-input ${styles.checkBox}`}
                  onClick={(e) => {
                    dispatch("setColumnSelect", {
                      key,
                      value: !col.selected,
                    });
                  }}
                  checked={col.required ? true : col.selected ?? false}
                  disabled={col.required}
                  id={`segment_column_${key}_checkbox`}
                />

                {/* Column Name Label */}
                <label
                  htmlFor={`segment_column_${key}_checkbox`}
                  className={`ms-2 user-select-none ${styles.colItems}`}
                >
                  {col.name}
                </label>

                {/* Column Description Overlay */}
                {col.description ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <span className={`${styles.overlayText}`}>
                          {col.description}
                        </span>
                      </Tooltip>
                    }
                    trigger={["hover", "focus"]}
                    placement="top"
                  >
                    <span className="mx-1">
                      <img
                        src={cautionImg}
                        alt="moreInfo"
                        width={12}
                      />
                    </span>
                  </OverlayTrigger>
                ) : null}
              </div>
            );
          })}
        </InfiniteScroll>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MoreCols;
