import { Channel } from "pusher-js";

export interface CampaignEmailStatusUpdatedRes {
  campaignId: number | string;
  emailId: number | string;
  status: string;
}

export type newTicketEventCB = (res: any) => void;

export const campaignEmailStatusUpdatedEvent = (
  channel: Channel,
  callback: newTicketEventCB,
) => {
  channel.bind("campaign_email_status_updated", callback);
};

export const campaignEmailStatusUpdatedEventUnbind = (channel: Channel) => {
  channel.unbind("campaign_email_status_updated");
};
