import {
  ProductSectionSequence,
  SequenceStepSection,
} from "src/services/Campaign/createCampaignV2.service";
import styles from "../SequenceSection.module.scss";
import { validateURLNew } from "src/utils/utils";
import BaseInput from "./BaseInput";

const ProductSection = ({
  section,
  handleSectionsChange,
  handleDelete,
  showErrors,
}: {
  section: ProductSectionSequence;
  handleSectionsChange: (updatedSection: SequenceStepSection) => void;
  handleDelete: () => void;
  showErrors: boolean;
}) => {
  // Function to update the feature name
  const onFeatureNameChange = (name: string) => {
    const updatedSection = {
      ...section,
      name,
    };
    handleSectionsChange(updatedSection);
  };

  // Function to update the feature description
  const onFeatureDescriptionChange = (description: string) => {
    const updatedSection = {
      ...section,
      description,
    };
    handleSectionsChange(updatedSection);
  };

  // Function to update the feature link
  const onFeatureLinkChange = (link: string) => {
    const updatedSection = {
      ...section,
      link,
    };
    handleSectionsChange(updatedSection);
  };

  return (
    <div className={`w-100 d-flex ${styles.introBox}`}>
      <div className="d-flex align-items-center me-2">
        <p className="mb-0 d-flex">
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <span>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </p>
      </div>
      <div className="w-100">
        <div className="mt-2 w-100">
          <div className="d-flex justify-content-between">
            <h5 className={styles.introHead}>Feature/Product suggestion</h5>
            <span
              className={`${styles.deleteText} cursor-pointer`}
              onClick={handleDelete}
            >
              <i className="fa-solid fa-trash"></i>
            </span>
          </div>

          {/* Feature Name Input */}
          <div className="d-flex align-items-center w-100">
            <p className={styles.featureLabel}>Feature Name</p>
            <div className={`ms-3 me-3 ${styles.inputWrapper} w-100`}>
              <BaseInput
                onChange={onFeatureNameChange}
                value={section.name}
                error={
                  showErrors && section.name?.trim().length === 0
                    ? "* Feature name cannot be empty. Please enter a name to proceed."
                    : ""
                }
                placeholder="Add a feature name..."
              />
            </div>
          </div>

          {/* Feature Description Input */}
          <div className="d-flex align-items-center w-100">
            <p className={styles.featureLabel}>Feature Description</p>
            <div className={`ms-3 me-3 ${styles.inputWrapper} w-100`}>
              <BaseInput
                onChange={onFeatureDescriptionChange}
                value={section.description}
                error={
                  showErrors && section.description?.trim().length === 0
                    ? "* Feature description cannot be empty. Please enter a brief description to proceed."
                    : ""
                }
                placeholder="Add a description..."
              />
            </div>
          </div>

          {/* Feature Link Input */}
          <div className="d-flex align-items-center w-100">
            <p className={styles.featureLabel}>Feature Link</p>
            <div className={`ms-3 me-3 ${styles.inputWrapper} w-100`}>
              <BaseInput
                onChange={onFeatureLinkChange}
                value={section.link}
                error={
                  showErrors && validateURLNew(section.link) != null
                    ? "* Feature link is not valid. Please enter a valid feature link to proceed."
                    : ""
                }
                placeholder="Add a link..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
