import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * The type of the channel.
 */
export type IChannel = "email" | "chat";

export interface GetMessagePreviewParams {
  messageId: string;
}
export interface Brand {
  id: string;
  name: string;
  imageUrl?: string | null;
}
export interface GetMessagePreviewResponse {
  html?: string; // Incase of email channel
  senderName: string;
  senderImgURL: null | string;
  brand: Brand;
  messageData?: {
    messageId: number | string;
    messageDateTime: string;
    messageType: string; // "message" | "event"
  };
}

/**
 * Fetches all sent messages based on the given parameters.
 * @param params - The parameters to fetch messages including pagination and filters.
 * @returns The response data containing table headings, all messages, and total message count.
 * @throws Error if the request fails or an unexpected error occurs.
 */
async function getMessagePreview(params: GetMessagePreviewParams) {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/campaign/getAllSentMessages`
    : "/api/campaign/getMessagePreview";

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occurred!");
  }
  return res as GetMessagePreviewResponse;
}
export default getMessagePreview;
