import { useState } from "react";
import styles from "./EditVariant.module.scss"; // Update with your actual stylesheet path
interface EditableVariantProps {
  variantName: string; // The name of the variant to be displayed and edited
  onNameChange: (newName: string) => void; // Callback function to update the variant name
}
const EditVariant = ({ variantName, onNameChange }: EditableVariantProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentName, setCurrentName] = useState(variantName);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: any) => {
    setCurrentName(event.target.value);
  };

  const handleSave = () => {
    if(currentName.trim().length === 0){
      return;
    }
    setIsEditing(false);
    onNameChange(currentName.trim()); // Update the parent component's state
  };

  const variantClass = isEditing ? styles.editingVariant : styles.variant;

  return (
    <div
      className={`${
        isEditing ? styles.editVariantBox : styles.variantBox
      } mb-2 px-2 cursor-pointer`}
    >
      {isEditing ? (
        <input
          type="text"
          value={currentName}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSave();
          }}
          className={`${styles.inputField} ${currentName.trim().length === 0 && styles.dangerBorder}`}
          autoFocus
        />
      ) : (
        <span className={variantClass}>{currentName}</span>
      )}
      <span
        className={`${styles.editVariant}`}
        onClick={isEditing ? handleSave : handleEditClick}
      >
        {isEditing ? "Save" : "Edit name"}
      </span>
    </div>
  );
};

export default EditVariant;
