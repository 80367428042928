import { useRef, useState } from "react";
import styles from "./ReportSideBar.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppSelector } from "src/store/store";
import useReportVisibility from "src/hooks/reports/useReportVisibility";

function ReportSideBar() {
  const [toggleTickets, setToggleTickets] = useState<boolean>(true);
  const [liveChat, setLiveChat] = useState<boolean>(true);
  const [userFlow, setUserFlow] = useState<boolean>(true);
  const [toggleHelpdeskSettings, setToggleHelpdeskSettings] =
    useState<boolean>(true);
  const [automations, setAutomations] = useState<boolean>(true);
  const [showOverlay, setShowOveray] = useState(false);
  const [showSettingsSubSB, setShowSettingsSubSB] = useState<boolean>(true);

  const ui_visibility = useAppSelector(
    (state) => state.globals.currentUserData?.ui_visibility,
  );

  const hiddenFeatures = useAppSelector(
    (state) => state.globals.currentUserData?.hiddenFeatures,
  );

  const reportVisibility = useReportVisibility();

  const handleToggleSubSideBar = () => {
    if (showSettingsSubSB === true) {
      setShowSettingsSubSB(false);
    } else {
      setShowSettingsSubSB(true);
    }
  };

  const getToggleContent = () => {
    return (
      <div
        className={`${styles.subSideBarCloseBtnDiv}`}
        onClick={() => handleToggleSubSideBar()}
      >
        <OverlayTrigger
          show={showOverlay}
          placement="top"
          overlay={
            <Tooltip className={`${styles.toolTipCustom}`}>
              <span className={`${styles.tooltipHead}`}>
                {showSettingsSubSB === true ? "Hide sidebar" : "Show sidebar"}
              </span>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              onMouseOver={() => {
                setShowOveray(true);
              }}
              onMouseOut={() => {
                setShowOveray(false);
              }}
            >
              <span
                className={`${styles.subSideBarCloseBtnToggleArrow} m-auto`}
              >
                {showSettingsSubSB === true ? (
                  <i className="fa-solid fa-chevron-left"></i>
                ) : (
                  <i className="fa-solid fa-chevron-right"></i>
                )}
              </span>
            </div>
          )}
        </OverlayTrigger>
      </div>
    );
  };
  const location = useLocation();
  const isFromCampeign = useRef(location.state?.isFromCampeign ?? false); // Default to false if undefined
  const navigate = useNavigate();

  return (
    <div className={`${styles.subSideToggle}`}>
      {showSettingsSubSB === true && (
        <div className={`${styles.subsideToggleInner}`}>
          <div
            className={` ${styles.sidenav_content}  border shadow-sm overflow-auto`}
          >
            <ul
              className={` ${styles.sidebar_nav} `}
              id={`${styles.sidebar_nav}`}
            >
              <li
                className={` ${styles.nav_item} ${styles.pink_clr} d-flex align-items-center`}
              >
                {isFromCampeign.current ? (
                  <div
                    className={`me-2 ${styles.backbtn} cursor-pointer`}
                    onClick={() => navigate("/campaigns")}
                  >
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </div>
                ) : null}

                <span>Reports</span>
                <i className="bi bi-grid" />
              </li>

              {!hiddenFeatures?.includes("ticket_view") &&
                reportVisibility.tickets &&
                (reportVisibility.currentStatus ||
                  reportVisibility.volumeActivity ||
                  reportVisibility.performance ||
                  reportVisibility.revenue) && (
                  <li className={`${styles["nav_item"]}`}>
                    <div
                      className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                      aria-expanded="false"
                      onClick={(e) => setToggleTickets(!toggleTickets)}
                    >
                      <span className={`w-100 ${styles.toggler}`}>Tickets</span>
                      {toggleTickets !== true ? (
                        <i
                          className={`fa fa-angle-down ${styles.toggleColor}`}
                        />
                      ) : (
                        <i className={`fa fa-angle-up ${styles.toggleColor}`} />
                      )}
                    </div>
                    <Collapse in={toggleTickets}>
                      <ul
                        id="account"
                        className={` ${styles.nav_content} ${styles.collapsed}`}
                        data-bs-parent="#sidebar_nav"
                      >
                        {ui_visibility?.some((item) =>
                          item.startsWith("tickets_currentStatus"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/current-status"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Current Status</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("tickets_volumeActivity"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/volume-activity"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Volume and Activity</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("tickets_performance"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/performance"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Performance</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("tickets_revenue"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/revenue"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Revenue</span>
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )}
              {reportVisibility.liveChat &&
                (reportVisibility.totalChats ||
                  reportVisibility.missed ||
                  reportVisibility.chatAvailability ||
                  reportVisibility.chatSatisfaction) && (
                  <li className={`${styles["nav_item"]}`}>
                    <div
                      className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                      aria-expanded="false"
                      onClick={(e) => setLiveChat(!liveChat)}
                    >
                      <span className={`w-100 ${styles.toggler}`}>
                        Live chat
                      </span>
                      {liveChat !== true ? (
                        <i
                          className={`fa fa-angle-down ${styles.toggleColor}`}
                        />
                      ) : (
                        <i className={`fa fa-angle-up ${styles.toggleColor}`} />
                      )}
                    </div>
                    <Collapse in={liveChat}>
                      <ul
                        id="account"
                        className={` ${styles.nav_content} ${styles.collapsed}`}
                        data-bs-parent="#sidebar_nav"
                      >
                        {ui_visibility?.some((item) =>
                          item.startsWith("liveChat_totalChats"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/total-chats"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff " : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Total chats</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("liveChat_missedChats"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/missed-chats"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Missed Chats</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("liveChat_chatSatisfaction"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/chat-satisfaction"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Chat satisfaction</span>
                            </NavLink>
                          </li>
                        )}
                        {ui_visibility?.some((item) =>
                          item.startsWith("liveChat_chatAvailability"),
                        ) && (
                          <li>
                            <NavLink
                              className={`${styles.a}`}
                              to="/report/chat-availability"
                              style={({ isActive }) => ({
                                color: isActive ? "#fff" : "",
                                background: isActive ? "#ff8499" : "",
                              })}
                            >
                              <span>Chat Availability</span>
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </li>
                )}

              {ui_visibility?.includes("campaign_view") && (
                <li className={`${styles["nav_item"]}`}>
                  <div
                    className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3`}
                    aria-expanded="false"
                    onClick={() => setUserFlow(!userFlow)}
                  >
                    <span className={`w-100 ${styles.toggler}`}>User Flow</span>
                    {userFlow !== true ? (
                      <i className={`fa fa-angle-down ${styles.toggleColor}`} />
                    ) : (
                      <i className={`fa fa-angle-up ${styles.toggleColor}`} />
                    )}
                  </div>
                  <Collapse in={userFlow}>
                    <ul
                      id="account"
                      className={` ${styles.nav_content} ${styles.collapsed}`}
                      data-bs-parent="#sidebar_nav"
                    >
                      <li>
                        <NavLink
                          className={`${styles.a}`}
                          to="/report/campaign"
                          style={({ isActive }) => ({
                            color: isActive ? "#fff " : "",
                            background: isActive ? "#ff8499" : "",
                          })}
                        >
                          <span>Segment</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={`${styles.a}`}
                          to="/report/sunburst"
                          style={({ isActive }) => ({
                            color: isActive ? "#fff " : "",
                            background: isActive ? "#ff8499" : "",
                          })}
                        >
                          <span>Sunburst</span>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              )}

              <li className={`${styles.nav_item} d-none`}>
                <div
                  className={` ${styles.nav_link} cursor-pointer ${styles.collapsed} ps-3 d-flex `}
                  aria-expanded="false"
                  onClick={(e) => setAutomations(!automations)}
                >
                  <span className={`w-100 ${styles.toggler}`}>Automations</span>
                  {automations === true ? (
                    <i className={`fa fa-angle-down ${styles.toggleColor}`} />
                  ) : (
                    <i className={`fa fa-angle-up ${styles.toggleColor}`} />
                  )}
                </div>
              </li>
            </ul>
          </div>
          {showSettingsSubSB === true && getToggleContent()}
        </div>
      )}
      {showSettingsSubSB === false && getToggleContent()}
    </div>
  );
}
export default ReportSideBar;
