import { axiosJSON } from "src/globals/axiosEndPoints";
import { ECampaignStatus } from "./getCampaignById";

export interface GetAllDripCampaignParams {
  start: number;
  limit: number;
}

export interface DripCampaignData {
  id: string | number;
  name: string;
  status: ECampaignStatus;
  emailSendCount: number;
}

export interface DripCampaign {
  segmentId: string | number;
  segmentName: string;
  campaignTotalCount: number;
  campaignsData: DripCampaignData[];
}

export interface GetAllDripCampaignResponse {
  data: DripCampaign[];
  hasMore: boolean;
}

async function getAllDripCampaignsService(params: GetAllDripCampaignParams) {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/getUserSegmentsWithCampaignsData",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllDripCampaignResponse = {
    data: res.data,
    hasMore: true,
  };

  if (ret.data.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
}

export default getAllDripCampaignsService;
