import { Channel } from "pusher-js";

export interface CernTypingResponse {
  data: {
    status: string;
    parentMessageId: number;
  };
}

export type newTicketEventCB = (res: CernTypingResponse) => void;

export const getCernAiTypingEvent = (
  channel: Channel,
  callback: newTicketEventCB,
) => {
  channel.bind("cern_typing_status_update", callback, {
    pauseOnInActiveTab: true,
  });
};

export const getCernAiTypingEventUnbind = (channel: Channel) => {
  channel.unbind("cern_typing_status_update");
};
