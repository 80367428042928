import { useMemo, useState } from "react";
import { ESegmentStatus } from "src/services/Campaign/updateSegment";
import styles from "./SegmentTab.module.scss";
import TableData from "./TableData/TableData";
import useGetAllSegment from "./TableData/useGetAllSegment";
import Pagination from "src/routes/TicketList/children/Pagination/Pagination";
const LIMIT = 10;

const SegmentTab = ({ onBack }: { onBack: (id?: string | number) => void }) => {
  const [activeTab, setActiveTab] = useState<ESegmentStatus>(
    ESegmentStatus.Approved,
  );
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return LIMIT * currentPage - LIMIT;
  }, [currentPage]);

  const payload = {
    start: start,
    limit: LIMIT,
    filters: {
      status: activeTab,
    },
  };

  const { allSegments, isError, isLoading, totalPages, onStatusChange } =
    useGetAllSegment(payload);

  // Function to handle tab change
  const handleTabClick = (tab: ESegmentStatus) => {
    setCurrentPage(1);
    setActiveTab(tab);
  };

  return (
    <div className={`${styles.tabContainer}`}>
      <div className={`px-3 pt-3 ${styles.tabs} px-4`}>
        <button
          className={`${styles.tab} me-5 ${
            activeTab === ESegmentStatus.Approved ? styles.activeTab : ""
          }`}
          onClick={() => handleTabClick(ESegmentStatus.Approved)}
        >
          Approved segments
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === ESegmentStatus.Draft ? styles.activeTab : ""
          }`}
          onClick={() => handleTabClick(ESegmentStatus.Draft)}
        >
          Drafted segments
        </button>
      </div>
      <div className="tab-content px-4 py-3">
        <TableData
          allSegments={allSegments ?? []}
          isError={isError}
          isLoading={isLoading}
          onStatusChange={onStatusChange}
          onBack={onBack}
        />
        {totalPages > 1 && (
          <div className="mt-3 pb-5 mb-2">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SegmentTab;
