import { useInfiniteQuery } from "@tanstack/react-query";
import { getUserSegmentPage } from "./getAlluserSegment.service";

export const useUserSegment = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery(
      ["userSegments"],
      ({ pageParam = 0 }) => getUserSegmentPage({ pageParam }),
      {
        getNextPageParam: (lastPage) =>
          lastPage.hasNextPage ? lastPage.nextPage : undefined,
      },
    );

  const options = data?.pages.flatMap((page) => page.data) ?? [];
  return {
    options,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  };
};
