import { useCallback, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import useGetAutomationData from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";
import { useReturnPreviewState } from "src/features/ReturnAutoWorkFlow/hooks/useReturnPreview";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  toggleAntiRefundMethodStep13,
  updateArsResponse,
  validateChanges,
} from "../../../../store/slices/AntiRefundStrategiesOrdersSlice/antiRefundStrategiesOrders.slice";
import {
  AntiRefundStrategiesReason,
  RefundScenario,
  toggleAntiRefundMethod,
} from "../../../../store/slices/AntiRefundStrategiesSlice/antiRefundStrategies.slice";
import SpecificElementModal from "../AddStrategy/AddStrategy";
import AntiRefundMethodModal from "../RefundMethod/RefundMethod";
import styles from "./Scenario.module.scss";
import ScenarioFollowUpQuestion from "./ScenarioFollowUpQuestion/ScenarioFollowUpQuestion";
import ScenarioItemsAndTags from "./ScenarioItemsAndTags/ScenarioItemsAndTags";
import ScenarioReducer, {
  ScenarioDispatchContext,
  ScenarioStateContext,
} from "./ScenarioReducer";

interface Props {
  scenario: RefundScenario;
  index: number;
  reasonData: AntiRefundStrategiesReason;
  question: string;
}

function Scenario({ scenario, index, reasonData, question }: Props) {
  const { id, reasonName, scenarios } = reasonData;
  const dispatch = useAppDispatch();

  const [showSpecificEleModal, setShowSpecificEleModal] = useState(false);
  const [showAntiRefundMethodModal, setShowAntiRefundMethodModal] =
    useState(false);

  // Function used to show the Select specific items and orders modal
  const showSpecificEleModalBtn = useCallback(() => {
    setShowSpecificEleModal(true);
  }, []);

  // Function used to hide the Select specific items and orders modal
  const hideSpecificEleModalBtn = useCallback(() => {
    setShowSpecificEleModal(false);
  }, []);

  // Function used to hide the Configure anti- refund strategies modal
  const onHideAntiRefundMethodModal = useCallback(() => {
    setShowAntiRefundMethodModal(false);
  }, []);

  // Function used to show the Configure anti- refund strategies modal
  const onShowAntiRefundMethodModal = useCallback(() => {
    setShowAntiRefundMethodModal(true);
  }, []);

  const { clearModalData } = useReturnPreviewState();

  const { state, dispatch: reducerDispatch } = ScenarioReducer(question);
  // Get the selected 'orderTypeId' from the Redux store
  const orderTypeId = useAppSelector(
    (state) =>
      state.antiRefundStrategiesOrders.antiRefundOrderTypesData
        .selectedOrderTypeId,
  );

  const { showPreview } = useReturnPreviewState();

  const { getAntiRefundMultipleOrders, getAntiRefundOrders } =
    useGetAutomationData();

  const { returnAutoWorkFlow, dispatch: modalDispatch } =
    useReturnAutoWorkFlow();

  const activeModal = useMemo(() => {
    return returnAutoWorkFlow?.activeModalConfigDetails?.configureKey ?? null;
  }, [returnAutoWorkFlow?.activeModalConfigDetails]);

  // Will trigger when anti refund modal opens and generate orders based on the tabs
  useMemo(() => {
    if (
      activeModal === "antiRefundStrategies" ||
      activeModal === "antiRefundStrategiesOrders"
    ) {
      if (question === "question12") {
        getAntiRefundMultipleOrders();
      } else if (question === "question11") {
        getAntiRefundOrders();
      }
    }
  }, [activeModal, showPreview, question]);

  return (
    <div>
      <ScenarioStateContext.Provider value={state}>
        <ScenarioDispatchContext.Provider value={reducerDispatch}>
          <div className={`mt-1 ${styles.refundBox}`}>
            <div className={`ps-4 py-4 ${styles.innerBox}`}>
              {scenarios && scenarios.length > 1 ? (
                <p className={`mb-0 ${styles.innerHead}`}>
                  SCENARIO {index + 1}
                </p>
              ) : null}

              {/* Items */}
              {(scenario.items && scenario.items.length > 0) ||
              (scenario.tags && scenario.tags.length > 0) ? (
                <ScenarioItemsAndTags
                  scenario={scenario}
                  showSpecificEleModalBtn={showSpecificEleModalBtn}
                />
              ) : null}

              {/* Follow Up Questions */}
              {scenario.followUpQuestions.length > 0 && (
                <ScenarioFollowUpQuestion
                  scenario={scenario}
                  showSpecificEleModalBtn={showSpecificEleModalBtn}
                />
              )}

              {scenario.items.length === 0 &&
                scenario.followUpQuestions.length === 0 && (
                  <>
                    <p className={`mb-0 ${styles.innerHead2}`}>
                      {index !== 0
                        ? "Remaining items and follow-up questions"
                        : "For all items and questions"}
                    </p>
                    <span
                      className={`pb-2 ${styles.selectItem}`}
                      onClick={showSpecificEleModalBtn}
                    >
                      Select specific items and question
                    </span>
                  </>
                )}
              <div>
                <Form.Check
                  type="switch"
                  id={`${scenario.id}`}
                  label={
                    scenario.enabled
                      ? "Disable anti-refund methods"
                      : "Enable anti-refund methods"
                  }
                  className={`mb-0 mt-1 ${styles.formCheck}`}
                  role="button"
                  checked={scenario.enabled}
                  onChange={() => {
                    if (question === "question11") {
                      dispatch(
                        toggleAntiRefundMethod({
                          enabled: !scenario.enabled,
                          id,
                          scenarioId: scenario.id,
                        }),
                      );
                    }
                    if (question === "question12") {
                      dispatch(
                        toggleAntiRefundMethodStep13({
                          orderTypeId: orderTypeId ?? "",
                          enabled: !scenario.enabled,
                          id,
                          scenarioId: scenario.id,
                        }),
                      );
                      dispatch(validateChanges());
                    }
                  }}
                />
              </div>

              {scenario.additionalInformation?.enabled ||
              scenario.giveOffer?.enabled ||
              scenario.offerExchange?.enabled ? (
                <button
                  className={`mt-2 px-2 ${styles.addBtn} ${
                    scenario.enabled ? "" : "d-none"
                  }`}
                  onClick={() => {
                    onShowAntiRefundMethodModal();
                    /**
                     * Setting active submodal data in the context
                     */
                    modalDispatch("setState", {
                      activeSubModalConfigDetails: {
                        name: "arsStages",
                        reasonId: id.toString(),
                      },
                    });
                  }}
                >
                  <span className={`${styles.checkLogo} p-1 mx-1`}>
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <span>
                    {
                      [
                        scenario.additionalInformation?.enabled,
                        scenario.giveOffer?.enabled,
                        scenario.offerExchange?.enabled,
                      ].filter(Boolean).length
                    }{" "}
                    anti - refund methods applied
                  </span>
                </button>
              ) : (
                <button
                  className={`mt-2 ${styles.addBtn} ${
                    scenario.enabled ? "" : "d-none"
                  }`}
                  onClick={() => {
                    onShowAntiRefundMethodModal();
                    /**
                     * Setting active submodal data in the context
                     */
                    modalDispatch("setState", {
                      activeSubModalConfigDetails: {
                        name: "arsStages",
                        reasonId: id.toString(),
                      },
                    });
                  }}
                >
                  + Add anti-refund strategies
                </button>
              )}
            </div>
            <Modal
              backdropClassName={`${styles.modalBack}`}
              show={showSpecificEleModal}
              onHide={hideSpecificEleModalBtn}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              // centered={true}
              className={`${styles.backDrop}`}
            >
              <SpecificElementModal
                onHide={hideSpecificEleModalBtn}
                refundReasonId={id}
                returnReason={reasonName}
                scenario={scenario}
                key={scenario.id}
                question={question}
              />
            </Modal>
            <Modal
              backdropClassName={`${styles.modalBack}`}
              show={showAntiRefundMethodModal}
              onHide={() => {
                onHideAntiRefundMethodModal();
                // To clear orders in return preview context
                clearModalData({
                  activeModal:
                    question === "question11"
                      ? "antiRefundStrategies"
                      : "antiRefundStrategiesOrders",
                });
                dispatch(updateArsResponse({ arsResponse: null }));
                /**
                 * Setting active submodal data in the context
                 */
                modalDispatch("setState", {
                  activeSubModalConfigDetails: null,
                });
              }}
              dialogClassName={`${styles.modalDialog}`}
              contentClassName={`${styles.modalContent}`}
              // centered={true}
              className={`${styles.backDrop}`}
            >
              <AntiRefundMethodModal
                onHide={() => {
                  onHideAntiRefundMethodModal();
                  // To clear orders in return preview context
                  clearModalData({
                    activeModal:
                      question === "question11"
                        ? "antiRefundStrategies"
                        : "antiRefundStrategiesOrders",
                  });
                  dispatch(updateArsResponse({ arsResponse: null }));
                  /**
                   * Setting active submodal data in the context
                   */
                  modalDispatch("setState", {
                    activeSubModalConfigDetails: null,
                  });
                }}
                reasonId={id}
                returnReason={reasonName}
                scenario={scenario}
                key={scenario.id}
                question={question}
              />
            </Modal>
          </div>
        </ScenarioDispatchContext.Provider>
      </ScenarioStateContext.Provider>
    </div>
  );
}

export default Scenario;
