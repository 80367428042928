import { axiosJSON } from "src/globals/axiosEndPoints";
import { TimelineData } from "src/store/slices/shopifySidebar/shopifySidebar.slice";
import { ECampaignStatus } from "../Campaign/getCampaignById";
import { ESegmentStatus } from "../Campaign/updateSegment";

export interface GetSegmentCampaignParams {
  /** In default case Segment Id is null */
  segmentId: string | number | null;
}

export interface ISegmentCampaign {
  segmentId: string | number;
  segmentName: string;
  timelineData: TimelineData;
  segmentStatus: ESegmentStatus;
  campaignData: {
    id: number;
    name: string;
    status: ECampaignStatus;
  };
}

async function getSegmentCampaignService(
  params: GetSegmentCampaignParams,
): Promise<ISegmentCampaign> {
  const { data: res } = await axiosJSON.post(
    `/api/reports/segment/getSegmentCampaign`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res.data as ISegmentCampaign;
}

export default getSegmentCampaignService;
