// Check full tutorial: https://dev.to/andrewchmr/react-d3-sunburst-chart-3cpd

import { useEffect, useRef } from "react";
import chart from "./chart";

export interface SunBurstZoomableData {
  /**
   * Key - `name` should be composed of both `name` and `value` in bracked Eg: Number Of Customers(248).
   */
  name: string;
  /**
   * Key - `value` should be number bracked Eg: 248.
   */
  value?: number;
  children?: Array<SunBurstZoomableData>;
}

const SunBurstZoomable = ({ data }: { data: SunBurstZoomableData }) => {
  const svgRef = useRef(null as any);

  useEffect(() => {
    const svg = chart(data);
    svgRef.current.innerHTML = "";
    svgRef.current.appendChild(svg);
  }, [data]);

  return <div ref={svgRef}></div>;
};

export default SunBurstZoomable;
