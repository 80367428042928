import { axiosJSON } from "src/globals/axiosEndPoints";
import { JSONTemplate } from "state/types/types";

export interface GetEmailTemplateVariantParams {
  categoryId: string | number;
  templateId: string | number;
  variantId: string | number;
}

export interface IEmailTemplateVariant {
  categoryId: string | number;
  variantId: string | number;
  templateId: string | number;
  variantName: string;
  categoryName?: string;
  templateName?: string;
  isDefault: boolean;
  fileName?: string;
  /** HTML Format of the Email design */
  html: string;
  /** React Email Editor design json */
  design: JSONTemplate;
  /** Heading of the template */
  heading?: string;
  /** Logo Url of the template */
  logoUrl?: string | null;
  /** Logo Url of the template */
  iconUrl?: string | null;
}

const getEmailTemplateVariantService = async (
  params: GetEmailTemplateVariantParams,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/campaign/templates/getVarient`,
    params,
  );

  if (res.error || res.err) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  return res.data as IEmailTemplateVariant;
};

export default getEmailTemplateVariantService;
