import React from "react";
import Select, {
  ActionMeta,
  components,
  MultiValue,
  OptionProps,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from "react-select";
import styles from "./ChannelSelect.module.scss";
import { UserSelectBrandOption } from "../../PrimaryDetails/Children/UserSelect/UserSelect";

interface UserSelectProps {
  options: UserSelectBrandOption[];
  selectedOption: UserSelectBrandOption | null;
  handleChange: (selected: UserSelectBrandOption) => void;
  placeholder?: string;
}

// Custom option component
const CustomOption: React.FC<OptionProps<UserSelectBrandOption>> = (props) => (
  <components.Option {...props}>
    <div className={`d-flex align-items-center cursor-pointer `}>
      <label
        className={`form-check-label ps-2 cursor-pointer ${styles.brandName}`}
        htmlFor={`checkbox-${props.data.value}`}
      >
        {props.label}
      </label>
    </div>
  </components.Option>
);

// Custom single value component
const CustomSingleValue: React.FC<SingleValueProps<UserSelectBrandOption>> = (
  props,
) => (
  <components.SingleValue {...props}>
    <div className={`d-flex align-items-center`}>
      <span className={`ps-1 ${styles.selectBrand}`}>{props.data.label}</span>
    </div>
  </components.SingleValue>
);
const CustomDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <span className={`${styles.indicator}`}>
      <i
        className={`fa-solid ${
          props.selectProps.menuIsOpen ? "fa-caret-up" : "fa-caret-down"
        }`}
      ></i>
    </span>
  </components.DropdownIndicator>
);
// Custom styles for the Select component
const customStyles: StylesConfig<UserSelectBrandOption, true> = {
  control: (provided, state) => ({
    ...provided,
    background: "#E2E2E2",
    border: "none",
    width: "160px",
    borderRadius: "3px",
    minHeight: "31x",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
    font: "normal normal normal 11px/17px Poppins",
    color: "#000000",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "31px",
    padding: "0px !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#0B68BB" : "#fff",
    color: state.isFocused ? "#fff" : "#000",
    padding: "0 10px",
    minHeight: "31px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }),
  menuList: (provided) => ({
    ...provided,
    margin: "0px 10px",
    cursor: "pointer",
    fontSize: "11px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 3px 20px #00000029",
    borderRadius: "5px",
    padding: "8px 0",
  }),
};

// UserSelect component with dynamic options and placeholder
const ChannelSelect: React.FC<UserSelectProps> = ({
  options,
  placeholder = "Select an option...",
  selectedOption,
  handleChange,
}) => {
  const handleChannelChange = (
    newValue:
      | SingleValue<UserSelectBrandOption>
      | MultiValue<UserSelectBrandOption>,
    actionMeta: ActionMeta<UserSelectBrandOption>,
  ) => {
    // Ensure `newValue` is a single option and not an array since `isMulti` is false
    if (newValue && !Array.isArray(newValue)) {
      // Type assertion to `UserSelectBrandOption`
      handleChange(newValue as UserSelectBrandOption);
    }
  };

  return (
    <Select
      className="w-100 cursor-pointer"
      classNamePrefix="dropdown"
      options={options}
      placeholder={placeholder}
      isSearchable={false}
      menuPortalTarget={document.body}
      styles={customStyles}
      isMulti={false}
      value={selectedOption}
      menuPosition="fixed"
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        DropdownIndicator: CustomDropdownIndicator,
        IndicatorSeparator: null,
      }}
      onChange={handleChannelChange}
    />
  );
};

export default ChannelSelect;
