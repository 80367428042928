import { IGetAllFeaturedArticle } from "src/services/LiveChat/Settings/knowledgeBased/featuredArticles/getAllFeaturedArticles";
import { IKnowledgeBasedSetting } from "./knowledgeBasedSetting.slice";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";

const fetchKnowledgeBaseDataFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: { payload: any }
) => {
  state = {
    ...state,
    knowledgeBasedData: payload.knowledgeBased,
    knowledgeBasedDataAjaxStatus: "fulfilled",
  };

  return state;
};

const updateKnowledgeBaseDataFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: { payload: any }
) => {
  state.updateKnowledgeBasedDataAjaxStatus = "fulfilled";
};

const fetchAllFeaturedArticlesFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  state.featuredArticlesIds = Array.from(
    new Set([...state.featuredArticlesIds, ...payload.featuredArticleIds])
  );
  state.featuredArticles = {
    ...state.featuredArticles,
    ...payload.featuredArticles,
  };

  state.featuredArticleMeta = payload.metaData;
  state.fetchFeaturedArticlesAjaxStatus = "fulfilled";

  return state;
};

const addFeaturedArticlesFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  state.addFeaturedArticlesAjaxStatus = "fulfilled";

  return state;
};
const updateFeaturedArticlesFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  state.updateFeaturedArticlesAjaxStatus = "fulfilled";

  return state;
};

const fetchAllArticleCategoriesFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  state.categoryIdList = Array.from(
    new Set([...state.categoryIdList, ...payload.categoryIds])
  );
  state.categoryData = {
    ...state.categoryData,
    ...payload.categories,
  };

  state.categoryMetaData = payload.metaData;
  state.fetchCategoryAjaxStatus = "fulfilled";

  return state;
};

const fetchAllArticlesFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  const categoryId = payload.categoryId;

  if (categoryId) {
    if (state.categoryIdList.includes(categoryId)) {
      const category = state.categoryData[categoryId];

      if (category) {
        state.categoryData[categoryId].articles = {
          ...state.categoryData[categoryId].articles,
          ...payload.articles,
        };
        state.categoryData[categoryId].articlesIds = Array.from(
          new Set([
            ...state.categoryData[categoryId].articlesIds,
            ...payload.articlesIds,
          ])
        );
        state.categoryData[categoryId].metaData = payload.metaData;
      }
    }
  }

  return state;
};

const fetchDuplicateArticleFulfilled = (
  state: IKnowledgeBasedSetting,
  { payload }: any
) => {
  pushTheToast({
    type: "success",
    text: `duplicated "${payload.articleName}" Article`,
    position: "top-right",
  });
  state.fetchDuplicateArticleAjaxStatus = "fulfilled";
  state.categoryData[payload.categoryId].articles[payload.articleId] = {
    articleId: payload.articleId,
    articleName: payload.articleName,
    articleStatus: payload.articleStatus as "draft" | "public",
    createdAt: new Date(payload.createdAt),
    createdAtGmt: new Date(payload.createdAtGmt),
    integrationId: payload.integrationId,
    generatedBy: payload?.generatedBy
  };
  state.categoryData[payload.categoryId].articlesIds = [
    ...state.categoryData[payload.categoryId].articlesIds,
    payload.articleId,
  ]
};

export default {
  fetchKnowledgeBaseDataFulfilled,
  updateKnowledgeBaseDataFulfilled,
  fetchAllFeaturedArticlesFulfilled,
  addFeaturedArticlesFulfilled,
  updateFeaturedArticlesFulfilled,
  fetchAllArticleCategoriesFulfilled,
  fetchAllArticlesFulfilled,
  fetchDuplicateArticleFulfilled,
};
