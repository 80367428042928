import MainSB from "./children/MainSB/MainSB";
import SubSB from "./children/SubSB/SubSB";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { setSideBarWidthExp } from "../BulkActionSection/BulkActionSection";
import { fetchSideBarData } from "src/store/slices/ticketSidebar/ticketSidebar.slice";
import { useAppDispatch } from "src/store/store";
import { ILoginData } from "src/hooks/auth/useLogin";
import SettingsSideBar from "src/routes/Setting/children/SideBar/SideBar";
import ServiceSideBar from "src/routes/SelfService/Children/ServiceSideBar/ServiceSideBar";
import ReportSideBar from "src/routes/Report/Children/SideBar/ReportSideBar";

export enum RouteNames {
  home = "/",
  tickets = "/tickets",
  cutomers = "/cutomers",
  report = "/report",
  settings = "/settings",
  liveChat = "/live-chat",
  knowledgeBase = "/knowledge-base",
  recording = "/recordings",
  selfService = "/selfService",
  bot = "/bot",
  customerSegments = "/customer-segments",
  campaigns = "/campaigns",
  campaignAdmin = "/campaign/admin"
}

export enum SBNames {
  home = "Home",
  tickets = "Tickets",
  cutomers = "Cutomers",
  report = "Report",
  settings = "Settings",
  liveChat = "Live Chat",
  knowledgeBase = "Knowledge Base",
  recording = "Phone Calls",
  selfService = "Self Service",
  bot = "Automation",
  customerSegment = "Customer",
  campaigns = "Campaigns",
  campaignAdmin = "Campaign Admin",
}
export const RouteSBNameLink: any = {
  [RouteNames.home]: SBNames.home,
  [RouteNames.tickets]: SBNames.tickets,
  [RouteNames.cutomers]: SBNames.cutomers,
  [RouteNames.report]: SBNames.report,
  [RouteNames.settings]: SBNames.settings,
  [RouteNames.liveChat]: SBNames.liveChat,
  [RouteNames.knowledgeBase]: SBNames.knowledgeBase,
  [RouteNames.recording]: SBNames.recording,
  [RouteNames.selfService]: SBNames.selfService,
  [RouteNames.bot]: SBNames.bot,
  [RouteNames.customerSegments]: SBNames.customerSegment,
  [RouteNames.campaigns]: SBNames.campaigns,
};

const SideBar = ({ loginData }: { loginData: ILoginData }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sideBarRef: any = useRef(null);
  const [showMainSB, setShowMainSB] = useState(
    RouteSBNameLink["/" + location.pathname.split("/")[1]],
  );

  useEffect(() => {
    let sbN = location.pathname.split("/")[1];
    if (sbN === "automations") {
      sbN = "bot";
    }
    setShowMainSB(RouteSBNameLink[`/${sbN}`]);
  }, [location.pathname.split("/")[1]]);

  useEffect(() => {
    setSideBarWidthExp(sideBarRef.current.offsetWidth + "px");
  }, [showMainSB]);

  useEffect(() => {
    dispatch(fetchSideBarData());
  }, [false]);

  return (
    <aside
      ref={sideBarRef}
      className="sidebar position-relative d-flex"
    >
      <MainSB
        activeSB={showMainSB}
        loginData={loginData}
      />
      {location.pathname.indexOf(RouteNames.tickets) === 0 &&
        !location.pathname.includes("/ticket/") && <SubSB />}
      {location.pathname.indexOf(RouteNames.settings) === 0 && (
        <SettingsSideBar />
      )}
      {location.pathname.indexOf(RouteNames.selfService) === 0 && (
        <ServiceSideBar />
      )}
      {location.pathname.indexOf(RouteNames.report) === 0 && <ReportSideBar />}
    </aside>
  );
};

export default SideBar;
