import React, { useEffect, useState } from 'react';
import styles from "./components.module.scss"
interface Option {
  id: number;
  value: string;
}
interface Props {
  listOptions: Option[] | undefined,
  onOptionsChange: (listOptions: Option[]) => void;
  status: "loading" | "error" | "success",
}
const GenericListItem = ({ status, onOptionsChange, listOptions }: Props) => {

  const [options, setOptions] = useState<Option[]>(listOptions || []);


  useEffect(() => {
    if (listOptions && listOptions.length > 0) {
      setOptions(listOptions);

    }
  }, [listOptions]);

  // Handler for changing the input value
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, value: e.target.value } : option
    );
    setOptions(updatedOptions);
    onOptionsChange(updatedOptions); // Trigger the callback on options change
  };
  // Add a new option
  const handleAddOption = () => {
    const newOption = { id: options.length + 1, value: '' };
    setOptions([...options, newOption]);
  };

  // Remove an option by id
  const handleRemoveOption = (id: number) => {
    const filteredOptions = options.filter((option) => option.id !== id);
    setOptions(filteredOptions);
  };

  return (
    <div>
      {options.map((option, index) => (
        <div key={option.id} className={styles.mainDiv}>
          <input
            type="text"
            value={option.value}
            onChange={(e) => handleInputChange(e, option.id)}
            placeholder={`Order or 1-200`}
            className={`d-block px-2 ${styles.stagNameInput} 
            `}
          />
          {options.length > 1 && index !== 0 && (
            <div className={styles.deleteBtn} onClick={() => handleRemoveOption(option.id)} ><i className='fa-solid fa-trash' /></div>

          )}
        </div>
      ))}

      <div className={styles.addBtn} onClick={handleAddOption}> + Add more list</div>

    </div>
  );
};

export default GenericListItem;
