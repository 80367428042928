import { axiosJSON } from "src/globals/axiosEndPoints";
import { SegmentMessageType } from "../CustomerSegments/NewMessage/sendNewMessage.service";

export interface GetAllOneTimeCampaignParams {
  start: number;
  limit: number;
}

export enum EOneTimeCampaignStatus {
  Draft = "draft",
  Sent = "sent",
}

export interface IOneTimeCampaign {
  id: string | number;
  title: string;
  status: EOneTimeCampaignStatus;
  sender: string;
  content: SegmentMessageType;
  viewed: number | null;
  clicked: number | null;
  unsubscribed: number | null;
  type: "users" | "leads";
  createdAt: string;
}

export interface GetAllOneTimeCampaignResponse {
  data: IOneTimeCampaign[];
  hasMore: boolean;
}

async function getAllOneTimeCampaignsService(
  params: GetAllOneTimeCampaignParams,
) {
  const { data: res } = await axiosJSON.post(
    "/api/campaign/getOneTimeCampaigns",
    params,
  );

  if (res.err || res.error) {
    throw new Error(res.msg ?? res.message ?? "Something Unexpected Occured!");
  }

  const ret: GetAllOneTimeCampaignResponse = {
    data: res.data,
    hasMore: true,
  };

  if (ret.data.length < params.limit) {
    ret.hasMore = false;
  }

  return ret;
}

export default getAllOneTimeCampaignsService;
