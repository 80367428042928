import { useAddCampaignState } from "../../../../Hooks/useAddCampaignContext";
import CampaignType from "./Children/CampaignType/CampaignType";
import ChannelSelectDD from "./Children/ChannelSelectDD/ChannelSelectDD";
import TriggerCheckbox from "./Children/TriggerCheckbox/TriggerCheckbox";
import UserSegmentSelectDD from "./Children/UserSegmentSelectDD/UserSegmentSelectDD";
import styles from "./PrimaryDetails.module.scss";

const PrimaryDetails = () => {
  const { state, dispatch } = useAddCampaignState();

  return (
    <div className={`${styles.contentWrapper}`}>
      <div className="mb-3">
        <label
          htmlFor="campaignName"
          className={`form-label ${styles.label}`}
        >
          Campaign name
        </label>
        <input
          type="email"
          className={`form-control ${styles.inputBox} ${
            state.showErrors && (!state.name || state.name.trim() === "")
              ? "border border-danger"
              : ""
          }`}
          id="campaignName"
          value={state.name}
          onChange={(e) => {
            dispatch("updateState", { name: e.target.value });
          }}
          placeholder="Add campaign name here..."
        />
      </div>
      <UserSegmentSelectDD />
      <ChannelSelectDD />
      <TriggerCheckbox />
      <CampaignType />
    </div>
  );
};

export default PrimaryDetails;
