import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";

/**
 * Parameters for mapping attributes.
 */
export interface UpdateMappedAttributesParams {
  headingIds: string[]; //should have correct indexes after update
  attachmentId: string;
}

export interface MappedAttributeError {
  tableHeadingId: string;
  errorId: string;
  errorDescription: string;
}
/**
 * The response structure for the `updateMappedAttributes` function.
 */
export interface UpdateMappedAttributesResponse {
  errors?: MappedAttributeError[];
}
export interface MapAttributesErrors {
  mapAttributeErrors: Record<string, MappedAttributeError>;
}

/**
 * Fetches all products data for the Uploaded CSV modal.
 *
 * This function used to retrieve all products data for uploaded CSV modal.
 *
 * @param {UpdateMappedAttributesParams} params - The parameters to send with the request.
 * @returns {Promise<MapAttributesErrors>} A promise that resolves to the response data containing all errors.
 * @throws {Error} Throws an error if the API response contains an error or if an error occurs during the request.
 */
async function updateMappedAttributes({
  activeSegmentType,
  params,
}: {
  params: UpdateMappedAttributesParams;
  activeSegmentType: string;
}): Promise<MapAttributesErrors> {
  const apiEndPoint = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/mapAttributes`
    : `/api/segment/${activeSegmentType}/csv/mapAttributes`;

  const { data: res } = await axiosJSON.post(apiEndPoint, params);

  if (res.err || res.error) {
    if (res.data && res.data?.errors) {
      const errors = normalize(res.data);
      throw errors;
    }
    throw new Error(res.msg ?? res.message ?? "Some Error Occurred.");
  }
  const normalizedData = normalize(res.data);
  return normalizedData;
}
const normalize = (data: UpdateMappedAttributesResponse) => {
  const ret: MapAttributesErrors = {
    mapAttributeErrors: {},
  };
  data.errors?.map((error) => {
    ret.mapAttributeErrors[error.tableHeadingId] = error;
  });
  return ret;
};
export default updateMappedAttributes;
