import { useMemo } from "react";
import ConfiguredStep from "./ConfiguredStep/ConfiguredStep";
import styles from "./StepConfigured.module.scss";
import { useAutomationContext } from "../../../../Hooks/useAutomationContext";
import {
  Question,
  StepStatus,
} from "src/services/Automation/getAutomationById.service";

/**
 * Component for configured steps
 */
function StepsConfigured({ activeIndex }: { activeIndex: number }) {
  const { formData } = useAutomationContext();
  const areAllStepsCompleted = useMemo(() => {
    return formData.stepIds.every((stepId) => {
      const step = formData.stepsData[stepId];
      return step.stepStatus === StepStatus.Completed;
    });
  }, [formData]);
  return (
    <>
      <div className={`${styles.stepConfigureWrapper}`}>
        <div></div>

        <div className={`mt-2 px-2 px-lg-0`}>
          {formData.stepIds.map((id, idx) => {
            const step = formData.stepsData[id];
            let questions: Question[] = [];
            step.questionKeys.forEach((questionKey) => {
              const question = formData.questionsData[questionKey];
              questions.push({
                ...question,
              });
            });
            return (
              <ConfiguredStep
                key={id}
                index={idx}
                title={step.stepTitle}
                isActive={activeIndex == idx}
                isComplete={step.stepStatus === "completed"}
                questions={questions}
              />
            );
          })}
          {areAllStepsCompleted ? (
            <ConfiguredStep
              key={"goLiveStepInStepsConfigured"}
              index={formData?.stepIds?.length}
              title={"You're ready to go! Go Live"}
              isActive={activeIndex === formData.stepIds.length}
              isComplete={formData?.published ? true : false}
              isGoLiveStep={true}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default StepsConfigured;
