import { Dropdown } from "react-bootstrap";
import styles from "./SendMessageIf.module.scss";
import { useState } from "react";
import { ScheduleData } from "src/services/LiveChat/ScheduleFollowups/getAllSchedules";

type Props = {
  selected: ScheduleData["condition"];
  onSelect?: (value: string | null) => void;
};

function SendMessageIf({ selected, onSelect }: Props) {
  const [show, setShow] = useState(false);
  const selectedConditionName = {
    none: "Send without checking any conditions",
    noNewOrderPlacedAfterScheduling: "No new order placed after scheduling",
    noNewResponseOrMessageReceivedFromTheCustomer:
      "No new response or message received from the customer",
  };

  return (
    <div className={`pt-2 pe-2 ${styles.sendIfWrap}`}>
      <div className="d-flex align-items-start flex-column flex-md-row align-items-md-center">
        <p className={`mb-0 ${styles.text}`}>Send this message if:</p>

        <Dropdown
          onToggle={setShow}
          onSelect={onSelect}
        >
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            bsPrefix={`dropdown cursor-pointer ms-0 ms-md-4 ${
              styles.customBtn
            } ${show ? styles.active : ""}`}
            className="d-flex flex-row justify-content-between"
          >
            {selectedConditionName[selected] ?? "None"}
            {show ? (
              <span className="ms-1">
                <i className="fa-solid fa-caret-up"></i>
              </span>
            ) : (
              <span className="ms-1">
                <i className="fa-solid fa-caret-down"></i>
              </span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.dropMenu}`}>
            <Dropdown.Item
              eventKey="none"
              className={`${styles.listItem}`}
            >
              Send without checking any conditions
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="noNewResponseOrMessageReceivedFromTheCustomer"
              className={`${styles.listItem}`}
            >
              No new response or message received from the customer
            </Dropdown.Item>
            {/* Jun 30 2023 disabling this option as it is not fully implemented */}
            <Dropdown.Item
              disabled={true}
              eventKey="noNewOrderPlacedAfterScheduling"
              className={`${styles.listItem} ${styles.disabled}`}
            >
              No new order placed after scheduling
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default SendMessageIf;
