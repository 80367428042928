import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import styles from "./MsgSent.module.scss";
import { useMemo } from "react";
import { useAppSelector } from "src/store/store";
import { convertGmtToUserTimezone } from "src/utils/dateLibrary";
interface Props {
  status: IMessageData["messageInfo"];
}
const MsgSent = ({ status }: Props) => {
  // Retrieve user timezone from the global state using Redux selector
  const userTimezone = useAppSelector(
    (state) => state.globals.currentUserData?.userTimezone,
  );

  // Use useMemo to memoize the generated date based on status.dateTimeGMT and userTimezone
  const generatedDate = useMemo(() => {
    // Check if status.dateTimeGMT is available
    if (status?.dateTimeGMT) {
      // Create a new Date object using the provided dateTimeGMT and append "Z" to indicate UTC
      return new Date(status?.dateTimeGMT + "Z").toLocaleString("en-US", {
        // Format the date and time based on user's timezone
        timeZone: userTimezone,
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true, // Use 12-hour format
      });
    } else {
      // If status.dateTimeGMT is not available, return null
      return null;
    }
  }, [status?.dateTimeGMT, userTimezone]); // Dependency array for useMemo to track changes in status.dateTimeGMT and userTimezone

  return (
    <div className="d-flex align-items-center justify-content-end">
      <div
        className={`${styles.sentCheckBox}`}
        style={{ background: status?.color ?? "#b7b7b7" }}
      >
        <i className={status?.icon ? status.icon : "fa-solid fa-check"}></i>
      </div>
      <span className={`ps-1 ${styles.msgText}`}>
        {`${status?.displayName} ${
          generatedDate && generatedDate !== "" ? ", " + generatedDate : ""
        }`}
      </span>
    </div>
  );
};

export default MsgSent;
