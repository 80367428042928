import React, { useMemo } from "react";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import InstructionMessage from "../PromptMessage/InstructionMessage/InstructionMessage";
import InternalNote from "../InternalNote/InternalNote";
import EditEscalation from "../EditEscalation/EditEscalation";
import AvailableTask from "../AvailableTask/AvailableTask";
import PromptMessage from "../PromptMessage/PromptMessage";
import { CernThreadMsgMap } from "../../usefetchCernThread";
import { IMessageData } from "src/store/slices/innerTicket/innerTicket.slice";
import styles from "../../CernThread.module.scss";
import { Spinner } from "react-bootstrap";
import Lottie from "lottie-light-react";
import botImg from "src/assets/images/botImg.json";
interface Props {
  cernThread: CernThreadMsgMap;
  messageId?: number | string | null;
  messageList: {
    [messageId: number | string]: IMessageData;
  };
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPageHandler?: () => void;
  isCampaign?: boolean;
  scrollToBottomMsg: React.RefObject<HTMLDivElement>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  styles: {
    [className: string]: string;
  };
  showCernLoader?: boolean;
}
const defaultMessage: IMessageData & {
  messageDateTimeGmt: string;
  parentMessageId: number;
  visibility: string;
} = {
  messageId: 1,
  ticketId: 1,
  messageDateTime: "2024-11-12 05:09:22",
  isDraftMessage: false,
  messageDateTimeGmt: "2024-11-12 13:09:22",
  subject: "",
  channelId: "",
  message:
    '<p>Hi! I am Cern bot. Feel free to give me any instructions about your campaign and I will try to execute them. Here&rsquo;s an example:</p> <p><span id="c1f956ca-0089-4311-9bc7-edb41b33cddf" class="mceNonEditable" style="color: #4000a1; background-color: #dbebf9; border-radius: 7px; font-size: 12px; padding: 0px 4px; outline: none; cursor: default; display: inline-block;" data-cern-mention-instruction-type="1">@Cern</span>&nbsp;Create an email campaign targeting new users. Use the subject line "Welcome to [Your Brand] &ndash; Let&rsquo;s Get Started!" and add the expertise of our in email.</p>',
  messageUuid: "",
  parentMessageId: 1,
  visibility: "ticketView",
  cc: "",
  bcc: "",
  senderName: "Cern",
  senderImgURL: null,
  isPublicAttachmentUrl: false,
  sentBy: "agent",
  to: "",
  toName: "",
  from: "",
  messageType: "BotAiNote",
  attachments: [],
};
const MessageRenderer = ({
  cernThread,
  messageId,
  messageList,
  isLoading,
  hasNextPage,
  fetchNextPageHandler,
  isCampaign,
  scrollToBottomMsg,
  setMessage,
  styles,
  showCernLoader,
}: Props) => {
  const sortedCernThread = useMemo(() => {
    if (!cernThread) return [];

    if (!isCampaign) {
      return Object.values(cernThread);
    }
    // Convert the object to an array of IMessageData
    const cernThreadArray = Object.values(cernThread);

    // Sort the array by messageDateTime
    return cernThreadArray.sort((a: IMessageData, b: IMessageData) => {
      const dateA = new Date(a.messageDateTime).getTime();
      const dateB = new Date(b.messageDateTime).getTime();
      return dateA - dateB;
    });
  }, [isCampaign, cernThread]);

  const firstMessage = sortedCernThread[0];
  const firstMessageDateTime =
    firstMessage?.messageDateTime ?? new Date().toISOString();

  return isLoading && Object.keys(cernThread).length === 0 ? (
    <Loader />
  ) : !messageId && Object.keys(cernThread).length === 0 ? (
    <div
      className={`${styles.loaderContainer} d-flex justify-content-center align-items-center`}
    >
      {isCampaign ? (
        <div>
          <InstructionMessage dateTime={firstMessageDateTime} />
          <InternalNote
            key={"message_1"}
            msg={{
              ...defaultMessage,
              messageDateTime: firstMessageDateTime,
            }}
            direction="left"
          />
        </div>
      ) : (
        <span className={styles.noCernMsg}>
          There are no messages in the Cern's thread
        </span>
      )}
    </div>
  ) : (
    <InfiniteScroll
      initialDataLoaded={true}
      // hasMoreBottom={hasNextPage || isLoading}
      hasMoreTop={hasNextPage || isLoading}
      // loadMoreFromBottom={!isLoading ? changePage : () => {}}
      loadMoreFromTop={!isLoading ? fetchNextPageHandler : () => {}}
      className={`${styles.chatContainer} p-3`}
      root={scrollToBottomMsg}
      infiniteLoaderTop={
        <div
          className={`mb-2`}
          onClick={fetchNextPageHandler}
        >
          <Spinner
            size="sm"
            animation="border"
          />
        </div>
      }
    >
      <div>
        {isCampaign && <InstructionMessage dateTime={firstMessageDateTime} />}
        {isCampaign && (
          <InternalNote
            key={"message_1"}
            msg={{
              ...defaultMessage,
              messageDateTime: firstMessageDateTime,
            }}
            direction="left"
          />
        )}
        {messageId && messageList[messageId] && !hasNextPage && !isLoading && (
          <InternalNote msg={messageList[messageId]} />
        )}
        {sortedCernThread.map((msg) => {
          const msgId = msg.messageId;
          if (
            msg.messageType === "BotAiNote" &&
            msg.escalateDetails?.isEscalateMsgExist &&
            (msg.escalateDetails?.escalateMsgStatus === "pending" ||
              msg.escalateDetails?.escalateMsgStatus === "paused" ||
              msg.escalateDetails?.escalateMsgStatus === "cancelled")
          ) {
            return (
              <div key={msgId}>
                <InternalNote
                  msg={msg}
                  direction={isCampaign ? "left" : undefined}
                />
                <EditEscalation msg={msg} />
              </div>
            );
          } else if (
            msg.messageType === "NoteBotAiQuery" ||
            msg.messageType === "BotAiNote"
          ) {
            return (
              <InternalNote
                msg={msg}
                key={msgId}
                direction={isCampaign ? "left" : undefined}
              />
            );
          } else if (
            msg.messageType === "NoteBotAiMissingDetails" ||
            msg.messageType === "NoteBotAiOutOfScope"
          ) {
            return (
              <div key={msgId}>
                <InternalNote
                  msg={msg}
                  direction={isCampaign ? "left" : undefined}
                />
                <AvailableTask selectInstruction={setMessage} />
              </div>
            );
          } else if (msg.messageType === "BotAiEscalatedNote") {
            return (
              <InternalNote
                msg={msg}
                key={msgId}
                direction={isCampaign ? "left" : undefined}
              />
            );
          }
          return null;
        })}
        {showCernLoader && (
          <div className="d-flex flex-column justify-content-center">
            <span
              className={`${styles.lottieImg} d-flex justify-content-center`}
            >
              {/* Default Bot Image */}
              <Lottie animationData={botImg}></Lottie>
            </span>
            <span
              className={`${styles.botLoaderMessage}  d-flex justify-content-center`}
            >
              Generating....
            </span>
          </div>
        )}
      </div>
    </InfiniteScroll>
  );
};

export default MessageRenderer;
