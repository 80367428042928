import { axiosJSON } from "src/globals/axiosEndPoints";
import { ECampaignStatus } from "./getCampaignById";

interface GetAllCampaignsParams {
  start: number;
  limit: number;
  isSegmentDataRequired?: boolean;
}

interface CampaignData {
  id: number;
  name: string;
  status?: ECampaignStatus;
  userSegment?: {
    id: number | string;
    name: string;
  };
}

export interface GetAllCampaignsResponse {
  data: CampaignData[];
  metaData: {
    total: number;
  };
}

export const getAllCampaigns = async (params: GetAllCampaignsParams) => {
  const { data: res } = await axiosJSON.post("/api/campaign/getAll", params);
  if (res.err) {
    throw res.msg;
  }

  return res as GetAllCampaignsResponse;
};
