import { axiosJSON } from "src/globals/axiosEndPoints";

export interface CheckLCInstallationPayload {
  integration_id: number | string;
}

export interface CheckPostLCInstallationPayload {
  integrationId: number | string;
}

export const checkLCInstallation = async (
  payload: CheckLCInstallationPayload,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/checkInstallation`,
    payload,
  );
  if (res.err) {
    // If the status code indicates extension not installed, return the corresponding status
    if (res.statusCode && res.statusCode === "extension_not_installed") {
      return "extension_not_installed";
    }
    throw res.msg;
  } else if (res.statusCode) {
    return res.statusCode;
  }
  return res.msg as string;
};

export const checkPostLCInstallation = async (
  payload: CheckPostLCInstallationPayload,
) => {
  const { data: res } = await axiosJSON.post(
    `/api/chatSetting/widget/checkPostInstallation`,
    payload,
  );
  if (res.error) {
    throw "Installation unsucessful!";
  } else if (res.data.installation_validated) {
    return res.data; //returing the response data object instead of boolean, data object used to update enable/disable status in live chat setting -> channels -> chat widget
  } else {
    throw "Installation unsucessful!";
  }
};
