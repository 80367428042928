import styles from "./ImportData.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import { useQuery } from "@tanstack/react-query";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import getImportStatus, {
  GetImportStatusParams,
  GetImportStatusResponse,
} from "src/services/CustomerSegments/UploadCSV/getImportStatus.service";
import { useSegmentView } from "../../../hooks/useSegmentView";
import { useGetAllCustomers } from "../MapAttribute/MapAttribute";
import { GetAllCustomersParams } from "src/services/CustomerSegments/UploadCSV/getAllCustomers.service";
import ProductsTable from "./Children/ProductsTable/ProductsTable";

/**
 * Custom hook to handle the data for importing csv data.
 *
 * This hook uses the `useQuery` hook from React Query to fetch and manage the state of product variant data.
 * It transforms the raw data into a structured format suitable for use in the modal, including processing
 * table headings and variant information.
 *
 * @param {GetImportStatusParams} params - The parameters used to fetch product variant data.
 * @returns object - An object containing the formatted products details, loading state, and error state.
 */
const useImportCsvData = (
  params: GetImportStatusParams,
  activeSegmentType: string,
) => {
  const { data, isLoading, isError } = useQuery<GetImportStatusResponse>({
    queryKey: ["getImportStatus", params],
    queryFn: () =>
      getImportStatus({ params: params, activeSegmentType: activeSegmentType }),
  });
  return {
    data: data,
    isLoading,
    isError,
  };
};

const ImportData = ({
  setNextBtnDisabled,
}: {
  setNextBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { state } = useUploadNewCsvModalContext();
  const limit = 10;
  /**
   * Starting index for API call
   */
  const start = useMemo(() => {
    return limit * currentPage - limit;
  }, [currentPage]);

  const params: GetAllCustomersParams = {
    start: start,
    limit: limit,
    attachmentId: state.uploadCSV?.attachmentId ?? "",
  };
  const { activeSegmentDetails, fetchMoreSegmentView } = useSegmentView();

  // Calling this api once to start the import
  const {
    data: importData,
    isError: importError,
    isLoading: isImportLoading,
  } = useImportCsvData(
    {
      attachmentId: state.uploadCSV?.attachmentId ?? "",
      importType: state.configureType ?? "users",
    },
    activeSegmentDetails.activeSegmentType ?? "",
  );

  const { isError, isLoading, data } = useGetAllCustomers(
    params,
    activeSegmentDetails.activeSegmentType ?? "",
    isImportLoading,
  );

  useEffect(() => {
    if (importError) {
      setNextBtnDisabled(true);
      pushTheToast({
        position: "top-right",
        text: "Something went wrong while importing!",
        type: "danger",
      });
    } else if (importData) {
      fetchMoreSegmentView(true);
      setNextBtnDisabled(false);
    }
  }, [importError, importData]);
  /**
   * Total pages available
   */
  const totalPages = useMemo(() => {
    return data?.totalCustomers ? Math.ceil(data?.totalCustomers / limit) : 0;
  }, [data?.totalCustomers]);

  return (
    <div>
      {isLoading || isImportLoading ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : data ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className={`${styles.heading}`}>Imported file</h4>
              <p className={`mb-0 ${styles.subHeading}`}>
                <span className={`${styles.fileName}`}>File name- </span>
                <span className={`${styles.fileDesc}`}>
                  {state.uploadCSV?.uploadedFileName ?? ""}
                </span>
              </p>
            </div>
          </div>
          <div className={`mt-3 ${styles.table}`}>
            <ProductsTable
              data={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              viewContext="newUploads"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImportData;
