import Lottie from "lottie-light-react";
import { useCallback, useEffect, useRef } from "react";
import LoaderAnimation from "src/assets/animation/white-loader.json";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import sendNewMessageService from "src/services/CustomerSegments/NewMessage/sendNewMessage.service";
import { generateAndValidatePayload } from "../../Hooks/sendMessage.helpers";
import { useSendMessage } from "../../Hooks/useSendMessageContext";
import styles from "../../SendMessageModal.module.scss";

const ActionButtons = ({ onHide }: { onHide: () => void }) => {
  const { state, dispatch } = useSendMessage();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timeout = timerRef.current;

    // Cleanup timeout on unmount or re-render
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const resetSendMessageStatus = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      dispatch("updateState", {
        sendMessageStatus: "idle",
        showErrors: false,
      });
    }, 3000);
  }, [dispatch]);

  const onSend = useCallback(async () => {
    const payload = generateAndValidatePayload(state);
    if (!state.logoUrl || state.logoUrl === "") {
      dispatch("updateState", {
        showFileWarning: {
          show: true,
          type: "empty",
        },
      });
    } else {
      dispatch("updateState", {
        showFileWarning: {
          show: false,
          type: null,
        },
      });
    }
    if (!payload) {
      dispatch("updateState", { showErrors: true });
      pushTheToast({
        position: "top-right",
        text: "Please fill required fields",
        type: "danger",
      });
      return;
    }

    try {
      dispatch("updateState", { sendMessageStatus: "pending" });

      await sendNewMessageService(payload);

      dispatch("updateState", { sendMessageStatus: "fulfilled" });

      resetSendMessageStatus();
    } catch (e) {
      const err = e as Error;

      dispatch("updateState", {
        showErrors: true,
        sendMessageStatus: "rejected",
      });

      resetSendMessageStatus();

      pushTheToast({
        position: "top-right",
        text: typeof err === "string" ? err : err.message,
        type: "danger",
      });
    }
  }, [dispatch, resetSendMessageStatus, state]);

  return (
    <div
      className={`d-flex flex-column align-items-end justify-content-center px-2 pt-2 ${
        styles.buttonWrapper
      } ${state.showErrors ? styles.failWrapper : ""}`}
    >
      <div className="d-flex align-items-center">
        {/* Discard button  */}
        <button
          className={`me-2 px-2 ${styles.discardBtn}`}
          id="sendMessageCancelBtn"
          onClick={onHide}
        >
          Cancel
        </button>
        {/* Save button  */}
        <button
          className={`${styles.saveBtn}`}
          id="sendMessageSendBtn"
          onClick={onSend}
          disabled={state.sendMessageStatus === "pending"}
        >
          {state.sendMessageStatus === "pending" ? (
            <div className={`d-flex align-items-center`}>
              <div className="mx-1">Sending</div>
              <div className={`${styles.loader}`}>
                <Lottie animationData={LoaderAnimation} />
              </div>
            </div>
          ) : state.sendMessageStatus === "fulfilled" ? (
            "Message sent"
          ) : state.sendMessageStatus === "rejected" ? (
            "Sending Failed!"
          ) : (
            "Send"
          )}
        </button>
      </div>

      {/* Error message  */}
      {state.showErrors && (
        <div className={`d-flex mb-2 mt-1 ${styles.errText}`}>
          Sending failed. Please review the errors
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
