import { AxiosProgressEvent } from "axios";
import html2canvas from "html2canvas";
import { useCallback, useMemo, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import ErrorModal from "src/routes/Campaigns/Children/ErrorModal/ErrorModal";
import createTemplateService from "src/services/Campaign/createTemplate.service";
import { IEmailTemplateVariant } from "src/services/Campaign/getEmailTemplateVariant.service";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";
import styles from "./CreateNewTemplate.module.scss";

const LoadingBar = ({ progress }: { progress: number }) => {
  return (
    <div className={`${styles.loading}`}>
      <ProgressBar
        className={`progress-bar ${styles.bar} my-4`}
        now={progress}
      />
      <span className={`ps-2 ${styles.count}`}>{progress}%</span>
    </div>
  );
};

const Options = ({
  onChoose,
}: {
  onChoose: (type: "upload" | "design") => void;
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${styles.optionSection}`}>
      <h6>Which option would you like to choose?</h6>
      <button
        className={`mb-2 ${styles.templateBtn}`}
        onClick={() => onChoose("upload")}
      >
        <span>
          <i className="fa-solid fa-code"></i>
        </span>
        <span className="ps-2">Upload json</span>
      </button>

      <button
        className={`${styles.scratchBtn}`}
        // onClick={() => onChoose("design")}
        onClick={() => setShowModal(true)}
      >
        <span>
          <i className="fa-brands fa-figma"></i>
        </span>
        <span className="ps-2">Design from scratch</span>
      </button>

      <ErrorModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

const ALLOWED_JSON_FILE_TYPES = ".json";
const SUPPORTED_JSON_EXTENSION_TYPES = ["json"];

const ALLOWED_HTML_FILE_TYPES = ".html";
const SUPPORTED_HTML_EXTENSION_TYPES = ["html"];

const FileInput = ({
  type,
  label,
  placeholder,
  onChangeAttachment,
  showError,
}: {
  type: "json" | "html";
  label: string;
  placeholder?: string;
  onChangeAttachment: (file: File) => void;
  showError: boolean;
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [attachmentName, setAttachmentName] = useState("");

  const filePicker = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    } else {
      console.error("fileRef is not initialized");
    }
  }, []);

  const isFileSupported = useCallback(
    (fileName: string) => {
      const fileType = fileName.split(".").pop() || "";
      const supportedType =
        type === "json"
          ? SUPPORTED_JSON_EXTENSION_TYPES
          : SUPPORTED_HTML_EXTENSION_TYPES;
      return supportedType.includes(fileType.toLowerCase().trim());
    },
    [type],
  );

  const onFileInputChange = useCallback(
    (files: FileList | null) => {
      if (files) {
        const uploadableFiles = Array.from(files).filter((file) =>
          isFileSupported(file.name),
        );
        if (uploadableFiles.length !== 0) {
          const file = uploadableFiles[0];
          setAttachmentName(file.name);
          onChangeAttachment(file);
        } else {
          pushTheToast({
            position: "top-right",
            type: "danger",
            text: "Unsupported attachment Type",
          });
        }
      }
    },
    [isFileSupported, onChangeAttachment],
  );

  return (
    <div className="">
      <label
        htmlFor="name"
        className={`form-label ${styles.label}`}
      >
        {label}
      </label>
      <div className="position-relative">
        <input
          accept={
            type === "json" ? ALLOWED_JSON_FILE_TYPES : ALLOWED_HTML_FILE_TYPES
          }
          style={{ display: "none" }}
          ref={fileRef}
          type="file"
          onChange={(e) => onFileInputChange(e.target.files)}
        />
        <input
          type="input"
          className={`form-control ${styles.fileInput} ${
            showError ? "border border-danger" : ""
          }`}
          id={`file_${type}`}
          value={attachmentName}
          placeholder={placeholder}
          disabled
        />
        <span
          className={`px-2 ${styles.browse} cursor-pointer`}
          onClick={filePicker}
        >
          Browse
        </span>
      </div>
    </div>
  );
};

const htmlFileToImageBlob = async (htmlFile: File): Promise<Blob | null> => {
  try {
    const htmlContent = await htmlFile.text();
    const container = document.createElement("div");
    container.style.visibility = "hidden";
    container.innerHTML = htmlContent;
    document.body.appendChild(container);
    const canvas = await html2canvas(container, {
      useCORS: true,
      allowTaint: false,
    });
    const dataUrl = canvas.toDataURL("image/png");
    const imageBlob = await (await fetch(dataUrl)).blob();
    document.body.removeChild(container);
    return imageBlob;
  } catch (error) {
    console.error("Error converting HTML file to image Blob:", error);
    return null;
  }
};

const UploadForm = ({
  onCancel,
  onSuccess,
  categoryId,
  createHtml,
  createJson,
  action,
  templateId,
}: {
  categoryId: string;
  onCancel: () => void;
  onSuccess: (createdTemplate: IEmailTemplateVariant) => void;
  createJson: () => File | null;
  createHtml: () => File | null;
  action: "create" | "edit" | null;
  templateId?: string | number;
}) => {
  const [templateName, setTemplateName] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [htmlFile, setHtmlFile] = useState<File | null>(null);
  const [jsonFile, setJsonFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);

  useMemo(() => {
    if (action === "edit") {
      const html = createHtml();
      setHtmlFile(html);
      const json = createJson();
      setJsonFile(json);
    }
  }, [action, createHtml, createJson]);

  const changeLoadingProgress = useCallback(
    (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setLoadingProgress(percentage);
      } else {
        setLoadingProgress(50);
      }
    },
    [],
  );

  const onSubmit = useCallback(async () => {
    if (!htmlFile || !jsonFile || templateName.trim() === "") {
      setShowError(true);
      return;
    }

    try {
      setIsLoading(true);
      setLoadingProgress(0);
      const abortController = new AbortController();

      // Generate the preview image from the HTML file
      const previewImageBlob = await htmlFileToImageBlob(htmlFile);

      setLoadingProgress(50);

      const previewImageFile = previewImageBlob
        ? new File([previewImageBlob], `${templateName}.png`, {
            type: "image/png",
          })
        : null;

      const res = await createTemplateService(
        {
          categoryId,
          html: htmlFile,
          json: jsonFile,
          templateName,
          previewImage: previewImageFile,
          abortControllerSignal: abortController.signal,
          ...(action === "edit" ? { templateId } : {}), // Corrected spread syntax
        },
        changeLoadingProgress,
      );
      onSuccess(res);
    } catch (e) {
      const err = e as Error;
      pushTheToast({
        position: "top-right",
        type: "danger",
        text: typeof err === "string" ? err : err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    action,
    categoryId,
    changeLoadingProgress,
    htmlFile,
    jsonFile,
    onSuccess,
    templateId,
    templateName,
  ]);

  if (isLoading) {
    return <LoadingBar progress={loadingProgress} />;
  }

  return (
    <div className={`${styles.formSection}`}>
      <div className="mb-3">
        <label
          htmlFor="name"
          className={`form-label ${styles.label}`}
        >
          Template name:
        </label>
        <input
          type="text"
          className={`form-control ${styles.inputBox} ${
            showError && templateName.trim() === ""
              ? "border border-danger"
              : ""
          }`}
          id="name"
          placeholder="Template name:"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </div>

      <FileInput
        label="Template file(.html file only)"
        onChangeAttachment={setHtmlFile}
        type="html"
        placeholder="Upload HTML file for your template..."
        showError={showError && !htmlFile}
      />

      <FileInput
        label="Template file(.json file only)"
        onChangeAttachment={setJsonFile}
        type="json"
        placeholder="Upload JSON file for your template..."
        showError={showError && !jsonFile}
      />

      <div className="d-flex justify-content-between align-items-center mt-4">
        <button
          className={`${styles.cancelBtn}`}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className={`${styles.uploadBtn}`}
          onClick={onSubmit}
        >
          <span>
            <i className="fa-solid fa-cloud-arrow-up"></i>
          </span>
          <span className="ps-1">Upload</span>
        </button>
      </div>
    </div>
  );
};

const CreateNewTemplate = ({
  onSuccess,
  action,
  createHtml,
  createJson,
  templateId,
}: {
  onSuccess: (createdTemplate: IEmailTemplateVariant) => void;
  createJson: () => File | null;
  createHtml: () => File | null;
  action: "create" | "edit" | null;
  templateId?: string | number;
}) => {
  const { state } = useAddCampaignState();
  const [selectedType, setSelectedType] = useState<"upload" | "design" | null>(
    action === "edit" ? "upload" : null,
  );

  return (
    <div>
      <div className={`${styles.contentWrapper}`}>
        {selectedType === null && <Options onChoose={setSelectedType} />}
        {selectedType === "upload" && (
          <UploadForm
            onCancel={() => setSelectedType(null)}
            onSuccess={onSuccess}
            categoryId={state.categoryId + ""}
            createJson={createJson}
            createHtml={createHtml}
            action={action}
            templateId={templateId}
          />
        )}
        {selectedType === "design" && <></>}
      </div>
    </div>
  );
};

export default CreateNewTemplate;
