import styles from "./CampaignCernThread.module.scss";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import saufter from "src/assets/images/saufter.png";
import { UploadAttachmentRes } from "src/services/Attachment/uploadAttachment";
import {
  IMessageData,
  setShowCernThread,
} from "src/store/slices/innerTicket/innerTicket.slice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pushTheToast } from "src/containers/ToastContainer/ToastContainer";
import useCustomMentions from "src/hooks/TinyMCEContainer/useCustomMentions";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import Loader from "src/components/Loader";
import { sendCernThreadMessage } from "src/services/Campaign/sendCernThreadMessage.service";
import ErrorModal from "src/features/SelectPricing/children/ErrorModal/ErrorModal";
import MessageRenderer from "src/routes/Ticket/children/MainChat/children/CernThread/Children/MessageRenderer/MessageRenderer";
import MessageEditor from "src/routes/Ticket/children/MainChat/children/CernThread/Children/MessageEditor/MessageEditor";
import useCampaignCernThread from "./useCampaignCernThread";
import useClickAway from "src/hooks/useClickAway";
import {
  CampaignActionType,
  CampaignContext,
} from "../CampainContext/CampainContext";
import {
  ICampaignSendMessageService,
  sendCampaignThreadMessage,
} from "src/services/Campaign/sendCampaignThreadMessage";
import PromptMessage from "src/routes/Ticket/children/MainChat/children/CernThread/Children/PromptMessage/PromptMessage";

const CampaignCernThread = ({ handleClose }: { handleClose?: () => void }) => {
  const [err, setErr] = useState(false);
  const [state, campaignDispatch] = useContext(CampaignContext);
  const [showCernLoader, setShowCernLoader] = useState(false);
  const messageRef = state?.messageRef;

  const [message, setMessage] = useState(messageRef?.current ?? "");
  const [showingWhichModal, setShowingWhichModal] = useState<string>(
    "" as "template" | "escalateDraft",
  );
  //attachement state
  const [attachmentUploadData, setAttachmentUploadData] =
    useState<UploadAttachmentRes>();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentAttachmentBatchNumber, setCurrentAttachmentBatchNumber] =
    useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const scrollToBottomMsg = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef(0);
  const [addNewMsg, setAddNewMsg] = useState(false); // to check new message is added or not. If added then scroll div to bottom

  const [showPromptError, setShowPromptError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { cernThread, isLoading, setCernThread, hasNextPage, changePage } =
    useCampaignCernThread(
      state?.activeCampaignEmailId,
      state?.activeCampaignId,
    );
  const { fetchAllMentions } = useCustomMentions();

  useEffect(() => {
    if (messageRef) {
      messageRef.current = message;
    }
  }, [message]);

  useEffect(() => {
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop =
        scrollableDiv.scrollHeight - prevScrollHeightRef.current;
    }
    if (addNewMsg && scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      setAddNewMsg(false);
    }
  }, [cernThread]);

  const { mutate, isLoading: sendMsgLoading } = useMutation({
    mutationFn: sendCampaignThreadMessage,
    onMutate: async (newMessage) => {
      // Create a temporary message object that matches IMessageData
      const tempMessage: IMessageData = {
        messageId: newMessage.uuid,
        ticketId: 1,
        messageDateTime: new Date().toISOString(),
        isDraftMessage: false,
        subject: "", // Assuming subject is not part of the payload
        channelId: "email",
        message: newMessage.message,
        to: "",
        from: "",
        cc: "",
        bcc: "",
        sentBy: "", // Assuming 'sentBy' is not part of the payload
        messageType: newMessage.messageType,
        senderName: "", // Assuming 'senderName' is not part of the payload
        senderImgURL: null,
        attachments: [],
        // messageStatus: "sending", // Indicate that this is a temporary message
        messageUuid: newMessage.uuid,
        // Add other fields as necessary
      };

      // Optimistically update the cernThread state
      setCernThread((prev) => ({
        ...prev,
        [newMessage.uuid]: tempMessage,
      }));

      // Return a context object with the temporary message ID
      return { tempMessageId: newMessage.uuid };
    },
    onSuccess: (data, variables, context) => {
      if (context) {
        // Replace the temporary message with the actual message data
        setCernThread((prev) => {
          const { tempMessageId } = context;
          const { [tempMessageId]: _, ...rest } = prev; // Remove the temporary message
          data?.data?.forEach((message) => {
            rest[message.messageId] = message;
          });
          return {
            ...rest,
          };
        });
      }
      if (data?.meta?.generatedContent && state.activePromptElement?.callback) {
        state.activePromptElement.callback(data.meta.generatedContent);
      }

      // queryClient.invalidateQueries(["campaign/cernThread"]);
      setAddNewMsg(true);
      setShowCernLoader(false);
    },
    onError: (error, variables, context) => {
      // Optionally show an error message to the user
      pushTheToast({
        text: "Failed to send message",
        type: "danger",
        position: "top-right",
      });
      setShowCernLoader(false);
    },
  });

  const sendMessageHandler = useCallback(() => {
    if (state.activeCampaignId && state.activeCampaignEmailId) {
      if (selectedFiles.length > 0 || message.length > 0) {
        const messageUuid = uuid();
        const payload: ICampaignSendMessageService = {
          campaignId: state.activeCampaignId,
          campaignEmailId: state.activeCampaignEmailId,
          message: message,
          messageType: "NoteBotAiQuery",
          sendAs: "Open",
          attachmentBatchNumber: selectedFiles
            ? selectedFiles.map(function (value: any, index: any) {
                return value.batchNumber as string;
              })
            : ([] as string[]),
          uuid: messageUuid,
          channel: "Email",
          selectedText: state.activePromptElement?.selectedValue,
        };
        mutate(payload);

        if (!state.activePromptElement) {
          setShowPromptError(true);
        } else {
          setShowPromptError(false);
          setShowCernLoader(true);
        }
        setMessage("");
        setSelectedFiles([]);
        setAttachmentUploadData(undefined);
        setCurrentAttachmentBatchNumber("");
      } else {
        pushTheToast({
          text: "Please add text or attachment!",
          type: "warning",
          position: "top-right",
        });
      }
    }
  }, [state, selectedFiles, message, mutate]);

  const fetchNextPageHandler = useCallback(() => {
    changePage();
    const scrollableDiv = scrollToBottomMsg.current;
    if (scrollableDiv) {
      prevScrollHeightRef.current = scrollableDiv.scrollHeight;
    }
  }, [cernThread]);

  const cernThreadRef = useRef<HTMLDivElement | null>(null);

  useClickAway(cernThreadRef, () => {
    campaignDispatch({
      type: CampaignActionType.TOGGLE_SHOW_CERN_THREAD,
      payload: false,
    });
  });

  return (
    <div
      ref={cernThreadRef}
      className={`${styles.cernThread} p-3 ${styles.campaignWrapper}`}
    >
      <div className={`d-flex justify-content-between align-items-center mb-3`}>
        <p className={`mb-0 d-flex align-items-center ${styles.heading}`}>
          <>
            {" "}
            <span
              className={`cursor-pointer ${styles.rightArrow}`}
              onClick={() => handleClose && handleClose()}
            >
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <img
              src={saufter}
              alt="saufter"
              width={24}
              className="mx-2"
            />
          </>

          <span>Cern's thread</span>
        </p>
      </div>
      <div
        className={`${styles.msgContainer} ${styles.campaignContainer} d-flex flex-column`}
      >
        <MessageRenderer
          cernThread={cernThread}
          messageId={null}
          messageList={{}}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPageHandler={fetchNextPageHandler}
          isCampaign={true}
          scrollToBottomMsg={scrollToBottomMsg}
          setMessage={setMessage}
          styles={styles}
          showCernLoader={showCernLoader}
        />
        {showPromptError && <PromptMessage />}

        <MessageEditor
          sendMessageHandler={sendMessageHandler}
          message={message}
          setMessage={setMessage}
          attachmentUploadData={attachmentUploadData}
          setAttachmentUploadData={setAttachmentUploadData}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          currentAttachmentBatchNumber={currentAttachmentBatchNumber}
          setCurrentAttachmentBatchNumber={setCurrentAttachmentBatchNumber}
          isFileUploading={isFileUploading}
          setIsFileUploading={setIsFileUploading}
          err={err}
          setErr={setErr}
          fetchAllMentions={fetchAllMentions}
          sendMsgLoading={sendMsgLoading}
          styles={styles}
          disableAttachment={true}
        />
      </div>
    </div>
  );
};

export default CampaignCernThread;
