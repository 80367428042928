import { axiosJSON } from "src/globals/axiosEndPoints";
import { AJAXSTATUS } from "src/globals/constants";
import { IArticleCategoryData } from "./getAllArticleCategory";

export interface ArticleData {
  articleId: number | string;
  articleName: string;
  integrationId: number | string;
  articleStatus: "public" | "draft";
  createdAt: Date;
  createdAtGmt: Date;
  generatedBy?: "ai" | "manual";
}
export interface GetAllArticles {
  articles: { [articleId: string | number]: ArticleData };
  articlesIds: Array<number | string>;
  ajaxStatus: AJAXSTATUS,
  metaData: {
    count: number;
    total: number;
  };
}
export interface GetAllArticleParamsV2 {
  searchTerm?: string,
  integrationId: number|string,
  categoryId: number|string,
  start: number;
  limit: number;
  articleType?: "all" | "public";
}

export async function getAllArticles(params: GetAllArticleParamsV2) {
  const { data: res }: any = await axiosJSON.post(
    "/api/chatSetting/article/getAllV2",
    params
  );

  if (res.error === true) {
    throw new Error(res.message as string);
  }

  return normalizeArticleData(res.data.articles, res.metaData);
}

function normalizeArticleData(data: any, metaData: any) {
  const allArticles: GetAllArticles = {
    articles: {},
    articlesIds: [],
    ajaxStatus: "fulfilled",
    metaData: {
      count: 0,
      total: 0,
    },
  };

  data.map((article: ArticleData) => {
    const id = parseInt(article.articleId + "");
    allArticles.articlesIds.push(article.articleId);
    allArticles.articles[id] = article;
  });
  allArticles.metaData = {
    count: metaData.currentCount,
    total: metaData.totalCount,
  };

  return allArticles;
}
