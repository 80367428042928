import Lottie from "lottie-light-react";
import styles from "./SuccessMsg.module.scss";
import celebration from "src/assets/images/celebration.json";
import { useUploadNewCsvModalContext } from "../Hooks/useUploadNewCsvModalContext";
import Loader from "src/components/Loader";
import { GetAllCustomersParams } from "src/services/CustomerSegments/UploadCSV/getAllCustomers.service";
import { useGetAllCustomers } from "../MapAttribute/MapAttribute";
import { useSegmentView } from "../../../hooks/useSegmentView";

const SuccessMsg = () => {
  const { state } = useUploadNewCsvModalContext();
  const params: GetAllCustomersParams = {
    start: 0,
    limit: 10,
    attachmentId: state.uploadCSV?.attachmentId ?? "",
  };
  const { activeSegmentDetails } = useSegmentView();

  const { isError, isLoading, data } = useGetAllCustomers(
    params,
    activeSegmentDetails.activeSegmentType ?? "",
  );

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : isError ? (
        <div className={`mt-3 ${styles.contentWrapper}`}>
          <div className="d-flex align-items-center justify-content-center text-danger">
            {"Something went wrong!"}
          </div>
        </div>
      ) : data ? (
        <div className={`text-center mt-5 ${styles.successWrapper}`}>
          <Lottie
            loop={false}
            className={`${styles.celebrationAnimation}`}
            animationData={celebration}
          ></Lottie>
          <span className={`mx-auto mb-3 ${styles.successIcon}`}>
            <i className="fa-solid fa-check"></i>
          </span>
          <p className={`mb-2 ${styles.successHead}`}>
            CSV file uploaded successful
          </p>
          <span className={`d-block ${styles.successSpan}`}>
            {state.uploadCSV?.uploadedFileName ?? ""}
          </span>
          <span className={`${styles.successDesc}`}>
            Total of {data?.totalCustomers ?? 0} users were imported
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default SuccessMsg;
