import styles from "./SelectExchange.module.scss";
import returnImg from "src/assets/images/ReturnImg.png";
import orderImg from "src/assets/images/order1.png";
import orderImg2 from "src/assets/images/order2.png";
import TimeLine from "../Timeline/Timeline";
import { useCallback, useState } from "react";
import {
  IAddedItem,
  StepName,
  useReturnPreviewState,
} from "../../../../../../hooks/useReturnPreview";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import SearchProducts, {
  MiniProductVarient,
  dummyProducts,
} from "../SearchProducts/SearchProducts";
import ProductDetail from "../ProductDetail/ProductDetail";
import useGetAutomationData, {
  IReturnReason,
} from "src/features/ReturnAutoWorkFlow/hooks/useGetAutomationData";
import { useReturnAutoWorkFlow } from "src/features/ReturnAutoWorkFlow/hooks/useReturnAutoWorkFlow";

const SelectExchange = () => {
  const [showError, setShowError] = useState(false);
  const {
    selectedOrder,
    orders,
    updateState,
    followupModalData,
    ...contextValues
  } = useReturnPreviewState();
  const {
    getAllReturnTypes,
    getAllReasons,
    isReturnReasonsAvailable,
    arsResponse,
    explainAnswer,
    isReturnMethodAvailable,
  } = useGetAutomationData();
  const { allReturnTypes } = getAllReturnTypes();
  const allReasons = getAllReasons();
  const { returnAutoWorkFlow } = useReturnAutoWorkFlow();
  const [selectedProductVarientId, setSelectedProductVariendId] =
    useState<string>();
  // Get all item IDs from addedItems
  const items = Object.values(selectedOrder?.addedItems ?? {});

  const addedItems = selectedOrder?.addedItems ?? {};
  const getSameProduct = (id: string) => {
    return [
      {
        id: id,
        name:
          selectedOrder?.itemsInOrder?.find(
            (value) => value.id + "" === id + "",
          )?.name ?? "Acme T-Shirt",
        sku:
          selectedOrder?.itemsInOrder?.find(
            (value) => value.id + "" === id + "",
          )?.sku ?? "0101",
        imgUrl:
          selectedOrder?.itemsInOrder?.find(
            (value) => value.id + "" === id + "",
          )?.name === "Acme T-Shirt"
            ? orderImg
            : orderImg2,
        price: selectedOrder?.itemsInOrder?.find(
          (value) => value.id + "" === id + "",
        )?.unitPrice ?? {
          amount: 789,
          currencyCode: "$",
        },
      } as MiniProductVarient,
    ];
  };
  const isExchangeWithSameItem = useCallback(
    (itemId: string) => {
      const item = addedItems[itemId];
      return (
        item?.selectedReturnType === "exchangeWithSameItems" ||
        item?.newReturnType === "exchangeWithSameItems"
      );
    },
    [selectedOrder?.addedItems],
  );

  const handleNext = () => {
    if (!selectedOrder) {
      return;
    }

    if (!selectedOrder.activeItemIdForExchange) {
      const addedItemsLength = Object.keys(addedItems).length;
      const selectedExchangeItemsLength = Object.values(addedItems).filter(
        (data) => data.exchangeItemIds && data.exchangeItemIds.length > 0,
      ).length;

      if (
        selectedExchangeItemsLength > 0 &&
        addedItemsLength === selectedExchangeItemsLength
      ) {
        let nextPage: StepName = isReturnMethodAvailable
          ? "returnMethod"
          : "configureNextSteps";
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: nextPage,
            prevStepForConfig: "arsExchange",
          },
        });
      } else {
        setShowError(true);
        return;
      }
    } else if (
      selectedOrder.activeItemIdForExchange &&
      !selectedProductVarientId
    ) {
      setShowError(true);
      return;
    } else if (
      selectedOrder.activeItemIdForExchange &&
      selectedProductVarientId
    ) {
      const activeItemIdForExchange = selectedOrder.activeItemIdForExchange;
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: {
            ...addedItems,
            [activeItemIdForExchange]: {
              ...addedItems[activeItemIdForExchange],
              exchangeItemIds: [
                ...(addedItems[activeItemIdForExchange]?.exchangeItemIds ?? []),
                selectedProductVarientId,
              ],
            },
          },
          activeItemIdForExchange: undefined,
        },
      });
      setSelectedProductVariendId(undefined);
      setShowError(false);
    }
  };

  const handleBack = () => {
    if (!selectedOrder?.activeItemIdForExchange) {
      let backPage: StepName = "addReturnItems";
      // Check if any item's selected reason has moreDetails length > 0
      const hasMoreDetails = Object.values(
        selectedOrder?.addedItems ?? {},
      ).some((data) => {
        if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
          "returnReasonOrderAndItems"
        ) {
          const reason = (allReasons as IReturnReason[])?.find(
            (reason) => reason.id === data.addedReasonId,
          );
          return (
            reason &&
            reason.moreQuestions &&
            reason.moreQuestions.length > 0 &&
            explainAnswer === "requiredAndCustom"
          );
        } else if (
          returnAutoWorkFlow.activeModalConfigDetails?.configureKey ===
            "followUpQuestion" &&
          data.addedReasonId
        ) {
          const reason = followupModalData.reasons[data.addedReasonId];
          return (
            (reason &&
              reason.reason.moreQuestions &&
              reason.reason.moreQuestions.length > 0) ||
            followupModalData.moreQuestions.length > 0
          );
        }
        return false;
      });

      // calculating backPage
      if (arsResponse?.returnTypes && arsResponse?.returnTypes?.length > 0) {
        backPage = "arsReturnTypes";
      } else if (arsResponse?.isOffersAvailable) {
        backPage = "arsOffers";
      } else if (arsResponse?.isInformationAvailable) {
        backPage = "arsInformation";
      } else if (hasMoreDetails) {
        backPage = "moreDetails";
      } else if (isReturnReasonsAvailable) {
        backPage = "reason";
      } else {
        backPage = "returnType";
      }
      const updatedAddedItems = Object.keys(addedItems).reduce(
        (acc, itemId) => {
          return {
            ...acc,
            [itemId]: {
              ...addedItems[itemId],
              exchangeItemIds: [], // Remove all exchange IDs
            },
          };
        },
        {} as Record<string, IAddedItem>,
      );

      if (selectedOrder)
        updateState({
          ...contextValues,
          selectedOrder: {
            ...selectedOrder,
            activeStep: backPage,
            activeItemIdForExchange: undefined,
            addedItems: updatedAddedItems,
          },
        });
    } else if (
      selectedOrder?.activeItemIdForExchange &&
      !selectedProductVarientId
    ) {
      if (selectedOrder)
        updateState({
          selectedOrder: {
            ...selectedOrder,
            activeItemIdForExchange: undefined,
          },
        });
    } else {
      setSelectedProductVariendId(undefined);
    }
  };
  const handleSelectExchangeItem = (itemId: string) => {
    if (selectedOrder)
      updateState({
        selectedOrder: {
          ...selectedOrder,
          activeItemIdForExchange: itemId,
        },
      });
  };
  const removeExchangeItem = (itemId: string, exchangeItemId: string) => {
    if (selectedOrder) {
      updateState({
        ...contextValues,
        selectedOrder: {
          ...selectedOrder,
          addedItems: {
            ...addedItems,
            [itemId]: {
              ...addedItems[itemId],
              exchangeItemIds:
                addedItems[itemId]?.exchangeItemIds?.filter(
                  (id) => id !== exchangeItemId,
                ) ?? [],
            },
          },
          activeItemIdForExchange: undefined,
        },
      });
    }
  };

  return (
    <div>
      <div className={`${styles.widgetBody}`}>
        {!selectedOrder?.activeItemIdForExchange ? (
          <div>
            <TimeLine activeStep={selectedOrder?.activeStep ?? "selectOrder"} />
            <div className="mt-3">
              <div className="d-flex align-items-center mb-3 pb-1">
                <img
                  src={returnImg}
                  alt=""
                />
                <span className={`ps-1 ${styles.heading}`}>
                  Select item to exchange
                </span>
              </div>
              {showError && (
                <span className={`p-1 mb-1 mt-2 ${styles.errorMsg}`}>
                  * Please select item(s) to exchange
                </span>
              )}
              {items?.map((item) => {
                const itemData = selectedOrder?.itemsInOrder?.find(
                  (value) => value.id + "" === item.itemId + "",
                );
                const returnType = allReturnTypes?.find(
                  (returnType) =>
                    returnType.type == item?.newReturnType ||
                    returnType.type == item?.selectedReturnType,
                );

                return (
                  <div className={`mb-2 ${styles.orderBox}`}>
                    <p className={`${styles.orderType}`}>
                      {itemData?.name} <span>*{returnType?.name}</span>
                    </p>
                    <span className={`${styles.radioLabel}`}></span>
                    <div className={`d-flex mb-3`}>
                      <div className={`${styles.cart}`}>
                        <img
                          src={
                            itemData?.name == "Acme T-Shirt"
                              ? orderImg
                              : orderImg2
                          }
                          alt="order"
                          className={`${styles.orderImg}`}
                        />
                      </div>
                      <div className={`ms-2`}>
                        <span className={`${styles.orderName}`}>
                          {" "}
                          {itemData?.unitPrice?.currencyCode}
                          {itemData?.unitPrice?.amount} x {itemData?.quantity}
                        </span>
                        <span className={`${styles.orderDesc}`}>
                          SKU: {itemData?.sku}
                        </span>
                      </div>
                    </div>
                    {item?.exchangeItemIds &&
                    item?.exchangeItemIds.length > 0 ? (
                      <div>
                        {item.exchangeItemIds.map((exchangeItemId, index) => {
                          const exchangeItem = isExchangeWithSameItem(
                            exchangeItemId,
                          )
                            ? getSameProduct(exchangeItemId)[0]
                            : dummyProducts.find(
                                (product) => product.id == exchangeItemId,
                              );
                          return (
                            <div key={index}>
                              <div className={`mt-2 ${styles.labelBox}`}>
                                <div className="d-flex">
                                  <div className={styles.cart}>
                                    <img
                                      src={exchangeItem?.imgUrl}
                                      alt="order"
                                      width={37}
                                      height={37}
                                    />
                                  </div>
                                  <div className="ms-2 w-100">
                                    <div className="d-flex justify-content-between align-items-lg-end">
                                      <div>
                                        <span className={styles.labelName}>
                                          {exchangeItem?.name}
                                        </span>
                                        <span className={styles.labelDesc}>
                                          SKU: {exchangeItem?.sku}
                                        </span>
                                        <span className={styles.labelValue}>
                                          {exchangeItem?.price?.currencyCode}
                                          {exchangeItem?.price?.amount}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`${styles.closeBtn} cursor-pointer`}
                                  onClick={() => {
                                    removeExchangeItem(
                                      item?.itemId,
                                      exchangeItemId,
                                    );
                                  }}
                                >
                                  <span>
                                    <i className="fa-solid fa-xmark"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <p
                          className={`my-2 ${styles.addMoreItem} cursor-pointer`}
                          onClick={() => handleSelectExchangeItem(item.itemId)}
                        >
                          + Add more items
                        </p>
                      </div>
                    ) : (
                      <div
                        className={`d-flex justify-content-between ${
                          styles.selectBox
                        } cursor-pointer ${
                          showError && "border border-danger"
                        }`}
                        onClick={() => handleSelectExchangeItem(item.itemId)}
                      >
                        <span className={styles.selectText}>
                          Select exchange item
                        </span>
                        <span className={styles.arrow}>
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : selectedProductVarientId ? (
          <ProductDetail
            product={
              isExchangeWithSameItem(selectedOrder?.activeItemIdForExchange)
                ? getSameProduct(selectedProductVarientId)
                  ? getSameProduct(selectedProductVarientId)[0]
                  : null
                : dummyProducts.find(
                    (product) => product.id === selectedProductVarientId,
                  ) ?? null
            }
          />
        ) : (
          <SearchProducts
            selectedProductVarientId={selectedProductVarientId}
            setSelectedProductVariendId={setSelectedProductVariendId}
            isExchangeWithSameItem={
              isExchangeWithSameItem(selectedOrder?.activeItemIdForExchange) ??
              false
            }
            sameProducts={getSameProduct(
              selectedOrder?.activeItemIdForExchange,
            )}
          />
        )}
      </div>
      <ButtonWrapper
        onBackClick={handleBack}
        onNextClick={handleNext}
      />
      <WidgetFooter />
    </div>
  );
};

export default SelectExchange;

{
  /* <div className="d-none">
  <p className={`${styles.selectText}`}>
    Would you prefer to exchange your order with another size or product,
    instead of a refund?
  </p>
  <div className={`mb-2 ${styles.orderBox}`}>
    <p className={`${styles.orderType}`}>
      Return Type : <span>Refund to original source</span>
    </p>
    <p className={`mb-0 ${styles.orderHeading}`}>Acme T-shirt</p>

    <div className={`d-flex mb-3`}>
      <div className={`${styles.cart}`}>
        <img
          src={orderImg}
          alt="order"
          className={`${styles.orderImg}`}
        />
      </div>
      <div className={`ms-2`}>
        <span className={`${styles.orderName}`}>$ 789 x 2</span>
        <span className={`${styles.orderDesc}`}>SKU : 0101</span>
      </div>
    </div>
    <p className={`mb-1 ${styles.chooseText}`}>Choose to proceed</p>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault1"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault1"
      >
        Exchange with same item
      </label>
    </div>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault2"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault2"
      >
        Exchange with other items
      </label>
    </div>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault3"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault3"
      >
        Process with refund
      </label>
    </div>
  </div>
  <div className={`mb-2 ${styles.orderBox}`}>
    <p className={`mb-0 ${styles.orderHeading}`}>Acme T-shirt</p>

    <div className={`d-flex mb-3`}>
      <div className={`${styles.cart}`}>
        <img
          src={orderImg}
          alt="order"
          className={`${styles.orderImg}`}
        />
      </div>
      <div className={`ms-2`}>
        <span className={`${styles.orderName}`}>$ 789 x 2</span>
        <span className={`${styles.orderDesc}`}>SKU : 0101</span>
      </div>
    </div>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault1"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault1"
      >
        Exchange with same item
      </label>
    </div>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault2"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault2"
      >
        Exchange with other items
      </label>
    </div>
    <div className="form-check">
      <input
        className={`form-check-input ${styles.radioCheck}`}
        type="radio"
        name="flexRadioDefault"
        id="flexRadioDefault3"
      />
      <label
        className={`form-check-label ${styles.radioLabel}`}
        htmlFor="flexRadioDefault3"
      >
        Process with refund
      </label>
    </div>
  </div>
</div>; */
}
