import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { IVariableB } from "src/routes/BotSettings/Children/BotBox/ChatBot/Children/Children/ConfigureSteps/Children/EmailSettings/ConfigureEmailModal/Children/EditEmail/EditEmail";

export interface UserDetails {
  type: string;
  Label: string;
  required: boolean;
  choices?: string[];
}

export interface AudienceConfig {
  id: number;
  botProfileId: number;
  userId: string | number | null;
  field: string;
  compareType: string;
  value: string;
}

interface SelectedOptions {
  id: string;
  name: string;
}

interface BrandData {
  id: number | string;
  name: string;
  email: string;
  imgURL?: string | null;
}
export type OptionType = {
  label: string;
  value: string;
};
export interface BotProfile {
  id: number;
  name: string;
  brandId: string;
  userId: number;
  channels: (number | string)[];
  eligibleUsers: (number | string)[];
  introductionMessage: string;
  botAnswerStrategy: (number | string)[];
  needUserDetails: boolean | null;
  userDetails: UserDetails[];
  createdAtGmt: string;
  updatedAt: string;
  updatedAtGmt: string;
  deleted: number;
  metaData: any[];
  audienceConfig: AudienceConfig[];
  answerContent: (number | string)[];
  collectDetailsFromUser: boolean;
  brandData?: BrandData | null;
  status: "draft" | "public";
  integrationId: number | string | undefined;
  botTestURL?: string;
  botPreviewURL?: string;
  visibleQuestionKeys?: Array<
    | "channels"
    | "eligible_users"
    | "introduction_message"
    | "bot_answer_strategy"
    | "need_user_details"
    | "user_details"
    | "answer_content"
    | "email_inbound"
    | "email_handle_ticket_assigned_to_agents"
    | "email_automatically_close_ticket"
    | "email_configure"
    | "email_followup_configure"
    | "isConfigureAdvancedBotSettings"
    | "configureAdvancedBotSettingsModel"
    | "useAdditionalCustomerInformation"
    | "user_segment"
  >;
  configureFormStatus: "pending" | "completed";
  email_inbound?: string;
  email_handle_ticket_assigned_to_agents?: string;
  email_automatically_close_ticket?: boolean;
  email_followup_configure?: boolean;
  emailConfigureModel?: {
    salutation: string;
    introduction: string;
    questionRegardingResolution: string;
  };
  emailFollowupConfigureModel?: {
    salutation: string;
    body: string;
  };
  emailVariables?: IVariableB[];
  emailConfigureModelUpdated?: boolean; //For configure modal completion status
  emailFollowupConfigureModelUpdated?: boolean; //For configure modal completion status
  isConfigureAdvancedBotSettings?: boolean;
  useAdditionalCustomerInformation?: boolean;
  configureAdvancedBotSettingsModel: {
    isCustomTemperatureEnabled: boolean;
    customTemperature: number;
    optimismLevelForBotLanguage: "optimistic" | "neutral" | "cautious";
    energyLevelForBotResponse: "energetic" | "balanced" | "calm";
    isToneAdjustmentForBotResponse: boolean;
    toneForBotResponse:
      | "professional"
      | "friendly"
      | "casual"
      | "enthusiastic"
      | "empathetic";
    isCompleted: boolean;
    customToneInstructionEnabled: boolean;
    customToneInstructionMessage?: string;
    contentRelatedInstructionEnabled: boolean;
    signOffInstruction?: string;
    topicAvoidance?: string[];
    personalizeMessage?: string;
  };
  topicAvoidanceOptions?: Array<{ label: string; value: string }>;
  userSegmentsDetails?: Array<OptionType>;
}

export interface BotProfileResponse {
  id: number;
  name: string;
  brandId: string;
  channels: SelectedOptions[];
  eligibleUsers: SelectedOptions[];
  status: string;
  brandData: BrandData | null;
  isFullyConfigured: boolean;
  botTestURL?: string;
  botPreviewURL?: string;
}

export interface GetAllBotProfilesResponse {
  data: BotProfileResponse[];
  metaData: {
    total: number;
    count: number;
  };
}

export interface GetAllBotProfilesParams {
  start: number;
  limit: number;
}

const apiEndPoint = IS_TEST_ACTIVE
  ? `${process.env.REACT_APP_TEST_SITE_URL}/botProfile/getAll`
  : "/api/botProfile/getAll";

export const getAllBotProfiles = async (params: GetAllBotProfilesParams) => {
  const { data: res } = await axiosJSON.get(apiEndPoint, { params });
  if (res.err) {
    throw res.msg;
  }

  return res as GetAllBotProfilesResponse;
};
