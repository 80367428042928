import { useState } from "react";
import { Modal } from "react-bootstrap";
import buildingCIcon from "src/assets/images/building.png";
import call from "src/assets/images/call-new.png";
import clockFill from "src/assets/images/clockFill.png";
import envelop from "src/assets/images/envelop.png";
import idCard from "src/assets/images/idCard.png";
// import idCardClip from "src/assets/images/idCardClip.png";
import insta from "src/assets/images/insta-new.png";
import locationIcon from "src/assets/images/location.png";
import mailIcon from "src/assets/images/mainIcon.png";
import twitter from "src/assets/images/twitter.png";
import whatsapp from "src/assets/images/Whastapp.svg";
import AxiosImg from "src/components/AxiosImg";
import UserAvatar from "src/components/UserAvatar";
import { ISegmentUserData } from "src/services/CustomerSegments/getSegmentUserById.service";
import ProfileDetail from "./Children/ProfileDetail/ProfileDetail";
import UserNote from "src/routes/Ticket/children/CustomerSB/children/UserNote/UserNote";
import { generateTimeOnly } from "src/utils/dateLibrary";
import Qualification from "./Children/Qualification/Qualification";
import styles from "./ProfileSegmentData.module.scss";
import { useCustomerSegments } from "src/routes/CustomerSegments/hooks/useCustomerSegments";

const ProfileSegmentData = ({
  data,
  segmentUserId,
}: {
  data: ISegmentUserData;
  segmentUserId: string;
}) => {
  const { activeSegmentDetails } = useCustomerSegments();
  const {
    customerData,
    companyDetails,
    qualification,
    recentPageViews,
    segmentData,
    userSegments,
    customerId,
  } = data;

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {/* <div className={` ${styles.addNoteBox} mb-2 p-3`}>
        <h3 className={`${styles.heading}`}>User note</h3>
        <button className={`px-2 ${styles.addBtn}`}>Add a note</button>
      </div> */}
      <UserNote customerId={customerId} />

      <div className={` ${styles.addNoteBox} mb-2 p-3`}>
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex align-items-center">
            {customerData?.imageURL && customerData.imageURL.trim() !== "" ? (
              <AxiosImg
                url={customerData.imageURL}
                className={`rounded-circle`}
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              <UserAvatar
                name={customerData?.name ?? "NA"}
                size={50}
              />
            )}
            <span className={`mx-1 ${styles.userName}`}>
              {customerData.name
                ? `${customerData.name
                    .charAt(0)
                    .toUpperCase()}${customerData.name.slice(1)}`
                : "NA"}
            </span>
            <span className={`${styles.userBtn}`}>
              {customerData.type === "user" ? "User" : "Lead"}
            </span>
          </div>
          {/* <div>
            <span>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </span>
          </div> */}
        </div>

        {customerData.channels && (
          <div className="d-flex align-items-center mb-2">
            {Object.entries(customerData.channels).map(([key, value]) => {
              if (!value || value.length === 0) {
                return null;
              }

              return (
                <div className={`me-2 ${styles.mediaBox}`}>
                  {key === "otherEmails" && (
                    <img
                      src={envelop}
                      alt=""
                      className={`${styles.envelop}`}
                    />
                  )}
                  {key === "phone" && (
                    <img
                      src={call}
                      alt=""
                      className={`${styles.callLogo}`}
                    />
                  )}
                  {key === "whatsapp" && (
                    <img
                      src={whatsapp}
                      alt=""
                      className={`${styles.envelop}`}
                    />
                  )}
                  {key === "instagram" && (
                    <img
                      src={insta}
                      alt=""
                      className={`${styles.editLogo}`}
                    />
                  )}
                  {key === "facebook" && (
                    <span className={`${styles.fb}`}>
                      <i className="fa-brands fa-facebook"></i>
                    </span>
                  )}
                  {key === "twitter" && (
                    <img
                      src={twitter}
                      alt=""
                      className={`${styles.envelop}`}
                    />
                  )}
                  {key === "linkedIn" && (
                    <span className={`${styles.linkedin}`}>
                      <i className="fa-brands fa-linkedin"></i>
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <div>
          {customerData.company && (
            <div className="d-flex align-items-center mb-1">
              <img
                src={buildingCIcon}
                alt="company"
                className={`${styles.mailIcon}`}
              />
              <span className={`ms-2 ${styles.desc}`}>
                {customerData.company}
              </span>
            </div>
          )}
          {customerData.location &&
            (customerData.location.city !== null ||
              customerData.location.country !== null ||
              customerData.location.region !== null) && (
              <div className="d-flex align-items-center mb-1">
                <img
                  src={locationIcon}
                  alt="location"
                  className={`${styles.mailIcon}`}
                />
                <span className={`ms-2 ${styles.desc}`}>{`${
                  customerData.location.city
                    ? customerData.location.city + ", "
                    : ""
                }${
                  customerData.location.region
                    ? customerData.location.region + ", "
                    : ""
                }${customerData.location.country ?? ""}`}</span>
              </div>
            )}

          {customerData.createdTimeGmt && (
            <div className="d-flex align-items-center mb-1">
              <img
                src={clockFill}
                alt="clock"
                className={`${styles.mailIcon}`}
              />
              <span className={`ms-2 ${styles.desc}`}>
                {generateTimeOnly(new Date(customerData.createdTimeGmt))}
              </span>
            </div>
          )}

          <div className="d-flex align-items-center mb-1">
            <img
              src={mailIcon}
              alt="email"
              className={`${styles.mailIcon}`}
            />
            <span className={`ms-2 ${styles.desc}`}>{customerData.email}</span>
          </div>
          <div className="d-flex align-items-center mb-1">
            <img
              src={idCard}
              alt="owner"
              className={`${styles.mailIcon}`}
            />
            <span className={`ms-2 ${styles.desc}`}>
              {customerData.owner ?? "No Owner"}
            </span>
          </div>
          {/* <div className="d-flex align-items-center mb-1">
            <img
              src={idCardClip}
              alt="number"
              className={`${styles.mailIcon}`}
            />
            <span className={`ms-2 ${styles.desc}`}>23456789</span>
          </div> */}
          <div className="d-flex justify-content-between align-items-center">
            {segmentData && segmentData.length !== 0 && (
              <span
                className={`${styles.seeMore}`}
                onClick={() => setShowModal(true)}
              >
                See more
              </span>
            )}
            {/* <span className={`${styles.edit}`}>Edit</span> */}
          </div>
        </div>
      </div>

      {/* <div className={` ${styles.tagBox} mb-2 p-3`}>
        <h3 className={`${styles.heading}`}>User tags</h3>
        <button className={`px-2 ${styles.addBtn}`}>Add tag</button>
      </div> */}
      {companyDetails && (
        <div className={` ${styles.tagBox} mb-2 p-3`}>
          <h3 className={`${styles.heading}`}>Company details</h3>
          <span className={`${styles.desc}`}>
            {companyDetails.length === 0
              ? "No visible details"
              : companyDetails.join(" ,")}
          </span>
        </div>
      )}
      {userSegments && (
        <div className={` ${styles.tagBox} mb-2 p-3`}>
          <h3 className={`${styles.heading}`}>User segments</h3>
          {userSegments.length === 0 ? (
            <span className={`${styles.userSegmentTag} me-1 my-2`}>
              {activeSegmentDetails.segmentName}
            </span>
          ) : (
            userSegments.map((val) => {
              return (
                <span className={`${styles.userSegmentTag} me-1 my-2`}>
                  {val}
                </span>
              );
            })
          )}
          {/* <span className={`${styles.desc}`}>
            {userSegments.length === 0 ? "No User Segment" : ""}
          </span> */}
        </div>
      )}
      <Qualification
        qualification={qualification}
        segmentUserId={segmentUserId}
      />
      {recentPageViews && (
        <div className={` ${styles.tagBox} mb-2 p-3`}>
          <h3 className={`${styles.heading}`}>Recent page views</h3>
          <span className={`${styles.desc}`}>
            {recentPageViews.length === 0
              ? "No page view"
              : recentPageViews.join(" ,")}
          </span>
        </div>
      )}
      <Modal
        backdropClassName={`${styles.modalBack}`}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={`${styles.modalDialog}`}
        contentClassName={`${styles.modalContent}`}
        centered={true}
      >
        <ProfileDetail
          segmentData={segmentData}
          onHide={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};

export default ProfileSegmentData;
