/**
 * This file defines a React component responsible for configuring and managing
 * the steps in the bot setup process. It handles the rendering of questions and
 * answers, updating values, and managing the overall configuration state.
 *
 * @author @Anubhav-busibud
 * @author @navjyot-busibud
 * @author @yuvaraj-busibud
 * @author @Jayalakshmi-busibud
 *
 */
import styles from "./ConfigureSteps.module.scss";
import Modal from "react-bootstrap/esm/Modal";
import { useNavigate } from "react-router-dom";
import { UseMutateFunction } from "@tanstack/react-query";
import { UpdateBotProfileParams } from "src/services/Bot/BotProfiles/updateBotProfile.service";
import { BotProfile } from "src/services/Bot/BotProfiles/getAllBotProfiles.service";
import { BrandOption } from "../../../../BrandModal/BrandModal";
import { CreateBotProfileResponse } from "src/services/Bot/BotProfiles/createBotProfile.service";
import PublishModal from "./Children/PublishModal/PublishModal";
import useConfigureSteps from "./useConfigureSteps";
import { useEffect, useMemo, useState } from "react";
import UnPublishModal from "./Children/UnPlubishModal/UnPublishModal";
import Lottie from "lottie-light-react";
import liveDot from "src/assets/images/liveDot.json";
import { useAppSelector } from "src/store/store";
import { Spinner } from "react-bootstrap";
import BasicSettings from "./Children/BasicSettings/BasicSettings";
import EmailSettings from "./Children/EmailSettings/EmailSettings";
import LiveChatSettings from "./Children/LiveChatSettings/LiveChatSettings";
interface Props {
  botSettings: BotProfile;
  updateBotSettings: UseMutateFunction<
    CreateBotProfileResponse,
    unknown,
    UpdateBotProfileParams,
    unknown
  >;
  selectedBrand: BrandOption | undefined;
  setSelectedBrand: (brand: BrandOption) => void;
  refetchGetBot: () => void;
  updateBrandChange: Function;
}

const ConfigureSteps = ({
  botSettings,
  updateBotSettings,
  selectedBrand,
  setSelectedBrand,
  refetchGetBot,
  updateBrandChange,
}: Props) => {
  const [brand, setBrand] = useState(selectedBrand);
  const navigate = useNavigate();
  const configureSteps = useConfigureSteps({
    botSettings,
    updateBotSettings,
    selectedBrand: brand,
    refetchGetBot,
    setSelectedBrand,
  });

  const {
    state,
    currentActiveStep,
    setCurrentActiveStep,
    brandHide,
    unPublishHide,
    showbrandModal,
    showUnPlublishModal,
    handlePublish,
    publishLoader,
    showSetup,
    handleUnpublish,
    handleGoLive,
    showAlreadyPublishedModal,
  } = configureSteps;

  useEffect(() => {
    setBrand(selectedBrand);
  }, [selectedBrand]);

  // Getting currentUserData to check for ui visibility
  const currentUserData = useAppSelector(
    (state) => state.globals.currentUserData,
  );

  /**
   * Handles redirection to the test or preview URL based on the bot status.
   */
  const handleTestAndPreviewRedirect = () => {
    if (state.botProfile.status === "draft") {
      if (state.botProfile.botTestURL) {
        window.open(state.botProfile.botTestURL, "_blank");
      }
    } else {
      if (state.botProfile.botPreviewURL) {
        window.open(state.botProfile.botPreviewURL, "_blank");
      }
    }
  };

  const showGoLive = useMemo(
    () =>
      (
        state.botProfile.channels.includes("4") &&
        state.botProfile.channels.includes("5") &&
        currentUserData?.ui_visibility?.includes("email_bot_enabled") // Jun 11 Added ui visibility condition
          ? state.botProfile.email_inbound + "" === "3"
            ? state.botProfile.visibleQuestionKeys?.includes(
                "need_user_details",
              )
            : state.botProfile.visibleQuestionKeys?.includes(
                "need_user_details",
              ) &&
              state.botProfile.visibleQuestionKeys?.includes(
                "email_followup_configure",
              )
          : state.botProfile.channels.includes("4")
            ? state.botProfile.visibleQuestionKeys?.includes(
                "need_user_details",
              )
            : state.botProfile.visibleQuestionKeys?.includes(
                "email_followup_configure",
              )
      )
        ? true
        : false,
    [state],
  );

  const showLiveChatSettings = useMemo(() => {
    return (
      state.botProfile.channels.includes("4")
        ? state.botProfile.channels.includes("5") &&
          currentUserData?.ui_visibility?.includes("email_bot_enabled") // Jun 11 Added ui visibility condition
          ? state.botProfile.email_inbound + "" === "3"
            ? state.botProfile.visibleQuestionKeys?.includes("email_inbound")
            : state.botProfile.visibleQuestionKeys?.includes(
                "email_followup_configure",
              )
          : state.botProfile.visibleQuestionKeys?.includes("user_segment")
        : false
    )
      ? true
      : false;
  }, [state]);

  const showEmailSettings = useMemo(
    () =>
      // Jun 11 Added ui visibility condition
      currentUserData?.ui_visibility?.includes("email_bot_enabled")
        ? state.botProfile.channels.includes("5") && //retrospective change for old profiles
          ((state.botProfile.visibleQuestionKeys?.includes("email_inbound") &&
            state.botProfile.visibleQuestionKeys?.includes("answer_content")) ||
            state.botProfile.visibleQuestionKeys?.includes("user_segment"))
          ? true
          : false
        : false,
    [state],
  );

  return (
    <div className="mt-3">
      <h4 className={`mb-4 ${styles.heading}`}>
        Please provide us with the following details to set up cern to answer
        questions.
      </h4>

      <BasicSettings
        updateBotSettings={updateBotSettings}
        refetchGetBot={refetchGetBot}
        configureSteps={configureSteps}
      />

      {showEmailSettings && (
        <EmailSettings
          configureSteps={configureSteps}
          refetchGetBot={refetchGetBot}
          showLiveChatSettings={showLiveChatSettings}
          showGoLive={showGoLive}
          updateBotSettings={updateBotSettings}
        />
      )}

      {showLiveChatSettings && (
        <LiveChatSettings
          configureSteps={configureSteps}
          refetchGetBot={refetchGetBot}
          updateBotSettings={updateBotSettings}
        />
      )}

      {/* You're ready to go live */}
      {showGoLive && (
        //Added condition such that when the first question in email section answer is option 3 and channels is only email then do not show go live section
        <>
          <div className="d-flex position-relative mb-2">
            <div className={`d-flex flex-column align-items-center me-2`}>
              <span
                className={`${
                  state.botProfile.status === "draft"
                    ? styles.count
                    : styles.doneCount
                }`}
              >
                {state.botProfile.status === "public" ? (
                  <span>
                    <i className="fa-solid fa-check"></i>
                  </span>
                ) : (
                  <span>
                    {state.botProfile.channels.includes("5") &&
                    // Jun 11 Added ui visibility condition
                    currentUserData?.ui_visibility?.includes(
                      "email_bot_enabled",
                    ) &&
                    state.botProfile.channels.includes("4")
                      ? "4"
                      : "3"}
                  </span>
                )}
              </span>
            </div>

            <div className={`p-3 w-100 ${styles.stepBox}`}>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setCurrentActiveStep(currentActiveStep === 4 ? 0 : 4);
                }}
              >
                <h6 className={`mb-0 ${styles.live}`}>
                  You're ready to go live!
                </h6>
                <span className={`${styles.stepSpan}`}>
                  The setup is complete for you. Test the flow and go live with
                  automated returns flow.
                </span>
              </div>
              {currentActiveStep === 4 && (
                <div className="d-flex align-items-center mt-3">
                  <button
                    className={`me-2 px-3 ${styles.liveBtn}`}
                    onClick={handleGoLive}
                    disabled={selectedBrand === null}
                  >
                    {publishLoader ? (
                      <Spinner size="sm" />
                    ) : state.botProfile.status === "draft" ? (
                      <>
                        <p className="mb-0">
                          <span className="pe-1">
                            <i className="fa-regular fa-eye"></i>
                          </span>
                          <span>Go Live </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="mb-0">
                          <span className="pe-1">
                            <i className="fa-regular fa-eye"></i>
                          </span>
                          <span>Unpublish </span>
                        </p>
                      </>
                    )}
                  </button>
                  {state.botProfile.status === "draft" ? (
                    <>
                      <button
                        className={`px-3 ${styles.liveBtn} ${
                          !state.botProfile?.botTestURL ? "d-none" : ""
                        }`}
                        onClick={handleTestAndPreviewRedirect}
                      >
                        Preview
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`px-3 ${styles.liveBtn} ${
                          !state.botProfile?.botPreviewURL ? "d-none" : ""
                        }`}
                        onClick={handleTestAndPreviewRedirect}
                      >
                        Test Now
                      </button>
                    </>
                  )}
                  {state.botProfile.status !== "draft" && (
                    <>
                      <div className="ms-3 d-flex align-items-center">
                        {/* <div className={`${styles.greenDot}`}></div> */}
                        <span
                          style={{
                            height: 20,
                            width: 20,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Lottie animationData={liveDot}></Lottie>
                        </span>
                        <div className="ps-1">
                          <span className={`${styles.liveText}`}>Live</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {!showUnPlublishModal && (
              <Modal
                backdropClassName={`${styles.modalBrandBack}`}
                show={showbrandModal}
                onHide={brandHide}
                dialogClassName={`${styles.modalBrandDialog}`}
                contentClassName={`${styles.modalBrandContent}`}
              >
                <PublishModal
                  onHide={brandHide}
                  selectedBrand={brand}
                  setSelectedBrand={setBrand}
                  handlePublish={handlePublish}
                  publishLoader={publishLoader}
                  showSetup={showSetup}
                  handleUnpublish={handleUnpublish}
                  name={state.botProfile.name}
                  selectedBrandName={state.botProfile.brandData?.name ?? ""}
                  showAlreadyPublishedModal={showAlreadyPublishedModal}
                  // updateBrandChange={updateBrandChange}
                  // onComplete={onComplete}
                />
              </Modal>
            )}

            {showUnPlublishModal && (
              <Modal
                backdropClassName={`${styles.modalBrandBack}`}
                show={showUnPlublishModal}
                onHide={unPublishHide}
                dialogClassName={`${styles.modalBrandDialog}`}
                contentClassName={`${styles.modalBrandContent}`}
              >
                <UnPublishModal
                  publishLoader={publishLoader}
                  successHeading="Moved to Unpublished"
                  successMessage="Cern is no longer visible to the audience. Please publish it to make it live."
                  handleGoLive={handleGoLive}
                  handleOnOkay={() => {
                    navigate("/bot/botProfiles");
                  }}
                />
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigureSteps;
