import ParseHTML from "src/components/ParseHTML";

const string = `<table
  role="presentation"
  cellspacing="0"
  cellpadding="0"
  border="0"
  align="center"
  width="100%"
  style="background-color: #edeff3; max-width: 680px"
  class="mktoContainer email-container"
  id="mktoContainer"
>
  <tbody>
    <tr
      class="mktoModule"
      id="twoColumnsTextModule821d99cb-ec47-4ae2-a95b-1ba75412905a"
    >
      <td
        align="center"
        height="100%"
        valign="top"
        width="100%"
        style="
          background-color: #f6f7f9;
          width: 100%;
          padding: 15px 40px 15px 40px;
        "
      >
        <!--[if mso]>
                                      <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="660" style="width: 660px;">
                                          <tr>
                                              <td align="center" valign="top" width="660" style="width: 660px;">
                                              <![endif]-->
        <table
          role="presentation"
          border="0"
          cellpadding="0"
          cellspacing="0"
          align="center"
          width="100%"
          style="max-width: 600px"
        >
          <tbody>
            <tr>
              <td align="center" valign="top" style="font-size: 0">
                <!--[if mso]>
                                                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="660" style="width: 660px;">
                                                                  <tr>
                                                                      <td align="left" valign="top" width="300" style="width: 300px;">
                                                                      <![endif]-->
                <div
                  style="
                    display: inline-block;
                    max-width: 100%;
                    vertical-align: top;
                    width: 290px;
                  "
                  class="stack-column"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style="padding: 10px">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            width="100%"
                            style="width: 100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    font-size: 16px;
                                    padding-top: 10px;
                                    padding-left: 0px;
                                    text-align: left;
                                  "
                                  class="center-on-narrow"
                                >
                                  <div
                                    class="mktoText"
                                    id="twoColumnsTextModuleLeftContent821d99cb-ec47-4ae2-a95b-1ba75412905a"
                                    style="margin: 0"
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        color: #121118;
                                        font-family: 'Source Sans Pro', Arial,
                                          Helvetica, 'Open Sans', sans-serif;
                                        font-size: 16px;
                                        font-weight: normal;
                                        line-height: 150%;
                                      "
                                    >
                                      <strong></strong
                                      ><span
                                        
                                        title="BigCommerce Essentials"
                                        ><img
                                          src="https://grow.bigcommerce.com/rs/695-JJT-333/images/logo_20.10_bc_essentials_logo_260x82.png"
                                          width="124"
                                          height="39"
                                          constrain="true"
                                          imagepreview="false"
                                          alt="BigCommerce Essentials"
                                      /></span>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso]>
                                                                      </td>
                                                                      <td align="left" valign="top" width="300" style="width: 300px;">
                                                                      <![endif]-->
                <div
                  style="
                    display: inline-block;
                    max-width: 100%;
                    vertical-align: top;
                    width: 290px;
                  "
                  class="stack-column"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    width="100%"
                    style="width: 100%"
                  >
                    <tbody>
                      <tr>
                        <td class="padding-column-right-25">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            width="100%"
                            style="width: 100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style="
                                    font-size: 16px;
                                    padding-top: 10px;
                                    padding-right: 0px;
                                    text-align: right;
                                  "
                                  class="center-on-narrow"
                                >
                                  <div
                                    class="mktoText"
                                    id="twoColumnsTextModuleRightContent821d99cb-ec47-4ae2-a95b-1ba75412905a"
                                    style="margin: 0"
                                  ></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso]>
                                                                      </td>
                                                                  </tr>
                                                              </table>
                                                              <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        <!--[if mso]>
                                              </td>
                                          </tr>
                                      </table>
                                      <![endif]-->
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="fullWidthImageModule94d24394-0c28-4a50-be5f-594d5abe6df4"
    >
      <td
        style="
          background-color: #ffffff;
          font-size: 0;
          padding: 0px 0px 0px 0px;
          text-align: center;
          vertical-align: middle;
        "
      >
        <div
          class="mktoImg"
          id="heroImage94d24394-0c28-4a50-be5f-594d5abe6df4"
          mktolockimgsize="false"
          mktolockimgstyle="false"
        >
          <span
         
            style="border: none; color: #0d52ff; text-decoration: none"
           
          >
            <img
              align="center"
              alt="Pick a Plan"
              border="0"
              class="fluid g-img fluid g-img"
              style="
                background: #ffffff;
                color: #121118;
                max-width: 100%;
                width: 680px;
              "
              src="https://grow.bigcommerce.com/rs/695-JJT-333/images/email_21.08_sbtrialonboarding_680x210.png"
              width="680"
              height="210"
            />
          </span>
        </div>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="headlineModule57f27a9e-8fe5-409d-9e77-1c70cd04fc55"
    >
      <td>
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
          style="width: 100%"
        >
          <tbody>
            <tr>
              <td
                style="
                  background-color: #ffffff;
                  font-size: 16px;
                  padding: 45px 40px 0px 40px;
                  text-align: center;
                "
                class="center-on-narrow"
              >
                <div
                  class="mktoText"
                  id="headlineTxt57f27a9e-8fe5-409d-9e77-1c70cd04fc55"
                >
                  <h1
                    style="
                      color: 121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 25px;
                      font-weight: bold;
                      line-height: 150%;
                      margin: 0;
                    "
                  >
                    Get Your First Month on BigCommerce on Us
                  </h1>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="buttonModulebe9b6efb-aa15-4232-9ed5-c3ef10e216fc"
    >
      <td
        align="center"
        style="
          background-color: #ffffff;
          padding: 38px 40px 45px 40px;
          text-align: center;
        "
      >
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          align="center"
          width="100%"
        >
          <tbody>
            <tr>
              <td
                style="text-align: center; vertical-align: middle"
                align="center"
                valign="middle"
              >
                <!--[if mso]>
                                                  <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center">
                                                      <tr>
                                                          <td align="center" valign="middle">
                                                              <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="#" style="height: 42; v-text-anchor: middle; width: 190px;" arcsize="10%" strokecolor="#0D52FF" fillcolor="#0D52FF">
                                                                  <w:anchorlock/>
                                                                  <center style="color: #ffffff; font-family: Arial, Helvetica, sans-serif; font-size: 15px; font-weight: normal;">PICK A PLAN</center>
                                                              </v:roundrect>
                                                          <![endif]-->
                <span
                 
                  style="
                    background-color: #0d52ff;
                    border: 1px solid #0d52ff;
                    border-radius: 5px;
                    color: #ffffff;
                    display: inline-block;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 15px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    line-height: 44px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    width: 190px;
                    -webkit-text-size-adjust: none;
                    mso-hide: all;
                  "
                  >PICK A PLAN</span
                >
                <!--[if mso]>
                                                          </td>
                                                      </tr>
                                                  </table>
                                                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="fullWidthTextModulea0eca31c-3ac9-4e64-9bdc-01dc0a15394b"
    >
      <td>
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
          style="width: 100%"
        >
          <tbody>
            <tr>
              <td
                class="False"
                style="
                  background-color: #ffffff;
                  font-size: 16px;
                  padding: 45px 40px 0px 40px;
                  text-align: left;
                "
              >
                <div
                  id="fullWidthTextContenta0eca31c-3ac9-4e64-9bdc-01dc0a15394b"
                  class="mktoText"
                  style="margin: 0"
                >
                  <p
                    style="
                      margin: 0 0 10px 0;
                      color: #121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 150%;
                    "
                  >
                    Hi Jake,
                  </p>
                  <p
                    style="
                      margin: 0 0 10px 0;
                      color: #121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 150%;
                    "
                  >
                    <br />Congratulations on taking the step in upgrading your
                    ecommerce by starting a BigCommerce trial store. We hope
                    you're enjoying the trial process and exploring all of the
                    possible features and functionality.
                  </p>
                  <p
                    style="
                      margin: 0 0 10px 0;
                      color: #121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 150%;
                    "
                  >
                    To thank you for trying out the platform, we're offering you
                    a one-month free trial. You just have to pick the plan
                    that's the right fit for your business:
                  </p>
                  <ul style="padding: 0; margin: 0 0 0 40px">
                    <li
                      style="
                        color: #121118;
                        font-family: 'Source Sans Pro', Arial, Helvetica,
                          'Open Sans', sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 150%;
                        margin: 10px 0 0 0;
                        mso-special-format: bullet;
                        text-align: left;
                      "
                    >
                      <strong>Standard Plan:</strong> Quickly build a beautiful
                      online store designed to help convert.
                    </li>
                    <li
                      style="
                        color: #121118;
                        font-family: 'Source Sans Pro', Arial, Helvetica,
                          'Open Sans', sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 150%;
                        margin: 10px 0 0 0;
                        mso-special-format: bullet;
                        text-align: left;
                      "
                    >
                      <strong>Pro Plan:</strong> Help improve conversion with
                      advanced product search and access to Big Open Data
                      Solutions to create personalized shopper experiences.
                    </li>
                    <li
                      style="
                        color: #121118;
                        font-family: 'Source Sans Pro', Arial, Helvetica,
                          'Open Sans', sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 150%;
                        margin: 10px 0 0 0;
                        mso-special-format: bullet;
                        text-align: left;
                      "
                    >
                      <strong>Plus Plan:</strong> Help increase sales with
                      powerful conversion and marketing tools.
                    </li>
                    <li
                      style="
                        color: #121118;
                        font-family: 'Source Sans Pro', Arial, Helvetica,
                          'Open Sans', sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 150%;
                        margin: 10px 0 0 0;
                        mso-special-format: bullet;
                        text-align: left;
                      "
                    >
                      <strong>Enterprise Plan:</strong> Power your team's
                      success with the best full-service open platform and
                      custom pricing for customer groups (price lists).
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="buttonModule465b31c3-a14b-4936-a67a-a6963c3d3fcf"
    >
      <td
        align="center"
        style="
          background-color: #ffffff;
          padding: 50px 40px 0px 40px;
          text-align: center;
        "
      >
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          align="center"
          width="100%"
        >
          <tbody>
            <tr>
              <td
                style="text-align: center; vertical-align: middle"
                align="center"
                valign="middle"
              >
                <!--[if mso]>
                                                  <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center">
                                                      <tr>
                                                          <td align="center" valign="middle">
                                                              <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="#" style="height: 42; v-text-anchor: middle; width: 190px;" arcsize="10%" strokecolor="#0d52ff" fillcolor="#0d52ff">
                                                                  <w:anchorlock/>
                                                                  <center style="color: #ffffff; font-family: Arial, Helvetica, sans-serif; font-size: 16px; font-weight: normal;">PICK A PLAN</center>
                                                              </v:roundrect>
                                                          <![endif]-->
                <span
                 
                  style="
                    background-color: #0d52ff;
                    border: 1px solid #0d52ff;
                    border-radius: 5px;
                    color: #ffffff;
                    display: inline-block;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    line-height: 44px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    width: 190px;
                    -webkit-text-size-adjust: none;
                    mso-hide: all;
                  "
                  >PICK A PLAN</span
                >
                <!--[if mso]>
                                                          </td>
                                                      </tr>
                                                  </table>
                                                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="fullWidthTextModule2bc2b0fc-ebfc-4929-8f62-9828c8e50483"
    >
      <td>
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
          style="width: 100%"
        >
          <tbody>
            <tr>
              <td
                class="False"
                style="
                  background-color: #ffffff;
                  font-size: 16px;
                  padding: 50px 40px 0px 40px;
                  text-align: left;
                "
              >
                <div
                  id="fullWidthTextContent2bc2b0fc-ebfc-4929-8f62-9828c8e50483"
                  class="mktoText"
                  style="margin: 0"
                >
                  <p
                    style="
                      margin: 0 0 10px 0;
                      color: #121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 150%;
                    "
                  >
                    Thank you,
                  </p>
                  <p
                    style="
                      margin: 0 0 10px 0;
                      color: #121118;
                      font-family: 'Source Sans Pro', Arial, Helvetica,
                        'Open Sans', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 150%;
                    "
                  >
                    The BigCommerce Team
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="spacerModule0b5c585b-5a51-406c-aad9-d652faace003"
    >
      <td
        aria-hidden="true"
        height="70"
        style="background-color: #ffffff; line-height: 0"
      >
        &nbsp;
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="fullWidthImageModulec63c1f0e-7635-4c24-a951-975254bee763"
    >
      <td
        style="
          background-color: #0d52ff;
          font-size: 0;
          padding: 1px 0px 0px 0px;
          text-align: center;
          vertical-align: middle;
        "
      >
        <div
          class="mktoImg"
          id="heroImagec63c1f0e-7635-4c24-a951-975254bee763"
          mktolockimgsize="false"
          mktolockimgstyle="false"
        >
          <span
           
            style="border: none; color: #0d52ff; text-decoration: none"
            target="_blank"
          >
            <img
              align="center"
              alt="BigCommerce Essentials"
              border="0"
              class="fluid g-img fluid g-img"
              style="
                background: #0d52ff;
                color: #121118;
                max-width: 100%;
                width: 680px;
              "
              src="https://grow.bigcommerce.com/rs/695-JJT-333/images/logo_20.10_bc_footer_sb_bmark_1360x142.png"
              width="680"
              height="70"
            />
</span>
        </div>
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="socialLinksModule1a952c71-353b-4460-9ba9-2ff8ed7c72d3"
    >
      <td
        style="
          background-color: #121118;
          padding: 50px 40px 34px 40px;
          text-align: center;
        "
      >
        <!--[if mso]>
                                  <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="600" style="width: 600px;">
                                      <tr>
                                          <td align="center" valign="top" width="600" style="width: 600px;">
                                          <![endif]-->
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
        >
          <tbody>
            <tr>
              <td
                style="font-size: 0; text-align: center"
                class="social-link-module"
              >
                <!--[if mso]>
                                                          <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="600" style="width: 600px;">
                                                              <tr>
                                                                  <td align="left" valign="top" width="470" style="width: 470px;">
                                                                  <![endif]-->
                <div
                  style="
                    display: inline-block;
                    margin: 0 -2px;
                    width: 100%;
                    min-width: 200px;
                    max-width: 470px;
                    vertical-align: middle;
                    text-align: center;
                  "
                  class="stack-column"
                >
                  <table
                    role="presentation"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td style="text-align: center">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            align="center"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink11a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                     
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce Twitter"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/Twitter_White_32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                </span>
                                  </div>
                                </td>
                                <td style="padding-left: 15px">
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink21a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                     
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce Facebook"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/FB_White 32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                </span>
                                  </div>
                                </td>
                                <td style="padding-left: 15px">
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink31a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                    
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce LinkedIn"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/LinkedIn_White_32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                </span>
                                  </div>
                                </td>
                                <td style="padding-left: 15px">
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink41a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                    
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce YouTube"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/Youtube_White_32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td style="padding-left: 15px">
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink51a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                    
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce Pinterest"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/Pinterest_White_32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td style="padding-left: 15px">
                                  <div
                                    class="mktoImg"
                                    id="socialLinksSocialLink61a952c71-353b-4460-9ba9-2ff8ed7c72d3"
                                  >
                                    <span
                                     
                                      style="
                                        border: none;
                                        color: #0d52ff;
                                        text-decoration: none;
                                      "
                                    >
                                      <img
                                        alt="BigCommerce Instagram"
                                        border="0"
                                        class="center-on-narrow"
                                        height="auto"
                                        src="https://grow.bigcommerce.com/rs/695-JJT-333/images/Instagram_White_32px_Icon.png"
                                        width="25"
                                        style="
                                          background: #121118;
                                          color: #0d52ff;
                                          font-family: Arial, Helvetica;
                                          font-size: 9px;
                                          font-weight: normal;
                                          height: auto;
                                          line-height: 150%;
                                          max-width: 25px;
                                          width: 100%;
                                        "
                                      />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso]>
                                                                  </td>
                                                              </tr>
                                                          </table>
                                                          <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        <!--[if mso]>
                                          </td>
                                      </tr>
                                  </table>
                                  <![endif]-->
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="footerModule220a9823f-4c13-4857-9b8c-273fc4377f6f"
    >
      <td
        style="
          background-color: #121118;
          padding: 0px 40px 25px 40px;
          text-align: center;
        "
      >
        <!--[if mso]>
                                  <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="600" style="width: 600px;">
                                      <tr>
                                          <td align="center" valign="top" width="600" style="width: 600px;">
                                          <![endif]-->
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
        >
          <tbody>
            <tr>
              <td style="text-align: center; text-align: center">
                <!--[if mso]>
                                                          <table role="presentation" border="0" cellspacing="0" cellpadding="0" align="center" width="600" style="width: 600px;">
                                                              <tr>
                                                                  <td align="left" valign="top" width="470" style="width: 470px;">
                                                                  <![endif]-->
                <div
                  style="
                    display: inline-block;
                    margin: 0 -2px;
                    width: 100%;
                    min-width: 200px;
                    max-width: 470px;
                    vertical-align: middle;
                    text-align: center;
                  "
                  class="stack-column"
                >
                  <table
                    role="presentation"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    align="center"
                  >
                    <tbody>
                      <tr>
                        <td style="padding: 0 0 15px 0; text-align: center">
                          <table
                            role="presentation"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="stack-column-center"
                                  style="font-size: 16px"
                                >
                                  <div
                                    class="mktoText"
                                    id="footerAddressA20a9823f-4c13-4857-9b8c-273fc4377f6f"
                                  >
                                    <p
                                      style="
                                        font-family: 'Source Sans Pro', Arial,
                                          Helvetica, 'Open Sans', sans-serif;
                                        font-size: 13px;
                                        line-height: 150%;
                                        color: #ffffff;
                                        font-weight: normal;
                                        margin: 0;
                                      "
                                    >
                                      BigCommerce<span class="hide-on-mobile"
                                        >&nbsp;&nbsp;|&nbsp;&nbsp;</span
                                      >
                                    </p>
                                  </div>
                                </td>
                                <td
                                  class="stack-column-center"
                                  style="font-size: 16px"
                                >
                                  <div
                                    class="mktoText"
                                    id="footerAddressB20a9823f-4c13-4857-9b8c-273fc4377f6f"
                                  >
                                    <p
                                      style="
                                        font-family: 'Source Sans Pro', Arial,
                                          Helvetica, 'Open Sans', sans-serif;
                                        font-size: 13px;
                                        line-height: 150%;
                                        color: #ffffff;
                                        font-weight: normal;
                                        margin: 0;
                                      "
                                    >
                                      11305 4 Points Dr Building 2, Suite
                                      100<span class="hide-on-mobile"
                                        >&nbsp;&nbsp;|&nbsp;&nbsp;</span
                                      >
                                    </p>
                                  </div>
                                </td>
                                <td
                                  class="stack-column-center"
                                  style="font-size: 16px"
                                >
                                  <div
                                    class="mktoText"
                                    id="footerAddressC20a9823f-4c13-4857-9b8c-273fc4377f6f"
                                  >
                                    <p
                                      style="
                                        font-family: 'Source Sans Pro', Arial,
                                          Helvetica, 'Open Sans', sans-serif;
                                        font-size: 13px;
                                        line-height: 150%;
                                        color: #ffffff;
                                        font-weight: normal;
                                        margin: 0;
                                      "
                                    >
                                      Austin, TX 78726
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso]>
                                                                  </td>
                                                              </tr>
                                                          </table>
                                                          <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        <!--[if mso]>
                                          </td>
                                      </tr>
                                  </table>
                                  <![endif]-->
      </td>
    </tr>
    <tr
      class="mktoModule"
      id="bigCommerceSubscriptionsModule16224764-e4ba-4a36-969e-c376c5fd81d5"
    >
      <td>
        <table
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
          width="100%"
          style="width: 100%"
        >
          <tbody>
            <tr>
              <td
                style="
                  background-color: #121118;
                  font-size: 16px;
                  padding: 0px 40px 70px 40px;
                  text-align: center;
                "
              >
                <div
                  class="mktoText"
                  id="bigCommerceSubscriptionsText16224764-e4ba-4a36-969e-c376c5fd81d5"
                >
                  <p
                    style="
                      font-family: 'Source Sans Pro', Arial, sans-serif;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 24px;
                      margin: 0 0 10px 0;
                    "
                  >
                    You received this email because you are subscribed to
                    communications from BigCommerce.
                  </p>
                  <p
                    style="
                      font-family: 'Source Sans Pro', Arial, sans-serif;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 24px;
                      margin: 0 0 10px 0;
                    "
                  >
                    To ensure delivery to your inbox, be sure to mark us as a
                    Safe Sender by adding our email address to your contacts or
                    address book.
                  </p>
                  <p
                    style="
                      font-family: 'Source Sans Pro', Arial, sans-serif;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 24px;
                    "
                  >
                    <span
                     
                      style="
                        text-decoration: none !important;
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 24px;
                      "
                      >Privacy Policy</span
                    >
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span
                     
                      style="
                        text-decoration: none;
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 24px;
                      "
                      >Update Your Preferences or Unsubscribe</span
                    >
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span
                    
                      style="
                        text-decoration: none !important;
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 24px;
                      "
                      >View as webpage</span
                    >
                  </p>
                  <div id="_two50">
                    <br />
                  </div>
                  <img
                    id="_two50_img"
                    src="http://250ok.bigcommerce.net/ea/MvgVdpzBrX/?e=1"
                    width="1"
                    height="1"
                    style="
                      margin: 0 !important;
                      padding: 0 !important;
                      border: 0 !important;
                      height: 1px !important;
                      width: 1px !important;
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`;

const BigCommerce = () => {
  return <ParseHTML html={string} />;
};

export default BigCommerce;
