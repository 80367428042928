import { useCallback, useMemo } from "react";
import useFetchSegments from "src/routes/CustomerSegments/children/SegmentView/Children/SendMessageModal/Hooks/useFetchSegments";
import { useAddCampaignState } from "../../../../../../Hooks/useAddCampaignContext";
import styles from "../../PrimaryDetails.module.scss";
import UserSelect, { UserSelectBrandOption } from "../UserSelect/UserSelect";

const UserSegmentSelectDD = () => {
  const { state, dispatch } = useAddCampaignState();

  const { allSegments, allSegmentIds, fetchMore, segmentsFetchStatus } =
    useFetchSegments();

  const allOptions = useMemo(() => {
    return allSegmentIds.map((id) => {
      const segment = allSegments[id];

      return {
        label: segment.value,
        value: segment.id,
      } as UserSelectBrandOption;
    });
  }, [allSegmentIds, allSegments]);

  const onChange = useCallback(
    (val: UserSelectBrandOption) => {
      if (val) {
        dispatch("updateState", {
          userSegmentId: val.value,
          showErrors: false,
        });
      }
    },
    [dispatch],
  );

  const selectedOption = useMemo(() => {
    if (!state.userSegmentId) {
      return null;
    }

    const segment = allSegments[state.userSegmentId];

    if (!segment) {
      return null;
    }

    return {
      label: segment.value,
      value: segment.id,
    } as UserSelectBrandOption;
  }, [allSegments, state.userSegmentId]);

  return (
    <div className="mb-3">
      <label
        htmlFor="userSegment"
        className={`form-label ${styles.label}`}
      >
        User segment
      </label>
      <UserSelect
        options={allOptions}
        value={selectedOption}
        placeholder="Choose user segments..."
        onMenuScrollToBottom={fetchMore}
        isMulti={false}
        onChange={(val) => {
          onChange(val as UserSelectBrandOption);
        }}
        isClearable={false}
        isSearchable={false}
        inputId="userSegment"
        menuPosition="fixed"
        className={`${
          state.showErrors && !selectedOption && styles.errorBorder
        }`}
        isLoading={segmentsFetchStatus === "pending"}
        showError={state.showErrors && !selectedOption}
      />
    </div>
  );
};

export default UserSegmentSelectDD;
