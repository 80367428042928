import MessageTitle from "./children/MessageTitle/MessageTitle";
import styles from "./MainChat.module.scss";
import SendMsgCommon from "./children/SendMsgCommon/SendMsgCommon";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/store";
import InfiniteScroll from "src/components/InfiniteScrollBothSide";
import { Spinner } from "react-bootstrap";
import emptyBackPng from "src/assets/images/emptyBack.png";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  addMessageForTryAgain,
  dateMessageIdListSelector,
  fetchInnerTicketMessages,
  fetchIsChannelIntegrated,
  isCurrentTicketMergedWithDifferentSelector,
  messageDataTicketSelector,
  resetActiveMessageThread,
  resetInnerTicketMessage,
  setActiveTicketInfoFromTicketData,
  setInnerTicketActive,
} from "src/store/slices/innerTicket/innerTicket.slice";
import SendMessageW from "./children/SendMessage/SendMessageW";
import {
  globalLoaderProgress,
  showGlobalLoader,
} from "src/components/GlobalLoader/GlobalLoader";
import ChatCreated from "src/routes/LiveChat/Children/MainChat/Children/ChatHistoryUpdated/Children/HistoryInnerView/children/ChatCreated/ChatCreated";
import TicketHeader from "../TicketHeader/TicketHeader";

interface Props {
  setShowMerge: Function;
  isBotPreview?: boolean;
}

function MainChat({ setShowMerge, isBotPreview }: Props) {
  const dispatch = useAppDispatch();
  const loaderShow = useRef(true);
  const { tableType, ticketId, viewType }: any = useParams();
  const brands = useAppSelector(
    (state) => state.globals.brandSignatureData.brands,
  );
  const scrollToRef = useRef(null);
  const [scrolledId, setScrolledId] = useState(null as null | string | number);
  const messageDataTicket = useAppSelector(messageDataTicketSelector);
  const dateMessageIdList = useAppSelector(dateMessageIdListSelector);
  const isCurrentTicketMergedWithDifferent = useAppSelector(
    isCurrentTicketMergedWithDifferentSelector,
  );
  const {
    messageData,
    messageDataAjaxStatus,
    messageList,
    hasMoreMessage,
    ticketDataAjaxStatus,
    messageDataLimit,
    permissionStatus,
    activeTicketInfo,
  } = useAppSelector((state) => state.innerTicket);

  // created and selected template from message
  const [
    createdAndSelectedTemplateFromMsg,
    setCreatedAndSelectedTemplateFromMsg,
  ] = useState<string>("");

  const currentState = useRef({
    tableType,
    messageDataAjaxStatus,
    messageScrolled: false, // used to check if the messages are fetched and scrolled to bottom for the first time
  });

  useMemo(() => {
    currentState.current = {
      tableType,
      messageDataAjaxStatus,
      messageScrolled:
        currentState.current.messageScrolled === false
          ? false
          : dateMessageIdList.length !== 0, // updating messageScrolled based on message length
    };
  }, [messageDataAjaxStatus, dateMessageIdList, tableType]);

  useMemo(() => {
    setScrolledId(null);
  }, [ticketId]);

  useMemo(() => {
    setScrolledId((prev) => {
      if (prev === null) {
        if (
          currentState.current.tableType === "mentions" &&
          activeTicketInfo.unread_mention_message_id
        ) {
          return activeTicketInfo.unread_mention_message_id;
        } else {
          return dateMessageIdList[dateMessageIdList.length - 1] ?? null;
        }
      } else if (dateMessageIdList.slice(0, 25).includes(0)) {
        return dateMessageIdList[messageDataLimit + 1] ?? null;
      } else {
        return dateMessageIdList[messageDataLimit] ?? null;
      }
    });
  }, [
    JSON.stringify(dateMessageIdList),
    activeTicketInfo.unread_mention_message_id,
  ]);

  useEffect(() => {
    // if (loaderShow.current) {
    if (
      (messageDataAjaxStatus === "fulfilled" ||
        messageDataAjaxStatus === "rejected") &&
      (ticketDataAjaxStatus === "fulfilled" ||
        ticketDataAjaxStatus === "rejected")
    ) {
      loaderShow.current = false;
      if (!currentState.current.messageScrolled) {
        currentState.current.messageScrolled = true;
      }
      showGlobalLoader("None");
    } else {
      globalLoaderProgress(100);
    }
    // }
  }, [messageDataAjaxStatus, ticketDataAjaxStatus]);

  const handleInfiniteScroll = useCallback(() => {
    if (
      hasMoreMessage &&
      currentState.current.messageDataAjaxStatus === "fulfilled" &&
      messageList.length > 0
    ) {
      dispatch(fetchInnerTicketMessages());
    }
  }, [messageList, hasMoreMessage]);

  useEffect(() => {
    if (loaderShow.current) {
      setTimeout(() => {
        showGlobalLoader("TicketDetails");
      }, 0);
    }
    dispatch(resetActiveMessageThread());
    dispatch(resetInnerTicketMessage());
    dispatch(setInnerTicketActive({ ticketId }));
    dispatch(setActiveTicketInfoFromTicketData({ ticketId }));
    dispatch(fetchInnerTicketMessages());

    const unsentMessages = JSON.parse(
      localStorage.getItem("unsentMessages") || "[]",
    );
    if (unsentMessages.length > 0) {
      unsentMessages.forEach((msg: any) => {
        if (msg.ticketId == ticketId) {
          dispatch(addMessageForTryAgain(msg));
        }
      });
    }
  }, [ticketId]);

  const brandId = useMemo(() => {
    return brands?.find((val) => val.email === activeTicketInfo.ticket_from)
      ?.id;
  }, [activeTicketInfo.ticket_from, brands]);

  useEffect(() => {
    if (brandId) {
      dispatch(
        fetchIsChannelIntegrated({
          brandId: brandId + "",
          channels: ["slack"],
        }),
      );
    }
  }, [brandId, dispatch]);

  // handle when the template is created from message and used
  const handleCreatedTemplateCreatedAndSelectedFromMsg = (
    selectedTemplateText: string,
  ) => {
    setCreatedAndSelectedTemplateFromMsg(selectedTemplateText);
  };

  // const customerData = useAppSelector(
  //   (state) => state.shopifySidebar.customerData
  // );

  const lastMessageId = dateMessageIdList[dateMessageIdList.length - 1];

  return (
    <div className={`${styles.mainChatWrapper}`}>
      <TicketHeader />
      <div
        className={`${styles.mainChat} d-flex flex-column ${
          isBotPreview && "w-100"
        }`}
      >
        <div className={`w-100 h-0 m-auto mt-0`}>
          {isBotPreview ? (
            ""
          ) : (
            <div className={`p-2`}>
              <MessageTitle setShowMerge={setShowMerge} />
            </div>
          )}
        </div>
        <InfiniteScroll
          className={`p-2 mt-0 mb-auto mx-auto d-flex flex-column h-100 w-100 ${styles.scrollDiv}`}
          loadMoreFromTop={handleInfiniteScroll}
          hasMoreTop={
            messageDataAjaxStatus === "rejected"
              ? false
              : hasMoreMessage && messageList.length > 0
                ? true
                : false
          }
          scrollToChildRef={scrollToRef}
          scrollToDependency={[scrolledId]}
        >
          {messageDataAjaxStatus === "pending" && messageList.length === 0 ? (
            <div className={`${styles.loading} m-auto`}>
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
                className={`d-block m-auto mb-2`}
              />
              <span>Loading Messages...</span>
            </div>
          ) : messageDataAjaxStatus === "rejected" ? (
            <div className={`${styles.nothingFound} m-auto`}>
              <img
                src={emptyBackPng}
                className={`d-block m-auto mb-2`}
              />
              <span>
                {/* Check if the message is rejected because of permission */}
                {permissionStatus &&
                permissionStatus.status === "agentPermissionDenied" &&
                permissionStatus.message
                  ? permissionStatus.message
                  : `We were unable to load this section. Please try again by reloading
              the page.`}
              </span>
            </div>
          ) : (
            <>
              {dateMessageIdList.map((messageId) => {
                const ref = messageId === scrolledId ? scrollToRef : undefined;
                //to remove mentions css after 2 sec
                const mentionElement: any = ref?.current;
                if (mentionElement) {
                  setTimeout(() => {
                    mentionElement.classList.remove(styles.activeMentions);
                  }, 2000);
                }
                if (messageId === 0) {
                  // show the current ticket id, created date and ticket description
                  return (
                    <div key={messageId}>
                      <SendMsgCommon
                        ticketId={ticketId}
                        ticketData={activeTicketInfo}
                        ticketType={`ct`}
                      />
                    </div>
                  );
                } else if ((messageId + "").includes("chat")) {
                  const messageDataChat = messageData[messageId];
                  if (messageDataChat?.chatMeta) {
                    const customerMessage =
                      messageDataChat.chatMeta.messages.filter(
                        (msg) => msg.senderType === "Customer",
                      )[0];

                    return (
                      <div
                        key={messageId}
                        ref={ref}
                        className={`${
                          lastMessageId === messageId ? "" : styles.scrollMargin
                        }`}
                      >
                        <ChatCreated
                          customerData={{
                            email:
                              customerMessage?.sentBy.email ??
                              messageDataChat.chatMeta.customerEmail + "",
                            name:
                              customerMessage?.sentBy.name ??
                              messageDataChat.senderName,
                            id: customerMessage?.sentBy.id ?? "",
                            img: customerMessage?.sentBy.imageURL,
                          }}
                          {...messageDataChat.chatMeta}
                          showGoToChat={true}
                          hideActionBtn={true}
                        />
                        {/* <OtherTickets /> */}
                      </div>
                    );
                  }
                } else {
                  return (
                    // show all other messages for current ticket and other tickets
                    <div
                      ref={ref}
                      key={messageId}
                      className={`${
                        lastMessageId === messageId ? "" : styles.scrollMargin
                      } ${
                        activeTicketInfo.unread_mention_message_id === messageId
                          ? styles.activeMentions
                          : ""
                      }`}
                    >
                      <SendMsgCommon
                        ticketId={ticketId}
                        ticketData={activeTicketInfo}
                        messageId={
                          isNaN(Number(messageId))
                            ? undefined
                            : parseInt(messageId + "")
                        }
                        messageData={messageData[messageId]}
                        ticketType={
                          ticketId == messageData[messageId]?.ticketId
                            ? "msg"
                            : "ot"
                        }
                        handleCreatedTemplateCreatedAndSelectedFromMsg={(
                          selectedTemplateText: any,
                        ) => {
                          handleCreatedTemplateCreatedAndSelectedFromMsg(
                            selectedTemplateText,
                          );
                        }}
                      />
                    </div>
                  );
                }
              })}
              <div className="mb-4 mt-auto">
                {/* To send message and add internal note */}
                {(messageDataAjaxStatus !== "pending" ||
                  messageList.length > 0) && (
                  <>
                    {isCurrentTicketMergedWithDifferent ? (
                      <>
                        <div
                          className={`m-3 p-2 d-flex justify-content-center ${styles.showMerged}`}
                        >
                          <i className="material-icons mx-1 my-auto">
                            &#xe88f;
                          </i>
                          <span className="mx-1 my-auto">
                            {`You can't send message to this ticket as it has been merged with Ticket Id `}
                            {activeTicketInfo?.merged_parent_tickets ? (
                              activeTicketInfo.merged_parent_tickets[0] ? (
                                <NavLink
                                  to={
                                    tableType === "mentions"
                                      ? `${`/tickets/all/${viewType}/ticket/${activeTicketInfo.merged_parent_tickets[0]}/`}` +
                                        window.location.search
                                      : window.location.pathname.replace(
                                          `/ticket/${ticketId}`,
                                          `/ticket/${activeTicketInfo.merged_parent_tickets[0]}`,
                                        ) + window.location.search
                                  }
                                >
                                  {activeTicketInfo.merged_parent_tickets[0]}
                                </NavLink>
                              ) : (
                                0
                              )
                            ) : (
                              0
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <SendMessageW
                        createdAndSelectedTemplateFromMsg={
                          createdAndSelectedTemplateFromMsg
                        }
                        setCreatedAndSelectedTemplateFromMsg={
                          setCreatedAndSelectedTemplateFromMsg
                        }
                        ticketId={ticketId}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </InfiniteScroll>
        {/* <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className={` ${styles.toolTipCustom}`}>
            Scroll to top
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div
            className={`cursor-pointer ${styles.scrollTop}`}
            {...triggerHandler}
            ref={ref}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </div>
        )}
      </OverlayTrigger> */}
        {/* show below modal when see why button is clicked  */}
      </div>
      {/* campain wrapper component  */}
    </div>
  );
}

export default MainChat;
