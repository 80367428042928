import ParseHTML from "src/components/ParseHTML";
import styles from "./DesktopView.module.scss";
import imgUpload from "src/assets/images/imgUpload.png";

const DesktopView = ({
  src,
  heading,
  body,
}: {
  src: string;
  heading: string;
  body: string;
}) => {
  return (
    <div className="">
      <div className={`text-center p-3 ${styles.headerWrapper}`}>
        <img
          src={src && src.trim() !== "" ? src : imgUpload}
          alt="logo"
          width={50}
          height={50}
          className="mx-auto text-center"
        />
        <h4 className={`mt-2 ${styles.heading}`}>{heading}</h4>
      </div>
      <div className={`${styles.mailPreview}`}>
        <ParseHTML html={body} />
      </div>
      <div>
        <hr />
      </div>
      <div className={`text-center ${styles.footer}`}>
        <p className={`mb-2 ${styles.footerText}`}>
          To stop receiving Update emails,{" "}
          <span style={{ textDecoration: "underline" }}>unsubscribe</span>.
        </p>
        <p className={`mb-0 ${styles.footerText}`}>
          Copyright © 2024 "Company name" <br /> All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default DesktopView;
