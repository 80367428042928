import { axiosJSON } from "src/globals/axiosEndPoints";
import { IS_TEST_ACTIVE } from "src/globals/constants";
import { Question, StepStatus } from "./getAutomationById.service";
import { AxiosError } from "axios";
import { AutomationAPIPayload } from "./createAutomation.service";

export interface UpdateQuestionsStep {
  stepId: string;
  stepQuestions: Record<string, Question["questionValue"]>;
}
export interface UpdateQuestionsParams {
  integrationId: string;
  stepData: UpdateQuestionsStep[];
}

interface UpdateQuestionError {
  message: string;
  errors: string[];
}
export interface UpdateQuestionResponse {
  updatedQuestions: Array<Partial<Question>>;
}

export const updateQuestionsService = async ({
  type,
  payload,
}: AutomationAPIPayload<UpdateQuestionsParams>) => {
  const url = IS_TEST_ACTIVE
    ? `${process.env.REACT_APP_TEST_SITE_URL}/bugManagement/update`
    : `/api/automation/${type}/updateQuestions`;

  try {
    const { data: res } = await axiosJSON.post(url, payload);

    if (res.err || res.error || res.errors) {
      if (res.errors.length !== 0) {
        throw res.errors[0];
      }

      throw res.msg || res.message || "Cannot Update automation data";
    }
    if (res?.data) {
      return res.data as UpdateQuestionResponse;
    }
  } catch (err) {
    if (typeof err === "string") {
      throw err;
    }

    const error = err as AxiosError<UpdateQuestionError>;
    if (
      error.response?.data?.errors &&
      error.response?.data?.errors.length !== 0
    ) {
      throw error.response.data.errors[0];
    }
  }

  return true;
};
