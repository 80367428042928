import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { AJAXSTATUS } from "src/globals/constants";
import getTriggersService from "src/services/Campaign/getTriggers.service";

const useFetchTriggers = () => {
  const { data, status, isFetching } = useQuery(["getTriggersService"], {
    queryFn: getTriggersService,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const triggers = useMemo(() => data ?? [], [data]);
  const fetchStatus = useMemo(
    () =>
      (isFetching
        ? "pending"
        : status === "success"
          ? "fulfilled"
          : status === "loading"
            ? "pending"
            : "rejected") as AJAXSTATUS,
    [isFetching, status],
  );

  return {
    triggers,
    fetchStatus,
  };
};

export default useFetchTriggers;
